import React from "react";
import { useHistory } from "react-router-dom";
import * as actionCreators from "./../../actions/actionCreators";
import store from "./../../store";

const ClearFiltersButton = (props) => {
  const history = useHistory();

  const handleSubmit = async () => {
    const filterProp = {
      dateRangeFilter: null,
      hourRangeFilter: null,
      orderTypeFilter: null,
      symbolFilter: null,
      strategyFilter: null,
      emotionalStateFilter: null,
      physicalStateFilter: null,
      mistakeFilter: null,
      timeFrameFilter: null,
      confidenceFilter: null,
      marketStateFilter: null,
      dayOfWeekFilter: null,
      monthFilter: null,
      assetClassFilter: null,
      tagsFilter: null,
      yearFilter: null,
    };
    localStorage.setItem("filtersAreRunning", "yes");
    localStorage.setItem("filters", JSON.stringify(filterProp));
  };
  const handleChange = async () => {
    const savecheck =
      props.allData && props.allData.portfolio.settings.savefilters;
    store.dispatch(actionCreators.resetFilteredList());
    history.push("/BlankPage");
    history.goBack();
    savecheck && handleSubmit();
  };

  return (
    <button className="clearfilters" onClick={handleChange}>
      Clear
    </button>
  );
};

export default ClearFiltersButton;
