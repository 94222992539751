import React from "react";
import supportedCryptos from "./../arrays/isCurrencyCrypto";

var he = require("he");
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const metricComponentforDollars = (
  i,
  TotalMetricsLabels,
  bata,
  colour,
  props
) => {
  const supportedCryptosCheck = supportedCryptos.includes(props.defaultSymbol);

  return (
    <div className="outermetriccell" key={TotalMetricsLabels[i]}>
      <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
      <div
        className="innermetriccell"
        style={{
          color: colour,
        }}
      >
        {supportedCryptosCheck
          ? parseFloat(bata[i].toFixed(6)) + " " + props.defaultSymbol
          : he.decode(props.defaultSymbol) +
            numberWithCommas(bata[i].toFixed(2))}
      </div>
    </div>
  );
};
export const metricComponentforPercent = (
  i,
  TotalMetricsLabels,
  bata,
  colour
) => {
  return (
    <div className="outermetriccell" key={TotalMetricsLabels[i]}>
      <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
      <div className="innermetriccell" style={{ color: colour }}>
        {parseFloat(bata[i].toFixed(3)) + "%"}
      </div>
    </div>
  );
};
export default metricComponentforDollars;
