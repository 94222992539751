import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../../components/PageTemplate/PageTemplate';


const MAEandMFEReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> MAE and MFE Report </div>
  } />
);

MAEandMFEReport.defaultProps = {
tabName: "MAE and MFE"
}

MAEandMFEReport.propTypes = {
tabName: PropTypes.string,
}

export default MAEandMFEReport;

