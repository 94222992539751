export const pinnedMetrics = [
  {
    winrate: {
      title: "Win Rate",
      calcprop: "winRate",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqgreen",
      color: "#55f8a6",
    },
  },
  {
    winlossesbe: {
      title: "Wins / Losses / BE's",
      calcprop: "winlossesbe",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqwhite",
      color: ["#55f8a6", "#fa798b", "#808080"],
    },
  },
  {
    assetclasses: {
      title: "Assets Traded",
      calcprop: "assetclasses",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqwhite",
      color: ["#3ebf7e", "#55cde2", "#e553fa", "#f35801", "#f2cd67", "#f70286"],
    },
  },
  {
    longshort: {
      title: "Long/Short",
      calcprop: "winlossesbe",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqwhite",
      color: ["#55f8a6", "#fa798b", "#808080"],
    },
  },
  {
    maxdrawdownpercent: {
      title: "Max. DD. %",
      calcprop: "maxDrawdownPercent",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqred",
      color: "#fa798b",
    },
  },
  {
    averagerisk: {
      title: "Avg. Risk %",
      calcprop: "avgRisk",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqred" /* "tiqgolddash", */,
      color: "#fa798b",
    },
  },
  {
    averageefficiency: {
      title: "Avg. Eff. %",
      calcprop: "avgEfficiency",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqcoolblue" /* "tiqpurple", */,
      color: "#98b5eb" /* "#c7b8ff", */,
    },
  },
  {
    percenttimeinmarket: {
      title: "% Time in Market",
      calcprop: "percentTimeinMarket",
      graphClass: "winrategraphwrapper",
      valueClass: "tiqcoolblue",
      color: "#98b5eb",
    },
  },
];
export const toppinnedMetrics = [
  {
    pl24hr: {
      title: "24hr P/L",
      title2: "24hr P/L",
      calcprop: "twentyFourHrPL",
      graphprop: "twentyfourhrArr",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "/",
      tooltip: "Total P/L in the last 24 hours.",
      description: "Total P/L in the last 24 hours.",
    },
  },
  {
    tiqgoldenratio: {
      title: "Golden Ratio",
      title2: "Golden Ratio",
      calcprop: "tiqGoldenRatio",
      graphprop: "tiqGoldenRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqgold",
      color: "#fbd665",
      forwardpath: "/Risk-Management",
      description:
        "Measures how well you are sticking to your planned reward-risk ratio.",
      tooltip:
        "The TradeFuse golden ratio measures how well you are sticking to your planned reward-risk ratio.",
    },
  },
  {
    tfindex: {
      title: "TradeFuse Index",
      title2: "TradeFuse Index",
      calcprop: "tfIndex",
      graphprop: "tfIndexGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqgold",
      color: "#fbd665",
      extramargin: "52px",
      forwardpath: "/KPIs",
      description:
        "Measures of the long-term profitability of your trading account. Combines win rate and profit/loss ratio.",
      tooltip:
        "The TradeFuse index is one of the single best measures of the long-term profitability of your trading account. It combines two of the most important metrics in trading: win rate and profit/loss ratio.",
    },
  },
  {
    profitlossratio: {
      title: "Profit/Loss Ratio",
      title2: "Profit/Loss Ratio",
      calcprop: "profitLossRatio",
      graphprop: "profitLossRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "Used to determine the potential return for every unit of risk taken in a trade.",
      forwardpath: "",
      tooltip:
        "The profit/loss ratio is used to determine the potential return for every unit of risk taken in a trade.",
    },
  },
  {
    cpcIndex: {
      title: "CPC Index",
      title2: "CPC Index",
      calcprop: "CPCIndex",
      graphprop: "cpcGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "A compound metric evaluating trading performance, combining profit-loss ratio, profit factor, and win rate.",
      forwardpath: "",
      tooltip:
        "The CPC Index, or Cardinal Profitability Constructs, is a performance ratio developed by Sunny Harris. It integrates the profit-loss ratio, profit factor, and win rate to provide a compound measure of trading performance and efficiency.",
    },
  },
  {
    profitfactor: {
      title: "Profit Factor",
      title2: "Profit Factor",
      calcprop: "profitFactor",
      graphprop: "profitFactorGraph",
      graphClass: "winrategraphwrapper",
      extramargin: "52px",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "Compares total profits to total losses. Used to assess the effectiveness and profitability of your trading account.",
      forwardpath: "",
      tooltip:
        "The profit factor compares total profits to total losses and is used to assess the effectiveness and profitability of your trading account.",
    },
  },
  {
    probabilityofrandomchance: {
      title: "PRC",
      title2: "Probability of Random Chance",
      calcprop: "probabilityOfRandomChance",
      graphprop: "probChancearrGraph",
      graphClass: "winrategraphwrapper",
      extramargin: "70px",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "Evaluates the likelihood that your trading performance is due to random market fluctuations.",
      forwardpath: "",
      tooltip:
        "The probability of random chance (PRC) evaluates the likelihood that your trading performance is due to random market fluctuations, helping to distinguish between skill-based success and mere chance.",
    },
  },
  {
    ulcerindex: {
      title: "Ulcer Index",
      title2: "Ulcer Index",
      calcprop: "ulcerIndex",
      graphprop: "ulcerIndexGraph",
      graphClass: "winrategraphwrapper",
      extramargin: "50px",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "Measures the depth and duration of drawdowns in price, focusing on downside risk and the intensity of price declines.",
      forwardpath: "",
      tooltip:
        "The Ulcer Index (or Pain Index) quantifies the severity of potential price declines, indicating the level of downside risk. It helps investors understand the impact of drawdowns on their trades.",
    },
  },
  {
    avgholdingtime: {
      title: "Avg. Holding Time",
      title2: "Avg. Holding Time",
      calcprop: "avgHoldingTime",
      graphprop: "avgHoldingTimeGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      tooltip: "The average holding time of all trades.",
      description: "Average holding time of all trades.",
      forwardpath: "",
    },
  },
  {
    avgPLpertrade: {
      title: "Avg. P/L Per Trade",
      title2: "Avg. P/L Per Trade",
      calcprop: "avgPLPerTrade",
      graphprop: "avgPLGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      description:
        "The average P/L over all trades, i.e. the expected P/L for the next trade.",
      tooltip:
        "Average P/L per trade over all trades, i.e. the expected P/L for the next trade.",
      forwardpath: "",
    },
  },
  {
    avgRRR: {
      title: "Avg. RRR",
      title2: "Avg. RRR",
      calcprop: "avgRRR",
      graphprop: "avgRRRGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Measures the potential reward relative to the amount of risk taken.",
      tooltip:
        "Reward-Risk Ratio (RRR) measures the potential reward relative to the amount of risk taken.",
    },
  },
  {
    recoveryfactor: {
      title: "Recovery Factor",
      title2: "Recovery Factor",
      calcprop: "recoveryFactor",
      graphprop: "recoveryFactorGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description: "Assesses your ability to recover from drawdowns.",
      tooltip:
        "The recovery factor assesses your ability to recover from drawdowns and offers insights into risk management effectiveness.",
    },
  },
  {
    expectation: {
      title: "Expectation",
      title2: "Expectation",
      calcprop: "expectation",
      graphprop: "expectationGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description: "Quantifies trading strategy robustness.",
      tooltip:
        "Expectation quantifies trading strategy robustness by comparing expected value per trade to average loss, with higher ratios suggesting healthier returns versus losses.",
    },
  },
  {
    volatility: {
      title: "Volatility",
      title2: "Volatility",
      calcprop: "volatility",
      graphprop: "volatilityGraph",
      graphClass: "volatilitygraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Measures the degree of variation in your trading performance.",
      tooltip:
        "Volatility quantifies the risk of fluctuation in returns, with higher volatility indicating higher risk and potential for both significant gains and losses.",
    },
  },
  {
    percentconfidence: {
      title: "Percent Confidence",
      title2: "Percent Confidence",
      calcprop: "percentConfidence",
      graphprop: "percentConfidenceGraph",
      graphClass: "confidencegraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Indicates the confidence level associated with the statistical Z-score.",
      tooltip:
        "Percent Confidence, often related to the Z-score in statistical analysis, indicates the reliability of a trading strategy's predictions. It measures the percentage of time that a trading strategy's results fall within a certain range of values, providing insight into the consistency and predictability of returns.",
    },
  },
  {
    arithmeticSharpeRatio: {
      title: "Sharpe ratio (Arithmetic)",
      title2: "Sharpe ratio (Arithmetic)",
      calcprop: "arithmeticSharpeRatio",
      graphprop: "arithmeticSharpeRatioGraph",
      graphClass: "sharperatiographwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      tooltip:
        "The arithmetic Sharpe ratio measures the performance of an investment compared to a risk-free asset, after adjusting for its risk.",
      description:
        "Evaluates excess returns per unit of risk, using arithmetic means for calculations.",
    },
  },
  {
    arithmeticSortinoRatio: {
      title: "Sortino ratio (Arithmetic)",
      title2: "Sortino ratio (Arithmetic)",
      calcprop: "arithmeticSortinoRatio",
      graphprop: "arithmeticSortinoRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Assesses returns in relation to downside risk, using arithmetic means.",
      tooltip:
        "The arithmetic Sortino ratio measures the risk-adjusted return of an investment asset, focusing on downward deviation.",
    },
  },
  {
    geometricSharpeRatio: {
      title: "Sharpe ratio (Geometric)",
      title2: "Sharpe ratio (Geometric)",
      calcprop: "geometricSharpeRatio",
      graphprop: "geometricSharpeRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      extramargin: "60px",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Calculates risk-adjusted returns, emphasizing the effect of compounding by using geometric means.",
      tooltip:
        "The geometric Sharpe ratio evaluates investment performance on a geometric mean basis, adjusting for risk.",
    },
  },
  {
    geometricSortinoRatio: {
      title: "Sortino ratio (Geometric)",
      title2: "Sortino ratio (Geometric)",
      calcprop: "geometricSortinoRatio",
      graphprop: "geometricSortinoRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Evaluates performance by focusing on downside risk in the context of compound returns.",
      tooltip:
        "The geometric Sortino ratio offers a measure of risk-adjusted return, prioritizing negative variance and using geometric means.",
    },
  },
  {
    kratio: {
      title: "K-Ratio",
      title2: "K-Ratio",
      calcprop: "KRatio",
      graphprop: "KRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Uses linear regression to evaluate return consistency through time.",
      tooltip:
        "The K-Ratio measures the consistency of return over time using linear regression techniques; it's similar to the Sharpe Ratio but focuses on return consistency.",
    },
  },
  {
    lrcorrelation: {
      title: "LR Correlation",
      title2: "LR Correlation",
      calcprop: "LRCorrelation",
      graphprop: "LRCorrelationGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Determines how well your trading account balance trend can be predicted over time using linear regression.",
      tooltip:
        "LR Correlation measures the strength and direction of the relationship between your trading account balance over time and a linear regression of your account balance.",
    },
  },
  {
    lrstandarderror: {
      title: "LR Standard Error",
      title2: "LR Standard Error",
      calcprop: "LRStandardError",
      graphprop: "LRStandardErrorGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Indicates the average distance that your trading account falls from the linear regression line.",
      tooltip:
        "LR Standard Error provides an estimate of the standard deviation of the linear regression model's errors, applied to your trading account's balance.",
    },
  },
  {
    standarddeviation: {
      title: "Standard Deviation",
      title2: "Standard Deviation",
      calcprop: "standardDeviation",
      graphprop: "standardDeviationGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Quantifies the variability in trading performance, highlighting potential volatility.",
      tooltip:
        "Standard Deviation measures the amount of variability or dispersion around your average P/L, indicating the volatility of trading returns.",
    },
  },
  {
    avgpositionsize: {
      title: "Avg. Position Size",
      title2: "Avg. Position Size",
      calcprop: "avgPositionSize",
      graphprop: "avgPositionSizeGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description: "Reflects the average position size taken over all trades.",
      tooltip:
        "Average position size measures the typical amount of units allocated to a single trade within your trading account.",
    },
  },
  {
    rMultipleRatio: {
      title: "R-Multiple Ratio",
      title2: "R-Multiple Ratio",
      calcprop: "rmultipleratio",
      graphprop: "RMultipleRatioGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      description:
        "Measures the actual reward relative to the amount of risk taken.",
      tooltip:
        "R-Multiple ratio measures the actual reward relative to the amount of risk taken.",
    },
  },
  {
    zScore: {
      title: "Z-Score",
      title2: "Z-Score",
      calcprop: "zScore",
      graphprop: "zScoreGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      extramargin: "60px",
      description:
        "Measures the dependence between consecutive trades, indicating whether winning or losing streaks are likely based on your trade history.",
      tooltip:
        "The Z-Score measures the dependence between consecutive trades, indicating whether winning or losing streaks are likely based on your trade history.",
    },
  },
  {
    statisticalZScore: {
      title: "Statistical Z-Score",
      title2: "Statistical Z-Score",
      calcprop: "statisticalZScore",
      graphprop: "statisticalZScoreGraph",
      graphClass: "winrategraphwrapper",
      valueClass: "topmetricvalue tiqcoolblue",
      color: "#98b5eb",
      forwardpath: "",
      extramargin: "60px",
      description:
        "Measures the number of standard deviations your trading is from the mean.",
      tooltip:
        "The statistical Z-score is a measurement that describes your recent trading's relatiionship to the mean, measured in terms of standard deviations from the mean.",
    },
  },
];
export const columnIds = [
  "dateTimeOpened",
  "dateTimeClosed",
  "holdingTime",
  "profitLoss",
  "netPLdollar",
  "netPLpercent",
  "orderType",
  "symbol",
  "executions",
  "lotSize",
  "entryPrice",
  "expectedEntry",
  "stopLoss",
  "takeProfit",
  "exitPrice",
  "expectedExit",
  "slippage",
  "commissions",
  "fees",
  "maxAdEx",
  "maxFavEx",
  "efficiency",
  "riskpercent",
  "rewardriskratio",
  "rmultiple",
  "strategy",
  "selectedConfidence",
  "selectedEmotion",
  "selectedPhysical",
  "selectedMarket",
  "selectedTimeFrame",
  "selectedMistake",
  "selectedTags",
];
export const columnNames = [
  "dtopenedcheckbox",
  "dtclosedcheckbox",
  "holdingtimecheckbox",
  "plamountcheckbox",
  "netpldollarcheckbox",
  "netplpercentcheckbox",
  "ordertypecheckbox",
  "symbolcheckbox",
  "executionscheckbox",
  "lotsizecheckbox",
  "entrypricecheckbox",
  "expectedentrycheckbox",
  "stoplosscheckbox",
  "takeprofitcheckbox",
  "exitpricecheckbox",
  "expectedexitcheckbox",
  "slippagecheckbox",
  "commissionscheckbox",
  "feescheckbox",
  "maxadexcheckbox",
  "maxfavexcheckbox",
  "efficiencybarcheckbox",
  "riskpercentcheckbox",
  "rewardriskcheckbox",
  "rmultiplecheckbox",
  "strategycheckbox",
  "confidencecheckbox",
  "emotionalstatecheckbox",
  "physicalstatecheckbox",
  "marketstatecheckbox",
  "timeframecheckbox",
  "mistakecheckbox",
  "tagcheckbox",
];
export const entryDataIDs = [
  "entry.startDateTime",
  "entry.endDateTime",
  "holdingtime",
  "entry.profitLoss",
  "netPLdollar",
  "netPLpercent",
  "entry.orderType",
  "entry.symbol.symbols",
  "executions",
  "entry.lotSize",
  "entry.entryPrice",
  "entry.expectedEntry",
  "entry.stopLoss",
  "entry.takeProfit",
  "entry.exitPrice",
  "entry.expectedExit",
  "slippage",
  "entry.commissions",
  "entry.fees",
  "entry.maxAdEx",
  "entry.maxFavEx",
  "efficiency",
  "riskpercent",
  "rewardriskratio",
  "rmultiple",
  "entry.strategy",
  "entry.selectedConfidence",
  "entry.selectedEmotion",
  "entry.selectedPhysical",
  "entry.selectedMarket",
  "entry.selectedTimeframe",
  "entry.selectedMistake",
  "entry.tags",
];
export default pinnedMetrics;
