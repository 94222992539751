import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../../components/PageTemplate/PageTemplate';

const FundReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Fund Report </div>
  } />
);

FundReport.defaultProps = {
tabName: "Fund Report"
}

FundReport.propTypes = {
tabName: PropTypes.string,
}
export default FundReport;

