import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import RiskRewardGraph from "./RiskRewardGraph";
import GoldenRatio from "./TradersIQGoldenRatio";
import ImportantMetricComponent from "./ImportantMetricComponent";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import {
  MMStrategylabels,
  MMStrategymetriclabels,
  MMRMultipleCol1labels,
  MMRMultiplemetricCol1labels,
  MMRMultipleCol2labels,
  MMRMultiplemetricCol2labels,
} from "./arrays";
import useStateWithPromise from "../../hooks/useStateWithPromise";
import PercentMetric from "./percentMetric";
import PercentMetricGraph from "./percentMetricGraph";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MoneyManagementComponent = (props) => {
  const allData = props.allData;
  const calculations = allData.calculations;
  const showUpgradeModalProp = props.showUpgradeModal.current;
  const [height, setHeight] = useState(window.innerHeight);
  const [mmwidth, setmmwidth] = useState(0);
  const [mmheight, setmmheight] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);
  const firstUpdate = useRef(true);

  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: showUpgradeModalProp.show,
    featureSelect: showUpgradeModalProp.featureSelect,
    blur: showUpgradeModalProp.blur,
  });
  const createTableRow = (array, metricarr) => {
    let tableRowsStrategy = [];
    for (let i = 0; i < array.length; i++) {
      const calc =
        metricarr[i] === "coefficientofvariationrmultiple" ||
        metricarr[i] === "optimalf" ||
        metricarr[i] === "optimalleverage" ||
        metricarr[i] === "theoreticalunitsize" ||
        metricarr[i] === "royssafetyfirstcriteria" ||
        metricarr[i] === "kellycriterion" ||
        metricarr[i] === "larrywilliams"
          ? calculations[metricarr[i]]
          : calculations[metricarr[i]] + " R";
      tableRowsStrategy.push(
        <div className="outermetriccell" key={array[i]}>
          <div>
            <div className="innermetriccell1PM">{array[i]}</div>
          </div>
          <div className="innermetriccell">{calc}</div>
        </div>
      );
    }
    return tableRowsStrategy;
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
    const mmwrappermain = document.getElementById("MMcomponentwrapper");
    const resizeObserver = new ResizeObserver((entries) => {
      const itemWidth = entries[0].contentRect.width;
      const itemHeight = entries[0].contentRect.height;
      setmmwidth(itemWidth);
      setmmheight(itemHeight);
    });
    mmwrappermain && resizeObserver.observe(mmwrappermain);

    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
    };
  }, []);

  return (
    <PageTemplate
      title={props.tabName}
      upgradeShow={showUpgradeModal}
      setshowUpgradeModal={(e) => {
        setshowUpgradeModal({
          ...showUpgradeModal,
          show: e.show,
          featureSelect: e.featureSelect,
          blur: e.blur,
          tierText: e.tierText,
        });
      }}
      page={
        mmwidth <= 1110 ? (
          <div id="MMcomponentwrapper">
            <div className="MMcomponentRIGHTTOPINNERwrapper">
              <div id="goldenratiowrapper">
                <div id="goldenratio">
                  <div id="goldenratioTEXT">
                    {"Golden Ratio"}{" "}
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The TradeFuse golden ratio measures how well you are
                          sticking to your planned reward-risk ratio. It is
                          calculated by dividing the profit/loss ratio by your
                          average reward-risk ratio.
                          <br></br>
                        </div>
                      }
                      xAdjust={27}
                      yAdjust={0}
                      placement="bottom"
                      delay={300}
                      keepOpen={false}
                    >
                      <div className="tooptip-i-style5">&#x1D48A;</div>
                    </PopoverStickOnHover>
                  </div>
                  <div id="goldenratioNUMBER">{calculations.goldenRatio}</div>
                  <div id="goldenratioGRAPHwrapper">
                    <PercentMetricGraph
                      userData={allData.portfolio.entries}
                      userCalculations={allData.calculations}
                      defaultSymbol={String(
                        allData.portfolio.settings.currencySymbol
                      )}
                      startingBalance={allData.calculations.start_Balance}
                    />
                  </div>
                </div>{" "}
                <PercentMetric
                  userCalculations={allData.calculations}
                  userData={allData.portfolio.entries}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userSettings={allData.portfolio.settings}
                  startingBalance={allData.calculations.start_Balance}
                  graph={"riskedpercent"}
                  graphTitle={"Avg. Risk %"}
                />
              </div>
              <ImportantMetricComponent
                metric={{ value: "avgRRR", label: "Average RRR" }}
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                calculationType={allData.portfolio.settings.calculationType}
              />
              <ImportantMetricComponent
                metric={{
                  value: "rmultipleratio",
                  label: "R-Multiple Ratio",
                }}
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                calculationType={allData.portfolio.settings.calculationType}
              />
            </div>
            <div className="MMcomponentLEFTINNERwrapper">
              <RiskRewardGraph
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                defaultSymbol={String(
                  allData.portfolio.settings.currencySymbol
                )}
                userPortfolioType={
                  allData.portfolio.assetClasses.length > 1
                    ? []
                    : allData.portfolio.assetClasses
                }
                calculationType={allData.portfolio.settings.calculationType}
                startingBalance={allData.calculations.start_Balance}
                tier={props.tier}
                active={props.active}
              />
            </div>
            <div id="MMcomponentRIGHTINNERwrapper">
              <div id="MMcomponentBOTTOMINNERwrapper">
                <div id="MM-metrics-table-RMultiple">
                  <div id="MM-metrics-label-RMultiple">R-Multiple Analysis</div>
                  <div id="MM-metrics-table-RMultiple-innerleft">
                    <div className="MM-RMultiple-metrics">
                      {createTableRow(
                        MMRMultipleCol1labels,
                        MMRMultiplemetricCol1labels
                      )}
                    </div>
                  </div>
                  <div id="MM-metrics-table-RMultiple-innerright">
                    <div className="MM-RMultiple-metrics">
                      {createTableRow(
                        MMRMultipleCol2labels,
                        MMRMultiplemetricCol2labels
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="MMcomponentBOTTOMRIGHTINNERwrapper">
                <div id="MM-metrics-table-Strategy">
                  <div id="MM-metrics-label-Strategy">Strategies</div>
                  <div className="MM-strategy-metrics">
                    {createTableRow(MMStrategylabels, MMStrategymetriclabels)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="MMcomponentwrapper">
            <div className="MMcomponentLEFTINNERwrapper">
              <div id="MMcomponentTOPINNERwrapper">
                <RiskRewardGraph
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                  calculationType={allData.portfolio.settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  tier={props.tier}
                  active={props.active}
                />
              </div>
              <div id="MMcomponentBOTTOMINNERwrapper">
                <div id="MM-metrics-table-RMultiple">
                  <div id="MM-metrics-label-RMultiple">R-Multiple Analysis</div>
                  <div id="MM-metrics-table-RMultiple-innerleft">
                    <div className="MM-RMultiple-metrics">
                      {createTableRow(
                        MMRMultipleCol1labels,
                        MMRMultiplemetricCol1labels
                      )}
                    </div>
                  </div>
                  <div id="MM-metrics-table-RMultiple-innerright">
                    <div className="MM-RMultiple-metrics">
                      {createTableRow(
                        MMRMultipleCol2labels,
                        MMRMultiplemetricCol2labels
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="MMcomponentRIGHTINNERwrapper">
              <div className="MMcomponentRIGHTTOPINNERwrapper">
                <div id="goldenratiowrapper">
                  <div id="goldenratio">
                    <div id="goldenratioTEXT">
                      {"Golden Ratio"}{" "}
                      <PopoverStickOnHover
                        component={
                          <div
                            style={{
                              color: "#fff7cb",
                              padding: "2px",
                              textAlign: "left",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            The TradeFuse golden ratio measures how well you are
                            sticking to your planned reward-risk ratio. It is
                            calculated by dividing the profit/loss ratio by your
                            average reward-risk ratio. Golden ratios above 1
                            mean you're letting profits run and cutting losses
                            early!
                            <br></br>
                          </div>
                        }
                        placement="bottom"
                        delay={300}
                        xAdjust={27}
                        yAdjust={0}
                        keepOpen={false}
                      >
                        <div className="tooptip-i-style5">&#x1D48A;</div>
                      </PopoverStickOnHover>
                    </div>
                    <div id="goldenratioNUMBER">{calculations.goldenRatio}</div>
                    <div id="goldenratioGRAPHwrapper">
                      <PercentMetricGraph
                        userData={allData.portfolio.entries}
                        userCalculations={allData.calculations}
                        defaultSymbol={String(
                          allData.portfolio.settings.currencySymbol
                        )}
                        startingBalance={allData.calculations.start_Balance}
                      />
                    </div>
                  </div>{" "}
                  <PercentMetric
                    userCalculations={allData.calculations}
                    userData={allData.portfolio.entries}
                    defaultSymbol={String(
                      allData.portfolio.settings.currencySymbol
                    )}
                    userSettings={allData.portfolio.settings}
                    startingBalance={allData.calculations.start_Balance}
                    graph={"riskedpercent"}
                    graphTitle={"Avg. Risk %"}
                  />
                </div>
                <ImportantMetricComponent
                  metric={{ value: "avgRRR", label: "Average RRR" }}
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  calculationType={allData.portfolio.settings.calculationType}
                />
                <ImportantMetricComponent
                  metric={{
                    value: "rmultipleratio",
                    label: "R-Multiple Ratio",
                  }}
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  calculationType={allData.portfolio.settings.calculationType}
                />
              </div>
              <div id="MMcomponentBOTTOMRIGHTINNERwrapper">
                <div id="MM-metrics-table-Strategy">
                  <div id="MM-metrics-label-Strategy">Strategies</div>
                  <div className="MM-strategy-metrics">
                    {createTableRow(MMStrategylabels, MMStrategymetriclabels)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      userData={allData}
    />
  );
};

export default MoneyManagementComponent;
