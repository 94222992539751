export const stripePriceIds = {
  admin: "price_1LyUB3JEnF6qjMZiigFeheo5",
  freepro: "price_1MkuaPJEnF6qjMZikyf4Ew2F",
  monthly: "price_1NQzmvJEnF6qjMZiHK5k8GX6",
  quarterly: "price_1NQzmYJEnF6qjMZiqykNch1F",
  annual: "price_1NQzmBJEnF6qjMZiZhQkqQqa",
  freemaster: "price_1Mla3qJEnF6qjMZijRgbEgN3",
  monthlymaster: "price_1NQzfdJEnF6qjMZiV9Dn0wbW",
  quarterlymaster: "price_1NQzfQJEnF6qjMZitnEll29Q",
  annualmaster: "price_1NQzf5JEnF6qjMZizC0csd07",
  freeultimate: "price_1NNs2kJEnF6qjMZiRWeutOMh",
  monthlyultimate: "price_1NUEtDJEnF6qjMZivVU7aIUT",
  quarterlyultimate: "price_1NUEt4JEnF6qjMZiJfPdpO7L",
  annualultimate: "price_1NUEssJEnF6qjMZiAWdzGtMx",
};
export const stripePriceIdsMapping = {
  admin: "price_1LyUB3JEnF6qjMZiigFeheo5",
  pro0: "price_1MkuaPJEnF6qjMZikyf4Ew2F", // free pro access (current)
  pro1: "price_1NrZ9gJEnF6qjMZiGZBT2yOK", // monthly
  pro2: "price_1NrZ9HJEnF6qjMZicUdwt4Az", // annual
  pro3: "price_1NrZ9XJEnF6qjMZilJdClA9a", // quarterly
  oldpro1: "price_1NQzmvJEnF6qjMZiHK5k8GX6", // monthly
  oldpro2: "price_1NQzmBJEnF6qjMZiZhQkqQqa", // annual
  oldpro3: "price_1NQzmYJEnF6qjMZiqykNch1F", // quarterly
  master0: "price_1Mla3qJEnF6qjMZijRgbEgN3", // free master access
  master1: "price_1NrZ3SJEnF6qjMZifdKYlDFo", // monthly 2
  master2: "price_1NrZ3BJEnF6qjMZiC0mbaEzN", // quarterly 2
  master3: "price_1NrZ2cJEnF6qjMZiREJtN0bg", // annual 2
  master4: "price_1NrZ4jJEnF6qjMZiJjvHqJT0", // monthly 3
  master5: "price_1NrZ4UJEnF6qjMZi1v4TgU02", // quarterly 3
  master6: "price_1NrZ3xJEnF6qjMZiLyNIWVyZ", // annual 3
  master7: "price_1NrZ7AJEnF6qjMZiUgjO2W1q", // monthly 4
  master8: "price_1NrZ6KJEnF6qjMZiAaU4KZKz", // quarterly 4
  master9: "price_1NrZ5FJEnF6qjMZiHoyWDR4o", // annual 4
  master10: "price_1Nr5PrJEnF6qjMZihHXdaxIo", // monthly 5
  master11: "price_1Nr5PjJEnF6qjMZiVIBveXUn", // quarterly 5
  master12: "price_1Nr5PTJEnF6qjMZiqqEDvCCM", // annual 5
  ultimate0: "price_1NNs2kJEnF6qjMZiRWeutOMh", // free ultimate access
  ultimate1: "price_1NrcgQJEnF6qjMZiyuU53gwV", // monthly 6
  ultimate2: "price_1NrcgBJEnF6qjMZiHHyqL7zj", // quarterly 6
  ultimate3: "price_1NrcfhJEnF6qjMZiefJh6cra", // annual 6
  ultimate4: "price_1NrchLJEnF6qjMZiBxInopoT", // monthly 7
  ultimate5: "price_1Nrch2JEnF6qjMZiEGeV65e8", // quarterly 7
  ultimate6: "price_1NrcgjJEnF6qjMZiaLXJ0wuf", // annual 7
  ultimate7: "price_1NrciMJEnF6qjMZiGXzuyOfu", // monthly 8
  ultimate8: "price_1Nrci7JEnF6qjMZiU55b7OP5", // quarterly 8
  ultimate9: "price_1NrchkJEnF6qjMZi8UVeSqNY", // annual 8
  ultimate10: "price_1NrcjTJEnF6qjMZilagP5YoA", // monthly 9
  ultimate11: "price_1Nrcj3JEnF6qjMZijnp3WNFm", // quarterly 9
  ultimate12: "price_1NrcihJEnF6qjMZibyfQKCST", // annual 9
  ultimate13: "price_1NrckLJEnF6qjMZi7Z3y3Yi7", // monthly 10
  ultimate14: "price_1Nrck6JEnF6qjMZizx8aB3PQ", // quarterly 10
  ultimate15: "price_1NrcjpJEnF6qjMZiTlAJ2R1W", // annual 10
};

export const proPriceIds = [
  "price_1NrZ9gJEnF6qjMZiGZBT2yOK",
  "price_1NrZ9XJEnF6qjMZilJdClA9a",
  "price_1NrZ9HJEnF6qjMZicUdwt4Az",
];

export const masterPriceIds = [
  "price_1NrZ3SJEnF6qjMZifdKYlDFo",
  "price_1NrZ4jJEnF6qjMZiJjvHqJT0",
  "price_1NrZ7AJEnF6qjMZiUgjO2W1q",
  "price_1Nr5PrJEnF6qjMZihHXdaxIo",
  "price_1NrZ3BJEnF6qjMZiC0mbaEzN",
  "price_1NrZ4UJEnF6qjMZi1v4TgU02",
  "price_1NrZ6KJEnF6qjMZiAaU4KZKz",
  "price_1Nr5PjJEnF6qjMZiVIBveXUn",
  "price_1NrZ2cJEnF6qjMZiREJtN0bg",
  "price_1NrZ3xJEnF6qjMZiLyNIWVyZ",
  "price_1NrZ5FJEnF6qjMZiHoyWDR4o",
  "price_1Nr5PTJEnF6qjMZiqqEDvCCM",
];

export const ultimatePriceIds = [
  "price_1NrcgQJEnF6qjMZiyuU53gwV",
  "price_1NrchLJEnF6qjMZiBxInopoT",
  "price_1NrciMJEnF6qjMZiGXzuyOfu",
  "price_1NrcjTJEnF6qjMZilagP5YoA",
  "price_1NrckLJEnF6qjMZi7Z3y3Yi7",
  "price_1NrcgBJEnF6qjMZiHHyqL7zj",
  "price_1Nrch2JEnF6qjMZiEGeV65e8",
  "price_1Nrci7JEnF6qjMZiU55b7OP5",
  "price_1Nrcj3JEnF6qjMZijnp3WNFm",
  "price_1Nrck6JEnF6qjMZizx8aB3PQ",
  "price_1NrcfhJEnF6qjMZiefJh6cra",
  "price_1NrcgjJEnF6qjMZiaLXJ0wuf",
  "price_1NrchkJEnF6qjMZi8UVeSqNY",
  "price_1NrcihJEnF6qjMZibyfQKCST",
  "price_1NrcjpJEnF6qjMZiTlAJ2R1W",
];

export default stripePriceIds;
