const initialState =  {
  allData: {
    userData: {},  
    userSlicedData: {},
    userSettings: {},
    userCalculations: {},
    userStartingBalance: 0,
    userStartingMetrics: {},
    userStartingDW: 0,
    userPortfolioType: [""]
  }
}

export const allData = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_AND_SETTINGS': 
      return {
        ...state, 
        allData: {
          ...state.allData,
          [action.id]: action.value
        }
      }
    default: 
      return state
  }
}

export default allData