import React from "react";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import supportedCryptos from "../../arrays/isCurrencyCrypto";

let he = require("he");
const OverallReportGraph = (props) => {
  const entries = props.userData;
  const supportedCryptosCheck = supportedCryptos.includes(props.defaultSymbol);
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  const equityvaluefunction = () => {
    let equityvalue = [];

    // push the rest of the equity values
    for (var i = 0, j = entries.length; i < j; i++) {
      const thisAccountBalance = Number(entries[i].balance);
      equityvalue.push(thisAccountBalance);
    }
    return equityvalue;
  };
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = entries.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };
  const lineColor = "#ffeb94";
  //const lineColor = "#664bff" //purple

  const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: equityvaluefunction(),
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => {
          return "Your Equity: ";
        },
        label: (tooltipItems) => {
          return supportedCryptosCheck
            ? parseFloat(tooltipItems.yLabel?.toFixed(6)) +
                " " +
                props.defaultSymbol
            : he.decode(props.defaultSymbol) +
                parseFloat(tooltipItems.yLabel)?.toFixed(2);
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return supportedCryptosCheck
                ? value + " " + props.defaultSymbol
                : he.decode(props.defaultSymbol) + value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Equity",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="overallreportgraph">
      <div className="equitycurve-label" id="equitycurve-label">
        Equity Curve
      </div>
      <div className="overallreportgraphwrapper">
        <Line
          data={data}
          options={options}
          className="ovallreportgraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default OverallReportGraph;
