import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { faFileImport, faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import liveIcong from "./../../images/noun-live-5455344ally2.png";
import liveIcon0 from "./../../images/noun-live-5455344.png";
import liveIcon1 from "./../../images/noun-live-5455344allwhite.png";
import liveIcon2 from "./../../images/noun-live-5455344oney2.png";
import liveIcon3 from "./../../images/noun-live-54553442y2.png";
import liveIcon4 from "./../../images/noun-live-54553443y2.png";
import liveIcon5 from "./../../images/noun-live-5455344allg2.png";
import linkedIcon from "./../../images/link-alt.png";
import linkedIcond from "./../../images/link-altd.png";
import unlinkedIcond from "./../../images/disconnected-chains.png";
import $ from "jquery";
import { DataContext } from "../../DataContext";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import getRobinhoodTokens from "./../../functions/robinhood/getRobinhoodTokens";
export const IntegrationsSubComponent = (props) => {
  const allGlobalData = useContext(DataContext);
  const customerPrice = allGlobalData?.customer?.priceId;
  const tier = props.tier;
  const active = props.active;
  const logo = props.logo;
  const integrationInfo = props.integrationInfo;
  const hasAuto = props.autoi;
  const hasFileImport = props.filei;
  const allautoImportConnectionsArray = props.allautoImportConnectionsArray;
  const numIntegrations = allautoImportConnectionsArray.length;
  const selectedBroker = props.sb;
  const logoTop = props.logotop;
  const web3s = props.web3s;
  const logotransform = props.logotransform;
  const logoheight = props.logoheight;
  const logowidth = props.logowidth;

  const connectedBroker = props.connected;
  /*   const connectedBroker = connectedBrokerUnfiltered?.filter((broker) => {
    console.log(broker)
  }) */
  const chosenPortfolios = props.chosenPortfolios;
  const createdPortfolios = props.createdPortfolios;
  // We need to further filter the connections because they
  const comingSoon = props.comingSoon;
  const portfolio = props.portfolio;
  const errorShow = props.errorShow;
  const portfolioBrokerInfo = portfolio?.settings?.linkedBrokerInfo;
  var images = [
    liveIcong,
    liveIcon2,
    liveIcon3,
    liveIcon4,
    liveIcon5,
    liveIcong,
    liveIcong,
  ];
  const localStorageautoiterationMessage =
    localStorage.getItem("connectingBroker");
  const buttonDisabledINIT = portfolioBrokerInfo?.broker === "" ? false : true;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonDisabled, setbuttonDisabled] = useState(buttonDisabledINIT);
  const [messageShow, setmessageShow] = useState(
    localStorageautoiterationMessage ? true : false
  );

  const history = useHistory();

  let canImport = false;
  let canAutoImport = false;

  // Tiering Logic
  if (tier === "free") {
  } else if (tier === "pro" && active) {
    canImport = true;
  } else if (tier === "oldpro" && active) {
    canImport = true;
  } else if (tier === "master" && active) {
    canAutoImport = true;
    canImport = true;
  } else if (tier === "ultimate" && active) {
    canAutoImport = true;
    canImport = true;
  } else if (tier === "admin" && active) {
    canAutoImport = true;
    canImport = true;
  } else {
  }

  useEffect(() => {
    const tick = () => {
      setCurrentIndex((currentIndex + 1) % images.length);
    };

    const loopTimeout = setTimeout(tick, 275);

    return () => clearTimeout(loopTimeout);
  }, [currentIndex, images]);

  useEffect(() => {
    const pauseTimeout = setTimeout(() => setCurrentIndex(0), 2000);

    return () => clearTimeout(pauseTimeout);
  });

  useEffect(() => {
    const localStorageautoiterationMessage =
      localStorage.getItem("connectingBroker");
    const pauseTimeout = setTimeout(() => {
      localStorageautoiterationMessage === null
        ? setbuttonDisabled(buttonDisabledINIT)
        : setbuttonDisabled(true);
      localStorageautoiterationMessage === null
        ? setmessageShow(false)
        : setmessageShow(true);
    }, 1);

    return () => clearTimeout(pauseTimeout);
  });

  // checks if the connection exists in the selected portoflio connections
  const connectedCheck =
    hasAuto &&
    connectedBroker?.length &&
    chosenPortfolios.includes(connectedBroker[0].port);

  let createdPortfolioMatching = createdPortfolios.slice();
  hasAuto &&
    connectedBroker?.length &&
    (createdPortfolioMatching = createdPortfolioMatching.filter((port) => {
      return port.name === connectedBroker[0].port;
    }));
  const portfolioColorPre =
    createdPortfolioMatching[0]?.settings?.portfolioColor;
  const colorRGB = portfolioColorPre && hexToRgb(portfolioColorPre);
  const portfolioColor =
    colorRGB && `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.08)`;
  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  // broker tokens
  const robinhoodTokens = getRobinhoodTokens();
  const integrationMaxCount =
    customerPrice === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    customerPrice === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    customerPrice === "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      ? 2
      : customerPrice === "price_1NrZ4jJEnF6qjMZiJjvHqJT0" ||
        customerPrice === "price_1NrZ4UJEnF6qjMZi1v4TgU02" ||
        customerPrice === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ"
      ? 3
      : customerPrice === "price_1NrZ7AJEnF6qjMZiUgjO2W1q" ||
        customerPrice === "price_1NrZ6KJEnF6qjMZiAaU4KZKz" ||
        customerPrice === "price_1NrZ5FJEnF6qjMZiHoyWDR4o"
      ? 4
      : customerPrice === "price_1Nr5PrJEnF6qjMZihHXdaxIo" ||
        customerPrice === "price_1Nr5PjJEnF6qjMZiVIBveXUn" ||
        customerPrice === "price_1Nr5PTJEnF6qjMZiqqEDvCCM"
      ? 5
      : customerPrice === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
        customerPrice === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
        customerPrice === "price_1NrcfhJEnF6qjMZiefJh6cra"
      ? 6
      : customerPrice === "price_1NrchLJEnF6qjMZiBxInopoT" ||
        customerPrice === "price_1Nrch2JEnF6qjMZiEGeV65e8" ||
        customerPrice === "price_1NrcgjJEnF6qjMZiaLXJ0wuf"
      ? 7
      : customerPrice === "price_1NrciMJEnF6qjMZiGXzuyOfu" ||
        customerPrice === "price_1Nrci7JEnF6qjMZiU55b7OP5" ||
        customerPrice === "price_1NrchkJEnF6qjMZi8UVeSqNY"
      ? 8
      : customerPrice === "price_1NrcjTJEnF6qjMZilagP5YoA" ||
        customerPrice === "price_1Nrcj3JEnF6qjMZijnp3WNFm" ||
        customerPrice === "price_1NrcihJEnF6qjMZibyfQKCST"
      ? 9
      : customerPrice === "price_1NrckLJEnF6qjMZi7Z3y3Yi7" ||
        customerPrice === "price_1Nrck6JEnF6qjMZizx8aB3PQ" ||
        customerPrice === "price_1NrcjpJEnF6qjMZiTlAJ2R1W"
      ? 10
      : customerPrice === "price_1Mla3qJEnF6qjMZijRgbEgN3"
      ? 2
      : customerPrice === "price_1NNs2kJEnF6qjMZiRWeutOMh"
      ? 6
      : 2; //default to 0, later add admin priveleges

  return (
    <div className="Integrations-component">
      <div
        className={
          connectedCheck
            ? "Integrations-inner-component-highlight"
            : "Integrations-inner-component"
        }
      >
        {selectedBroker.value === "AI File" ? (
          <div>
            {" "}
            <img
              className="linklogowrapperimg"
              style={{
                transform: logotransform,
                position: "relative",
                top: logoTop,
                maxWidth: 400,
                height: logoheight ? logoheight : "auto",
                width: logowidth ? logowidth : "100%",
              }}
              src={logo}
              alt=""
            />
            <div className="linklogowrapperimgsubheader1">Import with AI</div>
            <div className="linklogowrapperimgsubheaderBETA">Beta</div>
          </div>
        ) : (
          <img
            className="linklogowrapperimg"
            style={{
              transform: logotransform,
              position: "relative",
              top: logoTop,
              maxWidth: 400,
              height: logoheight ? logoheight : "auto",
              width: logowidth ? logowidth : "100%",
            }}
            src={logo}
            alt=""
          />
        )}
        {hasAuto &&
          (!connectedBroker?.length ? (
            ""
          ) : connectedCheck ? (
            connectedBroker[0].broker === "robinhood" &&
            (!robinhoodTokens?.length ||
              robinhoodTokens === null ||
              !robinhoodTokens) ? ( // || and other brokers mfa codes!!
              tier === "master" || tier === "ultimate" || tier === "admin" ? (
                <div
                  style={{
                    position: "absolute",
                    top: 100,
                    color: "#fff7cb",
                    margin: "-6px 0px 0px 0px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img
                    className="linklogowrapperautoimportbuttonimg"
                    alt=""
                    src={liveIcong}
                  />{" "}
                  &nbsp;
                  {`Connected but action needed`}
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#e67443",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Resolve Action
                      </div>
                    }
                    placement="bottom"
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <button
                      className="linklogowrapperautoimportbuttonactionneed"
                      onClick={() => allGlobalData.setmfaModalOpen(true)}
                    >
                      !
                    </button>
                  </PopoverStickOnHover>
                </div>
              ) : (
                <div className="linklogowrapperautoimportonfreewrapper">
                  <div>Connected but not listening.</div>
                  <div>
                    To regain access to automated importing,{" "}
                    <div
                      id="promocodeelement6"
                      onClick={() =>
                        history.push({
                          pathname: "/Manage-Account",
                          state: {
                            account: "none",
                            subscription: "block",
                            actions: "none",
                          },
                        })
                      }
                    >
                      <div id="promocodeelementmaintextold">
                        Upgrade to Master
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: 100,
                  color: "#fff7cb",
                  margin: "-6px 0px 0px 0px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {tier === "master" ||
                tier === "ultimate" ||
                tier === "admin" ? (
                  <span className="linklogowrapperautoimportonnotfreewrapper">
                    <img
                      className="linklogowrapperautoimportbuttonimg"
                      alt=""
                      src={images[currentIndex]}
                    />
                    &nbsp;&nbsp;
                    {`Connected to `}
                    <span
                      style={{
                        color: portfolioColorPre || "",
                        fontWeight: 500,
                      }}
                    >
                      {connectedBroker[0].port}
                    </span>{" "}
                    {`portfolio & `}
                    <div className="linklogowrapperautoimportonnotfreewrapperinner">
                      {`listening for ${
                        connectedBroker[0].broker === "metamask"
                          ? "transactions"
                          : "trades"
                      }...`}
                    </div>
                  </span>
                ) : (
                  <div className="linklogowrapperautoimportonfreewrapper">
                    <div>Connected but not listening.</div>
                    <div>
                      To regain access to automated importing,{" "}
                      <div
                        id="promocodeelement6"
                        onClick={() =>
                          history.push({
                            pathname: "/Manage-Account",
                            state: {
                              account: "none",
                              subscription: "block",
                              actions: "none",
                            },
                          })
                        }
                      >
                        <div id="promocodeelementmaintextold">
                          Upgrade to Master
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          ) : (
            <div
              style={{
                position: "absolute",
                top: 100,
                color: "#657385",
                margin: "-6px 0px 0px 0px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <img
                className="linklogowrapperautoimportbuttonimg"
                alt=""
                src={liveIcong}
              />{" "}
              &nbsp;
              {`Connected to ${connectedBroker[0].port} portfolio`}
            </div>
          ))}
        {hasAuto &&
          (!connectedBroker?.length ? (
            comingSoon ? (
              <div
                style={{
                  position: "absolute",
                  top: 136,
                  color: "#deebf7",
                  margin: "-6px 0px 0px 0px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Coming Soon...
              </div>
            ) : (
              <button
                disabled={buttonDisabled}
                onClick={() => {
                  const featureSelectMaster = {
                    show: true,
                    featureSelect: "Automated importing",
                    blur: false,
                    tierText: "master",
                  };
                  const featureSelectultimate = {
                    show: true,
                    featureSelect:
                      "Automated importing for up to 10 portfolios",
                    blur: false,
                    tierText: "ultimate",
                  };
                  if (!canAutoImport) {
                    props.setshowUpgradeModal(featureSelectMaster);
                    return;
                  }

                  if (chosenPortfolios?.length !== 2) {
                    $("#messageBox").fadeIn(300);
                    $("#overlay").fadeIn(300);
                    if (createdPortfolios.length === 1) {
                      document.getElementById("messageBoxMessage").innerHTML =
                        "Please create a portfolio to connect a broker.";
                    } else {
                      const returnThis =
                        "When connecting a broker, only one portfolio can be selected.";
                      document.getElementById("messageBoxMessage").innerHTML =
                        returnThis;
                    }
                  } else {
                    if (numIntegrations >= integrationMaxCount) {
                      /* if (integrationMaxCount === 5) {
                        //prompt to upgrade to enterprose
                      } else {
                        props.setupgradeOpen(true);
                      } */
                      props.setupgradeOpen(true);
                      return;
                    }
                    if (numIntegrations >= 10) {
                      // Display need more integration slots prompt
                      return;
                    }
                    props.openImportModal(true);
                    props.setimportState("auto");
                    props.setisweb3selected(web3s);
                    props.setselectedBroker(selectedBroker);
                  }
                }}
                className="linklogowrapperautoimportbutton"
              >
                <img
                  className="linklogowrapperautoimportbuttonimg"
                  alt=""
                  src={buttonDisabled ? linkedIcond : linkedIcon}
                />
                &nbsp;Connect for Auto Import
              </button>
            )
          ) : (
            (tier === "master" || tier === "ultimate" || tier === "admin") && (
              <button
                id={"autoimportunlinkbrokerbutton"}
                onClick={() => {
                  /*                 $("#autoimportunlinkmodalwrapper").fadeIn(300);
                document.getElementById(
                  "autoimportunlinkmodalwrapper"
                ).style.display = "flex";
                $("#overlay").fadeIn(300); */
                  props.openUnlinkModal(true);
                  props.setselectedBroker(selectedBroker);
                  props.setselectedConnectedBroker(connectedBroker);
                }}
              >
                <img
                  className="linklogowrapperautoimportbuttonimg2"
                  alt=""
                  src={unlinkedIcond}
                />{" "}
                &nbsp;
                {`Unlink ${selectedBroker.label}`}
              </button>
            )
          ))}
        {errorShow.state && selectedBroker?.value === errorShow.broker && (
          <div id="importwrongpreview2">
            {`Connection failed. Wait a few moments then try again.`}
          </div>
        )}
        {messageShow && buttonDisabled && (
          <div id="importwrongpreview2">{`Connection in progress.`}</div>
        )}
        <div className="Integrations-inner-line"></div>
        {hasFileImport && (
          <div
            style={{
              marginTop: "-6px",
            }}
            className="fileimportintegrationinfo"
          >
            <div style={{ fontWeight: 400 }}>
              {`Last Import: ${integrationInfo?.lastUp ? "" : "-"}`}
            </div>
            <div>{`${
              integrationInfo?.lastUp
                ? new Date(integrationInfo.lastUp).toLocaleString()
                : ""
            }`}</div>
            <div>
              {integrationInfo?.num
                ? `${integrationInfo.num} entries into `
                : ""}
              <span>{integrationInfo?.port}</span>
            </div>
          </div>
        )}
        {hasFileImport && (
          <button
            onClick={() => {
              const featureSelect = {
                show: true,
                featureSelect: "Unlimited imports",
                blur: false,
              };
              if (props.importCheckVar) {
                if (!canImport) {
                  props.setshowUpgradeModal(featureSelect);
                  return;
                }
              }

              if (chosenPortfolios?.length !== 2) {
                $("#messageBox").fadeIn(300);
                $("#overlay").fadeIn(300);
                if (createdPortfolios.length === 1) {
                  document.getElementById("messageBoxMessage").innerHTML =
                    "Please create a portfolio to import trades.";
                } else {
                  const returnThis = `When importing trades, only one portfolio can be selected.`;
                  document.getElementById("messageBoxMessage").innerHTML =
                    returnThis;
                }
              } else {
                props.openImportModal(true);
                props.setimportState("import");
                props.setisweb3selected(web3s);
                props.setselectedBroker(selectedBroker);
              }
            }}
            className="linklogowrapperimportbutton"
          >
            <FontAwesomeIcon icon={faFileImport} />
            &nbsp;&nbsp;Import From File
          </button>
        )}
      </div>
    </div>
  );
};
export default IntegrationsSubComponent;
