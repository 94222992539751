import React, { Component } from "react";

export const BarStyles = {
  BARS: "0",
  CANDLES: "1",
  HOLLOW_CANDLES: "9",
  HEIKIN_ASHI: "8",
  LINE: "2",
  AREA: "3",
  RENKO: "4",
  LINE_BREAK: "7",
  KAGI: "5",
  POINT_AND_FIGURE: "6",
};

export const IntervalTypes = {
  H: "60",
  D: "D",
  W: "W",
};

export const RangeTypes = {
  YTD: "ytd",
  ALL: "all",
};

export const Themes = {
  LIGHT: "Light",
  DARK: "Dark",
};

const SCRIPT_ID = "tradingview-widget-script";
const CONTAINER_ID = "tradingview-widget";

export default class TradingViewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static defaultProps = {
    allow_symbol_change: true,
    autosize: true,
    enable_publishing: false,
    hideideas: true,
    hide_legend: false,
    hide_side_toolbar: false,
    hide_top_toolbar: false,
    interval: IntervalTypes.H,
    locale: "en",
    save_image: true,
    show_popup_button: false,
    style: BarStyles.CANDLES,
    theme: Themes.DARK,
    timezone: "Etc/UTC",
    toolbar_bg: "#F1F3F6",
    widgetType: "widget",
    width: 980,
    withdateranges: false,
  };

  containerId = `${CONTAINER_ID}-${Math.random()}`;

  componentDidMount = () => this.appendScript(this.initWidget);

  componentDidUpdate = (prevProps) => {
    if (prevProps.symbol !== this.props.symbol) {
      this.cleanWidget();
      this.initWidget();
    }
  };

  canUseDOM = () =>
    !!(
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    );

  appendScript = (onload) => {
    if (!this.canUseDOM()) {
      onload();
      return;
    }

    if (this.scriptExists()) {
      /* global TradingView */
      if (typeof TradingView === "undefined") {
        this.updateOnloadListener(onload);
        return;
      }
      onload();
      return;
    }
    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://s3.tradingview.com/tv.js";
    script.onload = onload;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  getScriptElement = () => document.getElementById(SCRIPT_ID);

  scriptExists = () => this.getScriptElement() !== null;

  updateOnloadListener = (onload) => {
    const script = this.getScriptElement();
    const oldOnload = script.onload;
    return (script.onload = () => {
      oldOnload();
      onload();
    });
  };

  initWidget = () => {
    // eslint-disable-next-line
    /* global TradingView */
    if (!TradingView || !document.getElementById(this.containerId)) return;
    const { widgetType, ...widgetConfig } = this.props;
    const config = { ...widgetConfig, container_id: this.containerId };

    if (config.autosize) {
      delete config.width;
      delete config.height;
    }

    if (typeof config.interval === "number") {
      config.interval = config.interval.toString();
    }

    if (config.popup_width && typeof config.popup_width === "number") {
      config.popup_width = config.popup_width.toString();
    }

    if (config.popup_height && typeof config.popup_height === "number") {
      config.popup_height = config.popup_height.toString();
    }
    // eslint-disable-next-line
    /* global TradingView */
    const widget = new TradingView[widgetType](config);
    return widget;
  };

  cleanWidget = () => {
    if (!this.canUseDOM()) return;
    document.getElementById(this.containerId).innerHTML = "";
  };

  getStyle = () => {
    if (!this.props.autosize) return {};
    return {
      width: "100%",
      height: "100%",
    };
  };

  render = () => <article id={this.containerId} style={this.getStyle()} />;
}