import $ from "jquery";

export const ProfileIconHoverOnStyle = (bufferprofileiconon) => {
  $(bufferprofileiconon).css({
    "box-shadow": "0 0 0 3pt #1f2831",
  });
};

export const ProfileIconHoverOffStyle = (bufferprofileiconoff) => {
  $(bufferprofileiconoff).css({
    "box-shadow": "none",
  });
};

export const IconEllipseStyles = (bufferClasscaret) => {
  $(bufferClasscaret).css({
    padding: "6px 14px 6px 16px",
    "border-radius": "100%",
    cursor: "pointer",
    border: "none",
    "z-index": 10000000,
  });
};

export const DropdownListStyles = (bufferClassdropdownlists) => {
  $(bufferClassdropdownlists).css({
    display: "none",
    transition: "all 0.25s",
    "font-family": "Roboto",
    "list-style": "none",
    "font-size": "0.7em",
    "background-color": "#222a35",
    margin: 0,
    padding: "4px 0 4px 0",
    position: "absolute",
    overflow: "hidden",
    color: "#deebf7",
    left: 192,
    "z-index": 500000,
    border: "1px solid #1a2028",
    "border-radius": 6,
    "text-align": "left",
    "-webkit-filter": "drop-shadow(0 0 6px #14181e)",
    "-ms-filter": "drop-shadow(0 0 6px #14181e)",
    filter: "drop-shadow(0 0 6px #14181e)",
    "-moz-filter": "drop-shadow(0 0 6px #14181e)",
  });
};

export const DropdownItemsStyles = (bufferClassdropdownitems) => {
  $(bufferClassdropdownitems).css({
    "font-family": "Roboto",
    "font-size": "1.3em",
    "text-decoration": "none",
    "padding-top": 14,
    "padding-bottom": 14,
    "padding-left": 20,
  });
};

export default ProfileIconHoverOnStyle;
