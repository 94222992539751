import React from "react";
import { Line } from "react-chartjs-2";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import generateRandomColors from "../../functions/generateRandomColors";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import dollarCostAverage from "../../functions/dollarCostAvg";
import { useHistory } from "react-router-dom";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import filterTheTrades from "./filterTheTrades";
import { getDateDifferencewSeconds } from "../../functions/getDateDifference";
import {
  getMaxDD,
  getMaxDDPercent,
} from "../../functions/calculations_for_metrics/getmaxDD";
import longShortDelineation from "../../functions/longShortDelineation";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";

let he = require("he");
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
function findLargestArray(arrays) {
  let largestArray = [];

  for (let i = 0; i < arrays.length; i++) {
    if (arrays[i].length > largestArray.length) {
      largestArray = arrays[i];
    }
  }

  return largestArray;
}
function calculateGrade(grade) {
  if (grade >= 100) {
    return "A++";
  } else if (grade >= 99) {
    return "A+";
  } else if (grade >= 95) {
    return "A";
  } else if (grade >= 90) {
    return "A-";
  } else if (grade >= 87) {
    return "B+";
  } else if (grade >= 83) {
    return "B";
  } else if (grade >= 80) {
    return "B-";
  } else if (grade >= 77) {
    return "C+";
  } else if (grade >= 73) {
    return "C";
  } else if (grade >= 70) {
    return "C-";
  } else if (grade >= 67) {
    return "D+";
  } else if (grade >= 63) {
    return "D";
  } else if (grade >= 60) {
    return "D-";
  } else if (grade < 60) {
    return "F";
  }
}

const AIGraph = (props) => {
  const lineColorMain = props.lineColors;
  const whatToCompare = props.selectComparisons.label;
  const whatToCompareSingle = props.selectComparisons.single;
  const aiObjs = props.aiObjs;
  const whatToCompareProp = props.selectComparisons.prop;
  const metricToCompare = props.metricToCompare;
  const graphType = props.type;
  const selectedItems = props.selectedItems;
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const symbol = props.defaultSymbol;
  const settings = props.userSettings;
  const calType = settings.calculationType;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const labelsonly = selectedItems.map((obj) => (obj.label ? obj.label : "")); // for only bar
  const history = useHistory();
  let dataArray = [];

  //loop through the selected items...if the category doesnt exist just use 0
  for (const obj2 of selectedItems) {
    const matchingObj1 = Array.isArray(aiObjs)
      ? aiObjs.find((obj1) => obj1.category === obj2.label)
      : [];
    const score = matchingObj1 ? matchingObj1.score : 0;
    dataArray.push(score);
  }
  const data = {
    labels: labelsonly,
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColorMain,
        borderColor: lineColorMain,
        borderCapStyle: "butt",
        type: "bar",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColorMain,
        pointBackgroundColor: lineColorMain,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColorMain,
        pointHoverBorderColor: lineColorMain,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: dataArray,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      enabled: true,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: (tooltipItems) => {
          const datasetIndex = tooltipItems[0].index;
          return `${selectedItems[datasetIndex].label} AI Score`;
        },
        label: (tooltipItems) => {
          let returnVal = "";
          let grade = calculateGrade(
            parseFloat(tooltipItems.yLabel).toFixed(2)
          );
          returnVal = parseFloat(tooltipItems.yLabel).toFixed(2) + "/100";
          return [`Score: ${returnVal}`, `Grade: ${grade}`];
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = "default";
    },

    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            suggestedMin: 0,
            fontColor: "#98b5eb",
            callback: function (value) {
              let returnVal = "";
              returnVal = parseFloat(value).toFixed(2) + "/100";
              return returnVal;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Success Score",
            fontColor: "#fff7cb",
            fontSize: 12,
          },
        },
      ],
      xAxes: [
        {
          offset: true,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#98b5eb",
          },
          scaleLabel: {
            display: true,
            labelString: whatToCompareSingle,
            fontColor: "#fff7cb",
            fontSize: 12,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    responsiveAnimationDuration: 0,
    legend: {
      position: "bottom",
      align: "end",
      display: false,
    },
  };
  return (
    <div className="AIComparerGraph">
      <div className="ComparisonGraph-label" id="ComparisonGraph-label">
        <span className={`comparerReportInfo_Comparingheader2`}>AI Score</span>
        <PopoverStickOnHover
          component={
            <div
              style={{
                color: "#fff7cb",
                padding: "2px",
                width: 260,
                textAlign: "left",
              }}
            >
              We had an AI provide a score from 0 to 100 that represents the
              overall performance of each{" "}
              <span>{whatToCompareSingle.toLowerCase()}</span> based on the
              selected metrics for this comparison.
            </div>
          }
          placement="bottom"
          onMouseEnter={() => {}}
          delay={300}
          setClass="accountNumberSuperWrapper"
          xAdjust={27}
          yAdjust={0}
          keepOpen={false}
        >
          <span className="tooptip-i-style7">&#x1D48A;</span>
        </PopoverStickOnHover>
      </div>
      <div className="ComparisonGraphwrapper">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default React.memo(AIGraph);
