import dollarCostAverage from "./../functions/dollarCostAvg";
import getStandardDeviation from "../functions/calculations_for_metrics/standardDeviation";
import optimalF from "../functions/calculations_for_metrics/optimalf";
import { handleNANGOOD } from "./../functions/handleUndefined";
import { profitLossCalculation } from "./../functions/profitLossCalculation";
import { returnNetPLDollar } from "./../functions/profitLossCalculation";
import calculateRoySafetyFirstCriteria from "./../functions/roysSafetyFirstyCriteria";
import kellyCriterion from "./../functions/kellyCriterion";
import getEntryExitLots from "./../functions/getEntryExitLots";

const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);

export const moneyManagementCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW
) => {
  let result = {};
  let totalPL_dollar = 0;
  //let totalTrades = 0;
  /*   let newaccountbalancearr = [];
  let fullaccountbalancearr = []; */
  let totalrmultiple = 0;
  let rmultipleratio = 0;
  let standarddeviationrmultiple = 0;
  let coefficientofvariationrmultiple = 0;
  let avgrmultiplewins = 0;
  let largestrmultiplewins = 0;
  let avgrmultiplelosses = 0;
  let largestrmultipleLosses = 0;
  let optimalf = 0;
  let optimalleverage = 0;
  let theoreticalunitsize = 0;
  let royssafetyfirstcriteria = 0;
  let kellycriterion = 0;
  let larrywilliams = 0;
  let goldenRatio = 0;
  let goldenRatioArr = [];
  let averageRRR = 0;
  let averageRMultiple = 0;
  let averageRisk = 0;
  let riskedPercentArr = [];
  let xArray = [];
  let yArray = [];
  let totalProfits = 0;
  let totalLosses = 0;
  let totalTrades = 0;
  let totalWinningTrades = 0;
  let totalLosingTrades = 0;
  let rMultipleWins = [];
  let rMultipleLosses = [];
  let percentgain = 0;
  let percentgainArray = [];
  /*   let riskpercentarr = [];
   */ let fullBalanceArray = [];
  let largestLoss = 0;
  //let miscellaneousStdDevPerTradePercenttemparray = [];
  // Main For Loop
  /*   for (var i = 0, j = actualbalancearray.length; i < j; i++) {
    iterationarray.push(i);
    fullBalanceArray.push(actualbalancearray[i]);
    if (i === 0) {
    } else {
      let profitloss = actualbalancearray[i] - actualbalancearray[i - 1];
      let percentgain = (profitloss / actualbalancearray[i - 1]) * 100;
      percentgainArray.push(percentgain);
    }
  }  */

  for (let i = 0, j = data.length; i < j; i++) {
    const entryId = data[i].entryId;
    const thisAccountBalance = Number(data[i].balance);
    let orderType = data[i].entry.orderType;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    const correctedSUM = returnNetPLDollar(
      data[i].entry.profitLoss,
      data[i].entry.commissions,
      data[i].entry.fees,
      calctype
    );

    // handle the rest
    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      fullBalanceArray.push(thisAccountBalance);

      if (correctedSUM < largestLoss) {
        largestLoss = correctedSUM;
      }

      totalPL_dollar = totalPL_dollar + correctedSUM;
      if (correctedSUM > 0) {
        totalWinningTrades += 1;
        totalProfits += correctedSUM;
      } else if (correctedSUM < 0) {
        totalLosingTrades += 1;
        totalLosses += correctedSUM;
      }
      totalTrades += 1;
      //totalTrades += 1;
      /*       newaccountbalancearr.push(totalPL_dollar);
      fullaccountbalancearr.push(thisAccountBalance); */
      // Individual RRR and Rmultiple
      let averagePLPerWindollar = totalProfits / totalWinningTrades;
      let averagePLPerLossdollar = totalLosses / totalLosingTrades;

      let entryAvg = dollarCostAverage(
        data[i].entry.multiExecution,
        "entry",
        data[i].entry.orderType
      );
      let exitAvg = dollarCostAverage(
        data[i].entry.exitExecution,
        "exit",
        data[i].entry.orderType
      );
      let RRR =
        (Number(data[i].entry.takeProfit) - entryAvg) /
        (entryAvg - Number(data[i].entry.stopLoss));

      let RMultiple =
        (exitAvg - entryAvg) / (entryAvg - Number(data[i].entry.stopLoss));

      xArray.push(RRR);
      yArray.push(RMultiple);
      // COMPUTE RISK %
      let adjrrr = -entryAvg / (entryAvg - Number(data[i].entry.stopLoss));

      let divisor =
        data[i].entry.selectedPortfolioType === "Forex" ? RMultiple : adjrrr;

      let initPl = profitLossCalculation(
        multiExecution,
        [],
        orderType,
        data[i].entry.selectedPortfolioType,
        data[i].entry.symbol.pointValue,
        true
      );
      // If the asset class if forex we have to calculate risk a slightly different way
      let dividend =
        data[i].entry.selectedPortfolioType === "Forex"
          ? correctedSUM
          : returnNetPLDollar(
              initPl,
              data[i].entry.commissions,
              data[i].entry.fees,
              calctype
            );
      let percent =
        Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
      let riskedPercent = percent / divisor;
      riskedPercentArr.push(riskedPercent);
      goldenRatioArr.push(
        averagePLPerWindollar /
          Math.abs(averagePLPerLossdollar) /
          arrAvg(xArray)
      );
      // ---------------------
      if (correctedSUM > 0) {
        rMultipleWins.push(RMultiple);
      } else if (correctedSUM < 0) {
        rMultipleLosses.push(RMultiple);
      }
      // handle percent gain array
      percentgain = (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
      percentgainArray.push(percentgain);
    }
  }
  let averagePLPerWindollar = totalProfits / totalWinningTrades;
  let averagePLPerLossdollar = totalLosses / totalLosingTrades;
  averageRRR = arrAvg(xArray).toFixed(3);
  averageRMultiple = arrAvg(yArray).toFixed(3);
  totalrmultiple = arrSum(yArray).toFixed(3);
  avgrmultiplewins = arrAvg(rMultipleWins).toFixed(3);
  largestrmultiplewins = Math.max(...rMultipleWins).toFixed(3);
  avgrmultiplelosses = arrAvg(rMultipleLosses).toFixed(3);
  largestrmultipleLosses = Math.min(...rMultipleLosses).toFixed(3);
  rmultipleratio = (
    arrAvg(rMultipleWins) / Math.abs(arrAvg(rMultipleLosses))
  ).toFixed(3);
  goldenRatio = (
    averagePLPerWindollar /
    Math.abs(averagePLPerLossdollar) /
    arrAvg(xArray)
  ).toFixed(3);
  standarddeviationrmultiple = !getStandardDeviation(yArray)
    ? 0
    : getStandardDeviation(yArray).toFixed(3);
  coefficientofvariationrmultiple = !getStandardDeviation(yArray)
    ? "-"
    : (getStandardDeviation(yArray) / arrAvg(yArray)).toFixed(3) + "%";
  optimalf = optimalF(percentgainArray).toFixed(3);
  optimalleverage = Math.abs(
    optimalf / (Math.min(...percentgainArray) / 100)
  ).toFixed(3);
  theoreticalunitsize = Math.abs(largestLoss / optimalf).toFixed(3);
  averageRisk = arrAvg(riskedPercentArr).toFixed(3);
  larrywilliams = (
    (arrAvg(riskedPercentArr) * fullBalanceArray[fullBalanceArray.length - 1]) /
    largestLoss
  ).toFixed(3);
  royssafetyfirstcriteria =
    calculateRoySafetyFirstCriteria(fullBalanceArray).toFixed(3);
  kellycriterion = kellyCriterion(fullBalanceArray).toFixed(3);
  result = {
    totalrmultiple,
    rmultipleratio,
    avgrMultipleWins: handleNANGOOD(arrAvg(rMultipleWins).toFixed(3), "-"),
    avgrMultipleLosses: handleNANGOOD(arrAvg(rMultipleLosses).toFixed(3), "-"),
    standarddeviationrmultiple,
    coefficientofvariationrmultiple,
    avgrmultiplewins: handleNANGOOD(avgrmultiplewins, "-"),
    largestrmultiplewins: handleNANGOOD(largestrmultiplewins, "-"),
    avgrmultiplelosses: handleNANGOOD(avgrmultiplelosses, "-"),
    largestrmultipleLosses: handleNANGOOD(largestrmultipleLosses, "-"),
    optimalf: handleNANGOOD(optimalf, "0.00"),
    optimalleverage: handleNANGOOD(optimalleverage, "0.00"),
    theoreticalunitsize: handleNANGOOD(theoreticalunitsize, "0.00"),
    royssafetyfirstcriteria: handleNANGOOD(royssafetyfirstcriteria, "0.00"),
    kellycriterion: handleNANGOOD(kellycriterion, "0.00"),
    larrywilliams: handleNANGOOD(larrywilliams, "0.00"),
    goldenRatio: handleNANGOOD(goldenRatio, "-"),
    goldenRatioArr: goldenRatioArr,
    averageRRR: handleNANGOOD(averageRRR, "-"),
    averageRMultiple: handleNANGOOD(averageRMultiple, "-"),
    averageRisk: handleNANGOOD(averageRisk, "-"),
    start_Balance,
    start_DW,
  };
  return result;
};

export default moneyManagementCalculations;
