export const probabilityofLosingStreak = (N, K, p) => {
  if (K > N || N <= 0) return 0;
  if (K === 0) return 100;
  
  let prob = 0;
  let tempsum = 0;
  
  for (let m = K; m <= N; m++) {
  tempsum = Math.pow(p, K);
  for (let j = 1, q = K; j <= q; j++) {
  if (m - j >= 1) {
  tempsum += Math.pow(p, j - 1) * (1 - p) * prob;
  }
  }
  prob = tempsum;
  }
  
  return prob * 100;
  };

export default probabilityofLosingStreak;
