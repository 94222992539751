const proxyURL =
  "https://api.tradefuse.app/handleOpenAImessage";
const proxyURL2 =
  "https://api.tradefuse.app/handleOpenAImessage3_5";
const proxyURLTUNED =
  "https://api.tradefuse.app/handleOpenAITUNEDrequests";
export const getOpenAIMessage = async (message, signal) => {
  const dataIn = {
    data: {
      message: message,
    },
  };
  for (let i = 0; i < 1; i++) {
    try {
      const response = await fetch(proxyURL, {
        method: "POST",
        signal: signal,
        headers: {
          Host: proxyURL,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      return await response.json();
    } catch (err) {
      // Log error
      console.error(`Attempt ${i + 1} failed. Error: ${err}`);
      // If it was the last attempt, throw the error
      if (i + 1 === 1) {
        return "err";
      }
    }
  }
};

export const getOpenAIMessageMaster = async (message, signal) => {
  const dataIn = {
    data: {
      message: message,
    },
  };
  for (let i = 0; i < 1; i++) {
    try {
      const response = await fetch(proxyURL2, {
        method: "POST",
        signal: signal,
        headers: {
          Host: proxyURL2,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      return await response.json();
    } catch (err) {
      // Log error
      console.error(`Attempt ${i + 1} failed. Error: ${err}`);
      // If it was the last attempt, throw the error
      if (i + 1 === 1) {
        return "err";
      }
    }
  }
};

export const getOpenAIMessageTUNED = async (message, signal) => {
  const dataIn = {
    data: {
      message: message,
    },
  };
  for (let i = 0; i < 1; i++) {
    try {
      const response = await fetch(proxyURLTUNED, {
        method: "POST",
        signal: signal,
        headers: {
          Host: proxyURLTUNED,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      return await response.json();
    } catch (err) {
      // Log error
      console.error(`Attempt ${i + 1} failed. Error: ${err}`);
      // If it was the last attempt, throw the error
      if (i + 1 === 1) {
        return "err";
      }
    }
  }
};

export default getOpenAIMessage;
