import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import theme from "../../components/selectTheme";
import dropdownStyles from "../../functions/dropdownStyles";
export const SelectGraph = (props) => {
  const options = props.options;
  const [selectGraph, setselectGraph] = useState();
  const handleChange = (x) => {
    setselectGraph(x);
    props.callBack(x);
  };

  const component = (
    <label className="selectGraphselectionfields">
      <CreatableSelect
        isClearable
        styles={dropdownStyles}
        value={selectGraph}
        onChange={(x) => handleChange(x)}
        options={options}
        theme={theme}
        placeholder={<em>Select Metric ...</em>}
      />
    </label>
  );
  return component;
};
export default SelectGraph;
