import React, { useContext, useEffect, useRef, useState } from "react";
import DeleteButton from "../../components/deleteButton";
import NoteComponent from "../../components/NoteComponent";

function formatDate(date) {
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  return `${month}/${day}/${year}`;
}
export const CalendarNoteComponent = (props) => {
  const localNotes = localStorage.getItem("calendarNotes");
  const notes = !localNotes ? props.notes : JSON.parse(localNotes);
  const popup = props.popup;
  const lolevent = props.lolevent;
  const [notesState, setnotesState] = useState(
    notes && notes[formatDate(lolevent.start)]
  );
  const [notesStateINIT, setnotesStateINIT] = useState(
    notes && notes[formatDate(lolevent.start)]
  );
  // Logic for when to show the upgrade modal
  useEffect(() => {}, []);

  const outsideClickHandler = (e) => {
    const textarea = document.getElementById("commentscalbox");
    const button = document.getElementById("dailynotesbuttoncalendar");

    if (!popup.contains(e.target) && e.target !== textarea) {
      popup.remove();
      props.handleChange(e, lolevent, notesState, notesStateINIT);
      document.removeEventListener("click", outsideClickHandler);
    }
  };

  document.addEventListener("click", outsideClickHandler);

  return (
    <div className="calcustomobjwrappernote">
      <div className="calcustomobjwrappernoteheader">
        {formatDate(lolevent.start) + " Notes"}
      </div>
      <button
        className="calendarnotesxtop"
        onClick={(e) => {
          popup.remove();
          props.handleChange(e, lolevent, notesState, notesStateINIT);
        }}
      ></button>
      <div className="calcustomobjwrappernoteinner">
        <NoteComponent
          originalState={notesState}
          setNoteState={(e) => {
            setnotesState(e);
          }}
        />
      </div>
    </div>
  );
};

export default CalendarNoteComponent;
