const initialState = {
  filtervalue: {
    dateRangeFilter: null,
    hourRangeFilter: null,
    orderTypeFilter: null,
    symbolFilter: null,
    strategyFilter: null,
    emotionalStateFilter: null,
    physicalStateFilter: null,
    mistakeFilter: null,
    timeFrameFilter: null,
    confidenceFilter: null,
    marketStateFilter: null,
    dayOfWeekFilter: null,
    monthFilter: null,
    assetClassFilter: null,
    tagsFilter: null,
    yearFilter: null,
  },
};

export const defaultFilterValue = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FILTERED_LIST":
      return {
        ...state,
        filtervalue: {
          ...state.filtervalue,
          [action.id]: action.value,
        },
      };
    case "RESET_FILTERED_LIST":
      return (state = initialState);
    default:
      return state;
  }
};

export default defaultFilterValue;
