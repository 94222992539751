import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import ImportFromFileComponent from "./ImportFileComponent";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import useForceUpdate from "../../hooks/useForceUpdate";
import IntegrationsComponent from "./ComponentNew";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const Integrations = (props) => {
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const active = dataInstance && dataInstance.customer.active;
  const tier = dataInstance && dataInstance.customer.tier;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const forceUpdate = useForceUpdate();
  const [importState, setimportState] = useState("");
  const [isweb3selected, setisweb3selected] = useState(false);
  const [selectedBroker, setselectedBroker] = useState();
  const [importOpen, setimportOpen] = useState(false);

  const maxEntries = 50;

  let processedData = {};
  let createdPortfolios = "";
  let showINIT = false;
  let showINIT2 = false;
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Integrations - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    createdPortfolios = allData.createdPortfolios;
    //store.dispatch(setLoaderState({ loading: false }));
  }

  // Tiering Logic
  const portfolio = processedData.portfolio;
  const numEntries = portfolio && portfolio.entries.length;

  if (tier === "free") {
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
  } else if (tier === "ultimate" && active) {
  } else if (tier === "admin" && active) {
  } else {
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect: "Unlimited entries",
    blur: showINIT,
  });

  // Logic for when to show the upgrade modal
  useEffect(() => {
    if (tier === "free") {
    } else if (tier === "pro" && active) {
    } else if (tier === "oldpro" && active) {
    } else if (tier === "master" && active) {
    } else if (tier === "ultimate" && active) {
    } else if (tier === "admin" && active) {
    } else {
    }
  }, [tier, active, createdPortfolios, numEntries]);
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          /*           auto_isOpen={importOpen}
          auto_isweb3selected={isweb3selected}
          auto_broker={selectedBroker}
          auto_importState={importState} */
          title={props.tabName}
          upgradeShow={showUpgradeModal.current}
          setshowAutoImportModal={(e) => {
            forceUpdate();
          }}
          setshowUpgradeModal={(e) => {
            showUpgradeModal.current = {
              ...showUpgradeModal.current,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            };
            forceUpdate();
          }}
          page={
            /*             <ImportFromFileComponent
              tier={tier}
              active={active}
              show100TradesCheck={showINIT}
              showCheck={showINIT2}
              allData={processedData}
            /> */
            <IntegrationsComponent
              tier={tier}
              active={active}
              show100TradesCheck={showINIT}
              showCheck={showINIT2}
              allData={processedData}
              setimportState={(e) => setimportState(e)}
              setisweb3selected={(e) => setisweb3selected(e)}
              setselectedBroker={(e) => setselectedBroker(e)}
              openImportModal={(e) => setimportOpen(e)}
              setshowUpgradeModal={(e) => {
                showUpgradeModal.current = {
                  ...showUpgradeModal.current,
                  show: e.show,
                  featureSelect: e.featureSelect,
                  blur: e.blur,
                  tierText: e.tierText,
                };
                forceUpdate();
              }}
            />
          }
          userData={processedData}
        />
      </div>
    );
  }
};

Integrations.defaultProps = {
  tabName: "Integrations",
  pageName: "Import Trades",
};

Integrations.propTypes = {
  tabName: PropTypes.string,
};

export default Integrations;
