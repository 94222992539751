import React from 'react'
import dollarCostAverage from "../../functions/dollarCostAvg";
import getEfficiencyBarColor from "./getEfficiencyBarColor"
export const EfficiencyBar = (props) => {
  const row = props.row;
  const width = props.width;
  const orderType = row.orderType;
  const multiExecutioninner = row.multiExecution;
  let avgEntry = dollarCostAverage(multiExecutioninner, "entry", orderType);
  const multiExecutioninner2 = row.exitExecution;
  let avgExit = dollarCostAverage(multiExecutioninner2, "exit", orderType);
  if (
    row.orderType.toLowerCase().replace(/ /gi, "") ===
      "withdrawal" ||
    row.orderType.toLowerCase().replace(/ /gi, "") === "deposit"
  ) {
    return "";
  } else {
    if (
      avgExit === "" ||
      avgEntry === "" ||
      row.maxAdEx === "" ||
      row.maxFavEx === ""
    ) {
      const efficiency = 0;
      return (
        <span
          id="logBookEfficiencyBarOuter"
          style={{
            width: width,
            display: "block",
            textAlign: "center",
            minWidth: 200
          }}
        >
          <span>{efficiency + " %"}</span>
          <span id="logBookEfficiencyBar"></span>
        </span>
      );
    } else {
      if (row.orderType === "Long") {
        const efficiency = (
          100 *
          ((avgExit - avgEntry) /
            (row.maxFavEx - row.maxAdEx))
        ).toFixed(3);
        const marginLeftNumber = 100 - Math.abs(efficiency);
        const marginLeft = efficiency < 0 ? `${marginLeftNumber}%` : 0;
        return (
          <span
            id="logBookEfficiencyBarOuter"
            style={{
              width: width,
              display: "block",
              textAlign: "center",
              minWidth: 200
            }}
          >
            <span>{efficiency + " %"}</span>
            <span id="logBookEfficiencyBar">
              <span
                id="logBookEfficiencyBarInner"
                style={{
                  backgroundColor: getEfficiencyBarColor(Math.abs(efficiency)),
                  width: `${Math.abs(efficiency)}%`,
                  marginLeft: marginLeft,
                }}
              ></span>
            </span>
          </span>
        );
      } else if (row.orderType === "Short") {
        const efficiency = (
          100 *
          ((avgEntry - avgExit) /
            (row.maxAdEx - row.maxFavEx))
        ).toFixed(3);
        const marginLeftNumber = 100 - Math.abs(efficiency);
        const marginLeft = efficiency < 0 ? `${marginLeftNumber}%` : 0;
        return (
          <span
            id="logBookEfficiencyBarOuter"
            style={{
              width: width,
              display: "block",
              textAlign: "center",
              minWidth: 200
            }}
          >
            <span>{efficiency + " %"}</span>
            <span id="logBookEfficiencyBar">
              <span
                id="logBookEfficiencyBarInner"
                style={{
                  backgroundColor: getEfficiencyBarColor(Math.abs(efficiency)),
                  width: `${Math.abs(efficiency)}%`,
                  marginLeft: marginLeft,
                }}
              ></span>
            </span>
          </span>
        );
      } else {
        return "";
      }
    }
  }
};
export default EfficiencyBar;