function processTieredPortfolios(portfolios, n, chosenPortsIn) {
  // Sort portfolios by entries
  portfolios.sort((a, b) => b.entries.length - a.entries.length);

  // Filter out Default portfolio
  let defaultPortfolio = portfolios.filter(
    (portfolio) => portfolio.name === "Default"
  );
  let otherPortfolios = portfolios.filter(
    (portfolio) => portfolio.name !== "Default"
  );

  // Select n largest portfolios
  let createdPortfolios = defaultPortfolio.concat(otherPortfolios.slice(0, n));

  // Select names of created portfolios
  let createdPortfolioNames = createdPortfolios.map(
    (portfolio) => portfolio.name
  );

  // Filter out the names that are not part of the created portfolios, but keep 'Default'
  let chosenPortfolios = chosenPortsIn.filter(
    (portfolio) =>
      createdPortfolioNames.includes(portfolio) || portfolio === "Default"
  );

  // Sort createdPortfolios alphabetically
  createdPortfolios.sort((a, b) => a.name?.localeCompare(b.name));

  // Move chosen portfolios to the front of the list
  let chosenPortfoliosSet = new Set(chosenPortfolios);
  createdPortfolios = createdPortfolios.sort((a, b) => {
    if (chosenPortfoliosSet.has(a.name) && !chosenPortfoliosSet.has(b.name)) {
      return -1;
    }
    if (!chosenPortfoliosSet.has(a.name) && chosenPortfoliosSet.has(b.name)) {
      return 1;
    }
    return 0;
  });

  // Return the final object
  return {
    createdPortfolios,
    chosenPortfolios,
  };
}


export default processTieredPortfolios;
