export const supportedCryptos = [
  "ALGO",
  "AMP",
  "AVAX",
  "BAT",
  "BTC",
  "ADA",
  "CGLD",
  "CLV",
  "LINK",
  "COMP",
  "ATOM",
  "DAI",
  "MANA",
  "DOGE",
  "DYDX",
  "EOS",
  "ETH",
  "FIL",
  "FLOW",
  "GRT",
  "LTC",
  "MKR",
  "XMR",
  "DOT",
  "MATIC",
  "QNT",
  "SHIB",
  "SOL",
  "XLM",
  "XTZ",
  "UNI",
  "USDT",
  "ZEC",
];
export default supportedCryptos
