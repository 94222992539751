import React, { useContext, useEffect, useState, useRef } from "react";

export const TextResults = (props) => {
  const textResult = props.result;
  return (
    <div className="aiResultWrapper">
      <div className="aiResultHeader">AI Recommendations</div>
      <div>{textResult}</div>
    </div>
  );
};

export default TextResults;
