import React from "react";
import { setLoaderState } from "../../actions/actionCreators";
import store from "../../store";
import { v4 as uuidv4 } from "uuid";

import "./styles.scss";
//import StripePricingTable from "./../../components/StripePricingTable";
//import { DataContext } from "../../DataContext";
import createStripeSession from "./../../utils/stripe/createStripeSession";
import createStripePortalSession from "./../../utils/stripe/createStripePortalSession";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import { useHistory, useLocation } from "react-router-dom";

export const PricingTier = (props) => {
  //const dataInstance = useContext(DataContext);
  //const globalSettings = dataInstance.allData.globalSettings;
  //const stripeID = globalSettings.stripeId;
  const currentURL = window.location;
  const customerId = props.stripeId;
  const success_url = currentURL.origin + "/Dashboard";
  const cancel_url = currentURL.href;
  const returnUrl = currentURL.href;
  const history = useHistory();

  const tierComponent = props.tierComponent;
  const billingState = props.billingState;
  const features = tierComponent.features;
  const price =
    billingState === "Monthly"
      ? tierComponent.monthlyprice
      : billingState === "Quarterly"
      ? tierComponent.quarterlyprice
      : billingState === "Annual"
      ? tierComponent.annualprice
      : tierComponent.monthlyprice;
  const priceId =
    billingState === "Monthly"
      ? tierComponent.monthlystripeid
      : billingState === "Quarterly"
      ? tierComponent.quarterlystripeid
      : billingState === "Annual"
      ? tierComponent.annualstripeid
      : tierComponent.monthlystripeid;
  let buttonText = "";
  let activePlan = "";
  let highlighted = "";
  if (tierComponent.currentPlanId === tierComponent.free) {
    buttonText = "Manage Plan";
    activePlan = "pricing-tier-wrapper-highlighted";
  } else if (billingState === "Monthly") {
    if (tierComponent.monthlystripeid === tierComponent.currentPlanId) {
      buttonText = "Manage Plan";
      activePlan = "pricing-tier-wrapper-highlighted";
    } else {
      buttonText = "Choose Plan";
    }
  } else if (billingState === "Quarterly") {
    if (tierComponent.quarterlystripeid === tierComponent.currentPlanId) {
      buttonText = "Manage Plan";
      activePlan = "pricing-tier-wrapper-highlighted";
    } else {
      buttonText = "Choose Plan";
    }
  } else if (billingState === "Annual") {
    if (tierComponent.annualstripeid === tierComponent.currentPlanId) {
      buttonText = "Manage Plan";
      activePlan = "pricing-tier-wrapper-highlighted";
    } else {
      buttonText = "Choose Plan";
    }
  } else {
    if (tierComponent.monthlystripeid === tierComponent.currentPlanId) {
      buttonText = "Manage Plan";
      activePlan = "pricing-tier-wrapper-highlighted";
    } else {
      buttonText = "Choose Plan";
    }
  }
  if (tierComponent.buttonid === "pricing-tier-button-free") {
    if (tierComponent.monthlystripeid === tierComponent.currentPlanId) {
      buttonText = "";
      activePlan = "pricing-tier-wrapper-highlighted";
    } else {
      buttonText = "";
    }
  }
  const featuresComponent = features.map((feature) =>
    tierComponent.tier === "Free" ? (
      <ul
        className="featureItemFree"
        key={uuidv4()}
        id={tierComponent.featureItemId}
      >
        {feature}
      </ul>
    ) : feature === "Integration request priority" ? (
      <ul
        className="featureItem"
        key={uuidv4()}
        id={tierComponent.featureItemId}
      >
        {feature}
        <PopoverStickOnHover
          placement="bottom-end"
          delay={300}
          xAdjust={18}
          yAdjust={0}
          setClass="accountNumberSuperWrapper"
          keepOpen={false}
          component={
            <div
              style={{
                color: "#fff7cb",
                width: 232,
                textAlign: "left",
              }}
            >
              Request a broker for automated importing and we will stop what we
              are doing to get it integrated into our journal. Expect a
              turnaround time of no more than a month.
            </div>
          }
        >
          <span className="tooptip-i-style44rbrb2wrapper">
            <span className="tooptip-i-style44rbrb">&#x1D48A;</span>
          </span>
        </PopoverStickOnHover>
      </ul>
    ) : feature === "Automated importing for up to 5 portfolios" ? (
      <ul
        className="featureItem"
        key={uuidv4()}
        id={tierComponent.featureItemId}
      >
        {feature}
        <PopoverStickOnHover
          placement="bottom-end"
          delay={300}
          xAdjust={18}
          yAdjust={0}
          setClass="accountNumberSuperWrapper"
          keepOpen={false}
          component={
            <div
              style={{
                color: "#fff7cb",
                width: 232,
                textAlign: "left",
              }}
            >
              Connect your broker accounts with just a few clicks. Past trades
              are imported for you, and new trades are imported in real time. No
              waiting. No manual sync.
            </div>
          }
        >
          <span className="tooptip-i-style44rbrb2wrapper">
            <span className="tooptip-i-style44rbrb2">&#x1D48A;</span>
          </span>
        </PopoverStickOnHover>
      </ul>
    ) : (
      <ul
        className="featureItem"
        key={uuidv4()}
        id={tierComponent.featureItemId}
      >
        {feature}
      </ul>
    )
  );
  return (
    <div
      id="pricing-tier-wrapper"
      className={`${activePlan} noselect`}
      key={price}
    >
      {activePlan !== "" && (
        <div id="pricing-tier-highlighted-text">{"Current Plan"}</div>
      )}
      {/*       {billingState === "Annual" && tierComponent.tier === "Master" && (
        <div className="featuredTierItem">Featured</div>
      )} */}
      <div id={tierComponent.headerid}>{tierComponent.header}</div>
      <div id={tierComponent.subheaderid}>{tierComponent.subheader}</div>
      <div id={tierComponent.priceClass}>{price}</div>
      <button
        id={tierComponent.buttonid}
        onClick={async () => {
          if (tierComponent.buttonid === "pricing-tier-button-free") {
          } else {
            if (buttonText === "Manage Plan") {
              store.dispatch(setLoaderState({ loading: true }));
              const session = await createStripePortalSession(
                customerId,
                returnUrl
              );
              store.dispatch(setLoaderState({ loading: false }));
              window.location.replace(session.url);
            } else {
              store.dispatch(setLoaderState({ loading: true }));
              const session = await createStripeSession(
                customerId,
                priceId,
                success_url,
                cancel_url,
                history
              );
              store.dispatch(setLoaderState({ loading: false }));
              window.location.replace(session.url);
            }
          }
        }}
      >
        <div className={tierComponent.buttontextwrapper}>{buttonText}</div>
      </button>
      <div
        id="pricing-tier-featurehader"
        className={tierComponent.tier === "Free" ? "freecomponentheader" : ""}
      >
        {tierComponent.tier === "Pro"
          ? "Everything in Free, plus:"
          : tierComponent.tier === "Free"
          ? "Includes:"
          : tierComponent.tier === "Starter"
          ? "Everything in Free, plus:"
          : tierComponent.tier === "Master"
          ? "Everything in Pro, plus:"
          : tierComponent.tier === "Ultimate"
          ? "Everything in Master, plus:"
          : ""}
      </div>{" "}
      <div id={tierComponent.featureWrapper}>{featuresComponent}</div>
    </div>
  );
};
export default PricingTier;
