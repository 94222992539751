import React, { useState } from "react";
import $ from "jquery";
import "./feedbackStyles.scss";
import * as emailjs from "emailjs-com";
import DeleteButton from "./../../components/deleteButton";

const FeedbackComponent = (props) => {
  const user = props.userId;
  const [onetofive, setonetofive] = useState(0);
  const [improvements, setimprovements] = useState("");
  const [yesno, setyesno] = useState(null);

  const handleCloseDeleteTrades = () => {
    $("#feedback-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    document.getElementById("noclick").style.display = "none";
  };
  const handleChange = (event) => {
    setonetofive(event.target.value);
  };
  const handleChangeYN = (event) => {
    setyesno(event.target.value);
  };
  const handleChangeImprovements = (event) => {
    setimprovements(event.target.value);
  };

  const message = {
    NAME: `Name: ${user}`,
    RATING: `How satisfied are you with our Journal? ${onetofive}`,
    IMPROVEMENT: `Tell us how we can improve. Are you encountering any bugs? Do you have suggestions for us?: ${improvements}`,
    REACHOUT: `Would you like us to peronsally reach out to you regarding your feedback submission?: ${yesno}`
  };

  const emailData = {
    from_name: String(user), // sender address
    to_name: "tradersiqmain@gmail.com", // list of receivers
    subject: "Support Ticket from " + String(user), // Subject line
    message: message,
  };
  const sendEmail = () => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICEID,
        process.env.REACT_APP_EMAILJS_TEMPLATEID,
        emailData,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        function (response) {
        },
        function (error) {
        }
      );
    handleCloseDeleteTrades();
  };
  return (
    <div id="feedback-class">
      <span className="feedbackheading">SEND FEEDBACK</span>
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 0,
          paddingLeft: 30,
          paddingRight: 30,
          fontStyle: "italic",
        }}
      >
        {
          "User feedback is extremely important for continual improvement of our Journal, so we guarantee that we will review every feedback submission thoroughly."
        }
      </div>
      <div style={{ position: "absolute", top: 80, left: 30 }}>
        {"How satisfied are you with our Journal?"}
      </div>
      <div id="ratingwrapper">
        <label className="containerFB containerFB1">
          <input
            type="radio"
            name="User Journal Rating"
            id="orderTypeBuy"
            value={"Very Dissatisfied"}
            onClick={handleChange}
            required
          />
          Very Dissatisfied
          <span className="radiocircleFB"></span>
        </label>
        <label className="containerFB containerFB2">
          <input
            type="radio"
            name="User Journal Rating"
            id="orderTypeBuy"
            value={"Dissatisfied"}
            onClick={handleChange}
            required
          />
          Dissatisfied
          <span className="radiocircleFB"></span>
        </label>
        <label className="containerFB containerFB3">
          <input
            type="radio"
            name="User Journal Rating"
            id="orderTypeBuy"
            value={"It's Okay"}
            onClick={handleChange}
            required
          />
          It's Okay
          <span className="radiocircleFB"></span>
        </label>
        <label className="containerFB containerFB4">
          <input
            type="radio"
            name="User Journal Rating"
            id="orderTypeBuy"
            value={"Satisfied"}
            onClick={handleChange}
            required
          />
          Satisfied
          <span className="radiocircleFB"></span>
        </label>
        <label className="containerFB containerFB5">
          <input
            type="radio"
            name="User Journal Rating"
            id="orderTypeBuy"
            value={"Very Satisfied"}
            onClick={handleChange}
            required
          />
          Very Satisfied
          <span className="radiocircleFB"></span>
        </label>
      </div>
      <div style={{ position: "absolute", top: 160, left: 30 }}>
        {
          "Tell us how we can improve. Are you encountering any bugs? Do you have suggestions for us?"
        }
      </div>
      <textarea
        name="User Improvements"
        value={improvements}
        onChange={handleChangeImprovements}
        className="feedbackbox"
        autoComplete="off"
      ></textarea>
      <div style={{ position: "absolute", top: 350, left: 30 }}>
        {
          "Would you like us to personally reach out to you regarding your feedback submission?"
        }
      </div>
      <div id="ratingwrapper2">
        <label className="containerFB containerFB1x">
          <input
            type="radio"
            name="User Journal Get Back"
            id="orderTypeBuy"
            value={"Yes"}
            onClick={handleChangeYN}
            required
          />
          Yes
          <span className="radiocircleFB"></span>
        </label>
        <label className="containerFB containerFB2x">
          <input
            type="radio"
            name="User Journal Get Back"
            id="orderTypeBuy"
            value={"No"}
            onClick={handleChangeYN}
            required
          />
          No
          <span className="radiocircleFB"></span>
        </label>
      </div>
      <button
        className="deletetradesclosebutton"
        onClick={handleCloseDeleteTrades}
      >
        <DeleteButton />
      </button>
      <button className="feedbacknobutton" onClick={handleCloseDeleteTrades}>
        Cancel
      </button>
      <button className="feedbacksubmitbutton" onClick={sendEmail}>
        Submit
      </button>
    </div>
  );
};

export default FeedbackComponent;
