import React from "react";
import { Line } from "react-chartjs-2";
import supportedCryptos from "../../arrays/isCurrencyCrypto";

let he = require("he");
const SimulationGraph = (props) => {
  const simulationResults = props.simulationResults;
  const simulationResultsLength = !simulationResults.length
    ? []
    : simulationResults[0].data;
  const supportedCryptosCheck = supportedCryptos.includes(props.defaultSymbol);
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = simulationResultsLength.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };

  const data = {
    labels: xaxisfunction(),
    datasets: simulationResults,
  };
  const options = {
    spanGaps: true, // enable for all datasets
    tooltips: {
      mode: "nearest",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: false,
      callbacks: {
        title: () => {
          return "Simulated Equity: ";
        },
        label: (tooltipItems) => {
          return supportedCryptosCheck
            ? parseFloat(tooltipItems.yLabel.toFixed(6)) +
                " " +
                props.defaultSymbol
            : he.decode(props.defaultSymbol) +
                parseFloat(tooltipItems.yLabel).toFixed(2);
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return supportedCryptosCheck
                ? value + " " + props.defaultSymbol
                : he.decode(props.defaultSymbol) + value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Equity",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="simulationgraph">
      <div className="equitycurvesim-label" id="equitycurvesim-label">
        Equity Curve Simulations
      </div>
      <div className="overallreportgraphwrapper">
        <Line
          data={data}
          options={options}
          className="ovallreportgraphactual"
        />
      </div>
    </div>
  );
};

export default SimulationGraph;
