export const getSession = (dateTime) => {
  let tradingSession;
  const dateTimeIn = new Date(dateTime);
  // Convert to UTC Time
  let UTCHour = dateTimeIn.getUTCHours();

  // Check trading session
  if (UTCHour >= 8 && UTCHour < 17) {
    tradingSession = "London"; // London
  } else if (UTCHour >= 13 && UTCHour < 22) {
    tradingSession = "New York"; // New York
  } else if (
    (UTCHour >= 22 && UTCHour <= 23) ||
    (UTCHour >= 0 && UTCHour < 7)
  ) {
    tradingSession = "Sydney"; // Sydney
  } else if (UTCHour >= 0 && UTCHour < 9) {
    tradingSession = "Tokyo"; // Tokyo
  } else {
    tradingSession = ""; // Undefined session
  }
  return tradingSession;
};
export default getSession;
