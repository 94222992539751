import React, { useContext } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import processDataandSettings from "./../../utils/processDataandSettings";
import { DataContext } from "../../DataContext";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const HoldingTimeReport = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  let processedData = {};

  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Holding Time - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0) {
    return <PageTemplateBlank title={props.tabName} showloader={true} />;
  } else {
    return (
      <PageTemplate
        title={props.tabName}
        upgradeShow={showUpgradeModal}
        setshowUpgradeModal={(e) => {
          setshowUpgradeModal({
            ...showUpgradeModal,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          });
        }}
        page={<div> Holding Time Report </div>}
        userData={processedData}
      />
    );
  }
};

HoldingTimeReport.defaultProps = {
  tabName: "Holding Time",
  pageName: "Holding Time",
};

HoldingTimeReport.propTypes = {
  tabName: PropTypes.string,
};

export default HoldingTimeReport;
