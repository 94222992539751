export const getDateDifference = (date_future, date_now) => {
  let seconds = Math.abs(
    Math.floor((new Date(date_future) - new Date(date_now)) / 1000)
  );
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let years = Math.floor(days / 365);

  days = days - years * 365;
  hours = hours - years * 365 * 24 - days * 24;
  minutes = minutes - years * 365 * 24 * 60 - days * 24 * 60 - hours * 60;
  seconds =
    seconds -
    years * 365 * 24 * 60 * 60 -
    days * 24 * 60 * 60 -
    hours * 60 * 60 -
    minutes * 60;
  const yearsNew = isNaN(years) ? 0 : years;
  const daysNew = isNaN(days) ? 0 : days;
  const hoursNew = isNaN(hours) ? 0 : hours;
  const minutesNew = isNaN(minutes) ? 0 : minutes;
  const secondsNew = isNaN(seconds) ? 0 : seconds;
  const returnThis =
    years < 0 || days < 0 || minutes < 0 || seconds < 0 || hours < 0
      ? "-"
      : years === 0 || !years
      ? daysNew +
        " d " +
        "\xa0" +
        hoursNew +
        " h " +
        "\xa0" +
        minutesNew +
        " m " +
        "\xa0" +
        secondsNew +
        " s"
      : yearsNew +
        " y " +
        "\xa0" +
        daysNew +
        " d " +
        "\xa0" +
        hoursNew +
        " h " +
        "\xa0" +
        minutesNew +
        " m " +
        "\xa0" +
        secondsNew +
        " s";
  return returnThis;
};

export const getDateDifferencewSeconds = (diff) => {
  let seconds = diff;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  const daysNew = isNaN(days) ? 0 : days;
  const hoursNew = isNaN(hours) ? 0 : hours;
  const minutesNew = isNaN(minutes) ? 0 : minutes;
  const secondsNew = isNaN(seconds) ? 0 : seconds;
  const returnThis =
    days < 0 || minutes < 0 || seconds < 0 || hours < 0
      ? "-"
      : daysNew +
        " d " +
        "\xa0" +
        hoursNew +
        " h " +
        "\xa0" +
        minutesNew +
        " m " +
        "\xa0" +
        secondsNew.toFixed(0) +
        " s";
  return returnThis;
};
export default getDateDifference;
