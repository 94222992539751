import React from "react";
import { Line } from "react-chartjs-2";
import {
  getMaxDD,
  getMaxDDPercent,
} from "../../functions/calculations_for_metrics/getmaxDD";
import ulcerIndex from "../../functions/calculations_for_metrics/ulcerIndex";
import { handleNANs } from "../../functions/handleUndefined";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import linearRegression from "../../functions/calculations_for_metrics/linearRegression";
import pcorr from "../../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../../functions/calculations_for_metrics/steyx";
import DEVSQ from "../../functions/calculations_for_metrics/DEVSQ";
import { useHistory } from "react-router-dom";
import calculateZScore from "../../functions/zScoreCalculation";
import getEntryExitLots from "./../../functions/getEntryExitLots";

import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../../functions/sharpeRatio";
//let he = require("he");
var { jStat } = require("jstat");
function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => {
  return arr.reduce(function (a, b) {
    return a + b;
  }, 0);
};
const IndexGraph = (props) => {
  const entries = props.userData;
  const tier = props.tier;
  const active = props.active;
  const riskFreeRate = props.riskFreeRate;
  const chosenGraphGLOBAL = props.neededMetric;
  //const startingBalanceGlobal = props.startingBalance;
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  const metricvaluefunction = () => {
    // Set Variables
    let metricvalue = [];
    let iterationarray = [];
    let ProfitFactor = 0;
    let PercentProfitFactor = 0;
    let PercentProfitLossRatio = 0;
    let PercentCPCIndex = 0;
    let ProfitLossRatio = 0;
    let RecoveryFactor = 0;
    let KellyPercent = 0;
    let NormalizedExpectancy = 0;
    let winRate = 0;
    let CPCIndex = 0;
    let UlcerIndex = 0;
    let zScore = 0;
    //let SharpeRatio = 0;
    let ProbabilityOfRandomChance = 0;
    let LRCorrelation = 0;
    let LRStandardError = 0;
    let KRatio = 0;
    let Expectation = 0;
    let ReturnOverMaxDrawdown = 0;
    //let newaccountbal = startingBalanceGlobal;
    let percentgain = 0;
    let sharpeRatioArith = 0;
    let sharpeRatioGeo = 0;
    let sortinoRatioArith = 0;
    let sortinoRatioGeo = 0;
    let numWins = 0;
    let numLosses = 0;
    let numTrades = 0;
    let totalProfits = 0;
    let totalPL_dollar = 0;

    // let newactualaccountbalancearr = [];
    let fullaccountbalancearr = [];
    let totalDW = props.startingBalance;

    let totalLosses = 0;
    const chosenGraph = props.neededMetric;
    //// => ------ Functions related to percent gain array handling ------
    let percentgainArray = [];
    let percentgainWINArray = [];
    let percentgainLOSSArray = [];

    //let accountbaltemp = startingBalanceGlobal;
    /*     let accountbalancearr = [];
    for (let ip = 0, jp = entries.length; ip < jp; ip++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[ip].entry.profitLoss * 100);
      let x100commissions = Number(entries[ip].entry.commissions * 100);
      let x100fees = Number(entries[ip].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      const thisAccountBalance = Number(entries[ip].balance);

      //accountbaltemp += correctedSUM;
      //accountbalancearr.push(thisAccountBalance);
    } */ //MORE INIT STUFF
    /*     for (let iu = 0, ju = newactualaccountbalancearr.length; iu < ju; iu++) {
      iterationarray.push(iu);
      fullaccountbalancearr.push(newactualaccountbalancearr[iu]);
      if (iu === 0) {
      } else {
        let profitloss =
          newactualaccountbalancearr[iu] - newactualaccountbalancearr[iu - 1];
        let percentgain =
          (profitloss / newactualaccountbalancearr[iu - 1]) * 100;
        percentgainArray.push(percentgain);
      }
    }
    for (let ii = 0, jj = percentgainArray.length; ii < jj; ii++) {
      if (percentgainArray[ii] < 0) {
        percentgainLOSSArray.push(percentgainArray[ii]);
      } else if (percentgainArray[ii] > 0) {
        percentgainWINArray.push(percentgainArray[ii]);
      }
    }
    let lineararrINIT = [];
    const linearRegressionINIT = linearRegression(
      newactualaccountbalancearr,
      iterationarray
    );
    const slopeINIT = linearRegressionINIT.slope;
    const intercept = linearRegressionINIT.intercept;
    for (let i = 0, j = fullaccountbalancearr.length; i < j; i++) {
      let lineararrentry = slopeINIT * i + intercept;
      lineararrINIT.push(lineararrentry);
    } */ //INIT STUFF, NOT NEEDED
    // ---------------------------------------------------

    // -------------------- Calculate the rest then push  --------------------
    for (var i = 0, j = entries.length; i < j; i++) {
      percentgainLOSSArray = [];
      percentgainWINArray = [];
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      //newaccountbal += correctedSUM;
      const thisAccountBalance = Number(entries[i].balance);

      fullaccountbalancearr.push(thisAccountBalance);
      iterationarray.push(i);
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        percentgainArray.push(0);
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        percentgain =
          (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
        percentgainArray.push(percentgain);
      }

      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        metricvalue.push(metricvalue[i - 1]); // if the entry is a d/w, push the last metric value
        totalDW += correctedSUM;
        continue;
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        totalPL_dollar += correctedSUM;
        if (correctedSUM < 0) {
          totalLosses += correctedSUM;
          numLosses += 1;
        } else if (correctedSUM > 0) {
          totalProfits += correctedSUM;
          numWins += 1;
        }
        numTrades += 1;
      }

      for (let i = 0, j = percentgainArray.length; i < j; i++) {
        if (percentgainArray[i] < 0) {
          percentgainLOSSArray.push(percentgainArray[i]);
        } else if (percentgainArray[i] > 0) {
          percentgainWINArray.push(percentgainArray[i]);
        }
      }
      let averagePLPerTradepercent = arrAvg(percentgainArray);
      let maxDDPercent = getMaxDDPercent(fullaccountbalancearr);
      let averagePLPerWinpercent = arrAvg(percentgainWINArray);
      let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);
      let totalWinsPercent = arrSum(percentgainWINArray);
      let totalLossesPercent = arrSum(percentgainLOSSArray);

      //let totalPLPercent = arrSum(percentgainArray);
      let averagePLPerWindollar = totalProfits / numWins;
      let averagePLPerLossdollar = totalLosses / numLosses;
      let averagePLPerTradedollar = totalPL_dollar / numTrades;
      let stdDevperTradepercent = getStandardDeviation(percentgainArray);
      let cummulativeGain = (totalPL_dollar / totalDW) * 100;
      let lineararr = [];
      const linearRegressionReturn = linearRegression(
        fullaccountbalancearr,
        iterationarray
      );
      const slope = linearRegressionReturn.slope;
      const intercept = linearRegressionReturn.intercept;
      for (let i = 0, j = fullaccountbalancearr.length; i < j; i++) {
        let lineararrentry = slope * i + intercept;
        lineararr.push(lineararrentry);
      }
      /*       function reqListener() {
        console.log(this.responseText);
      }

      var testURL =
        "https://www.treasury.gov/resource-center/data-chart-center/interest-rates/pages/XmlView.aspx?data=billrates";
      const portfoliosPromise = fetch(testURL, {
        mode: "no-cors",
       dataType: "jsonp",
        method: "GET",
        headers: {
          Accept: "text/xml",
          headers: {
            "Content-Type": "text/xml",
          },
        },
      });
      portfoliosPromise
        .then((response) => {
          console.log(response);
          response.text();
        })
        .then((data) => {
          console.log(data);
          const parser = new DOMParser();
          const xml = parser.parseFromString(data, "application/xml");
          console.log(xml);
        })
        .catch(console.error); */
      /*       var x = new XMLHttpRequest();
      x.open("GET", testURL, true);
      x.onreadystatechange = function () {
        if (x.readyState == 4 && x.status == 200) {
          var doc = x.responseXML;
          // …
        }
      };
      x.send(null); */
      /*       $.ajax({
        url: testURL, // name of file you want to parse
        dataType: "xml", // type of file you are trying to read
        success: parse, // name of the function to call upon success
        error: function () {
          alert("Error: Something went wrong");
        },
      }); */
      if (chosenGraph === "profitFactor") {
        ProfitFactor = totalProfits / Math.abs(totalLosses);
        handleNANs(ProfitFactor, metricvalue);
      } else if (chosenGraph === "percentprofitFactor") {
        PercentProfitFactor = totalWinsPercent / Math.abs(totalLossesPercent);
        handleNANs(PercentProfitFactor, metricvalue);
      } else if (chosenGraph === "profitLossRatio") {
        ProfitLossRatio =
          averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
        handleNANs(ProfitLossRatio, metricvalue);
      } else if (chosenGraph === "percentprofitLossRatio") {
        PercentProfitLossRatio =
          averagePLPerWinpercent / Math.abs(averagePLPerLosspercent);
        handleNANs(PercentProfitLossRatio, metricvalue);
      } else if (chosenGraph === "recoveryFactor") {
        RecoveryFactor =
          totalPL_dollar / Math.abs(getMaxDD(fullaccountbalancearr));
        handleNANs(RecoveryFactor, metricvalue);
      } else if (chosenGraph === "kellypercent") {
        KellyPercent = (averagePLPerTradedollar / averagePLPerWindollar) * 100;
        handleNANs(KellyPercent, metricvalue);
      } else if (chosenGraph === "expectation") {
        Expectation =
          averagePLPerTradepercent / Math.abs(averagePLPerLosspercent);
        handleNANs(Expectation, metricvalue);
      } else if (chosenGraph === "returnOverMaxDrawdown") {
        ReturnOverMaxDrawdown = cummulativeGain / Math.abs(maxDDPercent);
        handleNANs(ReturnOverMaxDrawdown, metricvalue);
      } else if (chosenGraph === "ulcerIndex") {
        UlcerIndex = ulcerIndex(fullaccountbalancearr);
        handleNANs(UlcerIndex, metricvalue);
      } else if (chosenGraph === "zScore") {
        zScore = calculateZScore(fullaccountbalancearr).zScore;
        handleNANs(zScore, metricvalue);
      } else if (chosenGraph === "probabilityOfRandomChance") {
        ProbabilityOfRandomChance =
          numTrades - 1 <= 0
            ? 0
            : 1 -
              studentsTCumulativeValue(
                averagePLPerTradepercent /
                  (stdDevperTradepercent / Math.sqrt(numTrades - 1)),
                numTrades - 1
              );
        handleNANs(ProbabilityOfRandomChance * 100, metricvalue);
      } else if (chosenGraph === "normalizedExpectancypercent") {
        NormalizedExpectancy =
          (averagePLPerTradedollar / Math.abs(averagePLPerLossdollar)) * 100;
        handleNANs(NormalizedExpectancy, metricvalue);
      } else if (chosenGraph === "LRCorrelation") {
        LRCorrelation = pcorr(fullaccountbalancearr, lineararr);
        handleNANs(LRCorrelation, metricvalue);
      } else if (chosenGraph === "LRStandardError") {
        LRStandardError = STEYX(fullaccountbalancearr, lineararr);
        handleNANs(LRStandardError, metricvalue);
      } else if (chosenGraph === "sharpeRatioArith") {
        sharpeRatioArith = calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSharpeRatio;
        handleNANs(sharpeRatioArith, metricvalue);
      } else if (chosenGraph === "sortinoRatioArith") {
        sortinoRatioArith = calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSortinoRatio;
        handleNANs(sortinoRatioArith, metricvalue);
      } else if (chosenGraph === "sharpeRatioGeo") {
        sharpeRatioGeo = calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        ).geometricSharpeRatio;
        handleNANs(sharpeRatioGeo, metricvalue);
      } else if (chosenGraph === "sortinoRatioGeo") {
        sortinoRatioGeo = calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        ).geometricSortinoRatio;
        handleNANs(sortinoRatioGeo, metricvalue);
      } else if (chosenGraph === "KRatio") {
        KRatio =
          slope /
          ((STEYX(fullaccountbalancearr, lineararr) /
            Math.sqrt(DEVSQ(lineararr))) *
            numTrades);
        handleNANs(KRatio, metricvalue);
      } else if (chosenGraph === "CPCIndex") {
        ProfitLossRatio =
          averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
        ProfitFactor = totalProfits / Math.abs(totalLosses);
        winRate = numWins / numTrades;
        CPCIndex = ProfitLossRatio * ProfitFactor * winRate;
        handleNANs(CPCIndex, metricvalue);
      } else if (chosenGraph === "percentCPCIndex") {
        PercentProfitLossRatio =
          averagePLPerWinpercent / Math.abs(averagePLPerLosspercent);
        PercentProfitFactor = totalWinsPercent / Math.abs(totalLossesPercent);
        winRate = numWins / numTrades;
        PercentCPCIndex =
          PercentProfitLossRatio * PercentProfitFactor * winRate;
        handleNANs(PercentCPCIndex, metricvalue);
      } else {
        metricvalue = [];
      }
    }
    return metricvalue;
  };
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = entries.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };
  //const randomColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16);
  //const lineColor = randomColor;
  const lineColor = "#98b5eb"; //purple

  // Tiering
  let yvalues = "";
  if (tier === "free") {
    yvalues = [];
  } else if (tier === "pro" && active) {
    yvalues = metricvaluefunction();
  } else if (tier === "oldpro" && active) {
    yvalues = metricvaluefunction();
  } else if (tier === "master" && active) {
    yvalues = metricvaluefunction();
  } else if (tier === "ultimate" && active) {
    yvalues = metricvaluefunction();
  } else if (tier === "admin" && active) {
    yvalues = metricvaluefunction();
  } else {
    yvalues = [];
  }

  const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: yvalues,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      intersect: false,
      enabled: true,
      callbacks: {
        title: () => {
          return props.neededName + ": ";
        },
        label: (tooltipItems) => {
          if (
            chosenGraphGLOBAL === "kellypercent" ||
            chosenGraphGLOBAL === "normalizedExpectancypercent" ||
            chosenGraphGLOBAL === "probabilityOfRandomChance"
          ) {
            return parseFloat(tooltipItems.yLabel).toFixed(3) + "%";
          } else {
            return parseFloat(tooltipItems.yLabel).toFixed(3);
          }
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "grab";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#deebf7",
            callback: function (value) {
              if (
                chosenGraphGLOBAL === "kellypercent" ||
                chosenGraphGLOBAL === "normalizedExpectancypercent" ||
                chosenGraphGLOBAL === "probabilityOfRandomChance"
              ) {
                return value.toFixed(3) + "%";
              } else {
                return value.toFixed(3);
              }
            },
          },
          scaleLabel: {
            display: true,
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="indexgraph">
      <div className="indexgraphwrapper">
        <Line
          data={data}
          options={options}
          className="indexgraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default IndexGraph;
