/* function roundHundredths(value) {
  return Math.round(value * 100) / 100;
} */

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const totalCostCalculation = (
  multiexec,
  type,
  assetClass,
  pointValue // 100 for options, can be anything for futures
) => {
  let entryCost = 0;
  let tempbuysum = 0;
  let tempsellsum = 0;

  const entryExecs = multiexec;
  let entryLots = 0;
  entryExecs && entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue

  // If there are no exit executions, do not calculate profit/loss
  if (type === "Multi-Leg Strategy") {
    for (let i in multiexec) {
      const entrylegtype = multiexec[i].legType;
      if (entrylegtype === "Long Call" || entrylegtype === "Long Put") {
        tempbuysum =
          tempbuysum +
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      } else if (
        entrylegtype === "Short Call" ||
        entrylegtype === "Short Put"
      ) {
        tempsellsum =
          tempsellsum +
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      }
    }
    entryCost = tempbuysum - tempsellsum;
  } else {
    for (let i in multiexec) {
      entryCost += multiexec[i].lotSize * multiexec[i].entryPrice;
    }
  }

  let totalCost = 0;

  // If true, force totalCost to calculate as if there were full exit lots
  // If true, empty array is probably passed as exitExecs
  // We multiply the entry cost by the fraction of lots closed to only calculate closed P/L

  if (assetClass === "Options") {
    totalCost = entryCost * 100;
  } else if (assetClass === "Futures") {
    totalCost = entryCost * pointValue;
  } else if (
    assetClass === "Exercise Stock" ||
    assetClass === "Assigned Stock"
  ) {
    totalCost = entryCost;
  } else {
    totalCost = entryCost;
  }
  totalCost = Math.round(totalCost * 1e10) / 1e10; // fix rounding issue

  return totalCost;
};

export const returnNetPLDollar = (a, b, c, d) => {
  // This functions return net or gross P/L depending on d
  let newa = 0;
  let newb = 0;
  let newc = 0;
  if (a !== "") {
    newa = Number(a);
  }
  if (b !== "") {
    newb = Number(b);
  }
  if (c !== "") {
    newc = Number(c);
  }
  return d === "Gross" ? newa : newa + newb + newc;
};

export default totalCostCalculation;
