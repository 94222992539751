import React, { useEffect, useState, useContext, useRef } from "react";
import { columnIds } from "./arrays";
import {
  initialSettingsState,
  selectallColumns,
} from "../Settings/initialStates";
import { preCheckColumns } from "./functions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import DeleteButton from "../../components/deleteButton";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import {
  ProTierComponent,
  MasterTierComponent,
} from "../../components/tierComponents/pro";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import { useHistory } from "react-router-dom";

let he = require("he");

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const ChooseColumnsComponent = (props) => {
  const [isDisabled, setisDisabled] = useState(true);
  const allData = props.userData;
  const forceUpdate = useForceUpdate();
  const firstUpdate = useRef(true);

  const allGlobalData = useContext(DataContext);
  const active = allGlobalData.customer.active;
  const tier = allGlobalData.customer.tier;

  const { user /* isAuthenticated */ } = useAuth0();
  const history = useHistory();

  const newSettings = allData.portfolio.settings;
  const typetemp = props.userPortfolioType;
  const chosenPortTypelength = typetemp === undefined ? 0 : typetemp.length;
  const outertype = typetemp === undefined ? "" : typetemp[0];
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType = localStorage.getItem("columnSaved");
      if (addedType === "saved") {
        showConfirmedActionButton("Columns Saved!");
        localStorage.removeItem("columnSaved");
      }
    }
  }, []);
  const handleCloseColumnsChoose = () => {
    $("#choose-columns-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    preCheckColumns(newSettings, tier, active);
    setisDisabled(true);
  };
  const handleRESETColumnsChoose = () => {
    preCheckColumns(initialSettingsState, tier, active);
    handleChange();
    forceUpdate();
  };
  const handleALLColumnsChoose = () => {
    preCheckColumns(selectallColumns, tier, active);
    handleChange();
    forceUpdate();
  };

  const handleColumnsChoose = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    document.getElementById("noclick").style.display = "inline";
    let historyColumns = {};
    $("#choose-columns-class").fadeOut(300);
    const isChooseColChecked = document.querySelectorAll(
      "#boxChooseColumnsChecks"
    );
    for (var i = 0, j = isChooseColChecked.length; i < j; i++) {
      const boxname = isChooseColChecked[i].name;
      if (isChooseColChecked[i].checked === false) {
        historyColumns[columnIds[i]] = false;
      } else {
        // if the user is a free tier, falsify the pro columns
        if (tier === "free") {
          if (
            boxname === "netpldollarcheckbox" ||
            boxname === "netplpercentcheckbox" ||
            boxname === "commissionscheckbox" ||
            boxname === "feescheckbox" ||
            boxname === "efficiencybarcheckbox" ||
            boxname === "riskpercentcheckbox" ||
            boxname === "rewardriskcheckbox" ||
            boxname === "rmultiplecheckbox" ||
            boxname === "maxadexcheckbox" ||
            boxname === "maxfavexcheckbox" ||
            boxname === "slippagecheckbox" ||
            boxname === "expectedentrycheckbox" ||
            boxname === "expectedexitcheckbox"
          ) {
            historyColumns[columnIds[i]] = false;
          } else {
            historyColumns[columnIds[i]] = true;
          }
        } else if (tier === "pro" && active) {
          if (
            boxname === "slippagecheckbox" ||
            boxname === "expectedentrycheckbox" ||
            boxname === "expectedexitcheckbox"
          ) {
            historyColumns[columnIds[i]] = false;
          } else {
            historyColumns[columnIds[i]] = true;
          }
        } else if (tier === "oldpro" && active) {
          if (
            boxname === "slippagecheckbox" ||
            boxname === "expectedentrycheckbox" ||
            boxname === "expectedexitcheckbox"
          ) {
            historyColumns[columnIds[i]] = false;
          } else {
            historyColumns[columnIds[i]] = true;
          }
        } else if (tier === "master" && active) {
          historyColumns[columnIds[i]] = true;
        } else if (tier === "ultimate" && active) {
          historyColumns[columnIds[i]] = true;
        } else if (tier === "admin" && active) {
          historyColumns[columnIds[i]] = true;
        } else {
          if (
            boxname === "netpldollarcheckbox" ||
            boxname === "netplpercentcheckbox" ||
            boxname === "commissionscheckbox" ||
            boxname === "feescheckbox" ||
            boxname === "efficiencybarcheckbox" ||
            boxname === "riskpercentcheckbox" ||
            boxname === "rewardriskcheckbox" ||
            boxname === "rmultiplecheckbox" ||
            boxname === "maxadexcheckbox" ||
            boxname === "maxfavexcheckbox" ||
            boxname === "slippagecheckbox" ||
            boxname === "expectedentrycheckbox" ||
            boxname === "expectedexitcheckbox"
          ) {
            historyColumns[columnIds[i]] = false;
          } else {
            historyColumns[columnIds[i]] = true;
          }
        }
      }
    }
    await handleSubmit(historyColumns);
    handleChange();
    store.dispatch(setLoaderState({ loading: false }));
    document.getElementById("noclick").style.display = "none";
    localStorage.setItem("columnSaved", "saved");
    props.history.push("/BlankPage");
    props.history.goBack();
  };

  const handleSubmit = async (columns) => {
    let propsToChange = {
      historyColumnDisplay: !newSettings.historyColumnDisplay
        ? {
            dateTimeOpened: true,
            dateTimeClosed: true,
            holdingTime: false,
            profitLoss: true,
            netPLdollar: false,
            netPLpercent: false,
            orderType: true,
            symbol: true,
            executions: true,
            lotSize: true,
            entryPrice: true,
            expectedEntry: false,
            stopLoss: true,
            takeProfit: true,
            exitPrice: true,
            expectedExit: false,
            slippage: false,
            maxAdEx: false,
            maxFavEx: false,
            commissions: false,
            fees: false,
            riskpercent: false,
            efficiency: false,
            rewardriskratio: false,
            rmultiple: false,
            strategy: true,
            selectedConfidence: false,
            selectedEmotion: false,
            selectedPhysical: false,
            selectedMarket: false,
            selectedTimeFrame: false,
            selectedMistake: true,
            selectedTags: true,
          }
        : columns,
    };
    let globalpropsToChange = {};

    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChange,
      globalpropsToChange,
      newSettings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    await putUserData(S3Data, history);
    allGlobalData.updateAllData(finalPush);
    forceUpdate();
  };
  const handleChange = () => {
    const isChooseColChecked = document.querySelectorAll(
      "#boxChooseColumnsChecks"
    );
    const otherColChecked = newSettings.historyColumnDisplay;
    for (var i = 0, j = isChooseColChecked.length; i < j; i++) {
      const boxname = isChooseColChecked[i].name;

      if (tier === "free") {
        if (
          boxname === "netpldollarcheckbox" ||
          boxname === "netplpercentcheckbox" ||
          boxname === "commissionscheckbox" ||
          boxname === "feescheckbox" ||
          boxname === "efficiencybarcheckbox" ||
          boxname === "riskpercentcheckbox" ||
          boxname === "rewardriskcheckbox" ||
          boxname === "rmultiplecheckbox" ||
          boxname === "maxadexcheckbox" ||
          boxname === "maxfavexcheckbox" ||
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
        } else {
          if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
            break;
          }
        }
      } else if (tier === "pro" && active) {
        if (
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
        } else {
          if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
            break;
          }
        }
      } else if (tier === "oldpro" && active) {
        if (
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
        } else {
          if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
            break;
          }
        }
      } else if (tier === "master" && active) {
        if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
          setisDisabled(true);
        } else {
          setisDisabled(false);
          break;
        }
      } else if (tier === "ultimate" && active) {
        if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
          setisDisabled(true);
        } else {
          setisDisabled(false);
          break;
        }
      } else if (tier === "admin" && active) {
        if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
          setisDisabled(true);
        } else {
          setisDisabled(false);
          break;
        }
      } else {
        if (
          boxname === "netpldollarcheckbox" ||
          boxname === "netplpercentcheckbox" ||
          boxname === "commissionscheckbox" ||
          boxname === "feescheckbox" ||
          boxname === "efficiencybarcheckbox" ||
          boxname === "riskpercentcheckbox" ||
          boxname === "rewardriskcheckbox" ||
          boxname === "rmultiplecheckbox" ||
          boxname === "maxadexcheckbox" ||
          boxname === "maxfavexcheckbox" ||
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
        } else {
          if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
            break;
          }
        }
      }
    }
  };

  let tierdisable = "";
  let mastertierdisable = "";
  if (tier === "free") {
    tierdisable = true;
    mastertierdisable = true;
  } else if (tier === "pro" && active) {
    tierdisable = false;
    mastertierdisable = true;
  } else if (tier === "oldpro" && active) {
    tierdisable = false;
    mastertierdisable = true;
  } else if (tier === "master" && active) {
    tierdisable = false;
    mastertierdisable = false;
  } else if (tier === "ultimate" && active) {
    tierdisable = false;
    mastertierdisable = false;
  } else if (tier === "admin" && active) {
    tierdisable = false;
    mastertierdisable = false;
  } else {
    tierdisable = true;
    mastertierdisable = true;
  }

  return (
    <div id="choose-columns-class">
      <span className="deletetradesheading">Choose Columns</span>
      <button
        className="deletetradesclosebutton"
        onClick={handleCloseColumnsChoose}
      >
        <DeleteButton />
      </button>
      <div className="checkBoxContainWrapper">
        <div className="checkBoxContain1">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="dtopenedcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Date & Time Opened"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="dtclosedcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Date & Time Closed"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="holdingtimecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Holding Time"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="plamountcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {"Gross P/L " +
                he.decode(String(newSettings.currencySymbol)) +
                " (Amount)"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="netpldollarcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {"Net P/L " + he.decode(String(newSettings.currencySymbol))}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 90 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="netplpercentcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Net P/L %"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 92 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="ordertypecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Order Type"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="symbolcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Symbol"
                : outertype === "Stocks" || outertype === "Options"
                ? "Ticker Symbol"
                : outertype === "Crypto"
                ? "Pair"
                : "Symbol"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="executionscheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Executions"
                : outertype === "Options"
                ? "Legs"
                : "Executions"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="lotsizecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Units"
                : outertype === "Stocks"
                ? "Shares"
                : outertype === "Options" || outertype === "Futures"
                ? "Contracts"
                : outertype === "Forex"
                ? "Lots"
                : outertype === "Crypto"
                ? "Coins/Tokens"
                : outertype === "CFD"
                ? "Quantity"
                : "Units"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="entrypricecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Avg. Entry Price"
                : outertype === "Options" || outertype === "Futures"
                ? "Avg. Entry Spot Price"
                : "Avg. Entry Price"}
            </label>
          </div>
        </div>
        <div className="checkBoxContain2">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="expectedentrycheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={mastertierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <PopoverStickOnHover
                placement="top"
                delay={300}
                xAdjust={23}
                yAdjust={0}
                keepOpen={false}
                component={
                  <div style={{ color: "#fff7cb" }}>Avg. Expected Entry</div>
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: 30,
                    marginLeft: 24,
                  }}
                >
                  {"Avg. Exp. Entry"}{" "}
                </div>
              </PopoverStickOnHover>
              {mastertierdisable && (
                <div style={{ position: "absolute", top: -8, left: 125 }}>
                  <MasterTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="stoplosscheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Stop Loss"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="takeprofitcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Take Profit"
                : outertype === "Options"
                ? "Strike Price"
                : "Take Profit"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="exitpricecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Avg. Exit Price"
                : outertype === "Options" || outertype === "Futures"
                ? "Avg. Exit Spot Price"
                : "Avg. Exit Price"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="expectedexitcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={mastertierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>

              <PopoverStickOnHover
                placement="top"
                delay={300}
                xAdjust={23}
                yAdjust={0}
                keepOpen={false}
                component={
                  <div style={{ color: "#fff7cb" }}>Avg. Expected Exit</div>
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: 30,
                    marginLeft: 24,
                  }}
                >
                  {"Avg. Exp. Exit"}{" "}
                </div>
              </PopoverStickOnHover>
              {mastertierdisable && (
                <div style={{ position: "absolute", top: -8, left: 115 }}>
                  <MasterTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="slippagecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={mastertierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Slippage"}
              {mastertierdisable && (
                <div style={{ position: "absolute", top: -8, left: 82 }}>
                  <MasterTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="commissionscheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Commissions"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 120 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="feescheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Fees"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 56 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>

          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="maxadexcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <PopoverStickOnHover
                placement="top"
                delay={300}
                xAdjust={23}
                yAdjust={0}
                keepOpen={false}
                component={
                  <div style={{ color: "#fff7cb" }}>Max. Adverse Excursion</div>
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: 30,
                    marginLeft: 24,
                  }}
                >
                  {"MAE"}
                </div>
              </PopoverStickOnHover>

              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 56 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>

          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="maxfavexcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <PopoverStickOnHover
                placement="top"
                delay={300}
                xAdjust={23}
                yAdjust={0}
                keepOpen={false}
                component={
                  <div style={{ color: "#fff7cb" }}>
                    Max. Favorable Excursion
                  </div>
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: 30,
                    marginLeft: 24,
                  }}
                >
                  {"MFE"}
                </div>
              </PopoverStickOnHover>
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 56 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="efficiencybarcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Efficiency Bar"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 116 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
        </div>
        <div className="checkBoxContain3">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="riskpercentcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Risk %"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 68 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="rewardriskcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"RRR"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 54 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="rmultiplecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
                disabled={tierdisable}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"R-Multiple"}
              {tierdisable && (
                <div style={{ position: "absolute", top: -8, left: 94 }}>
                  <ProTierComponent />
                </div>
              )}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="strategycheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Strategies"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="confidencecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Confidence"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="emotionalstatecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Emotional States"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="physicalstatecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Physical States"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="marketstatecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Market State"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="timeframecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Time-Frame"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="mistakecheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Mistakes"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="tagcheckbox"
                type="checkbox"
                id="boxChooseColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Tags"}
            </label>
          </div>
        </div>
      </div>

      <button
        className="choosecolumnresetbutton"
        onClick={handleRESETColumnsChoose}
      >
        Default
      </button>
      <button
        className="choosecolumnALLbutton"
        onClick={handleALLColumnsChoose}
      >
        Select All
      </button>
      <button
        className="choosecolumnnobutton"
        onClick={handleCloseColumnsChoose}
      >
        Cancel
      </button>
      <button
        className="choosecolumnsubmitbutton"
        onClick={handleColumnsChoose}
        disabled={isDisabled}
      >
        Save
      </button>
    </div>
  );
};

export default withRouter(ChooseColumnsComponent);
