export const handleUndefined = (buff, equal) => {
  let otherchosenPortType = null;
  if (buff === undefined) {
    otherchosenPortType = equal;
  } else {
    if (buff[0] === "" || buff[0] === null || buff[0] === undefined) {
      otherchosenPortType = equal;
    } else {
      otherchosenPortType = buff;
    }
  }
  return otherchosenPortType;
};
export const handleNANs = (value, array) => {
  if (
    isNaN(value) ||
    value === null ||
    value === "Infinity" ||
    value === "-Infinity" ||
    Math.abs(value) === Infinity ||
    value === undefined ||
    value === "" ||
    !value
  ) {
    array.push("-");
  } else {
    array.push(value.toFixed(3));
  }
};
export const handleNANNonPUSH = (value) => {
  if (
    isNaN(value) ||
    value === null ||
    value === "Infinity" ||
    value === "-Infinity" ||
    Math.abs(value) === Infinity ||
    value === undefined ||
    value === "" ||
    !value
  ) {
    return true;
  } else {
    return false;
  }
};
export const handleNANGOOD = (value, desiredreturn) => {
  if (
    isNaN(value) ||
    value === null ||
    value === "Infinity" ||
    value === "-Infinity" ||
    Math.abs(value) === Infinity ||
    value === undefined ||
    value === "" ||
    !value
  ) {
    return desiredreturn;
  } else {
    return value;
  }
};
export const handleNANGOOD3dig = (value, desiredreturn) => {
  if (
    isNaN(value) ||
    value === null ||
    value === "Infinity" ||
    value === "-Infinity" ||
    Math.abs(value) === Infinity ||
    value === undefined ||
    value === "" ||
    !value
  ) {
    return desiredreturn;
  } else {
    return value.toFixed(3);
  }
};
export default handleUndefined;
