export const readContent = (file) => {
  return new Promise((accept, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      accept({
        name: file.name,
        type: file.type,
        content: reader.result,
      });
    reader.onerror = () => reject();
  });
};
export default readContent
