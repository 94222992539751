import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import getSpecificPortofolio from "../../../functions/getSpecificPortfolio";
import createDatafromSettingsProps from "../../../functions/createDatafromSettingsFunction";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../../utils/AWS/putS3UserObject";
import useForceUpdate from "../../../hooks/useForceUpdate";
import defaultTickerSymbols from "./../../../components/TickerTape/defaultTickerSymbols";
import PopoverStickOnHover from "./../../../components/PopoverStickOnHover";

import $ from "jquery";
function isArrayEmpty(arr) {
  return arr.length === 0;
}
export const EditTickerTape = (props) => {
  const firstUpdate = useRef(true);
  const history = useHistory();
  const allGlobalData = useContext(DataContext);
  const allData = props.allData;
  const portfolio = getSpecificPortofolio(allGlobalData.allData);
  const settings = portfolio && portfolio.settings;
  const dashboardSettings = settings?.dashboardElements;
  let tickerStateINIT = dashboardSettings?.tickertape;
  const { user /* isAuthenticated  */ } = useAuth0();

  const [tickerState, settickerState] = useState(tickerStateINIT.display);
  const [addTickerState, setaddTickerState] = useState("notadd");
  const [addTickerProName, setaddTickerProName] = useState("");
  const [addTickerDescription, setaddTickerDescription] = useState("");

  const [tickerSymbolsState, settickerSymbolsState] = useState(
    dashboardSettings.tickertape.postChange
      ? tickerStateINIT.symbols
      : defaultTickerSymbols //tickerStateINIT.symbols
  );
  const forceUpdate = useForceUpdate();

  const handleTickerTapeAddDelete = useCallback(
    async (tickerSymbolsStateIn) => {
      localStorage.setItem("dbSetRunning", "yes");
      localStorage.setItem("tickertapeChange", "yes");
      var localDashboardSettings = JSON.parse(
        localStorage.getItem("dashboardSettings")
      );
      const checkvar =
        localDashboardSettings && localDashboardSettings !== null
          ? localDashboardSettings
          : settings.dashboardElements;
      let tickeractual = checkvar.tickertape;

      tickeractual.symbols = tickerSymbolsStateIn;
      !tickeractual.postChange && (tickeractual.postChange = true);

      const dataToSet = {
        order: checkvar.order,
        tickertape: tickeractual,
        toppinnedmetrics: checkvar.toppinnedmetrics,
        maingraphs: checkvar.maingraphs,
        pinnedPercentages: checkvar.pinnedPercentages,
        recentTradesTable: checkvar.recentTradesTable,
      };
      localStorage.setItem("dashboardSettings", JSON.stringify(dataToSet));
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });

      let propsToChangei = {
        dashboardElements: dataToSet,
      };
      let globalpropsToChange = {};
      const finalPush = createDatafromSettingsProps(
        allData.allData.chosenPortfolios,
        allData.allData.createdPortfolios,
        propsToChangei,
        globalpropsToChange,
        settings,
        allData.allData.globalSettings,
        allData.allData.version
      );

      // SET S3 DATA
      const S3Data = {
        data: finalPush,
        userId: user.sub,
      };
      allGlobalData.updateAllData(finalPush);

      const finalAwait = async () => {
        await putUserData(S3Data, history);
        $(window).unbind("beforeunload");
        localStorage.setItem("dbSetRunning", "no");
      };
      await finalAwait();
    },
    [allData, settings, allGlobalData, user, tickerSymbolsState]
  );

  const handleTickerTapeShow = useCallback(
    async (checked) => {
      if (!tickerState) {
        settickerState(true);
      } else {
        settickerState(false);
      }
      localStorage.setItem("dbSetRunning", "yes");
      var localDashboardSettings = JSON.parse(
        localStorage.getItem("dashboardSettings")
      );
      const checkvar =
        localDashboardSettings && localDashboardSettings !== null
          ? localDashboardSettings
          : settings.dashboardElements;
      let tickeractual = checkvar.tickertape;
      tickeractual.display = !checked;

      const dataToSet = {
        order: checkvar.order,
        tickertape: tickeractual,
        toppinnedmetrics: checkvar.toppinnedmetrics,
        maingraphs: checkvar.maingraphs,
        pinnedPercentages: checkvar.pinnedPercentages,
        recentTradesTable: checkvar.recentTradesTable,
      };
      localStorage.setItem("dashboardSettings", JSON.stringify(dataToSet));
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });

      let propsToChangei = {
        dashboardElements: dataToSet,
      };
      let globalpropsToChange = {};
      const finalPush = createDatafromSettingsProps(
        allData.allData.chosenPortfolios,
        allData.allData.createdPortfolios,
        propsToChangei,
        globalpropsToChange,
        settings,
        allData.allData.globalSettings,
        allData.allData.version
      );

      // SET S3 DATA
      const S3Data = {
        data: finalPush,
        userId: user.sub,
      };
      allGlobalData.updateAllData(finalPush);

      const finalAwait = async () => {
        await putUserData(S3Data, history);
        $(window).unbind("beforeunload");
        localStorage.setItem("dbSetRunning", "no");
      };
      await finalAwait();
    },
    [allData, settings, allGlobalData, user]
  );
  const movecursor = {
    paddingLeft: "10px",
  };
  const tickerSymbolComp = tickerSymbolsState.map((ticker) => {
    const componentin = (
      <div className="editdashtickers">
        <div className="editdashtickersproname">{ticker.proName}</div>
        <div className="editdashtickersddesc">
          {ticker.title ? ticker.title : "No description"}
        </div>
        <div
          className="closeeditdashboardbuttonmini"
          onClick={(e) => {
            let tickerSymbolsStateTemp = tickerSymbolsState.slice();
            tickerSymbolsStateTemp = tickerSymbolsStateTemp.filter(
              (symbol) => symbol.proName !== ticker.proName
            );
            settickerSymbolsState(tickerSymbolsStateTemp);
            handleTickerTapeAddDelete(tickerSymbolsStateTemp);
          }}
        ></div>
      </div>
    );
    return componentin;
  });
  const component = (
    <div className="select-toggler-editdashticker-wrapper">
      <div
        className="select-toggler-editdashticker"
        id="select-toggler-editdashticker"
      >
        {"Show"}
        <label className="switch toggleadjust4">
          <input
            //disabled={toggledisabled}
            id="perftogglebutton"
            type="checkbox"
            checked={!tickerState}
            onChange={(e) => handleTickerTapeShow(e.target.checked)}
          />
          <span className="sliderperf roundperf"></span>
        </label>
        {"Hide"}
      </div>
      {/*       <div>Ticker Symbols</div> */}
      <div className="editdashtickersymbolswrapper">{tickerSymbolComp}</div>
      {addTickerState === "notadd" && (
        <button
          className="editdashtickersymbolsaddbutton"
          onClick={() => {
            setaddTickerState("add");
          }}
        >
          {"Add Ticker Symbol"}
        </button>
      )}
      {/*       {addTickerState === "notadd" && (
        <button
          className="editdashtickersymbolsaddbutton7"
          onClick={() => {
            setaddTickerState("add");
          }}
        >
          {"Sync Ticker Tape"}
        </button>
      )} */}
      {addTickerState === "add" && (
        <div className="editdashtickersymbolswrapper2">
          Symbol{" "}
          <PopoverStickOnHover
            component={
              <span
                style={{
                  color: "#fff7cb",
                  padding: "2px",
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                  width: 200,
                }}
              >
                The actual ticker symbol. Must match the ticker symbol from
                TradingView exactly.
              </span>
            }
            placement="bottom-start"
            setClass="serverimageTooltipSuperWrapper"
            delay={300}
            xAdjust={27}
            yAdjust={0}
            keepOpen={false}
          >
            <span className="tooptip-i-style6">&#x1D48A;</span>
          </PopoverStickOnHover>
          {!addTickerProName && (
            <em class="requiredlegtype2">&nbsp;Required</em>
          )}
          <input
            name="portfolioName"
            value={addTickerProName}
            style={movecursor}
            className="text-input-boxes-addport53"
            autoComplete="off"
            required
            pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
            onChange={(e) => {
              setaddTickerProName(e.target.value);
            }}
          />
          Description{" "}
          <input
            name="portfolioName"
            value={addTickerDescription}
            style={movecursor}
            className="text-input-boxes-addport532"
            autoComplete="off"
            pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
            onChange={(e) => {
              setaddTickerDescription(e.target.value);
            }}
          />
          <button
            className="editdashtickersymbolsaddbutton3"
            onClick={() => {
              setaddTickerState("notadd");
              setaddTickerProName("");
              setaddTickerDescription("");
            }}
          >
            Done
          </button>
          <button
            className="editdashtickersymbolsaddbutton4"
            disabled={!addTickerProName ? true : false}
            onClick={(e) => {
              setaddTickerProName("");
              setaddTickerDescription("");
              let tickerSymbolsStateTemp = tickerSymbolsState.slice();
              const newsymbol = {
                proName: addTickerProName,
                title: addTickerDescription,
              };
              tickerSymbolsStateTemp.push(newsymbol);

              settickerSymbolsState(tickerSymbolsStateTemp);
              handleTickerTapeAddDelete(tickerSymbolsStateTemp);
            }}
          >
            Add Symbol
          </button>
        </div>
      )}
    </div>
  );

  return component;
};
export default EditTickerTape;
