import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { metricsPropsTooltips } from "./arrays";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const showPerformanceMetricTable = (
  calcs,
  PerformanceMetricsLabels,
  selectedGraphs,
  metricsPROPS
) => {
  let tableRows = [];
  const selectedGraphz = selectedGraphs;
  const smoothScroll = (target, condition) => {
    if (!condition) {
    } else {
      $(".fadeindown").removeClass();
      $("#performancemetricgraphswrapper").animate(
        {
          scrollTop:
            $("#" + target).offset().top -
            $("#performancemetricgraphswrapper").offset().top +
            $("#performancemetricgraphswrapper").scrollTop() -
            $("#performancemetricgraphswrapper").height() / 3.1,
        },
        400
      );
    }
  };

  for (let i = 0; i < PerformanceMetricsLabels.length; i++) {
    let condition = false;
    let neededtooltip = metricsPropsTooltips.filter(
      (x) => x.value === metricsPROPS[i].value
    );
    const popover = (
      <Popover className="popover-basic-custom" id="popover-basic">
        <Popover.Header>{neededtooltip[0].title}</Popover.Header>
        <Popover.Body>
          {
            <div>
              <div>{neededtooltip[0].label}</div>
              <div style={{ marginTop: "10px", color: "#99add0" }}>
                {`Calculation: ${neededtooltip[0].calculation}`}
              </div>
            </div>
          }
        </Popover.Body>
      </Popover>
    );

    if (!selectedGraphz) {
    } else {
      for (let ii = 0; ii < selectedGraphz.length; ii++) {
        if (metricsPROPS[i].value !== selectedGraphz[ii]) {
          condition = false;
        } else {
          condition = true;
          break;
        }
      }
    }

    tableRows = tableRows.concat(
      <div className="outermetriccell" key={PerformanceMetricsLabels[i]}>
        <div className="indextext2">
          <div
            onClick={() => {
              smoothScroll(metricsPROPS[i].value, condition);
            }}
            className="inner-metric-cell1PM"
            disabled={!condition}
          >
            {condition && (
              <FontAwesomeIcon
                className="inner-metric-cell1PM2"
                icon={faCaretRight}
              />
            )}{" "}
          </div>
          <OverlayTrigger
            placement={neededtooltip[0].tooltipPlacement}
            delay={{ show: 700, hide: 500 }}
            overlay={popover}
          >
            <div
              onClick={() =>
                document
                  .querySelectorAll(".popover-basic-custom")
                  .forEach((e) => (e.style.display = "none"))
              }
            >
              {PerformanceMetricsLabels[i]}
            </div>
          </OverlayTrigger>
        </div>
        <div className="innermetriccell">{calcs[i]}</div>
      </div>
    );
  }
  return tableRows;
};
// export default showPerformanceMetricTable;
