import React, { useEffect, useState, useContext } from "react";
import { columnIds } from "./arrays";
import {
  initialSettingsState,
  selectallDashColumns,
} from "../Settings/initialStates";
import { preCheckColumnsDash } from "./functions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import premiumTierComponent from "../../components/tierComponents/premium";
import DeleteButton from "../../components/deleteButton";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import { useHistory } from "react-router-dom";

let he = require("he");

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const ChooseDashColumnsComponent = (props) => {
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();
  const [isDisabled, setisDisabled] = useState(true);
  const allData = props.userData;
  const history = useHistory();

  const forceUpdate = useForceUpdate();
  const newSettings = allData.portfolio.settings;
  const userId = user.sub;
  const typetemp = props.userPortfolioType;
  const chosenPortTypelength = typetemp === undefined ? 0 : typetemp.length;
  const outertype = typetemp === undefined ? "" : typetemp[0];
  useEffect(() => {}, []);
  const handleCloseColumnsChoose = () => {
    $("#choose-dashcolumns-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    preCheckColumnsDash(newSettings.dashboardElements.recentTradesTable);
    setisDisabled(true);
  };
  const handleRESETColumnsChoose = () => {
    preCheckColumnsDash(
      initialSettingsState.dashboardElements.recentTradesTable
    );
    handleChange();
    forceUpdate();
  };
  const handleALLColumnsChoose = () => {
    preCheckColumnsDash(selectallDashColumns);
    handleChange();
    forceUpdate();
  };

  const handleColumnsChoose = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    document.getElementById("noclick").style.display = "inline";
    let dashboardColumns = {};
    $("#choose-dashcolumns-class").fadeOut(300);
    const isChooseColChecked = document.querySelectorAll(
      "#boxChooseDashColumnsChecks"
    );
    for (var i = 0, j = isChooseColChecked.length; i < j; i++) {
      if (isChooseColChecked[i].checked === false) {
        dashboardColumns[columnIds[i]] = false;
      } else {
        dashboardColumns[columnIds[i]] = true;
      }
    }
    await handleSubmit(dashboardColumns);
    handleChange();
    forceUpdate();
    store.dispatch(setLoaderState({ loading: false }));
    document.getElementById("noclick").style.display = "none";
    props.history.push("/BlankPage");
    props.history.goBack();
  };

  const handleSubmit = async (columns) => {
    let propsToChange = {
      dashboardElements: {
        order: newSettings.dashboardElements.order,
        tickertape: newSettings.dashboardElements.tickertape,
        toppinnedmetrics: newSettings.dashboardElements.toppinnedmetrics,
        maingraphs: newSettings.dashboardElements.maingraphs,
        pinnedPercentages: newSettings.dashboardElements.pinnedPercentages,
        recentTradesTable: {
          display: true,
          dashboardColumnDisplay: !newSettings.dashboardElements
            .recentTradesTable.dashboardColumnDisplay
            ? {
                dateTimeOpened: true,
                dateTimeClosed: true,
                holdingTime: false,
                profitLoss: true,
                netPLdollar: false,
                netPLpercent: false,
                orderType: true,
                symbol: true,
                executions: true,
                lotSize: true,
                entryPrice: true,
                expectedEntry: false,
                stopLoss: true,
                takeProfit: true,
                exitPrice: true,
                expectedExit: false,
                slippage: false,
                maxAdEx: false,
                maxFavEx: false,
                commissions: false,
                fees: false,
                riskpercent: false,
                efficiency: false,
                rewardriskratio: false,
                rmultiple: false,
                strategy: true,
                selectedConfidence: false,
                selectedEmotion: false,
                selectedPhysical: false,
                selectedMarket: false,
                selectedTimeFrame: false,
                selectedMistake: true,
                selectedTags: true,
              }
            : columns,
        },
      },
    };
    let globalpropsToChange = {};
    localStorage.setItem(
      "dashboardSettings",
      JSON.stringify(propsToChange.dashboardElements)
    );
    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChange,
      globalpropsToChange,
      newSettings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    const editSettings = async () => {
      const S3Data = {
        data: finalPush,
        userId: userId,
      };
      await putUserData(S3Data, history);
      allGlobalData.updateAllData(finalPush);
      forceUpdate();
    };
    await editSettings();
  };
  const handleChange = () => {
    const isChooseColChecked = document.querySelectorAll(
      "#boxChooseDashColumnsChecks"
    );
    const otherColChecked =
      newSettings.dashboardElements.recentTradesTable.dashboardColumnDisplay;
    for (var i = 0, j = isChooseColChecked.length; i < j; i++) {
      if (isChooseColChecked[i].checked === otherColChecked[columnIds[i]]) {
        setisDisabled(true);
      } else {
        setisDisabled(false);
        break;
      }
    }
  };
  return (
    <div id="choose-dashcolumns-class">
      <span className="deletetradesheading">Choose Columns</span>
      <button
        className="deletetradesclosebutton"
        onClick={handleCloseColumnsChoose}
      >
        <DeleteButton />
      </button>
      <div className="checkBoxContainWrapper">
        <div className="checkBoxContain1">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="dtopenedcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Date & Time Opened"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="dtclosedcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Date & Time Closed"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="holdingtimecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Holding Time"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="plamountcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {"Gross P/L " +
                he.decode(String(newSettings.currencySymbol)) +
                " (Amount)"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="netpldollarcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {"Net P/L " + he.decode(String(newSettings.currencySymbol))}
              <div style={{ position: "absolute", left: 100, top: 4 }}>
                {premiumTierComponent()}
              </div>
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="netplpercentcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Net P/L %"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="ordertypecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Order Type"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="symbolcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Symbol"
                : outertype === "Stocks" || outertype === "Options"
                ? "Ticker Symbol"
                : outertype === "Crypto"
                ? "Pair"
                : "Symbol"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="executionscheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Executions"
                : outertype === "Options"
                ? "Legs"
                : "Executions"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="lotsizecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Units"
                : outertype === "Stocks"
                ? "Shares"
                : outertype === "Options" || outertype === "Futures"
                ? "Contracts"
                : outertype === "Forex"
                ? "Lots"
                : outertype === "Crypto"
                ? "Coins/Tokens"
                : outertype === "CFD"
                ? "Quantity"
                : "Units"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="entrypricecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Avg. Entry Price"
                : outertype === "Options" || outertype === "Futures"
                ? "Avg. Entry Spot Price"
                : "Avg. Entry Price"}
            </label>
          </div>
        </div>
        <div className="checkBoxContain2">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="expectedentrycheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Expected Entry"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="stoplosscheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Stop Loss"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="takeprofitcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Take Profit"
                : outertype === "Options"
                ? "Strike Price"
                : "Take Profit"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="exitpricecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;
              {chosenPortTypelength !== 1
                ? "Avg. Exit Price"
                : outertype === "Options" || outertype === "Futures"
                ? "Avg. Exit Spot Price"
                : "Avg. Exit Price"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="expectedexitcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Expected Exit"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="slippagecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Slippage"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="commissionscheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Commissions"}
              <div style={{ position: "absolute", left: 130, top: 4 }}>
                {premiumTierComponent()}
              </div>
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="feescheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Fees"}
              <div style={{ position: "absolute", left: 66, top: 4 }}>
                {premiumTierComponent()}
              </div>
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="maxadexcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Max. Adverse Excursion (MAE)"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="maxfavexcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Max. Favorable Excursion (MFE)"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="efficiencybarcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Efficiency Bar"}
            </label>
          </div>
        </div>
        <div className="checkBoxContain3">
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="riskpercentcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Risk %"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="rewardriskcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"RRR"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="rmultiplecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"R-Multiple"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="strategycheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Strategies"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="confidencecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Confidence"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="emotionalstatecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Emotional States"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="physicalstatecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Physical States"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="marketstatecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Market State"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="timeframecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Time-Frame"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="mistakecheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Mistakes"}
            </label>
          </div>
          <div className="checkboxlonatiner">
            <label className="container checkheaderseuss">
              <input
                name="tagcheckbox"
                type="checkbox"
                id="boxChooseDashColumnsChecks"
                onChange={handleChange}
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;{"Tags"}
            </label>
          </div>
        </div>
      </div>

      <button
        className="choosecolumnresetbutton"
        onClick={handleRESETColumnsChoose}
      >
        Default
      </button>
      <button
        className="choosecolumnALLbutton"
        onClick={handleALLColumnsChoose}
      >
        Select All
      </button>
      <button
        className="choosecolumnnobutton"
        onClick={handleCloseColumnsChoose}
      >
        Cancel
      </button>
      <button
        className="choosecolumnsubmitbutton"
        onClick={handleColumnsChoose}
        disabled={isDisabled}
      >
        Save
      </button>
    </div>
  );
};

export default withRouter(ChooseDashColumnsComponent);
