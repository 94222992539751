import React from "react";
import { v4 as uuidv4 } from "uuid";

import $ from "jquery";
import "./styles.scss";
export const NotificationsWrapper = (props) => {
  const check = props.check;

  const text = !check ? "Create Portfolio" : "Select Portfolio";
  const notificationsArr = [
    {
      maintext: "",
      subText: "",
      storageId: "testNotif",
    },
  ];
  const notifications = notificationsArr.map((notif, i) => {
    return (
      <div key={"notif" + i}>
        <div>{notif.maintext}</div>
        <div>{notif.subText}</div>
      </div>
    );
  });
  return <div id="notificationswrapper">{notifications}</div>;
};
export default NotificationsWrapper;
