import React from "react";
import PropTypes from "prop-types";
import { Overlay, Tooltip } from "react-bootstrap";
import $ from "jquery";
export default class PopoverStickOnHover extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      showPopover: false,
    };
  }

  handleMouseEnter() {
    // To set the toggle behavior off/on, in the actual component:
    // On - set the onMouseEnter={() => {}} prop
    // Off - do not set this prop at all
    const { delay, onMouseEnter } = this.props;

    this.setTimeoutConst = setTimeout(() => {
      this.setState({ showPopover: true }, () => {
        if (onMouseEnter) {
          onMouseEnter();
        }
      });
    }, delay);
  }

  handleMouseLeave() {
    clearTimeout(this.setTimeoutConst);
    this.setState({ showPopover: false });
  }

  componentWillUnmount() {
    if (this.setTimeoutConst) {
      clearTimeout(this.setTimeoutConst);
    }
  }
  onShowFunction(xAdjust, yAdjust) {
    const element = document.getElementById("sidebaronly");
    const widthNav = element?.offsetWidth;
    if (widthNav > 56) {
      this.setState({ showPopover: false });
      return;
    }
    const xval = xAdjust ? xAdjust : 0;
    const yval = yAdjust ? yAdjust : 0;

    let scaleAmountIn = 0;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    const heightToAdjust = 900;
    const widthToAdjust = 1680;

    if (width < widthToAdjust || height < heightToAdjust) {
      scaleAmountIn = 1.25;
    } else {
      scaleAmountIn = 1;
    }
    const elem = $("#popover");
    const { transformMe } = this.props;
    $(document).ready(function () {
      if (transformMe === false) {
        var dashboardEditButton = $(".dashboardeditbutton");
        // Get the position of the dashboardEditButton
        var position = dashboardEditButton.position();
        elem.css({
          position: "absolute",
          inset: `170px 12px auto auto`,
          transform: "none",
          width: 115,
          display: "block",
        });
      }
    });

    // adjust the x and y components of the transform/translate3d by some factor
    if (scaleAmountIn === 1.25) {
      $(document).ready(function () {
        if (transformMe === false) {
        } else {
          const currentTransform = elem.css("transform");
          const currentTranslateX = parseFloat(currentTransform?.split(",")[4]);
          const currentTranslateY = parseFloat(currentTransform?.split(",")[5]);
          const adjustedTranslateX = currentTranslateX * scaleAmountIn;
          const adjustedTranslateY = currentTranslateY * scaleAmountIn;
          elem.css({
            transform: `translate3d(${adjustedTranslateX + xval}px, ${
              adjustedTranslateY + yval
            }px, 0)`,
          });
        }
      });
    }
  }
  render() {
    let {
      component,
      children,
      placement,
      keepOpen,
      xAdjust,
      yAdjust,
      setClass,
    } = this.props;
    const child = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        ref: (node) => {
          this._child = node;
          const { ref } = child;
          if (typeof ref === "function") {
            ref(node);
          }
        },
      })
    )[0];

    return (
      <React.Fragment>
        {child}
        <Overlay
          onEnter={() => this.onShowFunction(xAdjust, yAdjust)}
          show={this.state.showPopover}
          placement={placement}
          target={this._child}
          shouldUpdatePosition={true}
        >
          {keepOpen ? (
            <Tooltip
              onMouseEnter={() => {
                this.setState({ showPopover: true });
              }}
              onMouseLeave={this.handleMouseLeave}
              id="popover"
              className={setClass ? setClass : ""}
            >
              {component}
            </Tooltip>
          ) : (
            <Tooltip id="popover" className={setClass ? setClass : ""}>
              {component}
            </Tooltip>
          )}
        </Overlay>
      </React.Fragment>
    );
  }
}

PopoverStickOnHover.defaultProps = {
  delay: 0,
};

PopoverStickOnHover.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  component: PropTypes.node.isRequired,
};
