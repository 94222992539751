import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./tierstyles.scss";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../DataContext";

export const FreeTierComponent = () => {
  const history = useHistory();

  const component = (
    <span
      onClick={() =>
        history.push({
          pathname: "/Manage-Account",
          state: {
            account: "none",
            subscription: "block",
            actions: "none",
          },
        })
      }
      className="masterTierComponent upgradeTierComponent"
    >
      Free
    </span>
  );
  return component;
};

export const ProTierComponent = () => {
  const history = useHistory();

  const component = (
    <span
      onClick={() =>
        history.push({
          pathname: "/Manage-Account",
          state: {
            account: "none",
            subscription: "block",
            actions: "none",
          },
        })
      }
      id="proTierComponent"
    >
      Pro
    </span>
  );
  return component;
};
export const UltimateTierComponent = () => {
  const history = useHistory();

  const component = (
    <span
      onClick={() =>
        history.push({
          pathname: "/Manage-Account",
          state: {
            account: "none",
            subscription: "block",
            actions: "none",
          },
        })
      }
      id="UltimateTierComponent"
    >
      <span id="UltimateTierComponentInner">Ultimate</span>
    </span>
  );
  return component;
};
export const MasterTierComponent = () => {
  const allGlobalData = useContext(DataContext);
  const active = allGlobalData.customer.active;
  const tier = allGlobalData.customer.tier;
  const trialEnd = allGlobalData?.customer?.subscription[0]?.trial_end;
  const trialStart = allGlobalData?.customer?.subscription[0]?.trial_start;
  const history = useHistory();
  const component = (
    <span
      onClick={() =>
        history.push({
          pathname: "/Manage-Account",
          state: {
            account: "none",
            subscription: "block",
            actions: "none",
          },
        })
      }
/*       className={
        !trialEnd || !trialStart
          ? "masterTierComponent"
          : tier !== "master"
          ? "masterTierComponent"
          : "masterTierComponent trialTierComponent"
      } */
      className="masterTierComponent"
    >
      {/*       {!trialEnd || !trialStart
        ? "Master"
        : tier !== "master"
        ? "Master"
        : "Trial"} */}
      {"Master"}
    </span>
  );
  return component;
};
export default ProTierComponent;
