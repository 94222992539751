import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth0Context } from "@auth0/auth0-react";
import { faUserCog, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import ProTierComponent from "./../../components/tierComponents/pro";
export const UnorderedList = styled.div`
  list-style-type: none;
  padding: 2px 2px 3px 3px;
  margin: 0;
`;

const AccountDropdown = (props) => {
  const allData = props.data.allData;
  const tier = props.tier;
  const active = props.active;
  const globalSettings = allData.globalSettings;
  const profileuserName = globalSettings.username;
  const auth0Instance = useContext(Auth0Context);
  const user = auth0Instance.user;
  const history = useHistory();
  const location = useLocation();

  const userAvatar = user.picture;
  const userName = user.name;
  const userEmail = user.email;

  // Tiering logic
  let profile_username_class = "";
  let ispro = false;
  if (tier === "free") {
    profile_username_class = "profileuserName-free";
  } else if (tier === "pro" && active) {
    profile_username_class = "profileuserName-pro";
    ispro = true;
  } else if (tier === "oldpro" && active) {
    profile_username_class = "profileuserName-pro";
    ispro = true;
  } else if (tier === "master" && active) {
    profile_username_class = "profileuserName-master";
    ispro = true;
  } else if (tier === "ultimate" && active) {
    profile_username_class = "profileuserName-ultimate";
    ispro = true;
  } else if (tier === "admin" && active) {
    profile_username_class = "profileuserName-ultimate";
    ispro = true;
  } else {
    profile_username_class = "profileuserName-free";
  }

  /*   const reputationPoints = !sharedTradesCalcs
    ? 0
    : sharedTradesCalcs.reputationPoints; */

  return (
    <UnorderedList id="account-dropdown" className={"account-dropdown"}>
      {/*       <div className="reputationpointaccdrop">
        <FontAwesomeIcon icon={faCrown} /> {reputationPoints}
      </div> */}
      <div className="profilechange">
        <div style={{ overflow: "hidden", borderRadius: "100%" }}>
          <img alt="" src={userAvatar} style={{ width: "100%" }}></img>
        </div>
      </div>
      <span className={profile_username_class}>
        <div>{profileuserName}</div>
        {/*         {ispro && <ProTierComponent />}
         */}{" "}
      </span>
      <div className="profileName">
        <div>{userName}</div>
      </div>
      <div className="profileEmail">
        <div>{userEmail}</div>
      </div>
      <div className="profileLine"></div>
      {/*       <div className="bothbalances">
        <div className="tokenBalance">
          {!web3 ? (
            <div style={{ color: "#808080", fontSize: "0.6em" }}>
              {"Not opted in for optInForRewards"}{" "}
              <button
                onClick={async () => await optInRewards()}
                className="optin-link"
              >
                Opt In
              </button>
            </div>
          ) : (
            web3.balance + " TF"
          )}
        </div>
        <div className="usdBalance">
          {!web3 ? "" : "$" + web3.balance / web3.rate}
        </div>
      </div> */}

      {/*         <div className="numportsselected">
          {chosenPortfolio.length === 1
            ? "1 portfolio selected"
            : `${chosenPortfolio.length} portfolios selected`}
        </div> */}

      <button
        onClick={async () => {
          //window.location.reload();
          localStorage.clear();
          auth0Instance.logout({
            returnTo: window.location.origin,
          });
        }}
        className="logout-link"
      >
        <div className="small-icons-OC5">
          <FontAwesomeIcon icon={faPowerOff} />
        </div>
        Log Out
      </button>
      <button
        className={
          location.pathname === "/Manage-Account"
            ? "manage-account-link-gold"
            : "manage-account-link"
        }
        onClick={() => history.push("./Manage-Account")}
      >
        <div
          className={
            location.pathname === "/Manage-Account"
              ? "small-icons-OC3-gold"
              : "small-icons-OC3"
          }
        >
          <FontAwesomeIcon icon={faUserCog} />
        </div>
        Manage Account
      </button>
    </UnorderedList>
  );
};

AccountDropdown.defaultProps = {
  userId: "",
};

AccountDropdown.propTypes = {
  userId: PropTypes.string,
};

export default AccountDropdown;
