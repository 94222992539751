import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
//import PerformanceMeter from "./PerformanceMeter";
import { DataContext } from "../../DataContext";
import DashboardComponent from "./Component";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import StartupWizard from "../../components/startupWizard/index.js";
import ChooseDashColumnsComponent from "./chooseColumns";
import processDataandSettings from "./../../utils/processDataandSettings";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;

  /*   const trialEnd = dataInstance?.customer?.subscription[0]?.trial_end;
  const trialStart = dataInstance?.customer?.subscription[0]?.trial_start;
 */
  const subscription = dataInstance ? dataInstance.customer.subscription : [];
  const need2faCheck = dataInstance?.miscData?.need2faOpen;
  let ispro = false;
  let tierDiv = "";

  const allData = dataInstance && dataInstance.allData;
  const riskFreeRate = dataInstance.miscData.riskFreeRate;
  const [dasheditMode, seteditMode] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  //const history = useHistory();

  /*   const closeModal = () => {
    setIsOpen(false);
    localStorage.removeItem("trialinitialLogin");
  }; */
  const localAcceptTerms = localStorage.getItem("tempacceptTerms");
  let processedData = {};
  if (allData && Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Dashboard - TradeFuse";
    processedData = processDataandSettings(allData, tier, active, riskFreeRate);
    //store.dispatch(setLoaderState({ loading: false }));
  }

  // Logic for when to show the upgrade modal
  useEffect(() => {
    var initialLogin = localStorage.getItem("trialinitialLogin");
    const trialCheck =
      dataInstance &&
      dataInstance.customer &&
      subscription &&
      !subscription.length
        ? true
        : false;
    if (initialLogin === "yes") {
      if (tier === "free") {
        setIsOpen(trialCheck);
      } else if (tier === "pro" && active) {
        setIsOpen(false);
      } else if (tier === "oldpro" && active) {
        setIsOpen(false);
      } else if (tier === "master" && active) {
        setIsOpen(false);
      } else if (tier === "enterprise" && active) {
        setIsOpen(false);
      } else if (tier === "admin" && active) {
        setIsOpen(false);
      } else {
        setIsOpen(trialCheck);
      }
    } else {
      setIsOpen(false);
    }
  }, [tier, active, dataInstance, subscription]);
  
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <DashboardComponent
          allData={processedData}
          tabName={"Dashboard"}
          pageName={"Dashboard"}
          editMode={dasheditMode}
          seteditMode={(val) => seteditMode(val)}
          tier={tier}
          active={active}
          riskFreeRate={riskFreeRate}
        />
        <div className="sidedashboardwrapper"></div>
        {localAcceptTerms !== "yes" && (
          <StartupWizard
            seteditMode={(e) => {
              seteditMode(e);
            }}
            allData={processedData}
          />
        )}{" "}
        {/*         <Modal
          ariaHideApp={false}
          id={"TrialExpiredModal"}
          className={"TrialExpiredModal"}
          isOpen={modalIsOpen}
          closeTimeoutMS={200}
          contentLabel="Trade"
          overlayClassName="OverlayModal"
          onRequestClose={closeModal}
        >
          <div className={"TrialExpiredModalHeader"}>
            Thank you for trying TradeFuse!
          </div>
          <button
            className="choosedaterangeclosebutton TrialExpiredModalX"
            onClick={closeModal}
          >
            <DeleteButton />
          </button>
          <div className={"TrialExpiredModalHeader2"}>
            Your trial has expired
          </div>
          <div className={"TrialExpiredModalICON"}>
            <FontAwesomeIcon icon={faHourglassEnd} />
          </div>
          <div className={"TrialExpiredModalHeadermessage"}>
            You are now back on the free version of TradeFuse, but don't worry!
            Your logged trades and data are safe and can be accessed again by
            upgrading to a premium subscription. Upgrade now to unlock full
            functionality and make the most of your trading experience.
          </div>
          <div
            id="promocodeelement3"
            onClick={() =>
              history.push({
                pathname: "/Manage-Account",
                state: {
                  account: "none",
                  subscription: "block",
                  actions: "none",
                },
              })
            }
          >
            <div id="promocodeelementmaintextold">Upgrade Now</div>
          </div>
        </Modal> */}
        <ChooseDashColumnsComponent
          userPortfolioType={
            processedData.portfolio.assetClasses.length !== 1
              ? []
              : processedData.portfolio.assetClasses
          }
          userData={processedData}
        />
      </div>
    );
  }
};

Dashboard.defaultProps = {
  tabName: "Dashboard",
  pageName: "Dashboard",
};

Dashboard.propTypes = {
  tabName: PropTypes.string,
};

export default Dashboard;
