import { proddev } from "../../PROD-DEV-TOGGLE";

const deletePictures = async (data, history) => {
  const stringifiedData = JSON.stringify(data);
  fetch(
    proddev === "prod"
      ? "https://opkt3gy2of.execute-api.us-west-1.amazonaws.com/prod/delete-pictures"
      : "https://c5jfnnmixj.execute-api.us-west-1.amazonaws.com/default/delete-pictures",
    {
      method: "POST",
      body: stringifiedData,
      headers: {
        "Content-Type": "application/json", // Specifies the type of content being sent to the server
        Accept: "application/json", // Specifies the type of content you expect to receive back from the server
      },
    }
  ).catch((err) => {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message,
      },
    });
    window.location.reload();
  });
};

export default deletePictures;