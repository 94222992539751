import $ from "jquery";
import { columnIds } from "./arrays";

export const handleDashColumnChoosing = () => {
  $("#choose-dashcolumns-class").fadeIn(300);
  document.getElementById("choose-dashcolumns-class").style.display = "flex";
  $("#overlay").fadeIn(300);
};
export const preCheckColumnsDash = (checkbuffer) => {
  const isChooseColCheckednu = document.querySelectorAll(
    "#boxChooseDashColumnsChecks"
  );
  for (var i = 0, j = columnIds.length; i < j; i++) {
    if (checkbuffer.dashboardColumnDisplay[columnIds[i]] === true) {
      isChooseColCheckednu[i].checked = true;
    } else {
      isChooseColCheckednu[i].checked = false;
    }
  }
};
export default handleDashColumnChoosing