export const allMT4Brokers = [
  {
    label: "Pacific Broker",
    server1: "PacificBroker-Demo",
    server2: "PacificBroker-Live",
    value: "Pacific Broker",
  },
  {
    label: "101FX",
    server1: "101VentureCapital-Demo",
    server2: "101VentureCapital-Live",
    value: "101FX",
  },
  {
    label: "10TradeFX",
    server1: "HoliwayInvestments-Demo",
    server2: "HoliwayInvestments-LD Live",
    server3: "HoliwayInvestmentsLtd-DemoUK",
    server4: "HoliwayInvestmentsLtd-LiveUK",
    value: "10TradeFX",
  },
  {
    label: "121 Securities",
    server1: "121Securities-Demo",
    server2: "121Securities-Real",
    value: "121 Securities",
  },
  {
    label: "121Clearing",
    server1: "121clearing-Demo",
    server2: "121clearing-Live",
    value: "121Clearing",
  },
  {
    label: "168FX",
    server1: "Forexstart-Demo",
    server2: "Forexstart-Real",
    server3: "168Consultants-Demo",
    server4: "168Consultants-Live",
    server5: "168Consult-Demo",
    server6: "FX168-Live",
    value: "168FX",
  },
  {
    label: "2 Pip Fixed",
    server1: "Leverate-Main",
    value: "2 Pip Fixed",
  },
  {
    label: "21incpool",
    server1: "21IncPool-Live",
    value: "21incpool",
  },
  {
    label: "22 Groups",
    server1: "22Groups-Demo",
    server2: "22Groups-Live",
    value: "22 Groups",
  },
  {
    label: "24FX",
    server1: "24FX-Demo",
    server2: "24FX-Primary",
    server3: "24FX-Live",
    value: "24FX",
  },
  {
    label: "24option",
    server1: "24Option-Live",
    server2: "24Option2-Live",
    server3: "RichFieldCapital-Demo",
    server4: "RichFieldCapital-Live",
    server5: "24Option-Demo",
    value: "24option",
  },
  {
    label: "2WayFx",
    server1: "Bergio-Server",
    value: "2WayFx",
  },
  {
    label: "360 Capital",
    server1: "360Capital-Demo",
    server2: "360Capital-/*  */Real",
    value: "360 Capital",
  },
  {
    label: "3TGFX",
    server1: "3TGFX-Demo",
    server2: "3TGFX-Main",
    server3: "3TGFXMT4-Demo",
    value: "3TGFX",
  },
  {
    label: "4FXbroker",
    server1: "DMF-Demo",
    server2: "DMF-Server",
    value: "4FXbroker",
  },
  {
    label: "4RunnerForex",
    server1: "PrimeXM-Live",
    value: "4RunnerForex",
  },
  {
    label: "4T",
    server1: "4TLtd-Live",
    value: "4T",
  },
  {
    label: "4xCube",
    server1: "4xCube-Demo",
    server2: "4xCube-Live",
    value: "4xCube",
  },
  {
    label: "4XDG",
    server1: "4XDG-Live",
    server2: "4XDG-Server",
    value: "4XDG",
  },
  {
    label: "4xhub",
    server1: "4XHUBLimited-Demo",
    server2: "4XHUBLimited-live1",
    value: "4xhub",
  },
  {
    label: "6iTrade",
    server1: "6iGroup-Demo",
    server2: "6iGroup-Real",
    value: "6iTrade",
  },
  {
    label: "7Bforex",
    server1: "7BForex-Demo",
    server2: "7BForex-Real2",
    value: "7Bforex",
  },
  {
    label: "8MAX",
    server1: "RedBlueSystems-DemoServer",
    server2: "RedBlueSystems-RealServer",
    value: "8MAX",
  },
  {
    label: "A1FX",
    server1: "A1Fx-DemoA1",
    server2: "A1Fx-RealA1",
    value: "A1FX",
  },
  {
    label: "AAAECN",
    server1: "AAAECN-Demo",
    server2: "AAAECN-Live",
    server3: "BGOGroup-Demo",
    value: "AAAECN",
  },
  {
    label: "AAAFx",
    server1: "Tripleafx-FX-Demo",
    server2: "Tripleafx-FX-Real",
    server3: "AAAFx-FX-Real",
    server4: "AAAFx-FX-Demo",
    server5: "TripleAExperts-FX-demo",
    value: "AAAFx",
  },
  {
    label: "AAFX Trading",
    server1: "AAFXTrading-Demo",
    server2: "AAFXTrading-Live",
    value: "AAFX Trading",
  },
  {
    label: "Aaj FX",
    server1: "AAJFinancials-Demo",
    server2: "AAJFinancials-Live",
    value: "Aaj FX",
  },
  {
    label: "ABC-123",
    server1: "ABC123-Demo",
    server2: "ABC123-Live",
    value: "ABC-123",
  },
  {
    label: "ABCmarkets",
    server1: "ABCmarkets-MT4 Demo",
    server2: "ABCmarkets-Real3",
    value: "ABCmarkets",
  },
  {
    label: "ABM Prime",
    server1: "AMBPRIME-Demo",
    server2: "AMBPRIME-Real",
    value: "ABM Prime",
  },
  {
    label: "ABS Group",
    server1: "ABS-Live",
    server2: "ABS-Demo",
    value: "ABS Group",
  },
  {
    label: "Abshire-Smith",
    server1: "Abshire-SmithMT4-Demo",
    server2: "Abshire-SmithMT4-Live",
    value: "Abshire-Smith",
  },
  {
    label: "Absolute Market",
    server1: "AbsoluteMarket-Demo",
    server2: "AbsoluteMarket-Main",
    value: "Absolute Market",
  },
  {
    label: "AbsoluteMarkets",
    server1: "AbsoluteMarkets-demo",
    server2: "AbsoluteMarkets-Server",
    server3: "AbsoluteMarkets-Server-1",
    server4: "AbsoluteMarkets-Server-2",
    value: "AbsoluteMarkets",
  },
  {
    label: "ABX Trade",
    server1: "ABXTrade-DEMO",
    server2: "ABXTrade-LIVE",
    value: "ABX Trade",
  },
  {
    label: "AC Forex",
    server1: "ACFX-Demo",
    server2: "ACFX-Real2",
    value: "AC Forex",
  },
  {
    label: "AC Markets",
    server1: "AsiaCapitalMarkets-Demo",
    server2: "AsiaCapitalMarkets-Live",
    value: "AC Markets",
  },
  {
    label: "AccentForex",
    server1: "Butenix-Server",
    server2: "Butenix-Demo Server",
    value: "AccentForex",
  },
  {
    label: "Access Direct Markets",
    server1: "AccessDirectMarkets-Demo Server",
    server2: "AccessDirectMarkets-Live Server",
    value: "Access Direct Markets",
  },
  {
    label: "Access Soft",
    server1: "AccessSoft-Live",
    server2: "AccessSoft-Demo",
    value: "Access Soft",
  },
  {
    label: "Access To Forex",
    server1: "AccesstoForex-Demo",
    server2: "AccesstoForex-Real",
    value: "Access To Forex",
  },
  {
    label: "ACCFX",
    server1: "ACCFXBrokers-Demo",
    server2: "ACCFXBrokers-Real",
    value: "ACCFX",
  },
  {
    label: "Accuindex",
    server1: "AccuindexLimited-Demo",
    server2: "AccuindexLimited-Live",
    value: "Accuindex",
  },
  {
    label: "Accuracy Accounts",
    server1: "AccuracyAccounts-Demo01",
    server2: "AccuracyAccounts-Real01",
    value: "Accuracy Accounts",
  },
  {
    label: "Ace Markets",
    server1: "AceMarkets-DemoServer",
    server2: "AceMarkets-Server",
    value: "Ace Markets",
  },
  {
    label: "AceForex",
    server1: "AceForex-Demo",
    server2: "AceForex-Live",
    value: "AceForex",
  },
  {
    label: "Acenya Capital",
    server1: "AcenyaCapital-Demo",
    server2: "AcenyaCapital-Live",
    value: "Acenya Capital",
  },
  {
    label: "Acetop",
    server1: "Acetop-Demo",
    server2: "Acetop-Live",
    value: "Acetop",
  },
  {
    label: "ACFX",
    server1: "AtlasCapital-Demo",
    server2: "AtlasCapital-Live",
    value: "ACFX",
  },
  {
    label: "ACM Gold",
    server1: "Renco-Demo",
    server2: "Gold-Demo",
    server3: "Gold-Live",
    value: "ACM Gold",
  },
  {
    label: "ACN Trading",
    server1: "ACNTradingService-Live",
    server2: "ACNTradingService-Demo",
    value: "ACN Trading",
  },
  {
    label: "ActivTrades",
    server1: "Activtrades-Demo",
    server2: "Activtrades-Server",
    server3: "Activtrades-interbank Server",
    server4: "Activtrades-Pro",
    server5: "Activtrades-Classic Server",
    server6: "ActivTrades-Classic Server 2",
    server7: "Activtrades-4",
    server8: "Activtrades-3",
    server9: "Activtrades-2",
    server10: "Activtrades-1",
    server11: "ActivTradesCorp-5",
    server12: "QTrade-2",
    server13: "ActivTradesEurope-1",
    server14: "ActivTradesEurope-2",
    server15: "ActivTradesEurope-3",
    server16: "ActivTradesEurope-4",
    server17: "ActivTradesEurope-5",
    server18: "ActivTradesEurope-Demo",
    server19: "Activtrades-5",
    server20: "QTrade-3",
    value: "ActivTrades",
  },
  {
    label: "ACY Capital",
    server1: "ACYCapital-Demo",
    server2: "ACYCapital-Live",
    server3: "ACYCapital-Live02",
    server4: "ACYCapital-Live03",
    value: "ACY Capital",
  },
  {
    label: "ACY FX",
    server1: "ACYFX-Live",
    server2: "ACYFX-Demo",
    server3: "ACYCapital-Live",
    server4: "ACYCapital-Live02",
    server5: "ACYCapital-Live03",
    server6: "ACYSecurities-Live",
    server7: "ACYSecurities-Live02",
    server8: "ACYSecurities-Live03",
    server9: "ACYFX-Live02",
    value: "ACY FX",
  },
  {
    label: "ACY Securities",
    server1: "ACYSecurities-Demo",
    server2: "ACYSecurities-Live",
    server3: "ACYSecurities-Live02",
    server4: "ACYSecurities-Live03",
    server5: "ACYCapital-Live",
    server6: "ACYCapital-Live 02",
    server7: "ACYCapital-Live03",
    server8: "ACYFX-Live",
    server9: "ACYFX-Demo",
    server10: "ACYFX-Live02",
    server11: "ACYFX-Live",
    server12: "ACYFX-Live03",
    value: "ACY Securities",
  },
  {
    label: "Adamant Finanace",
    server1: "AdamantFinance-Demo",
    server2: "AdamantFinance-Real",
    value: "Adamant Finanace",
  },
  {
    label: "AdiuvoFX",
    server1: "Adiuvo-Demo",
    server2: "Adiuvo-Live-UK",
    value: "AdiuvoFX",
  },
  {
    label: "Admiral Markets",
    server1: "AdmiralMarkets-Demo",
    server2: "AdmiralMarkets-Demo2",
    server3: "AdmiralMarkets-Live",
    server4: "AM-Live3",
    server5: "AdmiralMarkets-Live2",
    server6: "AdmiralMarkets-Live3",
    server7: "Admiralex-Live3",
    server8: "Aglobe-Live-1",
    server9: "Aglobe-Demo-1",
    server10: "AM-Live2",
    server11: "AM-Live",
    server12: "AM-Demo2",
    server13: "AM-Demo",
    value: "Admiral Markets",
  },
  {
    label: "ADN Broker",
    server1: "ADNBroker-Demo",
    server2: "ADNBroker-LiveLiquidity1",
    value: "ADN Broker",
  },
  {
    label: "AdroFx",
    server1: "AdroFX-Demo Server",
    server2: "AdroFX-Live Server",
    server3: "Adromkt-Demo Server",
    server4: "Adromkt-Live Server",
    value: "AdroFx",
  },
  {
    label: "ADS Securities",
    server1: "ADSS-Demo",
    server2: "ADSS-Live",
    server3: "ADSS-Live1",
    server4: "ADSS-Live2",
    server5: "ADSS-Live3",
    server6: "ADSSecurities-Live2",
    value: "ADS Securities",
  },
  {
    label: "ADS Securities London",
    server1: "ADSSecurities-Demo",
    server2: "ADSSecurities-Live2",
    server3: "ADSSecurities-Demo2",
    value: "ADS Securities London",
  },
  {
    label: "ADSS",
    server1: "ADSS-Demo",
    server2: "ADSS-Live",
    server3: "ADSS-Live3",
    server4: "ADSS-Live1",
    server5: "ADSSecurities-Live2",
    server6: "ADSSecurities-Demo2",
    value: "ADSS",
  },
  {
    label: "Advanced Finanacial Markets",
    server1: "AFM-Server",
    value: "Advanced Finanacial Markets",
  },
  {
    label: "Advanced Markets",
    server1: "AdvancedMarkets-Live",
    server2: "AdvancedMarkets-Demo",
    server3: "AdvancedMarkets-Live 2",
    value: "Advanced Markets",
  },
  {
    label: "Advised Trading",
    server1: "AdvisedTrading-Server",
    value: "Advised Trading",
  },
  {
    label: "AETOS",
    server1: "AETOS-Demo",
    server2: "AETOS-Live",
    server3: "AETOS-LIVE F",
    server4: "AETOSAU-Live",
    server5: "AETOS-LIVE F5",
    server6: "AETOS-Demo F",
    server7: "AETOS-LIVE M",
    server8: "AETOSUK-Live",
    server9: "AETOS-LIVE F2",
    server10: "AETOSUK-Demo",
    server11: "AETOS-Live AU",
    server12: "AETOSMarkets-Live",
    server13: "AETOSMarkets-Demo",
    value: "AETOS",
  },
  {
    label: "AFBFX",
    server1: "AFBFX-Demo",
    server2: "AFBFX-Live",
    value: "AFBFX",
  },
  {
    label: "AForex",
    server1: "AForex-Demo",
    server2: "AForex-Real",
    value: "AForex",
  },
  {
    label: "AFT",
    server1: "AFTFX-Demo",
    server2: "AFTFX-Live",
    value: "AFT",
  },
  {
    label: "Afterprime",
    server1: "Afterprime-Demo AP",
    server2: "Afterprime-Live AP",
    value: "Afterprime",
  },
  {
    label: "AFX Capital",
    server1: "STOUK-Real",
    server2: "AFXCapital-Real",
    server3: "AFXCapital-Demo",
    value: "AFX Capital",
  },
  {
    label: "AG Markets",
    server1: "AGMarkets-Live01",
    server2: "AGMarkets-Demo",
    value: "AG Markets",
  },
  {
    label: "AGEA",
    server1: "AGEA-Demo",
    server2: "AGEA-Live",
    value: "AGEA",
  },
  {
    label: "AGM Markets",
    server1: "AGMMarkets-Demo",
    server2: "AGMMarkets-Live",
    value: "AGM Markets",
  },
  {
    label: "Agrodana",
    server1: "Agrodana-Demo",
    server2: "Agrodana-Main",
    server3: "Agrodana-Live",
    value: "Agrodana",
  },
  {
    label: "AHMZ TradeFX",
    server1: "AHMZTradeFX-Demo",
    server2: "AHMZTradeFX-Real",
    value: "AHMZ TradeFX",
  },
  {
    label: "Ahorro",
    server1: "Ahorro-Demo",
    server2: "Ahorro-Real5",
    value: "Ahorro",
  },
  {
    label: "AIGroup",
    server1: "AIGroup-Demo",
    server2: "AIGroup-Live",
    value: "AIGroup",
  },
  {
    label: "AIKO Markets",
    server1: "AikoInternational-Demo",
    server2: "AikoInternational-Live",
    value: "AIKO Markets",
  },
  {
    label: "Aimsfx",
    server1: "AIMS-Demo",
    server2: "AIMS-Live Server",
    value: "Aimsfx",
  },
  {
    label: "AITS FX",
    server1: "AITSFX-Demo",
    server2: "AITSFX-Live",
    value: "AITS FX",
  },
  {
    label: "Aiz Group",
    server1: "AizGroup-Live",
    server2: "AizGroup-Demo",
    value: "Aiz Group",
  },
  {
    label: "Ajaj FX",
    server1: "AjajFX-Demo",
    server2: "AjajFX-Live",
    server3: "Rexor-Live",
    value: "Ajaj FX",
  },
  {
    label: "Ajax Financial",
    server1: "Ajax-Demo",
    server2: "Ajax-Live",
    value: "Ajax Financial",
  },
  {
    label: "AKDForex",
    server1: "AKDForex-Demo",
    server2: "AKDForex-Real",
    value: "AKDForex",
  },
  {
    label: "AKDFX",
    server1: "AKDFX-Demo",
    server2: "AKDFX-Real",
    value: "AKDFX",
  },
  {
    label: "AKFX",
    server1: "AKFXFinancial-Demo-2",
    server2: "AKFXFinancial-Live-2",
    server3: "AKFXFinancial-Live-3",
    server4: "AKFXFinancial-Live-5",
    server5: "AKFXFinancial-SB-2",
    value: "AKFX",
  },
  {
    label: "Akmos Trade",
    server1: "Akmos-Server",
    server2: "Akmos-Forex",
    value: "Akmos Trade",
  },
  {
    label: "AksysCapital",
    server1: "AksysCap-Demo",
    server2: "AksysCap-Live",
    value: "AksysCapital",
  },
  {
    label: "AKTIF Forex",
    server1: "ActiveFinancialMarkets-Demo02",
    server2: "ActiveFinancialMarkets-Live08",
    value: "AKTIF Forex",
  },
  {
    label: "Al Salam Bank",
    server1: "AlSalamBank-Demo",
    server2: "AlSalamBank-Demo2",
    server3: "AlSalamBank-Live2",
    server4: "AlSalamBank-Real5",
    value: "Al Salam Bank",
  },
  {
    label: "AL Trade",
    server1: "ALTrade-Server",
    value: "AL Trade",
  },
  {
    label: "Al-Awal Limited",
    server1: "AlAwal-Demo",
    server2: "AlAwal-Live",
    value: "Al-Awal Limited",
  },
  {
    label: "Alain Fx",
    server1: "AlainsMarket-Demo",
    server2: "AlainsMarket-Live",
    value: "Alain Fx",
  },
  {
    label: "ALB Forex",
    server1: "ALB-DEMO_SERVER",
    server2: "ALB-REAL_SERVER",
    server3: "ALBForex-Live_Server",
    server4: "ALBForex-Live_Server",
    value: "ALB Forex",
  },
  {
    label: "ALB Securities",
    server1: "ALB-DEMO_SERVER",
    server2: "ALB-REAL_SERVER",
    value: "ALB Securities",
  },
  {
    label: "AlchemyMarkets",
    server1: "AlchemyMarkets-Demo",
    server2: "AlchemyMarkets-Live",
    value: "AlchemyMarkets",
  },
  {
    label: "Alfa Robo",
    server1: "AlfaRobo-DEMO",
    server2: "AlfaRobo-REAL SERVER",
    value: "Alfa Robo",
  },
  {
    label: "Alfa Success Corp",
    server1: "AlfaSuccessCorp-Demo",
    server2: "AlfaSuccessCorp-Live",
    value: "Alfa Success Corp",
  },
  {
    label: "AlfaForex",
    server1: "AlfaForex-Server",
    server2: "AlfaForex-Demo",
    server3: "AlfaForex-Real",
    server4: "AlfaForex-Real",
    value: "AlfaForex",
  },
  {
    label: "AlfaTrade",
    server1: "ATS-Demo",
    server2: "ATS-Live",
    value: "AlfaTrade",
  },
  {
    label: "Algo Global",
    server1: "AlgoGlobal-Demo",
    server2: "AlgoGlobal-Real",
    value: "Algo Global",
  },
  {
    label: "AlgoBanque",
    server1: "AlgoBanque-Demo Server",
    server2: "AlgoBanque-Main Server 1",
    value: "AlgoBanque",
  },
  {
    label: "AlgoCipher",
    server1: "AlgoCipher-Live",
    server2: "AlgoCipher-Demo",
    value: "AlgoCipher",
  },
  {
    label: "Aliom",
    server1: "Aliom-Demo",
    server2: "Aliom-Live",
    value: "Aliom",
  },
  {
    label: "Ally Invest",
    server1: "AllyInvestForex-Live 120",
    server2: "AllyInvestForex-Live 121",
    server3: "AllyInvestForex-Live 122",
    server4: "AllyInvestForex-Live 124",
    server5: "AllyInvestForex-Demo 106",
    server6: "AllyInvestForex-Live 125",
    server7: "AllyInvestForex-Demo(R)",
    server8: "AllyInvestForex-Live 7",
    value: "Ally Invest",
  },
  {
    label: "Alma Forex",
    server1: "AlmaGroup-Server",
    server2: "AlmaGroup-MT4",
    server3: "AlmaGroup-Demo",
    value: "Alma Forex",
  },
  {
    label: "Alnus FX",
    server1: "ALNUSFX-DEMO",
    server2: "ALNUSFX-Live",
    value: "Alnus FX",
  },
  {
    label: "Alpari Eurasia",
    server1: "AlpariEvrasia-Trade",
    server2: "AlpariEvrasia-Trade02",
    value: "Alpari Eurasia",
  },
  {
    label: "Alpari FS",
    server1: "AlpariFS-ECN",
    server2: "AlpariFS-ECN-Demo",
    server3: "AlpariFS-Standard",
    server4: "AlpariFS-Standard-Demo",
    server5: "Alpari-ECN-Demo",
    value: "Alpari FS",
  },
  {
    label: "Alpari INT",
    server1: "Alpari-Int-Trade",
    server2: "Alpari-Int-ECN-Pro",
    server3: "Alpari-Int-Fix",
    server4: "Alpari-ECN-Demo",
    server5: "Alpari-Pro.ECN",
    server6: "Alpari-Pro.ECN-Demo",
    server7: "Alpari-Trade",
    server8: "Alpari-Contest",
    server9: "Alpari-Nano",
    server10: "Alpari-Demo",
    server11: "Alpari-Trade02",
    server12: "Alpari-ECN1",
    server13: "Alpari-Standard2",
    server14: "Alpari-Standard3",
    server15: "Alpari-Standard1",
    server16: "Alpari-Pro.ECN2",
    value: "Alpari INT",
  },
  {
    label: "Alpari Japan",
    server1: "AlpariJapan-Demo-1",
    server2: "AlpariJapan-Live-1",
    server3: "AlpariJapan-Pro",
    value: "Alpari Japan",
  },
  {
    label: "Alpari NZ",
    server1: "Alpari-Customers",
    server2: "Alpari-Classic",
    server3: "Alpari-Contest",
    server4: "Alpari-Demo",
    server5: "Alpari-Micro",
    server6: "Alpari-Micro2",
    server7: "Alpari-Classic2",
    server8: "Alpari-ECN-Demo",
    server9: "Alpari-ECN-Live",
    server10: "Alpari-NDD-Demo",
    server11: "Alpari-Micro3",
    server12: "Alpari-Demo2",
    server13: "Alpari-Standard3",
    server14: "Alpari-Standard1",
    server15: "Alpari-Standard2",
    server16: "Alpari-ECN-New",
    server17: "Alpari-NZ-ECN",
    server18: "Alpari-Nano",
    server19: "Alpari-PRO",
    server20: "Alpari-ECN1",
    server21: "Alpari-NZ-ECN-Pro",
    value: "Alpari NZ",
  },
  {
    label: "Alpari RU",
    server1: "Alpari-Contest",
    server2: "Alpari-Demo",
    server3: "Alpari-ECN-Demo",
    server4: "Alpari-Nano",
    server5: "Alpari-Standard1",
    server6: "Alpari-Standard2",
    server7: "Alpari-Standard3",
    server8: "Alpari-Standard4",
    server9: "Alpari-PRO",
    server10: "Alpari-Pro.ECN-Demo",
    server11: "Alpari-ECN1",
    server12: "Alpari-Pro.ECN",
    server13: "Alpari-Contest",
    server14: "AlpariBroker-Server",
    value: "Alpari RU",
  },
  {
    label: "Alpari US",
    server1: "AlpariUS-Demo",
    server2: "AlpariUS-Live",
    server3: "AlpariUS-Pro",
    server4: "AlpariUS-Pro Demo",
    value: "Alpari US",
  },
  {
    label: "AlpForex",
    server1: "AlpfxCompany-Live",
    server2: "AlpfxCompany-Demo",
    value: "AlpForex",
  },
  {
    label: "Alpha Capital Markets plc",
    server1: "ACM-Demo",
    server2: "ACM-Live",
    server3: "ACM-Live 2",
    value: "Alpha Capital Markets plc",
  },
  {
    label: "Alpha Fortis",
    server1: "Alpha BT Asia",
    server2: "Alpha BT-Live 7",
    server3: "Alpha BT-Live 8",
    value: "Alpha Fortis",
  },
  {
    label: "Alpha Prime FX",
    server1: "AlphaPrime-BT Asia",
    value: "Alpha Prime FX",
  },
  {
    label: "Alpha Tradex",
    server1: "AlphaTradex01-Live",
    server2: "AlphaTradex01-Demo",
    server3: "AlphaTradex2-Live",
    server4: "AlphaTradex2-Demo",
    value: "Alpha Tradex",
  },
  {
    label: "Alpha Trading",
    server1: "AlphaTrading-Live 7",
    value: "Alpha Trading",
  },
  {
    label: "ALPHA TRADING CM",
    server1: "SpotTrader-Real",
    server2: "SpotTrader-Demo",
    value: "ALPHA TRADING CM",
  },
  {
    label: "AlphaBeta FX",
    server1: "AlphaBetaFX-Demo-UK",
    server2: "AlphaBetaFX-Live-UK",
    value: "AlphaBeta FX",
  },
  {
    label: "Alphachain Capital",
    server1: "AlphaGlobalMarkets-Live",
    server2: "AlphaGlobalMarkets-Demo",
    value: "Alphachain Capital",
  },
  {
    label: "Alphafx Markets",
    server1: "AlphaFXMarkets-Live",
    server2: "AlphaFXMarkets-Practice",
    value: "Alphafx Markets",
  },
  {
    label: "ALTFX",
    server1: "AFMarkets-Demo",
    server2: "AFMarkets-Live",
    value: "ALTFX",
  },
  {
    label: "Alvexo",
    server1: "Alvexo-Demo",
    server2: "Alvexo-Real",
    server3: "Alvexo1-Primary Server",
    server4: "Alvexo1-Demo",
    value: "Alvexo",
  },
  {
    label: "AlwaysRichGlobal",
    server1: "AlwaysRichGlobal-Demo",
    server2: "AlwaysRichGlobal-Live",
    value: "AlwaysRichGlobal",
  },
  {
    label: "AM Financials",
    server1: "AMFinancials-Demo",
    server2: "AMFinancials-Real",
    server3: "XMGlobal-Real 18",
    value: "AM Financials",
  },
  {
    label: "AMAFX",
    server1: "AMAFXMarketGlobal-Demo",
    server2: "AMAFXMarketGlobal-Live",
    value: "AMAFX",
  },
  {
    label: "Amana Capital",
    server1: "AmanaCapital-Demo",
    server2: "AmanaCapital-Real",
    server3: "AFSGlobal-Real",
    value: "Amana Capital",
  },
  {
    label: "AMarkets",
    server1: "AMarkets-Real",
    server2: "AMarkets-Demo",
    value: "AMarkets",
  },
  {
    label: "AmazingTick",
    server1: "AmazingTick-Demo01",
    server2: "AmazingTick-Live01",
    server3: "AmazingTick-Real2",
    server4: "AmazingTick-Real3",
    server5: "AmazingTick-Real4",
    server6: "AmazingTick-Real5",
    server7: "AmazingTick-Real6",
    server8: "AmazingTick-Real8",
    server9: "AmazingTick-Real9",
    server10: "AmazingTick-Real10",
    value: "AmazingTick",
  },
  {
    label: "AMB Prime",
    server1: "AMBPRIME-Demo",
    server2: "AMBPRIME-Real",
    value: "AMB Prime",
  },
  {
    label: "AMEGA",
    server1: "Amega-DemoUK",
    server2: "Amega-LiveUK",
    value: "AMEGA",
  },
  {
    label: "Amenda Markets",
    server1: "Amenda-Demo",
    server2: "Amenda-Live2",
    value: "Amenda Markets",
  },
  {
    label: "AMFX",
    server1: "AMFX-Demo",
    server2: "AMFX-Live",
    value: "AMFX",
  },
  {
    label: "AnBer FX",
    server1: "AnBerFx-Demo",
    server2: "AnBerFx-Live",
    value: "AnBer FX",
  },
  {
    label: "Andromex",
    server1: "Andromex-Demo",
    server2: "Andromex-Live2",
    value: "Andromex",
  },
  {
    label: "Angelite",
    server1: "Angelite-Demo",
    server2: "Angelite-Real",
    value: "Angelite",
  },
  {
    label: "Angels Broker",
    server1: "AngelsBroker-Demo",
    server2: "AngelsBroker-Real",
    value: "Angels Broker",
  },
  {
    label: "AnkoFX",
    server1: "ECN-ANKOFX",
    value: "AnkoFX",
  },
  {
    label: "Antos Pinnacles",
    server1: "AntosPinnacles-Demo",
    server2: "AntosPinnacles-Live",
    value: "Antos Pinnacles",
  },
  {
    label: "Anzo Capital",
    server1: "AnzoCapital-Demo",
    server2: "AnzoCapital-Live",
    value: "Anzo Capital",
  },
  {
    label: "Apex Financial Services",
    server1: "Apex-Demo",
    server2: "Apex-Real",
    value: "Apex Financial Services",
  },
  {
    label: "Apex FX Trading",
    server1: "Apex-Real",
    server2: "Apex-Demo",
    server3: "ForexTimeFXTM-Cent",
    value: "Apex FX Trading",
  },
  {
    label: "Appdewise",
    server1: "Appdewise-Live",
    server2: "Appdewise-Demo",
    value: "Appdewise",
  },
  {
    label: "APX Prime",
    server1: "APXPrime-Demo",
    server2: "APXPrime-Live",
    server3: "APXCapital-Demo",
    server4: "APXCapital-Live",
    value: "APX Prime",
  },
  {
    label: "ARA FX",
    server1: "ARAFX-Real",
    server2: "ARAFX-Demo",
    value: "ARA FX",
  },
  {
    label: "Arab Financial Brokers",
    server1: "AFB-Demo",
    server2: "AFB-Server",
    value: "Arab Financial Brokers",
  },
  {
    label: "ArcherCTS",
    server1: "ArcherConsultants-Demo",
    server2: "ArcherConsultants-MetaTrader Live",
    value: "ArcherCTS",
  },
  {
    label: "Arena Trade",
    server1: "ArenaTrading-Demo",
    server2: "ArenaTrading-Live1",
    value: "Arena Trade",
  },
  {
    label: "ArenaFX",
    server1: "ArenaForex-Demo",
    server2: "ArenaForex-Main",
    value: "ArenaFX",
  },
  {
    label: "Argo Forex",
    server1: "ArgoForex-Server",
    server2: "ArgoForex-DemoServer",
    value: "Argo Forex",
  },
  {
    label: "ArgusFX",
    server1: "ArgusFX-Demo",
    server2: "ArgusFX-Live",
    value: "ArgusFX",
  },
  {
    label: "Ariston Tradex",
    server1: "AristonTradex-Demo Server",
    server2: "AristonTradex-Production",
    value: "Ariston Tradex",
  },
  {
    label: "Aristrex",
    server1: "Aristrex-Demo Asia",
    server2: "Aristrex-Live 2",
    value: "Aristrex",
  },
  {
    label: "Armada Markets",
    server1: "Armada-Live",
    server2: "Armada-DemoUK",
    value: "Armada Markets",
  },
  {
    label: "AROTRADE",
    server1: "SpeedSolutions-Trader",
    value: "AROTRADE",
  },
  {
    label: "Arsenal FX",
    server1: "FinArsenal-Demo",
    server2: "FinArsenal-Server",
    value: "Arsenal FX",
  },
  {
    label: "ARUM Capital",
    server1: "ArumCapital-Demo",
    server2: "ArumCapital-Live5",
    value: "ARUM Capital",
  },
  {
    label: "Ascend Market",
    server1: "AscendMarket-Live",
    server2: "AscendMarket-Demo",
    value: "Ascend Market",
  },
  {
    label: "Asco Global",
    server1: "AscoGlobal-Demo",
    server2: "AscoGlobal-Live",
    value: "Asco Global",
  },
  {
    label: "Ascot Prime",
    server1: "AscotPrime-Demo",
    server2: "AscotPrime-Live",
    value: "Ascot Prime",
  },
  {
    label: "AsFX",
    server1: "ASFinancial-Demo",
    server2: "ASFinancial-Live",
    value: "AsFX",
  },
  {
    label: "Ashok FX",
    server1: "AshokFX-Demo",
    server2: "AshokFX-Live",
    value: "Ashok FX",
  },
  {
    label: "Asia Future",
    server1: "AsiaFutureTrading-Live",
    server2: "AsiaFutureTrading-DEMO",
    value: "Asia Future",
  },
  {
    label: "AsiafutureFX",
    server1: "AsiaFutureTrading-DEMO",
    server2: "AsiaFutureTrading-LIVE",
    server3: "AsiaFutureTrading-REAL",
    value: "AsiafutureFX",
  },
  {
    label: "AsiaTradeFX",
    server1: "ATP-Demo",
    server2: "ATP-Live",
    value: "AsiaTradeFX",
  },
  {
    label: "ASK FX",
    server1: "ASKThaiForexMarketplace-Asia Demo",
    server2: "ASKThaiForexMarketplace-Asia 3",
    value: "ASK FX",
  },
  {
    label: "Askap Futures",
    server1: "AsiaBerjangka-Demo",
    server2: "AsiaBerjangka-Real",
    server3: "AsiaBerjangka-Real2",
    value: "Askap Futures",
  },
  {
    label: "Askap Social",
    server1: "AskapFutures-Demo",
    server2: "AskapFutures-Primary",
    value: "Askap Social",
  },
  {
    label: "Askobid",
    server1: "Askobid-Demo",
    server2: "Askobid-Main",
    value: "Askobid",
  },
  {
    label: "ASN-FX",
    server1: "Assassin-DEMO",
    server2: "Assassin-Live",
    server3: "AssassinLimited-Live",
    value: "ASN-FX",
  },
  {
    label: "Aspen",
    server1: "ASPEN-Demo",
    server2: "ASPEN-Live",
    server3: "ASPEN-Real",
    server4: "AspenCap-Live1",
    value: "Aspen",
  },
  {
    label: "AssetsFx",
    server1: "AssetsfxGlobal-Demo",
    server2: "AssetsfxGlobal-Live",
    value: "AssetsFx",
  },
  {
    label: "Asta Forex",
    server1: "AstaForex-Server",
    value: "Asta Forex",
  },
  {
    label: "AT Forex",
    server1: "MXCapitalCorp-Live 2",
    server2: "MXCapitalCorp-Demo",
    value: "AT Forex",
  },
  {
    label: "Ata Foreks",
    server1: "AtaForeks-Demo",
    server2: "AtaForeks-RealATA",
    value: "Ata Foreks",
  },
  {
    label: "Atallia",
    server1: "No servers",
    value: "Atallia",
  },
  {
    label: "ATC Brokers",
    server1: "ATCBrokers-Live 2",
    server2: "ATCBrokers-UK Demo",
    server3: "ATCBrokers-Live 1",
    server4: "ATCBrokers-Demo-Pro-EST",
    server5: "ATCBrokers-Avail Trading Corp",
    server6: "ATCBrokers-Live",
    server7: "ATCBrokers-Demo",
    server8: "209.222.64.2:443",
    server9: "ATCBrokersLiq2-Live",
    server10: "ATCBrokersLiq1-Demo",
    server11: "ATCBrokersLiq2-Demo",
    server12: "ATCBrokers-UK Live",
    server13: "ATCBrokers-US Live",
    value: "ATC Brokers",
  },
  {
    label: "Atech",
    server1: "ATech-Demo",
    server2: "ATech-Live",
    value: "Atech",
  },
  {
    label: "ATFX",
    server1: "ATFXGM1-Demo",
    server2: "ATFXGM1-Live",
    server3: "ATFXGM2-Demo",
    server4: "ATFXGM2-Live",
    server5: "ATFXGM3-Demo",
    server6: "ATFXGM3-Live",
    server7: "ATFXGM4-Demo",
    server8: "ATFXGM4-Live",
    server9: "ATFXGM5-Demo",
    server10: "ATFXGM5-Live",
    server11: "ATFXGM7-Demo",
    server12: "ATFXGM7-Live",
    server13: "ATFXGM8-Live",
    server14: "ATFXGM8-Demo",
    server15: "ATFXGM9-Demo",
    server16: "ATFXGM9-Live",
    value: "ATFX",
  },
  {
    label: "Athens",
    server1: "AthensMarkets-Demo",
    server2: "AthensMarkets-Pro-1",
    value: "Athens",
  },
  {
    label: "ATIG-Forex",
    server1: "ATIG-Srv",
    server2: "ATIG-Demo",
    value: "ATIG-Forex",
  },
  {
    label: "Atiora",
    server1: "Atiora-Demo",
    server2: "Atiora-Live",
    server3: "Atiora-Server",
    value: "Atiora",
  },
  {
    label: "Atlas Capital FX",
    server1: "AtlasCapital-Demo",
    server2: "AtlasCapital-Live",
    value: "Atlas Capital FX",
  },
  {
    label: "Atmos Market",
    server1: "AtmosMarket-demo",
    server2: "AtmosMarket-live",
    value: "Atmos Market",
  },
  {
    label: "Atom8",
    server1: "Atom8-Demo",
    server2: "Atom8-Live",
    server3: "PrimeXM-LiveUK",
    value: "Atom8",
  },
  {
    label: "ATS",
    server1: "AdvancedTradeService-Live-UK-2",
    value: "ATS",
  },
  {
    label: "Audentia Capital",
    server1: "AudentiaCapital-Demo",
    server2: "AudentiaCapital-Live",
    value: "Audentia Capital",
  },
  {
    label: "AUDRN",
    server1: "AUDRN-Demo",
    server2: "AUDRN-Live",
    value: "AUDRN",
  },
  {
    label: "AUGS Markets",
    server1: "AugsMarkets-Demo",
    server2: "AugsMarkets-Server",
    value: "AUGS Markets",
  },
  {
    label: "AURA CAPITAL",
    server1: "HoliwayInvestments-LD Live",
    server2: "HoliwayInvestments-Demo",
    value: "AURA CAPITAL",
  },
  {
    label: "AuraFX",
    server1: "AuraFX-Server",
    server2: "AuraFX-Live-2",
    server3: "AuraFX-Demo",
    server4: "AuraFX-Live",
    value: "AuraFX",
  },
  {
    label: "Aurora-fx",
    server1: "Aurora-Fx-Demo",
    server2: "Aurora-Fx-Real",
    value: "Aurora-fx",
  },
  {
    label: "Ausforex",
    server1: "AUSForex-Demo",
    server2: "AUSForex-Live",
    server3: "AUSForex-Live 2",
    server4: "AUSGlobal-Demo",
    server5: "AUSGlobal-Live",
    server6: "AUSMarkets-Live",
    value: "Ausforex",
  },
  {
    label: "AutoTrade",
    server1: "AutoTrade Demo",
    value: "AutoTrade",
  },
  {
    label: "AutoTrade Demo",
    server1: "No servers",
    value: "AutoTrade Demo",
  },
  {
    label: "Avant-Markets",
    server1: "AvantMarkets-DEMO",
    server2: "AvantMarkets-REAL SERVER",
    value: "Avant-Markets",
  },
  {
    label: "AVANTGARDEFX",
    server1: "PureMGlobal-Demo",
    server2: "PureMGlobal-Live",
    value: "AVANTGARDEFX",
  },
  {
    label: "AvaTrade",
    server1: "Ava-Real 2",
    server2: "AvaTrade-Demo-Floating",
    server3: "Ava-Real 1",
    server4: "Ava-Demo",
    server5: "Ava-Real 3",
    server6: "AvaTrade-Real-Floating",
    server7: "Ava-Real 5",
    server8: "Ava-Real 4",
    server9: "Ava-Demo 2",
    server10: "Ava-Real 6",
    value: "AvaTrade",
  },
  {
    label: "AVFX",
    server1: "SMGlobal-Demo",
    server2: "SMGlobal-Live",
    value: "AVFX",
  },
  {
    label: "Avian Markets",
    server1: "AvianIntMarkets-Demo",
    server2: "AvianIntMarkets-Live-UK",
    value: "Avian Markets",
  },
  {
    label: "AVIVAFX",
    server1: "Avivafx-Demo",
    server2: "Avivafx-Real",
    value: "AVIVAFX",
  },
  {
    label: "AVS Carter",
    server1: "AVSCarter-Server",
    value: "AVS Carter",
  },
  {
    label: "AWEFX",
    server1: "AweSwissGlobalLtd-Demo",
    server2: "AweSwissGlobalLtd-Live",
    value: "AWEFX",
  },
  {
    label: "Axel Private Market Limited",
    server1: "AxelPrivateMarket-Demo",
    server2: "AxelPrivateMarket-Live",
    value: "Axel Private Market Limited",
  },
  {
    label: "Axen Capital",
    server1: "AxenCapital-Demo",
    server2: "AxenCapital-Live",
    value: "Axen Capital",
  },
  {
    label: "Axi",
    server1: "AxisTrader-Demo",
    server2: "AxiTrader-Demo",
    server3: "AxiTrader-Live",
    server4: "AxiTrader-AU01-Demo",
    server5: "AxiTrader-US04-Live",
    server6: "AxiTrader-US01-Demo",
    server7: "Axi-US05-Live",
    server8: "Axi-US03-Demo",
    server9: "Axi-US02-Live",
    server10: "AxiTrader-US02-Demo",
    server11: "Axi-US06-Live",
    server12: "Axi-US09-Live",
    server13: "Axi-US03-Live",
    server14: "Axi-US07-Live",
    server15: "Axi-US888-Live",
    server16: "AxiTrader-US05-Live",
    server17: "AxiTrader-US888-Demo",
    server18: "AxiTrader-US10-Live",
    server19: "AxiTraderSVG-US12-Live",
    server20: "Axi-US12-Live",
    server21: "Axi-US16-Live",
    value: "Axi",
  },
  {
    label: "Axiance",
    server1: "AxianceSC-Real1",
    server2: "AxianceSC-Demo1",
    value: "Axiance",
  },
  {
    label: "Aximtrade",
    server1: "TharaHeights-demo",
    server2: "TharaHeights-Server",
    server3: "AximTrade-Live",
    server4: "AximTrade-Demo",
    server5: "AximTrade2-Live",
    server6: "AximTrade2-Demo",
    server7: "AximTrade2-Live2",
    server8: "AximTrade2-Live3",
    value: "Aximtrade",
  },
  {
    label: "Axion Trade",
    server1: "AxionTrade-Demo",
    server2: "AxionTrade-Live",
    value: "Axion Trade",
  },
  {
    label: "Axiory",
    server1: "Axiory-Demo",
    server2: "Axiory-Live",
    server3: "Axiory-Demo 1",
    server4: "Axiory-Live2",
    server5: "Axiory-Live 6",
    server6: "Axiory-Live 4",
    server7: "AxioryAsia-01Live",
    server8: "AxioryEuro-01Demo",
    server9: "AxioryEuro-03Live",
    server10: "AxioryAsia-02Live",
    server11: "AxioryAsia-02Demo",
    server12: "AxioryAsia03-Live",
    server13: "AxioryAsia-01Demo",
    server14: "AxioryAsia-04Live",
    server15: "AxioryAsia-05Live",
    server16: "AxioryAsia-06Live",
    value: "Axiory",
  },
  {
    label: "Axiory Europe",
    server1: "AxioryEuro-01Live",
    server2: "AxioryEuro-02Demo",
    server3: "AxioryEuro-02Live",
    server4: "AxioryEuro-04Live",
    value: "Axiory Europe",
  },
  {
    label: "AXISCAPITAL",
    server1: "AxisCapital-AsiaPac Demo",
    server2: "AxisCapital-AsiaPac Live",
    value: "AXISCAPITAL",
  },
  {
    label: "AxisForex",
    server1: "AxisForex-MT4 Demo",
    server2: "AxisForex-MT4 Live",
    value: "AxisForex",
  },
  {
    label: "AxisODL",
    server1: "AxisTrader-Demo",
    server2: "AxisTrader-Server",
    server3: "AxisTrader-Live",
    value: "AxisODL",
  },
  {
    label: "AXO Capital",
    server1: "AxoCapitalFutures-Demo",
    server2: "AxoCapitalFutures-Live",
    value: "AXO Capital",
  },
  {
    label: "AZ Capital",
    server1: "AZCapital-Demo",
    server2: "AZCapital-Live",
    value: "AZ Capital",
  },
  {
    label: "AzenFx",
    server1: "AzenFx-Demo",
    server2: "AzenFx-Live",
    value: "AzenFx",
  },
  {
    label: "Azura Markets",
    server1: "AzuraMarkets-Demo",
    server2: "AzuraMarkets-Real2",
    value: "Azura Markets",
  },
  {
    label: "Azure Xchange",
    server1: "AzureXchange-Demo",
    server2: "AzureXchange-live1",
    value: "Azure Xchange",
  },
  {
    label: "B Capitals FX",
    server1: "BCAPITALS-Demo",
    server2: "BCAPITALS-Live",
    value: "B Capitals FX",
  },
  {
    label: "B.I.C. Markets",
    server1: "B.I.C.Markets-Demo",
    server2: "B.I.C.Markets-Live",
    value: "B.I.C. Markets",
  },
  {
    label: "BA CAPITAL group",
    server1: "BACapitalGroup-Live",
    server2: "BACapitalGroup-Demo",
    value: "BA CAPITAL group",
  },
  {
    label: "Ballistic Markets",
    server1: "BallisticMarkets-Live",
    server2: "BallisticMarkets-Demo",
    value: "Ballistic Markets",
  },
  {
    label: "BankBB",
    server1: "BankBB-Demo",
    server2: "BankBB-Real",
    value: "BankBB",
  },
  {
    label: "BankDirectFX",
    server1: "BDFX-Live",
    server2: "BDFX-Demo",
    value: "BankDirectFX",
  },
  {
    label: "Basel Financial",
    server1: "BaselFinancial-Server",
    value: "Basel Financial",
  },
  {
    label: "Basis",
    server1: "RaptorTrade-Live",
    server2: "RaptorTrade-Demo",
    server3: "live.basiscap.com",
    value: "Basis",
  },
  {
    label: "Baskent FX",
    server1: "BaskentFX-Demo",
    server2: "BaskentFX-Srv",
    value: "Baskent FX",
  },
  {
    label: "BBI Trading",
    server1: "BBITradingMarkets-Demo",
    server2: "BBITradingMarkets-Live",
    value: "BBI Trading",
  },
  {
    label: "BCFX Broker",
    server1: "BestChoiceFBC-Demo",
    server2: "BestChoiceFBC-Live",
    server3: "FinFx-Live",
    value: "BCFX Broker",
  },
  {
    label: "BCR",
    server1: "BCRCo-DEMO",
    server2: "BCRCo-REAL",
    server3: "BCR-DEMO",
    server4: "BCR-REAL",
    value: "BCR",
  },
  {
    label: "BCS Forex",
    server1: "BCS-Demo",
    server2: "BCS-Real",
    value: "BCS Forex",
  },
  {
    label: "BDSwiss",
    server1: "BDSwissGlobal-Real01",
    server2: "BDSwissGlobal-Demo01",
    server3: "BDSwissGlobal-Real02",
    server4: "BDSSwissMarkets-Real02",
    server5: "BDSwissGlobal-Real03",
    server6: "BDSSwissMarkets-Real03",
    server7: "BDSwiss-Demo01",
    server8: "BDSwiss-Real01",
    server9: "BDSSwissMarkets-Real01",
    server10: "Activtrades-5",
    server11: "BDSwissSC-Real01",
    server12: "BDSwissSC-Real02",
    server13: "BDSwissSC-Real03",
    server14: "BDSwissGlobal-Real04",
    server15: "BDSwissSC-Real04",
    server16: "BDSwissGlobal-Real05",
    server17: "BDSSwissMarkets-Demo01",
    server18: "BDSwissSC-Demo01",
    server19: "BDSwissSC-Real05",
    value: "BDSwiss",
  },
  {
    label: "Bealgo",
    server1: "BAXHoldings-Demo",
    server2: "BAXHoldings-Live",
    value: "Bealgo",
  },
  {
    label: "BeamFX",
    server1: "BeamFX-Demo 3",
    server2: "BeamFX-Live",
    value: "BeamFX",
  },
  {
    label: "BECFD",
    server1: "BECFD-Trader",
    value: "BECFD",
  },
  {
    label: "Belarta",
    server1: "BELARTA-Demo",
    server2: "BELARTA-Live2",
    value: "Belarta",
  },
  {
    label: "Beleggen24",
    server1: "Beleggen24-DemoUK",
    server2: "Beleggen24-LiveUK",
    value: "Beleggen24",
  },
  {
    label: "BelforFX",
    server1: "BelforFX-Demo",
    server2: "BelforFX-Main",
    value: "BelforFX",
  },
  {
    label: "BelFx",
    server1: "OLOplatforms-Demo",
    server2: "OLOplatforms-Real",
    value: "BelFx",
  },
  {
    label: "Bellerophon",
    server1: "Bellerophon-Demo",
    server2: "Bellerophon-Live",
    value: "Bellerophon",
  },
  {
    label: "BenchMark Finance",
    server1: "BenchMark-Demo",
    server2: "BenchMark-Real",
    value: "BenchMark Finance",
  },
  {
    label: "Beneforex",
    server1: "BenefitZoneFX-DEMO",
    server2: "BenefitZoneFX-Live",
    value: "Beneforex",
  },
  {
    label: "Berndale Capital",
    server1: "BerndaleCapital-DemoUK",
    server2: "BerndaleCapital-Live",
    server3: "BerndaleCapitalSecurities-Live",
    value: "Berndale Capital",
  },
  {
    label: "Bernstein Bank",
    server1: "Bernstein-Bank-Live",
    server2: "Bernstein-Bank-Demo",
    value: "Bernstein Bank",
  },
  {
    label: "Berry Markets",
    server1: "BerryfinInternational-DEMO",
    server2: "BerryfinInternational-Main",
    server3: "BerryMarketsLtd-Demo",
    server4: "BerryMarketsLtd-Real",
    value: "Berry Markets",
  },
  {
    label: "BES",
    server1: "bes-dem",
    server2: "bes-ecn",
    server3: "bes-cfd",
    value: "BES",
  },
  {
    label: "Bess",
    server1: "BessTechnology-Live",
    value: "Bess",
  },
  {
    label: "Best Leader",
    server1: "BestLeader-MT4 Demo Server",
    server2: "BestLeader-MT4 Live Server",
    value: "Best Leader",
  },
  {
    label: "BestecNFX",
    server1: "Bestec-Live",
    server2: "Bestec-Demo",
    value: "BestecNFX",
  },
  {
    label: "BestSpreadFX",
    server1: "PHP-LiveLiquidity1",
    server2: "PHP-Demo",
    value: "BestSpreadFX",
  },
  {
    label: "Betafx245",
    server1: "Betafx245-Live",
    server2: "Betafx245-Demo",
    value: "Betafx245",
  },
  {
    label: "Betal Trade",
    server1: "BetalTrade-Live",
    server2: "BetalTrade-Practice",
    value: "Betal Trade",
  },
  {
    label: "BethleAsterFX",
    server1: "BethleAsterFX-Demo Server",
    server2: "BethleAsterFX-Live Server",
    value: "BethleAsterFX",
  },
  {
    label: "Beyond",
    server1: "Beyond-Trading",
    value: "Beyond",
  },
  {
    label: "BFB Capital",
    server1: "BFBCapital-MT4 Demo",
    server2: "BFBCapital-Real4",
    value: "BFB Capital",
  },
  {
    label: "BFM",
    server1: "BerryFin-Demo",
    server2: "BerryFin-Live",
    value: "BFM",
  },
  {
    label: "Bforex",
    server1: "Bforex-TNDemo",
    server2: "Bforex-TNLive",
    value: "Bforex",
  },
  {
    label: "BFSforex",
    server1: "BFS-Demo",
    server2: "BFS-Live",
    value: "BFSforex",
  },
  {
    label: "BFX",
    server1: "BearmanVentures-Demo",
    server2: "BearmanVentures-Real",
    value: "BFX",
  },
  {
    label: "Bigboss",
    server1: "BIGSolutions-DEMO2",
    server2: "BIGBOSS-DEMO",
    server3: "BIGBOSS-LIVE",
    server4: "BIGBOSSHoldings-LIVE2",
    server5: "BIGBOSSHOLDINGSCL-LIVE3",
    server6: "BIGBOSSHOLDINGSCL-LIVE4",
    server7: "BIGBOSSHoldings-DEMO",
    server8: "BIGBOSSHoldings-LIVE",
    server9: "BIGSolutions-LIVE3",
    server10: "BIGSolutions-LIVE4",
    server11: "BIGSolutions-LIVE5",
    value: "Bigboss",
  },
  {
    label: "Billion Capitals",
    server1: "TradeFXPlatform-Demo",
    server2: "TradeFXPlatform-Live",
    value: "Billion Capitals",
  },
  {
    label: "Billionforex",
    server1: "Billionforex-Live 7",
    server2: "Billionforex-Live 5",
    value: "Billionforex",
  },
  {
    label: "BITPoint FX",
    server1: "BITPoint-FX Demo",
    server2: "BITPoint-FX Live",
    value: "BITPoint FX",
  },
  {
    label: "BizTradePro",
    server1: "No servers",
    value: "BizTradePro",
  },
  {
    label: "BKFX",
    server1: "BKFX-Demo",
    server2: "BKFX-Live",
    value: "BKFX",
  },
  {
    label: "Black Moon Trade",
    server1: "BlackMoonTrade-Demo",
    server2: "BlackMoonTrade-LiveLiquidity1",
    value: "Black Moon Trade",
  },
  {
    label: "BlackBird Clear",
    server1: "MT4-BBEURO",
    value: "BlackBird Clear",
  },
  {
    label: "BlackBull Markets",
    server1: "BlackBullMarkets-Demo",
    server2: "BlackBullMarkets-Live",
    server3: "BlackBullMarkets-Live 2",
    server4: "BlackBullMarkets-Demo",
    value: "BlackBull Markets",
  },
  {
    label: "BlackPearlFX",
    server1: "BlackPearlFX-Live",
    server2: "BlackPearlFX-Demo",
    value: "BlackPearlFX",
  },
  {
    label: "BLACKRIDGE",
    server1: "BlackridgeCapital-Live1",
    server2: "BlackridgeCapital-DemoUK",
    server3: "BlackridgeCapital-LiveUK",
    value: "BLACKRIDGE",
  },
  {
    label: "BlackStone",
    server1: "BlackStoneCapital-Demo",
    server2: "BlackStoneCapital-Live",
    server3: "BlackstoneFutures-LiveBravo",
    server4: "BlackstoneFutures-DemoBravo",
    value: "BlackStone",
  },
  {
    label: "Blackwell Global Investment",
    server1: "BlackwellGlobal-Demo",
    server2: "BlackwellGlobal-Live",
    server3: "BlackwellGlobalCYLive",
    server4: "BlackwellGlobalCYDemo",
    server5: "BlackwellGlobal-Live2",
    server6: "BlackwellGlobalCY-Live2",
    server7: "BlackwellGlobal1-Live3",
    server8: "BlackwellGlobal1-Demo3",
    server9: "BlackwellGlobal2-Live3",
    server10: "BlackwellGlobal2-Demo3",
    server11: "BlackwellGlobal1-Live5",
    server12: "BlackwellGlobal1-Demo5",
    value: "Blackwell Global Investment",
  },
  {
    label: "Blaze Markets",
    server1: "BlazeMarkets-DEMO",
    server2: "BlazeMarkets-LIVE",
    value: "Blaze Markets",
  },
  {
    label: "Blitz Brokers",
    server1: "BlitzBrokers-Live",
    value: "Blitz Brokers",
  },
  {
    label: "Blue Isle FX",
    server1: "BlueIsle-Demo",
    server2: "BlueIsle-Live",
    value: "Blue Isle FX",
  },
  {
    label: "Blueberry Markets",
    server1: "BlueberryMarkets-Demo",
    server2: "BlueberryMarkets-Live",
    server3: "BlueberryMarkets2-Real2",
    server4: "BlueberryMarkets2-Demo2",
    value: "Blueberry Markets",
  },
  {
    label: "BlueMax Capital",
    server1: "BlueMax-Demo",
    server2: "BlueMax-Live",
    value: "BlueMax Capital",
  },
  {
    label: "BlueStarFX",
    server1: "BlueStarFX-Live",
    server2: "BlueStarFX-Live-2",
    server3: "BlueStarFX-Live-3",
    server4: "BlueStarFX-Practice",
    value: "BlueStarFX",
  },
  {
    label: "BlueSuisse",
    server1: "BlueSuisse-Real",
    server2: "BlueSuisse-Demo",
    value: "BlueSuisse",
  },
  {
    label: "BMFN",
    server1: "BMFN-DemoCFD",
    server2: "BMFN-DemoFX",
    server3: "BMFN-DMA",
    server4: "BMFN-DMADemo",
    server5: "BMFN-Institutional",
    server6: "BMFN-RealCFD",
    server7: "BMFN-RealFX",
    value: "BMFN",
  },
  {
    label: "BMH",
    server1: "BetaMGM-Server",
    value: "BMH",
  },
  {
    label: "BML",
    server1: "BML-Demo",
    server2: "BML-Live",
    value: "BML",
  },
  {
    label: "BocaFX",
    server1: "BocaFX-Demo",
    server2: "BocaFX-Main",
    value: "BocaFX",
  },
  {
    label: "Bold Prime",
    server1: "BoldPrime-Demo",
    server2: "BoldPrime-Live",
    value: "Bold Prime",
  },
  {
    label: "Bondex",
    server1: "BondexGlobal-Live(STP)",
    server2: "BondexGlobal-Demo(STP)",
    value: "Bondex",
  },
  {
    label: "BOSSAFX",
    server1: "BossaFX-Demo",
    server2: "BossaFX-Real",
    value: "BOSSAFX",
  },
  {
    label: "Boston Brokerage Group",
    server1: "BBG-Boston MA Demo",
    server2: "BBG-Boston MA",
    value: "Boston Brokerage Group",
  },
  {
    label: "Boston Merchant Financial",
    server1: "BMFN-Demo",
    server2: "BMFN-Real",
    server3: "BMFN-RealCFD",
    server4: "BMFN-DemoCFD",
    server5: "BMFN-DMA",
    server6: "BMFN-DMADemo",
    server7: "BMFN-RealFX",
    value: "Boston Merchant Financial",
  },
  {
    label: "Boston Prime",
    server1: "LiqCon2-Demo 3",
    server2: "LiqCon-Live",
    server3: "LiqCon-Live2",
    server4: "LiqCon2-Live",
    server5: "LiqCon2-Live 5",
    server6: "LiqCon2-BT Japan",
    server7: "BostonPrime-Live 7",
    server8: "LiqCon2-ClearSpan",
    server9: "LiqCon2-Live 8",
    server10: "BostonPrime-Live 8",
    value: "Boston Prime",
  },
  {
    label: "Boston Technologies",
    server1: "BostonTechnologies-Live 7",
    server2: "BostonTechnologies-Live",
    value: "Boston Technologies",
  },
  {
    label: "BOTFX",
    server1: "BOTFX-DemoUS",
    server2: "BOTFX-LiveUS",
    value: "BOTFX",
  },
  {
    label: "BP Global Finance",
    server1: "BPGLOBAL-Demo",
    server2: "BPGLOBAL-Live2",
    value: "BP Global Finance",
  },
  {
    label: "Bp Prime",
    server1: "BlackPearlFX-Demo",
    server2: "BlackPearlFX-Live",
    value: "Bp Prime",
  },
  {
    label: "BPS Capital",
    server1: "BPSCapital-Main",
    server2: "BPSCapital-Demo",
    server3: "BPSCapital2-Demo",
    server4: "BPSCapital2-Live",
    server5: "BPSCapital3-Real 3",
    server6: "bpscapital-REAL",
    value: "BPS Capital",
  },
  {
    label: "BreForex ECN",
    server1: "DIBRE-Demo",
    server2: "DIBRE-REAL",
    value: "BreForex ECN",
  },
  {
    label: "Brickhill Capital",
    server1: "Brickhill-Demo",
    server2: "Brickhill-Live",
    value: "Brickhill Capital",
  },
  {
    label: "BrickLion",
    server1: "BrickLion-Demo",
    server2: "BrickLion-Real",
    value: "BrickLion",
  },
  {
    label: "BrightWin",
    server1: "BrightWin-Demo",
    server2: "BrightWin-Primary",
    value: "BrightWin",
  },
  {
    label: "Brilliant One Forex",
    server1: "BrilliantOneForex-Demo",
    server2: "BrilliantOneForex-Live",
    value: "Brilliant One Forex",
  },
  {
    label: "Brisk Liquidity",
    server1: "BriskLiquidity-Demo",
    server2: "BriskLiquidity-Live",
    value: "Brisk Liquidity",
  },
  {
    label: "Briton Markets",
    server1: "BritonMarkets-Live",
    server2: "BritonMarkets-Demo",
    value: "Briton Markets",
  },
  {
    label: "BroCo",
    server1: "BroCo-San-Francisco",
    server2: "BroCo-Demo",
    server3: "BroCo-Real",
    server4: "BroCo-Classic",
    server5: "BroCoInvestments-Scalper-Mini",
    server6: "BroCoInvestments-Contest",
    value: "BroCo",
  },
  {
    label: "Broker Capital",
    server1: "BrokerCapital-Live04",
    server2: "BrokerCapital-Practice",
    value: "Broker Capital",
  },
  {
    label: "Brokers Star",
    server1: "BrokersStar-DemoUS",
    server2: "BrokersStar-LiveUS",
    server3: "LiqCon2-Live 7",
    server4: "BrokersStar-Live 8",
    value: "Brokers Star",
  },
  {
    label: "Brokers500",
    server1: "Brokers500-Demo",
    server2: "Brokers500-Real",
    value: "Brokers500",
  },
  {
    label: "Brokerz",
    server1: "BrokerzLtd-Demo",
    server2: "BrokerzLtd-Live",
    value: "Brokerz",
  },
  {
    label: "BTC-e",
    server1: "BTC-eExchange-Real1",
    server2: "BTC-eExchange-ECN Demo Server",
    value: "BTC-e",
  },
  {
    label: "BTFT Markets",
    server1: "BTFTMarkets-DEMO",
    server2: "BTFTMarkets-REAL",
    value: "BTFT Markets",
  },
  {
    label: "BTFX",
    server1: "Brokersclub-Demo",
    server2: "Brokersclub-Live",
    server3: "BTFX-NY Live",
    server4: "BTFX-LD Live",
    value: "BTFX",
  },
  {
    label: "BTGForex",
    server1: "BTGForex-Gold-i Live1",
    value: "BTGForex",
  },
  {
    label: "Btrader",
    server1: "Btrader-Real",
    server2: "Btrader-Server",
    value: "Btrader",
  },
  {
    label: "Budacash",
    server1: "BudaCash-MT4-ID01",
    server2: "BudaCash-MT4-IM01",
    server3: "BudaCash-MT4-IS01",
    server4: "BudaForex-Live 7",
    server5: "BudaForex-Demo 5",
    value: "Budacash",
  },
  {
    label: "Bulbrokers",
    server1: "STS-Demo",
    server2: "STS-Real",
    value: "Bulbrokers",
  },
  {
    label: "Bull Sphere",
    server1: "BullSphereLimited-Demo-UK",
    server2: "BullSphereLimited-Live-UK",
    server3: "BullSphereLimited-Live-UK-3",
    value: "Bull Sphere",
  },
  {
    label: "BullBell",
    server1: "BullBell-Demo",
    server2: "BullBell-Live",
    value: "BullBell",
  },
  {
    label: "BullishFX",
    server1: "OvalInc-Live",
    value: "BullishFX",
  },
  {
    label: "BullMFX",
    server1: "BullM-Demo",
    server2: "BullM-Real",
    value: "BullMFX",
  },
  {
    label: "Bulls Capital Markets",
    server1: "BullsCapital-Demo",
    server2: "BullsCapital-Live",
    value: "Bulls Capital Markets",
  },
  {
    label: "BURGANFX",
    server1: "BurganFX-Demo",
    server2: "BurganFX-Real",
    value: "BURGANFX",
  },
  {
    label: "BVIFX",
    server1: "MaxrichGroup-Demo",
    server2: "MaxrichGroup-Real",
    value: "BVIFX",
  },
  {
    label: "BWBFX",
    server1: "BWBInternational-Live",
    server2: "BWBInternational-Demo",
    value: "BWBFX",
  },
  {
    label: "Bwin Brokers",
    server1: "BwinGMFCF-Live",
    value: "Bwin Brokers",
  },
  {
    label: "Bybit",
    server1: "BybitGlobal-Asia",
    server2: "BybitGlobal-Demo",
    value: "Bybit",
  },
  {
    label: "BYFX Global",
    server1: "BYFXGlobal-Demo",
    server2: "BYFXGlobal-Real",
    value: "BYFX Global",
  },
  {
    label: "C 7 Traders",
    server1: "C7TradersClub-Capital7",
    value: "C 7 Traders",
  },
  {
    label: "Cabana Capital",
    server1: "CabanaCapitals-DEMO",
    server2: "CabanaCapitals-LIVE",
    value: "Cabana Capital",
  },
  {
    label: "CaesarTrade",
    server1: "CTFC-Demo",
    server2: "CTFC-Real",
    value: "CaesarTrade",
  },
  {
    label: "Cakra Group Limited",
    server1: "CakraGroupLtd-Asia 2",
    value: "Cakra Group Limited",
  },
  {
    label: "Camseas",
    server1: "Camseas-Demo",
    server2: "Camseas-Live",
    value: "Camseas",
  },
  {
    label: "Cantor Fitzgerald",
    server1: "Cantor-Demo",
    server2: "Cantor-Live",
    value: "Cantor Fitzgerald",
  },
  {
    label: "Capital City Markets",
    server1: "CapitalCityMarkets-Live",
    server2: "CapitalCityMarkets-Demo",
    value: "Capital City Markets",
  },
  {
    label: "Capital Coin Exchange",
    server1: "DirectCoin-Demo",
    server2: "DirectCoin-Live",
    value: "Capital Coin Exchange",
  },
  {
    label: "Capital Com",
    server1: "CapitalComBY-Real",
    server2: "CapitalComBY-Demo",
    value: "Capital Com",
  },
  {
    label: "Capital Gate",
    server1: "CapitalGateLtd-Demo",
    server2: "CapitalGateLtd-Real",
    value: "Capital Gate",
  },
  {
    label: "Capital Hall",
    server1: "CapitalTech-Live",
    server2: "CapitalTech-Demo",
    value: "Capital Hall",
  },
  {
    label: "Capital Investment Brokers",
    server1: "CIB-Brokers Server",
    value: "Capital Investment Brokers",
  },
  {
    label: "Capital One Forex",
    server1: "COFX-Real",
    value: "Capital One Forex",
  },
  {
    label: "Capital Trade FX",
    server1: "No servers",
    value: "Capital Trade FX",
  },
  {
    label: "Capital Trust",
    server1: "CapitalTrust-Demo",
    server2: "CapitalTrust-Live",
    value: "Capital Trust",
  },
  {
    label: "Capital Trust Markets",
    server1: "CapitalTrustMarkets-Demo",
    server2: "CapitalTrustMarkets-Real",
    value: "Capital Trust Markets",
  },
  {
    label: "Capital Way Group",
    server1: "EastridgeCapital-Demo",
    server2: "EastridgeCapital-Live",
    value: "Capital Way Group",
  },
  {
    label: "Capital.com",
    server1: "Capital.com-Demo",
    server2: "Capital.com-Real",
    value: "Capital.com",
  },
  {
    label: "CapitalFX",
    server1: "CapitalFX-Server",
    value: "CapitalFX",
  },
  {
    label: "CapitalIndex",
    server1: "CapitalIndex-Demo",
    server2: "CapitalIndex-LD4",
    server3: "CapitalIndex-Live",
    server4: "CapitalIndexUK-Live",
    server5: "CapitalIndexUK-Demo",
    server6: "CapitalIndexGlobal-Live",
    server7: "CapitalIndexGlobal-Demo",
    value: "CapitalIndex",
  },
  {
    label: "CapitalOneForex",
    server1: "COFX-Demo",
    server2: "COFX-Server",
    value: "CapitalOneForex",
  },
  {
    label: "CapitAlpha",
    server1: "AJFFinancialServices-Demo",
    server2: "AJFFinancialServices-Live",
    value: "CapitAlpha",
  },
  {
    label: "CapitalXtend",
    server1: "CapitalXtend-Demo",
    server2: "CapitalXtend-Real",
    value: "CapitalXtend",
  },
  {
    label: "CapXM",
    server1: "CapXM-Demo",
    server2: "CapXM-Real",
    value: "CapXM",
  },
  {
    label: "Carbon Capital",
    server1: "CarbonCapital-Real",
    server2: "CarbonCapital-Demo",
    value: "Carbon Capital",
  },
  {
    label: "Cardiff",
    server1: "CardiffGlobalMarkets-Demo",
    server2: "CardiffGlobalMarkets-Live",
    value: "Cardiff",
  },
  {
    label: "Carrax",
    server1: "CARRAX-Server",
    server2: "CARRAX-demo",
    value: "Carrax",
  },
  {
    label: "CarryFX",
    server1: "CarryFX-Demo",
    server2: "CarryFX-Live",
    value: "CarryFX",
  },
  {
    label: "Castle Rock FX",
    server1: "CastleRockFX-Demo",
    server2: "CastleRockFX-Live",
    value: "Castle Rock FX",
  },
  {
    label: "Caveo",
    server1: "Caveo-A1 Demo",
    server2: "Caveo-A1 Live",
    server3: "Caveo-Demo",
    value: "Caveo",
  },
  {
    label: "Cawada Investment",
    server1: "CawadaInvestment-Demo",
    server2: "CawadaInvestment-Live",
    value: "Cawada Investment",
  },
  {
    label: "Caxton Global",
    server1: "CaxtonGlobal-Demo",
    server2: "CaxtonGlobal-Live",
    value: "Caxton Global",
  },
  {
    label: "CB Technologies",
    server1: "CBTLimited-Trading",
    value: "CB Technologies",
  },
  {
    label: "CBSFX",
    server1: "CBSFOREX-CBSFX",
    server2: "CBSFOREX-Main",
    value: "CBSFX",
  },
  {
    label: "CCF",
    server1: "CCF-Demo",
    server2: "CCF-Live",
    value: "CCF",
  },
  {
    label: "CCFX",
    server1: "No servers",
    value: "CCFX",
  },
  {
    label: "CDG Global",
    server1: "CDGGlobal-Live",
    server2: "CDGGlobal-Demo",
    value: "CDG Global",
  },
  {
    label: "CDO Markets",
    server1: "CDOMarkets-Live 2",
    server2: "CDOMarkets-Demo 2",
    value: "CDO Markets",
  },
  {
    label: "CedarFX",
    server1: "CedarLLC-Demo2",
    server2: "CedarLLC-Real2",
    value: "CedarFX",
  },
  {
    label: "CEMX",
    server1: "CemxInternational-Demo",
    server2: "CemxInternational-Primary",
    value: "CEMX",
  },
  {
    label: "Central Capital Futures",
    server1: "CentralCapitalFutures-Demo",
    server2: "CentralCapitalFutures-Live",
    value: "Central Capital Futures",
  },
  {
    label: "CentroFX",
    server1: "No servers",
    value: "CentroFX",
  },
  {
    label: "Century BO",
    server1: "CenturyBinaryOption-Demo",
    server2: "CenturyBinaryOption-Live",
    value: "Century BO",
  },
  {
    label: "Century Investment Futures",
    server1: "CIF-Demo",
    server2: "CIF-Live",
    value: "Century Investment Futures",
  },
  {
    label: "CEO Trading",
    server1: "CEO-Demo",
    server2: "CEO-Live",
    value: "CEO Trading",
  },
  {
    label: "Cerdas Indonesia Berjangka",
    server1: "CerdasIndonesiaBerjangka2-Demo",
    server2: "CerdasIndonesiaBerjangka2-Live",
    value: "Cerdas Indonesia Berjangka",
  },
  {
    label: "CES",
    server1: "CESFX-Demo",
    server2: "CESFX-Live",
    value: "CES",
  },
  {
    label: "CESFX",
    server1: "CESFX-Live",
    server2: "CESFX-Demo",
    value: "CESFX",
  },
  {
    label: "CFD Galaxy",
    server1: "TFB-Demo",
    server2: "TFB-Live",
    value: "CFD Galaxy",
  },
  {
    label: "CFD1000",
    server1: "CFD1000-Demo",
    server2: "CFD1000-Main",
    value: "CFD1000",
  },
  {
    label: "CFDTimes",
    server1: "CFDTimes-Demo",
    server2: "CFDTimes-Live-UK",
    value: "CFDTimes",
  },
  {
    label: "CFH Clearing",
    server1: "CFHMarkets-Demo",
    server2: "CFHMarkets-Real",
    server3: "CFHMarkets-Live1",
    server4: "CFHClearing-Live1",
    server5: "CFHClearing-Demo",
    server6: "itexsys-Live",
    server7: "itexsys-Demo",
    value: "CFH Clearing",
  },
  {
    label: "CFI Financial Group",
    server1: "CFITrader-Real",
    server2: "CFITrader-Demo",
    value: "CFI Financial Group",
  },
  {
    label: "CFI Markets",
    server1: "CFIMarkets-Demo",
    server2: "CFIMarkets-Real2",
    server3: "CFIM-Server",
    value: "CFI Markets",
  },
  {
    label: "CFMerchants",
    server1: "CFMerchants-Demo",
    server2: "CFMerchants-Live",
    value: "CFMerchants",
  },
  {
    label: "CFX International",
    server1: "CFXInternational-Live 7",
    server2: "CFXInternational-Demo Server",
    value: "CFX International",
  },
  {
    label: "CFX Markets",
    server1: "CFXMarkets-Real",
    server2: "CFXMarkets-Demo",
    value: "CFX Markets",
  },
  {
    label: "CFXI",
    server1: "CFXI-Demo",
    server2: "CFXI-Real",
    server3: "AFX-Real",
    server4: "CFXInternational-Live 7",
    value: "CFXI",
  },
  {
    label: "CGIWorld",
    server1: "CGIWorldInternational-Demo",
    server2: "CGIWorldInternational-Live",
    value: "CGIWorld",
  },
  {
    label: "CGS CIMB",
    server1: "CGSCIMBSecurities-Live-UK",
    server2: "CGSCIMBSecurities-Demo-UK",
    value: "CGS CIMB",
  },
  {
    label: "CGtrade",
    server1: "CGTRADE-Demo",
    server2: "CGTRADE-Live",
    value: "CGtrade",
  },
  {
    label: "Champ Forex Traders",
    server1: "ChampTrader-Demo2",
    server2: "ChampTrader-Live2",
    value: "Champ Forex Traders",
  },
  {
    label: "Charging Bull",
    server1: "ChargingBullInv-Demo",
    server2: "ChargingBullInv-Live",
    value: "Charging Bull",
  },
  {
    label: "CharlesFX",
    server1: "CharlesFX-Demo",
    server2: "CharlesFX-FX-CFD Live",
    server3: "CharlesFX-Standard",
    value: "CharlesFX",
  },
  {
    label: "Charterprime",
    server1: "Charterprime-Demo",
    server2: "Charterprime-Live",
    server3: "CharterprimeFX-Live",
    server4: "CharterprimeAU-Demo",
    server5: "CharterprimeAU-Live",
    value: "Charterprime",
  },
  {
    label: "ChaseTradeFX",
    server1: "Chase-Live 5",
    server2: "Chase-Live 7",
    server3: "LiqCon2-Live 5",
    value: "ChaseTradeFX",
  },
  {
    label: "Choice-FX",
    server1: "ChoicesTech-Demo",
    server2: "ChoicesTech-Live",
    value: "Choice-FX",
  },
  {
    label: "CIBfx",
    server1: "CIB-Brokers Server",
    value: "CIBfx",
  },
  {
    label: "Circle Markets",
    server1: "FTT-Live2",
    server2: "FTT-Demo",
    value: "Circle Markets",
  },
  {
    label: "Citiex",
    server1: "CitiexLtd-Demo",
    server2: "CitiexLtd-Live",
    value: "Citiex",
  },
  {
    label: "CitiFX Pro",
    server1: "CitiFX-Demo2",
    server2: "CitiFX-Live",
    server3: "CitiFxPro-Live",
    server4: "CitiFxPro-CitiFXPro Demo",
    value: "CitiFX Pro",
  },
  {
    label: "City Credit Capital",
    server1: "CCCUK-Demo",
    server2: "CCCUK-Live",
    server3: "CityCreditCapitalKY-Live",
    value: "City Credit Capital",
  },
  {
    label: "City Wealth",
    server1: "CityWealth-Demo",
    server2: "CityWealth-Live",
    value: "City Wealth",
  },
  {
    label: "CityIndex",
    server1: "CityIndexAU-Live 101",
    server2: "CityIndexUK-Live 102",
    server3: "CityIndexSG-Live 107",
    server4: "CityIndex-Demo",
    server5: "CityIndex-Live",
    server6: "CityIndexUK-Demo 106",
    server7: "CityIndexAU-Demo 106",
    server8: "CityIndexAU-Live 101",
    value: "CityIndex",
  },
  {
    label: "CJC Markets",
    server1: "CJCMarketsCo-RS-S4-Demo",
    server2: "CJCMarketsCo-RS-S4-Main",
    server3: "CJCMarkets-Demo",
    server4: "CJCMarkets-Real",
    value: "CJC Markets",
  },
  {
    label: "CK Markets",
    server1: "CKMarkets-Live",
    server2: "CKMarkets-Demo",
    value: "CK Markets",
  },
  {
    label: "ClearStreamFX",
    server1: "ClearStreamFX-A1 Demo",
    server2: "ClearStreamFX-A1 Live",
    server3: "ClearStreamFX-LIVE",
    server4: "ClearStreamFX-DEMO",
    value: "ClearStreamFX",
  },
  {
    label: "CLIQ FX",
    server1: "CliqFX-Demo",
    server2: "CliqFX-Live",
    value: "CLIQ FX",
  },
  {
    label: "CLMarkets",
    server1: "CLMarkets-Trading",
    value: "CLMarkets",
  },
  {
    label: "Clover4X",
    server1: "Clover4x-Demo",
    server2: "Clover4x-Live",
    server3: "Clover4x-Live4",
    value: "Clover4X",
  },
  {
    label: "ClubFX",
    server1: "ClubFX-Demo",
    server2: "ClubFX-Live",
    value: "ClubFX",
  },
  {
    label: "CM Index",
    server1: "CMIndex-DEMO",
    server2: "CMIndex-REAL",
    value: "CM Index",
  },
  {
    label: "CMAP",
    server1: "No servers",
    value: "CMAP",
  },
  {
    label: "CMC Markets",
    server1: "CMCMarkets1-Demo",
    server2: "CMCMarkets1-Live",
    server3: "CMCMarkets1-EUR",
    server4: "CMCMarkets1-Canada",
    server5: "CMCMarkets1-Singapore",
    value: "CMC Markets",
  },
  {
    label: "CMG Australia Pty Ltd",
    server1: "CMGAustralia-Demo",
    server2: "CMGAustralia-Real",
    server3: "CMGAustraliaPtyLtd-US03-Demo",
    server4: "CMGAustraliaPtyLtd-US10-Live",
    value: "CMG Australia Pty Ltd",
  },
  {
    label: "CMI",
    server1: "CapitalMarketInvestments-Demo",
    server2: "CapitalMarketInvestments-Live",
    value: "CMI",
  },
  {
    label: "CMS",
    server1: "CMS-Demo",
    server2: "CMS-Real",
    value: "CMS",
  },
  {
    label: "CMS Forex",
    server1: "CMSFX-Demo",
    server2: "CMSFX-Live",
    server3: "LiqCon-Demo 3",
    value: "CMS Forex",
  },
  {
    label: "CMTrading",
    server1: "CMTrading-Demo",
    server2: "CMTrading-Live",
    value: "CMTrading",
  },
  {
    label: "CmxMarkets",
    server1: "CMXMarkets-Demo",
    server2: "CMXMarkets-Real",
    value: "CmxMarkets",
  },
  {
    label: "CNS",
    server1: "CNS-Demo",
    server2: "CNS-Live",
    server3: "ConnoisseurInvestments-Live",
    value: "CNS",
  },
  {
    label: "Coin Copy Trade",
    server1: "TradingGroupLimited-Live",
    server2: "TradingGroupLimited-Demo",
    value: "Coin Copy Trade",
  },
  {
    label: "Coinexx",
    server1: "TurnkeyFX-Live",
    server2: "Coinexx-Demo",
    server3: "Coinexx-Live",
    value: "Coinexx",
  },
  {
    label: "Colmex Pro",
    server1: "TGLColmex-Live",
    server2: "TGLColmex-Demo",
    server3: "ColmexFX-Demo",
    server4: "ColmexFX-Live",
    value: "Colmex Pro",
  },
  {
    label: "Colosseum",
    server1: "Colosseum-Demo 2",
    server2: "Colosseum-Live 2",
    value: "Colosseum",
  },
  {
    label: "Colossus Securities",
    server1: "ColossusSecurities-Demo",
    server2: "ColossusSecurities-Live",
    value: "Colossus Securities",
  },
  {
    label: "CommexFx",
    server1: "CommexFX-Demo",
    server2: "CommexFX-Real",
    server3: "CommexFX-Live",
    value: "CommexFx",
  },
  {
    label: "Commo T",
    server1: "CommoTCoLtd-Live",
    server2: "CommoTCoLtd-Demo",
    value: "Commo T",
  },
  {
    label: "CompassFX",
    server1: "CompassFX-Demo",
    server2: "CompassFX-Live",
    value: "CompassFX",
  },
  {
    label: "Compfybro",
    server1: "Comfybroc-ComfyBro Main Server",
    value: "Compfybro",
  },
  {
    label: "Concord Bay",
    server1: "ConcordBay-DemoUK",
    server2: "ConcordBay-LiveUK",
    value: "Concord Bay",
  },
  {
    label: "Conotoxia",
    server1: "ConotoxiaLimited-DEMO",
    server2: "ConotoxiaLimited-LIVE",
    value: "Conotoxia",
  },
  {
    label: "ContinentalFX",
    server1: "ContiFX-CFX",
    value: "ContinentalFX",
  },
  {
    label: "ContinueFX",
    server1: "ContinueMarkets-DEMO",
    server2: "ContinueMarkets-LIVE",
    value: "ContinueFX",
  },
  {
    label: "Contra FX",
    server1: "contra-Demo",
    server2: "contra-Live",
    server3: "contra-Live2",
    value: "Contra FX",
  },
  {
    label: "CORE CAPITAL",
    server1: "CoreCapital-DEMO",
    server2: "CoreCapital-LIVE",
    value: "CORE CAPITAL",
  },
  {
    label: "Core Liquidity Markets",
    server1: "CoreLiquidity-Real 2",
    server2: "CoreLiquidity-Real 1",
    server3: "CoreLiquidity-Demo",
    server4: "CoreLiquidityMarkets-Live 2",
    server5: "CoreLiquidityMarkets-Demo",
    server6: "CoreLiquidityMarkets-Live 2",
    server7: "CLMarkets-Trading",
    value: "Core Liquidity Markets",
  },
  {
    label: "Core Spreads",
    server1: "CoreSpreads-DemoBravo",
    server2: "CoreSpreads-LiveBravo",
    value: "Core Spreads",
  },
  {
    label: "COZFX",
    server1: "COZfx-Demo",
    server2: "COZfx-Live",
    value: "COZFX",
  },
  {
    label: "CPMarkets",
    server1: "CPMarketsLtd-Demo",
    server2: "CPMarketsLtd-Live",
    server3: "CPMarketsLimited-Demo",
    server4: "CPMarketsLimited-US01-Live",
    value: "CPMarkets",
  },
  {
    label: "CPT Markets",
    server1: "CPTMarkets-Demo",
    server2: "CPTMarkets-Live",
    server3: "CPTMarketsLtd-Demo",
    server4: "CPTMarketsLtd-Live",
    value: "CPT Markets",
  },
  {
    label: "CPT Markets UK",
    server1: "CPTMarketsUKLimited-Trading-Live",
    value: "CPT Markets UK",
  },
  {
    label: "CreativeCSC",
    server1: "CreativeCSCLimited-Demo-UK",
    server2: "CreativeCSCLimited-Live-UK",
    value: "CreativeCSC",
  },
  {
    label: "Cresco FX",
    server1: "CrescoFX-Malta-LiveUS",
    server2: "CrescoFX-Malta-DemoUS",
    server3: "CrescoFX-Live",
    server4: "CrescoFXMalta-Demo",
    server5: "CrescoCapitalMarkets-DemoUK",
    server6: "CrescoCapitalMarkets-LiveUK",
    value: "Cresco FX",
  },
  {
    label: "Crown Forex",
    server1: "CrownForexSA-Server",
    server2: "CrownForexSA-Demo",
    value: "Crown Forex",
  },
  {
    label: "CryptoRocket",
    server1: "CryptoRocket-Demo3",
    server2: "CryptoRocket-Real3",
    value: "CryptoRocket",
  },
  {
    label: "CSI-FX",
    server1: "CSIFX-Demo",
    server2: "CSIFX-Live",
    server3: "CSIGroupLtd-Live",
    value: "CSI-FX",
  },
  {
    label: "Cube Forex",
    server1: "CubeGlobal-Demo",
    server2: "CubeGlobalImpex-Demo",
    server3: "CubeGlobalImpex-DemoEU",
    server4: "CubeGlobalImpex-Live",
    server5: "CubeGlobal-Live",
    value: "Cube Forex",
  },
  {
    label: "CVC Markets",
    server1: "CVCMarkets-LIVE",
    server2: "CVCMarkets-DEMO",
    value: "CVC Markets",
  },
  {
    label: "CWG MARKETS",
    server1: "CWGMarketsUK-Demo",
    server2: "CWGMarketsUK-Live",
    server3: "CWGMarketsSVGLtd-Live",
    value: "CWG MARKETS",
  },
  {
    label: "CXM Direct",
    server1: "CXMDirect-Live",
    server2: "CXMDirect-Demo",
    server3: "CXMDirect-Live2",
    value: "CXM Direct",
  },
  {
    label: "CXM Trading",
    server1: "CXMTradingLtd-Demo",
    server2: "CXMTradingLtd-Real",
    value: "CXM Trading",
  },
  {
    label: "Cyber Futures",
    server1: "CyberFuturesIntl-Live",
    value: "Cyber Futures",
  },
  {
    label: "Cyber Futures Forex",
    server1: "CyberFutures-Demo",
    server2: "CyberFutures-Primary",
    value: "Cyber Futures Forex",
  },
  {
    label: "Cyber Trade International",
    server1: "Cyber-Live",
    server2: "Cyber-Demo",
    server3: "CyberFuturesIntl-Demo",
    server4: "CyberFuturesIntl-Live",
    value: "Cyber Trade International",
  },
  {
    label: "CyberAgent",
    server1: "CyberAgentFX-Demo1",
    server2: "CyberAgentFX-Live1",
    value: "CyberAgent",
  },
  {
    label: "CyberFX",
    server1: "CyberFXPty-Demo",
    server2: "CyberFXPty-Live",
    value: "CyberFX",
  },
  {
    label: "D2M",
    server1: "D2M-MAIN",
    server2: "D2M-DEMO",
    value: "D2M",
  },
  {
    label: "Danpac Futures",
    server1: "DF-Demo Server",
    server2: "DF-Real",
    value: "Danpac Futures",
  },
  {
    label: "Dart Trader",
    server1: "No servers",
    value: "Dart Trader",
  },
  {
    label: "Darwinex",
    server1: "Darwinex-Live",
    server2: "Darwinex-DemoUK",
    server3: "Darwinex-Demo",
    value: "Darwinex",
  },
  {
    label: "Daweda",
    server1: "Daweda-Demo",
    server2: "Daweda-Live",
    server3: "DawedaLimited-Live",
    server4: "DawedaLimited-Demo",
    server5: "DawedaLimited-Live-2",
    value: "Daweda",
  },
  {
    label: "dbFX",
    server1: "LiqCon-Demo",
    server2: "LiqCon-Live",
    server3: "216.93.250.133",
    server4: "216.93.249.69",
    server5: "GCMfx-Demo",
    value: "dbFX",
  },
  {
    label: "DCFxbroker",
    server1: "DifferentChoice-Demo",
    server2: "DifferentChoice-Live",
    server3: "DifferentChoice-PRO Live",
    server4: "DCFxBroker-Live",
    server5: "DCFxBroker-Demo",
    value: "DCFxbroker",
  },
  {
    label: "DCM Forex",
    server1: "DCMForex-Demo",
    server2: "DCMForex-Live",
    value: "DCM Forex",
  },
  {
    label: "DCMDirect",
    server1: "DCMDirect-DemoUK",
    server2: "DCMDirect-LiveUK",
    value: "DCMDirect",
  },
  {
    label: "DDT",
    server1: "DigitalDreamTechnology-Live",
    server2: "DigitalDreamTechnology-Demo",
    value: "DDT",
  },
  {
    label: "DealFX",
    server1: "DealFXIntl1-STD-1-Live",
    server2: "DealFXIntl1-STD-1-Demo",
    server3: "DealFXIntl2-ACT-1-Demo",
    server4: "DealFXIntl2-ACT-1-Live",
    value: "DealFX",
  },
  {
    label: "Dealing24",
    server1: "Mutual-Server",
    value: "Dealing24",
  },
  {
    label: "Decagon Market",
    server1: "DecagonMarket-Live",
    value: "Decagon Market",
  },
  {
    label: "Decode Global",
    server1: "DecodeGlobalLtd-Main Server",
    server2: "DecodeGlobalLtd-Demo",
    value: "Decode Global",
  },
  {
    label: "DefoneFX",
    server1: "DefoneFX-Live",
    server2: "DefoneFX-Demo",
    value: "DefoneFX",
  },
  {
    label: "DefoneFX",
    server1: "DefoneFX-Live",
    server2: "DefoneFX-Demo",
    value: "DefoneFX",
  },
  {
    label: "Delta Bank",
    server1: "DeltaBank-Server",
    value: "Delta Bank",
  },
  {
    label: "DeltaFX",
    server1: "Deltafx-Demo",
    server2: "Deltafx-Real",
    value: "DeltaFX",
  },
  {
    label: "Deltastock",
    server1: "Deltastock-Server",
    server2: "Deltastock-Live",
    server3: "Deltastock-Demo",
    value: "Deltastock",
  },
  {
    label: "DenizFX",
    server1: "DenizFX-DEMO",
    server2: "DenizFX-Live",
    value: "DenizFX",
  },
  {
    label: "Deriv",
    server1: "Deriv- Demo",
    server2: "Deriv- Server",
    value: "Deriv",
  },
  {
    label: "Desert Forex",
    server1: "Desertfx-Real",
    value: "Desert Forex",
  },
  {
    label: "Destek Markets",
    server1: "DestekMarkets-Demo",
    server2: "DestekMarkets-Live",
    server3: "DestekGlobal-Demo",
    value: "Destek Markets",
  },
  {
    label: "DestekFX",
    server1: "DestekFX-Demo",
    server2: "DestekFX-Trade",
    value: "DestekFX",
  },
  {
    label: "Deutsche Trading",
    server1: "DeutscheTrading-Demo",
    server2: "DeutscheTrading-Live",
    value: "Deutsche Trading",
  },
  {
    label: "Devlani",
    server1: "Devlani-Demo",
    server2: "Devlani-Main",
    server3: "Devlani-Demo (ECN)",
    server4: "Devlani-Main (ECN)",
    value: "Devlani",
  },
  {
    label: "DF Markets",
    server1: "DFMarkets-Demo",
    server2: "DFMarkets-Live",
    value: "DF Markets",
  },
  {
    label: "DGM FX",
    server1: "DGM-Demo",
    server2: "DGM-Server",
    value: "DGM FX",
  },
  {
    label: "DIBORT FX",
    server1: "DibortFX-Demo",
    server2: "DibortFX-Real",
    value: "DIBORT FX",
  },
  {
    label: "DIDIFX",
    server1: "DIDIFX-Asia 1",
    server2: "DIDIFX-Demo Server",
    value: "DIDIFX",
  },
  {
    label: "DIDIMAX",
    server1: "PTDidiMaxBerjangka-Demo",
    server2: "PTDidiMaxBerjangka-Real",
    value: "DIDIMAX",
  },
  {
    label: "DIF Broker",
    server1: "DIF-USDDemo01",
    server2: "DIF-USDReal02",
    server3: "DIF-EURDemo01",
    value: "DIF Broker",
  },
  {
    label: "Diff Forex",
    server1: "DiffLtd-Demo",
    server2: "DiffLtd-Live",
    server3: "DIFFLimited-Live",
    value: "Diff Forex",
  },
  {
    label: "DigitalDealing",
    server1: "DigitalDealing-Demo",
    value: "DigitalDealing",
  },
  {
    label: "Direct Trading Technologies",
    server1: "DTTMT4-Demo",
    server2: "DTTMT4-Live",
    value: "Direct Trading Technologies",
  },
  {
    label: "directa",
    server1: "DirectaSIM-Demo",
    server2: "DirectaSIM-Live",
    value: "directa",
  },
  {
    label: "DirectFX",
    server1: "DirectFX-Demo",
    server2: "DirectFX-Live",
    value: "DirectFX",
  },
  {
    label: "Direktbroker-FX",
    server1: "Leverate-Real",
    server2: "Leverate-Europe",
    server3: "Leverate-Demo",
    value: "Direktbroker-FX",
  },
  {
    label: "Discovery FX",
    server1: "DiscoveryForex-DemoServer",
    server2: "DiscoveryForex-Server",
    value: "Discovery FX",
  },
  {
    label: "Diven FX",
    server1: "DivenFX-Demo",
    server2: "DivenFX-Main",
    value: "Diven FX",
  },
  {
    label: "Divergence Prime",
    server1: "DivergencePrime-Demo",
    server2: "DivergencePrime-Real",
    value: "Divergence Prime",
  },
  {
    label: "DivestiX",
    server1: "DivestiX-Demo",
    server2: "DivestiX-Real",
    value: "DivestiX",
  },
  {
    label: "Divisa Capital",
    server1: "Divisa-Live",
    server2: "Divisafx-Live",
    server3: "Divisafx-Demo",
    server4: "Divisa-Demo",
    value: "Divisa Capital",
  },
  {
    label: "DK Trade",
    server1: "DKTrade-Live-1.6",
    value: "DK Trade",
  },
  {
    label: "DMA Brokers",
    server1: "DMABrokers-Demo",
    server2: "DMABrokers-Live5",
    server3: "DMABrokers2-Real",
    value: "DMA Brokers",
  },
  {
    label: "DMAClearing",
    server1: "DMAClearing-LiveServer",
    server2: "DMAClearing-DemoServer",
    value: "DMAClearing",
  },
  {
    label: "DMMFX",
    server1: "DMMFXAU-Demo",
    server2: "DMMFXAU-Main",
    value: "DMMFX",
  },
  {
    label: "DNA Markets",
    server1: "DNAMarkets-Real-5",
    server2: "DNAMarkets-Demo04",
    value: "DNA Markets",
  },
  {
    label: "Dollars Markets",
    server1: "DollarsMarkets2-Live",
    server2: "DollarsMarkets2-Demo",
    server3: "DollarsMarkets-Live-UK-2",
    server4: "DollarsMarkets-Live",
    server5: "DollarsMarkets-Demo",
    value: "Dollars Markets",
  },
  {
    label: "Dom Maklerski BZ WBK",
    server1: "DMBZWBK-DEMO - Inwestor online FX",
    server2: "DMBZWBK-REAL - Inwestor online FX",
    value: "Dom Maklerski BZ WBK",
  },
  {
    label: "DominionFX",
    server1: "DominionFX-Server01",
    value: "DominionFX",
  },
  {
    label: "Domino Forex",
    server1: "DominoForex-Demo",
    server2: "DominoForex-Live",
    value: "Domino Forex",
  },
  {
    label: "DomusFX",
    server1: "DomusFX-DEMO",
    server2: "DomusFX-LIVE",
    value: "DomusFX",
  },
  {
    label: "DooPrime",
    server1: "DooPrime-Demo",
    server2: "DooPrime-Live 2",
    server3: "DooPrime-Live",
    server4: "DooPrime-Demo 2",
    server5: "DooPrime-Live 3",
    server6: "DooPrime-Live 4",
    server7: "DooFintech-Live 5",
    server8: "DooFintech-Demo 5",
    value: "DooPrime",
  },
  {
    label: "DORGOSHI",
    server1: "Dorgoshi-Server",
    value: "DORGOSHI",
  },
  {
    label: "DorsiaFX",
    server1: "DORSIA-DEMO",
    server2: "DORSIA-ECN",
    server3: "DORSIA-ECN2",
    value: "DorsiaFX",
  },
  {
    label: "Double M",
    server1: "DoubleMWorld-Asia 3",
    server2: "DoubleMWorld-Demo",
    value: "Double M",
  },
  {
    label: "Dragon FX",
    server1: "FOREX-Server",
    server2: "DragonUniversalHolding-Demo",
    server3: "DragonUniversalHolding-Live",
    value: "Dragon FX",
  },
  {
    label: "DTM",
    server1: "DTM-Demo",
    server2: "DTM-Main",
    value: "DTM",
  },
  {
    label: "DUBAIFXM",
    server1: "DubaiFXM-Demo",
    server2: "DubaiFXM-Main",
    value: "DUBAIFXM",
  },
  {
    label: "Dukascopy",
    server1: "FTT-Demo",
    server2: "FTT-Live",
    server3: "FTT-Live2",
    server4: "Dukascopy-DEMO-1",
    server5: "Dukascopy-LIVE-1",
    value: "Dukascopy",
  },
  {
    label: "Dunamis Capital",
    server1: "DunamisCapital-Demo",
    server2: "DunamisCapital-Live2",
    value: "Dunamis Capital",
  },
  {
    label: "DunamisFX",
    server1: "DunamisCapital-Live",
    server2: "DunamisFx-Live 8",
    value: "DunamisFX",
  },
  {
    label: "DUO Markets",
    server1: "DuoMarkets-Live04",
    server2: "DuoMarkets-DemoBHS",
    value: "DUO Markets",
  },
  {
    label: "Dutch Prime Securities",
    server1: "DutchPrimeSecurities-Demo",
    server2: "DutchPrimeSecurities-Live",
    value: "Dutch Prime Securities",
  },
  {
    label: "DVMarkets",
    server1: "DVMarkets-Demo",
    server2: "DVMarkets-Main Server",
    server3: "DVMarketsLimited-Main Server",
    server4: "DVMarketsLimited-Demo",
    value: "DVMarkets",
  },
  {
    label: "DW Securities",
    server1: "DWSecurities-Demo",
    server2: "DWSecurities-Main",
    value: "DW Securities",
  },
  {
    label: "E MEXV",
    server1: "EMEXV-Demo Accounts Server",
    server2: "EMEXV-Global Server",
    value: "E MEXV",
  },
  {
    label: "E-XUN",
    server1: "EXUN-North Asia",
    value: "E-XUN",
  },
  {
    label: "E8 Funding",
    server1: "E8Funding-Demo",
    server2: "E8Funding-Live",
    value: "E8 Funding",
  },
  {
    label: "Each Markets",
    server1: "EACHMARKETS-Demo",
    server2: "EACHMARKETS-Live",
    value: "Each Markets",
  },
  {
    label: "EagleFX",
    server1: "EagleFX-Demo",
    server2: "EagleFX-Live",
    value: "EagleFX",
  },
  {
    label: "Eanree",
    server1: "Eanree-Demo",
    server2: "Eanree-Real",
    value: "Eanree",
  },
  {
    label: "Eanree",
    server1: "Eanree-Demo",
    server2: "Eanree-Real",
    value: "Eanree",
  },
  {
    label: "Earn Markets",
    server1: "EarnMarkets-DEMO",
    server2: "EarnMarkets-REAL",
    value: "Earn Markets",
  },
  {
    label: "Earnex",
    server1: "MecoFX-Demo",
    server2: "MecoFX-Live",
    value: "Earnex",
  },
  {
    label: "Easy Tycoon",
    server1: "EasyTycoonTechnology-Demo",
    server2: "EasyTycoonTechnology-Live",
    value: "Easy Tycoon",
  },
  {
    label: "EasyForex",
    server1: "EasyForex-Demo",
    server2: "EasyForex-Live",
    value: "EasyForex",
  },
  {
    label: "EasyTrade",
    server1: "EasyTrade-Demo",
    server2: "EasyTrade-Real",
    value: "EasyTrade",
  },
  {
    label: "EasyTrade Markets",
    server1: "EasytradeMarkets-demo",
    server2: "EasytradeMarkets-live",
    value: "EasyTrade Markets",
  },
  {
    label: "EBC Financial Group",
    server1: "EBCGroup-Demo",
    server2: "EBCGroup-Live",
    value: "EBC Financial Group",
  },
  {
    label: "EBEinc",
    server1: "EBEInc-Demo",
    server2: "EBEInc-Live",
    value: "EBEinc",
  },
  {
    label: "EBH",
    server1: "EUBrokerageHouse-Pro Demo",
    server2: "EUBrokerageHouse-Pro Live",
    value: "EBH",
  },
  {
    label: "EBI",
    server1: "AUExcellentBusiness-Demo",
    server2: "AUExcellentBusiness-Live",
    value: "EBI",
  },
  {
    label: "EC Markets",
    server1: "ECMarketsGlobalLLC-Demo",
    server2: "ECMarketsGlobalLLC-Live",
    server3: "ECMarkets-Demo",
    server4: "ECMarkets-Live02",
    server5: "ECMarkets-Live01",
    value: "EC Markets",
  },
  {
    label: "ECM Trader",
    server1: "ECMTrader-Server1",
    server2: "ECMTrader-Server2",
    server3: "ECM-Live",
    server4: "ECM-Demo",
    value: "ECM Trader",
  },
  {
    label: "ECMarkets",
    server1: "ECMarkets-Live",
    server2: "ECMarketsGlobalLLC-Live",
    value: "ECMarkets",
  },
  {
    label: "ECN Alpha",
    server1: "ECNAlpha-Demo",
    server2: "ECNAlpha-Main",
    value: "ECN Alpha",
  },
  {
    label: "ECN Trade",
    server1: "ECNTrade-Demo",
    server2: "ECNTrade-Live",
    value: "ECN Trade",
  },
  {
    label: "ECN.Broker",
    server1: "ECNBroker-Live",
    server2: "ECNBroker-Demo",
    value: "ECN.Broker",
  },
  {
    label: "ECN4X",
    server1: "ECN4X-Demo",
    server2: "ECN4X-Real",
    value: "ECN4X",
  },
  {
    label: "ECNPROFX",
    server1: "ECNPROFX-Server",
    value: "ECNPROFX",
  },
  {
    label: "ECNTradeFX",
    server1: "ECNTradeFX-Demo 3",
    server2: "ECNTradeFX-Live",
    server3: "ECNTradeFX-Server",
    value: "ECNTradeFX",
  },
  {
    label: "Edeal Markets",
    server1: "EDealmarkets-Demo",
    server2: "EDealmarkets-Real2",
    value: "Edeal Markets",
  },
  {
    label: "EDGE Capital Market",
    server1: "No servers",
    value: "EDGE Capital Market",
  },
  {
    label: "EdwardsFX",
    server1: "EdwardsSecurities-Demo",
    server2: "EdwardsSecurities-Live",
    value: "EdwardsFX",
  },
  {
    label: "EES FX",
    server1: "No servers",
    value: "EES FX",
  },
  {
    label: "eFOREX",
    server1: "EFX-Demo",
    server2: "EFX-Live",
    server3: "eForex-Professional",
    server4: "eForex-ProfessionalDemo",
    value: "eFOREX",
  },
  {
    label: "eFxBank",
    server1: "eFxBank-Real",
    server2: "eFxBank-Demo",
    value: "eFxBank",
  },
  {
    label: "EGINETS",
    server1: "EagleInternational-Demo",
    server2: "EagleInternational-Live",
    value: "EGINETS",
  },
  {
    label: "EGlobal",
    server1: "EGlobal-Demo",
    server2: "EGlobal-Real",
    server3: "EGlobal-Server",
    server4: "EGlobal-Cent",
    server5: "EGlobal-Cent2",
    server6: "EGlobal-Cent1",
    server7: "EGlobal-Cent4",
    server8: "EGlobal-Cent3",
    server9: "EGlobal-PAMM",
    server10: "EGlobal-ProSTP",
    server11: "EGlobalTrade-Cent7",
    server12: "EGlobalTrade-Cent1",
    server13: "EGlobal-Classic3",
    server14: "EGlobal-Classic2",
    server15: "EGlobal-Cent5",
    server16: "EglobalTrade-Cent6",
    server17: "EGlobal-Cent6",
    server18: "EGlobal-Cent7",
    server19: "EGlobal-Cent8",
    server20: "EGlobal-Classic1",
    value: "EGlobal",
  },
  {
    label: "EGM",
    server1: "EagleGM-DemoBravo",
    server2: "EagleGM-LiveBravo",
    value: "EGM",
  },
  {
    label: "EGM Securities Ltd",
    server1: "EGMSecurities-Demo",
    server2: "EGMSecurities-Live 4",
    value: "EGM Securities Ltd",
  },
  {
    label: "EIGEN FX",
    server1: "EigenInternational-Demo",
    server2: "EigenInternational-Live",
    value: "EIGEN FX",
  },
  {
    label: "Eightcap",
    server1: "Eightcap-Demo",
    server2: "Eightcap-Real",
    server3: "EightcapLtd-Real2",
    server4: "EightcapLtd-Demo2",
    server5: "EightcapLtd-Real-3",
    server6: "EightcapLtd-Real-4",
    server7: "EightcapLtd-Demo3",
    server8: "Eightcap-Demo04",
    server9: "EightcapLtd-Demo05",
    value: "Eightcap",
  },
  {
    label: "EkolFX",
    server1: "No servers",
    value: "EkolFX",
  },
  {
    label: "EkolFX",
    server1: "No servers",
    value: "EkolFX",
  },
  {
    label: "ELANA",
    server1: "ELANAMT4-Real5",
    server2: "ELANAMT4-Demo",
    value: "ELANA",
  },
  {
    label: "ElanFX",
    server1: "ELAN FX Server",
    server2: "ElanServer-Demo",
    server3: "ElanServer-Real",
    value: "ElanFX",
  },
  {
    label: "ELEX - Electronic FX",
    server1: "CrescoFX-Malta-DemoUS",
    server2: "PrimeXM-DemoUK",
    server3: "PrimeXM-DemoUS",
    server4: "PrimeXM-LiveUK",
    server5: "PrimeXM-LiveUS",
    value: "ELEX - Electronic FX",
  },
  {
    label: "Eliom FX",
    server1: "EliomFX-Demo",
    server2: "EliomFX-Live",
    value: "Eliom FX",
  },
  {
    label: "Elit4x",
    server1: "EliteTrader-Demo",
    server2: "EliteTrader-Live",
    value: "Elit4x",
  },
  {
    label: "Elite Brokers",
    server1: "EliteBrokers-Server",
    value: "Elite Brokers",
  },
  {
    label: "Elite Capital Markets",
    server1: "EliteCapitalInvestment-Demo",
    server2: "EliteCapitalInvestment-Live",
    value: "Elite Capital Markets",
  },
  {
    label: "Elite Strategies",
    server1: "EliteStrategies-Demo",
    server2: "EliteStrategies-Live",
    value: "Elite Strategies",
  },
  {
    label: "Elitrix prime",
    server1: "ElitrixCapital-Demo",
    server2: "ElitrixCapital-Live",
    value: "Elitrix prime",
  },
  {
    label: "EllentTrade",
    server1: "HKEllentComtrade-Demo",
    server2: "HKEllentComtrade-Live",
    value: "EllentTrade",
  },
  {
    label: "Ellipsys",
    server1: "ZenTrade-Server",
    value: "Ellipsys",
  },
  {
    label: "ELXI Markets",
    server1: "ElxiMarkets-Demo",
    server2: "ElxiMarkets-Live",
    value: "ELXI Markets",
  },
  {
    label: "EMD Broker",
    server1: "No servers",
    value: "EMD Broker",
  },
  {
    label: "Emperor Financial",
    server1: "EmperorFinancial-Demo",
    server2: "EmperorFinancial-Live",
    value: "Emperor Financial",
  },
  {
    label: "Empire Global",
    server1: "EmpireGlobal-Server",
    value: "Empire Global",
  },
  {
    label: "EmpowerFX",
    server1: "EmpowerLLC-Demo",
    server2: "EmpowerLLC-Live",
    server3: "EmpowerLLC-Live4",
    server4: "EmpowerLLC-Real",
    value: "EmpowerFX",
  },
  {
    label: "Enfinium",
    server1: "Enfinium-Demo",
    server2: "Enfinium-Live",
    server3: "Enfinium-Live 2",
    value: "Enfinium",
  },
  {
    label: "Envi FX",
    server1: "EnviLLC-Demo",
    server2: "EnviLLC-Live",
    server3: "EnviLLC-Live4",
    server4: "EnviLLC-Real",
    value: "Envi FX",
  },
  {
    label: "eqlx",
    server1: "eqlx-Demo",
    server2: "eqlx-Demo",
    server3: "eqlx-Live 2",
    value: "eqlx",
  },
  {
    label: "Equilor",
    server1: "Equilor-MT4-ID01",
    server2: "Equilor-MT4-IS02",
    server3: "Equilor-MT4-IS04",
    value: "Equilor",
  },
  {
    label: "Equiti",
    server1: "Equiti-Demo",
    server2: "Equiti-Live",
    server3: "EquitiGroup-Live",
    server4: "EquitiBrokerageSC-Live 3",
    server5: "EquitiBrokerageSC-Live",
    server6: "EquitiBrokerageSC-Live 2",
    server7: "EquitiBrokerageSC-Live 4",
    server8: "EquitiGroup-Live 2",
    server9: "EquitiSecurities-Live 3",
    server10: "EquitiGroup-Demo",
    server11: "EquitiUS-Live",
    server12: "EquitiUS-Demo",
    server13: "EquitiUS-Test",
    value: "Equiti",
  },
  {
    label: "Equiti Capital",
    server1: "Divisa-Demo",
    server2: "Divisa-Live",
    server3: "EquitiCapitalUK-Demo",
    server4: "EquitiCapitalUK-Live",
    server5: "EquitiCapitalUK-Demo 2",
    server6: "EquitiCapitalUK-Live 5",
    value: "Equiti Capital",
  },
  {
    label: "Equiti Group",
    server1: "EquitiGroup-Live",
    server2: "EquitiGroup-Demo",
    server3: "EquitiGroup-Live 2",
    server4: "EquitiBrokerageSC-Live",
    server5: "EquitiBrokerageSC-Live 3",
    server6: "EquitiBrokerageSC-Live 4",
    server7: "EquitiBrokerageSC-Live 2",
    server8: "EquitiBrokerageSC-Demo",
    value: "Equiti Group",
  },
  {
    label: "EQUITIMAX",
    server1: "Equitimax-Demo",
    server2: "Equitimax-Live",
    value: "EQUITIMAX",
  },
  {
    label: "ErgoFx",
    server1: "ErgoFX-Demo Server",
    server2: "ErgoFX-Live 3",
    value: "ErgoFx",
  },
  {
    label: "Errante",
    server1: "ErranteSecurities-Demo",
    server2: "ErranteSecurities-Live",
    server3: "ErranteSC-Demo2",
    server4: "ErranteSC-Real2",
    server5: "ErranteTrading-Real2",
    server6: "ErranteTrading-Demo2",
    value: "Errante",
  },
  {
    label: "ES markets",
    server1: "ESMarkets-Demo02",
    server2: "ESMarkets-Live08",
    value: "ES markets",
  },
  {
    label: "ESANDAR ARTHAMAS BERJANGKA",
    server1: "Esandar-Demo",
    server2: "Esandar-Live",
    value: "ESANDAR ARTHAMAS BERJANGKA",
  },
  {
    label: "Esperio",
    server1: "OFGCap-Demo",
    server2: "OFGCap-Pacific",
    value: "Esperio",
  },
  {
    label: "Esplanade MS",
    server1: "Esplanade-Live",
    server2: "Esplanade-Demo",
    value: "Esplanade MS",
  },
  {
    label: "EssenceFX",
    server1: "Essence-LiveLiquidity1",
    server2: "Essence-Demo",
    server3: "EssenceMillennium-Live",
    server4: "EssenceMillennium-Demo",
    value: "EssenceFX",
  },
  {
    label: "Esteem Trader",
    server1: "Esteem-Demo",
    server2: "Esteem-Real",
    value: "Esteem Trader",
  },
  {
    label: "Ester",
    server1: "Ester-Demo",
    server2: "Ester-Live",
    value: "Ester",
  },
  {
    label: "Estocks",
    server1: "Estocks-Demo",
    server2: "Estocks-Live",
    value: "Estocks",
  },
  {
    label: "EsTUbroker",
    server1: "Estu-EURDemo01",
    server2: "Estu-EURReal01",
    value: "EsTUbroker",
  },
  {
    label: "Eternalfx",
    server1: "EternalFX-MT4 Demo",
    server2: "EternalFX-Real3",
    value: "Eternalfx",
  },
  {
    label: "ETERNITY GLOBAL LIMITED",
    server1: "EternityGlobal-Demo",
    server2: "EternityGlobal-Live",
    value: "ETERNITY GLOBAL LIMITED",
  },
  {
    label: "ETFinance",
    server1: "ETFinance",
    server2: "ETFinance-Demo",
    value: "ETFinance",
  },
  {
    label: "ETFX",
    server1: "ETGlobal-Demo",
    server2: "ETGlobal-Live",
    value: "ETFX",
  },
  {
    label: "ETHOS",
    server1: "FxEthos-DemoUK",
    server2: "FxEthos-LiveUK",
    value: "ETHOS",
  },
  {
    label: "Etiq Markets",
    server1: "ETIQMarkets-Demo",
    server2: "ETIQMarkets-Live",
    value: "Etiq Markets",
  },
  {
    label: "ETOMarkets",
    server1: "ETO-Demo",
    server2: "ETO-Live",
    value: "ETOMarkets",
  },
  {
    label: "ETX Capital",
    server1: "ETXCapital-Demo Server",
    server2: "ETXCapital-Live Server",
    server3: "ETXCapital-Live2 Server",
    value: "ETX Capital",
  },
  {
    label: "EU Trading Group",
    server1: "EuTradingGroup-main",
    value: "EU Trading Group",
  },
  {
    label: "EurAsia Markets",
    server1: "EurAsiaMarkets-Demo",
    server2: "EurAsiaMarkets-Live",
    value: "EurAsia Markets",
  },
  {
    label: "Euro Forex",
    server1: "EuroForex-Server",
    value: "Euro Forex",
  },
  {
    label: "Euro Orient",
    server1: "EuroOrient-Demo",
    server2: "EuroOrient-Real",
    server3: "EuroOrient-Real1",
    server4: "EuroOrient-Real2",
    value: "Euro Orient",
  },
  {
    label: "Euro Pacific Bank",
    server1: "GlobalTrading-DemoUK",
    server2: "GlobalTrading-LiveUK",
    value: "Euro Pacific Bank",
  },
  {
    label: "Euro Trade Premier",
    server1: "EuroTrade-Server",
    value: "Euro Trade Premier",
  },
  {
    label: "Euromaxfx",
    server1: "Euromaxfx-Main",
    value: "Euromaxfx",
  },
  {
    label: "EuropeFX",
    server1: "EuropeFX-Live",
    server2: "EuropeFX-Demo",
    server3: "EuropeFXAU-Live3",
    server4: "EuropeFX1-Live",
    value: "EuropeFX",
  },
  {
    label: "Eurotrader",
    server1: "EurotradeSA-Live01",
    server2: "EurotradeSA-Demo01",
    server3: "EuroTradeGlobal-Demo01",
    server4: "EuroTradeGlobal-Live01",
    value: "Eurotrader",
  },
  {
    label: "EvenForex",
    server1: "EvenForex.com-Demo",
    server2: "EvenForex.com-Live",
    server3: "EvenForex-Real2",
    value: "EvenForex",
  },
  {
    label: "EverestCM",
    server1: "EverestCM-Live",
    server2: "EverestCM-Platform",
    value: "EverestCM",
  },
  {
    label: "EverFX",
    server1: "EVERFX-Demo1",
    server2: "EVERFX-Real1",
    server3: "EverFX2-Real1",
    value: "EverFX",
  },
  {
    label: "EVFOREX",
    server1: "EVForeignExchange-Live",
    server2: "EVForeignExchange-Demo",
    value: "EVFOREX",
  },
  {
    label: "EVFX",
    server1: "EVFXLimited-Demo",
    server2: "EVFXLimited-Live",
    value: "EVFX",
  },
  {
    label: "Evolve Markets",
    server1: "EvolveMarkets-MT4 Demo Server",
    server2: "EvolveMarkets-MT4 Live Server",
    value: "Evolve Markets",
  },
  {
    label: "EWFX",
    server1: "EWFX-Live",
    server2: "EWFX-demo",
    value: "EWFX",
  },
  {
    label: "EWGfx",
    server1: "EWGFX-Demo",
    server2: "EWGFX-Live",
    value: "EWGfx",
  },
  {
    label: "EXANTE",
    server1: "EXANTE-Live",
    value: "EXANTE",
  },
  {
    label: "Excel Markets",
    server1: "ExcelMarketsNZ-Demo",
    server2: "ExcelMarketsNZ-Live",
    value: "Excel Markets",
  },
  {
    label: "eXcentral EU",
    server1: "eXcentral-MAIN Live",
    server2: "eXcentral-Demo",
    value: "eXcentral EU",
  },
  {
    label: "Exclusive Markets",
    server1: "ExclusiveMarkets2-Live 2",
    server2: "ExclusiveMarkets-DEMO",
    server3: "ExclusiveMarkets-LIVE",
    value: "Exclusive Markets",
  },
  {
    label: "EXCO Finance",
    server1: "RSGFinance-Live",
    server2: "RSGFinance-Demo",
    value: "EXCO Finance",
  },
  {
    label: "Exe Markets",
    server1: "EXEMarkets-Live",
    server2: "EXEMarkets-Demo",
    value: "Exe Markets",
  },
  {
    label: "EXfinances",
    server1: "FinexGroup-Demo",
    server2: "FinexGroup-Live",
    value: "EXfinances",
  },
  {
    label: "EXNESS",
    server1: "ExnessUK-Real10",
    server2: "Exness-Real9",
    server3: "Exness-Real12",
    server4: "Exness-Trial4",
    server5: "Exness-Trial5",
    server6: "Exness-Trial6",
    server7: "Exness-Real7",
    server8: "Exness-Trial2",
    server9: "Exness-Real11",
    server10: "ExnessCY-Trial",
    server11: "Exness-Real",
    server12: "Exness-Trial",
    server13: "Exness-Server",
    server14: "Exness-Real2",
    server15: "Exness-Real3",
    server16: "Exness-Real4",
    server17: "Exness-Real5",
    server18: "Exness-Real6",
    server19: "Exness-Real8",
    server20: "Exness-Trial7",
    server21: "Exness-Real14",
    server22: "Exness-Real15",
    server23: "Exness-Real16",
    server24: "Exness-Real17",
    server25: "Exness-Real18",
    server26: "Exness-Real19",
    server27: "Exness-Real20",
    server28: "Exness-Real21",
    server29: "Exness-Real22",
    server30: "Exness-Real23",
    server31: "Exness-Trial8",
    server32: "Exness-Trial9",
    server33: "Exness-Trial11",
    server34: "Exness-Real27",
    server35: "Exness-Real24",
    server36: "Exness-Real25",
    server37: "Exness-Real26",
    server38: "Exness-Real27",
    server39: "Exness-Trial12",
    server40: "Exness-Trial14",
    server41: "Exness-Trial10",
    server42: "Exness-Real28",
    server43: "Exness-Real29",
    server44: "Exness-Real30",
    server45: "Exness-Real31",
    server46: "Exness-Real32",
    server47: "Exness-Real33",
    value: "EXNESS",
  },
  {
    label: "ExperFX",
    server1: "ExperFinancial-Live",
    value: "ExperFX",
  },
  {
    label: "Exxi Capital",
    server1: "ExisteInvestment-Live 7",
    server2: "ExisteInvestment-Demo Server",
    value: "Exxi Capital",
  },
  {
    label: "EZ SQUARE",
    server1: "EZSquareTech-Demo-UK",
    server2: "EZSquareTech-Live-UK",
    value: "EZ SQUARE",
  },
  {
    label: "EZINVEST",
    server1: "EZINVEST-Demo",
    server2: "EZINVEST-Live",
    server3: "EZInvestsecurities-Live",
    server4: "EZInvestsecurities-Demo",
    server5: "WGMServices-Demo",
    server6: "WGMServices-Live",
    value: "EZINVEST",
  },
  {
    label: "EZYFX",
    server1: "EZYFX-Demo Server",
    server2: "EZYFX-Live 7",
    server3: "EZYFX-Live Server",
    value: "EZYFX",
  },
  {
    label: "FA Markets",
    server1: "Live_Server_1",
    server2: "Live_Server_2",
    server3: "Demo_Server",
    value: "FA Markets",
  },
  {
    label: "Fab Investing",
    server1: "FidelityAsiaBank-DemoUK",
    server2: "FidelityAsiaBank-LiveUK",
    value: "Fab Investing",
  },
  {
    label: "Fair Forex",
    server1: "FairForex-Demo",
    server2: "FairForex-Live",
    value: "Fair Forex",
  },
  {
    label: "Fair Lane",
    server1: "FairLane-Live",
    server2: "FairLane-Demo",
    value: "Fair Lane",
  },
  {
    label: "Fair Markets",
    server1: "FairMarketsINT-Demo-3",
    server2: "FairMarketsINT-Live-1.7",
    value: "Fair Markets",
  },
  {
    label: "Fair Trading Technology",
    server1: "FTT-Live2",
    server2: "FTT-Demo",
    server3: "FTT-Live",
    value: "Fair Trading Technology",
  },
  {
    label: "FalcoFX",
    server1: "FalcoFX-Demo",
    server2: "FalcoFX-Live",
    value: "FalcoFX",
  },
  {
    label: "Falcon Brokers",
    server1: "Falcon-Server",
    server2: "FalconBrokers-Demo",
    server3: "FalconBrokers-Real",
    value: "Falcon Brokers",
  },
  {
    label: "FastBrokers",
    server1: "FastBrokersFX-MT4 Demo Server 2",
    server2: "FastBrokersFX-MT4 Live Server 2",
    server3: "FastBrokersFX-MT4 Live Server 3",
    server4: "FastBrokersFX-MT4 Live Server",
    server5: "FastBrokersFX-MTXtreme Server",
    server6: "FastBrokersFX-MT4 Demo Server",
    value: "FastBrokers",
  },
  {
    label: "FastOne",
    server1: "FastOneGlobal-Demo",
    server2: "FastOneGlobal-Main",
    value: "FastOne",
  },
  {
    label: "FBK markets",
    server1: "FBKMarkets-Demo",
    server2: "FBKMarkets-Live01",
    value: "FBK markets",
  },
  {
    label: "FBP",
    server1: "FBPLimited-Main",
    server2: "FBPLimited-Demo",
    value: "FBP",
  },
  {
    label: "FBS",
    server1: "FBS-Demo",
    server2: "FBS-Real-1",
    server3: "Tradestone-Demo",
    server4: "Tradestone-Real-1",
    server5: "FBSInc-Real-11",
    server6: "FBS-Real-12",
    server7: "FBSInc-bonus1",
    server8: "FBS-Real-13",
    server9: "FBS-Real-11",
    server10: "FBS-Real-2",
    server11: "FBS-Real-Micro-Cent",
    server12: "FBS-Real-3",
    server13: "FBS-Real-4",
    server14: "FBS-Real-5",
    server15: "FBS-Real-6",
    server16: "FBS-Real-7",
    server17: "FBS-Real-8",
    server18: "FBS-Real-9",
    server19: "FBS-Real-10",
    value: "FBS",
  },
  {
    label: "FCI Markets",
    server1: "itexsys-Demo",
    server2: "itexsys-Live",
    server3: "ForeignCurrency-Live2",
    value: "FCI Markets",
  },
  {
    label: "FDAFX",
    server1: "FDACMarkets-DemoUS",
    server2: "FDACMarkets-LiveUS",
    value: "FDAFX",
  },
  {
    label: "FeelTrade",
    server1: "Feeltrade-Live",
    server2: "Feeltrade-Demo",
    value: "FeelTrade",
  },
  {
    label: "FGMarkets",
    server1: "FiveCircles-Demo Server",
    server2: "FiveCircles-Live Server",
    value: "FGMarkets",
  },
  {
    label: "FGS Markets",
    server1: "TechInvest-Server",
    value: "FGS Markets",
  },
  {
    label: "Fibido",
    server1: "No servers",
    value: "Fibido",
  },
  {
    label: "FIBO Group",
    server1: "FIBO-FIBO Group MT4 Demo Serve",
    server2: "FIBO-FIBO Group MT4 Real Server",
    server3: "FIBO-FIBO Group MT4 Real (CENT)",
    server4: "FIBO-FIBO Group MT4 Demo Server",
    server5: "FIBO-FIBO Group MT4 Real Server 2",
    value: "FIBO Group",
  },
  {
    label: "FIBO Group (Cyprus)",
    server1: "FIBO-FIBO Group MT4 Demo Server",
    server2: "FIBO-FIBO Group MT4 Real Server",
    server3: "FIBOGroup-FIBO GROUP HOLDINGS LTD DEMO",
    server4: "FIBOGroup-REAL FIBO Group Holdings Ltd",
    value: "FIBO Group (Cyprus)",
  },
  {
    label: "Fidelcrest",
    server1: "Fidelcrest-Demo",
    server2: "Fidelcrest-LiveLiquidity1",
    value: "Fidelcrest",
  },
  {
    label: "Fidelis Capital Markets",
    server1: "FidelisCapitalMarkets-LIVE",
    server2: "FidelisCapitalMarkets-DEMO",
    value: "Fidelis Capital Markets",
  },
  {
    label: "Fidelis Cyprus",
    server1: "FCMCY-Demo",
    server2: "FCMCY-Live",
    server3: "FideliscmCyprus-Demo",
    server4: "FideliscmCyprus-Live",
    value: "Fidelis Cyprus",
  },
  {
    label: "FIFWOLF FX",
    server1: "FIFWolf-Demo",
    server2: "FIFWolf-Real",
    value: "FIFWOLF FX",
  },
  {
    label: "FIGfx",
    server1: "FIGSolutions-Alpha (FIG)",
    server2: "FIGSolutions-Server",
    value: "FIGfx",
  },
  {
    label: "Final Forex",
    server1: "FinalForex-demo",
    server2: "FinalForex-Server",
    value: "Final Forex",
  },
  {
    label: "Finalto",
    server1: "FinaltoUK-Demo",
    server2: "FinaltoUK-Live1",
    value: "Finalto",
  },
  {
    label: "Finam",
    server1: "FINAM-Demo",
    server2: "FINAM-Real",
    server3: "FINAM-Real4",
    value: "Finam",
  },
  {
    label: "Finance Administrator",
    server1: "FinanceAdministrator-Demo",
    server2: "FinanceAdministrator-Live",
    value: "Finance Administrator",
  },
  {
    label: "Finans Online FX",
    server1: "FinansOnlineFX-Demo",
    server2: "FinansOnlineFX-Live",
    value: "Finans Online FX",
  },
  {
    label: "FinCIB",
    server1: "FinCIB-Server",
    value: "FinCIB",
  },
  {
    label: "Fine Capitals",
    server1: "FineCapitals-Demo",
    server2: "FineCapitals-Live",
    value: "Fine Capitals",
  },
  {
    label: "Finex",
    server1: "Finex-Demo",
    server2: "Finex-Live",
    value: "Finex",
  },
  {
    label: "Finexo",
    server1: "Finexo-Real",
    server2: "Finexo-Server",
    value: "Finexo",
  },
  {
    label: "FinFx",
    server1: "FinFx-Server",
    server2: "FinFx-Demo",
    server3: "FinFx-Live",
    server4: "FinFx-ECN Pro",
    server5: "FinFX-Managed Live",
    server6: "FinFX-PRO Live",
    server7: "FinFX.com-Demo",
    server8: "FinFX.com-Live",
    server9: "FinFXGlobal-Demo",
    server10: "FinFXGlobal-Real",
    value: "FinFx",
  },
  {
    label: "FinFx-Pro",
    server1: "TradeProServices-Live",
    value: "FinFx-Pro",
  },
  {
    label: "Finix",
    server1: "CPMarketsLtd-Live",
    value: "Finix",
  },
  {
    label: "FinMarket",
    server1: "FinMarket-Server",
    value: "FinMarket",
  },
  {
    label: "FinmarkFX",
    server1: "FinancialMarkets-Demo",
    server2: "FinancialMarkets-LiveLiquidity1",
    value: "FinmarkFX",
  },
  {
    label: "FINMSK",
    server1: "AAAGlobal-Demo",
    server2: "AAAGlobal-Live",
    value: "FINMSK",
  },
  {
    label: "FinproTrading",
    server1: "KPDN-Demo",
    server2: "KPDN-Live",
    server3: "TurnkeyFX-Live",
    server4: "TurnkeyFX-Demo",
    server5: "FinproHoldings-Demo",
    server6: "FinproHoldings-Live",
    server7: "FPT-Live",
    server8: "FPT-Demo",
    value: "FinproTrading",
  },
  {
    label: "FINQ",
    server1: "LeadCapitalCorp-Live",
    server2: "LeadCapitalCorp-Practice",
    value: "FINQ",
  },
  {
    label: "FINSA EUROPE",
    server1: "PipIndexCapitalMarkets-Live",
    server2: "FinsaEurope-Live",
    server3: "FinsaPty-DemoBravo",
    server4: "FinsaPty-LiveBravo",
    value: "FINSA EUROPE",
  },
  {
    label: "FINTECH GLOBAL",
    server1: "FintechGlobal-demo",
    server2: "FintechGlobal-Server",
    value: "FINTECH GLOBAL",
  },
  {
    label: "FINTECHFX",
    server1: "MyGroupFintech-Demo",
    server2: "MyGroupFintech-Live",
    value: "FINTECHFX",
  },
  {
    label: "Finveo",
    server1: "FinveoMN-Real",
    value: "Finveo",
  },
  {
    label: "FIPFX Global",
    server1: "ILQ-M1 Demo",
    server2: "ILQ-M1 Live",
    server3: "ILQ-M2 Demo",
    server4: "ILQ-M2 Live",
    server5: "ILQAu-A1 Live",
    value: "FIPFX Global",
  },
  {
    label: "FirewoodFX",
    server1: "Firewood-DemoUS",
    server2: "Firewood-LiveUS",
    server3: "FirewoodFX-Main",
    server4: "FirewoodFX-Demo",
    value: "FirewoodFX",
  },
  {
    label: "First Index",
    server1: "FirstIndexLtd-Demo",
    server2: "FirstIndexLtd-Live",
    server3: "FirstIndex-Server",
    value: "First Index",
  },
  {
    label: "First International Traders",
    server1: "FIT-Demo",
    server2: "FIT-Real",
    value: "First International Traders",
  },
  {
    label: "First State Futures",
    server1: "FSF-Demo",
    server2: "FSF-Live",
    value: "First State Futures",
  },
  {
    label: "FirstFX",
    server1: "FirstFX-Demo",
    server2: "FirstFX-Main",
    value: "FirstFX",
  },
  {
    label: "Five Stars Forex",
    server1: "5StarsForex-Demo",
    server2: "5StarsForex-Real",
    value: "Five Stars Forex",
  },
  {
    label: "Fixi",
    server1: "FIXI-US01-Demo",
    server2: "FIXI-US01-Live",
    value: "Fixi",
  },
  {
    label: "FIXI Markets",
    server1: "FixiMarkets-Live",
    server2: "FixiMarkets-Demo",
    value: "FIXI Markets",
  },
  {
    label: "Flatex AG",
    server1: "flatexAG-FX Server",
    server2: "flatex-FX Server",
    server3: "FlatexGmbH-DemoServer",
    server4: "FlatexGmbH-LiveServer",
    value: "Flatex AG",
  },
  {
    label: "FlowBank",
    server1: "FlowBank-Live",
    server2: "FlowBank-Demo",
    value: "FlowBank",
  },
  {
    label: "FLRS",
    server1: "FlourisiCoLtd-Demo",
    server2: "FlourisiCoLtd-Live",
    value: "FLRS",
  },
  {
    label: "FM Global",
    server1: "FMGlobal-Demo01",
    server2: "FMGlobal-Live02",
    server3: "FMGlobalLtd-Live",
    server4: "FMGlobalLtd-Demo",
    value: "FM Global",
  },
  {
    label: "FMTM",
    server1: "FormaxTime-Demo",
    server2: "FormaxTime-Live",
    value: "FMTM",
  },
  {
    label: "Focus Markets",
    server1: "FocusMarkets-Demo2",
    server2: "FocusMarkets-Real2",
    server3: "FocusMarkets-Real-4",
    value: "Focus Markets",
  },
  {
    label: "Foo Markets",
    server1: "FooGlobalMarketsLimited-Demo",
    server2: "FooGlobalMarketsLimited-live1",
    value: "Foo Markets",
  },
  {
    label: "FORAIT Capital",
    server1: "FORAIT-Live 5 server",
    value: "FORAIT Capital",
  },
  {
    label: "Forex 24",
    server1: "Forex24Global-Demo",
    server2: "Forex24Global-Real",
    value: "Forex 24",
  },
  {
    label: "Forex Bank",
    server1: "SmartTradeCo-Demo",
    server2: "SmartTradeCo-Real",
    value: "Forex Bank",
  },
  {
    label: "Forex Birds",
    server1: "ForexBirds-DEMO",
    server2: "ForexBirds-LIVE",
    value: "Forex Birds",
  },
  {
    label: "Forex Broker Inc",
    server1: "ForexBrokerInc-Demo",
    server2: "ForexBrokerInc-Main",
    server3: "ForexBrokerInc-Demo",
    server4: "ForexBrokerInc-Main",
    server5: "FBForexBrokerInc-Main",
    server6: "FBForexBrokerInc-Demo",
    value: "Forex Broker Inc",
  },
  {
    label: "Forex Bull",
    server1: "FOREXBULL-Demo",
    server2: "FOREXBULL-Main",
    value: "Forex Bull",
  },
  {
    label: "Forex Capital Trading",
    server1: "ForexCT-TNDemo",
    server2: "ForexCT-TNLive",
    value: "Forex Capital Trading",
  },
  {
    label: "Forex CFDs",
    server1: "ForexCFDsAU-Live",
    server2: "ForexCFDsAU-Demo",
    server3: "ForexCFDs-Demo",
    server4: "ForexCFDs-Live",
    value: "Forex CFDs",
  },
  {
    label: "Forex Chief",
    server1: "ForexChief-Classic",
    server2: "ForexChief-Demo",
    server3: "ForexChief-DirectFX",
    value: "Forex Chief",
  },
  {
    label: "Forex Club",
    server1: "ForexClub-MT4 Real Server",
    server2: "ForexClub-MT4 Demo Server",
    server3: "ForexClub-MT4 MoneyManager Server",
    server4: "ForexClub-MT4 Real 2 Server",
    server5: "ForexClubCIF-MT4 Real Server",
    server6: "ForexClubCIF-MT4 Demo Server",
    server7: "ForexClub-MT4 Market Demo Server",
    server8: "ForexClub-MT4 Market Real Server",
    server9: "ForexClub-MT4 Market Real 2 Server",
    server10: "ForexClub-MT4 Market Real 3 Server",
    server11: "ForexClubBY-MT4 Market Real 2 Server",
    server12: "ForexClub-MT4 Market Real 4 Server",
    server13: "ForexClubBY-MT4 Demo Server",
    server14: "ForexClubBY-MT4 Market Demo Server",
    server15: "ForexClubBY-MT4 Market Real Server",
    server16: "ForexClubBY-MT4 Real 2 Server",
    server17: "ForexClubBY-MT4 Real Server",
    value: "Forex Club",
  },
  {
    label: "FOREX EXCHANGE",
    server1: "ForexExchangeCoLtd-Demo1",
    server2: "ForexExchangeCoLtd-Live1",
    value: "FOREX EXCHANGE",
  },
  {
    label: "Forex FS",
    server1: "FXFS-Demo Server",
    server2: "FXFS-Main Server",
    server3: "FFSMarkets-Demo",
    server4: "FFSMarkets-Live",
    value: "Forex FS",
  },
  {
    label: "Forex FS AU",
    server1: "FXFS-Demo",
    server2: "FXFS-Main Server",
    value: "Forex FS AU",
  },
  {
    label: "Forex Grand",
    server1: "GrandServices-Demo",
    server2: "GrandServices-Live",
    server3: "GrandInvestingLtd-Real2",
    value: "Forex Grand",
  },
  {
    label: "Forex Hub",
    server1: "ForexHubLtd-Demo",
    server2: "ForexHubLtd-Live",
    value: "Forex Hub",
  },
  {
    label: "Forex Ltd",
    server1: "FOREX-Server",
    value: "Forex Ltd",
  },
  {
    label: "Forex MMCIS",
    server1: "MMCIS-Server",
    server2: "MMCIS-Real",
    server3: "MMCIS-Demo",
    value: "Forex MMCIS",
  },
  {
    label: "Forex Nation",
    server1: "FN-US02-Live",
    server2: "FN-US03-Demo",
    server3: "FN-US05-Live",
    server4: "FN-US06-Live",
    value: "Forex Nation",
  },
  {
    label: "Forex Optimum",
    server1: "FOG-Demo",
    server2: "FOG-Pacific",
    server3: "FOG-Server",
    value: "Forex Optimum",
  },
  {
    label: "Forex Place (4XP)",
    server1: "ForexPlace-Demo Server",
    server2: "ForexPlace-Main Server",
    server3: "ForexPlace-Main Server 2",
    server4: "ForexPlace-Main Server 3",
    server5: "ForexPlace-Main Server 1",
    value: "Forex Place (4XP)",
  },
  {
    label: "Forex Prince",
    server1: "ICGlobal-Demo",
    server2: "ICGlobal-Live",
    value: "Forex Prince",
  },
  {
    label: "forex service",
    server1: "ForexService-Demo",
    server2: "ForexService-Server",
    value: "forex service",
  },
  {
    label: "Forex Trading Edge",
    server1: "Forex.comUK-Demo(R)",
    server2: "Forex.comUK-Live(R)",
    value: "Forex Trading Edge",
  },
  {
    label: "Forex Trend",
    server1: "ForexTrend-Trade7",
    server2: "ForexTrend-Trade5",
    server3: "Skopalino-Server",
    value: "Forex Trend",
  },
  {
    label: "FOREX UKRAINE",
    server1: "FOREX-Server",
    value: "FOREX UKRAINE",
  },
  {
    label: "Forex-3D",
    server1: "RMSFameliaCo-Live",
    server2: "RMSFameliaCo-Demo",
    value: "Forex-3D",
  },
  {
    label: "Forex-Market",
    server1: "CapitalInvest-Real Expert",
    server2: "CapitalInvest-Demo",
    server3: "CapitalInvest-Real",
    server4: "Real Expert",
    server5: "Demo Expert",
    value: "Forex-Market",
  },
  {
    label: "Forex-Metal",
    server1: "Dunboyne-Demo Server",
    server2: "Dunboyne-Production",
    value: "Forex-Metal",
  },
  {
    label: "Forex.CH",
    server1: "GCI-Live",
    value: "Forex.CH",
  },
  {
    label: "Forex.com",
    server1: "Forex.com-Demo",
    server2: "Forex.com-Live",
    server3: "Forex.com-Server",
    server4: "Forex.com-Demo(R)",
    server5: "Forex.com-Live(R)",
    server6: "Forex.comUK-Demo(R)",
    server7: "FOREX.comJapan-Demo(R)",
    server8: "FOREX.comJapan-Live(R)",
    server9: "FOREX.comJapan-Live 1",
    server10: "Forex.com-Live 1",
    server11: "Forex.com-Live 2",
    server12: "FOREX.comJapan-Live 4",
    server13: "Forex.com-Live 5",
    server14: "Forex.comUK-Live 1",
    server15: "Forex.comUK-Live 2",
    server16: "Forex.comUK-Live 3",
    server17: "Forex.com-Demo(I)",
    server18: "FOREX.comAU-Demo(R)",
    server19: "FOREX.comAU-Live 6",
    server20: "FOREX.com-Live 7",
    server21: "Forex.com-Live 3",
    server22: "FOREX.comJapan-Live 8",
    server23: "Forex.com-Live 6",
    server24: "FOREX.comJapan-Live 12",
    server25: "Forex.comCA-Live 11",
    server26: "Forex.com-Live 9",
    server27: "FOREX.comCA-Demo",
    server28: "Forex.comUK-Demo 5",
    server29: "Forex.com-Demo 3",
    server30: "FOREX.com-Live 15",
    server31: "Forex.com-Live 13",
    server32: "FOREX.com-Live16",
    server33: "FOREX.comJapan-Demo(I)",
    server34: "Forex.comUK-Demo 106",
    server35: "FOREX.comGlobalCN-Live 126",
    server36: "FOREX.comCA-Demo 129",
    server37: "FOREX.com JP-Live 105",
    server38: "FOREX.com JP-Live 109",
    server39: "FOREX.com JP-Live 110",
    server40: "FOREX.com JP-Demo 104",
    server41: "Forex.com-Live 120",
    server42: "Forex.comUK-Live 112",
    server43: "Forex.com-Demo 106",
    server44: "Forex.comUKLtd-Live 116",
    server45: "FOREX.comGlobal-Demo(R)",
    server46: "Forex.com-Live 125",
    server47: "Forex.com-Live 123",
    server48: "Forex.com-Live 122",
    server49: "Forex.com-Live 121",
    server50: "Forex.com-Live 124",
    server51: "Forex.comUK-Live 115",
    server52: "FOREX.comGlobalCN-Live 25",
    server53: "FOREX.comGlobal-Live 117",
    server54: "FOREX.comGlobal-Live 114",
    server55: "FOREX.comGlobalCN-Live 118",
    server56: "FOREX.comGlobalCN-Live 128",
    server57: "FOREX.comCA-Live 130",
    server58: "FOREX.comGlobalCN-Live 115",
    server59: "FOREX.comGlobalCN-Live 127",
    server60: "Forex.com-Demo 108",
    server61: "FOREX.comCA-Live 11",
    server62: "Forex.com-Demo(R)",
    server63: "Forex.com-Live 17",
    server64: "Forex.com-Live 18",
    server65: "Forex.comUKLtd-Live 19",
    server66: "Forex.comUKLtd-Live 15",
    server67: "FOREX.comCA-Demo(R)",
    server68: "Forex.comUK-Live 6",
    server69: "Forex.comUKLtd-Demo 3",
    server70: "Forex.com-Demo 5",
    server71: "GAINCapitalJapan-Live 4",
    server72: "Forex.com-Live 23",
    server73: "Forex.com-Live 24",
    server74: "Forex.com-Live 22",
    server75: "Forex.comUK-Live 5",
    server76: "FOREX.comGlobalCN-Demo 7",
    server77: "FOREX.comGlobalCN-Live 20",
    server78: "GAINCapitalJapan-Demo 104",
    server79: "GAINCapitalJapan-Demo(I)",
    server80: "GAINCapitalJapan-Live 4",
    server81: "GAINCapitalJapan-Live 8",
    server82: "GAINCapitalJapan-Live 12",
    server83: "GAINCapitalJapan-Live 105",
    server84: "GAINCapitalJapan-Live 105",
    server85: "Forex.comUKLtd-Live 13",
    server86: "FOREX.comGlobal-Live 21",
    server87: "Forex.comUK-Live 114",
    server88: "Forex.comUK-Live 111",
    server89: "Alpari-Standard2",
    value: "Forex.com",
  },
  {
    label: "Forex.ee",
    server1: "NetStockECN-ECN Demo Server",
    server2: "NetStockECN-ECN Live Server",
    server3: "NetStockECN-Real1",
    server4: "NetStockECN-Demo STP",
    value: "Forex.ee",
  },
  {
    label: "Forex359",
    server1: "ElanaTradingAD-Demo",
    server2: "ElanaTradingAD-Real",
    value: "Forex359",
  },
  {
    label: "Forex4you",
    server1: "EGlobalTrade-Cent4",
    server2: "EGlobalTrade-Classic1",
    server3: "EGlobalTrade-Cent5",
    server4: "EGlobalTrade-Cent7",
    server5: "EGlobalTrade-Cent3",
    server6: "EGlobalTrade-Demo",
    server7: "EGlobalTrade-Classic3",
    server8: "EGlobalTrade-Cent8",
    server9: "EGlobalTrade-Cent1",
    server10: "EGlobalTrade-Cent2",
    server11: "EGlobalTrade-Cent6",
    server12: "EGlobal-Cent6",
    server13: "EGlobal-Cent8",
    server14: "EGlobal-Cent7",
    server15: "EGlobal-Classic",
    server16: "EGlobal-Demo",
    server17: "EGlobal-Cent2",
    server18: "EGlobal-Cent1",
    server19: "EGlobal-Pro",
    server20: "EGlobal-Cent3",
    server21: "EGlobal-PAMM",
    server22: "EGlobal-ProSTP",
    server23: "EGlobal-Classic1",
    server24: "EGlobal-Classic2",
    server25: "EGlobal-Classic3",
    server26: "EGlobal-Cent4",
    server27: "EGlobal-Cent5",
    value: "Forex4you",
  },
  {
    label: "Forex500",
    server1: "Forex500-DEMO",
    server2: "Forex500-REAL",
    value: "Forex500",
  },
  {
    label: "ForexBroker",
    server1: "ForexBroker-Real",
    server2: "ForexBroker-Demo",
    value: "ForexBroker",
  },
  {
    label: "ForexCent",
    server1: "UnitedForex-Demo Server",
    server2: "UnitedForex-Real Server",
    value: "ForexCent",
  },
  {
    label: "ForexCT",
    server1: "ForexCT-TNDemo",
    server2: "ForexCT-TNLive",
    value: "ForexCT",
  },
  {
    label: "Forexer",
    server1: "FOREXerLimited-FOREXer",
    value: "Forexer",
  },
  {
    label: "ForexGen",
    server1: "ForexGen-Real",
    server2: "ForexGen-Demo",
    value: "ForexGen",
  },
  {
    label: "ForexHunt",
    server1: "FXH-Demo.com",
    server2: "FXH-Real.com",
    value: "ForexHunt",
  },
  {
    label: "FOREXimf",
    server1: "IMF-Demo",
    server2: "IMF-Server",
    server3: "IMF-Server2",
    server4: "IMF-Server3",
    server5: "IMF-Server4",
    server6: "IntlMitraFutures-Demo",
    server7: "IntlMitraFutures-Live",
    value: "FOREXimf",
  },
  {
    label: "ForexInn",
    server1: "ForexInn-Demo",
    server2: "ForexInn-Real",
    value: "ForexInn",
  },
  {
    label: "Forexland",
    server1: "Forexland-Demo01",
    server2: "Forexland-Live01",
    value: "Forexland",
  },
  {
    label: "ForexLion",
    server1: "ForexLion-Demo",
    server2: "ForexLion-Main",
    value: "ForexLion",
  },
  {
    label: "ForexMart",
    server1: "ForexMart-DemoServer",
    server2: "ForexMart-RealServer",
    value: "ForexMart",
  },
  {
    label: "ForexMeta",
    server1: "FTD-MT4 Demo Server",
    server2: "FTD-MT4 Live Server 2",
    server3: "FTD-MT4 Live Server 3",
    server4: "FTD-MT4 Live Server",
    server5: "FTD-MT4 Live Server 6",
    value: "ForexMeta",
  },
  {
    label: "Forexoma",
    server1: "Forexoma-Demo 3",
    server2: "Forexoma-Live",
    value: "Forexoma",
  },
  {
    label: "FOREXOTODAY",
    server1: "Forexotoday-Live",
    server2: "Forexotoday-Practice",
    server3: "Forexotoday-Live-2",
    value: "FOREXOTODAY",
  },
  {
    label: "ForexPalm",
    server1: "ForexPalm-DemoUS",
    server2: "ForexPalm-LiveUS",
    value: "ForexPalm",
  },
  {
    label: "ForexPixel",
    server1: "ForexPixel-Demo",
    server2: "ForexPixel-Live",
    value: "ForexPixel",
  },
  {
    label: "ForexRiver",
    server1: "ForexRiver-Live",
    server2: "ForexRiver-Demo",
    value: "ForexRiver",
  },
  {
    label: "ForexStart",
    server1: "Forexstart-Demo",
    server2: "Forexstart-Real",
    value: "ForexStart",
  },
  {
    label: "ForexTB",
    server1: "ForexTB-Demo",
    server2: "ForexTB-Live",
    value: "ForexTB",
  },
  {
    label: "FOREXTER",
    server1: "Forexter-Demo",
    server2: "Forexter-Live",
    value: "FOREXTER",
  },
  {
    label: "ForexTime",
    server1: "ForexTime-ECN",
    server2: "ForexTime-ECN-demo",
    server3: "ForexTime-Standard",
    server4: "ForexTime-Standard-demo",
    server5: "ForexTime-Amanah",
    server6: "ForexTime-Cent",
    server7: "ForexTime-Cent-demo",
    server8: "ForexTime-ECN-Zero",
    server9: "ForexTime-Fixed",
    server10: "ForexTime-Fixed-demo",
    server11: "ForexTimeFXTM-Standard",
    server12: "ForexTimeFXTM-Cent",
    server13: "ForexTimeFXTM-ECN-Zero",
    server14: "ForexTimeFXTM-ECN-Zero-Demo",
    server15: "ForexTimeFXTM-Cent-demo",
    server16: "ForexTimeFXTM-Pro",
    server17: "ForexTimeFXTM-ECN",
    server18: "ForexTime-ECN-Zero-demo",
    server19: "ForexTimeFXTM-ECN-demo",
    server20: "ForexTimeFXTM-Standard-demo",
    server21: "ForexTimeFXTM-Pro",
    server22: "ForexTimeFXTM-ECN2",
    server23: "ForexTimeFXTM-Cent2",
    value: "ForexTime",
  },
  {
    label: "ForexTrading",
    server1: "ForexTrading-Demo",
    server2: "ForexTrading-Live",
    value: "ForexTrading",
  },
  {
    label: "ForexTradingPro",
    server1: "PROFTrading-Demo",
    server2: "PROFTrading-Live",
    value: "ForexTradingPro",
  },
  {
    label: "ForexVox",
    server1: "ForexVox-Demo",
    server2: "ForexVox-Demo-HK",
    server3: "ForexVox-Real",
    server4: "ForexVox-Real-HK",
    value: "ForexVox",
  },
  {
    label: "ForexYard",
    server1: "ForexYard-Practice",
    server2: "ForexYard-Live",
    value: "ForexYard",
  },
  {
    label: "FORINVEST",
    server1: "ForInvest-Server",
    value: "FORINVEST",
  },
  {
    label: "Formax",
    server1: "Formax-Demo",
    server2: "Formax-Live",
    server3: "FormaxTrader-Demo",
    server4: "FormaxTrader-Live",
    server5: "FormaxPrime-Demo",
    server6: "FormaxPrime-Live",
    value: "Formax",
  },
  {
    label: "FormulaFX",
    server1: "FormulaFXMT4-Demo",
    server2: "FormulaFXMT4-Live",
    value: "FormulaFX",
  },
  {
    label: "Fort Financial Services",
    server1: "FortFS-Demo",
    server2: "FortFS-Real",
    server3: "FortFS-Real1",
    value: "Fort Financial Services",
  },
  {
    label: "Fortis",
    server1: "Forticultimate-Server",
    value: "Fortis",
  },
  {
    label: "FortisFX",
    server1: "Fortis-Demo",
    server2: "Fortis-Live",
    server3: "FortFS-Real",
    value: "FortisFX",
  },
  {
    label: "Fortrade",
    server1: "ForTrade-Demo01",
    server2: "ForTrade-Real01",
    server3: "GCMAsia-Demo01",
    server4: "GCMAsia-Real01",
    value: "Fortrade",
  },
  {
    label: "Fortress Prime",
    server1: "FortressPrime-Demo",
    server2: "FortressPrime-ECN Live",
    value: "Fortress Prime",
  },
  {
    label: "Fortune International Financial Services",
    server1: "Forexcn-Demo",
    server2: "Forexcn-Live",
    value: "Fortune International Financial Services",
  },
  {
    label: "Fortune Option Xpert",
    server1: "FortuneOX-Demo",
    server2: "FortuneOX-Live",
    value: "Fortune Option Xpert",
  },
  {
    label: "Fortune Prime Global",
    server1: "FortunePrimeGlobal-Demo",
    server2: "FortunePrimeGlobal-Live",
    value: "Fortune Prime Global",
  },
  {
    label: "FourAdvance-Live",
    server1: "FourAdvance-Live",
    server2: "FourAdvance-Demo",
    value: "FourAdvance-Live",
  },
  {
    label: "FoxcelFX",
    server1: "FoxcelIntl-Live",
    server2: "FoxcelIntl-Demo",
    value: "FoxcelFX",
  },
  {
    label: "FoxiTrade",
    server1: "Foxitrade-Demo",
    server2: "Foxitrade-Live",
    value: "FoxiTrade",
  },
  {
    label: "FoxMarkets",
    server1: "FOXMarkets-Demo",
    server2: "FOXMarkets-Live",
    value: "FoxMarkets",
  },
  {
    label: "Foyle Markets",
    server1: "FoyleCapitalManagement-Demo",
    server2: "FoyleCapitalManagement-Real",
    value: "Foyle Markets",
  },
  {
    label: "FP Markets",
    server1: "FPMarkets-Demo",
    server2: "FPMarkets-Live",
    server3: "FPMarkets-Live2",
    server4: "FPMarkets-Live3",
    value: "FP Markets",
  },
  {
    label: "FQSecurities",
    server1: "FQSecurities-Live",
    server2: "FQSecurities-Demo",
    server3: "FQSecurities-LiveUK",
    server4: "FQSecurities-DemoUK",
    value: "FQSecurities",
  },
  {
    label: "Free Pip Trade",
    server1: "FPT-Demo",
    server2: "FPT-Real",
    value: "Free Pip Trade",
  },
  {
    label: "FreedomCFD",
    server1: "FCFDGlobal-DemoUS",
    server2: "FCFDGlobal-LiveUS",
    value: "FreedomCFD",
  },
  {
    label: "FreshForex",
    server1: "FreshForex-Real",
    server2: "FreshForex-Demo",
    server3: "RistonCapital-FreshForex Demo",
    server4: "RistonCapital-FreshForex Real",
    server5: "RistonCapital-Demo",
    server6: "RistonCapital-Real",
    value: "FreshForex",
  },
  {
    label: "FSMsmart",
    server1: "Capzone Invest",
    server2: "FSMSmart-Primary",
    value: "FSMsmart",
  },
  {
    label: "FTM Brokers",
    server1: "FTMBrokers-MT4 Demo",
    server2: "FTMBrokers-Real3",
    server3: "FTMBrokersLLC-Real",
    value: "FTM Brokers",
  },
  {
    label: "FTMO",
    server1: "FFTraderSRO-Demo",
    server2: "FFTraderSRO-Live",
    server3: "FTMO-Server",
    server4: "FTMO-Demo",
    server5: "FTMO-Server2",
    server6: "FTMO-Demo2",
    value: "FTMO",
  },
  {
    label: "FTO Capital",
    server1: "NonaMarketing-Demo",
    server2: "NonaMarketing-Live",
    value: "FTO Capital",
  },
  {
    label: "FUJIFX",
    server1: "FujiInvestment-DemoUK",
    server2: "FujiInvestment-LiveUK",
    value: "FUJIFX",
  },
  {
    label: "Fullerton Markets",
    server1: "FullertonMarkets-Demo",
    server2: "FullertonMarkets-Live",
    server3: "FullertonMarketsInc-Live2",
    server4: "FullertonMarketsInc-Demo2",
    value: "Fullerton Markets",
  },
  {
    label: "Fullwin",
    server1: "FullwinGlobalGroup-Demo",
    server2: "FullwinGlobalGroup-Live",
    value: "Fullwin",
  },
  {
    label: "Funding Talent",
    server1: "FundingTalent-Live",
    server2: "FundingTalent-Demo",
    server3: "FundingTalent2-Live",
    server4: "FundingTalent2-Demo",
    value: "Funding Talent",
  },
  {
    label: "FusionMarkets",
    server1: "FusionMarkets-Demo",
    server2: "FusionMarkets-Live",
    server3: "FusionMarkets-Live 2",
    value: "FusionMarkets",
  },
  {
    label: "Future FX",
    server1: "FutureFX-Demo",
    server2: "FutureFX-Live",
    value: "Future FX",
  },
  {
    label: "Future Pip",
    server1: "FPIP-Demo",
    server2: "FPIP-Live",
    server3: "FUTUREPIP-Institutional",
    value: "Future Pip",
  },
  {
    label: "Futures Galleria",
    server1: "MaxrichGroup-Real",
    value: "Futures Galleria",
  },
  {
    label: "FVP Trade",
    server1: "FVPTrade-Demo",
    server2: "FVPTrade-Live",
    value: "FVP Trade",
  },
  {
    label: "FW Markets",
    server1: "FWMarkets-DemoUS",
    server2: "FWMarkets-LiveUS",
    value: "FW Markets",
  },
  {
    label: "FX Beyond",
    server1: "BeyondSolutions-DEMO",
    server2: "BeyondSolutions-Live",
    value: "FX Beyond",
  },
  {
    label: "FX Choice",
    server1: "FxChoice-Classic Live",
    server2: "FXChoice-Demo",
    server3: "FxChoice-ECN Demo",
    server4: "FxChoice-Classic Demo",
    server5: "FXChoice-Pro Live",
    server6: "FXChoice-Pro Demo",
    value: "FX Choice",
  },
  {
    label: "FX Clearing",
    server1: "FxClearing-Demo",
    server2: "FxClearing-Main",
    server3: "FxClearing-Main2",
    value: "FX Clearing",
  },
  {
    label: "Fx Dana",
    server1: "Dana-Live",
    server2: "Dana-Demo",
    value: "Fx Dana",
  },
  {
    label: "FX Grand",
    server1: "FXGrant-Demo",
    server2: "FXGrant-Live",
    value: "FX Grand",
  },
  {
    label: "FX IntegraBank",
    server1: "IntegralBank-Server",
    value: "FX IntegraBank",
  },
  {
    label: "FX Money World",
    server1: "Fxmoneyworld-Demo",
    server2: "Fxmoneyworld-Live",
    value: "FX Money World",
  },
  {
    label: "FX Next",
    server1: "FXNEXTGLOBAL-DemoUK",
    server2: "FXNEXTGLOBAL-LiveUK",
    value: "FX Next",
  },
  {
    label: "FX Premax",
    server1: "FXPremax-Real2",
    value: "FX Premax",
  },
  {
    label: "Fx Profit",
    server1: "No servers",
    value: "Fx Profit",
  },
  {
    label: "FX Ranex",
    server1: "FxRanex-Server",
    value: "FX Ranex",
  },
  {
    label: "FX Solutions",
    server1: "FXSolutions-Demo Server",
    server2: "FXSolutionsAustralia-Demo Server",
    server3: "FXSolutionsAustralia-Real Server",
    server4: "FXSolutionsUK-Demo Server",
    server5: "FXSolutionsUK-Real Server",
    server6: "FXSolutions-Real Server",
    value: "FX Solutions",
  },
  {
    label: "FX Systems",
    server1: "FXSystems-Demo",
    server2: "FXSystems-Live",
    value: "FX Systems",
  },
  {
    label: "FX Van",
    server1: "FXVan-Demo Server",
    server2: "FXVan-Real Server",
    value: "FX Van",
  },
  {
    label: "FX WildCats",
    server1: "DIS-Demo",
    server2: "DIS-Real-01",
    value: "FX WildCats",
  },
  {
    label: "FX Wonders",
    server1: "FXWonder-DEMO",
    server2: "FXWonder-REAL",
    value: "FX Wonders",
  },
  {
    label: "FX-Edge",
    server1: "FXEDGE-DEMO",
    server2: "FXEDGE-LIVE",
    value: "FX-Edge",
  },
  {
    label: "FX-MATE",
    server1: "BankOfEarth-ST-Real",
    value: "FX-MATE",
  },
  {
    label: "FX88",
    server1: "AnzoCapital-Demo",
    server2: "AnzoCapital-Live",
    value: "FX88",
  },
  {
    label: "FXANDCO",
    server1: "SMTrader-Server",
    value: "FXANDCO",
  },
  {
    label: "FXAsia",
    server1: "FXAsia-Demo",
    server2: "FXAsia-Live",
    value: "FXAsia",
  },
  {
    label: "FXB Trading",
    server1: "FXBTrading-Demo",
    server2: "FXBTrading-Live",
    value: "FXB Trading",
  },
  {
    label: "FxBitCapital",
    server1: "FXBitCapital-Real",
    server2: "FXBitCapital-Demo",
    server3: "FXBitCapital-Live",
    value: "FxBitCapital",
  },
  {
    label: "FXBox",
    server1: "FXBOX-Demo",
    server2: "FXBOX-Real",
    value: "FXBox",
  },
  {
    label: "FxBrew",
    server1: "FxBrew-Demo",
    server2: "FxBrew-Live",
    value: "FxBrew",
  },
  {
    label: "FXBTG",
    server1: "FXBTG-Demo",
    server2: "FXBTG-Main",
    value: "FXBTG",
  },
  {
    label: "FXBV",
    server1: "FXBVGroupLimited-Demo",
    server2: "FXBVGroupLimited-Live",
    server3: "BroadmanVickery-Demo",
    server4: "BroadmanVickery-Live",
    value: "FXBV",
  },
  {
    label: "FXcast",
    server1: "FXcast-NEXTT",
    server2: "Surplus-Demo",
    server3: "Surplus-Real",
    value: "FXcast",
  },
  {
    label: "FXCBS",
    server1: "FienexGroup-Demo Server",
    server2: "FienexGroup-Real Server",
    server3: "FienexGroup-Server1",
    server4: "FienexGroup-Server2",
    value: "FXCBS",
  },
  {
    label: "FXCC",
    server1: "FXCC1-Demo",
    server2: "FXCC1-Live",
    server3: "FXCC-Live",
    server4: "FXCC-Demo",
    server5: "FXCC2-Real Server",
    value: "FXCC",
  },
  {
    label: "FxCentral",
    server1: "FxCentral-Fxcentral 3",
    server2: "FxCentral-Live",
    server3: "FxCentral-server",
    server4: "FxCentral-spreads",
    value: "FxCentral",
  },
  {
    label: "Fxcess",
    server1: "FXCESS-CT01",
    server2: "FXCESS-Demo01",
    server3: "FXCESS-Live01",
    value: "Fxcess",
  },
  {
    label: "FXCH",
    server1: "FECH-Demo",
    server2: "FECH-Server",
    server3: "ForeignExchangeClearingHouse-Live",
    server4: "ForeignExchangeClearingHouse-Demo",
    value: "FXCH",
  },
  {
    label: "FxCitizen",
    server1: "FXCitizen-Demo",
    server2: "Fxcitizen-Server",
    server3: "Fxcitizen-Main",
    server4: "UniverseCitizen-Demo",
    server5: "UniverseCitizen-Main",
    value: "FxCitizen",
  },
  {
    label: "FXCL",
    server1: "FxClearing-Demo",
    server2: "FxClearing-Main",
    server3: "FxClearing-Main2",
    server4: "FXCL-Demo",
    server5: "FXCL-Main2",
    value: "FXCL",
  },
  {
    label: "FXCM",
    server1: "FXCM-USDDemo01",
    server2: "FXCM-EURDemo01",
    server3: "FXCM-EURReal01",
    server4: "FXCM-GBPReal01",
    server5: "FXCM-JPYReal01",
    server6: "FXCM-USDReal01",
    server7: "FXCM-USDReal02",
    server8: "FXCM-USDReal03",
    server9: "FXCM-USDReal04",
    server10: "FXCM-USDReal05",
    server11: "FXCM-USDReal06",
    server12: "FXCM-USDReal07",
    server13: "FXCM-USDReal08",
    server14: "FXCM-USDReal09",
    server15: "FXCM-CADReal01",
    server16: "FXCM-AUDReal01",
    server17: "FXCM-AUDDemo01",
    server18: "FXCM-USDDemo02",
    server19: "FXCM-JPYDemo01",
    server20: "FXCM-GBPDemo01",
    server21: "FXCMSpreadBetting-GBPReal01",
    server22: "FXCM-EURReal02",
    server23: "FXCM-JPYReal02",
    server24: "FXCMSpreadBetting-GBPDemo01",
    server25: "FXCMSpreadBetting-USDReal07",
    server26: "FXCMSpreadBetting-EURReal01",
    server27: "FXCMSB-GBPReal01",
    server28: "FXCM-EURReal01",
    server29: "FXCM-EURReal01",
    value: "FXCM",
  },
  {
    label: "FXCMMENA",
    server1: "MCG-DemoUK",
    server2: "MCG-LiveUK",
    value: "FXCMMENA",
  },
  {
    label: "FxCompany",
    server1: "FxCompany-Demo",
    server2: "FxCompany-Real",
    value: "FxCompany",
  },
  {
    label: "FXD24",
    server1: "FXdirect-Demo",
    server2: "FXdirect-Live",
    server3: "FXdirect-Real",
    value: "FXD24",
  },
  {
    label: "FXDD",
    server1: "FXDD-MT4 Demo Server",
    server2: "FXDD-MT4 Live Server 4",
    server3: "FXDD-MT4 Live Server",
    server4: "FXDD-MT4 Live Server 7",
    server5: "FXDD-MT4 Live Server 5",
    server6: "FXDDMauritius-MT4 Demo Server",
    server7: "FXDDMauritius-MT4 Live Server",
    server8: "FXDDMauritius-MT4 Live Server 2",
    server9: "FXDDMauritius-MT4 Live Server 3",
    server10: "FXDDMauritius-MT4 Live Server 4",
    server11: "FXDDTrading-MT4 Demo Server",
    server12: "FXDDMauritius-MT4 Live Server 8",
    server13: "FXDDTrading-MT4 Live Server",
    server14: "FXDDTrading-MT4 Live Server 8",
    server15: "FXDDTrading-MT4 Live Server 5",
    server16: "FXDDTrading-MT4 Live Server 7",
    server17: "FXDDMalta-MT4 Demo Server",
    server18: "FXDDMalta-MT4 Live Server",
    server19: "FXDD-MT4 Demo Server 2",
    server20: "FXDDMalta-MT4 Live Server 3",
    server21: "FXDDMalta-MT4 Live Server 4",
    server22: "FXDDMalta-MT4 Live Server 2",
    server23: "FXDDMalta-MT4 Live Server 5",
    server24: "FXDDMalta-MT4 Live Server 6",
    server25: "FXDDMalta-MT4 Live Server 9",
    server26: "FXDD-MT4 Live Server 6",
    server27: "FXDDMalta-MT4 Live Server 7",
    server28: "FXDDMalta-MT4 Live Server 10",
    server29: "FXDD-MT4 Live Server 3",
    server30: "FXDD-MT4 Live Server 2",
    value: "FXDD",
  },
  {
    label: "FXDealer.us",
    server1: "FXDealer-MT4 Demo Server",
    server2: "FXDealer-MT4 Live Server 4",
    server3: "FXDealer-MT4 Live Server",
    value: "FXDealer.us",
  },
  {
    label: "FXDM",
    server1: "FXDMMT4-Server1",
    server2: "fxdmtrader.com-Demo",
    server3: "fxdmtrader.com-Server1",
    value: "FXDM",
  },
  {
    label: "Fxedeal",
    server1: "EdealMarketsPtyLtd-DEMO",
    server2: "EdealMarketsPtyLtd-REAL",
    value: "Fxedeal",
  },
  {
    label: "FXEM",
    server1: "FXEmpiremarkets-Demo Server",
    server2: "FXEmpiremarkets-Live 7",
    value: "FXEM",
  },
  {
    label: "FxEthos",
    server1: "FxEthos-LiveUK",
    server2: "FxEthos-Live",
    server3: "FxEthos-Demo",
    server4: "CapitalMarketInvestments-Live",
    value: "FxEthos",
  },
  {
    label: "FXFair",
    server1: "FXFair-Live 8",
    server2: "FXFair.com-Server",
    server3: "FXFair.com-Live",
    server4: "FXFair.com-DEMO",
    server5: "FXFair.com-Live2",
    value: "FXFair",
  },
  {
    label: "FXFinance",
    server1: "FXFINANCE-Demo",
    server2: "FXFINANCE-Real",
    server3: "PrigaFXFINANCE-Real",
    server4: "PrigaFXFINANCE-Demo",
    value: "FXFinance",
  },
  {
    label: "FXFINPRO",
    server1: "FXFINPRO-Demo",
    server2: "FXFINPRO-Real",
    value: "FXFINPRO",
  },
  {
    label: "FxFlame",
    server1: "FFINTERNATIONAL-Demo",
    server2: "FFINTERNATIONAL-Live",
    value: "FxFlame",
  },
  {
    label: "FXFlat",
    server1: "FXFlatMT4-DemoServer",
    server2: "FXFlatMT4-LiveServer",
    value: "FXFlat",
  },
  {
    label: "FXGate",
    server1: "FXGate-Live",
    value: "FXGate",
  },
  {
    label: "FXGC",
    server1: "FXGC-Real",
    server2: "FXGC-Server2",
    value: "FXGC",
  },
  {
    label: "FXGiants BM",
    server1: "FXGiantsBM-Real3",
    server2: "FXGiantsBM-Real8",
    server3: "FXGiantsBM-Demo1",
    server4: "FXGiantsBM-Real9",
    server5: "FXGiantsBM-Real10",
    server6: "FXGiantsBM-Real2",
    server7: "FXGiantsBM-Real5",
    server8: "FXGiantsBM-Real1",
    server9: "FXGiantsBM-Real6",
    server10: "FXGiantsBM-Real4",
    value: "FXGiants BM",
  },
  {
    label: "FXGiants UK",
    server1: "FXGiants-Real4",
    server2: "FXGiants-Real10",
    server3: "FXGiantsUK-Real9",
    server4: "FXGiantsUK-Demo1",
    server5: "FXGiantsUK-Real3",
    server6: "FXGiantsUK-Real4",
    server7: "FXGiantsUK-Real8",
    server8: "FXGiants-Real8",
    server9: "FXGiants-Demo1",
    value: "FXGiants UK",
  },
  {
    label: "FXGIM",
    server1: "TradingTechnologies-Demo",
    server2: "TradingTechnologies-Real",
    value: "FXGIM",
  },
  {
    label: "FXGlobe",
    server1: "Fxglobe-Real",
    server2: "FXGlobe-Demo",
    server3: "FXGlobe-Real",
    value: "FXGlobe",
  },
  {
    label: "FxGlory",
    server1: "FXGlory-Demo Server",
    server2: "FXGlory-Real Server",
    value: "FxGlory",
  },
  {
    label: "FXGM",
    server1: "FXGM-TNDemo",
    server2: "FXGM-TNLive",
    value: "FXGM",
  },
  {
    label: "FXGroup",
    server1: "FXBusinessGroup-Demo",
    server2: "FXBusinessGroup-Live",
    value: "FXGroup",
  },
  {
    label: "FxGrow",
    server1: "FxGrow-Demo",
    server2: "FxGrow-Live",
    value: "FxGrow",
  },
  {
    label: "FXHighSummit",
    server1: "FxHighSummit-Live",
    value: "FXHighSummit",
  },
  {
    label: "FXIDEAL",
    server1: "IdealGlobalTrading-Live",
    server2: "IdealGlobalTrading-Demo",
    value: "FXIDEAL",
  },
  {
    label: "FxInvest",
    server1: "FXInvest-Demo",
    server2: "FXInvest-Real",
    value: "FxInvest",
  },
  {
    label: "FXJE",
    server1: "ForexTrading-CFD-Server",
    value: "FXJE",
  },
  {
    label: "FXKM",
    server1: "ForexKingdomMarkets-Real-01",
    server2: "ForexKingdomMarkets-Demo",
    value: "FXKM",
  },
  {
    label: "FXLider",
    server1: "FXLider-Server",
    value: "FXLider",
  },
  {
    label: "FXlift",
    server1: "FXliftEU-Demo1",
    server2: "FXliftEU-Real3",
    value: "FXlift",
  },
  {
    label: "FXLinked",
    server1: "FXLinked-Demo",
    server2: "FXLinked-Live",
    value: "FXLinked",
  },
  {
    label: "FXliquid",
    server1: "FXLiquidTrader-Demo",
    server2: "FXLiquidTrader-real",
    value: "FXliquid",
  },
  {
    label: "FXLite",
    server1: "FXLite-MT4 DEMO Server",
    server2: "FXLite-MT4 Live Server 2",
    server3: "FXLite-MT4 Live Server",
    value: "FXLite",
  },
  {
    label: "FxLiveCapital",
    server1: "FXLiveCapital-Demo",
    server2: "FxLiveCapital-Real",
    value: "FxLiveCapital",
  },
  {
    label: "FXM Financial Group",
    server1: "FXM-Demo",
    server2: "FXM-Real",
    server3: "FXM-Server",
    value: "FXM Financial Group",
  },
  {
    label: "FxMars",
    server1: "FxMars-Demo",
    server2: "FxMars-Live",
    value: "FxMars",
  },
  {
    label: "FXMISO",
    server1: "FXMISO-Live 2",
    server2: "MISO-Real",
    server3: "MISO-Demo",
    server4: "MISO-Live",
    value: "FXMISO",
  },
  {
    label: "FxNet",
    server1: "FXNet-Real",
    server2: "FXNet-Demo",
    server3: "FXNetLtd-Real",
    server4: "FXNetLtd-Demo",
    value: "FxNet",
  },
  {
    label: "FXNewTech",
    server1: "FXNewtech-Demo",
    server2: "FXNewtech-Live",
    value: "FXNewTech",
  },
  {
    label: "FXNextGen",
    server1: "FXNextGen-Demo",
    server2: "FXNextGen-Real",
    server3: "NAS-Demo",
    value: "FXNextGen",
  },
  {
    label: "fxnz",
    server1: "Abullion-Demo",
    server2: "Abullion-Real",
    value: "fxnz",
  },
  {
    label: "FXonspot",
    server1: "2dots-Live",
    server2: "2dots-Demo",
    value: "FXonspot",
  },
  {
    label: "FxOnUs",
    server1: "FxOnUs-Demo",
    server2: "FxOnUs-Live",
    value: "FxOnUs",
  },
  {
    label: "FXOpen",
    server1: "FXOpen-Demo",
    server2: "FXOpen-Real1",
    server3: "FXOpen-Real2",
    server4: "FXOpen-ECN Demo Server",
    server5: "FXOpen-ECN Live Server",
    server6: "FXOpen-Real3",
    server7: "ecndemo.fxopen.com:443",
    server8: "FXOpen-Competition",
    server9: "FXOpenAU-ECN Demo Server",
    server10: "FXOpenAU-ECN Live Server",
    server11: "ecndemo.fxopen.co.uk",
    server12: "FXOpen-Demo STP",
    server13: "FXOpenUK-ECN Demo Server",
    server14: "FXOpenUK-ECN Live Server",
    server15: "FXOpenAU-Demo STP",
    server16: "FXOpenAU-Real1",
    server17: "FXOpenUK-Real1",
    server18: "FXOpenAU-Real3",
    value: "FXOpen",
  },
  {
    label: "FXOptimax",
    server1: "FXOPTIMAX-DemoUS",
    server2: "FXOPTIMAX-LiveUS",
    server3: "FXOPTIMAX-DemoUS",
    value: "FXOptimax",
  },
  {
    label: "FxOptimum",
    server1: "FOG-Demo",
    server2: "FOG-Server",
    value: "FxOptimum",
  },
  {
    label: "FXORO",
    server1: "FXORO-Server",
    value: "FXORO",
  },
  {
    label: "FXPace",
    server1: "FXPace-Demo1",
    server2: "FXPace-Real1",
    server3: "FXPace-Real2",
    server4: "FXPace-Real4",
    value: "FXPace",
  },
  {
    label: "FXPCM",
    server1: "PCMTrader-Demo",
    server2: "PCMTrader-Live",
    value: "FXPCM",
  },
  {
    label: "FXPGS",
    server1: "FXPGS-MT4 Demo Server 2",
    server2: "FXPGS-MT4 Live Server 5",
    value: "FXPGS",
  },
  {
    label: "FXPIG",
    server1: "FXPIG-Live",
    server2: "FXPIG-Demo",
    server3: "FXPIGECN-ECN Demo Server",
    server4: "FXPIGECN-ECN Live Server",
    server5: "FXPIG.com-NY7 DEMO",
    server6: "FXPIG.com-LD4 LIVE",
    value: "FXPIG",
  },
  {
    label: "FXPM",
    server1: "CFMarketingGroup-Demo",
    server2: "CFMarketingGroup-Live",
    value: "FXPM",
  },
  {
    label: "FXPN",
    server1: "Leverate-Europe",
    value: "FXPN",
  },
  {
    label: "FXPremax",
    server1: "FXPremax-Demo",
    server2: "FXPremax-Real2",
    value: "FXPremax",
  },
  {
    label: "FxPresent",
    server1: "FxPresent-Demo",
    server2: "FxPresent-Live",
    value: "FxPresent",
  },
  {
    label: "FXPrestige",
    server1: "PrestigeVenture-Demo",
    server2: "PrestigeVenture-Live",
    server3: "PrestigeVenture-Real",
    value: "FXPrestige",
  },
  {
    label: "FXPrime 365",
    server1: "PrimeTech-Live",
    server2: "PrimeTech-Demo",
    value: "FXPrime 365",
  },
  {
    label: "FXPrimeBook",
    server1: "PrimeBook-Live",
    server2: "PrimeBook-Demo",
    server3: "PrimeBookInc-LiveUK",
    value: "FXPrimeBook",
  },
  {
    label: "FXPRIMUS",
    server1: "PrimusGlobal-Practice",
    server2: "PrimusGlobal-Live-3",
    server3: "PrimusMarkets-Live-5",
    server4: "PrimusAfrica-Live-5",
    server5: "PrimusAfrica-Live-4",
    server6: "PrimusAfrica-Live-3",
    server7: "PrimusAfrica-Live-2",
    server8: "FXPrimus-Server",
    server9: "FXPrimus-Demo",
    server10: "FXPrimus-Live",
    server11: "FXPrimus-Practice",
    server12: "FXPrimus-Live-2",
    server13: "FXPRIMUS-Live-3",
    server14: "PrimusAfrica-Live",
    server15: "PrimusGlobal-Live",
    server16: "PrimusGlobal-Live-2",
    server17: "PrimusGlobal-Live-4",
    server18: "PrimusMarkets-Live-6",
    server19: "PrimusGlobal-Live-7",
    server20: "PrimusMarkets-Live-2",
    server21: "PrimusMarkets-Live",
    server22: "PrimusMarkets-Live-3",
    server23: "PrimusMarkets-Practice",
    server24: "PrimusMarkets-Live-4",
    value: "FXPRIMUS",
  },
  {
    label: "FxPrivate",
    server1: "FxPrivate-Server",
    value: "FxPrivate",
  },
  {
    label: "FxPro",
    server1: "FxPro.com-Real1",
    server2: "FxPro.com-Real02",
    server3: "FxPro.com-Real03",
    server4: "FxPro.com-Demo",
    server5: "FxPro.com-Demo1",
    server6: "FxPro.com-Demo01",
    server7: "FxPro.com-Demo02",
    server8: "FxPro.com-Demo03",
    server9: "FxPro.com-Real01",
    server10: "FxPro.com-Real02",
    server11: "FxProcom-Demo03",
    server12: "FxPro.com-Real04",
    server13: "FxPro.com-Demo04",
    server14: "FxPro.com-Real05",
    server15: "FxPro.com-Demo05",
    server16: "FxPro.com-Demo06",
    server17: "FxPro.com-Real06",
    server18: "FXChoice-Pro Demo",
    server19: "FXChoice-Classic Demo",
    server20: "FxPro.com-Real07",
    server21: "FxPro.com-Real08",
    server22: "FxPro.com-Real08",
    value: "FxPro",
  },
  {
    label: "FxPulp",
    server1: "FxPulp-FxPulp - Demo",
    server2: "FxPulp-FxPulp - Real",
    value: "FxPulp",
  },
  {
    label: "FXQM",
    server1: "QMGroupLtd-DEMO",
    server2: "QMGroupLtd-LIVE",
    server3: "MGroupLtd-REAL SERVER",
    server4: "QMGroupLtd-REAL SERVER",
    value: "FXQM",
  },
  {
    label: "FXRich",
    server1: "FXRich-Demo",
    server2: "FXRich-Real",
    value: "FXRich",
  },
  {
    label: "FXRising",
    server1: "RisingInternational-Demo",
    server2: "RisingInternational-Live",
    value: "FXRising",
  },
  {
    label: "FXSalt",
    server1: "FXSalt-Demo",
    server2: "PrimeXM-Live",
    server3: "PrimeXM-Live2",
    server4: "FXSALT-LiveUS",
    server5: "RoyalFXT-Demo",
    server6: "FXSALT-DemoUS",
    value: "FXSalt",
  },
  {
    label: "FXSIDE",
    server1: "Fxside-Demo",
    server2: "Fxside-Live",
    server3: "Fxside-Live2",
    value: "FXSIDE",
  },
  {
    label: "FXstart",
    server1: "Forexstart-Real",
    server2: "DigitalDealing-Server",
    server3: "FXstart-Demo",
    server4: "FXstart-Dollar",
    server5: "FXstart-Server",
    server6: "ForexServer-Real",
    server7: "ForexServer-Real",
    value: "FXstart",
  },
  {
    label: "FXSuit",
    server1: "FXSuit-Demo1",
    server2: "FXSuit-Real3",
    server3: "FXSuit-Real4",
    server4: "FXSuit-Real10",
    value: "FXSuit",
  },
  {
    label: "FxSway",
    server1: "FXSway-Demo",
    server2: "FXSway-Live",
    server3: "FxSway-Live4",
    value: "FxSway",
  },
  {
    label: "FXTCR",
    server1: "FXTCR-Demo",
    server2: "FXTCR-Live",
    value: "FXTCR",
  },
  {
    label: "FXTF",
    server1: "FXTF-Demo",
    server2: "FXTF-Live",
    value: "FXTF",
  },
  {
    label: "FXTG",
    server1: "FXTG-Demo Server",
    server2: "FXTG-Main Server 1",
    server3: "FXTG-Main Server 2",
    server4: "FXTG-Main Server 3",
    value: "FXTG",
  },
  {
    label: "FXTM",
    server1: "ForexTimeFXTM-Cent",
    server2: "ForexTimeFXTM-Cent-demo",
    server3: "ForexTimeFXTM-ECN",
    server4: "ForexTimeFXTM-ECN-demo",
    server5: "ForexTimeFXTM-ECN-Zero",
    server6: "ForexTimeFXTM-ECN-Zero-demo",
    server7: "ForexTimeFXTM-Pro",
    server8: "ForexTimeFXTM-Standard",
    server9: "ForexTimeFXTM-Standard-demo",
    server10: "ForexTime-Standard-demo",
    server11: "ForexTime-ECN-Zero",
    server12: "ForexTime-Standard",
    server13: "ForexTime-ECN",
    server14: "ForexTime-ECN-demo",
    server15: "ForexTime-Cent",
    server16: "ForexTime-Cent-demo",
    server17: "ForexTime-Pro",
    server18: "ForexTimeFXTM-ECN2",
    server19: "ForexTimeFXTM-Cent2",
    value: "FXTM",
  },
  {
    label: "FXTPG",
    server1: "FXTPG-Demo",
    server2: "FXTPG-Live",
    value: "FXTPG",
  },
  {
    label: "FXTrader",
    server1: "FXTrader-Demo",
    server2: "FXTrader-Live",
    value: "FXTrader",
  },
  {
    label: "FXtradeweb",
    server1: "FXtradeweb-Demo",
    server2: "FXtradeweb-Live",
    server3: "FXtradeweb-LiveBravo",
    value: "FXtradeweb",
  },
  {
    label: "FXTRADING.com",
    server1: "FXTRADING.com-Demo",
    server2: "FXTRADING.com-Live",
    value: "FXTRADING.com",
  },
  {
    label: "FXTSwiss",
    server1: "FXTSwiss-Server",
    value: "FXTSwiss",
  },
  {
    label: "FXTune",
    server1: "FXTLtd-Server",
    value: "FXTune",
  },
  {
    label: "FxUnited",
    server1: "FxUnited-Demo",
    server2: "FxUnited-Real",
    server3: "FxUnited-Live",
    server4: "FxUnited-Live2",
    value: "FxUnited",
  },
  {
    label: "FXup",
    server1: "FXUPME-Server",
    value: "FXup",
  },
  {
    label: "Fxview",
    server1: "FXView-Demo",
    server2: "FXView-Live",
    value: "Fxview",
  },
  {
    label: "FXVV",
    server1: "FXVV-Demo",
    server2: "FXVV-Live",
    value: "FXVV",
  },
  {
    label: "FXVV",
    server1: "FXVV-Demo",
    server2: "FXVV-Live",
    value: "FXVV",
  },
  {
    label: "FxWinning",
    server1: "FXWinning-Demo",
    server2: "FXWinning-Real",
    value: "FxWinning",
  },
  {
    label: "FXWorldmarkets",
    server1: "FXWM-Demo",
    server2: "FXWM-Live",
    value: "FXWorldmarkets",
  },
  {
    label: "G&C Capital",
    server1: "GCCFX-Demo",
    server2: "GCCFX-Live",
    value: "G&C Capital",
  },
  {
    label: "GAF",
    server1: "GAFutures-Demo Accounts Server",
    server2: "GAFutures-Real Accounts Server",
    server3: "GlobalArthaFutures-Live",
    server4: "GlobalArthaFutures-Demo",
    value: "GAF",
  },
  {
    label: "GAFX",
    server1: "GAFX-BT Asia",
    value: "GAFX",
  },
  {
    label: "Gainsy",
    server1: "GAINSY-Demo",
    server2: "GAINSY-Real",
    value: "Gainsy",
  },
  {
    label: "Gaitame Finest",
    server1: "GaitameFinest-Demo",
    server2: "GaitameFinest-Main",
    server3: "GaitameFinest-S2-Main",
    server4: "GaitameFinest-S2-Demo",
    value: "Gaitame Finest",
  },
  {
    label: "GalaFX",
    server1: "GalaFX-Demo",
    server2: "GalaFX-Live",
    value: "GalaFX",
  },
  {
    label: "Galaxy Brokers",
    server1: "GalaxyBrokers-Demo Server",
    server2: "GalaxyBrokers-Live",
    value: "Galaxy Brokers",
  },
  {
    label: "Gallant Capital Markets",
    server1: "GCMfx-Demo",
    server2: "GCMfx-Server",
    server3: "LiqCon-Demo2",
    server4: "GCMFX-Live",
    server5: "GCMFX-Demo",
    value: "Gallant Capital Markets",
  },
  {
    label: "Gallant FX",
    server1: "GallantFX-Demo2",
    server2: "GallantFX-Live",
    server3: "GallantFX-Demo 3",
    server4: "GallantFX-Gallant Live",
    server5: "GallantFX-MT4 Live",
    server6: "LiqCon2-Demo 3",
    value: "Gallant FX",
  },
  {
    label: "Galleass",
    server1: "GalleassCG-Demo",
    server2: "GalleassCG-Real1",
    value: "Galleass",
  },
  {
    label: "GammaMarkets",
    server1: "Gamma-Server",
    value: "GammaMarkets",
  },
  {
    label: "Gana Trader",
    server1: "GanaTraderIntelliSystem3-Demo",
    server2: "GanaTraderIntelliSystem3-Live",
    value: "Gana Trader",
  },
  {
    label: "GannMarkets",
    server1: "GannMarkets-Demo",
    server2: "GannMarkets-Live",
    value: "GannMarkets",
  },
  {
    label: "Garuda Berjangka",
    server1: "GarudaBerjangka-Demo",
    server2: "GarudaBerjangka-Real",
    value: "Garuda Berjangka",
  },
  {
    label: "Gate Forex",
    server1: "GateForex-Demo 3",
    server2: "GateForex-Live",
    value: "Gate Forex",
  },
  {
    label: "GBE brokers",
    server1: "GBEbrokers-Demo",
    server2: "GBEbrokers-Live",
    value: "GBE brokers",
  },
  {
    label: "GCC Investing",
    server1: "GCCInvestment-Main",
    server2: "GCCInvestment-Demo",
    value: "GCC Investing",
  },
  {
    label: "GCF",
    server1: "GCFFX-Demo",
    server2: "GCFFX-Live",
    value: "GCF",
  },
  {
    label: "GCI Financial",
    server1: "GCIFinancial-Demo",
    server2: "GCI-Demo",
    server3: "GCI-Live",
    server4: "GCIFinancial-Live",
    value: "GCI Financial",
  },
  {
    label: "GCM Forex",
    server1: "GCM-Real3",
    server2: "GCM-Real4",
    server3: "GCM-Demo",
    server4: "GCM-Demo",
    server5: "GCM-Real",
    server6: "GCM-Real2",
    value: "GCM Forex",
  },
  {
    label: "GCM Prime",
    server1: "GCMPrime-Demo",
    server2: "GCMPrime-Live",
    value: "GCM Prime",
  },
  {
    label: "GDFX",
    server1: "GDFX-Server",
    value: "GDFX",
  },
  {
    label: "GDMFX",
    server1: "GDMFX-Demo",
    server2: "GDMFX-Live",
    server3: "GDMFXFE-Demo",
    server4: "GDMFXEU-Live",
    server5: "GDMFXFE-Live",
    value: "GDMFX",
  },
  {
    label: "GedikFX",
    server1: "GedikForex-Demo",
    server2: "GedikForex-Live",
    server3: "GedikForex-GedikFX",
    value: "GedikFX",
  },
  {
    label: "GemForex",
    server1: "OZHS-Live-UK",
    server2: "GemForex-Live",
    server3: "GemForex-Demo",
    server4: "GemForex-Live2",
    server5: "GemTrade3-Live3",
    server6: "GemTrade-Live4",
    server7: "GemTrade-Live5",
    server8: "GemTrade-Live6",
    server9: "GemTrade-Live7",
    server10: "GemTrade-Live2",
    server11: "GemTrade-Live",
    server12: "GemTrade-Demo",
    value: "GemForex",
  },
  {
    label: "Gemini Capital",
    server1: "GeminiCapitalMarkets-Demo",
    server2: "GeminiCapitalMarkets-Live",
    server3: "GeminiCapitalLLC-Live01",
    server4: "GeminiCapitalLLC-Demo",
    value: "Gemini Capital",
  },
  {
    label: "Genesys Private Fund",
    server1: "GenesysPrivate-Demo",
    server2: "GenesysPrivate-Live",
    value: "Genesys Private Fund",
  },
  {
    label: "GeneTrade",
    server1: "GeniusTrading-Demo",
    server2: "GeniusTrading-Live",
    value: "GeneTrade",
  },
  {
    label: "GenexFX",
    server1: "GenexFX-Demo",
    server2: "GenexFX-Live",
    value: "GenexFX",
  },
  {
    label: "Geratsu",
    server1: "Geratsu-MetaTrader 4",
    value: "Geratsu",
  },
  {
    label: "Gerchik & CO",
    server1: "GerchikCo-Demo",
    server2: "GerchikCo-Live",
    value: "Gerchik & CO",
  },
  {
    label: "GFC",
    server1: "GlobalFinancialContracts-Demo",
    server2: "GlobalFinancialContracts-Live 2",
    value: "GFC",
  },
  {
    label: "GFT Forex",
    server1: "GFTForex-Server",
    server2: "GFTForex-Demo 3",
    server3: "LiqCon2-Server-1",
    server4: "GFTForex-Demo",
    value: "GFT Forex",
  },
  {
    label: "GFX",
    server1: "GFXInternational-Demo",
    server2: "GFXInternational-Live",
    value: "GFX",
  },
  {
    label: "GH Wallstreet",
    server1: "GHMarket-Demo",
    server2: "GHMarket-Server",
    value: "GH Wallstreet",
  },
  {
    label: "Ghana FX",
    server1: "GhanaFX-Main",
    server2: "GhanaFX-Demo",
    value: "Ghana FX",
  },
  {
    label: "GIBICO",
    server1: "GIBICo-Demo",
    server2: "GIBICo-Live",
    value: "GIBICO",
  },
  {
    label: "GIFX Capital",
    server1: "GIFX-Demo",
    server2: "GIFX-Live",
    value: "GIFX Capital",
  },
  {
    label: "GIGFX",
    server1: "GIGBrokers-Demo",
    server2: "GIGBrokers-Server",
    value: "GIGFX",
  },
  {
    label: "Gimex",
    server1: "Gimex-NEXTT",
    value: "Gimex",
  },
  {
    label: "GIV CAPITAL",
    server1: "GlobalInvestVentures-DEMO",
    server2: "GlobalInvestVentures-REAL",
    value: "GIV CAPITAL",
  },
  {
    label: "GivTrade",
    server1: "GivTrade-server",
    value: "GivTrade",
  },
  {
    label: "GKFX",
    server1: "GKFXST-Spread Trading Live",
    server2: "GKFX-FX-CFD Live",
    server3: "GKFX-Demo",
    server4: "GKFX-ECN Demo",
    server5: "GKFX-ECN",
    server6: "GKFX-Asia Pacific",
    server7: "GKFX-STP",
    server8: "GKFX-Standard",
    server9: "GKFX-Demo-2",
    server10: "GKFX-Live-5",
    server11: "GKFX-SB-2",
    server12: "GKFX-Live-3",
    server13: "GKFX-SB-1",
    server14: "GKFX-Live-2",
    server15: "GKFX-Demo-1",
    server16: "GKFXPrime-Demo (ECN, STP)",
    server17: "GKFXPrime-Live-1",
    server18: "GKFX-Live-4",
    server19: "GKFXPrime-ECN",
    server20: "GKFXInternet-ECN Russia",
    server21: "GKFXPrime-Live-1.4",
    server22: "TriveFinancial-Demo-2",
    server23: "TriveFinancial-Live-2",
    server24: "TriveFinancial-Live-3",
    server25: "TriveFinancial-Live-5",
    server26: "TriveFinancial-SB-2",
    server27: "GKFXPrime-Live-1.2",
    server28: "GKFXInternet-Demo (ECN, STP) Russia",
    value: "GKFX",
  },
  {
    label: "GKFX Prime",
    server1: "GKFXPrime-Demo-1",
    server2: "GKFXPrime-Demo-2",
    server3: "GKFXPrime-Live-1",
    server4: "GKFXPrime-Live-1.2",
    server5: "GKFX-Demo-2",
    server6: "GKFXPrime-Live-1.4",
    server7: "GKFXPrime-Live-1.3",
    server8: "GKFXPrime-Live-1.5",
    value: "GKFX Prime",
  },
  {
    label: "GKFX Prime CN",
    server1: "GKFXPrime-Demo-1",
    server2: "GKFXPrime-Live-1.2",
    server3: "GKFXPrime-Live-1.3",
    server4: "GKFXPrime-Live-1.4",
    server5: "GKFXPrime-Live-1",
    value: "GKFX Prime CN",
  },
  {
    label: "GLOBAL",
    server1: "JandMGlobalCompany-Demo",
    server2: "JandMGlobalCompany-Live",
    server3: "JandMGlobalInt-Demo",
    server4: "JandMGlobalInt-Live",
    value: "GLOBAL",
  },
  {
    label: "Global Commodities House",
    server1: "GlobalCommoditiesHouse-live",
    server2: "GlobalCommoditiesHouse-Demo",
    value: "Global Commodities House",
  },
  {
    label: "Global FT Market",
    server1: "GFTMALimited-DEMO",
    server2: "GFTMALimited-REAL",
    value: "Global FT Market",
  },
  {
    label: "Global Holdings",
    server1: "GlobalHoldings-Real",
    server2: "GlobalHoldings-Demo",
    value: "Global Holdings",
  },
  {
    label: "Global Intra Berjangka",
    server1: "GlobalIntraBerjangka-Demo",
    server2: "GlobalIntraBerjangka-Live",
    value: "Global Intra Berjangka",
  },
  {
    label: "Global Liquidity Solutions",
    server1: "GLS-Demo",
    server2: "GLS-Live",
    value: "Global Liquidity Solutions",
  },
  {
    label: "Global Premier",
    server1: "GlobalPremier-Demo",
    server2: "GlobalPremier-Live",
    value: "Global Premier",
  },
  {
    label: "Global Prime",
    server1: "GlobalPrime-Demo",
    server2: "GlobalPrime-Live",
    server3: "Integral-Demo",
    value: "Global Prime",
  },
  {
    label: "Global Trading",
    server1: "GTP-Demo",
    server2: "GTP-ECN",
    server3: "GTP-ECN-SERVER",
    server4: "GlobalTrading-LiveUK",
    server5: "GlobalTrading-DemoUK",
    value: "Global Trading",
  },
  {
    label: "Global Venture Financing",
    server1: "GVF-server",
    value: "Global Venture Financing",
  },
  {
    label: "Global-FX",
    server1: "GlobalPartnerLimited-Demo",
    server2: "GlobalPartnerLimited-Live",
    value: "Global-FX",
  },
  {
    label: "GlobalFX",
    server1: "GlobalFX-Server",
    server2: "TradeProServices-Live",
    value: "GlobalFX",
  },
  {
    label: "globalTRADE",
    server1: "LiqCon-Demo2",
    value: "globalTRADE",
  },
  {
    label: "GlobePro",
    server1: "GlobePro-REAL",
    server2: "GlobePro-Demo",
    value: "GlobePro",
  },
  {
    label: "Globex Bank",
    server1: "GlobexBank-Demo",
    server2: "GlobexBank-Real",
    value: "Globex Bank",
  },
  {
    label: "Globex360",
    server1: "Globex360Pty-Demo",
    server2: "Globex360Pty-Real",
    value: "Globex360",
  },
  {
    label: "Globia Wealth",
    server1: "GlobiaWealth-Live",
    server2: "GlobiaWealth-Demo",
    value: "Globia Wealth",
  },
  {
    label: "GloryForex",
    server1: "GloryForexLtd-DemoServer",
    server2: "GloryForexLtd-Server",
    value: "GloryForex",
  },
  {
    label: "GLSpeedFX",
    server1: "GLSpeed-Demo",
    server2: "GLSpeed-Live",
    value: "GLSpeedFX",
  },
  {
    label: "GLX",
    server1: "LiriFinancialLtd-Demo",
    server2: "LiriFinancialLtd-Live3",
    value: "GLX",
  },
  {
    label: "GMG",
    server1: "itexsys-Demo",
    server2: "itexsys-Live",
    value: "GMG",
  },
  {
    label: "GMI",
    server1: "GMI2-Live",
    server2: "GMIEdge-Live05",
    server3: "GMI2-Live",
    server4: "GMI-Live12",
    server5: "GMI-Live10",
    server6: "GMI-Live15",
    server7: "GMI-Live01",
    server8: "GMI-Live13",
    server9: "GMI-Live08",
    server10: "GMI-Demo03",
    server11: "GMI-Live03",
    server12: "GMI-Live04",
    server13: "GMIUK-Live06",
    server14: "GMI-Live10",
    value: "GMI",
  },
  {
    label: "GMI Edge",
    server1: "GMI-Live01",
    server2: "GMI-Demo01",
    server3: "GMI-Live02",
    server4: "GMI-Live03",
    server5: "GMI-Demo03",
    server6: "GMIndex-Demo",
    server7: "GMIndex-Real",
    server8: "GMIEdge-Live13",
    server9: "GMIEdge-Live11",
    server10: "GMI-Live05",
    server11: "GMI-Live08",
    server12: "GMI-Live09",
    server13: "GMI-Live10",
    server14: "GMI-Live11",
    server15: "GMI-Live13",
    server16: "GMI-Live15",
    server17: "GMIEdge-Demo01",
    server18: "GMIEdge-Live01",
    server19: "GMIEdge-Live05",
    server20: "GMIEdge-Live08",
    server21: "GMIEdge-Live09",
    server22: "GMIEdge-Live10",
    value: "GMI Edge",
  },
  {
    label: "GMO Trading",
    server1: "RoyalForexLtd-Demo",
    server2: "RoyalForexLtd-Live",
    value: "GMO Trading",
  },
  {
    label: "GMT Ltd",
    server1: "GMT-Server",
    value: "GMT Ltd",
  },
  {
    label: "GMT Prime",
    server1: "GMTPrime-Demo 1",
    server2: "GMTPrime-Live2",
    server3: "GMTPrimeFx-GMT Server 1",
    value: "GMT Prime",
  },
  {
    label: "GMTINVEST",
    server1: "GmtInvest-Demo",
    server2: "GmtInvest-Real",
    value: "GMTINVEST",
  },
  {
    label: "GNT Capital",
    server1: "GNTCapital-Demo",
    server2: "GNTCapital-Live 2",
    value: "GNT Capital",
  },
  {
    label: "GO Markets",
    server1: "GoMarkets-Demo",
    server2: "GoMarkets-Real 1",
    server3: "GoMarkets-Real 2",
    server4: "Go Markets Pty -Demo",
    server5: "GoMarkets-Real 3",
    server6: "GoMarkets-Live-Europe",
    server7: "GoMarkets-Real 9",
    server8: "GOMarketsLtd-Live",
    server9: "GOMarketsLtd-Demo",
    server10: "GOMarketsSVG-Real 3",
    server11: "GOMarketsMU-Real 2",
    server12: "GOMarketsMU-Demo",
    server13: "GOMarketsMU-Real 1",
    server14: "GOMarketsIntl-Real9",
    server15: "GOMarketsIntl-Live 8",
    server16: "GOMarketsSVG-Demo",
    server17: "GoMarkets-Live UK",
    server18: "GoMarkets-Real 8",
    value: "GO Markets",
  },
  {
    label: "GO4X",
    server1: "GO4X-Demo",
    server2: "GO4X-Live",
    value: "GO4X",
  },
  {
    label: "Goat4x",
    server1: "Goat4X-Demo",
    server2: "Goat4X-Real",
    value: "Goat4x",
  },
  {
    label: "GoDo FX",
    server1: "GoDo-Demo",
    server2: "GoDo-Live",
    value: "GoDo FX",
  },
  {
    label: "GoForex.co.il",
    server1: "Fxglobe-Demo",
    server2: "Fxglobe-Real",
    value: "GoForex.co.il",
  },
  {
    label: "GoForex.com",
    server1: "Goforex-Live",
    server2: "Goforex-Live-EUR",
    server3: "Goforex-Practice",
    server4: "Goforex-Live03",
    server5: "Goforex-Live04",
    value: "GoForex.com",
  },
  {
    label: "GOFX",
    server1: "GOFX-DEMO",
    server2: "GOFX-REAL SERVER",
    value: "GOFX",
  },
  {
    label: "Gold Copytrade",
    server1: "GoldCopyTrade-Live",
    server2: "GoldCopyTrade-Demo",
    value: "Gold Copytrade",
  },
  {
    label: "Gold Tinkle",
    server1: "GenesisBusinessGroup-Live",
    server2: "GenesisBusinessGroup-Demo",
    value: "Gold Tinkle",
  },
  {
    label: "Goldboro",
    server1: "FXRock-Server",
    server2: "Goldboro-Server",
    value: "Goldboro",
  },
  {
    label: "Golden Brokers",
    server1: "No servers",
    value: "Golden Brokers",
  },
  {
    label: "Golden Bullion",
    server1: "GTrader-Demo",
    server2: "GTrader-G Trader Server",
    value: "Golden Bullion",
  },
  {
    label: "Golden Capital FX",
    server1: "GoldenCapitalFX-Demo",
    server2: "GoldenCapitalFX-Real",
    value: "Golden Capital FX",
  },
  {
    label: "Golden Contract",
    server1: "No servers",
    value: "Golden Contract",
  },
  {
    label: "Golden Expert",
    server1: "GoldenExpert-Real",
    server2: "GoldenExpert-Demo",
    server3: "GoldenExpert-Demo",
    value: "Golden Expert",
  },
  {
    label: "Golden Lines",
    server1: "GoldenLines-Server",
    value: "Golden Lines",
  },
  {
    label: "Golden Stone Capital",
    server1: "SimplePrime-GSCFxDemo1",
    server2: "SimplePrime-GSCFxDemo2",
    server3: "SimplePrime-GSCFxReal1",
    server4: "SimplePrime-GSCFxReal2",
    server5: "GSCFX-Demo",
    server6: "GSCFX-Live",
    value: "Golden Stone Capital",
  },
  {
    label: "Golden Trade",
    server1: "GoldenTrade-Real",
    value: "Golden Trade",
  },
  {
    label: "Golden Wave",
    server1: "GoldenWave-Live",
    server2: "GoldenWave-Demo",
    value: "Golden Wave",
  },
  {
    label: "Golden Wave",
    server1: "GoldenWave-Live",
    server2: "GoldenWave-Demo",
    value: "Golden Wave",
  },
  {
    label: "Goldenway",
    server1: "GWMT4-Demo",
    server2: "GWMT4-Live",
    value: "Goldenway",
  },
  {
    label: "Goldenway Japan",
    server1: "FXTF-Demo",
    server2: "FXTF-Live",
    value: "Goldenway Japan",
  },
  {
    label: "Golder Markets",
    server1: "GolderMarkets-Demo",
    server2: "GolderMarkets-Main",
    value: "Golder Markets",
  },
  {
    label: "Goldex Group",
    server1: "GoldexGlobal-Trading",
    value: "Goldex Group",
  },
  {
    label: "GoldFX",
    server1: "GoldFX-Demo",
    server2: "GoldFX-Live",
    value: "GoldFX",
  },
  {
    label: "Goldland Capital Group",
    server1: "GCG-Demo",
    server2: "GCG-Main",
    value: "Goldland Capital Group",
  },
  {
    label: "Goldstein Brokers",
    server1: "GoldsteinBrokers-Demo 1",
    server2: "GoldsteinBrokers-Live",
    value: "Goldstein Brokers",
  },
  {
    label: "Goldstone Global",
    server1: "Goldstone-Demo",
    server2: "Goldstone-Live",
    server3: "GoldstoneGlobalCapital-Live",
    server4: "GSAUGlobal-Trading",
    value: "Goldstone Global",
  },
  {
    label: "Goldwell Capital",
    server1: "GoldwellCapital-Demo",
    server2: "GoldwellCapital-Live2",
    server3: "GoldwellCapital-Live3",
    value: "Goldwell Capital",
  },
  {
    label: "GOOD ENOUGH FX",
    server1: "GoodEnoughTreasure-Asia 4",
    server2: "GoodEnoughTreasure-Demo",
    value: "GOOD ENOUGH FX",
  },
  {
    label: "Got Money FX",
    server1: "GotMoneyFX-MT4-IM01",
    server2: "GotMoneyFX-MT4-IS01",
    server3: "GotMoneyFX-VIP",
    value: "Got Money FX",
  },
  {
    label: "GPP Markets",
    server1: "GPPMarkets-Demo",
    server2: "GPPMarkets-Live",
    value: "GPP Markets",
  },
  {
    label: "Graalia",
    server1: "Graalia-DemoUS",
    server2: "Graalia-LiveUS",
    value: "Graalia",
  },
  {
    label: "Grafton Capital Markets",
    server1: "Grafton-Demo",
    server2: "Grafton-Live",
    value: "Grafton Capital Markets",
  },
  {
    label: "Grand Ages Precious Metals Limited",
    server1: "GrandAges-Demo",
    server2: "GrandAges-Live",
    value: "Grand Ages Precious Metals Limited",
  },
  {
    label: "Grand Banks FX",
    server1: "GBFX-Server",
    value: "Grand Banks FX",
  },
  {
    label: "Grand Trade Exchange",
    server1: "GrandMarshall-Demo",
    server2: "GrandMarshall-Main",
    value: "Grand Trade Exchange",
  },
  {
    label: "GrandCapital",
    server1: "GrandCapital-Demo",
    server2: "GrandCapital-Real",
    server3: "GrandCapital-Server",
    server4: "AxiTrader-US03-Demo",
    server5: "ICMarkets-Demo01",
    server6: "Alpari-Standard3",
    server7: "ICMarkets-Demo02",
    server8: "FBS-Real-3",
    server9: "XMGlobal-Real 8",
    server10: "XMGlobal-Real 26",
    server11: "ICMarkets-Live11",
    server12: "GrandCapital-Server-1",
    server13: "GrandCapital-Server-2",
    value: "GrandCapital",
  },
  {
    label: "GrapheneFX",
    server1: "Graphene-Server",
    value: "GrapheneFX",
  },
  {
    label: "Gravity Market",
    server1: "GravityMLLC-Demo",
    server2: "GravityMLLC-Live",
    value: "Gravity Market",
  },
  {
    label: "Great Wall",
    server1: "GreatWall-Demo",
    server2: "GreatWall-Live",
    value: "Great Wall",
  },
  {
    label: "Greenvault",
    server1: "Greenvault-Demo",
    server2: "Greenvault-Live",
    value: "Greenvault",
  },
  {
    label: "GreenWaveX",
    server1: "GreenWaveX-Demo",
    server2: "GreenWaveX-Real",
    value: "GreenWaveX",
  },
  {
    label: "Grinta Invest",
    server1: "GrintaInvest-Demo",
    server2: "GrintaInvest-Real",
    value: "Grinta Invest",
  },
  {
    label: "GRM FX",
    server1: "GreatMotivationIntl-Live",
    server2: "GreatMotivationIntl-Demo",
    value: "GRM FX",
  },
  {
    label: "Grow Brokers",
    server1: "GrowBrokers-Server",
    value: "Grow Brokers",
  },
  {
    label: "GS",
    server1: "GS-Demo",
    server2: "GS-Live",
    value: "GS",
  },
  {
    label: "GSCFX",
    server1: "GSCFinancialService-Demo",
    server2: "GSCFinancialService-Live",
    value: "GSCFX",
  },
  {
    label: "GSDO",
    server1: "GSDeepOcean-Demo",
    server2: "GSDeepOcean-Live",
    server3: "GSDeepOceanLtd-Demo",
    server4: "GSDeepOceanLtd-Server",
    server5: "GSDOGroup-Demo",
    server6: "GSDOGroup-Server",
    value: "GSDO",
  },
  {
    label: "GSG",
    server1: "GSG-Demo",
    server2: "GSG-Live",
    value: "GSG",
  },
  {
    label: "GSI Markets",
    server1: "InterGlobal-Demo",
    server2: "InterGlobal-Real",
    server3: "TradeTech-Demo",
    server4: "TradeTech-Main",
    server5: "PlatinumWorld-Main",
    value: "GSI Markets",
  },
  {
    label: "GSM",
    server1: "GSM-Demo",
    server2: "GSM-Live",
    value: "GSM",
  },
  {
    label: "GT Forex",
    server1: "ShiftForex-Real2",
    value: "GT Forex",
  },
  {
    label: "GTC",
    server1: "GTCGlobalTrade-Demo",
    server2: "GTCGlobalTrade-Live",
    value: "GTC",
  },
  {
    label: "GTCFX",
    server1: "GTCGlobalTrade-Demo",
    server2: "GTCGlobalTrade-Live",
    value: "GTCFX",
  },
  {
    label: "GTFFXONLINE",
    server1: "GTFFXONLINE-Demo",
    server2: "GTFFXONLINE-LIVE",
    value: "GTFFXONLINE",
  },
  {
    label: "GTH FX",
    server1: "GTHFX-Demo",
    server2: "GTHFX-LIVE",
    value: "GTH FX",
  },
  {
    label: "GTLtradeup",
    server1: "GTLAustralia-Demo",
    server2: "GTLAustralia-Live",
    value: "GTLtradeup",
  },
  {
    label: "GULF Brokers",
    server1: "GULFBROKERS1-Demo",
    server2: "GULFBROKERS1-Real",
    value: "GULF Brokers",
  },
  {
    label: "Gulliver FX",
    server1: "GulliverFX-Demo",
    server2: "GulliverFX-Server",
    value: "Gulliver FX",
  },
  {
    label: "GVSFX",
    server1: "GoldenYearVentures-Demo",
    server2: "GoldenYearVentures-LIVE",
    value: "GVSFX",
  },
  {
    label: "GWFX",
    server1: "Goldenway-Goldenway Demo",
    server2: "Goldenway-Goldenway Live",
    server3: "GWTrader-Live",
    server4: "GWFX-Demo",
    value: "GWFX",
  },
  {
    label: "Hadwins",
    server1: "Hadwins-FXChina",
    server2: "Hadwins-Global Trader",
    value: "Hadwins",
  },
  {
    label: "Halifax Investment Services",
    server1: "HalifaxISL-Demo(R)",
    server2: "HalifaxISL-Live 1",
    server3: "HalifaxPro-Demo",
    server4: "HalifaxPro-Live",
    value: "Halifax Investment Services",
  },
  {
    label: "Halkfx",
    server1: "HalkFX-Demo",
    server2: "HalkFX-Real",
    value: "Halkfx",
  },
  {
    label: "Hamilton",
    server1: "HamiltonInvestments-NoDealingDesk",
    server2: "HamiltonInvestments-Server",
    value: "Hamilton",
  },
  {
    label: "Hanjumarket",
    server1: "HANJUGlobalCapital-Demo",
    server2: "HANJUGlobalCapital-Live",
    value: "Hanjumarket",
  },
  {
    label: "Hankotrade",
    server1: "Hankotrade-Demo",
    server2: "Hankotrade-Live",
    value: "Hankotrade",
  },
  {
    label: "Hanseatic Brokerhouse",
    server1: "HanseaticBrokerhouse-Live",
    server2: "HanseaticBrokerhouse-Demo",
    server3: "NagaMarkets-Live",
    server4: "NagaMarkets-Demo",
    value: "Hanseatic Brokerhouse",
  },
  {
    label: "Hantec Financial",
    server1: "HantecMarketsVu-S2-Main",
    value: "Hantec Financial",
  },
  {
    label: "Hantec Global Ltd",
    server1: "HantecGlobal-Demo",
    server2: "HantecGlobal-Server1",
    server3: "HantecMarketsLtd-Server4",
    value: "Hantec Global Ltd",
  },
  {
    label: "Hantec Markets",
    server1: "HantecMarkets-Demo",
    server2: "HantecMarkets-Server1",
    server3: "HantecMarkets-Server2",
    server4: "HantecMarketsLimited-Server3",
    server5: "HantecNZ-S3-Demo",
    server6: "HantecNZ-S2-Main",
    server7: "HantecMarkets-Demo1",
    server8: "HantecMarkets-Server4",
    server9: "HantecMarketsVu-S2-Demo",
    server10: "HantecMarketsVu-S2-Main",
    server11: "HantecMarketsVu-S3-Demo",
    server12: "HantecMarketsVu-S3-Main",
    server13: "Hantec-S2-Main",
    server14: "HantecMarketsLtd-Demo2",
    server15: "HantecMarketsLtd-Server4",
    value: "Hantec Markets",
  },
  {
    label: "Hantec Markets Australia",
    server1: "HantecMarketsAustralia-S2-Demo",
    server2: "HantecMarketsAustralia-S2-Main",
    value: "Hantec Markets Australia",
  },
  {
    label: "Harbrox",
    server1: "Harborx-Demo",
    server2: "Harborx-Institutional",
    server3: "Harborx-Main",
    value: "Harbrox",
  },
  {
    label: "hardwealth",
    server1: "HW-Server",
    value: "hardwealth",
  },
  {
    label: "Harvest Group",
    server1: "HarvestGroup-Demo",
    server2: "HarvestGroup-Live",
    value: "Harvest Group",
  },
  {
    label: "HAST FOREX",
    server1: "HASTFinancialGroup-Real",
    value: "HAST FOREX",
  },
  {
    label: "HBC Broker",
    server1: "IVABestLtd-Demo",
    server2: "IVABestLtd-Live",
    value: "HBC Broker",
  },
  {
    label: "HDForex",
    server1: "HDF-Demo",
    server2: "HDF-Live2",
    value: "HDForex",
  },
  {
    label: "Heart Forex",
    server1: "HeartCapital-Real",
    server2: "HeartCapital-Demo",
    value: "Heart Forex",
  },
  {
    label: "HEDEFONLINE",
    server1: "Netinvest-Demo",
    server2: "Netinvest-Real",
    value: "HEDEFONLINE",
  },
  {
    label: "Hedge Market Inc.",
    server1: "HedgeMarket-Demo",
    server2: "HedgeMarket-Live",
    value: "Hedge Market Inc.",
  },
  {
    label: "Hedge4x",
    server1: "Hedge4x-AsiaPac Demo",
    server2: "Hedge4x-AsiaPac Live",
    value: "Hedge4x",
  },
  {
    label: "Henordy",
    server1: "Henordy-Demo",
    server2: "Henordy-Live",
    value: "Henordy",
  },
  {
    label: "HermesMarketfx",
    server1: "HermesMarket-DEMO",
    server2: "HermesMarket-LIVE",
    value: "HermesMarketfx",
  },
  {
    label: "Hero FX",
    server1: "HeroFX-Pro-1",
    server2: "HeroFX-Demo",
    value: "Hero FX",
  },
  {
    label: "Hextra Prime",
    server1: "HextraPrime-DEMO",
    server2: "HextraPrime-REAL",
    value: "Hextra Prime",
  },
  {
    label: "Hey Forex",
    server1: "HeyForex-Demo",
    server2: "HeyForex-Live",
    value: "Hey Forex",
  },
  {
    label: "HF Markets",
    server1: "HFMarkets-Demo Server",
    server2: "HFMarkets-Live Server",
    server3: "HFMarkets-Live Server2",
    server4: "HFMarketsSV-Live Server 5",
    server5: "HFMarketsSV-Live Server 4",
    server6: "HFMarketsSV-Live Server 6",
    server7: "HFMarketsSV-Live Server 3",
    server8: "HFMarketsEurope-Live Server",
    server9: "HFMarketsSV-Live Server2",
    server10: "HFMarketsEurope-Demo Server",
    server11: "HFMarketsSV-Demo Server",
    server12: "HFMarketsSV-Live Server 7",
    server13: "HFMarketsKE-Live Server 8",
    server14: "HFMarketsKE-Demo Server 2",
    value: "HF Markets",
  },
  {
    label: "HF Markets EU",
    server1: "HFMarketsEurope-Demo Server 2",
    server2: "HFMarketsEurope-Live Server2",
    value: "HF Markets EU",
  },
  {
    label: "HF Markets SA (PTY)",
    server1: "HFMarketsSA-Demo Server",
    server2: "HFMarketsSA-Live Server 3",
    server3: "HFMarketsSA-Live Server 4",
    server4: "HFMarketsSA-Live Server 5",
    server5: "HFMarketsSA-Live Server",
    server6: "HFMarketsSA-Live Server 6",
    server7: "HFMarketsSA-Live Server 7",
    server8: "HFMarketsSA-Demo",
    server9: "HFMarketsSA1-Live Server 8",
    server10: "HFMarketsSA1-Demo Server 2",
    value: "HF Markets SA (PTY)",
  },
  {
    label: "HF Markets SC",
    server1: "HFMarketsSC-Live Server 7",
    server2: "HFMarketsSC-Demo Server",
    server3: "HFMarketsSC-Live Server 3",
    server4: "HFMarketsSC-Live Server 4",
    server5: "HFMarketsSC-Live Server 5",
    server6: "HFMarketsSC-Live Server",
    server7: "HFMarketsSC-Live Server 6",
    value: "HF Markets SC",
  },
  {
    label: "HF Markets SV",
    server1: "HFMarketsSV-Demo Server",
    server2: "HFMarketsSV-Live Server 3",
    server3: "HFMarketsSV-Live Server 4",
    server4: "HFMarketsSV-Live Server 5",
    server5: "HFMarketsSV-Live Server",
    server6: "HFMarketsSV-Live Server 6",
    server7: "HFMarketsMENA-Live Server2",
    server8: "HFMarketsMENA-Demo Server 2",
    server9: "HFMarketsSV-Live Server 7",
    value: "HF Markets SV",
  },
  {
    label: "HF Markets UK",
    server1: "HFMarketsUK-Demo Server 2",
    server2: "HFMarketsUK-Live Server2",
    value: "HF Markets UK",
  },
  {
    label: "HFGJ",
    server1: "RichChinaIntl-Demo Server",
    server2: "RichChinaIntl-Production Server",
    value: "HFGJ",
  },
  {
    label: "HFT Brokers",
    server1: "HFTBrokers-Demo",
    server2: "HFTBrokers-Live",
    value: "HFT Brokers",
  },
  {
    label: "HFX",
    server1: "HFXIntlBerjangka-Demo",
    server2: "HFXIntlBerjangka-Primary",
    value: "HFX",
  },
  {
    label: "HFX",
    server1: "HFXIntlBerjangka-Demo",
    server2: "HFXIntlBerjangka-Primary",
    value: "HFX",
  },
  {
    label: "HGForex",
    server1: "HGForex-Real3",
    server2: "HGForex-MT4 Demo",
    value: "HGForex",
  },
  {
    label: "HIG",
    server1: "HIG-Demo Accounts Server",
    server2: "HIG-International Server",
    server3: "HIG-Real Accounts Server",
    value: "HIG",
  },
  {
    label: "High Trade FX",
    server1: "PacificTrade-Demo",
    server2: "PacificTrade-Live2",
    server3: "PacificTrade-Live5",
    value: "High Trade FX",
  },
  {
    label: "HighSky",
    server1: "HighSky-Demo",
    server2: "HighSky-Real 2",
    value: "HighSky",
  },
  {
    label: "Hilton Kapital",
    server1: "No servers",
    value: "Hilton Kapital",
  },
  {
    label: "Hippotrade",
    server1: "HippoTrade-hippo-001",
    value: "Hippotrade",
  },
  {
    label: "Hirose Financial UK",
    server1: "HiroseFinancialUK-MT4Demo1",
    server2: "HiroseFinancialUK-MT4Live1",
    server3: "HiroseFinancialLtd-MT4Live1",
    server4: "HiroseFinancialLtd-MT4Demo1",
    value: "Hirose Financial UK",
  },
  {
    label: "Hive Markets",
    server1: "HiveTech-Demo",
    server2: "HiveTech-Live",
    server3: "HiveTech-Live4",
    server4: "HiveTech-Real",
    value: "Hive Markets",
  },
  {
    label: "HiWayFX",
    server1: "HiWayFx-Demo01",
    server2: "HiWayFx-Real01",
    value: "HiWayFX",
  },
  {
    label: "HKDH",
    server1: "HKDAHUI-Demo",
    server2: "HKDAHUI-live",
    value: "HKDH",
  },
  {
    label: "HKMesesCo",
    server1: "HKMesesCo-Demo",
    server2: "HKMesesCo-Live",
    value: "HKMesesCo",
  },
  {
    label: "HLFX",
    server1: "HLFX-Real",
    server2: "HLFX-Demo",
    value: "HLFX",
  },
  {
    label: "HoldingFx",
    server1: "HoldingFx-Demo",
    server2: "HoldingFx-Real1",
    value: "HoldingFx",
  },
  {
    label: "HonorFx",
    server1: "HonorFX-Demo",
    server2: "HonorFX-Live",
    value: "HonorFx",
  },
  {
    label: "Hooforex Markets",
    server1: "HooforexMarkets-Demo",
    server2: "HooforexMarkets-Live",
    server3: "HFMarketsSV-Live",
    server4: "HFMarketsSV-Live Server",
    server5: "HFMarketsSV-Live Server 4",
    server6: "HFMarketsSA-Live Server",
    server7: "HFMarketsSV-Demo Server",
    server8: "HFMarketsSA-Live Server 4",
    server9: "HFMarketsSV-Live Server 3",
    server10: "HFMarketsSV-Live Server 5",
    server11: "HFMarketsEurope-Demo Server 2",
    server12: "HFMarketsSA-Live Server 3",
    value: "Hooforex Markets",
  },
  {
    label: "Horseforex",
    server1: "HorseForex-Demo",
    server2: "HorseForex-Live",
    value: "Horseforex",
  },
  {
    label: "House of Borse",
    server1: "HouseOfBorse-Demo",
    server2: "HouseOfBorse-Live",
    value: "House of Borse",
  },
  {
    label: "House of World Exchange",
    server1: "WorldHouse-Demo",
    server2: "WorldHouse-Live",
    value: "House of World Exchange",
  },
  {
    label: "HQBroker",
    server1: "CapzoneInvest-Primary",
    value: "HQBroker",
  },
  {
    label: "HTFX",
    server1: "HTFXLtd-Demo",
    server2: "HTFXLtd-Live",
    value: "HTFX",
  },
  {
    label: "HTTFx",
    server1: "HTTFXCAPITAL-Demo",
    server2: "HTTFXCAPITAL-Live",
    server3: "HTTFXCAPITAL-Real",
    value: "HTTFx",
  },
  {
    label: "Hugo's Way",
    server1: "HugosWay-Live",
    server2: "HugosWay-Real3",
    server3: "HugosWay-Demo3",
    value: "Hugo's Way",
  },
  {
    label: "Hunter FX",
    server1: "HuntingtonServices-Demo",
    server2: "HuntingtonServices-Live",
    value: "Hunter FX",
  },
  {
    label: "Hustal Global",
    server1: "HustalGlobal-Demo",
    server2: "HustalGlobal-Live",
    value: "Hustal Global",
  },
  {
    label: "HVMarkets",
    server1: "HVMarkets-Live03",
    server2: "HVMarkets-Demo",
    value: "HVMarkets",
  },
  {
    label: "HVTrader",
    server1: "HVTrader-Demo",
    server2: "HVTrader-Live",
    value: "HVTrader",
  },
  {
    label: "HWG Securities",
    server1: "HWGSecurities-Demo",
    server2: "HWGSecurities-Live",
    value: "HWG Securities",
  },
  {
    label: "HY Markets",
    server1: "Henyep-Demo",
    server2: "Henyep-Live",
    value: "HY Markets",
  },
  {
    label: "HYBRIDFX",
    server1: "HybridTrading-Demo",
    server2: "HybridTrading-Real",
    value: "HYBRIDFX",
  },
  {
    label: "HYCM",
    server1: "Henyep-Demo",
    server2: "Henyep-DemoEU",
    server3: "Henyep-Live",
    server4: "HYCM-Live",
    value: "HYCM",
  },
  {
    label: "Hydra Markets",
    server1: "Hydra-Demo",
    server2: "Hydra-ECN Live",
    server3: "HydraMarkets-Live",
    value: "Hydra Markets",
  },
  {
    label: "HyperForex",
    server1: "HyperGlobalTrade-Asia 3",
    server2: "HyperGlobalTrade-Demo",
    value: "HyperForex",
  },
  {
    label: "IamFX",
    server1: "98.129.173.112",
    server2: "IamFX-Live",
    server3: "LiqCon-MT4 Live",
    server4: "LiqCon2-Server",
    server5: "IamFX-Demo",
    value: "IamFX",
  },
  {
    label: "IBCapital",
    server1: "IBCapitalLP-LIVE",
    value: "IBCapital",
  },
  {
    label: "IBCFX",
    server1: "mt4liveibcfxcom-Demo",
    server2: "mt4liveibcfxcom-Real",
    value: "IBCFX",
  },
  {
    label: "IBF Markets",
    server1: "IBFMarkets-Live",
    server2: "IBFMarkets-Demo",
    value: "IBF Markets",
  },
  {
    label: "IBFX",
    server1: "InterbankFX-Demo Accounts",
    server2: "InterbankFX-Demo",
    server3: "InterbankFX-Metals",
    server4: "InterbankFX-Live Accounts",
    server5: "InterbankFX-MT4 Demo Accounts 2",
    server6: "InterbankFX-MT4 Mini Accounts 2",
    server7: "InterbankFX-MT4 Mini Accounts 3",
    server8: "InterbankFX-MT4 Mini Accounts 4",
    server9: "InterbankFX-Server",
    server10: "InterbankFX-MT4 Mini Accounts",
    server11: "InterbankFX-MT4 MM Mini",
    server12: "InterbankFX-MT4 Standard Accounts 2",
    server13: "InterbankFX-MT4 Standard Accounts",
    server14: "IBFX-MT4 Demo-1_5 decimal",
    server15: "IBFX-MT4 Demo-2_4 decimal",
    server16: "IBFX-MT4 Mini-1",
    server17: "IBFX-MT4 Mini-2",
    server18: "IBFX-MT4 Mini-3",
    server19: "IBFX-MT4 Mini-4",
    server20: "IBFX-MT4 Standard-1",
    server21: "IBFX-MT4 Standard-2",
    server22: "IBFX-Beta",
    server23: "IBFX-Demo",
    server24: "IBFX-MT4-Demo1",
    server25: "IBFX-MT4-Demo2",
    server26: "IBFXAU-International Demos",
    server27: "IBFXAU-MT4-IM01",
    server28: "IBFXAU-MT4-IS02",
    server29: "mt4-id01.fxsecure.com:443",
    server30: "IBFXAU-MT4-ID01",
    server31: "IBFXUS-MT4-01",
    server32: "IBFXAU-MT4-IS01",
    server33: "IBFX-MT4-05",
    server34: "IBFX-MT4-04",
    server35: "IBFXAU-MT4-IS03",
    server36: "IBFX-MT4-06",
    server37: "IBFX-MT4-03",
    server38: "IBFX-MT4-01",
    value: "IBFX",
  },
  {
    label: "IBFX Australia",
    server1: "IBFXAU-MT4-01",
    server2: "IBFXAU-MT4-ID01",
    server3: "IBFXAU-MT4-IM01",
    server4: "IBFXAU-MT4-IM02",
    server5: "IBFXAU-MT4-IS01",
    server6: "IBFXAU-MT4-IS02",
    server7: "IBFXAU-MT4-IS03",
    value: "IBFX Australia",
  },
  {
    label: "iBrokers.trade",
    server1: "iBrokers.trade-Server",
    value: "iBrokers.trade",
  },
  {
    label: "IC FX",
    server1: "ICFXCompany-Server",
    value: "IC FX",
  },
  {
    label: "IC Markets",
    server1: "ICMarkets-Live",
    server2: "ICMarkets-Demo",
    server3: "CMAP-Demo",
    server4: "ICMarkets-Live01",
    server5: "ICMarkets-Live04",
    server6: "ICMarkets-Live02",
    server7: "ICMarkets-Demo01",
    server8: "ICMarkets-Live03",
    server9: "ICMarkets-Live05",
    server10: "ICMarkets-Live06",
    server11: "ICMarkets-Demo02",
    server12: "ICMarkets-Live07",
    server13: "ICMarkets-Demo03",
    server14: "ICMarkets-Live08",
    server15: "ICMarkets-Live09",
    server16: "ICMarkets-Live10",
    server17: "ICMarkets-Live11",
    server18: "ICMarketsSC-Live25",
    server19: "ICMarkets-Live25",
    server20: "ICMarketsEU-Live25",
    server21: "ICMarketsSC-Live23",
    server22: "ICMarkets-Live26",
    server23: "ICMarketsSC-Live26",
    server24: "ICMarketsSC-Live27",
    server25: "ICMarketsSC-Demo06",
    server26: "ICMarkets-Live27",
    server27: "ICMarketsSC-Demo01",
    server28: "ICMarketsSC-Live31",
    server29: "ICMarketsSC-Live32",
    server30: "ICMarketsSC-Live33",
    server31: "ICMarketsSC-Live34",
    server32: "ICMarketsSC-Live02",
    server33: "ICMarkets-Live12",
    server34: "ICMarkets-Live14",
    server35: "ICMarkets-Live15",
    server36: "ICMarkets-Live16",
    server37: "ICMarkets-Live17",
    server38: "ICMarkets-Live18",
    server39: "ICMarketsSC-Demo01",
    server40: "ICMarketsSC-Demo02",
    server41: "ICMarketsSC-Demo03",
    server42: "ICMarketsSC-Live01",
    server43: "ICMarketsSC-Live02",
    server44: "ICMarketsSC-Live03",
    server45: "ICMarketsSC-Live04",
    server46: "ICMarketsSC-Live05",
    server47: "ICMarketsSC-Live06",
    server48: "ICMarketsSC-Live07",
    server49: "ICMarketsSC-Live08",
    server50: "ICMarketsSC-Live09",
    server51: "ICMarketsSC-Live10",
    server52: "ICMarketsSC-Live11",
    server53: "ICMarketsSC-Live12",
    server54: "ICMarketsSC-Live14",
    server55: "ICMarketsSC-Live15",
    server56: "ICMarketsSC-Live16",
    server57: "ICMarketsSC-Live17",
    server58: "ICMarketsSC-Live18",
    server59: "ICMarketsEU-Live01",
    server60: "ICMarketsEU-Demo01",
    server61: "ICMarketsEU-Demo02",
    server62: "ICMarketsEU-Demo03",
    server63: "ICMarketsEU-Live02",
    server64: "ICMarketsEU-Live03",
    server65: "ICMarketsEU-Live04",
    server66: "ICMarketsEU-Live05",
    server67: "ICMarketsEU-Live10",
    server68: "ICMarketsEU-Live12",
    server69: "ICMarketsEU-Live14",
    server70: "ICMarketsEU-Live15",
    server71: "ICMarketsEU-Live16",
    server72: "ICMarketsEU-Live17",
    server73: "ICMarketsEU-Live18",
    server74: "ICMarkets-Live19",
    server75: "ICMarketsSC-Live19",
    server76: "ICMarkets-Live20",
    server77: "ICMarketsSC-Live20",
    server78: "ICMarketsEU-Live20",
    server79: "ICMarkets-Live22",
    server80: "ICMarketsSC-Live22",
    server81: "ICMarkets-Live23",
    server82: "ICMarketsSC-Live23",
    server83: "ICMarkets-Live24",
    server84: "ICMarketsSC-Live24",
    server85: "ICMarkets-Demo04",
    server86: "ICMarketsEU-Live23",
    server87: "ICMarketsSC-Demo04",
    value: "IC Markets",
  },
  {
    label: "IC-FX",
    server1: "EquusGroup-Demo",
    server2: "EquusGroup-Live",
    server3: "ICFXCompany-Server",
    value: "IC-FX",
  },
  {
    label: "Ice FX",
    server1: "IceFX-Server",
    value: "Ice FX",
  },
  {
    label: "ICE-FX",
    server1: "IceFX-Server",
    value: "ICE-FX",
  },
  {
    label: "ICG Markets",
    server1: "ICGlobal-Demo",
    server2: "ICGlobal-Live",
    value: "ICG Markets",
  },
  {
    label: "ICM",
    server1: "ICMCapital-Demo",
    server2: "ICMCapital-Real",
    server3: "ICMCapital-LIVE2",
    server4: "ICMCapital-LIVE",
    server5: "ICMCapitalVC-LIVE3",
    server6: "ICMCapitalVC-Demo",
    server7: "ICMCapitalVC-LIVE",
    server8: "ICMCapitalUK-Real",
    server9: "ICMCapitalVC-LIVE2",
    value: "ICM",
  },
  {
    label: "ICM Brokers",
    server1: "ICMBrokers-Demo",
    server2: "ICMBrokers-Live",
    value: "ICM Brokers",
  },
  {
    label: "ICM Trader",
    server1: "ICMTrader-LIVE",
    server2: "ICMTrader-Demo",
    value: "ICM Trader",
  },
  {
    label: "ICMTrading",
    server1: "ICMTrading-Demo",
    server2: "ICMTrading-Live",
    value: "ICMTrading",
  },
  {
    label: "Icon FX",
    server1: "IconTech-US01-Demo",
    server2: "IconTech-US01-Live",
    server3: "IconTech-US07-Live",
    value: "Icon FX",
  },
  {
    label: "ICVector",
    server1: "ICVector-Server",
    value: "ICVector",
  },
  {
    label: "IDS International",
    server1: "IDSIntl-Demo",
    server2: "IDSIntl-Live",
    value: "IDS International",
  },
  {
    label: "IEEMarkets",
    server1: "IEEMarkets-Demo",
    server2: "IEEMarkets-Primary",
    value: "IEEMarkets",
  },
  {
    label: "IEXS",
    server1: "IEXSLLC-Live",
    server2: "IEXSLLC-Demo",
    value: "IEXS",
  },
  {
    label: "IfamDirect",
    server1: "IFAMdirect-Demo",
    server2: "IFAMdirect-REAL",
    value: "IfamDirect",
  },
  {
    label: "IFC Markets",
    server1: "ISecurities-Real",
    server2: "CFHMarkets-Real",
    server3: "IFCMarkets-Real",
    server4: "IFCMarkets-Demo",
    value: "IFC Markets",
  },
  {
    label: "IFM Trade",
    server1: "IFMTrade-Live",
    server2: "IFMTrade-Demo",
    value: "IFM Trade",
  },
  {
    label: "IFS Markets",
    server1: "IFSMarkets-Demo",
    server2: "IFSMarkets-Main Server",
    server3: "IFSMarketsLimited-Main Server",
    server4: "IFSMarketsLimited-Demo",
    value: "IFS Markets",
  },
  {
    label: "IFSFX",
    server1: "IFS-Demo Server",
    server2: "IFS-Production",
    value: "IFSFX",
  },
  {
    label: "IFX Brokers",
    server1: "IFXBrokers-Demo",
    server2: "IFXBrokers-Live",
    value: "IFX Brokers",
  },
  {
    label: "IG",
    server1: "IG-DEMO",
    server2: "IG-LIVE",
    server3: "IG-LIVE2",
    value: "IG",
  },
  {
    label: "IG Index",
    server1: "IG-LIVE",
    server2: "IG-DEMO",
    value: "IG Index",
  },
  {
    label: "IG Markets",
    server1: "IG-DEMO",
    server2: "IG-LIVE",
    value: "IG Markets",
  },
  {
    label: "iGaitame",
    server1: "iGaitame-Trader",
    value: "iGaitame",
  },
  {
    label: "IGkiwi",
    server1: "IG-KIWI-Demo",
    server2: "IG-KIWI-Live",
    value: "IGkiwi",
  },
  {
    label: "IGMFX",
    server1: "IGMForexLtd-Demo",
    server2: "IGMForexLtd-Real",
    value: "IGMFX",
  },
  {
    label: "IGNIS",
    server1: "IgnisGroup-Demo",
    server2: "IgnisGroup-Live",
    value: "IGNIS",
  },
  {
    label: "IGOFX",
    server1: "IGOFX-Demo",
    server2: "IGOFX-Live",
    server3: "IGOGlobal-Live",
    server4: "IGOGlobal-Demo",
    value: "IGOFX",
  },
  {
    label: "IGTFX",
    server1: "No servers",
    value: "IGTFX",
  },
  {
    label: "iHForex",
    server1: "IHForex-Demo",
    server2: "IHForex-Real",
    value: "iHForex",
  },
  {
    label: "IIB",
    server1: "IIBFX-DEMO",
    server2: "IIBFX-SERVER 1",
    value: "IIB",
  },
  {
    label: "IITfinance",
    server1: "TradingTechnologies-Demo",
    server2: "TradingTechnologies-Real",
    value: "IITfinance",
  },
  {
    label: "IKOFX",
    server1: "IKOfx-Demo",
    server2: "IKOfx-Live",
    server3: "IKOFX-ASIA-Demo",
    server4: "IKOFX-ASIA-STP",
    server5: "IKOfx-Main",
    value: "IKOFX",
  },
  {
    label: "Ikon Atlantic",
    server1: "WCI-Demo",
    server2: "WCI-Real",
    server3: "IkonGroup-Demo 2",
    server4: "IkonGroup-Live 2",
    server5: "IkonGroup-Server3",
    value: "Ikon Atlantic",
  },
  {
    label: "Ikon Finance",
    server1: "IKONFL-Demo 2",
    server2: "IKONFL-Demo",
    server3: "IKONFL-Live 2",
    server4: "IKONFL-Live 4",
    server5: "IKONFL-Live",
    server6: "IkonGroup-Live 4",
    value: "Ikon Finance",
  },
  {
    label: "IKON GM – Royal Division",
    server1: "IKONRoyal-Demo",
    server2: "IKONRoyal-Live",
    value: "IKON GM – Royal Division",
  },
  {
    label: "IKON Group",
    server1: "IKONGROUPCORP-Demo",
    server2: "IKONGROUPCORP-Real",
    server3: "IkonCapital-Real",
    server4: "IkonMatrix-Demo 2",
    server5: "IkonMatrix-Demo",
    server6: "IkonGroup-Live 2",
    server7: "IkonGroup-Live",
    value: "IKON Group",
  },
  {
    label: "Ikon Menkul",
    server1: "IKONMenkul-MT4",
    server2: "IKON-Menkul-IKON MENKUL",
    server3: "IKON-Menkul-MT4",
    value: "Ikon Menkul",
  },
  {
    label: "IKTrust",
    server1: "IKTrust-Demo",
    server2: "IKTrust-Live",
    server3: "IKTRUST-Main",
    value: "IKTrust",
  },
  {
    label: "ILQ",
    server1: "ILQAu-Demo",
    server2: "ILQAu-A1 Live",
    server3: "ILQ-M1 Live",
    server4: "ILQ-Demo",
    server5: "ILQAu-A2 Live",
    server6: "ILQ-M3 Demo",
    server7: "ILQUSA-M3 Live",
    server8: "ILQ-M1 Demo",
    server9: "ILQAu1-A1 Live",
    server10: "ILQ-M2 Demo",
    server11: "ILQ-M2 Live",
    server12: "ILQAu-A1 Demo",
    server13: "ILQAu-A2 Demo",
    value: "ILQ",
  },
  {
    label: "IMDFX",
    server1: "imdFX-Demo",
    server2: "imdFX-Main",
    value: "IMDFX",
  },
  {
    label: "IMGFX",
    server1: "IMGFX-Trading",
    value: "IMGFX",
  },
  {
    label: "IMMFX",
    server1: "IMMFX-Demo",
    server2: "IMMFX-Real",
    value: "IMMFX",
  },
  {
    label: "IMMS",
    server1: "IMMS-Demo",
    server2: "IMMS-Live",
    value: "IMMS",
  },
  {
    label: "Impact Tech Ltd",
    server1: "IMPACT-Demo",
    value: "Impact Tech Ltd",
  },
  {
    label: "Improforex",
    server1: "ImproForex-DemoUK",
    server2: "ImproForex-LiveUK",
    value: "Improforex",
  },
  {
    label: "IMS FX",
    server1: "IMSFX-Demo",
    server2: "IMSFX-Live",
    value: "IMS FX",
  },
  {
    label: "IMX",
    server1: "MXIMX-Demo",
    server2: "MXIMX-Server",
    value: "IMX",
  },
  {
    label: "InArabiaFX",
    server1: "InArabiaFX-Demo",
    server2: "InArabiaFX-Live",
    server3: "InArabiaFX-X-Demo",
    value: "InArabiaFX",
  },
  {
    label: "Inbesto",
    server1: "Inbesto-Demo",
    server2: "Inbesto-Real",
    value: "Inbesto",
  },
  {
    label: "Incenteco",
    server1: "Incenteco-Demo",
    server2: "Incenteco-Live",
    value: "Incenteco",
  },
  {
    label: "IncoNeon",
    server1: "IncoNeon-Demo",
    server2: "IncoNeon-Real",
    value: "IncoNeon",
  },
  {
    label: "Indelta",
    server1: "Indelta-Server",
    value: "Indelta",
  },
  {
    label: "IndigoDMA",
    server1: "IndigoDMA-Demo",
    server2: "IndigoDMA-Live",
    value: "IndigoDMA",
  },
  {
    label: "indonsuccess",
    server1: "IndonSuccess-Demo",
    server2: "IndonSuccess-Real",
    value: "indonsuccess",
  },
  {
    label: "Indosukses Futures",
    server1: "IndosuksesFutures-Demo",
    server2: "IndosuksesFutures-Real",
    value: "Indosukses Futures",
  },
  {
    label: "Infin Markets",
    server1: "InfinMarkets-Demo",
    server2: "InfinMarkets-Real",
    value: "Infin Markets",
  },
  {
    label: "Infinitas",
    server1: "InfinitasGlobal-Demo",
    server2: "InfinitasGlobal-Live",
    value: "Infinitas",
  },
  {
    label: "INFINOX Capital",
    server1: "InfinoxCapitalLimited-InfinoxUK",
    server2: "InfinoxCapitalLimited-Live04",
    server3: "InfinoxCapitalLimited-Demo",
    server4: "InfinoxCapitalLimited-InfinoxUK3",
    server5: "InfinoxCapital-DemoBHS",
    server6: "InfinoxLimited-Live05",
    server7: "InfinoxCapital-Live04",
    server8: "InfinoxLimited-Live06",
    server9: "InfinoxCapitalLimited-Live02",
    server10: "InfinoxCapital-Demo02",
    server11: "InfinoxLimited-Live04",
    server12: "InfinoxLimited-Live03",
    server13: "InfinoxCapital-Live03",
    server14: "InfinoxCapitalLimited-Live01",
    server15: "InfinoxCapital-Live05",
    value: "INFINOX Capital",
  },
  {
    label: "InForex",
    server1: "ZetusHoldings-Demo Server",
    server2: "ZetusHoldings-Live Server2",
    value: "InForex",
  },
  {
    label: "INGOT Brokers",
    server1: "IngotBrokers-Demo",
    server2: "IngotBrokers2-Server",
    server3: "IngotBroker2-Demo",
    server4: "INGOTGlobalLtd-Server",
    server5: "INGOTGlobalLtd-Demo",
    value: "INGOT Brokers",
  },
  {
    label: "INMARKETFX",
    server1: "IntegratedMarkets-Demo",
    server2: "IntegratedMarkets-Live",
    value: "INMARKETFX",
  },
  {
    label: "InnovestFX",
    server1: "InnovestSystem-DemoUK",
    server2: "InnovestSystem-LiveUK",
    value: "InnovestFX",
  },
  {
    label: "InovaTrade",
    server1: "Inovatrade-Demo",
    server2: "Inovatrade-Real",
    server3: "Inovatrade-Bank",
    value: "InovaTrade",
  },
  {
    label: "InstaForex",
    server1: "InstaForex-1Demo.com",
    server2: "InstaForex-1Contest.com",
    server3: "ITC-Demo.com",
    server4: "ITC-Live.com",
    server5: "ITC-Singapore.com",
    server6: "InstaForex-Demo.com",
    server7: "InstaForex-USA.com",
    server8: "InstaForex-Cent.com",
    server9: "67.228.75.130:443",
    server10: "InstaForex-Contest.com",
    server11: "InstaForex-Singapore.com",
    server12: "InstaForex-Europe.com",
    server13: "InstaForex-HongKong.com",
    server14: "InstaForex-UK.com",
    server15: "InstaForex-Cent2.com",
    server16: "InstaForex-USA2.com",
    value: "InstaForex",
  },
  {
    label: "Instinct",
    server1: "Instinct-Demo",
    server2: "Instinct-Real",
    value: "Instinct",
  },
  {
    label: "Institutional Liquidity",
    server1: "ILQ-Demo",
    server2: "ILQ-Live",
    value: "Institutional Liquidity",
  },
  {
    label: "IntegerFX",
    server1: "IntegerFx-Demo",
    server2: "IntegerFx-Live",
    value: "IntegerFX",
  },
  {
    label: "Integral",
    server1: "Ulukartal-MetaServer(Main)",
    server2: "IntegralFX-MetaServer(Main)",
    server3: "IntegralFX-MetaServer(Main-2)",
    server4: "Integral-Real",
    value: "Integral",
  },
  {
    label: "IntegralBank",
    server1: "No servers",
    value: "IntegralBank",
  },
  {
    label: "IntegrateFX",
    server1: "INTFX-Demo",
    server2: "INTFX-Live",
    value: "IntegrateFX",
  },
  {
    label: "IntegrityFX",
    server1: "IFX-Demo",
    server2: "IFX-Live",
    value: "IntegrityFX",
  },
  {
    label: "IntelFX",
    server1: "IFX-Demo",
    server2: "IFX-Real",
    value: "IntelFX",
  },
  {
    label: "Intell Financial Markets",
    server1: "Intell-AU01-Demo",
    server2: "Intell-AU01-Live",
    server3: "Intell-US01-Demo",
    server4: "Intell-US02-Live",
    value: "Intell Financial Markets",
  },
  {
    label: "Intelligence Prime Capital",
    server1: "IntelligencePrime-demo",
    server2: "IntelligencePrime-live",
    value: "Intelligence Prime Capital",
  },
  {
    label: "Inter Pan Pasifik Futures",
    server1: "PTIPPF-Demo",
    server2: "PTIPPF-Real",
    server3: "PPI-Real",
    server4: "InterPanPasifik-Real",
    value: "Inter Pan Pasifik Futures",
  },
  {
    label: "Interactive Market",
    server1: "Fastweb-Demo",
    server2: "Fastweb-Live",
    value: "Interactive Market",
  },
  {
    label: "Intercore Financial",
    server1: "IntercoreFinancial-Demo(R)",
    server2: "IntercoreFinancial-Live(R)",
    value: "Intercore Financial",
  },
  {
    label: "International Business Futures",
    server1: "IntBusinessFutures-Demo1",
    server2: "IntBusinessFutures-Live1",
    value: "International Business Futures",
  },
  {
    label: "International PAMM Services",
    server1: "FXI-ECN",
    server2: "FXI-ETF",
    value: "International PAMM Services",
  },
  {
    label: "Interstellar FX",
    server1: "InterStellarFinancial-Demo",
    server2: "InterStellarFinancial-Live",
    value: "Interstellar FX",
  },
  {
    label: "Intertrader",
    server1: "InterTrader-InterTraderDirect-Demo",
    server2: "InterTrader-Live",
    server3: "InterTrader-Demo",
    server4: "InterTrader-Live Pro",
    value: "Intertrader",
  },
  {
    label: "Inti Makmur",
    server1: "IntiMakmur-Live",
    server2: "IntiMakmur-Demo",
    value: "Inti Makmur",
  },
  {
    label: "Intrinite",
    server1: "Intrinite-Real",
    server2: "Intrinite-Demo",
    value: "Intrinite",
  },
  {
    label: "INVAST",
    server1: "InvastGlobal-Live",
    server2: "InvastGlobal-Demo",
    value: "INVAST",
  },
  {
    label: "Invertox LLC",
    server1: "Invertox-Demo",
    server2: "Invertox-Live",
    value: "Invertox LLC",
  },
  {
    label: "Inves2",
    server1: "Inves2-Demo",
    server2: "Inves2-Real",
    value: "Inves2",
  },
  {
    label: "Inveslo",
    server1: "Inveslo-Demo",
    server2: "Inveslo-Real",
    value: "Inveslo",
  },
  {
    label: "Invest FX",
    server1: "InvestFX-Demo",
    server2: "InvestFX-Live",
    value: "Invest FX",
  },
  {
    label: "Invest Life Limited",
    server1: "InvestLife-Demo",
    server2: "InvestLife-Live",
    value: "Invest Life Limited",
  },
  {
    label: "Invest Markets",
    server1: "ForexTB",
    value: "Invest Markets",
  },
  {
    label: "InvestAZ",
    server1: "InvestAZ-Server",
    server2: "InvestAZ-DEMO",
    server3: "InvestAZ-REAL-Turkey",
    server4: "InvestAZ-REAL",
    value: "InvestAZ",
  },
  {
    label: "INVESTIZO",
    server1: "Investizo-Demo",
    server2: "Investizo-Real",
    value: "INVESTIZO",
  },
  {
    label: "Investo Trade",
    server1: "InvestoUniTrade-LIVE",
    server2: "InvestoUniTrade-DEMO",
    value: "Investo Trade",
  },
  {
    label: "InvestorsEurope",
    server1: "IEMT4-Live",
    server2: "IEMT4-Demo",
    value: "InvestorsEurope",
  },
  {
    label: "Investous",
    server1: "F1Markets-Demo",
    server2: "F1Markets-Live",
    value: "Investous",
  },
  {
    label: "InvestPro",
    server1: "GerchikCo-Demo",
    server2: "GerchikCo-Gerchik and Co Ltd",
    server3: "CFHClearing-Live1",
    server4: "InvestPro-Real",
    value: "InvestPro",
  },
  {
    label: "InvestTechFX",
    server1: "InvestTechFx-Server",
    server2: "InvestTechFx-Live",
    server3: "InvestTechFx-Demo",
    value: "InvestTechFX",
  },
  {
    label: "iPForex",
    server1: "iPForex-Server",
    value: "iPForex",
  },
  {
    label: "IPPFX",
    server1: "InsightPipsPro-Demo",
    server2: "InsightPipsPro-Live",
    value: "IPPFX",
  },
  {
    label: "IQ Option",
    server1: "IQ Option",
    value: "IQ Option",
  },
  {
    label: "iQuoto",
    server1: "iQuoto - demo",
    server2: "iQuoto - Live",
    value: "iQuoto",
  },
  {
    label: "IRA Crown",
    server1: "IraCrownGroup-DEMO",
    server2: "IraCrownGroup-LIVE",
    server3: "IraCrown-Demo",
    server4: "IraCrown-Live",
    value: "IRA Crown",
  },
  {
    label: "IRCgroups",
    server1: "IRCgroups-Demo",
    server2: "IRCgroups-Live",
    value: "IRCgroups",
  },
  {
    label: "IronFX",
    server1: "IronFX-Demo1",
    server2: "IronFX-Real1",
    server3: "IronFX-Real2",
    server4: "IronFX-Real3",
    server5: "IronFX-Real4",
    server6: "IronFX-Real5",
    server7: "IronFX-Real6",
    server8: "IronFX-Real7",
    server9: "IronFX-Real8",
    server10: "IronFX-Demo2",
    server11: "IronFX-Demo3",
    server12: "IronFX-Real9",
    server13: "IronFX-Real10",
    server14: "IronFX-Real11",
    server15: "IronFX-Real12",
    server16: "IronFX-Real13",
    server17: "IronFX-Real14",
    server18: "IronFXBM-Real2",
    server19: "IronFXBM-Demo1",
    server20: "IronFXBM-Real3",
    server21: "IronFXBM-Real5",
    server22: "IronFXBM-Real6",
    server23: "IronFXBM-Real4",
    server24: "IronFXBM-Real8",
    server25: "IronFXBM-Real1",
    server26: "IronFXBM-Real9",
    server27: "IronFXBM-Real10",
    value: "IronFX",
  },
  {
    label: "IS Yatirim",
    server1: "TradeMasterFX-Demo",
    server2: "TradeMasterFX-Live1",
    server3: "TradeMasterFX-Live2",
    value: "IS Yatirim",
  },
  {
    label: "is6com",
    server1: "is6com-Demo",
    server2: "is6com-Live",
    server3: "is6com-Live2",
    value: "is6com",
  },
  {
    label: "IS6FX",
    server1: "IS6FX-Live",
    server2: "IS6FX-Demo",
    server3: "IS6Technologies-Demo",
    server4: "IS6Technologies-Live",
    value: "IS6FX",
  },
  {
    label: "ISIGroup",
    server1: "ISIGroup-Server",
    server2: "ISIGroup-Demo",
    server3: "ISIGroup-Real",
    value: "ISIGroup",
  },
  {
    label: "ISIKFX",
    server1: "ISIKFX-Live",
    server2: "ISIKFX-Demo",
    value: "ISIKFX",
  },
  {
    label: "ITCFX",
    server1: "ITCFX-Demo",
    server2: "ITCFX-Real",
    value: "ITCFX",
  },
  {
    label: "itgbroker",
    server1: "IntergratedBrokers-Demo",
    server2: "IntergratedBrokers-Real",
    value: "itgbroker",
  },
  {
    label: "ITI Markets",
    server1: "ITICapital-Demo",
    server2: "ITICapital-Live",
    value: "ITI Markets",
  },
  {
    label: "iTrader",
    server1: "iTrader-Live",
    server2: "iTrader-Demo",
    value: "iTrader",
  },
  {
    label: "IUX Markets",
    server1: "IUXMarket-Live",
    server2: "IUXMarket-Demo",
    value: "IUX Markets",
  },
  {
    label: "IV Markets",
    server1: "IVMarkets-Live",
    server2: "IVMarkets-Demo",
    value: "IV Markets",
  },
  {
    label: "IX Capital Group",
    server1: "InfinoxCapital-Live05",
    server2: "InfinoxCapital-Live03",
    server3: "InfinoxCapital-DemoBHS",
    value: "IX Capital Group",
  },
  {
    label: "IX Securities",
    server1: "IXSecurities-Demo",
    server2: "IXSecurities-Live01",
    value: "IX Securities",
  },
  {
    label: "JadeForex",
    server1: "JadeBelfort-Live1",
    value: "JadeForex",
  },
  {
    label: "JadeFX",
    server1: "JadeFX-Demo2",
    server2: "JadeFX-Live2",
    server3: "JadeFX-Demo",
    server4: "JadeFX-Real",
    value: "JadeFX",
  },
  {
    label: "JadenForex",
    server1: "JadenCenter-Demo",
    server2: "JadenCenter-Live",
    value: "JadenForex",
  },
  {
    label: "JAFX",
    server1: "JAFX-Demo",
    server2: "JAFX-Real3",
    server3: "JAFX-Demo3",
    value: "JAFX",
  },
  {
    label: "Jalatama Artha Berjangka",
    server1: "JalatamaArthaBerjangka-Demo",
    server2: "JalatamaArthaBerjangka-Live",
    value: "Jalatama Artha Berjangka",
  },
  {
    label: "JazeeraFX",
    server1: "Jazeera-Demo",
    server2: "Jazeera-Real2",
    value: "JazeeraFX",
  },
  {
    label: "JBC",
    server1: "JBC-Demo",
    server2: "JBC-Real",
    value: "JBC",
  },
  {
    label: "JCMFX",
    server1: "JCMFX-Live",
    server2: "JCMFX-Online",
    value: "JCMFX",
  },
  {
    label: "JD Capital",
    server1: "JDCapitalFinancialGroup-Asia",
    value: "JD Capital",
  },
  {
    label: "JDR Securities",
    server1: "JDRSecurities-Demo",
    server2: "JDRSecurities-Live",
    value: "JDR Securities",
  },
  {
    label: "JetTrade Markets",
    server1: "No servers",
    value: "JetTrade Markets",
  },
  {
    label: "JFD",
    server1: "JFD-Demo",
    server2: "JFD-Live",
    server3: "JFD-Live02",
    server4: "JFD-Live01",
    server5: "JFD-Demo",
    value: "JFD",
  },
  {
    label: "Jiffix Markets",
    server1: "JFX-Demo",
    server2: "JFX-Live",
    value: "Jiffix Markets",
  },
  {
    label: "Jin Markets",
    server1: "HKHuiJinIntCommondity-Demo",
    server2: "HKHuiJinIntCommondity-Real",
    value: "Jin Markets",
  },
  {
    label: "Jireh Trillions",
    server1: "JirehTrillionsBerjangka-Demo",
    server2: "JirehTrillionsBerjangka-Live",
    server3: "TRILLIONSBERJANGKA-Live",
    value: "Jireh Trillions",
  },
  {
    label: "JJPROFX",
    server1: "JJPro-Demo",
    server2: "JJPro-Live",
    value: "JJPROFX",
  },
  {
    label: "JM Financial",
    server1: "JMFinancialBrokerage-Demo",
    server2: "JMFinancialBrokerage-Live",
    server3: "JMFinancial-Demo",
    server4: "JMFinancial-Real",
    server5: "JMFinancial2-Live",
    server6: "JMFinancial2-Demo",
    value: "JM Financial",
  },
  {
    label: "JMI Brokers",
    server1: "JMIBrokers-Real",
    server2: "JMIBrokers-Demo",
    value: "JMI Brokers",
  },
  {
    label: "JMIFX",
    server1: "JMI-Demo",
    server2: "JMI-Server",
    value: "JMIFX",
  },
  {
    label: "JOEROSS",
    server1: "JoeRossFairTrading-Demo",
    server2: "JoeRossFairTrading-Live",
    value: "JOEROSS",
  },
  {
    label: "JOF Capital Markets",
    server1: "JOFCapitalMarkets-Demo",
    server2: "JOFCapitalMarkets-Live",
    value: "JOF Capital Markets",
  },
  {
    label: "Joinfx",
    server1: "JoinGroupCoLtd-Demo",
    server2: "JoinGroupCoLtd-Live",
    value: "Joinfx",
  },
  {
    label: "JP Markets",
    server1: "JPMarkets-Real",
    server2: "JPHoldings-Demo",
    server3: "JPHoldings-Real",
    server4: "JPMarkets-Demo",
    server5: "JPMarketsSA-Demo",
    server6: "JPMarketsSA-Live",
    value: "JP Markets",
  },
  {
    label: "JPE Forex",
    server1: "JPE-Live",
    value: "JPE Forex",
  },
  {
    label: "JPM INVEST",
    server1: "ECNPool-Demo",
    server2: "ECNPool-REAL",
    value: "JPM INVEST",
  },
  {
    label: "Juno Markets",
    server1: "Juno-Demo",
    server2: "Juno-Live3",
    server3: "JunoMarkets-Live",
    server4: "JunoMarkets-Demo",
    value: "Juno Markets",
  },
  {
    label: "Just Perfect Markets",
    server1: "JPHoldings-Real",
    server2: "JPHoldings-Demo",
    value: "Just Perfect Markets",
  },
  {
    label: "Just Start Trading",
    server1: "JustStartTrading-US01-Live",
    server2: "JustStartTrading-US03-Demo",
    server3: "JustStartTrading-US07-Live",
    server4: "JustStartTrading-US02-Live",
    value: "Just Start Trading",
  },
  {
    label: "Just2Trade",
    server1: "Just2Trade-Demo",
    server2: "Just2Trade-Demo2",
    server3: "Just2Trade-Real",
    server4: "Just2Trade-Real2",
    server5: "Just2Trade-Real3",
    server6: "Just2Trade-Real4",
    value: "Just2Trade",
  },
  {
    label: "JustMarkets",
    server1: "JustForex-Live",
    server2: "JustForex-Demo",
    server3: "JustForex-Live3",
    server4: "JustForex-Live5",
    server5: "JustForex-Live4",
    server6: "JustForex-Live2",
    value: "JustMarkets",
  },
  {
    label: "justperfectmarkets",
    server1: "JustPerfectMarkets-Demo",
    server2: "JustPerfectMarkets-Real",
    value: "justperfectmarkets",
  },
  {
    label: "Justrader",
    server1: "Justrader-Live",
    server2: "Justrader-Demo",
    value: "Justrader",
  },
  {
    label: "KAB",
    server1: "KABKG-Demo",
    server2: "KABKG-Real",
    value: "KAB",
  },
  {
    label: "KachingMarket",
    server1: "KachingMarket-Demo",
    server2: "KachingMarket-Real2",
    value: "KachingMarket",
  },
  {
    label: "Kaiser Broker",
    server1: "KBGL-Live",
    server2: "KBGL-Demo",
    value: "Kaiser Broker",
  },
  {
    label: "Kanuodi",
    server1: "KND-Server",
    server2: "KND-Server-2",
    server3: "KND-Server-3",
    server4: "KND-Server-4",
    server5: "KND-Server-5",
    server6: "KND-Server-6",
    value: "Kanuodi",
  },
  {
    label: "Kapital RS",
    server1: "KapitalRS-Demo01",
    server2: "KapitalRS-Real01",
    value: "Kapital RS",
  },
  {
    label: "KappaFX",
    server1: "KAPPAFX-Demo",
    server2: "KAPPAFX-Main",
    value: "KappaFX",
  },
  {
    label: "Karma Fx",
    server1: "Karma-FX-Demo",
    server2: "Karma-FX-Real",
    value: "Karma Fx",
  },
  {
    label: "Karoll Brokerage",
    server1: "Karoll-Demo",
    server2: "Karoll-Real",
    value: "Karoll Brokerage",
  },
  {
    label: "KayaFX",
    server1: "GammaTechServices-Demo",
    server2: "GammaTechServices-Live",
    value: "KayaFX",
  },
  {
    label: "KAYSER FX",
    server1: "Kayser-Demo",
    server2: "Kayser-Live 8",
    value: "KAYSER FX",
  },
  {
    label: "KDFX",
    server1: "KDFXCompany-Demo",
    server2: "KDFXCompany-Live",
    value: "KDFX",
  },
  {
    label: "Kerford Investments",
    server1: "Kerford-Demo",
    server2: "Kerford-Live",
    server3: "Kfd-Demo",
    server4: "Kfd-Live",
    value: "Kerford Investments",
  },
  {
    label: "Key to Markets",
    server1: "KTM-Demo",
    server2: "KTM-Live",
    server3: "KTMNZ-Demo",
    server4: "KTMNZ-Live",
    server5: "KeyToMarkets-Live",
    server6: "KeyToMarkets-Demo",
    value: "Key to Markets",
  },
  {
    label: "KeyStock",
    server1: "KeystockNuntius-Live03",
    server2: "KeystockNuntius-Practice",
    server3: "Keystock-Demo",
    server4: "Keystock-Live",
    value: "KeyStock",
  },
  {
    label: "KGI ONG Capital",
    server1: "KGI-Demo Server",
    server2: "KGI-Live Server",
    server3: "KGISecurities-Live Server",
    value: "KGI ONG Capital",
  },
  {
    label: "KGIL",
    server1: "KRCCORP-Demo",
    server2: "KRCCORP-Real",
    value: "KGIL",
  },
  {
    label: "Khwezi Trade",
    server1: "Khwezi-DemoBravo",
    server2: "Khwezi-LiveBravo",
    server3: "KhweziFinancial-Demo",
    server4: "KhweziFinancial-Live2",
    value: "Khwezi Trade",
  },
  {
    label: "Kinetic Securities",
    server1: "KS-Demo",
    server2: "KS-Real",
    value: "Kinetic Securities",
  },
  {
    label: "KINGDOM FOREX",
    server1: "KingdomForex-Demo2",
    server2: "KingdomForex-Live2",
    value: "KINGDOM FOREX",
  },
  {
    label: "Kings Road Group",
    server1: "KingsRoadGroup-Demo",
    server2: "KingsRoadGroup-Live",
    value: "Kings Road Group",
  },
  {
    label: "Kingstone Capital",
    server1: "KingstoneFX-Demo 1",
    server2: "KingstoneFX-Live2",
    value: "Kingstone Capital",
  },
  {
    label: "KirikFX",
    server1: "KirikInfoTech-Demo",
    server2: "KirikInfoTech-Real",
    value: "KirikFX",
  },
  {
    label: "Kitco Markets",
    server1: "KitcoMarkets-Asia",
    value: "Kitco Markets",
  },
  {
    label: "Kiwi FX Bank",
    server1: "KiwiFX-Demo",
    server2: "KiwiFX-Live",
    value: "Kiwi FX Bank",
  },
  {
    label: "KlasFX",
    server1: "KlasTrading-Demo",
    server2: "KlasTrading-Real",
    value: "KlasFX",
  },
  {
    label: "Klimex",
    server1: "Klimex-Demo",
    server2: "Klimex-Live",
    value: "Klimex",
  },
  {
    label: "kmgfx",
    server1: "KMGfx-Demo",
    server2: "KMGfx-Real",
    value: "kmgfx",
  },
  {
    label: "Knight Capital",
    server1: "Knight-Demo",
    server2: "Knight-Live",
    value: "Knight Capital",
  },
  {
    label: "Knit FX",
    server1: "KnitFX-Demo",
    server2: "KnitFX-Real",
    value: "Knit FX",
  },
  {
    label: "Kohle Capital Markets",
    server1: "KohleCapitalMarkets-Demo",
    server2: "KohleCapitalMarkets-Live",
    value: "Kohle Capital Markets",
  },
  {
    label: "KOT4X",
    server1: "KOT-Live3",
    server2: "KOT-Live4",
    server3: "KOT-Demo",
    server4: "KOT-Live",
    server5: "KOT-Live2",
    value: "KOT4X",
  },
  {
    label: "KPME",
    server1: "KMPME-Demo",
    server2: "KMPME-Main",
    value: "KPME",
  },
  {
    label: "KRC",
    server1: "KRCCORP-Demo",
    value: "KRC",
  },
  {
    label: "KRC",
    server1: "KRCCORP-Demo",
    value: "KRC",
  },
  {
    label: "KRCNZ Limited",
    server1: "KRCCORP-Demo",
    server2: "KRCCORP-Real",
    value: "KRCNZ Limited",
  },
  {
    label: "Kresna Futures",
    server1: "KresnaFutures-Real",
    value: "Kresna Futures",
  },
  {
    label: "Kresna Futures",
    server1: "KresnaFutures-Real",
    value: "Kresna Futures",
  },
  {
    label: "KT Financial Group",
    server1: "KTFG-Demo",
    server2: "KTFG-Real",
    value: "KT Financial Group",
  },
  {
    label: "Kuber FX",
    server1: "KuberFX-Demo",
    server2: "KuberFX-Live",
    value: "Kuber FX",
  },
  {
    label: "Kumo Markets",
    server1: "KumoMarkets-Live",
    server2: "KumoMarkets-Demo",
    value: "Kumo Markets",
  },
  {
    label: "KVB Kunlun",
    server1: "KVBKunlun-Demo Server",
    server2: "KVBKunlun-Production Server",
    server3: "KVBKunlun-Production Server 2",
    server4: "KVBKunlun-Production Server 1",
    server5: "KVBKunlunNZ-Production Server",
    server6: "KVBPrimeLimited-Live",
    value: "KVB Kunlun",
  },
  {
    label: "KVBPrime",
    server1: "KVBPrimeUK-Demo",
    server2: "KVBPrimeUK-Live",
    server3: "KVBPrimeLimited-Demo",
    server4: "KVBPrimeLimited-Live",
    server5: "KVBPrimeLimited2-Demo",
    server6: "KVBPrimeLimited2-Live",
    value: "KVBPrime",
  },
  {
    label: "Kwakol Markets",
    server1: "KwakolMarketsLtd-Demo",
    server2: "KwakolMarketsLtd-Real",
    value: "Kwakol Markets",
  },
  {
    label: "La Cloche Capital Markets",
    server1: "LCCM-Demo",
    server2: "LCCM-Live",
    value: "La Cloche Capital Markets",
  },
  {
    label: "Lamda Brokers",
    server1: "LamdaBrokers-Live",
    server2: "LamdaBrokers-Demo",
    value: "Lamda Brokers",
  },
  {
    label: "Land FX",
    server1: "LandFX-Demo",
    server2: "LandFX-Live",
    server3: "LandFX-Live3",
    server4: "LandFX-Live2",
    value: "Land FX",
  },
  {
    label: "Lariox",
    server1: "Lariox-DEMO",
    server2: "Lariox-REAL SERVER",
    value: "Lariox",
  },
  {
    label: "Larson&Holz",
    server1: "Larson-Demo",
    server2: "Larson-Live",
    value: "Larson&Holz",
  },
  {
    label: "LatAmFX",
    server1: "LatAm-Demo",
    server2: "LatAm-Live",
    value: "LatAmFX",
  },
  {
    label: "Latitude FX",
    server1: "LatitudeFX-Demo(R)",
    server2: "LatitudeFX-Live(R)",
    value: "Latitude FX",
  },
  {
    label: "LBLV",
    server1: "LBLV-Server",
    value: "LBLV",
  },
  {
    label: "LCG Markets",
    server1: "LCG-Demo",
    server2: "LCG-Live",
    server3: "LCG-Demo Production",
    server4: "LCG-Real01",
    server5: "LCG-Demo01",
    server6: "LCG-Live2",
    server7: "LCG-Demo2",
    server8: "LCG-Live1",
    value: "LCG Markets",
  },
  {
    label: "LCMfx",
    server1: "Lucrorfx-Demo1",
    server2: "Lucrorfx-Live",
    value: "LCMfx",
  },
  {
    label: "LDF Markets",
    server1: "LDFHoldings-Demo",
    server2: "LDFHoldings-Live",
    value: "LDF Markets",
  },
  {
    label: "Lego Market",
    server1: "LegoMarket-DEMO",
    server2: "LegoMarket-REAL SERVER",
    server3: "LegoMarketLLC-Demo",
    server4: "LegoMarketLLC-Live",
    server5: "LegoMarketLLC2-Live",
    value: "Lego Market",
  },
  {
    label: "Leizhen Capital",
    server1: "LeizanCapital-Live1",
    value: "Leizhen Capital",
  },
  {
    label: "Leom Market",
    server1: "LeomMarket-live",
    server2: "LeomMarket-demo",
    value: "Leom Market",
  },
  {
    label: "Leoprime",
    server1: "LEO-Demo",
    server2: "LEO-Live",
    value: "Leoprime",
  },
  {
    label: "Leverate",
    server1: "Leverate-Demo",
    server2: "Leverate-Live",
    server3: "Leverate-Primary",
    server4: "Leverate-Europe",
    server5: "Leverate-Main",
    server6: "Leverate-Real",
    value: "Leverate",
  },
  {
    label: "LeveronFX",
    server1: "LeveronFX-Server",
    value: "LeveronFX",
  },
  {
    label: "Lforex",
    server1: "LinkingGlobalGroup-Live1",
    server2: "LinkingGlobalGroup-Demo",
    value: "Lforex",
  },
  {
    label: "LG Capital",
    server1: "LGCapital-Server",
    value: "LG Capital",
  },
  {
    label: "LGTrading",
    server1: "Birbades-CT01",
    server2: "Birbades-Demo01",
    server3: "Birbades-Live01",
    server4: "LGTrading-Real01",
    value: "LGTrading",
  },
  {
    label: "LiberalFX",
    server1: "CSIFX-Demo",
    server2: "CSIFX-Live",
    server3: "LiberalFX-Demo",
    server4: "LiberalFX-Live",
    value: "LiberalFX",
  },
  {
    label: "Libertex",
    server1: "ForexClub-MT4 Demo Server",
    server2: "ForexClub-MT4 Market Demo Server",
    server3: "ForexClub-MT4 Market Real Server",
    server4: "ForexClub-MT4 Real 2 Server",
    server5: "ForexClub-MT4 Real Server",
    server6: "LibertexCom-MT4 Market Demo Server",
    server7: "LibertexCom-MT4 Market Real 2 Server",
    server8: "LibertexCom-MT4 Market Real Server",
    server9: "ForexClub-MT4 Market Real 2 Server",
    server10: "ForexClub-MT4 Market Real 4 Server",
    value: "Libertex",
  },
  {
    label: "Liberty FX",
    server1: "LibertyFXLimited-Live 4",
    server2: "LibertyFXLimited-Demo",
    value: "Liberty FX",
  },
  {
    label: "Libra FX",
    server1: "Libra-Demo",
    server2: "Libra-Primary",
    value: "Libra FX",
  },
  {
    label: "LiderForex",
    server1: "LiderForex-Server",
    server2: "LiderForex-Demo01",
    server3: "LiderForex-Real01",
    value: "LiderForex",
  },
  {
    label: "LidyaTrade",
    server1: "LidyaTrade-Demo",
    server2: "LidyaTrade-Main",
    value: "LidyaTrade",
  },
  {
    label: "Life Capital",
    server1: "LifeCM-Demo Xchange",
    server2: "LifeCM-Xchange",
    value: "Life Capital",
  },
  {
    label: "LigFX",
    server1: "LIGFX-Demo",
    server2: "LIGFX-Primary",
    server3: "LIGFX-Live",
    value: "LigFX",
  },
  {
    label: "LimaForex",
    server1: "ECN-LIMAFX",
    server2: "ECN2-LIMAFX",
    server3: "DEMO-LIMAFX",
    value: "LimaForex",
  },
  {
    label: "Linthrope",
    server1: "LINTHROPE-Demo",
    server2: "LINTHROPE-Main",
    value: "Linthrope",
  },
  {
    label: "Lionbrokerlimited",
    server1: "LionBrokersLimited-Demo",
    server2: "LionBrokersLimited-Live",
    value: "Lionbrokerlimited",
  },
  {
    label: "Lionstone",
    server1: "Lionstone-Demo",
    server2: "Lionstone-Live",
    value: "Lionstone",
  },
  {
    label: "LiquiBridge",
    server1: "LiquiBridge-Demo",
    server2: "LiquiBridge-Live",
    value: "LiquiBridge",
  },
  {
    label: "LiquidityX",
    server1: "CapitalSecuritiesInv-Live",
    server2: "CapitalSecuritiesInv-Demo",
    value: "LiquidityX",
  },
  {
    label: "Lirunex",
    server1: "Lirunex-Demo-UK",
    server2: "Lirunex-Live-UK",
    server3: "LirunexLtd-Demo",
    server4: "LirunexLtd-Real",
    server5: "LirunexLimited-Demo",
    server6: "LirunexLimited-Live",
    value: "Lirunex",
  },
  {
    label: "Lite Finance",
    server1: "LiteFinance-Cent.com",
    server2: "LiteFinance-Cent2.com",
    server3: "LiteFinance-Cent3.com",
    server4: "LiteFinance-Classic.com",
    server5: "LiteFinance-Classic2.com",
    server6: "LiteFinance-Demo.com",
    server7: "LiteFinance-ECN.com",
    server8: "LiteFinance-ECN2.com",
    server9: "LiteFinance-ECN-PRO.com",
    value: "Lite Finance",
  },
  {
    label: "LiteForex",
    server1: "LiteForex-Demo.com",
    server2: "LiteForex-Classic.com",
    server3: "LiteForex-Cent.com",
    server4: "LiteFinance-ECN.com",
    server5: "LiteForexEU-ECN.com",
    server6: "LiteForex-ECN2.com",
    server7: "LiteForexEU-Classic.com",
    server8: "LiteForexEU-Demo.com",
    server9: "LiteForex-Classic2.com",
    server10: "LiteForex-Cent2.com",
    server11: "LiteForex-ECN-PRO.com",
    server12: "LiteForex-Cent3.com",
    value: "LiteForex",
  },
  {
    label: "LiteForex (Europe)",
    server1: "MIC-Demo.com",
    server2: "LiteForexEU-Classic.com",
    server3: "MIC-STP-Classic-EU.com",
    server4: "MIC-STP-Platinum-EU.com",
    server5: "LiteForexEU-ECN.com",
    server6: "LiteForexEU-Demo.com",
    server7: "LiteForexEU-Live",
    value: "LiteForex (Europe)",
  },
  {
    label: "LizFx",
    server1: "LizGroupltd-Demo",
    server2: "LizGroupltd-Live",
    value: "LizFx",
  },
  {
    label: "LMAX",
    server1: "lmax-Live",
    server2: "lmax-Demo",
    server3: "LMAX-DemoUK",
    server4: "LMAX-LiveUK",
    server5: "LMAXNZ2-DEMO",
    server6: "LMAXNZ2-LIVE",
    server7: "LMAXEU-LiveUK",
    server8: "LMAXEU-DemoUK",
    server9: "LMAXNZ-LiveUK",
    value: "LMAX",
  },
  {
    label: "LMFX",
    server1: "LMFX-Demo Server",
    server2: "LMFX-Live Server 1",
    value: "LMFX",
  },
  {
    label: "Logipip",
    server1: "LogiPipLtd-Demo",
    server2: "LogiPipLtd-Real",
    server3: "LogiPipLtd-Real2",
    value: "Logipip",
  },
  {
    label: "London FX",
    server1: "HubCapital-HubCapital",
    server2: "London-Server",
    value: "London FX",
  },
  {
    label: "London Trading Index",
    server1: "LondonTradingIndex-DEMO",
    server2: "LondonTradingIndex-LIVE",
    value: "London Trading Index",
  },
  {
    label: "Longasia Forex",
    server1: "LongAsiaGroup-Live",
    server2: "LongAsiaGroup-Demo",
    value: "Longasia Forex",
  },
  {
    label: "LonghornFX",
    server1: "Longhorn-Real2",
    server2: "Longhorn-Demo2",
    value: "LonghornFX",
  },
  {
    label: "Lotas Capital",
    server1: "LotasTrading-DEMO",
    server2: "LotasTrading-LIVE",
    value: "Lotas Capital",
  },
  {
    label: "Lotus International",
    server1: "LotusIntl-Demo",
    server2: "LotusIntl-Live",
    value: "Lotus International",
  },
  {
    label: "Lowcost Forex",
    server1: "PHP-Demo",
    server2: "PHP-LiveLiquidity1",
    value: "Lowcost Forex",
  },
  {
    label: "Loyal Forex",
    server1: "Leverate-Demo",
    server2: "PrimeXM-Live",
    value: "Loyal Forex",
  },
  {
    label: "LOYEX",
    server1: "LoyalBank-Demo",
    server2: "LoyalBank-Real5",
    value: "LOYEX",
  },
  {
    label: "LPTrades",
    server1: "LPTrades-Demo",
    server2: "LPTrades-Live",
    value: "LPTrades",
  },
  {
    label: "LQD Markets",
    server1: "LQDMarketsUK-Demo",
    server2: "LQDMarkets-Live 2",
    server3: "LQDMarkets-Live",
    server4: "LQDMarketsUK-Live 3",
    server5: "LQDMarketsUK-Live 2",
    value: "LQD Markets",
  },
  {
    label: "LQDFX",
    server1: "LQDFX-MT4 Demo",
    server2: "LQDFX-Real3",
    server3: "LQD-Real3",
    server4: "LQD-MT4 Demo",
    server5: "MatchTrade-LIVE",
    server6: "LQD1-Live01",
    server7: "LQD1-Demo01",
    server8: "LQD1-Live02",
    server9: "LQDLLC-Live02",
    server10: "LQDLLC-DEMO01",
    value: "LQDFX",
  },
  {
    label: "LUCROR Foreign eXchange",
    server1: "Lucrorfx-Live",
    server2: "Lucrorfx-Demo1",
    value: "LUCROR Foreign eXchange",
  },
  {
    label: "Lupin FX",
    server1: "Lupin-FX-Demo",
    server2: "Lupin-FX-Live",
    value: "Lupin FX",
  },
  {
    label: "LVMexchange",
    server1: "LVMarkets-Demo",
    server2: "LVMarkets-Live",
    value: "LVMexchange",
  },
  {
    label: "Lwin LLC",
    server1: "CPMarketsLtd-Demo",
    server2: "CPMarketsLtd-Live",
    server3: "LwinLLC-Demo",
    server4: "LwinLLC-Live",
    value: "Lwin LLC",
  },
  {
    label: "M2 Forex",
    server1: "M2-M1 Demo",
    server2: "M2-M1 Live",
    server3: "M2-M3 Live",
    value: "M2 Forex",
  },
  {
    label: "M365FX",
    server1: "365FXMarketsLtd-Demo",
    server2: "365FXMarketsLtd-Real",
    value: "M365FX",
  },
  {
    label: "M4Markets",
    server1: "M4Markets-Platform",
    server2: "M4Markets-Live",
    server3: "M4Markets-Demo",
    server4: "M4Markets-Real1",
    server5: "TrinotaMarkets-Demo",
    server6: "TrinotaMarkets-Live",
    value: "M4Markets",
  },
  {
    label: "Magic Compass",
    server1: "MagicCompass-Demo",
    server2: "MagicCompass-Live",
    value: "Magic Compass",
  },
  {
    label: "MAGMarkets",
    server1: "MAGMarkets-Demo",
    server2: "MAGMarkets-Live",
    value: "MAGMarkets",
  },
  {
    label: "Magna Trust",
    server1: "MagnaFX-Demo",
    server2: "MagnaFX-Live",
    value: "Magna Trust",
  },
  {
    label: "MahiFX",
    server1: "MahiFX-Demo",
    server2: "MahiFX-Live",
    value: "MahiFX",
  },
  {
    label: "Make Capital",
    server1: "MakeCapital-Demo02",
    server2: "MakeCapital-Live08",
    value: "Make Capital",
  },
  {
    label: "Mako Global Market",
    server1: "MakoGM-Demo",
    server2: "MakoGM-Real",
    value: "Mako Global Market",
  },
  {
    label: "Maliksi Ltd",
    server1: "Maliksi-Live",
    server2: "Maliksi-Demo",
    value: "Maliksi Ltd",
  },
  {
    label: "MANDAKINYU",
    server1: "Mandakinyu-Asia 3",
    server2: "Mandakinyu-Demo",
    value: "MANDAKINYU",
  },
  {
    label: "Manhattan Global Markets",
    server1: "ManhattanGlobal-Demo",
    server2: "ManhattanGlobal-Live",
    server3: "TTFX-Trading",
    value: "Manhattan Global Markets",
  },
  {
    label: "Manors Group",
    server1: "ManorsGroup-Trade5",
    server2: "Skopalino-Trade7",
    value: "Manors Group",
  },
  {
    label: "MAPS IG",
    server1: "MapsIG-Server",
    value: "MAPS IG",
  },
  {
    label: "MarabelAG",
    server1: "MarabelAG-MT4 Demo",
    server2: "MarabelAG-MT4 Live",
    value: "MarabelAG",
  },
  {
    label: "Marbas Menkul",
    server1: "MARFX-Demo",
    server2: "MARFX-Live",
    value: "Marbas Menkul",
  },
  {
    label: "MarcoFX",
    server1: "MarketContinental-DEMO",
    server2: "MarketContinental-LIVE",
    value: "MarcoFX",
  },
  {
    label: "Mark FX",
    server1: "MarkFX-Live",
    server2: "MarkFX-Demo",
    value: "Mark FX",
  },
  {
    label: "Market Investments",
    server1: "MIA-ECN Live",
    server2: "MIA-ECN Demo",
    value: "Market Investments",
  },
  {
    label: "MARKET.COM CN",
    server1: "Markets.com-Live",
    server2: "Markets.com-Practice",
    server3: "Markets.com-Live02",
    server4: "Markets.com-Live03",
    server5: "STAGlobalInvestments-HK",
    server6: "STAGlobalInvestments-Demo-CN",
    value: "MARKET.COM CN",
  },
  {
    label: "MarketeerFX",
    server1: "MarketeerGroup-Demo",
    server2: "MarketeerGroup-Live",
    server3: "MarketeerGroup-Live",
    value: "MarketeerFX",
  },
  {
    label: "Markets Online",
    server1: "ChandonGroup-Server",
    value: "Markets Online",
  },
  {
    label: "Markets.com",
    server1: "STAGlobalInvestments-Demo-CN",
    server2: "STAGlobalInvestments-HK",
    server3: "STAGlobalInvestments-Live02",
    server4: "STAGlobalInvestments-Live03",
    server5: "STAGlobalInvestments-Practice",
    server6: "Markets.com-Practice",
    server7: "Markets.com-Live",
    server8: "TradeTechMarkets-Live",
    server9: "TradeTechMarkets-Practice",
    server10: "TradeTechMarkets2-MarketsX",
    value: "Markets.com",
  },
  {
    label: "MartexFX",
    server1: "MartexGroup-Demo",
    server2: "MartexGroup-Live",
    value: "MartexFX",
  },
  {
    label: "Masari Capital",
    server1: "MasariCapital-Live",
    server2: "MasariCapital-Demo",
    server3: "MasariCapital-Live4",
    value: "Masari Capital",
  },
  {
    label: "Masarut Capital",
    server1: "Masarut-Demo",
    server2: "Masarut-Real",
    value: "Masarut Capital",
  },
  {
    label: "MasterForex",
    server1: "Masterforex-Cent",
    server2: "Masterforex-Demo",
    server3: "Masterforex-Real",
    server4: "Masterforex-ECN",
    server5: "Masterforex-Dollar",
    server6: "MFXBroker-ECN",
    server7: "MFXBroker-Dollar",
    server8: "MFXBroker-Demo",
    server9: "MFXBroker-Cent",
    value: "MasterForex",
  },
  {
    label: "Matach 24",
    server1: "Matach24-Demo",
    server2: "Matach24-Real",
    value: "Matach 24",
  },
  {
    label: "Matador Prime",
    server1: "MatadorPrime-Demo",
    server2: "MatadorPrime-MetaTrader Live",
    server3: "MatadorPrimeMX-Live",
    server4: "MatadorPrime-Live",
    value: "Matador Prime",
  },
  {
    label: "Match-Trade",
    server1: "MatchTrade-LIVE",
    server2: "MatchTrade-DEMO",
    server3: "BuySellTradingInv-LIVE",
    value: "Match-Trade",
  },
  {
    label: "MatchX",
    server1: "MatchTrade-DEMO",
    server2: "MatchTrade-LIVE",
    value: "MatchX",
  },
  {
    label: "MAX Exchange",
    server1: "MAXExchange-LIVE",
    server2: "MAXExchange-DEMO",
    value: "MAX Exchange",
  },
  {
    label: "MaxcoFutures",
    server1: "MaxcoFutures-Demo",
    server2: "MaxcoFutures-Live",
    value: "MaxcoFutures",
  },
  {
    label: "MaxFX",
    server1: "MaxFX-Demo",
    server2: "MaxFX-Live Server",
    server3: "MaxFX-Demo Server",
    value: "MaxFX",
  },
  {
    label: "MaxGlobalFX",
    server1: "MaxGlobLimited-Demo",
    server2: "MaxGlobLimited-Live",
    value: "MaxGlobalFX",
  },
  {
    label: "MAXI FOREX",
    server1: "MaxiForex-Demo",
    server2: "MaxiForex-Real",
    value: "MAXI FOREX",
  },
  {
    label: "MAXIM Trader",
    server1: "Maxim-Demo",
    server2: "Maxim-Live",
    value: "MAXIM Trader",
  },
  {
    label: "MaxiMarkets",
    server1: "MaxiMarkets-Live 5",
    server2: "MaxiMarkets-Maxi Services",
    server3: "MaxiServices-Real",
    server4: "MaxiServices-Demo",
    value: "MaxiMarkets",
  },
  {
    label: "MaximusFX",
    server1: "MaximusMarkets-Demo",
    server2: "MaximusMarkets-LiveLiquidity1",
    value: "MaximusFX",
  },
  {
    label: "MaxoFx",
    server1: "AxoCapital-Demo",
    server2: "AxoCapital-Real",
    server3: "MaxoFxCorp-Demo",
    server4: "MaxoFxCorp-Real",
    value: "MaxoFx",
  },
  {
    label: "MaxTraRichforex",
    server1: "MaxtraRichForex-Demo-UK",
    server2: "MaxtraRichForex-Live-UK-2",
    value: "MaxTraRichforex",
  },
  {
    label: "MaxxEmpire",
    server1: "MaxxEmpirePty-Demo",
    server2: "MaxxEmpirePty-Live",
    value: "MaxxEmpire",
  },
  {
    label: "MB Trading",
    server1: "MBTrading-Demo Server",
    server2: "MBTrading-Live Server",
    server3: "MBT-Free ECN Live",
    server4: "MBT- Free ECN Demo",
    server5: "MBTrading-Live",
    value: "MB Trading",
  },
  {
    label: "MBA",
    server1: "BuanaArtha-DEMO",
    server2: "BuanaArtha-LIVE",
    value: "MBA",
  },
  {
    label: "MBCFX",
    server1: "MBCFX-Demo",
    server2: "MBCFX-Real",
    value: "MBCFX",
  },
  {
    label: "MBX",
    server1: "MBXLTD-Demo",
    server2: "MBXLTD-Main",
    value: "MBX",
  },
  {
    label: "MCFX",
    server1: "MCFXGroup-Demo",
    server2: "MCFXGroup-Live",
    value: "MCFX",
  },
  {
    label: "MCmarkets",
    server1: "No servers",
    value: "MCmarkets",
  },
  {
    label: "MDF",
    server1: "MDF-demo02",
    server2: "MDF-live03",
    value: "MDF",
  },
  {
    label: "Mega Menara Mas Berjangka",
    server1: "PTMegaMenaraMas-Demo",
    server2: "PTMegaMenaraMas-Live",
    value: "Mega Menara Mas Berjangka",
  },
  {
    label: "Mega Trader FX",
    server1: "MegaTraderFx-Demo",
    server2: "MegaTraderFx-Real",
    value: "Mega Trader FX",
  },
  {
    label: "MeksaFX",
    server1: "Meksa-Meksa",
    value: "MeksaFX",
  },
  {
    label: "Menara Mas Futures",
    server1: "MenaraMF-Demo",
    server2: "MenaraMF-Live",
    value: "Menara Mas Futures",
  },
  {
    label: "Mentari",
    server1: "Mentari-Real",
    server2: "Mentari-Demo",
    value: "Mentari",
  },
  {
    label: "Mercam",
    server1: "Mercam-Live2",
    server2: "Mercam-Demo2",
    value: "Mercam",
  },
  {
    label: "Mercer Capital",
    server1: "MercerFX-Demo",
    server2: "MercerFX-Live",
    value: "Mercer Capital",
  },
  {
    label: "MerchantFX",
    server1: "No servers",
    value: "MerchantFX",
  },
  {
    label: "Merex Markets",
    server1: "MFX-Demo",
    server2: "MFX-Live",
    value: "Merex Markets",
  },
  {
    label: "Merit Forex",
    server1: "MeritFinancial-Demo",
    server2: "MeritFinancial-Real",
    value: "Merit Forex",
  },
  {
    label: "MetaCentral",
    server1: "MetaCentral-Demo",
    server2: "MetaCentral-Live 2",
    value: "MetaCentral",
  },
  {
    label: "Metadoro",
    server1: "RHCInvestments-Demo",
    server2: "RHCInvestments-Server",
    value: "Metadoro",
  },
  {
    label: "MetaQuotes",
    server1: "MetaQuotes-Demo",
    value: "MetaQuotes",
  },
  {
    label: "Metas Group",
    server1: "MetasGroup-Demo",
    server2: "MetasGroup-Live",
    value: "Metas Group",
  },
  {
    label: "MetaTrada",
    server1: "Trada-Demo",
    server2: "Trada-Live",
    value: "MetaTrada",
  },
  {
    label: "MetisEtrade",
    server1: "MetisEtrade-MT4",
    value: "MetisEtrade",
  },
  {
    label: "MEX Atlantic",
    server1: "MEXGlobalFinancial-Demo",
    server2: "MEXGlobalFinancial-Real-3",
    server3: "MEXAtlantic-Real-2",
    server4: "MEXAtlantic-Real-3",
    server5: "MEXAtlantic-Real",
    server6: "MEXAtlantic-Real-4",
    value: "MEX Atlantic",
  },
  {
    label: "MEX Exchange",
    server1: "MEXIntGroup-Real",
    server2: "MEXExchange-Demo",
    server3: "MEXExchange-Live",
    value: "MEX Exchange",
  },
  {
    label: "MEX Group",
    server1: "MBG-Demo",
    server2: "MBG-Real",
    server3: "MEXIntGroup-Real",
    server4: "MEXIntGroup-Demo",
    server5: "MEX-(EUROPE)-LIMITED",
    server6: "MEXIntGroup-Real-2",
    server7: "MEXAtlantic-Real",
    server8: "MEXAtlantic-Real 2",
    server9: "MEXAtlantic-Real 3",
    server10: "MEXAtlantic-Real 3",
    server11: "MEXAtlantic-Demo",
    value: "MEX Group",
  },
  {
    label: "Mezzo Forex",
    server1: "Mezzoforex-Demo",
    server2: "Mezzoforex-Live",
    value: "Mezzo Forex",
  },
  {
    label: "MF Global FX",
    server1: "MFGlobal-Powered by BT",
    server2: "MFGlobal-Demo2",
    value: "MF Global FX",
  },
  {
    label: "MFC",
    server1: "MFCI-Demo",
    server2: "MFCI-PRO Live",
    value: "MFC",
  },
  {
    label: "MFI Forex",
    server1: "MFIForex-Demo",
    server2: "MFIForex-Live",
    value: "MFI Forex",
  },
  {
    label: "MFMSecurities",
    server1: "MFMSecurities-Demo",
    server2: "MFMSecurities-Real",
    value: "MFMSecurities",
  },
  {
    label: "mForex",
    server1: "mForex-REAL",
    server2: "mForex-Demo",
    value: "mForex",
  },
  {
    label: "MFX Broker",
    server1: "MFXBroker-Cent",
    server2: "MFXBroker-Demo",
    server3: "MFXBroker-Dollar",
    server4: "MFXBroker-ECN",
    value: "MFX Broker",
  },
  {
    label: "MGFX",
    server1: "CPMarketsLtd-Live",
    server2: "WCI-Demo",
    server3: "BetaMGM-Server",
    value: "MGFX",
  },
  {
    label: "MGK GLOBAL",
    server1: "MGK-DEMO",
    server2: "MGK-MAIN",
    value: "MGK GLOBAL",
  },
  {
    label: "MGL Forex",
    server1: "MGLFOREX-Demo Server",
    server2: "MGLFOREX-Live 7",
    server3: "MGLForexFinancial-Real",
    server4: "MGLForexFinancial-Demo",
    value: "MGL Forex",
  },
  {
    label: "MICFX",
    server1: "MultiInvestmentCompany-Demo",
    server2: "MultiInvestmentCompany-Live",
    value: "MICFX",
  },
  {
    label: "Midas",
    server1: "DigitalCMedia-Demo",
    server2: "DigitalCMedia-Main",
    value: "Midas",
  },
  {
    label: "Midas Financial Management",
    server1: "MidasFinancial-Demo",
    server2: "MidasFinancial-Live",
    value: "Midas Financial Management",
  },
  {
    label: "MidasFX",
    server1: "MidasFX-Demo",
    server2: "MidasFX-Live",
    value: "MidasFX",
  },
  {
    label: "MidcoFX",
    server1: "MDC-Live",
    server2: "MDC-Demo",
    value: "MidcoFX",
  },
  {
    label: "Midtou Aryacom Futures",
    server1: "PTMidtouAryacom-Live",
    server2: "PTMidtouAryacom-Demo",
    value: "Midtou Aryacom Futures",
  },
  {
    label: "Midtou Global Financial",
    server1: "MidtouGlobal-Demo",
    server2: "MidtouGlobal-Live",
    value: "Midtou Global Financial",
  },
  {
    label: "MIG Bank",
    server1: "MIG-Real",
    server2: "MIG-Real2",
    server3: "MIG-Demo",
    server4: "MIGBank-Real1",
    server5: "MIG-Real-MAM",
    server6: "MIGBank-Real2",
    server7: "MIGBank-Demo",
    server8: "MIGBank-DemoMAM",
    server9: "MIGBank-MAM",
    server10: "MIGBank-Real2",
    server11: "MIGBank-Real2",
    value: "MIG Bank",
  },
  {
    label: "MIG Capital",
    server1: "MIGCapital-Demo",
    server2: "MIGCapital-Live",
    value: "MIG Capital",
  },
  {
    label: "Miki Forex",
    server1: "ALTrade-Server",
    value: "Miki Forex",
  },
  {
    label: "Mill Trade",
    server1: "MillTrade-Demo",
    server2: "MillTrade-Real",
    value: "Mill Trade",
  },
  {
    label: "Millenium Penata Futures",
    server1: "PTMillennium-Demo Accounts Server",
    server2: "PTMillennium-International Server",
    server3: "PTMillennium-Real Accounts Server",
    server4: "PTMillennium-Real Accounts 2 Server",
    value: "Millenium Penata Futures",
  },
  {
    label: "Million Tinkle",
    server1: "MTI-Real",
    server2: "MTI-Demo",
    value: "Million Tinkle",
  },
  {
    label: "MillonariumFX",
    server1: "Millonarium-Demo",
    server2: "Millonarium-Live 7",
    server3: "Millonarium-LiveLiquidity1",
    value: "MillonariumFX",
  },
  {
    label: "Milton Markets",
    server1: "MiltonMarkets-LiveUK",
    server2: "MiltonMarkets-DemoUK",
    server3: "MiltonMarkets-Demo",
    server4: "MiltonMarkets-Live",
    server5: "MiltonMarkets2-Live 8",
    server6: "MiltonMarkets2-Demo Server",
    server7: "MiltonMarkets-Demo1",
    server8: "MiltonMarkets-Live1",
    server9: "MiltonTech-Platform",
    value: "Milton Markets",
  },
  {
    label: "Milton Prime",
    server1: "MiltonTech-Platform",
    value: "Milton Prime",
  },
  {
    label: "Mira Capital Markets",
    server1: "MiraCapitalMarkets-Demo",
    server2: "MiraCapitalMarkets-Live",
    value: "Mira Capital Markets",
  },
  {
    label: "MISHOV GROUP",
    server1: "MishovTrading-Demo",
    server2: "MishovTrading-Live",
    value: "MISHOV GROUP",
  },
  {
    label: "MLX Limited",
    server1: "MLiquidityXchange-Demo",
    server2: "MLiquidityXchange-Live",
    value: "MLX Limited",
  },
  {
    label: "MMC DOMAIN RESOURCES",
    server1: "MMCDomainResources-Demo",
    server2: "MMCDomainResources-Real",
    value: "MMC DOMAIN RESOURCES",
  },
  {
    label: "MMI Markets",
    server1: "MMImarkets-Demo",
    server2: "MMImarkets-Live",
    value: "MMI Markets",
  },
  {
    label: "Mocaz",
    server1: "MFMGroup-Demo",
    server2: "MFMGroup-Live",
    value: "Mocaz",
  },
  {
    label: "Mocaz Financial Markets",
    server1: "MFMGroup-Live",
    server2: "MFMGroup-Demo",
    value: "Mocaz Financial Markets",
  },
  {
    label: "MogaFX",
    server1: "MogaInternational-Live-Asia",
    server2: "MogaIntlGroup-Demo",
    server3: "MogaIntlGroup-Live",
    server4: "MogaIntlGroup-Live-Europe",
    server5: "MogaIntlGroup-LiveAsia",
    value: "MogaFX",
  },
  {
    label: "Mohicans Markets",
    server1: "Mohicans-Demo",
    server2: "Mohicans-Live",
    value: "Mohicans Markets",
  },
  {
    label: "Moneta Markets",
    server1: "VantageFXInternational-Live 3",
    server2: "VantageFXInternational-Live 1",
    server3: "MonetaMarkets-Demo",
    server4: "MonetaMarkets-Live 6",
    server5: "MonetaMarkets-Live 3",
    value: "Moneta Markets",
  },
  {
    label: "Monex Investindo Futures",
    server1: "Monex-Demo",
    server2: "Monex-Server",
    server3: "Monex-Server2",
    server4: "Monex-Server3",
    server5: "Monex-Server4",
    value: "Monex Investindo Futures",
  },
  {
    label: "Monex Securities AU",
    server1: "MonexSecuritiesAU-Live5",
    server2: "MonexSecuritiesAU-Demo",
    value: "Monex Securities AU",
  },
  {
    label: "Moneyage FX",
    server1: "MoneyAge-Server",
    value: "Moneyage FX",
  },
  {
    label: "MoneyRain",
    server1: "MoneyRain-Demo",
    server2: "MoneyRain-Live",
    server3: "MoneyRain-Server",
    value: "MoneyRain",
  },
  {
    label: "Moore Capital Group",
    server1: "MCG-Demo",
    server2: "MCG-Live",
    value: "Moore Capital Group",
  },
  {
    label: "MorganHill",
    server1: "MorganHillFS-Server",
    value: "MorganHill",
  },
  {
    label: "Morning Sky",
    server1: "MorningSkyTrade-Live",
    server2: "MorningSkyTrade-Demo",
    value: "Morning Sky",
  },
  {
    label: "MorningFX",
    server1: "No servers",
    value: "MorningFX",
  },
  {
    label: "MosTradingHouse",
    server1: "Techview-Server",
    server2: "195.189.122.189:443",
    server3: "212.5.162.225:443",
    value: "MosTradingHouse",
  },
  {
    label: "MPLUSFX",
    server1: "MPlusGlobal-LiveUK",
    server2: "MPlusGlobal-DemoUK",
    server3: "MPlusGlobal1-Platform",
    value: "MPLUSFX",
  },
  {
    label: "MRC Markets",
    server1: "MRC-Demo",
    server2: "MRC-Live",
    server3: "MRC-Server",
    value: "MRC Markets",
  },
  {
    label: "MRG Mega Berjangka",
    server1: "MRGMega-Primary",
    server2: "MRGMega-Demo",
    value: "MRG Mega Berjangka",
  },
  {
    label: "MRGForex",
    server1: "MaxrichGroup-Demo",
    server2: "MaxrichGroup-Real",
    value: "MRGForex",
  },
  {
    label: "MRTmarkets",
    server1: "RTFintech-Server1",
    server2: "TradingTechnologies-Demo",
    server3: "TradingTechnologies-Real",
    value: "MRTmarkets",
  },
  {
    label: "MSC Group",
    server1: "MSCGroup-Demo",
    server2: "MSCGroup-Real",
    value: "MSC Group",
  },
  {
    label: "MSMAX",
    server1: "MSMaxGlobal-DEMO",
    server2: "MSMaxGlobal-REAL SERVER",
    value: "MSMAX",
  },
  {
    label: "MSR Capital",
    server1: "AdvancedMarkets-Demo",
    server2: "AdvancedMarkets-Live 2",
    server3: "AdvancedMarkets-Live",
    value: "MSR Capital",
  },
  {
    label: "MT COOK",
    server1: "MTCOOK-Demo",
    server2: "MTCOOK-Live",
    server3: "AtlanticPearl-Live 2",
    server4: "AtlanticPearl-Demo 2",
    server5: "AtlanticPearl-Live 1",
    server6: "AtlanticPearl-Demo",
    server7: "MTCOOK-Live 5",
    value: "MT COOK",
  },
  {
    label: "MTBankFX",
    server1: "MTBank-DEMO-1",
    server2: "MTBank-LIVE-1",
    value: "MTBankFX",
  },
  {
    label: "MTI Markets",
    server1: "MTIGroup-Demo",
    server2: "MTIGroup-Real",
    value: "MTI Markets",
  },
  {
    label: "MTrading",
    server1: "MTrading-Demo",
    server2: "MTrading-Live",
    server3: "MTrading-Live2",
    value: "MTrading",
  },
  {
    label: "MTS Gold",
    server1: "MTSGold-Live",
    server2: "MTSGold-Demo",
    value: "MTS Gold",
  },
  {
    label: "MTS Gold Futures",
    server1: "MTSGoldFutures-Demo",
    server2: "MTSGoldFutures-Live",
    value: "MTS Gold Futures",
  },
  {
    label: "MTS Prime",
    server1: "MTSPrimeSolutions-Live",
    server2: "MTSPrimeSolutions-Demo",
    value: "MTS Prime",
  },
  {
    label: "MU-VERSE",
    server1: "MuVerse-Live",
    server2: "MuVerse-Demo",
    value: "MU-VERSE",
  },
  {
    label: "Mubasher Trade",
    server1: "Mubasher-Demo 5",
    server2: "Mubasher-Live 5",
    value: "Mubasher Trade",
  },
  {
    label: "Mugan Markets",
    server1: "MuganFX-Real1",
    value: "Mugan Markets",
  },
  {
    label: "Multi-LP",
    server1: "MultiLP-Live",
    server2: "MultiLP-Demo",
    value: "Multi-LP",
  },
  {
    label: "MultiBank",
    server1: "MEXIntGroup-Demo",
    server2: "MEXatlantic-Real",
    server3: "MultiBankFXCorp-Live",
    server4: "MEXIntGroup-Real-2",
    server5: "MultiBankFXCorp-Demo",
    server6: "MEXAtlantic-Real-2",
    server7: "MEXAtlantic-Real-3",
    server8: "MEXAtlantic-Real-4",
    value: "MultiBank",
  },
  {
    label: "MultiBank Group",
    server1: "MEXIntGroup-Real-2",
    value: "MultiBank Group",
  },
  {
    label: "Mutual Forex",
    server1: "No servers",
    value: "Mutual Forex",
  },
  {
    label: "MXC Forex",
    server1: "MXCapitalCorp-Live 2",
    server2: "MXCapitalCorporation-Demo",
    server3: "MXCapitalCorporation-Live",
    value: "MXC Forex",
  },
  {
    label: "MXT",
    server1: "VantageFX-Demo",
    server2: "VantageFX-Live 1",
    server3: "VantagePrime-Live",
    value: "MXT",
  },
  {
    label: "MXTrade",
    server1: "MXTrade-Demo",
    server2: "MXTrade-Real",
    value: "MXTrade",
  },
  {
    label: "MyForexFunds",
    server1: "TradersGlobalGroup-Live",
    server2: "TradersGlobalGroup-Demo",
    server3: "TradersGlobalGroup-Demo 2",
    server4: "TradersGlobalGroup-Live 2",
    server5: "TradersGlobalGroup-Demo3",
    value: "MyForexFunds",
  },
  {
    label: "MYFX Markets",
    server1: "MYFX-US01-Demo",
    server2: "MYFX-US01-Live",
    server3: "MYFXMarkets-US02-Live",
    server4: "MYFXMarkets-US03-Demo",
    server5: "MYFXMarkets-US03-Live",
    server6: "MYFXMarkets-US06-Live",
    server7: "MYFXMarkets-US09-Live",
    server8: "MYFXMarkets-US01-Demo",
    server9: "MYFX-US07-Live",
    value: "MYFX Markets",
  },
  {
    label: "MyPerksFX",
    server1: "MPFX-Demo",
    server2: "MPFX-Live",
    value: "MyPerksFX",
  },
  {
    label: "MySmartFx",
    server1: "MySmartFx-Production",
    server2: "MySmartFx-MII Demo",
    value: "MySmartFx",
  },
  {
    label: "MyTrademarkets",
    server1: "MyTradeMarkets-Demo",
    server2: "MyTradeMarkets-Live",
    value: "MyTrademarkets",
  },
  {
    label: "myWealthBook",
    server1: "myWealthBook-Demo",
    server2: "myWealthBook-Real",
    value: "myWealthBook",
  },
  {
    label: "N1CM",
    server1: "N1CapitalMarkets-Demo",
    server2: "N1CapitalMarkets-Live",
    value: "N1CM",
  },
  {
    label: "NAGA Global",
    server1: "NAGAGlobal-Live",
    server2: "NAGAGlobal-Live02",
    server3: "NAGAGlobal-Live03",
    server4: "NAGAGlobal-Demo",
    server5: "NAGAGlobal-Demo02",
    value: "NAGA Global",
  },
  {
    label: "NAGA Markets",
    server1: "NagaMarkets-Demo",
    server2: "NagaMarkets-Live",
    server3: "NAGAGlobal-Live",
    server4: "NAGAGlobal-Demo",
    server5: "NAGAGlobal-Live02",
    server6: "NAGAGlobal-Live03",
    server7: "NagaCapitalLtd-Live",
    server8: "NagaCapitalLtd-Demo",
    value: "NAGA Markets",
  },
  {
    label: "Nano4x",
    server1: "Nano4x-Demo",
    server2: "Nano4x-Real",
    value: "Nano4x",
  },
  {
    label: "NAS Broker",
    server1: "NASTrader-Live 5",
    server2: "NAS-Real",
    server3: "NAS-Demo",
    server4: "NASBroker-Real",
    value: "NAS Broker",
  },
  {
    label: "Nash Markets",
    server1: "NashMarkets-Live",
    server2: "NashMarkets-Demo",
    value: "Nash Markets",
  },
  {
    label: "Nash Value Investment",
    server1: "NashValueInvestment-Live",
    server2: "NashValueInvestment-Demo",
    value: "Nash Value Investment",
  },
  {
    label: "Nation FX",
    server1: "NationFXLLC-Demo",
    server2: "NationFXLLC-Real",
    value: "Nation FX",
  },
  {
    label: "NatureForex",
    server1: "NatureForex-Real",
    server2: "NatureForex-Demo",
    server3: "NatureForex-Server",
    value: "NatureForex",
  },
  {
    label: "Navi FX",
    server1: "NavigatorGlobal-Demo01",
    server2: "NavigatorGlobal-Live02",
    value: "Navi FX",
  },
  {
    label: "Navitas Markets",
    server1: "NavFX-Demo1",
    server2: "NavFX-Real2",
    value: "Navitas Markets",
  },
  {
    label: "NBFOREX",
    server1: "NPBFX-Real-2",
    server2: "NB-DEMO",
    server3: "NB-LIVE",
    server4: "NB-LIVE2",
    server5: "NB-LIVE3",
    server6: "NB-LIVE4",
    value: "NBFOREX",
  },
  {
    label: "NBH Markets",
    server1: "NBHMarkets-Live",
    server2: "NBHMarkets-Demo",
    value: "NBH Markets",
  },
  {
    label: "NBN Broker",
    server1: "NBNBroker-DEMO",
    server2: "NBNBroker-REAL",
    value: "NBN Broker",
  },
  {
    label: "NCM Investment",
    server1: "NCMInvestment-Live",
    server2: "NCMInvestment-Demo",
    value: "NCM Investment",
  },
  {
    label: "NCS",
    server1: "NCS-Server",
    value: "NCS",
  },
  {
    label: "NebulaXC",
    server1: "NebulaXC-Demo",
    server2: "NebulaXC-Live-UK",
    value: "NebulaXC",
  },
  {
    label: "Neferma",
    server1: "DTTMT4-Demo",
    server2: "DTTMT4-Live",
    value: "Neferma",
  },
  {
    label: "Nefteprombank",
    server1: "Nefteprombank-Demo",
    server2: "Nefteprombank-Real",
    value: "Nefteprombank",
  },
  {
    label: "NESSfx",
    server1: "OLOplatforms-Demo",
    server2: "OLOplatforms-Real",
    value: "NESSfx",
  },
  {
    label: "NetoTrade",
    server1: "NetoTrade-Demo",
    server2: "NetoTrade-Primary",
    value: "NetoTrade",
  },
  {
    label: "NetPro",
    server1: "NetProTrading-Demo",
    server2: "NetProTrading-Live",
    value: "NetPro",
  },
  {
    label: "Neuimex",
    server1: "No servers",
    value: "Neuimex",
  },
  {
    label: "Neural4X",
    server1: "Neural4X-Demo1",
    server2: "Neural4X-Real1",
    server3: "Neural4X-Real4",
    server4: "Neural4X-Real10",
    value: "Neural4X",
  },
  {
    label: "New Differ Global",
    server1: "NewDiffrGlobal-Demo",
    server2: "NewDiffrGlobal-Live",
    value: "New Differ Global",
  },
  {
    label: "New Forex",
    server1: "NewForex-Demo",
    server2: "NewForex-Live",
    value: "New Forex",
  },
  {
    label: "New Win FX",
    server1: "NewWinFx-DEMO",
    server2: "NewWinFx-REAL",
    value: "New Win FX",
  },
  {
    label: "NewCapital Securities",
    server1: "NewCapTrader-Demo Server",
    server2: "NewCapTrader-Live 7",
    value: "NewCapital Securities",
  },
  {
    label: "NewTycoonFX",
    server1: "Newtycoon-Demo",
    server2: "Newtycoon-Real",
    value: "NewTycoonFX",
  },
  {
    label: "Nexsus Financial Markets",
    server1: "NexsusFinancialMarkets-Demo",
    server2: "NexsusFinancialMarkets-Live",
    server3: "NexsusFinancialMarkets-Real",
    value: "Nexsus Financial Markets",
  },
  {
    label: "NEXTART",
    server1: "NextArt-Asia 3",
    server2: "NextArt-Demo",
    value: "NEXTART",
  },
  {
    label: "nextmarkets",
    server1: "nextmarkets-Demo",
    server2: "nextmarkets-Real",
    value: "nextmarkets",
  },
  {
    label: "Nexus Trade",
    server1: "NexusTrade-Demo",
    server2: "NexusTrade-Real",
    value: "Nexus Trade",
  },
  {
    label: "Ngel Partners",
    server1: "NgelPartners-Demo",
    server2: "NgelPartners-Live",
    value: "Ngel Partners",
  },
  {
    label: "NicoFX",
    server1: "NICOFX-Demo Server",
    server2: "NICOFX-Live Server",
    server3: "NICOFX-MAIN Live",
    value: "NicoFX",
  },
  {
    label: "Nine Liquidity",
    server1: "MatchTrade-DEMO",
    server2: "MatchTrade-LIVE",
    value: "Nine Liquidity",
  },
  {
    label: "NineCapital",
    server1: "NineCapital-Live",
    value: "NineCapital",
  },
  {
    label: "Nissan Century Securities",
    server1: "NCSEC-JPYDemo01",
    server2: "NCSEC-JPYReal01",
    server3: "NCSEC-JPYReal02",
    value: "Nissan Century Securities",
  },
  {
    label: "NixonFX",
    server1: "NixonFx-Demo",
    server2: "NixonFx-Main",
    value: "NixonFX",
  },
  {
    label: "NKFX",
    server1: "NKFX-Demo",
    server2: "NKFX-Real",
    value: "NKFX",
  },
  {
    label: "NoaFX",
    server1: "NoaFX-Demo",
    server2: "NoaFX-Live",
    value: "NoaFX",
  },
  {
    label: "NoaPrime",
    server1: "NoaPrime-Demo",
    server2: "NoaPrime-Live",
    value: "NoaPrime",
  },
  {
    label: "NobleMarkets",
    server1: "NobleSecurities-Demo",
    server2: "NobleSecurities-Real",
    value: "NobleMarkets",
  },
  {
    label: "Noor Al Mal",
    server1: "NoorAlmalFin-Live",
    server2: "NoorAlmalFin-Demo",
    value: "Noor Al Mal",
  },
  {
    label: "Noor Capital",
    server1: "NoorCapital-DemoCFD",
    server2: "NoorCapital-RealCFD",
    value: "Noor Capital",
  },
  {
    label: "Noor Capital Global Markets",
    server1: "NoorCapital-Live",
    server2: "NoorCapital-Demo",
    value: "Noor Capital Global Markets",
  },
  {
    label: "NoorCM",
    server1: "NoorCM-Demo",
    server2: "NoorCM-Real",
    server3: "NoorAlmalFin-Demo",
    server4: "NoorAlmalFin-Live",
    server5: "NoorCMTR-Demo",
    server6: "NoorCMTR-Real",
    server7: "NoorCM-Live",
    value: "NoorCM",
  },
  {
    label: "Nord FX",
    server1: "NordFX-Real5",
    server2: "NordFX-Demo",
    server3: "NordFX-Real3",
    server4: "NordFX-Real2",
    server5: "NordFX-Real4",
    server6: "NordFX-Real1",
    value: "Nord FX",
  },
  {
    label: "NordHedge",
    server1: "LiqCon-Demo2",
    server2: "LiqCon-Live2",
    server3: "LiqCon2-Live",
    value: "NordHedge",
  },
  {
    label: "NordMarkets",
    server1: "NordMarkets-Demo",
    server2: "NordMarkets-Live",
    server3: "NordMarkets-Demo2",
    value: "NordMarkets",
  },
  {
    label: "North West Financial Broker",
    server1: "NWBroker-Server",
    value: "North West Financial Broker",
  },
  {
    label: "NorthFinance",
    server1: "NorthFinance-Demo",
    server2: "NorthFinance-Real",
    value: "NorthFinance",
  },
  {
    label: "Norvik Banka",
    server1: "Norvik-Real",
    server2: "Norvik-Demo",
    value: "Norvik Banka",
  },
  {
    label: "Novafx Trading",
    server1: "NovaTradex-Demo Server",
    server2: "NovaTradex-Production",
    value: "Novafx Trading",
  },
  {
    label: "Novatrades",
    server1: "Novatrades-Demo",
    server2: "Novatrades-Main",
    value: "Novatrades",
  },
  {
    label: "Novox Global",
    server1: "NovoxGroupPty-DEMO",
    server2: "NovoxGroupPty-Live",
    value: "Novox Global",
  },
  {
    label: "NPBFX",
    server1: "NPBFX-Demo",
    server2: "NPBFX-Real",
    server3: "NPBFX-Real-2",
    value: "NPBFX",
  },
  {
    label: "NSD Markets",
    server1: "NSDMarkets-Demo",
    server2: "NSDMarkets-Live",
    value: "NSD Markets",
  },
  {
    label: "NSFX",
    server1: "NSFX-Demo",
    server2: "NSFX-Live1",
    value: "NSFX",
  },
  {
    label: "NTFX",
    server1: "NTFX-Demo",
    server2: "NTFX-Real",
    value: "NTFX",
  },
  {
    label: "NTGMFX",
    server1: "NTGMFX-Server",
    value: "NTGMFX",
  },
  {
    label: "NTSFX",
    server1: "NTSFXLtd-Demo",
    server2: "NTSFXLtd-Live",
    value: "NTSFX",
  },
  {
    label: "NTWO",
    server1: "NTwo-Main",
    server2: "NTwo-Server",
    value: "NTWO",
  },
  {
    label: "Number One Capital Markets",
    server1: "N1CapitalMarkets-Demo",
    server2: "N1CapitalMarkets-Live",
    value: "Number One Capital Markets",
  },
  {
    label: "NXTRades",
    server1: "NXTRADE-DEMO",
    server2: "NXTRADE-LIVE",
    value: "NXTRades",
  },
  {
    label: "NYFX",
    server1: "NYFXFinancial-Live1",
    server2: "NYFXFinancial-Live2",
    server3: "NYFXFinancial-Demo1",
    value: "NYFX",
  },
  {
    label: "NZ Financial",
    server1: "NZF-Server",
    server2: "NZF-Live",
    value: "NZ Financial",
  },
  {
    label: "NZGFT",
    server1: "NZGlobal-Demo",
    server2: "NZGlobal-Live",
    value: "NZGFT",
  },
  {
    label: "OAForex",
    server1: "OAFX-Demo",
    server2: "OAFX-Live",
    value: "OAForex",
  },
  {
    label: "Oanda Europe MT4",
    server1: "No servers",
    value: "Oanda Europe MT4",
  },
  {
    label: "Oanda MT4",
    server1: "OANDA-Japan Live",
    server2: "OANDA-GMT 2 Live",
    server3: "OANDA-Japan Practice",
    server4: "OANDA-v20 Live",
    server5: "OANDA-v20 Practice",
    server6: "OANDA-Japan Pro Live",
    server7: "OANDA-Japan FX Live",
    server8: "OANDA-v20 Live-1",
    server9: "OANDA-v20 Live-2",
    server10: "OANDA-v20 Practice-1",
    server11: "OANDA-v20 Practice-2",
    server12: "OANDA-MT4 Practice",
    server13: "OANDA-MT4",
    server14: "OANDA-MT4 FXTrade JP",
    server15: "OANDA-MT4 Practice JP",
    server16: "OANDA-GMT-5 Live",
    server17: "OANDA-GMT-5 Practice",
    server18: "OANDA-GMT+2 Live",
    server19: "OANDA-Demo-1",
    server20: "OANDA-OGM Live-1",
    server21: "OANDA-Demo-2",
    server22: "OANDA-v20 Live-3",
    server23: "OANDA-v20 Practice-3",
    server24: "OANDA-v20 Live-4",
    server25: "OANDA-v20 Practice-4",
    server26: "OANDA-v20 Live-5",
    server27: "OANDA-xOGM Demo-1",
    value: "Oanda MT4",
  },
  {
    label: "OBRinvest",
    server1: "OBRInvestmentsLtd-Demo",
    server2: "OBRInvestmentsLtd-Live",
    value: "OBRinvest",
  },
  {
    label: "OCF",
    server1: "OCFX-Demo",
    server2: "OCFX-Live",
    value: "OCF",
  },
  {
    label: "OctaFx",
    server1: "OctaFX-Demo",
    server2: "OctaFX-Real",
    server3: "OctaFX-Real7",
    server4: "OctaFX-Real8",
    server5: "OctaFX-Demo2",
    server6: "OctaFX-Real9",
    server7: "OctaFX-Real10",
    server8: "OctaFX-Real2",
    server9: "OctaFX-Real3",
    server10: "OctaFX-Real4",
    server11: "OctaFX-Real5",
    server12: "OctaInvestamaIDN-BRJ-Demo1",
    server13: "OctaInvestamaIDN-BRJ-Real1",
    server14: "OctaFX-Real6",
    value: "OctaFx",
  },
  {
    label: "OD Markets",
    server1: "ODMarkets-Demo",
    server2: "ODMarkets-Live",
    value: "OD Markets",
  },
  {
    label: "Odin Capital",
    server1: "ODIN-Demo",
    server2: "ODIN-Live",
    server3: "OdinGroup-Live",
    server4: "OdinGroup-Demo",
    value: "Odin Capital",
  },
  {
    label: "ODL Securities",
    server1: "ODL-MT4 Demo",
    server2: "ODL-MT4 Live",
    server3: "ODL-ODL Securities MT4 Demo",
    server4: "ODL-ODL Securities MT4 Live",
    server5: "ODL_Pro-Web Demo",
    server6: "ODL_Pro-MT4 Demo",
    server7: "BestCFD-MT4 Demo",
    server8: "BestCFD-MT4 Live",
    server9: "ODL_Pro-MT4 Live",
    value: "ODL Securities",
  },
  {
    label: "OEXN",
    server1: "OEXNLimited-Asia",
    server2: "OEXNLimited-Live2",
    server3: "OEXNLimited-Demo",
    value: "OEXN",
  },
  {
    label: "OFMFX",
    server1: "OFMFX-Demo",
    server2: "OFMFX-Live",
    value: "OFMFX",
  },
  {
    label: "OGL Trader",
    server1: "OGL-Demo",
    server2: "OGL-Real",
    value: "OGL Trader",
  },
  {
    label: "OGM",
    server1: "OneGlobalMarket-Demo",
    server2: "OneGlobalMarket-Live",
    value: "OGM",
  },
  {
    label: "OLIM",
    server1: "OLIM-server",
    value: "OLIM",
  },
  {
    label: "Olive Markets",
    server1: "OliveOrganik-Demo",
    server2: "OliveOrganik-Real",
    value: "Olive Markets",
  },
  {
    label: "OliveFX",
    server1: "OliveFinancialMarkets-Demo",
    server2: "OliveFinancialMarkets-Live2",
    value: "OliveFX",
  },
  {
    label: "OloFx",
    server1: "BelFX-Demo",
    server2: "BelFX-Real",
    value: "OloFx",
  },
  {
    label: "OloTrading",
    server1: "OLO-Demo",
    server2: "OLO-Real1",
    value: "OloTrading",
  },
  {
    label: "OLT",
    server1: "OLT-Demo",
    server2: "OLT-Live",
    value: "OLT",
  },
  {
    label: "Olymp Trade",
    server1: "OlympTrade2-Demo",
    server2: "OlympTrade2-Live",
    server3: "OlympTrade-Live",
    value: "Olymp Trade",
  },
  {
    label: "OlympFX",
    server1: "OlympFOREX-OlympFX",
    value: "OlympFX",
  },
  {
    label: "OlympusFx",
    server1: "OlympusFinancialGroup-Demo",
    server2: "OlympusFinancialGroup-Live",
    value: "OlympusFx",
  },
  {
    label: "Omen Wealth",
    server1: "OmenWealthHoldings-Demo",
    server2: "OmenWealthHoldings-Live",
    value: "Omen Wealth",
  },
  {
    label: "Omnivest",
    server1: "Omnivest-Omnivest Trading System",
    value: "Omnivest",
  },
  {
    label: "Onasis",
    server1: "MT4-ECN",
    server2: "MT4-ETF",
    value: "Onasis",
  },
  {
    label: "One Financial Markets",
    server1: "OneFinancialMarkets-Real",
    server2: "OneFinancialMarkets-US11-Live",
    server3: "OneFinancialMarkets-Demo",
    server4: "OneF-Real",
    server5: "OneF-Demo",
    value: "One Financial Markets",
  },
  {
    label: "One FX",
    server1: "OneFXLimited-Demo",
    server2: "OneFXLimited-Real",
    value: "One FX",
  },
  {
    label: "One Prime",
    server1: "OnePrimeTechnology-Demo",
    server2: "OnePrimeTechnology-Live",
    value: "One Prime",
  },
  {
    label: "One Royal",
    server1: "RoyalPlatforms-Real",
    server2: "RoyalPlatforms-Real-01",
    server3: "RoyalPlatforms-Demo",
    value: "One Royal",
  },
  {
    label: "One Trade",
    server1: "OneTrade-Demo",
    server2: "OneTrade-Real",
    value: "One Trade",
  },
  {
    label: "OneBrok",
    server1: "ForexAC-Demo",
    server2: "ForexAC-Real",
    value: "OneBrok",
  },
  {
    label: "OneCorpFX",
    server1: "OneCorporation-Demo",
    server2: "OneCorporation-Live",
    value: "OneCorpFX",
  },
  {
    label: "OnePro",
    server1: "OneproGlobal-Live",
    server2: "OneproGlobal-Demo",
    value: "OnePro",
  },
  {
    label: "Online4x",
    server1: "Online4X-Demo",
    server2: "Online4X-Live",
    value: "Online4x",
  },
  {
    label: "Opal Financial",
    server1: "OpalFinancial-Demo",
    server2: "OpalFinancial-Live",
    value: "Opal Financial",
  },
  {
    label: "OpenFX",
    server1: "OpenInvestments-LiveUK",
    server2: "OpenInvestments-DemoUK",
    value: "OpenFX",
  },
  {
    label: "Opofinance",
    server1: "OpogroupLLC-DEMO",
    server2: "OpogroupLLC-Real1",
    value: "Opofinance",
  },
  {
    label: "Opoforex",
    server1: "OpoGroup-Real",
    server2: "OpoGroup-Demo",
    server3: "OpogroupLLC-Real1",
    server4: "OpogroupLLC-DEMO",
    value: "Opoforex",
  },
  {
    label: "Opportunity Trade",
    server1: "Opportunity TradTradingTechnologies-Demo",
    server2: "TradingTechnologies-Real",
    value: "Opportunity Trade",
  },
  {
    label: "Option Field",
    server1: "Optionfield-Trading",
    value: "Option Field",
  },
  {
    label: "ORBEX",
    server1: "Orbex-Demo",
    server2: "Orbex-Live",
    server3: "OrbexLtd-Server",
    server4: "OrbexGlobal-Live",
    server5: "OrbexGlobal-Demo",
    value: "ORBEX",
  },
  {
    label: "Oribix",
    server1: "ORIBIX-Demo",
    server2: "ORIBIX-Live",
    server3: "ORIBIX2-Live",
    value: "Oribix",
  },
  {
    label: "Origin ECN",
    server1: "OriginECN-Demo",
    server2: "OriginECN-Live",
    value: "Origin ECN",
  },
  {
    label: "Orion",
    server1: "Orion-DEMO",
    server2: "OIG-Live2",
    server3: "OIG-Live",
    value: "Orion",
  },
  {
    label: "OrionStarCapital",
    server1: "OrionStarInt-Demo",
    server2: "OrionStarInt-Live",
    value: "OrionStarCapital",
  },
  {
    label: "Oroku Edge",
    server1: "OrokuEdge-Demo",
    server2: "OrokuEdge-Live",
    value: "Oroku Edge",
  },
  {
    label: "Ortega Capital",
    server1: "OrtegaCapital-Server",
    server2: "OrtegaCapital-Demo",
    value: "Ortega Capital",
  },
  {
    label: "OS-Laugh Marketing",
    server1: "OSLaughMarketing-Demo",
    server2: "OSLaughMarketing-Live",
    value: "OS-Laugh Marketing",
  },
  {
    label: "OSG FOREX",
    server1: "OSG-Demo",
    server2: "OSG-Live",
    value: "OSG FOREX",
  },
  {
    label: "OspreyFX",
    server1: "Osprey-Demo",
    server2: "Osprey-Live",
    server3: "Osprey-Live4",
    server4: "Osprey-Real",
    value: "OspreyFX",
  },
  {
    label: "OTC Banking",
    server1: "OTCBanking-Main",
    server2: "OTCBanking-Demo",
    value: "OTC Banking",
  },
  {
    label: "OTGL Markets",
    server1: "OTGL-Demo",
    server2: "OTGL-Live",
    value: "OTGL Markets",
  },
  {
    label: "Other(MT4)",
    server1: "No servers",
    value: "Other(MT4)",
  },
  {
    label: "OTISFX",
    server1: "OTISFX-Demo",
    server2: "OTISFX-Live",
    value: "OTISFX",
  },
  {
    label: "OTSO",
    server1: "OTSOInvestment-Demo",
    server2: "OTSO-Primary",
    server3: "OTSOInvestment-Live 2",
    server4: "OTSOInvestment-Live",
    server5: "OTSOInvestment-Demo",
    value: "OTSO",
  },
  {
    label: "Overseas Commercial Futures",
    server1: "Overseas-Demo",
    server2: "Overseas-Live",
    value: "Overseas Commercial Futures",
  },
  {
    label: "OX Markets",
    server1: "OXCapitalMarkets-Demo",
    server2: "OXCapitalMarkets-Live",
    value: "OX Markets",
  },
  {
    label: "Ox Securities",
    server1: "OxSecurities-Demo",
    server2: "OxSecurities-Live",
    server3: "OXTrader-Demo",
    server4: "OXTrader-Live",
    value: "Ox Securities",
  },
  {
    label: "OxfordForex",
    server1: "OxfordForex-Demo",
    server2: "OxfordForex-Live",
    value: "OxfordForex",
  },
  {
    label: "OxleyFX",
    server1: "OxleyMarkets-Demo",
    server2: "OxleyMarkets-Live",
    value: "OxleyFX",
  },
  {
    label: "Oyak Yatirim Fx",
    server1: "OyakYatirim-Demo",
    server2: "OyakYatirim-RealOyak",
    value: "Oyak Yatirim Fx",
  },
  {
    label: "Ozone Markets",
    server1: "OzoneMarkets-Demo",
    server2: "OzoneMarkets-Live",
    value: "Ozone Markets",
  },
  {
    label: "P Global Markets",
    server1: "OIG-Live2v",
    server2: "OIG-Live",
    server3: "OIG-Demo",
    value: "P Global Markets",
  },
  {
    label: "Pacific Financial Derivatives (PFD)",
    server1: "PFD-Demo",
    server2: "PFD-Real",
    value: "Pacific Financial Derivatives (PFD)",
  },
  {
    label: "Pacific Union",
    server1: "PacificUnionLLC-Demo",
    server2: "PacificUnionLLC-Live",
    server3: "PacificUnionInt-Demo",
    server4: "PacificUnionInt-Live",
    server5: "PacificUnionLLC-Live 2",
    server6: "PacificUnionLLC-Live 3",
    server7: "PacificUnion-Demo",
    server8: "PacificUnion-Live 1",
    value: "Pacific Union",
  },
  {
    label: "Palmetto Brothers Capital",
    server1: "PalmettoBrothers-Live",
    server2: "PalmettoBrothers-Demo",
    value: "Palmetto Brothers Capital",
  },
  {
    label: "Panteon-Finance",
    server1: "Domberg-Trade5",
    server2: "Domberg-Trade7",
    server3: "Panteon-Server",
    value: "Panteon-Finance",
  },
  {
    label: "Panthera Trade",
    server1: "No servers",
    value: "Panthera Trade",
  },
  {
    label: "Paragon FX",
    server1: "ParagonFX-Live",
    server2: "ParagonFX-DEMO1",
    server3: "CMAP-Real",
    value: "Paragon FX",
  },
  {
    label: "PaxForex",
    server1: "PaxForex-Live Server",
    server2: "PaxForex-Demo Server",
    value: "PaxForex",
  },
  {
    label: "PBFX",
    server1: "PrimeBusiness-Demo",
    server2: "PrimeBusiness-Live",
    value: "PBFX",
  },
  {
    label: "PCFX",
    server1: "PCForex-Server",
    value: "PCFX",
  },
  {
    label: "PCIfx",
    server1: "PCI-Server",
    value: "PCIfx",
  },
  {
    label: "PCMBrokers",
    server1: "PCMTrader-Live",
    server2: "PCMTrader-Demo",
    value: "PCMBrokers",
  },
  {
    label: "PDM",
    server1: "PDM-Demo",
    server2: "PDM-Real",
    value: "PDM",
  },
  {
    label: "Pearl Capital Markets",
    server1: "Pearl-Demo",
    server2: "Pearl-Live",
    server3: "PearlCapitalMarkets-LIVE",
    server4: "PearlCapitalMarkets-DEMO",
    value: "Pearl Capital Markets",
  },
  {
    label: "PellucidFX",
    server1: "PellucidFX-Demo",
    server2: "PellucidFX-Main",
    value: "PellucidFX",
  },
  {
    label: "Penson Futures",
    server1: "PensonFutures-Demo",
    server2: "PensonFutures-Real5",
    value: "Penson Futures",
  },
  {
    label: "Pepperstone",
    server1: "Pepperstone-Demo01",
    server2: "Pepperstone-Live",
    server3: "Pepperstone-Demo02",
    server4: "Pepperstone-Edge01",
    server5: "Pepperstone-Edge02",
    server6: "Pepperstone Edge Live",
    server7: "Pepperstone-Edge03",
    server8: "Pepperstone-Edge04",
    server9: "Pepperstone-Edge05",
    server10: "Pepperstone-Edge06",
    server11: "Pepperstone-01",
    server12: "Pepperstone-Edge07",
    server13: "Pepperstone-Edge08",
    server14: "Pepperstone-Edge09",
    server15: "Pepperstone-Edge10",
    server16: "PepperstoneUK-Edge10",
    server17: "Pepperstone-Edge14",
    server18: "PepperstoneUK-Demo03",
    server19: "Pepperstone-Edge22",
    server20: "Pepperstone-Edge11",
    server21: "Pepperstone-Edge09",
    server22: "Pepperstone-Edge12",
    value: "Pepperstone",
  },
  {
    label: "Perfect Markets",
    server1: "PerfectMarkets-DEMO",
    server2: "PerfectMarkets-LIVE",
    value: "Perfect Markets",
  },
  {
    label: "PFGBest",
    server1: "PFG-Demo",
    server2: "PFG-Real",
    server3: "SpotTrader-Real",
    value: "PFGBest",
  },
  {
    label: "PFH Markets",
    server1: "PFHMarkets-NY7 DEMO",
    server2: "PFHMarkets-LIVE",
    value: "PFH Markets",
  },
  {
    label: "PFK",
    server1: "PFK-Server",
    value: "PFK",
  },
  {
    label: "Phase Markets",
    server1: "PhaseMarkets-MT4 Demo",
    server2: "PhaseMarkets-Real2",
    server3: "PhaseMarkets-Real4",
    value: "Phase Markets",
  },
  {
    label: "Phenexx",
    server1: "Phenexx-Demo",
    server2: "Phenexx-Live",
    value: "Phenexx",
  },
  {
    label: "Phillip Futures Pte. Ltd",
    server1: "Phillip Futures Pte. Ltd",
    server2: "PhillipFutures-Server",
    value: "Phillip Futures Pte. Ltd",
  },
  {
    label: "PhillipCapital",
    server1: "PCUK-Demo",
    server2: "PCUK-Live",
    server3: "PhillipCapital-AU-Demo",
    server4: "PhillipCapital-AU-Live",
    server5: "PhillipCapitalUK-Live",
    server6: "PhillipCapitalUK-Demo",
    value: "PhillipCapital",
  },
  {
    label: "PHOENIX 4X",
    server1: "Phoenix4X-Demo",
    server2: "Phoenix4X-Real",
    value: "PHOENIX 4X",
  },
  {
    label: "PinkForex",
    server1: "PinkGroup-Demo",
    server2: "PinkGroup-Live",
    value: "PinkForex",
  },
  {
    label: "Pipbull",
    server1: "Pipbull-Demo",
    server2: "Pipbull-Live",
    value: "Pipbull",
  },
  {
    label: "Piphijau Ltd",
    server1: "PipHijau-Demo",
    server2: "PipHijau-Real",
    value: "Piphijau Ltd",
  },
  {
    label: "PIPINDEX",
    server1: "PipIndexCapitalMarkets-Demo",
    server2: "FinsaEurope-Live",
    value: "PIPINDEX",
  },
  {
    label: "PipsMarkets",
    server1: "PipsMarkets-Demo",
    server2: "PipsMarkets-Pro",
    value: "PipsMarkets",
  },
  {
    label: "PipStriker",
    server1: "PIPSTRIKER-Demo",
    server2: "PIPSTRIKER-Real",
    value: "PipStriker",
  },
  {
    label: "Pivot Markets",
    server1: "PivotMarkets-Demo",
    server2: "PivotMarkets-Live",
    value: "Pivot Markets",
  },
  {
    label: "PKO FX",
    server1: "PKOFX-Demo",
    server2: "PKOFX-Real",
    value: "PKO FX",
  },
  {
    label: "Place a Trade",
    server1: "PlaceATrade-Real2",
    server2: "PlaceATrade-Demo2",
    server3: "PlaceATrade-Real-4",
    server4: "PlaceATrade-Demo3",
    value: "Place a Trade",
  },
  {
    label: "Platin FX",
    server1: "PlatinTrading-MT4 Demo",
    server2: "PlatinTrading-Real3",
    value: "Platin FX",
  },
  {
    label: "PLB Pro FX",
    server1: "PLBFX-Server",
    value: "PLB Pro FX",
  },
  {
    label: "Plotio",
    server1: "PlotioFX-Demo",
    server2: "PlotioFX-Live",
    value: "Plotio",
  },
  {
    label: "PlusFN",
    server1: "PlusFN-Demo Server",
    server2: "PlusFN-Main Server 1",
    value: "PlusFN",
  },
  {
    label: "PNB",
    server1: "PNB-Demo",
    server2: "PNB-Real",
    value: "PNB",
  },
  {
    label: "Poloinvest Limited",
    server1: "PoloInvest-Demo",
    server2: "PoloInvest-Live",
    value: "Poloinvest Limited",
  },
  {
    label: "PomeloFx",
    server1: "PomeloLimited-DEMO",
    server2: "PomeloLimited-LIVE",
    server3: "Pomelo-Demo",
    server4: "Pomelo-Live",
    value: "PomeloFx",
  },
  {
    label: "Portex Markets",
    server1: "PortexMarkets-Demo",
    server2: "PortexMarkets-Real",
    value: "Portex Markets",
  },
  {
    label: "PortFX",
    server1: "PortFX-Real",
    server2: "PortFX-Demo",
    value: "PortFX",
  },
  {
    label: "Power Rich Market",
    server1: "PowerRichMarket-Demo",
    server2: "PowerRichMarket-Live",
    value: "Power Rich Market",
  },
  {
    label: "PowerTrader",
    server1: "PowerTrader-Demo",
    server2: "PowerTrader-Live",
    value: "PowerTrader",
  },
  {
    label: "PPS Forex",
    server1: "PPSFX-Live",
    server2: "PPSFX-Demo",
    value: "PPS Forex",
  },
  {
    label: "PRC Group",
    server1: "PRC-Demo",
    server2: "PRC-Live",
    value: "PRC Group",
  },
  {
    label: "PreciousWealthGroup",
    server1: "PreciousWealth-Demo",
    server2: "PreciousWealth-Live",
    value: "PreciousWealthGroup",
  },
  {
    label: "Precise FX",
    server1: "PreciseFX-Live",
    server2: "PreciseFX-Live-2",
    server3: "PreciseFX-Practice",
    value: "Precise FX",
  },
  {
    label: "Precision4X",
    server1: "Precision4X-Demo",
    server2: "Precision4X-Live",
    value: "Precision4X",
  },
  {
    label: "PremierFX",
    server1: "PremierMarket-DEMO",
    server2: "PremierMarket-LIVE",
    server3: "PremierFX-DEMO",
    server4: "PremierFX-LIVE",
    value: "PremierFX",
  },
  {
    label: "PREMIUM ZONE",
    server1: "PremiumZone-Demo",
    server2: "PremiumZone-LiveLiquidity1",
    value: "PREMIUM ZONE",
  },
  {
    label: "Prestige Venture",
    server1: "PrestigeVenture-Demo",
    server2: "PrestigeVenture-Real",
    value: "Prestige Venture",
  },
  {
    label: "Prevail FX",
    server1: "PrevailFX-DEMO",
    server2: "PrevailFX-REAL SERVER",
    value: "Prevail FX",
  },
  {
    label: "Price Markets",
    server1: "PriceMarkets-Demo",
    server2: "PriceMarkets-Live",
    server3: "PriceMarkets-Live2",
    server4: "PriceMarkets-LiveLD",
    value: "Price Markets",
  },
  {
    label: "PriceBox Fx",
    server1: "PriceboxLLC-Demo",
    server2: "PriceboxLLC-Real",
    value: "PriceBox Fx",
  },
  {
    label: "Prima international",
    server1: "PrimaFuturesIntl-Real",
    server2: "PrimaFuturesIntl-Demo",
    value: "Prima international",
  },
  {
    label: "Prime Wealth Limited",
    server1: "PrimeWealth-Demo",
    server2: "PrimeWealth-Live",
    value: "Prime Wealth Limited",
  },
  {
    label: "Prime4x",
    server1: "PrimeForex-Demo",
    server2: "PrimeForex-Real",
    value: "Prime4x",
  },
  {
    label: "PrimeBankForex",
    server1: "PrimeBankForex-Demo",
    server2: "PrimeBankForex-Demo2",
    server3: "PrimeBankForex-Live2",
    value: "PrimeBankForex",
  },
  {
    label: "PrimeXM",
    server1: "PrimeXM-Demo",
    server2: "PrimeXM-Live",
    server3: "PrimeXM-Live 2",
    server4: "PrimeXM-DemoUS",
    server5: "PrimeXM-DemoUK",
    server6: "PrimeXM-LiveUS",
    server7: "PrimeXM-LiveUK",
    value: "PrimeXM",
  },
  {
    label: "Priminvestfx",
    server1: "Priminvestfx-Demo",
    server2: "Priminvestfx-Main",
    value: "Priminvestfx",
  },
  {
    label: "PriorFX",
    server1: "PriorFX-DemoUS",
    server2: "PriorFX-Live",
    server3: "PriorFX-Live2",
    value: "PriorFX",
  },
  {
    label: "PrivateFX",
    server1: "PrivateFX-MainMT4Server",
    value: "PrivateFX",
  },
  {
    label: "Privilege Systems",
    server1: "PrivilegeSystems-Live 3",
    server2: "PrivilegeSystems-Demo Server",
    value: "Privilege Systems",
  },
  {
    label: "Prixo Markets",
    server1: "PrixoMarkets-DEMO",
    server2: "PrixoMarkets-LIVE",
    value: "Prixo Markets",
  },
  {
    label: "Pro Active Trader",
    server1: "PAT-Live",
    value: "Pro Active Trader",
  },
  {
    label: "Pro Finance",
    server1: "ProFinance-Server",
    value: "Pro Finance",
  },
  {
    label: "ProCapitals",
    server1: "No servers",
    value: "ProCapitals",
  },
  {
    label: "Profiforex",
    server1: "Profiforex-Demo",
    server2: "ForexServer-Real",
    server3: "ForexServer-Demo",
    server4: "Forexstart-Real",
    server5: "Forexstart-Demo",
    server6: "Forexstart-Real",
    server7: "Profiforex Server",
    value: "Profiforex",
  },
  {
    label: "Profit Group",
    server1: "ProfitLT-Server",
    server2: "PROFITGroup-Server",
    value: "Profit Group",
  },
  {
    label: "Profit Large Trading",
    server1: "ProfitLT-Server",
    value: "Profit Large Trading",
  },
  {
    label: "Profit-Trade",
    server1: "GlobalTopMarketingLtd-Trader",
    value: "Profit-Trade",
  },
  {
    label: "Profit4Trade",
    server1: "Profit4Trade-Demo",
    server2: "Profit4Trade-LiveLiquidity1",
    value: "Profit4Trade",
  },
  {
    label: "Profit4x",
    server1: "Profit4X-Demo",
    server2: "Profit4X-Live",
    server3: "Profit4X-Real",
    value: "Profit4x",
  },
  {
    label: "Profito FX",
    server1: "Profitofx-Real",
    value: "Profito FX",
  },
  {
    label: "Profitto",
    server1: "Profitto-DEMO",
    server2: "Profitto-LIVE",
    server3: "ProfittoLtd-Live",
    server4: "ProfittoLtd-Demo",
    value: "Profitto",
  },
  {
    label: "ProFunds FX",
    server1: "ProFunds-Demo 2",
    server2: "ProFunds-Live 2",
    value: "ProFunds FX",
  },
  {
    label: "ProFX Market",
    server1: "ProFXmarket-Demo",
    server2: "ProFXmarket-Live",
    server3: "TradeProServices-Live",
    value: "ProFX Market",
  },
  {
    label: "ProlineFX",
    server1: "ProLine-FX-Demo",
    server2: "ProLine-FX-Main",
    value: "ProlineFX",
  },
  {
    label: "PronetFX",
    server1: "No servers",
    value: "PronetFX",
  },
  {
    label: "ProsperityFX",
    server1: "Prosperity-Demo",
    server2: "Prosperity-Live",
    server3: "Prosperity-Live4",
    server4: "Prosperity-Real",
    value: "ProsperityFX",
  },
  {
    label: "Prospero",
    server1: "ProsperoMarkets-Demo",
    server2: "ProsperoMarkets-Live",
    value: "Prospero",
  },
  {
    label: "Proton Capital Markets",
    server1: "ProtonCapital-Demo",
    server2: "ProtonCapital-Live",
    value: "Proton Capital Markets",
  },
  {
    label: "Protrade",
    server1: "VTR-Server",
    value: "Protrade",
  },
  {
    label: "Prudent International",
    server1: "PrudentIntl-Demo",
    server2: "PrudentIntl-Live",
    server3: "PrudentIntlBerjangka-Demo",
    server4: "PrudentIntlBerjangka-Live",
    server5: "CIF-Demo",
    value: "Prudent International",
  },
  {
    label: "Pruton Capital",
    server1: "PrutonMF-Demo",
    server2: "PrutonMF-Live",
    server3: "PrutonMF2-Live",
    server4: "PrutonMF4-Demo",
    server5: "PrutonMF4-Live",
    server6: "PrutonMF3-Live-B",
    server7: "PrutonMF10-Live",
    server8: "PrutonMF7-Demo",
    server9: "PrutonMF7-Live",
    server10: "PrutonMF9-Live",
    server11: "PrutonMF8-Live",
    server12: "PrutonMF12-Live",
    server13: "PrutonMF13-Live",
    value: "Pruton Capital",
  },
  {
    label: "Pruton Futures",
    server1: "Pruton-Demo",
    server2: "Pruton-Real",
    server3: "PrutonMF10-Live",
    value: "Pruton Futures",
  },
  {
    label: "PSS",
    server1: "PSS-Demo",
    server2: "PSS-Live",
    value: "PSS",
  },
  {
    label: "PT Agrodana Futures",
    server1: "Agrodana-Live",
    server2: "Agrodana-Demo",
    value: "PT Agrodana Futures",
  },
  {
    label: "PT Trijaya Pratama Futures",
    server1: "TPF-Live",
    server2: "TPF-Real",
    server3: "TPF-Demo",
    value: "PT Trijaya Pratama Futures",
  },
  {
    label: "PU Prime",
    server1: "PUPrime-Demo",
    server2: "PUPrime-Live 2",
    server3: "PUPrime-Live 3",
    server4: "PUPrime-Live",
    value: "PU Prime",
  },
  {
    label: "Pure Market",
    server1: "PureMGlobal-Demo",
    server2: "PureMGlobal-Live",
    value: "Pure Market",
  },
  {
    label: "PureECN",
    server1: "Goldrockfx-Live",
    server2: "Goldrockfx-Demo",
    value: "PureECN",
  },
  {
    label: "Purple Trading",
    server1: "PurpleTrading-01Demo",
    server2: "PurpleTrading-03Live",
    server3: "PurpleTrading-04Live",
    server4: "PurpleTrading-01Live",
    server5: "PurpleTrading-02Demo",
    server6: "PurpleTrading-02Live",
    server7: "PurpleTrading-05Live",
    server8: "PurpleTradingSC-02Demo",
    server9: "PurpleTradingSC-04Live",
    value: "Purple Trading",
  },
  {
    label: "PVPMARKETS",
    server1: "PVPMarkets-Demo",
    server2: "PVPMarkets-Real",
    value: "PVPMARKETS",
  },
  {
    label: "PXC FX",
    server1: "Primexecute-Demo",
    server2: "Primexecute-Live",
    value: "PXC FX",
  },
  {
    label: "Pylon FX",
    server1: "PylonFintech-Server",
    value: "Pylon FX",
  },
  {
    label: "Q8 Trade",
    server1: "KuwaitSecurities-Live",
    server2: "KuwaitSecurities-Demo",
    value: "Q8 Trade",
  },
  {
    label: "QNB Finansinvest",
    server1: "QNBFinansInvest-Demo",
    server2: "QNBFinansInvest-Live",
    value: "QNB Finansinvest",
  },
  {
    label: "QRS Global",
    server1: "QRSGlobal-Demo",
    server2: "QRSGlobal-Real",
    value: "QRS Global",
  },
  {
    label: "QTrade",
    server1: "QTrade-1",
    server2: "QTrade-2",
    server3: "QTrade-3",
    server4: "QTrade-4",
    server5: "QTrade-5",
    server6: "QTrade-Demo",
    value: "QTrade",
  },
  {
    label: "Qtrade Limited",
    server1: "Qtrade-Live",
    server2: "Qtrade-Demo",
    server3: "QTradeFX-Demo",
    server4: "QtradeFX-Live",
    server5: "QuoteTrade-Live 3",
    server6: "QuoteTrade-Demo Server",
    server7: "QtradeFX-Demo Production",
    server8: "QtradeFX-Live2",
    server9: "QTrade-5",
    server10: "QtradeFX-Demo2",
    value: "Qtrade Limited",
  },
  {
    label: "QuadraFX",
    server1: "QuadraFx-Demo",
    server2: "QuadraFx-Live",
    value: "QuadraFX",
  },
  {
    label: "Quantic",
    server1: "QuanticVenture-Demo",
    server2: "QuanticVenture-Live",
    value: "Quantic",
  },
  {
    label: "Quantlio Investments",
    server1: "Quantlio-Demo",
    server2: "Quantlio-Trading",
    value: "Quantlio Investments",
  },
  {
    label: "Quantum Trade",
    server1: "QuantumTrade-Demo",
    server2: "QuantumTrade-Live",
    value: "Quantum Trade",
  },
  {
    label: "Questrade",
    server1: "Questrade-Demo(R)",
    server2: "Questrade-QST",
    server3: "Questrade-Live 11",
    server4: "Forex.com-Demo(R)",
    value: "Questrade",
  },
  {
    label: "Quineex",
    server1: "Quineex-Demo",
    server2: "Quineex-Live",
    value: "Quineex",
  },
  {
    label: "Quinnix",
    server1: "QUINNIX-Demo",
    server2: "QUINNIX-Live",
    value: "Quinnix",
  },
  {
    label: "Rad Forex",
    server1: "RadTrading-Demo",
    server2: "RadTrading-Real",
    value: "Rad Forex",
  },
  {
    label: "Radex Markets",
    server1: "RadexMarkets-Demo",
    server2: "RadexMarkets-Real 3",
    server3: "RadexMarkets-Real 6",
    value: "Radex Markets",
  },
  {
    label: "Raffles Market",
    server1: "RafflesMarket-Demo",
    server2: "RafflesMarket-Live",
    value: "Raffles Market",
  },
  {
    label: "RaiseFX",
    server1: "RaiseGroup-Live-UK-2",
    value: "RaiseFX",
  },
  {
    label: "Raising FX",
    server1: "RaisingInternational-Demo",
    server2: "RaisingInternational-Real",
    server3: "RaisingInternational-Live",
    value: "Raising FX",
  },
  {
    label: "Rakuten Securities Australia",
    server1: "RakutenSecurities-Demo",
    server2: "RakutenSecurities-Live",
    server3: "RakutenSecuritiesAustralia-Live",
    server4: "RakutenSecuritiesHK-Demo",
    server5: "RakutenSecuritiesBullion-Live",
    server6: "RakutenSecuritiesAustralia-Demo",
    value: "Rakuten Securities Australia",
  },
  {
    label: "RallyTrade",
    server1: "ForexRally-MT4 Demo",
    server2: "ForexRally-Real3",
    value: "RallyTrade",
  },
  {
    label: "Rallyville Markets",
    server1: "RallyvilleMarkets-Live",
    value: "Rallyville Markets",
  },
  {
    label: "Random FX",
    server1: "Random-EURDemo01",
    server2: "Random-EURReal01",
    server3: "Random-USDReal01",
    server4: "Random-USDDemo01",
    value: "Random FX",
  },
  {
    label: "Range Markets",
    server1: "RangeMarkets-Demo",
    server2: "RangeMarkets-Real2",
    value: "Range Markets",
  },
  {
    label: "RannForex",
    server1: "MTrading-Demo",
    server2: "MTrading-Live",
    value: "RannForex",
  },
  {
    label: "Raynar Group",
    server1: "RaynarGroup-Demo",
    server2: "RaynarGroup-Real",
    value: "Raynar Group",
  },
  {
    label: "RBC Forex",
    server1: "Ficex-Real",
    server2: "Ficex-Demo",
    value: "RBC Forex",
  },
  {
    label: "RBFXpro",
    server1: "RBFXPRO-Server",
    value: "RBFXpro",
  },
  {
    label: "RCG Markets",
    server1: "RCGMarkets-Demo",
    server2: "RCGMarkets-Real",
    value: "RCG Markets",
  },
  {
    label: "Real Forex",
    server1: "RFX-Demo",
    server2: "CMAP-Live-UK",
    server3: "CMAP-Real",
    server4: "RFX-Real",
    value: "Real Forex",
  },
  {
    label: "Real Time Futures",
    server1: "RealTimeFutures-Demo",
    server2: "RealTimeFutures-Live",
    value: "Real Time Futures",
  },
  {
    label: "Real Trade",
    server1: "RealTrade-Demo",
    server2: "RealTrade-Real",
    value: "Real Trade",
  },
  {
    label: "RealityMarket",
    server1: "WMMarketsLLC-Demo",
    server2: "WMMarketsLLC-Live2",
    value: "RealityMarket",
  },
  {
    label: "Realms FX",
    server1: "RealmsTech-Demo",
    server2: "RealmsTech-live1",
    value: "Realms FX",
  },
  {
    label: "REDbfx",
    server1: "RedbTrader-Demo",
    server2: "RedbTrader-Real",
    value: "REDbfx",
  },
  {
    label: "Redex Holdings Limited",
    server1: "RedexHoldings-Demo",
    server2: "RedexHoldings-Real",
    value: "Redex Holdings Limited",
  },
  {
    label: "RediMaxCapital",
    server1: "RediMax-Live1",
    value: "RediMaxCapital",
  },
  {
    label: "RedMars",
    server1: "Redmars-Server",
    value: "RedMars",
  },
  {
    label: "Redstone",
    server1: "RedstoneMarketsLtd-Demo",
    server2: "RedstoneMarketsLtd-Live",
    value: "Redstone",
  },
  {
    label: "REF Markets",
    server1: "RiverEast-Demo",
    server2: "RiverEast-Live5",
    value: "REF Markets",
  },
  {
    label: "Regal Core Markets",
    server1: "RegalCoreMarkets-Demo",
    server2: "RegalCoreMarkets-Live",
    value: "Regal Core Markets",
  },
  {
    label: "RegalX",
    server1: "RegalHoldings-Demo01",
    server2: "RegalHoldings-Real01",
    server3: "RegalHoldings-Real02",
    server4: "RegalHoldings-Real03",
    value: "RegalX",
  },
  {
    label: "Regenesis Markets",
    server1: "RegenesisMarkets-DEMO",
    server2: "RegenesisMarkets-LIVE",
    value: "Regenesis Markets",
  },
  {
    label: "Renaissance",
    server1: "RenaissanceInvestment-Real",
    server2: "RenaissanceInvestment-Demo",
    value: "Renaissance",
  },
  {
    label: "Renesource Capital",
    server1: "Renesource-REAL",
    server2: "Renesource-DEMO",
    value: "Renesource Capital",
  },
  {
    label: "REVIVE TRADING GROUP",
    server1: "ReviveTradingGroup-DEMO",
    server2: "ReviveTradingGroup-REAL SERVER",
    value: "REVIVE TRADING GROUP",
  },
  {
    label: "Revotrade",
    server1: "Revotrade-Demo",
    server2: "Revotrade-Live",
    value: "Revotrade",
  },
  {
    label: "Rex Capital Futures",
    server1: "RexTrader-Demo",
    server2: "RexTrader-Live",
    value: "Rex Capital Futures",
  },
  {
    label: "REXFO",
    server1: "REXFO-Demo",
    server2: "REXFO-Real",
    value: "REXFO",
  },
  {
    label: "Rexor",
    server1: "Rexor-Demo",
    server2: "Rexor-Live",
    value: "Rexor",
  },
  {
    label: "RFC",
    server1: "RFC-Demo",
    server2: "RFC-Live",
    value: "RFC",
  },
  {
    label: "RFXT",
    server1: "RoyalFXT-Real",
    server2: "RoyalFXT-Demo",
    server3: "ROYAL-Real-01",
    value: "RFXT",
  },
  {
    label: "RGV Financial Ltd",
    server1: "RGVFinancial-Demo",
    server2: "RGVFinancial-Live",
    value: "RGV Financial Ltd",
  },
  {
    label: "Rhodium Forex",
    server1: "InterForex-Server",
    server2: "Rhodium-Server",
    value: "Rhodium Forex",
  },
  {
    label: "RI Markets",
    server1: "FcorpServicesLtd-Demo",
    server2: "FcorpServicesLtd-Live",
    value: "RI Markets",
  },
  {
    label: "RI-FX",
    server1: "RealForInvestment-Demo",
    server2: "RealForInvestment-Real",
    value: "RI-FX",
  },
  {
    label: "Rich Smart",
    server1: "RichSmart-Demo",
    server2: "RichSmart-Live",
    value: "Rich Smart",
  },
  {
    label: "Ridge Capital Markets",
    server1: "RDGroup-Demo",
    server2: "RDGroup-Live",
    value: "Ridge Capital Markets",
  },
  {
    label: "Rietumu",
    server1: "Rietumu-DemoUS",
    server2: "Rietumu-LiveUS",
    value: "Rietumu",
  },
  {
    label: "RiftFX",
    server1: "RiftBroker-Demo Server",
    server2: "RiftBroker-Live 8",
    value: "RiftFX",
  },
  {
    label: "RIFX",
    server1: "RoyalInvMediation-Demo",
    server2: "RoyalInvMediation-Live 2",
    value: "RIFX",
  },
  {
    label: "RIGFX",
    server1: "RigFX-Demo",
    server2: "RigFX-RCM",
    server3: "RigFX-Live",
    value: "RIGFX",
  },
  {
    label: "Right Win Group",
    server1: "RightWinGroup-Demo",
    server2: "RightWinGroup-Live",
    server3: "SICFXLive",
    server4: "PrimeXM-Live",
    server5: "PrimeXM-Live 2",
    server6: "PrimeXM-demo",
    value: "Right Win Group",
  },
  {
    label: "Rinkost",
    server1: "Rinkost-Demo",
    server2: "Rinkost-Real",
    value: "Rinkost",
  },
  {
    label: "Rise Hill Holdings",
    server1: "RiseHill-Live",
    server2: "RiseHill-Demo",
    value: "Rise Hill Holdings",
  },
  {
    label: "RITZSTAR",
    server1: "RIT-Demo",
    server2: "RIT-Live",
    value: "RITZSTAR",
  },
  {
    label: "RMS-CORPORATE",
    server1: "RMSFameliaCo-Demo",
    server2: "RMSFameliaCo-Live",
    value: "RMS-CORPORATE",
  },
  {
    label: "RoboForex",
    server1: "Robo-Demo",
    server2: "RoboForex-Fix",
    server3: "Robo-Pro",
    server4: "RoboForex-Demo",
    server5: "RoboForex-FixCent",
    server6: "RoboForex-Pro",
    server7: "RoboForexEU-ECN",
    server8: "RoboForex-ProCent",
    server9: "RoboForex-ECN",
    server10: "RoboForexEU-ProCent",
    server11: "RoboForexEU-FixCent",
    server12: "RoboFX-Demo",
    server13: "RoboFX-ECN",
    server14: "RoboForexEU-Pro",
    server15: "RoboForex - ECN-Pro-NDD",
    server16: "RoboForexEU-Demo",
    server17: "RoboForexDE-Pro",
    server18: "RoboForexDE-Demo",
    server19: "RoboForexEU-Fix",
    server20: "RoboForex-Prime",
    server21: "RoboForex-Pro-3",
    server22: "RoboForex-ECN-2",
    server23: "RoboForex-ProCent-7",
    server24: "RoboForex-ProCent-6",
    server25: "RoboForex-Pro-4",
    server26: "RoboForex-Pro-2",
    server27: "RoboForex-ProCent-2",
    server28: "RoboForex-DemoPro",
    server29: "RoboForex-ProCent-3",
    server30: "RoboForex-ProCent-4",
    server31: "RoboForex-ProCent-5",
    value: "RoboForex",
  },
  {
    label: "RoboMarkets",
    server1: "RoboMarketsDE-Demo",
    server2: "RoboMarketsDE-DemoPro",
    server3: "RoboMarketsDE-ECN",
    server4: "RoboMarketsDE-Prime",
    server5: "RoboMarketsDE-Pro",
    server6: "RoboMarketsDE-Pro-2",
    server7: "RoboMarketsDE-ProCent",
    server8: "RoboMarketsDE-ProCent-2",
    server9: "RoboMarketsDE-ProCent-3",
    server10: "RoboMarketsDE-ProCent-4",
    server11: "RoboMarketsLLC-Demo",
    server12: "RoboMarketsLLC-DemoPro",
    server13: "RoboMarketsLLC-ECN",
    server14: "RoboMarketsLLC-ECN-2",
    server15: "RoboMarketsLLC-Prime",
    server16: "RoboMarketsLLC-Pro",
    server17: "RoboMarketsLLC-Pro-2",
    server18: "RoboMarketsLLC-Pro-3",
    server19: "RoboMarketsLLC-Pro-4",
    server20: "RoboMarketsLLC-ProCent",
    server21: "RoboMarketsLLC-ProCent-2",
    server22: "RoboMarketsLLC-ProCent-3",
    server23: "RoboMarketsLLC-ProCent-4",
    server24: "RoboMarketsLLC-ProCent-5",
    server25: "RoboMarketsLLC-ProCent-6",
    server26: "RoboMarketsLLC-ProCent-7",
    value: "RoboMarkets",
  },
  {
    label: "Rockfort Markets",
    server1: "RockfortMarkets-Live",
    server2: "RockfortMarkets-Demo",
    server3: "RockfortIntl-Live",
    server4: "RockfortIntl-Demo",
    value: "Rockfort Markets",
  },
  {
    label: "RocoForex",
    server1: "ROCO-Demo",
    server2: "ROCO-Real",
    value: "RocoForex",
  },
  {
    label: "Roger Templeton NY",
    server1: "BSTrading-Demo Server",
    server2: "BSTrading-Live 3",
    server3: "BSTrading-Live 8",
    server4: "RogerTempletonNY-Demo Server",
    server5: "RogerTempletonNY-Live 4",
    value: "Roger Templeton NY",
  },
  {
    label: "Royal Financial Trading",
    server1: "ROYAL-Demo",
    server2: "ROYAL-Real",
    server3: "RoyalFinancials-Demo",
    server4: "RoyalPlatforms-Real-01",
    server5: "RoyalFinancialTrading-Demo",
    server6: "RoyalFinancialTrading-Real",
    server7: "RoyalFinancials-Real",
    server8: "RoyalFinancials-Demo",
    server9: "RoyalFinancials-DemoTEST",
    server10: "RoyalPlatforms-Real",
    value: "Royal Financial Trading",
  },
  {
    label: "Royal Pip",
    server1: "RoyalPip-Demo",
    server2: "RoyalPip-Real",
    value: "Royal Pip",
  },
  {
    label: "RPD FX",
    server1: "RPDFX-Demo Server",
    server2: "RPDFX-Live Server",
    value: "RPD FX",
  },
  {
    label: "RSM4x",
    server1: "RSM4X-Live 8",
    value: "RSM4x",
  },
  {
    label: "RSQ Forex",
    server1: "RSQForex-Demo",
    server2: "RSQForex-Real2",
    value: "RSQ Forex",
  },
  {
    label: "Ruizean Trader",
    server1: "RuizeanTrader-Demo",
    server2: "RuizeanTrader-Live",
    value: "Ruizean Trader",
  },
  {
    label: "RVD Markets",
    server1: "RVDMarkets-Live ECN",
    server2: "RVDMarkets-Demo ECN",
    value: "RVD Markets",
  },
  {
    label: "SageFX",
    server1: "SageFx-Real",
    server2: "SageFx-Demo",
    server3: "SageFx-Live",
    value: "SageFX",
  },
  {
    label: "Sakura FX Trading",
    server1: "SakuraFX-Live",
    value: "Sakura FX Trading",
  },
  {
    label: "Salam Investments",
    server1: "AlSalamFinancialInv-Demo",
    server2: "AlSalamFinancialInv-Live",
    value: "Salam Investments",
  },
  {
    label: "SalmaForex",
    server1: "Salmaforex-Demo",
    server2: "Salmaforex-Server",
    server3: "SalmaMarkets-Server",
    server4: "SalmaMarkets-Demo",
    value: "SalmaForex",
  },
  {
    label: "salmamarkets",
    server1: "SalmaMarkets-Demo",
    server2: "SalmaMarkets-Live",
    value: "salmamarkets",
  },
  {
    label: "Sanko Yatirim FX",
    server1: "SankoFX-Demo",
    server2: "SankoFX-Live",
    value: "Sanko Yatirim FX",
  },
  {
    label: "Sapphire Markets",
    server1: "SapphireMarkets-Demo",
    server2: "SapphireMarkets-Markets Live 2",
    value: "Sapphire Markets",
  },
  {
    label: "SaracenMarkets",
    server1: "SaracenInc-Live",
    server2: "SaracenInc-Demo",
    value: "SaracenMarkets",
  },
  {
    label: "SAXO",
    server1: "SaxoMT4-Demo",
    server2: "SaxoBank-Live",
    server3: "SaxoMT4-Server",
    server4: "SaxoBank-Server",
    value: "SAXO",
  },
  {
    label: "Saxon Capital Group",
    server1: "Saxon-Demo",
    server2: "Saxon-Real",
    value: "Saxon Capital Group",
  },
  {
    label: "SBOForex",
    server1: "Asiawide-Demo",
    server2: "Asiawide-Real",
    value: "SBOForex",
  },
  {
    label: "Scandinavian Capital Markets",
    server1: "ScandinavianMarkets-Demo",
    server2: "ScandinavianMarkets-Live",
    server3: "ScandinavianCapital-Live-UK",
    server4: "ScandinavianCapital-Demo-UK",
    value: "Scandinavian Capital Markets",
  },
  {
    label: "Schatz Markets",
    server1: "SchatzMarkets-Demo",
    server2: "SchatzMarkets-Live",
    value: "Schatz Markets",
  },
  {
    label: "SCM",
    server1: "ScandinavianMarkets-Live",
    server2: "ScandinavianMarkets-Demo",
    server3: "ScandinavianCapital-Live-UK",
    value: "SCM",
  },
  {
    label: "SCN BROKER",
    server1: "CoreSpreads-LiveBravo",
    server2: "CoreSpreads-DemoBravo",
    value: "SCN BROKER",
  },
  {
    label: "Scope Markets",
    server1: "ScopeMarkets-Demo",
    server2: "ScopeMarkets-Live",
    value: "Scope Markets",
  },
  {
    label: "SEC Markets",
    server1: "SecMarketsLLC-Demo",
    server2: "SecMarketsLLC-Live",
    value: "SEC Markets",
  },
  {
    label: "SECONDVIEW FX",
    server1: "SecondViewMarkets-Live 7",
    server2: "SecondViewMarkets-Demo Server",
    server3: "SecondViewMarkets-Asia 4",
    value: "SECONDVIEW FX",
  },
  {
    label: "SekerFX",
    server1: "SekerFX-DEMO",
    server2: "SekerFX-Gercek",
    value: "SekerFX",
  },
  {
    label: "SENSE LEADER",
    server1: "SenseLeader-Demo",
    server2: "SenseLeader-Real",
    value: "SENSE LEADER",
  },
  {
    label: "SENSEI markets",
    server1: "XTrade-MT4 Demo",
    server2: "XTrade-Real3",
    value: "SENSEI markets",
  },
  {
    label: "Sentratama Investor",
    server1: "SentratamaInvestor-Live",
    server2: "SentratamaInvestor-Demo",
    value: "Sentratama Investor",
  },
  {
    label: "Sese Skyline",
    server1: "SeseSkyline-Demo",
    server2: "SeseSkyline-Main",
    server3: "SeseSkyline-Live",
    value: "Sese Skyline",
  },
  {
    label: "SETfx",
    server1: "SouthEastTrading-Demo",
    server2: "SouthEastTrading-Live",
    value: "SETfx",
  },
  {
    label: "Seven FX Group",
    server1: "SevenGroupFinancial-Demo",
    server2: "SevenGroupFinancial-Live",
    value: "Seven FX Group",
  },
  {
    label: "Seven Star FX",
    server1: "SevenStarFX-Demo",
    server2: "SevenStarFX-Live",
    value: "Seven Star FX",
  },
  {
    label: "SFEX",
    server1: "SFEXGlobal-Demo",
    server2: "SFEXGlobal-Live",
    value: "SFEX",
  },
  {
    label: "SFX Markets",
    server1: "SFX-Markets-AsiaPac Demo",
    server2: "SFX-Markets-Prod",
    server3: "SFXMarkets-Live",
    server4: "SFXGlobal-Server",
    value: "SFX Markets",
  },
  {
    label: "SFXCapitals",
    server1: "SeedingIntlGroup-Trading",
    value: "SFXCapitals",
  },
  {
    label: "SG Global FX",
    server1: "SGAdvisoryLtd-Demo",
    server2: "SGAdvisoryLtd-Live",
    value: "SG Global FX",
  },
  {
    label: "SGT Markets",
    server1: "SGTMarkets-Demo",
    server2: "SGTMarkets-Live",
    value: "SGT Markets",
  },
  {
    label: "SHKForex",
    server1: "SHK-Demo",
    server2: "SHK-Live",
    value: "SHKForex",
  },
  {
    label: "SIBFX",
    server1: "SentratamaInvestor-Demo",
    server2: "SentratamaInvestor-Live",
    value: "SIBFX",
  },
  {
    label: "Sibilit",
    server1: "SibilitMarkets-Server",
    value: "Sibilit",
  },
  {
    label: "SIG",
    server1: "SIG-Real.com",
    server2: "SIG-Cent.com",
    server3: "SIG-Lite.hk",
    server4: "SIG-Lite.us",
    server5: "SIG-Live",
    server6: "SIG-Real.hk",
    server7: "SIG-Real.us",
    server8: "SIG-Lite2.com",
    value: "SIG",
  },
  {
    label: "Sigma Forex",
    server1: "SigmaForex-Real",
    server2: "SigmaForex-Demo",
    value: "Sigma Forex",
  },
  {
    label: "Sigma Primary",
    server1: "SigmaPrimary-Demo",
    server2: "SigmaPrimary-Real",
    value: "Sigma Primary",
  },
  {
    label: "SIGMA TREND",
    server1: "SigmaTrend-Server",
    value: "SIGMA TREND",
  },
  {
    label: "Simple Trading",
    server1: "SimplePrime-DEMO",
    server2: "SimplePrime-LIVE",
    server3: "SimpleHoldingInt-DEMO",
    server4: "SimpleHoldingInt-LIVE",
    value: "Simple Trading",
  },
  {
    label: "SimpleFX",
    server1: "SimpleFX-DemoUK",
    server2: "SimpleFX-LiveUK",
    value: "SimpleFX",
  },
  {
    label: "SinoneFX",
    server1: "SinonefxPremier-Demo",
    server2: "SinonefxPremier-Live",
    value: "SinoneFX",
  },
  {
    label: "SismaCapital",
    server1: "Sisma-Demo",
    server2: "Sisma-Main",
    value: "SismaCapital",
  },
  {
    label: "Skilling",
    server1: "SkillingLimited-Live",
    server2: "SkillingLimited-Demo",
    value: "Skilling",
  },
  {
    label: "Sky Alliance Markets",
    server1: "SkyAllianceMarkets-Demo",
    server2: "SkyAllianceMarkets-Live",
    value: "Sky Alliance Markets",
  },
  {
    label: "Skyeast",
    server1: "Skyeast-Demo Accounts Server",
    server2: "Skyeast-Live Accounts Server",
    value: "Skyeast",
  },
  {
    label: "SkyFX",
    server1: "SKYFX-Main Server 1",
    server2: "SKYFX-Demo Server",
    server3: "SKYFX-Main Server 2",
    server4: "SKYFX-Main Server",
    server5: "Belarta-Live",
    server6: "CFHMarkets-Demo",
    server7: "MBT-Live Server",
    value: "SkyFX",
  },
  {
    label: "SL Industrial Trader",
    server1: "SLIndustrial-Demo",
    server2: "SLIndustrial-Live",
    value: "SL Industrial Trader",
  },
  {
    label: "Smart Capital",
    server1: "SmartCapital-Demo",
    server2: "SmartCapital-MetaTrader Live",
    value: "Smart Capital",
  },
  {
    label: "Smart Live Markets",
    server1: "SmartLive-Demo",
    server2: "SmartLive-FX-CFD Live",
    server3: "SmartLive-Spread Trading Live",
    value: "Smart Live Markets",
  },
  {
    label: "Smart Markets",
    server1: "SmartMarkets-Demo",
    server2: "SmartMarkets-Live",
    value: "Smart Markets",
  },
  {
    label: "Smart Prime FX",
    server1: "SmartPrime-Live",
    server2: "SmartPrime-Demo",
    value: "Smart Prime FX",
  },
  {
    label: "SmartTradeFX",
    server1: "SmartTradeFX-Demo",
    server2: "SmartTradeFX-Real",
    server3: "STFX-Demo",
    server4: "STFX-Real",
    value: "SmartTradeFX",
  },
  {
    label: "SNP Investments LTD",
    server1: "SNP-Demo",
    server2: "SNP-Live",
    value: "SNP Investments LTD",
  },
  {
    label: "Sôegee Futures",
    server1: "SoegeeTrader-Demo",
    server2: "SoegeeTrader-Real",
    value: "Sôegee Futures",
  },
  {
    label: "SohoMarkets",
    server1: "VstarandSohoMarkets-Demo",
    server2: "VstarandSohoMarkets-Live",
    server3: "SohoMarkets-Demo",
    server4: "SohoMarkets-Live",
    value: "SohoMarkets",
  },
  {
    label: "Solforex",
    server1: "Solforex-Demo",
    server2: "Solforex-Live",
    value: "Solforex",
  },
  {
    label: "Solid Trust Markets",
    server1: "SolidOptionsBrokers-Demo",
    server2: "SolidOptionsBrokers-Real",
    value: "Solid Trust Markets",
  },
  {
    label: "Solidary Markets FX",
    server1: "SADASoftware-LiveLiquidity1",
    server2: "SADASoftware-Demo",
    value: "Solidary Markets FX",
  },
  {
    label: "Solidity Brokers",
    server1: "SolidityBrokers-Demo",
    server2: "SolidityBrokers-Main",
    value: "Solidity Brokers",
  },
  {
    label: "SolidusFX",
    server1: "SFX-CFD",
    server2: "SFX-ECN",
    value: "SolidusFX",
  },
  {
    label: "Solution Keys",
    server1: "SolutionKeys-Demo Accounts Server",
    server2: "SolutionKeys-Live Accounts Server",
    value: "Solution Keys",
  },
  {
    label: "Solver Trade",
    server1: "SolverTrade-Server",
    value: "Solver Trade",
  },
  {
    label: "Sovereign trade group",
    server1: "AFS-Demo",
    server2: "AFS-Live",
    value: "Sovereign trade group",
  },
  {
    label: "Sparen FX",
    server1: "Sparen-Server",
    server2: "200.46.13.23:443",
    value: "Sparen FX",
  },
  {
    label: "SpartanFX",
    server1: "SpartanFX-Demo",
    server2: "SpartanFX-Live",
    server3: "Bank.mt4demo1.primexm.com:443",
    value: "SpartanFX",
  },
  {
    label: "SpediaFX",
    server1: "SpediaFX-Demo",
    server2: "SpediaFX-Real",
    value: "SpediaFX",
  },
  {
    label: "Spot Trader Fx",
    server1: "SpotTrader-Real",
    value: "Spot Trader Fx",
  },
  {
    label: "SpotX Markets",
    server1: "SpotXMarkets-LIVE",
    server2: "SpotXMarkets-DEMO",
    value: "SpotX Markets",
  },
  {
    label: "Spread International (SICFX)",
    server1: "SICFX-SIC FZC",
    server2: "SICFX-Demo",
    value: "Spread International (SICFX)",
  },
  {
    label: "SPREADCO",
    server1: "SCO-Demo",
    server2: "SCO-Server",
    value: "SPREADCO",
  },
  {
    label: "Spring Gold Market",
    server1: "SpringGoldMarket-Demo",
    server2: "SpringGoldMarket-Live",
    value: "Spring Gold Market",
  },
  {
    label: "Square Link Financial",
    server1: "SquareLink-Demo",
    server2: "SquareLink-Live",
    value: "Square Link Financial",
  },
  {
    label: "Squared Financial",
    server1: "SquaredDirect-Demo",
    server2: "SquaredDirect-Live2",
    server3: "SquaredMT4-Demo",
    server4: "SquaredMT4-Live",
    server5: "SquaredFinancial-Live",
    server6: "SquaredFinancial-Demo2",
    server7: "SquaredFinancial-Demo",
    server8: "SquaredFinancial-Live2",
    value: "Squared Financial",
  },
  {
    label: "Squared Pro",
    server1: "SquaredMT4-Live",
    server2: "SquaredMT4-Demo",
    server3: "SquaredProMT4-Live",
    server4: "SquaredProMT4-Demo",
    value: "Squared Pro",
  },
  {
    label: "SSFX Limited",
    server1: "SSFX-Demo",
    server2: "SSFX-Real2",
    server3: "StrategyStation-DemoUK",
    server4: "StrategyStation-LiveUK",
    value: "SSFX Limited",
  },
  {
    label: "Star Financial Markets",
    server1: "StarFinancial-AU01-Demo",
    server2: "StarFinancial-Demo",
    server3: "StarFinancial-Live",
    server4: "StarFinancial-AU01-Live",
    server5: "StarFinancial-US05-Live",
    server6: "StarFinancial-US03-Demo",
    value: "Star Financial Markets",
  },
  {
    label: "StarfineX",
    server1: "StarFinancials-Server",
    value: "StarfineX",
  },
  {
    label: "Starfish FX",
    server1: "StarfishFX-Demo",
    server2: "StarfishFX-Live",
    server3: "Starfish Live-Demo",
    server4: "StarfishFX-Live2",
    server5: "StarfishFX-MT4",
    value: "Starfish FX",
  },
  {
    label: "StarFX",
    server1: "TradingBulls-Demo",
    server2: "TradingBulls-Live",
    value: "StarFX",
  },
  {
    label: "StartForex",
    server1: "No servers",
    value: "StartForex",
  },
  {
    label: "STARTRADER",
    server1: "STARTRADERINTL-Demo",
    server2: "STARTRADERINTL-Live",
    server3: "STARTRADERINTL-Live2",
    value: "STARTRADER",
  },
  {
    label: "STForex",
    server1: "STForex-Demo",
    server2: "STForex-Live",
    value: "STForex",
  },
  {
    label: "STO",
    server1: "AFX-Demo",
    server2: "AFX-Real",
    server3: "STOUK-Real",
    server4: "AFXCapital-Real",
    server5: "AFXCapital-Demo",
    value: "STO",
  },
  {
    label: "STOCK.com",
    server1: "LeadCapitalMarkets-Live",
    server2: "LeadCapitalMarkets-Practice",
    value: "STOCK.com",
  },
  {
    label: "Stocksforex",
    server1: "NuntiusBrokerageInv1-Live",
    server2: "NuntiusBrokerageInv1-Demo",
    value: "Stocksforex",
  },
  {
    label: "STOX Market",
    server1: "MarketierHoldings-Demo",
    server2: "MarketierHoldings-Live",
    value: "STOX Market",
  },
  {
    label: "Strategon",
    server1: "Strategon-Demo",
    server2: "Strategon-Live",
    server3: "Strategon-Live2",
    value: "Strategon",
  },
  {
    label: "Strategy Station Ltd.",
    server1: "StrategyStation-LiveUK",
    server2: "StrategyStation-DemoUK",
    value: "Strategy Station Ltd.",
  },
  {
    label: "Strathos Capital",
    server1: "StrathosCapital-Demo",
    server2: "StrathosCapital-live1",
    value: "Strathos Capital",
  },
  {
    label: "STRATO Trader",
    server1: "STRATO-Demo",
    server2: "STRATO-Live",
    value: "STRATO Trader",
  },
  {
    label: "Strattonmarkets",
    server1: "WCI-Demo",
    server2: "WCI-Real",
    server3: "F1Markets-Live",
    value: "Strattonmarkets",
  },
  {
    label: "StreamForex",
    server1: "StreamCapital-Demo",
    server2: "StreamCapital-Live",
    value: "StreamForex",
  },
  {
    label: "STREAMLINE",
    server1: "SLT-Demo",
    server2: "SLT-Real2",
    server3: "StreamlineForex-Demo",
    value: "STREAMLINE",
  },
  {
    label: "Streams",
    server1: "StreamsGlobalMarkets-REAL",
    server2: "StreamsGlobalMarkets-Demo",
    value: "Streams",
  },
  {
    label: "streamsfxglobal",
    server1: "StreamsFinancial-Demo",
    server2: "StreamsFinancial-Live",
    server3: "StreamsGlobal-Demo",
    server4: "StreamsGlobal-Live",
    server5: "StreamsGlobalMarkets-REAL",
    server6: "StreamsGlobalMarkets-Demo",
    value: "streamsfxglobal",
  },
  {
    label: "STRIKEPRO",
    server1: "StrikeProFX-Demo",
    server2: "StrikeProFX-Real",
    value: "STRIKEPRO",
  },
  {
    label: "Sunbird",
    server1: "Sunbird-Demo",
    server2: "Sunbird-Real",
    value: "Sunbird",
  },
  {
    label: "SunFX Global",
    server1: "SunFXGlobal-Demo",
    server2: "SunFXGlobal-Live",
    value: "SunFX Global",
  },
  {
    label: "Super Jet FX",
    server1: "WCI-Demo",
    server2: "WCI-Real",
    value: "Super Jet FX",
  },
  {
    label: "SuperForex",
    server1: "SuperForex-Real Europe",
    server2: "SuperForex-Real",
    server3: "SuperForex-ECN",
    value: "SuperForex",
  },
  {
    label: "SuperTrader Markets",
    server1: "SuperTraderMarkets-Demo",
    server2: "SuperTraderMarkets-Live",
    value: "SuperTrader Markets",
  },
  {
    label: "SuperTradingOnline",
    server1: "AFX-Demo",
    server2: "AFX-Real",
    value: "SuperTradingOnline",
  },
  {
    label: "SureFX",
    server1: "SureCapitalGroup-Live",
    server2: "SureCapitalGroup-Demo",
    value: "SureFX",
  },
  {
    label: "SvoFX",
    server1: "SvodaGlobalInc-Demo",
    server2: "SvodaGlobalInc-Live",
    server3: "SVOCapitalLtd-Demo",
    server4: "SVOCapitalLtd-Live",
    server5: "SVOFXLtd-Live",
    value: "SvoFX",
  },
  {
    label: "SVSFX",
    server1: "SVSFX-Demo",
    server2: "SVSFX-Live",
    value: "SVSFX",
  },
  {
    label: "Swiss Allied Brokers",
    server1: "SABrokers-Main",
    value: "Swiss Allied Brokers",
  },
  {
    label: "Swiss Markets",
    server1: "BDSwiss-Real01",
    server2: "BDSwiss-Demo01",
    server3: "BDSSwissMarkets-Real01",
    server4: "BDSSwissMarkets-Real02",
    value: "Swiss Markets",
  },
  {
    label: "SwissCapitalFX",
    server1: "Evalanch-DemoUK",
    server2: "Evalanch-LiveUK",
    value: "SwissCapitalFX",
  },
  {
    label: "SwissFS",
    server1: "SwissInternational-DemoFX",
    server2: "SwissInternational-RealFX",
    server3: "BMFN-RealFX",
    value: "SwissFS",
  },
  {
    label: "SwissPrime",
    server1: "SwissPrime-DemoFX",
    server2: "SwissPrime-RealFX",
    value: "SwissPrime",
  },
  {
    label: "SwissProFX",
    server1: "SwissProFx-Demo",
    server2: "SwissProFx-Live",
    value: "SwissProFX",
  },
  {
    label: "Swissquote Bank",
    server1: "Swissquote-MAM",
    server2: "Swissquote-Live6",
    server3: "Swissquote-Live2",
    server4: "Swissquote-Real1",
    server5: "Swissquote-Real2",
    server6: "Swissquote-Live3",
    server7: "Swissquote-Demo1",
    server8: "Swissquote-Live",
    server9: "MIGBank-Real2",
    server10: "MIGBank-Real1",
    server11: "Swissquote-Live1",
    server12: "SwissquoteLtd-Demo",
    server13: "SwissquoteLtd-Live",
    server14: "Swissquote-Demo1",
    value: "Swissquote Bank",
  },
  {
    label: "Swissquote LTD",
    server1: "Swissquote-Demo1",
    server2: "Swissquote-Live",
    server3: "Swissquote-Live1",
    server4: "SwissquoteLtd-Live5",
    server5: "Swissquote-Real1",
    server6: "Swissquote-Real2",
    server7: "Swissquote-MAM",
    server8: "SwissquoteLtd-Live",
    server9: "SwissquoteLtd-Demo",
    value: "Swissquote LTD",
  },
  {
    label: "Swisstrade Finance",
    server1: "SwissTradeFinance-Demo",
    server2: "SwissTradeFinance-Live",
    value: "Swisstrade Finance",
  },
  {
    label: "SwissTrustFx",
    server1: "SwissTrustFX-Demo",
    server2: "SwissTrustFX-Live2",
    value: "SwissTrustFx",
  },
  {
    label: "Switch Markets",
    server1: "SwitchMarkets-Real-01",
    server2: "SwitchMarkets-Demo",
    server3: "SwitchMarkets-Real",
    value: "Switch Markets",
  },
  {
    label: "SWORD CAPITAL",
    server1: "Sword-Demo",
    server2: "Sword-Live",
    value: "SWORD CAPITAL",
  },
  {
    label: "Synergy FX",
    server1: "SynergyFX-Demo",
    server2: "SynergyFX-Live",
    server3: "SFM-Demo",
    server4: "SFM-Live",
    server5: "SFM-Live02",
    server6: "SynergyMarkets-Live",
    server7: "SynergyMarkets-Live02",
    server8: "SynergyMarkets-Live03",
    value: "Synergy FX",
  },
  {
    label: "SystemForex",
    server1: "SystemGates-Live",
    server2: "SystemGates-Demo",
    value: "SystemForex",
  },
  {
    label: "T4TCapital",
    server1: "T4TCapitalFunds-Live 2",
    server2: "T4TCapitalFunds-Demo 2",
    value: "T4TCapital",
  },
  {
    label: "T4Trade",
    server1: "T4Trade-Real1",
    server2: "T4Trade-Real2",
    server3: "T4Trade-Real3",
    server4: "T4Trade-Real5",
    server5: "T4Trade-Real8",
    server6: "T4Trade-Real9",
    server7: "T4Trade-Demo1",
    server8: "T4Trade-Real4",
    server9: "T4Trade-Real10",
    server10: "T4Trade-Real6",
    value: "T4Trade",
  },
  {
    label: "TAHOE Group",
    server1: "TahoeGroup-Live",
    server2: "TahoeGroup-Demo",
    value: "TAHOE Group",
  },
  {
    label: "TahoeFX",
    server1: "Tahoe-Demo",
    server2: "Tahoe-Live",
    value: "TahoeFX",
  },
  {
    label: "Tal Ventures FX",
    server1: "TALVentures-Live",
    server2: "TALVentures-Demo",
    server3: "MXCapitalCorporation-Demo",
    value: "Tal Ventures FX",
  },
  {
    label: "Tallinex",
    server1: "Tallinex-Demo",
    server2: "Tallinex-Live",
    value: "Tallinex",
  },
  {
    label: "Tarsier FX",
    server1: "TarsierFX-Demo",
    server2: "TarsierFX-Real",
    value: "Tarsier FX",
  },
  {
    label: "TASNIC CAPITAL",
    server1: "TasnicCapital-Demo",
    server2: "TasnicCapital-Asia 2",
    value: "TASNIC CAPITAL",
  },
  {
    label: "TAURO PRO TRADE",
    server1: "TauroCapitalMarket-Demo",
    server2: "TauroCapitalMarket-Live",
    value: "TAURO PRO TRADE",
  },
  {
    label: "Taurus Global Markets",
    server1: "No servers",
    value: "Taurus Global Markets",
  },
  {
    label: "Taya",
    server1: "TayaFinancial-Demo",
    server2: "TayaFinancial-Live",
    value: "Taya",
  },
  {
    label: "TBF Markets",
    server1: "TheBestFuture-DEMO",
    server2: "TheBestFuture-LIVE",
    value: "TBF Markets",
  },
  {
    label: "TCC",
    server1: "TopCapitalCorporationTCC-Demo",
    server2: "TopCapitalCorporationTCC-Live",
    value: "TCC",
  },
  {
    label: "TCForex",
    server1: "tcForexServer-Live",
    server2: "tcForexServer-Demo",
    value: "TCForex",
  },
  {
    label: "TDFX",
    server1: "TripleDiamondFX-Demo",
    server2: "TripleDiamondFX-Live",
    value: "TDFX",
  },
  {
    label: "TDMarkets",
    server1: "TDMHoldings-Demo",
    server2: "TDMHoldings-Real",
    value: "TDMarkets",
  },
  {
    label: "Tech Venture Capital",
    server1: "TechVentureCap-Demo",
    server2: "TechVentureCap-Live",
    value: "Tech Venture Capital",
  },
  {
    label: "Tech-FX",
    server1: "CXCMarkets-Asia 3",
    server2: "CXCMarkets-Demo",
    server3: "Technofino-Live",
    server4: "Technofino-Demo",
    value: "Tech-FX",
  },
  {
    label: "TegasFX",
    server1: "TegasFX-Live-UK",
    server2: "TegasFX-Demo",
    server3: "TegasFX-Demo-UK",
    server4: "TegasFX-Live-UK-3",
    value: "TegasFX",
  },
  {
    label: "TeleTrade",
    server1: "TeleTRADECY-North Asia",
    server2: "TeleTRADECY-Demo",
    server3: "TeleTrade-NoDealingDesk",
    server4: "TeleTRADECY-Server",
    server5: "TeleTrade-North Asia",
    server6: "TeleTRADECY-NoDealingDesk",
    server7: "TeleTrade-Demo",
    server8: "TeleTrade-Server",
    value: "TeleTrade",
  },
  {
    label: "TempleFX",
    server1: "TempleFX-Demo",
    server2: "TempleFX-Live 2",
    server3: "TempleFX-Live",
    server4: "TSCapital-Demo",
    server5: "TSCapital-Live",
    server6: "TempleFX-Real",
    value: "TempleFX",
  },
  {
    label: "TemplerFx",
    server1: "Templer-MT4-USD",
    server2: "Templer-TemplerFX-Cent",
    server3: "Templer-MT4-Multicurrency",
    value: "TemplerFx",
  },
  {
    label: "TenkoFX",
    server1: "TenkoFX-Demo",
    server2: "TenkoFX-Real",
    value: "TenkoFX",
  },
  {
    label: "TenX Prime",
    server1: "TenXPrime-REAL SERVER",
    server2: "TenXPrime-DEMO",
    value: "TenX Prime",
  },
  {
    label: "TERA FX",
    server1: "TeraMenkul-Demo",
    server2: "TeraMenkul-Real",
    server3: "TeraEurope-Demo",
    server4: "TeraEurope-Main",
    value: "TERA FX",
  },
  {
    label: "TF Capital",
    server1: "TFCapital-Demo",
    server2: "TFCapital-MetaTrader Live",
    value: "TF Capital",
  },
  {
    label: "TFC Market",
    server1: "TFCMarket-Server",
    value: "TFC Market",
  },
  {
    label: "TFI Markets",
    server1: "TFI-Demo",
    server2: "TFI-Real",
    value: "TFI Markets",
  },
  {
    label: "TFM Invest",
    server1: "TFM-Invest-MT4 Demo Server",
    server2: "TFM-Invest-MT4 Real Server",
    server3: "LiqCon-Live2",
    value: "TFM Invest",
  },
  {
    label: "TFX Global",
    server1: "TriumpGlobalAsia-Demo",
    server2: "TriumpGlobalAsia-Live",
    value: "TFX Global",
  },
  {
    label: "TGX MARKETS",
    server1: "TGXMarkets-DEMO",
    server2: "TGXMarkets-LIVE",
    value: "TGX MARKETS",
  },
  {
    label: "The Beyond Trading",
    server1: "Beyond-Trading",
    value: "The Beyond Trading",
  },
  {
    label: "The BullFx",
    server1: "TheBullPrime-Demo",
    server2: "TheBullPrime-Live",
    value: "The BullFx",
  },
  {
    label: "The Collective FX",
    server1: "CollectiveFX-Demo",
    server2: "CollectiveFX-Live",
    server3: "CollectiveFX-Demo 1",
    server4: "CollectiveFX-Demo 3",
    server5: "MT4Live1.TheCollectiveFX.com",
    server6: "CollectiveFX-LIVE1",
    server7: "CollectiveFX-DEMO1",
    value: "The Collective FX",
  },
  {
    label: "The Liquidity",
    server1: "TheLiquidity-live",
    server2: "TheLiquidity-Demo",
    value: "The Liquidity",
  },
  {
    label: "The Traders Domain",
    server1: "TradersDomainFX-Demo",
    server2: "TradersDomainFX-Real",
    value: "The Traders Domain",
  },
  {
    label: "THEMY FX",
    server1: "THEMYFX-Demo",
    server2: "THEMYFX-Real",
    value: "THEMY FX",
  },
  {
    label: "Thenine Exchange",
    server1: "ThenineIntl-DEMO",
    server2: "ThenineIntl-REAL",
    server3: "ThenineIntl-LIVE",
    value: "Thenine Exchange",
  },
  {
    label: "ThinkMarkets",
    server1: "ThinkForex-Demo",
    server2: "ThinkForex-Live",
    server3: "ThinkForex-Live 2",
    server4: "ThinkForex-Demo Asia",
    server5: "ThinkForexAU-Demo 2",
    server6: "ThinkForexAU-Demo",
    server7: "ThinkForexAU-Live",
    server8: "ThinkMarkets-Live",
    server9: "ThinkMarkets-Live 2",
    server10: "ThinkMarkets-Demo",
    server11: "ThinkMarkets-Demo Asia",
    server12: "ThinkForexAU-Live 2",
    server13: "ThinkForexUK-Demo",
    server14: "ThinkForexUK-Live 2",
    server15: "ThinkForexUK-Live",
    server16: "ThinkForexUK-Demo 2",
    server17: "ThinkMarkets-Demo 2",
    server18: "ThinkForex-Live 2",
    server19: "ThinkMarkets-Live 4",
    value: "ThinkMarkets",
  },
  {
    label: "Thorex",
    server1: "Thorex-Server",
    value: "Thorex",
  },
  {
    label: "ThreeTrader",
    server1: "ThreeTrader-Live",
    server2: "ThreeTrader-Demo",
    value: "ThreeTrader",
  },
  {
    label: "Thunder Forex",
    server1: "ThunderForex-Demo",
    server2: "ThunderForex-Real",
    value: "Thunder Forex",
  },
  {
    label: "Thunder Markets",
    server1: "ThunderMarkets-Demo",
    server2: "ThunderMarkets-Real",
    value: "Thunder Markets",
  },
  {
    label: "TickCap",
    server1: "TickCapCorporation-Demo",
    server2: "TickCapCorporation-MetaTrader Live",
    value: "TickCap",
  },
  {
    label: "Tickcopy",
    server1: "Tickcopy-Demo",
    server2: "Tickcopy-Real",
    value: "Tickcopy",
  },
  {
    label: "Tickmill",
    server1: "Tickmill-Live",
    server2: "Tickmill-DemoUK",
    server3: "Tickmill-Live02",
    server4: "Tickmill-Live03",
    server5: "TickmillUK-Live03",
    server6: "Tickmill-Live09",
    server7: "Tickmill-Live10",
    server8: "TickmillAsia-Live06",
    server9: "TickmillEU-Demo",
    server10: "TickmillEU-Live",
    server11: "Tickmill02-Live",
    server12: "Tickmill-Live04",
    server13: "Tickmill-Live05",
    server14: "Tickmill-Demo",
    server15: "Tickmill-Live08",
    value: "Tickmill",
  },
  {
    label: "Tier1FX",
    server1: "TierOneFX-Demo",
    server2: "TierOneFX-Live",
    server3: "Tier1FX-Live 2",
    server4: "Tier1FX-Demo",
    server5: "Tier1FX-Real",
    value: "Tier1FX",
  },
  {
    label: "Tifia",
    server1: "Tifia-MT4",
    server2: "Tifia-Demo",
    value: "Tifia",
  },
  {
    label: "TigerWit",
    server1: "TigerWit-Live",
    server2: "TigerWit-Trial",
    value: "TigerWit",
  },
  {
    label: "Time Wealth",
    server1: "TimeWealth-Live",
    server2: "TimeWealth-London",
    server3: "TimeWealth-Live 3",
    value: "Time Wealth",
  },
  {
    label: "Tio Markets",
    server1: "TIOMarkets-Live-4",
    server2: "TIOMarkets-Practice",
    server3: "TIOMarketsUK-Live-4",
    value: "Tio Markets",
  },
  {
    label: "TIOmarkets",
    server1: "TIOMarkets-Live-4",
    server2: "TIOMarkets-Practice",
    value: "TIOmarkets",
  },
  {
    label: "TitanFX",
    server1: "TitanFX-Demo02",
    server2: "TitanFX-Edge01",
    server3: "TitanFX-Edge02",
    server4: "TitanFX-Edge03",
    server5: "TitanFX-Edge04",
    server6: "TitanFX-Edge05",
    server7: "TitanFX-04",
    server8: "TitanFX-01",
    server9: "TitanFX-Demo01",
    server10: "TitanFX-02",
    server11: "TitanFX-03",
    server12: "TitanFX-05",
    server13: "TitanFX-06",
    value: "TitanFX",
  },
  {
    label: "Tmarkets",
    server1: "TMARKETS-Demo",
    server2: "TMARKETS-Live",
    value: "Tmarkets",
  },
  {
    label: "TMGM",
    server1: "TradeMaxGlobal-Demo",
    server2: "TradeMaxGlobal-Live1",
    server3: "TradeMaxGlobal-Live2",
    server4: "TradeMaxGlobal-Live3",
    server5: "TradeMaxGlobal-Live4",
    server6: "TMGM.TradeMax-Live4",
    server7: "TMGM.TradeMax-Demo",
    server8: "TMGM.TradeMax-Live1",
    server9: "TMGM.TradeMax-Live2",
    server10: "TMGM.TradeMax-Live3",
    server11: "TMGM.TradeMaxAU-Demo",
    server12: "TMGM.TradeMaxAU-Live1",
    server13: "TMGM.TradeMaxAU-Live2",
    server14: "TMGM.TradeMax-Live5",
    server15: "TMGM.TradeMax-Live6",
    server16: "TradeMax-Live1",
    server17: "TMGM.TradeMax-Live7",
    server18: "TMGM.TradeMax-Live8",
    server19: "TMGM.TradeMax-Live9",
    value: "TMGM",
  },
  {
    label: "TMS Brokers",
    server1: "TMS-Demo",
    server2: "TMS-Live",
    server3: "TMSTrader-Live",
    server4: "TMSTrader-Demo",
    value: "TMS Brokers",
  },
  {
    label: "TNFX",
    server1: "TiranForex-Demo",
    server2: "TiranForex-Live",
    server3: "TiranForex2-Demo",
    server4: "TiranForex2-Live",
    value: "TNFX",
  },
  {
    label: "Top Half Finance",
    server1: "TopHalfIntlTechnology-Demo",
    server2: "TopHalfIntlTechnology-Live",
    value: "Top Half Finance",
  },
  {
    label: "Top Pro FX",
    server1: "TOPPRO-DEMO",
    server2: "TOPPRO-LIVE",
    server3: "TooProMarket-LIVE",
    server4: "TopProFX-Live",
    value: "Top Pro FX",
  },
  {
    label: "Top Trader",
    server1: "TopTrader-Demo",
    server2: "TopTraderCo-Live",
    value: "Top Trader",
  },
  {
    label: "TopForex",
    server1: "TOPFOREX-Demo",
    server2: "TOPFOREX-Real",
    value: "TopForex",
  },
  {
    label: "TopFX",
    server1: "TOPFX-Demo",
    server2: "TOPFX-Live Server",
    server3: "TOPFX-Demo Server",
    server4: "TopFXSC-Demo Server",
    server5: "TopFXSC-Live Server",
    server6: "TopFXGlobal-Live Server",
    server7: "TopFXGlobal-Demo Server",
    server8: "TopFXBM-Live Server",
    server9: "TopFXBM-Demo Server",
    value: "TopFX",
  },
  {
    label: "Topgrowth",
    server1: "Topgrowth-Real",
    value: "Topgrowth",
  },
  {
    label: "Topic Markets",
    server1: "TopicMarkets-Demo",
    server2: "TopicMarkets-Live",
    value: "Topic Markets",
  },
  {
    label: "TopRichFX",
    server1: "ToprichInt-Main",
    server2: "ToprichInt-Demo",
    value: "TopRichFX",
  },
  {
    label: "Topstep",
    server1: "TopstepFX-Demo 2",
    server2: "TopstepFX-Live 5",
    value: "Topstep",
  },
  {
    label: "Totally FX",
    server1: "TotallyFX-Demo 3",
    server2: "TotallyFX-Live",
    value: "Totally FX",
  },
  {
    label: "TotemFX",
    server1: "TotemFx-Demo",
    server2: "TotemFx-Live",
    value: "TotemFX",
  },
  {
    label: "TouchTrades",
    server1: "TouchTrades-Demo",
    server2: "TouchTrades-Real",
    value: "TouchTrades",
  },
  {
    label: "TP Global FX",
    server1: "TPGlobal-Demo",
    server2: "TPGlobal-Live",
    server3: "TPGlobalServices-LIVE",
    server4: "TPGlobalServices-DEMO",
    value: "TP Global FX",
  },
  {
    label: "TP Trades",
    server1: "TPTradesHoldingLtd-Demo",
    server2: "TPTradesHoldingLtd-Real",
    value: "TP Trades",
  },
  {
    label: "TPFOREX",
    server1: "TPForex-Demo",
    server2: "TPForex-Live",
    server3: "TPF-Live",
    value: "TPFOREX",
  },
  {
    label: "Tra Markets",
    server1: "TRAMarkets-Main",
    server2: "TRAMarkets-Demo",
    value: "Tra Markets",
  },
  {
    label: "Trade Bank FX",
    server1: "GFT-Real",
    server2: "TBF-Demo",
    server3: "TBF-Live",
    value: "Trade Bank FX",
  },
  {
    label: "Trade IT",
    server1: "TradeItBrokerhouse-Demo Server",
    server2: "TradeItBrokerhouse-Live 8",
    value: "Trade IT",
  },
  {
    label: "Trade It Brokerhouse",
    server1: "TradeItBrokerhouse-Demo Server",
    server2: "TradeItBrokerhouse-Live 3",
    server3: "TradeItBrokerhouse-Live 8",
    value: "Trade It Brokerhouse",
  },
  {
    label: "Trade Kings",
    server1: "TradeKings-Demo",
    server2: "TradeKings-Real",
    value: "Trade Kings",
  },
  {
    label: "Trade LTD",
    server1: "Titanium-Demo",
    server2: "Titanium-Live",
    server3: "TitaniumPremium-Demo",
    server4: "TitaniumPremium-Live",
    value: "Trade LTD",
  },
  {
    label: "Trade Markets",
    server1: "TradeMarkets-demo",
    server2: "TradeMarkets-Live",
    value: "Trade Markets",
  },
  {
    label: "Trade Master",
    server1: "TradeMasterFX-Demo",
    server2: "TradeMasterFX-Live",
    server3: "TradeMasterFX-Live2",
    value: "Trade Master",
  },
  {
    label: "Trade Mono",
    server1: "TradeMono-Demo",
    server2: "TradeMono-Live",
    value: "Trade Mono",
  },
  {
    label: "Trade Nation",
    server1: "TradeNation-DemoBravo",
    server2: "TradeNation-LiveBravo",
    value: "Trade Nation",
  },
  {
    label: "Trade Pulse",
    server1: "TradePulse-Demo",
    server2: "TradePulse-Live",
    value: "Trade Pulse",
  },
  {
    label: "Trade Quo",
    server1: "DVentureMarkets-LIVE",
    server2: "DVentureMarkets-DEMO",
    value: "Trade Quo",
  },
  {
    label: "Trade V Group",
    server1: "TradeVanguard-Live",
    server2: "TradeVanguard-Demo",
    value: "Trade V Group",
  },
  {
    label: "Trade-Wiz",
    server1: "AlgoTrade-Main Server 1",
    value: "Trade-Wiz",
  },
  {
    label: "Trade.com",
    server1: "Finexo-Live.",
    server2: "Trade.com-Live.",
    server3: "Trade.com-Live-EUR",
    server4: "Trade.com-Practice",
    server5: "LeadcapitalMarkets-Live",
    server6: "LeadcapitalMarkets-Practice",
    value: "Trade.com",
  },
  {
    label: "Trade12",
    server1: "Trade12-Demo",
    server2: "Trade12-Real",
    server3: "Trade12-Primary",
    value: "Trade12",
  },
  {
    label: "Trade24",
    server1: "Trade24-Demo",
    server2: "Trade24-Live",
    value: "Trade24",
  },
  {
    label: "Trade245",
    server1: "Trade245-Live",
    server2: "Trade245-Demo",
    value: "Trade245",
  },
  {
    label: "Trade4x",
    server1: "TradeFX-Live",
    value: "Trade4x",
  },
  {
    label: "Tradealot",
    server1: "tradealot-Real",
    server2: "tradealot-Demo",
    value: "Tradealot",
  },
  {
    label: "TradeATF",
    server1: "No servers",
    value: "TradeATF",
  },
  {
    label: "TradeCMX",
    server1: "TradeCMX-Demo",
    server2: "TradeCMX-Live",
    value: "TradeCMX",
  },
  {
    label: "TradeDirect365",
    server1: "No servers",
    value: "TradeDirect365",
  },
  {
    label: "TradeFills",
    server1: "TradeFills-Demo",
    server2: "TradeFills-Real",
    server3: "TradeFills-Real-1",
    server4: "TradeFills-Real-2",
    value: "TradeFills",
  },
  {
    label: "TradeFort",
    server1: "TradeFort-Demo",
    server2: "TradeFort-Real",
    value: "TradeFort",
  },
  {
    label: "TradeFred",
    server1: "BrightFX-Demo",
    server2: "BrightFX-Main",
    server3: "TradeFredAU-Live",
    server4: "TradeFredAU-Demo",
    server5: "TradeFred2-Demo",
    server6: "TradeFred2-Main",
    value: "TradeFred",
  },
  {
    label: "TradeFxP",
    server1: "TradeFxPLtd-Demo",
    server2: "TradeFxPLtd-Live",
    value: "TradeFxP",
  },
  {
    label: "TradeKing",
    server1: "TradeKing-Live 7",
    server2: "TradeKing-Demo(R)",
    server3: "AllyInvestForex-Live 7",
    value: "TradeKing",
  },
  {
    label: "Tradeland",
    server1: "TradelandHoldingsLtd-Live",
    server2: "TradelandHoldingsLtd-Demo",
    value: "Tradeland",
  },
  {
    label: "TradeLine FX",
    server1: "TradeLineFX-Server",
    value: "TradeLine FX",
  },
  {
    label: "Tradenext",
    server1: "Tradenext-Demo",
    server2: "Tradenext-Live",
    value: "Tradenext",
  },
  {
    label: "TradenovaFX",
    server1: "TradeNovaFX-Demo",
    server2: "TradeNovaFX-Live",
    value: "TradenovaFX",
  },
  {
    label: "Tradeo",
    server1: "TradeoCy-Live",
    server2: "Tradeo-Real",
    value: "Tradeo",
  },
  {
    label: "TradeOptions",
    server1: "TradeOptionFX-Demo",
    server2: "TradeOptionFX-Live",
    value: "TradeOptions",
  },
  {
    label: "TradePro",
    server1: "TradeProServices-Demo",
    server2: "TradeProServices-Live",
    value: "TradePro",
  },
  {
    label: "Tradeprofx",
    server1: "TradeProServices-Live",
    server2: "TradeProServices-Demo",
    value: "Tradeprofx",
  },
  {
    label: "Trader Novo",
    server1: "TraderNovo-Trading",
    value: "Trader Novo",
  },
  {
    label: "Trader's Way",
    server1: "TW-Demo",
    server2: "TW-Main",
    server3: "TradersWay-Live",
    server4: "TradersWay-Demo",
    server5: "TradersWay-TW ECN Live",
    server6: "TradersWay-Live 2",
    value: "Trader's Way",
  },
  {
    label: "Trader.Online",
    server1: "Trader.Online",
    value: "Trader.Online",
  },
  {
    label: "Trader24",
    server1: "Trader24-Demo",
    server2: "Trader24-Main",
    value: "Trader24",
  },
  {
    label: "Traders Global Group",
    server1: "TradersGlobalGroup-Demo 2",
    server2: "TradersGlobalGroup-Live 2",
    server3: "TradersGlobalGroup-Demo",
    server4: "TradersGlobalGroup-Live",
    server5: "TradersGlobalGroup-Demo2",
    server6: "TradersGlobalGroup-Demo3",
    server7: "TradersGlobalGroup-Demo4",
    value: "Traders Global Group",
  },
  {
    label: "Traders Live",
    server1: "HCFSGlobal-Demo",
    server2: "HCFSGlobal-Server L",
    value: "Traders Live",
  },
  {
    label: "Traders Live EU",
    server1: "HCFSHighCapital-Demo",
    server2: "HCFSHighCapital-Live",
    value: "Traders Live EU",
  },
  {
    label: "Traders Pulse",
    server1: "TradersPulse-Demo",
    server2: "TradersPulse-Real",
    value: "Traders Pulse",
  },
  {
    label: "Traders Trust",
    server1: "TradersTrust-Demo",
    server2: "TradersTrust-Live",
    server3: "TradersTrust-DemoUK",
    server4: "TradersTrust-LiveUK",
    server5: "TTCM-demo2",
    server6: "TTCM-Live",
    server7: "TTCM-demo",
    server8: "TTCM-Live2",
    server9: "TTCM-Live3",
    value: "Traders Trust",
  },
  {
    label: "TradersTFX",
    server1: "THTFX-Demo",
    server2: "THTFX-Live",
    value: "TradersTFX",
  },
  {
    label: "TRADESTO",
    server1: "TraDesto-Live",
    server2: "TraDesto-Live-2",
    server3: "TraDesto-Live-3",
    server4: "TraDesto-Practice",
    server5: "TradestoFX-Demo Server",
    server6: "TradestoFX-Live Server",
    server7: "TradestoCorp-MetaTrader Live",
    server8: "TradestoCorp-Demo",
    value: "TRADESTO",
  },
  {
    label: "TradeTechAlpha",
    server1: "TradeTechAlpha-Demo",
    server2: "TradeTechAlpha-Live 2",
    value: "TradeTechAlpha",
  },
  {
    label: "TradeTechMarkets",
    server1: "TradeTechMarkets2-MarketsX",
    server2: "TradeTechMarkets-Live",
    server3: "TradeTechMarkets-Practice",
    value: "TradeTechMarkets",
  },
  {
    label: "Tradeview Forex",
    server1: "Tradeview-Demo",
    server2: "TradeviewFx-Demo",
    server3: "Tradeview-Live",
    server4: "Tradeview-Markets Live 2",
    value: "Tradeview Forex",
  },
  {
    label: "Tradeview Markets",
    server1: "Tradeview-Demo",
    server2: "Tradeview-Live",
    server3: "Tradeview-Markets Live 2",
    value: "Tradeview Markets",
  },
  {
    label: "TradeWiseFX",
    server1: "TradeWise-DemoUS",
    server2: "TradeWise-LiveUS",
    value: "TradeWiseFX",
  },
  {
    label: "TradeX Prime",
    server1: "TradeXPrime-Demo 2",
    server2: "TradeXPrime-Live 2",
    value: "TradeX Prime",
  },
  {
    label: "Trading Forex",
    server1: "MSCrestConsulting-Demo",
    server2: "MSCrestConsulting-Live",
    value: "Trading Forex",
  },
  {
    label: "Trading.com",
    server1: "Trading.com-Demo 2",
    server2: "Trading.com-Demo",
    server3: "Trading.com-Real 2",
    server4: "Trading.com-Real 3",
    server5: "Trading.com-Real 6",
    server6: "Trading.com-Real 8",
    server7: "Trading.com-Real 14",
    server8: "Trading.com-Real 15",
    server9: "Trading.com-Real 16",
    server10: "Trading.com-Real 17",
    server11: "Trading.com-Real 18",
    server12: "Trading.com-Real 19",
    server13: "Trading.com-Real 20",
    server14: "Trading.com-Real 23",
    server15: "Trading.com-Real 30",
    value: "Trading.com",
  },
  {
    label: "Trading789",
    server1: "Trading789Company-Demo",
    server2: "Trading789Company-Live",
    value: "Trading789",
  },
  {
    label: "TradingBanks",
    server1: "TRADINGBANKS-Demo",
    server2: "TRADINGBANKS-Real",
    value: "TradingBanks",
  },
  {
    label: "TradingFloor FX",
    server1: "TFB-Demo",
    server2: "TFB-Live",
    value: "TradingFloor FX",
  },
  {
    label: "TradingPro",
    server1: "TradingProInternational-Demo",
    server2: "TradingProInternational-Live",
    server3: "TradingProInternational-Live 2",
    value: "TradingPro",
  },
  {
    label: "Tradiso",
    server1: "TradisoGroup-Demo",
    server2: "TradisoGroup-Real",
    value: "Tradiso",
  },
  {
    label: "Tradize",
    server1: "Tradize-Demo",
    server2: "Tradize-Live",
    value: "Tradize",
  },
  {
    label: "Tradmaker",
    server1: "Tradmaker-Demo",
    server2: "Tradmaker-Main",
    value: "Tradmaker",
  },
  {
    label: "Trategico Capital Markets",
    server1: "PureMarket-Demo",
    server2: "PureMarket-Live",
    value: "Trategico Capital Markets",
  },
  {
    label: "TRB",
    server1: "TRBHInternational-Live",
    server2: "TRBHInternational-Demo",
    value: "TRB",
  },
  {
    label: "Trend Fx",
    server1: "No servers",
    value: "Trend Fx",
  },
  {
    label: "TrendFX",
    server1: "TrendFX-Live",
    value: "TrendFX",
  },
  {
    label: "Trendoks IMG",
    server1: "Trendoks-Server",
    value: "Trendoks IMG",
  },
  {
    label: "Tricom FX",
    server1: "OIG-Live",
    server2: "OIG-Demo",
    value: "Tricom FX",
  },
  {
    label: "TrillionFX",
    server1: "TrillionFX-DemoUS",
    server2: "TrillionFX-LiveUS",
    value: "TrillionFX",
  },
  {
    label: "TRILT",
    server1: "TRILeadingTrader-Demo-UK",
    server2: "TRILeadingTrader-Live-UK",
    value: "TRILT",
  },
  {
    label: "TrioMarkets",
    server1: "TrioMarkets-Demo Server",
    server2: "TrioMarkets-Live Server",
    value: "TrioMarkets",
  },
  {
    label: "Triumph FX",
    server1: "TriumphFX-Demo",
    server2: "TriumphFX-Live",
    value: "Triumph FX",
  },
  {
    label: "Trive",
    server1: "TriveFinancial-Demo-2",
    server2: "TriveFinancial-Live-2",
    server3: "TriveFinancial-Live-3",
    server4: "TriveFinancial-Live-5",
    server5: "TriveFinancial-SB-2",
    value: "Trive",
  },
  {
    label: "Troy Forex",
    server1: "TroyFinancial-Live",
    value: "Troy Forex",
  },
  {
    label: "True Forex Funds",
    server1: "TruePropietaryFunds-DEMO",
    server2: "TruePropietaryFunds-REAL",
    value: "True Forex Funds",
  },
  {
    label: "TrueSTP.com",
    server1: "Demo.truestp.com",
    server2: "Live.truestp.com",
    value: "TrueSTP.com",
  },
  {
    label: "Trust Broker Group",
    server1: "TrustBrokerGroup-Live",
    server2: "TrustBrokerGroup-Demo",
    value: "Trust Broker Group",
  },
  {
    label: "Trust Capital",
    server1: "TrustCapital-Demo",
    server2: "TrustCapital-Real",
    value: "Trust Capital",
  },
  {
    label: "TSGBroker",
    server1: "GTP-Main",
    value: "TSGBroker",
  },
  {
    label: "TSKFX",
    server1: "TSKFXGroup-Demo",
    server2: "TSKFXGroup-Live",
    value: "TSKFX",
  },
  {
    label: "TTM",
    server1: "TTMTrading-Demo",
    server2: "TTMTrading-Real",
    value: "TTM",
  },
  {
    label: "TTS Markets",
    server1: "RichlyActive-Demo",
    server2: "RichlyActive-Live",
    value: "TTS Markets",
  },
  {
    label: "Turbo Forex",
    server1: "TurboForex-Demo",
    server2: "TurboForex-Main",
    value: "Turbo Forex",
  },
  {
    label: "Turnkey Forex",
    server1: "TurnkeyGlobal-Demo",
    server2: "TurnkeyGlobal-Live",
    value: "Turnkey Forex",
  },
  {
    label: "TusarFX",
    server1: "Tusar-Demo",
    server2: "Tusar-Main",
    server3: "Tusar-Main2",
    value: "TusarFX",
  },
  {
    label: "Twenty Four Securities",
    server1: "TwentyFourSecurities-DEMO",
    server2: "TwentyFourSecurities-REAL SERVER",
    value: "Twenty Four Securities",
  },
  {
    label: "Tycoon",
    server1: "Tycoon-Demo",
    value: "Tycoon",
  },
  {
    label: "TyTradiumFX-",
    server1: "TyTradiumFX-Demo",
    server2: "TyTradiumFX-Real",
    value: "TyTradiumFX-",
  },
  {
    label: "UAG TRADE ASIA",
    server1: "UAGTradeAsia-DEMO",
    server2: "UAGTradeAsia-LIVE",
    value: "UAG TRADE ASIA",
  },
  {
    label: "UAGTRADE",
    server1: "UAG-Demo",
    server2: "UAG-Live",
    server3: "UAGTrade-DEMO",
    server4: "UAGTrade-LIVE",
    value: "UAGTRADE",
  },
  {
    label: "UB Swiss FX",
    server1: "UBSWISSFX-Demo",
    server2: "UBSWISSFX-Live",
    value: "UB Swiss FX",
  },
  {
    label: "UBFS invest",
    server1: "MoneyChoice-DemoUK",
    server2: "MoneyChoice-LiveUK",
    value: "UBFS invest",
  },
  {
    label: "UEGL",
    server1: "UniversalExchangeGroup-Demo",
    server2: "UniversalExchangeGroup-Real",
    value: "UEGL",
  },
  {
    label: "UFX",
    server1: "UFXMarkets-Live 7",
    server2: "UFX-Live",
    value: "UFX",
  },
  {
    label: "UGL",
    server1: "UppointGroup-Demo",
    server2: "UppointGroup-Live",
    value: "UGL",
  },
  {
    label: "UGMFX",
    server1: "UGMFX-Live",
    value: "UGMFX",
  },
  {
    label: "Ukrgasbank",
    server1: "UkrGasBank-MT4",
    value: "Ukrgasbank",
  },
  {
    label: "Ukrsotsbank",
    server1: "Ukrsotsbank-MT4",
    value: "Ukrsotsbank",
  },
  {
    label: "Ulutia FX",
    server1: "PrinzProvidence-Demo",
    server2: "PrinzProvidence-Live",
    server3: "PrinzProvidence-Live2",
    value: "Ulutia FX",
  },
  {
    label: "Umarkets",
    server1: "MaxiServices-Demo",
    server2: "MaxiServices-Real",
    value: "Umarkets",
  },
  {
    label: "Umis",
    server1: "UMIS-Trading server",
    value: "Umis",
  },
  {
    label: "UMO Markets",
    server1: "UnitedMarketOracle-DEMO",
    server2: "UnitedMarketOracle-LIVE",
    value: "UMO Markets",
  },
  {
    label: "UMOFX",
    server1: "UnitedMarketOracle-Demo",
    server2: "UnitedMarketOracle-Main",
    value: "UMOFX",
  },
  {
    label: "Uni Borsa",
    server1: "UNI-LiveUK",
    value: "Uni Borsa",
  },
  {
    label: "UNI-FX PRO",
    server1: "CFC-Demo",
    server2: "CFC-Live",
    value: "UNI-FX PRO",
  },
  {
    label: "Unicorn Finance",
    server1: "UnicornFinance-Demo",
    server2: "UnicornFinance-Live",
    value: "Unicorn Finance",
  },
  {
    label: "UNICORNFX",
    server1: "UnicornGlobal-Demo",
    server2: "UnicornGlobal-Live",
    value: "UNICORNFX",
  },
  {
    label: "Unifi Forex",
    server1: "No servers",
    value: "Unifi Forex",
  },
  {
    label: "UniForex",
    server1: "Unibank-Demo",
    server2: "Unibank-Real5",
    value: "UniForex",
  },
  {
    label: "UniFX",
    server1: "UniFXCapital-Demo",
    server2: "UniFXCapital-Live",
    value: "UniFX",
  },
  {
    label: "Uniglobe Markets",
    server1: "UniGlobe-Demo",
    server2: "UniGlobe-Real2",
    server3: "UniglobeMarkets-Demo",
    server4: "UniglobeMarkets-Live",
    value: "Uniglobe Markets",
  },
  {
    label: "United Financial",
    server1: "UnitedFinancial-Demo",
    server2: "UnitedFinancial-Live",
    value: "United Financial",
  },
  {
    label: "United Pacific Financial Services",
    server1: "UPFS-NEXTT",
    value: "United Pacific Financial Services",
  },
  {
    label: "Unitedforex",
    server1: "UnitedForex-Real Server",
    value: "Unitedforex",
  },
  {
    label: "Univell Broker",
    server1: "UnivellBroker-Demo",
    server2: "UnivellBroker-Real",
    value: "Univell Broker",
  },
  {
    label: "Universal FX",
    server1: "UniversalFX-Universal-FX Demo",
    server2: "UniversalFX-Universal-FX Live",
    server3: "UniversalFX-Universal-FX ECN Demo",
    server4: "Integral-Universal-FX Demo",
    server5: "UniversalFX-Universal-FX ECN Live",
    server6: "PrimeXM-LiveUK",
    value: "Universal FX",
  },
  {
    label: "Universal Markets FX",
    server1: "UMI-Demo",
    server2: "UMI-Real",
    value: "Universal Markets FX",
  },
  {
    label: "UPFX",
    server1: "UpFX-Demo",
    server2: "UpFX-Main",
    value: "UPFX",
  },
  {
    label: "UPME Group LTD",
    server1: "No servers",
    value: "UPME Group LTD",
  },
  {
    label: "Uptos",
    server1: "No servers",
    value: "Uptos",
  },
  {
    label: "Uranus Investment Group",
    server1: "UranusIG-Demo",
    server2: "UranusIG-Real",
    value: "Uranus Investment Group",
  },
  {
    label: "Us Kela Capital",
    server1: "KELA-Demo",
    server2: "KELA-Live",
    value: "Us Kela Capital",
  },
  {
    label: "USGCapital",
    server1: "USGCapital.com-Demo",
    server2: "USGCapital.com-Main",
    value: "USGCapital",
  },
  {
    label: "USGFX",
    server1: "USGVU-LiveAsia",
    server2: "USGVU-UK",
    server3: "USGVU-Demo",
    server4: "USGVU-Live-Europe",
    server5: "UnionStandardInt-Live-Asia",
    server6: "USGUK-Demo",
    server7: "USGUK-Live3",
    server8: "USGUK-Live4",
    server9: "USIGUK-UK",
    server10: "USIGUK-Live-Europe",
    server11: "USIGUK-LiveAsia",
    server12: "USIGUK-Demo",
    value: "USGFX",
  },
  {
    label: "UTMarkets",
    server1: "UTM-Demo",
    server2: "UTM-Live",
    value: "UTMarkets",
  },
  {
    label: "Utopia",
    server1: "UtopiaLimited-DEMO",
    server2: "UtopiaLimited-REAL SERVER",
    value: "Utopia",
  },
  {
    label: "uTrada",
    server1: "UtradaTech-Demo",
    server2: "UtradaTech-Live",
    value: "uTrada",
  },
  {
    label: "Utrade",
    server1: "UtradeFX-Demo",
    server2: "UtradeFX-Live",
    value: "Utrade",
  },
  {
    label: "UW GLOBAL",
    server1: "UWGlobal-Demo",
    server2: "UWGlobal-Live",
    value: "UW GLOBAL",
  },
  {
    label: "UWNZ",
    server1: "UWTrader-Demo",
    server2: "UWTrader-Live",
    value: "UWNZ",
  },
  {
    label: "UzmanFX",
    server1: "UzmanTrading-Live",
    value: "UzmanFX",
  },
  {
    label: "V Trades",
    server1: "VTSynergy-Live-UK-2",
    value: "V Trades",
  },
  {
    label: "V-Equities",
    server1: "Vestfold-Server",
    value: "V-Equities",
  },
  {
    label: "V2FX",
    server1: "V2FX-Demo",
    server2: "V2FX-Live",
    value: "V2FX",
  },
  {
    label: "V3FX",
    server1: "V3FX-Server",
    value: "V3FX",
  },
  {
    label: "Valbury",
    server1: "ValburyCapitalLtd-US01-Live",
    server2: "ValburyCapitalLimited-Live",
    server3: "ValburyCapitalLimited-Demo",
    server4: "VAF-Demo",
    server5: "VAF-Real",
    server6: "VCL-DemoUS",
    server7: "VCL-LiveUS",
    server8: "Valbury-Demo",
    server9: "Valbury-Real",
    server10: "ValburyCapital-Demo",
    server11: "ValburyCapital-Real",
    value: "Valbury",
  },
  {
    label: "Value Trading Markets",
    server1: "ValueTradingMarkets-DEMO",
    server2: "ValueTradingMarkets-LIVE",
    value: "Value Trading Markets",
  },
  {
    label: "Valuta Markets",
    server1: "MVEHoldings-Demo01",
    server2: "MVEHoldings-Real01",
    server3: "MVEHoldings-Real02",
    server4: "MVEHoldings-Real03",
    server5: "MVEHoldings-Real04",
    server6: "MVEHoldings-Real05",
    value: "Valuta Markets",
  },
  {
    label: "Valutrades",
    server1: "Valutrades-Real-HK",
    server2: "Valutrades-Demo-HK",
    server3: "ValutradesSeychelles-Real",
    server4: "Valutrades-Demo",
    server5: "Valutrades-Real",
    value: "Valutrades",
  },
  {
    label: "Vanavel",
    server1: "VanavelCryptoTech-DEMO",
    server2: "VanavelCryptoTech-LIVE",
    value: "Vanavel",
  },
  {
    label: "VANLONG GLOBAL",
    server1: "VanlongGlobal-Demo",
    server2: "VanlongGlobal-Live",
    value: "VANLONG GLOBAL",
  },
  {
    label: "Vantage Markets",
    server1: "VantageFX-Demo",
    server2: "VantageFX-Live 1",
    server3: "VantageFX-Live",
    server4: "VantageFX-Live 2",
    server5: "VantageFX-UK-Demo",
    server6: "VantageFX-UK-Real",
    server7: "VantageFX-Live 3",
    server8: "VantageFXInternational-Live 8",
    server9: "VantageFXInternational-Live 7",
    server10: "VantageGlobalPrime-Live",
    server11: "VantageGlobalPrime-Demo",
    server12: "VantageInternational-Live1",
    server13: "VantageInternational-Live 3",
    server14: "VantageInternational-Live 2",
    server15: "VantageInternational-Live 4",
    server16: "VantageInternational-Live 5",
    server17: "VantageInternational-Live 6",
    server18: "VantageInternational-Live 7",
    server19: "VantageInternational-Live 8",
    server20: "VantageInternational-Demo",
    server21: "VantageInternational-Live 4",
    server22: "VantageInternational-Live 10",
    server23: "VantageInternational-Live 12",
    server24: "VantageInternational-Live 9",
    server25: "VantageInternational-Demo 2",
    server26: "VantageInternational-Live 14",
    server27: "VantageInternational-Live 11",
    server28: "VantageFX-UK-Live UK",
    server29: "VantageAU-Live",
    server30: "VantagePrime-Demo",
    server31: "VantagePrime-Live",
    server32: "VantageAU-Live 1",
    server33: "VantageAU-Live 2",
    server34: "VantageAU-Demo",
    server35: "VantageInternational-Live 16",
    server36: "VantageFX-Live 4",
    server37: "VantageFXInternational-Live 4",
    server38: "VantageFXInternational-Demo",
    server39: "VantageFXInternational-Live 1",
    server40: "VantageFXInternational-Live 2",
    server41: "VantageFXInternational-Live 3",
    server42: "VantageFXInternational-Live 6",
    server43: "VantageFXInternational-Live 5",
    server44: "VantageFX-Live 5",
    value: "Vantage Markets",
  },
  {
    label: "Varchev Brokers",
    server1: "Varchev-Demo",
    server2: "Varchev-Real",
    value: "Varchev Brokers",
  },
  {
    label: "VarengoldBankFX",
    server1: "Varengold-Server",
    server2: "Varengold-Demo Server",
    server3: "Varengold-Varengold EUR Integral Server",
    server4: "Varengold-CFD-Server",
    server5: "83.169.49.214:443",
    server6: "Varengold-EUR Server",
    server7: "Varengold-Varengold",
    server8: "VarengoldBank-Demo",
    value: "VarengoldBankFX",
  },
  {
    label: "VARIANSE",
    server1: "VARIANSE-Demo",
    server2: "VARIANSE-Main",
    server3: "VDX-Live",
    server4: "VDX-Demo",
    server5: "VARIANSE-DemoUS",
    server6: "VARIANSE-Live",
    server7: "VARIANSE-Live3",
    server8: "VARIANSE-Demo3",
    value: "VARIANSE",
  },
  {
    label: "VaslonFX",
    server1: "VaslonInternational-Demo",
    server2: "VaslonInternational-Live",
    value: "VaslonFX",
  },
  {
    label: "Vast Triumph",
    server1: "VastTriumphHolding-Live",
    server2: "VastTriumphHolding-Demo",
    value: "Vast Triumph",
  },
  {
    label: "Vault Markets",
    server1: "VaultMarkets-Demo",
    server2: "VaultMarkets-Live01",
    value: "Vault Markets",
  },
  {
    label: "VCOM",
    server1: "VCOM-Demo",
    server2: "VCOM-Real",
    value: "VCOM",
  },
  {
    label: "Vector Securities",
    server1: "ICVector-Server",
    value: "Vector Securities",
  },
  {
    label: "VeForex",
    server1: "Vestfold-Server",
    value: "VeForex",
  },
  {
    label: "VegaMarkets",
    server1: "VegaMarkets-Demo",
    server2: "VegaMarkets-Main",
    value: "VegaMarkets",
  },
  {
    label: "Vego Trade",
    server1: "VegoTrade-Live",
    server2: "VegoTrade-Demo",
    value: "Vego Trade",
  },
  {
    label: "Velocity Trade",
    server1: "VelocityTrades-Demo",
    server2: "VelocityTrades-Real",
    server3: "VTMT4-Demo 5",
    server4: "VTMT4-Live 5",
    value: "Velocity Trade",
  },
  {
    label: "Velocity4X",
    server1: "Velocity4x-Demo1",
    server2: "Velocity4x-Demo",
    server3: "Velocity4x-Real",
    server4: "Velocity4x-VServer",
    value: "Velocity4X",
  },
  {
    label: "Venbey Yatırım",
    server1: "Venbey-Demo",
    server2: "Venbey-Live",
    value: "Venbey Yatırım",
  },
  {
    label: "VenetFX",
    server1: "VenetFX-NY7 DEMO",
    server2: "VenetFX-NY7 LIVE",
    value: "VenetFX",
  },
  {
    label: "VENICE BANKS",
    server1: "VeniceBanks-Demo",
    server2: "VeniceBanks-Live",
    value: "VENICE BANKS",
  },
  {
    label: "VennFX",
    server1: "VennTrading-Demo",
    server2: "VennTrading-Live",
    value: "VennFX",
  },
  {
    label: "Ventezo",
    server1: "Ventezo-Demo",
    server2: "Ventezo-Server",
    server3: "Ventezo-Live",
    value: "Ventezo",
  },
  {
    label: "VenturaPrimus",
    server1: "VenturaPrimus-Server",
    value: "VenturaPrimus",
  },
  {
    label: "Veracity Markets",
    server1: "VeracityMarkets-Demo",
    server2: "VeracityMarkets-Real",
    server3: "VeracityMarkets-Real-1",
    server4: "VeracityMarkets-Real-2",
    value: "Veracity Markets",
  },
  {
    label: "Vertical Markets",
    server1: "VerticalMarkets-Real",
    value: "Vertical Markets",
  },
  {
    label: "VertiFX",
    server1: "Vertifx-Demo",
    server2: "Vertifx-Live",
    value: "VertiFX",
  },
  {
    label: "VerumFX",
    server1: "VerumFX-Demo",
    server2: "VerumFX-Real",
    value: "VerumFX",
  },
  {
    label: "Vestrado",
    server1: "Vestrado-Demo",
    server2: "Vestrado-Real",
    value: "Vestrado",
  },
  {
    label: "VFM Brokers",
    server1: "VentureFinancialMarketsLtd-Real2",
    server2: "VentureFinancialMarketsLtd-Demo",
    server3: "VentureFinancialMarketsLtd-Live",
    value: "VFM Brokers",
  },
  {
    label: "VGX",
    server1: "VeritasSolutions-Demo",
    server2: "VeritasSolutions-Live",
    value: "VGX",
  },
  {
    label: "VIBHS",
    server1: "VIBHSFinancialLtd-Demo",
    server2: "VIBHSFinancialLtd-Main",
    server3: "VIBHSUK-Live",
    server4: "VIBHSUK-Demo",
    server5: "VIBHS-Demo",
    value: "VIBHS",
  },
  {
    label: "Victorian Prime FX",
    server1: "VictorianPrimeFX-Demo",
    server2: "VictorianPrimeFX-Live",
    value: "Victorian Prime FX",
  },
  {
    label: "Victory International Futures",
    server1: "VIF-Demo Accounts Server",
    server2: "VIF-International Server",
    server3: "VIF-Real Accounts Server",
    value: "Victory International Futures",
  },
  {
    label: "VictoryManagement-Demo",
    server1: "No servers",
    value: "VictoryManagement-Demo",
  },
  {
    label: "VincentFX",
    server1: "VincentFX-Demo",
    server2: "VincentFX-Live",
    value: "VincentFX",
  },
  {
    label: "Vinson Financials",
    server1: "VinsonTrader-Demo",
    server2: "VinsonTrader-Real",
    value: "Vinson Financials",
  },
  {
    label: "VIPortal",
    server1: "VIPortal-Live",
    value: "VIPortal",
  },
  {
    label: "Vipotor",
    server1: "VIPOTORWealth-Demo",
    server2: "VIPOTORWealth-Live",
    value: "Vipotor",
  },
  {
    label: "Vipro Markets",
    server1: "ViproMarkets-Demo",
    server2: "ViproMarkets-Live",
    server3: "TickmillEU-Live",
    server4: "TickmillEU-Demo",
    value: "Vipro Markets",
  },
  {
    label: "VirtueForex",
    server1: "VirtueTechnology-Demo",
    server2: "VirtueTechnology-Live",
    value: "VirtueForex",
  },
  {
    label: "Vista Brokers",
    server1: "Vistabrokers-MT4 Demo Server",
    server2: "Vistabrokers-MT4 Main Server",
    value: "Vista Brokers",
  },
  {
    label: "VitalMarkets",
    server1: "VitalMarkets-Demo",
    server2: "VitalMarkets-Live",
    server3: "VitalMarkets-Real",
    value: "VitalMarkets",
  },
  {
    label: "Vitrade",
    server1: "ViTrade-FX Server",
    value: "Vitrade",
  },
  {
    label: "VIVA Forex",
    server1: "VivaFx-Demo",
    server2: "VivaFx-Live 2",
    value: "VIVA Forex",
  },
  {
    label: "Vizavi",
    server1: "VZVImpex-Demo",
    server2: "VZVImpex-Real",
    value: "Vizavi",
  },
  {
    label: "Vlado",
    server1: "VladoLimited-Demo",
    server2: "VladoLimited-Live",
    server3: "Vlado-Live",
    server4: "Vlado-Demo",
    value: "Vlado",
  },
  {
    label: "VMS Markets",
    server1: "VictoryManagement-Demo",
    server2: "VictoryManagement-Real",
    value: "VMS Markets",
  },
  {
    label: "VOBLAST",
    server1: "VoblastCorp-Demo",
    server2: "VoblastCorp-Live",
    value: "VOBLAST",
  },
  {
    label: "VolumeFX",
    server1: "VolumeFX-Demo",
    server2: "VolumeFX-Real2",
    value: "VolumeFX",
  },
  {
    label: "Vomma Trading",
    server1: "Vomma-Demo",
    server2: "Vomma-Live2",
    server3: "XTuneMedia-Demo",
    server4: "XTuneMedia-Real",
    server5: "ShiftForex-Demo",
    server6: "ShiftForex-Real",
    value: "Vomma Trading",
  },
  {
    label: "VortexAssets",
    server1: "VortexAssets-Real",
    server2: "VortexAssets-Demo",
    value: "VortexAssets",
  },
  {
    label: "VP Fintech",
    server1: "VPMarketsGlobal-Demo",
    server2: "VPMarketsGlobal-Live",
    value: "VP Fintech",
  },
  {
    label: "VPEAG",
    server1: "VPEAG-Demo",
    server2: "VPEAG-Real",
    server3: "CMAP-LIVE",
    server4: "vPEdma-Demo",
    server5: "vPEdma-Live",
    server6: "mtpro01.primexm.com:443",
    value: "VPEAG",
  },
  {
    label: "VPFX",
    server1: "VPFX-Server",
    value: "VPFX",
  },
  {
    label: "VT Markets",
    server1: "VTMarkets-Demo",
    server2: "VTMarkets-Live",
    server3: "VTMarkets-Live 2",
    server4: "VTMarkets-Live 3",
    server5: "VTMarkets-Live 4",
    server6: "VTMarkets-Live 5",
    value: "VT Markets",
  },
  {
    label: "VTSGold",
    server1: "TradeProServices-Demo",
    server2: "TradeProServices-Live",
    value: "VTSGold",
  },
  {
    label: "VZN",
    server1: "VZNResources-DEMO",
    server2: "VZNResources-REAL",
    value: "VZN",
  },
  {
    label: "W7",
    server1: "W7Limited-Demo",
    server2: "W7Limited-Live",
    value: "W7",
  },
  {
    label: "Walbrook",
    server1: "WalbrookCapitalMarkets-Demo",
    server2: "WalbrookCapitalMarkets-Live",
    value: "Walbrook",
  },
  {
    label: "Wall Street Brokers",
    server1: "WSBrokers-Server",
    value: "Wall Street Brokers",
  },
  {
    label: "WALTFX",
    server1: "Walt-Demo",
    server2: "Walt-Live",
    value: "WALTFX",
  },
  {
    label: "Wave To Markets",
    server1: "WaveToMarkets-Demo",
    server2: "WaveToMarkets-Live2",
    value: "Wave To Markets",
  },
  {
    label: "WCA BROKER",
    server1: "WCAFinance-Demo",
    server2: "WCAFinance-Real",
    value: "WCA BROKER",
  },
  {
    label: "Wealth Motion FX",
    server1: "WealthMotionFX-Demo",
    server2: "WealthMotionFX-Live",
    value: "Wealth Motion FX",
  },
  {
    label: "Wealth Trade",
    server1: "WealthTrade-Demo 3",
    server2: "WealthTrade-Live",
    server3: "WealthTrade-London",
    value: "Wealth Trade",
  },
  {
    label: "WeAreTraders",
    server1: "WeAreTraders-DemoUS",
    server2: "WeAreTraders-LiveUS",
    value: "WeAreTraders",
  },
  {
    label: "Well-FX",
    server1: "WellTogether-Live-UK-2",
    value: "Well-FX",
  },
  {
    label: "Welly Trader Global",
    server1: "WellyTraderGlobal-Demo",
    server2: "WellyTraderGlobal-Real",
    value: "Welly Trader Global",
  },
  {
    label: "WELTRADE",
    server1: "Weltrade-Demo",
    server2: "Weltrade-Live",
    value: "WELTRADE",
  },
  {
    label: "weonefx",
    server1: "WeOne-Demo",
    server2: "WeOne-Live",
    value: "weonefx",
  },
  {
    label: "WesternFX",
    server1: "WesternGroup-Demo",
    server2: "WesternGroup-Live",
    value: "WesternFX",
  },
  {
    label: "Westfield",
    server1: "WestfieldPremier-Demo",
    server2: "WestfieldPremier-Live",
    value: "Westfield",
  },
  {
    label: "WeTrade FX",
    server1: "WetradeInternational-Live",
    server2: "WetradeInternational-Demo",
    server3: "WeTradeBroker-Demo",
    server4: "WeTradeBroker-Live1",
    server5: "WeTradeBroker-Live2",
    value: "WeTrade FX",
  },
  {
    label: "Wforex.ru",
    server1: "WForex-Real",
    server2: "WForex-Demo",
    server3: "WorldForex-Live",
    value: "Wforex.ru",
  },
  {
    label: "WFX",
    server1: "WFX-Demo",
    server2: "WFX-Live",
    value: "WFX",
  },
  {
    label: "WFX Markets",
    server1: "WFXBank-Demo",
    server2: "WFXBank-Live",
    value: "WFX Markets",
  },
  {
    label: "WH SelfInvest",
    server1: "WHSelfinvestSA-DemoServer",
    server2: "WHSelfinvestSA-LiveServer",
    value: "WH SelfInvest",
  },
  {
    label: "Whale",
    server1: "WhaleMarkets-Demo",
    server2: "WhaleMarkets-Real01",
    value: "Whale",
  },
  {
    label: "WhichWay",
    server1: "WhichWayFX-Demo",
    server2: "WhichWayFX-Real",
    value: "WhichWay",
  },
  {
    label: "White Stone",
    server1: "WSFX-Demo",
    server2: "WSFX-Live",
    value: "White Stone",
  },
  {
    label: "WhiteRock FX",
    server1: "WRF-US03-Demo",
    server2: "WRF-US05-Live",
    server3: "WRF-US06-Live",
    value: "WhiteRock FX",
  },
  {
    label: "Whittworth Investing",
    server1: "WhittworthInvesting-Demo",
    server2: "WhittworthInvesting-Real",
    value: "Whittworth Investing",
  },
  {
    label: "WhoTrades",
    server1: "WhoTrades-Demo",
    server2: "WhoTrades-Real",
    server3: "WhoTrades-Real2",
    server4: "WhoTrades-Real 3",
    server5: "WhoTrades-Real4",
    value: "WhoTrades",
  },
  {
    label: "Wibs Capital",
    server1: "WibsCapital-REAL SERVER",
    server2: "WibsCapital-DEMO",
    value: "Wibs Capital",
  },
  {
    label: "WilfordTrade",
    server1: "WilfordTrade-demo",
    server2: "WilfordTrade-Server",
    value: "WilfordTrade",
  },
  {
    label: "Windsor Brokers",
    server1: "Windsor-REAL",
    server2: "WindsorBrokers-Demo",
    server3: "WindsorBrokers-Real",
    server4: "WindsorBrokers-Windsor Brokers Ltd",
    server5: "Windsor-DEMO",
    server6: "WindsorBrokersBZ-DEMO",
    server7: "WindsorBrokersBZ-REAL",
    server8: "WindsorBrokers-REAL2",
    value: "Windsor Brokers",
  },
  {
    label: "Wisdom",
    server1: "WisdomBroker-Demo",
    server2: "WisdomBroker-Live",
    server3: "WisdomMarketLimited-Demo",
    server4: "WisdomMarketLimited-Live",
    value: "Wisdom",
  },
  {
    label: "Wisdom Forex",
    server1: "WisdomTechnology-Demo",
    server2: "WisdomTechnology-Live",
    value: "Wisdom Forex",
  },
  {
    label: "Wise Group",
    server1: "WiseGroup-Demo",
    server2: "WiseGroup-Live",
    value: "Wise Group",
  },
  {
    label: "Wisebanc",
    server1: "WiseFinancialLtd-Trader",
    value: "Wisebanc",
  },
  {
    label: "WistonFx",
    server1: "WistonGlobalLimited-Demo",
    server2: "WistonGlobalLimited-Live",
    value: "WistonFx",
  },
  {
    label: "WIT",
    server1: "WITltd-Real",
    value: "WIT",
  },
  {
    label: "WITE FX",
    server1: "WhiteWinner-Demo",
    server2: "WhiteWinner-Real",
    value: "WITE FX",
  },
  {
    label: "WL limited",
    server1: "WLLimited-Demo1",
    server2: "WLLimited-Live1",
    value: "WL limited",
  },
  {
    label: "WLFX",
    server1: "WholeLearned-Live",
    server2: "WholeLearned-Demo",
    value: "WLFX",
  },
  {
    label: "WM Forex",
    server1: "WMGroup-Global",
    server2: "WMGroup-Global2",
    value: "WM Forex",
  },
  {
    label: "Wohlstand FX",
    server1: "Wohlstand-Demo",
    server2: "Wohlstand-Live",
    value: "Wohlstand FX",
  },
  {
    label: "Woodside Capital Inc",
    server1: "WCI-Real",
    value: "Woodside Capital Inc",
  },
  {
    label: "World Class Markets",
    server1: "WorldClassMarket-DEMO",
    server2: "WorldClassMarket-LIVE",
    value: "World Class Markets",
  },
  {
    label: "World Credit Savings",
    server1: "No servers",
    value: "World Credit Savings",
  },
  {
    label: "World Forex",
    server1: "WForex-Demo",
    server2: "WForex-Server",
    server3: "WForex-Real",
    server4: "WorldForex-Live",
    server5: "WorldForex-Demo",
    value: "World Forex",
  },
  {
    label: "World Pro FX",
    server1: "WorldProFX-Demo",
    server2: "WorldProFX-Live",
    value: "World Pro FX",
  },
  {
    label: "World Traders",
    server1: "WorldTraders-Demo",
    server2: "WorldTraders-LiveLiquidity1",
    value: "World Traders",
  },
  {
    label: "Worldclass Financial Intelligence",
    server1: "WorldclassFinancial-REAL SERVER",
    server2: "WorldclassFinancial-DEMO",
    value: "Worldclass Financial Intelligence",
  },
  {
    label: "WorldWide Markets",
    server1: "WWM-Demo",
    server2: "WWM-Live",
    value: "WorldWide Markets",
  },
  {
    label: "WSD",
    server1: "WSD-Demo",
    server2: "WSD-WSD Financial (NZ) Ltd.",
    value: "WSD",
  },
  {
    label: "WSI (Wave And Soros)",
    server1: "WSIForex-Demo",
    server2: "WSIForex-Live",
    value: "WSI (Wave And Soros)",
  },
  {
    label: "WSL Quotes",
    server1: "WSL-Live",
    server2: "WSL-Demo",
    value: "WSL Quotes",
  },
  {
    label: "WSM",
    server1: "WSM-Demo",
    server2: "WSM-Live",
    server3: "WSM-Live 1",
    value: "WSM",
  },
  {
    label: "WSX",
    server1: "WSXLLC-Demo",
    server2: "WSXLLC-Live",
    server3: "WSXLLC-Live4",
    value: "WSX",
  },
  {
    label: "WTG",
    server1: "WinTrendsGlobal-Live",
    server2: "WinTrendsGlobal-Demo",
    value: "WTG",
  },
  {
    label: "WynnFX",
    server1: "WynnFXCompany-Demo",
    server2: "WynnFXCompany-Live2",
    value: "WynnFX",
  },
  {
    label: "X Global Markets",
    server1: "XGLOBAL-Real",
    server2: "XGLOBAL-Demo",
    value: "X Global Markets",
  },
  {
    label: "X-Trade Brokers",
    server1: "XTB-Demo",
    server2: "XTB-Real",
    server3: "XTrade-Demo",
    server4: "XTrade-Real",
    server5: "XTradeUA-Demo",
    server6: "XTradeUA-Real",
    server7: "XTrade-Contest",
    server8: "XTrade-MT4 Demo",
    server9: "XTradeSK-Contest",
    server10: "XTradeSK-Demo",
    server11: "XTradeSK-MT4 Demo",
    server12: "XTradeSK-Real",
    server13: "XTrade-Real2",
    server14: "XtradeServer-Real3",
    server15: "XTrade-Real3",
    value: "X-Trade Brokers",
  },
  {
    label: "XAU Merlion",
    server1: "XAUMerlion-DemoUK",
    server2: "XAUMerlion-LiveUK",
    server3: "XAUMerlion-Live1",
    value: "XAU Merlion",
  },
  {
    label: "XB Prime",
    server1: "Marketfinancials-Demo",
    server2: "Marketfinancials-Live02",
    value: "XB Prime",
  },
  {
    label: "XBTFX",
    server1: "XBTFX-Demo",
    server2: "XBTFX-Real",
    value: "XBTFX",
  },
  {
    label: "XBulls",
    server1: "RynatTrading-Demo",
    server2: "RynatTrading-Real",
    value: "XBulls",
  },
  {
    label: "XCOQ",
    server1: "XCOQ-Server",
    value: "XCOQ",
  },
  {
    label: "XDirect",
    server1: "xDirect-LVS",
    server2: "xDirect-MT4 Demo",
    server3: "xDirect-Real",
    server4: "xDirect-Real2",
    server5: "xDirect-Real3",
    value: "XDirect",
  },
  {
    label: "Xero Markets",
    server1: "XeroCapital-Demo",
    server2: "XeroCapital-Real",
    value: "Xero Markets",
  },
  {
    label: "XFlow",
    server1: "XFlowMarkets-DEMO",
    server2: "XFlowMarkets-LIVE",
    value: "XFlow",
  },
  {
    label: "XForex",
    server1: "Xforex-Demo",
    server2: "Xforex-Main",
    value: "XForex",
  },
  {
    label: "XGLOBAL Markets",
    server1: "XGLOBAL-Real",
    value: "XGLOBAL Markets",
  },
  {
    label: "XIG Limited",
    server1: "XIG-Demo",
    server2: "XIG-ECN",
    server3: "XIG-Fix",
    server4: "XIG-FixCent",
    server5: "XIG-Pro",
    server6: "XIG-ProCent",
    server7: "XIGLimited-Live",
    value: "XIG Limited",
  },
  {
    label: "XINFX",
    server1: "HKXinMarketGroup-Demo",
    server2: "HKXinMarketGroup-Live",
    value: "XINFX",
  },
  {
    label: "XM",
    server1: "XM.COM-Demo",
    server2: "XeMarkets-Real",
    server3: "XM.COM-Real 1",
    server4: "XM.COM-Real 2",
    server5: "XM.COM-Real 3",
    server6: "XeMarkets-Real 3",
    server7: "XM.COM-Real 4",
    server8: "XM.COM-Real 5",
    server9: "XM.COM-Real 6",
    server10: "XM.COM-Real 7",
    server11: "XM.COM-Real 8",
    server12: "XMGlobal-Real 3",
    server13: "XM.COM-AU-Demo 2",
    server14: "XMGLobal-Real29",
    server15: "XMGlobal-Real 28",
    server16: "XMGlobal-Real 29",
    server17: "XMGlobal-Real 5",
    server18: "XMTrading-Real 31",
    server19: "XMAU-Real 19",
    server20: "XMAU-Real 20",
    server21: "XMAU-Demo 2",
    server22: "XMGlobal-Real 33",
    server23: "XMGlobal-Real 32",
    server24: "XMTrading-Real 7",
    server25: "XMTrading-Real 34",
    server26: "XMGlobal-Real 36",
    server27: "XMUK-Real 30",
    server28: "XMGlobal-Real 35",
    server29: "XMTrading-Real 37",
    server30: "XMGlobal-Real 38",
    server31: "XMGlobal-Real 39",
    server32: "XMTrading-Demo 2",
    server33: "XMTrading-Real 40",
    server34: "XMGlobal-Real 42",
    server35: "XMGlobal-Real 41",
    server36: "XMAU-Real 13",
    server37: "XMGlobal-Real 43",
    server38: "XMTrading-Real 45",
    server39: "XMGlobal-Real 44",
    server40: "XMGlobal-Real 46",
    server41: "XMTrading-Real 47",
    server42: "XMGlobal-Demo 2",
    server43: "XMTrading-Real 48",
    server44: "XMGlobal-Real 30",
    server45: "XM.COM-Real 9",
    server46: "XM.COM-Real 10",
    server47: "XM.COM-Real 11",
    server48: "XM.COM-Demo 2",
    server49: "XM.COM-Real 13",
    server50: "XMTrading-Real 12",
    server51: "XMGlobal-Real 14",
    server52: "XM.COM-Real 15",
    server53: "XMTrading-Real 11",
    server54: "XM.COM-AU-Real 13",
    server55: "XM.COM-Real 17",
    server56: "XM.COM-Real 16",
    server57: "XM.COM-Real 18",
    server58: "XMUK-Demo",
    server59: "XMUK-Real 17",
    server60: "XM.COM-AU-Real 17",
    server61: "XMUK-Real 18",
    server62: "XM.COM-AU-Real 14",
    server63: "XMUK-Demo 2",
    server64: "XMUK-Real 15",
    server65: "XMUK-Real 3",
    server66: "XMUK-Real 2",
    server67: "XMUK-Real 19",
    server68: "XM.COM-Real 19",
    server69: "XM.COM-AU-Real 18",
    server70: "XMGlobal-Real 20",
    server71: "XMUK-Real 20",
    server72: "XM.COM-Real 23",
    server73: "XMUK-Real 23",
    server74: "XM.COM-AU-Real 20",
    server75: "XMGlobal-Real 22",
    server76: "XM.COM-AU-Real 23",
    server77: "XM.COM-AU-Demo",
    server78: "XMGlobal-Real 24",
    server79: "XMTrading-Real 25",
    server80: "XMGlobal-Real 23",
    server81: "XMGlobal-Real 13",
    server82: "XMGlobal-Real 19",
    server83: "XMGlobal-Real 9",
    server84: "XMGlobal-Real 15",
    server85: "XM.COM-Real 20",
    server86: "XMGlobal-Real 10",
    server87: "XMGlobal-Real 16",
    server88: "XMGlobal-Real 18",
    server89: "XMUK-Real 6",
    server90: "XMGlobal-Real 27",
    server91: "XMGlobal-Real 21",
    server92: "XMGlobal-Real 2",
    server93: "XMGlobal-Demo 4",
    server94: "XMGlobal-Demo",
    server95: "XMTrading-Demo 3",
    server96: "XMGlobal-Real 26",
    server97: "XMGlobal-Real 6",
    server98: "XMGlobal-Real 17",
    server99: "XM.COM-Real 14",
    server100: "XM.COM-AU-Real 3",
    server101: "XM.COM-AU-Real 19",
    server102: "XMGlobal-Real 8",
    server103: "XMTrading-Real 49",
    server104: "XMGlobal-Real 39",
    server105: "XMTrading-Real 250",
    server106: "XMGlobal-Real 251",
    server107: "XMTrading-Real 252",
    server108: "XMTrading-Real 253",
    server109: "XMTrading-Real 254",
    server110: "XMTradingMU-Real 255",
    server111: "XMTrading-Real256",
    server112: "XMTrading-Real 257",
    server113: "XMTrading-Demo 5",
    server114: "XMAU-Demo",
    server115: "XMTrading-Real258",
    value: "XM",
  },
  {
    label: "XMTrading",
    server1: "XMTrading-Demo 3",
    server2: "XMTrading-Real 7",
    server3: "XMTrading-Real 11",
    server4: "XMTrading-Real 12",
    server5: "XMTrading-Real 25",
    server6: "XMTrading-Real 31",
    server7: "XMTrading-Real 34",
    server8: "XMTrading-Real 250",
    server9: "XMTrading-Real 49",
    server10: "XMTrading-Real 252",
    server11: "XMTrading-Real 253",
    server12: "XMTrading-Real 47",
    server13: "XMTrading-Real 37",
    server14: "XMTrading-Real 45",
    server15: "XMTrading-Real 254",
    server16: "XMTrading-Real 40",
    server17: "XMTradingMU-Real 255",
    server18: "XMTrading-Real 48",
    server19: "XMTrading-Real256",
    server20: "XMTrading-Real 257",
    server21: "XMTrading-Demo 5",
    server22: "XMTrading-Real 258",
    value: "XMTrading",
  },
  {
    label: "XP Investimentos",
    server1: "XPMT5-Demo",
    value: "XP Investimentos",
  },
  {
    label: "XPG Markets",
    server1: "XPGMarkets-DEMO",
    server2: "XPGMarkets-REAL",
    value: "XPG Markets",
  },
  {
    label: "XPrime Markets",
    server1: "XPrimeMarkets-Live",
    server2: "XPrimeMarkets-Demo",
    value: "XPrime Markets",
  },
  {
    label: "XS",
    server1: "XSSystems-Demo",
    server2: "XSSystems-Real1",
    value: "XS",
  },
  {
    label: "XTB",
    server1: "XtradeServer-MT4 Demo",
    server2: "XtradeServer-Real",
    server3: "XtradeServer-Real2",
    server4: "XtradeServer-Real3",
    server5: "XTrade-MT4 Demo",
    server6: "XTrade-Real",
    server7: "XTrade-Real3",
    server8: "XMTrading-Real 12",
    server9: "XTrade-Real2",
    server10: "XTrade-Real4",
    value: "XTB",
  },
  {
    label: "XtreamForex",
    server1: "GrandInvesting-Demo",
    server2: "GrandInvesting-Live",
    server3: "GrandInvestingLTD-Real2",
    server4: "XtreamMarkets-Real2",
    server5: "XtreamMarkets-Demo",
    value: "XtreamForex",
  },
  {
    label: "Y5 Markets",
    server1: "Y5Markets-Live",
    server2: "Y5Markets-Demo",
    value: "Y5 Markets",
  },
  {
    label: "YA-HI",
    server1: "YaHi-Real",
    server2: "YaHi-Demo",
    value: "YA-HI",
  },
  {
    label: "Yadix",
    server1: "Yadixcom-Demo",
    server2: "Yadixcom-Live",
    server3: "QuantixFS-Live2",
    server4: "Quantix-Live2",
    server5: "Quantix-Demo",
    value: "Yadix",
  },
  {
    label: "YaMarkets",
    server1: "YaMarkets-DEMO",
    server2: "YaMarkets-REAL",
    value: "YaMarkets",
  },
  {
    label: "YCM-Invest",
    server1: "FinotecFX-Demo",
    server2: "FinotecFX-Live",
    server3: "Finotec-DemoUS",
    server4: "Finotec-LiveUS",
    server5: "YCMInvest-Live",
    value: "YCM-Invest",
  },
  {
    label: "Yellow Peacock",
    server1: "itexsys-Demo",
    server2: "itexsys-Live",
    server3: "itexsys-Platform",
    value: "Yellow Peacock",
  },
  {
    label: "YoogainFX",
    server1: "Yoogain-Demo",
    server2: "Yoogain-Live",
    value: "YoogainFX",
  },
  {
    label: "York Markets",
    server1: "YorkMarkets-Demo",
    server2: "YorkMarkets-Live",
    value: "York Markets",
  },
  {
    label: "You Trade FX",
    server1: "Youtradefx-Demo",
    server2: "Youtradefx-Real",
    value: "You Trade FX",
  },
  {
    label: "YSI",
    server1: "YSIMarkets-Demo",
    server2: "YSIMarkets-Live2",
    value: "YSI",
  },
  {
    label: "YuLo",
    server1: "YuloTrading-Live",
    value: "YuLo",
  },
  {
    label: "YunikonFX",
    server1: "YunikonFinancial-Demo",
    server2: "YunikonFinancial-Live",
    value: "YunikonFX",
  },
  {
    label: "YUS",
    server1: "YUS-Demo",
    server2: "YUS-Live",
    value: "YUS",
  },
  {
    label: "Yutaka Century Realtime",
    server1: "YCR-Demo",
    server2: "YCR-Live",
    value: "Yutaka Century Realtime",
  },
  {
    label: "Z.com Trade",
    server1: "Z.comTradeUK-Demo Server",
    server2: "Z.comTradeUK-Live Server",
    server3: "Z.comTradeUKLtd-Live-UK",
    server4: "Z.comTradeUKLtd-Demo-UK",
    value: "Z.com Trade",
  },
  {
    label: "ZAIX",
    server1: "ZAIXLtd-Demo",
    server2: "ZAIXLtd-Live",
    value: "ZAIX",
  },
  {
    label: "Zandbox",
    server1: "UniversalInnovation-Demo-UK",
    server2: "UniversalInnovation-Live-UK",
    value: "Zandbox",
  },
  {
    label: "Zara FX",
    server1: "ArazFX-Demo",
    server2: "ArazFX-Real",
    value: "Zara FX",
  },
  {
    label: "ZarFX",
    server1: "ZARFX-Demo",
    server2: "ZARFX-Real2",
    value: "ZarFX",
  },
  {
    label: "ZBForex.com",
    server1: "ZBFOREX.COM-Demo",
    server2: "ZBFOREX.COM-Live",
    value: "ZBForex.com",
  },
  {
    label: "ZBtrading",
    server1: "BTGForex-Gold-i_Demo1",
    server2: "Dana-Demo",
    server3: "Dana-Live",
    server4: "FXTG-Main Server 1",
    server5: "Goldi-Gold-i_Demo1",
    server6: "IHForex-Demo",
    server7: "MBT-Pay for Limits Demo",
    server8: "MCmarkets-Demo",
    server9: "MCmarkets-Real",
    server10: "SKYFX-Demo Server",
    server11: "SKYFX-Main Server 3",
    server12: "SKYFX-Main Server",
    server13: "ZB-Demo",
    server14: "ZB-Live",
    value: "ZBtrading",
  },
  {
    label: "Zebrafx",
    server1: "zebrafx-Demo",
    server2: "zebrafx-Live2",
    value: "Zebrafx",
  },
  {
    label: "Zenfinex",
    server1: "Zenfinex-Demo-UK",
    server2: "Zenfinex-Live-UK",
    server3: "Zenfinex-Live",
    value: "Zenfinex",
  },
  {
    label: "Zentrade",
    server1: "ZenTradePremier-Demo",
    server2: "ZenTradePremier-Live",
    value: "Zentrade",
  },
  {
    label: "Zero Markets",
    server1: "ZeroMarkets-Live",
    server2: "ZeroMarkets-Demo",
    server3: "ZeroMarkets-Live-1",
    value: "Zero Markets",
  },
  {
    label: "ZFX",
    server1: "ZealCapital-Demo",
    server2: "ZealCapital-Live",
    server3: "ZealCapitalMarketSC-Demo",
    server4: "ZealCapitalMarketSC-Live",
    server5: "ZealCapitalMarket-Demo02",
    server6: "ZealCapitalMarket-Live02",
    server7: "ZealCapitalMarketSC-Live02",
    server8: "ZealCapitalMarketUK-Demo",
    server9: "ZealCapitalMarketUK-Live",
    value: "ZFX",
  },
  {
    label: "Zirve Forex",
    server1: "ZirveFX-Demo",
    server2: "ZirveFX-Real",
    value: "Zirve Forex",
  },
  {
    label: "ZlatFX",
    server1: "ZlatFx-Demo",
    server2: "ZlatFx-Live",
    value: "ZlatFX",
  },
  {
    label: "Zlato Capital",
    server1: "Z-Demo",
    server2: "Z-Live",
    value: "Zlato Capital",
  },
];
export default allMT4Brokers;
