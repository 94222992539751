import styled from "styled-components";

export const InputCardsDW = styled.div`

`;
export const CardColumnDW = styled.div`
  margin: 0px 16px 32px 24px;
  position: relative;
  top: -48px;
  font-size: 0.965em;
  width: 100%;
`;
export default InputCardsDW