import React from "react";
import { showTotalMetricTable } from "./MetricFunctions";
var he = require("he");

const TotalPerformanceMetrics = (props) => {
  const porttype = props.userPortfolioType[0];
  const tier = props.tier;
  const active = props.active;
  const specificPortfolio = props.specificPortfolio;
  const porttypelength = props.userPortfolioType.length;
  const typestring =
    porttypelength !== 1
      ? "Units"
      : porttype === "Stocks"
      ? "Shares"
      : porttype === "Options" || porttype === "Futures"
      ? "Contracts"
      : porttype === "Forex"
      ? "Lots"
      : porttype === "Crypto"
      ? "Coins/Tokens"
      : porttype === "CFD"
      ? "Quantity"
      : "Units";

  const TotalMetricsLabels = [
    "Total P/L " + he.decode(props.defaultSymbol),
    "Total P/L %",
    "Total Number of Trades",
    `Total ${typestring} Traded`,
    "Total Commissions " + he.decode(props.defaultSymbol),
    "Total Fees " + he.decode(props.defaultSymbol),
    "Total Slippage " + he.decode(props.defaultSymbol), //Toggle between pips, cents, etc?
    "Total Deposits " + he.decode(props.defaultSymbol),
    "Total Withdrawals " + he.decode(props.defaultSymbol),
    "Symbols Traded",
    "Strategies Traded",
  ];
  const commissions =
    specificPortfolio.settings.calculationType === "Gross"
      ? 0
      : props.userCalculations["totalCommissions"];
  const fees =
    specificPortfolio.settings.calculationType === "Gross"
      ? 0
      : props.userCalculations["totalFees"];
  const totalMetrics = [
    props.userCalculations["totalPL_dollar"],
    props.userCalculations["totalPL_percent"],
    props.userCalculations["totalTrades"],
    parseFloat(props.userCalculations["totalLotsTraded"])?.toFixed(2),
    commissions,
    fees,
    props.userCalculations["totalSlippage"],
    props.userCalculations["totalDeposits"],
    props.userCalculations["totalWithdrawals"],
    props.userCalculations["totalSymbols"],
    props.userCalculations["totalStrategies"],
  ];

  return (
    <div className="totalmetricomponentwrapper">
      <div className="total-metrics-label" id="total-metrics-label">
        Totals
      </div>
      <div className="total-metrics-table" id="metric-component">
        <div>
          {showTotalMetricTable(
            totalMetrics,
            TotalMetricsLabels,
            props,
            tier,
            active
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalPerformanceMetrics;
