import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import "./DrawdownStyles.scss";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import randomChanceTool from "./randomChanceTool";
import ToggleDDRU from "./toggleDDRU";
import useStateWithPromise from "../../hooks/useStateWithPromise";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DrawdownComponent = (props) => {
  const firstUpdate = useRef(true);
  const [drawdownWidth, setdrawdownWidth] = useState(0);
  const [height, setHeight] = useState([window.innerHeight]);
  const [width, setWidth] = useState([window.innerWidth]);
  const showUpgradeModalProp = props.showUpgradeModal.current;
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: showUpgradeModalProp.show,
    featureSelect: showUpgradeModalProp.featureSelect,
    blur: showUpgradeModalProp.blur,
    tierText: showUpgradeModalProp.tierText,
  });
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const settings = allData.portfolio.settings;
  const drawdownToggleSetting = settings.streakCalcToggle;
  var localDrawdownSettings = JSON.parse(
    localStorage.getItem("streakCalcToggle")
  );
  const displayTypeINIT =
    localDrawdownSettings !== null
      ? localDrawdownSettings === false
        ? "Drawdown"
        : "Runup"
      : drawdownToggleSetting;
  const [state, setState] = useState(displayTypeINIT);
  const handleChange = useCallback((e) => {
    setState(e);
  }, []);
  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
    if (firstUpdate.current) {
      const drawdown_Wrapper = document.getElementById(
        "drawdowncomponentwrapper"
      );
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.attributeName === "style" ||
            mutation.attributeName === "max-width" ||
            mutation.attributeName === "min-width"
          ) {
            const width = drawdown_Wrapper.offsetWidth;
            setdrawdownWidth(width);
          }
        });
      });
      if (drawdown_Wrapper) {
        observer.observe(drawdown_Wrapper, { attributes: true });
      } else {
        console.log("drawdowncomponentwrapper not found");
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
    };
  }, [setdrawdownWidth]);

  return (
    <PageTemplate
      title={props.tabName}
      upgradeShow={showUpgradeModal}
      setshowUpgradeModal={(e) => {
        setshowUpgradeModal({
          ...showUpgradeModal,
          show: e.show,
          featureSelect: e.featureSelect,
          blur: e.blur,
          tierText: e.tierText,
        });
      }}
      page={
        <div
          className="streakprobcomponentwrapper"
          id="streakprobcomponentwrapper"
        >
          <div className="streakprobwrapper">
            <div className="streakprobwrapperddru">
              <ToggleDDRU
                data={allData}
                tier={tier}
                active={active}
                setcalcstate={(e) => handleChange(e)}
              />
            </div>
            <div className="streakprobwrappertool">
              {randomChanceTool(allData, state, tier, active)}
            </div>
          </div>
          {/*           <div className="drawdowncomponentRIGHTINNERwrapper">
            <ToggleDDRU
              data={allData}
              tier={tier}
              active={active}
              setcalcstate={(e) => handleChange(e)}
            />
            {state === "Drawdown" ? (
              <MetricComponent
                data={allData}
                toggleState={state}
                tier={tier}
                active={active}
              />
            ) : (
              <RunupMetricComponent
                data={allData}
                toggleState={state}
                tier={tier}
                active={active}
              />
            )}
          </div>
          <div className="drawdowncomponentLEFTINNERwrapper">
            <div className="drawdowncomponentTOPINNERwrapper">
              {state === "Drawdown" ? (
                <DrawdownPercentGraph
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                  calculationType={allData.portfolio.settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  startingDW={allData.calculations.start_DW}
                  tier={tier}
                  active={active}
                />
              ) : (
                <RunUpPercentGraph
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                  calculationType={allData.portfolio.settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  startingDW={allData.calculations.start_DW}
                  tier={tier}
                  active={active}
                />
              )}
            </div>
            <div className="drawdowncomponentBOTTOMINNERwrapper">
              {randomChanceTool(allData, state, tier, active)}
            </div>
          </div> */}
        </div>
      }
      userData={allData}
    />
  );
};

export default DrawdownComponent;
