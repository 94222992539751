import styled from "styled-components";
export const SuperContainer = styled.div`
  overflow: hidden;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 100%;
`;

export const Background = styled.div`
  position: fixed;
  height: 100%;
  z-index: -10000;
  width: 100%;
  top: 0em;
  background-color: #1a2028;
  overflow: hidden;
`;

export const StyledSideNav = styled.div`
  padding: 0;
  margin: 0;
  flex: auto;
  overflow: hidden;
`;

export const RightPanel = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  right: 0;
  color: #deebf7;
  border: 2px solid #1a2028;
  border-top: 50px solid #1a2028;
  background-color: #222a35;
  padding: 0;
  height: 100%;
  justify-content: center;
  display: flex;
  margin: 0;
  margin-left: 2px;
  min-width: 0px;
  overflow-x: visible;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    color: #1f2831;
    background-color: #1f2831;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
`;

export const GridWrapper = styled.div``;

export default { GridWrapper, ContentWrapper };
