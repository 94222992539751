import { initialGlobalSettingState } from "./initialStates";
// ****** Make sure to also add the setting in
// intialStates.js when adding a new setting!!
export const createGlobalSettings = (settingsRef, propstochange, stripeId) => {
  const settingsProps = [
    "optInForRewards",
    "stripeId",
    "cryptodotcomId",
    "username",
    "acceptedTerms",
    "integrationsInfo",
    "quantumCounter",
    "subscription_end",

    // <= NEW SETTING PROPS GO HERE
  ];
  let settingsData = {};
  settingsProps.forEach((prop) => {
    if (propstochange.hasOwnProperty(prop)) {
      settingsData[prop] = propstochange[prop];
    } else {
      settingsData[prop] =
        !settingsRef[prop] || settingsRef[prop] === undefined
          ? initialGlobalSettingState(stripeId)[prop]
          : settingsRef[prop];
    }
  });
  return settingsData;
};
export default createGlobalSettings;
