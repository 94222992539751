import { getFilteredList, setLoaderState } from "./../actions/actionCreators";
import store from "./../store";
import { ProfileIconHoverOffStyle } from "./../components/Navigation-Bar/JQUERY/InitialStyledQuery";
import $ from "jquery";
export const overlayClick = () => {
  $("#overlay").click(function (e) {
    e.stopPropagation();
    store.dispatch(getFilteredList("dateRangeFilter", null));
    store.dispatch(setLoaderState({ loading: false }));
    document.getElementById("noclick").style.display = "none";
    $("#overlay").fadeOut(300);
    $("#messageBox").fadeOut(300);
    $("#choose-columns-class").fadeOut(300);
    $("#choose-dashcolumns-class").fadeOut(300);
    $("#choose-date-range").fadeOut(300);
    $("#choose-time-range").fadeOut(300);
    $("#choose-modal-class").fadeOut(300);
    $("#delete-trades-class").fadeOut(300);
    $("#tag-trades-class").fadeOut(300);
    $("#autoimportmodalwrapper").fadeOut(300);
    $("#portfolioModal").fadeOut(300);
    $(".portfolio-icon").css({ color: "#deebf7" });
    $("#feedback-class").fadeOut(300);
    $("#autoimportunlinkmodalwrapper").fadeOut(300);
    ProfileIconHoverOffStyle(".profile-icon");
    $(".MuiPaper-root").fadeOut(0);
  });
};
export default overlayClick;
