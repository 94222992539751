export const countExerciseAssign = (executions) => {
  let numAssign = 0;
  let numExercise = 0;
  let numAssignLots = 0;
  let numExerciseLots = 0;
  executions.forEach((exec) => {
    numExercise += exec.exercised === "Exercised" && 1;
    numAssign += exec.exercised === "Assigned" && 1;
    numExerciseLots +=
      exec.exercised === "Exercised" && Number(exec.exitLotSize);
    numAssignLots += exec.exercised === "Assigned" && Number(exec.exitLotSize);
  });
  return {
    numExercise: numExercise,
    numAssign: numAssign,
    numExerciseLots: numExerciseLots,
    numAssignLots: numAssignLots,
  };
};
export const countExerciseAssignIsEqual = (executions) => {
  let numAssign = 0;
  let numExercise = 0;
  executions.forEach((exec) => {
    numExercise += exec.exercised === "Exercised" && 1;
    numAssign += exec.exercised === "Assigned" && 1;
  });
  return numExercise === numAssign && numAssign !== 0 && numExercise !== 0
    ? true
    : false;
};
export default countExerciseAssign;
