export const dropdownStyles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      height: "10px",
      width: "10px",
      color: "#1f2831",
      backgroundColor: "#1f2831",
    },
    "::-webkit-scrollbar-track": {
      borderRadius: "10px",
      border: "#a4a9b1",
      color: "#1f2831",
      backgroundColor: "#1f2831",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      color: "#14181e",
      backgroundColor: "#14181e",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
      WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
    },
    "::-webkit-scrollbar-corner": {
      backgroundColor: "#1f2831",
      borderBottomRightRadius: "5px",
    },
  }),
};
export default dropdownStyles;
