import React, { useContext } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import SettingsComponent from "./component";
import styled from "styled-components";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
//import useForceUpdate from "../../hooks/useForceUpdate";
import AutoImportModal from "../Integrations/AutoImportModal";
import UnlinkModal from "../Integrations/UnlinkModal";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import { useLocation } from "react-router-dom";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

export const CurrencySymbolItemOther = styled.div`
  padding: 0;
  margin: 0;
  font-size: 1.5em;
  font-weight: 400;
`;
const Settings = (props) => {
  const allGlobalData = useContext(DataContext);
  const location = useLocation();

  const locationState = location.state;
  const allData = allGlobalData && allGlobalData.allData;
  const active = allGlobalData.customer.active;
  const tier = allGlobalData.customer.tier;
  const need2faCheck = allGlobalData?.miscData?.need2faOpen;

  // PROCESS CONTEXT DATA!
  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Settings - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  // ----------------------

  const [selectedBroker, setselectedBroker] = useStateWithPromise();
  const [web3selected, setweb3selected] = useStateWithPromise();

  const [overwriteEntries, setoverwriteEntries] = useStateWithPromise();
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  //const forceUpdate = useForceUpdate();

  /* --------------- Render Components  ---------------*/
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          title={props.tabName}
          upgradeShow={showUpgradeModal}
          setshowUpgradeModal={(e) => {
            setshowUpgradeModal({
              ...showUpgradeModal,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            });
          }}
          page={
            <div style={{ width: "100%" }} id="settingswrapper">
              {
                <SettingsComponent
                  allData={processedData}
                  initialSettingsView={locationState}
                  setselectedBroker={(buffer) => {
                    setselectedBroker(buffer);
                  }}
                  setOverwriteEntries={(buffer) => {
                    setoverwriteEntries(buffer);
                  }}
                  setweb3selected={(buffer) => {
                    setweb3selected(buffer);
                  }}
                  setUpgradeModal={(buffer) => {
                    setshowUpgradeModal(buffer);
                  }}
                />
              }
            </div>
          }
          userData={processedData}
        />
      </div>
    );
  }
};

Settings.defaultProps = {
  tabName: "Settings",
  pageName: "Settings",
};

Settings.propTypes = {
  tabName: PropTypes.string,
};

export default Settings;
