var he = require("he");

export const MiscMetricsPROPS = [
  { value: "profitFactor", label: "Profit Factor" },
  { value: "percentprofitFactor", label: "Profit Factor %" },
  { value: "recoveryFactor", label: "Recovery Factor" },
  { value: "returnOverMaxDrawdown", label: "Return Over Max Drawdown" },
  { value: "profitLossRatio", label: "Profit/Loss Ratio" },
  { value: "percentprofitLossRatio", label: "Profit/Loss Ratio %" },
  { value: "CPCIndex", label: "CPC Index" },
  { value: "percentCPCIndex", label: "CPC Index %" },
  { value: "expectation", label: "Expectation" },
  { value: "kellypercent", label: "Kelly %" },
  { value: "normalizedExpectancypercent", label: "Normalized Expectancy %" },
  { value: "ulcerIndex", label: "Ulcer Index" },
  { value: "sharpeRatioArith", label: "Sharpe Ratio (Arithmetic)" },
  { value: "sortinoRatioArith", label: "Sortino Ratio (Arithmetic)" },
  { value: "sharpeRatioGeo", label: "Sharpe Ratio (Geometric)" },
  { value: "sortinoRatioGeo", label: "Sortino Ratio (Geometric)" },

  {
    value: "probabilityOfRandomChance",
    label: "Probability of Random Chance",
  },
  { value: "zScore", label: "Z-Score" },
  { value: "LRCorrelation", label: "LR Correlation" },
  { value: "LRStandardError", label: "LR Standard Error" },
  { value: "KRatio", label: "K-Ratio" },
];
// Label and value
export const MiscMetricsPROPSDollarArith = [
  { value: "profitFactor", label: "Profit Factor" },
  { value: "recoveryFactor", label: "Recovery Factor" },
  { value: "returnOverMaxDrawdown", label: "Return Over Max Drawdown" },
  { value: "profitLossRatio", label: "Profit/Loss Ratio" },
  { value: "CPCIndex", label: "CPC Index" },
  { value: "expectation", label: "Expectation" },
  { value: "kellypercent", label: "Kelly %" },
  { value: "normalizedExpectancypercent", label: "Normalized Expectancy %" },
  { value: "ulcerIndex", label: "Ulcer Index" },
  { value: "sharpeRatioArith", label: "Sharpe Ratio (Arithmetic)" },
  { value: "sortinoRatioArith", label: "Sortino Ratio (Arithmetic)" },
  {
    value: "probabilityOfRandomChance",
    label: "Probability of Random Chance",
  },
  { value: "zScore", label: "Z-Score" },
  { value: "LRCorrelation", label: "LR Correlation" },
  { value: "LRStandardError", label: "LR Standard Error" },
  { value: "KRatio", label: "K-Ratio" },
];
export const MiscMetricsPROPSDollarGeo = [
  { value: "profitFactor", label: "Profit Factor" },
  { value: "recoveryFactor", label: "Recovery Factor" },
  { value: "returnOverMaxDrawdown", label: "Return Over Max Drawdown" },
  { value: "profitLossRatio", label: "Profit/Loss Ratio" },
  { value: "CPCIndex", label: "CPC Index" },
  { value: "expectation", label: "Expectation" },
  { value: "kellypercent", label: "Kelly %" },
  { value: "normalizedExpectancypercent", label: "Normalized Expectancy %" },
  { value: "ulcerIndex", label: "Ulcer Index" },
  { value: "sharpeRatioGeo", label: "Sharpe Ratio (Geometric)" },
  { value: "sortinoRatioGeo", label: "Sortino Ratio (Geometric)" },
  {
    value: "probabilityOfRandomChance",
    label: "Probability of Random Chance",
  },
  { value: "zScore", label: "Z-Score" },
  { value: "LRCorrelation", label: "LR Correlation" },
  { value: "LRStandardError", label: "LR Standard Error" },
  { value: "KRatio", label: "K-Ratio" },
];
export const MiscMetricsPROPSPercentArith = [
  { value: "percentprofitFactor", label: "Profit Factor %" },
  { value: "recoveryFactor", label: "Recovery Factor" },
  { value: "returnOverMaxDrawdown", label: "Return Over Max Drawdown" },
  { value: "percentprofitLossRatio", label: "Profit/Loss Ratio %" },

  { value: "percentCPCIndex", label: "CPC Index %" },
  { value: "expectation", label: "Expectation" },
  { value: "kellypercent", label: "Kelly %" },
  { value: "normalizedExpectancypercent", label: "Normalized Expectancy %" },
  { value: "ulcerIndex", label: "Ulcer Index" },
  { value: "sharpeRatioArith", label: "Sharpe Ratio (Arithmetic)" },
  { value: "sortinoRatioArith", label: "Sortino Ratio (Arithmetic)" },
  {
    value: "probabilityOfRandomChance",
    label: "Probability of Random Chance",
  },
  { value: "zScore", label: "Z-Score" },
  { value: "LRCorrelation", label: "LR Correlation" },
  { value: "LRStandardError", label: "LR Standard Error" },
  { value: "KRatio", label: "K-Ratio" },
];
export const MiscMetricsPROPSPercentGeo = [
  { value: "percentprofitFactor", label: "Profit Factor %" },
  { value: "recoveryFactor", label: "Recovery Factor" },
  { value: "returnOverMaxDrawdown", label: "Return Over Max Drawdown" },
  { value: "percentprofitLossRatio", label: "Profit/Loss Ratio %" },

  { value: "percentCPCIndex", label: "CPC Index %" },
  { value: "expectation", label: "Expectation" },
  { value: "kellypercent", label: "Kelly %" },
  { value: "normalizedExpectancypercent", label: "Normalized Expectancy %" },
  { value: "ulcerIndex", label: "Ulcer Index" },
  { value: "sharpeRatioGeo", label: "Sharpe Ratio (Geometric)" },
  { value: "sortinoRatioGeo", label: "Sortino Ratio (Geometric)" },
  {
    value: "probabilityOfRandomChance",
    label: "Probability of Random Chance",
  },
  { value: "zScore", label: "Z-Score" },
  { value: "LRCorrelation", label: "LR Correlation" },
  { value: "LRStandardError", label: "LR Standard Error" },
  { value: "KRatio", label: "K-Ratio" },
];

export const metricsPropsTooltips = [
  {
    label:
      "The Profit Factor relates the amount of profit per unit of risk. Values greater than 1 indicate a profitable trading system. Values 1.75 and higher are excellent.",
    value: "profitFactor",
    title: "Profit Factor",
    tooltipPlacement: "left",
    calculation: "Sum of All Wins / Sum of All Losses",
  },
  {
    label:
      "The Profit Factor % relates the amount of profit % per unit of risk. Values greater than 1 indicate a profitable trading system. Values 1.75 and higher are excellent.",
    value: "percentprofitFactor",
    title: "Profit Factor %",
    tooltipPlacement: "left",
    calculation: "Sum of All Wins % / Sum of All Losses %",
  },
  {
    label:
      "The Recovery Factor reflects the riskiness of your strategy, i.e. the amount of money risked to make the total profits.",
    value: "recoveryFactor",
    title: "Recovery Factor",
    tooltipPlacement: "left",
    calculation: "Total P/L / Maximum Drawdown",
  },
  {
    label:
      "Return Over Maximum Drawdown (RoMaD) measures how well you manage risk.",
    value: "returnOverMaxDrawdown",
    title: "Return Over Max Drawdown",
    tooltipPlacement: "left",
    calculation: "Total P/L in %'s/ Maximum Drawdown %",
  },
  {
    label:
      "The profit/loss ratio measures how a trading strategy or system is performing",
    value: "profitLossRatio",
    title: "Profit Loss Ratio",
    tooltipPlacement: "left",
  },
  {
    label:
      "The profit/loss ratio measures how a trading strategy or system is performing",
    value: "percentprofitLossRatio",
    title: "Percent Profit Loss Ratio",
    tooltipPlacement: "left",
  },
  {
    label:
      "CPC Index is a great check for how robust a trading system is. Shoot for values greater than 1.2.",
    value: "CPCIndex",
    title: "CPC Index",
    tooltipPlacement: "left",
    calculation: "Profit Factor * Profit/Loss Ratio * Win Rate",
  },
  {
    label:
      "CPC Index % is a great check for how robust a trading system is. Shoot for values greater than 1.2.",
    value: "percentCPCIndex",
    title: "Percent CPC Index",
    tooltipPlacement: "left",
    calculation: "Profit Factor % * Profit/Loss Ratio % * Win Rate",
  },
  {
    label:
      "The expectation metric determines whether the avg. P/L per trade has an associated high or low risk. Calculated with % averages.",
    value: "expectation",
    title: "Expectation",
    tooltipPlacement: "left",
  },
  {
    label:
      "The Kelly Criterion is used to determine the optimal amount of money to put into a single trade or bet.",
    value: "kellypercent",
    title: "Kelly Percent",
    tooltipPlacement: "left",
  },
  {
    label:
      "Normalized expectancy is the expectation calculated with dollar averages.",
    value: "normalizedExpectancypercent",
    title: "Normalized Expectancy Percent",
    tooltipPlacement: "left",
  },
  {
    label:
      "The Ulcer Index (UI) measures the depth and duration of percentage drawdowns in price from earlier highs. The closer to 0 you can get, the better.",
    value: "ulcerIndex",
    title: "Ulcer Index",
    tooltipPlacement: "left",
  },
  {
    label:
      "The Sharpe ratio adjusts a portfolio's past performance—or expected future performance—for the excess risk that was taken by the investor.",
    value: "sharpeRatioArith",
    title: "Sharpe Ratio Arithmetic ",
    tooltipPlacement: "left",
  },
  {
    label:
      "The Sortino ratio is a variation of the Sharpe ratio that differentiates harmful volatility from total overall volatility by using the asset's standard deviation of negative portfolio returns",
    value: "sortinoRatioArith",
    title: "Sortino Ratio Arithmetic",
    tooltipPlacement: "left",
  },
  {
    label:
      " Geometric mean of returns is the cumulative return scaled by the time period length.",
    value: "sharpeRatioGeo",
    title: "Sharpe Ratio (Geometric)",
    tooltipPlacement: "left",
  },
  {
    label:
      " Geometric mean of returns is the cumulative return scaled by the time period length.",
    value: "sortinoRatioGeo",
    title: "Sortino Ratio (Geometric)",
    tooltipPlacement: "left",
  },
  {
    value: "probabilityOfRandomChance",
    label: "Probability of something random happening",
    title: "Probability of Random Chance",
    tooltipPlacement: "left",
  },
  {
    value: "zScore",
    label:
      "Z-score indicates how much a given value differs from the standard deviation.",
    title: "Z-score",
    tooltipPlacement: "left",
  },
  {
    value: "LRCorrelation",
    label:
      "Linear regression is one of the most common techniques of regression analysis when there are only two variables.",
    title: "Linerar Regression Correlation",
    tooltipPlacement: "left",
  },
  {
    value: "LRStandardError",
    label:
      "The standard error is an absolute gauge between the sample survey and the total population.",
    title: "Linerar Regression Standard Error",
    tooltipPlacement: "left",
  },
  {
    value: "KRatio",
    label:
      "K-ratios measure an equity’s consistency of returns over time, calculated using the value-added monthly index (VAMI).",
    title: "K Ratio",
    tooltipPlacement: "left",
  },
];

// Label only
export const MiscMetricsLabelsArith = (symbol) => {
  return [
    `Profit Factor ${he.decode(String(symbol))}`,
    `Recovery Factor`,
    "Return Over Max Drawdown",
    `Profit/Loss Ratio ${he.decode(String(symbol))}`,
    `CPC Index ${he.decode(String(symbol))}`,
    "Expectation",
    "Kelly %",
    "Normalized Expectancy %",
    "Ulcer Index",
    "Sharpe Ratio (Arithmetic)",
    "Sortino Ratio (Arithmetic)",
    "Probability of Random Chance",
    "Z-Score",
    "LR Correlation",
    "LR Standard Error",
    "K-Ratio",
  ];
};
export const MiscMetricsLabelsGeo = (symbol) => {
  return [
    `Profit Factor ${he.decode(String(symbol))}`,
    "Recovery Factor",
    "Return Over Max Drawdown",
    `Profit/Loss Ratio ${he.decode(String(symbol))}`,
    `CPC Index ${he.decode(String(symbol))}`,
    "Expectation",
    "Kelly %",
    "Normalized Expectancy %",
    "Ulcer Index",
    "Sharpe Ratio (Geometric)",
    "Sortino Ratio (Geometric)",
    "Probability of Random Chance",
    "Z-Score",
    "LR Correlation",
    "LR Standard Error",
    "K-Ratio",
  ];
};
export const MiscMetricsLabelspercentArith = [
  "Profit Factor %",
  "Recovery Factor",
  "Return Over Max Drawdown",
  "Profit/Loss Ratio %",
  "CPC Index %",
  "Expectation",
  "Kelly %",
  "Normalized Expectancy %",
  "Ulcer Index",
  "Sharpe Ratio (Arithmetic)",
  "Sortino Ratio (Arithmetic)",
  "Probability of Random Chance",
  "Z-Score",
  "LR Correlation",
  "LR Standard Error",
  "K-Ratio",
];
export const MiscMetricsLabelspercentGeo = [
  "Profit Factor %",
  "Recovery Factor",
  "Return Over Max Drawdown",
  "Profit/Loss Ratio %",
  "CPC Index %",
  "Expectation",
  "Kelly %",
  "Normalized Expectancy %",
  "Ulcer Index",
  "Sharpe Ratio (Geometric)",
  "Sortino Ratio (Geometric)",
  "Probability of Random Chance",
  "Z-Score",
  "LR Correlation",
  "LR Standard Error",
  "K-Ratio",
];
export default MiscMetricsPROPS;
