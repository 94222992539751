import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import ComprehensiveComponent from "./Component";
import "./OverallReportStyles.scss";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const OverallReport = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  let showINIT = false;
  // Tiering Logic
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = false;
  } else if (tier === "oldpro" && active) {
    showINIT = false;
  } else if (tier === "master" && active) {
    showINIT = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
  } else {
    showINIT = true;
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect: "Advanced reports",
    blur: showINIT,
  });

  useEffect(() => {
    if (tier === "free") {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "pro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "oldpro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "master" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "ultimate" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "admin" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    }
  }, [tier, active]);

  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Comprehensive - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <ComprehensiveComponent
        allData={processedData}
        tabName={"Comprehensive"}
        showUpgradeModal={showUpgradeModal}
        pageName={"Comprehensive"}
        tier={tier}
        active={active}
      />
    );
  }
};

OverallReport.defaultProps = {
  tabName: "Comprehensive",
  pageName: "Comprehensive",
};

OverallReport.propTypes = {
  tabName: PropTypes.string,
};

export default OverallReport;
