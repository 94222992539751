import React /* , { useState } */ from "react";
import { Bar } from "react-chartjs-2";
import isArray from "./../../functions/arrayFunctions/isArray";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import getEntryExitLots from "./../../functions/getEntryExitLots";

var he = require("he");
let decor = (v, i) => [v, i]; // set index to value
let undecor = (a) => a[1]; // leave only index
let argsort = (arr) => arr.map(decor).sort().map(undecor);
const PnLByTradeInfoGraph = (props) => {
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const proptoGraph = props.graph;
  const title = props.title;
  const symbol = props.defaultSymbol;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let allProps = [];
  const equityvaluefunction = () => {
    let yaxisarray = [];
    let cumulativereturnarray = [];
    for (var i = 0, j = entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        // Add new props
        const individualProp =
          proptoGraph === "symbol"
            ? entries[i].entry["symbol"].symbols[0]
            : entries[i].entry[proptoGraph];

        if (individualProp === "") {
        } else {
          if (isArray(individualProp)) {
            for (var iii = 0, jjj = individualProp.length; iii < jjj; iii++) {
              if (!allProps.includes(individualProp[iii])) {
                allProps.push(individualProp[iii]);
              }
            }
          } else {
            if (!allProps.includes(individualProp)) {
              allProps.push(individualProp);
            }
          }
        }
        // get index of sorted
        if (individualProp === "") {
        } else {
          if (isArray(individualProp)) {
            for (var ii = 0, jj = individualProp.length; ii < jj; ii++) {
              const index = allProps.indexOf(individualProp[ii]);
              yaxisarray.push(0); // creates a new item in the array first, so we have the index
              let specificvalue = yaxisarray[index];
              let addthis = Number(correctedSUM) + Number(specificvalue);
              yaxisarray[index] = addthis;
              cumulativereturnarray = yaxisarray;
            }
          } else {
            const index = allProps.indexOf(individualProp);
            yaxisarray.push(0); // creates a new item in the array first, so we have the index
            let specificvalue = yaxisarray[index];
            let addthis = Number(correctedSUM) + Number(specificvalue);
            yaxisarray[index] = addthis;
            cumulativereturnarray = yaxisarray;
          }
        }
      }
    }
    return cumulativereturnarray;
  };

  let yaxisvaluesInit = equityvaluefunction();

  // create a mapping array like [3,1,0,2, etc]
  // to map the yaxis functionvalues to the sorted xvalues
  let mappingArray = argsort(allProps);

  // sort the xvalues
  allProps = allProps.sort((a, b) => (a > b ? 1 : -1));

  // map the yaxis values to the order of the sorted xaxis
  const yaxisvalues = mappingArray.map((index) => yaxisvaluesInit[index]);

  const xaxisvalues = allProps;

  // colors
  const colours = yaxisvalues.map((value) =>
    value < 0 ? "#fa798b" : "#55f8a6"
  );
  const data = {
    labels: xaxisvalues,
    datasets: [
      {
        label: "P/L",
        fill: false,
        lineTension: 0.1,
        backgroundColor: colours,
        borderColor: colours,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colours,
        pointBackgroundColor: colours,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colours,
        pointHoverBorderColor: colours,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: yaxisvalues,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: (tooltipItems) => {
          return `${tooltipItems[0].xLabel} P/L: `;
        },
        label: (tooltipItems) => {
          return supportedCryptosCheck
            ? parseFloat(tooltipItems.yLabel.toFixed(3)) + " " + symbol
            : he.decode(symbol) + parseFloat(tooltipItems.yLabel.toFixed(3));
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            beginAtZero: true,
            callback: function (value) {
              return supportedCryptosCheck
                ? value + " " + symbol
                : he.decode(symbol) + value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "P/L",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: capitalizeFirstLetter(title),
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };

  return (
    <div className="plbytimegraph noselect">
      <div className="dashboard-graphs-label">{`P/L By ${title}`}</div>

      {/*       <div className="cummulativereturn-toggleindi">
        {!dateToggle ? "Sorted By First Entry" : "Sorted By Last Exit"}
      </div> */}

      <div className="cumulativereturngraphwrapper">
        <Bar
          data={data}
          options={options}
          className="cumulativereturngraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              //const index = elems[0]._index;
              //const filterData = xaxisvalues[index];
            }
          }}
        />
      </div>
    </div>
  );
};

export default PnLByTradeInfoGraph;
