import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { showPerformanceMetricTable } from "./functions";
import "./PerformanceMetricsStyles.scss";
import TIQIndexGraph from "./TradersIQIndexGraph";
import IndexGraph from "./MetricGraph";
import useForceUpdate from "../../hooks/useForceUpdate";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { useHistory } from "react-router-dom";
import {
  MiscMetricsPROPS,
  MiscMetricsPROPSDollarArith,
  MiscMetricsPROPSPercentGeo,
  MiscMetricsPROPSDollarGeo,
  MiscMetricsPROPSPercentArith,
  MiscMetricsLabelsArith,
  MiscMetricsLabelsGeo,
  MiscMetricsLabelspercentArith,
  MiscMetricsLabelspercentGeo,
  metricsPropsTooltips,
} from "./arrays";
import SelectGraph from "./SelectGraph";
import $ from "jquery";
import { DataContext } from "../../DataContext";
import { OverlayTrigger, Popover } from "react-bootstrap";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

window.jQuery = $;
require("jquery-ui/ui/version");
require("jquery-ui/ui/plugin");
require("jquery-ui/ui/widget");
require("jquery-ui/ui/widgets/mouse");
require("jquery-ui/ui/widgets/sortable");

var he = require("he");
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PerformanceMetrics = (props) => {
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const settings = allData.portfolio.settings;
  const calculations = allData.calculations;
  const riskFreeRate = props.riskFreeRate;
  let showINIT = true;
  // Tiering Logic
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = false;
  } else if (tier === "oldpro" && active) {
    showINIT = false;
  } else if (tier === "master" && active) {
    showINIT = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
  } else {
    showINIT = true;
  }

  /// LOCAL STORAGE VARIABLES
  var localgraphs = JSON.parse(localStorage.getItem("performanceMetricGraphs"));
  var localdollarpercent = JSON.parse(
    localStorage.getItem("performanceMetricDollarPercent")
  );
  var localarithgeo = JSON.parse(
    localStorage.getItem("performanceMetricArithGeo")
  );
  var isDBRunning = localStorage.getItem("dbSetRunning");

  /// -------------------------
  const graphs = settings.performanceMetricGraphs;
  const currencySymbol = settings.currencySymbol;
  /*   const displayTypeINIT =
    settings.performanceMetricCalculationType === "Dollar" ? false : true; */
  /*   const displayAriGeoTypeINIT =
    settings.performanceMetricCalculationAriGeo === "Arithmetic" ? false : true;
 */
  const displayTypeINIT =
    localdollarpercent !== null
      ? localdollarpercent
      : settings.performanceMetricCalculationType === "Dollar"
      ? false
      : true;
  const displayAriGeoTypeINIT =
    localarithgeo !== null
      ? localarithgeo
      : settings.performanceMetricCalculationAriGeo === "Arithmetic"
      ? false
      : true;
  const displayGraphsINIT =
    localgraphs !== null
      ? localgraphs
      : !settings.performanceMetricGraphs
      ? []
      : settings.performanceMetricGraphs;
  const [displayType, setdisplayType] = useState(displayTypeINIT);
  const [arithGeoType, setArithGeoType] = useState(displayAriGeoTypeINIT);
  const { user /* isAuthenticated */ } = useAuth0();
  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);
  const [perfmetricswidth, setperfmetricswidth] = useState(0);
  const [perfmetricsheight, setperfmetricsheight] = useState(0);
  const history = useHistory();

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const firstUpdate = useRef(true);
  const allGlobalData = useContext(DataContext);

  const forceUpdate = useForceUpdate();
  const [selectedGraphs, setSelectedGraphs] = useState(displayGraphsINIT);
  const saveSorts = useCallback(async () => {
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
    $(".fadeindown").removeClass();
    let parentDiv = [];
    $("#performancemetricgraphswrapper > div").each((index, elem) => {
      if (
        elem.id === "noncegraph" ||
        elem.id === "" ||
        elem.id === "scrollbuttonsPM" ||
        elem.id === "invisible"
      ) {
      } else {
        parentDiv = parentDiv.concat(elem.id);
      }
    });
    // LOCAL OBJECT
    localStorage.setItem("performanceMetricGraphs", JSON.stringify(parentDiv));
    //await editSettings(finalPush);
  }, []);

  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localgraphs2 = JSON.parse(
      localStorage.getItem("performanceMetricGraphs")
    );
    var localdollarpercent2 = JSON.parse(
      localStorage.getItem("performanceMetricDollarPercent")
    );
    var localarithgeo2 = JSON.parse(
      localStorage.getItem("performanceMetricArithGeo")
    );
    const check1 = !settings.performanceMetricGraphs
      ? []
      : settings.performanceMetricGraphs;
    const check2 =
      settings.performanceMetricCalculationType === "Dollar" ? false : true;
    const check3 =
      settings.performanceMetricCalculationAriGeo === "Arithmetic"
        ? false
        : true;
    let propsToChange = {
      performanceMetricCalculationType:
        localdollarpercent2 !== null
          ? localdollarpercent2 === false
            ? "Dollar"
            : "Percent"
          : check1,
      performanceMetricCalculationAriGeo:
        localarithgeo2 !== null
          ? localarithgeo2 === false
            ? "Arithmetic"
            : "Geometric"
          : check2,
      performanceMetricGraphs: localgraphs2 !== null ? localgraphs2 : check3,
    };
    let globalpropsToChange = {};
    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChange,
      globalpropsToChange,
      settings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    allGlobalData.updateAllData(finalPush);
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, settings, allGlobalData, user]);

  useEffect(() => {
    const perfmetricswrappermain = document.getElementById(
      "performancemetricomponentwrapper"
    );
    const resizeObserver = new ResizeObserver((entries) => {
      const itemWidth = entries[0].contentRect.width;
      const itemHeight = entries[0].contentRect.height;
      setperfmetricswidth(itemWidth);
      setperfmetricsheight(itemHeight);
    });
    perfmetricswrappermain && resizeObserver.observe(perfmetricswrappermain);
    let graphLength = graphs.length;
    let selectedgraphLength = !selectedGraphs ? [] : selectedGraphs.length;
    // checks every 200ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleCallSetJSON();
      } else {
      }
    }, 200);
    // check if scrolled to the top and show scroll down button
    var youter = $("#performancemetricgraphswrapper").scrollTop();
    if (youter === 0) {
      $("#scrolldownbuttonPM").show();
    }

    // if number of graphs is equal to or less than 2, hide the scroll buttons
    if (graphLength <= 1 || selectedgraphLength <= 1) {
      $("#scrollupbuttonPM").hide();
      $("#scrolldownbuttonPM").hide();
    }

    // handle graph scrolling showing scroll buttons
    $("#performancemetricgraphswrapper").scroll(function (e) {
      var y = $("#performancemetricgraphswrapper").scrollTop();
      if (y > 275) {
        $("#scrollupbuttonPM").fadeIn(400);
      } else {
        $("#scrollupbuttonPM").fadeOut(400);
      }
      if (
        $("#performancemetricgraphswrapper").scrollTop() +
          $("#performancemetricgraphswrapper").innerHeight() >=
        $("#performancemetricgraphswrapper")[0].scrollHeight - 275
      ) {
        $("#scrolldownbuttonPM").fadeOut(400);
      } else {
        $("#scrolldownbuttonPM").fadeIn(400);
      }
    });
    // make it sortable
    $(function () {
      $(".performancemetricgraphswrapper").sortable({
        stop: () => saveSorts(),
        zIndex: 2147483647,
        axis: "y",
        items: ".perftopwrapper",
        scroll: true,
        scrollSensitivity: 180,
        cancel: ".closemetricgraphbutton, .metricWrapper",
        revert: 200,
      });
    });
    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    setSelectedGraphs,
    graphs,
    selectedGraphs,
    saveSorts,
    callcounter,
    finalStateTimeChanged,
    handleCallSetJSON,
    allData,
    localarithgeo,
    localdollarpercent,
    localgraphs,
    settings,
    isDBRunning,
  ]);

  const createMetricArray = (arr) => {
    let temparr = [];
    for (let i = 0, j = arr.length; i < j; i++) {
      if (calculations[arr[i].value] === "-" || !calculations[arr[i].value]) {
        temparr = temparr.concat(calculations[arr[i].value]);
      } else {
        if (
          arr[i].value === "kellypercent" ||
          arr[i].value === "normalizedExpectancypercent" ||
          arr[i].value === "probabilityOfRandomChance"
        ) {
          temparr = temparr.concat(calculations[arr[i].value].toFixed(3) + "%");
        } else {
          temparr = temparr.concat(calculations[arr[i].value].toFixed(3));
        }
      }
    }
    return temparr;
  };
  const miscMetricsArith = createMetricArray(MiscMetricsPROPSDollarArith);
  const miscMetricspercentArith = createMetricArray(
    MiscMetricsPROPSPercentArith
  );
  const miscMetricsGeo = createMetricArray(MiscMetricsPROPSDollarGeo);
  const miscMetricspercentGeo = createMetricArray(MiscMetricsPROPSPercentGeo);

  // handle which metrics to displayu based on the toggle button settings
  const displayfunction = (propz, x, y) => {
    let metrictabletodisplay = "";
    if (count !== 0) {
      metrictabletodisplay =
        x === false && y === false
          ? showPerformanceMetricTable(
              miscMetricsArith,
              MiscMetricsLabelsArith(currencySymbol),
              selectedGraphs,
              MiscMetricsPROPSDollarArith
            )
          : x === true && y === false
          ? showPerformanceMetricTable(
              miscMetricspercentArith,
              MiscMetricsLabelspercentArith,
              selectedGraphs,
              MiscMetricsPROPSPercentArith
            )
          : x === true && y === true
          ? showPerformanceMetricTable(
              miscMetricspercentGeo,
              MiscMetricsLabelspercentGeo,
              selectedGraphs,
              MiscMetricsPROPSPercentGeo
            )
          : showPerformanceMetricTable(
              miscMetricsGeo,
              MiscMetricsLabelsGeo(currencySymbol),
              selectedGraphs,
              MiscMetricsPROPSDollarGeo
            );
    } else {
      metrictabletodisplay =
        propz.portfolio.settings.performanceMetricCalculationType ===
          "Dollar" &&
        propz.portfolio.settings.performanceMetricCalculationAriGeo ===
          "Arithmetic" //SETTINGS PROP DONT FORGET!!!!!
          ? showPerformanceMetricTable(
              miscMetricsArith,
              MiscMetricsLabelsArith(currencySymbol),
              selectedGraphs,
              MiscMetricsPROPSDollarArith
            )
          : propz.portfolio.settings.performanceMetricCalculationType ===
              "Percent" &&
            propz.portfolio.settings.performanceMetricCalculationAriGeo ===
              "Arithmetic"
          ? showPerformanceMetricTable(
              miscMetricspercentArith,
              MiscMetricsLabelspercentArith,
              selectedGraphs,
              MiscMetricsPROPSPercentArith
            )
          : propz.portfolio.settings.performanceMetricCalculationType ===
              "Percent" &&
            propz.portfolio.settings.performanceMetricCalculationAriGeo ===
              "Geometric"
          ? showPerformanceMetricTable(
              miscMetricspercentGeo,
              MiscMetricsLabelspercentGeo,
              selectedGraphs,
              MiscMetricsPROPSPercentGeo
            )
          : showPerformanceMetricTable(
              miscMetricsGeo,
              MiscMetricsLabelsGeo(currencySymbol),
              selectedGraphs,
              MiscMetricsPROPSDollarGeo
            );
    }
    return metrictabletodisplay;
  };

  const addGraphsFunction = () => {
    let neededGraphs =
      selectedGraphs === undefined ? [] : selectedGraphs.slice();
    const selectOptions = MiscMetricsPROPS;
    const newoptions = selectOptions.filter((item) => {
      for (let i = 0, j = neededGraphs.length; i < j; i++) {
        if (item.value === neededGraphs[i]) {
          return false;
        }
      }
      return true;
    });
    let neededGraphs2 =
      selectedGraphs === undefined
        ? []
        : selectedGraphs[0] === ""
        ? []
        : selectedGraphs;
    if (neededGraphs2[0] === undefined) {
      if (count2 === 0) {
        neededGraphs = [];
      } else {
      }
    }
    const innerClick = async () => {
      setCount2(count2 + 1);
      setSelectedGraphs(neededGraphs);
    };
    const handleAddGraphClick = async () => {
      if (tier === "free") {
      } else if (tier === "pro" && active) {
        neededGraphs.push("");
        $(".fadeindown").removeClass();

        $("#performancemetricgraphswrapper").animate(
          {
            scrollTop: $("#performancemetricgraphswrapper").prop(
              "scrollHeight"
            ),
          },
          800
        );
        innerClick();
      } else if (tier === "oldpro" && active) {
        neededGraphs.push("");
        $(".fadeindown").removeClass();

        $("#performancemetricgraphswrapper").animate(
          {
            scrollTop: $("#performancemetricgraphswrapper").prop(
              "scrollHeight"
            ),
          },
          800
        );
        innerClick();
      } else if (tier === "master" && active) {
        neededGraphs.push("");
        $(".fadeindown").removeClass();

        $("#performancemetricgraphswrapper").animate(
          {
            scrollTop: $("#performancemetricgraphswrapper").prop(
              "scrollHeight"
            ),
          },
          800
        );
        innerClick();
      } else if (tier === "ultimate" && active) {
        neededGraphs.push("");
        $(".fadeindown").removeClass();

        $("#performancemetricgraphswrapper").animate(
          {
            scrollTop: $("#performancemetricgraphswrapper").prop(
              "scrollHeight"
            ),
          },
          800
        );
        innerClick();
      } else if (tier === "admin" && active) {
        neededGraphs.push("");
        $(".fadeindown").removeClass();

        $("#performancemetricgraphswrapper").animate(
          {
            scrollTop: $("#performancemetricgraphswrapper").prop(
              "scrollHeight"
            ),
          },
          800
        );
        innerClick();
      } else {
      }
    };
    let neededGraphNames = [];
    for (var iy = 0, jy = neededGraphs.length; iy < jy; iy++) {
      for (var ix = 0, jx = selectOptions.length; ix < jx; ix++) {
        if (
          selectOptions[ix].value === neededGraphs[iy] ||
          neededGraphs[iy] === ""
        ) {
          if (neededGraphs[iy] === "") {
            neededGraphNames.push("");
            break;
          } else {
            neededGraphNames.push(selectOptions[ix].label);
          }
        } else {
        }
      }
    }
    let item = [];
    const neededGraphslength = neededGraphs.length;
    for (var i = 0, j = neededGraphslength; i < j; i++) {
      const thisi = i;
      const thisGraph = neededGraphs[thisi];
      const thisGraphName = neededGraphNames[thisi];

      let neededtooltip = metricsPropsTooltips.filter(
        (x) => x.value === thisGraph
      );
      const divDrag = (
        <div
          key={thisGraphName + thisi}
          className="perftopwrapper noselect draggable"
          id={thisGraph}
        >
          <div
            className="closemetricgraphbutton"
            key={"closemetricgraphbutton" + thisGraph}
            onClick={async () => {
              const currentTimez = Date.now();
              setfinalStateTimeChanged(currentTimez);
              $(window).bind("beforeunload", function () {
                return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
              });
              setcallcounter(1);
              if (neededGraphs.length <= 2) {
                $("#scrollupbuttonPM").fadeOut(400);
                $("#scrolldownbuttonPM").fadeOut(400);
              }
              const index = neededGraphs.indexOf(thisGraph);

              if (index > -1) {
                neededGraphs.splice(index, 1);
                const neededGraphsACTUAL =
                  neededGraphs[0] === undefined ? [] : neededGraphs;
                //Create and send data
                // LOCAL OBJECT

                localStorage.setItem(
                  "performanceMetricGraphs",
                  JSON.stringify(neededGraphsACTUAL)
                );

                setSelectedGraphs(neededGraphsACTUAL);
                forceUpdate();
                //await editSettings(finalPush);
              }
            }}
          ></div>
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: neededGraphslength - thisi,
              position: "relative",
            }}
          >
            <div
              className="indexwrapper grabbable"
              id={thisGraph}
              key={thisGraph}
            >
              <div className="metricWrapper">
                {neededGraphNames[thisi] === "" ? (
                  <div className="tradersiqindexChoosetext noselect">
                    {" "}
                    <SelectGraph
                      options={newoptions}
                      callBack={async (x) => {
                        const currentTimez = Date.now();
                        setfinalStateTimeChanged(currentTimez);
                        $(window).bind("beforeunload", function () {
                          return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
                        });
                        setcallcounter(1);
                        neededGraphs[thisi] = x.value;
                        if (neededGraphs[thisi] === "") {
                        } else {
                          const neededGraphsACTUAL =
                            neededGraphs[0] === undefined ? [] : neededGraphs;
                          setSelectedGraphs(neededGraphsACTUAL);
                          localStorage.setItem(
                            "performanceMetricGraphs",
                            JSON.stringify(neededGraphsACTUAL)
                          );
                          forceUpdate();
                          //await editSettings(finalPush);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="indextext noselect">
                    <div className="radio-label-buy-tooltip">
                      <div className="radio-label-buy-tooltip-tip"></div>
                      {neededtooltip[0].label}
                    </div>
                    {neededGraphNames[thisi]}
                  </div>
                )}
                {neededGraphNames[thisi] === "" ? (
                  ""
                ) : (
                  <div className="indexcalculation noselect">
                    {calculations[neededGraphs[thisi]] === "-"
                      ? "-"
                      : neededGraphs[thisi] === "kellypercent" ||
                        neededGraphs[thisi] === "normalizedExpectancypercent" ||
                        neededGraphs[thisi] === "probabilityOfRandomChance"
                      ? calculations[neededGraphs[thisi]].toFixed(3) + "%"
                      : calculations[neededGraphs[thisi]].toFixed(3)}
                  </div>
                )}
              </div>
              <div className="TIQIndexgraphsuperwrapper">
                <IndexGraph
                  userData={
                    allData.portfolio.entries === undefined
                      ? ""
                      : allData.portfolio.entries
                  }
                  userCalculations={
                    calculations === undefined ? "" : calculations
                  }
                  defaultSymbol={
                    props.defaultSymbol === undefined ? "" : props.defaultSymbol
                  }
                  userPortfolioType={props.userPortfolioType}
                  calculationType={settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  startingDW={allData.calculations.start_DW}
                  neededMetric={thisGraph}
                  neededName={thisGraphName}
                  tier={tier}
                  active={active}
                  riskFreeRate={riskFreeRate}
                />
              </div>
            </div>
          </div>
        </div>
      );
      item.push(divDrag);
    }

    item.push(
      <div
        key={"noncegraph"}
        id="noncegraph"
        className="indexgraphwrapperblank noselect"
        onClick={handleAddGraphClick}
      >
        <div className="indexgraphicon noselect">+</div>
      </div>
    );
    return item;
  };
  const handleCheckChange = async (e, type) => {
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
    if (type === "dollarPercent") {
      setCount(count + 1);
      setdisplayType(e.target.checked);
      localStorage.setItem(
        "performanceMetricDollarPercent",
        JSON.stringify(e.target.checked)
      );

      forceUpdate();
      //await editSettings(finalPush);
    } else {
      setCount(count + 1);
      setArithGeoType(e.target.checked);
      localStorage.setItem(
        "performanceMetricArithGeo",
        JSON.stringify(e.target.checked)
      );
      forceUpdate();
      //await editSettings(finalPush);
    }
  };
  const TIQpopover = (
    <Popover className="popover-basic-custom" id="popover-basic">
      <Popover.Header>{"TradeFuse Index"}</Popover.Header>
      <Popover.Body>
        {
          "The TF Index combines your win rate and risk/reward ratio and normalizes it to 1 so that values greater than one indicate a profitable trading system. It maps a value to fact that a 33% win rate and 2:1 RRR or a 50% win rate and 1:1 RRR etc. is a break-even trader."
        }
      </Popover.Body>
    </Popover>
  );

  return perfmetricswidth <= 1200 ? (
    <div
      className="performancemetricomponentwrapper"
      id="performancemetricomponentwrapper"
    >
      <div className="performance-metrics-tablewrapper">
        <div
          className="performance-metrics-label"
          id="performance-metrics-label"
        >
          KPI's
        </div>
        <div id="perfoutersettings">
          <div
            className="select-calculation-type-perf"
            id="select-calculation-type-perf"
          >
            {he.decode(String(props.defaultSymbol))}
            <label className="switch">
              <input
                disabled={showINIT}
                id="perftogglebutton"
                type="checkbox"
                checked={displayType}
                onChange={(e) => handleCheckChange(e, "dollarPercent")}
              />
              <span className="sliderperf roundperf"></span>
            </label>
            {"%"}
            <div style={{ position: "relative", top: -20, left: 136 }}>
              {/* {premiumTierComponent()} */}
            </div>
          </div>
          <div
            className="select-calculation-airgeo-perf"
            id="select-calculation-airgeo-perf"
          >
            {"Arith."}
            <label className="switch">
              <input
                disabled={showINIT}
                id="perftogglebutton"
                type="checkbox"
                checked={arithGeoType}
                onChange={(e) => handleCheckChange(e, "arithmeticGeometric")}
              />
              <span className="sliderperf roundperf"></span>
            </label>
            {"Geo."}
            <div style={{ position: "relative", top: -20, left: 136 }}>
              {/* {premiumTierComponent()} */}
            </div>
          </div>
        </div>
        <div className="performance-metrics-table">
          <div className="performancemetricol1">
            {displayfunction(allData, displayType, arithGeoType)}
          </div>
        </div>
      </div>
      <div
        className="performancemetricgraphswrapper"
        id="performancemetricgraphswrapper"
      >
        <div id="scrollbuttonsPM" className="fadeindown">
          <div
            id="scrollupbuttonPM"
            onClick={() => {
              $("#performancemetricgraphswrapper").animate(
                { scrollTop: 0 },
                400
              );
              $(".fadeindown").removeClass();
            }}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </div>
          <div
            id="scrolldownbuttonPM"
            onClick={() => {
              $(".fadeindown").removeClass();
              $("#performancemetricgraphswrapper").animate(
                {
                  scrollTop: $("#performancemetricgraphswrapper").prop(
                    "scrollHeight"
                  ),
                },
                500
              );
            }}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
        <div className="TIQperftopwrapper">
          <div
            style={{
              zIndex: 21000000,
              position: "relative",
            }}
            className="tradersiqindexwrapper"
          >
            <div className="TIQmetricWrapper">
              <OverlayTrigger
                placement={"bottom"}
                delay={{ show: 500, hide: 400 }}
                overlay={TIQpopover}
              >
                <div
                  onClick={() =>
                    document
                      .querySelectorAll(".popover-basic-custom")
                      .forEach((e) => (e.style.display = "none"))
                  }
                >
                  <div className="tradersiqindextext">TradeFuse Index</div>
                </div>
              </OverlayTrigger>
              <div className="tradersiqindexcalculation noselect">
                {calculations["tfIndex"]}
              </div>
            </div>

            <div className="TIQIndexgraphsuperwrapper">
              <TIQIndexGraph
                tier={tier}
                active={active}
                userData={
                  allData.portfolio.entries === undefined
                    ? ""
                    : allData.portfolio.entries
                }
                userCalculations={
                  calculations === undefined ? "" : calculations
                }
                defaultSymbol={
                  props.defaultSymbol === undefined ? "" : props.defaultSymbol
                }
                userPortfolioType={props.userPortfolioType}
                calculationType={settings.calculationType}
                startingBalance={allData.calculations.start_Balance}
                startingDW={allData.calculations.start_DW}
              />
            </div>
          </div>
        </div>
        {addGraphsFunction()}
      </div>
    </div>
  ) : (
    <div
      className="performancemetricomponentwrapper"
      id="performancemetricomponentwrapper"
    >
      <div
        className="performancemetricgraphswrapper"
        id="performancemetricgraphswrapper"
      >
        <div id="scrollbuttonsPM" className="fadeindown">
          <div
            id="scrollupbuttonPM"
            onClick={() => {
              $("#performancemetricgraphswrapper").animate(
                { scrollTop: 0 },
                400
              );
              $(".fadeindown").removeClass();
            }}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </div>
          <div
            id="scrolldownbuttonPM"
            onClick={() => {
              $(".fadeindown").removeClass();
              $("#performancemetricgraphswrapper").animate(
                {
                  scrollTop: $("#performancemetricgraphswrapper").prop(
                    "scrollHeight"
                  ),
                },
                500
              );
            }}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
        <div className="TIQperftopwrapper">
          <div
            style={{
              zIndex: 21000000,
              position: "relative",
            }}
            className="tradersiqindexwrapper"
          >
            <div className="TIQmetricWrapper">
              <OverlayTrigger
                placement={"bottom"}
                delay={{ show: 500, hide: 400 }}
                overlay={TIQpopover}
              >
                <div
                  onClick={() =>
                    document
                      .querySelectorAll(".popover-basic-custom")
                      .forEach((e) => (e.style.display = "none"))
                  }
                >
                  <div className="tradersiqindextext">TradeFuse Index</div>
                </div>
              </OverlayTrigger>
              <div className="tradersiqindexcalculation noselect">
                {calculations["tfIndex"]}
              </div>
            </div>

            <div className="TIQIndexgraphsuperwrapper">
              <TIQIndexGraph
                tier={tier}
                active={active}
                userData={
                  allData.portfolio.entries === undefined
                    ? ""
                    : allData.portfolio.entries
                }
                userCalculations={
                  calculations === undefined ? "" : calculations
                }
                defaultSymbol={
                  props.defaultSymbol === undefined ? "" : props.defaultSymbol
                }
                userPortfolioType={props.userPortfolioType}
                calculationType={settings.calculationType}
                startingBalance={allData.calculations.start_Balance}
                startingDW={allData.calculations.start_DW}
              />
            </div>
          </div>
        </div>
        {addGraphsFunction()}
      </div>

      <div className="performance-metrics-tablewrapper">
        <div
          className="performance-metrics-label"
          id="performance-metrics-label"
        >
          KPI's
        </div>
        <div id="perfoutersettings">
          <div
            className="select-calculation-type-perf"
            id="select-calculation-type-perf"
          >
            {he.decode(String(props.defaultSymbol))}
            <label className="switch">
              <input
                disabled={showINIT}
                id="perftogglebutton"
                type="checkbox"
                checked={displayType}
                onChange={(e) => handleCheckChange(e, "dollarPercent")}
              />
              <span className="sliderperf roundperf"></span>
            </label>
            {"%"}
            <div style={{ position: "relative", top: -20, left: 136 }}>
              {/* {premiumTierComponent()} */}
            </div>
          </div>
          <div
            className="select-calculation-airgeo-perf"
            id="select-calculation-airgeo-perf"
          >
            {"Arith."}
            <label className="switch">
              <input
                disabled={showINIT}
                id="perftogglebutton"
                type="checkbox"
                checked={arithGeoType}
                onChange={(e) => handleCheckChange(e, "arithmeticGeometric")}
              />
              <span className="sliderperf roundperf"></span>
            </label>
            {"Geo."}
            <div style={{ position: "relative", top: -20, left: 136 }}>
              {/* {premiumTierComponent()} */}
            </div>
          </div>
        </div>
        <div className="performance-metrics-table">
          <div className="performancemetricol1">
            {displayfunction(allData, displayType, arithGeoType)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceMetrics;
