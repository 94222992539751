import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import moment from "moment/moment";
import { confidence, market, timeFrame } from "./DropDownOptions";
import { theme } from "./../../components/selectTheme";
import { InputCards, CardColumn, CardWrapper } from "./CardandParent";
// import postTradeData from "../../service-calls/postTradeData";
import $ from "jquery";
import "./AddNewTradeStyles.scss";
import { v4 as uuidv4 } from "uuid";
import { setLoaderState } from "../../actions/actionCreators";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import store from "../../store";
import useForceUpdate from "../../hooks/useForceUpdate";
import usePrevious from "../../hooks/usePrevious";
import DeleteButton from "../../components/deleteButton";
import dollarCostAverage from "./../../functions/dollarCostAvg";
import { handleNANGOOD } from "../../functions/handleUndefined";
import profitLossCalculation from "./../../functions/profitLossCalculation";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getOptionsStrategy from "./../../functions/getOptionsStrategy";
import { DataContext } from "../../DataContext";
import { OverlayTrigger, Popover /* , Tooltip */ } from "react-bootstrap";
import Modal from "react-modal";
import isArray from "../../functions/arrayFunctions/isArray";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import uploadPicture from "../../utils/AWS/uploadPicture";
import supportedCryptos from "./../../arrays/isCurrencyCrypto";
import dropdownStyles from "./../../functions/dropdownStyles";
import readContent from "./../../functions/fileReaderPromise";
import { countExerciseAssignIsEqual } from "./../../functions/countExerciseAssign";
import createEquityComponent from "./../../functions/createExecutions";
import { getSpecificPortfolio } from "../../functions/getSpecificPortfolio";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import {
  ProTierComponent,
  MasterTierComponent,
} from "./../../components/tierComponents/pro";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import NoteComponent from "./../../components/NoteComponent";
var he = require("he");

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const AddTradeForm = (props) => {
  const show100TradesCheck = props.show100TradesCheck;
  const showCheck = props.showCheck;
  const allData = props.userData;
  const portfolio = allData.portfolio;
  const allportfolios = allData.allData;
  const assetState = portfolio.assetClasses;
  const entries = portfolio.entries;
  const tier = props.tier;
  const active = props.active;
  const supportedCryptosCheck = supportedCryptos.includes(
    portfolio.settings.currencySymbol
  );
  const verified = 0;
  const tempstatelength =
    assetState === undefined || assetState === null || assetState === ""
      ? 0
      : assetState.length;
  const tempstateINIT =
    assetState === undefined || assetState === null || assetState === ""
      ? ""
      : tempstatelength === 1
      ? assetState[0]
      : "";
  const allGlobalData = useContext(DataContext);
  /*   const [optionmodalIsOpen, setoptionmodalIsOpen] = useState(false);
   */
  const [modalIsOpen, setIsOpen] = useState(false);
  const [exitmodalIsOpen, setexitIsOpen] = useState(false);
  const { user /* isAuthenticated */ } = useAuth0();
  const [picIsOpen, setpicIsOpen] = useState(false);

  const [entrycheckbuffer, setentrycheckbuffer] = useState(0);
  const [tempstate, settempstate] = useState(tempstateINIT);
  const [editCount, seteditCount] = useState(0);
  const [exitcheckbuffer, setexitcheckbuffer] = useState(0);
  const [hasEntrySlippage, sethasEntrySlippage] = useState(false);
  const [hasExitSlippage, sethasExitSlippage] = useState(false);
  const [hasSaveForSymbol, sethasSaveForSymbol] = useState(false);

  const [isDisabled, setisDisabled] = useState(true);
  const [exitExecution, setexitExecution] = useState([]);
  const [exitExecutionToo, setexitExecutionToo] = useState([]);
  const [exitExecutionLast, setexitExecutionLast] = useState([]);
  const [multiExecution, setmultiExecution] = useState([]);
  const [multiExecutionToo, setmultiExecutionToo] = useState([]);
  const [multiExecutionLast, setmultiExecutionLast] = useState([]);
  const [dateTimeNowAdd, setdateTimeNowAdd] = useState({
    dateTimeOpenedSame: "",
    expirationDateSame: "",
    dateTimeClosedSame: "",
    exitExpirationDateSame: "",
  });

  const [picId, setpicId] = useState("");
  const [picData, setPicData] = useState([]);
  const [picCurrent, setPicCurrent] = useState(["N/A"]);
  const picFileArrRef = useRef();
  const picDiv = useRef();
  const fixRound = (num) => {
    return Math.round(num * 1e10) / 1e10;
  };
  const [isExitExecutionDisabled, setIsExitExecutionDisabled] = useState(true);
  const [isExitExecutionDisabled2, setIsExitExecutionDisabled2] =
    useState(true);
  const [isExecutionDisabled, setIsExecutionDisabled] = useState(true);
  const [isExecutionDisabled2, setIsExecutionDisabled2] = useState(true);
  const [optionsStrategy, setoptionsStrategy] = useState("");
  const [inEditMode, setInEditMode] = useState(false);

  const forceUpdate = useForceUpdate();
  //const [optionStrategyState, setoptionStrategyState] = useState(false);
  const [exerciseDisable, setexerciseDisable] = useState();
  const [assignDisable, setassignDisable] = useState();
  const [filterlists, setfilterlists] = useState({});
  const [exercised, setexercised] = useState(null);

  const [assetClassSelect, setassetClassSelect] = useState(
    portfolio.assetClasses.length === 1 ? portfolio.assetClasses[0] : ""
  );

  const [state, setState] = useState({
    entryId: uuidv4(),
    orderType: "",
    legType: "",
    exitlegType: "",
    orderNumber: "",
    selectedSymbol: null,
    selectedStrategy: null,
    selectedConfidence: null,
    selectedEmotion: null,
    selectedMarket: null,
    selectedTimeframe: null,
    selectedMistake: null,
    strikePrice: "",
    expirationDate: "",
    exitstrikePrice: "",
    exitexpirationDate: "",
    lotSize: "",
    exitLotSize: "",
    pointValue: "",
    endDateTime: "",
    startDateTime: "",
    entryPrice: "",
    stopLoss: "",
    takeProfit: "",
    expectedEntry: "",
    profitLoss: "",
    exitPrice: "",
    commissions: "",
    fees: "",
    maxAdEx: "",
    maxFavEx: "",
    expectedExit: "",
    comments: "",
    verifiedLevel: verified,
    chosenPortfolioName: portfolio.name,
    chosenPortfolioAsset: portfolio.assetClasses[0],
    tags: null,
    selectedPhysical: null,
  });
  const firstUpdate = useRef(true);

  const history = useHistory();
  const openModalpic = async () => {
    setpicIsOpen(true);
  };
  const closeModalpic = () => {
    setpicIsOpen(false);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      if (tempstatelength === 1) {
        $(".requiredassetclass").hide();
      }
      settempstate(tempstateINIT);
      // --------------------------
      setfilterlists(allData.filterLists);
      $(function () {
        $("img.uploadPicture").on("click", function () {
          $(this).width(1000);
        });
      });
      var addedType = localStorage.getItem("added");
      if (addedType === "Opened") {
        showConfirmedActionButton("Trade Opened!");
        localStorage.removeItem("added");
      } else if (addedType === "Closed") {
        showConfirmedActionButton("Trade Logged!");
        localStorage.removeItem("added");
      } else {
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      if (firstUpdate.current === true) {
      } else {
      }
      firstUpdate.current = false;
    };
  }, [props, allData, firstUpdate, tempstatelength, tempstateINIT]);
  const {
    entryId,
    orderType,
    legType,
    exitlegType,
    orderNumber,
    selectedSymbol,
    selectedStrategy,
    selectedConfidence,
    selectedEmotion,
    selectedMarket,
    selectedTimeframe,
    selectedMistake,
    lotSize,
    exitLotSize,
    pointValue,
    endDateTime,
    startDateTime,
    strikePrice,
    expirationDate,
    exitstrikePrice,
    exitexpirationDate,
    entryPrice,
    stopLoss,
    takeProfit,
    expectedEntry,
    profitLoss,
    exitPrice,
    commissions,
    fees,
    maxAdEx,
    maxFavEx,
    expectedExit,
    comments,
    chosenPortfolioName,
    chosenPortfolioAsset,
    tags,
    selectedPhysical,
  } = state;
  const prevEntry = usePrevious(expectedEntry);
  const prevExit = usePrevious(expectedExit);

  const {
    dateTimeOpenedSame,
    expirationDateSame,
    dateTimeClosedSame,
    exitExpirationDateSame,
  } = dateTimeNowAdd;
  const prevdateTimeOpenedSame = usePrevious(startDateTime);
  const prevexpirationDateSame = usePrevious(expirationDate);
  const prevdateTimeClosedSame = usePrevious(endDateTime);
  const prevexitExpirationDateSame = usePrevious(exitexpirationDate);

  const pointValueSearch = useCallback(
    (e) => {
      const incomingSymbol = e && e.label;
      let newEntries = entries.slice();

      newEntries = newEntries.filter(
        (trade) => trade.entry.symbol.symbols[0] === incomingSymbol
      );
      let returnSymbol = "";
      if (!incomingSymbol) {
      } else {
        if (!newEntries || !newEntries[0]) {
          returnSymbol = pointValue;
        } else {
          /*           if (newEntries[0].entry.symbol.pointValue === "") {
            returnSymbol = pointValue;
          } else {
            returnSymbol = newEntries[0].entry.symbol.pointValue;
          } */
          returnSymbol = newEntries[0].entry.symbol.pointValue;
        }
      }
      return returnSymbol;
    },
    [pointValue, entries]
  );
  const handleExecutionDisable = useCallback(() => {
    const multilength = !multiExecution[0] ? 1 : multiExecution.length;
    for (let ii = 0, j = multilength; ii < j; ii++) {
      if (
        !multiExecution[0] ||
        !multiExecution[ii].startDateTime ||
        !multiExecution[ii].entryPrice ||
        !multiExecution[ii].lotSize ||
        (tempstate === "Options" &&
          (!multiExecution[ii].strikePrice ||
            !multiExecution[ii].expirationDate)) ||
        (orderType === "Multi-Leg Strategy" && !multiExecution[ii].legType) ||
        $(".lotSizeErrorMsg").html() !== "" ||
        $(".startDateTimeErrorMsg").html() !== "" ||
        (tempstate === "Options" &&
          ($(".expDateTimeErrorMsg").html() !== "" ||
            $(".strikePriceErrorMsg").html() !== "")) ||
        $(".entryPriceErrorMsg").html() !== ""
      ) {
        //setIsExecutionDisabled(true);
      } else {
        if (isExecutionDisabled === false) {
          setIsExecutionDisabled(false);
        } else {
        }
      }
    }
    const exitlength = !exitExecution[0] ? 1 : exitExecution.length;
    for (let ii = 0, j = exitlength; ii < j; ii++) {
      if (
        !exitExecution[0] ||
        !exitExecution[ii].endDateTime ||
        !exitExecution[ii].exitPrice ||
        !exitExecution[ii].exitLotSize ||
        (tempstate === "Options" &&
          (!exitExecution[ii].exitstrikePrice ||
            !exitExecution[ii].exitexpirationDate)) ||
        (orderType === "Multi-Leg Strategy" && !exitExecution[ii].exitlegType)
      ) {
        /*         if (isExitExecutionDisabled === false) {
        } else {
          if (exitlength === 1) {
          } else {
            setIsExitExecutionDisabled(true);
          }
        } */
      } else {
        if (isExitExecutionDisabled === false) {
          setIsExitExecutionDisabled(false);
        } else {
        }
      }
    }

    let checkpass = true;
    let checkpassExit = true;

    const dateTimeRegex =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)\s+([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; //years 1900-2099
    const decimalRegex = /^\d*\.?\d*$/;
    const enddatetemp = moment(endDateTime).format("MM/DD/YYYY HH:mm:ss");
    const startdatetemp = moment(startDateTime).format("MM/DD/YYYY HH:mm:ss");
    const expdatetemp = moment(expirationDate).format("MM/DD/YYYY HH:mm:ss");
    const exitexpdatetemp = moment(exitexpirationDate).format(
      "MM/DD/YYYY HH:mm:ss"
    );

    // ------- Start Date validator -------
    if (
      (!dateTimeRegex.test(startdatetemp) && startdatetemp !== "") ||
      startdatetemp === undefined
    ) {
      checkpass = false;
    } else {
      checkpass = true;
    }
    // ------- Expiration Date validator -------
    if (tempstate === "Options") {
      if (
        (!dateRegex.test(expdatetemp) && expdatetemp !== "") ||
        expdatetemp === undefined
      ) {
        checkpass = false;
      } else {
        checkpass = true;
      }
    }
    // ------- exit Expiration Date validator -------
    if (tempstate === "Options") {
      if (
        (!dateRegex.test(exitexpdatetemp) && exitexpdatetemp !== "") ||
        exitexpdatetemp === undefined
      ) {
        checkpassExit = false;
      } else {
        checkpassExit = true;
      }
    }
    // ------- Lot Size validator -------
    if (
      (!decimalRegex.test(lotSize) && lotSize !== "") ||
      lotSize === undefined
    ) {
      checkpass = false;
    } else {
      checkpass = true;
    }

    // ------- Entry Price validator -------
    if (
      (!decimalRegex.test(entryPrice) && entryPrice !== "") ||
      entryPrice === undefined
    ) {
      checkpass = false;
    } else {
      checkpass = true;
    }
    // ------- Strike Price validator -------
    if (tempstate === "Options") {
      if (
        (!decimalRegex.test(strikePrice) && strikePrice !== "") ||
        strikePrice === undefined
      ) {
        checkpass = false;
      } else {
        checkpass = true;
      }
    }
    // ------- EXIT End Date validator -------
    if (
      (!dateTimeRegex.test(enddatetemp) && endDateTime !== "") ||
      endDateTime === undefined
    ) {
      checkpassExit = false;
    } else {
      checkpassExit = true;
    }

    // ------- EXIT Lot Size validator -------
    if (
      (!decimalRegex.test(exitLotSize) && exitLotSize !== "") ||
      exitLotSize === undefined
    ) {
      checkpassExit = false;
    } else {
      checkpassExit = true;
    }

    // ------- EXIT Entry Price validator -------
    if (!decimalRegex.test(exitPrice) || exitPrice === undefined) {
      checkpassExit = false;
    } else {
      checkpassExit = true;
    }

    // Add Entry Execution Button Validation
    if (
      lotSize === "" ||
      entryPrice === "" ||
      startDateTime === "" ||
      startDateTime === null ||
      (tempstate === "Options" &&
        (strikePrice === "" ||
          expirationDate === "" ||
          expirationDate === null)) ||
      (orderType === "Multi-Leg Strategy" &&
        (legType === "" || legType === false || !legType)) ||
      checkpass === false ||
      ($(".lotSizeErrorMsg").html() !== "" &&
        $(".lotSizeErrorMsg").html() !== undefined) ||
      ($(".startDateTimeErrorMsg").html() !== "" &&
        $(".startDateTimeErrorMsg").html() !== undefined) ||
      (tempstate === "Options" &&
        (($(".expDateTimeErrorMsg").html() !== "" &&
          $(".expDateTimeErrorMsg").html() !== undefined) ||
          ($(".strikePriceErrorMsg").html() !== "" &&
            $(".strikePriceErrorMsg").html() !== undefined))) ||
      ($(".entryPriceErrorMsg").html() !== "" &&
        $(".entryPriceErrorMsg").html() !== undefined)
    ) {
      setIsExecutionDisabled2(true);
    } else {
      setIsExecutionDisabled2(false);
    }

    // Add Exit Execution Button Validation

    if (
      exitLotSize === "" ||
      endDateTime === "" ||
      endDateTime === null ||
      (tempstate === "Options" &&
        (exitstrikePrice === "" ||
          exitexpirationDate === "" ||
          exitexpirationDate === null)) ||
      (orderType === "Multi-Leg Strategy" &&
        (exitlegType === "" || exitlegType === false || !exitlegType)) ||
      checkpassExit === false ||
      ($(".exitlotSizeErrorMsg").html() !== "" &&
        $(".exitlotSizeErrorMsg").html() !== undefined) ||
      ($(".endDateTimeErrorMsg").html() !== "" &&
        $(".endDateTimeErrorMsg").html() !== undefined) ||
      (tempstate === "Options" &&
        (($(".expDateTimeErrorMsg").html() !== "" &&
          $(".expDateTimeErrorMsg").html() !== undefined) ||
          ($(".strikePriceErrorMsg").html() !== "" &&
            $(".strikePriceErrorMsg").html() !== undefined)))
    ) {
      setIsExitExecutionDisabled2(true);
    } else {
      if (exitPrice === "") {
        if (
          (exercised === "Exercised" ||
            exercised === "Assigned" ||
            exercised === "Expired") &&
          $(".exitPriceErrorMsg").html() === "" &&
          $(".exitPriceErrorMsg").html() !== undefined
        ) {
          setIsExitExecutionDisabled2(false);
        } else {
          setIsExitExecutionDisabled2(true);
        }
      } else {
        setIsExitExecutionDisabled2(false);
      }
    }

    // Handling the Assigned and Exercised disabling
    if (tempstate === "Options") {
      if (
        orderType === "Long Call" ||
        orderType === "Long Put" ||
        exitlegType === "Long Call" ||
        exitlegType === "Long Put"
      ) {
        setexerciseDisable(false);
        setassignDisable(true);
      } else if (
        orderType === "Short Call" ||
        orderType === "Short Put" ||
        exitlegType === "Short Call" ||
        exitlegType === "Short Put"
      ) {
        setexerciseDisable(true);
        setassignDisable(false);
      }
    } else {
    }
  }, [
    exercised,
    legType,
    orderType,
    tempstate,
    lotSize,
    exitLotSize,
    entryPrice,
    strikePrice,
    exitstrikePrice,
    exitexpirationDate,
    exitlegType,
    exitPrice,
    startDateTime,
    expirationDate,
    endDateTime,
    multiExecution,
    exitExecution,
    isExecutionDisabled,
    isExitExecutionDisabled,
  ]);

  const validation = useCallback(
    (data, input) => {
      const decimalRegex = /^\d*\.?\d*$/;
      const currencyRegex = /^-?(?:0|[1-9]\d{0,2}(?:,?\d{3})*)(?:\.\d+)?$/;
      const dateRegex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; //years 1900-2099
      const dateTimeRegex =
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)\s+([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;
      const chosenPortfolios = allportfolios.chosenPortfolios;
      if (chosenPortfolios.length !== 2) {
        $("#messageBox").fadeIn(300);
        $("#overlay").fadeIn(300);
        if (allportfolios.createdPortfolios.length === 1) {
          document.getElementById("messageBoxMessage").innerHTML =
            "Please create a portfolio to add a trade.";
        } else {
          document.getElementById("messageBoxMessage").innerHTML =
            "When adding a trade, only one portfolio can be selected.";
        }
      } else {
        // ------- Start Date validator -------
        if (input === "startDateTime") {
          const startdatetemp = moment(data).format("MM/DD/YYYY HH:mm:ss");
          if (
            (!dateTimeRegex.test(startdatetemp) &&
              data !== "" &&
              data !== null) ||
            data === undefined
          ) {
            $(".startDateTimeErrorMsg").css({
              display: "inline",
              right: tempstate === "Options" ? "50%" : "0%",
            });
            $(".startDateTimeErrorMsg").html("Only MM/DD/YYYY HH:mm:ss");
          } else {
            $(".startDateTimeErrorMsg").css({ display: "none" });
            $(".startDateTimeErrorMsg").html("");
          }
        }
        // ------- Expiration Date validator -------
        if (input === "expirationDate") {
          const expdatetemp = moment(data).format("MM/DD/YYYY");
          if (tempstate === "Options") {
            if (
              (!dateRegex.test(expdatetemp) && data !== "" && data !== null) ||
              data === undefined
            ) {
              $(".expDateTimeErrorMsg").css({ display: "inline" });
              $(".expDateTimeErrorMsg").html("Only MM/DD/YYYY");
            } else {
              $(".expDateTimeErrorMsg").css({ display: "none" });
              $(".expDateTimeErrorMsg").html("");
            }
          }
        }
        // ------- Exit Expiration Date validator -------
        if (input === "exitexpirationDate") {
          const expdatetemp = moment(data).format("MM/DD/YYYY");
          if (tempstate === "Options") {
            if (
              (!dateRegex.test(expdatetemp) && data !== "" && data !== null) ||
              data === undefined
            ) {
              $(".exitexpDateTimeErrorMsg").css({ display: "inline" });
              $(".exitexpDateTimeErrorMsg").html("Only MM/DD/YYYY");
            } else {
              $(".exitexpDateTimeErrorMsg").css({ display: "none" });
              $(".exitexpDateTimeErrorMsg").html("");
            }
          }
        }
        // ------- End Date validator -------
        if (input === "endDateTime") {
          const enddatetemp = moment(data).format("MM/DD/YYYY HH:mm:ss");
          if (
            (!dateTimeRegex.test(enddatetemp) &&
              data !== "" &&
              data !== null) ||
            data === undefined
          ) {
            $(".endDateTimeErrorMsg").css({
              display: "inline",
              right: tempstate === "Options" ? "50%" : "0%",
            });
            $(".endDateTimeErrorMsg").html("Only MM/DD/YYYY HH:mm:ss");
          } else {
            $(".endDateTimeErrorMsg").css({ display: "none" });
            $(".endDateTimeErrorMsg").html("");
          }
        }

        // ------- Lot Size validator -------
        if (input === "lotSize") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".lotSizeErrorMsg").css({ display: "inline" });
            $(".lotSizeErrorMsg").html("Numerical values only");
          } else {
            $(".lotSizeErrorMsg").css({ display: "none" });
            $(".lotSizeErrorMsg").html("");
          }
        }

        // ------- Point Value validator -------
        if (input === "pointValue") {
          if (
            tempstate === "Futures" ||
            (portfolio.assetClasses.length === 1 &&
              portfolio.assetClasses[0] === "Futures")
          ) {
            if (!decimalRegex.test(data) && data !== "") {
              $(".pointValueErrorMsg").css({ display: "inline" });
              $(".pointValueErrorMsg").html("Numerical values only");
            } else {
              $(".pointValueErrorMsg").css({ display: "none" });
              $(".pointValueErrorMsg").html("");
            }
          }
        }
        // ------- Entry Price validator -------
        if (input === "entryPrice") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".entryPriceErrorMsg").css({ display: "inline" });
            $(".entryPriceErrorMsg").html("Numerical values only");
          } else {
            $(".entryPriceErrorMsg").css({ display: "none" });
            $(".entryPriceErrorMsg").html("");
          }
        }
        // ------- Strike Price validator -------
        if (input === "strikePrice") {
          if (tempstate === "Options") {
            if (
              (!decimalRegex.test(data) && data !== "") ||
              data === undefined
            ) {
              $(".strikePriceErrorMsg").css({ display: "inline" });
              $(".strikePriceErrorMsg").html("Numerical values only");
            } else {
              $(".strikePriceErrorMsg").css({ display: "none" });
              $(".strikePriceErrorMsg").html("");
            }
          }
        }
        // ------- Stop Loss validator -------
        if (input === "stopLoss") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".stopLossErrorMsg").css({ display: "inline" });
            $(".stopLossErrorMsg").html("Numerical values only");
          } else {
            $(".stopLossErrorMsg").css({ display: "none" });
            $(".stopLossErrorMsg").html("");
          }
        }

        // ------- Take Profit validator -------
        if (input === "takeProfit") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".takeProfitErrorMsg").css({ display: "inline" });
            $(".takeProfitErrorMsg").html("Numerical values only");
          } else {
            $(".takeProfitErrorMsg").css({ display: "none" });
            $(".takeProfitErrorMsg").html("");
          }
        }

        // ------- Profit/Loss validator -------
        if (
          (assetClassSelect === "Forex" ||
            (portfolio.assetClasses.length === 1 &&
              portfolio.assetClasses[0] === "Forex")) &&
          input === "profitLoss"
        ) {
          if (
            (!currencyRegex.test(data) && data !== "") ||
            data === undefined
          ) {
            $(".profitLossErrorMsg").css({ display: "inline" });
            $(".profitLossErrorMsg").html("Currency format only (\u00B1#.##)");
          } else {
            $(".profitLossErrorMsg").css({ display: "none" });
            $(".profitLossErrorMsg").html("");
          }
        }

        // ------- Exit Price validator -------
        if (input === "exitPrice") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".exitPriceErrorMsg").css({ display: "inline" });
            $(".exitPriceErrorMsg").html("Numerical values only");
          } else {
            $(".exitPriceErrorMsg").css({ display: "none" });
            $(".exitPriceErrorMsg").html("");
          }
        }
        if (input === "exitLotSize") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".exitlotSizeErrorMsg").css({ display: "inline" });
            $(".exitlotSizeErrorMsg").html("Numerical values only");
          } else {
            $(".exitlotSizeErrorMsg").css({ display: "none" });
            $(".exitlotSizeErrorMsg").html("");
          }
        }
        // ------- Fees validator -------
        if (input === "fees") {
          if (
            (!currencyRegex.test(data) && data !== "") ||
            data === undefined
          ) {
            $(".feesErrorMsg").css({ display: "inline" });
            $(".feesErrorMsg").html("Currency format only (\u00B1#.##)");
          } else {
            $(".feesErrorMsg").css({ display: "none" });
            $(".feesErrorMsg").html("");
          }
        }

        // ------- Commissions validator -------
        if (input === "commissions") {
          if (
            (!currencyRegex.test(data) && data !== "") ||
            data === undefined
          ) {
            $(".commissionsErrorMsg").css({ display: "inline" });
            $(".commissionsErrorMsg").html("Currency format only (\u00B1#.##)");
          } else {
            $(".commissionsErrorMsg").css({ display: "none" });
            $(".commissionsErrorMsg").html("");
          }
        }
        const multilength = !multiExecution[0] ? 1 : multiExecution.length;
        //const exitlength = !exitExecution[0] ? 1 : exitExecution.length;
        handleExecutionDisable();

        for (let ii = 0, j = multilength; ii < j; ii++) {
          if (
            !multiExecution[0] ||
            !multiExecution[ii].startDateTime ||
            !multiExecution[ii].entryPrice ||
            !multiExecution[ii].lotSize ||
            (tempstate === "Options" &&
              (!multiExecution[ii].strikePrice ||
                !multiExecution[ii].expirationDate)) ||
            (orderType === "Multi-Leg Strategy" &&
              !multiExecution[ii].legType) ||
            (tempstate === "Futures" && !pointValue) ||
            !orderType ||
            !selectedSymbol ||
            (assetState.length !== 1 && !assetClassSelect) ||
            /* !profitLoss ||
            !exitPrice || */
            ($(".lotSizeErrorMsg").html() !== "" &&
              $(".lotSizeErrorMsg").html() !== undefined) ||
            ($(".startDateTimeErrorMsg").html() !== "" &&
              $(".startDateTimeErrorMsg").html() !== undefined) ||
            (tempstate === "Options" &&
              (($(".expDateTimeErrorMsg").html() !== "" &&
                $(".expDateTimeErrorMsg").html() !== undefined) ||
                ($(".strikePriceErrorMsg").html() !== "" &&
                  $(".strikePriceErrorMsg").html() !== undefined))) ||
            ($(".entryPriceErrorMsg").html() !== "" &&
              $(".entryPriceErrorMsg").html() !== undefined) ||
            $(".stopLossErrorMsg").html() !== "" ||
            $(".takeProfitErrorMsg").html() !== "" ||
            (tempstate === "Futures" &&
              $(".pointValueErrorMsg").html() !== "") ||
            ((assetClassSelect === "Forex" ||
              (portfolio.assetClasses.length === 1 &&
                portfolio.assetClasses[0] === "Forex")) &&
              $(".profitLossErrorMsg").html() !== "") ||
            ($(".endDateTimeErrorMsg").html() !== "" &&
              $(".endDateTimeErrorMsg").html() !== undefined) ||
            ($(".exitPriceErrorMsg").html() !== "" &&
              $(".exitPriceErrorMsg").html() !== undefined) ||
            ($(".exitlotSizeErrorMsg").html() !== "" &&
              $(".exitlotSizeErrorMsg").html() !== undefined) ||
            $(".feesErrorMsg").html() !== "" ||
            $(".commissionsErrorMsg").html() !== ""
          ) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
          }
        }
        /*         for (let ii = 0, j = exitlength; ii < j; ii++) {
          if (
            !exitExecution[0] ||
            !exitExecution[ii].endDateTime ||
            !exitExecution[ii].exitPrice ||
            !exitExecution[ii].exitLotSize ||
             !profitLoss ||
            !exitPrice || 
            $(".profitLossErrorMsg").html() !== "" ||
            $(".endDateTimeErrorMsg").html() !== "" ||
            $(".exitPriceErrorMsg").html() !== "" ||
            $(".exitlotSizeErrorMsg").html() !== "" ||
            $(".feesErrorMsg").html() !== "" ||
            $(".commissionsErrorMsg").html() !== ""
          ) {
            setisDisabled(true);
          } else {
            setisDisabled(false);
          }
        } */
      }
    },
    [
      assetClassSelect,
      pointValue,
      orderType,
      selectedSymbol,
      tempstate,
      /*       stopLoss,
      takeProfit, */
      allportfolios.chosenPortfolios,
      allportfolios.createdPortfolios,

      /*       chosenPortfolioName,
      chosenPortfolioAsset, */
      multiExecution,
      assetState,
      portfolio,
      //exitExecution,
      handleExecutionDisable,
    ]
  );

  useEffect(() => {
    validation(endDateTime, "endDateTime");
  }, [endDateTime, validation]);
  useEffect(() => {
    validation(expirationDate, "expirationDate");
  }, [expirationDate, validation]);
  useEffect(() => {
    validation(orderType, "orderType");
  }, [orderType, validation]);
  useEffect(() => {
    validation(pointValue, "pointValue");
  }, [pointValue, validation]);
  useEffect(() => {
    validation(legType, "legType");
  }, [legType, validation]);
  useEffect(() => {
    validation(selectedSymbol, "");
  }, [selectedSymbol, validation]);
  useEffect(() => {
    validation(chosenPortfolioAsset, "");
  }, [chosenPortfolioAsset, validation]);
  useEffect(() => {
    validation(profitLoss, "profitLoss");
  }, [profitLoss, validation]);
  useEffect(() => {
    validation(fees, "fees");
  }, [fees, validation]);
  useEffect(() => {
    validation(commissions, "commissions");
  }, [commissions, validation]);
  useEffect(() => {
    validation(startDateTime, "startDateTime");
  }, [startDateTime, validation]);
  useEffect(() => {
    validation(lotSize, "lotSize");
  }, [lotSize, validation]);
  useEffect(() => {
    validation(entryPrice, "entryPrice");
  }, [entryPrice, validation]);
  useEffect(() => {
    validation(strikePrice, "strikePrice");
  }, [strikePrice, validation]);
  useEffect(() => {
    validation(stopLoss, "stopLoss");
  }, [stopLoss, validation]);
  useEffect(() => {
    validation(takeProfit, "takeProfit");
  }, [takeProfit, validation]);
  useEffect(() => {
    validation(exitPrice, "exitPrice");
  }, [exitPrice, validation]);
  useEffect(() => {
    validation(expirationDate, "exitexpirationDate");
  }, [expirationDate, validation]);
  useEffect(() => {
    validation(exitLotSize, "exitLotSize");
  }, [exitLotSize, validation]);
  useEffect(() => {
    validation(multiExecution, "");
  }, [multiExecution, validation]);
  useEffect(() => {
    validation(exitExecution, "");
  }, [exitExecution, validation]);
  useEffect(() => {
    handleExecutionDisable();
  }, [multiExecution, handleExecutionDisable]);
  useEffect(() => {
    handleExecutionDisable();
  }, [exitExecution, handleExecutionDisable]);

  const pictureslength = picCurrent[0] === "N/A" ? 0 : picCurrent.length;

  // Tiering
  let showINIT = true;
  let showMaster = false;

  if (tier === "free") {
    if (pictureslength >= 1) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "pro" && active) {
    if (pictureslength >= 5) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "oldpro" && active) {
    if (pictureslength >= 10) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "master" && active) {
    if (pictureslength >= 10) {
      showINIT = true;
    } else {
      showINIT = false;
    }
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    if (pictureslength >= 25) {
      showINIT = true;
    } else {
      showINIT = false;
    }
    showMaster = true;
  } else if (tier === "admin" && active) {
    showINIT = false;
    showMaster = true;
  } else {
    if (pictureslength >= 1) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  }

  const handleEntrySlippage = (e) => {
    sethasEntrySlippage(e.target.checked);
    if (!e.target.checked) {
      setState({ ...state, expectedEntry: prevEntry });
    } else {
      setState({ ...state, expectedEntry: entryPrice });
    }
    setentrycheckbuffer(1);
  };

  const handleExitSlippage = (e) => {
    sethasExitSlippage(e.target.checked);
    if (!e.target.checked) {
      setState({ ...state, expectedExit: prevExit });
    } else {
      setState({ ...state, expectedExit: exitPrice });
    }
    setexitcheckbuffer(1);
  };

  const handleImageChange = (pictureFiles) => {
    const filesinputelem = document.getElementById("addpictureinput");
    const fileslargeelem = document.getElementById("addpictureinputlarge");
    const filesInit = fileslargeelem ? fileslargeelem : filesinputelem;
    let picFileArr = filesInit ? [...filesInit.files] : [];

    if (tier === "free") {
      // If the user has 2 pictures, prompt them to upgrade
      if (picFileArr.length + pictureslength > 1) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 1 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    } else if (tier === "pro" && active) {
      // If the user has 5 pictures, prompt them
      if (picFileArr.length + pictureslength > 5) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 5 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    } else if (tier === "oldpro" && active) {
      // If the user has 10 pictures, prompt them
      if (picFileArr.length + pictureslength > 10) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 10 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    } else if (tier === "master" && active) {
      // If the user has 10 pictures, prompt them
      if (picFileArr.length + pictureslength > 10) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 10 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    } else if (tier === "ultimate" && active) {
      // If the user has 25 pictures, prompt them
      if (picFileArr.length + pictureslength > 25) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 25 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    } else if (tier === "admin" && active) {
    } else {
      if (picFileArr.length + pictureslength > 1) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 1 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(picFileArr[i]);
        }
        picFileArr = list.files;
      } else {
      }
    }
    let currentPicsData = picData && picData.slice();
    let currentPics = picCurrent[0] === "N/A" ? [] : [...picCurrent];
    const setPicStates = async (name, type, result, index) => {
      let userKey = user.sub + "::";
      let pictureKey = userKey + name;
      currentPics.push(pictureKey);
      const base64Data = result;
      //console.log("Encoded Base 64 File String:", reader.result);

      /******************* for Binary ***********************/
      /*             var data = base64Data.split(",")[1];
      var binaryBlob = atob(data); */
      //console.log("Encoded Binary File String:", binaryBlob);
      currentPicsData.push({
        base64Data: base64Data,
        pictureKey: pictureKey,
      });
      if (picFileArr.length - 1 === index) {
        picFileArrRef.current = picFileArr;
        setPicData(currentPicsData);
        setPicCurrent(currentPics);
      }
    };
    const filesAsArray = [...picFileArr];

    Promise.all(filesAsArray.map(readContent)).then((files) =>
      Promise.all(
        files.map((file, index) =>
          setPicStates(file.name, file.type, file.content, index)
        )
      )
    );
  };

  const featureSelect = {
    show: true,
    featureSelect: "10 pictures per trade",
    blur: false,
  };
  const featureSelectMaster = {
    show: true,
    featureSelect: "25 pictures per trade",
    blur: false,
    tierText: "master",
  };
  const featureSelectultimate = {
    show: true,
    featureSelect: "100 pictures per trade",
    blur: false,
    tierText: "ultimate",
  };
  const featureSelect2 = {
    show: true,
    featureSelect: "Advanced reports",
    blur: false,
  };
  const featureSelect3 = {
    show: true,
    featureSelect: "Commissions/Fees",
    blur: false,
  };
  const featureSelect4 = {
    show: true,
    featureSelect: "Slippage",
    blur: false,
    tierText: "master",
  };
  const picOnClick = () => {
    if (tier === "free") {
      // If the user has 1 picture, prompt them to upgrade
      if (pictureslength >= 1) {
        props.setshowUpgradeModal(featureSelect);
      } else {
      }
    } else if (tier === "pro" && active) {
      // If the user has 5 pictures, prompt them to upgrade
      if (pictureslength >= 5) {
        props.setshowUpgradeModal(featureSelectMaster);
      } else {
      }
    } else if (tier === "oldpro" && active) {
      // If the user has 10 pictures, prompt them to upgrade
      if (pictureslength >= 10) {
        props.setshowUpgradeModal(featureSelectMaster);
      } else {
      }
    } else if (tier === "master" && active) {
      if (pictureslength >= 10) {
        props.setshowUpgradeModal(featureSelectultimate);
        // in the future this may need to be a limit exceeded warning
      } else {
      }
    } else if (tier === "ultimate" && active) {
      if (pictureslength >= 25) {
        //props.setshowUpgradeModal(featureSelectultimate);
        // in the future this may need to be a limit exceeded warning
      } else {
      }
    } else if (tier === "admin" && active) {
    } else {
      // If the user has 1 picture, prompt them to upgrade
      if (pictureslength >= 1) {
        props.setshowUpgradeModal(featureSelect);
      } else {
      }
    }
  };

  // PICTURES TIER HANDLING
  if (tier === "free") {
    // If the user has 1 picture, prompt them to upgrade
    if (pictureslength >= 1) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  } else if (tier === "pro" && active) {
    if (pictureslength >= 5) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  } else if (tier === "oldpro" && active) {
    if (pictureslength >= 10) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  } else if (tier === "master" && active) {
    if (pictureslength >= 10) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  } else if (tier === "ultimate" && active) {
    if (pictureslength >= 25) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  } else if (tier === "admin" && active) {
    picDiv.current = (
      <input
        disabled={show100TradesCheck ? show100TradesCheck : showINIT}
        multiple
        type="file"
        accept=".png, .jpg, .jpeg"
        className="addpictureinput"
        id="addpictureinput"
        onChange={(e) => {
          handleImageChange(e);
        }}
      />
    );
  } else {
    // If the user has 2 pictures, prompt them to upgrade
    if (pictureslength >= 1) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={show100TradesCheck ? show100TradesCheck : showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      );
    }
  }

  const imageDisplayFunction = () => {
    const picturesArray = picCurrent;

    if (picturesArray[0] === "N/A") {
      return (
        <div className="addtradeshowtradepictureswrapper">
          <div className="nopics">
            <input
              multiple
              type="file"
              accept=".png, .jpg, .jpeg"
              className="addpictureinputlarge"
              id="addpictureinputlarge"
              disabled={show100TradesCheck}
              onChange={(e) => {
                handleImageChange(e);
              }}
            />
            {"Choose Images"}
          </div>
        </div>
      );
    } else {
      let picturesNew = picData?.map(
        (pic) =>
          pic && (
            <span className="innerpicturewrapper" key={uuidv4()}>
              <span>
                <img
                  className="innerpictures"
                  src={pic.base64Data}
                  alt=""
                  height={"80%"}
                  key={uuidv4()}
                  onClick={(e) => {
                    setpicId(pic);
                    openModalpic(true);
                  }}
                />
              </span>
              <span
                className="deletepicbutton"
                onClick={async () => {
                  if (show100TradesCheck) {
                  } else {
                    let currentPicsInner = [...picturesArray];
                    const index = currentPicsInner.indexOf(pic.pictureKey);
                    if (index > -1) {
                      currentPicsInner.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    if (!currentPicsInner.length) {
                      currentPicsInner = ["N/A"];
                    }
                    let picDataState = picData.slice();
                    picDataState = picDataState.filter(
                      (picture) => picture.pictureKey !== pic.pictureKey
                    );
                    setPicData(picDataState);
                    setPicCurrent(currentPicsInner);
                    forceUpdate();
                    // MIGHT NEED TO UNPIN FILES HERE IN THE FUTURE
                  }
                }}
              ></span>
            </span>
          )
      );
      const addPictureButton = (
        <div
          onClick={() => {
            if (show100TradesCheck) {
            } else {
              picOnClick();
            }
          }}
          className="addpicturebutton"
          key="addpicturebutton"
        >
          <div className="addpicturebuttoninner">
            {picDiv.current}
            <div className="addpicturebuttoninnerplus">{"+"}</div>
          </div>
        </div>
      );
      picturesNew.unshift(addPictureButton);
      return (
        <div className="addtradeshowtradepictureswrappernon">{picturesNew}</div>
      );
    }
  };

  const clearMultiExecutionFields = () => {
    setState({
      ...state,
      lotSize: "",
      startDateTime: null,
      entryPrice: "",
      expectedEntry: "",
      strikePrice: "",
      expirationDate: null,
      legType: false,
    });
    var x2 = document.getElementsByName("dateTimeNowAdd");
    x2.forEach((leg) => {
      leg.checked = false;
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      dateTimeOpenedSame: false,
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      expirationDateSame: false,
    });
    var x = document.getElementsByName("legType");
    x.forEach((leg) => {
      leg.checked = false;
    });
    sethasEntrySlippage(false);
  };
  const clearExitExecutionFields = () => {
    setState({
      ...state,
      exitLotSize: "",
      endDateTime: null,
      exitPrice: "",
      expectedExit: "",
      exitstrikePrice: "",
      exitexpirationDate: null,
      exitlegType: false,
    });
    sethasExitSlippage(false);
    setexercised(false);
    var x2 = document.getElementsByName("dateTimeNowAdd");
    x2.forEach((leg) => {
      leg.checked = false;
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      dateTimeClosedSame: false,
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      exitExpirationDateSame: false,
    });
    var x = document.getElementsByName("legType2");
    x.forEach((leg) => {
      leg.checked = false;
    });
    var y = document.getElementsByName("exercised");
    y.forEach((exer) => {
      exer.checked = false;
    });
    var x22 = document.getElementsByName("usesltpforexit");
    x22.forEach((leg) => {
      leg.checked = false;
    });
  };
  const handleAddMultiExecution = () => {
    let execution = {
      id: uuidv4(),
      lotSize: Number(lotSize),
      entryPrice: entryPrice,
      startDateTime: startDateTime,
      expectedEntry: expectedEntry,
      strikePrice: strikePrice,
      expirationDate: expirationDate,
      legType: legType,
    };
    setmultiExecution(multiExecution.concat(execution));
    setmultiExecutionToo(multiExecution.concat(execution));
    clearMultiExecutionFields();
    setIsExecutionDisabled(false);
  };
  const handleDeleteMultiExecution = () => {
    clearMultiExecutionFields();
    if (!multiExecutionLast.length) {
      setIsExecutionDisabled(true);
    } else {
      setIsExecutionDisabled(false);
    }
    setmultiExecution([]);
    setInEditMode(false);
  };
  const handleDeleteMultiExitExecution = () => {
    setexitExecution([]);
    clearExitExecutionFields();
    if (!exitExecutionLast.length) {
      setIsExitExecutionDisabled(true);
    } else {
      setIsExitExecutionDisabled(false);
    }
    setInEditMode(false);
    setexerciseDisable(false);
    setassignDisable(false);
  };
  const addExitExecution = () => {
    let execution = {
      id: uuidv4(),
      exitLotSize: Number(exitLotSize),
      exitPrice: exitPrice,
      endDateTime: endDateTime,
      expectedExit: expectedExit,
      exitstrikePrice: exitstrikePrice,
      exitexpirationDate: exitexpirationDate,
      // if the exitlegtype doesn't exist, the user is not on a multilegstrategy
      // so just set to the original leg type
      exitlegType: !exitlegType || exitlegType === "" ? orderType : exitlegType,
      equityComponents: createEquityComponent(
        exitstrikePrice,
        exitLotSize,
        exercised
      ),
      exercised: exercised,
    };
    setexitExecution(exitExecution.concat(execution));
    setexitExecutionToo(exitExecution.concat(execution));
    clearExitExecutionFields();
    setIsExitExecutionDisabled(false);
  };

  const editExecutions = (execution) => {
    const buyPutLegType = document.getElementById("BuyPut");
    const sellPutLegType = document.getElementById("SellPut");
    const buyCallLegType = document.getElementById("BuyCall");
    const sellCallLegType = document.getElementById("SellCall");

    setInEditMode(true);
    setIsExecutionDisabled(true);
    if (multiExecutionToo.length > 1) {
      setmultiExecution(multiExecution.filter((exec) => exec !== execution));
      if (lotSize === "" || entryPrice === "" || startDateTime === "") {
      } else {
        setmultiExecution(
          multiExecution
            .filter((exec) => exec !== execution)
            .concat({
              id: uuidv4(),
              lotSize: lotSize,
              entryPrice: entryPrice,
              startDateTime: startDateTime,
              expectedEntry: expectedEntry,
              strikePrice: strikePrice,
              expirationDate: expirationDate,
              legType: legType,
            })
        );
      }
      setState({
        ...state,
        lotSize: execution.lotSize,
        entryPrice: execution.entryPrice,
        startDateTime: execution.startDateTime,
        expectedEntry: execution.expectedEntry,
        strikePrice: execution.strikePrice,
        expirationDate: execution.expirationDate,
        legType: execution.legType,
      });
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.legType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.legType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.legType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.legType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
    } else if (multiExecutionToo.length === 1) {
      setState({
        ...state,
        lotSize: execution.lotSize,
        entryPrice: execution.entryPrice,
        startDateTime: execution.startDateTime,
        expectedEntry: execution.expectedEntry,
        strikePrice: execution.strikePrice,
        expirationDate: execution.expirationDate,
        legType: execution.legType,
      });
      setmultiExecution([]);
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.legType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.legType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.legType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.legType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
    } else {
    }
  };

  const editExitExecutions = (execution) => {
    const buyPutLegType = document.getElementById("ExitBuyPut");
    const sellPutLegType = document.getElementById("ExitSellPut");
    const buyCallLegType = document.getElementById("ExitBuyCall");
    const sellCallLegType = document.getElementById("ExitSellCall");

    const exercisedElem = document.getElementById("optionExercised");
    const assignedElem = document.getElementById("optionAssigned");
    const expiredElem = document.getElementById("optionExpired");
    setIsExitExecutionDisabled(true);
    setInEditMode(true);
    if (exitExecutionToo.length > 1) {
      setexitExecution(exitExecution.filter((exec) => exec !== execution));
      if (exitLotSize === "" || endDateTime === "") {
      } else {
        setexitExecution(
          exitExecution
            .filter((exec) => exec !== execution)
            .concat({
              id: uuidv4(),
              exitLotSize: exitLotSize,
              exitPrice: exitPrice,
              endDateTime: endDateTime,
              expectedExit: expectedExit,
              exitstrikePrice: exitstrikePrice,
              exitexpirationDate: exitexpirationDate,
              exitlegType: exitlegType,
              equityComponents: createEquityComponent(
                exitstrikePrice,
                exitLotSize,
                exercised
              ),
              exercised: exercised,
            })
        );
      }
      setState({
        ...state,
        exitLotSize: execution.exitLotSize,
        exitPrice: execution.exitPrice,
        endDateTime: execution.endDateTime,
        expectedExit: execution.expectedExit,
        exitstrikePrice: execution.exitstrikePrice,
        exitexpirationDate: execution.exitexpirationDate,
        exitlegType: execution.exitlegType,
        exercised: execution.exercised,
      });
      // set radio button states
      if (!exercisedElem || !assignedElem) {
      } else {
        if (execution.exercised === "Exercised") {
          exercisedElem.checked = true;
        } else if (execution.exercised === "Assigned") {
          assignedElem.checked = true;
        } else if (execution.exercised === "Expired") {
          expiredElem.checked = true;
        } else if (!execution.exercised) {
          expiredElem.checked = false;
          exercisedElem.checked = false;
          assignedElem.checked = false;
        }
      }
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.exitlegType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.exitlegType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.exitlegType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.exitlegType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
      setexercised(execution.exercised);
    } else if (exitExecutionToo.length === 1) {
      setState({
        ...state,
        exitLotSize: execution.exitLotSize,
        exitPrice: execution.exitPrice,
        endDateTime: execution.endDateTime,
        expectedExit: execution.expectedExit,
        exitstrikePrice: execution.exitstrikePrice,
        exitexpirationDate: execution.exitexpirationDate,
        exitlegType: execution.exitlegType,
      });
      if (!exercisedElem || !assignedElem) {
      } else {
        if (execution.exercised === "Exercised") {
          exercisedElem.checked = true;
        } else if (execution.exercised === "Assigned") {
          assignedElem.checked = true;
        } else if (execution.exercised === "Expired") {
          expiredElem.checked = true;
        } else if (!execution.exercised) {
          expiredElem.checked = false;
          exercisedElem.checked = false;
          assignedElem.checked = false;
        }
      }
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.exitlegType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.exitlegType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.exitlegType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.exitlegType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
      setexitExecution([]);
      setexercised(execution.exercised);
    } else {
    }
  };

  const editExecutionButtons = () => {
    if (multiExecution[0] === "" || !multiExecution.length) {
      return (
        <div style={{ color: "#808080", fontStyle: "italic" }}>
          {orderType === "Multi-Leg Strategy"
            ? `Fill in the required fields then click "Add Leg".`
            : `Fill in the required fields then click "Add Execution".`}
        </div>
      );
    } else {
      let multiExecCurrent = multiExecution.slice();
      multiExecCurrent.sort(function (a, b) {
        var c = new Date(a.startDateTime);
        var d = new Date(b.startDateTime);
        return c - d;
      });
      return multiExecCurrent.map((i) => (
        <span className="executionwrapper" key={uuidv4()}>
          <button
            className="editExecutionButton noselect"
            onClick={() => editExecutions(i)}
          >
            {orderType === "Multi-Leg Strategy" ? i.legType + ", " : ""}
            {moment(i.startDateTime).format("MM/DD/YYYY HH:mm:ss")}
            {", "}
            {i.lotSize}
            {", "}
            {i.entryPrice}
            {tempstate === "Options" &&
              ", " +
                (!i.expirationDate
                  ? ""
                  : moment(i.expirationDate).format("MM/DD/YYYY")) +
                ", " +
                i.strikePrice}
            {", "}
            {i.expectedEntry}
            {/*             <table>
              <tr>
                <td className="editExecutionButtonText">{i.lotSize}</td>
                <td className="editExecutionButtonText">{i.entryPrice}</td>
                <td className="editExecutionButtonText">{i.expectedEntry}</td>
                <td className="editExecutionButtonText">
                  {moment(i.startDateTime).format("MM/DD/YYYY HH:mm:ss")}
                </td>
              </tr>
            </table> */}
          </button>
          <span
            className="deleteExecutionButton"
            onClick={() => handleDeleteExecution(i)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </span>
      ));
    }
  };

  const editExitExecutionButtons = () => {
    if (exitExecution[0] === "" || !exitExecution.length) {
      return (
        <div style={{ color: "#808080", fontStyle: "italic" }}>
          {orderType === "Multi-Leg Strategy"
            ? `Fill in the required fields then click "Add Leg".`
            : `Fill in the required fields then click "Add Execution".`}
        </div>
      );
    } else {
      let exitExecCurrent = exitExecution.slice();
      exitExecCurrent.sort(function (a, b) {
        var c = new Date(a.endDateTime);
        var d = new Date(b.endDateTime);
        return c - d;
      });
      return exitExecCurrent.map((i) => (
        <span className="executionwrapper" key={"buttonexitkey" + i}>
          <button
            className={
              i.exercised === "Expired"
                ? "expiredcolor editExecutionButton noselect"
                : i.exercised === "Exercised"
                ? "exercisedcolor editExecutionButton noselect"
                : i.exercised === "Assigned"
                ? "assignedcolor editExecutionButton noselect"
                : "editExecutionButton noselect"
            }
            onClick={() => editExitExecutions(i)}
          >
            {orderType === "Multi-Leg Strategy" ? i.exitlegType + ", " : ""}
            {moment(i.endDateTime).format("MM/DD/YYYY HH:mm:ss")}
            {", "}
            {i.exitLotSize}
            {", "}
            {i.exitPrice}
            {tempstate === "Options" &&
              ", " +
                (!i.exitexpirationDate
                  ? ""
                  : moment(i.exitexpirationDate).format("MM/DD/YYYY")) +
                ", " +
                i.exitstrikePrice +
                ", " +
                i.exercised}
            {", "}
            {i.expectedExit}
            {/*             <table>
              <tr>
                <td className="editExecutionButtonText">{i.lotSize}</td>
                <td className="editExecutionButtonText">{i.entryPrice}</td>
                <td className="editExecutionButtonText">{i.expectedEntry}</td>
                <td className="editExecutionButtonText">
                  {moment(i.startDateTime).format("MM/DD/YYYY HH:mm:ss")}
                </td>
              </tr>
            </table> */}
          </button>
          <span
            className="deleteExecutionButton"
            onClick={() => handleDeleteExitExecution(i)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </span>
      ));
    }
  };

  const handleDeleteExecution = (execution) => {
    setmultiExecution(multiExecution.filter((exec) => execution !== exec));
    setmultiExecutionToo(
      multiExecutionToo.filter((exec) => execution !== exec)
    );
    if (!multiExecutionLast.length) {
      setIsExecutionDisabled(true);
    } else {
      if (inEditMode === true) {
        setIsExecutionDisabled(true);
      } else {
        setIsExecutionDisabled(false);
      }
    }
    /*     handleExecutionClose();
    alert("execution deleted!"); */
  };
  const handleDeleteExitExecution = (execution) => {
    setexitExecution(exitExecution.filter((exec) => execution !== exec));
    setexitExecutionToo(exitExecutionToo.filter((exec) => execution !== exec));
    if (!exitExecutionLast.length) {
      setIsExitExecutionDisabled(true);
    } else {
      if (inEditMode === true) {
        setIsExitExecutionDisabled(true);
      } else {
        setIsExitExecutionDisabled(false);
      }
    }
    /*     handleExitExecutionClose();
    alert("Exit execution deleted!"); */
  };

  const handleSaveExecutions = () => {
    handleAddMultiExecution();
    clearMultiExecutionFields();
    setInEditMode(false);
  };
  const saveExitExecutions = () => {
    addExitExecution();
    clearExitExecutionFields();
    setInEditMode(false);
  };
  const closeModalOnlyClose = () => {
    setIsOpen(false);
  };

  const handleExecutionClose = (type) => {
    closeModalOnlyClose();
    setInEditMode(false);
    setIsExecutionDisabled(true);
    if (type === "add") {
      setmultiExecutionLast(multiExecution);
      setmultiExecutionToo(multiExecution);
      //let positions = {};
      // CHECK WHICH OPTIONS STRATEGY THE USER CREATED
      const optionsStrategy =
        assetClassSelect === "Options" &&
        getOptionsStrategy(multiExecution, "-", orderType);
      setoptionsStrategy(optionsStrategy);
    } else if (type === "close") {
      setmultiExecution(multiExecutionLast);
      setmultiExecutionToo(multiExecutionLast);
    }

    //setmultiExecutionToo(multiExecution.concat(execution));
    clearMultiExecutionFields();
  };
  const closeExitModalOnlyClose = () => {
    setexitIsOpen(false);
  };
  const handleExitExecutionClose = (type) => {
    const exercisedElem = document.getElementById("optionExercised");
    const assignedElem = document.getElementById("optionAssigned");
    const expiredElem = document.getElementById("optionExpired");
    expiredElem && (expiredElem.checked = false);
    exercisedElem && (exercisedElem.checked = false);
    assignedElem && (assignedElem.checked = false);
    closeExitModalOnlyClose();
    setIsExitExecutionDisabled(true);
    setInEditMode(false);
    setexerciseDisable(false);
    setassignDisable(false);
    if (type === "add") {
      setexitExecutionLast(exitExecution);
      setexitExecutionToo(exitExecution);
    } else if (type === "close") {
      setexitExecution(exitExecutionLast);
      setexitExecutionToo(exitExecutionLast);
    }
    clearExitExecutionFields();
  };

  const createSelectedArray = (arr) => {
    const arrCheck = isArray(arr);
    let createdArray = [];
    arrCheck &&
      arr.forEach((item) => {
        createdArray = createdArray.concat(item.label);
      });
    if (!arrCheck) {
      createdArray = createdArray.concat(arr.label);
    }
    return createdArray;
  };

  // ------- THIS FUNCTION SUBMITS THE TRADE (RUN ON CLICKING THE LOG TRADE BUTTON) -------
  const handleSubmit = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    let picFileArr = picFileArrRef.current;
    const upload = async (name, type, result) => {
      const base64Data = result;
      //console.log("Encoded Base 64 File String:", reader.result);

      /******************* for Binary ***********************/
      /*             var data = base64Data.split(",")[1];
        var binaryBlob = atob(data); */
      //console.log("Encoded Binary File String:", binaryBlob);
      const S3PictureData = {
        data: {
          base64Data: base64Data,
          name: name,
          type: type,
          userId: user.sub,
        },
      };
      await uploadPicture(S3PictureData, type, history);
    };
    const filesAsArray = isArray(picFileArr) ? [...picFileArr] : [];
    const picturesArray = isArray(picFileArr)
      ? picFileArr.map((file) => {
          let userKey = user.sub + "::";
          let pictureKey = userKey + file.name;
          return pictureKey;
        })
      : undefined;

    filesAsArray.length &&
      Promise.all(filesAsArray.map(readContent)).then((files) =>
        Promise.all(
          files.map((file) => upload(file.name, file.type, file.content))
        )
      );
    let startDateTime = "";
    let endDateTimez = "";
    let entryLots = 0;
    let exitLots = 0;
    for (let im = 0, jm = multiExecution.length; im < jm; im++) {
      if (im === 0) {
        startDateTime = multiExecution[im].startDateTime;
      } else {
        startDateTime =
          multiExecution[im].startDateTime < startDateTime
            ? multiExecution[im].startDateTime
            : startDateTime;
      }
      entryLots = entryLots + Number(multiExecution[im].lotSize);
    }
    for (let ie = 0, je = exitExecution.length; ie < je; ie++) {
      if (ie === 0) {
        endDateTimez = exitExecution[ie].endDateTime;
      } else {
        endDateTimez =
          exitExecution[ie].endDateTime > endDateTimez
            ? exitExecution[ie].endDateTime
            : endDateTimez;
      }
      exitLots = exitLots + Number(exitExecution[ie].exitLotSize);
    }

    entryLots = fixRound(entryLots); // fix rounding issue
    exitLots = fixRound(exitLots);

    const startdate = new Date(startDateTime);

    //const optionsUUid = uuidv4();

    let tempAll = allData.allData.chosenPortfolios;
    let tempAllOther = allData.allData.createdPortfolios;
    let temparr = allData.allData.createdPortfolios;

    const portName = getSpecificPortfolio(allData.allData).name;

    temparr = temparr.filter((x) => x.name !== portName);
    tempAllOther = tempAllOther.filter((x) => x.name === portName);

    let mutableTrades = tempAllOther[0].entries;
    const optionEntryId = uuidv4();
    let isOptionLinked = false;
    let exerciseAssignStockPL = 0;

    if (assetClassSelect === "Options") {
      if (entryLots !== exitLots) {
        // if it's an open trade, prompt the user if they want to add the exercise/assign stock
      } else {
        // If the position is fully closed
        // and the exits have the same number fof exercise/assignments, calcualte the pnl between them
        // tghen add to the pnl
        if (countExerciseAssignIsEqual(exitExecution)) {
        } else {
          for (let i = 0, j = exitExecution.length; i < j; i++) {
            const predate = exitExecution[i].endDateTime;
            const optionStartDate = new Date(predate);
            if (exitExecution[i].exercised === "Exercised") {
              isOptionLinked = true;
              if (exitExecution[i].exitlegType === "Long Call") {
                let optionISODate = optionStartDate;
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: Number(exitExecution[i].exitLotSize) * 100,
                  entryPrice: exitExecution[i].exitstrikePrice,
                  startDateTime: optionISODate,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };
                const newdata = {
                  entryId: optionEntryId,
                  entry: {
                    pictures: ["N/A"],
                    symbol: !selectedSymbol
                      ? {}
                      : {
                          symbols: createSelectedArray(selectedSymbol),
                          pointValue: "",
                        },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: chosenPortfolioName,
                    selectedPortfolioType: "Exercise Stock",
                    orderType: "Long",
                    orderNumber: "",
                    dayOfWeek: weekday[optionStartDate.getDay()],
                    monthOfYear: montharr[optionStartDate.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: "",
                    commissions: 0,
                    fees: 0,
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [entryexecution],
                    exitExecution: [],
                    startDateTime: optionISODate,
                    endDateTime: "",
                    tags: [],
                    verifiedLevel: verified,
                    idLinks: [entryId],
                  },
                };
                mutableTrades = mutableTrades.concat(newdata);
              } else if (exitExecution[i].exitlegType === "Long Put") {
                let optionISODate = optionStartDate;
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: Number(exitExecution[i].exitLotSize) * 100,
                  entryPrice: exitExecution[i].exitstrikePrice,
                  startDateTime: optionISODate,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };
                const newdata = {
                  entryId: optionEntryId,
                  entry: {
                    pictures: ["N/A"],
                    symbol: !selectedSymbol
                      ? {}
                      : {
                          symbols: createSelectedArray(selectedSymbol),
                          pointValue: "",
                        },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: chosenPortfolioName,
                    selectedPortfolioType: "Exercise Stock",
                    orderType: "Short",
                    orderNumber: "",
                    dayOfWeek: weekday[optionStartDate.getDay()],
                    monthOfYear: montharr[optionStartDate.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: "",
                    commissions: 0,
                    fees: 0,
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [entryexecution],
                    exitExecution: [],
                    startDateTime: optionISODate,
                    endDateTime: "",
                    tags: [],
                    verifiedLevel: verified,
                    idLinks: [entryId],
                  },
                };
                mutableTrades = mutableTrades.concat(newdata);
              }
            } else if (exitExecution[i].exercised === "Assigned") {
              isOptionLinked = true;
              if (exitExecution[i].exitlegType === "Short Call") {
                let optionISODate = optionStartDate;
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: Number(exitExecution[i].exitLotSize) * 100,
                  entryPrice: exitExecution[i].exitstrikePrice,
                  startDateTime: optionISODate,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };
                const newdata = {
                  entryId: optionEntryId,
                  entry: {
                    pictures: ["N/A"],
                    symbol: !selectedSymbol
                      ? {}
                      : {
                          symbols: createSelectedArray(selectedSymbol),
                          pointValue: "",
                        },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: chosenPortfolioName,
                    selectedPortfolioType: "Assign Stock",
                    orderType: "Short",
                    orderNumber: "",
                    dayOfWeek: weekday[optionStartDate.getDay()],
                    monthOfYear: montharr[optionStartDate.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: "",
                    commissions: 0,
                    fees: 0,
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [entryexecution],
                    exitExecution: [],
                    startDateTime: optionISODate,
                    endDateTime: "",
                    tags: [],
                    verifiedLevel: verified,
                    idLinks: [entryId],
                  },
                };
                mutableTrades = mutableTrades.concat(newdata);
              } else if (exitExecution[i].exitlegType === "Short Put") {
                let optionISODate = optionStartDate;
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: Number(exitExecution[i].exitLotSize) * 100,
                  entryPrice: exitExecution[i].exitstrikePrice,
                  startDateTime: optionISODate,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };
                const newdata = {
                  entryId: optionEntryId,
                  entry: {
                    pictures: ["N/A"],
                    symbol: !selectedSymbol
                      ? {}
                      : {
                          symbols: createSelectedArray(selectedSymbol),
                          pointValue: "",
                        },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: chosenPortfolioName,
                    selectedPortfolioType: "Assign Stock",
                    orderType: "Long",
                    orderNumber: "",
                    dayOfWeek: weekday[optionStartDate.getDay()],
                    monthOfYear: montharr[optionStartDate.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: "",
                    commissions: 0,
                    fees: 0,
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [entryexecution],
                    exitExecution: [],
                    startDateTime: optionISODate,
                    endDateTime: "",
                    tags: [],
                    verifiedLevel: verified,
                    idLinks: [entryId],
                  },
                };
                mutableTrades = mutableTrades.concat(newdata);
              }
            }
          }
        }
      }
    }

    //-----------------------------
    const optionIdLinks = !isOptionLinked ? [] : [optionEntryId];
    const data = {
      entryId: entryId,
      entry: {
        pictures:
          picturesArray === undefined || picturesArray.length === 0
            ? ["N/A"]
            : picturesArray,
        symbol: !selectedSymbol
          ? {}
          : {
              symbols: createSelectedArray(selectedSymbol),
              pointValue:
                assetClassSelect === "Futures" ||
                (portfolio.assetClasses.length === 1 &&
                  portfolio.assetClasses[0] === "Futures")
                  ? !hasSaveForSymbol
                    ? ""
                    : pointValue
                  : "",
            },
        strategy: !selectedStrategy
          ? ""
          : createSelectedArray(selectedStrategy),
        selectedConfidence: !selectedConfidence ? "" : selectedConfidence.label, // only one can be selected
        selectedEmotion: !selectedEmotion
          ? ""
          : createSelectedArray(selectedEmotion),
        selectedPhysical: !selectedPhysical
          ? ""
          : createSelectedArray(selectedPhysical),
        selectedMarket: !selectedMarket ? "" : selectedMarket.label, // only one can be selected
        selectedTimeframe: !selectedTimeframe ? "" : selectedTimeframe.label, // only one can be selected
        selectedMistake: !selectedMistake
          ? ""
          : createSelectedArray(selectedMistake),
        selectedPortfolio: chosenPortfolioName,
        selectedPortfolioType: assetClassSelect,
        orderType: orderType,
        orderNumber: orderNumber,
        dayOfWeek: weekday[startdate.getDay()],
        monthOfYear: montharr[startdate.getMonth()],
        stopLoss: stopLoss,
        takeProfit: takeProfit,
        profitLoss:
          assetClassSelect === "Forex" ||
          (portfolio.assetClasses.length === 1 &&
            portfolio.assetClasses[0] === "Forex")
            ? profitLoss
            : exitLots === 0 //if there are no exit executions, don't log a P/L
            ? ""
            : Number(
                profitLossCalculation(
                  multiExecution,
                  exitExecution,
                  orderType,
                  assetClassSelect,
                  pointValue,
                  false
                )
              ) + Number(exerciseAssignStockPL),
        commissions:
          commissions === "" ? 0 : -1 * Math.abs(Number(commissions)),
        fees: fees === "" ? 0 : Number(fees),
        maxAdEx: maxAdEx,
        maxFavEx: maxFavEx,
        comments: comments,
        multiExecution: multiExecution,
        exitExecution: exitExecution,
        startDateTime: startdate,
        endDateTime: endDateTimez,
        tags: !tags ? null : createSelectedArray(tags),
        verifiedLevel: verified,
        idLinks: optionIdLinks,
      },
    };
    mutableTrades.push(data);

    // -----------------------------------------
    let settingsTemp = tempAllOther[0].settings;
    settingsTemp.verified = 0;
    const portfoliofinal = {
      name: tempAllOther[0].name,
      assetClasses: tempAllOther[0].assetClasses,
      settings: settingsTemp,
      entries: mutableTrades,
    };
    temparr.push(portfoliofinal);
    const uploadData = {
      chosenPortfolios: tempAll,
      createdPortfolios: temparr,
      globalSettings: allData.allData.globalSettings,
      version: Number(allData.allData.version) + 1,
      sharedTrades: allData.allData.sharedTrades,
    };
    const S3Data = {
      data: uploadData,
      userId: user.sub,
    };
    const customerId =
      uploadData === null ? "" : uploadData.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      uploadData,
      "trades",
      user.sub,
      history
    );

    await putUserData(S3Data, history);
    allGlobalData.updateAllData(uploadData);
    setexitExecution([]);
    setmultiExecution([]);
    if (exitExecution.length === 0) {
      localStorage.setItem("added", "Opened");
    } else {
      localStorage.setItem("added", "Closed");
    }
    setTimeout(() => {
      history.push("/BlankPage");
      history.goBack();
    }, 0);
  };
  const movecursor = {
    paddingLeft: "10px",
  };
  /*   const inputProps2 = {
    placeholder: "Optional",
  }; */
  /*   const optionalProp = {
    placeholder: "Optional",
  }; */

  const handleEditExecutions = () => {
    if (portfolio.name !== "Default") {
      setIsOpen(true);
    }
  };
  const handleExitExecutionModal = () => {
    if (portfolio.name !== "Default") {
      setexitIsOpen(true);
    }
  };
  const displayAssetClasses = () => {
    let assetClasses = portfolio.assetClasses;
    let buttonArr = [];
    assetClasses.sort();
    assetClasses.forEach((asset) =>
      buttonArr.push(
        <button
          id={
            assetClasses.length === 1
              ? "assetClassButton_mteonly"
              : asset === assetClassSelect || tempstatelength === 1
              ? "assetClassButton_mteselected"
              : "assetClassButton_mte"
          }
          key={asset}
          name={asset}
          disabled={show100TradesCheck}
          onClick={(e) => {
            const propName = e.target.name;
            setassetClassSelect(propName);
            setState({ ...state, orderType: "" }); // WE NEED TO CHANGE THIS TO A NUMBER. SAME FOR EXITLOTSIZE.
            if (propName) {
              $(".requiredassetclass").hide();
            }
            const multiExecModal = document.getElementById(
              "multiExecutionModal"
            );
            const exitExecModal = document.getElementById("exitExecutionModal");
            if (multiExecModal && exitExecModal) {
              if (propName === "Options") {
                //$("#multiExecutionModal").addClass("multiExecutionModalOPTIONS");
                $(".editExecutionModalInputs > .ANTselectionfieldsDT").addClass(
                  "ANTselectionfieldsRequiredOPTION"
                );
                $(
                  ".editExecutionModalInputs > .ANTselectionfieldsRequiredOPTION"
                ).removeClass("ANTselectionfieldsDT");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsDT"
                ).addClass("ANTselectionfieldsRequiredOPTION");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsRequiredOPTION"
                ).removeClass("ANTselectionfieldsDT");
                $(".editExecutionModalInputs > .ANTselectionfields").addClass(
                  "ANTselectionfieldsRequiredOPTION"
                );
                $(
                  ".editExecutionModalInputs > .ANTselectionfieldsRequiredOPTION"
                ).removeClass("ANTselectionfields");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfields"
                ).addClass("ANTselectionfieldsRequiredOPTION");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsRequiredOPTION"
                ).removeClass("ANTselectionfields");
              } else {
                /*               $("#multiExecutionModal").removeClass(
                "multiExecutionModalOPTIONS"
              ); */
                $(
                  ".editExecutionModalInputs > .ANTselectionfieldsRequiredOPTION"
                ).addClass("ANTselectionfieldsDT");
                $(
                  ".editExecutionModalInputs > .ANTselectionfieldsDT"
                ).removeClass("ANTselectionfieldsRequiredOPTION");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsRequiredOPTION"
                ).addClass("ANTselectionfieldsDT");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsDT"
                ).removeClass("ANTselectionfieldsRequiredOPTION");
                $(
                  ".editExecutionModalInputs > .ANTselectionfieldsRequiredOPTION"
                ).addClass("ANTselectionfields");
                $(
                  ".editExecutionModalInputs > .ANTselectionfields"
                ).removeClass("ANTselectionfieldsRequiredOPTION");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfieldsRequiredOPTION"
                ).addClass("ANTselectionfields");
                $(
                  ".editExecutionModalInputs > div > .ANTselectionfields"
                ).removeClass("ANTselectionfieldsRequiredOPTION");
              }
            }
            settempstate(propName);
            forceUpdate();
          }}
        >
          {asset}
        </button>
      )
    );

    if (assetClasses.length <= 5 && portfolio.name !== "Default") {
      buttonArr.push(
        <div
          key={portfolio.name}
          style={{ color: "#808080", fontStyle: "italic", marginTop: "4px" }}
        >
          Don't see your asset? Add them
          <button
            disabled={show100TradesCheck}
            onClick={() => {
              if (editCount === 0) {
                props.seteditMode("yes");
                seteditCount(1);
              } else {
                props.seteditMode("yes2");
                seteditCount(0);
              }
              const dropdown = $(".portfolio-dropdown");
              if (dropdown.css("display") === "flex") {
              } else {
                setTimeout(() => {
                  dropdown.css({
                    display: "flex",
                  });
                }, 0);
                firstUpdate.current = true; // this prevents the execution modals from disappearing
              }
            }}
            id="addassetClassButton"
          >
            here
            {/* <FontAwesomeIcon icon={faPlus} />  */}
          </button>
        </div>
      );
    }
    return buttonArr;
  };
  return (
    <div className="addtradeformwrapper">
      <Modal
        ariaHideApp={false}
        id="PictureModal"
        className="PictureModal"
        isOpen={picIsOpen}
        onRequestClose={closeModalpic}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <div
          className="exitpicbutton"
          onClick={() => {
            closeModalpic();
          }}
        ></div>
        <img
          className="innerpicturesBG"
          src={picId.base64Data}
          alt=""
          key={"bigpicture" + picId.pictureKey}
        />
      </Modal>
      {/* OPTIONS EXERCISE|ASSIGNMENT PROFIT CHECK MODAL */}
      <Modal
        ariaHideApp={false}
        id="optionsCheckModal"
        className={"optionsCheckModal"}
        isOpen={modalIsOpen}
        onRequestClose={closeModalOnlyClose}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      ></Modal>
      {/* --------------- Multi Execution Modal --------------- */}
      <Modal
        ariaHideApp={false}
        id="multiExecutionModal"
        className={"multiExecutionModal"}
        isOpen={modalIsOpen}
        onRequestClose={closeModalOnlyClose}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        <div id="centerit">
          {orderType !== "Multi-Leg Strategy" ? (
            <h3 className="modalSymbolHeader">{"Add Entry Executions"}</h3>
          ) : (
            <h3 className="modalSymbolHeader">{"Add Entry Legs"}</h3>
          )}
          <button
            className="deletetradesclosebutton"
            onClick={() => handleExecutionClose("close")}
          >
            <DeleteButton />
          </button>
          <div className="editExecutionModalInputs">
            {tempstate === "Options" && orderType === "Multi-Leg Strategy" ? (
              <span>
                {legType ? (
                  <label>Option Leg Type</label>
                ) : (
                  <label>
                    Option Leg Type{" "}
                    <em className="requiredlegtype">&nbsp;Required</em>
                  </label>
                )}
                <span id="optionsselectwrapper-upper">
                  <div className="buy-call-container">
                    <label className="container checksygBC2 ">
                      <input
                        type="radio"
                        disabled={show100TradesCheck}
                        name="legType"
                        id="BuyCall"
                        value={"Long Call"}
                        onClick={(e) =>
                          setState({ ...state, legType: e.target.value })
                        }
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-buy">
                      <label>{"Long Call"}</label>
                    </div>
                  </div>
                  <div className="optionsellcall-container-upper">
                    <label className="container checksygBC">
                      <input
                        type="radio"
                        disabled={show100TradesCheck}
                        name="legType"
                        id="SellCall"
                        value={"Short Call"}
                        onClick={(e) =>
                          setState({ ...state, legType: e.target.value })
                        }
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Call"}</label>
                    </div>
                  </div>
                  <div className="buy-put-container">
                    <label className="container checksygBP">
                      <input
                        type="radio"
                        disabled={show100TradesCheck}
                        name="legType"
                        id="BuyPut"
                        value={"Long Put"}
                        onClick={(e) =>
                          setState({ ...state, legType: e.target.value })
                        }
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Long Put"}</label>
                    </div>
                  </div>
                  <div className="optionsellput-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        disabled={show100TradesCheck}
                        name="legType"
                        id="SellPut"
                        value={"Short Put"}
                        onClick={(e) =>
                          setState({ ...state, legType: e.target.value })
                        }
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Put"}</label>
                    </div>
                  </div>
                </span>
              </span>
            ) : (
              ""
            )}
            <label
              className={
                tempstate === "Options"
                  ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                  : "ANTselectionfieldsDT ANTselectionfieldsDT515"
              }
            >
              {"Date & Time Opened"}{" "}
              <span className="container checksantcurretntime">
                <input
                  type="checkbox"
                  id="boxNoEntrySlip"
                  name="dateTimeNowAdd"
                  value={dateTimeOpenedSame}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setState({
                        ...state,
                        startDateTime: new Date(),
                      });
                    } else {
                      setState({
                        ...state,
                        startDateTime: prevdateTimeOpenedSame,
                      });
                    }
                    setdateTimeNowAdd({
                      ...dateTimeNowAdd,
                      dateTimeOpenedSame: e.target.checked,
                    });
                  }}
                />
                <span className="checkmark checkmark-col1-2"></span>
                <span
                  style={{ position: "relative", top: "1px", marginRight: 4 }}
                >
                  &emsp;&ensp;Use Now
                </span>
              </span>
              <span className="mandatory startDateTimeErrorMsg">{""}</span>
              {/*               <DateTime
                value={startDateTime}
                name="startDateTime"
                onChange={(e) => setState({ ...state, startDateTime: e._d })}
                dateFormat="MM/DD/YYYY"
                timeFormat="HH:mm:ss"
                inputProps={inputProps}
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "Required",
                      }}
                      className="text-input-boxes"
                      disabled={show100TradesCheck}
                    />
                  )}
                  ampm={false}
                  views={["day", "year", "hours", "minutes", "seconds"]}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  disabled={show100TradesCheck}
                  clearable
                  value={startDateTime || null}
                  name="startDateTime"
                  onChange={(e) =>
                    setState({
                      ...state,
                      startDateTime: !e ? "" : e._d,
                    })
                  }
                  onClose={(e) =>
                    setState({
                      ...state,
                      startDateTime: startDateTime,
                    })
                  }
                />
              </LocalizationProvider>
            </label>
            <div className="main-labels">
              <label
                className={
                  tempstate === "Options"
                    ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                    : "ANTselectionfields ANTselectionfields515"
                }
              >
                {tempstate === "Stocks"
                  ? "Shares"
                  : tempstate === "Options" || tempstate === "Futures"
                  ? "Contracts"
                  : tempstate === "Forex"
                  ? "Lots"
                  : tempstate === "Crypto"
                  ? "Coins/Tokens"
                  : tempstate === "CFD"
                  ? "Quantity"
                  : "Units"}
                <span className="mandatory lotSizeErrorMsg">{""}</span>
                <input
                  name="lotSize"
                  style={movecursor}
                  disabled={show100TradesCheck}
                  value={lotSize}
                  onChange={(e) => {
                    setState({ ...state, lotSize: e.target.value }); // WE NEED TO CHANGE THIS TO A NUMBER. SAME FOR EXITLOTSIZE.
                  }}
                  className="text-input-boxes required"
                  required
                  placeholder="Required"
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="main-labels">
              <label
                className={
                  tempstate === "Options"
                    ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                    : " ANTselectionfields ANTselectionfields515"
                }
              >
                {tempstate === "Options" ? "Entry Premium" : "Entry Price"}
                <span className="mandatory entryPriceErrorMsg">{""}</span>
                <input
                  name="entryPrice"
                  style={movecursor}
                  disabled={show100TradesCheck}
                  value={entryPrice}
                  onChange={(e) => {
                    setState({ ...state, entryPrice: e.target.value });
                  }}
                  className="text-input-boxes required"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  required
                  placeholder="Required"
                />
              </label>
            </div>
            {tempstate === "Options" && (
              <div className="optionsOptionals">
                {" "}
                <label className="ANTselectionfieldsOPTION">
                  {"Expiration Date"}{" "}
                  <span className="container checksantcurretntimeexp">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="dateTimeNowAdd"
                      value={expirationDateSame}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let dateN = new Date();
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          setState({
                            ...state,
                            expirationDate: dateN,
                          });
                        } else {
                          setState({
                            ...state,
                            expirationDate: prevexpirationDateSame,
                          });
                        }
                        setdateTimeNowAdd({
                          ...dateTimeNowAdd,
                          expirationDateSame: e.target.checked,
                        });
                      }}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span
                      style={{
                        position: "relative",
                        top: "1px",
                        marginRight: 4,
                      }}
                    >
                      &emsp;&ensp;Use Today
                    </span>
                  </span>
                  <span className="mandatory expDateTimeErrorMsg">{""}</span>
                  {/*                   <DateTime
                    value={expirationDate}
                    name="expirationDate"
                    onChange={(e) =>
                      setState({ ...state, expirationDate: e._d })
                    }
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    inputProps={inputProps}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{
                            ...props.inputProps,
                            placeholder: "Required",
                          }}
                          className="text-input-boxes required"
                          disabled={show100TradesCheck}
                        />
                      )}
                      inputFormat="MM/DD/YYYY"
                      clearable
                      value={expirationDate || null}
                      disabled={show100TradesCheck}
                      name="expirationDate"
                      onChange={(e) => {
                        let dateN = !e ? "" : new Date(e._d);
                        if (dateN === "") {
                        } else {
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          setState({
                            ...state,
                            expirationDate: dateN,
                          });
                        }
                      }}
                    />
                  </LocalizationProvider>
                </label>
                <div className="main-labels">
                  <label className="ANTselectionfieldsOPTION">
                    Strike Price
                    <span className="mandatory strikePriceErrorMsg">{""}</span>
                    <input
                      name="strikePrice"
                      style={movecursor}
                      disabled={show100TradesCheck}
                      value={strikePrice}
                      onChange={(e) => {
                        setState({ ...state, strikePrice: e.target.value });
                      }}
                      placeholder="Required"
                      className="text-input-boxes required"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </label>
                </div>
                <div className="main-labels">
                  <label className="ANTselectionfieldsNOTRequiredOPTION mae-tooltip-hover">
                    <span>{`Expected Entry `}</span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to enter the trade at.<br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={200}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedEntry"
                        id="expectedEntry"
                        disabled={
                          show100TradesCheck ? show100TradesCheck : !showMaster
                        }
                        style={movecursor}
                        value={
                          hasEntrySlippage === false
                            ? expectedEntry
                            : entrycheckbuffer === 0
                            ? expectedEntry
                            : entryPrice
                        }
                        onChange={(e) => {
                          setState({ ...state, expectedEntry: e.target.value });
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />
                    </div>
                  </label>
                </div>
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checkssspv">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="hasEntrySlippage"
                      checked={hasEntrySlippage}
                      disabled={
                        show100TradesCheck ? show100TradesCheck : !showMaster
                      }
                      onChange={(e) => handleEntrySlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Entry Slippage
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you entered at the intended entry price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={200}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>{" "}
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-14px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}
            {tempstate === "Options" ? (
              ""
            ) : (
              <div>
                <div className="main-labels">
                  <label
                    className={
                      "ANTselectionfieldsNOTRequiredOPTION ANTselectionfieldsNOTRequiredOPTION515 mae-tooltip-hover"
                    }
                  >
                    {" "}
                    <span
                      style={{ marginRight: 4 }}
                      onClick={() =>
                        document
                          .querySelectorAll(".popover-basic-custom")
                          .forEach((e) => (e.style.display = "none"))
                      }
                    >
                      Expected Entry
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to enter the trade at.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={200}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedEntry"
                        id="expectedEntry"
                        style={movecursor}
                        disabled={
                          show100TradesCheck ? show100TradesCheck : !showMaster
                        }
                        value={
                          hasEntrySlippage === false
                            ? expectedEntry
                            : entrycheckbuffer === 0
                            ? expectedEntry
                            : entryPrice
                        }
                        onChange={(e) => {
                          setState({ ...state, expectedEntry: e.target.value });
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />
                    </div>
                  </label>
                </div>{" "}
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checkssspv mae-tooltip-hover">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="hasEntrySlippage"
                      checked={hasEntrySlippage}
                      disabled={
                        show100TradesCheck ? show100TradesCheck : !showMaster
                      }
                      onChange={(e) => handleEntrySlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Entry Slippage
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you entered at the intended entry price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={200}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>{" "}
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-14px",
                          marginLeft: "-5px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="commentsbox showmultiExecsBox" autoComplete="off">
            {editExecutionButtons()}
          </div>
          {/*           <table style={{ width: "100%" }}>
            <tr>
              <th className="editExecutionHeaderText">Lot Size</th>
              <th className="editExecutionHeaderText">Entry Price</th>
              <th className="editExecutionHeaderText">Expected Entry</th>
              <th className="editExecutionHeaderText">Start Date and Time</th>
            </tr>
            <tr>
              <td colSpan="4">{editExecutionButtons()}</td>
            </tr>
          </table> */}
        </div>
        <button
          name="clearExecutionButton"
          className="clearExecutionButton"
          onClick={() => handleDeleteMultiExecution()}
          disabled={show100TradesCheck}
        >
          {"Clear"}
        </button>
        {inEditMode ? (
          <button
            name="executionButton"
            className="executionButton addexec"
            onClick={() => handleSaveExecutions()}
            disabled={
              show100TradesCheck ? show100TradesCheck : isExecutionDisabled2
            }
          >
            Save Edit
          </button>
        ) : (
          <button
            name="executionButton"
            className="executionButton addexec"
            onClick={() => handleAddMultiExecution()}
            disabled={
              show100TradesCheck ? show100TradesCheck : isExecutionDisabled2
            }
          >
            {orderType === "Multi-Leg Strategy" && tempstate === "Options"
              ? "Add Leg"
              : "Add Execution"}
          </button>
        )}
        <button
          name="cancelExecutionButton"
          className="cancelExecutionButton"
          onClick={() => handleExecutionClose("close")}
          disabled={show100TradesCheck}
        >
          Cancel
        </button>
        <button
          name="saveExecutionButton"
          className="saveExecutionButton"
          onClick={() => handleExecutionClose("add")}
          disabled={
            show100TradesCheck ? show100TradesCheck : isExecutionDisabled
          }
        >
          Save
        </button>
        {/*         <button
          name="clearExecutionButton"
          className="clearExecutionButton executionbutton2 donewidth"
          onClick={() => handleExecutionClose()}
          disabled={isExecutionDisabled}
        >
          Save
        </button> THIS IS A TEMPLATE FOR THE CANCEL BUTTON -  */}
      </Modal>{" "}
      {/* --------------- Exit Execution Modal  ---------------*/}
      <Modal
        ariaHideApp={false}
        id="exitExecutionModal"
        className={"exitExecutionModal"}
        isOpen={exitmodalIsOpen}
        onRequestClose={closeExitModalOnlyClose}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        {orderType !== "Multi-Leg Strategy" ? (
          <h3 className="modalSymbolHeader">{"Add Exit Executions"}</h3>
        ) : (
          <h3 className="modalSymbolHeader">{"Add Exit Legs"}</h3>
        )}
        <button
          className="deletetradesclosebutton"
          onClick={() => handleExitExecutionClose("close")}
          disabled={show100TradesCheck}
        >
          <DeleteButton />
        </button>
        <div className="editExecutionModalInputs">
          {tempstate === "Options" && orderType === "Multi-Leg Strategy" ? (
            <span>
              {exitlegType ? (
                <label>Option Leg Type</label>
              ) : (
                <label>
                  Option Leg Type{" "}
                  <em className="requiredlegtype">&nbsp;Required</em>
                </label>
              )}
              <span id="optionsselectwrapper-upper">
                <div className="buy-call-container">
                  <label className="container checksygBC2">
                    <input
                      type="radio"
                      name="legType2"
                      disabled={show100TradesCheck}
                      id="ExitBuyCall"
                      value={"Long Call"}
                      onClick={(e) => {
                        setState({ ...state, exitlegType: e.target.value });
                      }}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-buy">
                    <label>{"Long Call"}</label>
                  </div>
                </div>
                <div className="optionsellcall-container-upper">
                  <label className="container checksygBC">
                    <input
                      type="radio"
                      name="legType2"
                      disabled={show100TradesCheck}
                      id="ExitSellCall"
                      value={"Short Call"}
                      onClick={(e) => {
                        setState({ ...state, exitlegType: e.target.value });
                      }}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Short Call"}</label>
                  </div>
                </div>
                <div className="buy-put-container">
                  <label className="container checksygBP">
                    <input
                      type="radio"
                      name="legType2"
                      id="ExitBuyPut"
                      disabled={show100TradesCheck}
                      value={"Long Put"}
                      onClick={(e) =>
                        setState({ ...state, exitlegType: e.target.value })
                      }
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Long Put"}</label>
                  </div>
                </div>
                <div className="optionsellput-container">
                  <label className="container checksygBC2">
                    <input
                      type="radio"
                      name="legType2"
                      id="ExitSellPut"
                      disabled={show100TradesCheck}
                      value={"Short Put"}
                      onClick={(e) =>
                        setState({ ...state, exitlegType: e.target.value })
                      }
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Short Put"}</label>
                  </div>
                </div>
              </span>
            </span>
          ) : (
            ""
          )}
          <div className="main-labels">
            <label
              className={
                tempstate === "Options"
                  ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                  : "ANTselectionfieldsDT ANTselectionfieldsDT515"
              }
            >
              {"Date & Time Closed"}
              <span className="container checksantcurretntime">
                <input
                  type="checkbox"
                  id="boxNoEntrySlip"
                  name="dateTimeNowAdd"
                  value={dateTimeClosedSame}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setState({
                        ...state,
                        endDateTime: new Date(),
                      });
                    } else {
                      setState({
                        ...state,
                        endDateTime: prevdateTimeClosedSame,
                      });
                    }
                    setdateTimeNowAdd({
                      ...dateTimeNowAdd,
                      dateTimeClosedSame: e.target.checked,
                    });
                  }}
                />
                <span className="checkmark checkmark-col1-2"></span>
                <span
                  style={{ position: "relative", top: "1px", marginRight: 4 }}
                >
                  &emsp;&ensp;Use Now
                </span>
              </span>
              <span className="mandatory endDateTimeErrorMsg">{""}</span>
              {/*               <DateTime
                value={endDateTime}
                onChange={(e) => setState({ ...state, endDateTime: e._d })}
                dateFormat="MM/DD/YYYY"
                timeFormat="HH:mm:ss"
                inputProps={inputProps}
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "Required",
                      }}
                      className="text-input-boxes required"
                      disabled={show100TradesCheck}
                    />
                  )}
                  views={["day", "year", "hours", "minutes", "seconds"]}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  clearable
                  ampm={false}
                  value={endDateTime || null}
                  disabled={show100TradesCheck}
                  name="endDateTime"
                  onChange={(e) =>
                    setState({
                      ...state,
                      endDateTime: !e ? "" : e._d,
                    })
                  }
                  onClose={(e) =>
                    setState({
                      ...state,
                      endDateTime: endDateTime,
                    })
                  }
                />
              </LocalizationProvider>
            </label>
          </div>
          <div>
            <label
              className={
                tempstate === "Options"
                  ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                  : "ANTselectionfields ANTselectionfields515"
              }
            >
              {tempstate === "Stocks"
                ? "Shares"
                : tempstate === "Options" || tempstate === "Futures"
                ? "Contracts"
                : tempstate === "Forex"
                ? "Lots"
                : tempstate === "Crypto"
                ? "Coins/Tokens"
                : tempstate === "CFD"
                ? "Quantity"
                : "Units"}
              <span className="mandatory exitlotSizeErrorMsg">{""}</span>
              <input
                name="exitLotSize"
                style={movecursor}
                value={exitLotSize}
                disabled={show100TradesCheck}
                onChange={(e) => {
                  setState({ ...state, exitLotSize: e.target.value });
                }}
                className="text-input-boxes required"
                required
                placeholder="Required"
              />
            </label>
          </div>

          {/* --------- Exit Price component --------- */}
          <div className="main-labels">
            <label
              className={
                tempstate === "Options"
                  ? "ANTselectionfieldsRequiredOPTION ANTselectionfieldsRequiredOPTION515"
                  : "ANTselectionfields ANTselectionfields515"
              }
            >
              {tempstate === "Options"
                ? exercised === "Exercised"
                  ? "Premium at Exercise"
                  : exercised === "Assigned"
                  ? "Premium at Assignment"
                  : exercised === "Expired"
                  ? "Premium at Expiration"
                  : "Exit Premium"
                : "Exit Price"}
              <div
                className={
                  tempstate === "Options"
                    ? "usesltpforwexitwrappero"
                    : "usesltpforwexitwrapper"
                }
              >
                <div className="buy-put-container">
                  <label className="container checksygBP">
                    <input
                      type="radio"
                      name="usesltpforexit"
                      id="ExitBuyPut"
                      disabled={show100TradesCheck}
                      onClick={(e) =>
                        e.target.value &&
                        setState({ ...state, exitPrice: stopLoss })
                      }
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Use SL"}</label>
                  </div>
                </div>
                <div className="optionsellput-container">
                  <label className="container checksygBC2">
                    <input
                      type="radio"
                      name="usesltpforexit"
                      id="ExitSellPut"
                      disabled={show100TradesCheck}
                      onClick={(e) =>
                        e.target.value &&
                        setState({ ...state, exitPrice: takeProfit })
                      }
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Use TP"}</label>
                  </div>
                </div>
              </div>

              <span className="mandatory exitPriceErrorMsg">{""}</span>
              {exercised === "Exercised" ||
              exercised === "Assigned" ||
              exercised === "Expired" ? (
                <input
                  name="exitPrice"
                  style={movecursor}
                  value={exitPrice}
                  disabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, exitPrice: e.target.value });
                  }}
                  className="text-input-boxes"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  placeholder="Optional"
                />
              ) : (
                <input
                  name="exitPrice"
                  style={movecursor}
                  value={exitPrice}
                  disabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, exitPrice: e.target.value });
                  }}
                  className="text-input-boxes required"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  required
                  placeholder="Required"
                />
              )}
            </label>
          </div>
          {tempstate === "Options" && (
            <span className="exercised-super-container">
              <div className="exercised-container">
                <label
                  className={
                    exerciseDisable
                      ? "containerdis checksygBC"
                      : "container checksygBC"
                  }
                >
                  <input
                    type="radio"
                    name="exercised"
                    id="optionExercised"
                    value={"Exercised"}
                    onClick={(e) => setexercised(e.target.value)}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : exerciseDisable
                    }
                  />
                  <span
                    className={
                      exerciseDisable ? "radiocircledis" : "radiocircle"
                    }
                  ></span>
                </label>
                <div
                  className={
                    exerciseDisable
                      ? "radio-label-sell-dis"
                      : "radio-label-sell"
                  }
                >
                  <label>{"Exercised"}</label>
                </div>
              </div>
              <div className="assigned-container">
                <label
                  className={
                    assignDisable
                      ? "containerdis checksygBC"
                      : "container checksygBC"
                  }
                >
                  <input
                    type="radio"
                    name="exercised"
                    id="optionAssigned"
                    value={"Assigned"}
                    onClick={(e) => setexercised(e.target.value)}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : assignDisable
                    }
                  />
                  <span
                    className={assignDisable ? "radiocircledis" : "radiocircle"}
                  ></span>
                </label>
                <div
                  className={
                    assignDisable ? "radio-label-sell-dis" : "radio-label-sell"
                  }
                >
                  <label>{"Assigned"}</label>
                </div>
              </div>
              <div className="expired-container">
                <label className="container checksygBCC">
                  <input
                    type="radio"
                    name="exercised"
                    id="optionExpired"
                    value={"Expired"}
                    onClick={(e) => setexercised(e.target.value)}
                    disabled={show100TradesCheck}
                  />
                  <span className="radiocircle"></span>
                </label>
                <div className="radio-label-sell">
                  <label>{"Expired"}</label>
                </div>
              </div>
            </span>
          )}
          {tempstate === "Options" && (
            <div className="optionsOptionalsexit">
              {" "}
              <label className="ANTselectionfieldsOPTION">
                {"Expiration Date"}{" "}
                <span className="container checksantcurretntimeexp">
                  <input
                    type="checkbox"
                    id="boxNoEntrySlip"
                    name="dateTimeNowAdd"
                    value={exitExpirationDateSame}
                    onChange={(e) => {
                      if (e.target.checked) {
                        let dateN = new Date();
                        dateN.setHours(0, 0, 0, 0); // last midnight
                        setState({
                          ...state,
                          exitexpirationDate: dateN,
                        });
                      } else {
                        setState({
                          ...state,
                          exitexpirationDate: prevexitExpirationDateSame,
                        });
                      }
                      setdateTimeNowAdd({
                        ...dateTimeNowAdd,
                        exitExpirationDateSame: e.target.checked,
                      });
                    }}
                  />
                  <span className="checkmark checkmark-col1-2"></span>
                  <span
                    style={{ position: "relative", top: "1px", marginRight: 4 }}
                  >
                    &emsp;&ensp;Use Today
                  </span>
                </span>
                <span className="mandatory exitexpDateTimeErrorMsg">{""}</span>
                {/*                 <DateTime
                  value={exitexpirationDate}
                  name="expirationDate"
                  onChange={(e) =>
                    setState({ ...state, exitexpirationDate: e._d })
                  }
                  dateFormat="MM/DD/YYYY"
                  timeFormat={false}
                  inputProps={inputProps}
                /> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        inputProps={{
                          ...props.inputProps,
                          placeholder: "Required",
                        }}
                        className="text-input-boxes required"
                        disabled={show100TradesCheck}
                      />
                    )}
                    inputFormat="MM/DD/YYYY"
                    clearable
                    value={exitexpirationDate || null}
                    disabled={show100TradesCheck}
                    name="exitexpirationDate"
                    onChange={(e) => {
                      let dateN = !e ? "" : new Date(e._d);
                      if (dateN === "") {
                      } else {
                        dateN.setHours(0, 0, 0, 0); // last midnight
                        setState({
                          ...state,
                          exitexpirationDate: dateN,
                        });
                      }
                    }}
                  />
                </LocalizationProvider>
              </label>
              <div className="main-labels">
                <label className="ANTselectionfieldsOPTION">
                  Strike Price
                  <span className="mandatory strikePriceErrorMsg">{""}</span>
                  <input
                    name="exitstrikePrice"
                    style={movecursor}
                    value={exitstrikePrice}
                    disabled={show100TradesCheck}
                    onChange={(e) => {
                      setState({ ...state, exitstrikePrice: e.target.value });
                    }}
                    placeholder="Required"
                    className="text-input-boxes required"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />
                </label>
              </div>
              <div className="main-labels">
                <label className="ANTselectionfieldsNOTRequiredOPTION mae-tooltip-hover">
                  <span style={{ marginRight: 4 }}>Expected Exit</span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The price you intended to exit the trade at.
                        <br></br>
                      </div>
                    }
                    placement="bottom"
                    delay={200}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style6">&#x1D48A;</span>
                  </PopoverStickOnHover>
                  {!showMaster && (
                    <span
                      style={{
                        display: "inline-block",
                        position: "relative",
                        top: "-13px",
                        marginLeft: "-2px",
                        width: 0,
                        height: 0,
                      }}
                    >
                      <MasterTierComponent />
                    </span>
                  )}
                  <div
                    onClick={() => {
                      if (
                        active &&
                        (tier === "master" ||
                          tier === "ultimate" ||
                          tier === "admin")
                      ) {
                      } else {
                        props.setshowUpgradeModal(featureSelect4);
                        setexitIsOpen(false);
                      }
                    }}
                  >
                    <input
                      name="expectedEntry"
                      id="expectedEntry"
                      style={movecursor}
                      disabled={
                        show100TradesCheck ? show100TradesCheck : !showMaster
                      }
                      value={
                        hasEntrySlippage === false
                          ? expectedExit
                          : exitcheckbuffer === 0
                          ? expectedExit
                          : exitPrice
                      }
                      onChange={(e) => {
                        setState({ ...state, expectedExit: e.target.value });
                      }}
                      placeholder="Optional"
                      className="text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                </label>
              </div>
              <div
                onClick={() => {
                  if (
                    active &&
                    (tier === "master" ||
                      tier === "ultimate" ||
                      tier === "admin")
                  ) {
                  } else {
                    props.setshowUpgradeModal(featureSelect4);
                    setexitIsOpen(false);
                  }
                }}
                style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
              >
                <label className="container checkssspv mae-tooltip-hover">
                  <input
                    type="checkbox"
                    id="boxNoExitSlip"
                    name="hasExitSlippage"
                    checked={hasExitSlippage}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : !showMaster
                    }
                    onChange={(e) => handleExitSlippage(e)}
                  />
                  <span className="checkmark checkmark-col1-2"></span>
                  <span style={{ marginTop: "-24px", marginRight: 4 }}>
                    &emsp;&ensp;No Exit Slippage
                  </span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Check this if you exited at the intended exit price.
                        <br></br>
                      </div>
                    }
                    placement="bottom"
                    delay={200}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style6">&#x1D48A;</span>
                  </PopoverStickOnHover>
                  {!showMaster && (
                    <span
                      style={{
                        display: "inline-block",
                        position: "relative",
                        top: "-13px",
                        marginLeft: "-5px",
                        width: 0,
                        height: 0,
                      }}
                    >
                      <MasterTierComponent />
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {tempstate === "Options" ? (
            ""
          ) : (
            <div>
              {" "}
              <div className="main-labels">
                <label
                  className={
                    "ANTselectionfieldsNOTRequiredOPTION ANTselectionfieldsNOTRequiredOPTION515 mae-tooltip-hover"
                  }
                >
                  <span style={{ marginRight: 4 }}>Expected Exit</span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The price you intended to exit the trade at.
                        <br></br>
                      </div>
                    }
                    placement="bottom"
                    delay={200}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style6">&#x1D48A;</span>
                  </PopoverStickOnHover>
                  {!showMaster && (
                    <span
                      style={{
                        display: "inline-block",
                        position: "relative",
                        top: "-13px",
                        marginLeft: "-2px",
                        width: 0,
                        height: 0,
                      }}
                    >
                      <MasterTierComponent />
                    </span>
                  )}
                  <div
                    onClick={() => {
                      if (
                        active &&
                        (tier === "master" ||
                          tier === "ultimate" ||
                          tier === "admin")
                      ) {
                      } else {
                        props.setshowUpgradeModal(featureSelect4);
                        setexitIsOpen(false);
                      }
                    }}
                  >
                    <input
                      name="expectedExit"
                      style={movecursor}
                      disabled={
                        show100TradesCheck ? show100TradesCheck : !showMaster
                      }
                      value={
                        hasExitSlippage === false
                          ? expectedExit
                          : exitcheckbuffer === 0
                          ? expectedExit
                          : exitPrice
                      }
                      onChange={(e) => {
                        setState({ ...state, expectedExit: e.target.value });
                      }}
                      placeholder="Optional"
                      className="text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                </label>
              </div>
              <div
                onClick={() => {
                  if (
                    active &&
                    (tier === "master" ||
                      tier === "ultimate" ||
                      tier === "admin")
                  ) {
                  } else {
                    props.setshowUpgradeModal(featureSelect4);
                    setexitIsOpen(false);
                  }
                }}
                style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
              >
                <label className="container checksst mae-tooltip-hover">
                  <input
                    type="checkbox"
                    id="boxNoExitSlip"
                    name="hasExitSlippage"
                    checked={hasExitSlippage}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : !showMaster
                    }
                    onChange={(e) => handleExitSlippage(e)}
                  />
                  <span className="checkmark checkmark-col1-2"></span>
                  <span style={{ marginTop: "-24px", marginRight: 4 }}>
                    &emsp;&ensp;No Exit Slippage
                  </span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Check this if you exited at the intended exit price.
                        <br></br>
                      </div>
                    }
                    placement="bottom"
                    delay={200}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style6">&#x1D48A;</span>
                  </PopoverStickOnHover>{" "}
                  {!showMaster && (
                    <span
                      style={{
                        display: "inline-block",
                        position: "relative",
                        top: "-13px",
                        marginLeft: "-5px",
                        width: 0,
                        height: 0,
                      }}
                    >
                      <MasterTierComponent />
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="commentsbox exitcommentsbox" autoComplete="off">
          {editExitExecutionButtons()}
        </div>
        <button
          name="clearExecutionButton"
          className="clearExecutionButton"
          onClick={() => handleDeleteMultiExitExecution()}
          disabled={show100TradesCheck}
        >
          {"Clear"}
        </button>
        {inEditMode ? (
          <button
            name="executionButton"
            className="executionButton addexec"
            onClick={() => saveExitExecutions()}
            disabled={
              show100TradesCheck ? show100TradesCheck : isExitExecutionDisabled2
            }
          >
            Save Edit
          </button>
        ) : (
          <button
            name="executionButton"
            className="executionButton addexec"
            onClick={() => addExitExecution()}
            disabled={
              show100TradesCheck ? show100TradesCheck : isExitExecutionDisabled2
            }
          >
            {orderType === "Multi-Leg Strategy" && tempstate === "Options"
              ? "Add Leg"
              : "Add Execution"}
          </button>
        )}
        <button
          name="cancelExecutionButton"
          className="cancelExecutionButton"
          onClick={() => handleExitExecutionClose("close")}
          disabled={show100TradesCheck}
        >
          Cancel
        </button>
        <button
          id="saveExecutionButton"
          name="saveExecutionButton"
          className="saveExecutionButton"
          onClick={() => {
            handleExitExecutionClose("add");
          }}
          disabled={
            show100TradesCheck ? show100TradesCheck : isExitExecutionDisabled
          }
        >
          Save
        </button>
      </Modal>
      {/* --------------- Components ---------------*/}
      <CardWrapper className="ANTcardwrapper">
        <InputCards className="input-card-styles">
          <div className="ANT-heading">Entry Data</div>
          <CardColumn>
            {/*             <div className="main-labels order-type-style">
              {orderType ? (
                <label>Asset Class</label>
              ) : (
                <label>
                  Asset Class{" "}
                  <em className="requiredordertype">&nbsp;Required</em>
                </label>
              )}
              <div className="move-container">
                {displayAssetClasses()}
              </div>
            </div> */}
            {/*             <div className="main-labels">
              <label className="select-symbol ANTselectionfields">
                {"Asset Class"}
                <CreatableSelect
                  isClearable
                  value={chosenPortfolioAsset}
                  onChange={(e) => {
                    setState({ ...state, chosenPortfolioAsset: e });
                  }}
                  theme={theme}
                  options={generateAssetClasses(portfolio.assetClasses)}
                  placeholder={
                    <em
                      style={{
                        fontStyle: "italic",
                        color: "#fbd665",
                        opacity: 0.7,
                      }}
                    >
                      {"Required"}
                    </em>
                  }
                />
              </label>
            </div> */}

            <div className="main-labels">
              {"Asset Class"}{" "}
              <em className="requiredassetclass">&nbsp;Required</em>
              <div id="assetClassButtonWrapper_mte">
                {displayAssetClasses()}
              </div>
            </div>
            <div className="main-labels">
              {orderType ? (
                <label>Order Type</label>
              ) : (
                <label>
                  Order Type{" "}
                  <em className="requiredordertype">&nbsp;Required</em>
                </label>
              )}
              {tempstate !== "Options" ||
              (portfolio.assetClasses.length === 1 &&
                portfolio.assetClasses[0] !== "Options") ? (
                <div id="allotherselectwrapper">
                  <div className="buy-container">
                    <label className="container checksyg">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeBuy"
                        disabled={show100TradesCheck}
                        value={"Long"}
                        onClick={(e) =>
                          setState({ ...state, orderType: e.target.value })
                        }
                        required
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-buy">
                      <label>{"Long"}</label>
                    </div>
                  </div>
                  <div className="sell-container">
                    <label className="container">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeSell"
                        disabled={show100TradesCheck}
                        value={"Short"}
                        onClick={(e) =>
                          setState({ ...state, orderType: e.target.value })
                        }
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short"}</label>
                    </div>
                  </div>
                </div>
              ) : (
                <div id="optionsselectwrapper">
                  <div className="buy-call-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeBuy"
                        value={"Long Call"}
                        disabled={show100TradesCheck}
                        onClick={(e) => {
                          if (orderType === "Multi-Leg Strategy") {
                            setState({
                              ...state,
                              orderType: e.target.value,
                            });
                          } else {
                            setState({
                              ...state,
                              orderType: e.target.value,
                              legType: e.target.value,
                              exitlegType: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-buy">
                      <label>{"Long Call"}</label>
                    </div>
                  </div>
                  <div className="optionsellcall-container">
                    <label className="container checksygBP">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeSell"
                        value={"Short Call"}
                        disabled={show100TradesCheck}
                        onClick={(e) => {
                          if (orderType === "Multi-Leg Strategy") {
                            setState({
                              ...state,
                              orderType: e.target.value,
                            });
                          } else {
                            setState({
                              ...state,
                              orderType: e.target.value,
                              legType: e.target.value,
                              exitlegType: e.target.value,
                            });
                          }
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Call"}</label>
                    </div>
                  </div>
                  <div className="buy-put-container">
                    <label className="container checksygBP">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeSell"
                        disabled={show100TradesCheck}
                        value={"Long Put"}
                        onClick={(e) => {
                          if (orderType === "Multi-Leg Strategy") {
                            setState({
                              ...state,
                              orderType: e.target.value,
                            });
                          } else {
                            setState({
                              ...state,
                              orderType: e.target.value,
                              legType: e.target.value,
                              exitlegType: e.target.value,
                            });
                          }
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Long Put"}</label>
                    </div>
                  </div>
                  <div className="optionsellput-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeSell"
                        value={"Short Put"}
                        disabled={show100TradesCheck}
                        onClick={(e) => {
                          if (orderType === "Multi-Leg Strategy") {
                            setState({
                              ...state,
                              orderType: e.target.value,
                            });
                          } else {
                            setState({
                              ...state,
                              orderType: e.target.value,
                              legType: e.target.value,
                              exitlegType: e.target.value,
                            });
                          }
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Put"}</label>
                    </div>
                  </div>
                  <div className="optionstrategy-container">
                    <label className="container checksygSt">
                      <input
                        type="radio"
                        name="orderType"
                        id="orderTypeSell"
                        value={"Multi-Leg Strategy"}
                        disabled={show100TradesCheck}
                        onClick={(e) => {
                          if (orderType === "Multi-Leg Strategy") {
                            setState({
                              ...state,
                              orderType: e.target.value,
                            });
                          } else {
                            setState({
                              ...state,
                              orderType: e.target.value,
                              legType: e.target.value,
                              exitlegType: e.target.value,
                            });
                          }
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Multi-Leg Strategy"}</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="main-labels">
              <label className="select-symbol ANTselectionfields">
                {tempstate === "Stocks"
                  ? "Ticker Symbol"
                  : tempstate === "Crypto"
                  ? "Pair"
                  : "Symbol"}
                <CreatableSelect
                  isClearable
                  styles={dropdownStyles}
                  value={selectedSymbol}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({
                      ...state,
                      selectedSymbol: e,
                      pointValue: pointValueSearch(e),
                    });
                  }}
                  theme={theme}
                  options={filterlists.symbol}
                  placeholder={
                    <em
                      style={{
                        fontStyle: "italic",
                        color: "#fa798b",
                        opacity: 1,
                      }}
                    >
                      {"Required"}
                    </em>
                  }
                />
              </label>
            </div>
            {(tempstate === "Futures" ||
              (portfolio.assetClasses.length === 1 &&
                portfolio.assetClasses[0] === "Futures")) && (
              <div>
                <div className="main-labels">
                  <label className="ANTselectionfields">
                    Point Value
                    <span className="mandatory pointValueErrorMsg">{""}</span>
                    <input
                      name="pointValue"
                      style={movecursor}
                      value={pointValue}
                      disabled={show100TradesCheck}
                      onChange={(e) => {
                        setState({ ...state, pointValue: e.target.value });
                      }}
                      className="text-input-boxes required"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                      placeholder="Required"
                    />
                  </label>
                </div>
                <div className="pointValuewrapper">
                  <label className="container checksss">
                    <input
                      type="checkbox"
                      id="boxNoExitSlip"
                      name="hasSaveForSymbol"
                      checked={hasSaveForSymbol}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasSaveForSymbol(e.target.checked)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    &emsp;&ensp;Save for this Symbol
                  </label>
                </div>
              </div>
            )}
            <div className="main-labels">
              <label className="ANTselectionfields">
                Stop Loss
                <span className="mandatory stopLossErrorMsg">{""}</span>
                <input
                  name="stopLoss"
                  style={movecursor}
                  value={stopLoss}
                  disabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, stopLoss: e.target.value });
                  }}
                  className="text-input-boxes"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  placeholder="Optional"
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="ANTselectionfields">
                Take Profit
                <span className="mandatory takeProfitErrorMsg">{""}</span>
                <input
                  name="takeProfit"
                  style={movecursor}
                  value={takeProfit}
                  disabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, takeProfit: e.target.value });
                  }}
                  className="text-input-boxes"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  placeholder="Optional"
                />
              </label>
            </div>
            <button
              className="executionButtonInModals"
              name="editExecutionButton"
              onClick={() => handleEditExecutions()}
              disabled={show100TradesCheck}
              /* disabled={inEditMode || multiExecution.length < 1} */
            >
              {(tempstate === "Options" || tempstate === "Futures") &&
              orderType === "Multi-Leg Strategy" ? (
                "Add Entry Legs (Required)"
              ) : (
                <span>
                  Add Entry Executions{" "}
                  <span style={{ color: "#cd3e51" }}>(Required)</span>
                </span>
              )}
            </button>
            <div
              style={{
                textAlign: "center",
                marginLeft: "-41px",
                color: "#fbd665",
                filter: "drop-shadow(0 0 1px #fbd665)",
              }}
            >
              <p></p>
              <p>
                {"Avg. Entry Price: "}
                {handleNANGOOD(
                  dollarCostAverage(multiExecutionLast, "entry", orderType),
                  "-"
                )}
              </p>
              <p>
                {orderType === "Multi-Leg Strategy"
                  ? "Entry Legs: "
                  : "Entry Executions: "}
                {multiExecutionLast.length}
              </p>
              {orderType === "Multi-Leg Strategy" && tempstate === "Options" ? (
                <p>
                  {"Options Multi-Leg Strategy: "}
                  {optionsStrategy}
                </p>
              ) : (
                <div></div>
              )}
            </div>
          </CardColumn>
        </InputCards>
        <InputCards className="input-card-styles">
          <div className="ANT-heading-conc">Exit Data</div>
          <CardColumn className="column2">
            {(assetClassSelect === "Forex" ||
              (portfolio.assetClasses.length === 1 &&
                portfolio.assetClasses[0] === "Forex")) && (
              <div className="main-labels">
                <label className="ANTselectionfields">
                  Profit/Loss
                  <span className="mandatory profitLossErrorMsg">{""}</span>
                  <input
                    name="profitLoss"
                    style={movecursor}
                    disabled={show100TradesCheck}
                    value={profitLoss}
                    onChange={(e) => {
                      setState({ ...state, profitLoss: e.target.value });
                    }}
                    className="text-input-boxes"
                    autoComplete="off"
                    placeholder="Optional"
                    pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
                  />
                </label>
              </div>
            )}
            <div className="main-labels">
              <label className="ANTselectionfields ANTselectionfieldsNOTRequiredOPTION">
                <span className="ANTselectionfieldsinner">
                  Commissions
                  {showCheck && (
                    <div style={{ position: "relative", top: -9, left: -4 }}>
                      <ProTierComponent />
                    </div>
                  )}
                </span>
                <span className="mandatory commissionsErrorMsg"> {""}</span>
                <div
                  className="notrequired"
                  onClick={() => {
                    if (
                      active &&
                      (tier === "pro" ||
                        tier === "oldpro" ||
                        tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect3);
                    }
                  }}
                >
                  <input
                    name="commissions"
                    style={movecursor}
                    value={commissions}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : showCheck
                    }
                    onChange={(e) => {
                      setState({ ...state, commissions: e.target.value });
                    }}
                    placeholder="Optional"
                    className="text-input-boxes"
                    autoComplete="off"
                    pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
                  />
                </div>
              </label>
            </div>
            <div className="main-labels">
              <label className="ANTselectionfields ANTselectionfieldsNOTRequiredOPTION">
                <span className="ANTselectionfieldsinner">
                  Fees
                  {showCheck && (
                    <div style={{ position: "relative", top: -9, left: -4 }}>
                      <ProTierComponent />
                    </div>
                  )}
                </span>
                <span className="mandatory feesErrorMsg"> {""}</span>{" "}
                <div
                  className="notrequired"
                  onClick={() => {
                    if (
                      active &&
                      (tier === "pro" ||
                        tier === "oldpro" ||
                        tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect3);
                    }
                  }}
                >
                  <input
                    name="fees"
                    style={movecursor}
                    value={fees}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : showCheck
                    }
                    onChange={(e) => {
                      setState({ ...state, fees: e.target.value });
                    }}
                    placeholder="Optional"
                    className="text-input-boxes"
                    autoComplete="off"
                    pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
                  />{" "}
                </div>
              </label>
            </div>
            <div className="main-labels">
              <label className="ANTselectionfields ANTselectionfieldsNOTRequiredOPTION">
                <span>
                  <span
                    style={{
                      marginRight: "4px",
                    }}
                  >
                    MAE
                  </span>

                  {showCheck && (
                    <span
                      style={{
                        position: "absolute",
                        marginTop: "-6px",
                        marginRight: "4px",
                        left: 50,
                      }}
                    >
                      <ProTierComponent />
                    </span>
                  )}
                </span>
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Maximum Adverse Excursion (MAE) is the lowest price during
                      a long trade or the highest price during a short trade.
                      <br></br>
                    </div>
                  }
                  placement="bottom"
                  delay={200}
                  xAdjust={27}
                  yAdjust={0}
                  keepOpen={false}
                >
                  <span className="tooptip-i-style6">&#x1D48A;</span>
                </PopoverStickOnHover>{" "}
                <div
                  className="notrequired"
                  onClick={() => {
                    if (
                      active &&
                      (tier === "pro" ||
                        tier === "oldpro" ||
                        tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect2);
                    }
                  }}
                >
                  <input
                    name="maxAdEx"
                    style={movecursor}
                    value={maxAdEx}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : showCheck
                    }
                    onChange={(e) => {
                      setState({ ...state, maxAdEx: e.target.value });
                    }}
                    placeholder="Optional"
                    className="text-input-boxes"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />{" "}
                </div>
              </label>
            </div>
            <div className="main-labels">
              <label className="ANTselectionfields ANTselectionfieldsNOTRequiredOPTION">
                <span>
                  <span
                    style={{
                      marginRight: "4px",
                    }}
                  >
                    MFE
                  </span>
                  {showCheck && (
                    <span
                      style={{
                        position: "absolute",
                        marginTop: "-6px",
                        marginRight: "4px",
                        left: 50,
                      }}
                    >
                      <ProTierComponent />
                    </span>
                  )}
                </span>
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Maximum Favorable Excursion (MFE) is the highest price
                      during a long trade or the lowest price during a short
                      trade.
                      <br></br>
                    </div>
                  }
                  placement="bottom"
                  delay={200}
                  xAdjust={27}
                  yAdjust={0}
                  keepOpen={false}
                >
                  <span className="tooptip-i-style6">&#x1D48A;</span>
                </PopoverStickOnHover>{" "}
                <div
                  className="notrequired"
                  onClick={() => {
                    if (
                      active &&
                      (tier === "pro" ||
                        tier === "oldpro" ||
                        tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect2);
                    }
                  }}
                >
                  <input
                    name="maxFavEx"
                    style={movecursor}
                    value={maxFavEx}
                    disabled={
                      show100TradesCheck ? show100TradesCheck : showCheck
                    }
                    onChange={(e) => {
                      setState({ ...state, maxFavEx: e.target.value });
                    }}
                    placeholder="Optional"
                    className="text-input-boxes"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />{" "}
                </div>
              </label>
            </div>
            <div>
              <button
                className="executionButtonInModals"
                name="exitExecutionButton"
                onClick={() => handleExitExecutionModal()}
                disabled={show100TradesCheck}

                /*                   onClick={() => addExitExecution()}
                 */
              >
                {(tempstate === "Options" || tempstate === "Futures") &&
                orderType === "Multi-Leg Strategy"
                  ? "Add Exit Legs"
                  : "Add Exit Executions"}
              </button>
            </div>
            <div
              style={{
                textAlign: "center",
                marginLeft: "-41px",
                color: "#fbd665",
                filter: "drop-shadow(0 0 1px #fbd665)",
              }}
            >
              <p></p>
              <p>
                {"P/L: "}
                {supportedCryptosCheck
                  ? handleNANGOOD(
                      assetClassSelect === "Forex" ||
                        (portfolio.assetClasses.length === 1 &&
                          portfolio.assetClasses[0] === "Forex")
                        ? profitLoss
                        : Number(
                            profitLossCalculation(
                              multiExecution,
                              exitExecution,
                              orderType,
                              assetClassSelect,
                              pointValue,
                              false
                            )
                          ),
                      0
                    ) +
                    " " +
                    portfolio.settings.currencySymbol
                  : he.decode(portfolio.settings.currencySymbol) +
                    handleNANGOOD(
                      assetClassSelect === "Forex" ||
                        (portfolio.assetClasses.length === 1 &&
                          portfolio.assetClasses[0] === "Forex")
                        ? profitLoss
                        : Number(
                            profitLossCalculation(
                              multiExecution,
                              exitExecution,
                              orderType,
                              assetClassSelect,
                              pointValue,
                              false
                            )
                          ),
                      0
                    )}
              </p>
              <p>
                {"Avg. Exit Price: "}
                {handleNANGOOD(
                  dollarCostAverage(exitExecutionToo, "exit", orderType),
                  "-"
                )}
              </p>
              <p>
                {(tempstate === "Options" || tempstate === "Futures") &&
                orderType === "Multi-Leg Strategy"
                  ? "Exit Legs: "
                  : "Exit Executions: "}
                {exitExecutionToo.length}
              </p>
            </div>
          </CardColumn>
        </InputCards>
        <InputCards className="input-card-styles">
          <div className="ANT-heading-trade">
            <label>Trade Info</label>
          </div>
          <CardColumn className="column3">
            <div className="main-labels">
              <label className="ANTselectionfields">
                Order Number
                <input
                  name="orderNumber"
                  style={movecursor}
                  value={orderNumber}
                  disabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, orderNumber: e.target.value });
                  }}
                  className="text-input-boxes"
                  placeholder="Optional"
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-strategy ANTselectionfields">
                Strategies
                <CreatableSelect
                  isMulti
                  styles={dropdownStyles}
                  isClearable
                  value={selectedStrategy}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedStrategy: e });
                  }}
                  theme={theme}
                  options={filterlists.strategy}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-confidence ANTselectionfields">
                Confidence
                <CreatableSelect
                  isClearable
                  styles={dropdownStyles}
                  value={selectedConfidence}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedConfidence: e });
                  }}
                  options={filterlists.confidenceWithDefault}
                  theme={theme}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-emotional-state ANTselectionfields">
                Emotional States
                <CreatableSelect
                  isMulti
                  isClearable
                  styles={dropdownStyles}
                  value={selectedEmotion}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedEmotion: e });
                  }}
                  theme={theme}
                  options={filterlists.emotion}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-emotional-state ANTselectionfields">
                Physical States
                <CreatableSelect
                  isMulti
                  isClearable
                  styles={dropdownStyles}
                  value={selectedPhysical}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedPhysical: e });
                  }}
                  theme={theme}
                  options={filterlists.physical}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-time-frame ANTselectionfields">
                Time Frame
                <CreatableSelect
                  isClearable
                  styles={dropdownStyles}
                  value={selectedTimeframe}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedTimeframe: e });
                  }}
                  options={filterlists.timeframeWithDefault}
                  theme={theme}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-mistake ANTselectionfields">
                Mistakes
                <CreatableSelect
                  isMulti
                  styles={dropdownStyles}
                  isClearable
                  value={selectedMistake}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedMistake: e });
                  }}
                  theme={theme}
                  options={filterlists.mistake}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-market-state ANTselectionfields">
                Market State
                <CreatableSelect
                  isClearable
                  styles={dropdownStyles}
                  value={selectedMarket}
                  isDisabled={show100TradesCheck}
                  onChange={(e) => {
                    setState({ ...state, selectedMarket: e });
                  }}
                  options={filterlists.marketStateWithDefault}
                  theme={theme}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
            <div className="main-labels">
              <label className="select-market-state ANTselectionfields">
                Tags
                <CreatableSelect
                  isMulti
                  styles={dropdownStyles}
                  isClearable
                  isDisabled={show100TradesCheck}
                  value={tags}
                  onChange={(e) => {
                    setState({ ...state, tags: e });
                  }}
                  options={filterlists.tags}
                  theme={theme}
                  placeholder={<em>Optional</em>}
                />
              </label>
            </div>
          </CardColumn>
        </InputCards>
      </CardWrapper>
      <div className="addimage">
        <div className="ANT-heading-image">Pictures</div>
        <div className="addimageinner">{imageDisplayFunction()}</div>
      </div>
      <div className="comment-label">
        <div className="ANT-heading-comments">Notes</div>
        <NoteComponent
          originalState={comments}
          setNoteState={(e) => {
            setState({ ...state, comments: e });
          }}
        />
      </div>
      <div className="logtradebuttonwrapper">
        <button
          className="logtradebutton"
          disabled={show100TradesCheck ? show100TradesCheck : isDisabled}
          onClick={async () => await handleSubmit()}
        >
          Log Trade
        </button>
      </div>
    </div>
  );
};

export default AddTradeForm;
