import React from "react";
import "./tierstyles.scss";
import { useHistory } from "react-router-dom";

export const FreeTierComponent = () => {
  const history = useHistory();

  const component = (
    <span
      onClick={() =>
        history.push({
          pathname: "/Manage-Account",
          state: {
            account: "none",
            subscription: "block",
            actions: "none",
          },
        })
      }
      id="proTierComponent"
    >
      Pro
    </span>
  );
  return component;
};

export default FreeTierComponent;
