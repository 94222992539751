import React, { useEffect, useContext, useState, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import theme from "../selectTheme";
import store from "../../store";
import createOption from "./createOption";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { DataContext } from "../../DataContext";
import dropdownStyles from "../../functions/dropdownStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import isObjectEqual from "../../functions/isObjectEqual";
import isArray from "../../functions/arrayFunctions/isArray";

export const CreateableFilter = (props) => {
  const filterOptionsInit = props.defaultOptions;
  const filterLabel = props.filterLabel;
  const isMulti = props.isMulti;
  const defaultValue = props.defaultFilterValue;
  const firstUpdate = useRef(true);
  const history = useHistory();
  const [showDropdown, setshowDropdown] = useState();
  const [filterOptions, setfilterOptions] = useState(filterOptionsInit);
  const [scrollOffset, setScrollOffset] = useState(0);

  const [selectedItems, setselectedItems] = useState(defaultValue || []);
  function removeObjectByLabel(array, labelToRemove) {
    return array.filter((obj) => obj.label !== labelToRemove);
  }
  function filterObjects(originalArray, filterArray) {
    return originalArray?.filter(
      (obj) =>
        isArray(filterArray) &&
        !filterArray?.some(
          (filterObj) =>
            filterObj?.label === obj?.label && filterObj?.value === obj?.value
        )
    );
  }
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const buttonSmallRef = useRef(null);

  const handleSubmit = async () => {
    const reduxFilters = store.getState().defaultfilterValue.filtervalue;
    const filterProp = !props.allData.portfolio.settings.filters
      ? {
          dateRangeFilter: null,
          hourRangeFilter: null,
          orderTypeFilter: null,
          symbolFilter: null,
          strategyFilter: null,
          emotionalStateFilter: null,
          physicalStateFilter: null,
          mistakeFilter: null,
          timeFrameFilter: null,
          confidenceFilter: null,
          marketStateFilter: null,
          dayOfWeekFilter: null,
          monthFilter: null,
          assetClassFilter: null,
          tagsFilter: null,
          yearFilter: null,
        }
      : reduxFilters;

    localStorage.setItem("filtersAreRunning", "yes");
    localStorage.setItem("filters", JSON.stringify(filterProp));
  };

  const handleChange = async (newValue, type) => {
    const savecheck =
      props.allData && props.allData.portfolio.settings.savefilters;
    const newState = isMulti
      ? newValue === []
        ? null
        : type === "remove"
        ? newValue
        : [...selectedItems, newValue]
      : newValue;
    setselectedItems(newState);
    let buffer = newState;
    if (buffer === null) {
      props.selectedOptions(buffer);
      history.push("/BlankPage");
      history.goBack();
      savecheck && handleSubmit(); // crucial this is last
    } else if (
      buffer.label === "Custom Range" &&
      props.id === "dateRangeFilter"
    ) {
      $("#choose-date-range").fadeIn(300);
      $("#overlay").fadeIn(300);
      setselectedItems({ value: "" });
      document.getElementById("choose-date-range").style.display = "flex";
    } else if (
      buffer.label === "Custom Range" &&
      props.id === "hourRangeFilter"
    ) {
      $("#choose-time-range").fadeIn(300);
      $("#overlay").fadeIn(300);
      setselectedItems({ value: "" });
      document.getElementById("choose-time-range").style.display = "flex";
    } else {
      props.selectedOptions(buffer);
      history.push("/BlankPage");
      history.goBack();
      savecheck && handleSubmit();
    }
  };

  const handleClickOutside = (event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setshowDropdown(false);
    }
  };
  useEffect(() => {
    if (firstUpdate.current) {
      const filteredArray = filterObjects(filterOptions, selectedItems);
      setfilterOptions(filteredArray);
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [selectedItems]);
  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("click", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const adjustDropdownPosition = () => {
      const buttonRect = buttonSmallRef.current.getBoundingClientRect();
      const dropdown = containerRef.current;
      const dropdownRect = containerRef.current.getBoundingClientRect();

      const container = document.querySelector(".contentLB > div");
      const containerRect = container.getBoundingClientRect();
      // need to offeset the dropdown if scrolled
      const heightToAdjust = 900;
      const widthToAdjust = 1680;
      var width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      var height =
        window.innerHeight > 0 ? window.innerHeight : window.screen.height;

      dropdown.style.top = `143px`;
      if (width < widthToAdjust || height < heightToAdjust) {
        dropdown.style.left = `${
          1.25 * (buttonRect.right - dropdownRect.width + 4)
        }px`;
      } else {
        dropdown.style.left = `${buttonRect.right - dropdownRect.width + 4}px`;
      }
    };

    if (showDropdown) {
      adjustDropdownPosition();
      window.addEventListener("scroll", adjustDropdownPosition);
    }

    return () => {
      window.removeEventListener("scroll", adjustDropdownPosition);
    };
  }, [showDropdown]);
  return (
    <div className="filterSelectedContainerWrapper">
      <div className="filterSelectedLabel">{filterLabel}</div>
      <div
        key={filterLabel}
        className="filterSelectedContainer"
        ref={buttonRef}
        onClick={() => {
          const buttonRect = buttonSmallRef.current.getBoundingClientRect();
          const container = document.querySelector(".contentLB > div");
          const containerRect = container.getBoundingClientRect();
          const minWidth = containerRect.width - buttonRect.left + 28;
          if (minWidth > 70) {
            if (showDropdown) {
              setshowDropdown(false);
            } else {
              if (isArray(filterOptions)) {
                if (!filterOptions.length) {
                  setshowDropdown(false);
                } else {
                  setshowDropdown(true);
                }
              } else {
                setshowDropdown(false);
              }
            }
          }
        }}
      >
        <div className="filterSelectedContainerInside">
          {!isMulti ? (
            <div className="filterSelectedContainerInsideSingle">
              {selectedItems?.label}
              {!selectedItems?.label ||
                (selectedItems?.label !== "" && (
                  <button
                    className="filterSelectedContainerInsideX"
                    onClick={(e) => {
                      handleChange(null, "");
                    }}
                  ></button>
                ))}
            </div>
          ) : (
            <div className="filterSelectedContainerInsideMultiWrapper">
              {selectedItems?.map((item) => {
                return (
                  <div className="filterSelectedContainerInsideButton">
                    {item?.label}
                    <button
                      className="filterSelectedContainerInsideXrel"
                      onClick={(e) => {
                        const selectedItemsTemp = selectedItems;
                        const filteredOptions = removeObjectByLabel(
                          selectedItemsTemp,
                          item?.label
                        );
                        handleChange(filteredOptions, "remove");
                      }}
                    ></button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div ref={buttonSmallRef} className="filterSelectedContainerButton">
          <FontAwesomeIcon
            className="inner-metric-cell1PM2"
            icon={faCaretDown}
          />
        </div>
      </div>
      {showDropdown && (
        <div
          className="filterOptionsContainer noselect"
          ref={containerRef}
          id="filterOptionsContainer"
        >
          {filterOptions.map((option) => {
            return (
              <div
                key={filterLabel + option.label}
                className="filterOptionsButton"
                onClick={() => {
                  handleChange(option, "");
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default CreateableFilter;
