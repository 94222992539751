import store from "../store";
import { fetchFilterLists } from /*   fetchFilterListsFULL,
 */ "../functions/createDropdownOptions";
//import filterData from "../functions/filterData";
import OverallPerformanceCalculations from "../calculations/overallPerformance";
import PerformanceMetricsCalculations from "../calculations/importantMetrics";
import drawdownCalculations from "../calculations/drawdown";
import dashboardCalculations from "../calculations/dashboardCalculations";
import simulatorCalculations from "../calculations/simulatorCalculations";
import getEntryExitLots from "../functions/getEntryExitLots";
import quantumCalculations from "../calculations/quantumCalculations";

import getFilteredList from "../actions/actionCreators";
import moneyManagementCalculations from "../calculations/moneyManagement";
import {
  GetFUNCTIONFormattedDate,
  GetFormattedDate,
} from "../components/Right-Panel/Filter-Menu/getFormattedDate";
import moment from "moment/moment";
import getSpecificPortofolio from "./../functions/getSpecificPortfolio";
import isArray from "../functions/arrayFunctions/isArray";
//mport retry from "./retryOperation"
//import robinhoodWebApi from "../utils/brokers/robinhood";

//import updateSharedTrades from "./../utils/editSharedTrades";

localStorage.setItem("reloadFilters", "yes");

export const processDataandSettings = (
  allDataIn,
  tier,
  active,
  riskFreeRate
) => {
  const page = window.location.pathname;
  let entryData = []; // all trades from SELECTED portfolios & filters
  let fullData = []; // ALL TRADES in SELECTED portfolios

  let globalCalcs = {}; // calculations for all portfolios, individually
  let allAssetClasses = []; // All selected portfolios' asset classes
  //let calculationData = 0;
  let startingDW = 0;
  let startingBalance = 0;
  // ---------------------------
  let userCalculations = 0;
  // ---------------------------

  const specificPortfolio = getSpecificPortofolio(allDataIn);
  const maxEntries = 30;

  let createdPortfolios = allDataIn.createdPortfolios;
  let selectedPortfolios = allDataIn.chosenPortfolios;

  // ------------------ PORTFOLIO TIERING ------------------
  if (tier === "free") {
    createdPortfolios = createdPortfolios;
    selectedPortfolios = selectedPortfolios;
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
  } else if (tier === "ultimate" && active) {
  } else if (tier === "admin" && active) {
  } else {
    createdPortfolios = createdPortfolios;
    selectedPortfolios = selectedPortfolios;
  }

  // ------------------ *** FILTERING *** ------------------
  let reduxFilterState = [];
  const allIds = [
    "orderTypeFilter",
    "symbolFilter",
    "strategyFilter",
    "emotionalStateFilter",
    "physicalStateFilter",
    "mistakeFilter",
    "timeFrameFilter",
    "confidenceFilter",
    "marketStateFilter",
    "dayOfWeekFilter",
    "monthFilter",
    "assetClassFilter",
    "tagsFilter",
    "yearFilter",
  ];
  const allNames = [
    "orderType",
    "symbol",
    "strategy",
    "selectedEmotion",
    "selectedPhysical",
    "selectedMistake",
    "selectedTimeframe",
    "selectedConfidence",
    "selectedMarket",
    "dayOfWeek",
    "monthOfYear",
    "selectedPortfolioType",
    "tags",
  ];
  const addedType = localStorage.getItem("reloadFilters");
  const localFilters = JSON.parse(localStorage.getItem("filters"));

  const saveFiltersCheck = !specificPortfolio
    ? false
    : specificPortfolio.settings.savefilters;

  // Set intial redux filter state
  if (addedType === "yes") {
    reduxFilterState =
      localFilters !== null ? localFilters : specificPortfolio.settings.filters;
    if (specificPortfolio.settings.savefilters === false) {
      reduxFilterState = {
        dateRangeFilter: null,
        hourRangeFilter: null,
        orderTypeFilter: null,
        symbolFilter: null,
        strategyFilter: null,
        emotionalStateFilter: null,
        physicalStateFilter: null,
        mistakeFilter: null,
        timeFrameFilter: null,
        confidenceFilter: null,
        marketStateFilter: null,
        dayOfWeekFilter: null,
        monthFilter: null,
        assetClassFilter: null,
        tagsFilter: null,
        yearFilter: null,
      };
    } else {
    }
    allIds.forEach((filter) => {
      for (const item in reduxFilterState) {
        if (item === filter) {
          const innercheck =
            saveFiltersCheck === false ? null : reduxFilterState[item];
          store.dispatch(getFilteredList(filter, innercheck));
        } else {
        }
      }
    });
    const innercheckDT =
      saveFiltersCheck === false ? null : reduxFilterState["dateRangeFilter"];
    store.dispatch(getFilteredList("dateRangeFilter", innercheckDT));
    const innercheckT =
      saveFiltersCheck === false ? null : reduxFilterState["hourRangeFilter"];
    store.dispatch(getFilteredList("hourRangeFilter", innercheckT));
    localStorage.setItem("reloadFilters", "no");
  } else {
    reduxFilterState = store.getState().defaultfilterValue.filtervalue;
  }
  const createFilterLabel = (filterState, filter) => {
    let filterLabel = [];
    if (filterState[filter] === null) {
    } else {
      for (let i = 0, j = filterState[filter].length; i < j; i++) {
        filterLabel = filterLabel.concat(filterState[filter][i]?.label);
      }
    }
    return filterLabel;
  };
  // Handle Date Range
  const dateRangeFilter = reduxFilterState["dateRangeFilter"];
  var startDateBUFFER = new Date(-8640000000000000);
  var endDateBUFFER = new Date(8640000000000000);
  var bufferdatetoday = new Date();
  const dateRangeFilterlength =
    Object.keys(dateRangeFilter === null ? 0 : dateRangeFilter).length / 2;

  if (dateRangeFilter === null) {
  } else {
    if (dateRangeFilterlength > 1) {
      startDateBUFFER = dateRangeFilter[0];
      endDateBUFFER = dateRangeFilter[1];
    } else {
      if (dateRangeFilter.label === "Today") {
        startDateBUFFER = GetFormattedDate();
      }
      if (dateRangeFilter.label === "Last Week") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          bufferdatetoday.setDate(bufferdatetoday.getDate() - 7)
        );
      }
      if (dateRangeFilter.label === "Last Month") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          bufferdatetoday.setDate(bufferdatetoday.getDate() - 30)
        );
      }
      if (dateRangeFilter.label === "Last 3 Months") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          bufferdatetoday.setDate(bufferdatetoday.getDate() - 90)
        );
      }
      if (dateRangeFilter.label === "Last Year") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          bufferdatetoday.setDate(bufferdatetoday.getDate() - 365)
        );
      }
      if (dateRangeFilter.label === "This Year") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          new Date().getFullYear() + "-01-01"
        );
      }

      if (dateRangeFilter.label === "This Month") {
        startDateBUFFER = GetFUNCTIONFormattedDate(
          new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01"
        );
      }

      if (dateRangeFilter.label === "This Week") {
        var firstDayOfWeek = new Date().getDate() - new Date().getDay();
        startDateBUFFER = GetFUNCTIONFormattedDate(
          new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            firstDayOfWeek
        );
      }
    }
  }

  // Time filtering
  var startTimeBUFFER = "00:00:00";
  var endTimeBUFFER = "23:59:59";
  const hourRangeFilter = reduxFilterState["hourRangeFilter"];
  const hourRangeFilterlength =
    Object.keys(hourRangeFilter === null ? 0 : hourRangeFilter).length / 2;
  if (hourRangeFilter === null) {
  } else {
    if (hourRangeFilterlength > 1) {
      startTimeBUFFER = hourRangeFilter[0];
      endTimeBUFFER = hourRangeFilter[1];
    } else {
      if (hourRangeFilter.label === "00:00:00 - 00:59:59") {
        startTimeBUFFER = "00:00:00";
        endTimeBUFFER = "00:59:59";
      } else if (hourRangeFilter.label === "01:00:00 - 01:59:59") {
        startTimeBUFFER = "01:00:00";
        endTimeBUFFER = "01:59:59";
      } else if (hourRangeFilter.label === "02:00:00 - 02:59:59") {
        startTimeBUFFER = "02:00:00";
        endTimeBUFFER = "02:59:59";
      } else if (hourRangeFilter.label === "03:00:00 - 03:59:59") {
        startTimeBUFFER = "03:00:00";
        endTimeBUFFER = "03:59:59";
      } else if (hourRangeFilter.label === "04:00:00 - 04:59:59") {
        startTimeBUFFER = "04:00:00";
        endTimeBUFFER = "04:59:59";
      } else if (hourRangeFilter.label === "05:00:00 - 05:59:59") {
        startTimeBUFFER = "05:00:00";
        endTimeBUFFER = "05:59:59";
      } else if (hourRangeFilter.label === "06:00:00 - 06:59:59") {
        startTimeBUFFER = "06:00:00";
        endTimeBUFFER = "06:59:59";
      } else if (hourRangeFilter.label === "07:00:00 - 07:59:59") {
        startTimeBUFFER = "07:00:00";
        endTimeBUFFER = "07:59:59";
      } else if (hourRangeFilter.label === "08:00:00 - 08:59:59") {
        startTimeBUFFER = "08:00:00";
        endTimeBUFFER = "08:59:59";
      } else if (hourRangeFilter.label === "09:00:00 - 09:59:59") {
        startTimeBUFFER = "09:00:00";
        endTimeBUFFER = "09:59:59";
      } else if (hourRangeFilter.label === "10:00:00 - 10:59:59") {
        startTimeBUFFER = "10:00:00";
        endTimeBUFFER = "10:59:59";
      } else if (hourRangeFilter.label === "11:00:00 - 11:59:59") {
        startTimeBUFFER = "11:00:00";
        endTimeBUFFER = "11:59:59";
      } else if (hourRangeFilter.label === "12:00:00 - 12:59:59") {
        startTimeBUFFER = "12:00:00";
        endTimeBUFFER = "12:59:59";
      } else if (hourRangeFilter.label === "13:00:00 - 13:59:59") {
        startTimeBUFFER = "13:00:00";
        endTimeBUFFER = "13:59:59";
      } else if (hourRangeFilter.label === "14:00:00 - 14:59:59") {
        startTimeBUFFER = "14:00:00";
        endTimeBUFFER = "14:59:59";
      } else if (hourRangeFilter.label === "15:00:00 - 15:59:59") {
        startTimeBUFFER = "15:00:00";
        endTimeBUFFER = "15:59:59";
      } else if (hourRangeFilter.label === "16:00:00 - 16:59:59") {
        startTimeBUFFER = "16:00:00";
        endTimeBUFFER = "16:59:59";
      } else if (hourRangeFilter.label === "17:00:00 - 17:59:59") {
        startTimeBUFFER = "17:00:00";
        endTimeBUFFER = "17:59:59";
      } else if (hourRangeFilter.label === "18:00:00 - 18:59:59") {
        startTimeBUFFER = "18:00:00";
        endTimeBUFFER = "18:59:59";
      } else if (hourRangeFilter.label === "19:00:00 - 19:59:59") {
        startTimeBUFFER = "19:00:00";
        endTimeBUFFER = "19:59:59";
      } else if (hourRangeFilter.label === "20:00:00 - 20:59:59") {
        startTimeBUFFER = "20:00:00";
        endTimeBUFFER = "20:59:59";
      } else if (hourRangeFilter.label === "21:00:00 - 21:59:59") {
        startTimeBUFFER = "21:00:00";
        endTimeBUFFER = "21:59:59";
      } else if (hourRangeFilter.label === "22:00:00 - 22:59:59") {
        startTimeBUFFER = "22:00:00";
        endTimeBUFFER = "22:59:59";
      } else if (hourRangeFilter.label === "23:00:00 - 23:59:59") {
        startTimeBUFFER = "23:00:00";
        endTimeBUFFER = "23:59:59";
      }
    }
  }
  const dateFIlterBy =
    specificPortfolio.settings.dateFilterBy === "Trade Open"
      ? "startDateTime"
      : "endDateTime";
  let input2 = [
    {
      Field: dateFIlterBy,
      Operation: "DATEBETWEEN",
      Values: { startDate: startDateBUFFER, endDate: endDateBUFFER },
    },
    {
      Field: dateFIlterBy,
      Operation: "TIMEBETWEEN",
      Values: { startTime: startTimeBUFFER, endTime: endTimeBUFFER },
    },
    {
      Field: "selectedPortfolio",
      Operation: "INCLUDES",
      Values: selectedPortfolios,
    },
  ];
  let input3 = [
    {
      Field: "selectedPortfolio",
      Operation: "INCLUDES",
      Values: selectedPortfolios,
    },
  ];
  // Push rest of filters as "includes" logic
  for (let i = 0, j = allIds.length; i < j; i++) {
    if (!createFilterLabel(reduxFilterState, allIds[i]).length) {
    } else {
      if (allIds[i] === "symbolFilter") {
        const pushdis = {
          Field: allNames[i],
          Operation: "MULTIINCLUDES_SYMBOL",
          Values: createFilterLabel(reduxFilterState, allIds[i]),
        };
        input2 = input2.concat(pushdis);
      } else if (
        allIds[i] === "orderTypeFilter" ||
        allIds[i] === "assetClassFilter" ||
        allIds[i] === "confidenceFilter" ||
        allIds[i] === "timeFrameFilter" ||
        allIds[i] === "marketStateFilter" ||
        allIds[i] === "dayOfWeekFilter" ||
        allIds[i] === "monthFilter"
      ) {
        const pushdis = {
          Field: allNames[i],
          Operation: "INCLUDES",
          Values: createFilterLabel(reduxFilterState, allIds[i]),
        };
        input2 = input2.concat(pushdis);
      } else {
        const pushdis = {
          Field: allNames[i],
          Operation: "MULTIINCLUDES",
          Values: createFilterLabel(reduxFilterState, allIds[i]),
        };
        input2 = input2.concat(pushdis);
      }
    }
  }

  // Iterate through each created portfolio
  // Set the starting balances from the settings
  // Sort each array then add "balance" to each trade
  // as well as create the "entryData" array of all trades from slected portfolios
  // fullData is like entryData but does not go through all filters, only the portfolio filter
  let totalBalanceofSelectedPortfolios = 0;
  for (let i = 0, j = createdPortfolios.length; i < j; i++) {
    if (createdPortfolios[i].name === "Default") {
    } else {
      let entries = createdPortfolios[i].entries;
      if (tier === "free") {
        entries = entries.slice(-maxEntries);
      } else if (tier === "pro" && active) {
      } else if (tier === "oldpro" && active) {
      } else if (tier === "master" && active) {
      } else if (tier === "ultimate" && active) {
      } else if (tier === "admin" && active) {
      } else {
        entries = entries.slice(-maxEntries);
      }
      let balanceForGlobalCalcs = Number(
        createdPortfolios[i].settings.startingBalance
      ); // for the global calcuations

      /*       let totalDWPerSelectedPortfolio = Number(
        createdPortfolios[i].settings.startingBalance
      ); */
      let totalDWforGlobalCalcs = Number(
        createdPortfolios[i].settings.startingBalance
      ); // for the global calcuations

      let totalProfits = 0;
      let totalLosses = 0;
      let totalTrades = 0;
      let totalWinningTrades = 0;
      let totalLosingTrades = 0;
      let totalPL_dollar = 0;
      globalCalcs[createdPortfolios[i].name] = {
        currentBalance: balanceForGlobalCalcs, // start with the starting balance
        totalPL: 0,
        totalPLPercent: 0,
        totalEntries: 0,
        tfIndex: 0,
      };

      // ------------- set starting Balance -------------
      // We add them if the user has multiple portfolios selected!
      // Only execute these lines of code if the portfolio is selected
      if (selectedPortfolios.includes(createdPortfolios[i].name)) {
        totalBalanceofSelectedPortfolios += Number(
          createdPortfolios[i].settings.startingBalance
        );
        startingBalance += Number(
          createdPortfolios[i].settings.startingBalance
        );
        startingDW += Number(createdPortfolios[i].settings.startingBalance);
        allAssetClasses.push(...createdPortfolios[i].assetClasses);

        // Have to sort here by date
        if (entries === null || entries.length === 1) {
        } else {
          entries.sort(function (a, b) {
            var c = new Date(
              !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
            );
            var d = new Date(
              !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
            );
            return c - d;
          });
        }

        // add calculationType to each trade first
        for (let ii = 0, jj = entries.length; ii < jj; ii++) {
          entryData.push({
            entry: entries[ii].entry,
            entryId: entries[ii].entryId,
            calculationType: createdPortfolios[i].settings.calculationType,
          });
        }
      }

      // Global calculations, mainly for the portfolio tooltip
      for (let ii = 0, jj = entries.length; ii < jj; ii++) {
        let correctedSUM = 0;
        let x100profitLoss = Number(entries[ii].entry.profitLoss * 100);
        let x100commissions = Number(entries[ii].entry.commissions * 100);
        let x100fees = Number(entries[ii].entry.fees * 100);
        if (createdPortfolios[i].settings.calculationType === "Gross") {
          correctedSUM = x100profitLoss / 100;
        } else if (createdPortfolios[i].settings.calculationType === "Net") {
          correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
        }
        let multiExecution = entries[ii].entry.multiExecution;
        let exitExecution = entries[ii].entry.exitExecution;

        const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

        const exitLots = entryExitValues.exitLots;
        if (
          entries[ii].entry.orderType === "Deposit" ||
          entries[ii].entry.orderType === "Withdrawal" ||
          entries[ii].entry.orderType === "Funding Payment" ||
          entries[ii].entry.orderType === "Commit" ||
          entries[ii].entry.orderType === "Approval" ||
          entries[ii].entry.orderType === "Wrap" ||
          entries[ii].entry.orderType === "Self"
        ) {
          totalDWforGlobalCalcs += correctedSUM;
        } else if (exitLots === 0) {
          continue; // exclude trades with no exit executions
        } else {
          totalPL_dollar += correctedSUM;
          totalTrades += 1;
          if (correctedSUM > 0) {
            totalWinningTrades += 1;
            totalProfits += correctedSUM;
          } else if (correctedSUM < 0) {
            totalLosingTrades += 1;
            totalLosses += correctedSUM;
          }
          globalCalcs[createdPortfolios[i].name].totalPL = totalPL_dollar;
        }

        balanceForGlobalCalcs += correctedSUM;
        //console.log(balance);
        // Calculate global calcs
        globalCalcs[createdPortfolios[i].name].currentBalance =
          balanceForGlobalCalcs;
        globalCalcs[createdPortfolios[i].name].totalEntries = entries?.length;
        let percentgain = (totalPL_dollar / totalDWforGlobalCalcs) * 100;
        globalCalcs[createdPortfolios[i].name].totalPLPercent = percentgain;
        let averagePLPerWindollar = totalProfits / totalWinningTrades;
        let averagePLPerLossdollar = totalLosses / totalLosingTrades;
        let profitLossRatio =
          averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
        let winRate = totalWinningTrades / totalTrades;

        let tfIndex = winRate * (1 + profitLossRatio);
        globalCalcs[createdPortfolios[i].name].tfIndex = tfIndex;
      }
    }
  }

  // add "balance" and calculate global calcs
  // and create the "entryData" array of all trades from selected portfolios
  let entryDataTemp = entryData.slice();
  entryData = [];
  fullData = [];
  // Have to sort here by date
  if (entryDataTemp === null || entryDataTemp.length === 1) {
  } else {
    entryDataTemp.sort(function (a, b) {
      var c = new Date(
        !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
      );
      var d = new Date(
        !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
      );
      return c - d;
    });
  }
  for (let ii = 0, jj = entryDataTemp.length; ii < jj; ii++) {
    let correctedSUM = 0;
    const calcType = entryDataTemp[ii].calculationType;
    let x100profitLoss = Number(entryDataTemp[ii].entry.profitLoss * 100);
    let x100commissions = Number(entryDataTemp[ii].entry.commissions * 100);
    let x100fees = Number(entryDataTemp[ii].entry.fees * 100);
    if (calcType === "Gross") {
      correctedSUM = x100profitLoss / 100;
    } else if (calcType === "Net") {
      correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
    }
    totalBalanceofSelectedPortfolios += correctedSUM;
    entryData.push({
      entry: entryDataTemp[ii].entry,
      entryId: entryDataTemp[ii].entryId,
      balance: totalBalanceofSelectedPortfolios,
      calculationType: calcType,
    });
    fullData.push({
      entry: entryDataTemp[ii].entry,
      entryId: entryDataTemp[ii].entryId,
      balance: totalBalanceofSelectedPortfolios,
      calculationType: calcType,
    });
  }

  // ------------------ HANDLE TIERING FIRST HERE ------------------
  if (tier === "free") {
    entryData = entryData.slice(-maxEntries);
    fullData = fullData.slice(-maxEntries);
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
  } else if (tier === "ultimate" && active) {
  } else if (tier === "admin" && active) {
  } else {
    entryData = entryData.slice(-maxEntries);
    fullData = fullData.slice(-maxEntries);
  }

  // --------- FILTER THE ENTRIES ARRAY ---------
  const convertToSeconds = (time) => {
    var a = time.split(":"); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = a[0] * 60 * 60 + a[1] * 60 + a[2];

    return seconds;
  };
  const convertToTime = (date) => {
    let resultnew = moment(date).format("HH:mm:ss");
    return resultnew;
  };
  const findOneInOtherArray = (haystack, arr) => {
    return isArray(arr) && arr.some((v) => haystack.includes(v));
  };
  const findOne = (haystack, arr) => {
    return haystack.includes(arr);
  };
  const entryDataNew = [...entryData];
  const data = entryDataNew,
    input = input2,
    operators = {
      DATEBETWEEN(k, v) {
        if (!dateRangeFilter) {
          return (o) => o;
        } else {
          return (o) =>
            new Date(o.entry[k]) >= new Date(v.startDate) &&
            new Date(o.entry[k]) <= new Date(v.endDate);
        }
      },
      TIMEBETWEEN(k, v) {
        if (!hourRangeFilter) {
          return (o) => o;
        } else {
          return (o) =>
            Number(convertToSeconds(convertToTime(o.entry[k]))) >=
              Number(convertToSeconds(v.startTime)) &&
            Number(convertToSeconds(convertToTime(o.entry[k]))) <=
              Number(convertToSeconds(v.endTime));
        }
      },
      INCLUDES(k, v) {
        return (o) => findOne(v, o.entry[k]);
      },
      MULTIINCLUDES(k, v) {
        return (o) => findOneInOtherArray(v, o.entry[k]);
      },
      MULTIINCLUDES_SYMBOL(k, v) {
        return (o) => findOneInOtherArray(v, o.entry[k].symbols);
      },
    },
    filters = input.map(({ Field, Operation, Values }) =>
      operators[Operation](Field, Values)
    ),
    result = data.filter((o) => filters.every((f) => f(o)));
  // ------------------

  // entry data is the filtered data
  entryData = result;

  // full Data only filtered by selected portfolios
  const fullDataNew = [...fullData];
  const data2 = fullDataNew,
    input22 = input3,
    operators2 = {
      INCLUDES(k, v) {
        return (o) => findOne(v, o.entry[k]);
      },
    },
    filters2 = input22.map(({ Field, Operation, Values }) =>
      operators2[Operation](Field, Values)
    ),
    result2 = data2.filter((o) => filters2.every((f) => f(o)));
  fullData = result2;
  // all trades
  if (fullData === null) {
  } else {
    fullData.sort(function (a, b) {
      var c = new Date(
        !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
      );
      var d = new Date(
        !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
      );
      return c - d;
    });
  }
  // We have to sort entryData again, globally
  if (entryData === null) {
  } else {
    entryData.sort(function (a, b) {
      var c = new Date(
        !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
      );
      var d = new Date(
        !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
      );
      return c - d;
    });
  }
  // -------------------------------------------------------

  // startingDW is separate from startingBalance.
  // Adding something to one does not add it to the other.
  // use startingDW sparingly. Its currently only used in the cummulative return graph and %change calcs

  // ----------------------------------------

  // ----------- Set calculation props for specific pages -----------
  // tier and active variables passed in to prvent users form seeing data they aren't paying for
  if (page === "/Comprehensive") {
    const userCalculationsPro = OverallPerformanceCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/KPIs") {
    const userCalculationsPro = PerformanceMetricsCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW,
      riskFreeRate
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Streak-Odds-Calculator") {
    const userCalculationsPro = drawdownCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Drawdown-RunUp") {
    const userCalculationsPro = drawdownCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Compare-&-Conquer") {
    const userCalculationsPro = {
      start_DW: startingDW,
    };
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Monte-Carlo-Simulator") {
    const userCalculationsPro = simulatorCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Risk-Management") {
    const userCalculationsPro = moneyManagementCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
    if (tier === "free") {
    } else if (tier === "pro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "oldpro" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "master" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "ultimate" && active) {
      userCalculations = userCalculationsPro;
    } else if (tier === "admin" && active) {
      userCalculations = userCalculationsPro;
    } else {
    }
  } else if (page === "/Dashboard" || page === "/") {
    userCalculations = dashboardCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW,
      riskFreeRate,
      specificPortfolio.settings.dateFIlterBy
    );
  } else if (page === "/Quantum-Query") {
    userCalculations = quantumCalculations(
      entryData,
      specificPortfolio.settings.calculationType,
      startingBalance,
      startingDW
    );
  } else if (page === "/Logbook") {
    userCalculations = {
      startingBalance: startingBalance,
    };
  }
  // ------------------------------------

  // ------------ REMEMBER ------------
  // portfolio is the selected portfolio if there's only one selected, otherwise, it's
  /*{
    name: "Default"
    settings: default settings 
        **** currency symbol, starting balance, and commissions/fees
             are per trade settings, so decide when to use the actual portfolio setting
             or from the default setting on an ad hoc basis.
             For instance, we use actual settings for doing auto-import stuff, displaying a trades currency symbol etc
             But we use the default setting, for instance, for the label of "Current Equity"
    entries: combined entry array from selected portfolios
    assetClasses: combined asset classes arrays
  } */
  let tieredcalctype = "";
  let tieredsavefilters = "";

  if (tier === "free") {
    tieredcalctype = "Gross";
    tieredsavefilters = false;
  } else if (tier === "pro" && active) {
    tieredcalctype = specificPortfolio.settings.calculationType;
    tieredsavefilters = specificPortfolio.settings.savefilters;
  } else if (tier === "oldpro" && active) {
    tieredcalctype = specificPortfolio.settings.calculationType;
    tieredsavefilters = specificPortfolio.settings.savefilters;
  } else if (tier === "master" && active) {
    tieredcalctype = specificPortfolio.settings.calculationType;
    tieredsavefilters = specificPortfolio.settings.savefilters;
  } else if (tier === "ultimate" && active) {
    tieredcalctype = specificPortfolio.settings.calculationType;
    tieredsavefilters = specificPortfolio.settings.savefilters;
  } else if (tier === "admin" && active) {
    tieredcalctype = specificPortfolio.settings.calculationType;
    tieredsavefilters = specificPortfolio.settings.savefilters;
  } else {
    tieredcalctype = "Gross";
    tieredsavefilters = false;
  }
  const pseudoPortfolio = Object.assign({}, specificPortfolio, {
    entries: entryData,
    assetClasses: [...new Set(allAssetClasses)],
    settings: {
      ...specificPortfolio.settings,
      calculationType: tieredcalctype,
      savefilters: tieredsavefilters,
    }, //settings mainly here for logic for different tiers, added 5/26/23
  });
  const results = {
    globalCalculations: globalCalcs,
    calculations: userCalculations,
    portfolio: pseudoPortfolio, // only for the logbook state
    filterLists: fetchFilterLists(fullData, allAssetClasses),
    allData: allDataIn,
  };
  return results;
};

export default processDataandSettings;
