import { proddev } from "../../PROD-DEV-TOGGLE";

const uploadPicture = async (data, type, history) => {
  const stringifiedData = JSON.stringify(data);
  fetch(
    proddev === "prod"
      ? "https://opkt3gy2of.execute-api.us-west-1.amazonaws.com/prod/post-pictures"
      : "https://c5jfnnmixj.execute-api.us-west-1.amazonaws.com/default/post-pictures",
    {
      method: "POST",
      headers: {
        Accept: type,
        "Content-Type": type,
      },
      body: stringifiedData,
    }
  ).catch((err) => {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message,
      },
    });
    window.location.reload();
  });
};

export default uploadPicture;