export function getRobinhoodTokens() {
  const tokens = [];

  // Get all keys from localStorage
  const keys = Object.keys(localStorage);

  // Iterate over the keys and add items that start with "robinhoodToken" to the array
  keys.forEach((key) => {
    if (key.startsWith("robinhoodToken")) {
      const token = localStorage.getItem(key);
      tokens.push(token);
    }
  });

  return tokens;
}
export default getRobinhoodTokens;
