import React, { useEffect, useState, useCallback } from "react";
import $ from "jquery";
import getColorPoint from "../../functions/includeColor";
import { handleNANGOOD } from "../../functions/handleUndefined";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";

const ImportantMetricComponent = (props) => {
  const metric = props.metric;
  const calculations = props.userCalculations;
  const [factor, setfactor] = useState(0);
  const [factorRM, setfactorRM] = useState(0);
  const [riskWidth, setriskWidth] = useState(0);
  const [emptyWidth, setemptyWidth] = useState(0);
  useEffect(() => {
    const element = $("#MMimportantmetricgraphwraper");
    const elementRM = $("#MMimportantmetricgraphwraper2");
    const elementRisk = $("#MMimportantmetricgraphwraper3");

    let weightfactor =
      Number(element.width() - 10) / (calculations.averageRRR + 1);
    let weightfactorRM =
      Number(elementRM.width() - 10) / (calculations.rmultipleratio + 1);
    let riskWidthTemp =
      Number(elementRisk.width()) * (calculations.averageRisk / 100);
    setfactor(weightfactor);
    setfactorRM(weightfactorRM);
    setriskWidth(riskWidthTemp);
    setemptyWidth(Number(elementRisk.width()) - riskWidthTemp);
    $(window).resize(function () {
      let weightfactor =
        Number(element.width() - 10) / (calculations.averageRRR + 1);
      let weightfactorRM =
        Number(elementRM.width() - 10) / (calculations.rmultipleratio + 1);
      let riskWidthTemp =
        Number(elementRisk.width()) * (calculations.averageRisk / 100);
      setfactor(weightfactor);
      setfactorRM(weightfactorRM);
      setriskWidth(riskWidthTemp);
      setemptyWidth(Number(elementRisk.width()) - riskWidthTemp);
    });
  }, [
    calculations.averageRRR,
    calculations.rmultipleratio,
    calculations.averageRisk,
  ]);

  const graph = useCallback(() => {
    let returnGraph = "";
    if (metric.value === "avgRRR") {
      returnGraph = (
        <div id="MMimportantmetricgraphwraper">
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={
              <div style={{ color: "#fff7cb" }}>
                {"Reward: " + calculations.averageRRR}
              </div>
            }
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(calculations.averageRRR * factor, 0),
                backgroundColor: "#55f8a6",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                filter: "drop-shadow(0 0 2px #55f8a6)",
                WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
                MozFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
              }}
            ></div>{" "}
          </PopoverStickOnHover>
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={<div style={{ color: "#fff7cb" }}>{"Risk: " + 1}</div>}
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(factor, 0),
                backgroundColor: "#fa798b",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                filter: "drop-shadow(0 0 2px #fa798b)",
                WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
                MozFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
              }}
            ></div>{" "}
          </PopoverStickOnHover>
        </div>
      );
    } else if (metric.value === "rmultipleratio") {
      returnGraph = (
        <div id="MMimportantmetricgraphwraper2">
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            setClass="accountNumberSuperWrapper"
            xAdjust={10}
            yAdjust={0}
            component={
              <div style={{ color: "#fff7cb" }}>
                {"Avg. R-Multiple Wins: " + calculations.avgrMultipleWins + "R"}
              </div>
            }
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(calculations.rmultipleratio * factorRM, 0),
                backgroundColor: "#55f8a6",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                filter: "drop-shadow(0 0 2px #55f8a6)",
                WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
                MozFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
              }}
            ></div>
          </PopoverStickOnHover>
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            setClass="accountNumberSuperWrapper"
            xAdjust={10}
            yAdjust={0}
            component={
              <div style={{ color: "#fff7cb" }}>
                {"Avg. R-Multiple Losses: " +
                  calculations.avgrMultipleLosses +
                  "R"}
              </div>
            }
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(factorRM, 0),
                backgroundColor: "#fa798b",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                filter: "drop-shadow(0 0 2px #fa798b)",
                WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
                MozFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
              }}
            ></div>{" "}
          </PopoverStickOnHover>
        </div>
      );
    } else {
      //linear-gradient(to right, #55f8a6 0%, #e1f967 5%, #ff9735 10%, rgb(238, 73, 73) 15%, rgb(139, 43, 43) 20%)

      let color = "";
      if (calculations.averageRisk < 5) {
        color = getColorPoint(
          "#49f875",
          "#e1f967",
          calculations.averageRisk / 5,
          "hex"
        );
      } else if (
        calculations.averageRisk >= 5 &&
        calculations.averageRisk < 10
      ) {
        color = getColorPoint(
          "#e1f967",
          "#ff9735",
          (calculations.averageRisk - 5) / 5,
          "hex"
        );
      } else if (
        calculations.averageRisk >= 10 &&
        calculations.averageRisk < 15
      ) {
        color = getColorPoint(
          "#ff9735",
          "rgb(238, 73, 73)",
          (calculations.averageRisk - 10) / 5,
          "hex"
        );
      } else if (
        calculations.averageRisk >= 15 &&
        calculations.averageRisk < 20
      ) {
        color = getColorPoint(
          "rgb(238, 73, 73)",
          "rgb(139, 43, 43)",
          (calculations.averageRisk - 15) / 5,
          "hex"
        );
      } else {
        color = "rgb(139, 43, 43)";
      }
      returnGraph = (
        <div id="MMimportantmetricgraphwraper3">
          <div
            style={{
              display: "block",
              height: 6,
              width: handleNANGOOD(riskWidth, 0),
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              background: color,
              filter: "drop-shadow(0 0 3px #171c23)",
              WebkitFilter: "drop-shadow(0 0 3px #171c23)",
              MozFilter:
                "drop-shadow(0 0 3px #171c23)" /* Old versions of Firefox ffc20a*/,
              msFilter:
                "drop-shadow(0 0 3px #171c23)" /* Internet Explorer/Edge */,
            }}
          ></div>
          <div
            style={{
              display: "block",
              height: 6,
              width: handleNANGOOD(emptyWidth, 0),
              right: 6,
              borderBottomRightRadius: 3,
              borderTopRightRadius: 3,
              backgroundColor: "#1a2028",
            }}
          ></div>
        </div>
      );
    }
    return returnGraph;
  }, [
    emptyWidth,
    factorRM,
    factor,
    calculations.rmultipleratio,
    calculations.averageRRR,
    metric.value,
    riskWidth,
    calculations.averageRisk,
  ]);
  const metricvalue = () => {
    if (metric.value === "avgRRR") {
      return handleNANGOOD(calculations.averageRRR, "-") + " : 1";
    } else if (metric.value === "rmultipleratio") {
      return handleNANGOOD(calculations.rmultipleratio, "-") + " R";
    } else {
      return handleNANGOOD(calculations.averageRisk, "-") + "%";
    }
  };
  return (
    <div id="metricsratios">
      <div id="metricsratiosrightside">
        <div id="metricsratioslabel">{metric.label}</div>
        <div id="metricsratiosvalue">{metricvalue()}</div>
      </div>
      <div id="metricsratiosleftside">{graph()}</div>
    </div>
  );
};

export default ImportantMetricComponent;
