import getSession from "../../functions/getSession";
import getEntryExitLots from "../../functions/getEntryExitLots";

export const filterTheTrades = (
  whatToCompare,
  whatToCompareProp,
  selectedItem,
  filteredTradesPre,
  dateFilterBy
) => {
  if (!whatToCompare || !whatToCompareProp || !selectedItem) {
    return filteredTradesPre;
  }

  let filteredTrades = "";
  if (
    whatToCompare === "Confidences" ||
    whatToCompare === "Days of Week" ||
    whatToCompare === "Months" ||
    whatToCompare === "Time Frames" ||
    whatToCompare === "Order Types" ||
    whatToCompare === "Asset Classes" ||
    whatToCompare === "Portfolios" ||
    whatToCompare === "Market States"
  ) {
    filteredTrades = filteredTradesPre.filter(
      (trade) => trade.entry[whatToCompareProp] === selectedItem
    );
  } else if (whatToCompare === "Symbols") {
    filteredTrades = filteredTradesPre.filter(
      (trade) => trade.entry.symbol.symbols[0] === selectedItem
    );
  } else if (
    whatToCompare === "Strategies" ||
    whatToCompare === "Emotional States" ||
    whatToCompare === "Tags" ||
    whatToCompare === "Physical States" ||
    whatToCompare === "Mistakes"
  ) {
    filteredTrades = filteredTradesPre.filter(
      (trade) =>
        trade.entry[whatToCompareProp] !== null &&
        trade.entry[whatToCompareProp].includes(selectedItem)
    );
  } else if (whatToCompare === "Trading Sessions") {
    filteredTrades = filteredTradesPre.filter((trade) => {
      var dateTime = new Date(
        dateFilterBy === "Trade Open"
          ? trade.entry.startDateTime
          : trade.entry.endDateTime
      );

      // Add condition to filter trades based on trading session
      const tradingSession = getSession(dateTime);

      const selectedSession = selectedItem; // Replace with the selected trading session
      return dateTime !== null && tradingSession === selectedSession;
    });
  } else if (whatToCompare === "Years") {
    // Implement year filtering logic
    const selectedYear = parseInt(selectedItem, 10); // Ensure selectedItem is treated as an integer
    filteredTrades = filteredTradesPre.filter((trade) => {
      const tradeDate = new Date(trade.entry.startDateTime); // Assuming you want to filter based on startDateTime
      const tradeYear = tradeDate.getFullYear();
      return tradeYear === selectedYear;
    });
  } else if (whatToCompare === "Hour of Day") {
    const [startRange, endRange] = selectedItem
      .split(" - ")
      .map((time) => time.substring(0, 2));
    const startHour = parseInt(startRange, 10);
    const endHour = parseInt(endRange, 10);

    filteredTrades = filteredTradesPre.filter((trade) => {
      const tradeStartTime = new Date(
        dateFilterBy === "Trade Open"
          ? trade.entry.startDateTime
          : trade.entry.endDateTime
      );
      const tradeHour = tradeStartTime.getHours();
      // Handle the edge case where endHour is less than startHour (unlikely in this scenario, but good practice)
      if (startHour <= endHour) {
        return tradeHour >= startHour && tradeHour <= endHour;
      } else {
        return tradeHour >= startHour || tradeHour <= endHour;
      }
    });
  } else if (whatToCompare === "Custom Date Range") {
    const [startDateString, endDateString] = selectedItem;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    filteredTrades = filteredTradesPre.filter((trade) => {
      const tradeDate = new Date(
        dateFilterBy === "Trade Open"
          ? trade.entry.startDateTime
          : trade.entry.endDateTime
      );
      return tradeDate >= startDate && tradeDate <= endDate;
    });
  } else if (whatToCompare === "Volume") {
    filteredTrades = filteredTradesPre.filter((trade) => {
      const entryExecs = trade.entry.multiExecution;
      const exitExecs = trade.entry.exitExecution;
      const entryExitValues = getEntryExitLots(entryExecs, exitExecs);
      const entryLots = entryExitValues.entryLots;
      const exitLots = entryExitValues.exitLots;
      const totalVolume = entryLots + exitLots; // Assuming total volume is the sum of entry and exit lots

      // Parse the selected volume range
      let minVolume = 0, maxVolume = Infinity;
      if (selectedItem.includes("+")) {
        minVolume = parseInt(selectedItem.replace(/,/g, '').split('+')[0]);
      } else if (selectedItem.includes("-")) {
        const [min, max] = selectedItem.split(' - ').map(s => parseInt(s.replace(/,/g, ''), 10));
        minVolume = min;
        maxVolume = max;
      } else if (selectedItem.includes("<")) {
        maxVolume = parseInt(selectedItem.substring(1).replace(/,/g, ''), 10);
      }

      return totalVolume >= minVolume && totalVolume <= maxVolume;
    });
  }
  
  return filteredTrades;
};
export default filterTheTrades;
