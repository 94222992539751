import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import DeleteButton from "../../components/deleteButton";
import $ from "jquery";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import MetaApi from "metaapi.cloud-sdk";
import removeAllRobinhoodTokens from "../../functions/robinhood/removeRobinhoodTokens";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";

const NodeRSA = require("node-rsa");

const metaapi = new MetaApi(process.env.REACT_APP_METAAPIKEY);
const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");

export const UnlinkModal = (props) => {
  const { user /* isAuthenticated */ } = useAuth0();
  const allData = props.userData;
  const selectedBroker = props.selectedBroker;
  const selectedConnectedBroker = props.selectedConnectedBroker[0];
  // ------- BROKER/PLATFORM RECOGNITION ALGORITHM -------
  const selectedBrokerINIT = allData.portfolio.settings.linkedBrokerInfo;
  const allGlobalData = useContext(DataContext);

  const history = useHistory();

  const handleCloseLinkBroker = () => {
    props.setunlinkOpen(false);
    $("#autoimportunlinkmodalwrapper").fadeOut(300);
    $("#overlay").fadeOut(300);
  };

  // Function that actually sets Skynet data;
  // called individually in each switch case above
  const callsetData = async () => {
    handleCloseLinkBroker();
    store.dispatch(setLoaderState({ loading: true }));

    let propsToChange = {};
    let globalpropsToChange = {};

    // ----------- Remove all trades -----------
    let temparr = allData.allData.createdPortfolios.slice();
    for (
      var ie = 0, je = allData.allData.createdPortfolios.length;
      ie < je;
      ie++
    ) {
      let tempName = allData.allData.createdPortfolios[ie].name;
      let settings = allData.allData.createdPortfolios[ie].settings;
      const linkedBrokerInfo = settings.linkedBrokerInfo;
      if (
        tempName === "Default" ||
        linkedBrokerInfo.integrationId !== selectedConnectedBroker.integrationId
      ) {
      } else {
        // filter portfolios to only deal with the one selected
        if (linkedBrokerInfo.broker === "robinhood") {
          localStorage.removeItem(
            `robinhoodToken${linkedBrokerInfo.portfolio}`
          );
        } else if (linkedBrokerInfo.broker === "dYdX") {
          localStorage.removeItem(`candYdXOpen`);
          localStorage.removeItem(`canCallWS`);
        } else if (linkedBrokerInfo.broker === "metamask") {
          localStorage.removeItem(`canMetaMaskOpen`);
          localStorage.removeItem(`canCallWS`);
        } else if (
          linkedBrokerInfo.broker === "metatrader4" ||
          linkedBrokerInfo.broker === "metatrader5"
        ) {
          //decrypt credentials
          localStorage.removeItem(`canCallWS`);
          const apiCredsPre = linkedBrokerInfo.cred;
          const decryptedString = key.decrypt(apiCredsPre, "utf8");
          const apiCreds = JSON.parse(decryptedString);
          try {
            const account = await metaapi.metatraderAccountApi.getAccount(
              apiCreds
            );
            await account.remove();
          } catch (e) {
            console.log(e);
          }
        }
        temparr = temparr.filter((x) => x.name !== tempName);
        settings.linkedBrokerInfo = {
          broker: "",
        };
        settings.verified = 0;
        settings.verifiedDate = "";
        const portfolioedit = {
          name: allData.allData.createdPortfolios[ie].name,
          assetClasses: allData.allData.createdPortfolios[ie].assetClasses,
          settings: settings,
          entries: [],
        };
        const filteredConnections = allGlobalData.connectionsArray.filter(
          (conn) => conn.integrationId !== selectedConnectedBroker.integrationId
        );
        temparr = temparr.concat(portfolioedit);
        let propsToChange = {};

        // ------------------------------------------
        const finalPush = createDatafromSettingsProps(
          allData.allData.chosenPortfolios,
          temparr,
          propsToChange,
          globalpropsToChange,
          settings,
          allData.allData.globalSettings,
          allData.allData.version
        );
        const S3Data = {
          data: finalPush,
          userId: user.sub,
        };
        const customerId =
          finalPush === null ? "" : finalPush.globalSettings.stripeId;
        await updateStripeCustomerWrapper(
          customerId,
          finalPush,
          "trades",
          user.sub,
          history
        );

        await putUserData(S3Data, history);
        allGlobalData.updateAllData(finalPush);
        allGlobalData.setconnectionsArray(filteredConnections); // then add back to array of portfolios

        localStorage.setItem("savedsettings", "Unlink");

        history.push("/BlankPage");
        history.goBack();
        break;
      }
    }
  };

  let modalheader = "Unlink " + selectedBroker.label;

  return (
    <div id="autoimportunlinkmodalwrapper">
      <span className="autoimportmodalunlinkheading">{modalheader}</span>
      <div id="unlinkbrokertext">
        {`Are you sure you want to unlink ${selectedBroker.label}? Once unlinked, you will have to re-enter your login information to reconnect your account.`}
      </div>
      <span className="unlinkbuttonwrapper">
        <button
          name="cancelBrokerActualButton"
          className="cancelBrokerActualButton"
          onClick={handleCloseLinkBroker}
        >
          Cancel
        </button>
        <button
          name="linkBrokerActualUNLINKButton"
          className="linkBrokerActualUNLINKButton"
          onClick={async () => await callsetData()}
        >
          Unlink
        </button>
      </span>
    </div>
  );
};
export default UnlinkModal;
