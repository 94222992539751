import React /* , { useState }  */ from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import getEntryExitLots from "./../../functions/getEntryExitLots";

var he = require("he");

const PnLHistoryGraph = (props) => {
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const symbol = props.defaultSymbol;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);

  //const calcs = props.userCalculations;
  const sorted = props.sorted;
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  /*   <button
    className="cumulativereturngraphsortbutton"
    onClick={() => {
      handlesort();
    }}
  >
    {!sorted ? "Sort By Date" : "Sort By P/L"}
  </button>; */
  const equityvaluefunction = () => {
    let cumulativereturnarray = [];
    let totalreturnarray = [];

    let equityValues = entries;

    // if the user wants to sort by P/L, do that sorting here
    !sorted &&
      equityValues.sort(function (a, b) {
        let correctedSUMa = 0;
        let x100profitLossa = Number(a.entry.profitLoss * 100);
        let x100commissionsa = Number(a.entry.commissions * 100);
        let x100feesa = Number(a.entry.fees * 100);
        if (props.calculationType === "Gross") {
          correctedSUMa = x100profitLossa / 100;
        } else if (props.calculationType === "Net") {
          correctedSUMa =
            (x100profitLossa + x100commissionsa + x100feesa) / 100;
        }
        let correctedSUMb = 0;
        let x100profitLossb = Number(b.entry.profitLoss * 100);
        let x100commissionsb = Number(b.entry.commissions * 100);
        let x100feesb = Number(b.entry.fees * 100);
        if (props.calculationType === "Gross") {
          correctedSUMb = x100profitLossb / 100;
        } else if (props.calculationType === "Net") {
          correctedSUMb =
            (x100profitLossb + x100commissionsb + x100feesb) / 100;
        }
        var c = Number(correctedSUMa);
        var d = Number(correctedSUMb);
        return c - d;
      });

    // if the user wants to sort by Date
    sorted &&
      equityValues.sort(function (a, b) {
        var c = new Date(
          !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
        );
        var d = new Date(
          !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
        );
        return c - d;
      });

    // Actual P/L Calculations
    let totalPL = 0;
    let totalTrades = 0;

    for (var i = 0, j = entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        cumulativereturnarray = cumulativereturnarray.concat(0);
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        cumulativereturnarray = cumulativereturnarray.concat(correctedSUM);
        totalPL = totalPL + correctedSUM;
        totalTrades = totalTrades + 1;
        totalreturnarray = totalreturnarray.concat(totalPL / totalTrades);
      }
    }
    return { cumulativereturnarray, totalreturnarray };
  };
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = entries.length; iiy < jjy; iiy++) {
      xaxisvalue = xaxisvalue.concat(iiy + 1);
    }
    return xaxisvalue;
  };
  const colours = equityvaluefunction().cumulativereturnarray.map((value) =>
    value < 0 ? "#fa798b" : "#55f8a6"
  );

  const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        type: "bar",
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        backgroundColor: colours,
        borderColor: colours,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colours,
        pointBackgroundColor: colours,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colours,
        pointHoverBorderColor: colours,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: equityvaluefunction().cumulativereturnarray,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => {
          return "P/L: ";
        },
        label: (tooltipItems) => {
          return supportedCryptosCheck
            ? parseFloat(tooltipItems.yLabel.toFixed(3)) + " " + symbol
            : he.decode(symbol) + parseFloat(tooltipItems.yLabel.toFixed(3));
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            beginAtZero: true,
            callback: function (value) {
              return supportedCryptosCheck
                ? value + " " + symbol
                : he.decode(symbol) + value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Equity",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  const sortedindi = sorted ? "Sorted By Date" : "Sorted By P/L";
  return (
    <div className="plhistorygraph noselect">
      <div className="dashboard-graphs-label">{`P/L History`}</div>

      <div className="cummulativereturn-label3">{sortedindi}</div>
      <div className="cumulativereturngraphwrapper">
        <Bar
          data={data}
          options={options}
          className="cumulativereturngraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default PnLHistoryGraph;
