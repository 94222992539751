import $ from "jquery";
export const transitiontoggle = () => {
  $(".sidebaronly").css({
    transition: "none",
  });
};

export const ontoggle = (width, height) => {
  $(".sidenavstyledmain").css({
    transition: "none",
    width: 50,
  });
  $(".insightsbuffer").css({
    transition: "none",
    width: 50,
  });
  $(".communitybuffer").css({
    transition: "none",
    width: 50,
  });
  $(".toolsbuffer").css({
    transition: "none",
    width: 50,
  });
  $(".managedatabuffer").css({
    transition: "none",
    width: 50,
  });
  $(".sidebaronly").css({
    position: "fixed",
    height: "100%",
    "z-index": 21,
    width: width <= 1440 && height <= 800 ? 46 : 56,
    top: "0em",
    "background-color": "#14181e",
  });
};

export const offtoggle = () => {
  $(".sidenavstyledmain").css({
    width: 204,
  });
  $(".insightsbuffer").css({
    width: 204,
  });
  $(".toolsbuffer").css({
    width: 204,
  });
  $(".managedatabuffer").css({
    width: 204,
  });
  $(".sidebaronly").css({
    position: "fixed",
    height: "100%",
    "z-index": 21,
    width: 210,
    top: "0em",
    "background-color": "#14181e",
  });
};

export default ontoggle;
