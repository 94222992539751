import getColorPoint from "./includeColor";

export const getRiskedPercentColor = (risked) => {
  let color = "";
  if (risked < 0 || risked > 100) {
    color = "#deebf7";
  } else {
    if (risked < 5) {
      color = getColorPoint("#49f875", "#e1f967", risked / 5, "hex");
    } else if (risked >= 5 && risked < 10) {
      color = getColorPoint("#e1f967", "#ff9735", (risked - 5) / 5, "hex");
    } else if (risked >= 10 && risked < 15) {
      color = getColorPoint(
        "#ff9735",
        "rgb(238, 73, 73)",
        (risked - 10) / 5,
        "hex"
      );
    } else if (risked >= 15 && risked < 20) {
      color = getColorPoint(
        "rgb(238, 73, 73)",
        "rgb(139, 43, 43)",
        (risked - 15) / 5,
        "hex"
      );
    } else {
      color = "rgb(139, 43, 43)";
    }
  }

  return color;
};
export default getRiskedPercentColor