import createSettings from "../pages/Settings/createSettings";
import createGlobalSettings from "../pages/Settings/createGlobalSettings";

// THIS FUNCTION CREATES THE SKYNET DATA OBJECT FOR CHANGES IN SETTINGS
export const createDatafromSettingsProps = (
  chosenPortfoliosINIT, // the chosen portfolios
  createdPortfoliosINIT, // array of all created portfolios
  propValues, // settings props -> values to change
  globalpropValues, // global settings props -> values to change
  settings, // current settings of the selected portfolio
  globalsettings, // current global settings
  version // current version number
) => {

  const propsValuesToChange = propValues;
  const globalpropsValuesToChange = globalpropValues;
  const settingsINIT = settings;
  const globalsettingsINIT = globalsettings;
  const stripeId = !globalsettingsINIT.stripeId
    ? ""
    : globalsettingsINIT.stripeId; // this should always be populated
  const chosenPortfolios = chosenPortfoliosINIT;
  let tempAll = chosenPortfoliosINIT;
  let tempAllOther = createdPortfoliosINIT;
  let temparr = createdPortfoliosINIT;
  const index = tempAll.indexOf("Default");
  const newIndex = index === 1 ? 0 : 1;

  // ----- Handle portfolios -----
  const portName =
    tempAll.length === 1 || tempAll.length > 2 ? "Default" : tempAll[newIndex];
  temparr = temparr.filter((x) => x.name !== portName);
  tempAllOther = tempAllOther.filter((x) => x.name === portName);
  // -------------------------------------

  // ----- Add props and values to data object -----
  const settingsData = createSettings(settingsINIT, propsValuesToChange);
  const globalsettingsDataPre = createGlobalSettings(
    globalsettingsINIT,
    globalpropsValuesToChange,
    stripeId
  );

  // ------------------------------
  if (!tempAllOther[0]) {
    // Check if any portfolios were filtered...rare edge cases
  } else {
    const pushObj = {
      name: tempAllOther[0].name,
      assetClasses: tempAllOther[0].assetClasses,
      entries: tempAllOther[0].entries,
      settings: settingsData,
    };
    temparr = temparr.concat(pushObj);
  }
  const finalPush = {
    chosenPortfolios: chosenPortfolios,
    createdPortfolios: temparr,
    globalSettings: globalsettingsDataPre,
    version: Number(version) + 1,
    sharedTrades: [],
  };
  return finalPush;
};
export default createDatafromSettingsProps;
