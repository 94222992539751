import styled from "styled-components";

export const CardWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
`;

export const InputCards = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  position: relative;
  padding: 21px 30px 0px 20px;
  flex-grow: 1;
  // height: 715px;
  border-radius: 10px;
  background-color: #1f2831;
  margin: 24px 14px 4px 14px;
  box-shadow: 0 0 4pt 2pt #171c23;
  .column2 {
    top: -48px;
  }
  .column3 {
    top: -48px;
  }
`;
export const CheckBoxContainer = styled.div`
  margin: 0;
  padding: 0;
`;
export const CardColumn = styled.div`
  margin: 0px 16px -16px 24px;
  position: relative;
  top: -48px;
  font-size: 0.965em;
  width: 100%;
`;

export default CardWrapper;
