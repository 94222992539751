import {
  confidence,
  market,
  timeFrame,
  emotions,
  physicals,
} from "./../pages/Manual-Trade-Entry/DropDownOptions";
import {
  OrderTypeOptions,
  OrderTypeOptionswOptions,
  OrderTypeOptionsOnlyOptions,
} from "./../components/Right-Panel/Filter-Menu/FilterMenuDropdownOptions";
import isArray from "./../functions/arrayFunctions/isArray";

const orderTypeDefault = (assetClasses) => {
  let returnArr = "[]";
  const optionsIncludes =
    assetClasses && isArray(assetClasses) && assetClasses.includes("Options");

  returnArr = optionsIncludes
    ? assetClasses.length === 1
      ? OrderTypeOptionsOnlyOptions
      : OrderTypeOptionswOptions
    : OrderTypeOptions;
  return returnArr;
};
export const fetchFilterLists = (fullData, assetClasses, dateToggle) => {
  const fetchedColumns = {
    symbol: [],
    strategy: [],
    emotion: [...emotions],
    mistake: [],
    physical: [...physicals],
    tags: [],
    years: [],
    confidenceWithDefault: [...confidence],
    timeframeWithDefault: [...timeFrame],
    marketStateWithDefault: [...market],
    orderType: [...orderTypeDefault(assetClasses)],
  };
  const optionCreate = (optionToCreate, fetchedColumn) => {
    if (optionToCreate && optionToCreate.length > 0) {
      for (const option of optionToCreate) {
        if (!option || fetchedColumn.some((e) => e.label === option)) {
          continue;
        }

        fetchedColumn.push({ value: option, label: option });
      }
    }
  };
  const optionCreateOnlyText = (optionToCreate, fetchedColumn) => {
    if (
      !optionToCreate ||
      fetchedColumn.some((e) => e.label === optionToCreate)
    ) {
      return;
    }
    if (optionToCreate === "Multi-Leg Strategy") {
      // TO DO: push multi-leg strategies names here
      // TO DO: Also need to create filters in processDataandSettings specifically for multi-leg strategies names
    } else {
      const createdOption = { value: optionToCreate, label: optionToCreate };
      fetchedColumn.push(createdOption);
    }
  };

  for (const data of fullData) {
    const { entry } = data;
    optionCreate(entry.symbol.symbols, fetchedColumns.symbol);
    optionCreate(entry.strategy, fetchedColumns.strategy);
    optionCreate(entry.selectedEmotion, fetchedColumns.emotion);
    optionCreate(entry.selectedMistake, fetchedColumns.mistake);
    optionCreate(entry.selectedPhysical, fetchedColumns.physical);
    optionCreate(entry.tags, fetchedColumns.tags);
    optionCreateOnlyText(
      entry.selectedConfidence,
      fetchedColumns.confidenceWithDefault
    );
    optionCreateOnlyText(
      entry.selectedTimeframe,
      fetchedColumns.timeframeWithDefault
    );
    optionCreateOnlyText(
      entry.selectedMarket,
      fetchedColumns.marketStateWithDefault
    );
    optionCreateOnlyText(entry.orderType, fetchedColumns.orderType);
    var dateTime = new Date(
      !dateToggle ? entry.startDateTime : entry.endDateTime
    );
    const tradeYear = new Date(
      dateTime // Assuming filtering based on startDateTime, adjust if necessary
    ).getFullYear();
    optionCreateOnlyText(String(tradeYear), fetchedColumns.years);
  }

  const sortFetchedColumns = (columns) => {
    for (const column of Object.values(columns)) {
      column.sort((a, b) => a.value?.localeCompare(b.value));
    }
  };

  sortFetchedColumns(fetchedColumns);

  return fetchedColumns;
};
export default fetchFilterLists;
