import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  DateRangeOptions,
  MonthOptions,
  MarketStateOptions,
  DayofWeekOptions,
  TimeFrameOptions,
  ConfidenceOptions,
  HourRangeOptions,
} from "./FilterMenuDropdownOptions";
import "./filterstyling.scss";
import CreatableAdvanced from "./FilterMenuDropdownComponent";
import moment from "moment/moment";
import store from "./../../store";
import {
  getFilteredList,
  setFiltersOpen,
  setFiltersToggleLocation,
} from "./../../actions/actionCreators";
import isArray from "../../functions/arrayFunctions/isArray";
import CreateableFilter from "./FilterMenuDropdownComponentNew";
import ClearFiltersButton from "./clearFilterMenuButton";

const FilterSelectComponent = styled.div`
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column wrap;
  flex: auto;
  align-items: left;
  justify-content: left;
  margin: 0;
  padding-right: 36px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const FilterMenu = (props) => {
  const allData = props.userData;
  const locationState = store.getState().filtersToggleLocation.location;
  const portfolio = allData === undefined ? [] : allData.portfolio;
  const filterlistActual = allData === undefined ? [] : allData.filterLists;
  const assetClasses = portfolio.assetClasses;

  function setFilterMenuStyles(height, display) {
    var element = document.querySelector(".filtermenunewwrapper");
    if (element) {
      element.style.height = height;
      element.style.display = display;
    } else {
      console.error('Element with class "filtermenunewwrapper" not found.');
    }
  }
  const optionsIncludes =
    assetClasses && isArray(assetClasses) && assetClasses.includes("Options");

  const generateAssetClasses = (portfolio) => {
    let arr = [];
    portfolio &&
      portfolio.forEach((item) => {
        arr = arr.concat({ label: item, value: item });
      });
    return arr;
  };
  let _isMounted = useRef(true);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [isScrolling, setisScrolling] = useState(false);

  useEffect(() => {
    if (_isMounted.current) {
      var item = document.getElementById("divforfilternewscroll");

      function handleScroll(e) {
        // Check if the scrolling is happening within an element with class 'filterOptionsContainer'
        if (e.target.closest(".filterOptionsContainer")) {
          return; // Do nothing if the event target is inside 'filterOptionsContainer'
        }
        // Continue with the existing scroll logic for the filter menu
        if (e.deltaY > 0) item.scrollLeft += 70;
        else item.scrollLeft -= 70;
      }
      item.addEventListener("mouseenter", function () {
        window.addEventListener("wheel", handleScroll);
      });

      item.addEventListener("mouseleave", function () {
        window.removeEventListener("wheel", handleScroll);
      });

      function getScrollPosition(elementId) {
        const element = document.getElementById(elementId);

        function updateScrollPosition() {
          const scrollPosition = element && element.scrollLeft;
          store.dispatch(
            setFiltersToggleLocation({
              location: !scrollPosition ? 0 : scrollPosition,
            })
          );
          // Replace the console.log statement with your desired logic to use the scroll position
        }

        element && element.addEventListener("scroll", updateScrollPosition);
        element && (element.scrollLeft = locationState);
        // Initial call to get the scroll position when the function is first invoked
        updateScrollPosition();
      }
      getScrollPosition("divforfilternewscroll");
    }
    return () => {
      _isMounted.current = false;
    };
  }, [props, locationState]);

  const allIds = [
    "dateRangeFilter",
    "orderTypeFilter",
    "symbolFilter",
    "strategyFilter",
    "emotionalStateFilter",
    "mistakeFilter",
    "timeFrameFilter",
    "confidenceFilter",
    "marketStateFilter",
    "dayOfWeekFilter",
    "monthFilter",
    "assetClassFilter",
    "tagsFilter",
    "physicalStateFilter",
    "hourRangeFilter",
    "yearFilter",
  ];

  // IMPORTANT LINES OF CODE
  const globalfilter = store.getState();
  const globalFilterState = globalfilter.defaultfilterValue;
  // Dates
  const stateDateRange = globalFilterState.filtervalue.dateRangeFilter;
  var stringDate1 =
    stateDateRange === null
      ? null
      : moment(stateDateRange[0]).add(1, "days").format("MM/DD/YYYY");
  var stringDate2 =
    stateDateRange === null
      ? null
      : moment(stateDateRange[1]).format("MM/DD/YYYY");
  var stringDate = "";
  stringDate = stringDate1 + " - " + stringDate2;
  const dateRangeFilterlength =
    Object.keys(stateDateRange === null ? 0 : stateDateRange).length / 2;
  const stringDateRange = {
    value: stringDate,
    label: stringDate,
  };
  const finaldefaultvalue =
    dateRangeFilterlength > 1 ? stringDateRange : stateDateRange;
  // Time
  const stateTimeRange = globalFilterState.filtervalue.hourRangeFilter;
  var stringTime1 = stateTimeRange === null ? null : stateTimeRange[0];
  var stringTime2 = stateTimeRange === null ? null : stateTimeRange[1];
  var stringTime = "";
  stringTime = stringTime1 + " - " + stringTime2;
  const timeRangeFilterlength =
    Object.keys(stateTimeRange === null ? 0 : stateTimeRange).length / 2;
  const stringTimeRange = {
    value: stringTime,
    label: stringTime,
  };
  const finaldefaultvalueTime =
    timeRangeFilterlength > 1 ? stringTimeRange : stateTimeRange;
  return (
    <div className="divforfilternewscrollwrapper">
      <div className="divforfilternewscroll" id="divforfilternewscroll">
        <CreateableFilter
          allowCreateWhileLoading={false}
          id={allIds[0]}
          defaultFilterValue={finaldefaultvalue}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[0], buffer))
          }
          isMulti={false}
          defaultOptions={DateRangeOptions}
          allData={allData}
          filterLabel={"Date Range"}
        />
        <CreateableFilter
          id={allIds[14]}
          defaultFilterValue={finaldefaultvalueTime}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[14], buffer))
          }
          isMulti={false}
          defaultOptions={HourRangeOptions}
          allData={allData}
          filterLabel={"Time of Day"}
        />
        <CreateableFilter
          id={allIds[1]}
          defaultFilterValue={globalFilterState.filtervalue.orderTypeFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[1], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.orderType}
          allData={allData}
          filterLabel={"Order Type"}
        />
        <CreateableFilter
          id={allIds[2]}
          defaultFilterValue={globalFilterState.filtervalue.symbolFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[2], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.symbol}
          allData={allData}
          filterLabel={"Symbol"}
        />
        <CreateableFilter
          id={allIds[12]}
          defaultFilterValue={globalFilterState.filtervalue.assetClassFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[11], buffer))
          }
          isMulti={true}
          defaultOptions={generateAssetClasses(portfolio.assetClasses)}
          allData={allData}
          filterLabel={"Asset Class"}
        />
        <CreateableFilter
          id={allIds[3]}
          defaultFilterValue={globalFilterState.filtervalue.strategyFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[3], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.strategy}
          allData={allData}
          filterLabel={"Strategy"}
        />
        <CreateableFilter
          id={allIds[4]}
          defaultFilterValue={
            globalFilterState.filtervalue.emotionalStateFilter
          }
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[4], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.emotion}
          allData={allData}
          filterLabel={"Emotional State"}
        />
        <CreateableFilter
          id={allIds[13]}
          defaultFilterValue={globalFilterState.filtervalue.physicalStateFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[13], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.physical}
          allData={allData}
          filterLabel={"Physical State"}
        />
        <CreateableFilter
          id={allIds[5]}
          defaultFilterValue={globalFilterState.filtervalue.mistakeFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[5], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.mistake}
          allData={allData}
          filterLabel={"Mistake"}
        />
        <CreateableFilter
          id={allIds[6]}
          defaultFilterValue={globalFilterState.filtervalue.timeFrameFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[6], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.timeframeWithDefault}
          allData={allData}
          filterLabel={"Time Frame"}
        />
        <CreateableFilter
          id={allIds[7]}
          defaultFilterValue={globalFilterState.filtervalue.confidenceFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[7], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.confidenceWithDefault}
          allData={allData}
          filterLabel={"Confidence"}
        />
        <CreateableFilter
          id={allIds[8]}
          defaultFilterValue={globalFilterState.filtervalue.marketStateFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[8], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.marketStateWithDefault}
          allData={allData}
          filterLabel={"Market State"}
        />
        <CreateableFilter
          id={allIds[9]}
          defaultFilterValue={globalFilterState.filtervalue.dayOfWeekFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[9], buffer))
          }
          isMulti={true}
          defaultOptions={DayofWeekOptions}
          allData={allData}
          filterLabel={"Day of Week"}
        />
        <CreateableFilter
          id={allIds[10]}
          defaultFilterValue={globalFilterState.filtervalue.monthFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[10], buffer))
          }
          isMulti={true}
          defaultOptions={MonthOptions}
          allData={allData}
          filterLabel={"Month"}
        />{" "}
        <CreateableFilter
          id={allIds[15]}
          defaultFilterValue={globalFilterState.filtervalue.yearFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[15], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.years}
          allData={allData}
          filterLabel={"Year"}
        />
        <CreateableFilter
          id={allIds[12]}
          defaultFilterValue={globalFilterState.filtervalue.tagsFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[12], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.tags}
          allData={allData}
          filterLabel={"Tags"}
        />
      </div>{" "}
      <div className="filtermenunbuttonsmain">
        <div
          onClick={() => {
            setFilterMenuStyles("0px", "none");
            props.setFilterMenu(false);
            store.dispatch(setFiltersOpen({ open: false }));
          }}
          className="closefiltersbutton"
        >
          Close
        </div>
        <ClearFiltersButton allData={allData} />
      </div>
    </div>
  );
};

export default FilterMenu;
