import React from "react";
import { Scatter } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import dollarCostAverage from "../../functions/dollarCostAvg";
import linearRegression from "../../functions/calculations_for_metrics/linearRegression";
import getEntryExitLots from "../../functions/getEntryExitLots";

const RiskRewardGraph = (props) => {
  const tier = props.tier;
  const active = props.active;
  const entries = props.userData;
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    let entryFilter = entries.slice();
    const filterDWs = (entry) => {
      if (
        entry.entry.orderType === "Deposit" ||
        entry.entry.orderType === "Withdrawal" ||
        entry.entry.orderType === "Funding Payment" ||
        entry.entry.orderType === "Commit" ||
        entry.entry.orderType === "Approval" ||
        entry.entry.orderType === "Wrap" ||
        entry.entry.orderType === "Self"
      ) {
        return false;
      } else {
        return true;
      }
    };
    // have to filter out deposits and withdrawals since we skip it in the graph
    entryFilter = entryFilter.filter(filterDWs);
    entry = entryFilter.filter((_, index) => index === i);
    return entry;
  };
  const equityvaluefunction = () => {
    let linearRegressionData = "";
    let LRArray = [];
    let xArray = [];
    let yArray = [];
    let grapharray = [];
    for (var i = 0, j = entries.length; i < j; i++) {
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;
      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
      const entryLots = entryExitValues.entryLots;
      const exitLots = entryExitValues.exitLots;

      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        continue;
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        let entryAvg = dollarCostAverage(
          entries[i].entry.multiExecution,
          "entry",
          entries[i].entry.orderType
        );
        let exitAvg = dollarCostAverage(
          entries[i].entry.exitExecution,
          "exit",
          entries[i].entry.orderType
        );
        let RRR =
          (Number(entries[i].entry.takeProfit) - entryAvg) /
          (entryAvg - Number(entries[i].entry.stopLoss));

        let RMultiple =
          (exitAvg - entryAvg) / (entryAvg - Number(entries[i].entry.stopLoss));
        grapharray.push({ x: RRR, y: RMultiple });
      }
    }

    for (var ig = 0, jg = grapharray.length; ig < jg; ig++) {
      xArray.push(grapharray[ig].x);
      yArray.push(grapharray[ig].y);
    }
    linearRegressionData = linearRegression(yArray, xArray);
    for (var il = 0, jl = xArray.length; il < jl; il++) {
      LRArray.push({
        x: xArray[il],
        y:
          linearRegressionData.slope * xArray[il] +
          linearRegressionData.intercept,
      });
    }
    return { grapharray, LRArray };
  };
  let xvalues = "";
  let yvalues = "";
  if (tier === "free") {
    xvalues = [];
    yvalues = [];
  } else if (tier === "pro" && active) {
    xvalues = equityvaluefunction().grapharray;
    yvalues = equityvaluefunction().LRArray;
  } else if (tier === "oldpro" && active) {
    xvalues = equityvaluefunction().grapharray;
    yvalues = equityvaluefunction().LRArray;
  } else if (tier === "master" && active) {
    xvalues = equityvaluefunction().grapharray;
    yvalues = equityvaluefunction().LRArray;
  } else if (tier === "ultimate" && active) {
    xvalues = equityvaluefunction().grapharray;
    yvalues = equityvaluefunction().LRArray;
  } else if (tier === "admin" && active) {
    xvalues = equityvaluefunction().grapharray;
    yvalues = equityvaluefunction().LRArray;
  } else {
    xvalues = [];
    yvalues = [];
  }
  const data = {
    datasets: [
      {
        label: "Dataset 2",
        backgroundColor: "#fbd665",
        data: xvalues,
        borderColor: "#fbd665",
        borderWidth: 2,
      },
      {
        label: "Dataset 1",
        showLine: true,
        borderColor: "#fff8b9",
        backgroundColor: "#fff8b9",
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
        data: yvalues,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => {
          return "";
        },
        label: (tooltipItems) => {
          return [
            parseFloat(tooltipItems.yLabel).toFixed(3) + "R",
            parseFloat(tooltipItems.xLabel).toFixed(3) + " : 1 RRR",
          ];
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return value.toFixed(3) + "R";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "R-Multiple",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return value.toFixed(3) + " : 1";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Reward-Risk Ratio",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="MMreportgraph">
      <div className="riskrewardscatter-label" id="riskrewardscatter-label">
        RRR vs. R-Multiple Distribution
      </div>
      <div className="drawdownreportgraphwrapper">
        <Scatter
          data={data}
          options={options}
          className="drawdownreportgraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default RiskRewardGraph;
