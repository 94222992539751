import React from "react";
import "../../../DateTime.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { withRouter } from "react-router-dom";
import {
  getFilteredList,
  setLoaderState,
} from "../../../actions/actionCreators";
import store from "../../../store";
import $ from "jquery";
import DeleteButton from "../../deleteButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DataContext } from "../../../DataContext";

var buffer = {};

class CustomTimeRangeComponent extends React.Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      startTime: "",
      endTime: "",
    };
  }

  handleSubmit = async () => {
    const reduxFilters = store.getState().defaultfilterValue.filtervalue;
    const filterProp = !this.props.allData.portfolio.settings.filters
      ? {
          dateRangeFilter: null,
          hourRangeFilter: null,
          orderTypeFilter: null,
          symbolFilter: null,
          strategyFilter: null,
          emotionalStateFilter: null,
          physicalStateFilter: null,
          mistakeFilter: null,
          timeFrameFilter: null,
          confidenceFilter: null,
          marketStateFilter: null,
          dayOfWeekFilter: null,
          monthFilter: null,
          assetClassFilter: null,
          tagsFilter: null,
          yearFilter: null,
        }
      : reduxFilters;

    localStorage.setItem("filtersAreRunning", "yes");
    localStorage.setItem("filters", JSON.stringify(filterProp));
  };

  handleExitClick = () => {
    store
      .dispatch(getFilteredList("hourRangeFilter", null))
      .then(() => store.dispatch(setLoaderState({ loading: false })));
    $("#choose-time-range").fadeOut(300);
    $("#overlay").fadeOut(300);
    document.getElementById("noclick").style.display = "none";
    document.getElementById("select-time-red").style.display = "none";
    document.getElementById("select-time-red2").style.display = "none";
  };

  handleendDateChange = (endTime) => this.setState({ endTime });

  handlestartDateChange = (startTime) => {
    this.setState({ startTime });
  };
  convertToDate = (date) => {
    var result = new Date(date); // split it at the colons
    const newres = result.toLocaleTimeString("it-IT");
    return newres;
  };
  handleSubmitClick = () => {
    const savecheck =
      this.props.allData && this.props.allData.portfolio.settings.savefilters;
    if (this.state.startTime === "" || this.state.endTime === "") {
      if (this.state.startTime === "") {
        document.getElementById("select-time-red").style.display = "inline";
        //$(".customDRinputs").focus();
      }
      if (this.state.endTime === "") {
        document.getElementById("select-time-red2").style.display = "inline";
        //$(".customDRinputs").focus();
      }
      return false;
    } else {
      buffer[0] = this.convertToDate(this.state.startTime);
      buffer[1] = this.convertToDate(this.state.endTime);
      buffer[2] = "";
      store.dispatch(getFilteredList("hourRangeFilter", buffer));
      store.dispatch(setLoaderState({ loading: false }));
      document.getElementById("select-time-red").style.display = "none";
      document.getElementById("select-time-red2").style.display = "none";
      $("#choose-time-range").fadeOut(300);
      $("#overlay").fadeOut(300);
      document.getElementById("noclick").style.display = "none";
      this.props.history.push("/BlankPage");
      this.props.history.goBack();
      savecheck && this.handleSubmit();
    }
  };

  render() {
    return (
      <div id="choose-time-range">
        <span className="choosedaterangeheading">CUSTOM RANGE</span>
        <button
          className="choosedaterangeclosebutton"
          onClick={this.handleExitClick}
        >
          <DeleteButton />
        </button>
        <div className="main-labels-CDR">
          <div id="select-time-red">**Select Time</div>
          <label>
            {"Starting Hour"}
            {/*             <DateTime
              id="startTimecustoms"
              className="customDRinputs"
              value={this.state.startTime}
              onChange={this.handlestartDateChange}
              dateFormat={false}
              timeFormat="HH:mm:ss"
            /> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                disableCloseOnSelect={false}
                ampm={false}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    placeholder="Required"
                    className="text-input-boxes required customDRinputs"
                  />
                )}
                views={["hours"]}
                inputFormat="HH:mm:ss"
                value={this.state.startTime}
                onChange={this.handlestartDateChange}
              />
            </LocalizationProvider>
          </label>
        </div>
        <div className="main-labels-CDR main-labels-CDR-moveup">
          <div id="select-time-red2">**Select Time</div>
          <label>
            {"Ending Hour"}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                ampm={false}
                disableCloseOnSelect={false}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    placeholder="Required"
                    className="text-input-boxes required customDRinputs"
                  />
                )}
                views={["hours"]}
                inputFormat="HH:mm:ss"
                value={this.state.endTime}
                onChange={this.handleendDateChange}
              />
            </LocalizationProvider>
          </label>
        </div>
        <button
          className="choosedaterangecancelbutton"
          onClick={this.handleExitClick}
        >
          Cancel
        </button>
        <button
          className="choosedaterangesubmitbutton"
          onClick={this.handleSubmitClick}
        >
          Apply
        </button>
      </div>
    );
  }
}

export default withRouter(CustomTimeRangeComponent);
