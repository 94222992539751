export const drawdownFunction = (equityCurve) => {
	// Initialisations
	var highWaterMark = -Infinity;

	// Other initialisations
	let ddVectorNew = [];

	// Loop over all the values to compute the drawdown vector
	for (var i=0; i<equityCurve.length; ++i) {
		highWaterMark = equityCurve[i] > highWaterMark ? equityCurve[i] : highWaterMark;
		ddVectorNew = ddVectorNew.concat(((highWaterMark - equityCurve[i]) / highWaterMark)*100)
	}
	// Return the computed vector
	return ddVectorNew;
}

export const ulcerIndex = (equityCurve) => {
	// Compute the drawdown function
	 var ddFunc = drawdownFunction(equityCurve);
	 const handleNANs = (value) => {
		if (
			isNaN(value) ||
			value === null ||
			value === Infinity ||
			value === undefined ||
			value === ""
		) {
			return true
		} else {
			return false
		}
	};
	// Compute the sum of squares of this function
	var sumSquares = 0.0;
	for (var i=0; i < ddFunc.length; ++i) {
		if (handleNANs(ddFunc[i])) {
			sumSquares += 0;
		} else {
			sumSquares += ddFunc[i]*ddFunc[i];
		}
	}
	const returnedValue = Math.sqrt(sumSquares/ddFunc.length);
	// Compute and return the ulcer index
	return returnedValue; 
	
}
export default ulcerIndex