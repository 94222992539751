
export const GetFUNCTIONFormattedDate = (buffer) => {
  var today = new Date(buffer);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd + "T00:00:00.000Z";
  return today;
}

export const GetFUNCTIONFormattedDatOTHER = (buffer) => {
  var today = new Date(buffer);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd + "T23:59:59.999Z";
  return today;
}

export const GetFormattedDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd + "T00:00:00.000Z";
  return today;
}

export const GetFormattedDateEnd = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd + "T23:59:59.999Z";
  return today;
}

export default GetFUNCTIONFormattedDate