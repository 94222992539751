import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import "./styles.scss";
import getSpecificPortfolio from "../../functions/getSpecificPortfolio";
import SimulationGraph from "./SimulationGraph";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import SimulatorDistributionGraph from "./SimulatorbarGraph";
import { handleNANGOOD } from "../../functions/handleUndefined";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import { getMaxDDPercent } from "../../functions/calculations_for_metrics/getmaxDD";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

function usePrevious(data) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
}
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
function percentile(arr, percentile) {
  // Sort the array
  arr.sort(function (a, b) {
    return a - b;
  });

  // Find the index of the nth percentile
  const index = Math.ceil((percentile / 100) * arr.length) - 1;

  // Return the value at that index
  return arr[index];
}

function calculateTradingStats(accountBalances) {
  let maxConsecutiveLosses = 0;
  let maxConsecutiveWins = 0;
  let maxDrawdown = -Infinity;

  for (let i = 0; i < accountBalances.length; i++) {
    const balances = accountBalances[i];
    let currentConsecutiveLosses = 0;
    let currentConsecutiveWins = 0;
    let currentDrawdown = getMaxDDPercent(balances);
    maxDrawdown = currentDrawdown > maxDrawdown ? currentDrawdown : maxDrawdown;
    for (let j = 1; j < balances.length; j++) {
      if (balances[j] < balances[j - 1]) {
        currentConsecutiveLosses++;
        currentConsecutiveWins = 0;
      } else {
        currentConsecutiveWins++;
        currentConsecutiveLosses = 0;
      }
      maxConsecutiveLosses = Math.max(
        maxConsecutiveLosses,
        currentConsecutiveLosses
      );
      maxConsecutiveWins = Math.max(maxConsecutiveWins, currentConsecutiveWins);
    }
  }

  return { maxConsecutiveLosses, maxConsecutiveWins, maxDrawdown };
}

let he = require("he");

export const SimulatorComponent = (props) => {
  const userData = props.userData;
  const allData = props.processedData;
  const tier = props.tier;
  const active = props.active;

  const calculations = allData.calculations;
  const portfolio = getSpecificPortfolio(userData);
  const [hasUseMetrics, sethasUseMetrics] = useState();
  const [simulationResults, setsimulationResults] = useState([]);
  const [simulationTime, setsimulationTime] = useState(0);

  const firstUpdate = useRef(true);

  const supportedCryptosCheck = supportedCryptos.includes(
    String(allData.portfolio.settings.currencySymbol)
  );

  // Tiering
  let showMaster = false;

  if (tier === "free") {
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    showMaster = true;
  } else if (tier === "admin" && active) {
    showMaster = true;
  } else {
  }
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, []);
  const handleUseMetrics = (e) => {
    sethasUseMetrics(e.target.checked);
    if (!e.target.checked) {
      setState({
        ...state,
        winRate: prevWR,
        avgRisk: prevRisk,
        profitlossRatio: prevPLR,
        stdDev: prevStdDev,
        startingBalance: prevBal,
      });
    } else {
      setState({
        ...state,
        winRate: calculations.winRate,
        avgRisk: calculations.avgRisk,
        profitlossRatio: calculations.profitLossRatio,
        stdDev: calculations.stdDev,
        startingBalance: isNaN(Number(calculations.currentBalance))
          ? ""
          : supportedCryptosCheck
          ? Number(calculations.currentBalance).toFixed(6)
          : Number(calculations.currentBalance).toFixed(2),
      });
    }
  };
  // Helper function to generate normally distributed random numbers
  function gaussRand() {
    let u = 2 * Math.random() - 1;
    let v = 2 * Math.random() - 1;
    let r = u * u + v * v;
    if (r == 0 || r > 1) {
      return gaussRand();
    }
    let c = Math.sqrt((-2 * Math.log(r)) / r);
    return u * c;
  }

  function simulateTradingAccount(
    winRate,
    avgRisk,
    profitLossRatio,
    stdDev,
    startingBalance,
    numTrades,
    numSimulations
  ) {
    // Initialize an array to hold the results
    let simulationResults = [];

    // Loop through each simulation
    for (let i = 0; i < numSimulations; i++) {
      // Initialize an array to hold the account balances for this simulation
      let accountBalances = [Number(startingBalance)];

      // Loop through each trade in this simulation
      for (let j = 0; j < numTrades; j++) {
        // Calculate the amount of the account to risk on this trade
        let riskAmount = accountBalances[j] * (Number(avgRisk) / 100);

        // Generate a random number to determine the trade outcome
        let random = Math.random();
        // Determine the outcome of the trade based on the win rate and profit/loss ratio
        let tradeResult = 0;
        if (random <= Number(winRate) / 100) {
          // The trade is a win
          let winReturn = Number(profitLossRatio) * riskAmount;
          tradeResult =
            winReturn + accountBalances[j] * ((stdDev / 100) * gaussRand());
        } else {
          // The trade is a loss
          let lossReturn = -riskAmount;
          tradeResult =
            lossReturn + accountBalances[j] * ((stdDev / 100) * gaussRand());
        }

        // Update the account balance with the trade result
        let newBalance = accountBalances[j] + tradeResult;
        accountBalances.push(newBalance < 0 ? 0 : newBalance);
      }
      // Add the array of account balances to the array of simulation results
      const lineColor = `rgb(${[1, 2, 3].map(
        (x) => (Math.random() * 256) | 0
      )})`;
      simulationResults.push({
        label: "Your Equity" + i,
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true, // enable for all datasets
        data: accountBalances,
      });
    }

    // Return the array of simulation results
    return simulationResults;
  }

  const [state, setState] = useState({
    winRate: 53.67,
    avgRisk: 1.108,
    stdDev: 0.956,
    profitlossRatio: 1.123,
    startingBalance: 1000,
    numTrades: 200,
    numSims: 200,
  });
  const {
    winRate,
    avgRisk,
    stdDev,
    profitlossRatio,
    startingBalance,
    numTrades,
    numSims,
  } = state;
  const prevWR = usePrevious(winRate);
  const prevStdDev = usePrevious(stdDev);
  const prevPLR = usePrevious(profitlossRatio);
  const prevRisk = usePrevious(avgRisk);
  const prevBal = usePrevious(startingBalance);

  const calculateMetrics = (simulationResults) => {
    let avgEndingEquity = 0;
    let avgPL = 0;
    let avgPLpercent = 0;
    let lowerPercentile = 0;
    let upperPercentile = 0;
    let standarddev = 0;
    let maxequity = 0;
    let minequity = 0;
    let maxconsloss = 0;
    let maxconswin = 0;
    let maxdd = 0;
    let maxddpercent = 0;
    const simulationResultsNew = simulationResults.map((result) => {
      return result.data;
    });
    const endingequityarr = simulationResultsNew.map((arr) => {
      return arr[arr.length - 1];
    });
    const plarr = simulationResultsNew.map((arr) => {
      return arr[arr.length - 1] - arr[0];
    });
    maxequity = Math.max(...endingequityarr);
    minequity = Math.min(...endingequityarr);
    avgEndingEquity = arrAvg(endingequityarr);
    avgPL = arrAvg(plarr);
    lowerPercentile = percentile(endingequityarr, 95);
    upperPercentile = percentile(endingequityarr, 5);
    standarddev = getStandardDeviation(endingequityarr);
    maxconswin = calculateTradingStats(simulationResultsNew).maxConsecutiveWins;
    maxconsloss =
      calculateTradingStats(simulationResultsNew).maxConsecutiveLosses;
    maxdd = calculateTradingStats(simulationResultsNew).maxDrawdown;

    return {
      avgEndingEquity: handleNANGOOD(avgEndingEquity, "-"),
      avgPL: handleNANGOOD(avgPL, "-"),
      avgPLpercent: handleNANGOOD(avgPLpercent, "-"),
      lowerPercentile: handleNANGOOD(lowerPercentile, "-"),
      upperPercentile: handleNANGOOD(upperPercentile, "-"),
      standarddev: handleNANGOOD(standarddev, "-"),
      maxequity: handleNANGOOD(maxequity, "-"),
      minequity: handleNANGOOD(minequity, "-"),
      maxconsloss: handleNANGOOD(maxconsloss, "0"),
      maxconswin: handleNANGOOD(maxconswin, "0"),
      maxdd: handleNANGOOD(maxdd, "-"),
      maxddpercent: handleNANGOOD(maxddpercent, "-"),
    };
  };
  const movecursor = {
    paddingLeft: "10px",
  };
  return (
    <div className="simulatorWrapper">
      <div className="secondsimwrapper">
        <div className="simuserinputWrapper">
          <div className="siminputs-heading">Simulator Inputs</div>
          <div className="simuserinputWrapperinner">
            <label className="SIMselectionfields">
              Starting Balance
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="startingBalance"
                style={movecursor}
                value={startingBalance}
                onChange={(e) => {
                  setState({ ...state, startingBalance: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Win Rate (%)
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="winRate"
                style={movecursor}
                value={winRate}
                onChange={(e) => {
                  setState({ ...state, winRate: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Avg. Risk (%)
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="avgRisk"
                style={movecursor}
                value={avgRisk}
                onChange={(e) => {
                  setState({ ...state, avgRisk: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Profit/Loss Ratio
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="profitlossRatio"
                style={movecursor}
                value={profitlossRatio}
                onChange={(e) => {
                  setState({ ...state, profitlossRatio: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              <span>{`Std. Dev. (%) `}</span>
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#fff7cb",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Standard deviation of the % gains/losses of all your trades.
                    <br></br>
                  </div>
                }
                placement="bottom"
                xAdjust={27}
                yAdjust={0}
                delay={300}
                keepOpen={false}
              >
                <span className="tooptip-i-style6">&#x1D48A;</span>
              </PopoverStickOnHover>
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="stdDev"
                style={movecursor}
                value={stdDev}
                onChange={(e) => {
                  setState({ ...state, stdDev: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
          </div>
          <div className="simuserinputWrapperinner">
            <label className="SIMselectionfields">
              Number of Trades
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="numTrades"
                style={movecursor}
                value={numTrades}
                onChange={(e) => {
                  setState({ ...state, numTrades: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Number of Simulations
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="numSims"
                style={movecursor}
                value={numSims}
                onChange={(e) => {
                  setState({ ...state, numSims: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>
            <div style={{ margin: "8px 0px 0px 0px", padding: "0px" }}>
              <label className="container checkssspvt">
                <input
                  disabled={!showMaster}
                  type="checkbox"
                  id="boxNoEntrySlip"
                  name="hasEntrySlippage"
                  checked={hasUseMetrics}
                  onClick={(e) => handleUseMetrics(e)}
                />
                <span className="checkmark checkmark-col1-2"></span>
                &emsp;&ensp;Use Current Metrics
              </label>
            </div>
            {/*             {simulationTime !== 0 && (
              <div>Simulation successful! Run in {simulationTime}s</div>
            )} */}
            <button
              disabled={!showMaster}
              onClick={() => {
                var startTime, endTime;
                startTime = new Date();

                const simulationResults = simulateTradingAccount(
                  winRate,
                  avgRisk,
                  profitlossRatio,
                  stdDev,
                  startingBalance,
                  numTrades,
                  numSims
                );
                setsimulationResults(simulationResults);
                endTime = new Date();
                var timeDiff = endTime - startTime; //in ms
                // strip the ms
                timeDiff /= 1000;

                // get seconds
                //var seconds = Math.round(timeDiff);
                setsimulationTime(timeDiff.toFixed(3));
              }}
              id="runsimulationbutton"
            >
              Run Simulation
            </button>
            <button
              disabled={!showMaster}
              onClick={() => {
                setState({
                  ...state,
                  winRate: "",
                  avgRisk: "",
                  profitlossRatio: "",
                  stdDev: "",
                  startingBalance: "",
                  numTrades: "",
                  numSims: "",
                });
                sethasUseMetrics(false);
              }}
              id="runsimcancelbutton"
            >
              Clear
            </button>
          </div>
        </div>
        <div className="SIMdistributionGraphwrapper">
          <SimulationGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            userPortfolioType={
              allData.portfolio.assetClasses.length > 1
                ? []
                : allData.portfolio.assetClasses
            }
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingBalanceSetting={allData.calculations.start_Balance_setting}
            simulationResults={simulationResults}
          />{" "}
          <SimulatorDistributionGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={false}
            simulationResults={simulationResults}
          />
        </div>
        <div className="simanalysiswrapper">
          <div className="sim-metrics-label" id="sim-metrics-label">
            Simulation Analysis
          </div>
          <div className="sim-metrics-table">
            <div className="miscmetricol1">
              <div className="outermetriccell" key={"Avg. Ending Equity"}>
                <div className="innermetriccell1">{"Avg. Ending Equity"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).avgEndingEquity === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(
                        simulationResults
                      ).avgEndingEquity.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(
                        simulationResults
                      ).avgEndingEquity.toFixed(2)}
                </div>
              </div>
              <div className="outermetriccell" key={"Avg. P/L"}>
                <div className="innermetriccell1">{"Avg. P/L"}</div>
                <div
                  className={
                    calculateMetrics(simulationResults)?.avgPL > 0
                      ? "innermetriccell tiqgreen"
                      : calculateMetrics(simulationResults)?.avgPL < 0
                      ? "innermetriccell tiqred"
                      : "innermetriccell"
                  }
                >
                  {calculateMetrics(simulationResults).avgPL === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(simulationResults).avgPL.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) + calculateMetrics(simulationResults).avgPL.toFixed(2)}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"5th Percentile"}>
                <div className="innermetriccell1">{"5th Percentile"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).lowerPercentile === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(
                        simulationResults
                      ).lowerPercentile.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(
                        simulationResults
                      ).lowerPercentile.toFixed(2)}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"95th Percentile"}>
                <div className="innermetriccell1">{"95th Percentile"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).upperPercentile === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(
                        simulationResults
                      ).upperPercentile.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(
                        simulationResults
                      ).upperPercentile.toFixed(2)}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"Ending Equity Std. Dev."}>
                <div className="innermetriccell1">
                  {"Ending Equity Std. Dev."}
                </div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).standarddev === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(simulationResults).standarddev.toFixed(
                        6
                      ) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(simulationResults).standarddev.toFixed(
                        2
                      )}
                </div>
              </div>{" "}
            </div>
            <div className="miscmetricol2">
              {" "}
              <div className="outermetriccell" key={"Max. Ending Equity"}>
                <div className="innermetriccell1">{"Max. Ending Equity"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).maxequity === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(simulationResults).maxequity.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(simulationResults).maxequity.toFixed(2)}
                </div>
              </div>
              <div className="outermetriccell" key={"Min. Ending Equity"}>
                <div className="innermetriccell1">{"Min. Ending Equity"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).minequity === "-"
                    ? "-"
                    : supportedCryptosCheck
                    ? calculateMetrics(simulationResults).minequity.toFixed(6) +
                      " " +
                      String(allData.portfolio.settings.currencySymbol)
                    : he.decode(
                        String(allData.portfolio.settings.currencySymbol)
                      ) +
                      calculateMetrics(simulationResults).minequity.toFixed(2)}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"Max. Consecutive Wins"}>
                <div className="innermetriccell1">
                  {"Max. Consecutive Wins"}
                </div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).maxconswin}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"Max. Consecutive Losses"}>
                <div className="innermetriccell1">
                  {"Max. Consecutive Losses"}
                </div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).maxconsloss}
                </div>
              </div>{" "}
              <div className="outermetriccell" key={"Max. Drawdown"}>
                <div className="innermetriccell1">{"Max. Drawdown"}</div>
                <div className="innermetriccell">
                  {calculateMetrics(simulationResults).maxdd === "-"
                    ? "-"
                    : calculateMetrics(simulationResults).maxdd.toFixed(3) +
                      "%"}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulatorComponent;
