import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import ManageAccountComponent from "./component";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const ManageAccount = (props) => {
  const location = useLocation();
  const locationState = location.state;
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const active = dataInstance && dataInstance.customer.active;
  const tier = dataInstance && dataInstance.customer.tier;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Manage Account - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          title={props.tabName}
          upgradeShow={showUpgradeModal}
          setshowUpgradeModal={(e) => {
            setshowUpgradeModal({
              ...showUpgradeModal,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            });
          }}
          page={
            <div style={{ width: "100%" }} id="settingswrapper">
              <ManageAccountComponent
                allData={processedData}
                state={locationState}
              />
            </div>
          }
          userData={processedData}
        />
      </div>
    );
  }
};

ManageAccount.defaultProps = {
  tabName: "Manage Account",
  pageName: "Manage Account",
};

ManageAccount.propTypes = {
  tabName: PropTypes.string,
};

export default ManageAccount;
