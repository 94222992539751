import React from "react";
import mt4IMG1 from "../../../images/MT4/First.png";
import mt4IMG2 from "../../../images/MT4/Second.png";
import mt4IMG3 from "../../../images/MT4/Third2.png";
export const Mt4Instructions = (props) => {
  const entries = props.entries;
  return (
    <div className="showinstructionswrapper">
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 1</div>
        <div className="instructsteptext">{`In your MT4 platform, open the Terminal if it isn't already by pressing Ctrl + T or in the top left, hover over "View" and click "Terminal".`}</div>
        <img className="instructstepimage" src={mt4IMG1} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 2</div>
        <div className="instructsteptext">{`Near the bottom left of the terminal, click on "Account History"`}</div>
        <img className="instructstepimage" src={mt4IMG2} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 3</div>
        <div className="instructsteptext">{`Right click on any entry and click "Save as Report". Save the file in a location you can remember easily.`}</div>
        <img className="instructstepimage" src={mt4IMG3} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 4</div>
        <div className="instructsteptext">{`In our app, click the "Choose .htm File" button and select the file you saved from Step 3.`}</div>
      </div>
      {!entries.length ? (
        <div className="instructstepwrapper">
          <div className="instructstepstep">Step 5</div>
          <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
        </div>
      ) : (
        <div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 5</div>
            <div className="instructsteptext">{`Choose your Import Method. We recommend the "Smart Import" choice.`}</div>
          </div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 6</div>
            <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Mt4Instructions;
