export var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

export var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";
export default weekday