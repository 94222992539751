import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
//import premiumTierComponent from "./../../tierComponents/premium";

export const MegaMenuWrapper = styled.div`
  width: 208px;
  /* height: 438px; */
  height: 180px;
  top: 274px;
  border: none !important;
`;
export const MegaMenuColumn = styled.div`
  width: calc(100% / 2);
  position: relative;
  margin: -14px 0px 0px 0px;
`;
export const MegaMenuColumn2 = styled.div`
  width: calc(100% / 2);
  position: absolute;
  top: 4px;
  left: 203px;
  margin: 0;
`;
const MegaMenu = () => {
  const location = useLocation();
  return (
    <MegaMenuWrapper className="dropdown-list-insights">
      <MegaMenuColumn>
        <div className="item-megamenu">
          <div className="megamenulist">
            <div className="dropdown-text">
              <Link
                to="/Comprehensive"
                className="dropdown-items"
                style={{
                  color:
                    location.pathname === "/Comprehensive"
                      ? "#fbd665"
                      : "#deebf7",
                }}
              >
                Comprehensive
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/KPIs"
                className="dropdown-items"
                style={{
                  //textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/KPIs"
                      ? "#fbd665"
                      : "#deebf7",
                }}
              >
                KPI's
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Drawdown-RunUp"
                className="dropdown-items"
                style={{
                  //textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Drawdown-RunUp"
                      ? "#fbd665"
                      : "#deebf7",
                }}
              >
                Drawdown | Run-Up
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Risk-Management"
                className="dropdown-items"
                style={{
                  //textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Risk-Management"
                      ? "#fbd665"
                      : "#deebf7",
                }}
              >
                Risk Management
              </Link>
            </div>
{/*             <div className="dropdown-text">
              <Link
                to="/Holding-Time-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Holding-Time-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Holding Time
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/MAE-and-MFE-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/MAE-and-MFE-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                MAE and MFE
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Efficiency-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Efficiency-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Efficiency
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Fund-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Fund-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Fund Report
              </Link>
              <div style={{ position: "relative", top: 14, left: -20 }}>
                {premiumTierComponent()}
              </div>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Daily-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Daily-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Time Period
              </Link>
            </div>
            <div className="dropdown-text">
              <Link
                to="/Wins-Report"
                className="dropdown-items"
                style={{
                  textDecoration: "line-through", //comment out in main release
                  color:
                    location.pathname === "/Wins-Report"
                      ? "#fbd665"
                      : "#deebf7",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Wins/Losses/BE's
              </Link>
            </div> */}
          </div>
        </div>
      </MegaMenuColumn>
    </MegaMenuWrapper>
  );
};
export default MegaMenu;
