import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import processDataandSettings from "./../../utils/processDataandSettings";
import { DataContext } from "../../DataContext";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import CalendarComponent from "./Component";
import useForceUpdate from "../../hooks/useForceUpdate";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const Calendar = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const forceUpdate = useForceUpdate();

  let processedData = {};
  let createdPortfolios = "";
  let showINIT = true;

  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Calendar - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    createdPortfolios = allData.createdPortfolios;
    //store.dispatch(setLoaderState({ loading: false }));
  }

  // Tiering Logic
  const portfolio = processedData.portfolio;
  const numEntries = portfolio && portfolio.entries.length;
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = false;
  } else if (tier === "oldpro" && active) {
    showINIT = false;
  } else if (tier === "master" && active) {
    showINIT = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
  } else {
    showINIT = true;
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect: "Calendar",
    blur: showINIT,
  });
  // Logic for when to show the upgrade modal
  useEffect(() => {
    if (tier === "free") {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "pro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "oldpro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "master" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "ultimate" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "admin" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    }
  }, [tier, active, createdPortfolios, numEntries]);

  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return <PageTemplateBlank title={props.tabName} showloader={true} />;
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          tier={tier}
          active={active}
          title={props.tabName}
          upgradeShow={showUpgradeModal.current}
          setshowUpgradeModal={(e) => {
            showUpgradeModal.current = {
              ...showUpgradeModal.current,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            };
            forceUpdate();
          }}
          page={
            <CalendarComponent
              userData={allData}
              processedData={processedData}
              showINIT={showINIT}
              tier={tier}
              active={active}
            />
          }
          userData={processedData}
        />
      </div>
    );
  }
};

Calendar.defaultProps = {
  tabName: "Calendar",
  pageName: "Calendar",
};

Calendar.propTypes = {
  tabName: PropTypes.string,
};

export default Calendar;
