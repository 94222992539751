// GET THE SPECIFIC PORTFOLIO
export const getSpecificPortfolio = (allDataIn) => {
  // Use this function only OUTSIDE processedDataandSettings, so like index.js of pages and above
  // Inside we should processedData.portfolio
  // This function returns the selected portfolio if there is only one selected
  // If none or more than one is selected, it will return the default portfolio
  let tempVar = allDataIn.chosenPortfolios;
  let tempVarCreated = allDataIn.createdPortfolios;
  const index = tempVar.indexOf("Default");
  const newIndex = index === 1 ? 0 : 1;
  const portName =
    tempVar.length === 1 || tempVar.length > 2 ? "Default" : tempVar[newIndex];
  tempVarCreated = tempVarCreated.filter((x) => x.name === portName);
  const returnedObj = tempVarCreated[0];
  return returnedObj;
};
export const getMatchingPortfolios = (allDataIn) => {
  // This function returns the array of only selected portfolios
  let tempVar = allDataIn.chosenPortfolios.slice();
  let tempVarCreated = allDataIn.createdPortfolios.slice();
  let returnArr = [];
  tempVarCreated.forEach((portfolio) => {
    if (portfolio.name === "Default") {
    } else {
      if (tempVar.includes(portfolio.name)) {
        returnArr.push(portfolio);
      } else {
      }
    }
  });
  return returnArr;
};
export const getPortfoliowSpecificBrokerIntegration = (allDataIn, brokerId) => {
  // This function returns the portfolio that matches the broker integration name
  // Remember only one portfolio can connect to a specific broker, i.e.
  // Two portfolios can't be connected to robinhood etc
  let tempVarCreated = allDataIn.createdPortfolios.slice();
  let returnArr = [];
  tempVarCreated.forEach((portfolio) => {
    const linkedBroker = portfolio.settings.linkedBrokerInfo.integrationId;
    if (portfolio.name === "Default") {
    } else {
      if (linkedBroker === brokerId) {
        returnArr.push(portfolio);
      } else {
      }
    }
  });
  return returnArr[0];
};
export default getSpecificPortfolio;
