import getStandardDeviation from "../functions/calculations_for_metrics/standardDeviation";
import linearRegression from "../functions/calculations_for_metrics/linearRegression";
import pcorr from "../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../functions/calculations_for_metrics/steyx";
import DEVSQ from "../functions/calculations_for_metrics/DEVSQ";
import { handleNANGOOD } from "../functions/handleUndefined";
import calculateZScore from "../functions/zScoreCalculation";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../functions/sharpeRatio";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../functions/getStartorEndDateTime";
import getEntryExitLots from "./../functions/getEntryExitLots";
var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
export const PerformanceMetricsCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW,
  riskFreeRate
) => {
  const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
  const arrSum = (arr) => {
    return arr.reduce(function (a, b) {
      return a + b;
    }, 0);
  };
  /*   const getStandardDeviation = (array) => {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }; */
  const drawdownFunction = (equityCurve) => {
    // Initialisations
    var highWaterMark = -Infinity;

    // Other initialisations
    let ddVectorNew = [];

    // Loop over all the values to compute the drawdown vector
    for (var i = 0; i < equityCurve.length; ++i) {
      highWaterMark =
        equityCurve[i] > highWaterMark ? equityCurve[i] : highWaterMark;
      ddVectorNew.push(
        ((highWaterMark - equityCurve[i]) / highWaterMark) * 100
      );
    }
    // Return the computed vector
    return ddVectorNew;
  };

  const ulcerIndexFunction = (equityCurve) => {
    // Compute the drawdown function
    var ddFunc = drawdownFunction(equityCurve);
    const handleNANs = (value) => {
      if (
        isNaN(value) ||
        value === null ||
        value === Infinity ||
        value === undefined ||
        value === ""
      ) {
        return true;
      } else {
        return false;
      }
    };
    // Compute the sum of squares of this function
    var sumSquares = 0.0;
    for (var i = 0; i < ddFunc.length; ++i) {
      if (handleNANs(ddFunc[i])) {
        sumSquares += 0;
      } else {
        sumSquares += ddFunc[i] * ddFunc[i];
      }
    }
    const returnedValue = Math.sqrt(sumSquares / ddFunc.length);
    // Compute and return the ulcer index
    return returnedValue;
  };
  const getMaxDD = (x) => {
    let peak = 0;
    let maxDrawdown = 0;
    let dd = 0;
    for (let i = 0, j = x.length; i < j; i++) {
      peak = x[i] > peak ? x[i] : peak;
      dd = peak - x[i];
      maxDrawdown = dd > maxDrawdown ? dd : maxDrawdown;
    }
    return maxDrawdown;
  };
  const getMaxDDPercent = (x) => {
    let peak = 0;
    let maxDrawdown = 0;
    let dd = 0;
    for (let i = 0, j = x.length; i < j; i++) {
      peak = x[i] > peak ? x[i] : peak;
      dd = ((peak - x[i]) / peak) * 100;
      maxDrawdown = dd > maxDrawdown ? dd : maxDrawdown;
    }
    return maxDrawdown;
  };
  let result = {};
  let plArr = [];

  // Actual Calculations
  let tfIndex = 0;
  let profitFactor = 0;
  let percentprofitFactor = 0;
  let recoveryFactor = 0;
  let returnOverMaxDrawdown = 0;
  let profitLossRatio = 0;
  let percentprofitLossRatio = 0;
  let expectation = 0;
  let kellypercent = 0;
  let normalizedExpectancypercent = 0;
  let probabilityOfRandomChance = 0;
  let zScore = 0;
  let CPCIndex = 0;
  let percentCPCIndex = 0;

  let ulcerIndex = 0;
  let sharpeRatioArith = 0;
  let sharpeRatioGeo = 0;
  let sortinoRatioArith = 0;
  let sortinoRatioGeo = 0;
  let LRCorrelation = 0;
  let LRStandardError = 0;
  let KRatio = 0;
  let RINAIndex = 0;

  // Variables needed for calculations

  let totalPL_dollar = 0;
  let totalProfits = 0;
  let totalLosses = 0;
  let totalTrades = 0;
  let totalWinningTrades = 0;
  let totalLosingTrades = 0;
  let balancearray = [];
  //let actualbalancearray = start_metrics.actualbalance_array;
  /*   let totalDeposits = 0;
  let totalWithdrawals = 0; */
  let winRate = 0;
  //let firstLoss = 0;
  let newaccountbalancearr = balancearray;
  //let newnewaccountbalancearr = balancearray;
  //let newaccountbal = start_Balance;
  let fullBalanceArray = [];
  let iterationarray = [];
  //let adjfullaccountbalancearr = [];

  // ---------- Expectation initialization ----------
  let percentgainArray = [];
  let percentgainLOSSArray = [];
  let percentgainWINArray = [];
  let totalDW = start_DW;

  /*   for (var i = 0, j = actualbalancearray.length; i < j; i++) {
    iterationarray.push(i);
    fullBalanceArray.push(actualbalancearray[i]);
    if (i === 0) {
    } else {
      let profitloss = actualbalancearray[i] - actualbalancearray[i - 1];
      let percentgain = (profitloss / actualbalancearray[i - 1]) * 100;
      percentgainArray.push(percentgain);
    }
  } */
  // ------------------------------

  // ---------- Main For Loop ----------

  for (let i = 0, j = data.length; i < j; i++) {
    const entryId = data[i].entryId;
    const thisAccountBalance = Number(data[i].balance);
    let orderType = data[i].entry.orderType;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    let correctedSUM = 0;
    let x100profitLoss = Number(data[i].entry.profitLoss * 100);
    let x100commissions = Number(data[i].entry.commissions * 100);
    let x100fees = Number(data[i].entry.fees * 100);
    if (calctype === "Gross") {
      correctedSUM = x100profitLoss / 100;
    } else if (calctype === "Net") {
      correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
    }
    // ----------------
    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      /*       if (data[i].entry.orderType === "Deposit") {
        if (calctype === "Gross") {
          totalDeposits += x100profitLoss / 100;
        } else if (calctype === "Net") {
          totalDeposits += (x100profitLoss + x100fees) / 100;
        }
      } else {
      }
      if (data[i].entry.orderType === "Withdrawal") {
        if (calctype === "Gross") {
          totalWithdrawals += x100profitLoss / 100;
        } else if (calctype === "Net") {
          totalWithdrawals += (x100profitLoss + x100fees) / 100;
        }
      } else {
      } */
      totalDW += correctedSUM;
      //adjfullaccountbalancearr.shift();
      //adjfullaccountbalancearr.unshift(totalDW);
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      iterationarray.push(i);

      //newaccountbal += correctedSUM;
      let percentgain =
        (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
      fullBalanceArray.push(thisAccountBalance);

      totalPL_dollar += correctedSUM;
      totalTrades += 1;
      newaccountbalancearr.push(totalPL_dollar);
      plArr.push(correctedSUM);
      percentgainArray.push(percentgain);
      //adjfullaccountbalancearr.push(thisAccountBalance);
      if (correctedSUM > 0) {
        totalWinningTrades += 1;
        totalProfits += correctedSUM;
      } else if (correctedSUM < 0) {
        totalLosingTrades += 1;
        totalLosses += correctedSUM;
      }
      if (percentgain < 0) {
        percentgainLOSSArray.push(percentgain);
      } else if (percentgain > 0) {
        percentgainWINArray.push(percentgain);
      }
    }
  }
  /*   for (let i = 0, j = percentgainArray.length; i < j; i++) {
    if (percentgainArray[i] < 0) {
      percentgainLOSSArray.push(percentgainArray[i]);
    } else if (percentgainArray[i] > 0) {
      percentgainWINArray.push(percentgainArray[i]);
    }
  } */
  // Calculations needed after for loop
  let averagePLPerTradedollar = totalPL_dollar / totalTrades;
  let averagePLPerWindollar = totalProfits / totalWinningTrades;
  let averagePLPerLossdollar = totalLosses / totalLosingTrades;
  /*   let totalPL_percent =
    (totalPL_dollar / (totalDeposits + totalWithdrawals)) * 100; */

  let averagePLPerTradepercent = arrAvg(percentgainArray);
  let averagePLPerWinpercent = arrAvg(percentgainWINArray);
  let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);
  let stdDevperTradepercent = getStandardDeviation(percentgainArray);

  let cummulativeGain = (totalPL_dollar / totalDW) * 100;

  // Calculations
  winRate = totalWinningTrades / totalTrades;
  profitFactor = totalProfits / Math.abs(totalLosses);
  profitLossRatio = averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
  percentprofitFactor =
    arrSum(percentgainWINArray) / Math.abs(arrSum(percentgainLOSSArray));
  percentprofitLossRatio =
    averagePLPerWinpercent / Math.abs(averagePLPerLosspercent);
  recoveryFactor = totalPL_dollar / Math.abs(getMaxDD(fullBalanceArray));
  expectation = averagePLPerTradepercent / Math.abs(averagePLPerLosspercent);
  kellypercent = (averagePLPerTradedollar / averagePLPerWindollar) * 100;
  returnOverMaxDrawdown =
    cummulativeGain / Math.abs(getMaxDDPercent(fullBalanceArray)); //maxxdd percent needs a special array
  normalizedExpectancypercent =
    (averagePLPerTradedollar / Math.abs(averagePLPerLossdollar)) * 100;
  tfIndex = winRate * (1 + profitLossRatio);
  CPCIndex = profitFactor * profitLossRatio * winRate;
  percentCPCIndex = percentprofitFactor * percentprofitLossRatio * winRate;
  probabilityOfRandomChance =
    totalTrades - 1 <= 0
      ? 0
      : (1 -
          studentsTCumulativeValue(
            averagePLPerTradepercent /
              (stdDevperTradepercent / Math.sqrt(totalTrades - 1)),
            totalTrades - 1
          )) *
        100;
  ulcerIndex = ulcerIndexFunction(fullBalanceArray);
  let lineararr = [];
  const linearRegressionCalc = linearRegression(
    fullBalanceArray,
    iterationarray
  );
  const slope = linearRegressionCalc.slope;
  const intercept = linearRegressionCalc.intercept;
  for (let iu = 0, ju = fullBalanceArray.length; iu < ju; iu++) {
    let lineararrentry = slope * iu + intercept;
    lineararr.push(lineararrentry);
  }
  LRCorrelation = pcorr(fullBalanceArray, lineararr);
  LRStandardError = STEYX(fullBalanceArray, lineararr);
  KRatio =
    slope /
    ((STEYX(fullBalanceArray, lineararr) / Math.sqrt(DEVSQ(lineararr))) *
      totalTrades);
  zScore = calculateZScore(fullBalanceArray);
  sharpeRatioArith = calculateSharpeRatios(
    fullBalanceArray,
    Number(riskFreeRate)
  ).arithmeticSharpeRatio;
  sharpeRatioGeo = calculateSharpeRatios(
    fullBalanceArray,
    Number(riskFreeRate)
  ).geometricSharpeRatio;
  sortinoRatioArith = calculateSortinoRatios(
    fullBalanceArray,
    Number(riskFreeRate)
  ).arithmeticSortinoRatio;
  sortinoRatioGeo = calculateSortinoRatios(
    fullBalanceArray,
    Number(riskFreeRate)
  ).geometricSortinoRatio;
  result = {
    // TradeFuse Index
    tfIndex: handleNANGOOD(tfIndex.toFixed(3), "-"),
    // Column 1
    profitFactor: handleNANGOOD(profitFactor, "-"),
    percentprofitFactor: handleNANGOOD(percentprofitFactor, "-"),
    recoveryFactor: handleNANGOOD(recoveryFactor, "-"),
    returnOverMaxDrawdown: handleNANGOOD(returnOverMaxDrawdown, "-"),
    profitLossRatio: handleNANGOOD(profitLossRatio, "-"),
    percentprofitLossRatio: handleNANGOOD(percentprofitLossRatio, "-"),
    expectation: handleNANGOOD(expectation, "-"),
    kellypercent: handleNANGOOD(kellypercent, "-"),
    normalizedExpectancypercent: handleNANGOOD(
      normalizedExpectancypercent,
      "-"
    ),
    probabilityOfRandomChance: handleNANGOOD(probabilityOfRandomChance, "-"),
    zScore: handleNANGOOD(zScore.zScore, "-"),
    percentConfidence: handleNANGOOD(zScore.confidence, "-"),
    CPCIndex: handleNANGOOD(CPCIndex, "-"),
    percentCPCIndex: handleNANGOOD(percentCPCIndex, "-"),
    ulcerIndex: handleNANGOOD(ulcerIndex, "-"),
    sharpeRatioArith: handleNANGOOD(sharpeRatioArith, "-"),
    sharpeRatioGeo: handleNANGOOD(sharpeRatioGeo, "-"),
    sortinoRatioArith: handleNANGOOD(sortinoRatioArith, "-"),
    sortinoRatioGeo: handleNANGOOD(sortinoRatioGeo, "-"),
    LRCorrelation: handleNANGOOD(LRCorrelation, "-"),
    LRStandardError: handleNANGOOD(LRStandardError, "-"),
    KRatio: handleNANGOOD(KRatio, "-"),
    RINAIndex: handleNANGOOD(RINAIndex, "-"),
    start_Balance,
    start_DW,
  };
  return result;
};

export default PerformanceMetricsCalculations;
