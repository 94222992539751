import React from "react";
import PropTypes from "prop-types";
import FilterMenu from "./FilterMenu";

const FilterMenuFunction = (props) => {
  const userData = props.data;
  return (
    <div id="filtermenunewwrapper" className="filtermenunewwrapper">
      <FilterMenu
        userData={userData}
        setFilterMenu={(e) => props.setFilterMenu(e)}
      />
    </div>
  );
};

FilterMenuFunction.propTypes = {
  userId: PropTypes.string,
};

export default FilterMenuFunction;
