import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../../components/PageTemplate/PageTemplate';


const MonthlyReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Monthly Report </div>
  } />
);

MonthlyReport.defaultProps = {
tabName: "Monthly"
}

MonthlyReport.propTypes = {
tabName: PropTypes.string,
}

export default MonthlyReport;

