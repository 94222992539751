const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const STEYX = (data_y, data_x) => {
  var xmean = arrAvg(data_x);
  var ymean = arrAvg(data_y);
  var n = data_x.length;
  var lft = 0;
  var num = 0;
  var den = 0;
  for (var i = 0; i < n; i++) {
    lft += Math.pow(data_y[i] - ymean, 2);
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den += Math.pow(data_x[i] - xmean, 2);
  }
  return Math.sqrt((lft - num * num / den) / (n - 2));
};
export default STEYX