import React, { useEffect, useState, useCallback, useRef } from "react";
import { toppinnedMetrics } from "./arrays";
import { Line } from "react-chartjs-2";
import isArray from "./../../functions/arrayFunctions/isArray";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import { Link } from "react-router-dom";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import calculatePercentChange from "./../../functions/getPercentChange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { getDateDifferencewSeconds } from "./../../functions/getDateDifference";
import getColorPoint from "../../functions/includeColor";
import { handleNANGOOD } from "../../functions/handleUndefined";
import $ from "jquery";

var he = require("he");

const TopPinnedMetrics = (props) => {
  const firstUpdate = useRef(true);
  const history = useHistory();
  const [factor, setfactor] = useState(0);
  const [factorRM, setfactorRM] = useState(0);
  const [riskWidth, setriskWidth] = useState(0);
  const [emptyWidth, setemptyWidth] = useState(0);
  const dasheditMode = props.dasheditMode;
  const entries = props.userData;
  const calcs = props.userCalculations;
  const symbol = props.defaultSymbol;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const dashboardSettings = props.userSettings.dashboardElements;

  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  useEffect(() => {
    const element = $("#dashimportantmetricgraphwraper");
    const elementRM = $("#editimportantmetricgraphwraper3");
    const elementRisk = $("#MMimportantmetricgraphwraper3");

    let weightfactor =
      Number(element.width() - 10) / (calcs.avgRRRvalueonly + 1);
    let weightfactorRM =
      Number(elementRM.width() - 10) / (calcs.rmultipleratio + 1);
    let riskWidthTemp = Number(elementRisk.width()) * (calcs.averageRisk / 100);
    setfactor(weightfactor);
    setfactorRM(weightfactorRM);
    setriskWidth(riskWidthTemp);
    setemptyWidth(Number(elementRisk.width()) - riskWidthTemp);
    $(window).resize(function () {
      let weightfactor =
        Number(element.width() - 10) / (calcs.avgRRRvalueonly + 1);
      let weightfactorRM =
        Number(elementRM.width() - 10) / (calcs.rmultipleratio + 1);
      let riskWidthTemp =
        Number(elementRisk.width()) * (calcs.averageRisk / 100);
      setfactor(weightfactor);
      setfactorRM(weightfactorRM);
      setriskWidth(riskWidthTemp);
      setemptyWidth(Number(elementRisk.width()) - riskWidthTemp);
    });
    if (firstUpdate.current) {
    }

    return () => {
      firstUpdate.current = false;
    };
  }, [calcs.avgRRRvalueonly, calcs.rmultipleratio, calcs.averageRisk]);
  const rgraph = useCallback(
    (neededGraph) => {
      let returnGraph = "";
      if (neededGraph.calcprop === "avgRRR") {
        returnGraph = (
          <div id="dashimportantmetricgraphwraper">
            <PopoverStickOnHover
              placement="bottom"
              delay={350}
              keepOpen={false}
              xAdjust={10}
              yAdjust={0}
              component={
                <div style={{ color: "#fff7cb" }}>
                  {"Reward: " + calcs.avgRRRvalueonly}
                </div>
              }
            >
              <div
                style={{
                  display: "block",
                  height: 10,
                  width: handleNANGOOD(calcs.avgRRRvalueonly * factor, 0),
                  backgroundColor: "#55f8a6",
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  filter: "drop-shadow(0 0 2px #55f8a6)",
                  WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
                  MozFilter:
                    "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
                  msFilter:
                    "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
                }}
              ></div>
            </PopoverStickOnHover>
            <PopoverStickOnHover
              placement="bottom"
              delay={350}
              keepOpen={false}
              xAdjust={10}
              yAdjust={0}
              component={<div style={{ color: "#fff7cb" }}>{"Risk: " + 1}</div>}
            >
              <div
                style={{
                  display: "block",
                  height: 10,
                  width: handleNANGOOD(factor, 0),
                  backgroundColor: "#fa798b",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  filter: "drop-shadow(0 0 2px #fa798b)",
                  WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
                  MozFilter:
                    "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
                  msFilter:
                    "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
                }}
              ></div>{" "}
            </PopoverStickOnHover>
          </div>
        );
      } else if (neededGraph.calcprop === "rmultipleratio") {
        returnGraph = (
          <div id="editimportantmetricgraphwraper3">
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(calcs.rmultipleratio * factorRM, 0),
                backgroundColor: "#55f8a6",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                filter: "drop-shadow(0 0 2px #55f8a6)",
                WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
                MozFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
              }}
            ></div>
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(factorRM, 0),
                backgroundColor: "#fa798b",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                filter: "drop-shadow(0 0 2px #fa798b)",
                WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
                MozFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
              }}
            ></div>
          </div>
        );
      } else {
        //linear-gradient(to right, #55f8a6 0%, #e1f967 5%, #ff9735 10%, rgb(238, 73, 73) 15%, rgb(139, 43, 43) 20%)

        let color = "";
        if (calcs.averageRisk < 5) {
          color = getColorPoint(
            "#49f875",
            "#e1f967",
            calcs.averageRisk / 5,
            "hex"
          );
        } else if (calcs.averageRisk >= 5 && calcs.averageRisk < 10) {
          color = getColorPoint(
            "#e1f967",
            "#ff9735",
            (calcs.averageRisk - 5) / 5,
            "hex"
          );
        } else if (calcs.averageRisk >= 10 && calcs.averageRisk < 15) {
          color = getColorPoint(
            "#ff9735",
            "rgb(238, 73, 73)",
            (calcs.averageRisk - 10) / 5,
            "hex"
          );
        } else if (calcs.averageRisk >= 15 && calcs.averageRisk < 20) {
          color = getColorPoint(
            "rgb(238, 73, 73)",
            "rgb(139, 43, 43)",
            (calcs.averageRisk - 15) / 5,
            "hex"
          );
        } else {
          color = "rgb(139, 43, 43)";
        }
        returnGraph = (
          <div id="MMimportantmetricgraphwraper3">
            <div
              style={{
                display: "block",
                height: 6,
                width: handleNANGOOD(riskWidth, 0),
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                background: color,
                filter: "drop-shadow(0 0 3px #171c23)",
                WebkitFilter: "drop-shadow(0 0 3px #171c23)",
                MozFilter:
                  "drop-shadow(0 0 3px #171c23)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 3px #171c23)" /* Internet Explorer/Edge */,
              }}
            ></div>
            <div
              style={{
                display: "block",
                height: 6,
                width: handleNANGOOD(emptyWidth, 0),
                right: 6,
                borderBottomRightRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: "#1a2028",
              }}
            ></div>
          </div>
        );
      }
      return returnGraph;
    },
    [
      emptyWidth,
      factorRM,
      factor,
      calcs.rmultipleratio,
      calcs.avgRRRvalueonly,
      riskWidth,
      calcs.averageRisk,
    ]
  );

  const returnMetrics = () => {
    let elements = [];
    let graphs = dashboardSettings.toppinnedmetrics;
    const equityvaluefunction = (calcprop, graphprop) => {
      if (!calcprop) {
      } else {
        if (
          calcprop === "avgPLPerTrade" ||
          calcprop === "twentyFourHrPL" ||
          calcprop === "standardDeviation" ||
          calcprop === "volatility"
        ) {
          return {
            calc: supportedCryptosCheck
              ? calcs[calcprop] + " " + he.decode(symbol)
              : he.decode(symbol) + calcs[calcprop],
            arr:
              calcs &&
              calcs[graphprop]?.map((obj) => {
                return obj.value;
              }),
            arrAll: calcs[graphprop],
          };
        } else if (
          calcprop === "probabilityOfRandomChance" ||
          calcprop === "percentConfidence"
        ) {
          return {
            calc: calcs[calcprop] + "%",
            arr:
              calcs &&
              calcs[graphprop]?.map((obj) => {
                return obj.value;
              }),
            arrAll: calcs[graphprop],
          };
        } else {
          return {
            calc: calcs[calcprop],
            arr:
              calcs &&
              calcs[graphprop]?.map((obj) => {
                return obj.value;
              }),
            arrAll: calcs[graphprop],
          };
        }
      }
    };
    const getEntrybyIndex = (calcprop, graphprop, i) => {
      const arr = equityvaluefunction(calcprop, graphprop).arrAll;
      let entryPre = {};
      let entry = {};
      entryPre = arr.filter((_, index) => index === i);
      const neededEntryId = entryPre && entryPre[0].id;
      entry = entries.filter((entry) => entry.entryId === neededEntryId);
      return entry;
    };
    const xaxisfunction = (arr) => {
      let xaxisvalue = [];
      for (var iiy = 0, jjy = arr.length; iiy < jjy; iiy++) {
        xaxisvalue.push(iiy + 1);
      }
      return xaxisvalue;
    };
    //const lineColor = "#ffeb94";

    const data = (props) => {
      const lineColor = props.color; //purple
      return {
        labels: xaxisfunction(
          isArray(equityvaluefunction(props.calcprop, props.graphprop).arr)
            ? equityvaluefunction(props.calcprop, props.graphprop).arr
            : []
        ),
        datasets: [
          {
            label: "Your Equity",
            fill: false,
            bezierCurve: true,
            backgroundColor: lineColor,
            borderColor: lineColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "round",
            pointBorderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: lineColor,
            pointHoverBorderColor: lineColor,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: equityvaluefunction(props.calcprop, props.graphprop).arr,
          },
        ],
      };
    };
    const options = (props) => {
      return {
        tooltips: {
          mode: "index",
          intersect: false,
          backgroundColor: "#14181e",
          titleFontSize: 14,
          titleFontColor: "#fbd665",
          bodyFontColor: "#fff7cb",
          bodyFontSize: 14,
          displayColors: false,
          enabled: true,
          callbacks: {
            title: () => {
              return `${props.title}: `;
            },
            label: (tooltipItems) => {
              let tooltipLabel = "";
              if (
                props.title === "Golden Ratio" ||
                props.title === "TradeFuse Index" ||
                props.title === "Profit/Loss Ratio" ||
                props.title === "Profit Factor" ||
                props.title === "Expectation" ||
                props.title === "Z-Score" ||
                props.title === "Statistical Z-Score" ||
                props.title === "LR Standard Error" ||
                props.title === "LR Correlation" ||
                props.title === "K-Ratio" ||
                props.title === "Sharpe ratio (Arithmetic)" ||
                props.title === "Sortino ratio (Arithmetic)" ||
                props.title === "Sortino ratio (Geometric)" ||
                props.title === "Sharpe ratio (Geometric)" ||
                props.title === "CPC Index" ||
                props.title === "Recovery Factor" ||
                props.title === "Ulcer Index"
              ) {
                tooltipLabel = parseFloat(tooltipItems.yLabel.toFixed(3));
              } else if (props.title === "Avg. Position Size") {
                tooltipLabel = parseFloat(tooltipItems.yLabel.toFixed(2));
              } else if (
                props.title === "PRC" ||
                props.title === "Percent Confidence"
              ) {
                tooltipLabel = parseFloat(tooltipItems.yLabel.toFixed(3)) + "%";
              } else if (
                props.title === "Avg. P/L Per Trade" ||
                props.title === "24hr P/L" ||
                props.title === "Standard Deviation" ||
                props.title === "Volatility"
              ) {
                tooltipLabel =
                  he.decode(symbol) +
                  parseFloat(tooltipItems.yLabel.toFixed(2));
              } else if (props.title === "Avg. Holding Time") {
                tooltipLabel = getDateDifferencewSeconds(
                  tooltipItems.yLabel.toFixed(3)
                );
              }
              return tooltipLabel;
            },
          },
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = "default";
        },
        onClick: (event, chartElement) => {
          if (!chartElement.length) {
          } else {
            toComponentB2(
              getEntrybyIndex(
                props.calcprop,
                props.graphprop,
                chartElement[0]._index
              )[0]
            );
          }
        },
        hover: {
          mode: "index",
          intersect: false,
        },
        interaction: {
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#deebf7",
                callback: function (value) {
                  return "";
                },
              },
              scaleLabel: {
                display: false,
                labelString: "Equity",
                fontColor: "#fbd665",
                fontSize: 14,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#deebf7",
                callback: function (value) {
                  return "";
                },
              },
              scaleLabel: {
                display: false,
                labelString: "Entry Number",
                fontColor: "#fbd665",
                fontSize: 14,
              },
            },
          ],
        },
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 0,
        legend: {
          display: false,
        },
      };
    };
    graphs.forEach((g) => {
      const filterByID = (metric) => {
        const metricKey = Object.keys(metric)[0];
        if (metricKey === g) {
          return true;
        } else {
          return false;
        }
      };
      let neededGraphPre = toppinnedMetrics.filter(filterByID)[0];

      let neededGraph =
        neededGraphPre && neededGraphPre[Object.keys(neededGraphPre)[0]];
      const percentChange = calculatePercentChange(
        equityvaluefunction(neededGraph.calcprop, neededGraph.graphprop).arr
      );
      const graph = neededGraph && (
        <div
          className="toppinnedmetricwrapper noselect"
          key={"topgraph" + g}
          id={g}
        >
          <div className="deletepicbutton2" onClick={() => {}}>
            X
          </div>
          <div className="topmetricheaderwrapper">
            <div className="topmetricheader">
              {neededGraph.title}
              {(neededGraph.title === "Golden Ratio" ||
                neededGraph.title === "Profit/Loss Ratio") && (
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {neededGraph.tooltip} <br></br>
                      {neededGraph.forwardpath !== "" && (
                        <Link
                          to={neededGraph.forwardpath}
                          className="linktooltiphover"
                        >
                          View Risk Management &#8594;
                        </Link>
                      )}
                    </div>
                  }
                  placement="bottom"
                  onMouseEnter={() => {}}
                  xAdjust={27}
                  yAdjust={0}
                  delay={300}
                  keepOpen={true}
                >
                  <div className="tooptip-i-style">&#x1D48A;</div>
                </PopoverStickOnHover>
              )}
              {(neededGraph.title === "TradeFuse Index" ||
                neededGraph.title === "PRC" ||
                neededGraph.title === "CPC Index" ||
                neededGraph.title === "Expectation" ||
                neededGraph.title === "Recovery Factor" ||
                neededGraph.title === "Ulcer Index") && (
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {neededGraph.tooltip} <br></br>
                      {neededGraph.forwardpath !== "" && (
                        <Link
                          to={neededGraph.forwardpath}
                          className="linktooltiphover"
                        >
                          View KPIs &#8594;
                        </Link>
                      )}
                    </div>
                  }
                  placement="bottom"
                  onMouseEnter={() => {}}
                  xAdjust={27}
                  yAdjust={0}
                  delay={300}
                  keepOpen={true}
                >
                  <div className="tooptip-i-style">&#x1D48A;</div>
                </PopoverStickOnHover>
              )}
              {percentChange > 0 ? (
                <div className="percentchangenumber tiqgreen">
                  {percentChange + "%"}
                  <div
                    style={{
                      transform: "rotate(270deg)",
                      height: 0,
                      width: 0,
                      fontSize: "0.7em",
                      position: "relative",
                      top: 14,
                      left: 3,
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                </div>
              ) : percentChange < 0 ? (
                <div className="percentchangenumber tiqred">
                  {percentChange + "%"}
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      height: 0,
                      width: 0,
                      fontSize: "0.7em",
                      position: "relative",
                      top: 6,
                      left: 15,
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                </div>
              ) : percentChange === 0 ? (
                <div className="percentchangenumber tiqgrey">
                  {percentChange + "%"}
                </div>
              ) : (
                <div className="percentchangenumber tiqgrey">
                  {percentChange + "%"}
                </div>
              )}
            </div>
            <div className={neededGraph.valueClass}>
              {equityvaluefunction(neededGraph.calcprop, neededGraph.graphprop)
                .calc === "-"
                ? "-"
                : equityvaluefunction(
                    neededGraph.calcprop,
                    neededGraph.graphprop
                  ).calc}
            </div>
          </div>
          <div className="topmetricgraphwrapper">
            {neededGraph.calcprop === "avgRRR" ||
            neededGraph.calcprop === "rmultipleratio" ? (
              rgraph(neededGraph)
            ) : (
              <Line
                data={data(neededGraph)}
                options={options(neededGraph)}
                className="ovallreportgraphactual"
                onElementsClick={(elems) => {
                  /*                 console.log(elems);
                if (!elems.length) {
                } else {
                  toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
                }
                // if required to build the URL, you can
                // get datasetIndex and value index from an `elem`:
                //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
                // and then redirect to the target page:
                //window.location = "https://example.com"; */
                }}
              />
            )}
          </div>
        </div>
      );
      elements = elements.concat(graph);
    });
    return elements;
  };
  //const key = `superToppinnedMetricsWrapper.${String(!dasheditMode)}`;
  return (
    <div
      className={
        dasheditMode
          ? "superToppinnedMetricsWrapper"
          : "superToppinnedMetricsWrapper"
      }
      id="superToppinnedMetricsWrapper"
      /*       key={key}
       */
    >
      {/*       <div
        className={
          dasheditMode
            ? "superToppinnedMetricsWrappereditablehov"
            : "superToppinnedMetricsWrapperhov"
        }
      ></div> */}
      {returnMetrics()}
    </div>
  );
};

export default TopPinnedMetrics;
