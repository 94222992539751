import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  drawdownDOLLARlabels,
  drawdownPERCENTlabels,
  drawdownDOLLARmetriclabels,
  drawdownPERCENTmetriclabels,
} from "./arrays";
import { DataContext } from "../../DataContext";
import { useHistory } from "react-router-dom";

import useForceUpdate from "../../hooks/useForceUpdate";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import moment from "moment/moment";
import $ from "jquery";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import supportedCryptos from "../../arrays/isCurrencyCrypto";

var he = require("he");
export const MetricComponent = (props) => {
  const { user /* isAuthenticated */ } = useAuth0();
  const tier = props.tier;
  const active = props.active;
  // Tiering
  let toggledisabled = true;
  if (tier === "free") {
  } else if (tier === "pro" && active) {
    toggledisabled = false;
  } else if (tier === "oldpro" && active) {
    toggledisabled = false;
  } else if (tier === "master" && active) {
    toggledisabled = false;
  } else if (tier === "ultimate" && active) {
    toggledisabled = false;
  } else if (tier === "admin" && active) {
    toggledisabled = false;
  } else {
  }

  const allData = props.data;

  const calculations = allData.calculations;
  const settings = allData.portfolio.settings;
  const symbol = allData.portfolio.settings.currencySymbol;
  const toggleState = props.toggleState;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);

  /// LOCAL STORAGE VARIABLES
  var localDrawdownSettings = JSON.parse(
    localStorage.getItem("drawdownSetting")
  );
  var isDBRunning = localStorage.getItem("dbSetRunning");

  // set intial drawdown to local storage is local storage does not equal null
  const displayTypeINIT =
    localDrawdownSettings !== null
      ? localDrawdownSettings
      : settings.drawdownCalculationType === "Dollar"
      ? false
      : true;

  const [calcState, setcalcState] = useState(displayTypeINIT);
  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);
  const allGlobalData = useContext(DataContext);
  const history = useHistory();

  const forceUpdate = useForceUpdate();
  const firstUpdate = useRef(true);
  const PerformanceMetricsLabels = !calcState
    ? drawdownDOLLARlabels(symbol)
    : drawdownPERCENTlabels;

  const handleNANs = (value, valuestring) => {
    const neededValue = valuestring;
    const returnedValue =
      value === null || value === undefined || value === ""
        ? "-"
        : neededValue === "dateofMaxDrawdown" ||
          neededValue === "dateofMaxDrawdownPercent"
        ? moment(value).format("MM/DD/YYYY")
        : neededValue === "longestDrawdownPeriod" ||
          neededValue === "averageDrawdownPeriod"
        ? value
        : calcState === false
        ? supportedCryptosCheck
          ? parseFloat(Number(value).toFixed(6)) + " " + he.decode(symbol)
          : he.decode(symbol) + parseFloat(Number(value)).toFixed(2)
        : parseFloat(Number(value)).toFixed(3) + "%";
    return returnedValue;
  };

  const createMetricArray = (arr) => {
    let temparr = [];
    for (let i = 0, j = arr.length; i < j; i++) {
      temparr = temparr.concat(handleNANs(calculations[arr[i]], arr[i]));
    }
    return temparr;
  };

  const calcs = !calcState
    ? createMetricArray(drawdownDOLLARmetriclabels)
    : createMetricArray(drawdownPERCENTmetriclabels);
  const handleChange = async (e) => {
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
    localStorage.setItem("drawdownSetting", JSON.stringify(e));
    setcalcState(e);
    forceUpdate();
  };

  // This function gets called after 1.6 seconds of
  // no user provoked state changes
  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localDrawdownSettings2 = JSON.parse(
      localStorage.getItem("drawdownSetting")
    );
    const checkvar =
      settings.drawdownCalculationType === "Dollar" ? false : true;
    let propsToChangei = {
      drawdownCalculationType:
        localDrawdownSettings2 !== null
          ? localDrawdownSettings2 === false
            ? "Dollar"
            : "Percent"
          : checkvar,
    };
    let globalpropsToChange = {};
    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    allGlobalData.updateAllData(finalPush);

    // SET S3 DATA
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, settings, allGlobalData, user]);

  useEffect(() => {
    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleCallSetJSON();
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    allData,
    localDrawdownSettings,
    settings,
    firstUpdate,
    calcState,
    callcounter,
    finalStateTimeChanged,
    handleCallSetJSON,
    isDBRunning,
  ]);
  let tableRows = [];
  for (let i = 0; i < PerformanceMetricsLabels.length; i++) {
    tableRows = tableRows.concat(
      <div className="outermetriccell" key={PerformanceMetricsLabels[i]}>
        <div>
          <div className="innermetriccell1PM">
            {PerformanceMetricsLabels[i]}
          </div>
        </div>
        <div className="innermetriccell">{calcs[i]}</div>
      </div>
    );
  }
  const divtoreturn = (
    <div className="drawdown-metrics-table">
      {toggleState === "Drawdown" ? (
        <div
          className="drawdown-metrics-label tiqred"
          id="drawdown-metrics-label"
        >
          Drawdown KPI's
        </div>
      ) : (
        <div
          className="drawdown-metrics-label tiqgreen"
          id="drawdown-metrics-label"
        >
          Run-Up KPI's
        </div>
      )}
      <div
        className="select-calculation-type-drawdown"
        id="select-calculation-type-drawdown"
      >
        {he.decode(String(symbol))}
        <label className="switch">
          <input
            disabled={toggledisabled}
            id="perftogglebutton"
            type="checkbox"
            checked={calcState}
            onChange={(e) => handleChange(e.target.checked)}
          />
          <span className="sliderperf roundperf"></span>
        </label>
        {"%"}
      </div>
      <div className="drawdown-metrics">{tableRows}</div>
    </div>
  );
  return divtoreturn;
};
export default MetricComponent;
