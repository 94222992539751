import { firstAIOuput } from "./aiFileImportMsgs";
import {
  getOpenAIMessage,
  getOpenAIMessageMaster,
} from "./../../utils/handleOpenAIRequest";
export const csvToArray = (str, delimiter = ",") => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
  const headersNew = headers.map((element) => {
    return element.trim();
  });
  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headersNew.reduce(function (object, header, index) {
      const rowValue = values[index];
      object[header] = String(rowValue).trim();
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
};

export const csvToArrayFromFills = (str, delimiter = `","`) => {
  // Find the index of "Fills" section start
  const fillsStartIndex = str.indexOf("Fills");
  // Slice from "Fills" section start to the end of the text
  const headersStart = str.indexOf("\n", fillsStartIndex) + 1; // Start of headers just after "Fills"
  const headers = str
    .slice(headersStart, str.indexOf("\n", headersStart))
    .split(delimiter)
    .map((header) => header.trim().replace(/^\"|\"$/g, "")); // Remove quotes at the start and end

  // Slice from the end of headers to the end of the text
  const rowsStart = str.indexOf("\n", headersStart) + 1;
  const rows = str.slice(rowsStart).split("\n");

  // Map the rows
  const arr = rows.map((row) => {
    const values = row
      .split(delimiter)
      .map((value) => value.trim().replace(/^\"|\"$/g, "")); // Remove quotes at the start and end
    const el = headers.reduce((object, header, index) => {
      object[header] = values[index] || ""; // Use || "" to handle missing values
      return object;
    }, {});
    return el;
  });

  // Return the array
  return arr;
};

function findRowIndexAfterHeaders(csvString, headers) {
  // Split the stringified CSV into rows
  const rows = csvString.trim().split("\n");

  // Iterate through each row
  for (let i = 0; i < rows.length; i++) {
    // Split the current row into columns by comma
    const columns = rows[i].trim().split(",");

    // Check if the current row's columns match the headers
    if (
      columns.length === headers.length &&
      columns.every((value, index) => value === headers[index])
    ) {
      // Return the index of the next row if headers match
      return i + 1;
    }
  }

  // Return -1 if no matching header row is found
  return -1;
}

/**
 * Generalizes CSV to array conversion to work with any delimiter and quote character.
 * Automatically trims spaces and handles quoted fields.
 *
 * @param {string} str - The CSV string.
 * @param {string} [delimiter=','] - The field delimiter. Default is comma.
 * @param {string} [quoteChar='"'] - The character used for quoting fields.
 * @returns {Object[]} An array of objects, each representing a row with key-value pairs.
 */
/* export const csvToArrayGeneral = async (
  str,
  delimiter = ",",
  quoteChar = '"'
) => {
  // Split headers
  const headersStart = 0; // Start of headers just after "Fills"
  const alteredInput2 = firstAIOuput(str);
  let parsedObject2;
  try {
    const output = await getOpenAIMessage(alteredInput2);
    if (output !== "err") {
      function removeFormatting(text) {
        // Replace the beginning "```json" with an empty string
        let result = text.replace("```json", "");

        // Replace the ending "```" with an empty string
        result = result.replace("```", "");

        return result;
      }

      // Example usage
      const formattedString = output.content;
      const cleanString = removeFormatting(formattedString);
      parsedObject2 = JSON.parse(cleanString);
    }
  } catch (error) {
    // Handle the error here
    console.error("Error parsing JSON:", error);
    // Set a default value or perform any other necessary actions
    parsedObject2 = null; // or any other appropriate default value
  }
  const { headers } = parsedObject2 || {};
  console.log(headers);
  const headersStartIndex = findRowIndexAfterHeaders(str, headers);
  // Slice from "Fills" section start to the end of the text
  console.log(headersStartIndex);
  // Slice from the end of headers to the end of the text
  const rowsStart =
    str.indexOf("\n", headersStartIndex ? headersStartIndex : 0) + 1;

  // Slice from the end of headers to the end of the text
  const rows = str.slice(0).split("\n");

  // Map the rows
  const arr = rows.map((row) => {
    const values = row
      .split(delimiter)
      .map((value) => value.trim().replace(/^\"|\"$/g, "")); // Remove quotes at the start and end
    const el = headers.reduce((object, header, index) => {
      object[header] = values[index] || ""; // Use || "" to handle missing values
      return object;
    }, {});
    return el;
  });

  return arr.filter((row) => Object.keys(row).length > 0); // Filter out empty rows
}; */

export const csvToArrayGeneral = async (
  str,
  delimiter = ",",
  quoteChar = '"'
) => {
  // Helper function to split a row by delimiter and respect quoted sections
  const splitRow = (row, delimiter, quoteChar) => {
    let values = []; // Stores the split values
    let currentVal = "";
    let inQuotes = false;

    for (let char of row) {
      if (char === quoteChar) {
        // Toggle inQuotes state
        inQuotes = !inQuotes;
      } else if (char === delimiter && !inQuotes) {
        // End of a value, push to values array
        values.push(currentVal);
        currentVal = "";
      } else {
        // Accumulate characters into currentVal
        currentVal += char;
      }
    }
    // Push the last value as it wouldn't be followed by a delimiter
    values.push(currentVal);

    return values.map((value) =>
      value.trim().replace(new RegExp(`^${quoteChar}|${quoteChar}$`, "g"), "")
    );
  };

  const csvArrays = str
    .split("\n")
    .filter((row) => row) // Filter out any empty rows
    .map((row, id) => {
      // Split the row into items, then map over each item.
      // If an item is an empty string, replace it with "**blank".
      const rowItems = splitRow(row, delimiter, quoteChar).map((item) =>
        item === "" ? "blank" : item
      );
      return { line: id, row: rowItems };
    });
  // Split headers
  const headersStart = 0; // Start of headers just after "Fills"
  const alteredInput2 = firstAIOuput(csvArrays);
  console.log(alteredInput2);
  let parsedObject2;
  try {
    const output = await getOpenAIMessageMaster(alteredInput2);
    console.log(output);
    if (output !== "err") {
      function removeFormatting(text) {
        // Replace the beginning "```json" with an empty string
        let result = text?.replace("```json", "");

        // Replace the ending "```" with an empty string
        result = result?.replace("```", "");

        return result;
      }

      // Example usage
      const formattedString = output.content;
      const cleanString = removeFormatting(formattedString);
      parsedObject2 = JSON.parse(cleanString);
    }
  } catch (error) {
    // Handle the error here
    console.error("Error parsing JSON:", error);
    // Set a default value or perform any other necessary actions
    parsedObject2 = null; // or any other appropriate default value
  }
  const { headers, dataType, headersNew } = parsedObject2 || {};
  console.log(parsedObject2);
  const headersActual = headers.row;
  const headersIndex = headers.line;

  // Find the index of the "Price" field in your headers array
  const priceIndex = headers.findIndex((header) => header === "Size");

  const startIndex =
    csvArrays.findIndex(
      (row) =>
        headers.length === row.length &&
        headers.every((header, index) => header === row[index])
    ) + 1; // +1 to start processing from the next row after headers

  let endIndex = csvArrays.length; // Default to the end unless a drastic change is found

  for (let i = startIndex; i < csvArrays.length; i++) {
    const row = csvArrays[i];
    // Check if the "Price" field is blank for this row
    if (row[priceIndex] && row[priceIndex].trim() === "") {
      endIndex = i;
      break; // Found the row where "Price" becomes blank, indicating a drastic change
    }
  }

  // Slice the csvArrays to get only the relevant rows
  const rows = csvArrays.slice(
    startIndex,
    endIndex === -1 ? undefined : endIndex
  );

  // Map the rows
  // Assuming 'rows' is now an array of arrays, each representing a parsed CSV row
  const arr = rows.map((rowArray) => {
    const el = headersNew.reduce((object, header, index) => {
      object[header] = rowArray[index] || ""; // Use || "" to handle missing values
      return object;
    }, {});
    return el;
  });
  // Split CSV into rows and parse each row
  return arr;
};

export default csvToArray;
