export const allMT5brokers = [
  {
    label: "10TradeFX",
    server1: "10tradefx-Server",
    server2: "TenTrade-Server",
    value: "10TradeFX",
  },
  {
    label: "1Market",
    server1: "1Market-Live",
    value: "1Market",
  },
  {
    label: "305Markets",
    server1: "305Markets-Live",
    value: "305Markets",
  },
  {
    label: "360 Capital",
    server1: "360Capital-Demo",
    server2: "360Capital-Live",
    value: "360 Capital",
  },
  {
    label: "3TFX",
    server1: "3TVentureCapital-MetaTrader5",
    value: "3TFX",
  },
  {
    label: "4T",
    server1: "mt4live.4t.com",
    value: "4T",
  },
  {
    label: "4xCube",
    server1: "4xCube-MT5",
    value: "4xCube",
  },
  {
    label: "7Axiom",
    server1: "7AxiomLLC-Demo",
    server2: "7AxiomLLC-Live",
    value: "7Axiom",
  },
  {
    label: "A-Book Broker",
    server1: "ABook-Live",
    value: "A-Book Broker",
  },
  {
    label: "AAFX Trading",
    server1: "AAFX-Live",
    server2: "AAFX-Demo",
    value: "AAFX Trading",
  },
  {
    label: "ABC Group Ltd",
    server1: "ABCGroup-LIVE",
    server2: "ABCGroup-Demo",
    value: "ABC Group Ltd",
  },
  {
    label: "Abet Global",
    server1: "AbetLLC-Server",
    value: "Abet Global",
  },
  {
    label: "Access Direct Markets",
    server1: "AccessDirectMarkets-Demo",
    server2: "AccessDirectMarkets-Live",
    value: "Access Direct Markets",
  },
  {
    label: "Accuindex",
    server1: "AccuindexLimited-Demo",
    server2: "AccuindexLimited-Live",
    value: "Accuindex",
  },
  {
    label: "Active Broker",
    server1: "Quotex-Real",
    value: "Active Broker",
  },
  {
    label: "ActiveProFX",
    server1: "IronTulip-Real",
    value: "ActiveProFX",
  },
  {
    label: "ActivTrades",
    server1: "ActivTradesCorp-Server",
    server2: "ActivTrades-Server",
    server3: "ActivTradesEurope-Server",
    value: "ActivTrades",
  },
  {
    label: "ActivTrades",
    server1: "ActivTradesCorp-Server",
    server2: "ActivTrades-Server",
    server3: "ActivTradesEurope-Server",
    value: "ActivTrades",
  },
  {
    label: "ACY FX",
    server1: "ACYSecurities-Live",
    value: "ACY FX",
  },
  {
    label: "ACY Securities",
    server1: "ACYSecurities-Demo",
    server2: "ACYSecurities-Live",
    value: "ACY Securities",
  },
  {
    label: "Admiral Markets",
    server1: "Aglobe-Demo",
    server2: "Aglobe-Live",
    server3: "AdmiralMarkets-Live",
    server4: "AdmiralMarkets-Demo",
    value: "Admiral Markets",
  },
  {
    label: "Advanced Markets",
    server1: "AdvancedMarketsUK-Demo",
    server2: "AdvancedMarketsUK-Live",
    server3: "AdvancedMarkets-Demo",
    value: "Advanced Markets",
  },
  {
    label: "Aeron",
    server1: "Aerontech-Server",
    server2: "AeronLimited-Server",
    value: "Aeron",
  },
  {
    label: "AeronFX",
    server1: "No servers",
    value: "AeronFX",
  },
  {
    label: "Aether Capital",
    server1: "AetherCapital-LIVE",
    value: "Aether Capital",
  },
  {
    label: "AETOS Capital Group (UK)",
    server1: "AETOSCapitalUK-Demo MT5",
    server2: "AETOSCapitalUK-Live MT5",
    value: "AETOS Capital Group (UK)",
  },
  {
    label: "AIFC Technology",
    server1: "AIFCTechnology-Server",
    value: "AIFC Technology",
  },
  {
    label: "Air JFx",
    server1: "AirJFX-Pro-1",
    value: "Air JFx",
  },
  {
    label: "AKFX",
    server1: "AKFXFinancial-MT5-Demo2",
    server2: "AKFXFinancial-MT5Live-2",
    value: "AKFX",
  },
  {
    label: "Al-Awal Limited",
    server1: "AlAwal-Live",
    server2: "AlAwal-Demo",
    value: "Al-Awal Limited",
  },
  {
    label: "Alain Fx",
    server1: "AlainsMarket-Server",
    value: "Alain Fx",
  },
  {
    label: "Alchemy Markets",
    server1: "AlchemyPrime-Server",
    value: "Alchemy Markets",
  },
  {
    label: "AlfaForex",
    server1: "AlfaForex-Server",
    server2: "AlfaForex-Real",
    server3: "AlfaForexRU-Real",
    value: "AlfaForex",
  },
  {
    label: "Almeigra LTD",
    server1: "AlmeigraLtd-Server",
    value: "Almeigra LTD",
  },
  {
    label: "Alpari",
    server1: "Alpari-MT5-Demo",
    server2: "Alpari-MT5",
    server3: "AlpariEvrasia-MT5",
    server4: "AlpariEvrasia-MT5-Demo",
    server5: "AlpariFS-MT5",
    server6: "AlpariUK-MT5",
    server7: "Alpari-Demo01",
    server8: "Alpari-Real01",
    server9: "Alpari-Demo03",
    server10: "AlpariEvrasia-Real01",
    value: "Alpari",
  },
  {
    label: "Alpha Bull Markets",
    server1: "AlphaBullMarkets-Live",
    value: "Alpha Bull Markets",
  },
  {
    label: "Alpha Capital Group",
    server1: "ACGMarkets-Demo",
    server2: "ACGMarkets-Live",
    value: "Alpha Capital Group",
  },
  {
    label: "Alpha Capital Markets plc",
    server1: "No servers",
    value: "Alpha Capital Markets plc",
  },
  {
    label: "AlphaBeta FX",
    server1: "AlphaBetaFX-Live",
    value: "AlphaBeta FX",
  },
  {
    label: "Alphafx Markets",
    server1: "AlphaFXMarkets-Live",
    server2: "AlphaFXMarkets-Demo",
    value: "Alphafx Markets",
  },
  {
    label: "AM Broker",
    server1: "AMBROKER-Live",
    server2: "AMBROKER-Demo",
    value: "AM Broker",
  },
  {
    label: "Amana Capital",
    server1: "AmanaCapital-Live",
    server2: "AmanaCapital-Demo",
    server3: "AmanaTrader-Real",
    value: "Amana Capital",
  },
  {
    label: "AMarkets",
    server1: "AMarkets-Demo",
    server2: "AMarkets-Real",
    value: "AMarkets",
  },
  {
    label: "AMEGA",
    server1: "Amega-Forex",
    value: "AMEGA",
  },
  {
    label: "AMP Global (Europe)",
    server1: "AMPGlobalEU-Demo",
    server2: "AMPGlobalEU-Live",
    value: "AMP Global (Europe)",
  },
  {
    label: "AMP Global Clearing",
    server1: "AMPGlobalClearing-Demo-CQG",
    server2: "AMPGlobalClearing-Live-CQG",
    server3: "AMPGlobalUSA-Demo",
    server4: "AMPGlobalUSA-Live",
    value: "AMP Global Clearing",
  },
  {
    label: "Antos Pinnacles",
    server1: "AntosPinnacles-Server",
    value: "Antos Pinnacles",
  },
  {
    label: "Apex Capital Markets",
    server1: "ApexCapitalMarkets-ECN",
    value: "Apex Capital Markets",
  },
  {
    label: "Apollo Markets",
    server1: "ApolloMarkets-MetaTrader5",
    value: "Apollo Markets",
  },
  {
    label: "April Investors",
    server1: "AprilInvestors-Server",
    value: "April Investors",
  },
  {
    label: "Ardekka Finance Inc",
    server1: "ArdeKKa-ECN",
    value: "Ardekka Finance Inc",
  },
  {
    label: "Ardu Prime",
    server1: "ArduPrime-Live",
    server2: "ArduPrime-Demo",
    value: "Ardu Prime",
  },
  {
    label: "Aron Groups",
    server1: "AronGroups-Server",
    value: "Aron Groups",
  },
  {
    label: "ARUM TRADE",
    server1: "ArumTradeLimited-Server",
    server2: "ArumTradeLimited-Demo",
    value: "ARUM TRADE",
  },
  {
    label: "ARUMPRO",
    server1: "ARUMPROCapital-Server",
    server2: "ARUMPROCapital-Demo",
    value: "ARUMPRO",
  },
  {
    label: "Ascot Prime",
    server1: "AscotPrime-Live",
    value: "Ascot Prime",
  },
  {
    label: "Asia Future Trading",
    server1: "AsiaFutureTrading-Server",
    value: "Asia Future Trading",
  },
  {
    label: "ASJ Forex Global",
    server1: "ASJForexGlobalLimited-Live",
    server2: "ASJForexGlobalLimited-Live2",
    value: "ASJ Forex Global",
  },
  {
    label: "AssetsFx",
    server1: "AssetsfxGlobal-Server",
    value: "AssetsFx",
  },
  {
    label: "ATA Markets",
    server1: "ATAMarkets-Demo",
    server2: "ATAMarkets-Live",
    value: "ATA Markets",
  },
  {
    label: "Atallia",
    server1: "AtalliaFX-Live",
    server2: "AtalliaFX-Demo",
    value: "Atallia",
  },
  {
    label: "ATG Markets",
    server1: "ATGWorldTrading-Demo",
    server2: "ATGWorldTrading-LIVE",
    value: "ATG Markets",
  },
  {
    label: "Athens",
    server1: "AthensMarkets-Pro-1",
    value: "Athens",
  },
  {
    label: "Audacity Capital",
    server1: "AudaCityGlobal-Live",
    value: "Audacity Capital",
  },
  {
    label: "Aurea Capital",
    server1: "AureaCapital-Demo",
    server2: "AureaCapital-Live",
    value: "Aurea Capital",
  },
  {
    label: "AutoFX",
    server1: "AutoFX-MT5",
    value: "AutoFX",
  },
  {
    label: "AvaTrade",
    server1: "Ava-Demo 1-MT5",
    server2: "Ava-Real 1-MT5",
    value: "AvaTrade",
  },
  {
    label: "Axen Capital",
    server1: "AxenCapital-MetaTrader5",
    value: "Axen Capital",
  },
  {
    label: "Axincapital",
    server1: "AxinCapital-MetaTrader5",
    value: "Axincapital",
  },
  {
    label: "Axiory",
    server1: "Axiory-Demo",
    server2: "Axiory-Live",
    value: "Axiory",
  },
  {
    label: "Baazex",
    server1: "Baazex-Demo",
    server2: "Baazex-Live",
    value: "Baazex",
  },
  {
    label: "BABILFX",
    server1: "BabilFinancial-Live",
    server2: "BabilFinancial-Demo",
    value: "BABILFX",
  },
  {
    label: "Bank VTB24",
    server1: "No servers",
    value: "Bank VTB24",
  },
  {
    label: "Barclay Stone",
    server1: "BarclayStone-Production",
    server2: "BarclayStone-Live",
    value: "Barclay Stone",
  },
  {
    label: "Basel Markets",
    server1: "BaselMarkets-Demo",
    server2: "BaselMarkets-LIVE",
    value: "Basel Markets",
  },
  {
    label: "Baxia Markets",
    server1: "BaxiaMarkets-UK01",
    value: "Baxia Markets",
  },
  {
    label: "BCS",
    server1: "BCS-Demo",
    server2: "BCS-MT",
    server3: "BCSForex-MT5RUSP",
    value: "BCS",
  },
  {
    label: "BCS Markets",
    server1: "BCS5-Real",
    server2: "BCS-MetaTrader5",
    value: "BCS Markets",
  },
  {
    label: "BDFX",
    server1: "BlueDragon-Demo",
    server2: "BlueDragon-Live",
    value: "BDFX",
  },
  {
    label: "BDSwiss",
    server1: "BDSwissGlobal-Server01",
    value: "BDSwiss",
  },
  {
    label: "BeingFull",
    server1: "BeingfullLimited-Live",
    value: "BeingFull",
  },
  {
    label: "BelleoFX",
    server1: "BelleoMarkets-Server",
    value: "BelleoFX",
  },
  {
    label: "BenchMark Finance",
    server1: "BenchMark-Server",
    value: "BenchMark Finance",
  },
  {
    label: "Berry Markets",
    server1: "No servers",
    value: "Berry Markets",
  },
  {
    label: "Best Bonus FX",
    server1: "BestBonus-Server",
    value: "Best Bonus FX",
  },
  {
    label: "BestoFeX",
    server1: "BestoFex-Demo",
    server2: "BestoFex-Live",
    value: "BestoFeX",
  },
  {
    label: "BidX Markets",
    server1: "itexsys -Platform",
    value: "BidX Markets",
  },
  {
    label: "Bigboss",
    server1: "BIGSolutions-LIVE1",
    value: "Bigboss",
  },
  {
    label: "Binary.com",
    server1: "Deriv-Server",
    server2: "Deriv-Demo",
    value: "Binary.com",
  },
  {
    label: "BitForex",
    server1: "BitForex-MetaTrader5",
    value: "BitForex",
  },
  {
    label: "Bitterz",
    server1: "Bitterz- Live",
    value: "Bitterz",
  },
  {
    label: "BizTradePro",
    server1: "BizTradePro-Server",
    value: "BizTradePro",
  },
  {
    label: "BlackBull Markets",
    server1: "BlackBullMarkets-Demo",
    server2: "BlackBullMarkets-Live",
    value: "BlackBull Markets",
  },
  {
    label: "BlackGateBroker LLC",
    server1: "BlackGateBroker-Live",
    server2: "BlackGateBroker-Demo",
    value: "BlackGateBroker LLC",
  },
  {
    label: "Blackwell Global",
    server1: "BlackwellGlobal-MT5-Server",
    server2: "BlackwellGlobalBS-Live",
    server3: "BlackwellGlobalBS-Demo",
    value: "Blackwell Global",
  },
  {
    label: "Blink Trades",
    server1: "BlinkTrade-Server",
    value: "Blink Trades",
  },
  {
    label: "Blueberry Markets",
    server1: "BlueberryMarkets-Demo",
    server2: "BlueberryMarkets-Live",
    server3: "BerryMarkets-Live",
    server4: "BerryMarkets-Demo",
    value: "Blueberry Markets",
  },
  {
    label: "Bold Prime",
    server1: "BoldPrime-MT5",
    value: "Bold Prime",
  },
  {
    label: "Bridge Markets",
    server1: "BridgeMarkets-Live",
    value: "Bridge Markets",
  },
  {
    label: "Bull Sphere",
    server1: "BullSphereInt-Online",
    value: "Bull Sphere",
  },
  {
    label: "Bullet Global Investment",
    server1: "BulletGlobalInvest-Live",
    value: "Bullet Global Investment",
  },
  {
    label: "BVCFX",
    server1: "BillionVenture-Demo",
    server2: "BillionVenture-Live",
    value: "BVCFX",
  },
  {
    label: "Cabana Capital",
    server1: "CabanaCapitals-Demo",
    server2: "CabanaCapitals-Live",
    value: "Cabana Capital",
  },
  {
    label: "Capex",
    server1: "KeyWayGroup-Live",
    server2: "KeyWayGroup-Demo",
    value: "Capex",
  },
  {
    label: "Capital Futures",
    server1: "CapitalFutures-Live",
    value: "Capital Futures",
  },
  {
    label: "Capital Purse",
    server1: "CapitalPurse-Demo",
    server2: "CapitalPurse-Live",
    value: "Capital Purse",
  },
  {
    label: "CapitalCore",
    server1: "Capitalcore-Server",
    value: "CapitalCore",
  },
  {
    label: "CapitalXtend",
    server1: "CapitalXtend-MetaTrader5",
    value: "CapitalXtend",
  },
  {
    label: "Carbon Capital",
    server1: "CarbonCapital-Pro-1",
    server2: "CarbonProFX-Pro-1",
    value: "Carbon Capital",
  },
  {
    label: "Castle Rock FX",
    server1: "CastleRockFX-Demo",
    server2: "CastleRockFX-Live",
    value: "Castle Rock FX",
  },
  {
    label: "Caveo",
    server1: "Caveo-Multi Assets",
    value: "Caveo",
  },
  {
    label: "CB Technologies",
    server1: "CBT-Limited",
    value: "CB Technologies",
  },
  {
    label: "CCFX",
    server1: "CreativeCapital-Server",
    server2: "CreativeCapitalFX-Live",
    server3: "CreativeCapitalFX-Demo",
    value: "CCFX",
  },
  {
    label: "CDG Global",
    server1: "CDGGlobal-Server",
    value: "CDG Global",
  },
  {
    label: "Centrum Markets",
    server1: "CentrumMarkets-Server",
    value: "Centrum Markets",
  },
  {
    label: "CenturyInvestment",
    server1: "CenturyInvestmentFutures-MT5-Live",
    value: "CenturyInvestment",
  },
  {
    label: "CFHClearing",
    server1: "CFHClearing-Demo3",
    server2: "CFHClearing-Live7",
    value: "CFHClearing",
  },
  {
    label: "CFI Financial Group",
    server1: "CFITrader-Demo",
    server2: "CreditFinancierInvest-Real",
    server3: "CFITrader-Real",
    value: "CFI Financial Group",
  },
  {
    label: "Chancellor Precious Metals",
    server1: "ChancellorPreciousMetals-Demo",
    server2: "ChancellorPreciousMetals-Live",
    server3: "ChancellorPreciousMetals-Server",
    value: "Chancellor Precious Metals",
  },
  {
    label: "Chancellor Precious Metals",
    server1: "ChancellorPreciousMetals-Demo",
    server2: "ChancellorPreciousMetals-Live",
    server3: "ChancellorPreciousMetals-Server",
    value: "Chancellor Precious Metals",
  },
  {
    label: "Clear",
    server1: "Clear-PRD",
    server2: "Clear-Demo",
    value: "Clear",
  },
  {
    label: "CLEAR CCTVM S.A.",
    server1: "CLEAR-PRD",
    server2: "ClearInvestimentos-Demo",
    server3: "ClearInvestimentos-PRD",
    value: "CLEAR CCTVM S.A.",
  },
  {
    label: "Climax Prime",
    server1: "ClimaxPrimeLLC-Demo",
    server2: "ClimaxPrimeLLC-Live",
    server3: "ClimaxPrime-Server",
    value: "Climax Prime",
  },
  {
    label: "CloverMarkets",
    server1: "CloverMarket-Online",
    value: "CloverMarkets",
  },
  {
    label: "CLSA Premium",
    server1: "CLSAPremium-Production",
    server2: "CLSAPremium-Demo",
    value: "CLSA Premium",
  },
  {
    label: "CMS Prime",
    server1: "CapitalMarketSolutions-Demo",
    server2: "CapitalMarketSolutions-Server",
    value: "CMS Prime",
  },
  {
    label: "Coinexx",
    server1: "Coinexx-Live",
    server2: "Coinexx-Demo",
    value: "Coinexx",
  },
  {
    label: "Commerce Wealth",
    server1: "TradingAccount-Server",
    value: "Commerce Wealth",
  },
  {
    label: "Conotoxia",
    server1: "Conotoxia-Live",
    value: "Conotoxia",
  },
  {
    label: "Constant Capital",
    server1: "Constant-Server",
    value: "Constant Capital",
  },
  {
    label: "CRIB MARKETS",
    server1: "CribMarkets-Server",
    value: "CRIB MARKETS",
  },
  {
    label: "Crypto Margen",
    server1: "CryptoMargen-Server",
    value: "Crypto Margen",
  },
  {
    label: "CryptoAltum",
    server1: "Cryptoaltum-Live",
    server2: "Cryptoaltum-Demo",
    value: "CryptoAltum",
  },
  {
    label: "CRYPTOGRAPH LIMITED",
    server1: "Cryptograph-Live",
    value: "CRYPTOGRAPH LIMITED",
  },
  {
    label: "CryptoGT",
    server1: "HatioLtd-Demo",
    server2: "HatioLtd-Live",
    value: "CryptoGT",
  },
  {
    label: "CWG MARKETS",
    server1: "CWGMarketsUK-Live",
    server2: "CWGMarketsUK-Demo",
    value: "CWG MARKETS",
  },
  {
    label: "DAFEX",
    server1: "DafexGlobal-Live",
    server2: "DafexGlobal-Demo",
    value: "DAFEX",
  },
  {
    label: "Daman Markets",
    server1: "AlDammanSecurities-Demo",
    server2: "AlDammanSecurities-Live",
    value: "Daman Markets",
  },
  {
    label: "DARICO",
    server1: "Darico-MetaTrader5",
    value: "DARICO",
  },
  {
    label: "Darwinex",
    server1: "Darwinex-Live",
    server2: "Darwinex-Demo",
    value: "Darwinex",
  },
  {
    label: "db Broker",
    server1: "DBProspectivus-Live",
    value: "db Broker",
  },
  {
    label: "DCFX",
    server1: "DCFXAsia-Real",
    value: "DCFX",
  },
  {
    label: "DDT",
    server1: "DigitalDreamTech-Live",
    value: "DDT",
  },
  {
    label: "Delapan",
    server1: "DelapanEmas-MT5-Live",
    value: "Delapan",
  },
  {
    label: "Deriv",
    server1: "Deriv-Server",
    server2: "Deriv-Demo",
    server3: "Deriv-Server-02",
    value: "Deriv",
  },
  {
    label: "DEUSFX",
    server1: "DeusTechnology-Server",
    server2: "DeusTechnology-Demo",
    server3: "DeusTechnology-Live",
    value: "DEUSFX",
  },
  {
    label: "DIDIMAX",
    server1: "PTDidiMaxBerjangka-Demo",
    server2: "PTDidiMaxBerjangka-Live",
    value: "DIDIMAX",
  },
  {
    label: "Digibits",
    server1: "DigibitsNetwork-Server",
    server2: "DigibitsNetwork-Live",
    value: "Digibits",
  },
  {
    label: "diversify",
    server1: "Diversify-Demo",
    server2: "Diversify-Live",
    value: "diversify",
  },
  {
    label: "DivestiX",
    server1: "DivestiX-MetaTrader5",
    value: "DivestiX",
  },
  {
    label: "Dizicx",
    server1: "Dizicx-Demo",
    server2: "Dizicx-Live",
    server3: "DizicxLimited-Demo",
    server4: "DizicxLimited-Live",
    value: "Dizicx",
  },
  {
    label: "DK Trade",
    server1: "DKTrade-MT5Live-3",
    value: "DK Trade",
  },
  {
    label: "DNA Markets",
    server1: "DNAMarkets-Real",
    server2: "DNAMarkets-Demo",
    value: "DNA Markets",
  },
  {
    label: "Dollars Markets",
    server1: "DollarsMarkets-Live",
    server2: "DollarsMarkets-Demo",
    value: "Dollars Markets",
  },
  {
    label: "Dominion Markets",
    server1: "DominionMarkets-MetaTrader5",
    server2: "DominionMarketsLLC-MT5",
    value: "Dominion Markets",
  },
  {
    label: "DooPrime",
    server1: "DooForex-Demo",
    server2: "DooForex-Live",
    value: "DooPrime",
  },
  {
    label: "Dotway Capitals",
    server1: "DotwayCapitals-Live",
    value: "Dotway Capitals",
  },
  {
    label: "DUO Markets",
    server1: "DuoMarkets-MT5Demo",
    server2: "DuoMarkets-MT5Live",
    value: "DUO Markets",
  },
  {
    label: "E8 Funding",
    server1: "E8Funding-Demo",
    value: "E8 Funding",
  },
  {
    label: "easyMarkets",
    server1: "easyMarkets-Live",
    value: "easyMarkets",
  },
  {
    label: "EBH",
    server1: "EUBrokerageHouse-MetaTrader 5 Pro",
    value: "EBH",
  },
  {
    label: "ECR",
    server1: "ECRCapitals-Demo",
    server2: "ECRCapitals-Live",
    value: "ECR",
  },
  {
    label: "Eightcap",
    server1: "Eightcap-Live",
    server2: "Eightcap-Demo",
    server3: "EightcapEU-Live",
    value: "Eightcap",
  },
  {
    label: "Elite Trade Fx",
    server1: "Elitetradefx-Demo",
    server2: "Elitetradefx-Live",
    value: "Elite Trade Fx",
  },
  {
    label: "EliteMarkets",
    server1: "EliteMarket-Demo",
    server2: "EliteMarket-Live",
    value: "EliteMarkets",
  },
  {
    label: "ElvMarkets",
    server1: "Elvmarkets-CriptoBursatil",
    value: "ElvMarkets",
  },
  {
    label: "emc2Trading",
    server1: "XPTM5-PRD",
    value: "emc2Trading",
  },
  {
    label: "EMD Broker",
    server1: "EMDFinancial-Server",
    value: "EMD Broker",
  },
  {
    label: "Empire Trading",
    server1: "EmpireTrading-Live",
    value: "Empire Trading",
  },
  {
    label: "Empower Markets",
    server1: "EmpowerMarkets-Live",
    server2: "EmpowerMarkets-Demo",
    value: "Empower Markets",
  },
  {
    label: "Envi FX",
    server1: "EnviLLC-Demo",
    server2: "EnviLLC-Live",
    value: "Envi FX",
  },
  {
    label: "Equiti Group",
    server1: "EquitiGroupLtd-Demo",
    server2: "EquitiGroupLtd-Live",
    server3: "EquitiBrokerageSC-Live",
    server4: "EquitiBrokerageSC-Demo",
    value: "Equiti Group",
  },
  {
    label: "Equitrade",
    server1: "EquiTrade-Demo",
    server2: "EquiTrade-Live",
    value: "Equitrade",
  },
  {
    label: "Equity Advisers",
    server1: "EAEquityAdvisers-Server",
    value: "Equity Advisers",
  },
  {
    label: "Errante",
    server1: "Errante-Demo",
    server2: "Errante-Live",
    server3: "ErranteSC-Demo",
    server4: "ErranteSC-Live",
    server5: "ErranteTrading-Live",
    server6: "ErranteTrading-Demo",
    value: "Errante",
  },
  {
    label: "Errante EU",
    server1: "Errante-Demo",
    server2: "Errante-Live",
    value: "Errante EU",
  },
  {
    label: "Esperio",
    server1: "OFGCap-Sharp ECN",
    value: "Esperio",
  },
  {
    label: "ETERWEALTH",
    server1: "Eterwealth-Online",
    value: "ETERWEALTH",
  },
  {
    label: "Euro Street",
    server1: "EUROSTREETCapital-Server",
    value: "Euro Street",
  },
  {
    label: "EuroTrader",
    server1: "EuroTraderGlobal-Server-1",
    server2: "EurotradeSA-Server-1",
    value: "EuroTrader",
  },
  {
    label: "Even Capitals",
    server1: "EvenCapitals-Live",
    server2: "EvenCapitals-Demo",
    value: "Even Capitals",
  },
  {
    label: "EverestCM",
    server1: "EverestCM-Demo",
    server2: "EverestCM-Live",
    value: "EverestCM",
  },
  {
    label: "EverForex",
    server1: "EverforexFinancial-Trade Server",
    server2: "EverforexFinancial-Live",
    value: "EverForex",
  },
  {
    label: "Evolve Markets",
    server1: "EvolveMarkets-MT5 Demo Server",
    server2: "EvolveMarkets-MT5 Live Server",
    value: "Evolve Markets",
  },
  {
    label: "Exa Markets LLC",
    server1: "EXAMarkets-Live",
    server2: "EXAMarkets-Demo",
    value: "Exa Markets LLC",
  },
  {
    label: "Exaprofit",
    server1: "EXAProfit-Real",
    value: "Exaprofit",
  },
  {
    label: "Exclusive Capital",
    server1: "ExclusiveCapital-Live",
    server2: "ExclusiveCapital-Demo",
    value: "Exclusive Capital",
  },
  {
    label: "Exclusive Markets",
    server1: "ExclusiveMarkets-Live",
    server2: "ExclusiveMarkets-Demo",
    value: "Exclusive Markets",
  },
  {
    label: "EXNESS",
    server1: "Exness-MT5Real",
    server2: "Exness-MT5Trial",
    server3: "Exness-MT5Real4",
    server4: "Exness-MT5Trial2",
    server5: "Exness-MT5Real3",
    server6: "Exness-MT5Trial3",
    server7: "Exness-MT5Trial4",
    server8: "Exness-MT5Real2",
    server9: "Exness-MT5Trial8",
    server10: "Exness-MT5Real6",
    server11: "Exness-MT5Trial6",
    server12: "Exness-MT5Real8",
    server13: "Exness-MT5Real9",
    server14: "Exness-MT5Real7",
    server15: "Exness-MT5Real12",
    server16: "Exness-MT5Real11",
    server17: "Exness-MT5Real15",
    server18: "Exness-MT5Real5",
    server19: "Exness-MT5Real10",
    server20: "Exness-MT5Trial9",
    server21: "Exness-MT5Trial5",
    server22: "Exness-MT5Trial7",
    server23: "Exness-MT5Trial11",
    server24: "Exness-MT5Trial10",
    server25: "Exness-MT5Trial12",
    value: "EXNESS",
  },
  {
    label: "Extrasum",
    server1: "Extrasum-Demo",
    server2: "Extrasum-Live",
    value: "Extrasum",
  },
  {
    label: "Fair Forex",
    server1: "FairForex- Live",
    server2: "FairForex- Demo",
    value: "Fair Forex",
  },
  {
    label: "Fair Markets",
    server1: "FairMarketsInt-Demo",
    server2: "FairMarketsInt-MT5Live-4",
    value: "Fair Markets",
  },
  {
    label: "Fast Fx",
    server1: "FastFX-Server",
    value: "Fast Fx",
  },
  {
    label: "FBS",
    server1: "FBS-Real",
    server2: "FBS-Demo",
    value: "FBS",
  },
  {
    label: "FCI Markets",
    server1: "ForeignCurrency-Live7",
    server2: "ForeignCurrency-Demo3",
    server3: "FCIMarkets-Live",
    server4: "FCIGroup-Live",
    value: "FCI Markets",
  },
  {
    label: "FCS BUSINESS SOLUTIONS",
    server1: "FCSBusinessSolutions-Server",
    value: "FCS BUSINESS SOLUTIONS",
  },
  {
    label: "Fibido",
    server1: "FibidoTechnologies-Live",
    value: "Fibido",
  },
  {
    label: "FIBO Group",
    server1: "FIBOGroup-MT5 Server",
    value: "FIBO Group",
  },
  {
    label: "Fidelis Capital Markets",
    server1: "Fidelis Capital Markets-Live",
    server2: "Fidelis Capital Markets-Demo",
    value: "Fidelis Capital Markets",
  },
  {
    label: "Finalto",
    server1: "FinaltoAustralia-Live7",
    server2: "FinaltoAustralia-Demo3",
    server3: "FinaltoUK-Live7",
    server4: "FinaltoUK-Demo3",
    server5: "FinaltoEurope-Live7",
    server6: "FinaltoEurope-Demo3",
    server7: "Markets.com-Demo",
    server8: "Markets.com-Live",
    value: "Finalto",
  },
  {
    label: "FinmaxFX",
    server1: "MaxCapital-Live",
    server2: "MaxCapital-Demo",
    value: "FinmaxFX",
  },
  {
    label: "Finotive Markets",
    server1: "FinotiveMarkets-Live",
    value: "Finotive Markets",
  },
  {
    label: "Finpro Trading",
    server1: "FPT-Demo",
    server2: "FPT-Live",
    value: "Finpro Trading",
  },
  {
    label: "FinPros",
    server1: "FinquotesFinancial-Real",
    value: "FinPros",
  },
  {
    label: "Fintech Global",
    server1: "OnlineFintech-Demo",
    server2: "OnlineFintech-Live",
    value: "Fintech Global",
  },
  {
    label: "Finveo",
    server1: "FinveoMN-Demo",
    server2: "FinveoMN-Live",
    value: "Finveo",
  },
  {
    label: "Fixi",
    server1: "FixiMarkets-Live",
    value: "Fixi",
  },
  {
    label: "FMS Markets",
    server1: "StellarHoldings-Live",
    value: "FMS Markets",
  },
  {
    label: "Focus Markets",
    server1: "FocusMarkets-Real",
    server2: "FocusMarkets-Demo",
    value: "Focus Markets",
  },
  {
    label: "Forex Bank",
    server1: "SmartTradeCo-MetaTrader5",
    value: "Forex Bank",
  },
  {
    label: "Forex Chief",
    server1: "ForexChief-MT5",
    value: "Forex Chief",
  },
  {
    label: "Forex Club",
    server1: "ForexClub-MT5 Demo Server",
    server2: "ForexClub-MT5 Real Server",
    value: "Forex Club",
  },
  {
    label: "Forex.com",
    server1: "FOREX.comGlobal-Demo 531",
    server2: "FOREX.comGlobal-Live 532",
    server3: "Forex.comGlobalCN-Live534",
    value: "Forex.com",
  },
  {
    label: "Forex4you",
    server1: "Forex4you-Classic",
    server2: "Forex4you-Demo",
    value: "Forex4you",
  },
  {
    label: "ForexG",
    server1: "ForexgPTY-Demo",
    server2: "ForexgPTY-Live",
    value: "ForexG",
  },
  {
    label: "ForexTime",
    server1: "ForexTimeFXTM-MT5",
    server2: "ForexTime-MT5",
    server3: "ForexTime-Demo01",
    server4: "ForexTime-Demo02",
    server5: "ForexTimeFXTM-Live02",
    server6: "ForexTimeFXTM-Live01",
    value: "ForexTime",
  },
  {
    label: "Forman Forex",
    server1: "FormanFinacialPty-Demo",
    server2: "FormanFinacialPty-Live",
    value: "Forman Forex",
  },
  {
    label: "Fort Financial Services",
    server1: "FortFS-Live",
    value: "Fort Financial Services",
  },
  {
    label: "FP Markets",
    server1: "FPMarkets-Demo",
    server2: "FPMarkets-Live",
    value: "FP Markets",
  },
  {
    label: "Freedom Capital FX",
    server1: "FreedomCapital-Server",
    value: "Freedom Capital FX",
  },
  {
    label: "FreshForex",
    server1: "FreshForex-MT5",
    value: "FreshForex",
  },
  {
    label: "FRXE",
    server1: "FRXETrading-Server",
    server2: "FRXETrading-MT5",
    value: "FRXE",
  },
  {
    label: "FTMO",
    server1: "FTMO-Live",
    server2: "FTMO-Server",
    server3: "FTMO-Demo",
    value: "FTMO",
  },
  {
    label: "Fundiza",
    server1: "Trading Account Limited",
    value: "Fundiza",
  },
  {
    label: "FusionMarkets",
    server1: "FusionMarkets-Live",
    server2: "FusionMarkets-Demo",
    value: "FusionMarkets",
  },
  {
    label: "Future FX",
    server1: "FutureFX-Server",
    server2: "FutureFX-Demo",
    value: "Future FX",
  },
  {
    label: "Future Managment Systems",
    server1: "FutureManagement-Server",
    server2: "FutureManagement-Systems",
    value: "Future Managment Systems",
  },
  {
    label: "FX Choice",
    server1: "FXChoice-MetaTrader 5 Pro",
    value: "FX Choice",
  },
  {
    label: "FxBay",
    server1: "FxBay-Demo",
    server2: "FxBay-Live",
    value: "FxBay",
  },
  {
    label: "FXCE",
    server1: "FXCELLC-Demo",
    server2: "FXCELLC-Live",
    value: "FXCE",
  },
  {
    label: "FxCitizen",
    server1: "UniverseCitizen-Live",
    server2: "UniverseCitizen-Demo",
    value: "FxCitizen",
  },
  {
    label: "FXDD",
    server1: "FXDD-Live",
    server2: "FXDDMauritius-Live",
    server3: "FXDD-Demo",
    value: "FXDD",
  },
  {
    label: "FXFlat",
    server1: "FXFlatMT5-LiveServer",
    server2: "FXFlatMT5-DemoServer",
    value: "FXFlat",
  },
  {
    label: "FXGlobe",
    server1: "FXGlobeInternational-Real",
    value: "FXGlobe",
  },
  {
    label: "FxGrow",
    server1: "FxGrow- Live",
    server2: "FxGrow- Demo",
    value: "FxGrow",
  },
  {
    label: "FXGT",
    server1: "360Degrees-Live",
    server2: "360Degrees-Demo",
    server3: "FXGT-Live",
    server4: "FXGT-Demo",
    value: "FXGT",
  },
  {
    label: "FXGT",
    server1: "360Degrees-Live",
    server2: "360Degrees-Demo",
    server3: "FXGT-Live",
    server4: "FXGT-Demo",
    value: "FXGT",
  },
  {
    label: "FxLiveCapital",
    server1: "FxLiveCapital-Server",
    value: "FxLiveCapital",
  },
  {
    label: "FXNextGen-MetaTrader5",
    server1: "FXNextGen-MetaTrader5",
    value: "FXNextGen-MetaTrader5",
  },
  {
    label: "FXOpen",
    server1: "FXOpen-MT5",
    value: "FXOpen",
  },
  {
    label: "FXOpulence",
    server1: "FXOpulenceLimited-Live",
    value: "FXOpulence",
  },
  {
    label: "FXPRIMUS",
    server1: "PrimusMarkets-Live1",
    value: "FXPRIMUS",
  },
  {
    label: "FxPro",
    server1: "FxPro-MT5",
    server2: "ProTradingFX-Server",
    value: "FxPro",
  },
  {
    label: "Fxsign",
    server1: "FxSignLLC-Server",
    value: "Fxsign",
  },
  {
    label: "FXStock",
    server1: "FXStockBroker-Server",
    value: "FXStock",
  },
  {
    label: "FXTM",
    server1: "ForexTimeFXTM-Demo01",
    server2: "ForexTimeFXTM-Live01",
    server3: "ForexTimeFXTM-Demo02",
    server4: "ForexTimeFXTM-Live02",
    value: "FXTM",
  },
  {
    label: "FXTRADING.com",
    server1: "FXTRADING.COM-MT5",
    value: "FXTRADING.com",
  },
  {
    label: "Fxview",
    server1: "FXView-Demo",
    server2: "FXView-Live",
    value: "Fxview",
  },
  {
    label: "FxWinning",
    server1: "FXWinning-Live",
    value: "FxWinning",
  },
  {
    label: "Gaitame Finest",
    server1: "GaitameFinest-Demo",
    server2: "GaitameFinest-Live",
    value: "Gaitame Finest",
  },
  {
    label: "Garnet",
    server1: "Garnet-Server",
    value: "Garnet",
  },
  {
    label: "GBE brokers",
    server1: "GBEbrokers-LIVE",
    server2: "GBEbrokers-Demo",
    value: "GBE brokers",
  },
  {
    label: "GCL Markets",
    server1: "GCLMarkets-Server",
    value: "GCL Markets",
  },
  {
    label: "GCXPrime",
    server1: "GCXPrime-Live",
    server2: "GCXPrime-Server",
    value: "GCXPrime",
  },
  {
    label: "Geldex Invest",
    server1: "GeldexInvest-Demo",
    server2: "GeldexInvest-Live",
    server3: "GeldexInvest-Live 2",
    value: "Geldex Invest",
  },
  {
    label: "GemForex",
    server1: "GemTradeCo-Live",
    value: "GemForex",
  },
  {
    label: "Genial Investment",
    server1: "Genialinvestimentos-PRD",
    value: "Genial Investment",
  },
  {
    label: "Geo Markets",
    server1: "AetramTradeMerchants-Live",
    server2: "AetramTradeMerchants-Demo",
    value: "Geo Markets",
  },
  {
    label: "Gerchik & CO",
    server1: "GerchikCo-MT5",
    value: "Gerchik & CO",
  },
  {
    label: "GFX Securities",
    server1: "GFXCompanyWLL-GFXSECURITIES",
    value: "GFX Securities",
  },
  {
    label: "GIB Capital",
    server1: "GibCapitalGroup-Live",
    value: "GIB Capital",
  },
  {
    label: "GIBXChange",
    server1: "GIBXChange-Live",
    value: "GIBXChange",
  },
  {
    label: "GICTrade",
    server1: "GlobalInvestaCapital-Live",
    server2: "GlobalInvestaCapital-Demo",
    value: "GICTrade",
  },
  {
    label: "GIFX PRIME",
    server1: "Gifx prime investment",
    value: "GIFX PRIME",
  },
  {
    label: "GivTrade",
    server1: "GivTrade-server",
    value: "GivTrade",
  },
  {
    label: "GKFX",
    server1: "TriveFinancial-MT5-Demo2",
    server2: "TriveFinancial-MT5Live-2",
    value: "GKFX",
  },
  {
    label: "GKFXPrime",
    server1: "GKFXPrime-MT5Live-1",
    value: "GKFXPrime",
  },
  {
    label: "GkInvest",
    server1: "PTGlobalKapitallnv-MT5- Live",
    value: "GkInvest",
  },
  {
    label: "Global Forex Service",
    server1: "GlobalFxService-Server",
    value: "Global Forex Service",
  },
  {
    label: "Global immense",
    server1: "GlobalImmense-Server",
    value: "Global immense",
  },
  {
    label: "Global Next Trade",
    server1: "GNTCapital-Demo",
    server2: "GNTCapital-Live",
    value: "Global Next Trade",
  },
  {
    label: "GMS INTERNATIONAL PTY LTD",
    server1: "GMSSoftwareGroup-Live",
    server2: "GMSSoftwareGroup-Demo",
    value: "GMS INTERNATIONAL PTY LTD",
  },
  {
    label: "GNT Capital",
    server1: "GNTCapital-Live",
    server2: "GNTCapital-Demo",
    value: "GNT Capital",
  },
  {
    label: "GO Markets",
    server1: "GoMarkets-Demo",
    server2: "GoMarkets-Live",
    server3: "GOMarketsMU-Demo",
    server4: "GOMarketsMU-Live",
    server5: "GOMarketsSVG-Live",
    value: "GO Markets",
  },
  {
    label: "Goat4x",
    server1: "Goat4X-MetaTrader5",
    value: "Goat4x",
  },
  {
    label: "GOFX",
    server1: "ICDX-Demo",
    server2: "ICDX-Live",
    value: "GOFX",
  },
  {
    label: "Gold Tinkle",
    server1: "GenesisBusiness-Live",
    server2: "GenesisBusiness-Demo",
    value: "Gold Tinkle",
  },
  {
    label: "Golden Brokers",
    server1: "GoldenBrokersMY-Live",
    value: "Golden Brokers",
  },
  {
    label: "GoldenTradex",
    server1: "EvestGroup-Server",
    value: "GoldenTradex",
  },
  {
    label: "Goldstone Securities",
    server1: "GSSecurities-Demo",
    server2: "GSSecurities-Live",
    value: "Goldstone Securities",
  },
  {
    label: "GrandCapital",
    server1: "GrandCapital-Demo",
    server2: "GrandCapital-Real",
    value: "GrandCapital",
  },
  {
    label: "GreenCandleFX",
    server1: "GreenCandle-Server",
    value: "GreenCandleFX",
  },
  {
    label: "Growth Next",
    server1: "GrowthNext-Server",
    server2: "GrowthNext-Demo",
    value: "Growth Next",
  },
  {
    label: "GrozavFX",
    server1: "Grozavfx-server",
    value: "GrozavFX",
  },
  {
    label: "GSTrade",
    server1: "GSTrade-Live",
    value: "GSTrade",
  },
  {
    label: "GT247",
    server1: "GT247-LIVE",
    value: "GT247",
  },
  {
    label: "GTC",
    server1: "GTCglobaltrade-server",
    value: "GTC",
  },
  {
    label: "GTCFX",
    server1: "GTCGlobalTrade-Server",
    value: "GTCFX",
  },
  {
    label: "GTCMarkets",
    server1: "GTCMarkets-MT5",
    value: "GTCMarkets",
  },
  {
    label: "GTFMarkets",
    server1: "GTFMarkets-MetaTrader5",
    server2: "GTFMarketsLtd-LIVE",
    server3: "GTFMarketsLtd-Demo",
    value: "GTFMarkets",
  },
  {
    label: "Gujju FX",
    server1: "GujjuFX-Live",
    server2: "GujjuFX-Demo",
    value: "Gujju FX",
  },
  {
    label: "GULF Brokers",
    server1: "GULFBROKERS-Live",
    value: "GULF Brokers",
  },
  {
    label: "Guzemarkets",
    server1: "GuzeMarkets-Online",
    value: "Guzemarkets",
  },
  {
    label: "Halifax Investment Services",
    server1: "HalifaxPlus-Live",
    server2: "HalifaxPlus-Demo",
    value: "Halifax Investment Services",
  },
  {
    label: "Hankotrade",
    server1: "Hankotrade-Live",
    server2: "Hankotrade-Demo",
    value: "Hankotrade",
  },
  {
    label: "Hantec Markets",
    server1: "HantecMarkets-MT5",
    value: "Hantec Markets",
  },
  {
    label: "Hantec Markets Australia",
    server1: "HantecAU-Demo",
    server2: "HantecAU-LIVE",
    value: "Hantec Markets Australia",
  },
  {
    label: "HarborFX",
    server1: "HarborFinancial-Server",
    value: "HarborFX",
  },
  {
    label: "HartFort Broker",
    server1: "HartfortBroker-MetaTrader5",
    value: "HartFort Broker",
  },
  {
    label: "HatioLtd",
    server1: "HatioLtd-Live",
    server2: "HatioLtd-Demo",
    value: "HatioLtd",
  },
  {
    label: "Hedge Total",
    server1: "HTOTAL.RU-MT5",
    value: "Hedge Total",
  },
  {
    label: "Hero FX",
    server1: "HeroFX-Pro-1",
    value: "Hero FX",
  },
  {
    label: "HF Markets",
    server1: "HFMarketsGlobal-Live1",
    server2: "HFMarketsGlobal-Demo",
    server3: "HFMarketsUK-live2",
    value: "HF Markets",
  },
  {
    label: "HF Markets",
    server1: "HFMarketsGlobal-Live1",
    server2: "HFMarketsGlobal-Demo",
    server3: "HFMarketsUK-live2",
    value: "HF Markets",
  },
  {
    label: "HF Markets EU",
    server1: "HFMarketsEurope-Live2",
    server2: "HFMarketsEurope-Demo2",
    value: "HF Markets EU",
  },
  {
    label: "HighSky",
    server1: "HighSky-Server",
    server2: "HighSky-Demo",
    server3: "HighSky-Real 2",
    value: "HighSky",
  },
  {
    label: "Hilton Kapital",
    server1: "HiltonKapital-Live",
    value: "Hilton Kapital",
  },
  {
    label: "HonorFx",
    server1: "HonorFX-Demo",
    server2: "HonorFX-Live",
    value: "HonorFx",
  },
  {
    label: "House of Borse",
    server1: "HouseOfBorse-Multi Assets",
    value: "House of Borse",
  },
  {
    label: "HTFX",
    server1: "HTFXLtd-Demo",
    server2: "HTFXLtd-Server",
    value: "HTFX",
  },
  {
    label: "Hunely Capital",
    server1: "HunelyCapital-Demo",
    server2: "HunelyCapital-Real",
    value: "Hunely Capital",
  },
  {
    label: "HYCM",
    server1: "HYCM-Live",
    value: "HYCM",
  },
  {
    label: "iAL Group",
    server1: "iALGroup-Server",
    value: "iAL Group",
  },
  {
    label: "Iam-Trader",
    server1: "IBellMarkets-Live",
    server2: "IBellMarkets-Demo",
    value: "Iam-Trader",
  },
  {
    label: "IB Markets",
    server1: "IBMarkets-Demo",
    server2: "IBMarkets-Live",
    value: "IB Markets",
  },
  {
    label: "Ibell Markets",
    server1: "IBellMarkets-Live",
    server2: "IBellMarkets-Demo",
    value: "Ibell Markets",
  },
  {
    label: "IC Markets",
    server1: "ICMarkets-MT5",
    server2: "ICMarkets-Demo",
    server3: "ICMarketsSC-Demo",
    server4: "ICMarketsSC-MT5",
    server5: "ICMarketsEU-MT5",
    server6: "ICMarketsEU-Demo",
    server7: "ICMarketsSC-MT5-2",
    server8: "mt5-2.icmarkets.com",
    server9: "ICMarketsEU-MT5-2",
    server10: "ICMarkets-MT5-2",
    server11: "ICMarketsSC-MT5-4",
    server12: "ICMarkets-MT5-4",
    server13: "ICMarketsEU-MT5-4",
    value: "IC Markets",
  },
  {
    label: "ICM",
    server1: "ICMCapital-Demo",
    server2: "ICMCapital-Live",
    value: "ICM",
  },
  {
    label: "IFC Markets",
    server1: "IFCMarkets-Demo",
    server2: "IFCMarkets-Real",
    value: "IFC Markets",
  },
  {
    label: "IFX Brokers",
    server1: "IFXBrokers-Real",
    value: "IFX Brokers",
  },
  {
    label: "IKOFX",
    server1: "IKOGlobal-Live",
    server2: "IKOGlobal-Demo",
    value: "IKOFX",
  },
  {
    label: "Illuminati Markets",
    server1: "IlluminatiMarkets-LIVE",
    value: "Illuminati Markets",
  },
  {
    label: "Impact Tech Ltd",
    server1: "IMPACT-Demo",
    value: "Impact Tech Ltd",
  },
  {
    label: "INFINOX Capital",
    server1: "InfinoxCapital-MT5Live",
    server2: "InfinoxCapital-MT5Demo",
    server3: "InfinoxLimited-MT5Live",
    value: "INFINOX Capital",
  },
  {
    label: "Info Xpo Solutions Ltd",
    server1: "XPOFX-live",
    value: "Info Xpo Solutions Ltd",
  },
  {
    label: "Infratrader",
    server1: "InfraTrader-Live",
    value: "Infratrader",
  },
  {
    label: "INGOT Brokers",
    server1: "IngotBrokers-Server",
    server2: "IngotBroker2-Server",
    server3: "INGOTGlobalLtd-server",
    server4: "IngotBrokers2-Server",
    value: "INGOT Brokers",
  },
  {
    label: "INMARKETFX",
    server1: "IntegratedMarkets-demo",
    server2: "IntegratedMarkets-Live",
    value: "INMARKETFX",
  },
  {
    label: "innovaTrade",
    server1: "innovaTrade-Live",
    value: "innovaTrade",
  },
  {
    label: "InstaForex",
    server1: "InstaForex-Server",
    value: "InstaForex",
  },
  {
    label: "Inter Pan Pasifik Futures",
    server1: "InterPanPasifik-Demo",
    server2: "InterPanPasifik-Live",
    value: "Inter Pan Pasifik Futures",
  },
  {
    label: "Intercontinental Securities",
    server1: "IntercontinentalSec-MetaTrader5",
    value: "Intercontinental Securities",
  },
  {
    label: "INVESTZO",
    server1: "Investzo-Demo",
    server2: "Investzo-Live",
    value: "INVESTZO",
  },
  {
    label: "iQuoto",
    server1: "iQuoto-Live",
    server2: "iQuoto-Demo",
    value: "iQuoto",
  },
  {
    label: "Ironclad Markets",
    server1: "IroncladMarkets-MetaTrader5",
    value: "Ironclad Markets",
  },
  {
    label: "IronFX",
    server1: "No servers",
    value: "IronFX",
  },
  {
    label: "IS6FX",
    server1: "IS6Technologies-Demo",
    server2: "IS6Technologies-Live",
    value: "IS6FX",
  },
  {
    label: "ITB",
    server1: "ITB-Server",
    value: "ITB",
  },
  {
    label: "IUX Markets",
    server1: "IUXMarket-Platform",
    server2: "IUXMarkets-Live2",
    server3: "IUXMarkets-Demo",
    server4: "IUXMarkets-Live",
    server5: "IUXMarkets-Live3",
    value: "IUX Markets",
  },
  {
    label: "java",
    server1: "JavaGlobalFutures-Demo Server",
    server2: "JavaGlobalFutures-Live",
    value: "java",
  },
  {
    label: "JetTrade Markets",
    server1: "JetTradeMarkets-Live",
    value: "JetTrade Markets",
  },
  {
    label: "JFD",
    server1: "JFD-Live",
    server2: "JFD-Demo",
    value: "JFD",
  },
  {
    label: "JM Financial",
    server1: "JMFinancial-Server",
    value: "JM Financial",
  },
  {
    label: "JQL Markets",
    server1: "JQLMarkets-Live",
    value: "JQL Markets",
  },
  {
    label: "JSCBrokerage",
    server1: "JSCBrokerage-Server",
    server2: "MultiFamilyOfficeWealthy-Server",
    value: "JSCBrokerage",
  },
  {
    label: "Just2Trade",
    server1: "Just2Trade-MT5",
    value: "Just2Trade",
  },
  {
    label: "JustMarkets",
    server1: "JFGlobal-Demo",
    server2: "JFGlobal-Live",
    server3: "JustForex-Live",
    server4: "JustForex-Demo",
    server5: "JustMarketsCY-Demo",
    server6: "JustMarketsCY-Live",
    value: "JustMarkets",
  },
  {
    label: "KERN Private Capital",
    server1: "KenPrivateCapital-Server",
    value: "KERN Private Capital",
  },
  {
    label: "Key To Markets",
    server1: "KeytoMarkets-Server",
    value: "Key To Markets",
  },
  {
    label: "KGI",
    server1: "KGIFutures-Demo",
    server2: "KGIFutures-Real",
    value: "KGI",
  },
  {
    label: "Khwezi Trade",
    server1: "Khwezi-Live",
    value: "Khwezi Trade",
  },
  {
    label: "KirikFX",
    server1: "KirikMarkets-Server",
    value: "KirikFX",
  },
  {
    label: "Kodin Capital",
    server1: "KodinCapitalMarkets-Server",
    value: "Kodin Capital",
  },
  {
    label: "Kwakol Markets",
    server1: "KwakolMarketsPty-Server",
    value: "Kwakol Markets",
  },
  {
    label: "L2L Forex",
    server1: "Link2LinkForex-Main",
    value: "L2L Forex",
  },
  {
    label: "Land FX",
    server1: "LandFX-Live",
    server2: "LandFX-Demo",
    server3: "LandFX-Live2",
    value: "Land FX",
  },
  {
    label: "LBLV",
    server1: "LBLV- Server",
    value: "LBLV",
  },
  {
    label: "LDN",
    server1: "LDNGlobalMarkets-Demo",
    server2: "LDNGlobalMarkets-Live",
    value: "LDN",
  },
  {
    label: "Leading Alliance",
    server1: "LeadingAlliance-Server",
    value: "Leading Alliance",
  },
  {
    label: "LegacyFX",
    server1: "LegacyFX-Server",
    value: "LegacyFX",
  },
  {
    label: "LHCrypto",
    server1: "LHCrypto-Real",
    server2: "LHCrypto-Demo",
    value: "LHCrypto",
  },
  {
    label: "Libertex",
    server1: "LibertexCom-MT5 Real Server",
    server2: "LibertexCom-MT5 Demo Server",
    value: "Libertex",
  },
  {
    label: "Light-Capital",
    server1: "Light-Capital-Server",
    value: "Light-Capital",
  },
  {
    label: "LiMarkets",
    server1: "LiquiditySoftSolutions-Live",
    server2: "LiquiditySoftSolutions-Demo",
    value: "LiMarkets",
  },
  {
    label: "Lite Finance",
    server1: "LiteFinance-MT5",
    value: "Lite Finance",
  },
  {
    label: "Liteforex",
    server1: "LiteForex-MT5",
    server2: "LiteForexInvestments-MT5.com",
    value: "Liteforex",
  },
  {
    label: "Loyal Markets",
    server1: "LoyalMarkets-Live",
    value: "Loyal Markets",
  },
  {
    label: "Lwin LLC",
    server1: "LwinLLC-Demo",
    server2: "LwinLLC-Live",
    value: "Lwin LLC",
  },
  {
    label: "M4Markets",
    server1: "M4Markets-Server",
    server2: "M4Markets-MT5",
    server3: "M4MarketsCY-Real1",
    value: "M4Markets",
  },
  {
    label: "Maniford",
    server1: "Maniford-Server",
    value: "Maniford",
  },
  {
    label: "Market Equity Inc",
    server1: "MarketEquityInc-Live",
    server2: "MarketEquityInc-Demo",
    value: "Market Equity Inc",
  },
  {
    label: "Market winner",
    server1: "MarketWinnerComm-Demo",
    server2: "MarketWinnerComm-Live",
    value: "Market winner",
  },
  {
    label: "Markets.com",
    server1: "TradeTechMarkets-Live",
    server2: "Markets.com-Live",
    value: "Markets.com",
  },
  {
    label: "MasterECNfx",
    server1: "MasterECN-Demo",
    server2: "MasterECN-Live",
    value: "MasterECNfx",
  },
  {
    label: "MaxFX",
    server1: "No servers",
    value: "MaxFX",
  },
  {
    label: "MedBondMarkets",
    server1: "MedBondMarkets-Live",
    value: "MedBondMarkets",
  },
  {
    label: "MENTARI",
    server1: "PTMentrariMuliaBerjangka-Real",
    value: "MENTARI",
  },
  {
    label: "Mentrari",
    server1: "No servers",
    value: "Mentrari",
  },
  {
    label: "Mercato Brokers",
    server1: "MercatoBrokers-Server",
    value: "Mercato Brokers",
  },
  {
    label: "MerchantFX",
    server1: "MerchantFX-Demo",
    server2: "MerchantFX-Live",
    value: "MerchantFX",
  },
  {
    label: "Metadoro",
    server1: "RHCInvestments-Metadoro",
    value: "Metadoro",
  },
  {
    label: "MetaQuotes",
    server1: "MetaQuotes-Demo",
    value: "MetaQuotes",
  },
  {
    label: "MetaQuotes Software Corp",
    server1: "MetaQuotes-Demo",
    value: "MetaQuotes Software Corp",
  },
  {
    label: "MEX Atlantic",
    server1: "MEXAtlantic-Demo",
    server2: "MEXAtlantic-Real",
    server3: "MEXAtlantic-Real-2",
    value: "MEX Atlantic",
  },
  {
    label: "Mex Group",
    server1: "MEXIntGroup-Real",
    server2: "MEXIntGroup-Demo",
    value: "Mex Group",
  },
  {
    label: "MFX",
    server1: "MFXPrimeDOO-real",
    value: "MFX",
  },
  {
    label: "MidasFX",
    server1: "MidasFX-Live",
    value: "MidasFX",
  },
  {
    label: "Milton Prime",
    server1: "No servers",
    value: "Milton Prime",
  },
  {
    label: "MintX markets",
    server1: "MintXLtd-Server",
    value: "MintX markets",
  },
  {
    label: "Mirus Capital",
    server1: "MirusCapital-Server",
    value: "Mirus Capital",
  },
  {
    label: "MISHOV GROUP",
    server1: "MishovMarkets-Live",
    value: "MISHOV GROUP",
  },
  {
    label: "MKDFX",
    server1: "MKDFXLLC-MT5",
    value: "MKDFX",
  },
  {
    label: "Mocaz Financial Markets",
    server1: "MFMGroup- live",
    server2: "MFMGroup- Demo",
    value: "Mocaz Financial Markets",
  },
  {
    label: "Modal DTVM",
    server1: "ModalMais-MetaTrader 5",
    server2: "ModalMais-DMA4 - Beta",
    value: "Modal DTVM",
  },
  {
    label: "ModalMais-MetaTrader 5",
    server1: "No servers",
    value: "ModalMais-MetaTrader 5",
  },
  {
    label: "MogaFX",
    server1: "MogaIntlGroup-Live",
    server2: "MogaIntlGroup-Demo",
    value: "MogaFX",
  },
  {
    label: "Mohicans Markets",
    server1: "Mohicans-Real",
    value: "Mohicans Markets",
  },
  {
    label: "MorningFX",
    server1: "MorningMarkets-Live",
    server2: "MorningMarkets-Demo",
    value: "MorningFX",
  },
  {
    label: "MultiBank",
    server1: "MultiBankFX-Real",
    server2: "MultiBankFX-Demo",
    server3: "MEXGlobalFinancial-Real",
    value: "MultiBank",
  },
  {
    label: "MurrenTrade",
    server1: "Murrentrade-Online",
    value: "MurrenTrade",
  },
  {
    label: "MyBrokerFX",
    server1: "MyBrokerFX-Live",
    server2: "MyBrokerFX-Demo",
    value: "MyBrokerFX",
  },
  {
    label: "MyForexFunds",
    server1: "TradersGlobalGroup-Demo",
    server2: "TradersGlobalGroup-Live",
    value: "MyForexFunds",
  },
  {
    label: "N1CM",
    server1: "N1CapitalMarkets-Server",
    server2: "N1CapitalMarkets-Live",
    value: "N1CM",
  },
  {
    label: "NAGA Global",
    server1: "NAGAGlobal-Demo",
    server2: "NAGAGlobal-Live",
    value: "NAGA Global",
  },
  {
    label: "NAGA Markets",
    server1: "NagaMarkets-Live",
    server2: "NagaMarkets-Demo",
    value: "NAGA Markets",
  },
  {
    label: "NAS Broker",
    server1: "NAS-MetaTrader5",
    value: "NAS Broker",
  },
  {
    label: "Nash Markets",
    server1: "NashMarkets-Live",
    server2: "NashMarkets-Demo",
    value: "Nash Markets",
  },
  {
    label: "Nash Value Investment",
    server1: "NashValueInvestment-Server",
    value: "Nash Value Investment",
  },
  {
    label: "NDDFX Technology",
    server1: "NDDFX-Live",
    server2: "NDDFX-UK",
    value: "NDDFX Technology",
  },
  {
    label: "Nimble Markets",
    server1: "NimbleMarkets-Demo",
    server2: "NimbleMarkets-Live",
    value: "Nimble Markets",
  },
  {
    label: "Noor Al Mal",
    server1: "NoorAlmal-Demo",
    server2: "NoorAlmal-Real",
    value: "Noor Al Mal",
  },
  {
    label: "Nord FX",
    server1: "NordFX-Server",
    value: "Nord FX",
  },
  {
    label: "Novotrend",
    server1: "Novotrend-MT5",
    value: "Novotrend",
  },
  {
    label: "NOZAX",
    server1: "NozaxAD-Trade",
    value: "NOZAX",
  },
  {
    label: "NPBFX",
    server1: "No servers",
    value: "NPBFX",
  },
  {
    label: "NSBroker",
    server1: "NSFXLtd-LIVE",
    server2: "NSFXLtd-Demo",
    value: "NSBroker",
  },
  {
    label: "NSFX",
    server1: "NSFXLtd-LIVE",
    value: "NSFX",
  },
  {
    label: "Number One Capital Markets",
    server1: "N1CapitalMarkets-Server",
    value: "Number One Capital Markets",
  },
  {
    label: "NYX",
    server1: "NYXMarkets-Live",
    server2: "NYXMarkets-Demo",
    value: "NYX",
  },
  {
    label: "Oanda",
    server1: "OANDA-Japan MT5 Live",
    server2: "OANDA-Japan MT5 Demo",
    server3: "OANDA-OGM MT5 Live",
    server4: "OANDA-Demo-1",
    value: "Oanda",
  },
  {
    label: "OBOFX",
    server1: "OBOFX-Demo",
    server2: "OBOFX-Live",
    value: "OBOFX",
  },
  {
    label: "Ocean Markets",
    server1: "OceanMarkets-Server",
    value: "Ocean Markets",
  },
  {
    label: "OctaFX",
    server1: "OctaFX-Real2",
    server2: "OctaFX-Real",
    server3: "OctaFX-Demo",
    value: "OctaFX",
  },
  {
    label: "OlympusFX",
    server1: "OlympusFinancialGroup-Demo",
    server2: "OlympusFinancialGroup-Live",
    value: "OlympusFX",
  },
  {
    label: "One Financial Markets",
    server1: "OneFinancialMarkets-US48-Demo",
    server2: "OneFinancialMarkets-US48-Live",
    value: "One Financial Markets",
  },
  {
    label: "One FX Capital",
    server1: "OneFXCapitals-Live",
    server2: "OneFXCapirals-Demo",
    value: "One FX Capital",
  },
  {
    label: "Open Broker",
    server1: "Open-Broker",
    server2: "Open-Demo",
    value: "Open Broker",
  },
  {
    label: "OpenFX",
    server1: "OpenInvestments-Demo",
    server2: "OpenInvestments-LIVE",
    value: "OpenFX",
  },
  {
    label: "Opofinance",
    server1: "Opogroup-Server1",
    value: "Opofinance",
  },
  {
    label: "Opoforex",
    server1: "Opogroup-Server1",
    value: "Opoforex",
  },
  {
    label: "Orama",
    server1: "OramaDTVM-Server",
    value: "Orama",
  },
  {
    label: "ORFINEX",
    server1: "OrfinexCapitalLtd-MT5",
    value: "ORFINEX",
  },
  {
    label: "Orient Markets",
    server1: "OrientMarketsLimited-Live",
    value: "Orient Markets",
  },
  {
    label: "OspreyFX",
    server1: "Osprey-Live",
    server2: "Osprey-Demo",
    value: "OspreyFX",
  },
  {
    label: "Other(MT5)",
    server1: "No servers",
    value: "Other(MT5)",
  },
  {
    label: "Ox Securities",
    server1: "OxSecurities-Live",
    value: "Ox Securities",
  },
  {
    label: "Oxis Fx Pro",
    server1: "No servers",
    value: "Oxis Fx Pro",
  },
  {
    label: "OxisFX",
    server1: "OxisFX-Demo",
    server2: "OxisFX-Live",
    server3: "PGCForex-Server",
    value: "OxisFX",
  },
  {
    label: "OxShare",
    server1: "OXShare-MetaTrader5",
    server2: "OXShareLtd-server",
    value: "OxShare",
  },
  {
    label: "Pacific Union",
    server1: "PacificUnionInt-Live",
    server2: "PacificUnionLLC-Live",
    server3: "PacificUnionInt-Demo",
    value: "Pacific Union",
  },
  {
    label: "Pelliron",
    server1: "PellironUniversal-Server",
    value: "Pelliron",
  },
  {
    label: "Pepperstone",
    server1: "Pepperstone-Demo",
    server2: "Pepperstone-MT5-Live01",
    server3: "PepperstoneUK-Live",
    server4: "Pepperstone-Demo 02",
    server5: "Pepperstone MT5-2",
    server6: "PepperstoneUK-Demo",
    value: "Pepperstone",
  },
  {
    label: "PFH Markets",
    server1: "PFHMarkets-Live",
    server2: "PFHMarkets-Demo",
    value: "PFH Markets",
  },
  {
    label: "PGC Forex",
    server1: "PGCForex-Server",
    value: "PGC Forex",
  },
  {
    label: "Phase Markets",
    server1: "PhaseMarkets-MT5Live",
    server2: "PhaseMarkets-MT5Demo",
    value: "Phase Markets",
  },
  {
    label: "Phillip Futures",
    server1: "PhillipFutures-Demo",
    server2: "PhillipFutures-Server",
    value: "Phillip Futures",
  },
  {
    label: "PhillipCapital",
    server1: "PhillipSecuritiesJP-PROD",
    value: "PhillipCapital",
  },
  {
    label: "Place a Trade",
    server1: "PlaceATrade-Real",
    server2: "PlaceATrade-Demo",
    value: "Place a Trade",
  },
  {
    label: "Platinum FX",
    server1: "Platinum-Server",
    value: "Platinum FX",
  },
  {
    label: "Platinum Global FX",
    server1: "PlatinumGlobalFX-Live",
    value: "Platinum Global FX",
  },
  {
    label: "Plotio",
    server1: "PlotioGlobalFinancial-Demo",
    server2: "PlotioGlobalFinancial-Live",
    value: "Plotio",
  },
  {
    label: "Pocket Option",
    server1: "PoTrade-MT5",
    value: "Pocket Option",
  },
  {
    label: "PomeloFx",
    server1: "Pomelo-Live",
    value: "PomeloFx",
  },
  {
    label: "PPDE",
    server1: "PPDExchange-Live",
    server2: "PPDExchange-Demo",
    value: "PPDE",
  },
  {
    label: "PRC Group",
    server1: "PerformanceRC-Demo",
    server2: "PerformanceRC-Live",
    value: "PRC Group",
  },
  {
    label: "Precise FX",
    server1: "PreciseFX-Server",
    value: "Precise FX",
  },
  {
    label: "Prime Codex",
    server1: "PrimeCodex-MT5",
    value: "Prime Codex",
  },
  {
    label: "PrimeBit",
    server1: "PrimeBit-Demo",
    server2: "PrimeBit-Live",
    value: "PrimeBit",
  },
  {
    label: "PrimeX Broker",
    server1: "PrimeXBroker-01",
    value: "PrimeX Broker",
  },
  {
    label: "PrizmFX",
    server1: "PrizmFX-Live",
    value: "PrizmFX",
  },
  {
    label: "Profit FX Markets",
    server1: "ProfitFxMarkets-Server",
    value: "Profit FX Markets",
  },
  {
    label: "Profitix",
    server1: "Profitix-server",
    value: "Profitix",
  },
  {
    label: "ProfitPipsFX",
    server1: "ProfitPipsMarkets-Live",
    value: "ProfitPipsFX",
  },
  {
    label: "ProfiXone",
    server1: "ProfiXoneCapital-Demo",
    server2: "ProfiXoneCapital-Real",
    value: "ProfiXone",
  },
  {
    label: "ProFX",
    server1: "ProTradingFX-Server",
    value: "ProFX",
  },
  {
    label: "Promax",
    server1: "MaxFx-MT5",
    value: "Promax",
  },
  {
    label: "PSBForex",
    server1: "PSBForex-Real",
    server2: "PSBForex-Demo",
    value: "PSBForex",
  },
  {
    label: "PSFX",
    server1: "PSFXInternational-Server",
    value: "PSFX",
  },
  {
    label: "PU Prime",
    server1: "PUPrime-Demo",
    server2: "PUPrime-Live",
    value: "PU Prime",
  },
  {
    label: "Pure Market",
    server1: "PureMGlobal-MT5",
    value: "Pure Market",
  },
  {
    label: "Q8 Trade",
    server1: "Q8trade-Live",
    server2: "Q8trade-Demo",
    value: "Q8 Trade",
  },
  {
    label: "QFX",
    server1: "QFXTradeLimited-Live",
    server2: "QFXTrade-Limited-Server",
    value: "QFX",
  },
  {
    label: "QMMFX",
    server1: "QMMFX-Demo",
    server2: "QMMFX-Live",
    value: "QMMFX",
  },
  {
    label: "QQ Global",
    server1: "QQGlobalGroup-Demo",
    server2: "QQGlobalGroup-Live",
    value: "QQ Global",
  },
  {
    label: "QTrade",
    server1: "QTrade-Server",
    value: "QTrade",
  },
  {
    label: "Quantum Exchange",
    server1: "QuantumCapital-Live",
    server2: "QuantumCapital-Demo",
    value: "Quantum Exchange",
  },
  {
    label: "QuickTrade",
    server1: "QuickTrade-Server",
    server2: "QuickTrade-Live",
    value: "QuickTrade",
  },
  {
    label: "RaiseFX",
    server1: "RaiseGroup-Server",
    value: "RaiseFX",
  },
  {
    label: "RannForex",
    server1: "RannForex-Server",
    value: "RannForex",
  },
  {
    label: "RedMars",
    server1: "RMMarkets-Real",
    value: "RedMars",
  },
  {
    label: "Revotrade",
    server1: "Revotrade-Metatrader5",
    value: "Revotrade",
  },
  {
    label: "Rico",
    server1: "Rico-Demo",
    server2: "Rico-PRD",
    value: "Rico",
  },
  {
    label: "RightFX",
    server1: "RightGroup-Live",
    value: "RightFX",
  },
  {
    label: "Riston Capital",
    server1: "FreshForex-MT5",
    value: "Riston Capital",
  },
  {
    label: "RoboForex",
    server1: "RoboForex-MetaTrader 5",
    server2: "RoboForex-Pro",
    server3: "RoboForex-ECN",
    value: "RoboForex",
  },
  {
    label: "Royal Capital Markets",
    server1: "RoyalCapitalMarkets-Live",
    value: "Royal Capital Markets",
  },
  {
    label: "Royal Trading Fx",
    server1: "RoyalTradingFX-LIVE",
    server2: "RoyalTradingFX-Demo",
    value: "Royal Trading Fx",
  },
  {
    label: "Salam Investments",
    server1: "AlSalamFinancialInv-Demo",
    server2: "AlSalamFinancialInv-Server",
    value: "Salam Investments",
  },
  {
    label: "SamrridhFx",
    server1: "SamrridhFXLimited-Server",
    value: "SamrridhFx",
  },
  {
    label: "Sapphire Markets",
    server1: "SapphireMarkets-Server",
    value: "Sapphire Markets",
  },
  {
    label: "SBC Forex",
    server1: "SBCGlobalLimited-Live",
    value: "SBC Forex",
  },
  {
    label: "Schatz Markets",
    server1: "SchatzMarkets-Server",
    value: "Schatz Markets",
  },
  {
    label: "Scope Markets",
    server1: "SCFMLimited-Live2",
    server2: "SCFMLimited-Demo2",
    server3: "ScopeMarkets-Live",
    server4: "SMCapitalMarkets-Live2",
    server5: "ScopeMarketsSA-Live",
    server6: "ScopeMarketsSA-Demo",
    value: "Scope Markets",
  },
  {
    label: "SEALARK",
    server1: "SealarkLimited-Demo",
    server2: "SealarkLimited-Real",
    value: "SEALARK",
  },
  {
    label: "SEC Markets",
    server1: "SECMarkets-Demo",
    server2: "SECMarkets-Live",
    value: "SEC Markets",
  },
  {
    label: "Seventy Brokers",
    server1: "SeventyInvestech-Platform",
    value: "Seventy Brokers",
  },
  {
    label: "SG Company",
    server1: "StrongGood-Demo",
    server2: "StrongGood-Real",
    value: "SG Company",
  },
  {
    label: "Shine Join FX",
    server1: "ShineJoinFX-Live",
    server2: "ShineJoinFX-Demo",
    value: "Shine Join FX",
  },
  {
    label: "Signal FX Capital",
    server1: "SignalFXCapital-Demo",
    server2: "SignalFXCapital-Live",
    value: "Signal FX Capital",
  },
  {
    label: "SinoxFX",
    server1: "SinoxFX-Server",
    value: "SinoxFX",
  },
  {
    label: "SkylarkFX",
    server1: "SkylardCaptialMarkets-live",
    value: "SkylarkFX",
  },
  {
    label: "Smart Securities and Commodities LTD",
    server1: "SmartSecurities-Demo",
    server2: "SmartSecurities-LiveUK",
    value: "Smart Securities and Commodities LTD",
  },
  {
    label: "So Trade FX",
    server1: "SoTradeFx-Live",
    server2: "SoTradeFx-Demo",
    value: "So Trade FX",
  },
  {
    label: "Solid ECN",
    server1: "SolidECN-Server",
    value: "Solid ECN",
  },
  {
    label: "Solidary Markets FX",
    server1: "SADASoftware-Server",
    server2: "SADASoftware-Demo",
    value: "Solidary Markets FX",
  },
  {
    label: "SpotX Markets",
    server1: "SpotXMarkets-Live",
    value: "SpotX Markets",
  },
  {
    label: "Spread Co Global Markets",
    server1: "SpreadCoGlobal-Live",
    value: "Spread Co Global Markets",
  },
  {
    label: "Squared Financial",
    server1: "SquaredFinancial-MT5 Live",
    server2: "SquaredFinancial-MT5-Demo",
    value: "Squared Financial",
  },
  {
    label: "SR Trades",
    server1: "SRTrades-Demo",
    server2: "SRTrades-Live",
    value: "SR Trades",
  },
  {
    label: "SS8FX",
    server1: "SS8Wealth-MT5",
    value: "SS8FX",
  },
  {
    label: "ST Global Markets",
    server1: "STGlobalMarketsLtd-Live-1",
    value: "ST Global Markets",
  },
  {
    label: "ST Market",
    server1: "STMarket-Demo",
    server2: "STMarket-Live",
    value: "ST Market",
  },
  {
    label: "Star Net Fx",
    server1: "StarNet-Server",
    value: "Star Net Fx",
  },
  {
    label: "STARTRADER",
    server1: "STARTRADERINTL-Live",
    value: "STARTRADER",
  },
  {
    label: "STP Trading",
    server1: "STPTrading-Server",
    value: "STP Trading",
  },
  {
    label: "Strathos Capital",
    server1: "No servers",
    value: "Strathos Capital",
  },
  {
    label: "StriFor",
    server1: "StriforLLC-Demo",
    server2: "StriforLLC-Live",
    value: "StriFor",
  },
  {
    label: "Sunton Capital",
    server1: "SuntonCapital-Live",
    server2: "SuntonCapital-Demo",
    value: "Sunton Capital",
  },
  {
    label: "Supreme FX",
    server1: "Supreme-Server",
    value: "Supreme FX",
  },
  {
    label: "Sway Markets",
    server1: "SwayMarkets-Server",
    value: "Sway Markets",
  },
  {
    label: "Swissquote",
    server1: "SwissquoteBankEU-Server",
    server2: "Swissquote",
    server3: "Swissquote-Server",
    server4: "SwissquoteLtd-Server",
    value: "Swissquote",
  },
  {
    label: "SynTradeFx",
    server1: "SyntradefxLimited-Server",
    value: "SynTradeFx",
  },
  {
    label: "Target FX Market",
    server1: "TFXMarkets-Live",
    value: "Target FX Market",
  },
  {
    label: "TDX Global Technologies",
    server1: "TDXGlobalTech-Demo",
    server2: "TDXGlobalTech-Server",
    value: "TDX Global Technologies",
  },
  {
    label: "TegasFX",
    server1: "tegasFX-Main-UK",
    value: "TegasFX",
  },
  {
    label: "TeleTrade",
    server1: "TeleTRADECY-Sharp ECN",
    server2: "Teletrade-Sharp ECN",
    value: "TeleTrade",
  },
  {
    label: "Tenda Index",
    server1: "TendaIndex-Demo",
    server2: "TendaIndex-Live",
    value: "Tenda Index",
  },
  {
    label: "TenorisFx",
    server1: "TenorisFX-MetaTrader5",
    value: "TenorisFx",
  },
  {
    label: "Terra Investimentos",
    server1: "TerraInvestimentos-Demo",
    server2: "TerraInvestimentos-PROD",
    value: "Terra Investimentos",
  },
  {
    label: "TGI Capitals",
    server1: "TGICapitals-Demo",
    server2: "TGICapitals-Live",
    value: "TGI Capitals",
  },
  {
    label: "TGKK",
    server1: "TGKKLimited-Demo",
    server2: "TGKKLimited-Live",
    value: "TGKK",
  },
  {
    label: "The Brokers Capital",
    server1: "BRKHoldings-Live",
    server2: "BRKHoldings-Demo",
    value: "The Brokers Capital",
  },
  {
    label: "The Traders Domain",
    server1: "TradersDomainFX-MetaTrader5",
    value: "The Traders Domain",
  },
  {
    label: "ThinkMarkets",
    server1: "ThinkMarkets-Live",
    server2: "ThinkMarkets-Demo",
    value: "ThinkMarkets",
  },
  {
    label: "Thunder Markets",
    server1: "No servers",
    value: "Thunder Markets",
  },
  {
    label: "Tickmill",
    server1: "TickmillUK-Demo",
    server2: "Tickmill-Live",
    server3: "TickmillUK-Live",
    server4: "TickMillEU-Live",
    server5: "TickmillAsia-Live",
    server6: "Tickmill-Demo",
    value: "Tickmill",
  },
  {
    label: "Tifia",
    server1: "Tifia-MT5",
    value: "Tifia",
  },
  {
    label: "Tio Markets",
    server1: "TIOMarkerts-Demo1",
    server2: "TIOMarkets-Live1",
    value: "Tio Markets",
  },
  {
    label: "TIOmarkets",
    server1: "Tiomarkets-Demo1",
    server2: "Tiomarkets-Live1",
    value: "TIOmarkets",
  },
  {
    label: "TitanFX",
    server1: "TitanFX-MT5-Demo",
    server2: "TitanFX-MT5-01",
    value: "TitanFX",
  },
  {
    label: "tixee",
    server1: "Pipbull-Live01",
    server2: "Pipbull-Demo01",
    value: "tixee",
  },
  {
    label: "TMS Brokers",
    server1: "TMS-MT5",
    value: "TMS Brokers",
  },
  {
    label: "TNFX",
    server1: "TiranForex-Demo",
    server2: "TiranForex-Live",
    server3: "TNFX-Live",
    value: "TNFX",
  },
  {
    label: "TOP1 Markets",
    server1: "Top1Group-Live",
    server2: "Top1Group-Demo",
    value: "TOP1 Markets",
  },
  {
    label: "TopForex",
    server1: "TOPFOREX-Live",
    value: "TopForex",
  },
  {
    label: "Topmax Global",
    server1: "TopMaxGlobal-Demo",
    server2: "TopMaxGlobal-Real",
    value: "Topmax Global",
  },
  {
    label: "topstarforex",
    server1: "No servers",
    value: "topstarforex",
  },
  {
    label: "TP Global FX",
    server1: "TPGlobalServicesLimited-Live",
    value: "TP Global FX",
  },
  {
    label: "Trade Quo",
    server1: "DVentureMarkets-Server",
    server2: "TradeQuo-server",
    value: "Trade Quo",
  },
  {
    label: "Trade Securities",
    server1: "TradeSecurities-Demo",
    server2: "TradeSecurities-Server",
    value: "Trade Securities",
  },
  {
    label: "Trade360",
    server1: "Trade360CrowdTrading-Live",
    value: "Trade360",
  },
  {
    label: "TradeFTM",
    server1: "TradeFTM-Live",
    server2: "TradeFTM-Demo",
    value: "TradeFTM",
  },
  {
    label: "TradeHall",
    server1: "TradehallLimited-Main",
    server2: "TradehallLimited-Demo",
    value: "TradeHall",
  },
  {
    label: "TradEmpire",
    server1: "Tradempire-Demo",
    server2: "Tradempire-Live",
    value: "TradEmpire",
  },
  {
    label: "TradePro",
    server1: "No servers",
    value: "TradePro",
  },
  {
    label: "TradeProMarkets",
    server1: "TradeProMarkets-Live",
    server2: "TradeProMarkets-Demo",
    value: "TradeProMarkets",
  },
  {
    label: "Trader's Way",
    server1: "TradersWay-Demo",
    server2: "TradersWay-Live",
    value: "Trader's Way",
  },
  {
    label: "Traders Global Group",
    server1: "TradersGlobalGroup-Demo",
    server2: "TradersGlobalGroup-Live",
    value: "Traders Global Group",
  },
  {
    label: "Traders Live EU",
    server1: "HCFSHighCapital-Live",
    value: "Traders Live EU",
  },
  {
    label: "TradersCentral",
    server1: "TCBridge-Live",
    server2: "TCBridge-Demo",
    value: "TradersCentral",
  },
  {
    label: "TradersHome",
    server1: "TradersHome Live",
    value: "TradersHome",
  },
  {
    label: "TradeTech Markets",
    server1: "TradeTechMarkets-Live",
    server2: "TradeTechMarkets-Demo",
    value: "TradeTech Markets",
  },
  {
    label: "Tradeview Forex",
    server1: "Tradeview-Demo",
    server2: "Tradeview-Live",
    value: "Tradeview Forex",
  },
  {
    label: "Tradeview Markets",
    server1: "Tradeview-Demo",
    server2: "Tradeview-Live",
    value: "Tradeview Markets",
  },
  {
    label: "Trading.com",
    server1: "Trading.com-MT5",
    server2: "Trading.comMarkets-MT5",
    value: "Trading.com",
  },
  {
    label: "TradingPro",
    server1: "TradingProInternational-Demo",
    server2: "TradingProInternational-Live",
    value: "TradingPro",
  },
  {
    label: "Tranquil",
    server1: "Tranquil-Server",
    value: "Tranquil",
  },
  {
    label: "Trident Colosseum",
    server1: "TridentInnovation-Live",
    value: "Trident Colosseum",
  },
  {
    label: "TRILLION GLOBAL CAPITAL",
    server1: "TrillionGlobalCapital-Live",
    value: "TRILLION GLOBAL CAPITAL",
  },
  {
    label: "TrintFX",
    server1: "LiquiditySoftSolutions-Live",
    value: "TrintFX",
  },
  {
    label: "TRISKEL TRADING",
    server1: "TriskelCapital-Live",
    value: "TRISKEL TRADING",
  },
  {
    label: "Trivial Capital",
    server1: "TrivialCapital-Demo",
    server2: "TrivialCapital-Live",
    value: "Trivial Capital",
  },
  {
    label: "Trubluefx",
    server1: "AresGlobal-Server",
    value: "Trubluefx",
  },
  {
    label: "True Forex Funds",
    server1: "TrueProprietaryFunds-Demo",
    server2: "TrueProprietaryFunds-LIVE",
    value: "True Forex Funds",
  },
  {
    label: "TTS Markets",
    server1: "LiquiditySoftSolutions-Live",
    server2: "LiquiditySoftSolutions-Demo",
    value: "TTS Markets",
  },
  {
    label: "Turnkey Forex",
    server1: "TurnkeyGlobal-Demo",
    server2: "TurnkeyGlobal-Live",
    value: "Turnkey Forex",
  },
  {
    label: "UBANXX",
    server1: "UBANXX-Live",
    value: "UBANXX",
  },
  {
    label: "UNFXB",
    server1: "UNFXB-Real",
    value: "UNFXB",
  },
  {
    label: "Unifi Forex",
    server1: "UnifiCapitalAU-MetaTrader5",
    value: "Unifi Forex",
  },
  {
    label: "Uniglobe Markets",
    server1: "UniglobeMarkets-Demo",
    server2: "UniglobeMarkets-Live",
    value: "Uniglobe Markets",
  },
  {
    label: "Unison Corporation",
    server1: "UnisonCorporation-Server",
    value: "Unison Corporation",
  },
  {
    label: "UPFXtrade",
    server1: "UltimatePinnacle-LIVE",
    value: "UPFXtrade",
  },
  {
    label: "Uptos",
    server1: "TradingAccount-Server",
    value: "Uptos",
  },
  {
    label: "USGFX",
    server1: "UnionStandardInt-Live",
    server2: "UnionStandardInt-Demo",
    server3: "USGVU-Live",
    server4: "USGVU-Demo",
    value: "USGFX",
  },
  {
    label: "UW GLOBAL",
    server1: "UWGlobal-Server",
    value: "UW GLOBAL",
  },
  {
    label: "Valutrades",
    server1: "Valutrades-Demo",
    server2: "Valutrades-Live",
    value: "Valutrades",
  },
  {
    label: "Vanex FX",
    server1: "VanexInternetioanal-Online",
    server2: "VanexGlobal-Online",
    value: "Vanex FX",
  },
  {
    label: "Vantage Markets",
    server1: "VantageFX-Live",
    server2: "VantageFX-Demo",
    server3: "VantageFXInternational-Live",
    server4: "VantageInternational-Demo",
    server5: "VantageInternational-Live",
    server6: "VantageGlobalPrimeLLP-Live",
    server7: "VantageGlobalPrimeLLP-Live 2",
    value: "Vantage Markets",
  },
  {
    label: "Varchev Brokers",
    server1: "Varchev-Server",
    value: "Varchev Brokers",
  },
  {
    label: "Venn Prime Securities",
    server1: "VennPrimeSecurities-Demo",
    server2: "VennPrimeSecurities-Live",
    value: "Venn Prime Securities",
  },
  {
    label: "VEONCO",
    server1: "VeoncoGroup-Live",
    server2: "VeoncoGroup-Demo",
    value: "VEONCO",
  },
  {
    label: "Veracity Markets",
    server1: "VeracityMarkets-Demo",
    server2: "VeracityMarkets-Live",
    value: "Veracity Markets",
  },
  {
    label: "Verse Capital",
    server1: "VerseCapital-Server",
    value: "Verse Capital",
  },
  {
    label: "VerticaFX",
    server1: "VerticaFX-Demo",
    server2: "VerticaFX-Live",
    value: "VerticaFX",
  },
  {
    label: "Vesta Group",
    server1: "VestaGroup-Metatrader5",
    value: "Vesta Group",
  },
  {
    label: "Vextrader",
    server1: "Vextrader-Server",
    value: "Vextrader",
  },
  {
    label: "VFM Brokers",
    server1: "VentureFinancialMarkets-LIVE",
    server2: "VentureFinancialMarkets-Demo",
    value: "VFM Brokers",
  },
  {
    label: "VGIFX",
    server1: "VGIPartners-Demo",
    server2: "VGIPartners-Live",
    value: "VGIFX",
  },
  {
    label: "Victory International Futures",
    server1: "VictoryInternational-DEMO",
    server2: "VictoryInternational-REAL",
    value: "Victory International Futures",
  },
  {
    label: "Viewmaxfx",
    server1: "ViewMaxFx-Demo",
    server2: "ViewMaxFx-LIVE",
    value: "Viewmaxfx",
  },
  {
    label: "VIPTrade",
    server1: "VIPTRADE-Server",
    value: "VIPTrade",
  },
  {
    label: "Vision Bank",
    server1: "VFinanceSA-Server",
    server2: "WorldVisionGroup-Live",
    server3: "WorldVisionGroup-Demo",
    value: "Vision Bank",
  },
  {
    label: "VitalMarkets",
    server1: "VitalMarkets-Demo",
    server2: "VitalMarkets-Live",
    value: "VitalMarkets",
  },
  {
    label: "VITTAVERSE",
    server1: "Vittaverse-Server",
    value: "VITTAVERSE",
  },
  {
    label: "Vizavi",
    server1: "VZVImpex-MetaTrader5",
    value: "Vizavi",
  },
  {
    label: "Volmax Group",
    server1: "VolmaxGroup-Server",
    value: "Volmax Group",
  },
  {
    label: "Volume Groups FX",
    server1: "VolumeGroupsFX-Server",
    value: "Volume Groups FX",
  },
  {
    label: "VPFX",
    server1: "VPFX-Live",
    server2: "VPFX-Demo",
    value: "VPFX",
  },
  {
    label: "VT Markets",
    server1: "VTMarkets-Demo",
    server2: "VTMarkets-Live",
    server3: "VTMarkets-Live 2",
    value: "VT Markets",
  },
  {
    label: "vtbforex",
    server1: "VTBForex-MetaTrader5",
    server2: "VTBForex-Core",
    value: "vtbforex",
  },
  {
    label: "VTFX International",
    server1: "VictoryTradeIntl-Live",
    value: "VTFX International",
  },
  {
    label: "VTindex",
    server1: "VTindex-MT5",
    value: "VTindex",
  },
  {
    label: "W7",
    server1: "W7Limited-Live",
    server2: "W7Limited-Demo",
    value: "W7",
  },
  {
    label: "Wealth Compound",
    server1: "WealthCompound-Server",
    value: "Wealth Compound",
  },
  {
    label: "Wealth World Markets",
    server1: "WealthWorldFinancial-live",
    value: "Wealth World Markets",
  },
  {
    label: "Webears",
    server1: "WeBears-Real",
    value: "Webears",
  },
  {
    label: "WELTRADE",
    server1: "Weltrade-Real",
    server2: "Weltrade-Demo",
    value: "WELTRADE",
  },
  {
    label: "WesternFX",
    server1: "WesternGroup-Demo",
    server2: "WesternGroup-LiveUK",
    value: "WesternFX",
  },
  {
    label: "WHIZ FX",
    server1: "WhizFX-Live",
    value: "WHIZ FX",
  },
  {
    label: "World Credit Savings",
    server1: "WorldCreditSavings-Server",
    value: "World Credit Savings",
  },
  {
    label: "World Forex",
    server1: "Existrade-WForex Server",
    value: "World Forex",
  },
  {
    label: "WQ Broker",
    server1: "WQBroker -MetaTrader5",
    value: "WQ Broker",
  },
  {
    label: "XB Prime",
    server1: "MarketFinancials-MT5",
    value: "XB Prime",
  },
  {
    label: "XBTFX",
    server1: "XBTFX-MetaTrader5",
    value: "XBTFX",
  },
  {
    label: "Xero Markets",
    server1: "XeroCapitalMarkets-LIVE",
    value: "Xero Markets",
  },
  {
    label: "XGLOBAL FX",
    server1: "XGLOBALFX-Server",
    value: "XGLOBAL FX",
  },
  {
    label: "XGLOBAL Markets",
    server1: "XGLOBAL-Server",
    value: "XGLOBAL Markets",
  },
  {
    label: "XM",
    server1: "XMGlobal-MT5 4",
    server2: "XMGlobal-MT5 5",
    server3: "XMGlobal-MT5 6",
    server4: "XM.COM-MT5",
    server5: "XMGlobal-MT5",
    server6: "XMTrading-MT5",
    server7: "XM.COM-AU-MT5",
    server8: "XMGlobal-MT5 2",
    server9: "XMAU-MT5",
    server10: "XMTrading-MT5 3",
    value: "XM",
  },
  {
    label: "XMTrading",
    server1: "XMTrading-MT5",
    server2: "XMTrading-MT5 3",
    server3: "XMTrading-MT5 3",
    value: "XMTrading",
  },
  {
    label: "XP Investimentos",
    server1: "XPMT5-PRD",
    server2: "XPMT5-Demo",
    value: "XP Investimentos",
  },
  {
    label: "XProTrade",
    server1: "XGXPROTRADE-Server",
    value: "XProTrade",
  },
  {
    label: "XS",
    server1: "XSSystems-REAL-1",
    value: "XS",
  },
  {
    label: "XSOCIO Markets",
    server1: "XSocioMarketsLtd-Demo",
    server2: "XSocioMarketsLtd-Live",
    value: "XSOCIO Markets",
  },
  {
    label: "YaMarkets",
    server1: "YaMarkets-Server",
    value: "YaMarkets",
  },
  {
    label: "YazhiFX",
    server1: "YazhiMarketLimited-Demo",
    server2: "YazhiMarketLimited-Live",
    server3: "YazhiMarketLimited-Live",
    value: "YazhiFX",
  },
  {
    label: "YCM-Invest",
    server1: "YCMInvest-Server",
    value: "YCM-Invest",
  },
  {
    label: "YLDFX",
    server1: "YLDFX-Server 01",
    value: "YLDFX",
  },
  {
    label: "YLG Bullion",
    server1: "YLGBullionFutures-Online",
    value: "YLG Bullion",
  },
  {
    label: "Yuanta Futures",
    server1: "Yuanta-Demo",
    server2: "Yuanta-Live",
    value: "Yuanta Futures",
  },
  {
    label: "Zara FX",
    server1: "ArazFX-MetaTrader5",
    value: "Zara FX",
  },
  {
    label: "Zenfinex",
    server1: "Zenfinex-Demo",
    server2: "Zenfinex-LIVE",
    value: "Zenfinex",
  },
  {
    label: "Zero Markets",
    server1: "ZeroMarkers-Live1",
    server2: "ZEROMarkets-Demo-1",
    value: "Zero Markets",
  },
  {
    label: "Zeyfex",
    server1: "Zeyfex-Live",
    server2: "Zeyfex-Demo",
    value: "Zeyfex",
  },
  {
    label: "zForex",
    server1: "ZForexcapitalmarket-Server",
    value: "zForex",
  },
  {
    label: "Zumafx",
    server1: "SPMHypersonic-LIVE01",
    value: "Zumafx",
  },
];
export default allMT5brokers;
