import React from "react";
import $ from "jquery";
export const MessageBox = (props) => {
  const check = props.check;
  const onClickfunction = () => {
    $("#overlay").fadeOut(300);
    $("#messageBox").fadeOut(300);
    $("#noclick").fadeOut(300);
    $(".portfolio-dropdown").css({
      display: "flex",
    });
  };
  const text = !check ? "Create Portfolio" : "Select Portfolio";
  return (
    <div onClick={() => onClickfunction()} id="messageBox">
      <div id="messageBoxMessage"></div>
      <div id="selectPortfoliobutton">{text}</div>
    </div>
  );
};
export default MessageBox;
