import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import { InputCardsDW, CardColumnDW } from "./CardandParent";
import "./DepositWithdrawalStyle.scss";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import showConfirmedActionButton from "./../../functions/showConfirmedActionButton";
import { setLoaderState } from "./../../actions/actionCreators";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import store from "./../../store";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import ProTierComponent from "./../../components/tierComponents/pro";
import { getSpecificPortfolio } from "../../functions/getSpecificPortfolio";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import NoteComponent from "./../../components/NoteComponent";

var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const DepositWithdrawalData = (props) => {
  const verified = 0;
  const show100TradesCheck = props.show100TradesCheck;
  const showCheck = props.showCheck;
  const tier = props.tier;
  const active = props.active;
  const allData = props.userData;
  const portfolio = allData.portfolio;
  const allportfolios = allData.allData;
  const [isDisabled, setisDisabled] = useState(true);
  const firstUpdate = useRef(true);
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();

  const [dwData, setDWData] = useState({
    entryId: uuidv4(),
    orderType: "",
    endDateTime: "",
    profitLoss: "",
    fees: "",
    comments: "",
    profitLossErrorMsg: "",
    feesErrorMsg: "",
    dateTimeErrorMsg: "",
    chosenPortfolioName: portfolio.name,
  });
  const history = useHistory();
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType = localStorage.getItem("added");
      if (addedType === "Deposit") {
        showConfirmedActionButton("Deposit Logged!");
        localStorage.removeItem("added");
      } else if (addedType === "Withdrawal") {
        showConfirmedActionButton("Withdrawal Logged!");
        localStorage.removeItem("added");
      } else {
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [props, allData, firstUpdate, dwData]);
  const {
    entryId,
    orderType,
    endDateTime,
    profitLoss,
    fees,
    comments,
    chosenPortfolioName,
  } = dwData;
  const validate = useCallback(
    (data, input) => {
      const currencyRegex = /^-?[0-9]\d*(.\d+)?$/;
      const dateTimeRegex =
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)\s+([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;
      const chosenPortfolios = allportfolios.chosenPortfolios;
      if (chosenPortfolios.length !== 2) {
        $("#messageBox").fadeIn(300);
        $("#overlay").fadeIn(300);
        if (allportfolios.createdPortfolios.length === 1) {
          document.getElementById("messageBoxMessage").innerHTML =
            "Please create a portfolio to add a trade.";
        } else {
          document.getElementById("messageBoxMessage").innerHTML =
            "When adding a deposit/withdrawal, only one portfolio can be selected.";
        }
      } else {
        if (input === "endDateTime") {
          const enddatetemp = moment(data).format("MM/DD/YYYY HH:mm:ss");
          if (
            (!dateTimeRegex.test(enddatetemp) && data !== "") ||
            data === undefined
          ) {
            $(".dateTimeErrorMsg").css({ display: "inline" });
            $(".dateTimeErrorMsg").html(
              "Date & Time format only (MM/DD/YYYY HH:mm:ss)"
            );
          } else {
            $(".dateTimeErrorMsg").css({ display: "none" });
            $(".dateTimeErrorMsg").html("");
          }
        }
        if (input === "fees") {
          if (!currencyRegex.test(data) && data !== "") {
            $(".feesErrorMsg").css({ display: "inline" });
            $(".feesErrorMsg").html("Currency values only (\u00B1#.##)");
          } else {
            $(".feesErrorMsg").css({ display: "none" });
            $(".feesErrorMsg").html("");
          }
        }
        if (input === "profitLoss") {
          if (!currencyRegex.test(data) && data !== "") {
            $(".profitLossErrorMsg").css({ display: "inline" });
            $(".profitLossErrorMsg").html("Currency values only (\u00B1#.##)");
          } else {
            $(".profitLossErrorMsg").css({ display: "none" });
            $(".profitLossErrorMsg").html("");
          }
        }
        if (
          !endDateTime ||
          !profitLoss ||
          !orderType ||
          $(".dateTimeErrorMsg").html() !== "" ||
          $(".profitLossErrorMsg").html() !== "" ||
          $(".feesErrorMsg").html() !== ""
        ) {
          setisDisabled(true);
        } else {
          setisDisabled(false);
        }
      }
    },
    [
      orderType,
      endDateTime,
      profitLoss,
      allportfolios.chosenPortfolios,
      allportfolios.createdPortfolios.length,
    ]
  );
  useEffect(() => {
    validate(endDateTime, "endDateTime");
  }, [endDateTime, validate]);
  useEffect(() => {
    validate(orderType, "orderType");
  }, [orderType, validate]);
  useEffect(() => {
    validate(profitLoss, "profitLoss");
  }, [profitLoss, validate]);
  useEffect(() => {
    validate(fees, "fees");
  }, [fees, validate]);

  const handleSubmit = async () => {
    await store.dispatch(setLoaderState({ loading: true }));
    const enddate = new Date(endDateTime);
    const data = {
      entryId: entryId,
      entry: {
        pictures: ["N/A"],
        profitLoss:
          orderType === "Withdrawal"
            ? String(-1 * Math.abs(profitLoss))
            : String(Math.abs(profitLoss)),
        symbol: {
          symbols: [],
          pointValue: 0,
        },
        strategy: "",
        selectedConfidence: "",
        selectedEmotion: "",
        selectedPhysical: "",
        selectedMarket: "",
        selectedTimeframe: "",
        selectedMistake: "",
        selectedPortfolio: chosenPortfolioName,
        selectedPortfolioType: "",
        endDateTime: enddate,
        orderType: orderType,
        ordernumber: "",
        dayOfWeek: weekday[enddate.getDay()],
        monthOfYear: montharr[enddate.getMonth()],
        stopLoss: "",
        takeProfit: "",
        commissions: "",
        fees: fees === "" ? 0 : fees,
        maxAdEx: "",
        maxFavEx: "",
        comments: comments,
        multiExecution: [],
        exitExecution: [],
        startDateTime: enddate,
        tags: "",
        verifiedLevel: verified,
        idLinks: [],
      },
    };
    let tempAll = allData.allData.chosenPortfolios;
    let tempAllOther = allData.allData.createdPortfolios;
    let temparr = allData.allData.createdPortfolios;
    const portName = getSpecificPortfolio(allData.allData).name;

    temparr = temparr.filter((x) => x.name !== portName);
    tempAllOther = tempAllOther.filter((x) => x.name === portName);

    let mutableTrades = tempAllOther[0].entries;
    mutableTrades = mutableTrades.concat(data);

    const portfolio = {
      name: tempAllOther[0].name,
      assetClasses: tempAllOther[0].assetClasses,
      settings: tempAllOther[0].settings,
      entries: mutableTrades,
    };
    temparr = temparr.concat(portfolio);

    const uploadData = {
      chosenPortfolios: tempAll,
      createdPortfolios: temparr,
      globalSettings: allData.allData.globalSettings,
      version: Number(allData.allData.version) + 1,
      sharedTrades: allData.allData.sharedTrades,
    };
    const S3Data = {
      data: uploadData,
      userId: user.sub,
    };
    const customerId =
      uploadData === null ? "" : uploadData.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      uploadData,
      "trades",
      user.sub,
      history
    );

    await putUserData(S3Data, history);
    if (orderType === "Withdrawal") {
      localStorage.setItem("added", "Withdrawal");
    } else {
      localStorage.setItem("added", "Deposit");
    }
    allGlobalData.updateAllData(uploadData);
    store.dispatch(setLoaderState({ loading: false }));

    history.push("/BlankPage");
    history.goBack();
  };
  const movecursor2 = {
    paddingLeft: "10px",
  };
  const featureSelect = {
    show: true,
    featureSelect: "Commissions/Fees",
    blur: false,
  };
  return (
    <InputCardsDW className="DWCard">
      <div className="DW-heading">Deposit/Withdrawal Data</div>
      <CardColumnDW className="columnDW">
        <div className="main-labels-DW order-type-style-DW">
          {orderType ? (
            <label>Type</label>
          ) : (
            <label>
              Type <em className="requiredordertypeDW">&nbsp;Required</em>
            </label>
          )}
          <div className="buy-container-DW">
            <label className="container-DW-d">
              <input
                type="radio"
                name="orderType"
                id="accountEntryTypeD"
                disabled={show100TradesCheck}
                value="Deposit"
                onChange={(e) => {
                  setDWData({ ...dwData, orderType: e.target.value });
                }}
              />
              <span className="radiocircle-DW"></span>
            </label>
            <div className="radio-label-buy-DW">
              <label>Deposit</label>
            </div>
          </div>
          <div className="sell-container-DW">
            <label className="container-DW-w">
              <input
                type="radio"
                name="orderType"
                id="accountEntryTypeW"
                value="Withdrawal"
                disabled={show100TradesCheck}
                onChange={(e) => {
                  setDWData({ ...dwData, orderType: e.target.value });
                }}
              />
              <span className="radiocircle-DW"></span>
            </label>
            <div className="radio-label-sell-DW">
              <label>Withdrawal</label>
            </div>
          </div>
        </div>
        <label className="DWinputwrapper DWinputwrapper2 addTopMargin">
          {"Date & Time Executed"}
          {<span className="mandatoryDW dateTimeErrorMsg">{""}</span>}
          {/*           <DateTime
            className="DWinputs2"
            value={endDateTime}
            onChange={(e) => {
              setDWData({ ...dwData, endDateTime: e._d });
            }}
            dateFormat="MM/DD/YYYY"
            timeFormat="hh:mm:ss"
           
            inputProps={inputProps}
          /> */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  inputProps={{
                    ...props.inputProps,
                    placeholder: "Required",
                  }}
                  className="text-input-boxes required DWinputs"
                  disabled={show100TradesCheck}
                />
              )}
              /*               PaperProps={{
                sx: {
                  backgroundColor: "#000000",
                  color: "#eeeeee",
                  "& .MuiButtonBase-root": {
                    color: "#deebf7",
                  },
                  "& .MuiPickersArrowSwitcher-button": {
                    backgroundColor: "#282d36",
                    color: "#deebf7",
                  },
                  // clocl background color
                  ".css-eziifo": {
                    backgroundColor: "#282d36",
                  },
                  // clock numbers color
                  ".css-1flhz3h": {
                    color: "#deebf7",
                  },
                  // clock selected items color
                  "& .css-7lip4c, .css-12ha4i7, .css-118whkv, .Mui-selected, .css-a1rc6s":
                    {
                      backgroundColor: "#fbd665",
                    },
                },
              }} */
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
              inputFormat="MM/DD/YYYY HH:mm:ss"
              value={endDateTime || null}
              ampm={false}
              disabled={show100TradesCheck}
              onChange={(e) => setDWData({ ...dwData, endDateTime: e._d })}
            />
          </LocalizationProvider>
        </label>
        <label className="DWinputwrapper DWinputwrapper2 addTopMargin">
          Amount
          {<span className="mandatoryDW profitLossErrorMsg">{""}</span>}
          <input
            name="profitLoss"
            style={movecursor2}
            value={profitLoss}
            disabled={show100TradesCheck}
            onChange={(e) => {
              setDWData({ ...dwData, profitLoss: e.target.value });
            }}
            className="text-input-boxes required DWinputs"
            autoComplete="off"
            pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
            required
            placeholder="Required"
          />
        </label>
        <div className="main-labels">
          <label className="ANTselectionfields ANTselectionfieldsNOTRequiredOPTION">
            Fees
            {showCheck && (
              <div
                style={{
                  position: "absolute",
                  marginTop: -32,
                  left: 28,
                }}
              >
                <ProTierComponent />
              </div>
            )}
            {<span className="mandatoryDW feesErrorMsg">{""}</span>}
            <div
              className="notrequired"
              onClick={() => {
                if (
                  active &&
                  (tier === "pro" ||
                    tier === "oldpro" ||
                    tier === "master" ||
                    tier === "ultimate" ||
                    tier === "admin")
                ) {
                } else {
                  props.setshowUpgradeModal(featureSelect);
                }
              }}
            >
              <input
                name="fees"
                style={movecursor2}
                disabled={show100TradesCheck ? show100TradesCheck : showCheck}
                value={fees}
                onChange={(e) => {
                  setDWData({ ...dwData, fees: e.target.value });
                }}
                placeholder="Optional"
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </div>
          </label>
        </div>
        <div className="DWinputwrapper33">
          <div className="DWinputwrapper33notes">Notes</div>
          <NoteComponent
            originalState={comments}
            setNoteState={(e) => {
              setDWData({ ...dwData, comments: e });
            }}
          />
        </div>
        <div>
          <input
            type="submit"
            value="Log Deposit/Withdrawal"
            className="logtradebuttonDW"
            disabled={show100TradesCheck ? show100TradesCheck : isDisabled}
            onClick={async () => await handleSubmit()}
          />
        </div>
      </CardColumnDW>
    </InputCardsDW>
  );
};

export default withRouter(DepositWithdrawalData);
