import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "./../../../functions/profitLossCalculation";
import getPointValue from "./../getFuturesPointValue";
import { montharr, weekday } from "./../../../arrays/weekandmonth";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../../../functions/getStartorEndDateTime";
import { filterByID } from "./../../../functions/filterByID";
import { csvToArrayGeneral } from "./../csvToArray";

export const aiParser = async (
  readerEvent,
  userId,
  portfolio,
  settrades,
  setinvalidFile
) => {
  var content = readerEvent.target.result; // =====> this is the content!
  let tradeArray = await csvToArrayGeneral(content); // generalized CSV to Array function
  if (!readerEvent) {
    setinvalidFile(true);
    return;
  }
  let trade = "";
  let trades = [];
  let fullTradearray = [];
  tradeArray.forEach((trade) => {
    fullTradearray.push(trade); // push trades
  });

  fullTradearray.sort(function (a, b) {
    var c = new Date(a["Time Stamp"]);
    var d = new Date(b["Time Stamp"]);
    return c - d;
  });

  const getFees = (arr, starti) => {
    let fees = 0;
    return fees;
  };

  const verified = 0; // could be 1 in the future

  for (let i = 0, j = fullTradearray.length; i < j; i++) {
    if (
      fullTradearray[i]["Contract"] === "Fund Transaction" ||
      fullTradearray[i]["Currency"] === "Fund Transaction"
    ) {
      var startDateTime = new Date(fullTradearray[i]["Timestamp"]);
      let delta = fullTradearray[i]["Delta"];
      delta = delta.replace(/["]+/g, "");
      // if the contract is a fund transaction, we have to create the amount
      // from "Amount" and "Delta" props
      if (fullTradearray[i]["Contract"] === "Fund Transaction") {
        let amount = fullTradearray[i]["Amount"];
        amount = amount.replace(/["]+/g, "");
        let totalDelta = Number(delta + amount);
        delta = totalDelta;
      } else {
      }
      trade = {
        entryId: uuidv4(),
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: portfolio.name,
          selectedPortfolioType: "",
          orderType: Number(delta) > 0 ? "Deposit" : "Withdrawal",
          orderNumber: fullTradearray[i]["Transaction ID"],
          dayOfWeek: weekday[startDateTime.getDay()],
          monthOfYear: montharr[startDateTime.getMonth()],
          stopLoss: "",
          takeProfit: "",
          profitLoss: Number(delta),
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "",
          multiExecution: [],
          exitExecution: [],
          startDateTime: startDateTime,
          endDateTime: startDateTime,
          tags: "",
          verifiedLevel: verified,
          idLinks: [],
        },
      };
      trades = trades.concat(trade);
    } else {
      // look for open trades in "trades" (not the current iteration!)
      // that matches the symbol
      let openTradesPre = [...trades];
      let openTradesPre2 = [...trades];
      let openTradesMatch = openTradesPre.filter((trade) =>
        filterByID(trade, false, fullTradearray[i]["Symbol"])
      );
      let openTradesNOTMatch = openTradesPre2.filter((trade) =>
        filterByID(trade, true, fullTradearray[i]["Symbol"])
      );
      // --------------------------

      // if the order was canceled or rejected dont do anything
      // also skip "Trade Paired" Cash entries - not needed
      if (
        fullTradearray[i]["Size"] === "0" ||
        fullTradearray[i]["execId"] === ""
      ) {
        // Don't do anything here
      } else {
        let startDateTime = new Date(fullTradearray[i]["Time Stamp"]);
        //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
        let side = fullTradearray[i]["Side"].toUpperCase(); // Convert to uppercase for case-insensitive comparison
        let orderType = side === "B" || side.includes("BUY") ? "Long" : "Short";

        //let delta = fullTradearray[i]["Delta"];

        let entryexecution = {
          id: uuidv4(),
          lotSize: Number(fullTradearray[i]["Size"]),
          entryPrice: Number(fullTradearray[i]["Price"]),
          startDateTime: startDateTime,
          expectedEntry: "",
          strikePrice: "",
          expirationDate: "",
          legType: "",
        };
        const openTrade = openTradesMatch[0];
        const opentradeOrderType = openTrade?.entry.orderType;
        if (!openTradesMatch.length) {
          trade = {
            entryId: uuidv4(),
            entry: {
              pictures: ["N/A"],
              symbol: {
                symbols: [fullTradearray[i]["Symbol"]],
                pointValue: getPointValue(
                  "rithmic",
                  fullTradearray[i]["Symbol"]
                ),
              },
              strategy: "",
              selectedConfidence: "",
              selectedEmotion: "",
              selectedPhysical: "",
              selectedMarket: "",
              selectedTimeframe: "",
              selectedMistake: "",
              selectedPortfolio: portfolio.name,
              selectedPortfolioType: fullTradearray[i]["Asset Class"],
              orderType: orderType,
              orderNumber: fullTradearray[i]["Order Number"],
              dayOfWeek: weekday[startDateTime.getDay()],
              monthOfYear: montharr[startDateTime.getMonth()],
              stopLoss: "",
              takeProfit: "",
              profitLoss: 0,
              commissions: 0,
              fees: getFees(fullTradearray, i),
              maxAdEx: "",
              maxFavEx: "",
              comments: "",
              multiExecution: [entryexecution],
              exitExecution: [],
              startDateTime: startDateTime,
              endDateTime: "",
              tags: "",
              verifiedLevel: verified,
              idLinks: [],
            },
          };
          trades.push(trade);
          openTradesPre = trades;
          openTradesPre2 = trades;
        } else {
          if (orderType !== opentradeOrderType) {
            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            var endDateTime = new Date(fullTradearray[i]["Time Stamp"]);
            let exitexecution = {
              id: uuidv4(),
              exitLotSize: Number(fullTradearray[i]["Size"]),
              exitPrice: Number(fullTradearray[i]["Price"]),
              endDateTime: endDateTime,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            };
            const openTrade = openTradesMatch[0];
            const openTradeEntry = openTrade?.entry;

            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            const opentrademultiExecution = openTradeEntry.multiExecution;
            const opentradeOrderType = openTradeEntry.orderType;
            const opentradeSymbol = openTradeEntry.symbol;
            const opentradeFees = openTradeEntry.fees;

            const opentradesType = openTradeEntry.selectedPortfolioType;
            const newExitExecution = [
              ...openTradeEntry.exitExecution,
              exitexecution,
            ];
            // calculate absolute start date time

            let startDateTimez = getStartDateTime(opentrademultiExecution);
            let endDateTimez = getEndDateTime(newExitExecution);
            const entry = Object.assign({}, openTradeEntry, {
              exitExecution: newExitExecution,
              profitLoss: Number(
                profitLossCalculation(
                  opentrademultiExecution,
                  newExitExecution,
                  opentradeOrderType,
                  opentradesType,
                  opentradeSymbol.pointValue,
                  false
                )
              ),
              endDateTime: endDateTimez,
              startDateTime: startDateTimez,
              fees: opentradeFees + getFees(fullTradearray, i),
            });
            const closedTrade = Object.assign({}, openTrade, {
              entry: entry,
            });
            openTradesNOTMatch.push(closedTrade);
            trades = openTradesNOTMatch;
            openTradesPre = openTradesNOTMatch;
            openTradesPre2 = openTradesNOTMatch;
          } else {
            // Add new opening executions
            const openTrade = openTradesMatch[0];
            const openTradeEntry = openTrade?.entry;
            const opentradeFeesin = openTradesMatch[0].entry.fees;
            const entry = Object.assign({}, openTradeEntry, {
              multiExecution: [
                ...openTradesMatch[0].entry.multiExecution,
                entryexecution,
              ],
              fees: opentradeFeesin + getFees(fullTradearray, i),
            });
            const closedTrade = Object.assign({}, openTrade, {
              entry: entry,
            });
            openTradesNOTMatch.push(closedTrade);
            trades = openTradesNOTMatch;
            openTradesPre = openTradesNOTMatch;
            openTradesPre2 = openTradesNOTMatch;
          }
        }
      }
    }
  }
  settrades(trades);
};
export default aiParser;
