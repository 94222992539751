export function getStandardDeviation(array) {
  if (!array.length) {
  } else {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b, 0) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n
    );
  }
}
export default getStandardDeviation;
