import chroma from "chroma-js";

export function generateRandomColors(
  size,
  similarityThreshold = 30,
  lightnessCutoff = 0.1
) {
  const colors = [];
  let color;

  // Define function to check if color is too similar to existing colors
  const isTooSimilar = (newColor) => {
    for (let i = 0; i < colors.length; i++) {
      const existingColor = colors[i];
      const distance = chroma.distance(newColor, existingColor);
      if (distance < similarityThreshold) {
        return true;
      }
    }
    return false;
  };

  // Generate random colors until array is full
  while (colors.length < size) {
    // Generate a random color
    color = chroma.random();

    // Check if color is too dark or too close to grey
    if (
      color.luminance() < lightnessCutoff /*  ||
        color.saturate(0.3).luminance() < 0.4 */
    ) {
      continue;
    }

    // Check if color is too similar to existing colors
    if (isTooSimilar(color)) {
      continue;
    }

    // Add color to array
    colors.push(color.hex());
  }

  return colors;
}
export default generateRandomColors;
