import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import MessageBox from "./../MessageBox";
import {
  ContentWrapper,
  StyledSideNav,
  Background,
} from "../PageTemplate/TemplateDivs.js";
import NavigationBar from "../Navigation-Bar/NavigationBar";
import "./PageTemplateStyles.scss";
import globaljquery from "../../functions/GLOBALJQUERY";
import RightPanelFunction from "./../Right-Panel/index";
import FullPageLoader from "./../FullPageLoader/FullPageLoader";
import { setLoaderState } from "./../../actions/actionCreators";
import store from "./../../store";
import FeedbackComponent from "./../../components/Feedback";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import $ from "jquery";
import AccountDropdown from "./../../components/AccountDropDown";
import PortfolioDropdown from "./../../components/PortfolioDropdown";
import { DataContext } from "../../DataContext";

import { SharedTradeContext } from "./../../SharedTradeContext";
import CustomDateRangeComponent from "./../../components/Right-Panel/Filter-Menu/customDateRangeComponent";
import CustomTimeRangeComponent from "./../../components/Right-Panel/Filter-Menu/customTimeRangeComponent";
import { Chart } from "react-chartjs-2";
import { useAuth0, Auth0Context } from "@auth0/auth0-react";
import putUserData from "./../../utils/AWS/putS3UserObject";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import { UpdateSubscriptionModalComponent } from "./../../components/UpdateSubscriptionPrompt";
import isObjectEqual from "./../../functions/isObjectEqual";
import NotificationsWrapper from "../Notifications.js";
import FilterMenuFunction from "./../FilterMenuNew";
import AlwaysLoadingLoader from "./../FullPageLoader/AlwaysLoadingLoader";
import AutoImportModal from "./../../pages/Integrations/AutoImportModal";
import Modal from "react-modal";

//import arraysEqual from "./../../functions/arrayEqual";

require("typeface-roboto");

// -----------------
const PageTemplate = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const sharedTradeInstance = useContext(SharedTradeContext);
  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);
  const [userEntries, setuserEntries] = useState([]);
  const openState = store.getState().filtersOpen.open;
  const [importState, setimportState] = useState("");
  const [selectedBroker, setselectedBroker] = useState();
  const [isweb3selected, setisweb3selected] = useState(false);
  const [importOpen, setimportOpen] = useState(false);
  const [fetchingAImessage2, setfetchingAImessage2] = useState();
  const [fetchingAImessagetext, setfetchingAImessagetext] = useState("");
  const savedMessage = localStorage.getItem("autoiterationMessage");

  const [message, setMessage] = useState(savedMessage ? savedMessage : "");
  const history = useHistory();

  const [wtfVariable, setWTFVariable] = useState(false);
  const auth0Instance = useContext(Auth0Context);

  const [filerMenuToggle, setfilerMenuToggle] = useState(openState);
  const [hasError, sethasError] = useState({ state: false, broker: "" });

  const { user /* isAuthenticated */ } = useAuth0();
  const [subscriptionModalIsOpen, setsubscriptionModalIsOpen] =
    useStateWithPromise({
      show: false,
      featureSelect: "",
      blur: false,
    });

  const userId = user.sub;
  const editMode = props.editMode;

  // Don't allow the user to do display none
  // Direct users to the career page if they try to hide the div
  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutationRecord) {
      const tempaccept = localStorage.getItem("tempacceptTerms");
      if (!tempaccept) {
        window.location.href = "https://tradefuse.app/careers/";
      }
    });
  });
  function addObserverIfDesiredNodeAvailable() {
    var target = document.getElementsByClassName("ReactModalPortal")[0];
    var target4 = document.getElementsByClassName(
      "ReactModal__Overlay ReactModal__Overlay--after-open OverlayModal"
    )[0];

    if (!target || !target4) {
      //The node we need does not exist yet.
      //Wait 500ms and try again
      window.setTimeout(addObserverIfDesiredNodeAvailable, 400);
      return;
    }
    var config = {
      childList: true,
      attributes: true,
      attributeFilter: ["style", "class"],
    };
    observer.observe(target, config);
    observer.observe(target4, config);
  }

  addObserverIfDesiredNodeAvailable();

  // SET CHART INFO

  Chart.pluginService.register({
    id: "paddingAboveLegends",
    beforeDraw: function (c) {
      var legends = c.legend.legendItems;
      legends.forEach(function (e) {
        //e.fillStyle = "#07C";
      });
    },
    beforeInit: function (chart, options) {
      if (chart?.config?.options?.metaData?.id === "positionsDoughnut") {
        chart.legend.afterFit = function () {
          this.height = this.height + 60;
        };
      }
    },
    afterDraw: function (chart, easing) {
      if (
        chart.tooltip._active &&
        chart.tooltip._active.length &&
        chart.options.tooltips.mode === "index" &&
        (chart.config.type === "line" || chart.config.type === "bar")
      ) {
        const activePoint = chart.controller.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const topY = chart.scales["y-axis-0"].top;
        const bottomY = chart.scales["y-axis-0"].bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#7689a1";
        ctx.stroke();
        ctx.restore();
      } else if (
        chart.tooltip._active &&
        chart.tooltip._active.length &&
        chart.options.tooltips.mode === "index" &&
        chart.config.type === "scatter"
      ) {
        const activePoint = chart.controller.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const y = activePoint.tooltipPosition().y;
        const topY = chart.scales["y-axis-1"].top;
        const bottomY = chart.scales["y-axis-1"].bottom;
        const leftX = chart.scales["x-axis-1"].left;
        const rightX = chart.scales["x-axis-1"].right;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.moveTo(leftX, y);
        ctx.lineTo(rightX, y);
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = "#7689a1";
        ctx.stroke();
        ctx.restore();
      }
    },
  });

  /// LOCAL STORAGE VARIABLES
  const localgraphs = JSON.parse(
    localStorage.getItem("performanceMetricGraphs")
  );
  const localdollarpercent = JSON.parse(
    localStorage.getItem("performanceMetricDollarPercent")
  );
  const localarithgeo = JSON.parse(
    localStorage.getItem("performanceMetricArithGeo")
  );
  const localDrawdownSettings = JSON.parse(
    localStorage.getItem("drawdownSetting")
  );
  const localDashboardSettings = JSON.parse(
    localStorage.getItem("dashboardSettings")
  );
  const localQuantumConversations = JSON.parse(
    localStorage.getItem("quantumConversations")
  );
  const localCalendarNotes = JSON.parse(localStorage.getItem("calendarNotes"));
  const localDashboardFrequencyToggle = JSON.parse(
    localStorage.getItem("dashboardFrequencyToggle")
  );
  const localDrawdownToggle = JSON.parse(
    localStorage.getItem("drawdownToggle")
  );
  const localstreakCalcToggle = JSON.parse(
    localStorage.getItem("streakCalcToggle")
  );
  const localEditTrade = JSON.parse(localStorage.getItem("editTrade"));
  const localEditTradeId = JSON.parse(localStorage.getItem("editTradeId"));

  const localFilters = JSON.parse(localStorage.getItem("filters"));
  const localFiltersRunning = localStorage.getItem("filtersAreRunning");

  const isDBRunning = localStorage.getItem("dbSetRunning");

  /// -------------------------

  // REDUX STATE
  const reduxFilterState = store.getState().defaultfilterValue.filtervalue;

  const allData = props.userData;
  const sharedTradesData = sharedTradeInstance.sharedTrades;
  const sharedTrades = sharedTradesData.sharedTrades;
  const title = props.title;
  const [height, setHeight] = useState([window.innerHeight]);
  const [width, setWidth] = useState([window.innerWidth]);

  const location = useLocation();

  let _isMounted = useRef(true);

  const setData = useCallback(
    async (finalPush) => {
      localStorage.setItem("dbSetRunning", "yes");
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });
      dataInstance.updateAllData(finalPush); // need to update logbook state here! VERY IMPORTANT!!
      // Once the logbook state is updated, we can remove the localstorage states
      // that pertain to dynamic setting of states/trades
      const S3Data = {
        data: finalPush,
        userId: userId,
      };
      const finalAwait = async () => {
        await putUserData(S3Data, history);
        $(window).unbind("beforeunload");
        localStorage.setItem("dbSetRunning", "no");
        localStorage.setItem("filtersAreRunning", "no");
      };
      await finalAwait();
    },
    [dataInstance, userId]
  );

  // Ellipsis useEffect
  useEffect(() => {
    /*     const element = document.querySelector(".fetchingInsightsloader3");
    let dotCount = 0;

    const ellipsisElement =
      element && element.querySelector(".fetchingInsightsloader3x");

    const ellipsisAnimationInterval = setInterval(() => {
      const ellipsis = ".".repeat((dotCount % 3) + 1);
      ellipsisElement &&
        (ellipsisElement.textContent = fetchingAImessagetext + ellipsis);
      dotCount++;
    }, 400);
    return () => {
      clearInterval(ellipsisAnimationInterval);
    }; */
  }, []);

  // useEffect for the lower right loader
  useEffect(() => {
    const element = document.querySelector("#fetchingInsightsloader3");
    if (message) {
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
    } else {
      if (element) {
        element.style.opacity = 0;
        element.style.transform = "translate(100%, 0)";
        element.style.transition = "none";
      }
    }
    return () => {};
  }, [message]);

  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (_isMounted.current === true) {
      globaljquery(history, location);
      document.getElementById("overlay").style.display = "none";
      document.getElementById("noclick").style.display = "none";

      // RUN SETJSON IN BACKGROUND IF LOCAL STORAGE VARIABLES ARE DIFFERENT THAN SKYNET DATA
      const checkdash = allData.portfolio.settings.dashboardElements;
      const checkCalNotes = allData.portfolio.settings.calendarNotes;

      const checkdrawdownCalcType =
        allData.portfolio.settings.drawdownCalculationType === "Dollar"
          ? false
          : true;
      const checkdashfreq = !allData.portfolio.settings.dashboardFrequencyToggle
        ? "week"
        : allData.portfolio.settings.dashboardFrequencyToggle;
      const checkperformanceMetricGraphs = !allData.portfolio.settings
        .performanceMetricGraphs
        ? []
        : allData.portfolio.settings.performanceMetricGraphs;
      const checkperformanceMetricCalcType =
        allData.portfolio.settings.performanceMetricCalculationType === "Dollar"
          ? false
          : true;
      const checkperformanceMetricCalculationAriGeo =
        allData.portfolio.settings.performanceMetricCalculationAriGeo ===
        "Arithmetic"
          ? false
          : true;
      const checkdrawdownToggle =
        allData.portfolio.settings.drawdownToggle === "Drawdown" ? false : true;
      const checkstreakCalcToggle =
        allData.portfolio.settings.streakCalcToggle === "Drawdown"
          ? false
          : true;
      const checkvarQuantum = !allData.portfolio.settings.quantumConversations
        ? []
        : allData.portfolio.settings.quantumConversations;
      const checkvarFilters = allData.portfolio.settings.filters;

      // check if redux filters are the same as skynet data

      // on initial load send data to skyDB if local storage is different than skynet data
      // and local storage has been changed to anything

      // SET SKYNET DATA IF THE LOCAL STORAGE VARIABLE ARE NOT NULL
      // AND ARE DIFFERENT THAN THE CURRENT SKYNET DATA

      /*             console.log(
              JSON.stringify(checkShared) !== JSON.stringify(localSharedTrades)
            ); */
      // -------- Handle change in trades --------
      let originalTrade = null;
      let temparr = allData.allData.createdPortfolios.slice();
      for (
        var ie = 0, je = allData.allData.createdPortfolios.length;
        ie < je;
        ie++
      ) {
        let currentData = allData.allData.createdPortfolios[ie].entries.slice();
        let currentDataNu =
          allData.allData.createdPortfolios[ie].entries.slice();

        let tempName = allData.allData.createdPortfolios[ie].name;
        if (tempName === "Default") {
        } else {
          // filter portfolios to only deal with the one we need
          temparr = temparr.filter((x) => x.name !== tempName);

          // remove the trade first
          let removeIndex = currentData
            .map((item) => item.entryId)
            .indexOf(localEditTradeId);
          const balance = ~removeIndex && currentData[removeIndex].balance;
          ~removeIndex && currentData.splice(removeIndex, 1);

          // create original trade
          ~removeIndex &&
            (originalTrade = currentDataNu.filter(
              (x) => x.entryId === localEditTradeId
            ));

          // add back the updated trade
          ~removeIndex &&
            currentData.splice(removeIndex, 0, {
              entryId: localEditTradeId,
              entry: localEditTrade,
              balance: balance,
              calculationType:
                allData.allData.createdPortfolios[ie].settings.calculationType,
            });
          let settingsTemp = allData.allData.createdPortfolios[ie].settings;
          const verificationValue =
            localEditTrade !== null
              ? localEditTrade.verifiedLevel
              : settingsTemp.verified;
          ~removeIndex && (settingsTemp.verified = verificationValue);

          const portfolioedit = {
            name: allData.allData.createdPortfolios[ie].name,
            assetClasses: allData.allData.createdPortfolios[ie].assetClasses,
            settings: settingsTemp,
            entries: currentData,
          };

          // then add back to array of portfolios
          temparr = temparr.concat(portfolioedit);
        }
      }
      // Props for settings
      let propsToChangei = {
        drawdownCalculationType:
          localDrawdownSettings !== null
            ? localDrawdownSettings === false
              ? "Dollar"
              : "Percent"
            : allData.portfolio.settings.drawdownCalculationType,
        drawdownToggle:
          localDrawdownToggle !== null
            ? localDrawdownToggle === false
              ? "Drawdown"
              : "Runup"
            : allData.portfolio.settings.drawdownToggle,
        streakCalcToggle:
          localstreakCalcToggle !== null
            ? localstreakCalcToggle === false
              ? "Drawdown"
              : "Runup"
            : allData.portfolio.settings.streakCalcToggle,
        performanceMetricCalculationType:
          localdollarpercent !== null
            ? localdollarpercent === false
              ? "Dollar"
              : "Percent"
            : allData.portfolio.settings.performanceMetricCalculationType,
        performanceMetricCalculationAriGeo:
          localarithgeo !== null
            ? localarithgeo === false
              ? "Arithmetic"
              : "Geometric"
            : allData.portfolio.settings.performanceMetricCalculationAriGeo,
        performanceMetricGraphs:
          localgraphs !== null
            ? localgraphs
            : allData.portfolio.settings.performanceMetricGraphs,
        quantumConversations:
          localQuantumConversations !== null
            ? localQuantumConversations
            : allData.portfolio.settings.quantumConversations,
        dashboardElements:
          localDashboardSettings !== null
            ? localDashboardSettings
            : allData.portfolio.settings.dashboardElements,
        dashboardFrequencyToggle:
          localDashboardFrequencyToggle !== null
            ? localDashboardFrequencyToggle
            : allData.portfolio.settings.dashboardFrequencyToggle,
        filters: !reduxFilterState
          ? localFilters !== null
            ? localFilters
            : allData.portfolio.settings.filters
          : reduxFilterState,
        calendarNotes:
          localCalendarNotes !== null
            ? localCalendarNotes
            : allData.portfolio.settings.calendarNotes,
      };

      let globalpropsToChange = {};

      // need to include the edited trade into createdPortfolios, check the 2nd input

      // this is a crucial line of code and could have major impact on the data
      // Basically if we are on the view trade page and have edited we need to keep that state
      const finalPortfolios =
        localEditTrade !== null
          ? temparr
          : dataInstance.allData.createdPortfolios;

      const finalPush = createDatafromSettingsProps(
        allData.allData.chosenPortfolios,
        finalPortfolios,
        propsToChangei,
        globalpropsToChange,
        allData.portfolio.settings,
        allData.allData.globalSettings,
        allData.allData.version
      );

      const entryFromDB =
        originalTrade === null ? null : originalTrade[0].entry;
      // update the session

      /*       const dashboardsAreEqual = (checkdashIn, localDashboardSettings) => {
        let returnbool = true;
        let localOrder = localDashboardSettings.order;
        let localTickerTape = localDashboardSettings.tickertape;
        let localTopMetrics = localDashboardSettings.toppinnedmetrics;
        let localMainComponents = localDashboardSettings.maingraphs;
        let localpinnedPercents = localDashboardSettings.pinnedPercentages;
        let localrecentTrades = localDashboardSettings.recentTradesTable;
        let logbookStateOrder = checkdashIn.order;
        let logbookStateTickerTape = checkdashIn.tickertape;
        let logbookStateTopMetrics = checkdashIn.toppinnedmetrics;
        let logbookStateMainComponents = checkdashIn.maingraphs;
        let logbookStatepinnedPercents = checkdashIn.pinnedPercentages;
        let logbookStaterecentTrades = checkdashIn.recentTradesTable;
        if (
          !arrayEqual(localOrder, logbookStateOrder) ||
          !arrayEqual(localpinnedPercents, logbookStatepinnedPercents) ||
          !arrayEqual(localTopMetrics, logbookStateTopMetrics)
        ) {
          returnbool = false;
        }
      }; */

      // Not sure if this is correct. May need to be moved inside if statement
      setTimeout(() => {
        localStorage.removeItem("editTrade");
        localStorage.removeItem("editTradeId");
        localStorage.removeItem("performanceMetricGraphs");
        localStorage.removeItem("drawdownSetting");
        localStorage.removeItem("drawdownToggle");
        localStorage.removeItem("performanceMetricDollarPercent");
        localStorage.removeItem("performanceMetricArithGeo");
        localStorage.removeItem("dashboardSettings");
        localStorage.removeItem("dashboardFrequencyToggle");
        localStorage.removeItem("calendarNotes");
        localStorage.removeItem("quantumConversations");
      }, 1);
      if (
        (localDashboardSettings !== null &&
          !isObjectEqual(checkdash, localDashboardSettings)) || //JSON.stringify(checkdash) != JSON.stringify(localDashboardSettings))
        (localDashboardFrequencyToggle !== null &&
          checkdashfreq !== localDashboardFrequencyToggle) ||
        (localDrawdownSettings !== null &&
          checkdrawdownCalcType !== localDrawdownSettings) ||
        (localDrawdownToggle !== null &&
          checkdrawdownToggle !== localDrawdownToggle) ||
        (localstreakCalcToggle !== null &&
          checkstreakCalcToggle !== localstreakCalcToggle) ||
        (localgraphs !== null &&
          !isObjectEqual(checkperformanceMetricGraphs, localgraphs)) || //JSON.stringify(checkperformanceMetricGraphs) !== JSON.stringify(localgraphs)) ||
        (localdollarpercent !== null &&
          checkperformanceMetricCalcType !== localdollarpercent) ||
        (localarithgeo !== null &&
          checkperformanceMetricCalculationAriGeo !== localarithgeo) ||
        (localEditTrade !== null &&
          !isObjectEqual(entryFromDB, localEditTrade)) || //JSON.stringify(entryFromDB) !== JSON.stringify(localEditTrade)) ||
        (localCalendarNotes !== null &&
          !isObjectEqual(checkCalNotes, localCalendarNotes)) ||
        (localQuantumConversations !== null &&
          !isObjectEqual(checkvarQuantum, localQuantumConversations)) || //JSON.stringify(checkdash) != JSON.stringify(localDashboardSettings))
        (allData.portfolio.settings.savefilters === true &&
          localFilters !== null &&
          !isObjectEqual(localFilters, checkvarFilters)) // JSON.stringify(localFilters) !== JSON.stringify(checkvarFilters))
      ) {
        if (isDBRunning === null) {
          console.log("error");

          // don't know if this code will ever get run but not sure
        } else if (isDBRunning === "no") {
          if (localFiltersRunning === "yes") {
            // set checks for filters fast updating
            const currentTimez = Date.now();
            setuserEntries(finalPush);
            setfinalStateTimeChanged(currentTimez);
            setcallcounter(1);
          } else {
            setData(finalPush);
          }
        } else {
          dataInstance.updateAllData(finalPush); // update app data state if skynet is running
        }
        // -----------------------
      } else {
        // IF THE CHECKS FAIL SET LOCAL STORAGE TO AWS DATA
        localStorage.setItem("filtersAreRunning", "no");
        localStorage.setItem("dbSetRunning", "no");
        if (isDBRunning === null) {
          localStorage.setItem("sharedTrades", JSON.stringify(sharedTrades));
          localStorage.setItem(
            "drawdownSetting",
            JSON.stringify(checkdrawdownCalcType)
          );
          localStorage.setItem(
            "drawdownToggle",
            JSON.stringify(checkdrawdownToggle)
          );
          localStorage.setItem(
            "streakCalcToggle",
            JSON.stringify(checkstreakCalcToggle)
          );
          localStorage.setItem("dashboardSettings", JSON.stringify(checkdash));
          localStorage.setItem(
            "dashboardFrequencyToggle",
            JSON.stringify(checkdashfreq)
          );

          localStorage.setItem(
            "performanceMetricGraphs",
            JSON.stringify(checkperformanceMetricGraphs)
          );
          localStorage.setItem(
            "quantumConversations",
            JSON.stringify(checkvarQuantum)
          );
          localStorage.setItem(
            "performanceMetricDollarPercent",
            JSON.stringify(checkperformanceMetricCalcType)
          );
          localStorage.setItem(
            "performanceMetricArithGeo",
            JSON.stringify(checkperformanceMetricCalculationAriGeo)
          );
          localStorage.setItem("calendarNotes", JSON.stringify(checkCalNotes));
        }
      }
      store.dispatch(setLoaderState({ loading: false }));
    }
    return (e) => {
      _isMounted.current = false;
    };
  }, [
    userId,
    allData,
    localDrawdownSettings,
    localarithgeo,
    localdollarpercent,
    localgraphs,
    localFilters,
    localFiltersRunning,
    setData,
    isDBRunning,
    localDrawdownToggle,
    localEditTrade,
    localEditTradeId,
    localQuantumConversations,
    localDashboardSettings,
    localCalendarNotes,
    localstreakCalcToggle,
    localDashboardFrequencyToggle,
    reduxFilterState,
    sharedTrades,
    location.pathname,
    sharedTradeInstance,
    dataInstance,
  ]);
  useEffect(() => {
    // interval for Filters!!!
    // checks every 300ms for state updates
    // If the user does not change a filter after 2.2 seconds and a few other checks, setData
    const userTimeAllowance = 2.2;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        localStorage.removeItem("filters");
        await setData(userEntries);
      } else {
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [
    allData,
    localDrawdownSettings,
    userId,
    callcounter,
    finalStateTimeChanged,
    isDBRunning,
    setData,
    userEntries,
  ]);

  //const adjustedHeight = 0.16778523489932887 * height + 3.5906040268456394;
  //const adjustedHeight = -0.0002 * height ** 2 + 0.3973 * height - 80.3076
  //const adjustedHeight = -3e-7 * height ** 3 + 0.000322 * height ** 2 + 0.137378 * height - 38.762333;
  return (
    <div className="appsupercontainer">
      <div id="overlay"></div>
      <div id="noclick"></div>
      <MessageBox
        check={allData.allData.createdPortfolios.length === 1 ? false : true}
      />
      <NotificationsWrapper />
      <UpdateSubscriptionModalComponent
        tier={tier}
        data={allData}
        active={active}
        tierText={props.upgradeShow ? props.upgradeShow.tierText : null}
        blur={props.upgradeShow ? props.upgradeShow.blur : null}
        featureToShow={
          props.upgradeShow ? props.upgradeShow.featureSelect : null
        }
        open={
          props.upgradeShow
            ? props.upgradeShow.show
            : subscriptionModalIsOpen.show
        }
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
        setshowUpgradeModal={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          props.setshowUpgradeModal && props.setshowUpgradeModal(newState);
        }}
      />
      <Background />
      <NavigationBar
        tabName={title}
        tier={tier}
        data={allData}
        active={active}
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
        setFilterMenu={(e) => {
          setfilerMenuToggle(e);
        }}
        filerMenuToggle={filerMenuToggle}
      />
      <FilterMenuFunction
        data={allData}
        setFilterMenu={(e) => {
          setfilerMenuToggle(e);
        }}
      />
      <AccountDropdown
        data={allData}
        tier={tier}
        active={active}
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
      />
      <PortfolioDropdown
        editMode={editMode}
        setEditMode={(mode) => props.seteditMode(mode)}
        data={allData}
        tier={tier}
        active={active}
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
        setshowUpgradeModal={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          props.setshowUpgradeModal && props.setshowUpgradeModal(newState);
        }}
      />{" "}
      <ContentWrapper id="main">
        <StyledSideNav className="sidebaronly" id="sidebaronly" />
        <div
          className="contentLB"
          style={{
            height:
              width < 1680 || height < 900
                ? filerMenuToggle // filters open
                  ? width < 481
                    ? `calc(100% - 152px)`
                    : `calc(100% - 164px)`
                  : width < 1680
                  ? width < 481
                    ? `calc(100% - 50px)`
                    : `calc(100% - 62px)`
                  : `calc(100% - 51px)`
                : width > 481
                ? height > 800
                  ? filerMenuToggle // filters open
                    ? height - 164
                    : height - 62
                  : height - 50
                : height - 50,
          }}
        >
          {props.page}
        </div>
        {/*         <RightPanelFunction
          tabName={title}
          data={allData}
          tier={tier}
          active={active}
          setSubscriptionModalOpen={(e) => {
            const newState = {
              ...subscriptionModalIsOpen,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
            };
            setsubscriptionModalIsOpen(newState);
          }}
        /> */}
      </ContentWrapper>
      <div className="confirmedactionwrapper">
        <div value="Submit" className="confirmedactionbutton"></div>
      </div>
      <FullPageLoader />
      <FeedbackComponent
        tier={tier}
        userId={userId}
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
      />
      <CustomDateRangeComponent
        setSubscriptionModalOpen={(e) => {
          const newState = {
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          setsubscriptionModalIsOpen(newState);
        }}
        tier={tier}
        allData={allData}
        active={active}
      />
      <CustomTimeRangeComponent
        setSubscriptionModalOpen={(e) =>
          setsubscriptionModalIsOpen({
            ...subscriptionModalIsOpen,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          })
        }
        tier={tier}
        allData={allData}
        active={active}
      />{" "}
      {/*       <span
        id={"fetchingInsightsloader3"}
        className={`${message ? "show" : ""}`}
      >
        <span
          className={
            showLoaderCheck2
              ? "finalloadtext"
              : showErrorCheck
              ? "notfinalloadtext"
              : "finalerrortext"
          }
        >
          {message}
        </span>
        {showLoaderCheck && <AlwaysLoadingLoader />}
        <span
          className="fetchingInsightsloader3x"
          onClick={() => {
            localStorage.removeItem("autoiterationMessage");
            setMessage(false);

            //setfetchingAImessage(false);
          }}
        ></span>
      </span> */}
      {/*       <Modal
        ariaHideApp={false}
        id={"IntegrationImportModal"}
        className={"IntegrationImportModal"}
        isOpen={importState === "auto" && importOpen}
        onRequestClose={closeImportModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        {importState === "auto" && (
          <AutoImportModal
            tier={tier}
            active={active}
            userData={allData}
            broker={selectedBroker}
            isweb3selected={isweb3selected}
            setShowImport={(e) => {
              setimportOpen(e);
            }}
            setShowError={(e) => {
              sethasError(e);
            }}
            setImportShow={() => {
              setimportOpen(true);
              setimportState("import");
              setisweb3selected(false);
              setselectedBroker(selectedBroker);
            }}
          />
        )}
      </Modal> */}
      {/*       {autoImportModal()} */}
    </div>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  page: PropTypes.element.isRequired,
  userData: PropTypes.object.isRequired,
};

export default PageTemplate;
