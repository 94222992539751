export const defaultTickerSymbols = [
  {
    proName: "SPXUSD",
    title: "S&P 500",
  },
  {
    proName: "NSXUSD",
    title: "US 100",
  },
  {
    proName: "EURUSD",
    title: "EUR/USD",
  },
  {
    description: "XAUUSD",
    proName: "OANDA:XAUUSD",
  },
  {
    description: "WTI CRUDE OIL",
    proName: "USOIL",
  },
  {
    proName: "BTCUSD",
    title: "Bitcoin",
  },
  {
    proName: "ETHUSD",
    title: "Ethereum",
  },
];

export default defaultTickerSymbols;
