import React, { useContext, useState } from "react";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import "./ViewTradeStyle.scss";
import ViewTradePage from "./ViewTradePage";
import { useLocation, useHistory } from "react-router-dom";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const ViewTrade = (props) => {
  const location = useLocation();
  const history = useHistory();

  const locationtrade = location.state;

  const tradeIdINIT = locationtrade && locationtrade.entryId;
  const [tradeId, setTradeId] = useState(tradeIdINIT);
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;

  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  let processedData = {};
  let trade = {};
  let showINIT2 = true;

  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Entry Details - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));

    // instead of setting to the location state,
    // take that id and find that trade in the context state
    const entries = processedData && processedData.portfolio.entries.slice();
    trade = entries.filter((entry) => entry.entryId === tradeId)[0];
    if (!trade) {
      history.push("/Dashboard");
    }
  }

  if (tier === "free") {
    showINIT2 = true;
  } else if (tier === "pro" && active) {
    showINIT2 = false;
  } else if (tier === "oldpro" && active) {
    showINIT2 = false;
  } else if (tier === "master" && active) {
    showINIT2 = false;
  } else if (tier === "ultimate" && active) {
    showINIT2 = false;
  } else if (tier === "admin" && active) {
    showINIT2 = false;
  } else {
    showINIT2 = true;
  }

  if (
    Object.keys(processedData).length === 0 ||
    !trade /* Added !trade to prevent error on change portfolio */ ||
    need2faCheck
  ) {
    return (
      <PageTemplateBlank
        title={"Entry Details"}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          title={"Entry Details"}
          upgradeShow={showUpgradeModal}
          setshowUpgradeModal={(e) => {
            setshowUpgradeModal({
              ...showUpgradeModal,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            });
          }}
          page={
            <ViewTradePage
              userData={processedData}
              trade={trade}
              showCheck={showINIT2}
              setTradeId={(id) => {
                setTradeId(id);
              }}
              setshowUpgradeModal={(e) => {
                setshowUpgradeModal({
                  ...showUpgradeModal,
                  show: e.show,
                  featureSelect: e.featureSelect,
                  blur: e.blur,
                  tierText: e.tierText,
                });
              }}
              initialTrade={locationtrade.entry}
              tier={tier}
              active={active}
            />
          }
          userData={processedData}
        />{" "}
      </div>
    );
  }
};

export default ViewTrade;
