import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { bindActionCreators } from "redux";
import * as actionCreators from "./../actions/actionCreators";
import { connect } from "react-redux";
import styled from "styled-components";
import FullPageLoader from "./FullPageLoader/FullPageLoader";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoader: (npmstrat) => actionCreators.setLoaderState(npmstrat),
    },
    dispatch
  );
};

export const BackgroundL = styled.div`
  position: fixed;
  height: 110vh;
  z-index: -10000;
  width: 110vw;
  top: 0em;
  background-color: #1a2028;
  overflow: hidden;
`;

const PrivateRoute = ({ setLoader, component, ...args }) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => {
          setLoader({ loading: true });
          return (
            <div>
              <BackgroundL />
              <FullPageLoader />
            </div>
          );
        },
      })}
      {...args}
    />
  );
};
export default connect(null, mapDispatchToProps)(PrivateRoute);
