import React /* , { useState } */ from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment/moment";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import getSession from "./../../functions/getSession";
import getEntryExitLots from "./../../functions/getEntryExitLots";

var he = require("he");

export const PnLBySessionGraph = (props) => {
  const entriespre = props.userData;
  const entries = entriespre.slice();
  //const calcs = props.userCalculations;
  const dateToggle = props.sorted;
  const symbol = props.defaultSymbol;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);

  /*   const [yaxisarray, setyaxisarray] = useState([
    { hour: 0, amount: 0 },
    { hour: 1, amount: 0 },
    { hour: 2, amount: 0 },
    { hour: 3, amount: 0 },
    { hour: 4, amount: 0 },
    { hour: 5, amount: 0 },
    { hour: 6, amount: 0 },
    { hour: 7, amount: 0 },
    { hour: 8, amount: 0 },
    { hour: 9, amount: 0 },
    { hour: 10, amount: 0 },
    { hour: 11, amount: 0 },
    { hour: 12, amount: 0 },
    { hour: 13, amount: 0 },
    { hour: 14, amount: 0 },
    { hour: 15, amount: 0 },
    { hour: 16, amount: 0 },
    { hour: 17, amount: 0 },
    { hour: 18, amount: 0 },
    { hour: 19, amount: 0 },
    { hour: 20, amount: 0 },
    { hour: 21, amount: 0 },
    { hour: 22, amount: 0 },
    { hour: 23, amount: 0 },
  ]); */
  const convertToTime = (date) => {
    let resultnew = moment(date).format("HH:mm:ss");
    return resultnew;
  };
  const convertToSeconds = (time) => {
    var a = time.split(":"); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = a[0] * 60 * 60 + a[1] * 60 + a[2];

    return seconds;
  };

  const equityvaluefunction = () => {
    let yaxisarray = [0, 0, 0, 0];
    let cumulativereturnarray = [];
    for (var i = 0, j = entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      var dateTime = new Date(
        !dateToggle
          ? entries[i].entry.startDateTime
          : entries[i].entry.endDateTime
      );
      let tradingSessionPre = getSession(dateTime);
      let tradingSession;
      if (tradingSessionPre === "London") {
        tradingSession = 0;
      } else if (tradingSessionPre === "New York") {
        tradingSession = 1;
      } else if (tradingSessionPre === "Sydney") {
        tradingSession = 2;
      } else if (tradingSessionPre === "Tokyo") {
        tradingSession = 3;
      }
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        let specificvalue = yaxisarray[tradingSession];
        let addthis = Number(correctedSUM) + Number(specificvalue);
        yaxisarray[tradingSession] = addthis;
        cumulativereturnarray = yaxisarray;
      }
    }
    return cumulativereturnarray;
  };

  const xaxisfunction = ["London", "New York", "Sydney", "Tokyo"];
  const colours = equityvaluefunction().map((value) =>
    value < 0 ? "#fa798b" : "#55f8a6"
  );

  const data = {
    labels: xaxisfunction,
    datasets: [
      {
        label: "P/L",
        fill: false,
        lineTension: 0.1,
        backgroundColor: colours,
        borderColor: colours,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colours,
        pointBackgroundColor: colours,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colours,
        pointHoverBorderColor: colours,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: equityvaluefunction(),
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: (tooltipItems) => {
          return `${tooltipItems[0].xLabel} P/L: `;
        },
        label: (tooltipItems) => {
          return supportedCryptosCheck
            ? parseFloat(tooltipItems.yLabel.toFixed(3)) + " " + symbol
            : he.decode(symbol) + parseFloat(tooltipItems.yLabel.toFixed(3));
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            beginAtZero: true,
            callback: function (value) {
              return supportedCryptosCheck
                ? value + " " + symbol
                : he.decode(symbol) + value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "P/L",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Session",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  const sortedindi = !dateToggle
    ? "Sorted By First Entry"
    : "Sorted By Last Exit";

  return (
    <div className="plbytimegraph noselect">
      <div className="dashboard-graphs-label">{`P/L By Session`}</div>
      <div className="cummulativereturn-label3">{sortedindi}</div>

      {/*       <div className="cummulativereturn-toggleindi">
        {!dateToggle ? "Sorted By First Entry" : "Sorted By Last Exit"}
      </div> */}

      <div className="cumulativereturngraphwrapper">
        <Bar
          data={data}
          options={options}
          className="cumulativereturngraphactual"
        />
      </div>
    </div>
  );
};

export default PnLBySessionGraph;
