import getOptionsStrategy from "./getOptionsStrategy";

export const longShortDelineation = (type, multiExecution) => {
  const optionsStrategy =
    type === "Multi-Leg Strategy" &&
    getOptionsStrategy(multiExecution, "", type);
  const multiDiff =
    optionsStrategy === "Call Credit Spread" ||
    optionsStrategy === "Put Debit Spread" ||
    optionsStrategy === "Call Debit Spread" ||
    optionsStrategy === "Put Credit Spread" ||
    optionsStrategy === "Iron Condor" || // Calculated as long
    optionsStrategy === "Iron Butterfly" // Calculated as long
      ? "Short"
      : "Long";
  const difference =
    type === "Long" || type === "Long Call" || type === "Long Put"
      ? "Long"
      : "Short";
  return type === "Multi-Leg Strategy" ? multiDiff : difference;
};
export default longShortDelineation;
