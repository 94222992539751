import React, { useEffect, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Navbar, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../../images/NewFuse.png";
import strikelogo from "../../images/NewFuseStrike.png";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import createSettings from "../../pages/Settings/createSettings";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  //faUsers,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import SideNav from "./Sidenav/SideNav";
import "./Dropdowns/DropdownStyles.scss";
import "./Sidenav/SidenavStyles.scss";
import "./TopNav/otherstyling.scss";
import initialSettingsState from "./../../pages/Settings/initialStates";
import blank from "./../../images/blank2.png";
import $ from "jquery";
import { Auth0Context } from "@auth0/auth0-react";
import {
  ProTierComponent,
  MasterTierComponent,
  UltimateTierComponent,
  FreeTierComponent,
} from "./../../components/tierComponents/pro";
import whiteIntegration from "./../../images/noun-connected-dataset-5356467w-min.png";
import yellowIntegration from "./../../images/noun-connected-dataset-5356467y-min.png";
import whiteIntegrationr from "./../../images/noun-connected-dataset-5356467wr-min.png";
import yellowIntegrationr from "./../../images/noun-connected-dataset-5356467yr-min.png";
import putUserData from "./../../utils/AWS/putS3UserObject";
import { DataContext } from "../../DataContext";
import {
  setLoaderState,
  resetFilteredList,
  setFiltersOpen,
} from "../../actions/actionCreators";
import store from "../../store";
import processTieredPortfolios from "./../../functions/processTieredPortfolios";
import internetIcon from "./../../images/connection.png";
import filterIcon from "./../../images/filter.png";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";

//import dydxlogo from "./../../images/dydxlogo.svg";

// ----- Top navigation bar -----
const NavigationBar = (props) => {
  const filerMenuToggleIn = props.filerMenuToggle;
  const auth0Instance = useContext(Auth0Context);
  const allGlobalData = useContext(DataContext);
  const [filerMenuToggle, setfilerMenuToggle] = useState(filerMenuToggleIn);
  const firstUpdate = useRef(true);

  const location = useLocation();
  const history = useHistory();
  const tier = props.tier;
  const active = props.active;
  function setFilterMenuStyles(height, display) {
    var element = document.querySelector(".filtermenunewwrapper");
    if (element) {
      element.style.height = height;
      element.style.display = display;
    } else {
      console.error('Element with class "filtermenunewwrapper" not found.');
    }
  }

  let tierDiv = "";
  let tierCheck = "";
  if (tier) {
    if (tier === "free") {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <FreeTierComponent />
        </div>
      );
      tierCheck = true;
    } else if (tier === "pro" && active) {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <ProTierComponent />
        </div>
      );
      tierCheck = false;
    } else if (tier === "oldpro" && active) {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <ProTierComponent />
        </div>
      );
      tierCheck = false;
    } else if (tier === "master" && active) {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <MasterTierComponent />
        </div>
      );
      tierCheck = false;
    } else if (tier === "ultimate" && active) {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <UltimateTierComponent />
        </div>
      );
      tierCheck = false;
    } else if (tier === "admin" && active) {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <MasterTierComponent />
        </div>
      );
      tierCheck = false;
    } else {
      tierDiv = (
        <div style={{ position: "relative", top: -9, left: -8 }}>
          <FreeTierComponent />
        </div>
      );
      tierCheck = true;
    }
  }
  const user = auth0Instance.user;
  const userData = props.data;
  const portfolio = userData && userData.portfolio;
  const chosenPortfolios = userData && userData.allData.chosenPortfolios;
  const createdPortfolios = userData && userData.allData.createdPortfolios;
  const allportfolios = userData && userData.allData;
  let tempChosen = chosenPortfolios?.slice();

  // hide selected the portfolios if it does not exists in the largest n
  // this is mainly logic for users who have downgraded
  if (tier === "free") {
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 1, chosenPortfolios.slice())
        .chosenPortfolios;
    // free users get 1 portfolio (plus the default)
  } else if (tier === "pro" && active) {
    // pro users get 2 portfolios (plus the default)
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 2, chosenPortfolios.slice())
        .chosenPortfolios;
  } else if (tier === "oldpro" && active) {
    // old pro users get 5 portfolios (plus the default)
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 5, chosenPortfolios.slice())
        .chosenPortfolios;
  } else if (tier === "master" && active) {
    // master users get 5 portfolios (plus the default)
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 5, chosenPortfolios.slice())
        .chosenPortfolios;
  } else if (tier === "ultimate" && active) {
    // ultimate users get 10 portfolios (plus the default)
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 10, chosenPortfolios.slice())
        .chosenPortfolios;
  } else if (tier === "admin" && active) {
    // unlimited portfolios
  } else {
    tempChosen =
      tempChosen &&
      processTieredPortfolios(createdPortfolios, 1, chosenPortfolios.slice())
        .chosenPortfolios;
  }

  let chosenPortString = ``;
  const filteredPortfolios =
    tempChosen && tempChosen.filter((e) => e !== "Default");

  filteredPortfolios &&
    filteredPortfolios.forEach(
      (port, key) =>
        (chosenPortString +=
          filteredPortfolios.length - 1 === key ? `${port}` : `${port}, `)
    );
  chosenPortString === "" && (chosenPortString = "Select...");

  const portfolioPreText =
    filteredPortfolios && filteredPortfolios.length === 1
      ? `Portfolio:`
      : chosenPortString === ""
      ? `Portfolio:`
      : `Portfolios:`;
  // ------- BROKER/PLATFORM RECOGNITION ALGORITHM -------
  const selectedBrokerINIT = portfolio && portfolio.name;
  const settings = portfolio && portfolio.settings;
  const loadDemoDataSettings = settings?.demoDataLoaded;
  let selectedBrokerSuggestion = selectedBrokerINIT;
  const lowerCaseBrokerSuggestion =
    selectedBrokerSuggestion &&
    selectedBrokerSuggestion.replace(/[^a-zA-Z ]/g, "").toLowerCase();

  //-------------
  const userAvatar = user.picture;

  useEffect(() => {
    const tempbuff =
      userData === undefined ? initialSettingsState : userData.allData;
    const fetchSettings = () => {
      const newval = tempbuff.chosenPortfolios;
      if (newval === undefined) {
        $(".portfolio-icon-notification").css({ display: "none" });
      } else {
        if (newval[0] === "" && newval.length === 1) {
          $(".portfolio-icon-notification").css({ display: "none" });
        } else {
          $(".portfolio-icon-notification").css({ display: "block" });
        }
      }
    };
    fetchSettings();
    if (firstUpdate.current) {
      if (!filerMenuToggleIn) {
        setFilterMenuStyles("0px", "none");
        setfilerMenuToggle(false);
        //props.setFilterMenu(false);
        store.dispatch(setFiltersOpen({ open: false }));
      } else {
        setFilterMenuStyles("92px", "block");
        setfilerMenuToggle(true);
        //props.setFilterMenu(true);
        store.dispatch(setFiltersOpen({ open: true }));
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [userData, filerMenuToggleIn, props]);
  /*   const showFeedback = () => {
    $("#feedback-class").fadeIn(300);
    $("#overlay").fadeIn(300);
  }; */

  // get number of filters selected

  let modallogodiv = "";
  if (lowerCaseBrokerSuggestion && lowerCaseBrokerSuggestion === "Robinhood") {
    modallogodiv = "";
  } else if (
    lowerCaseBrokerSuggestion &&
    lowerCaseBrokerSuggestion === "dydx"
  ) {
    /*     modallogodiv = (
      <img className="linklogowrapperimgmain" src={dydxlogo} alt="" />
    ); */
    modallogodiv = "";
  } else {
  }

  modallogodiv = (
    <span className="viewingportbuttontext">{chosenPortString}</span>
  );

  let numFilters = 0;
  let reduxFilterState = store.getState().defaultfilterValue.filtervalue;
  for (const filter of Object.entries(reduxFilterState)) {
    if (filter[0] === "dateRangeFilter") {
      const dateRangeFilter = reduxFilterState["dateRangeFilter"];
      if (dateRangeFilter === null) {
      } else {
        numFilters += 1;
      }
    } else if (filter[0] === "hourRangeFilter") {
      const hourRangeFilter = reduxFilterState["hourRangeFilter"];
      if (hourRangeFilter === null) {
      } else {
        numFilters += 1;
      }
    } else {
      let filterInner = filter[1];
      let filterLength = filterInner && filterInner.length;
      numFilters += Number(filterLength);
    }
  }

  const deleteTrades = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    let tempchosen = allGlobalData.allData.chosenPortfolios;
    const thisport = tempchosen.filter((name) => name !== "Default")[0]; // deleting in the navbar only occurs on one port selected
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    tempALL = tempALL.filter((x) => x.name !== thisport);
    tempALLOther = tempALLOther.filter((x) => x.name === thisport);
    let entries = tempALLOther[0].entries.slice();
    entries = entries.filter((obj) => !obj?.entry?.demoData);
    let tempsettings = tempALLOther[0].settings;
    tempsettings.demoDataLoaded = true; // since it's just one line, no need to import the function that creates the settings obj
    const propsValuesToChange = {
      demoDataLoaded: false,
    };
    const settingsDataPre = createSettings(tempsettings, propsValuesToChange);

    // Change portfolio name in current change to updated name

    const portfolio = {
      name: thisport,
      assetClasses: tempALLOther[0].assetClasses,
      entries: entries,
      settings: settingsDataPre,
    };

    tempALL = tempALL.concat(portfolio);

    const finalPush = {
      chosenPortfolios: chosenPortfolios,
      createdPortfolios: tempALL,
      globalSettings: allportfolios.globalSettings,
      version: Number(allportfolios.version) + 1,
      sharedTrades: allportfolios.sharedTrades,
    };
    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      user.sub,
      history
    );

    allGlobalData.updateAllData(finalPush); // this one needs to run on the res
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      localStorage.setItem("loadDemoDataAction", "no");
      store.dispatch(setLoaderState({ loading: false }));

      history.push("/BlankPage");
      history.goBack();
    };
    await finalAwait();
  };

  return (
    <div>
      <Navbar expand="lg">
        {/* <input type="text" placeholder="Search" className="search-bar" /> */}
        {location.pathname !== "/Error-Found" && (
          <div className="hamburger">
            <FontAwesomeIcon icon={faBars} />
          </div>
        )}
        {/* comment in production */}
        {/*         <div className="viewingporttesenvtextr">TEST ENVIRONMENT</div> */}
        {location.pathname !== "/Error-Found" && (
          <div className="viewingportwrapper">
            {loadDemoDataSettings && (
              <button
                className="deletedemodatabutton"
                onClick={() => {
                  deleteTrades();
                }}
              >
                <div style={{ marginTop: "-2px" }}>{"Delete Demo Data"}</div>
              </button>
            )}
            <span className="viewingportviewing">{portfolioPreText}</span>
            <button className="viewingportbutton">{modallogodiv}</button>
          </div>
        )}
        {/*         <div className="feedbackbuttonwrapper">
          <input
            type="submit"
            value="Feedback"
            className="feedbackbutton"
          />
        </div> */}
        {numFilters === 0 ? (
          ""
        ) : (
          <div className="portfolio-icon-notification">{numFilters}</div>
        )}
        {/*         <OverlayTrigger
          placement="bottom-end"
          delay={{ show: 400, hide: 250 }}
          overlay={<Tooltip>Filter</Tooltip>}
        >
          <button id="filter-icon" className="filter-icon">
            <FontAwesomeIcon id="filter-icon-icon" icon={faFilter} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom-end"
          delay={{ show: 400, hide: 250 }}
          overlay={<Tooltip>Share</Tooltip>}
        >
          <div className="forum-icon">
            <FontAwesomeIcon icon={faUsers} />
          </div>
        </OverlayTrigger> */}
        {location.pathname !== "/Error-Found" && (
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={<div style={{ color: "#fff7cb" }}>Integrations</div>}
          >
            <div
              className={
                location.pathname === "/Integrations"
                  ? "integrationsButtony"
                  : "integrationsButton"
              }
            >
              <div
                onClick={() => {
                  history.push("/Integrations");
                }}
              >
                <img
                  id="integrations-icon"
                  alt=""
                  src={
                    location.pathname === "/Integrations"
                      ? yellowIntegration
                      : whiteIntegration
                  }
                />
                <img
                  id="integrations-iconr"
                  alt=""
                  src={
                    location.pathname === "/Integrations"
                      ? yellowIntegrationr
                      : whiteIntegrationr
                  }
                />
              </div>
            </div>
          </PopoverStickOnHover>
        )}
        {location.pathname !== "/Error-Found" && (
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={12}
            yAdjust={0}
            component={<div style={{ color: "#fff7cb" }}>Global Filters</div>}
          >
            <button
              id="filter-icon"
              className={filerMenuToggleIn ? "filter-icon-gold" : "filter-icon"}
              onClick={() => {
                if (filerMenuToggleIn) {
                  setFilterMenuStyles("0px", "none");
                  setfilerMenuToggle(false);
                  props.setFilterMenu(false);
                  store.dispatch(setFiltersOpen({ open: false }));
                } else {
                  setFilterMenuStyles("92px", "block");
                  setfilerMenuToggle(true);
                  props.setFilterMenu(true);
                  store.dispatch(setFiltersOpen({ open: true }));
                }
              }}
            >
              <FontAwesomeIcon id="filter-icon-icon" icon={faFilter} />
            </button>
          </PopoverStickOnHover>
        )}
        {/*         <OverlayTrigger
          placement="bottom-end"
          delay={{ show: 400, hide: 250 }}
          overlay={<Tooltip>Share</Tooltip>}
        >
          <div className="forum-icon">
            <FontAwesomeIcon icon={faUsers} />
          </div>
        </OverlayTrigger> */}
        {/*  */}
        {location.pathname !== "/Error-Found" && (
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={<div style={{ color: "#fff7cb" }}>Settings</div>}
          >
            <div className="small-icons-OC2-wrapper">
              <div
                onClick={() => {
                  history.push("/Settings");
                }}
                className={
                  location.pathname === "/Settings"
                    ? "small-icons-OC2gold"
                    : "small-icons-OC2"
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </div>
            </div>
          </PopoverStickOnHover>
        )}
        {/* <div className="portfolio-icon-notification">{chosenPort}</div> */}
        {/*           <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div> */}
        <div
          className="profile-icon"
          id={
            location.pathname === "/Manage-Account"
              ? "profile-icon-gold"
              : "profile-icon"
          }
        >
          {userAvatar === "" || userAvatar === null || !userAvatar ? (
            <img
              alt=""
              src={blank}
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 100,
                zIndex: 1000000000,
              }}
            />
          ) : (
            <img
              alt=""
              src={userAvatar}
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 100,
                zIndex: 1000000000,
              }}
            />
          )}
        </div>
        <Link to="./Dashboard" className="navbar-brand">
          <Image
            src={logo}
            className="tradersIQlogo"
            style={{
              width: 39.12,
              height: 35,
              marginLeft: location.pathname === "/Error-Found" ? -64 : -8,
              marginTop: -2,
            }}
            alt="Traders IQ Home"
          ></Image>
          <Image
            src={strikelogo}
            className="tradersIQlogoStrike"
            style={{
              width: 39.12,
              height: 35,
              marginLeft: location.pathname === "/Error-Found" ? -64 : -8,
              marginTop: 0,
            }}
            alt="Traders IQ Home"
          ></Image>
        </Link>
        <div className="beta-topnav-text">{tierDiv}</div> {/* <== BETA DIV */}
        <div
          className={
            location.pathname === "/Error-Found"
              ? "navbar-tabname-err"
              : "navbar-tabname"
          }
        >
          {props.tabName}
          {tierCheck ? (
            <div
              id="promocodeelement2"
              className="upgradebuttonpositionsing"
              onClick={() =>
                history.push({
                  pathname: "/Manage-Account",
                  state: {
                    account: "none",
                    subscription: "block",
                    actions: "none",
                  },
                })
              }
            >
              <div id="promocodeelementmaintextold">Upgrade</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Navbar>
      <SideNav /* maybe a prop here for tier */ />
    </div>
  );
};
export default NavigationBar;

NavigationBar.propTypes = {
  tabName: PropTypes.string,
};
