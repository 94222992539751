import React from "react";
import { Line } from "react-chartjs-2";
import isArray from "../../functions/arrayFunctions/isArray";
import { useHistory } from "react-router-dom";

export const PercentMetricGraph = (props) => {
  const entries = props.userData;
  const calculations = props.userCalculations;
  const history = useHistory();
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const xaxisfunction = (arr) => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = arr.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };
  //const lineColor = "#ffeb94";

  const data = (props) => {
    const lineColor = "#fbd665"; //purple
    return {
      labels: xaxisfunction(
        isArray(calculations.goldenRatioArr) ? calculations.goldenRatioArr : []
      ),
      datasets: [
        {
          label: "Your Equity",
          fill: false,
          bezierCurve: true,
          backgroundColor: lineColor,
          borderColor: lineColor,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "round",
          pointBorderColor: lineColor,
          pointBackgroundColor: lineColor,
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: lineColor,
          pointHoverBorderColor: lineColor,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: calculations.goldenRatioArr,
        },
      ],
    };
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => {
          return "Golden Ratio: ";
        },
        label: (tooltipItems) => {
          return parseFloat(tooltipItems.yLabel.toFixed(3));
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return "";
            },
          },
          scaleLabel: {
            display: false,
            labelString: "Equity",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return "";
            },
          },
          scaleLabel: {
            display: false,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <Line
      data={data()}
      options={options}
      className="ovallreportgraphactual"
      onElementsClick={(elems) => {
        /*                 console.log(elems);
    if (!elems.length) {
    } else {
      toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
    }
    // if required to build the URL, you can
    // get datasetIndex and value index from an `elem`:
    //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
    // and then redirect to the target page:
    //window.location = "https://example.com"; */
      }}
    />
  );
};
export default PercentMetricGraph;
