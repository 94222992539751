import React from "react";
import PropTypes from "prop-types";

const MessageDropdown = () => {
  return (
    <div className="tooltip-bg">
      <div className="tip"></div>
      <div className="messagebox-text">{"Coming soon..."}</div>
    </div>
  );
};

MessageDropdown.defaultProps = {
  userId: "",
};

MessageDropdown.propTypes = {
  userId: PropTypes.string,
};

export default MessageDropdown;
