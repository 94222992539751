import React, { useContext, useEffect, useState, useRef } from "react";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import TotalPerformanceMetrics from "./TotalMetricsComponents";
import AveragePerformanceMetrics from "./AverageMetricsComponent";
import MiscPerformanceMetrics from "./MiscellaneousMetricComponent";
import OverallReportGraph from "./OverallReportGraph";
import { useAuth0 } from "@auth0/auth0-react";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

export const ComprehensiveComponent = (props) => {
  const firstUpdate = useRef(true);
  const { user /* isAuthenticated  */ } = useAuth0();
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const portfolio = allData && allData.portfolio;
  const settings = portfolio && portfolio.settings;
  const [width, setWidth] = useState([window.innerWidth]);
  const [comprehensiveWidth, setcomprehensiveWidth] = useState(0);
  const [height, setHeight] = useState([window.innerHeight]);
  const showUpgradeModalProp = props.showUpgradeModal.current;

  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: showUpgradeModalProp.show,
    featureSelect: showUpgradeModalProp.featureSelect,
    blur: showUpgradeModalProp.blur,
  });
  
  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });

    if (firstUpdate.current) {
      const overallWrapper = document.getElementById("overallreportwrapper");
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.attributeName === "style" ||
            mutation.attributeName === "max-width" ||
            mutation.attributeName === "min-width"
          ) {
            const width = overallWrapper.offsetWidth;
            setcomprehensiveWidth(width);
          }
        });
      });
      if (overallWrapper) {
        observer.observe(overallWrapper, { attributes: true });
      } else {
        console.log("overall report wrapper not found");
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
    };
  }, [setcomprehensiveWidth]);
  return (
    <div className="appvicegodwrapper">
      <PageTemplate
        title={props.tabName}
        upgradeShow={showUpgradeModal}
        setshowUpgradeModal={(e) => {
          setshowUpgradeModal({
            ...showUpgradeModal,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          });
        }}
        page={
          width <= 1182/*  || comprehensiveWidth <= 1312  */? (
            <div className="overallreportwrapper" id="overallreportwrapper">
              <div className="firstoverallreportwrapper">
                <TotalPerformanceMetrics
                  tier={tier}
                  active={active}
                  specificPortfolio={allData.portfolio}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
                <AveragePerformanceMetrics
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
              </div>
              <div className="secondoverallreportwrapper">
                <OverallReportGraph
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                  calculationType={allData.portfolio.settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  startingBalanceSetting={
                    allData.calculations.start_Balance_setting
                  }
                />
                <MiscPerformanceMetrics
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
              </div>
            </div>
          ) : (
            <div className="overallreportwrapper" id="overallreportwrapper">
              <div className="secondoverallreportwrapper">
                <OverallReportGraph
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                  calculationType={allData.portfolio.settings.calculationType}
                  startingBalance={allData.calculations.start_Balance}
                  startingBalanceSetting={
                    allData.calculations.start_Balance_setting
                  }
                />

                <TotalPerformanceMetrics
                  tier={tier}
                  active={active}
                  specificPortfolio={allData.portfolio}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
              </div>
              <div className="firstoverallreportwrapper">
                <MiscPerformanceMetrics
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
                <AveragePerformanceMetrics
                  userData={allData.portfolio.entries}
                  userCalculations={allData.calculations}
                  defaultSymbol={String(
                    allData.portfolio.settings.currencySymbol
                  )}
                  userPortfolioType={
                    allData.portfolio.assetClasses.length > 1
                      ? []
                      : allData.portfolio.assetClasses
                  }
                />
              </div>
            </div>
          )
        }
        userData={allData}
      />
    </div>
  );
};
export default ComprehensiveComponent;
