import defaultTickerSymbols from "./../../components/TickerTape/defaultTickerSymbols";
export const initialGlobalSettingState = (stripeId) => {
  return {
    optInForRewards: false,
    stripeId: stripeId,
    acceptedTerms: false,
    integrationsInfo: {},
    quantumCounter: 0,
    subscription_end: "",
    username: "",
  };
};
export const initialSettingsState = {
  currencySymbol: "&#36;",
  colorTheme: "dark",
  calculationType: "Gross",
  performanceMetricCalculationType: "Dollar",
  drawdownCalculationType: "Dollar",
  drawdownToggle: "Drawdown",
  streakCalcToggle: "Drawdown",
  performanceMetricCalculationAriGeo: "Arithmetic",
  performanceMetricGraphs: [],
  linkedBrokerInfo: { broker: "" },
  dateFilterBy: "Trade Open",
  quantumConversations: [],
  startingBalance: 0,
  portfolioColor: "",
  comparisonObjs: [],
  verified: 0,
  verifiedDate: "",
  calendarNotes: {},
  comparerKPIbundles: [],
  demoDataLoaded: false,
  dashboardFrequencyToggle: "week",
  dashboardElements: {
    order: [
      "tickertape",
      "toppinnedmetrics",
      "maingraphs",
      "pinnedPercentages",
    ],
    tickertape: {
      display: true,
      symbols: defaultTickerSymbols,
      postChange: true,
    },
    toppinnedmetrics: [
      "avgholdingtime",
      "pl24hr",
      "tfindex",
      "avgRRR",
      "avgPLpertrade",
    ],
    maingraphs: [
      {
        sorted: true,
        name: "pnlhistorygraph",
      },
      {
        sorted: true,
        name: "cummulativereturn",
      },
      {
        sorted: true,
        name: "pnlbytimegraph",
      },
      {
        sorted: true,
        name: "pnlbydayofweekgraph",
      },
      {
        sorted: true,
        name: "pnlbymonthgraph",
      },
      {
        sorted: true,
        name: "pnlbysessiongraph",
      },
      {
        sorted: true,
        name: "pnlbystrategygraph",
      },
      {
        sorted: true,
        name: "pnlbymistakegraph",
      },
      {
        sorted: true,
        name: "pnlbysymbolgraph",
      },
      {
        sorted: true,
        name: "pnlbyassetgraph",
      },
      {
        sorted: true,
        name: "pnlbyemotionsgraph",
      },
      {
        sorted: true,
        name: "pnlbyphysicalstategraph",
      },
      {
        sorted: true,
        name: "pnlbytimeframegraph",
      },
      {
        sorted: true,
        name: "pnlbymarketstategraph",
      },
      {
        sorted: true,
        name: "pnlbyConfidencegraph",
      },
      {
        sorted: true,
        name: "pnlbytagsgraph",
      },
    ],
    pinnedPercentages: [
      "maxdrawdownpercent",
      "averageefficiency",
      "winlossesbe",
      "averagerisk",
      "percenttimeinmarket",
    ],
  },
  filters: {
    dateRangeFilter: null,
    hourRangeFilter: null,
    orderTypeFilter: null,
    symbolFilter: null,
    strategyFilter: null,
    emotionalStateFilter: null,
    physicalStateFilter: null,
    mistakeFilter: null,
    timeFrameFilter: null,
    confidenceFilter: null,
    marketStateFilter: null,
    dayOfWeekFilter: null,
    monthFilter: null,
    assetClassFilter: null,
    tagsFilter: null,
    yearFilter: null,
  },
  savefilters: false,
  sidepanelprops: [{ width: 0, state: "Shared Trades" }],
  historyColumnDisplay: {
    dateTimeOpened: true,
    dateTimeClosed: true,
    holdingTime: false,
    profitLoss: true,
    netPLdollar: false,
    netPLpercent: false,
    orderType: true,
    symbol: true,
    executions: true,
    lotSize: true,
    entryPrice: true,
    expectedEntry: false,
    stopLoss: true,
    takeProfit: true,
    exitPrice: true,
    expectedExit: false,
    slippage: false,
    maxAdEx: false,
    maxFavEx: false,
    commissions: false,
    fees: false,
    riskpercent: false,
    efficiency: false,
    rewardriskratio: false,
    rmultiple: false,
    strategy: true,
    selectedConfidence: false,
    selectedEmotion: false,
    selectedPhysical: false,
    selectedMarket: false,
    selectedTimeFrame: false,
    selectedMistake: true,
    selectedTags: true,
  },
};
export const initialSettingsStateNew = (stripeId) => {
  return {
    version: 1,
    chosenPortfolios: ["Default"],
    createdPortfolios: [
      {
        name: "Default",
        assetClasses: ["Stocks"],
        entries: [],
        settings: {
          currencySymbol: "&#36;",
          colorTheme: "dark",
          calculationType: "Gross",
          performanceMetricCalculationType: "Dollar",
          dashboardElements: {
            order: [
              "tickertape",
              "toppinnedmetrics",
              "maingraphs",
              "pinnedPercentages",
            ],
            tickertape: {
              display: true,
              postChange: true,
              symbols: defaultTickerSymbols,
            },
            toppinnedmetrics: [
              "avgholdingtime",
              "pl24hr",
              "tfindex",
              "avgRRR",
              "avgPLpertrade",
            ],
            maingraphs: [
              {
                sorted: true,
                name: "pnlhistorygraph",
              },
              {
                sorted: true,
                name: "cummulativereturn",
              },
              {
                sorted: true,
                name: "pnlbytimegraph",
              },
              {
                sorted: true,
                name: "pnlbydayofweekgraph",
              },
              {
                sorted: true,
                name: "pnlbymonthgraph",
              },
              {
                sorted: true,
                name: "pnlbysessiongraph",
              },
              {
                sorted: true,
                name: "pnlbystrategygraph",
              },
              {
                sorted: true,
                name: "pnlbymistakegraph",
              },
              {
                sorted: true,
                name: "pnlbysymbolgraph",
              },
              {
                sorted: true,
                name: "pnlbyassetgraph",
              },
              {
                sorted: true,
                name: "pnlbyemotionsgraph",
              },
              {
                sorted: true,
                name: "pnlbyphysicalstategraph",
              },
              {
                sorted: true,
                name: "pnlbytimeframegraph",
              },
              {
                sorted: true,
                name: "pnlbymarketstategraph",
              },
              {
                sorted: true,
                name: "pnlbyConfidencegraph",
              },
              {
                sorted: true,
                name: "pnlbytagsgraph",
              },
            ],
            pinnedPercentages: [
              "maxdrawdownpercent",
              "averageefficiency",
              "winlossesbe",
              "averagerisk",
              "percenttimeinmarket",
            ],
          },
          drawdownCalculationType: "Dollar",
          dashboardFrequencyToggle: "week",
          drawdownToggle: "Drawdown",
          streakCalcToggle: "Drawdown",
          performanceMetricCalculationAriGeo: "Arithmetic",
          performanceMetricGraphs: [],
          linkedBrokerInfo: { broker: "" },
          dateFilterBy: "Trade Open",
          startingBalance: 0,
          comparisonObjs: [],
          comparerKPIbundles: [],
          verified: 0,
          verifiedDate: "",
          portfolioColor: "",
          quantumConversations: [],
          calendarNotes: {},
          demoDataLoaded: false,
          integrationsInfo: {},
          sidepanelprops: [{ width: 0, state: "Shared Trades" }],
          filters: {
            dateRangeFilter: null,
            hourRangeFilter: null,
            orderTypeFilter: null,
            symbolFilter: null,
            strategyFilter: null,
            emotionalStateFilter: null,
            physicalStateFilter: null,
            mistakeFilter: null,
            timeFrameFilter: null,
            confidenceFilter: null,
            marketStateFilter: null,
            dayOfWeekFilter: null,
            monthFilter: null,
            assetClassFilter: null,
            tagsFilter: null,
            yearFilter: null,
          },
          savefilters: false,
          historyColumnDisplay: {
            dateTimeOpened: true,
            dateTimeClosed: true,
            holdingTime: false,
            profitLoss: true,
            netPLdollar: false,
            netPLpercent: false,
            orderType: true,
            symbol: true,
            executions: true,
            lotSize: true,
            entryPrice: true,
            expectedEntry: false,
            stopLoss: true,
            takeProfit: true,
            exitPrice: true,
            expectedExit: false,
            slippage: false,
            maxAdEx: false,
            maxFavEx: false,
            commissions: false,
            fees: false,
            riskpercent: false,
            efficiency: false,
            rewardriskratio: false,
            rmultiple: false,
            strategy: true,
            selectedConfidence: false,
            selectedEmotion: false,
            selectedPhysical: false,
            selectedMarket: false,
            selectedTimeFrame: false,
            selectedMistake: true,
            selectedTags: true,
          },
        },
      },
    ],
    globalSettings: {
      optInForRewards: false,
      stripeId: stripeId,
      cryptodotcomId: "",
      username: "",
      integrationsInfo: {},
      acceptedTerms: false,
      quantumCounter: 0,
      subscription_end: "",
    },
    sharedTrades: [],
  };
};
export const selectallColumns = {
  historyColumnDisplay: {
    dateTimeOpened: true,
    dateTimeClosed: true,
    holdingTime: true,
    profitLoss: true,
    netPLdollar: true,
    netPLpercent: true,
    orderType: true,
    symbol: true,
    executions: true,
    lotSize: true,
    entryPrice: true,
    expectedEntry: true,
    stopLoss: true,
    takeProfit: true,
    exitPrice: true,
    expectedExit: true,
    slippage: true,
    maxAdEx: true,
    maxFavEx: true,
    commissions: true,
    fees: true,
    riskpercent: true,
    efficiency: true,
    rewardriskratio: true,
    rmultiple: true,
    strategy: true,
    selectedConfidence: true,
    selectedEmotion: true,
    selectedPhysical: true,
    selectedMarket: true,
    selectedTimeFrame: true,
    selectedMistake: true,
    selectedTags: true,
  },
};
export const selectallDashColumns = {
  dashboardColumnDisplay: {
    dateTimeOpened: true,
    dateTimeClosed: true,
    holdingTime: true,
    profitLoss: true,
    netPLdollar: true,
    netPLpercent: true,
    orderType: true,
    symbol: true,
    executions: true,
    lotSize: true,
    entryPrice: true,
    expectedEntry: true,
    stopLoss: true,
    takeProfit: true,
    exitPrice: true,
    expectedExit: true,
    slippage: true,
    maxAdEx: true,
    maxFavEx: true,
    commissions: true,
    fees: true,
    riskpercent: true,
    efficiency: true,
    rewardriskratio: true,
    rmultiple: true,
    strategy: true,
    selectedConfidence: true,
    selectedEmotion: true,
    selectedPhysical: true,
    selectedMarket: true,
    selectedTimeFrame: true,
    selectedMistake: true,
    selectedTags: true,
  },
};
export default initialSettingsState;
