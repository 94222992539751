export const optimalF = (arr) => {
  let optimalF = 0;
  let wnew = 0;
  let maxLoss = 0;
  let highW = 0;
  const percentGainArray = arr;
  const N = arr.length;
  for (let i = 0, j = N; i < j; i++) {
    if ((percentGainArray[i]/100) < maxLoss) {
      maxLoss = (percentGainArray[i]/100);
    }
  }
  for (let fo = 0, jf = 1000; fo < jf; fo++) {
    let f = fo/1000;
    let w = 1;
    for (let jj = 0, jjf = N; jj < jjf; jj++) {
      wnew = w * (1 - ((f * (percentGainArray[jj]/100))/maxLoss));
      w = wnew;
    }
    if (wnew > highW) {
      highW = wnew;
      optimalF = f;
    } else {
      break
    }
  }
  return optimalF;
}
export default optimalF