function getFirstNumber(arr) {
  if (!Array.isArray(arr) || !arr.length) {
    return null;
  }
  for (let i = 0; i < arr.length; i++) {
    if (!arr[i] || !arr[i] === "" || !arr[i] === undefined || !arr[i] === NaN) {
    } else {
      return Number(arr[i]);
    }
  }
  return null;
}
export const calculatePercentChange = (arr) => {
  if (!Array.isArray(arr) || !arr.length) {
    return 0;
  }
  const lastItem = Number(arr[arr.length - 1]);
  const firstItem = getFirstNumber(arr);

  const percentChange =
    lastItem === 0 || !lastItem
      ? 0
      : (lastItem - firstItem) / Math.abs(firstItem);
  return (percentChange * 100).toFixed(2);
};
export default calculatePercentChange;
