import React from "react";
import mt5IMG1 from "../../../images/MT5/First.PNG";
import mt5IMG2 from "../../../images/MT5/Second.png";
import mt5IMG3 from "../../../images/MT5/Third.PNG";
export const Mt5Instructions = (props) => {
  const entries = props.entries;
  return (
    <div className="showinstructionswrapper">
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 1</div>
        <div className="instructsteptext">{`In your MT5 platform, open the Toolbox if it isn't already by pressing Ctrl + T or in the top left, hover over "View" and click "Toolbox".`}</div>
        <img className="instructstepimage" src={mt5IMG1} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 2</div>
        <div className="instructsteptext">{`Near the bottom left of the toolbox, click on "History"`}</div>
        <img className="instructstepimage" src={mt5IMG2} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 3</div>
        <div className="instructsteptext">{`Right click on any entry, hover over "Report", then click on "HTML (Internet Explorer)". Save the file in a location you can remember easily.`}</div>
        <img className="instructstepimage" src={mt5IMG3} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 4</div>
        <div className="instructsteptext">{`In our app, click the "Choose .html File" button and select the file you saved from Step 3.`}</div>
      </div>
      {!entries.length ? (
        <div className="instructstepwrapper">
          <div className="instructstepstep">Step 5</div>
          <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
        </div>
      ) : (
        <div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 5</div>
            <div className="instructsteptext">{`Choose your Import Method. We recommend the "Smart Import" choice.`}</div>
          </div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 6</div>
            <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Mt5Instructions;
