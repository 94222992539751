import React from "react";
import tradovateIMG1 from "../../../images/Tradovate/First2.png";
import tradovateIMG2 from "../../../images/Tradovate/Second.png";
import tradovateIMG3 from "../../../images/Tradovate/Third.png";
import tradovateIMG4 from "../../../images/Tradovate/Fourth.png";
import tradovateIMG5 from "../../../images/Tradovate/Fifth.png";
export const TradovateInstructions = (props) => {
  const entries = props.entries;
  return (
    <div className="showinstructionswrapper">
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 1</div>
        <div className="instructsteptext">{`In your Tradovate platform, click on the "Reports" button. In the default app configuration, it's located in the right navigation bar.`}</div>
        <img className="instructstepimage" src={tradovateIMG1} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 2</div>
        <div className="instructsteptext">{`Click on the "Orders" tab located in the top navigation bar.`}</div>
        <img className="instructstepimage" src={tradovateIMG2} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 3</div>
        <div className="instructsteptext">{`Add any filters you would like from the filter bar.`}</div>
        <img className="instructstepimage" src={tradovateIMG3} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 4</div>
        <div className="instructsteptext">{`Click on the "Download CSV" button. Save the file in a location you can remember easily.`}</div>
        <img className="instructstepimage" src={tradovateIMG4} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 5</div>
        <div className="instructsteptext">{`In our app, click the "Choose Orders .csv File" button and select the file you saved from Step 4.`}</div>
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 6</div>
        <div className="instructsteptext">{`In your Tradovate platform, with the "Reports" tab from Step 1 still open, click on the "Cash History" tab located in the top navigation bar.`}</div>
        <img className="instructstepimage" src={tradovateIMG5} alt="0" />
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 7</div>
        <div className="instructsteptext">{`Again, add any filters you would like from the filter bar.`}</div>
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 8</div>
        <div className="instructsteptext">{`Click on the "Download CSV" button. Save the file in a location you can remember easily.`}</div>
      </div>
      <div className="instructstepwrapper">
        <div className="instructstepstep">Step 9</div>
        <div className="instructsteptext">{`In our app, click the "Choose Cash History .csv File" button and select the file you saved from Step 8.`}</div>
      </div>
      {!entries.length ? (
        <div className="instructstepwrapper">
          <div className="instructstepstep">Step 10</div>
          <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
        </div>
      ) : (
        <div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 10</div>
            <div className="instructsteptext">{`Choose your Import Method. We recommend the "Smart Import" choice.`}</div>
          </div>
          <div className="instructstepwrapper">
            <div className="instructstepstep">Step 11</div>
            <div className="instructsteptext">{`Click the "Import Trades" button to import the trades.`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TradovateInstructions;
