import $ from 'jquery'

export const searchbarfunction = (globalvar) => {
  $(".search-bar").click(function () {
    $(".account-dropdown").hide();
    $(".tooltip-bg").hide();
  });

  if (globalvar.searchbufferval === 0) {
    $(".search-bar").css({
      width: 37,
      "font-size": 0,
      transition: "none",
    });
    $(".search-icon").css({ color: "#999999" });
  } else if (globalvar.searchbufferval === 1) {
    $(".search-bar").css({
      width: 500,
      "font-size": "1em",
      transition: "none",
    });
    $(".search-icon").css({ color: "#deebf7" });
  }

  $(".search-icon").hover(
    function () {
      if (globalvar.searchbufferval === 1) {
        return false;
      } else if (globalvar.searchbufferval === 0) {
        $(".search-icon").css({ color: "#deebf7" });
      }
    },
    function () {
      if (globalvar.searchbufferval === 0) {
        $(".search-icon").css({ color: "#999999" });
      } else if (globalvar.searchbufferval === 1) {
        $(".search-icon").css({ color: "#deebf7" });
      }
    }
  );

  $(".search-icon").click(function () {
    let searchICONbuffer = $(window).width() - 644;
    if (globalvar.searchbufferval === 1) {
      $(".search-bar").css({
        width: 37,
        "font-size": 0,
        transition: "width 0.25s ease-in-out",
      });
      $(".search-bar").blur();
      $(".account-dropdown").hide();
      $(".tooltip-bg").hide();
      globalvar.searchbufferval = 0;
    } else if (globalvar.searchbufferval === 0) {
      if (searchICONbuffer > 500) {
        searchICONbuffer = 500;
      }
      if (searchICONbuffer < 37) {
        searchICONbuffer = 37;
      }
      $(".search-bar").css({
        width: searchICONbuffer,
        "font-size": "1em",
        transition: "width 0.25s ease-in-out",
      });
      $(".search-icon").css({ color: "#deebf7" });
      $(".search-bar").focus();
      $(".account-dropdown").hide();
      $(".tooltip-bg").hide();
      globalvar.searchbufferval = 1;
    }
  });
};

export const searchbarmin481 = (globalvar) => {
  if (globalvar.sidebuffervalue === 1) {
    $(".search-bar").css({
      display: "flex",
    });
    $(".search-icon").css({
      display: "flex",
      top: 23,
    });
  } else {
    $(".search-bar").css({
      display: "flex",
    });
    $(".search-icon").css({
      display: "flex",
      top: 23,
    });
  }
}
export const searchbarmax481 = () => {
  $(".search-icon").css({
    display: "none",
    top: 19,
  });
  $(".search-bar").css({
    display: "none",
  });
}

export default searchbarfunction