import React from "react";
import { columnIds } from "./arrays";
import $ from "jquery";
import {
  deleteISVisible,
  deleteNOTVisible,
  isCheckedFunction,
} from "./deleteTradesFunctions";

let he = require("he");

export const preCheckColumns = (checkbuffer, tier, active) => {
  const isChooseColCheckednu = document.querySelectorAll(
    "#boxChooseColumnsChecks"
  );
  for (var i = 0, j = columnIds.length; i < j; i++) {
    const boxname = isChooseColCheckednu[i].name;
    if (checkbuffer.historyColumnDisplay[columnIds[i]] === true) {
      // if the user is a free tier, falsify the pro columns
      if (tier === "free") {
        if (
          boxname === "netpldollarcheckbox" ||
          boxname === "netplpercentcheckbox" ||
          boxname === "commissionscheckbox" ||
          boxname === "feescheckbox" ||
          boxname === "efficiencybarcheckbox" ||
          boxname === "riskpercentcheckbox" ||
          boxname === "rewardriskcheckbox" ||
          boxname === "rmultiplecheckbox" ||
          boxname === "maxadexcheckbox" ||
          boxname === "maxfavexcheckbox" ||
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
          isChooseColCheckednu[i].checked = false;
        } else {
          isChooseColCheckednu[i].checked = true;
        }
      } else if (tier === "pro" && active) {
        if (
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
          isChooseColCheckednu[i].checked = false;
        } else {
          isChooseColCheckednu[i].checked = true;
        }
      } else if (tier === "oldpro" && active) {
        if (
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
          isChooseColCheckednu[i].checked = false;
        } else {
          isChooseColCheckednu[i].checked = true;
        }
      } else if (tier === "admin" && active) {
        isChooseColCheckednu[i].checked = true;
      } else if (tier === "master" && active) {
        isChooseColCheckednu[i].checked = true;
      } else if (tier === "ultimate" && active) {
        isChooseColCheckednu[i].checked = true;
      } else {
        if (
          boxname === "netpldollarcheckbox" ||
          boxname === "netplpercentcheckbox" ||
          boxname === "commissionscheckbox" ||
          boxname === "feescheckbox" ||
          boxname === "efficiencybarcheckbox" ||
          boxname === "riskpercentcheckbox" ||
          boxname === "rewardriskcheckbox" ||
          boxname === "rmultiplecheckbox" ||
          boxname === "maxadexcheckbox" ||
          boxname === "maxfavexcheckbox" ||
          boxname === "slippagecheckbox" ||
          boxname === "expectedentrycheckbox" ||
          boxname === "expectedexitcheckbox"
        ) {
          isChooseColCheckednu[i].checked = false;
        } else {
          isChooseColCheckednu[i].checked = true;
        }
      }
    } else {
      isChooseColCheckednu[i].checked = false;
    }
  }
};
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const rowdatanelPLFunction = (oisef, ifu) => {
  if (Number(oisef) > 0) {
    return (
      <span className="orderOutcome-Win">
        {he.decode(String(ifu)) +
          numberWithCommas(parseFloat(oisef).toFixed(2))}
      </span>
    );
  } else if (Number(oisef) < 0) {
    return (
      <span className="orderOutcome-Loss">
        {he.decode(String(ifu)) +
          numberWithCommas(parseFloat(oisef).toFixed(2))}
      </span>
    );
  } else if (Number(oisef) === 0) {
    return (
      <span className="orderOutcome-BE">
        {he.decode(String(ifu)) +
          numberWithCommas(parseFloat(oisef).toFixed(2))}
      </span>
    );
  } else {
    return "";
  }
};
export const hideTableColumns = (i) => {
  $(`.history-table > table > thead > tr > th:nth-child(${i + 3})`).css({
    display: "none",
  });
  $(`.history-table > table > tbody > tr > td:nth-child(${i + 3})`).css({
    display: "none",
  });
};
export const showTableColumns = (i) => {
  $(`.history-table > table > thead > tr > th:nth-child(${i + 3})`).css({
    display: "table-cell",
  });
  $(`.history-table > table > tbody > tr > td:nth-child(${i + 3})`).css({
    display: "table-cell",
  });
};
export const handleColumnChoosing = (a) => {
  $("#choose-columns-class").fadeIn(300);
  document.getElementById("choose-columns-class").style.display = "flex";
  $("#overlay").fadeIn(300);
};
export const handleDeleteTrades = () => {
  $("#delete-trades-class").fadeIn(300);
  document.getElementById("delete-trades-class").style.display = "flex";
  $("#overlay").fadeIn(300);
};
export const handleTagTrades = () => {
  $("#tag-trades-class").fadeIn(300);
  document.getElementById("tag-trades-class").style.display = "flex";
  $("#overlay").fadeIn(300);
};
export const handleHeaderCheckBoxClick = () => {
  const isDeleteChecked = document.getElementById("deleteTradeButton");
  const isTagChecked = document.getElementById("tagTradeButton");
  const isShareChecked = document.getElementById("shareTradeButton");
  const isAccessorChecked = document.querySelectorAll("#boxTradeChecks");

  let rows = $(`.history-table > table > tbody > tr`);
  // Save the original background colors if they're not already saved
  rows.each(function () {
    if (!$(this).attr("data-original-bg")) {
      $(this).attr("data-original-bg", $(this).css("backgroundColor"));
    }
  });

  if (document.getElementById("boxHeaderChecks").checked === true) {
    rows.css({
      "box-shadow": "0px 5px 8px 3px #556171",
      backgroundColor: "#1a2028",
    });
    $(`.history-table > table > tbody > tr:last-child`).css({
      "box-shadow": "0px 5px 8px -2px #556171",
      backgroundColor: "#1a2028",
    });
  } else {
    rows.each(function () {
      let originalBg = $(this).attr("data-original-bg");
      $(this).css({
        "box-shadow": "",
        backgroundColor: originalBg || "", // If originalBg is null, default to ""
      });
    });
  }
  if (isDeleteChecked.style.visibility === "visible") {
    deleteNOTVisible(isDeleteChecked, isTagChecked, isShareChecked);
  } else {
    deleteISVisible(isDeleteChecked, isTagChecked, isShareChecked);
  }
  if (document.getElementById("boxHeaderChecks").checked === true) {
    if (isDeleteChecked.style.visibility === "visible") {
      isCheckedFunction(true, isAccessorChecked);
    } else {
      isCheckedFunction(true, isAccessorChecked);
      deleteISVisible(isDeleteChecked, isTagChecked, isShareChecked);
    }
  } else {
    if (isDeleteChecked.style.visibility === "visible") {
      isCheckedFunction(true, isAccessorChecked);
    } else {
      isCheckedFunction(false, isAccessorChecked);
    }
  }
};

export const handleAccessorCheckBoxClick = (e) => {
  const isDeleteChecked = document.getElementById("deleteTradeButton");
  const isTagChecked = document.getElementById("tagTradeButton");
  const isShareChecked = document.getElementById("shareTradeButton");
  const isAccessorChecked = document.querySelectorAll("#boxTradeChecks");
  var checkedCount = 0;

  for (var ii = 0, jj = isAccessorChecked.length; ii < jj; ii++) {
    let row = $(`.history-table > table > tbody > tr:nth-child(${ii + 1})`);
    // Save the original background color if it's not already saved
    if (!row.attr("data-original-bg")) {
      row.attr("data-original-bg", row.css("backgroundColor"));
    }
    if (isAccessorChecked[ii].checked === true) {
      checkedCount++;
      row.css({
        "box-shadow":
          checkedCount === 1 ||
          isAccessorChecked[ii].checked !== isAccessorChecked[ii - 1].checked
            ? "0px 0px 8px 3px #556171"
            : "0px 3px 8px 1px #556171",
        backgroundColor: "#1a2028",
      });
    } else {
      $(`.history-table > table > tbody > tr:hover:nth-child(${ii + 1})`).css({
        "box-shadow": "0px 0px 8px 3px #556171",
        backgroundColor: "#1a2028",
      });

      // Use the saved background color
      let originalBg = row.attr("data-original-bg");
      row.css({
        "box-shadow": "",
        backgroundColor: originalBg || "", // If originalBg is null, default to ""
      });
      continue;
    }
  }
  if (checkedCount >= 1) {
    deleteISVisible(isDeleteChecked, isTagChecked, isShareChecked);
  } else {
    document.getElementById("boxHeaderChecks").checked = false;
    deleteNOTVisible(isDeleteChecked, isTagChecked, isShareChecked);
  }
};
export default preCheckColumns;
