import $ from "jquery";
import { getPortfoliowSpecificBrokerIntegration } from "../getSpecificPortfolio";
import parsedYdXOrders from "./parsers/dydx";
import addNewEntriesandIds from "./../autoimport/addNewEntriesandIds";
import putUserData from "../../utils/AWS/putS3UserObject";
import parseEtherOrders from "./../../functions/autoimport/parsers/etherwallet";
import parseMetaTraderOrders from "./../../functions/autoimport/parsers/metatrader";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
const NodeRSA = require("node-rsa");
const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");
const ethereum = window.ethereum;
// This funtion calculates the new logbook data state
// then updates allDataRef ref with the new data (including the new trades obv)
// then sets skynet data

export const mergeTradefromWebSockets = async (
  setallData,
  connection,
  userIdIN,
  data,
  prevStateIn,
  history
) => {
  const verified = 2;
  const prevState = prevStateIn;
  let finalPushMAIN = {};
  let changedIds = [];
  const userId = userIdIN;
  const brokerName = connection.broker;
  const brokerId = connection.integrationId;
  const marginMode = connection.marginMode;

  if (brokerName === "dYdX") {
    const onStateChange = (prevData) => {
      if (!prevData) {
      } else {
        let allDataIn = prevData.data;
        let portfolio = {};
        if (Object.keys(allDataIn).length === 0) {
        } else {
          // have to call b/c we haven't called processDataandSettings yet
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataIn,
            brokerId
          );
        }

        const linkedBrokerInfoOuter = portfolio?.settings.linkedBrokerInfo;
        let ids = linkedBrokerInfoOuter && [
          ...portfolio?.settings.linkedBrokerInfo.ids,
        ];

        let entries = portfolio?.entries;
        let tradedata = [];
        let trades = entries.slice();
        const content = data.contents;
        const fills = content && content.fills;
        const transfers = content && content.transfers;
        const fundingpayments = content && content.fundingPayments;
        fills.forEach((item) => {
          tradedata.push(item);
        });
        transfers.forEach((item) => {
          tradedata.push(item);
        });
        fundingpayments.forEach((item) => {
          tradedata.push(item);
        });

        // SORT BEFORE DOING STUFF TO tradedata
        tradedata &&
          tradedata.sort(function (a, b) {
            var c = new Date(
              !a["createdAt"] ? a["effectiveAt"] : a["createdAt"]
            );
            var d = new Date(
              !b["createdAt"] ? b["effectiveAt"] : b["createdAt"]
            );
            return d - c;
          });
        tradedata && tradedata.reverse();

        // PARSE INCOMING DYDX ORDERS
        // CREATE NEW IDS ARRAY
        const parsedOrdersandIds = parsedYdXOrders(
          trades,
          ids,
          portfolio,
          tradedata,
          userId,
          verified
        );

        trades = parsedOrdersandIds.trades;
        ids = parsedOrdersandIds.ids;

        changedIds.push(...parsedOrdersandIds.changedIds);
        //only merge the trades if the message contains the fills prop and it's a channel_data type
        //the fills prop is important because there is a message that does not contain this prop
        //but still comes through with every message push

        // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

        const preSetEntriesandIds = addNewEntriesandIds(
          allDataIn,
          portfolio,
          ids,
          trades
        );
        allDataIn["createdPortfolios"] = preSetEntriesandIds;

        // Then change settings. *if mulitple selected then default
        // Notice we used portfolioINIT here
        const finalPush = {
          chosenPortfolios: allDataIn.chosenPortfolios,
          createdPortfolios: allDataIn.createdPortfolios,
          globalSettings: allDataIn.globalSettings,
          version: Number(allDataIn.version) + 1,
          sharedTrades: allDataIn.sharedTrades,
        };
        $(window).bind("beforeunload", function () {
          return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
        });
        localStorage.setItem("dbSetRunning", "yes");
        finalPushMAIN = finalPush;
        return finalPush;
      }
    };

    // -------- NEED TO ADD THE NEW ENTRIES TO CHOSENPORTFOLIOS AND CREATEDPORTFOLIOS --------
    setallData((prevData) => {
      return {
        data: onStateChange(prevData),
      };
    });
  } else if (brokerName === "metamask") {
    const onStateChange = async (prevData) => {
      if (!prevData) {
      } else {
        let allDataIn = prevData.data;
        let portfolio = {};
        if (Object.keys(allDataIn).length === 0) {
        } else {
          // have to call b/c we haven't called processDataandSettings yet
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataIn,
            brokerId
          );
        }
        const linkedBrokerInfoOuter = portfolio.settings.linkedBrokerInfo;
        let ids = linkedBrokerInfoOuter && [
          ...portfolio.settings.linkedBrokerInfo.ids,
        ];
        let entries = portfolio.entries;
        let trades = entries.slice();

        // ----------- METAMASK -----------
        if (ethereum) {
          // From now on, this should always be true:
          // provider === window.ethereum
          const account = await ethereum.request({
            method: "eth_requestAccounts",
          });
          const address = account[0].toLowerCase();
          let tradedata = [data];
          const parsedOrdersandIds = await parseEtherOrders(
            trades,
            ids,
            portfolio,
            tradedata,
            userId,
            verified,
            address
          );
          trades = parsedOrdersandIds.trades;
          ids = parsedOrdersandIds.ids;
          changedIds.push(...parsedOrdersandIds.changedIds);

          // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

          const preSetEntriesandIds = addNewEntriesandIds(
            allDataIn,
            portfolio,
            ids,
            trades
          );

          allDataIn["createdPortfolios"] = preSetEntriesandIds;
        } else {
          // Some warning to install metamask
        }

        // Then change settings. *if mulitple selected then default
        // Notice we used portfolioINIT here
        const finalPush = {
          chosenPortfolios: allDataIn.chosenPortfolios,
          createdPortfolios: allDataIn.createdPortfolios,
          globalSettings: allDataIn.globalSettings,
          version: Number(allDataIn.version) + 1,
          sharedTrades: allDataIn.sharedTrades,
        };
        $(window).bind("beforeunload", function () {
          return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
        });
        localStorage.setItem("dbSetRunning", "yes");
        finalPushMAIN = finalPush;
        return finalPush;
      }
    };

    // -------- NEED TO ADD THE NEW ENTRIES TO CHOSENPORTFOLIOS AND CREATEDPORTFOLIOS --------

    async function updateState() {
      const newData = await onStateChange(prevState.current);
      setallData({ data: newData });
    }
    await updateState();
  } else if (brokerName === "metatrader4" || brokerName === "metatrader5") {
    const onStateChange = async (prevData) => {
      if (!prevData) {
      } else {
        let allDataIn = prevData.data;
        let portfolio = {};
        if (Object.keys(allDataIn).length === 0) {
        } else {
          // have to call b/c we haven't called processDataandSettings yet
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataIn,
            brokerId
          );
        }
        const linkedBrokerInfoOuter = portfolio?.settings?.linkedBrokerInfo;
        let ids = linkedBrokerInfoOuter && [
          ...portfolio?.settings?.linkedBrokerInfo.ids,
        ];
        let entries = portfolio?.entries;
        let trades = entries?.slice();

        // ----------- METAMASK -----------
        let tradedata = [data];
        const parsedOrdersandIds = parseMetaTraderOrders(
          trades,
          ids,
          portfolio,
          tradedata,
          userId,
          verified,
          marginMode
        );
        trades = parsedOrdersandIds.trades;
        ids = parsedOrdersandIds.ids;
        changedIds.push(...parsedOrdersandIds.changedIds);

        // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

        const preSetEntriesandIds = addNewEntriesandIds(
          allDataIn,
          portfolio,
          ids,
          trades
        );

        allDataIn["createdPortfolios"] = preSetEntriesandIds;

        // Then change settings. *if mulitple selected then default
        // Notice we used portfolioINIT here
        const finalPush = {
          chosenPortfolios: allDataIn.chosenPortfolios,
          createdPortfolios: allDataIn.createdPortfolios,
          globalSettings: allDataIn.globalSettings,
          version: Number(allDataIn.version) + 1,
          sharedTrades: allDataIn.sharedTrades,
        };
        $(window).bind("beforeunload", function () {
          return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
        });
        localStorage.setItem("dbSetRunning", "yes");
        finalPushMAIN = finalPush;
        return finalPush;
      }
    };

    // -------- NEED TO ADD THE NEW ENTRIES TO CHOSENPORTFOLIOS AND CREATEDPORTFOLIOS --------

    async function updateState() {
      const newData = await onStateChange(prevState.current);
      setallData({ data: newData });
    }
    await updateState();
  }

  // CREATE "PULSE" FOR INCOMING TRADE DATA
  $(".openpositionswrapper > table > tbody > tr").each(function () {
    const thisEl = $(this);
    thisEl.removeClass("pulsestyling");
    const entryId = thisEl[0].children[0].innerText;
    if (changedIds.includes(entryId)) {
      thisEl.addClass("pulsestyling");
      setTimeout(() => {
        thisEl.removeClass("pulsestyling");
      }, 2000);
    }
  });
  // SET S3 DATA
  const S3Data = {
    data: finalPushMAIN,
    userId: userId,
  };
  const customerId =
    finalPushMAIN === null ? "" : finalPushMAIN.globalSettings.stripeId;
  await updateStripeCustomerWrapper(
    customerId,
    finalPushMAIN,
    "trades",
    userId,
    history
  );

  const finalAwait = async () => {
    await putUserData(S3Data, history);
    $(window).unbind("beforeunload");
    localStorage.setItem("dbSetRunning", "no");
  };
  await finalAwait();
};
export default mergeTradefromWebSockets;
