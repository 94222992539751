import e from "cors";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
const ManageDataDropDown = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="dropdown-list-import">
      <div className="dropdown-text">
        <Link
          to="/Manual-Trade-Entry"
          display="block"
          className="dropdown-items"
          style={{
            color:
              location.pathname === "/Manual-Trade-Entry"
                ? "#fbd665"
                : "#deebf7",
          }}
        >
          Manual Trade Entry
        </Link>
      </div>
      <div className="dropdown-text">
        <Link
          to="/Deposits-Withdrawals"
          display="block"
          className="dropdown-items"
          style={{
            color:
              location.pathname === "/Deposits-Withdrawals"
                ? "#fbd665"
                : "#deebf7",
          }}
        >
          {"Deposits & Withdrawals"}
        </Link>
      </div>
      <div className="dropdown-text">
        <Link
          to="/Integrations"
          display="block"
          className="dropdown-items"
          style={{
            //textDecoration: "line-through", //comment out in main release
            color:
              location.pathname === "/Integrations" ? "#fbd665" : "#deebf7",
          }}
        >
          Integrations
        </Link>
      </div>
{/*       <div className="dropdown-text">
        <span
          className="dropdown-items"
          onClick={() => {
            if (location.pathname === "/Settings") {
              history.go(0);
              history.push({
                pathname: "/Settings",
                state: {
                  initView: {
                    general: "none",
                    web3: "none",
                    filtering: "none",
                    importing: "none",
                    subscription: "none",
                    integrations: "block",
                  },
                  initToggle: true,
                },
              });
            } else {
              history.push({
                pathname: "/Settings",
                state: {
                  initView: {
                    general: "none",
                    web3: "none",
                    filtering: "none",
                    importing: "none",
                    subscription: "none",
                    integrations: "block",
                  },
                  initToggle: true,
                },
              });
            }
          }}
        >
          Automated Importing
        </span>
      </div> */}
    </div>
  );
};

export default ManageDataDropDown;
