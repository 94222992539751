export const firstAIOuput = (parsedCSV) => {
  let newMessage;
  newMessage = `[no prose][Output only JSON] Provide a JSON response containing the props "headers", "firstDataRow", "dataType", and "headersNew". \n
    For the "headers" prop: Given the following array of objects, where the objects contain a "line" prop equivalent to the row number of the csv data, and a "row" props containing parsed CSV content of that row, \n
    ${JSON.stringify(parsedCSV)} \n
    return the EXACT object (both "line" and "row" prop) containing the HEADERS of the fills/deals/orders/executions data. Should only return ONE object. Ignore any sections related to cash transactions or funding transactions data. Try to ignore any sections related to positions or trades (rows that have both entry and exit prices) UNLESS that's the only data available. \n
    If there is not a header at all, look for the first row of fills/deals/orders/executions or positions/trades data, and assign reasonable and accurate as possible headers based on the fields, and return these in the same "line" and "row" prop containing object, with line being 0, and the row being the generated headers. \n
       
    For the "firstDataRow" prop: return the EXACT object corresponding to the FIRST row of the fills/deals/orders/executions data. It is completely possible for the first row of data to be line 0 if there were no headers in the original CSV file.

    For the "dataType" prop: this should be either "position", "non-position", "non-trade". If there was only position data (single rows with two prices, two times, two dates, etc...signaling an entry and exit in the same line) available, return "position", else if there was fills/deals/orders/executions data available, return "non-position". Return "non-trade" if the CSV appears to be data other than trading history.
  
    For the "headersNew" prop: take the EXACT headers object you just found, and make the following header item replacements (in the "row" prop):

    - Symbol/ticker Symbol related data -> replace with "Symbol"
    - Time of the fill/deal/order/execution -> replace with "Time Stamp"
    - Number of units traded -> replace with "Size"
    - Order side information -> replace with "Side"
    - Information on price of the fills/deals/orders/executions -> replace with "Price"
    - Order number information -> replace with "Order Number"
    - Look for fields that contain "stock", "futures", etc. in the "row" prop across all objects -> replace the header of that column with "Asset Class"

    If the "dataType" is "position", make the additional header mappings on top of the above:
    - Information on ENTRY price of the position/trade -> replace with "Entry Price"
    - Information on EXIT price of the position/trade -> replace with "Exit Price"
    `;
  return newMessage;
};

export const secondAIOuput = (parsedCSV) => {
  let newMessage;
  newMessage = `[no prose][Output only JSON] Provide a JSON response containing the prop "headersNew" \n
  For the "headersNew" prop: take the EXACT headers object you just found, and make the following header item replacements (in the "row" prop):

    - Symbol/ticker Symbol related data -> replace with "Symbol"
    - Time of the fill/deal/order/execution -> replace with "Time Stamp"
    - Number of units traded -> replace with "Size"
    - Order side information -> replace with "Side"
    - Information on price of the fills/deals/orders/executions -> replace with "Price"
    - Order number information -> replace with "Order Number"
    - Look for fields that contain "stock", "futures", etc. in the "row" prop across all objects -> replace the header of that column with "Asset Class"

    If the "dataType" is "position", make the additional header mappings on top of the above:
    - Information on ENTRY price of the position/trade -> replace with "Entry Price"
    - Information on EXIT price of the position/trade -> replace with "Exit Price"
    `;
  return newMessage;
};

export default firstAIOuput;
