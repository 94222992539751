import isObjectEqual from "./isObjectEqual";
export const mergeDuplicateExecutions = (execArr) => {
  const newExecutions = execArr.reduce((acc, curr) => {
    const objInAcc = acc.find(
      (o) =>
        o.lotSize === curr.lotSize &&
        o.entryPrice === curr.entryPrice &&
        o.startDateTime === curr.startDateTime &&
        o.expectedEntry === curr.expectedEntry &&
        o.strikePrice === curr.strikePrice &&
        o.expirationDate === curr.expirationDate &&
        o.legType === curr.legType
    );
    if (objInAcc) objInAcc.lotSize += curr.lotSize;
    else acc.push(curr);
    return acc;
  }, []);
  return newExecutions;
};
export const mergeDuplicateExitExecutions = (execArr) => {
  const newExitExecutions = execArr.reduce((acc, curr) => {
    const objInAcc = acc.find(
      (o) =>
        o.exitLotSize === curr.exitLotSize &&
        o.exitPrice === curr.exitPrice &&
        o.endDateTime === curr.endDateTime &&
        o.expectedExit === curr.expectedExit &&
        o.exitstrikePrice === curr.exitstrikePrice &&
        o.exitexpirationDate === curr.exitexpirationDate &&
        o.exitlegType === curr.exitlegType &&
        isObjectEqual(o.equityComponents, curr.equityComponents) && //JSON.stringify(o.equityComponents) === JSON.stringify(curr.equityComponents) &&
        o.exercised === curr.exercised
    );
    if (objInAcc) objInAcc.exitLotSize += curr.exitLotSize;
    else acc.push(curr);
    return acc;
  }, []);
  return newExitExecutions;
};
export default mergeDuplicateExecutions;
