import React, { useRef, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
/* import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
 */ import dollarCostAverage from "../../functions/dollarCostAvg";
/* import getRiskedPercentColor from "../../functions/getRiskedPercentColor";
import { profitLossCalculation } from "./../../functions/profitLossCalculation";
 */ import getEfficiencyBarColor from "../Logbook/getEfficiencyBarColor";
import { handleNANGOOD } from "../../functions/handleUndefined";
import ProTierComponent from "../../components/tierComponents/pro";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import getColorPoint from "../../functions/includeColor";

const PercentMetric = (props) => {
  const graph = props.graph;
  const graphTitle = props.graphTitle;
  const calculations = props.userCalculations;
  const avgRisk = calculations.averageRisk;
  const firstUpdate = useRef(true);
  const [perfmetricswidth, setperfmetricswidth] = useState(0);
  const [perfmetricsheight, setperfmetricsheight] = useState(0);
  const color = useRef("");

  const ref = useRef(null);

  const showINIT = props.showCheck;

  useEffect(() => {
    const perfmetricswrappermain = document.getElementsByClassName(
      "chartjs-render-monitor"
    )[2];
    const resizeObserver = new ResizeObserver((entries) => {
      const itemWidth = entries[0].contentRect.width;
      const itemHeight = entries[0].contentRect.height;
      setperfmetricswidth(itemWidth);
      setperfmetricsheight(itemHeight);
    });
    perfmetricswrappermain && resizeObserver.observe(perfmetricswrappermain);

    if (firstUpdate.current) {
    }

    return () => {
      firstUpdate.current = false;
    };
  }, []);
  const returnMetrics = () => {
    let elements = [];
    let graphs = [props.graph];
    const graphPropValues = avgRisk;
    let riskText = "";
    if (graph === "riskedpercent") {
      if (graphPropValues < 5) {
        color.current = getColorPoint(
          "#49f875",
          "#e1f967",
          graphPropValues / 5,
          "hex"
        );
        if (graphPropValues < 1) {
          riskText = "Very Low Risk";
        } else if (graphPropValues < 3) {
          riskText = "Low Risk";
        } else {
          riskText = "Moderate Risk";
        }
      } else if (graphPropValues >= 5 && graphPropValues < 10) {
        color.current = getColorPoint(
          "#e1f967",
          "#ff9735",
          (graphPropValues - 5) / 5,
          "hex"
        );
        if (graphPropValues < 10) {
          riskText = "High Risk";
        }
      } else if (graphPropValues >= 10 && graphPropValues < 15) {
        riskText = "Very High Risk";
        color.current = getColorPoint(
          "#ff9735",
          "rgb(238, 73, 73)",
          (graphPropValues - 10) / 5,
          "hex"
        );
      } else if (graphPropValues >= 15 && graphPropValues < 20) {
        riskText = "Very High Risk";
        color.current = getColorPoint(
          "rgb(238, 73, 73)",
          "rgb(139, 43, 43)",
          (graphPropValues - 15) / 5,
          "hex"
        );
      } else {
        riskText = "Very High Risk";
        color.current = "rgb(139, 43, 43)";
      }
    }
    const colours = (color) => [color, "#28303c"];
    const data = (title, color) => {
      return {
        labels: [title],
        datasets: [
          {
            data:
              graphPropValues === "-" || !graphPropValues || showINIT
                ? [0, 100]
                : [
                    Number(graphPropValues).toFixed(3),
                    100 - Number(graphPropValues).toFixed(3),
                  ],
            backgroundColor: colours(color),
            borderColor: colours(color),
          },
        ],
      };
    };
    const options = (title) => {
      return {
        tooltips: {
          enabled: false,
          backgroundColor: "#14181e",
          titleFontSize: 14,
          titleFontColor: "#fbd665",
          bodyFontColor: "#fff7cb",
          bodyFontSize: 14,
          displayColors: false,
          callbacks: {
            title: () => {
              return title + ": ";
            },
            label: () => {
              const returnthis =
                graphPropValues === "-" || !graphPropValues || showINIT
                  ? "-"
                  : Number(graphPropValues).toFixed(3) + "%";
              return returnthis;
            },
          },
        },
        animation: {
          animateRotate: true,
          duration: 1700,
          render: false,
        },
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 90,
        legend: {
          reverse: true,
          display: false,
        },
      };
    };
    let riskColor = "";
    riskColor =
      graphPropValues === "-" || !graphPropValues || showINIT
        ? "#1a2028"
        : color.current;

    graphs.forEach((g) => {
      const graph = (
        <div className="percentpinmetric" key={"graph" + g}>
          <div className="deletepicbutton2" onClick={() => {}}>
            X
          </div>
          <div className="singlepinmetricheader2">
            {graphTitle}{" "}
            {showINIT && (
              <div style={{ position: "relative", top: -12, left: "0" }}>
                <ProTierComponent />
              </div>
            )}
          </div>
          <div className="percentpinneddivtextwrapper" ref={ref}>
            {
              <Doughnut
                data={data("Risked %", riskColor)}
                options={options("Risked %", "riskedpercentviewtrade")}
                className="singlepinmetricactual"
              />
            }
          </div>
          <div
            className="percentpinmetricmetric"
            style={{
              color: riskColor,
              top: perfmetricsheight ** 1.2 / 5.3,
              /*               filter: `drop-shadow(0 0 2px ${riskColor})`,
              msFilter: `drop-shadow(0 0 2px ${riskColor})`,
              MozFilter: `drop-shadow(0 0 2px ${riskColor})`,
              WebkitFilter: `drop-shadow(0 0 2px ${riskColor})`, */
            }}
          >
            {graphPropValues === "-" || !graphPropValues || showINIT
              ? "-%"
              : Number(graphPropValues).toFixed(3) + "%"}
          </div>
          <div
            className="riskmetriclabel"
            style={{
              color: riskColor,
              /*               filter: `drop-shadow(0 0 2px ${riskColor})`,
              msFilter: `drop-shadow(0 0 2px ${riskColor})`,
              MozFilter: `drop-shadow(0 0 2px ${riskColor})`,
              WebkitFilter: `drop-shadow(0 0 2px ${riskColor})`, */
            }}
          >
            {riskText}
          </div>
        </div>
      );
      elements.push(graph);
    });
    return elements;
  };
  return (
    <div
      className="percentpinnedMetricsWrapper"
      id="percentpinnedMetricsWrapper"
    >
      {returnMetrics()}
    </div>
  );
};

export default PercentMetric;
