import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
/* import DateTime from "react-datetime";
 */ import DeleteButton from "../../components/deleteButton";
import { handleNANGOOD } from "../../functions/handleUndefined";
import { returnNetPLDollar } from "./../../functions/profitLossCalculation";

import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
/* import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
 */ import { setLoaderState } from "../../actions/actionCreators";
import { DataContext } from "../../DataContext";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import store from "../../store";
//import EfficiencyBar from "./../Logbook/efficiencyBar";
import getDateDifference from "../../functions/getDateDifference";
import useForceUpdate from "../../hooks/useForceUpdate";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Modal from "react-modal";
import profitLossCalculation from "./../../functions/profitLossCalculation";
import getOptionsStrategy from "../../functions/getOptionsStrategy";
import longShortDelineation from "../../functions/longShortDelineation";
import {
  faRedo,
  faPlus,
  faTrashAlt,
  faPlay,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import usePrevious from "../../hooks/usePrevious";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  confidence,
  market,
  timeFrame,
} from "./../Manual-Trade-Entry/DropDownOptions";
import { theme } from "./../../components/selectTheme";
import getEntryExitLots from "./../../functions/getEntryExitLots";

import ImportantMetricComponent from "./riskGraphs";
import SinglePercentMetric from "./singlePercentMetric";
import {
  getEndDateTime,
  getStartDateTime,
} from "../../functions/getStartorEndDateTime";
import isArray from "../../functions/arrayFunctions/isArray";
import dollarCostAverage from "../../functions/dollarCostAvg";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import retrievePicture from "./../../utils/AWS/retrievePicture";
import TradingViewWidget from "../../components/TradingViewWidget";
import uploadPicture from "../../utils/AWS/uploadPicture";
import deletePictures from "../../utils/AWS/deletePictures";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import readContent from "./../../functions/fileReaderPromise";
import {
  ProTierComponent,
  MasterTierComponent,
} from "./../../components/tierComponents/pro";
import {
  countExerciseAssignIsEqual,
  countExerciseAssign,
} from "./../../functions/countExerciseAssign";
import { isTradeClosed2 } from "./../../functions/checkIsClosed";
import createEquityComponent from "../../functions/createExecutions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import slippageCalculation from "../../functions/slippageCalculation";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import isObjectEqual from "./../../functions/isObjectEqual";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import verifiedImage from "./../../images/noun-verified-4364676-2.png";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import NoteComponent from "./../../components/NoteComponent";

function cutOffString(str) {
  str && str.replace("-", "").replace(/\//g, ""); // removes slashes
  var lastPeriodIndex = str.lastIndexOf(".");
  if (lastPeriodIndex === -1) {
    // There's no period in the string, return the original string
    return str;
  }
  // Remove everything after the last period
  return str.substring(0, lastPeriodIndex);
}
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex =
    hex &&
    hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
function numberWithCommas(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";
let he = require("he");

const ViewTradePage = (props) => {
  const allGlobalData = useContext(DataContext);
  /// LOCAL STORAGE VARIABLES
  var localEditTrade = JSON.parse(localStorage.getItem("editTrade"));
  var localEditTradeId = JSON.parse(localStorage.getItem("editTradeId"));
  const { user /* isAuthenticated */ } = useAuth0();
  const userId = user.sub;
  const active = props.active;
  const tier = props.tier;
  const showCheck = props.showCheck;
  var isDBRunning = localStorage.getItem("dbSetRunning");
  //
  const allData = props.userData;
  const portfolio = allData.portfolio;
  const settings = allData.portfolio.settings;
  const allportfolios = allData.allData;
  const chosenPortfolios = allportfolios.chosenPortfolios;
  const createdPortfolios = allportfolios.createdPortfolios;
  const filterlists = allData.filterLists;
  const thedefaultSymbol = portfolio.settings.currencySymbol;
  const supportedCryptosCheck = supportedCryptos.includes(thedefaultSymbol);
  const chosenEntries = portfolio.entries;
  const verified = 0;
  const trade = !props.trade ? props.initialTrade : props.trade; // handle undefined props.trade
  /*   const trade = portoflioIn.entries.filter(
    (entry) => entry.entryId === intrade.entryId
  )[0];
  console.log(intrade)

  console.log(trade) */
  const indexInit = chosenEntries
    .map(function (e) {
      return e.entryId;
    })
    .indexOf(trade.entryId);
  const tradeEntryIdINIT = trade.entryId;
  const balanceINIT = trade.balance;

  // Set row sata to local storage if local storage has changed
  let tradeEntry =
    localEditTrade !== null
      ? trade.entryId === localEditTradeId
        ? localEditTrade
        : trade.entry
      : trade.entry;
  // ---------------

  let createdPortfolioMatching = createdPortfolios.slice();
  createdPortfolioMatching = createdPortfolioMatching.filter((port) => {
    return port.entries.some((entry) => {
      return entry.entryId === trade.entryId;
    });
  });
  const portfolName = createdPortfolioMatching[0]?.name;

  function getBrightness(color) {
    var rgb = parseInt(color.slice(1), 16); // slice to remove the '#'
    var r = (rgb >> 16) & 0xff;
    var g = (rgb >> 8) & 0xff;
    var b = rgb & 0xff;
    return 0.299 * r + 0.587 * g + 0.114 * b;
  }

  function textColorBasedOnBgColor(bgColor) {
    return getBrightness(bgColor) > 127 ? "#14181e" : "#deebf7";
  }
  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  const displayAsCurrency = (val) => {
    return Number(val).toFixed(2);
  };
  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }
  const start = Date.now();
  const [callcounter, setcallcounter] = useState(0);
  const [entrycheckbuffer, setentrycheckbuffer] = useState(0);
  const [dateNow, setdateNow] = useState(start);

  /*   const [tempstate, settempstate] = useState(tempstateINIT);*/
  /*   const [inEditMode, setInEditMode] = useState(false);
   */ const [isExecutionDisabled, setIsExecutionDisabled] = useState(true);
  const [isExitExecutionDisabled, setIsExitExecutionDisabled] = useState(true);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(indexInit);
  const [tradeEntryId, settradeEntryId] = useState(tradeEntryIdINIT);
  const [balance, setBalance] = useState(balanceINIT);
  const [balanceSTAYTHISVALUE] = useState(balanceINIT);

  const [exitmodalIsOpen, setexitIsOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [exercised, setexercised] = useState(null);
  const [exerciseDisable, setexerciseDisable] = useState();
  const [assignDisable, setassignDisable] = useState();
  const [exitcheckbuffer, setexitcheckbuffer] = useState(0);
  const [hasExitSlippage, sethasExitSlippage] = useState(false);
  const [exitError, setExitError] = useState(false);
  const [picIsOpen, setpicIsOpen] = useState(false);
  const [shareIsOpen, setshareIsOpen] = useState(false);

  const [showButtonsToolbar, setshowButtonsToolbar] = useState(false);
  const [multiExecutionBeforeChange, setmultiExecutionBeforeChange] = useState(
    []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openShareModal, setShareModal] = useState(false);
  const [addState, setaddState] = useState("");
  const [addExitState, setaddExitState] = useState("");
  const [isChanged, setisChanged] = useState(false);
  const [isChanged2, setisChanged2] = useState(false);

  const [DWDTOpen, setDWDTOpen] = useState(false);
  const [showPrevTradeButton, setshowPrevTradeButton] = useState(
    indexInit === 0 ? false : true
  );
  const [showNextTradeButton, setshowNextTradeButton] = useState(
    indexInit === chosenEntries.length - 1 ? false : true
  );

  const [exitExecutionBeforeChange, setexitExecutionBeforeChange] = useState(
    []
  );
  const [picData, setPicData] = useState([]);
  const picDiv = useRef();
  const [hasEntrySlippage, sethasEntrySlippage] = useState(false);
  const [dateTimeNowAdd, setdateTimeNowAdd] = useState({
    dateTimeOpenedSame: "",
    expirationDateSame: "",
    dateTimeClosedSame: "",
    exitExpirationDateSame: "",
  });
  /*   const [hasExitSlippage, sethasExitSlippage] = useState(false);
   */ const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [picId, setpicId] = useState("");
  const [entryWarning, setEntryWarning] = useState(false);
  const firstUpdateInit = useRef(true);
  /*   const [isDisabled2, setisDisabled2] = useState(true);
   */ const history = useHistory();
  /*   const [isDisabled, setisDisabled] = useState(true);
   */ const firstUpdate = useRef(true);
  /*   const toComponentB = useCallback(
    (row) => {
      history.push({
        pathname: "/Trade-Details",
        state: row,
      });
    },
    [history]
  ); */

  const openModalpic = async () => {
    setpicIsOpen(true);
  };
  const closeModalpic = () => {
    setpicIsOpen(false);
  };
  const openModalshare = async () => {
    setshareIsOpen(true);
  };
  const closeModalshare = () => {
    setshareIsOpen(false);
  };
  const openModaldel = async () => {
    setDeleteOpen(true);
  };
  const closeModaldel = () => {
    setDeleteOpen(false);
  };

  const closeShareTradeConfirmationModal = async () => {
    setShareModal(false);
  };

  /*   const getAccountBalancefromID = useCallback(
    (id) => {
      const trades = portfolio.entries;
      let returnAccountBal = Number(settings.startingBalance);
      for (const trade in trades) {
        returnAccountBal = Number(trades[trade].balance);
        if (trades[trade].entryId === id) {
          break;
        } else {
        }
      }
      return returnAccountBal;
    },
    [portfolio, settings]
  ); */

  const forceUpdate = useForceUpdate();
  const rowData = useRef({
    orderType: tradeEntry.orderType,
    orderNumber: tradeEntry.orderNumber,
    symbol: tradeEntry.symbol,
    strategy: tradeEntry.strategy,
    selectedConfidence: tradeEntry.selectedConfidence,
    selectedEmotion: tradeEntry.selectedEmotion,
    selectedMarket: tradeEntry.selectedMarket,
    selectedTimeframe: tradeEntry.selectedTimeframe,
    selectedMistake: tradeEntry.selectedMistake,
    endDateTime: tradeEntry.endDateTime,
    startDateTime: tradeEntry.startDateTime,
    pictures: tradeEntry.pictures,
    stopLoss: tradeEntry.stopLoss,
    takeProfit: tradeEntry.takeProfit,
    multiExecution: tradeEntry.multiExecution,
    lotSize: "",
    entryPrice: "",
    execstartDateTime: "",
    expectedEntry: "",
    strikePrice: "",
    expirationDate: "",
    legType: "",
    exitLotSize: "",
    exitPrice: "",
    execendDateTime: "",
    expectedExit: "",
    exitstrikePrice: "",
    exitexpirationDate: "",
    exitlegType: "",
    exitExecution: tradeEntry.exitExecution,
    equityComponents: [],
    profitLoss: tradeEntry.profitLoss,
    commissions: tradeEntry.commissions,
    fees: tradeEntry.fees,
    maxAdEx: tradeEntry.maxAdEx,
    maxFavEx: tradeEntry.maxFavEx,
    comments: tradeEntry.comments,
    selectedPortfolio: tradeEntry.selectedPortfolio,
    selectedPortfolioType: tradeEntry.selectedPortfolioType,
    tags: tradeEntry.tags,
    selectedPhysical: tradeEntry.selectedPhysical,
    verifiedLevel: tradeEntry.verifiedLevel,
    idLinks: tradeEntry.idLinks,
    demoData: tradeEntry.demoData,
  });
  const [demoDataInit, sedemoDataInit] = useState({
    demoData: tradeEntry.demoData,
  });
  const [rowDataInit, setrowDataInit] = useState({
    pictures: tradeEntry.pictures,
    symbol: tradeEntry.symbol,
    strategy: tradeEntry.strategy,
    selectedConfidence: tradeEntry.selectedConfidence,
    selectedEmotion: tradeEntry.selectedEmotion,
    selectedPhysical: tradeEntry.selectedPhysical,
    selectedMarket: tradeEntry.selectedMarket,
    selectedTimeframe: tradeEntry.selectedTimeframe,
    selectedMistake: tradeEntry.selectedMistake,
    selectedPortfolio: tradeEntry.selectedPortfolio,
    selectedPortfolioType: tradeEntry.selectedPortfolioType,
    orderType: tradeEntry.orderType,
    orderNumber: tradeEntry.orderNumber,
    dayOfWeek: tradeEntry.dayOfWeek,
    monthOfYear: tradeEntry.monthOfYear,
    stopLoss: tradeEntry.stopLoss,
    takeProfit: tradeEntry.takeProfit,
    profitLoss: tradeEntry.profitLoss,
    commissions: tradeEntry.commissions,
    fees: tradeEntry.fees,
    maxAdEx: tradeEntry.maxAdEx,
    maxFavEx: tradeEntry.maxFavEx,
    comments: tradeEntry.comments,
    multiExecution: tradeEntry.multiExecution,
    exitExecution: tradeEntry.exitExecution,
    endDateTime: tradeEntry.endDateTime,
    startDateTime: tradeEntry.startDateTime,
    tags: tradeEntry.tags,
    verifiedLevel: tradeEntry.verifiedLevel,
    idLinks: tradeEntry.idLinks,
    demoData: tradeEntry.demoData,
  });
  const [edittradeProps, seeditttradeProps] = useState({
    editorderType: "",
    editlegType: "",
    editexitlegType: "",
    editorderNumber: "",
    editselectedSymbol: null,
    editselectedStrategy: null,
    editselectedConfidence: null,
    editselectedEmotion: null,
    editselectedMarket: null,
    editselectedTimeframe: null,
    editselectedMistake: null,
    editstrikePrice: "",
    editexpirationDate: "",
    editexitstrikePrice: "",
    editexitexpirationDate: "",
    editlotSize: "",
    editexitLotSize: "",
    editendDateTime: "",
    editstartDateTime: "",
    editentryPrice: "",
    editstopLoss: "",
    edittakeProfit: "",
    editexpectedEntry: "",
    editprofitLoss: "",
    editexitPrice: "",
    editcommissions: "",
    editfees: "",
    editmaxAdEx: "",
    editmaxFavEx: "",
    editexpectedExit: "",
    editcomments: "",
    edittags: null,
    editselectedPhysical: null,
  });
  const {
    /*     editorderType,
    editlegType,
    editexitlegType,
    editselectedSymbol, */
    editorderNumber,
    editselectedStrategy,
    editselectedConfidence,
    editselectedEmotion,
    editselectedMarket,
    editselectedTimeframe,
    editselectedMistake,
    /*     editlotSize,
    editexitLotSize,
    editendDateTime,
    editstrikePrice,
    editexpirationDate,
    editexitstrikePrice,
    editexitexpirationDate,
    editentryPrice, */
    editstopLoss,
    edittakeProfit,
    editprofitLoss,
    /*     editexpectedEntry,
    editprofitLoss,
    editexitPrice, */
    editcommissions,
    editfees,
    editmaxAdEx,
    editmaxFavEx,
    /*     editexpectedExit, */
    editcomments,
    edittags,
    editselectedPhysical,
    editstartDateTime,
  } = edittradeProps;

  const {
    dateTimeOpenedSame,
    expirationDateSame,
    dateTimeClosedSame,
    exitExpirationDateSame,
  } = dateTimeNowAdd;
  let prevdateTimeOpenedSame = usePrevious(rowData.current.execstartDateTime);
  let prevexpirationDateSame = usePrevious(rowData.current.expirationDate);
  let prevdateTimeClosedSame = usePrevious(rowData.current.execendDateTime);
  let prevexitExpirationDateSame = usePrevious(
    rowData.current.exitexpirationDate
  );
  //localStorage.setItem("editTrade") = rowData;
  const currentPictures = rowData.current.pictures;
  const pictureslength =
    currentPictures[0] === "N/A" ? 0 : currentPictures.length;
  let prevEntry = usePrevious(rowData.current.expectedEntry);
  let prevExit = usePrevious(rowData.current.expectedExit);
  const validation = useCallback(
    (data, input) => {
      const decimalRegex = /^\d*\.?\d*$/;
      const dateRegex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; //years 1900-2099
      const dateTimeRegex =
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)\s+([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;

      // ------- Start Date validator -------
      if (input === "startDateTime") {
        const startdatetemp = moment(data).format("MM/DD/YYYY HH:mm:ss");
        if (
          (!dateTimeRegex.test(startdatetemp) &&
            data !== "" &&
            data !== null) ||
          data === undefined
        ) {
          $(".startDateTimeErrorMsg").css({
            display: "inline",
            right:
              rowData.current.selectedPortfolioType === "Options"
                ? "50%"
                : "0%",
          });
          $(".startDateTimeErrorMsg").html(
            "Date & Time format only (MM/DD/YYYY HH:mm:ss)"
          );
        } else {
          $(".startDateTimeErrorMsg").css({ display: "none" });
          $(".startDateTimeErrorMsg").html("");
        }
      }
      // ------- Expiration Date validator -------
      if (input === "expirationDate") {
        const expdatetemp = moment(data).format("MM/DD/YYYY");
        if (rowData.current.selectedPortfolioType === "Options") {
          if (
            (!dateRegex.test(expdatetemp) && data !== "" && data !== null) ||
            data === undefined
          ) {
            $(".expDateTimeErrorMsg").css({ display: "inline" });
            $(".expDateTimeErrorMsg").html("Date format only (MM/DD/YYYY)");
          } else {
            $(".expDateTimeErrorMsg").css({ display: "none" });
            $(".expDateTimeErrorMsg").html("");
          }
        }
      }

      // ------- Lot Size validator -------
      if (input === "lotSize") {
        if (
          (!decimalRegex.test(Number(data)) && data !== "") ||
          data === undefined
        ) {
          $(".lotSizeErrorMsg").css({ display: "inline" });
          $(".lotSizeErrorMsg").html("Numerical values only");
        } else {
          $(".lotSizeErrorMsg").css({ display: "none" });
          $(".lotSizeErrorMsg").html("");
        }
      }

      // ------- Entry Price validator -------
      if (input === "entryPrice") {
        if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
          $(".entryPriceErrorMsg").css({ display: "inline" });
          $(".entryPriceErrorMsg").html("Numerical values only");
        } else {
          $(".entryPriceErrorMsg").css({ display: "none" });
          $(".entryPriceErrorMsg").html("");
        }
      }
      // ------- Strike Price validator -------
      if (input === "strikePrice") {
        if (rowData.current.selectedPortfolioType === "Options") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".strikePriceErrorMsg").css({ display: "inline" });
            $(".strikePriceErrorMsg").html("Numerical values only");
          } else {
            $(".strikePriceErrorMsg").css({ display: "none" });
            $(".strikePriceErrorMsg").html("");
          }
        }
      }
      if (addState === "Add") {
        if (
          !rowData.current.execstartDateTime ||
          !rowData.current.entryPrice ||
          !rowData.current.lotSize ||
          (rowData.current.selectedPortfolioType === "Options" &&
            (!rowData.current.strikePrice ||
              !rowData.current.expirationDate ||
              rowData.current.expirationDate === null)) ||
          (rowData.current.orderType === "Multi-Leg Strategy" &&
            !rowData.current.legType) ||
          $(".lotSizeErrorMsg").html() !== "" ||
          $(".startDateTimeErrorMsg").html() !== "" ||
          (rowData.current.selectedPortfolioType === "Options" &&
            ($(".expDateTimeErrorMsg").html() !== "" ||
              $(".strikePriceErrorMsg").html() !== "")) ||
          $(".entryPriceErrorMsg").html() !== ""
        ) {
          setIsExecutionDisabled(true);
        } else {
          setIsExecutionDisabled(false);
        }
      } else if (addState === "Edit") {
        let execution = {
          id: selectedId,
          lotSize: Number(rowData.current.lotSize),
          entryPrice: rowData.current.entryPrice,
          startDateTime: rowData.current.execstartDateTime,
          expectedEntry: rowData.current.expectedEntry,
          strikePrice: rowData.current.strikePrice,
          expirationDate: rowData.current.expirationDate,
          legType: rowData.current.legType,
        };

        if (
          !isObjectEqual(execution, multiExecutionBeforeChange) //JSON.stringify(execution) !== JSON.stringify(multiExecutionBeforeChange)
        ) {
          setIsExecutionDisabled(false);
        } else {
          setIsExecutionDisabled(true);
        }
      }
    },
    [
      /*       chosenPortfolioName,
      chosenPortfolioAsset, */
      addState,
      selectedId,
      multiExecutionBeforeChange,
      //exitExecution,
    ]
  );
  const exitvalidation = useCallback(
    (data, input) => {
      const decimalRegex = /^\d*\.?\d*$/;
      const dateRegex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; //years 1900-2099
      const dateTimeRegex =
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)\s+([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;

      // ------- Start Date validator -------
      if (input === "endDateTime") {
        const startdatetemp = moment(data).format("MM/DD/YYYY HH:mm:ss");
        if (
          (!dateTimeRegex.test(startdatetemp) &&
            data !== "" &&
            data !== null) ||
          data === undefined
        ) {
          $(".endDateTimeErrorMsg").css({
            display: "inline",
            right:
              rowData.current.selectedPortfolioType === "Options"
                ? "50%"
                : "0%",
          });
          $(".endDateTimeErrorMsg").html("Only MM/DD/YYYY HH:mm:ss");
        } else {
          $(".endDateTimeErrorMsg").css({ display: "none" });
          $(".endDateTimeErrorMsg").html("");
        }
      }
      // ------- exit Expiration Date validator -------
      if (input === "exitexpirationDate") {
        const expdatetemp = moment(data).format("MM/DD/YYYY");
        if (rowData.current.selectedPortfolioType === "Options") {
          if (
            (!dateRegex.test(expdatetemp) && data !== "" && data !== null) ||
            data === undefined
          ) {
            $(".exitexpDateTimeErrorMsg").css({ display: "inline" });
            $(".exitexpDateTimeErrorMsg").html("Date format only (MM/DD/YYYY)");
          } else {
            $(".exitexpDateTimeErrorMsg").css({ display: "none" });
            $(".exitexpDateTimeErrorMsg").html("");
          }
        }
      }

      // ------- Lot Size validator -------
      if (input === "exitLotSize") {
        if (
          (!decimalRegex.test(Number(data)) && data !== "") ||
          data === undefined
        ) {
          $(".exitLotSizeErrorMsg").css({ display: "inline" });
          $(".exitLotSizeErrorMsg").html("Numerical values only");
        } else {
          $(".exitLotSizeErrorMsg").css({ display: "none" });
          $(".exitLotSizeErrorMsg").html("");
        }
      }

      // ------- Exit Price validator -------
      if (input === "exitPrice") {
        if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
          $(".exitPriceErrorMsg").css({ display: "inline" });
          $(".exitPriceErrorMsg").html("Numerical values only");
        } else {
          $(".exitPriceErrorMsg").css({ display: "none" });
          $(".exitPriceErrorMsg").html("");
        }
      }
      // ------- Strike Price validator -------
      if (input === "exitstrikePrice") {
        if (rowData.current.selectedPortfolioType === "Options") {
          if ((!decimalRegex.test(data) && data !== "") || data === undefined) {
            $(".exitstrikePriceErrorMsg").css({ display: "inline" });
            $(".exitstrikePriceErrorMsg").html("Numerical values only");
          } else {
            $(".exitstrikePriceErrorMsg").css({ display: "none" });
            $(".exitstrikePriceErrorMsg").html("");
          }
        }
      }
      if (addExitState === "Add") {
        if (
          !rowData.current.execendDateTime ||
          !rowData.current.exitLotSize ||
          (rowData.current.selectedPortfolioType === "Options" &&
            (rowData.current.exitstrikePrice === "" ||
              rowData.current.exitexpirationDate === "" ||
              rowData.current.exitexpirationDate === null ||
              $(".exitexpDateTimeErrorMsg").html() !== "" ||
              $(".exitstrikePriceErrorMsg").html() !== "")) ||
          (rowData.current.orderType === "Multi-Leg Strategy" &&
            !rowData.current.exitlegType) ||
          $(".exitLotSizeErrorMsg").html() !== "" ||
          $(".endDateTimeErrorMsg").html() !== "" ||
          $(".exitPriceErrorMsg").html() !== ""
        ) {
          setIsExitExecutionDisabled(true);
        } else {
          if (rowData.current.exitPrice === "") {
            if (
              (exercised === "Exercised" ||
                exercised === "Assigned" ||
                exercised === "Expired") &&
              $(".exitPriceErrorMsg").html() === ""
            ) {
              setIsExitExecutionDisabled(false);
            } else {
              setIsExitExecutionDisabled(true);
            }
          } else {
            setIsExitExecutionDisabled(false);
          }
        }
      } else if (addExitState === "Edit") {
        let execution = {
          id: selectedId,
          exitLotSize: Number(rowData.current.exitLotSize),
          exitPrice: rowData.current.exitPrice,
          endDateTime: rowData.current.execendDateTime,
          expectedExit: rowData.current.expectedExit,
          exitstrikePrice: rowData.current.exitstrikePrice,
          exitexpirationDate: rowData.current.exitexpirationDate,
          exitlegType: rowData.current.exitlegType,
          equityComponents: rowData.current.equityComponents,
          exercised: exercised,
        };
        if (
          !isObjectEqual(execution, exitExecutionBeforeChange) //JSON.stringify(execution) !== JSON.stringify(exitExecutionBeforeChange)
        ) {
          setIsExitExecutionDisabled(false);
        } else {
          setIsExitExecutionDisabled(true);
        }
      }
      if (rowData.current.selectedPortfolioType === "Options") {
        if (
          rowData.current.orderType === "Long Call" ||
          rowData.current.orderType === "Long Put" ||
          rowData.current.exitlegType === "Long Call" ||
          rowData.current.exitlegType === "Long Put"
        ) {
          setexerciseDisable(false);
          setassignDisable(true);
        } else if (
          rowData.current.orderType === "Short Call" ||
          rowData.current.orderType === "Short Put" ||
          rowData.current.exitlegType === "Short Call" ||
          rowData.current.exitlegType === "Short Put"
        ) {
          setexerciseDisable(true);
          setassignDisable(false);
        }
      } else {
      }
    },
    [
      /*       chosenPortfolioName,
      chosenPortfolioAsset, */
      selectedId,
      addExitState,
      exitExecutionBeforeChange,
      exercised,
      //exitExecution,
    ]
  );

  const retrievePictures = async () => {
    const picturesArray = rowData.current.pictures;
    let picDataState = [];
    for (let ip = 0, jp = picturesArray.length; ip < jp; ip++) {
      const pictureKey = picturesArray[ip];
      if (pictureKey === "N/A") {
      } else {
        const S3PictureData = {
          data: {
            pictureKey: pictureKey,
          },
        };
        const ret = await retrievePicture(S3PictureData, history);
        const pictureBlob = ret.picData;
        pictureBlob &&
          picDataState.push({
            base64Data: pictureBlob,
            pictureKey: pictureKey,
          });
      }
    }
    setPicData(picDataState);
  };
  useEffect(() => {
    //localStorage.setItem("editTrade", JSON.stringify(updatedState));
    let interval = setInterval(async () => {
      const datenowinner = new Date();
      setdateNow(datenowinner);
    }, 1000);
    if (firstUpdateInit.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
      retrievePictures();
      /*       document.onpaste = function (event) {
        var items = (event.clipboardData || event.originalEvent.clipboardData)
          .items;
        console.log(JSON.stringify(items)); // will give you the mime types
        for (index in items) {
          var item = items[index];
          if (item.kind === "file") {
            var blob = item.getAsFile();
            var reader = new FileReader();
            reader.onload = function (event) {
              console.log(event.target.result);
            }; // data url!
            reader.readAsDataURL(blob);
          }
        }
      }; */
    } else {
      const incomingMsg = localStorage.getItem("incomingMsg");
      if (incomingMsg === "yes") {
        // Only set rowDataInit when we get a websocket msg.
        // check DataContext for this localstorage var
        const updatedState = {
          ...rowData.current,
          orderType: trade.entry.orderType,
          orderNumber: trade.entry.orderNumber,
          symbol: trade.entry.symbol,
          stopLoss: trade.entry.stopLoss,
          takeProfit: trade.entry.takeProfit,
          endDateTime: trade.entry.endDateTime,
          startDateTime: trade.entry.startDateTime,
          multiExecution: trade.entry.multiExecution,
          exitExecution: trade.entry.exitExecution,
          profitLoss: trade.entry.profitLoss,
          commissions: trade.entry.commissions,
          fees: trade.entry.fees,
        };
        rowData.current = updatedState;
        forceUpdate();
        setrowDataInit({
          ...rowDataInit,
          orderType: trade.entry.orderType,
          orderNumber: trade.entry.orderNumber,
          symbol: trade.entry.symbol,
          stopLoss: trade.entry.stopLoss,
          takeProfit: trade.entry.takeProfit,
          endDateTime: trade.entry.endDateTime,
          startDateTime: trade.entry.startDateTime,
          multiExecution: trade.entry.multiExecution,
          exitExecution: trade.entry.exitExecution,
          profitLoss: trade.entry.profitLoss,
          commissions: trade.entry.commissions,
          fees: trade.entry.fees,
        });
        localStorage.setItem("incomingMsg", "no");
      } else {
      }
      const actualStartDateTime = new Date(trade.entry.startDateTime);
      const newData = {
        pictures: rowData.current.pictures,
        symbol: trade.entry.symbol,
        strategy: rowData.current.strategy,
        selectedConfidence: rowData.current.selectedConfidence,
        selectedEmotion: rowData.current.selectedEmotion,
        selectedPhysical: rowData.current.selectedPhysical,
        selectedMarket: rowData.current.selectedMarket,
        selectedTimeframe: rowData.current.selectedTimeframe,
        selectedMistake: rowData.current.selectedMistake,
        selectedPortfolio: rowData.current.selectedPortfolio,
        selectedPortfolioType: rowData.current.selectedPortfolioType,
        orderType: trade.entry.orderType,
        orderNumber: trade.entry.orderNumber,
        dayOfWeek: weekday[actualStartDateTime.getDay()],
        monthOfYear: montharr[actualStartDateTime.getMonth()],
        stopLoss: rowData.current.stopLoss,
        takeProfit: rowData.current.takeProfit,
        profitLoss: trade.entry.profitLoss,
        commissions: trade.entry.commissions,
        fees: trade.entry.fees,
        maxAdEx: rowData.current.maxAdEx,
        maxFavEx: rowData.current.maxFavEx,
        comments: rowData.current.comments,
        multiExecution: trade.entry.multiExecution,
        exitExecution: trade.entry.exitExecution,
        startDateTime: trade.entry.startDateTime,
        endDateTime: trade.entry.endDateTime,
        tags: rowData.current.tags,
        verifiedLevel: rowData.current.verifiedLevel,
        idLinks: rowData.current.idLinks,
        demoData: demoDataInit.demoData,
      };
      localStorage.setItem("editTrade", JSON.stringify(newData));
    }
    return () => {
      firstUpdateInit.current = false;
      clearInterval(interval);
    };
  }, [allGlobalData, trade, picData]); // eslint-disable-line react-hooks/exhaustive-deps
  // Entry validation
  useEffect(() => {
    validation(rowData.current.expirationDate, "expirationDate");
  }, [rowData.current.expirationDate, validation]);
  useEffect(() => {
    validation(rowData.current.legType, "legType");
  }, [rowData.current.legType, validation]);
  useEffect(() => {
    validation(rowData.current.execstartDateTime, "startDateTime");
  }, [rowData.current.execstartDateTime, validation]);
  useEffect(() => {
    validation(rowData.current.lotSize, "lotSize");
  }, [rowData.current.lotSize, validation]);
  useEffect(() => {
    validation(rowData.current.entryPrice, "entryPrice");
  }, [rowData.current.entryPrice, validation]);
  useEffect(() => {
    validation(rowData.current.strikePrice, "strikePrice");
  }, [rowData.current.strikePrice, validation]);
  useEffect(() => {
    validation(rowData.current.expectedEntry, "expectedEntry");
  }, [rowData.current.expectedEntry, validation]);
  useEffect(() => {
    validation(rowData.current.multiExecution, "");
  }, [rowData.current.multiExecution, validation]);

  // Exit validation
  useEffect(() => {
    exitvalidation(rowData.current.exitlegType, "exitlegType");
  }, [rowData.current.exitlegType, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.execendDateTime, "endDateTime");
  }, [rowData.current.execendDateTime, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.exitLotSize, "exitLotSize");
  }, [rowData.current.exitLotSize, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.exitPrice, "exitPrice");
  }, [rowData.current.exitPrice, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.exitstrikePrice, "exitstrikePrice");
  }, [rowData.current.exitstrikePrice, exitvalidation]);
  useEffect(() => {
    exitvalidation(exercised, "exercised");
  }, [exercised, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.expectedExit, "expectedEntry");
  }, [rowData.current.expectedExit, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.exitexpirationDate, "exitexpirationDate");
  }, [rowData.current.exitexpirationDate, exitvalidation]);
  useEffect(() => {
    exitvalidation(rowData.current.exitExecution, "");
  }, [rowData.current.exitExecution, exitvalidation]);

  const handleChange = async (e) => {
    let startDateTimeInner = "";
    let endDateTimezInner = "";
    for (let im = 0, jm = e.multiExecution.length; im < jm; im++) {
      if (im === 0) {
        startDateTimeInner = e.multiExecution[im].startDateTime;
      } else {
        startDateTimeInner =
          e.multiExecution[im].startDateTime < startDateTimeInner
            ? e.multiExecution[im].startDateTime
            : startDateTimeInner;
      }
    }
    for (let ie = 0, je = e.exitExecution.length; ie < je; ie++) {
      if (ie === 0) {
        endDateTimezInner = e.exitExecution[ie].endDateTime;
      } else {
        endDateTimezInner =
          e.exitExecution[ie].endDateTime > endDateTimezInner
            ? e.exitExecution[ie].endDateTime
            : endDateTimezInner;
      }
    }
    const actualStartDateTime = new Date(startDateTimeInner);

    const verificationValue =
      e.orderType !== rowDataInit.orderType ||
      !isObjectEqual(e.symbol, rowDataInit.symbol) ||
      e.commissions !== rowDataInit.commissions ||
      e.fees !== rowDataInit.fees ||
      ((e.orderType === "Deposit" ||
        e.orderType === "Withdrawal" ||
        e.orderType === "Funding Payment" ||
        e.orderType === "Commit" ||
        e.orderType === "Approval" ||
        e.orderType === "Wrap" ||
        e.orderType === "Self" ||
        e.selectedPortfolioType === "Forex") &&
        e.profitLoss !== rowDataInit.profitLoss) ||
      !isObjectEqual(e.multiExecution, rowDataInit.multiExecution) ||
      !isObjectEqual(e.exitExecution, rowDataInit.exitExecution)
        ? 0
        : rowData.current.verifiedLevel;

    const newData = {
      pictures: e.pictures,
      symbol: e.symbol,
      strategy: e.strategy,
      selectedConfidence: e.selectedConfidence,
      selectedEmotion: e.selectedEmotion,
      selectedPhysical: e.selectedPhysical,
      selectedMarket: e.selectedMarket,
      selectedTimeframe: e.selectedTimeframe,
      selectedMistake: e.selectedMistake,
      selectedPortfolio: e.selectedPortfolio,
      selectedPortfolioType: e.selectedPortfolioType,
      orderType: e.orderType,
      orderNumber: e.orderNumber,
      dayOfWeek: weekday[actualStartDateTime.getDay()],
      monthOfYear: montharr[actualStartDateTime.getMonth()],
      stopLoss: e.stopLoss,
      takeProfit: e.takeProfit,
      profitLoss:
        e.orderType === "Deposit" ||
        e.orderType === "Withdrawal" ||
        e.orderType === "Funding Payment" ||
        e.orderType === "Commit" ||
        e.orderType === "Approval" ||
        e.orderType === "Wrap" ||
        e.orderType === "Self" ||
        e.selectedPortfolioType === "Forex"
          ? e.profitLoss
          : Number(
              profitLossCalculation(
                e.multiExecution,
                e.exitExecution,
                e.orderType,
                e.selectedPortfolioType,
                e.symbol.pointValue,
                false
              )
            ),
      commissions: e.commissions < 0 ? e.commissions : -1 * e.commissions,
      fees: e.fees,
      maxAdEx: e.maxAdEx,
      maxFavEx: e.maxFavEx,
      comments: e.comments,
      multiExecution: e.multiExecution,
      exitExecution: e.exitExecution,
      startDateTime: !startDateTimeInner ? e.startDateTime : startDateTimeInner,
      endDateTime: !endDateTimezInner ? e.endDateTime : endDateTimezInner,
      tags: e.tags,
      verifiedLevel: verificationValue,
      idLinks: e.idLinks,
      demoData: demoDataInit.demoData,
    };
    // setTimeout to ensure localstorage gets set
    setTimeout(() => {
      localStorage.setItem("editTrade", JSON.stringify(newData));
      localStorage.setItem("editTradeId", JSON.stringify(tradeEntryId));
    }, 0);

    // We need to set the new balance here
    // Final P/l - Initial P/l, then add that to the balance state
    // this allows for editing executions and the % p/l and other metrics update in real time,
    // and after refreshiong the page the data is still the same, and correct
    /*     const INITPL =
      rowDataInit.orderType === "Deposit" ||
      rowDataInit.orderType === "Withdrawal" ||
      rowDataInit.orderType === "Funding Payment" ||
      rowDataInit.orderType === "Commit" ||
      rowDataInit.orderType === "Approval" ||
      rowDataInit.orderType === "Wrap" ||
      rowDataInit.orderType === "Self" ||
      rowDataInit.selectedPortfolioType === "Forex"
        ? returnNetPLDollar(
            rowDataInit.profitLoss,
            rowDataInit.commissions,
            rowDataInit.fees,
            settings.calculationType
          )
        : Number(
            profitLossCalculation(
              rowDataInit.multiExecution,
              rowDataInit.exitExecution,
              rowDataInit.orderType,
              rowDataInit.selectedPortfolioType,
              rowDataInit.symbol.pointValue,
              false
            )
          );
    const FinalPL =
      e.orderType === "Deposit" ||
      e.orderType === "Withdrawal" ||
      e.orderType === "Funding Payment" ||
      e.orderType === "Commit" ||
      e.orderType === "Approval" ||
      e.orderType === "Wrap" ||
      e.orderType === "Self" ||
      e.selectedPortfolioType === "Forex"
        ? returnNetPLDollar(
            e.profitLoss,
            e.commissions,
            e.fees,
            settings.calculationType
          )
        : Number(
            profitLossCalculation(
              e.multiExecution,
              e.exitExecution,
              e.orderType,
              e.selectedPortfolioType,
              e.symbol.pointValue,
              false
            )
          ); */

    const updatedState = {
      ...rowData.current,
      ...newData,
    };
    rowData.current = updatedState;
    const objectEqual = isObjectEqual(rowDataInit, newData);
    if (!objectEqual) {
      setisChanged(true);
      setisChanged2(true);
      //setBalance(balanceSTAYTHISVALUE + (FinalPL - INITPL));
    } else {
      setisChanged(false);
      setisChanged2(false);
    }
    forceUpdate();
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
  };
  // -------------- EDIT TRADE --------------
  const editTrades = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    const multiExecution = rowData.current.multiExecution;
    const exitExecution = rowData.current.exitExecution.slice();
    const chosenPortfolioName = rowData.current.selectedPortfolio;
    let startDateTimeInner = getStartDateTime(rowData.current.multiExecution);
    let endDateTimezInner = getEndDateTime(rowData.current.exitExecution);
    const actualStartDateTime = new Date(startDateTimeInner);
    let newExec = exitExecution.slice();
    let newdata = {};
    let addOptionsStockTrade = false;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    let optionEntryId = "";

    // LOG FOR HANDLING EXERCISE/ASSIGN STOCK POSITIONS
    if (rowData.current.selectedPortfolioType === "Options") {
      if (entryLots !== exitLots) {
        // if it's an open trade, prompt the user if they want to add the exercise/assign stock
      } else {
        // If the position is fully closed
        // and the exits have the same number of exercise/assignments, calculate the pnl between them
        // then add to the pnl (this is done in the pnlcalculation function, not actually here)
        if (countExerciseAssignIsEqual(exitExecution)) {
        } else {
          const countExerciseAssignVals = countExerciseAssign(exitExecution);
          const netExerciseAssignLots = Math.abs(
            countExerciseAssignVals.numExerciseLots -
              countExerciseAssignVals.numAssignLots
          );
          optionEntryId = uuidv4();
          const stockTrade = chosenEntries.filter(
            (entry) => entry.entryId === rowData.current.idLinks[0]
          )[0];
          let stockEntryLots = 0;
          const stockMultiExec = stockTrade
            ? stockTrade.entry.multiExecution
            : [];
          for (let im = 0, jm = stockMultiExec.length; im < jm; im++) {
            stockEntryLots =
              stockEntryLots + Number(stockMultiExec[im].lotSize);
          }
          stockEntryLots = fixRound(stockEntryLots); // fix rounding issue
          const stockTotalLots = stockEntryLots;
          if (stockTotalLots === netExerciseAssignLots * 100) {
            // Dont add an exercise/assign stock order if the total lots match
            // we look for the entry lots of the exercise/asign stock
            // and compare to the options trade currently visible
          } else {
            store.dispatch(setLoaderState({ loading: true }));
            setTimeout(() => {
              store.dispatch(setLoaderState({ loading: false }));
            }, 1000);
            for (let i = 0, j = exitExecution.length; i < j; i++) {
              const predate = exitExecution[i].endDateTime;
              const optionStartDate = new Date(predate);
              if (exitExecution[i].exercised === "Exercised") {
                addOptionsStockTrade = true;
                if (exitExecution[i].exitlegType === "Long Call") {
                  let optionISODate = optionStartDate;
                  let entryexecution = {
                    id: uuidv4(),
                    lotSize: Number(exitExecution[i].exitLotSize) * 100,
                    entryPrice: exitExecution[i].exitstrikePrice,
                    startDateTime: optionISODate,
                    expectedEntry: "",
                    strikePrice: "",
                    expirationDate: "",
                    legType: "",
                  };
                  newdata = {
                    entryId: optionEntryId,
                    entry: {
                      pictures: ["N/A"],
                      symbol: rowData.current.symbol,
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: chosenPortfolioName,
                      selectedPortfolioType: "Exercise Stock",
                      orderType: "Long",
                      orderNumber: "",
                      dayOfWeek: weekday[optionStartDate.getDay()],
                      monthOfYear: montharr[optionStartDate.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: "",
                      commissions: 0,
                      fees: 0,
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [entryexecution],
                      exitExecution: [],
                      startDateTime: optionISODate,
                      endDateTime: "",
                      tags: [],
                      verifiedLevel: 2,
                      idLinks: [tradeEntryId],
                    },
                  };
                } else if (exitExecution[i].exitlegType === "Long Put") {
                  let optionISODate = optionStartDate;
                  let entryexecution = {
                    id: uuidv4(),
                    lotSize: Number(exitExecution[i].exitLotSize) * 100,
                    entryPrice: exitExecution[i].exitstrikePrice,
                    startDateTime: optionISODate,
                    expectedEntry: "",
                    strikePrice: "",
                    expirationDate: "",
                    legType: "",
                  };
                  newdata = {
                    entryId: optionEntryId,
                    entry: {
                      pictures: ["N/A"],
                      symbol: rowData.current.symbol,
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: chosenPortfolioName,
                      selectedPortfolioType: "Exercise Stock",
                      orderType: "Short",
                      orderNumber: "",
                      dayOfWeek: weekday[optionStartDate.getDay()],
                      monthOfYear: montharr[optionStartDate.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: "",
                      commissions: 0,
                      fees: 0,
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [entryexecution],
                      exitExecution: [],
                      startDateTime: optionISODate,
                      endDateTime: "",
                      tags: [],
                      verifiedLevel: 2,
                      idLinks: [tradeEntryId],
                    },
                  };
                }
              } else if (exitExecution[i].exercised === "Assigned") {
                addOptionsStockTrade = true;
                if (exitExecution[i].exitlegType === "Short Call") {
                  let optionISODate = optionStartDate;
                  let entryexecution = {
                    id: uuidv4(),
                    lotSize: Number(exitExecution[i].exitLotSize) * 100,
                    entryPrice: exitExecution[i].exitstrikePrice,
                    startDateTime: optionISODate,
                    expectedEntry: "",
                    strikePrice: "",
                    expirationDate: "",
                    legType: "",
                  };
                  newdata = {
                    entryId: optionEntryId,
                    entry: {
                      pictures: ["N/A"],
                      symbol: rowData.current.symbol,
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: chosenPortfolioName,
                      selectedPortfolioType: "Assign Stock",
                      orderType: "Short",
                      orderNumber: "",
                      dayOfWeek: weekday[optionStartDate.getDay()],
                      monthOfYear: montharr[optionStartDate.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: "",
                      commissions: 0,
                      fees: 0,
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [entryexecution],
                      exitExecution: [],
                      startDateTime: optionISODate,
                      endDateTime: "",
                      tags: [],
                      verifiedLevel: 2,
                      idLinks: [tradeEntryId],
                    },
                  };
                } else if (exitExecution[i].exitlegType === "Short Put") {
                  let optionISODate = optionStartDate;
                  let entryexecution = {
                    id: uuidv4(),
                    lotSize: Number(exitExecution[i].exitLotSize) * 100,
                    entryPrice: exitExecution[i].exitstrikePrice,
                    startDateTime: optionISODate,
                    expectedEntry: "",
                    strikePrice: "",
                    expirationDate: "",
                    legType: "",
                  };
                  newdata = {
                    entryId: optionEntryId,
                    entry: {
                      pictures: ["N/A"],
                      symbol: rowData.current.symbol,
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: chosenPortfolioName,
                      selectedPortfolioType: "Assign Stock",
                      orderType: "Long",
                      orderNumber: "",
                      dayOfWeek: weekday[optionStartDate.getDay()],
                      monthOfYear: montharr[optionStartDate.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: "",
                      commissions: 0,
                      fees: 0,
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [entryexecution],
                      exitExecution: [],
                      startDateTime: optionISODate,
                      endDateTime: "",
                      tags: [],
                      verifiedLevel: 2,
                      idLinks: [tradeEntryId],
                    },
                  };
                }
              }
            }
          }
        }
      }
    }

    const newRow = {
      ...rowData.current,
      idLinks: optionEntryId === "" ? rowData.current.idLinks : [optionEntryId],
    };
    rowData.current = newRow;

    const verificationValue =
      rowData.current.orderType !== rowDataInit.orderType ||
      !isObjectEqual(rowData.current.symbol, rowDataInit.symbol) ||
      rowData.current.commissions !== rowDataInit.commissions ||
      rowData.current.fees !== rowDataInit.fees ||
      ((rowData.current.orderType === "Deposit" ||
        rowData.current.orderType === "Withdrawal" ||
        rowData.current.orderType === "Funding Payment" ||
        rowData.current.orderType === "Commit" ||
        rowData.current.orderType === "Approval" ||
        rowData.current.orderType === "Wrap" ||
        rowData.current.orderType === "Self" ||
        rowData.current.selectedPortfolioType === "Forex") &&
        rowData.current.profitLoss !== rowDataInit.profitLoss) ||
      !isObjectEqual(
        rowData.current.multiExecution,
        rowDataInit.multiExecution
      ) ||
      !isObjectEqual(rowData.current.exitExecution, rowDataInit.exitExecution)
        ? 0
        : rowData.current.verifiedLevel;

    const data = {
      entryId: tradeEntryId,
      entry: {
        pictures: rowData.current.pictures,
        symbol: rowData.current.symbol,
        strategy: rowData.current.strategy,
        selectedConfidence: rowData.current.selectedConfidence,
        selectedEmotion: rowData.current.selectedEmotion,
        selectedPhysical: rowData.current.selectedPhysical,
        selectedMarket: rowData.current.selectedMarket,
        selectedTimeframe: rowData.current.selectedTimeframe,
        selectedMistake: rowData.current.selectedMistake,
        selectedPortfolio: rowData.current.selectedPortfolio,
        selectedPortfolioType: rowData.current.selectedPortfolioType,
        orderType: rowData.current.orderType,
        orderNumber: rowData.current.orderNumber,
        dayOfWeek: weekday[actualStartDateTime.getDay()],
        monthOfYear: montharr[actualStartDateTime.getMonth()],
        stopLoss: rowData.current.stopLoss,
        takeProfit: rowData.current.takeProfit,
        profitLoss:
          rowData.current.orderType === "Deposit" ||
          rowData.current.orderType === "Withdrawal" ||
          rowData.current.orderType === "Funding Payment" ||
          rowData.current.orderType === "Commit" ||
          rowData.current.orderType === "Approval" ||
          rowData.current.orderType === "Wrap" ||
          rowData.current.orderType === "Self" ||
          rowData.current.selectedPortfolioType === "Forex"
            ? rowData.current.profitLoss
            : Number(
                profitLossCalculation(
                  rowData.current.multiExecution,
                  newExec,
                  rowData.current.orderType,
                  rowData.current.selectedPortfolioType,
                  rowData.current.symbol.pointValue,
                  false
                )
              ),
        commissions:
          rowData.current.commissions === ""
            ? 0
            : rowData.current.commissions < 0
            ? rowData.current.commissions
            : -1 * rowData.current.commissions,
        fees: rowData.current.fees === "" ? 0 : rowData.current.fees,
        maxAdEx: rowData.current.maxAdEx,
        maxFavEx: rowData.current.maxFavEx,
        comments: rowData.current.comments,
        multiExecution: rowData.current.multiExecution,
        exitExecution: newExec,
        startDateTime: !startDateTimeInner
          ? rowData.current.startDateTime
          : startDateTimeInner,
        endDateTime: !endDateTimezInner
          ? rowData.current.endDateTime
          : endDateTimezInner,
        tags: rowData.current.tags,
        verifiedLevel: verificationValue,
        idLinks:
          optionEntryId === "" ? rowData.current.idLinks : [optionEntryId],
        demoData: demoDataInit.demoData,
      },
    };
    let temparr = allportfolios.createdPortfolios.slice();

    for (
      var ie = 0, je = allportfolios.createdPortfolios.length;
      ie < je;
      ie++
    ) {
      let currentData = allportfolios.createdPortfolios[ie].entries.slice();
      let tempName = allportfolios.createdPortfolios[ie].name;
      if (tempName === "Default") {
      } else {
        // filter portfolios to only deal with the one we need
        temparr = temparr.filter((x) => x.name !== tempName);

        // remove the trade first only if the removeIndex exists
        let removeIndex = currentData
          .map((item) => item.entryId)
          .indexOf(tradeEntryId);
        ~removeIndex && currentData.splice(removeIndex, 1);

        // add back the updated trade only if the removeIndex exists
        ~removeIndex && currentData.splice(removeIndex, 0, data);
        ~removeIndex &&
          addOptionsStockTrade &&
          currentData.splice(removeIndex + 1, 0, newdata);
        let settingsTemp = allportfolios.createdPortfolios[ie].settings;
        ~removeIndex && (settingsTemp.verified = verificationValue);
        const portfolioedit = {
          name: allportfolios.createdPortfolios[ie].name,
          assetClasses: allportfolios.createdPortfolios[ie].assetClasses,
          settings: settingsTemp,
          entries: currentData,
        };

        // then add back to array of portfolios
        temparr = temparr.concat(portfolioedit);
      }
    }
    const finalPush = {
      chosenPortfolios: chosenPortfolios,
      createdPortfolios: temparr,
      globalSettings: allportfolios.globalSettings,
      version: Number(allportfolios.version) + 1,
      sharedTrades: allportfolios.sharedTrades,
    };
    //toComponentB(data); // NOT NEEDED
    allGlobalData.updateAllData(finalPush);
    const S3Data = {
      data: finalPush,
      userId: userId,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
    setisChanged2(false);
  }, [
    rowData,
    allGlobalData,
    allportfolios,
    chosenPortfolios,
    chosenEntries,
    demoDataInit,
    rowDataInit,
    /*     legType,
    exitlegType, */
    /*     exitLotSize,
    lotSize, */
    /*     rowData.current.execstartDateTime,
    strikePrice,
    expirationDate, */
    tradeEntryId,
    userId,
  ]);
  const deleteTrades = async () => {
    let temparr = allportfolios.createdPortfolios.slice();
    for (
      var ie = 0, je = allportfolios.createdPortfolios.length;
      ie < je;
      ie++
    ) {
      let currentData = allportfolios.createdPortfolios[ie].entries.slice();
      let tempName = allportfolios.createdPortfolios[ie].name;
      if (tempName === "Default") {
      } else {
        // filter portfolios to only deal with the one we need
        temparr = temparr.filter((x) => x.name !== tempName);

        // remove the trade
        let removeIndex = currentData
          .map((item) => item.entryId)
          .indexOf(tradeEntryId);
        ~removeIndex && currentData.splice(removeIndex, 1);

        let settingsTemp = allportfolios.createdPortfolios[ie].settings;
        ~removeIndex && (settingsTemp.verified = 0);
        const portfolioedit = {
          name: allportfolios.createdPortfolios[ie].name,
          assetClasses: allportfolios.createdPortfolios[ie].assetClasses,
          settings: settingsTemp,
          entries: currentData,
        };

        // then add back to array of portfolios
        temparr = temparr.concat(portfolioedit);
      }
    }
    const finalPush = {
      chosenPortfolios: chosenPortfolios,
      createdPortfolios: temparr,
      globalSettings: allportfolios.globalSettings,
      version: Number(allportfolios.version) + 1,
      sharedTrades: allportfolios.sharedTrades,
    };
    localStorage.removeItem("editTrade");
    localStorage.removeItem("editTradeId");
    localStorage.setItem("tradeDeleted", "yes");
    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      userId,
      history
    );

    allGlobalData.updateAllData(finalPush); // this one needs to run on the res
    const S3Data = {
      data: finalPush,
      userId: userId,
    };
    const finalAwait = async () => {
      const S3PictureDataDelete = {
        data: {
          pictureKeys: rowData.current.pictures,
        },
      };
      let numTrades = 0;
      const createdportfoliosInner = createdPortfolios;

      // Find number of trades containing this key
      createdportfoliosInner.forEach((portfolio) => {
        const portfolioEntries = portfolio.entries;
        portfolioEntries.forEach((entry) => {
          const pictures = entry.entry.pictures;
          if (isArray(rowData.current.pictures)) {
            if (
              rowData.current.pictures.some((key) => pictures.includes(key))
            ) {
              numTrades += 1;
            }
          }
        });
      });
      // only delete the picture from aws if the picture only exists in this trade
      numTrades === 1 && (await deletePictures(S3PictureDataDelete));
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      history.push("/Dashboard");
    };
    await finalAwait();
  };

  useEffect(() => {
    // checks every 200ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await editTrades();
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    props,
    allData,
    firstUpdate,
    callcounter,
    editTrades,
    finalStateTimeChanged,
    isDBRunning,
  ]);

  /*   const noteComponentCallback = useCallback(() => {
    //forceUpdate();
    return (
      <NoteComponent
        editCommentsState={editcomments}
        originalState={rowData.current.comments}
        setNoteState={(e) => {
          const newRow = {
            ...rowData.current,
            comments: e,
          };
          handleChange(newRow);
          forceUpdate();
        }}
      />
    );
  }, [rowData, editcomments]); */

  // next, previous, and viee options tsock order buttons
  const viewExerciseStockOrder = useCallback(
    async (tradeId) => {
      setTimeout(async () => {
        if (isChanged2) {
          await editTrades();
        }
        const tradeFiltered = chosenEntries.filter(
          (entry) => entry.entryId === tradeId
        )[0];
        const exerciseIndex = chosenEntries
          .map(function (e) {
            return e.entryId;
          })
          .indexOf(tradeFiltered.entryId);
        if (index > 0) {
          const updatedState = {
            ...rowData.current,
            orderType: tradeFiltered.entry.orderType,
            orderNumber: tradeFiltered.entry.orderNumber,
            symbol: tradeFiltered.entry.symbol,
            strategy: tradeFiltered.entry.strategy,
            selectedConfidence: tradeFiltered.entry.selectedConfidence,
            selectedEmotion: tradeFiltered.entry.selectedEmotion,
            selectedMarket: tradeFiltered.entry.selectedMarket,
            selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
            selectedMistake: tradeFiltered.entry.selectedMistake,
            endDateTime: tradeFiltered.entry.endDateTime,
            startDateTime: tradeFiltered.entry.startDateTime,
            pictures: tradeFiltered.entry.pictures,
            stopLoss: tradeFiltered.entry.stopLoss,
            takeProfit: tradeFiltered.entry.takeProfit,
            multiExecution: tradeFiltered.entry.multiExecution,
            lotSize: "",
            entryPrice: "",
            execstartDateTime: "",
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
            exitLotSize: "",
            exitPrice: "",
            execendDateTime: "",
            expectedExit: "",
            exitstrikePrice: "",
            exitexpirationDate: "",
            exitlegType: "",
            exitExecution: tradeFiltered.entry.exitExecution,
            profitLoss: tradeFiltered.entry.profitLoss,
            commissions: tradeFiltered.entry.commissions,
            fees: tradeFiltered.entry.fees,
            maxAdEx: tradeFiltered.entry.maxAdEx,
            maxFavEx: tradeFiltered.entry.maxFavEx,
            comments: tradeFiltered.entry.comments,
            selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
            selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
            tags: tradeFiltered.entry.tags,
            selectedPhysical: tradeFiltered.entry.selectedPhysical,
            verifiedLevel: tradeFiltered.entry.verifiedLevel,
            idLinks: tradeFiltered.entry.idLinks,
          };
          const newData = {
            pictures: tradeFiltered.entry.pictures,
            symbol: tradeFiltered.entry.symbol,
            strategy: tradeFiltered.entry.strategy,
            selectedConfidence: tradeFiltered.entry.selectedConfidence,
            selectedEmotion: tradeFiltered.entry.selectedEmotion,
            selectedPhysical: tradeFiltered.entry.selectedPhysical,
            selectedMarket: tradeFiltered.entry.selectedMarket,
            selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
            selectedMistake: tradeFiltered.entry.selectedMistake,
            selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
            selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
            orderType: tradeFiltered.entry.orderType,
            orderNumber: tradeFiltered.entry.orderNumber,
            dayOfWeek: tradeFiltered.entry.dayOfWeek,
            monthOfYear: tradeFiltered.entry.monthOfYear,
            stopLoss: tradeFiltered.entry.stopLoss,
            takeProfit: tradeFiltered.entry.takeProfit,
            profitLoss: tradeFiltered.entry.profitLoss,
            commissions: tradeFiltered.entry.commissions,
            fees: tradeFiltered.entry.fees,
            maxAdEx: tradeFiltered.entry.maxAdEx,
            maxFavEx: tradeFiltered.entry.maxFavEx,
            comments: tradeFiltered.entry.comments,
            multiExecution: tradeFiltered.entry.multiExecution,
            exitExecution: tradeFiltered.entry.exitExecution,
            endDateTime: tradeFiltered.entry.endDateTime,
            startDateTime: tradeFiltered.entry.startDateTime,
            tags: tradeFiltered.entry.tags,
            verifiedLevel: tradeFiltered.entry.verifiedLevel,
            idLinks: tradeFiltered.entry.idLinks,
          };
          setrowDataInit({
            ...rowDataInit,
            ...newData,
          });
          localStorage.setItem("editTrade", JSON.stringify(newData));
          localStorage.removeItem("editTradeId");
          setisChanged(false);
          setisChanged2(false);
          retrievePictures();
          rowData.current = updatedState;
          setIndex(exerciseIndex);
          settradeEntryId(tradeFiltered.entryId);
          props.setTradeId(tradeFiltered.entryId);
          setBalance(tradeFiltered.balance);
          forceUpdate();
        }
      }, 1);
    },
    [
      rowData,
      isChanged2,
      editTrades,
      chosenEntries,
      forceUpdate,
      index,
      props,
      rowDataInit,
    ]
  );

  const getANDsetpreviousTrade = useCallback(async () => {
    setTimeout(async () => {
      if (isChanged2) {
        await editTrades();
      }
      const previousIndex = index - 1;
      if (index > 0) {
        const tradeFiltered = chosenEntries[previousIndex];
        const updatedState = {
          ...rowData.current,
          orderType: tradeFiltered.entry.orderType,
          orderNumber: tradeFiltered.entry.orderNumber,
          symbol: tradeFiltered.entry.symbol,
          strategy: tradeFiltered.entry.strategy,
          selectedConfidence: tradeFiltered.entry.selectedConfidence,
          selectedEmotion: tradeFiltered.entry.selectedEmotion,
          selectedMarket: tradeFiltered.entry.selectedMarket,
          selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
          selectedMistake: tradeFiltered.entry.selectedMistake,
          endDateTime: tradeFiltered.entry.endDateTime,
          startDateTime: tradeFiltered.entry.startDateTime,
          pictures: tradeFiltered.entry.pictures,
          stopLoss: tradeFiltered.entry.stopLoss,
          takeProfit: tradeFiltered.entry.takeProfit,
          multiExecution: tradeFiltered.entry.multiExecution,
          lotSize: "",
          entryPrice: "",
          execstartDateTime: "",
          expectedEntry: "",
          strikePrice: "",
          expirationDate: "",
          legType: "",
          exitLotSize: "",
          exitPrice: "",
          execendDateTime: "",
          expectedExit: "",
          exitstrikePrice: "",
          exitexpirationDate: "",
          exitlegType: "",
          exitExecution: tradeFiltered.entry.exitExecution,
          profitLoss: tradeFiltered.entry.profitLoss,
          commissions: tradeFiltered.entry.commissions,
          fees: tradeFiltered.entry.fees,
          maxAdEx: tradeFiltered.entry.maxAdEx,
          maxFavEx: tradeFiltered.entry.maxFavEx,
          comments: tradeFiltered.entry.comments,
          selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
          selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
          tags: tradeFiltered.entry.tags,
          selectedPhysical: tradeFiltered.entry.selectedPhysical,
          verifiedLevel: tradeFiltered.entry.verifiedLevel,
          idLinks: tradeFiltered.entry.idLinks,
        };
        const newData = {
          pictures: tradeFiltered.entry.pictures,
          symbol: tradeFiltered.entry.symbol,
          strategy: tradeFiltered.entry.strategy,
          selectedConfidence: tradeFiltered.entry.selectedConfidence,
          selectedEmotion: tradeFiltered.entry.selectedEmotion,
          selectedPhysical: tradeFiltered.entry.selectedPhysical,
          selectedMarket: tradeFiltered.entry.selectedMarket,
          selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
          selectedMistake: tradeFiltered.entry.selectedMistake,
          selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
          selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
          orderType: tradeFiltered.entry.orderType,
          orderNumber: tradeFiltered.entry.orderNumber,
          dayOfWeek: tradeFiltered.entry.dayOfWeek,
          monthOfYear: tradeFiltered.entry.monthOfYear,
          stopLoss: tradeFiltered.entry.stopLoss,
          takeProfit: tradeFiltered.entry.takeProfit,
          profitLoss: tradeFiltered.entry.profitLoss,
          commissions: tradeFiltered.entry.commissions,
          fees: tradeFiltered.entry.fees,
          maxAdEx: tradeFiltered.entry.maxAdEx,
          maxFavEx: tradeFiltered.entry.maxFavEx,
          comments: tradeFiltered.entry.comments,
          multiExecution: tradeFiltered.entry.multiExecution,
          exitExecution: tradeFiltered.entry.exitExecution,
          endDateTime: tradeFiltered.entry.endDateTime,
          startDateTime: tradeFiltered.entry.startDateTime,
          tags: tradeFiltered.entry.tags,
          verifiedLevel: tradeFiltered.entry.verifiedLevel,
          idLinks: tradeFiltered.entry.idLinks,
        };
        setrowDataInit({
          ...rowDataInit,
          ...newData,
        });
        localStorage.setItem("editTrade", JSON.stringify(newData));
        localStorage.removeItem("editTradeId");
        setisChanged(false);
        setisChanged2(false);
        rowData.current = updatedState;
        setIndex(previousIndex);
        settradeEntryId(tradeFiltered.entryId);
        props.setTradeId(tradeFiltered.entryId);
        setBalance(tradeFiltered.balance);
        retrievePictures();
        forceUpdate();
        if (index <= 1) {
          setshowNextTradeButton(true);
          setshowPrevTradeButton(false);
        } else {
          setshowNextTradeButton(true);
          setshowPrevTradeButton(true);
        }
      } else {
        setshowNextTradeButton(true);
        setshowPrevTradeButton(false);
      }
    }, 1);
  }, [
    rowData,
    isChanged2,
    editTrades,
    chosenEntries,
    forceUpdate,
    index,
    props,
    rowDataInit,
  ]);
  const getANDsetnextTrade = useCallback(async () => {
    setTimeout(async () => {
      if (isChanged2) {
        await editTrades();
      }
      const nextIndex = index + 1;
      if (index < chosenEntries.length - 1) {
        const tradeFiltered = chosenEntries[nextIndex];
        const updatedState = {
          ...rowData.current,
          orderType: tradeFiltered.entry.orderType,
          orderNumber: tradeFiltered.entry.orderNumber,
          symbol: tradeFiltered.entry.symbol,
          strategy: tradeFiltered.entry.strategy,
          selectedConfidence: tradeFiltered.entry.selectedConfidence,
          selectedEmotion: tradeFiltered.entry.selectedEmotion,
          selectedMarket: tradeFiltered.entry.selectedMarket,
          selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
          selectedMistake: tradeFiltered.entry.selectedMistake,
          endDateTime: tradeFiltered.entry.endDateTime,
          startDateTime: tradeFiltered.entry.startDateTime,
          pictures: tradeFiltered.entry.pictures,
          stopLoss: tradeFiltered.entry.stopLoss,
          takeProfit: tradeFiltered.entry.takeProfit,
          multiExecution: tradeFiltered.entry.multiExecution,
          lotSize: "",
          entryPrice: "",
          execstartDateTime: "",
          expectedEntry: "",
          strikePrice: "",
          expirationDate: "",
          legType: "",
          exitLotSize: "",
          exitPrice: "",
          execendDateTime: "",
          expectedExit: "",
          exitstrikePrice: "",
          exitexpirationDate: "",
          exitlegType: "",
          exitExecution: tradeFiltered.entry.exitExecution,
          profitLoss: tradeFiltered.entry.profitLoss,
          commissions: tradeFiltered.entry.commissions,
          fees: tradeFiltered.entry.fees,
          maxAdEx: tradeFiltered.entry.maxAdEx,
          maxFavEx: tradeFiltered.entry.maxFavEx,
          comments: tradeFiltered.entry.comments,
          selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
          selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
          tags: tradeFiltered.entry.tags,
          selectedPhysical: tradeFiltered.entry.selectedPhysical,
          verifiedLevel: tradeFiltered.entry.verifiedLevel,
          idLinks: tradeFiltered.entry.idLinks,
        };
        const newData = {
          pictures: tradeFiltered.entry.pictures,
          symbol: tradeFiltered.entry.symbol,
          strategy: tradeFiltered.entry.strategy,
          selectedConfidence: tradeFiltered.entry.selectedConfidence,
          selectedEmotion: tradeFiltered.entry.selectedEmotion,
          selectedPhysical: tradeFiltered.entry.selectedPhysical,
          selectedMarket: tradeFiltered.entry.selectedMarket,
          selectedTimeframe: tradeFiltered.entry.selectedTimeframe,
          selectedMistake: tradeFiltered.entry.selectedMistake,
          selectedPortfolio: tradeFiltered.entry.selectedPortfolio,
          selectedPortfolioType: tradeFiltered.entry.selectedPortfolioType,
          orderType: tradeFiltered.entry.orderType,
          orderNumber: tradeFiltered.entry.orderNumber,
          dayOfWeek: tradeFiltered.entry.dayOfWeek,
          monthOfYear: tradeFiltered.entry.monthOfYear,
          stopLoss: tradeFiltered.entry.stopLoss,
          takeProfit: tradeFiltered.entry.takeProfit,
          profitLoss: tradeFiltered.entry.profitLoss,
          commissions: tradeFiltered.entry.commissions,
          fees: tradeFiltered.entry.fees,
          maxAdEx: tradeFiltered.entry.maxAdEx,
          maxFavEx: tradeFiltered.entry.maxFavEx,
          comments: tradeFiltered.entry.comments,
          multiExecution: tradeFiltered.entry.multiExecution,
          exitExecution: tradeFiltered.entry.exitExecution,
          endDateTime: tradeFiltered.entry.endDateTime,
          startDateTime: tradeFiltered.entry.startDateTime,
          tags: tradeFiltered.entry.tags,
          verifiedLevel: tradeFiltered.entry.verifiedLevel,
          idLinks: tradeFiltered.entry.idLinks,
        };
        setrowDataInit({
          ...rowDataInit,
          ...newData,
        });
        rowData.current = updatedState;
        localStorage.setItem("editTrade", JSON.stringify(newData));
        localStorage.removeItem("editTradeId");
        setisChanged(false);
        setisChanged2(false);
        setIndex(nextIndex);
        settradeEntryId(tradeFiltered.entryId);
        props.setTradeId(tradeFiltered.entryId);
        setBalance(tradeFiltered.balance);
        retrievePictures();
        forceUpdate();
        if (index >= chosenEntries.length - 2) {
          setshowNextTradeButton(false);
          setshowPrevTradeButton(true);
        } else {
          setshowNextTradeButton(true);
          setshowPrevTradeButton(true);
        }
      } else {
        setshowNextTradeButton(false);
        setshowPrevTradeButton(true);
      }
    }, 1);
  }, [
    rowData,
    isChanged2,
    editTrades,
    chosenEntries,
    forceUpdate,
    index,
    props,
    rowDataInit,
  ]);

  // -------------- MISCELLANEOUS FUNCTIONS --------------

  // Tiering
  let showINIT = true;
  let showMaster = false;
  let showultimate = false;

  if (tier === "free") {
    if (pictureslength >= 1) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "pro" && active) {
    if (pictureslength >= 5) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "oldpro" && active) {
    if (pictureslength >= 10) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  } else if (tier === "master" && active) {
    if (pictureslength >= 10) {
      showINIT = true;
    } else {
      showINIT = false;
    }
    showMaster = true;
  } else if (tier === "admin" && active) {
    showINIT = false;
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    if (pictureslength >= 25) {
      showINIT = true;
    } else {
      showINIT = false;
    }
    showMaster = true;
    showultimate = true;
  } else {
    if (pictureslength >= 1) {
      showINIT = true;
    } else {
      showINIT = false;
    }
  }
  const portfolioColorPre = showMaster
    ? createdPortfolioMatching[0]?.settings?.portfolioColor
    : "";
  const portfolioColorDark = shadeColor(portfolioColorPre, -30);

  const colorRGB = portfolioColorPre && hexToRgb(portfolioColorPre);
  const portfolioColor = showMaster
    ? colorRGB && `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 1)`
    : "";
  const textColor = showMaster
    ? textColorBasedOnBgColor(portfolioColorPre)
    : "#deebf7";

  const handleEntrySlippage = (e) => {
    sethasEntrySlippage(e.target.checked);
    if (!e.target.checked) {
      rowData.current = {
        ...rowData.current,
        expectedEntry: prevEntry,
      };
    } else {
      rowData.current = {
        ...rowData.current,
        expectedEntry: rowData.current.entryPrice,
      };
    }
    if (entrycheckbuffer === 0) {
      setentrycheckbuffer(1);
    } else if (entrycheckbuffer === 1) {
      setentrycheckbuffer(0);
    }
  };
  const handleExitSlippage = (e) => {
    sethasExitSlippage(e.target.checked);
    if (!e.target.checked) {
      rowData.current = {
        ...rowData.current,
        expectedExit: prevExit,
      };
    } else {
      rowData.current = {
        ...rowData.current,
        expectedExit: rowData.current.exitPrice,
      };
    }
    if (exitcheckbuffer === 0) {
      setexitcheckbuffer(1);
    } else if (exitcheckbuffer === 1) {
      setexitcheckbuffer(0);
    }
  };
  const dwCheck =
    rowData.current.orderType.toLowerCase() !== "deposit" &&
    rowData.current.orderType.toLowerCase() !== "withdrawal" &&
    rowData.current.orderType.toLowerCase() !== "funding payment" &&
    rowData.current.orderType.toLowerCase() !== "approval" &&
    rowData.current.orderType.toLowerCase() !== "commit" &&
    rowData.current.orderType.toLowerCase() !== "wrap" &&
    rowData.current.orderType.toLowerCase() !== "self"
      ? true
      : false;

  const createSelectedArray = (arr) => {
    const arrCheck = isArray(arr);
    let createdArray = [];
    arrCheck &&
      arr.forEach((item) => {
        createdArray = createdArray.concat(item.label);
      });
    if (!arrCheck && arr) {
      createdArray = createdArray.concat(arr.label);
    } else if (!arrCheck && !arr) {
      createdArray = "";
    }
    return createdArray;
  };
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      alignItems: "left",
      justifyContent: "left",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        height: "10px",
        width: "10px",
        color: "#1f2831",
        backgroundColor: "#1f2831",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "10px",
        border: "#a4a9b1",
        color: "#1f2831",
        backgroundColor: "#1f2831",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        color: "#14181e",
        backgroundColor: "#14181e",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
        WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
      },
      "::-webkit-scrollbar-corner": {
        backgroundColor: "#1f2831",
        borderBottomRightRadius: "5px",
      },
    }),
  };
  const movecursor = {
    paddingLeft: "10px",
  };
  const createSelectedState = (arr) => {
    const arrCheck = isArray(arr);
    let createdArray = [];
    arrCheck &&
      arr.forEach((item) => {
        createdArray = createdArray.concat({ value: item, label: item });
      });
    return createdArray;
  };

  const commentsDisplayFunction = () => {
    if (rowData.current.comments === "") {
      return <div className="nocomments">{"Add Notes"}</div>;
    } else {
      return <div style={{ marginTop: 8 }}>{rowData.current.comments}</div>;
    }
  };
  const PreviewImage = async (e) => {
    store.dispatch(setLoaderState({ loading: true }));
    const filesinputelem = document.getElementById("addpictureinput");
    const fileslargeelem = document.getElementById("addpictureinputlarge");
    const filesInit = fileslargeelem ? fileslargeelem : filesinputelem;
    let files = filesInit ? [...filesInit.files] : [];

    // Tiering logic
    if (tier === "free") {
      // If the user has 2 pictures, prompt them to upgrade
      if (files.length + pictureslength > 1) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 1 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    } else if (tier === "pro" && active) {
      // If the user has 5 pictures, prompt them
      if (files.length + pictureslength > 5) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 5 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    } else if (tier === "oldpro" && active) {
      // If the user has 10 pictures, prompt them
      if (files.length + pictureslength > 10) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 10 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    } else if (tier === "master" && active) {
      // If the user has 10 pictures, prompt them
      if (files.length + pictureslength > 10) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 10 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    } else if (tier === "admin" && active) {
    } else if (tier === "ultimate" && active) {
      // If the user has 25 pictures, prompt them
      if (files.length + pictureslength > 25) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 25 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    } else {
      if (files.length + pictureslength > 1) {
        alert("Tier limit exceeded; files have been truncated");
        let list = new DataTransfer();
        const forlooplength = 1 - pictureslength;
        const forlooplengthACTUAL = forlooplength === 0 ? 1 : forlooplength;
        for (let i = 0; i < forlooplengthACTUAL; i++) {
          list.items.add(files[i]);
        }
        files = list.files;
      } else {
      }
    }
    let currentPics =
      rowData.current.pictures[0] === "N/A"
        ? []
        : [...rowData.current.pictures];
    let currentPicsData = picData.slice();

    const upload = async (name, type, result) => {
      let userKey = user.sub + "::";
      let pictureKey = userKey + name;
      currentPics.push(pictureKey);
      const base64Data = result;
      //console.log("Encoded Base 64 File String:", reader.result);

      /******************* for Binary ***********************/
      /*             var data = base64Data.split(",")[1];
      var binaryBlob = atob(data); */
      //console.log("Encoded Binary File String:", binaryBlob);
      const S3PictureData = {
        data: {
          base64Data: base64Data,
          name: name,
          type: type,
          userId: user.sub,
        },
      };
      await uploadPicture(S3PictureData, type, history);
      currentPicsData.push({
        base64Data: base64Data,
        pictureKey: pictureKey,
      });
      setPicData(currentPicsData);
      const newRow = { ...rowData.current, pictures: currentPics };
      handleChange(newRow);
      store.dispatch(setLoaderState({ loading: false }));
      forceUpdate();
    };
    const filesAsArray = [...files];

    Promise.all(filesAsArray.map(readContent)).then((files) =>
      Promise.all(
        files.map((file) => upload(file.name, file.type, file.content))
      )
    );
    //store.dispatch(setLoaderState({ loading: true }));
    //setRowData(imagebuff);
  };
  const resetTrade = () => {
    setrowDataInit(rowDataInit);
    handleChange(rowDataInit);
    setisChanged(false);
    setisChanged2(false);
    forceUpdate();
  };

  // ------------ Miscellaneous ------------
  const entryExecsd = rowData.current.multiExecution;
  const exitExecs = rowData.current.exitExecution;
  const entryExitValues = getEntryExitLots(entryExecsd, exitExecs);
  const entryLotsd = entryExitValues.entryLots;
  const exitLotsd = entryExitValues.exitLots;
  const openUnits = entryLotsd - exitLotsd;
  const asset =
    rowData.current.selectedPortfolioType === "Stocks"
      ? openUnits === 1
        ? "Share"
        : "Shares"
      : rowData.current.selectedPortfolioType === "Options" ||
        rowData.current.selectedPortfolioType === "Futures"
      ? openUnits === 1
        ? "Contract"
        : "Contracts"
      : rowData.current.selectedPortfolioType === "Forex"
      ? openUnits === 1
        ? "Lot"
        : "Lots"
      : rowData.current.selectedPortfolioType === "Crypto"
      ? openUnits === 1
        ? "Coin/Token"
        : "Coins/Tokens"
      : rowData.current.selectedPortfolioType === "CFD"
      ? "Quantity"
      : openUnits === 1
      ? "Unit"
      : "Units";

  // ------------ ENTRY EXECUTION RELATED FUNCTIONS ------------
  const openModal = async () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    sethasEntrySlippage(false);
    if (addState === "Edit") {
      const newData = {
        ...rowData.current,
        multiExecution: rowData.current.multiExecution,
        lotSize: "",
        entryPrice: "",
        execstartDateTime: null,
        expectedEntry: "",
        strikePrice: "",
        expirationDate: null,
        legType: false,
      };
      rowData.current = newData;
      forceUpdate();
    }
  };
  const closeModalOnlyClose = () => {
    setIsOpen(false);
  };

  const handleDeleteExecution = (i) => {
    const newData = {
      ...rowData.current,
      multiExecution:
        rowData?.current.multiExecution?.length <= 1
          ? rowData.current.multiExecution
          : rowData.current.multiExecution.filter(
              (exec) => !isObjectEqual(exec, i) //JSON.stringify(exec) !== JSON.stringify(i)
            ),
    };
    if (rowData?.current.multiExecution?.length <= 1) {
      setEntryWarning(true);
      setTimeout(() => {
        setEntryWarning(false);
      }, 7000);
    }
    handleChange(newData);
  };
  const handleClearMultiExecution = () => {
    const buyPutLegType = document.getElementById("EditBuyPut");
    const sellPutLegType = document.getElementById("EditSellPut");
    const buyCallLegType = document.getElementById("EditBuyCall");
    const sellCallLegType = document.getElementById("EditSellCall");
    rowData.current = {
      ...rowData.current,
      lotSize: "",
      entryPrice: "",
      execstartDateTime: null,
      expectedEntry: "",
      strikePrice: "",
      expirationDate: null,
      legType: false,
    };
    forceUpdate();
    var x2 = document.getElementsByName("dateTimeNowAdd");
    x2.forEach((leg) => {
      leg.checked = false;
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      dateTimeOpenedSame: false,
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      expirationDateSame: false,
    });
    setTimeout(() => {
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        buyPutLegType.checked = false;
        buyCallLegType.checked = false;
        sellPutLegType.checked = false;
        sellCallLegType.checked = false;
      }
    }, 0);
  };
  const saveExecution = () => {
    $(".saveExecutionButton").attr("disabled", true);
    let execution = {
      id: addExitState === "Add" ? uuidv4() : selectedId,
      lotSize: Number(rowData.current.lotSize),
      entryPrice: rowData.current.entryPrice,
      startDateTime: rowData.current.execstartDateTime,
      expectedEntry: rowData.current.expectedEntry,
      strikePrice: rowData.current.strikePrice,
      expirationDate: rowData.current.expirationDate,
      legType: rowData.current.legType,
    };
    const filteredExecArrayByInitExec = rowData.current.multiExecution.filter(
      (exec) => !isObjectEqual(exec, multiExecutionBeforeChange) //JSON.stringify(exec) !== JSON.stringify(multiExecutionBeforeChange)
    );
    const newExecArray = [...filteredExecArrayByInitExec, execution];
    const startDateTimez = getStartDateTime(newExecArray);

    if (addState === "Add") {
      // if the user adds an execution, just clear the modal and add the execution
      // this allows for smoother adding of multiple executions
      /*       const entryExecs = rowData.current.multiExecution;
      let entryLots = 0;
      entryExecs.forEach((exec) => {
        entryLots += Number(exec.lotSize);
      });
      let exitLots = 0;
      const exitExecs = newExecArray;
      exitExecs.forEach((exec) => {
        exitLots += Number(exec.exitLotSize);
      });
      entryLots = fixRound(entryLots); // fix rounding issue
      exitLots = fixRound(exitLots);
      if (exitLots > entryLots) {
        $(".addexecedittradeerror2").css({ display: "block" });
        setTimeout(() => {
          $(".addexecedittradeerror2").css({ display: "none" });
        }, 7000);
        return;
      } else if (exitLots === entryLots) {
        closeModalOnlyClose();
      } */
      const newRow = {
        ...rowData.current,
        multiExecution: newExecArray,
        startDateTime: startDateTimez,
        lotSize: "",
        entryPrice: "",
        execstartDateTime: null,
        expectedEntry: "",
        strikePrice: "",
        expirationDate: null,
        legType: false,
        verifiedLevel: !isObjectEqual(newExecArray, rowDataInit.multiExecution)
          ? 0
          : 2,
      };
      const buyPutLegType = document.getElementById("EditBuyPut");
      const sellPutLegType = document.getElementById("EditSellPut");
      const buyCallLegType = document.getElementById("EditBuyCall");
      const sellCallLegType = document.getElementById("EditSellCall");
      setTimeout(() => {
        if (
          !buyPutLegType ||
          !buyCallLegType ||
          !sellPutLegType ||
          !sellCallLegType
        ) {
        } else {
          buyPutLegType.checked = false;
          buyCallLegType.checked = false;
          sellPutLegType.checked = false;
          sellCallLegType.checked = false;
        }
      }, 0);
      handleChange(newRow);
      const updatedState = {
        ...rowData.current,
        ...newRow,
      };
      rowData.current = updatedState;
    } else {
      const newRow = {
        ...rowData.current,
        multiExecution: newExecArray,
        startDateTime: startDateTimez,
      };
      rowData.current = newRow;
      handleChange(newRow);
      closeModalOnlyClose();
    }
    sethasEntrySlippage(false);
    forceUpdate();
  };
  const addEntryExecutionInit = async () => {
    await openModal();
    setaddState("Add");
    rowData.current = {
      ...rowData.current,
      lotSize: "",
      entryPrice: "",
      execstartDateTime: null,
      expectedEntry: "",
      strikePrice: "",
      expirationDate: null,
      legType: false,
    };
    forceUpdate();
  };

  const editExecutions = async (execution) => {
    await openModal();
    setaddState("Edit");
    execution.expectedEntry === execution.entryPrice &&
      execution.entryPrice !== "" &&
      execution.expectedEntry !== "" &&
      sethasEntrySlippage(true);
    const buyPutLegType = document.getElementById("EditBuyPut");
    const sellPutLegType = document.getElementById("EditSellPut");
    const buyCallLegType = document.getElementById("EditBuyCall");
    const sellCallLegType = document.getElementById("EditSellCall");
    setIsExecutionDisabled(true);
    let executionInner = {
      id: execution.id,
      lotSize: execution.lotSize,
      entryPrice: execution.entryPrice,
      startDateTime: execution.startDateTime,
      expectedEntry: execution.expectedEntry,
      strikePrice: execution.strikePrice,
      expirationDate: execution.expirationDate,
      legType: execution.legType,
    };
    const newData = {
      ...rowData.current,
      lotSize: execution.lotSize,
      entryPrice: execution.entryPrice,
      execstartDateTime: execution.startDateTime,
      expectedEntry: execution.expectedEntry,
      strikePrice: execution.strikePrice,
      expirationDate: execution.expirationDate,
      legType: execution.legType,
    };
    rowData.current = newData;
    prevEntry = execution.expectedEntry;
    setselectedId(execution.id);
    forceUpdate();
    setmultiExecutionBeforeChange(executionInner);
    setTimeout(() => {
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.legType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.legType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.legType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.legType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
    }, 0);
  };
  const editExecutionButtons = () => {
    let multiExecCurrent = rowData.current.multiExecution.slice();
    multiExecCurrent.sort(function (a, b) {
      var c = new Date(a.startDateTime);
      var d = new Date(b.startDateTime);
      return c - d;
    });
    let editButtons = multiExecCurrent.map((i) => (
      <span className="executionwrapper" key={"buttonkey" + uuidv4()}>
        <button
          className="editExecutionButton noselect"
          onClick={() => editExecutions(i)}
        >
          {rowData.current.orderType === "Multi-Leg Strategy"
            ? i.legType + ", "
            : ""}
          {moment(i.startDateTime).format("MM/DD/YYYY")}
          {", "}
          {i.lotSize}
          {", "}
          {i.entryPrice}
          {rowData.current.selectedPortfolioType === "Options" &&
            ", " +
              (!i.expirationDate
                ? ""
                : moment(i.expirationDate).format("MM/DD/YYYY")) +
              ", " +
              i.strikePrice}
          {", "}
          {i.expectedEntry}
          {/*             <table>
              <tr>
                <td className="editExecutionButtonText">{i.lotSize}</td>
                <td className="editExecutionButtonText">{i.entryPrice}</td>
                <td className="editExecutionButtonText">{i.expectedEntry}</td>
                <td className="editExecutionButtonText">
                  {moment(i.startDateTime).format("MM/DD/YYYY HH:mm:ss")}
                </td>
              </tr>
            </table> */}
        </button>
        <span
          className="deleteExecutionButton"
          onClick={() => handleDeleteExecution(i)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </span>
    ));
    const AddExecbutton = (
      <PopoverStickOnHover
        placement="bottom"
        delay={300}
        xAdjust={18}
        yAdjust={0}
        setClass="accountNumberSuperWrapper"
        keepOpen={false}
        component={
          <div
            style={{
              color: "#fbd665",
            }}
          >
            Add Execution
          </div>
        }
      >
        <button
          className="addexecedittrade"
          onClick={() => {
            addEntryExecutionInit();
          }}
          key="addexecedittradebutton"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </PopoverStickOnHover>
    );
    if (
      rowData.current.orderType.toLowerCase() !== "deposit" &&
      rowData.current.orderType.toLowerCase() !== "withdrawal"
    ) {
      editButtons.push(AddExecbutton);
    }

    const AddExecError = (
      <div
        className="addexecedittradeerror"
        key={"addexecedittradeerror87236478"}
      >{`Trades must have at least one execution.`}</div>
    );
    if (entryWarning) {
      editButtons.push(AddExecError);
    }
    return editButtons;
  };

  // ------------ EXIT EXECUTION RELATED FUNCTIONS ------------
  const openExitModal = async () => {
    setexitIsOpen(true);
  };
  const closeExitModal = () => {
    setexitIsOpen(false);
    sethasExitSlippage(false);
    if (addExitState === "Edit") {
      const newData = {
        ...rowData.current,
        exitExecution: rowData.current.exitExecution,
        exitLotSize: "",
        exitPrice: "",
        execendDateTime: null,
        expectedExit: "",
        exitstrikePrice: "",
        exitexpirationDate: null,
        exitlegType: false,
      };
      rowData.current = newData;
      forceUpdate();
    }
  };
  const closeExitModalOnlyClose = () => {
    setexitIsOpen(false);
  };
  const handleDeleteExitExecution = (i) => {
    const newData = {
      ...rowData.current,
      exitExecution: rowData.current.exitExecution.filter(
        (exec) => !isObjectEqual(exec, i) //JSON.stringify(exec) !== JSON.stringify(i)
      ),
    };
    handleChange(newData);
  };
  const handleClearExitExecution = () => {
    const buyPutLegType = document.getElementById("ExitBuyPut");
    const sellPutLegType = document.getElementById("ExitSellPut");
    const buyCallLegType = document.getElementById("ExitBuyCall");
    const sellCallLegType = document.getElementById("ExitSellCall");
    const exercisedElem = document.getElementById("optionExercised");
    const assignedElem = document.getElementById("optionAssigned");
    const expiredElem = document.getElementById("optionExpired");
    rowData.current = {
      ...rowData.current,
      exitLotSize: "",
      exitPrice: "",
      execendDateTime: null,
      expectedExit: "",
      exitstrikePrice: "",
      exitexpirationDate: null,
      exitlegType: false,
    };
    var x2 = document.getElementsByName("dateTimeNowAdd");
    x2.forEach((leg) => {
      leg.checked = false;
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      dateTimeClosedSame: false,
    });
    setdateTimeNowAdd({
      ...dateTimeNowAdd,
      exitExpirationDateSame: false,
    });
    forceUpdate();
    setexercised(false);
    setselectedId("");
    setTimeout(() => {
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        buyPutLegType.checked = false;
        buyCallLegType.checked = false;
        sellPutLegType.checked = false;
        sellCallLegType.checked = false;
      }
      if (!exercisedElem || !assignedElem) {
      } else {
        expiredElem.checked = false;
        exercisedElem.checked = false;
        assignedElem.checked = false;
      }
    }, 0);
  };
  const handleExitExecutionSave = () => {
    let execution = {
      id: addExitState === "Add" ? uuidv4() : selectedId,
      exitLotSize: Number(rowData.current.exitLotSize),
      exitPrice: rowData.current.exitPrice,
      endDateTime: rowData.current.execendDateTime,
      expectedExit: rowData.current.expectedExit,
      exitstrikePrice: rowData.current.exitstrikePrice,
      exitexpirationDate: rowData.current.exitexpirationDate,
      // if the exitlegtype doesn't exist, the user is not on a multilegstrategy
      // so just set to the original leg type
      exitlegType:
        !rowData.current.exitlegType || rowData.current.exitlegType === ""
          ? rowData.current.orderType
          : rowData.current.exitlegType,
      equityComponents: rowData.current.equityComponents,
      exercised: exercised,
    };
    const filteredExitExecArrayByInitExec =
      rowData.current.exitExecution.filter(
        (exec) => !isObjectEqual(exec, exitExecutionBeforeChange) // JSON.stringify(exec) !== JSON.stringify(exitExecutionBeforeChange)
      );
    const newExitExecArray = [...filteredExitExecArrayByInitExec, execution];
    let endDateTimez = getEndDateTime(newExitExecArray);
    const entryExecs = rowData.current.multiExecution;
    const exitExecs = newExitExecArray;
    const entryExitValues = getEntryExitLots(entryExecs, exitExecs);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    if (addExitState === "Add") {
      // if the user adds an execution, just clear the modal and add the execution
      // this allows for smoother adding of multiple executions

      if (exitLots > entryLots) {
        $(".addexecedittradeerror2").css({ display: "block" });
        setTimeout(() => {
          $(".addexecedittradeerror2").css({ display: "none" });
        }, 7000);
        return;
      } else if (exitLots === entryLots) {
        closeExitModalOnlyClose();
      } else {
      }
      const newRow = {
        ...rowData.current,
        exitExecution: newExitExecArray,
        endDateTime: endDateTimez,
        exitLotSize: "",
        exitPrice: "",
        execendDateTime: null,
        expectedExit: "",
        exitstrikePrice: "",
        exitexpirationDate: null,
        exitlegType: false,
        verifiedLevel: !isObjectEqual(
          newExitExecArray,
          rowDataInit.exitExecution
        )
          ? 0
          : 2,
      };
      const buyPutLegType = document.getElementById("ExitBuyPut");
      const sellPutLegType = document.getElementById("ExitSellPut");
      const buyCallLegType = document.getElementById("ExitBuyCall");
      const sellCallLegType = document.getElementById("ExitSellCall");
      const exercisedElem = document.getElementById("optionExercised");
      const assignedElem = document.getElementById("optionAssigned");
      const expiredElem = document.getElementById("optionExpired");
      setexercised(false);
      setselectedId("");
      setTimeout(() => {
        if (
          !buyPutLegType ||
          !buyCallLegType ||
          !sellPutLegType ||
          !sellCallLegType
        ) {
        } else {
          buyPutLegType.checked = false;
          buyCallLegType.checked = false;
          sellPutLegType.checked = false;
          sellCallLegType.checked = false;
        }
        if (!exercisedElem || !assignedElem) {
        } else {
          expiredElem.checked = false;
          exercisedElem.checked = false;
          assignedElem.checked = false;
        }
      }, 0);

      handleChange(newRow);
      const updatedState = {
        ...rowData.current,
        ...newRow,
      };
      rowData.current = updatedState;
      $(".saveExecutionButton").attr("disabled", true);
    } else {
      if (exitLots > entryLots) {
        $(".addexecedittradeerror2").css({ display: "block" });
        setTimeout(() => {
          $(".addexecedittradeerror2").css({ display: "none" });
        }, 7000);
        return;
      } else {
      }
      const newRow = {
        ...rowData.current,
        exitExecution: newExitExecArray,
        endDateTime: endDateTimez,
      };
      rowData.current = newRow;
      handleChange(newRow);
      $(".saveExecutionButton").attr("disabled", true);
      closeExitModalOnlyClose();
    }
    sethasExitSlippage(false);

    forceUpdate();
  };
  const addExitExecutionInit = async () => {
    const entryExecs = rowData.current.multiExecution;
    const exitExecs = rowData.current.exitExecution;
    const entryExitValues = getEntryExitLots(entryExecs, exitExecs);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    if (exitLots !== entryLots) {
      setExitError(false);
      await openExitModal();
      setaddExitState("Add");
      rowData.current = {
        ...rowData.current,
        exitLotSize: "",
        exitPrice: "",
        execendDateTime: null,
        expectedExit: "",
        exitstrikePrice: "",
        exitexpirationDate: null,
        exitlegType: false,
      };
      forceUpdate();
      setexercised(false);
      setselectedId("");
    } else {
      setExitError(true);
      setTimeout(() => {
        setExitError(false);
      }, 7000);
    }
  };

  const editExitExecutions = async (execution) => {
    await openExitModal();
    setaddExitState("Edit");
    const buyPutLegType = document.getElementById("ExitBuyPut");
    const sellPutLegType = document.getElementById("ExitSellPut");
    const buyCallLegType = document.getElementById("ExitBuyCall");
    const sellCallLegType = document.getElementById("ExitSellCall");

    const exercisedElem = document.getElementById("optionExercised");
    const assignedElem = document.getElementById("optionAssigned");
    const expiredElem = document.getElementById("optionExpired");
    setIsExitExecutionDisabled(true);
    execution.expectedExit === execution.exitPrice &&
      execution.exitPrice !== "" &&
      execution.expectedExit !== "" &&
      sethasExitSlippage(true);
    const equityComponent = createEquityComponent(
      execution.exitstrikePrice,
      execution.exitLotSize,
      execution.exercised
    );
    let executionInner = {
      id: execution.id,
      exitLotSize: execution.exitLotSize,
      exitPrice: execution.exitPrice,
      endDateTime: execution.endDateTime,
      expectedExit: execution.expectedExit,
      exitstrikePrice: execution.exitstrikePrice,
      exitexpirationDate: execution.exitexpirationDate,
      exitlegType: execution.exitlegType,
      equityComponents: execution.equityComponents,
      exercised: execution.exercised,
    };
    const newData = {
      ...rowData.current,
      exitLotSize: execution.exitLotSize,
      exitPrice: execution.exitPrice,
      execendDateTime: execution.endDateTime,
      expectedExit: execution.expectedExit,
      exitstrikePrice: execution.exitstrikePrice,
      exitexpirationDate: execution.exitexpirationDate,
      exitlegType: execution.exitlegType,
      equityComponents:
        execution.equityComponents /* !execution?.equityComponents?.length
        ? equityComponent
        : execution.equityComponents, */,
    };
    setexercised(execution.exercised);
    setselectedId(execution.id);
    rowData.current = newData;
    forceUpdate();
    setexitExecutionBeforeChange(executionInner);
    setTimeout(() => {
      if (
        !buyPutLegType ||
        !buyCallLegType ||
        !sellPutLegType ||
        !sellCallLegType
      ) {
      } else {
        if (execution.exitlegType === "Long Put") {
          buyPutLegType.checked = true;
        } else if (execution.exitlegType === "Long Call") {
          buyCallLegType.checked = true;
        } else if (execution.exitlegType === "Short Put") {
          sellPutLegType.checked = true;
        } else if (execution.exitlegType === "Short Call") {
          sellCallLegType.checked = true;
        }
      }
      if (!exercisedElem || !assignedElem) {
      } else {
        if (execution.exercised === "Exercised") {
          exercisedElem.checked = true;
        } else if (execution.exercised === "Assigned") {
          assignedElem.checked = true;
        } else if (execution.exercised === "Expired") {
          expiredElem.checked = true;
        } else if (!execution.exercised) {
          expiredElem.checked = false;
          exercisedElem.checked = false;
          assignedElem.checked = false;
        }
      }
    }, 0);
  };

  const editExitExecutionButtons = () => {
    let exitExecCurrent = rowData.current.exitExecution.slice();
    let idLinks = rowData.current.idLinks;
    const numExercise = countExerciseAssign(exitExecCurrent).numExercise;
    const numAssign = countExerciseAssign(exitExecCurrent).numAssign;

    exitExecCurrent.sort(function (a, b) {
      var c = new Date(a.endDateTime);
      var d = new Date(b.endDateTime);
      return c - d;
    });
    let editexitButtons = exitExecCurrent.map((i) => (
      <span className="executionwrapper" key={"buttonexitkey" + uuidv4()}>
        <button
          className={
            i.exercised === "Expired"
              ? "expiredcolor editExecutionButton noselect"
              : i.exercised === "Exercised"
              ? "exercisedcolor editExecutionButton noselect"
              : i.exercised === "Assigned"
              ? "assignedcolor editExecutionButton noselect"
              : "editExecutionButton noselect"
          }
          onClick={() => editExitExecutions(i)}
        >
          {rowData.current.orderType === "Multi-Leg Strategy"
            ? i.exitlegType + ", "
            : ""}
          {moment(i.endDateTime).format("MM/DD/YYYY")}
          {", "}
          {i.exitLotSize}
          {", "}
          {i.exitPrice}
          {rowData.current.selectedPortfolioType === "Options" &&
            ", " +
              (!i.exitexpirationDate
                ? ""
                : moment(i.exitexpirationDate).format("MM/DD/YYYY")) +
              ", " +
              i.exitstrikePrice +
              ", " +
              i.exercised}
          {", "}
          {i.expectedExit}
          {/*             <table>
              <tr>
                <td className="editExecutionButtonText">{i.lotSize}</td>
                <td className="editExecutionButtonText">{i.entryPrice}</td>
                <td className="editExecutionButtonText">{i.expectedEntry}</td>
                <td className="editExecutionButtonText">
                  {moment(i.startDateTime).format("MM/DD/YYYY HH:mm:ss")}
                </td>
              </tr>
            </table> */}
        </button>
        <span
          className="deleteExecutionButton"
          onClick={() => handleDeleteExitExecution(i)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </span>
    ));
    const AddExecbutton = (
      <PopoverStickOnHover
        placement="bottom"
        delay={300}
        xAdjust={18}
        yAdjust={0}
        setClass="accountNumberSuperWrapper"
        keepOpen={false}
        component={
          <div
            style={{
              color: "#fbd665",
            }}
          >
            Add Execution
          </div>
        }
      >
        <button
          className="addexecedittrade"
          onClick={() => {
            addExitExecutionInit();
          }}
          key="addexecutionbutton"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </PopoverStickOnHover>
    );

    // click to view the exercise or assign stock order
    const ViewExerciseButton = (id) => {
      let buttontext = "";
      if (rowData.current.selectedPortfolioType === "Options") {
        buttontext =
          numAssign === numExercise
            ? ""
            : numAssign < numExercise
            ? "Exercise Stock"
            : "Assign Stock";
      } else if (
        rowData.current.selectedPortfolioType === "Exercise Stock" ||
        rowData.current.selectedPortfolioType === "Assign Stock"
      ) {
        buttontext = "Original Options";
      }

      return (
        <button
          className="viewExerciseColor viewExerciseStockOrderbutton noselect"
          onClick={() => {
            viewExerciseStockOrder(id);
          }}
          key="viewExerciseStockOrderbutton"
        >
          {`View ${buttontext} Order`}
        </button>
      );
    };
    // push the buttons to the array
    isArray(idLinks) &&
      idLinks.forEach((id) => {
        const viewelem = ViewExerciseButton(id);
        if (rowData.current.selectedPortfolioType === "Options") {
          numAssign !== numExercise && editexitButtons.push(viewelem);
        } else if (
          rowData.current.selectedPortfolioType === "Exercise Stock" ||
          rowData.current.selectedPortfolioType === "Assign Stock"
        ) {
          editexitButtons.push(viewelem);
        }
      });
    const AddExecError = (
      <div className="addexecedittradeerror">{`Total Exit ${asset} must be less than the total Entry ${asset}.`}</div>
    );
    if (
      rowData.current.orderType.toLowerCase() !== "deposit" &&
      rowData.current.orderType.toLowerCase() !== "withdrawal"
    ) {
      editexitButtons.push(AddExecbutton);
    }
    if (exitError) {
      editexitButtons.push(AddExecError);
    }
    return editexitButtons;
  };
  const featureSelect = {
    show: true,
    featureSelect: "10 pictures per trade",
    blur: false,
  };
  const featureSelectMaster = {
    show: true,
    featureSelect: "25 pictures per trade",
    blur: false,
    tierText: "master",
  };
  const featureSelectultimate = {
    show: true,
    featureSelect: "100 pictures per trade",
    blur: false,
    tierText: "ultimate",
  };
  const featureSelect2 = {
    show: true,
    featureSelect: "Advanced reports",
    blur: false,
  };
  const featureSelect3 = {
    show: true,
    featureSelect: "Commissions/Fees",
    blur: false,
  };
  const featureSelect4 = {
    show: true,
    featureSelect: "Slippage",
    blur: false,
    tierText: "master",
  };
  const picOnClick = () => {
    if (tier === "free") {
      // If the user has 1 picture, prompt them to upgrade to pro
      if (pictureslength >= 1) {
        props.setshowUpgradeModal(featureSelect);
      } else {
      }
    } else if (tier === "pro" && active) {
      // If the user has 5 pictures, prompt them to upgrade to master
      if (pictureslength >= 5) {
        props.setshowUpgradeModal(featureSelectMaster);
      } else {
      }
    } else if (tier === "oldpro" && active) {
      // If the user has 10 pictures, prompt them to upgrade to master
      if (pictureslength >= 10) {
        props.setshowUpgradeModal(featureSelectMaster);
      } else {
      }
    } else if (tier === "master" && active) {
      // If the user has 10 pictures, prompt them to upgrade
      if (pictureslength >= 10) {
        props.setshowUpgradeModal(featureSelectultimate);
      } else {
      }
    } else if (tier === "admin" && active) {
    } else if (tier === "ultimate" && active) {
      // If the user has 25 pictures, prompt them to upgrade
      if (pictureslength >= 25) {
        //props.setshowUpgradeModal(featureSelectultimate);
      } else {
      }
    } else {
      // If the user has 1 picture, prompt them to upgrade
      if (pictureslength >= 1) {
        props.setshowUpgradeModal(featureSelect);
      } else {
      }
    }
  };

  // PICTURES TIER HANDLING
  if (tier === "free") {
    // If the user has 1 picture, prompt them to upgrade
    if (pictureslength >= 1) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  } else if (tier === "pro" && active) {
    if (pictureslength >= 5) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  } else if (tier === "oldpro" && active) {
    if (pictureslength >= 10) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  } else if (tier === "master" && active) {
    if (pictureslength >= 10) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  } else if (tier === "ultimate" && active) {
    if (pictureslength >= 25) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  } else if (tier === "admin" && active) {
    picDiv.current = (
      <input
        disabled={showINIT}
        multiple
        type="file"
        accept=".png, .jpg, .jpeg"
        className="addpictureinput"
        id="addpictureinput"
        onChange={(e) => {
          PreviewImage();
        }}
      />
    );
  } else {
    // If the user has 1 picture, prompt them to upgrade
    if (pictureslength >= 1) {
      picDiv.current = "";
    } else {
      picDiv.current = (
        <input
          disabled={showINIT}
          multiple
          type="file"
          accept=".png, .jpg, .jpeg"
          className="addpictureinput"
          id="addpictureinput"
          onChange={(e) => {
            PreviewImage();
          }}
        />
      );
    }
  }
  // ---------------------------

  // Display images function return the images in divs
  const imageDisplayFunction = () => {
    const picturesArray = rowData.current.pictures;
    if (picturesArray[0] === "N/A") {
      return (
        <div className="showtradepictureswrapper">
          <div className="nopics">
            <input
              multiple
              type="file"
              accept=".png, .jpg, .jpeg"
              className="addpictureinputlarge"
              id="addpictureinputlarge"
              onChange={(e) => {
                PreviewImage(e);
              }}
            />
            {"Add Pictures"}
          </div>
        </div>
      );
    } else {
      let picturesNew = picData?.map(
        (pic) =>
          pic && (
            <span className="innerpicturewrapper" key={uuidv4()}>
              <span>
                <img
                  className="innerpictures"
                  src={pic.base64Data}
                  alt=""
                  height={"80%"}
                  key={uuidv4()}
                  onClick={(e) => {
                    setpicId(pic);
                    openModalpic(true);
                  }}
                />
              </span>
              <span
                className="deletepicbutton"
                onClick={async () => {
                  const createdportfoliosInner = createdPortfolios;
                  let numTrades = 0;
                  // Find number of pictures in all trades containing this key
                  createdportfoliosInner.forEach((portfolio) => {
                    const portfolioEntries = portfolio.entries;
                    portfolioEntries.forEach((entry) => {
                      const pictures = entry.entry.pictures;
                      if (pictures.includes(pic.pictureKey)) {
                        numTrades += 1;
                      }
                    });
                  });
                  let currentPicsInner = [...picturesArray];
                  const index = currentPicsInner.indexOf(pic.pictureKey);
                  if (index > -1) {
                    currentPicsInner.splice(index, 1); // 2nd parameter means remove one item only
                  }
                  if (!currentPicsInner.length) {
                    currentPicsInner = ["N/A"];
                  }
                  let picDataState = picData.slice();
                  picDataState = picDataState.filter(
                    (picture) => picture.pictureKey !== pic.pictureKey
                  );
                  setPicData(picDataState);
                  const newRow = {
                    ...rowData.current,
                    pictures: currentPicsInner,
                  };
                  const S3PictureDataDelete = {
                    data: {
                      pictureKeys: [pic.pictureKey],
                    },
                  };
                  // only delete the picture gtom aws if there is only one trade with this picture
                  numTrades === 1 &&
                    (await deletePictures(S3PictureDataDelete, history));
                  handleChange(newRow);
                  forceUpdate();
                  // MIGHT NEED TO UNPIN FILES HERE IN THE FUTURE
                }}
              ></span>
            </span>
          )
      );

      const addPictureButton = (
        <div
          onClick={() => {
            picOnClick();
          }}
          className="addpicturebutton"
          key="addpicturebutton"
        >
          <div className="addpicturebuttoninner">
            {picDiv.current}
            <div className="addpicturebuttoninnerplus">{"+"}</div>
          </div>
        </div>
      );
      picturesNew.unshift(addPictureButton);

      // only chow certain amount based on tier
      if (tier === "free") {
        picturesNew = picturesNew.slice(0, 2);
      } else if (tier === "pro" && active) {
        picturesNew = picturesNew.slice(0, 6);
      } else if (tier === "oldpro" && active) {
        picturesNew = picturesNew.slice(0, 11);
      } else if (tier === "master" && active) {
        picturesNew = picturesNew.slice(0, 11);
      } else if (tier === "ultimate" && active) {
        picturesNew = picturesNew.slice(0, 26);
      } else if (tier === "admin" && active) {
        // admins have no limitation
      } else {
        picturesNew = picturesNew.slice(0, 2);
      }
      return <div className="showtradepictureswrappernon">{picturesNew}</div>;
    }
  };
  // These are gross P/L
  const grossprofitLoss = supportedCryptosCheck
    ? rowData.current.orderType === "Deposit" ||
      rowData.current.orderType === "Withdrawal" ||
      rowData.current.orderType === "Funding Payment" ||
      rowData.current.orderType === "Commit" ||
      rowData.current.orderType === "Approval" ||
      rowData.current.orderType === "Wrap" ||
      rowData.current.orderType === "Self" ||
      rowData.current.selectedPortfolioType === "Forex"
      ? parseFloat(rowData.current.profitLoss).toFixed(6)
      : parseFloat(
          Number(
            profitLossCalculation(
              rowData.current.multiExecution,
              rowData.current.exitExecution,
              rowData.current.orderType,
              rowData.current.selectedPortfolioType,
              rowData.current.symbol.pointValue,
              false
            )
          ).toFixed(6)
        )
    : displayAsCurrency(
        rowData.current.orderType === "Deposit" ||
          rowData.current.orderType === "Withdrawal" ||
          rowData.current.orderType === "Funding Payment" ||
          rowData.current.orderType === "Commit" ||
          rowData.current.orderType === "Approval" ||
          rowData.current.orderType === "Wrap" ||
          rowData.current.orderType === "Self" ||
          rowData.current.selectedPortfolioType === "Forex"
          ? Number(rowData.current.profitLoss)
          : Number(
              profitLossCalculation(
                rowData.current.multiExecution,
                rowData.current.exitExecution,
                rowData.current.orderType,
                rowData.current.selectedPortfolioType,
                rowData.current.symbol.pointValue,
                false
              )
            )
      );

  // This is the net P/L
  const netprofitLoss = supportedCryptosCheck
    ? rowData.current.orderType === "Deposit" ||
      rowData.current.orderType === "Withdrawal" ||
      rowData.current.orderType === "Funding Payment" ||
      rowData.current.orderType === "Commit" ||
      rowData.current.orderType === "Approval" ||
      rowData.current.orderType === "Wrap" ||
      rowData.current.orderType === "Self" ||
      rowData.current.selectedPortfolioType === "Forex"
      ? parseFloat(rowData.current.profitLoss).toFixed(6)
      : parseFloat(
          returnNetPLDollar(
            Number(
              profitLossCalculation(
                rowData.current.multiExecution,
                rowData.current.exitExecution,
                rowData.current.orderType,
                rowData.current.selectedPortfolioType,
                rowData.current.symbol.pointValue,
                false
              )
            ),
            Number(rowData.current.commissions),
            Number(rowData.current.fees),
            settings.calculationType
          )
        ).toFixed(6)
    : displayAsCurrency(
        rowData.current.orderType === "Deposit" ||
          rowData.current.orderType === "Withdrawal" ||
          rowData.current.orderType === "Funding Payment" ||
          rowData.current.orderType === "Commit" ||
          rowData.current.orderType === "Approval" ||
          rowData.current.orderType === "Wrap" ||
          rowData.current.orderType === "Self" ||
          rowData.current.selectedPortfolioType === "Forex"
          ? returnNetPLDollar(
              Number(rowData.current.profitLoss),
              Number(rowData.current.commissions),
              Number(rowData.current.fees),
              settings.calculationType
            )
          : returnNetPLDollar(
              Number(
                profitLossCalculation(
                  rowData.current.multiExecution,
                  rowData.current.exitExecution,
                  rowData.current.orderType,
                  rowData.current.selectedPortfolioType,
                  rowData.current.symbol.pointValue,
                  false
                )
              ),
              Number(rowData.current.commissions),
              Number(rowData.current.fees),
              settings.calculationType
            )
      );
  const accountBalance = Number(balance) - Number(netprofitLoss); // THIS IS THE CONTINUALLTY UPDATED ACCOUNT BALANCE FOR ONLY THIS PAGE'S STATE. THIS IS NOT UPDATED IN THE GLOBAL LOGBOOK STATE!

  const percentProfitLoss =
    rowData.current.orderType === "Deposit" ||
    rowData.current.orderType === "Withdrawal" ||
    rowData.current.orderType === "Funding Payment" ||
    rowData.current.orderType === "Commit" ||
    rowData.current.orderType === "Approval" ||
    rowData.current.orderType === "Wrap" ||
    rowData.current.orderType === "Self"
      ? 0
      : (netprofitLoss / Number(accountBalance)) * 100;

  const color =
    grossprofitLoss > 0
      ? "#55f8a6"
      : grossprofitLoss < 0
      ? "#fa798b"
      : "#808080";
  let toprowclass = "";
  let toptypeclass = "";
  let toptypeclassml = "";
  let optionstrategyclass = "";
  const dateDiff =
    rowData.current.orderType === "Deposit" ||
    rowData.current.orderType === "Withdrawal" ||
    rowData.current.orderType === "Funding Payment" ||
    rowData.current.orderType === "Commit" ||
    rowData.current.orderType === "Approval" ||
    rowData.current.orderType === "Wrap" ||
    rowData.current.orderType === "Self"
      ? "-"
      : exitLotsd !== entryLotsd
      ? getDateDifference(dateNow, rowData.current.startDateTime)
      : getDateDifference(
          rowData.current.endDateTime,
          rowData.current.startDateTime
        );
  if (exitLotsd !== entryLotsd) {
    toprowclass = "tradecalculatedinfo_toprowopen";
    toptypeclass = "tradecalculatedinfo_ordertypeopen";
    toptypeclassml = "tradecalculatedinfo_ordertypeopenml";
    optionstrategyclass = "tradecalculatedinfo_typestrategyopen";
  } else {
    if (
      rowData.current.orderType === "Deposit" ||
      longShortDelineation(
        rowData.current.orderType,
        rowData.current.multiExecution
      ) === "Long"
    ) {
      toprowclass = "tradecalculatedinfo_toprowlong";
      toptypeclass = "tradecalculatedinfo_ordertypelong";
      toptypeclassml = "tradecalculatedinfo_ordertypelongml";
      optionstrategyclass = "tradecalculatedinfo_typestrategylong";
    } else if (rowData.current.orderType === "Funding Payment") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else if (rowData.current.orderType === "Commit") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else if (rowData.current.orderType === "Approval") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else if (rowData.current.orderType === "Self") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else if (rowData.current.orderType === "NFT") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else if (rowData.current.orderType === "Wrap") {
      toprowclass = "tradecalculatedinfo_toprowfp";
      toptypeclass = "tradecalculatedinfo_ordertypefp";
    } else {
      toprowclass = "tradecalculatedinfo_toprowshort";
      toptypeclass = "tradecalculatedinfo_ordertypeshort";
      toptypeclassml = "tradecalculatedinfo_ordertypeshortml";
      optionstrategyclass = "tradecalculatedinfo_typestrategyshort";
    }
  }

  const slippage = slippageCalculation(
    rowData.current.multiExecution,
    rowData.current.exitExecution,
    rowData.current.orderType,
    rowData.current.selectedPortfolioType,
    rowData.current.symbol.pointValue,
    false,
    rowData.current.profitLoss
  );
  const slippagecolor =
    slippage > 0 ? "#55f8a6" : slippage < 0 ? "#fa798b" : "#808080";

  return (
    <div className="viewtradetradeformwrapper">
      <Modal
        ariaHideApp={false}
        id="ConfirmationModal"
        className="ConfirmationModal"
        isOpen={deleteOpen}
        onRequestClose={closeModaldel}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        <h3 className="modalSymbolHeader4">{"Delete Trade"}</h3>

        <div className="deletemodaltext">
          Are you sure you want to delete this trade? This action CANNOT be
          undone!
        </div>
        <button
          className="edittradehistroytablebutton movedeletebutton"
          onClick={() => {
            store.dispatch(setLoaderState({ loading: true }));
            deleteTrades();
            setDeleteOpen(false);
            forceUpdate();
          }}
        >
          {"Delete"}
        </button>
        <button
          className="clearEditExecutionButton2 movecancelbutton"
          onClick={() => {
            setDeleteOpen(false);
          }}
        >
          {"Cancel"}
        </button>
      </Modal>
      <Modal
        ariaHideApp={false}
        id="ShareModal"
        className="ShareModal"
        isOpen={shareIsOpen}
        onRequestClose={closeModalshare}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <div>
          <div
            className="exitsharebutton"
            onClick={() => {
              closeModalshare();
            }}
          ></div>
          <div>Share To:</div>
          <div>Discord</div>
          <div>Twitter (X)</div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        id="ConfirmationModal"
        className="ConfirmationModal"
        isOpen={picIsOpen}
        onRequestClose={closeModalpic}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <div
          className="exitpicbutton"
          onClick={() => {
            closeModalpic();
          }}
        ></div>
        <img
          className="innerpicturesBG"
          src={picId.base64Data}
          alt=""
          key={"bigpicture" + picId.pictureKey}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        id="PictureModal"
        className="PictureModal"
        isOpen={picIsOpen}
        onRequestClose={closeModalpic}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <div
          className="exitpicbutton"
          onClick={() => {
            closeModalpic();
          }}
        ></div>
        <img
          className="innerpicturesBG"
          src={picId.base64Data}
          alt=""
          key={"bigpicture" + picId.pictureKey}
        />
      </Modal>
      {/* ---------- EXIT MODAL ---------- */}
      <Modal
        ariaHideApp={false}
        id="TableModal"
        className={
          rowData.current.selectedPortfolioType === "Options"
            ? "TableModalOptions"
            : "TableModal"
        }
        isOpen={exitmodalIsOpen}
        onRequestClose={closeExitModal}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        <div id="centerit">
          {rowData.current.orderType !== "Multi-Leg Strategy" ? (
            <h3 className="modalSymbolHeader5">{`${addExitState} Exit Execution`}</h3>
          ) : (
            <h3 className="modalSymbolHeader5">{`${addExitState} Exit Leg`}</h3>
          )}
          <button
            className="deletetradesclosebutton"
            onClick={() => {
              closeExitModal();
            }}
          >
            <DeleteButton />
          </button>
          <div className="editExecutionModalInputs3">
            {rowData.current.selectedPortfolioType === "Options" &&
            rowData.current.orderType === "Multi-Leg Strategy" ? (
              <span>
                {rowData.current.exitlegType ? (
                  <label>Option Leg Type</label>
                ) : (
                  <label>
                    Option Leg Type{" "}
                    <em className="requiredlegtype">&nbsp;Required</em>
                  </label>
                )}
                <span id="optionsselectwrapper-upper">
                  <div className="buy-call-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="legType2"
                        id="ExitBuyCall"
                        value={"Long Call"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            exitlegType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-buy">
                      <label>{"Long Call"}</label>
                    </div>
                  </div>
                  <div className="optionsellcall-container-upper">
                    <label className="container checksygBC">
                      <input
                        type="radio"
                        name="legType2"
                        id="ExitSellCall"
                        value={"Short Call"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            exitlegType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Call"}</label>
                    </div>
                  </div>
                  <div className="buy-put-container">
                    <label className="container checksygBP">
                      <input
                        type="radio"
                        name="legType2"
                        id="ExitBuyPut"
                        value={"Long Put"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            exitlegType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Long Put"}</label>
                    </div>
                  </div>
                  <div className="optionsellput-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="legType2"
                        id="ExitSellPut"
                        value={"Short Put"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            exitlegType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Put"}</label>
                    </div>
                  </div>
                </span>
              </span>
            ) : (
              ""
            )}
            <div className="main-labels">
              <label
                className={
                  rowData.current.selectedPortfolioType === "Options"
                    ? "ANTselectionfieldsRequiredOPTION"
                    : "VTPselectionfieldsDT"
                }
              >
                {"Date & Time Closed"}{" "}
                <span className="container checksantcurretntime">
                  <input
                    type="checkbox"
                    id="boxNoEntrySlip"
                    name="dateTimeNowAdd"
                    value={dateTimeClosedSame}
                    onChange={(e) => {
                      if (e.target.checked) {
                        rowData.current = {
                          ...rowData.current,
                          execendDateTime: new Date(),
                        };
                      } else {
                        rowData.current = {
                          ...rowData.current,
                          execendDateTime: prevdateTimeClosedSame,
                        };
                      }
                      forceUpdate();
                      setdateTimeNowAdd({
                        ...dateTimeNowAdd,
                        dateTimeClosedSame: e.target.checked,
                      });
                    }}
                  />
                  <span className="checkmark checkmark-col1-2"></span>
                  <span
                    style={{ position: "relative", top: "1px", marginRight: 4 }}
                  >
                    &emsp;&ensp;Use Now
                  </span>
                </span>
                <span className="mandatory endDateTimeErrorMsg">{""}</span>
                {/*               <DateTime
                value={endDateTime}
                onChange={(e) => rowData.current = ({ ...rowData.current, endDateTime: e._d })}
                dateFormat="MM/DD/YYYY"
                timeFormat="HH:mm:ss"
                inputProps={inputProps}
              /> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        inputProps={{
                          ...props.inputProps,
                          placeholder: "Required",
                        }}
                        className="text-input-boxes required"
                      />
                    )}
                    views={["day", "year", "hours", "minutes", "seconds"]}
                    inputFormat="MM/DD/YYYY HH:mm:ss"
                    clearable
                    ampm={false}
                    value={rowData.current.execendDateTime}
                    name="endDateTime"
                    onChange={(e) => {
                      rowData.current = {
                        ...rowData.current,
                        execendDateTime: !e ? "" : e._d,
                      };
                      forceUpdate();
                    }}
                  />
                </LocalizationProvider>
              </label>
            </div>
            <div>
              <label
                className={
                  rowData.current.selectedPortfolioType === "Options"
                    ? "ANTselectionfieldsRequiredOPTION"
                    : "VTPselectionfields"
                }
              >
                {rowData.current.selectedPortfolioType === "Stocks"
                  ? "Shares"
                  : rowData.current.selectedPortfolioType === "Options" ||
                    rowData.current.selectedPortfolioType === "Futures"
                  ? "Contracts"
                  : rowData.current.selectedPortfolioType === "Forex"
                  ? "Lots"
                  : rowData.current.selectedPortfolioType === "Crypto"
                  ? "Coins/Tokens"
                  : rowData.current.selectedPortfolioType === "CFD"
                  ? "Quantity"
                  : "Units"}
                <span className="mandatory exitLotSizeErrorMsg">{""}</span>
                <input
                  name="exitLotSize"
                  style={movecursor}
                  value={rowData.current.exitLotSize}
                  onChange={(e) => {
                    rowData.current = {
                      ...rowData.current,
                      exitLotSize: e.target.value,
                    };
                    forceUpdate();
                  }}
                  className="text-input-boxes required"
                  required
                  placeholder="Required"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
              </label>
            </div>

            {/* --------- Exit Price component --------- */}
            <div className="main-labels">
              <label
                className={
                  rowData.current.selectedPortfolioType === "Options"
                    ? "ANTselectionfieldsRequiredOPTION"
                    : "VTPselectionfields"
                }
              >
                {rowData.current.selectedPortfolioType === "Options"
                  ? exercised === "Exercised"
                    ? "Premium at Exercise"
                    : exercised === "Assigned"
                    ? "Premium at Assignment"
                    : exercised === "Expired"
                    ? "Premium at Expiration"
                    : "Exit Premium"
                  : "Exit Price"}
                <span className="mandatory exitPriceErrorMsg">{""}</span>
                {exercised === "Exercised" ||
                exercised === "Assigned" ||
                exercised === "Expired" ? (
                  <input
                    name="exitPrice"
                    style={movecursor}
                    value={rowData.current.exitPrice}
                    onChange={(e) => {
                      rowData.current = {
                        ...rowData.current,
                        exitPrice: e.target.value,
                      };
                      forceUpdate();
                    }}
                    className="text-input-boxes"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                    placeholder="Optional"
                  />
                ) : (
                  <input
                    name="exitPrice"
                    style={movecursor}
                    value={rowData.current.exitPrice}
                    onChange={(e) => {
                      rowData.current = {
                        ...rowData.current,
                        exitPrice: e.target.value,
                      };
                      forceUpdate();
                    }}
                    className="text-input-boxes required"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                    required
                    placeholder="Required"
                  />
                )}
              </label>
            </div>
            {rowData.current.selectedPortfolioType === "Options" && (
              <span
                style={{
                  margin: "4px 0px 0px 0px",
                  left: "116px",
                  padding: "0px",
                  width: "auto",
                  position: "relative",
                  display: "flex",
                  height: "30px",
                }}
              >
                <div className="exercised-container-vtp">
                  <label
                    className={
                      exerciseDisable
                        ? "containerdis checksygBC"
                        : "container checksygBC"
                    }
                  >
                    <input
                      type="radio"
                      name="exercised"
                      id="optionExercised"
                      value={"Exercised"}
                      onClick={(e) => setexercised(e.target.value)}
                      disabled={exerciseDisable}
                    />
                    <span
                      className={
                        exerciseDisable ? "radiocircledis" : "radiocircle"
                      }
                    ></span>
                  </label>
                  <div
                    className={
                      exerciseDisable
                        ? "radio-label-sell-dis"
                        : "radio-label-sell"
                    }
                  >
                    <label>{"Exercised"}</label>
                  </div>
                </div>
                <div className="assigned-container">
                  <label
                    className={
                      assignDisable
                        ? "containerdis checksygBC"
                        : "container checksygBC"
                    }
                  >
                    <input
                      type="radio"
                      name="exercised"
                      id="optionAssigned"
                      value={"Assigned"}
                      onClick={(e) => setexercised(e.target.value)}
                      disabled={assignDisable}
                    />
                    <span
                      className={
                        assignDisable ? "radiocircledis" : "radiocircle"
                      }
                    ></span>
                  </label>
                  <div
                    className={
                      assignDisable
                        ? "radio-label-sell-dis"
                        : "radio-label-sell"
                    }
                  >
                    <label>{"Assigned"}</label>
                  </div>
                </div>
                <div className="expired-container">
                  <label className="container checksygBCC">
                    <input
                      type="radio"
                      name="exercised"
                      id="optionExpired"
                      value={"Expired"}
                      onClick={(e) => setexercised(e.target.value)}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Expired"}</label>
                  </div>
                </div>
              </span>
            )}
            {rowData.current.selectedPortfolioType === "Options" && (
              <div
                className={
                  rowData.current.orderType === "Multi-Leg Strategy"
                    ? "optionsOptionals2 topadjustoptionoptional2"
                    : "optionsOptionals23 topadjustoptionoptional2"
                }
              >
                {" "}
                <label className="ANTselectionfieldsOPTION">
                  {"Expiration Date"}{" "}
                  <span className="container checksantcurretntimeexp">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="dateTimeNowAdd"
                      value={exitExpirationDateSame}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let dateN = new Date();
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          rowData.current = {
                            ...rowData.current,
                            exitexpirationDate: dateN,
                          };
                        } else {
                          rowData.current = {
                            ...rowData.current,
                            exitexpirationDate: prevexitExpirationDateSame,
                          };
                        }
                        setdateTimeNowAdd({
                          ...dateTimeNowAdd,
                          exitExpirationDateSame: e.target.checked,
                        });
                      }}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span
                      style={{
                        position: "relative",
                        top: "1px",
                        marginRight: 4,
                      }}
                    >
                      &emsp;&ensp;Use Today
                    </span>
                  </span>
                  <span className="mandatory exitexpDateTimeErrorMsg">
                    {""}
                  </span>
                  {/*                 <DateTime
                  value={rowData.current.exitexpirationDate}
                  name="expirationDate"
                  onChange={(e) =>
                    rowData.current = ({ ...rowData.current, exitexpirationDate: e._d })
                  }
                  dateFormat="MM/DD/YYYY"
                  timeFormat={false}
                  inputProps={inputProps}
                /> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{
                            ...props.inputProps,
                            placeholder: "Required",
                          }}
                          className="text-input-boxes required"
                        />
                      )}
                      inputFormat="MM/DD/YYYY"
                      clearable
                      value={rowData.current.exitexpirationDate}
                      name="exitexpirationDate"
                      onChange={(e) => {
                        let dateN = !e ? "" : new Date(e._d);
                        if (dateN === "") {
                        } else {
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          rowData.current = {
                            ...rowData.current,
                            exitexpirationDate: dateN,
                          };
                          forceUpdate();
                        }
                      }}
                    />
                  </LocalizationProvider>
                </label>
                <div className="main-labels">
                  <label className="ANTselectionfieldsOPTION">
                    Strike Price
                    <span className="mandatory exitstrikePriceErrorMsg">
                      {""}
                    </span>
                    <input
                      name="exitstrikePrice"
                      style={movecursor}
                      value={rowData.current.exitstrikePrice}
                      onChange={(e) => {
                        rowData.current = {
                          ...rowData.current,
                          exitstrikePrice: e.target.value,
                        };
                        forceUpdate();
                      }}
                      placeholder="Required"
                      className="text-input-boxes required"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </label>
                </div>
                <div className="main-labels">
                  <label className="VTPselectionfieldsNOTRequiredOPTIONf">
                    <span style={{ marginRight: 4 }}>Expected Exit</span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to exit the trade at.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setexitIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedEntry"
                        id="expectedEntry"
                        style={movecursor}
                        disabled={!showMaster}
                        value={
                          hasEntrySlippage === false
                            ? rowData.current.expectedExit
                            : exitcheckbuffer === 0
                            ? rowData.current.expectedExit
                            : rowData.current.exitPrice
                        }
                        onChange={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            expectedExit: e.target.value,
                          };
                          forceUpdate();
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />
                    </div>
                  </label>
                </div>
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setexitIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checkssspv">
                    <input
                      type="checkbox"
                      id="boxNoExitSlip"
                      name="hasExitSlippage"
                      checked={hasExitSlippage}
                      disabled={!showMaster}
                      onClick={(e) => handleExitSlippage(e)}
                      onChange={(e) => handleExitSlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Exit Slippage
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you exited at the intended exit price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-3px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}
            {rowData.current.selectedPortfolioType === "Options" ? (
              ""
            ) : (
              <div>
                {" "}
                <div className="main-labels" style={{ marginBottom: "-4px" }}>
                  <label className={"VTPselectionfieldsNOTRequiredOPTION"}>
                    <span style={{ marginRight: 4 }}>Expected Exit</span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to exit the trade at.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setexitIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedExit"
                        style={movecursor}
                        disabled={!showMaster}
                        value={
                          hasExitSlippage === false
                            ? rowData.current.expectedExit
                            : exitcheckbuffer === 0
                            ? rowData.current.expectedExit
                            : rowData.current.exitPrice
                        }
                        onChange={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            expectedExit: e.target.value,
                          };
                          forceUpdate();
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />{" "}
                    </div>
                  </label>
                </div>
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setexitIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checksst">
                    <input
                      type="checkbox"
                      id="boxNoExitSlip"
                      name="hasExitSlippage"
                      checked={hasExitSlippage}
                      disabled={!showMaster}
                      onChange={(e) => handleExitSlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Exit Slippage
                    </span>{" "}
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you exited at the intended exit price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-3px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}{" "}
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="addexecedittradeerror2">{`Total Exit ${asset} must be less than the total Entry ${asset}.`}</div>
          <button
            name="clearExitExecutionButton"
            className="clearExitExecutionButton"
            onClick={() => handleClearExitExecution()}
          >
            {"Clear"}
          </button>
          <button
            name="cancelExecutionButton"
            className="cancelExecutionButton"
            onClick={() => {
              closeExitModal();
            }}
          >
            Cancel
          </button>
          <button
            id="saveExecutionButton"
            name="saveExecutionButton"
            className="saveExecutionButton"
            onClick={() => {
              handleExitExecutionSave();
            }}
            disabled={isExitExecutionDisabled}
          >
            {addExitState === "Edit" ? "Save" : "Add"}
          </button>
        </div>
      </Modal>
      {/* ---------- ENTRY MODAL ---------- */}
      <Modal
        ariaHideApp={false}
        id="TableModal"
        className={
          rowData.current.selectedPortfolioType === "Options"
            ? "TableModalOptions"
            : "TableModal"
        }
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        <div id="centerit">
          {addState === "Edit" ? (
            rowData.current.orderType !== "Multi-Leg Strategy" ? (
              <h3 className="modalSymbolHeader3">{`${addState} Entry Execution`}</h3>
            ) : (
              <h3 className="modalSymbolHeader3">{`${addState} Entry Leg`}</h3>
            )
          ) : rowData.current.orderType !== "Multi-Leg Strategy" ? (
            <h3 className="modalSymbolHeader3">{`${addState} Entry Executions`}</h3>
          ) : (
            <h3 className="modalSymbolHeader3">{`${addState} Entry Legs`}</h3>
          )}
          <button
            className="deletetradesclosebutton"
            onClick={() => closeModal()}
          >
            <DeleteButton />
          </button>
          <div className="editExecutionModalInputs2">
            {rowData.current.selectedPortfolioType === "Options" &&
            rowData.current.orderType === "Multi-Leg Strategy" ? (
              <span>
                {rowData.current.legType ? (
                  <label>Option Leg Type</label>
                ) : (
                  <label>
                    Option Leg Type{" "}
                    <em className="requiredlegtype">&nbsp;Required</em>
                  </label>
                )}
                <span id="optionsselectwrapper-upper">
                  <div className="buy-call-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="legType"
                        id="EditBuyCall"
                        value={"Long Call"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            legType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-buy">
                      <label>{"Long Call"}</label>
                    </div>
                  </div>
                  <div className="optionsellcall-container-upper">
                    <label className="container checksygBC">
                      <input
                        type="radio"
                        name="legType"
                        id="EditSellCall"
                        value={"Short Call"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            legType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Call"}</label>
                    </div>
                  </div>
                  <div className="buy-put-container">
                    <label className="container checksygBP">
                      <input
                        type="radio"
                        name="legType"
                        id="EditBuyPut"
                        value={"Long Put"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            legType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Long Put"}</label>
                    </div>
                  </div>
                  <div className="optionsellput-container">
                    <label className="container checksygBC2">
                      <input
                        type="radio"
                        name="legType"
                        id="EditSellPut"
                        value={"Short Put"}
                        onClick={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            legType: e.target.value,
                          };
                          forceUpdate();
                        }}
                      />
                      <span className="radiocircle"></span>
                    </label>
                    <div className="radio-label-sell">
                      <label>{"Short Put"}</label>
                    </div>
                  </div>
                </span>
              </span>
            ) : (
              ""
            )}
            <label
              className={
                tradeEntry.selectedPortfolioType === "Options"
                  ? "ANTselectionfieldsRequiredOPTION"
                  : "VTPselectionfieldsDT"
              }
            >
              {"Date & Time Opened"}{" "}
              <span className="container checksantcurretntime">
                <input
                  type="checkbox"
                  id="boxNoEntrySlip"
                  name="dateTimeNowAdd"
                  value={dateTimeOpenedSame}
                  onChange={(e) => {
                    if (e.target.checked) {
                      rowData.current = {
                        ...rowData.current,
                        execstartDateTime: new Date(),
                      };
                    } else {
                      rowData.current = {
                        ...rowData.current,
                        execstartDateTime: prevdateTimeOpenedSame,
                      };
                    }
                    setdateTimeNowAdd({
                      ...dateTimeNowAdd,
                      dateTimeOpenedSame: e.target.checked,
                    });
                  }}
                />
                <span className="checkmark checkmark-col1-2"></span>
                <span
                  style={{ position: "relative", top: "1px", marginRight: 4 }}
                >
                  &emsp;&ensp;Use Now
                </span>
              </span>
              <span className="mandatory startDateTimeErrorMsg">{""}</span>
              {/*               <DateTime
                value={startDateTime}
                name="startDateTime"
                onChange={(e) => rowData.current = ({ ...rowData.current, startDateTime: e._d })}
                dateFormat="MM/DD/YYYY"
                timeFormat="HH:mm:ss"
                inputProps={inputProps}
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: "Required",
                      }}
                      className="text-input-boxes required"
                    />
                  )}
                  views={["day", "year", "hours", "minutes", "seconds"]}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  clearable={true}
                  ampm={false}
                  value={rowData.current.execstartDateTime}
                  name="execstartDateTime"
                  onChange={(e) => {
                    rowData.current = {
                      ...rowData.current,
                      execstartDateTime: !e ? "" : e._d,
                    };
                    forceUpdate();
                  }}
                />
              </LocalizationProvider>
            </label>
            <div className="main-labels">
              <label
                className={
                  tradeEntry.selectedPortfolioType === "Options"
                    ? "ANTselectionfieldsRequiredOPTION"
                    : "VTPselectionfields"
                }
              >
                {rowData.current.selectedPortfolioType === "Stocks"
                  ? "Shares"
                  : rowData.current.selectedPortfolioType === "Options" ||
                    rowData.current.selectedPortfolioType === "Futures"
                  ? "Contracts"
                  : rowData.current.selectedPortfolioType === "Forex"
                  ? "Lots"
                  : rowData.current.selectedPortfolioType === "Crypto"
                  ? "Coins/Tokens"
                  : rowData.current.selectedPortfolioType === "CFD"
                  ? "Quantity"
                  : "Units"}
                <span className="mandatory lotSizeErrorMsg">{""}</span>
                <input
                  name="lotSize"
                  style={movecursor}
                  value={rowData.current.lotSize}
                  onChange={(e) => {
                    rowData.current = {
                      ...rowData.current,
                      lotSize: e.target.value, // WE NEED TO CHANGE THIS TO A NUMBER. SAME FOR EXITLOTSIZE.
                    };
                    forceUpdate();
                  }}
                  className="text-input-boxes required"
                  required
                  placeholder="Required"
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="main-labels">
              <label
                className={
                  tradeEntry.selectedPortfolioType === "Options"
                    ? "ANTselectionfieldsRequiredOPTION"
                    : "VTPselectionfields"
                }
              >
                {rowData.current.selectedPortfolioType === "Options"
                  ? "Entry Premium"
                  : "Entry Price"}
                <span className="mandatory entryPriceErrorMsg">{""}</span>
                <input
                  name="entryPrice"
                  style={movecursor}
                  value={rowData.current.entryPrice}
                  onChange={(e) => {
                    rowData.current = {
                      ...rowData.current,
                      entryPrice: e.target.value,
                    };
                    forceUpdate();
                  }}
                  className="text-input-boxes required"
                  autoComplete="off"
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                  required
                  placeholder="Required"
                />
              </label>
            </div>
            {rowData.current.selectedPortfolioType === "Options" && (
              <div
                className={
                  rowData.current.orderType === "Multi-Leg Strategy"
                    ? "optionsOptionals2"
                    : "optionsOptionals23"
                }
              >
                {" "}
                <label className="ANTselectionfieldsOPTION">
                  {"Expiration Date"}{" "}
                  <span className="container checksantcurretntimeexp">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="dateTimeNowAdd"
                      value={expirationDateSame}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let dateN = new Date();
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          rowData.current = {
                            ...rowData.current,
                            expirationDate: dateN,
                          };
                        } else {
                          rowData.current = {
                            ...rowData.current,
                            expirationDate: prevexpirationDateSame,
                          };
                        }
                        setdateTimeNowAdd({
                          ...dateTimeNowAdd,
                          expirationDateSame: e.target.checked,
                        });
                      }}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span
                      style={{
                        position: "relative",
                        top: "1px",
                        marginRight: 4,
                      }}
                    >
                      &emsp;&ensp;Use Today
                    </span>
                  </span>
                  <span className="mandatory expDateTimeErrorMsg">{""}</span>
                  {/*                   <DateTime
                    value={expirationDate}
                    name="expirationDate"
                    onChange={(e) =>
                      rowData.current = ({ ...rowData.current, expirationDate: e._d })
                    }
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    inputProps={inputProps}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{
                            ...props.inputProps,
                            placeholder: "Required",
                          }}
                          className="text-input-boxes required"
                        />
                      )}
                      inputFormat="MM/DD/YYYY"
                      clearable
                      value={rowData.current.expirationDate}
                      name="expirationDate"
                      onChange={(e) => {
                        let dateN = !e ? "" : new Date(e._d);
                        if (dateN === "") {
                        } else {
                          dateN.setHours(0, 0, 0, 0); // last midnight
                          rowData.current = {
                            ...rowData.current,
                            expirationDate: dateN,
                          };
                          forceUpdate();
                        }
                      }}
                    />
                  </LocalizationProvider>
                </label>
                <div className="main-labels">
                  <label className="ANTselectionfieldsOPTION">
                    Strike Price
                    <span className="mandatory strikePriceErrorMsg">{""}</span>
                    <input
                      name="strikePrice"
                      style={movecursor}
                      value={rowData.current.strikePrice}
                      onChange={(e) => {
                        rowData.current = {
                          ...rowData.current,
                          strikePrice: e.target.value,
                        };
                        forceUpdate();
                      }}
                      placeholder="Required"
                      className="text-input-boxes required"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </label>
                </div>
                <div className="main-labels">
                  <label className="VTPselectionfieldsNOTRequiredOPTIONf">
                    <span style={{ marginRight: 4 }}>Expected Entry</span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to enter the trade at.<br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedEntry"
                        id="expectedEntry"
                        style={movecursor}
                        disabled={!showMaster}
                        value={
                          hasEntrySlippage === false
                            ? rowData.current.expectedEntry
                            : entrycheckbuffer === 0
                            ? rowData.current.expectedEntry
                            : rowData.current.entryPrice
                        }
                        onChange={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            expectedEntry: e.target.value,
                          };
                          forceUpdate();
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />
                    </div>
                  </label>
                </div>
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checkssspv">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="hasEntrySlippage"
                      checked={hasEntrySlippage}
                      disabled={!showMaster}
                      onClick={(e) => handleEntrySlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Entry Slippage
                    </span>{" "}
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you entered at the intended entry price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>{" "}
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-5px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}{" "}
                  </label>
                </div>
              </div>
            )}
            {rowData.current.selectedPortfolioType === "Options" ? (
              ""
            ) : (
              <div>
                <div className="main-labels">
                  <label className={"VTPselectionfieldsNOTRequiredOPTION"}>
                    <span style={{ marginRight: 4 }}>Expected Entry</span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          The price you intended to enter the trade at.<br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-2px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}{" "}
                    <div
                      onClick={() => {
                        if (
                          active &&
                          (tier === "master" ||
                            tier === "ultimate" ||
                            tier === "admin")
                        ) {
                        } else {
                          props.setshowUpgradeModal(featureSelect4);
                          setIsOpen(false);
                        }
                      }}
                    >
                      <input
                        name="expectedEntry"
                        id="expectedEntry"
                        style={movecursor}
                        disabled={!showMaster}
                        value={
                          hasEntrySlippage === false
                            ? rowData.current.expectedEntry
                            : entrycheckbuffer === 0
                            ? rowData.current.expectedEntry
                            : rowData.current.entryPrice
                        }
                        onChange={(e) => {
                          rowData.current = {
                            ...rowData.current,
                            expectedEntry: e.target.value,
                          };
                          forceUpdate();
                        }}
                        placeholder="Optional"
                        className="text-input-boxes"
                        autoComplete="off"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                      />
                    </div>
                  </label>
                </div>{" "}
                <div
                  onClick={() => {
                    if (
                      active &&
                      (tier === "master" ||
                        tier === "ultimate" ||
                        tier === "admin")
                    ) {
                    } else {
                      props.setshowUpgradeModal(featureSelect4);
                      setIsOpen(false);
                    }
                  }}
                  style={{ margin: "20px 0px 0px 0px", padding: "0px" }}
                >
                  <label className="container checkssspv">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="hasEntrySlippage"
                      checked={hasEntrySlippage}
                      disabled={!showMaster}
                      onChange={(e) => handleEntrySlippage(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;No Entry Slippage
                    </span>{" "}
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Check this if you entered at the intended entry price.
                          <br></br>
                        </div>
                      }
                      placement="bottom"
                      delay={300}
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style6">&#x1D48A;</span>
                    </PopoverStickOnHover>{" "}
                    {!showMaster && (
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-13px",
                          marginLeft: "-5px",
                          width: 0,
                          height: 0,
                        }}
                      >
                        <MasterTierComponent />
                      </span>
                    )}{" "}
                  </label>
                </div>
              </div>
            )}
          </div>
          {/*           <table style={{ width: "100%" }}>
            <tr>
              <th className="editExecutionHeaderText">Lot Size</th>
              <th className="editExecutionHeaderText">Entry Price</th>
              <th className="editExecutionHeaderText">Expected Entry</th>
              <th className="editExecutionHeaderText">Start Date and Time</th>
            </tr>
            <tr>
              <td colSpan="4">{editExecutionButtons()}</td>
            </tr>
          </table> */}
        </div>
        <button
          name="clearEditExecutionButton"
          className="clearEditExecutionButton"
          onClick={() => handleClearMultiExecution()}
        >
          {"Clear"}
        </button>
        <button
          name="cancelExecutionButton"
          className="cancelExecutionButton"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          name="saveExecutionButton"
          className="saveExecutionButton"
          onClick={() => saveExecution()}
          disabled={isExecutionDisabled}
        >
          {addState === "Edit" ? "Save" : "Add"}
        </button>
        {/*         <button
          name="clearExecutionButton"
          className="clearExecutionButton executionbutton2 donewidth"
          onClick={() => handleExecutionClose()}
          disabled={isExecutionDisabled}
        >
          Save
        </button> THIS IS A TEMPLATE FOR THE CANCEL BUTTON -  */}
      </Modal>
      <div
        className="tradecalculatedinfo"
        /*         style={{ backgroundColor: portfolioColor }} */
      >
        <div className={toprowclass}>
          {toprowclass === "tradecalculatedinfo_toprowopen" && (
            <div className="tradecalculatedinfo_opendisplay">
              OPEN {`${+parseFloat(openUnits).toFixed(5)} ${asset}`}
            </div>
          )}
          {rowData.current.orderType === "Multi-Leg Strategy" ? (
            <span className={toptypeclassml}>
              <span className="justneedmargintop">
                {rowData.current.orderType}
              </span>
              <span className={optionstrategyclass}>
                {getOptionsStrategy(
                  rowData.current.multiExecution,
                  "",
                  rowData.current.orderType
                )}
              </span>
            </span>
          ) : (
            <span className={toptypeclass}>
              <div className={"tradecalculatedinfo_ordertypedisplay"}>
                {rowData.current.orderType}
              </div>
            </span>
          )}
          <span className="tradecalculatedinfo_symbol">
            {rowData.current.symbol.symbols &&
              rowData.current.symbol.symbols[0]}
          </span>
          {!editorderNumber ? (
            <div
              className="flushrighttext-top"
              onClick={() => {
                seeditttradeProps({
                  ...edittradeProps,
                  editorderNumber: true,
                });
                forceUpdate();
              }}
              style={{ color: color }}
            >
              <span className="tradecalculatedinfo_orderNumber">
                {"Order No. " +
                  (!rowData.current.orderNumber
                    ? ""
                    : rowData.current.orderNumber)}
              </span>
            </div>
          ) : (
            <div
              className="logbook-text-input-boxes-wrapper"
              id="logbook-text-input-boxes-orderNumber-wrapper"
            >
              <input
                id="logbook-text-input-boxes-orderNumber"
                name="orderNumber"
                style={{
                  color: "#deebf7",
                  top: "20px",
                  left: "-8px",
                  paddingLeft: "10px",
                  width: 160,
                  position: "relative",
                  zIndex: 100000001,
                }}
                value={rowData.current.orderNumber}
                onChange={(e) => {
                  const newRow = {
                    ...rowData.current,
                    orderNumber: e.target.value,
                  };
                  handleChange(newRow);
                }}
                onBlur={() => {
                  seeditttradeProps({
                    ...edittradeProps,
                    editorderNumber: false,
                  });
                }}
                onFocus={() => {
                  document
                    .getElementById("logbook-text-input-boxes-orderNumber")
                    .addEventListener("keyup", function (event) {
                      // Number 13 is the "Enter" key on the keyboard
                      if (event.keyCode === 13) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        seeditttradeProps({
                          ...edittradeProps,
                          editorderNumber: false,
                        });
                      }
                    });
                }}
                autoFocus
                className="logbook-text-input-boxes"
                autoComplete="off"
                pattern="[+-]?([0-9]*[.])?[0-9]+"
              />
            </div>
          )}
        </div>
        <div className="tradecalculatedinfo_leftSide">
          <span className="tradecalculatedinfo_PL">
            <div className="tradecalculatedinfo_PLtitle">
              {rowData.current.orderType === "Deposit" ||
              rowData.current.orderType === "Withdrawal"
                ? "Amount " + he.decode(String(thedefaultSymbol))
                : "Gross Profit/Loss (" +
                  he.decode(String(thedefaultSymbol) + "/%)")}
            </div>
            <div
              className="tradecalculatedinfo_PLvaluemain"
              style={{ color: color }}
            >
              {rowData.current.orderType === "Deposit" ||
              rowData.current.orderType === "Withdrawal" ||
              rowData.current.selectedPortfolioType === "Forex" ? (
                !editprofitLoss ? (
                  <div
                    className="calculatedPLValue"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editprofitLoss: true,
                      });
                      forceUpdate();
                    }}
                    style={{ color: color }}
                  >
                    {rowData.current.orderType === "Deposit" ||
                    rowData.current.orderType === "Withdrawal"
                      ? supportedCryptosCheck
                        ? grossprofitLoss +
                          " " +
                          he.decode(String(thedefaultSymbol))
                        : he.decode(String(thedefaultSymbol)) +
                          numberWithCommas(grossprofitLoss)
                      : (supportedCryptosCheck
                          ? grossprofitLoss +
                            " " +
                            he.decode(String(thedefaultSymbol))
                          : he.decode(String(thedefaultSymbol)) +
                            numberWithCommas(grossprofitLoss)) +
                        " / " +
                        (handleNANGOOD(percentProfitLoss, "-") === "-"
                          ? "-"
                          : percentProfitLoss.toFixed(3)) +
                        "%"}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-profitLoss"
                      name="profitLoss"
                      style={{
                        color: color,
                        top: "6px",
                        left: "-12px",
                        paddingLeft: "10px",
                      }}
                      value={rowData.current.profitLoss}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          profitLoss: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editprofitLoss: false,
                        });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-profitLoss")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editprofitLoss: false,
                              });
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )
              ) : (
                (supportedCryptosCheck
                  ? grossprofitLoss + " " + he.decode(String(thedefaultSymbol))
                  : he.decode(String(thedefaultSymbol)) +
                    numberWithCommas(grossprofitLoss)) +
                " / " +
                (handleNANGOOD(percentProfitLoss, "-") === "-"
                  ? "-"
                  : percentProfitLoss.toFixed(3)) +
                "%"
              )}
            </div>
          </span>
          <div className="tradecalculatedinfo_leftSideinner">
            {dwCheck && (
              <span className="tradecalculatedinfo_ME">
                <div className="tradecalculatedinfo_PLtitle">
                  {"Holding Time"}
                </div>
                <div className="tradecalculatedinfo_PLvalue">{dateDiff}</div>
              </span>
            )}
            {dwCheck && (
              <span className="tradecalculatedinfo_slip">
                <div className="tradecalculatedinfo_PLtitle">
                  {"Slippage " + he.decode(String(thedefaultSymbol))}
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {
                          "The total amount lost (or gained) due to entering and exiting at prices different than expected."
                        }{" "}
                        <br></br>
                        {/*                   <Link to={"/KPIs"} className="linktooltiphover">
                    Explore &#8594;
                  </Link> */}
                      </div>
                    }
                    placement="bottom"
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <div className="tooptip-i-style44">
                      <div
                        style={{
                          margin: "-2px 0px 0px -5px",
                          padding: "2px 4px 0px 0px",
                          width: "16px",
                          height: "16px",
                          textAlign: "center",
                          fontSize: "1.05em",
                        }}
                      >
                        &#x1D48A;
                      </div>
                    </div>
                  </PopoverStickOnHover>
                </div>
                {tier === "free" || tier === "pro" || tier === "oldpro" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: 0,
                      right: 62,
                      top: 19,
                    }}
                  >
                    <MasterTierComponent />
                  </div>
                ) : (
                  <div
                    className="tradecalculatedinfo_PLvalue"
                    style={{ color: slippagecolor }}
                  >
                    {he.decode(String(thedefaultSymbol)) +
                      numberWithCommas(slippage.toFixed(2))}
                  </div>
                )}
              </span>
            )}
          </div>
        </div>
        <div className="tradecalculatedinfo_verticalline"></div>
        {dwCheck && (
          <div className="percentMetricsWrapper">
            <SinglePercentMetric
              userCalculations={allData.calculations}
              userData={allData.portfolio.entries}
              defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
              userSettings={allData.portfolio.settings}
              row={rowData.current}
              balance={accountBalance}
              entryId={tradeEntryId}
              tier={tier}
              active={active}
              showCheck={showCheck}
              graph={"riskedpercent"}
              graphTitle={"Risked %"}
            />
            <SinglePercentMetric
              userCalculations={allData.calculations}
              userData={allData.portfolio.entries}
              defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
              userSettings={allData.portfolio.settings}
              row={rowData.current}
              balance={accountBalance}
              entryId={tradeEntryId}
              tier={tier}
              active={active}
              showCheck={showCheck}
              graph={"entryefficiency"}
              graphTitle={"Entry Eff."}
            />{" "}
            <SinglePercentMetric
              userCalculations={allData.calculations}
              userData={allData.portfolio.entries}
              defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
              userSettings={allData.portfolio.settings}
              row={rowData.current}
              balance={accountBalance}
              entryId={tradeEntryId}
              tier={tier}
              active={active}
              showCheck={showCheck}
              graph={"exitefficiency"}
              graphTitle={"Exit Eff."}
            />
            <SinglePercentMetric
              userCalculations={allData.calculations}
              userData={allData.portfolio.entries}
              defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
              userSettings={allData.portfolio.settings}
              row={rowData.current}
              balance={accountBalance}
              entryId={tradeEntryId}
              tier={tier}
              active={active}
              showCheck={showCheck}
              graph={"efficiency"}
              graphTitle={"Total Eff."}
            />
          </div>
        )}
        {!dwCheck ? (
          <div className="stackeddatadwwrapper">
            <div className="stackeddatafeestop">
              <div className="datatypecolor">{"Date & Time Opened"}</div>
              {!editstartDateTime ? (
                <div
                  className="flushrighttext"
                  onClick={() => {
                    seeditttradeProps({
                      ...edittradeProps,
                      editstartDateTime: true,
                    });
                    setDWDTOpen(true);
                    forceUpdate();
                  }}
                >
                  {new Date(
                    new Date(rowData.current.startDateTime).getTime() -
                      new Date(
                        rowData.current.startDateTime
                      ).getTimezoneOffset() *
                        60000
                  ).toISOString()}
                </div>
              ) : (
                <div className="logbook-text-input-boxes-wrapper">
                  <span className="mandatory startDateTimeErrorMsg">{""}</span>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{
                            ...props.inputProps,
                            placeholder: "Required",
                          }}
                          className="text-input-boxesDT required"
                          id="logbook-text-input-boxes-dt"
                          onBlur={() => {
                            seeditttradeProps({
                              ...edittradeProps,
                              editstartDateTime: false,
                            });
                          }}
                          onFocus={() => {
                            document
                              .getElementById("logbook-text-input-boxes-dt")
                              .addEventListener("keyup", function (event) {
                                // Number 13 is the "Enter" key on the keyboard
                                if (event.keyCode === 13) {
                                  // Cancel the default action, if needed
                                  event.preventDefault();
                                  // Trigger the button element with a click
                                  seeditttradeProps({
                                    ...edittradeProps,
                                    editstartDateTime: false,
                                  });
                                } else if (event.keyCode === 9) {
                                  // Cancel the default action, if needed
                                  event.preventDefault();
                                  // Trigger the button element with a click
                                  seeditttradeProps({
                                    ...edittradeProps,
                                    editstartDateTime: false,
                                    editfees: true,
                                  });
                                }
                              });
                            document
                              .getElementById("logbook-text-input-boxes-dt")
                              .addEventListener("keydown", function (event) {
                                // Number 9 is the "Enter" key on the keyboard
                                if (event.keyCode === 9) {
                                  // Cancel the default action, if needed
                                  event.preventDefault();
                                  // Trigger the button element with a click
                                }
                              });
                          }}
                        />
                      )}
                      views={["day", "year", "hours", "minutes", "seconds"]}
                      inputFormat="MM/DD/YYYY HH:mm:ss"
                      clearable={true}
                      value={rowData.current.startDateTime || null}
                      name="execstartDateTime"
                      open={DWDTOpen}
                      ampm={false}
                      onOpen={() => setDWDTOpen(true)}
                      onChange={(e) => {
                        const dateTime = !e
                          ? ""
                          : dateIsValid(e._d)
                          ? e._d
                          : String(e._d);
                        const newRow = {
                          ...rowData.current,
                          startDateTime: dateTime,
                        };
                        handleChange(newRow);
                        forceUpdate();
                      }}
                      onAccept={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editstartDateTime: false,
                        });
                      }}
                      onClose={() => {
                        setDWDTOpen(false);
                        seeditttradeProps({
                          ...edittradeProps,
                          editstartDateTime: false,
                        });
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
            <div className="stackeddatafeestop">
              <div className="datatypecolor">{"Fees"}</div>
              {!editfees ? (
                <div
                  className="flushrighttext"
                  onClick={() => {
                    !showCheck &&
                      seeditttradeProps({
                        ...edittradeProps,
                        editfees: true,
                      });
                    showCheck && props.setshowUpgradeModal(featureSelect3);
                    forceUpdate();
                  }}
                >
                  {showCheck ? (
                    <div className="flushrighttextinnermul">
                      <ProTierComponent />
                    </div>
                  ) : supportedCryptosCheck ? (
                    !rowData.current.fees ? (
                      "0 " + String(thedefaultSymbol)
                    ) : (
                      parseFloat(rowData.current.fees.toFixed(6)) +
                      " " +
                      String(thedefaultSymbol)
                    )
                  ) : !rowData.current.fees ? (
                    he.decode(String(thedefaultSymbol)) + "0.00"
                  ) : (
                    he.decode(String(thedefaultSymbol)) +
                    parseFloat(rowData.current.fees).toFixed(2)
                  )}
                </div>
              ) : (
                <div className="logbook-text-input-boxes-wrapper">
                  <input
                    id="logbook-text-input-boxes-fees"
                    name="fees"
                    style={movecursor}
                    value={rowData.current.fees}
                    onChange={(e) => {
                      const newRow = {
                        ...rowData.current,
                        fees: e.target.value,
                      };
                      handleChange(newRow);
                    }}
                    onBlur={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editfees: false,
                      });
                    }}
                    onFocus={() => {
                      document
                        .getElementById("logbook-text-input-boxes-fees")
                        .addEventListener("keyup", function (event) {
                          // Number 13 is the "Enter" key on the keyboard
                          if (event.keyCode === 13) {
                            // Cancel the default action, if needed
                            event.preventDefault();
                            // Trigger the button element with a click
                            seeditttradeProps({
                              ...edittradeProps,
                              editfees: false,
                            });
                          }
                        });
                    }}
                    autoFocus
                    className="logbook-text-input-boxes"
                    autoComplete="off"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="tradecalculatedinfo_ratioswrapper">
            {/*             <span className="tradecalculatedinfo_Eff">
              <div className="tradecalculatedinfo_Efftitle">
                {"Risked %"}&ensp;
              </div>
              <ImportantMetricComponent
                metric={{ value: "avgRisk", label: "Risked %" }}
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                calculationType={allData.portfolio.settings.calculationType}
                startingBalance={allData.portfolio.settings.startingBalance}
                settings={allData.portfolio.settings}
                row={rowData.current}
                balance={accountBalance}
                entryId={tradeEntryId}
                tier={tier}
                active={active}
                showCheck={showCheck}
              />
            </span> */}
            <span className="tradecalculatedinfo_RRR">
              <div className="tradecalculatedinfo_Efftitle">
                &emsp;&emsp;&ensp;
                {`RRR `}{" "}
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                        width: 200,
                      }}
                    >
                      Reward-Risk Ratio (RRR) measures the <b>potential</b>{" "}
                      reward relative to the amount of risk taken.{" "}
                    </div>
                  }
                  placement="bottom"
                  delay={300}
                  setClass="accountNumberSuperWrapper"
                  xAdjust={27}
                  yAdjust={0}
                  keepOpen={false}
                >
                  <div className="tooptip-i-style4">&#x1D48A;</div>
                </PopoverStickOnHover>
              </div>
              <ImportantMetricComponent
                metric={{ value: "avgRRR", label: "Average RRR" }}
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                calculationType={allData.portfolio.settings.calculationType}
                startingBalance={allData.portfolio.settings.startingBalance}
                settings={allData.portfolio.settings}
                row={rowData.current}
                balance={accountBalance}
                entryId={tradeEntryId}
                tier={tier}
                active={active}
                showCheck={showCheck}
              />
            </span>{" "}
            <span className="tradecalculatedinfo_RM">
              <div className="tradecalculatedinfo_Efftitle">
                {"R-Multiple"}{" "}
                <PopoverStickOnHover
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Measures the <b>actual</b> reward relative to the amount
                      of risk taken.
                      {/*                   <Link to={"/KPIs"} className="linktooltiphover">
                Explore &#8594;
              </Link> */}
                    </div>
                  }
                  placement="bottom"
                  delay={300}
                  xAdjust={27}
                  yAdjust={0}
                  keepOpen={false}
                >
                  <div className="tooptip-i-style4">&#x1D48A;</div>
                </PopoverStickOnHover>
              </div>
              <ImportantMetricComponent
                metric={{ value: "rmultipleratio", label: "R-Multiple Ratio" }}
                userData={allData.portfolio.entries}
                userCalculations={allData.calculations}
                calculationType={allData.portfolio.settings.calculationType}
                startingBalance={allData.portfolio.settings.startingBalance}
                settings={allData.portfolio.settings}
                row={rowData.current}
                balance={accountBalance}
                entryId={tradeEntryId}
                tier={tier}
                active={active}
                showCheck={showCheck}
              />
            </span>{" "}
          </div>
        )}
        {isChanged && (
          <PopoverStickOnHover
            placement="bottom"
            delay={300}
            xAdjust={27}
            yAdjust={0}
            keepOpen={false}
            component={<div style={{ color: "#deebf7" }}>Undo Changes</div>}
          >
            <button
              className={
                dwCheck
                  ? "editraderedobutton"
                  : "editraderedobutton editraderedobuttonmove1"
              }
              onClick={() => {
                resetTrade();
              }}
            >
              <FontAwesomeIcon icon={faRedo} />
            </button>
          </PopoverStickOnHover>
        )}
        {/*         {dwCheck && (
          <PopoverStickOnHover
            placement="bottom"
            delay={300}
            xAdjust={27}
            yAdjust={0}
            keepOpen={false}
            component={<div style={{ color: "#deebf7" }}>Share</div>}
          >
            <button
              className={`sharetradehistroytablebutton `}
              onClick={() => {
                openModalshare();
              }}
            >
              <FontAwesomeIcon icon={faShareAlt} />
            </button>{" "}
          </PopoverStickOnHover>
        )} */}
        {
          <div className="togglenexttradesbuttonWrapper">
            {showPrevTradeButton && (
              <PopoverStickOnHover
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
                component={
                  <div style={{ color: "#deebf7" }}>Previous Entry</div>
                }
              >
                <button
                  className="togglenexttradesbutton1"
                  onClick={getANDsetpreviousTrade}
                >
                  <FontAwesomeIcon icon={faPlay} />
                </button>
              </PopoverStickOnHover>
            )}
            {showNextTradeButton && (
              <PopoverStickOnHover
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
                component={<div style={{ color: "#98b5eb" }}>Next Entry</div>}
              >
                <button
                  className="togglenexttradesbutton2"
                  onClick={getANDsetnextTrade}
                >
                  <FontAwesomeIcon icon={faPlay} />
                </button>
              </PopoverStickOnHover>
            )}
          </div>
        }
        <Modal
          ariaHideApp={false}
          id="ConfirmationModal"
          className="ConfirmationModal"
          isOpen={openShareModal}
          onRequestClose={closeShareTradeConfirmationModal}
          closeTimeoutMS={250}
          contentLabel="Trade"
          overlayClassName="OverlayModal"
        >
          <h3 className="modalSymbolHeader4">{"SHARE TRADE"}</h3>

          <div className="deletemodaltext">
            Are you sure you want to share this trade? Everyone will be able to
            see it.
            {/* {rowData.current.symbol.symbols[0]} */}
          </div>
          <button
            className="edittradehistroytablebutton2 movedeletebutton"
            onClick={() => {
              store.dispatch(setLoaderState({ loading: true }));
              setShareModal(false);
              /*               shareTrades(props, rowData.current, userUSERNAME, userId).then(
                () => {
                  history.push("/BlankPage");
                  history.goBack();
                }
              ); */
              forceUpdate();
            }}
          >
            {"Confirm"}
          </button>
          <button
            className="clearEditExecutionButton movecancelbutton"
            onClick={() => {
              setShareModal(false);
            }}
          >
            {"Cancel"}
          </button>
        </Modal>
        <PopoverStickOnHover
          placement="bottom"
          delay={300}
          xAdjust={18}
          yAdjust={0}
          keepOpen={false}
          component={<div style={{ color: "#fa798b" }}>Delete Entry</div>}
        >
          <button
            className="edittradedeletebutton"
            onClick={() => {
              openModaldel(true);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </PopoverStickOnHover>
        <PopoverStickOnHover
          placement="bottom-end"
          delay={300}
          xAdjust={18}
          setClass="accountNumberSuperWrapper"
          yAdjust={0}
          keepOpen={false}
          component={
            <span style={{ color: "#deebf7", width: "fit-content" }}>
              This entry is logged in your{" "}
              <span style={{ color: portfolioColorPre, fontWeight: 600 }}>
                {portfolName}
              </span>{" "}
              portfolio.
            </span>
          }
        >
          <div
            className="portfolioVTPshow"
            style={{
              backgroundImage: `linear-gradient(to bottom right, ${portfolioColor}, ${portfolioColorDark})`,
              color: textColor,
              fontWeight: 400,
            }}
          >
            {portfolName}
          </div>
        </PopoverStickOnHover>
        <PopoverStickOnHover
          placement="bottom-end"
          delay={300}
          xAdjust={27}
          setClass="accountNumberSuperWrapper"
          yAdjust={0}
          keepOpen={false}
          component={
            <span
              style={{
                color: "#ffeba8",
                padding: "2px",
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ width: 220 }}>
                <div className="verifiedportfoliotooltipheader">
                  Verified trade{" "}
                  <img
                    className="viewtradepageverifiedimage viewtradepageverifiedimage2"
                    alt="0"
                    src={verifiedImage}
                  />{" "}
                </div>{" "}
                <div className="verifiedtradetooltipbody">
                  Verified trades are trades that have maintained the original
                  trade data imported from automated importing. Except for
                  commissions & fees, modifications are allowed within the
                  "Trade Info" section, as well as pictures and notes - but any
                  changes made to the executions or profit/loss data will result
                  in the trade losing its verification status.
                </div>
              </div>
            </span>
          }
        >
          {rowData.current.verifiedLevel === 2 ? (
            <span className="verifiedimagewrapper">
              {" "}
              <img
                className="viewtradepageverifiedimage"
                alt="0"
                src={verifiedImage}
              />{" "}
              <span id="verifiedimagetext">{`Verified`}</span>
            </span>
          ) : (
            <div></div>
          )}
        </PopoverStickOnHover>
      </div>
      {dwCheck && (
        <div className="showtradedatasuperwrapper">
          <div className="showtradedatawrapperupper">
            <div className="view-trade-label" id="view-trade-label">
              Executions
            </div>
            <div className="showEDITExecsBoxwrapper">
              <div className="showEDITExecsBoxtext">
                <span>Entry Executions</span>
              </div>
              <p className="showEDITExecsBoxprice">
                {"Avg. Entry Price: "}
                <span className="showEDITExecsBoxpriceinner">
                  {handleNANGOOD(
                    dollarCostAverage(
                      rowData.current.multiExecution,
                      "entry",
                      rowData.current.orderType
                    ),
                    "-"
                  )}
                </span>
                <span className="showEDITExecsBoxpriceinner2">
                  {`Total `}
                  {rowData.current.selectedPortfolioType === "Stocks"
                    ? "Shares"
                    : rowData.current.selectedPortfolioType === "Options" ||
                      rowData.current.selectedPortfolioType === "Futures"
                    ? "Contracts"
                    : rowData.current.selectedPortfolioType === "Forex"
                    ? "Lots"
                    : rowData.current.selectedPortfolioType === "Crypto"
                    ? "Coins/Tokens"
                    : rowData.current.selectedPortfolioType === "CFD"
                    ? "Quantity"
                    : "Units"}
                  :
                  <span className="showEDITExecsBoxpriceinner">
                    {entryLotsd}
                  </span>
                </span>
                <span className="showEDITExecsBoxpriceinner2">
                  {"No. of Executions: "}
                  <span className="showEDITExecsBoxpriceinner">
                    {rowData.current.multiExecution.length}
                  </span>
                </span>
              </p>
              <div
                className="commentsboxVT showEDITExecsBox"
                autoComplete="off"
              >
                {editExecutionButtons()}
              </div>
            </div>
            <div className="showEDITExecsBoxwrapper">
              <div className="showEDITExecsBoxtext">Exit Executions</div>
              <p className="showEDITExecsBoxprice">
                {"Avg. Exit Price: "}
                <span className="showEDITExecsBoxpriceinner">
                  {handleNANGOOD(
                    dollarCostAverage(
                      rowData.current.exitExecution,
                      "exit",
                      rowData.current.orderType
                    ),
                    "-"
                  )}
                </span>
                <span className="showEDITExecsBoxpriceinner2">
                  {`Total `}
                  {rowData.current.selectedPortfolioType === "Stocks"
                    ? "Shares"
                    : rowData.current.selectedPortfolioType === "Options" ||
                      rowData.current.selectedPortfolioType === "Futures"
                    ? "Contracts"
                    : rowData.current.selectedPortfolioType === "Forex"
                    ? "Lots"
                    : rowData.current.selectedPortfolioType === "Crypto"
                    ? "Coins/Tokens"
                    : rowData.current.selectedPortfolioType === "CFD"
                    ? "Quantity"
                    : "Units"}
                  :
                  <span className="showEDITExecsBoxpriceinner">
                    {exitLotsd}
                  </span>
                </span>
                <span className="showEDITExecsBoxpriceinner2">
                  {"No. of Executions: "}
                  <span className="showEDITExecsBoxpriceinner">
                    {rowData.current.exitExecution.length}
                  </span>
                </span>
              </p>
              <div
                className="commentsboxVT showEDITExecsBox"
                autoComplete="off"
              >
                {editExitExecutionButtons()}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {dwCheck && (
        <div className="TradingViewWidgetwrapper">
          <TradingViewWidget
            symbol={cutOffString(rowData.current.symbol.symbols[0])}
            currentTrade={rowData.current}
          />
        </div>
      )}
      {/* ----------- TRADE INFO COMPONENT ----------- */}
      {dwCheck && (
        <div className="showtradeinfowrapperupper">
          <div className="showtradedatawrapper">
            <div className="view-trade-label" id="view-trade-label">
              Trade Info
            </div>
            <div className="showtradedatacol1">
              <div className="stackeddata">
                <div className="datatypecolor">{"Stop Loss"}</div>
                {!editstopLoss ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editstopLoss: true,
                      });
                      forceUpdate();
                    }}
                  >
                    {!rowData.current.stopLoss ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>-
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {rowData.current.stopLoss}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-stopLoss"
                      name="stopLoss"
                      style={movecursor}
                      value={rowData.current.stopLoss}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          stopLoss: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editstopLoss: false,
                        });
                        document
                          .getElementById("logbook-text-input-boxes-stopLoss")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editstopLoss: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editstopLoss: false,
                                edittakeProfit: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-stopLoss")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-stopLoss")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editstopLoss: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editstopLoss: false,
                                edittakeProfit: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-stopLoss")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata">
                <div className="datatypecolor">{"MAE"}</div>
                {!editmaxAdEx ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      !showCheck &&
                        seeditttradeProps({
                          ...edittradeProps,
                          editmaxAdEx: true,
                        });
                      showCheck && props.setshowUpgradeModal(featureSelect2);
                      forceUpdate();
                    }}
                  >
                    {showCheck ? (
                      <div className="flushrighttextinnermul">
                        <ProTierComponent />
                      </div>
                    ) : !rowData.current.maxAdEx ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>-
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {rowData.current.maxAdEx}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-maxAdEx"
                      name="maxAdEx"
                      style={movecursor}
                      value={rowData.current.maxAdEx}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          maxAdEx: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editmaxAdEx: false,
                        });
                        document
                          .getElementById("logbook-text-input-boxes-maxAdEx")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxAdEx: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxAdEx: false,
                                editselectedTimeframe: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-maxAdEx")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-maxAdEx")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxAdEx: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxAdEx: false,
                                editselectedTimeframe: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-maxAdEx")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata">
                <div className="datatypecolor">{"Commisions"}</div>
                {!editcommissions ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      !showCheck &&
                        seeditttradeProps({
                          ...edittradeProps,
                          editcommissions: true,
                        });
                      showCheck && props.setshowUpgradeModal(featureSelect3);

                      forceUpdate();
                    }}
                  >
                    {showCheck ? (
                      <div className="flushrighttextinnermul">
                        <ProTierComponent />
                      </div>
                    ) : supportedCryptosCheck ? (
                      !rowData.current.commissions ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {"0 " + String(thedefaultSymbol)}
                        </span>
                      ) : (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {parseFloat(rowData.current.commissions.toFixed(6)) +
                            " " +
                            String(thedefaultSymbol)}
                        </span>
                      )
                    ) : !rowData.current.commissions ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {he.decode(String(thedefaultSymbol)) + "0.00"}
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {he.decode(String(thedefaultSymbol)) +
                          parseFloat(rowData.current.commissions).toFixed(2)}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-commissions"
                      name="commissions"
                      style={movecursor}
                      value={rowData.current.commissions}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          commissions: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editcommissions: false,
                        });
                        document
                          .getElementById(
                            "logbook-text-input-boxes-commissions"
                          )
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editcommissions: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editcommissions: false,
                                editfees: true,
                              });
                            }
                          });
                        document
                          .getElementById(
                            "logbook-text-input-boxes-commissions"
                          )
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById(
                            "logbook-text-input-boxes-commissions"
                          )
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editcommissions: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editcommissions: false,
                                editfees: true,
                              });
                            }
                          });
                        document
                          .getElementById(
                            "logbook-text-input-boxes-commissions"
                          )
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Time Frame"}</div>
                {!editselectedTimeframe ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedTimeframe: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinner">
                      {!rowData.current.selectedTimeframe ||
                      rowData.current.selectedTimeframe.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {rowData.current.selectedTimeframe}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={rowData.current.selectedTimeframe.label}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedTimeframe: !e ? "" : e.label,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedTimeframe: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedTimeframe: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedTimeframe: false,
                                editselectedMarket: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedTimeframe: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedTimeframe: false,
                                editselectedMarket: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      defaultValue={
                        rowData.current.selectedTimeframe && {
                          label: rowData.current.selectedTimeframe,
                          value: rowData.current.selectedTimeframe,
                        }
                      }
                      autoFocus
                      styles={customStyles}
                      options={filterlists.timeframeWithDefault}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Market State"}</div>
                {!editselectedMarket ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedMarket: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinner">
                      {!rowData.current.selectedMarket ||
                      rowData.current.selectedMarket.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {rowData.current.selectedMarket}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={rowData.current.selectedMarket.label}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedMarket: !e ? "" : e.label,
                        };
                        handleChange(newRow);
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMarket: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMarket: false,
                                editselectedPhysical: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedMarket: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMarket: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMarket: false,
                                editselectedPhysical: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      defaultValue={
                        rowData.current.selectedMarket && {
                          label: rowData.current.selectedMarket,
                          value: rowData.current.selectedMarket,
                        }
                      }
                      autoFocus
                      styles={customStyles}
                      options={filterlists.marketStateWithDefault}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Physical States"}</div>
                {!editselectedPhysical ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedPhysical: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinnermul">
                      {!rowData.current.selectedPhysical ||
                      rowData.current.selectedPhysical.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        [
                          <span className="edittradepencil">{"✎ "}</span>,
                          ...rowData.current.selectedPhysical.map((i) => {
                            return <span className="selectedelems">{i}</span>;
                          }),
                        ]
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={createSelectedState(
                        rowData.current.selectedPhysical
                      )}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedPhysical: createSelectedArray(e),
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedPhysical: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedPhysical: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedPhysical: false,
                                editselectedStrategy: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedPhysical: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedPhysical: false,
                                editselectedStrategy: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      isMulti
                      autoFocus
                      styles={customStyles}
                      options={filterlists.physical}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
              {/*  Multi-Leg Strategy Element */}
              <div className="stackeddata">
                <div className="datatypecolor">{"Strategies"}</div>
                {!editselectedStrategy ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedStrategy: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinnermul">
                      {!rowData.current.strategy ||
                      rowData.current.strategy.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        [
                          <span className="edittradepencil">{"✎ "}</span>,
                          ...rowData.current.strategy.map((i) => {
                            return <span className="selectedelems">{i}</span>;
                          }),
                        ]
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={createSelectedState(rowData.current.strategy)}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          strategy: createSelectedArray(e),
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedStrategy: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedStrategy: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedStrategy: false,
                                editcommissions: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedStrategy: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedStrategy: false,
                                editcommissions: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      isMulti
                      autoFocus
                      styles={customStyles}
                      options={filterlists.strategy}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Confidence Element */}
            <div className="showtradedatacol2">
              <div className="stackeddata">
                <div className="datatypecolor">{"Take Profit"}</div>
                {!edittakeProfit ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        edittakeProfit: true,
                      });
                      forceUpdate();
                    }}
                  >
                    {!rowData.current.takeProfit ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>-
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {rowData.current.takeProfit}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-takeProfit"
                      name="takeProfit"
                      style={movecursor}
                      value={rowData.current.takeProfit}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          takeProfit: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          edittakeProfit: false,
                        });
                        document
                          .getElementById("logbook-text-input-boxes-takeProfit")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittakeProfit: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittakeProfit: false,
                                editmaxAdEx: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-takeProfit")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-takeProfit")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittakeProfit: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittakeProfit: false,
                                editmaxAdEx: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-takeProfit")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata">
                <div className="datatypecolor">{"MFE"}</div>
                {!editmaxFavEx ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      !showCheck &&
                        seeditttradeProps({
                          ...edittradeProps,
                          editmaxFavEx: true,
                        });
                      showCheck && props.setshowUpgradeModal(featureSelect2);

                      forceUpdate();
                    }}
                  >
                    {showCheck ? (
                      <div className="flushrighttextinnermul">
                        <ProTierComponent />
                      </div>
                    ) : !rowData.current.maxFavEx ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>-
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {rowData.current.maxFavEx}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-maxFavEx"
                      name="maxFavEx"
                      style={movecursor}
                      value={rowData.current.maxFavEx}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          maxFavEx: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editmaxFavEx: false,
                        });
                        document
                          .getElementById("logbook-text-input-boxes-maxFavEx")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxFavEx: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxFavEx: false,
                                editselectedConfidence: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-maxFavEx")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-maxFavEx")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxFavEx: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editmaxFavEx: false,
                                editselectedConfidence: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-maxFavEx")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata">
                <div className="datatypecolor">{"Fees"}</div>
                {!editfees ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      !showCheck &&
                        seeditttradeProps({
                          ...edittradeProps,
                          editfees: true,
                        });
                      showCheck && props.setshowUpgradeModal(featureSelect3);

                      forceUpdate();
                    }}
                  >
                    {showCheck ? (
                      <div className="flushrighttextinnermul">
                        <ProTierComponent />
                      </div>
                    ) : supportedCryptosCheck ? (
                      !rowData.current.fees ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {"0 " + String(thedefaultSymbol)}
                        </span>
                      ) : (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {parseFloat(rowData.current.fees.toFixed(6)) +
                            " " +
                            String(thedefaultSymbol)}
                        </span>
                      )
                    ) : !rowData.current.fees ? (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {he.decode(String(thedefaultSymbol)) + "0.00"}
                      </span>
                    ) : (
                      <span>
                        <span className="edittradepencil">{"✎ "}</span>
                        {he.decode(String(thedefaultSymbol)) +
                          parseFloat(rowData.current.fees).toFixed(2)}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="logbook-text-input-boxes-wrapper">
                    <input
                      id="logbook-text-input-boxes-fees"
                      name="fees"
                      style={movecursor}
                      value={rowData.current.fees}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          fees: e.target.value,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editfees: false,
                        });
                        document
                          .getElementById("logbook-text-input-boxes-fees")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editfees: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editfees: false,
                                editmaxFavEx: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-fees")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-text-input-boxes-fees")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editfees: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editfees: false,
                                editmaxFavEx: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-text-input-boxes-fees")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      autoFocus
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Confidence"}</div>
                {!editselectedConfidence ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedConfidence: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinner">
                      {!rowData.current.selectedConfidence ||
                      rowData.current.selectedConfidence.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>
                          {rowData.current.selectedConfidence}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={rowData.current.selectedConfidence.label}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedConfidence: !e ? "" : e.label,
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedConfidence: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedConfidence: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedConfidence: false,
                                editselectedMistake: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedConfidence: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedConfidence: false,
                                editselectedMistake: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      defaultValue={
                        rowData.current.selectedConfidence && {
                          label: rowData.current.selectedConfidence,
                          value: rowData.current.selectedConfidence,
                        }
                      }
                      autoFocus
                      styles={customStyles}
                      options={filterlists.confidenceWithDefault}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Mistakes"}</div>
                {!editselectedMistake ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedMistake: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinnermul">
                      {!rowData.current.selectedMistake ||
                      rowData.current.selectedMistake.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        [
                          <span className="edittradepencil">{"✎ "}</span>,
                          ...rowData.current.selectedMistake.map((i) => {
                            return <span className="selectedelems">{i}</span>;
                          }),
                        ]
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={createSelectedState(
                        rowData.current.selectedMistake
                      )}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedMistake: createSelectedArray(e),
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedMistake: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMistake: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMistake: false,
                                editselectedEmotion: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMistake: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedMistake: false,
                                editselectedEmotion: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      isMulti
                      autoFocus
                      styles={customStyles}
                      options={filterlists.mistake}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
              <div className="stackeddata3">
                <div className="datatypecolor">{"Emotional States"}</div>
                {!editselectedEmotion ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        editselectedEmotion: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinnermul">
                      {!rowData.current.selectedEmotion ||
                      rowData.current.selectedEmotion.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        [
                          <span className="edittradepencil">{"✎ "}</span>,
                          ...rowData.current.selectedEmotion.map((i) => {
                            return <span className="selectedelems">{i}</span>;
                          }),
                        ]
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={createSelectedState(
                        rowData.current.selectedEmotion
                      )}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          selectedEmotion: createSelectedArray(e),
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          editselectedEmotion: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedEmotion: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedEmotion: false,
                                edittags: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedEmotion: false,
                              });
                            } else if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                editselectedEmotion: false,
                                edittags: true,
                              });
                            }
                          });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keydown", function (event) {
                            // Number 9 is the "Enter" key on the keyboard
                            if (event.keyCode === 9) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                            }
                          });
                      }}
                      isMulti
                      autoFocus
                      styles={customStyles}
                      options={filterlists.emotion}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>

              <div className="stackeddata3">
                <div className="datatypecolor">{"Tags"}</div>
                {!edittags ? (
                  <div
                    className="flushrighttext"
                    onClick={() => {
                      seeditttradeProps({
                        ...edittradeProps,
                        edittags: true,
                      });
                      forceUpdate();
                    }}
                  >
                    <div className="flushrighttextinnermul">
                      {!rowData.current.tags ||
                      rowData.current.tags.length === 0 ? (
                        <span>
                          <span className="edittradepencil">{"✎ "}</span>-
                        </span>
                      ) : (
                        [
                          <span className="edittradepencil">{"✎ "}</span>,
                          ...rowData.current.tags.map((i) => {
                            return <span className="selectedelems">{i}</span>;
                          }),
                        ]
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="logbook-select-input-boxes-wrapper">
                    <CreatableSelect
                      className="logbook-select-input-boxes"
                      id="logbook-select-input-boxes"
                      isClearable
                      value={createSelectedState(rowData.current.tags)}
                      onChange={(e) => {
                        const newRow = {
                          ...rowData.current,
                          tags: createSelectedArray(e),
                        };
                        handleChange(newRow);
                      }}
                      onBlur={() => {
                        seeditttradeProps({
                          ...edittradeProps,
                          edittags: false,
                        });
                        document
                          .getElementById("logbook-select-input-boxes")
                          .removeEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittags: false,
                              });
                            }
                          });
                      }}
                      onFocus={() => {
                        document
                          .getElementById("logbook-select-input-boxes")
                          .addEventListener("keyup", function (event) {
                            // Number 13 is the "Enter" key on the keyboard
                            if (event.keyCode === 13) {
                              // Cancel the default action, if needed
                              event.preventDefault();
                              // Trigger the button element with a click
                              seeditttradeProps({
                                ...edittradeProps,
                                edittags: false,
                              });
                            }
                          });
                      }}
                      isMulti
                      autoFocus
                      styles={customStyles}
                      options={filterlists.tags}
                      theme={theme}
                      placeholder="Start typing or select..."
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/*       {dwCheck && (
        <div className="TradingViewWidgetwrapper">
          <TradingViewWidget
            symbol={cutOffString(rowData.current.symbol.symbols[0])}
            currentTrade={rowData.current}
          />
        </div>
      )} */}
      {/* ----------- PICTURES COMPONENT ----------- */}
      {dwCheck && (
        <div className="addimage2">
          <div className="ANT-heading-image">Pictures</div>
          <div className="addimageinner">{imageDisplayFunction()}</div>
        </div>
      )}
      {/* ----------- COMMENTS COMPONENT ----------- */}
      {
        <div
          className={
            dwCheck
              ? "showtradecommentswrappersuper2"
              : "showtradecommentswrappersuper"
          }
        >
          {/*           {!editcomments ? (
            <div
              className="showtradecommentswrapper cursorpointer"
              onClick={() => {
                seeditttradeProps({
                  ...edittradeProps,
                  editcomments: true,
                });
                forceUpdate();
              }}
            >
              {commentsDisplayFunction()}
            </div>
          ) : (
                         <input
              id="showtradecommentswrapper"
              className="showtradecommentswrapper"
              value={rowData.current.comments}
              onClick={() => {
                seeditttradeProps({
                  ...edittradeProps,
                  editcomments: true,
                });
                forceUpdate();
              }}
              onChange={(e) => {
                const newRow = {
                  ...rowData.current,
                  comments: e.target.value,
                };
                handleChange(newRow);
              }}
              onBlur={() => {
                seeditttradeProps({
                  ...edittradeProps,
                  editcomments: false,
                });
              }}
              onFocus={() => {
                document
                  .getElementById("showtradecommentswrapper")
                  .addEventListener("keyup", function (event) {
                    // Number 13 is the "Enter" key on the keyboard
                    if (event.keyCode === 13) {
                      // Cancel the default action, if needed
                      event.preventDefault();
                      // Trigger the button element with a click
                      seeditttradeProps({
                        ...edittradeProps,
                        editcomments: false,
                      });
                    }
                  });
              }}
              autoFocus
            /> 

          )} */}
          <div className="comment-label" id="comment-label">
            <div className="ANT-heading-comments">Notes</div>
            <NoteComponent
              editCommentsState={editcomments}
              originalState={rowData.current.comments}
              setNoteState={(e) => {
                const newRow = {
                  ...rowData.current,
                  comments: e,
                };
                handleChange(newRow);
                forceUpdate();
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default ViewTradePage;
