export const OrderTypeOptions = [
  { value: "long", label: "Long" },
  { value: "short", label: "Short" },
  { value: "deposit", label: "Deposit" },
  { value: "withdrawal", label: "Withdrawal" },
];
export const OrderTypeOptionswOptions = [
  { value: "long", label: "Long" },
  { value: "short", label: "Short" },
  { value: "Long Call", label: "Long Call" },
  { value: "Short Call", label: "Short Call" },
  { value: "Long Put", label: "Long Put" },
  { value: "Short Put", label: "Short Put" },
  { value: "Multi-Leg Strategy", label: "Multi-Leg Strategy" },
  { value: "deposit", label: "Deposit" },
  { value: "withdrawal", label: "Withdrawal" },
];
export const OrderTypeOptionsOnlyOptions = [
  { value: "Long Call", label: "Long Call" },
  { value: "Short Call", label: "Short Call" },
  { value: "Long Put", label: "Long Put" },
  { value: "Short Put", label: "Short Put" },
  { value: "Multi-Leg Strategy", label: "Multi-Leg Strategy" },
  { value: "deposit", label: "Deposit" },
  { value: "withdrawal", label: "Withdrawal" },
];
export const DateRangeOptions = [
  { value: "today", label: "Today" },
  { value: "thisWeek", label: "This Week" },
  { value: "lastWeek", label: "Last Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "lastMonth", label: "Last Month" },
  { value: "last3Months", label: "Last 3 Months" },
  { value: "thisYear", label: "This Year" },
  { value: "lastYear", label: "Last Year" },
  { value: "customRange", label: "Custom Range" },
];

export const DayofWeekOptions = [
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
];

export const HourRangeOptions = [
  { value: "00:00:00 - 00:59:59", label: "00:00:00 - 00:59:59" },
  { value: "01:00:00 - 01:59:59", label: "01:00:00 - 01:59:59" },
  { value: "02:00:00 - 02:59:59", label: "02:00:00 - 02:59:59" },
  { value: "03:00:00 - 03:59:59", label: "03:00:00 - 03:59:59" },
  { value: "04:00:00 - 04:59:59", label: "04:00:00 - 04:59:59" },
  { value: "05:00:00 - 05:59:59", label: "05:00:00 - 05:59:59" },
  { value: "06:00:00 - 06:59:59", label: "06:00:00 - 06:59:59" },
  { value: "07:00:00 - 07:59:59", label: "07:00:00 - 07:59:59" },
  { value: "08:00:00 - 08:59:59", label: "08:00:00 - 08:59:59" },
  { value: "09:00:00 - 09:59:59", label: "09:00:00 - 09:59:59" },
  { value: "10:00:00 - 10:59:59", label: "10:00:00 - 10:59:59" },
  { value: "11:00:00 - 11:59:59", label: "11:00:00 - 11:59:59" },
  { value: "12:00:00 - 12:59:59", label: "12:00:00 - 12:59:59" },
  { value: "13:00:00 - 13:59:59", label: "13:00:00 - 13:59:59" },
  { value: "14:00:00 - 14:59:59", label: "14:00:00 - 14:59:59" },
  { value: "15:00:00 - 15:59:59", label: "15:00:00 - 15:59:59" },
  { value: "16:00:00 - 16:59:59", label: "16:00:00 - 16:59:59" },
  { value: "17:00:00 - 17:59:59", label: "17:00:00 - 17:59:59" },
  { value: "18:00:00 - 18:59:59", label: "18:00:00 - 18:59:59" },
  { value: "19:00:00 - 19:59:59", label: "19:00:00 - 19:59:59" },
  { value: "20:00:00 - 20:59:59", label: "20:00:00 - 20:59:59" },
  { value: "21:00:00 - 21:59:59", label: "21:00:00 - 21:59:59" },
  { value: "22:00:00 - 22:59:59", label: "22:00:00 - 22:59:59" },
  { value: "23:00:00 - 23:59:59", label: "23:00:00 - 23:59:59" },
  { value: "customRange", label: "Custom Range" },
];
export const MonthOptions = [
  { value: "january", label: "January" },
  { value: "february", label: "February" },
  { value: "march", label: "March" },
  { value: "april", label: "April" },
  { value: "may", label: "May" },
  { value: "june", label: "June" },
  { value: "july", label: "July" },
  { value: "august", label: "August" },
  { value: "september", label: "September" },
  { value: "october", label: "October" },
  { value: "november", label: "November" },
  { value: "december", label: "December" },
];

export const MarketStateOptions = [
  { value: "bullishtrendingSmooth", label: "Bullish Trending Smooth" },
  { value: "bullishtrendingVolatile", label: "Bullish Trending Volatile" },
  { value: "bearishtrendingSmooth", label: "Bearish Trending Smooth" },
  { value: "bearishtrendingVolatile", label: "Bearish Trending Volatile" },
  { value: "sidewaysVolatile", label: "Sideways Volatile" },
  { value: "sidewaysDead", label: "Sideways Dead" },
];

export const TimeFrameOptions = [
  { value: "1Min", label: "1-Minute" },
  { value: "5Min", label: "5-Minute" },
  { value: "15Min", label: "15-Minute" },
  { value: "30Min", label: "30-Minute" },
  { value: "1hour", label: "1-Hour" },
  { value: "4hour", label: "4-Hour" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const ConfidenceOptions = [
  { value: "veryHigh", label: "Very High" },
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
  { value: "veryLow", label: "Very Low" },
];

export const AssetClassOptions = [
  { value: "Stocks", label: "Stocks" },
  { value: "Crypto", label: "Crypto" },
  { value: "Options", label: "Options" },
  { value: "Forex", label: "Forex" },
  { value: "Futures", label: "Futures" },
  { value: "CFD", label: "CFD" },
];

export default OrderTypeOptions;
