// completely AI generated
export function calculateZScore(balances) {
  // Calculate the returns by taking the difference between each balance and the previous balance
  const returns = balances.map((balance, i) =>
    i > 0 ? balance - balances[i - 1] : 0
  );

  // Calculate the mean and standard deviation of the returns
  const mean = returns.reduce((a, b) => a + b, 0) / returns.length;
  const stdDev = Math.sqrt(
    returns.reduce((a, b) => a + Math.pow(b - mean, 2), 0) /
      (returns.length - 1)
  );

  let N = balances.length - 1;
  // Check for insufficient data
  if (N < 1) {
    //console.log("Insufficient data to calculate Z-Score.");
    return { zScore: 0, confidence: 0 };
  }

  let W = 0,
    L = 0,
    R = 0,
    P;

  // Determine W and L
  for (let i = 1; i <= N; i++) {
    if (balances[i] > balances[i - 1]) {
      W++;
    } else if (balances[i] < balances[i - 1]) {
      L++;
    }
  }

  // Calculate R (number of streaks)
  let currentStreak = balances[1] > balances[0] ? "win" : "loss";
  for (let i = 2; i <= N; i++) {
    let newStreak = balances[i] > balances[i - 1] ? "win" : "loss";
    if (newStreak !== currentStreak) {
      R++;
      currentStreak = newStreak;
    }
  }

  R++; // To account for the final streak

  // Calculate P
  P = 2 * W * L;
  // Calculate Z-Score FOREX
  const zScore = (N * (R - 0.5) - P) / Math.sqrt((P * (P - N)) / (N - 1));
  // Calculate the Z-Score
  const statisticalZScore = (mean - 0) / stdDev;
  const zScoreFinal = isFinite(zScore) ? zScore : 0;
  // Calculate the % Confidence
  const confidence =
    stdDev === 0
      ? 0
      : Math.min(
          1 - normalCDF(-1 * Math.abs(zScoreFinal), 0, 1),
          normalCDF(Math.abs(zScoreFinal), 0, 1)
        ) * 100;
  const confidenceFinal = isFinite(zScore) ? confidence : 0;

  return {
    zScore: zScoreFinal,
    confidence: confidenceFinal,
    statisticalZScore: statisticalZScore,
  };
}

// In-house implementation of the CDF of the normal distribution
function normalCDF(x, mean, stdDev) {
  return (1 + erf((x - mean) / (stdDev * Math.sqrt(2)))) / 2;
}

// In-house implementation of the error function
function erf(x) {
  // Implement the erf function using a Taylor series expansion
  let result = 0;
  let term = x;
  let k = 0;

  while (Math.abs(term) > 1e-8) {
    result += term;
    k++;
    term *= (-x * x) / k;
  }

  return (result * 2) / Math.sqrt(Math.PI);
}
export default calculateZScore;
