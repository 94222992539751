const initialState =  {
  rightPanelValues: {
    rightPanelWidth: 340,
    rightPanelTool: "Shared Trades"
  }
}

export const rightPanelToggle = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RIGHT_PANEL_STORED': 
      return {
        ...state, 
        rightPanelValues: {
          ...state.rightPanelValues,
          [action.id]: action.value
        }
      }
    default: 
      return state
  }
}

export default rightPanelToggle

