/* import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation"; */
import { montharr, weekday } from "../../../arrays/weekandmonth";
import { Contract, ethers } from "ethers";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import { filterByID } from "./../../filterByID";
import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "../../profitLossCalculation";
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
function hexToDec(hexString) {
  return parseInt(hexString, 16);
}
function maxConsecutiveZeros(str) {
  let maxCount = 0;
  let currentCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "0") {
      currentCount++;
      if (currentCount > maxCount) {
        maxCount = currentCount;
      }
    } else {
      currentCount = 0;
    }
  }
  return maxCount;
}
function allEqual(input) {
  return input.split("").every((char) => char === input[0]);
}
/* const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
}; */
const ethereum = window.ethereum;
//const etherscanProvider = new ethers.providers.EtherscanProvider();
/* const provider =
  ethereum &&
  new ethers.providers.InfuraProvider(
    "mainnet",
    process.env.REACT_APP_INFURA_KEY
  ); */
const provider = ethereum && new ethers.providers.Web3Provider(ethereum);
/* const convertWeiToETH = (wei) => {
  let ethamount = ethers.utils.formatEther(wei);
  return ethamount;
};
const abi = ["function symbol() view returns (string)"];
const iface = new ethers.utils.Interface(abi);
const method_selector = iface.getSighash("symbol()");  */

export const parseEtherOrders = async (
  tradesIn, // trades already imported
  idsIn, // arrays if ids already imported
  portfolioIn, // visible portfolio (selected portfolio or default)
  tradedata, // incoming transactions from etherscan
  userId,
  verified, // trade verification level
  addressIn,
  importType
) => {
  const convertWeiToETH = (wei) => {
    let ethamount = ethers.utils.formatEther(wei);
    return ethamount;
  };
  const abi = [
    // Read-Only Functions
    /*     "function balanceOf(address owner) view returns (uint256)", */
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",
    // Authenticated Functions
    /*   "function transfer(address to, uint amount) returns (bool)", */

    // Events
    /*   "event Transfer(address indexed from, address indexed to, uint amount)", */
  ];

  const fetchSymbolFromAddress = async (address) => {
    let symbolIN;

    if (address) {
      const erc20Proxy = new Contract(address, abi, provider);
      await erc20Proxy
        .symbol()
        .then((res) => {
          symbolIN = res;
        })
        .catch((err) => {});
    }
    return symbolIN;
  };

  const fetchDecimalsFromAddress = async (address) => {
    let decimalsIN;

    if (address) {
      const erc20Proxy = new Contract(address, abi, provider);
      await erc20Proxy
        .decimals()
        .then((res) => {
          decimalsIN = res;
        })
        .catch((err) => {});
    }
    return decimalsIN;
  };

  const isAddressExistinLogs = (topics, logAddress) => {
    let foundAddress = false;
    topics.forEach((value) => {
      let tempValue = value.replace("0x", "").replace(/^0+/, "").toString();
      if (
        String("0x" + tempValue).toLowerCase() ===
        String(logAddress).toLowerCase()
      ) {
        foundAddress = true;
      }
    });
    return foundAddress;
  };

  let trades = tradesIn;
  let ids = idsIn; // ids (hashes) of all imported entries
  let portfolio = portfolioIn;
  let changedIds = []; // ids for the "pulse"
  const address = addressIn;
  for (let i = 0, j = tradedata.length; i < j; i++) {
    const currentIteration = i + 1;
    if (importType === "initialLink") {
      localStorage.setItem(
        "autoiterationMessage",
        `Importing ${currentIteration}/${tradedata.length} executions...`
      );
    }
    let trade = {};
    const tx = tradedata[i];
    let openTradesPre = [...trades];
    let openTradesPre2 = [...trades];
    const amountHex = tx.value;
    const timestamp = tx.timestamp;
    var startDateTime = new Date(timestamp * 1000);
    const hash = tx.hash;
    if (ids.includes(String(hash))) {
      break;
    } else {
      const amount = convertWeiToETH(amountHex.toString());
      const gasPriceHex = tx.gasPrice;
      const gasLimitHex = tx.gasLimit;
      const gas = convertWeiToETH(
        String(Number(gasPriceHex.toString()) * Number(gasLimitHex.toString()))
      );
      const toAddress = String(tx.to).toLowerCase();
      const fromAddress = String(tx.from).toLowerCase();
      const bytecode = await provider.getCode(toAddress);
      const parsedEvents = await provider.getTransactionReceipt(hash);
      const erc20to = new Contract(toAddress, abi, provider);

      if (toAddress === address && fromAddress === address) {
        // SELF transaction
        trade = {
          entryId: String(hash),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: "Self",
            orderNumber: "",
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: Number(amount),
            commissions: 0,
            fees: -Number(gas),
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: [],
            verifiedLevel: verified, // because it was a fully automated trade,
            idLinks: [],
          },
        };
        trades.push(trade);
        ids.push(String(hash));
        changedIds.push(String(hash));
      } else {
        if (toAddress === address) {
          if (bytecode.length > 2) {
          } else {
            trade = {
              entryId: String(hash),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Crypto",
                orderType: "Deposit",
                orderNumber: "",
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: Number(amount),
                commissions: 0,
                fees: -Number(gas),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: startDateTime,
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
              },
            };
            trades.push(trade);
            ids.push(String(hash));
            changedIds.push(String(hash));
          }
        } else if (fromAddress === address) {
          let potentiallyBadContract = false;
          if (bytecode.length > 2) {
            // --------- SEARCH LOGS FOR NEEDED DATA ---------
            const logs = parsedEvents.logs;
            let realIndex = 0;
            let foundIndex = 0;
            let otherIndex = 0;
            let orderSide = "";
            let otherTopic = "";
            let notAnApproval = false;
            const maxData = String(
              "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            );
            for (let ii = 0, jj = logs.length; ii < jj; ii++) {
              const log = logs[ii];
              const topics = log.topics;
              const logAddress = log.address;
              let foundAddress = false;
              const logAddresSameasATopic = isAddressExistinLogs(
                topics,
                logAddress
              );
              if (topics.length && String(log.data) !== maxData) {
                if (logAddresSameasATopic) {
                } else {
                  for (let iv = 0, jv = topics.length; iv < jv; iv++) {
                    let tempValue = topics[iv]
                      .replace("0x", "")
                      .replace(/^0+/, "")
                      .toString();
                    if (
                      "0x" + tempValue === address &&
                      topics[0] ===
                        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
                    ) {
                      otherTopic =
                        topics[iv === 1 ? 2 : 1] &&
                        topics[iv === 1 ? 2 : 1]
                          .replace("0x", "")
                          .replace(/^0+/, "")
                          .toString();
                      foundAddress = true;
                      orderSide = iv === 1 ? "sell" : "buy";
                      foundIndex = iv;
                    }
                  }
                }
              }
              if (foundAddress) {
                // Gets the Transfer log
                realIndex = ii;
                break;
              }
            }
            // we look for the last transaction with the "otherTopic" because it's a sell
            // mainly to get the amount that was originally traded
            // The last transaction with the otherTopic could actually be the first log
            if (foundIndex === 1) {
              for (let ie = 0, je = logs.length; ie < je; ie++) {
                const loge = logs[ie];
                const topicse = loge.topics;
                if (topicse.length && String(loge.data) !== maxData) {
                  for (let ix = 0, jx = topicse.length; ix < jx; ix++) {
                    let tempTotalValue = topicse[ix].toString();
                    let tempValue = topicse[ix]
                      .replace("0x", "")
                      .replace(/^0+/, "")
                      .toString();
                    if (
                      tempValue === otherTopic &&
                      topicse[0] ===
                        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
                    ) {
                      // if we come across the same address and data, we find the next transfer log
                      otherIndex = ie;
                      const zerozlength = maxConsecutiveZeros(tempTotalValue);
                      const newvalue = topicse[2]
                        .replace("0x", "")
                        .replace(/^0+/, "")
                        .toString();
                      if (
                        zerozlength >= 33 ||
                        tempValue === "dead" ||
                        tempValue === "" ||
                        tempValue === null ||
                        !tempValue
                      ) {
                        potentiallyBadContract = true;
                        continue;
                        // found 0x000000000000000000000000000000000000dEaD in OKLG
                        // there could be others
                      } else {
                        if (
                          newvalue === "dead" ||
                          newvalue === "" ||
                          newvalue === null ||
                          !newvalue
                        ) {
                        } else {
                          otherTopic = newvalue;
                        }
                      }
                    }
                  }
                }
              }
            }
            /*             if (foundIndex === 1) {
              for (let ie = logs.length - 1, je = 0; ie > je; ie--) {
                const loge = logs[ie];
                const topic0 = loge.topics[0];
                if (
                  String(topic0).toLowerCase() ===
                  "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef" // hash of Transfers!
                ) {
                  otherIndex = ie;
                  break;
                } else {
                }
              }
            } */
            const otherLog = logs[otherIndex];
            const otherLogAddress = otherLog && otherLog.address;
            let preotherLogData = otherLog && otherLog.data;

            // Replace 0x0000..... with ""
            let tempotherLogData =
              preotherLogData &&
              preotherLogData.replace("0x", "").replace(/^0+/, "").toString();
            // set decimals for first log address
            const otherdecimals = await fetchDecimalsFromAddress(
              otherLogAddress
            );

            // If all f's then set to zero, otherwise parse
            // Must divide by correct decimals
            const otherLogData =
              preotherLogData &&
              (!allEqual(tempotherLogData)
                ? hexToDec(tempotherLogData) / Math.pow(10, otherdecimals)
                : 0);

            // Attempt to find the log of interest.
            // If we do not find it, we search forwards in the array
            let actualLog = logs[realIndex];
            let actualLogAddress = actualLog && actualLog.address;
            let preactualLogData = actualLog && actualLog.data;

            // Replace 0x0000..... with ""
            let tempLogData =
              preactualLogData &&
              preactualLogData.replace("0x", "").replace(/^0+/, "").toString();

            // If all f's then set to zero, otherwise parse
            const actualdecimals = await fetchDecimalsFromAddress(
              actualLogAddress
            );
            const actualLogData =
              preactualLogData &&
              (!allEqual(tempLogData)
                ? hexToDec(tempLogData) / Math.pow(10, actualdecimals)
                : 0);

            let relevantLotSize = Number(actualLogData);
            let relevantPrice =
              orderSide === "sell"
                ? Number(otherLogData) / actualLogData
                : Number(amount) / actualLogData;
            const addressestoCheck = actualLogAddress;

            const status = parsedEvents.status;

            let symbol = "";

            await erc20to
              .symbol()
              .then((res) => {
                symbol = res;
              })
              .catch((err) => {});

            // check status
            if (status === 0) {
              // order failed or reverted
              // still push the hash
              ids.push(String(hash));
            } else {
              if (symbol === "") {
                // no symbol was found, so look in logs instead
                if (!actualLogAddress) {
                  trade = {
                    entryId: String(hash),
                    entry: {
                      pictures: ["N/A"],
                      symbol: {
                        symbols: [],
                        pointValue: 0,
                      },
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: portfolio.name,
                      selectedPortfolioType: "Crypto",
                      orderType: "Commit",
                      orderNumber: "",
                      dayOfWeek: weekday[startDateTime.getDay()],
                      monthOfYear: montharr[startDateTime.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: -Number(amount),
                      commissions: 0,
                      fees: -Number(gas),
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [],
                      exitExecution: [],
                      startDateTime: startDateTime,
                      endDateTime: startDateTime,
                      tags: [],
                      verifiedLevel: verified, // because it was a fully automated trade,
                      idLinks: [],
                    },
                  };
                  trades.push(trade);
                  ids.push(String(hash));
                  changedIds.push(String(hash));
                } else {
                  symbol = await fetchSymbolFromAddress(addressestoCheck);
                  if (symbol === "" || !symbol) {
                    // likely an NFT
                    trade = {
                      entryId: String(hash),
                      entry: {
                        pictures: ["N/A"],
                        symbol: {
                          symbols: [],
                          pointValue: 0,
                        },
                        strategy: "",
                        selectedConfidence: "",
                        selectedEmotion: "",
                        selectedPhysical: "",
                        selectedMarket: "",
                        selectedTimeframe: "",
                        selectedMistake: "",
                        selectedPortfolio: portfolio.name,
                        selectedPortfolioType: "Crypto",
                        orderType: "NFT",
                        orderNumber: "",
                        dayOfWeek: weekday[startDateTime.getDay()],
                        monthOfYear: montharr[startDateTime.getMonth()],
                        stopLoss: "",
                        takeProfit: "",
                        profitLoss: -Number(amount),
                        commissions: 0,
                        fees: -Number(gas),
                        maxAdEx: "",
                        maxFavEx: "",
                        comments: "",
                        multiExecution: [],
                        exitExecution: [],
                        startDateTime: startDateTime,
                        endDateTime: "",
                        tags: [],
                        verifiedLevel: verified, // because it was a fully automated trade,
                        idLinks: [],
                      },
                    };
                    trades.push(trade);
                    ids.push(String(hash));
                    changedIds.push(String(hash));
                  } else {
                    notAnApproval = true;
                  }
                }
              } else {
              }

              // Now we can check if it's an approval
              if (!symbol || symbol === "") {
              } else {
                if (notAnApproval) {
                  if (
                    logs[0].topics[0] ===
                      "0xe1fffcc4923d04b559f4d29a8bfc6cda04eb5b0d3c460751c2402c5c5cc9109c" &&
                    logs.length === 1
                  ) {
                    // this can also be a deposit...
                    trade = {
                      entryId: String(hash),
                      entry: {
                        pictures: ["N/A"],
                        symbol: {
                          symbols: [],
                          pointValue: 0,
                        },
                        strategy: "",
                        selectedConfidence: "",
                        selectedEmotion: "",
                        selectedPhysical: "",
                        selectedMarket: "",
                        selectedTimeframe: "",
                        selectedMistake: "",
                        selectedPortfolio: portfolio.name,
                        selectedPortfolioType: "Crypto",
                        orderType: "Deposit",
                        orderNumber: "",
                        dayOfWeek: weekday[startDateTime.getDay()],
                        monthOfYear: montharr[startDateTime.getMonth()],
                        stopLoss: "",
                        takeProfit: "",
                        profitLoss: Number(amount),
                        commissions: 0,
                        fees: -Number(gas),
                        maxAdEx: "",
                        maxFavEx: "",
                        comments: "",
                        multiExecution: [],
                        exitExecution: [],
                        startDateTime: startDateTime,
                        endDateTime: startDateTime,
                        tags: "",
                        verifiedLevel: verified, // because it was a fully automated trade,
                        idLinks: [],
                      },
                    };
                    trades.push(trade);
                    ids.push(String(hash));
                    changedIds.push(String(hash));
                  } else {
                    // This is where executions are parsed
                    const transactionSide = orderSide;
                    const newsymbol = String(symbol).replace("-", "") + "ETH";

                    if (!ids.includes(String(hash))) {
                      let openTradesMatch = openTradesPre.filter((trade) =>
                        filterByID(trade, false, newsymbol)
                      );
                      let openTradesNOTMatch = openTradesPre2.filter((trade) =>
                        filterByID(trade, true, newsymbol)
                      );

                      //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
                      let orderType =
                        transactionSide === "buy" ? "Long" : "Short";
                      //let delta = fullTradearray[i]["Delta"];

                      let executionsArray = [];
                      let entryexecution = {
                        id: uuidv4(),
                        lotSize: relevantLotSize,
                        entryPrice: relevantPrice,
                        startDateTime: startDateTime,
                        expectedEntry: "",
                        strikePrice: "",
                        expirationDate: "",
                        legType: "",
                      };
                      executionsArray.push(entryexecution);

                      let startDateTimezz = new Date(
                        getStartDateTime(executionsArray)
                      );

                      // Need to fix rounding issue of lotSizes by doing :
                      // Math.round(Number(tradedata[i]["size"]) * 1e10) / 1e10,
                      const fees = Number(gas);
                      const checkedfees = fees ? -fees : 0;
                      if (newsymbol === "WETHETH") {
                        trade = {
                          entryId: String(hash),
                          entry: {
                            pictures: ["N/A"],
                            symbol: {
                              symbols: [],
                              pointValue: 0,
                            },
                            strategy: "",
                            selectedConfidence: "",
                            selectedEmotion: "",
                            selectedPhysical: "",
                            selectedMarket: "",
                            selectedTimeframe: "",
                            selectedMistake: "",
                            selectedPortfolio: portfolio.name,
                            selectedPortfolioType: "Crypto",
                            orderType: "Wrap",
                            orderNumber: "",
                            dayOfWeek: weekday[startDateTime.getDay()],
                            monthOfYear: montharr[startDateTime.getMonth()],
                            stopLoss: "",
                            takeProfit: "",
                            profitLoss: 0,
                            commissions: 0,
                            fees: -Number(gas),
                            maxAdEx: "",
                            maxFavEx: "",
                            comments: "",
                            multiExecution: [],
                            exitExecution: [],
                            startDateTime: startDateTime,
                            endDateTime: startDateTime,
                            tags: "",
                            verifiedLevel: verified, // because it was a fully automated trade,
                            idLinks: [],
                          },
                        };
                        trades.push(trade);
                        ids.push(String(hash));
                        changedIds.push(String(hash));
                      } else {
                        if (!openTradesMatch.length) {
                          changedIds.push(String(hash));
                          // if we don't find open trade, open new one
                          trade = {
                            entryId: String(hash),
                            entry: {
                              pictures: ["N/A"],
                              symbol: {
                                symbols: [newsymbol],
                                pointValue: 0,
                              },
                              strategy: "",
                              selectedConfidence: "",
                              selectedEmotion: "",
                              selectedPhysical: "",
                              selectedMarket: "",
                              selectedTimeframe: "",
                              selectedMistake: "",
                              selectedPortfolio: portfolio.name,
                              selectedPortfolioType: "Crypto",
                              orderType: orderType,
                              orderNumber: "",
                              dayOfWeek: weekday[startDateTimezz.getDay()],
                              monthOfYear: montharr[startDateTimezz.getMonth()],
                              stopLoss: "",
                              takeProfit: "",
                              profitLoss: 0,
                              commissions: 0,
                              fees: checkedfees, // fees appear to be always negative
                              maxAdEx: "",
                              maxFavEx: "",
                              comments: "",
                              multiExecution: executionsArray,
                              exitExecution: [],
                              startDateTime: startDateTimezz,
                              endDateTime: "",
                              tags: potentiallyBadContract
                                ? ["Suspicious contract"]
                                : [],
                              verifiedLevel: verified, // because it was a fully automated trade,
                              idLinks: [],
                            },
                          };
                          trades.push(trade);
                          openTradesPre = trades;
                          openTradesPre2 = trades;
                        } else {
                          const openTrade = openTradesMatch[0];
                          changedIds.push(openTrade?.entryId);
                          const openTradeEntry = openTrade?.entry;
                          const opentradeOrderType = openTrade?.entry.orderType;
                          const opentradeSymbol = openTrade?.entry.symbol;
                          const opentradeFees = openTrade?.entry.fees;
                          const opentradesType =
                            openTrade?.entry.selectedPortfolioType;
                          const opentrademultiExecution =
                            openTrade?.entry.multiExecution;
                          const opentradeexitExecution =
                            openTrade?.entry.exitExecution;
                          // if the sides do not match then it's a close
                          if (orderType !== opentradeOrderType) {
                            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
                            let exitexecutionsArray = [];
                            let exitexecutionPRE = {
                              id: uuidv4(),
                              exitLotSize: relevantLotSize,
                              exitPrice: relevantPrice,
                              endDateTime: startDateTime,
                              expectedExit: "",
                              exitstrikePrice: "",
                              exitexpirationDate: "",
                              exitlegType: "",
                              equityComponents: [],
                              exercised: "",
                            };
                            exitexecutionsArray.push(exitexecutionPRE);
                            let entryLots = 0;
                            opentrademultiExecution.forEach((exec) => {
                              entryLots += Number(exec.lotSize);
                            });
                            let exitLots = 0;
                            /*                 const newExitExecutionPRE = [
                          ...openTrade?.entry.exitExecution,
                          exitexecutionPRE,
                        ];*/
                            const newExitExecutionPRE = [
                              ...opentradeexitExecution,
                              ...exitexecutionsArray,
                            ];

                            newExitExecutionPRE.forEach((exec) => {
                              exitLots += Number(exec.exitLotSize);
                            });
                            let exitLotsOLD = 0;
                            opentradeexitExecution.forEach((exec) => {
                              exitLotsOLD += Number(exec.exitLotSize);
                            });

                            entryLots = fixRound(entryLots); // fix rounding issues
                            exitLots = fixRound(exitLots);
                            exitLotsOLD = fixRound(exitLotsOLD);

                            let startDateTimez = new Date(
                              getStartDateTime(opentrademultiExecution)
                            );
                            let endDateTimez = new Date(
                              getEndDateTime(newExitExecutionPRE)
                            );

                            let finalFees = opentradeFees + checkedfees;

                            const entry = Object.assign({}, openTradeEntry, {
                              exitExecution: newExitExecutionPRE,
                              profitLoss: Number(
                                profitLossCalculation(
                                  opentrademultiExecution,
                                  newExitExecutionPRE,
                                  opentradeOrderType,
                                  opentradesType,
                                  opentradeSymbol.pointValue,
                                  false
                                )
                              ),
                              endDateTime: endDateTimez,
                              startDateTime: startDateTimez,
                              fees: finalFees, //add funding payments at the end
                            });

                            const closedTrade = Object.assign({}, openTrade, {
                              entry: entry,
                            });
                            openTradesNOTMatch.push(closedTrade);
                            trades = openTradesNOTMatch;
                            openTradesPre = openTradesNOTMatch;
                            openTradesPre2 = openTradesNOTMatch;

                            // calculate absolute start date time

                            // -------------------
                          } else {
                            // Add new opening executions
                            const opentradeFeesin = openTrade?.entry.fees;
                            const opentrademultiExecutionNew = [
                              ...opentrademultiExecution,
                              ...executionsArray,
                            ];
                            const entry = Object.assign({}, openTradeEntry, {
                              multiExecution: opentrademultiExecutionNew,
                              fees: opentradeFeesin - checkedfees,
                            });
                            const closedTrade = Object.assign({}, openTrade, {
                              entry: entry,
                            });
                            openTradesNOTMatch.push(closedTrade);
                            trades = openTradesNOTMatch;
                            openTradesPre = openTradesNOTMatch;
                            openTradesPre2 = openTradesNOTMatch;
                          }
                        }
                      }
                      ids.push(String(hash));
                    } else {
                    }
                  }
                } else {
                  trade = {
                    entryId: String(hash),
                    entry: {
                      pictures: ["N/A"],
                      symbol: {
                        symbols: [],
                        pointValue: 0,
                      },
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: portfolio.name,
                      selectedPortfolioType: "Crypto",
                      orderType: "Approval",
                      orderNumber: "",
                      dayOfWeek: weekday[startDateTime.getDay()],
                      monthOfYear: montharr[startDateTime.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: Number(amount), // Should be zero
                      commissions: 0,
                      fees: -Number(gas),
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: [],
                      exitExecution: [],
                      startDateTime: startDateTime,
                      endDateTime: startDateTime,
                      tags: [],
                      verifiedLevel: verified, // because it was a fully automated trade,
                      idLinks: [],
                    },
                  };
                  trades.push(trade);
                  ids.push(String(hash));
                  changedIds.push(String(hash));
                }
              }

              /*           const decodedInput = iface.parseTransaction({
                data: tx.data,
                value: tx.value,
              });
              console.log({
                function_name: decodedInput.name,
                from: tx.from,
                to: decodedInput.args[0],
                erc20Value: Number(decodedInput.args[1]),
              }); */
            }
          } else {
            trade = {
              entryId: String(hash),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Crypto",
                orderType: "Withdrawal",
                orderNumber: "",
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: -Number(amount),
                commissions: 0,
                fees: -Number(gas),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: "",
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
              },
            };
            trades.push(trade);
            ids.push(String(hash));
            changedIds.push(String(hash));
          }
        }
      }
    }
  }
  return { trades: trades, ids: ids, changedIds: changedIds };
};

export default parseEtherOrders;
