export function kellyCriterion(balances) {
  // Calculate the average return
  let totalReturns = 0;
  for (let i = 0; i < balances.length - 1; i++) {
    totalReturns += balances[i + 1] - balances[i];
  }
  const averageReturn = totalReturns / (balances.length - 1);

  // Calculate the standard deviation of returns
  let sumOfSquaredReturns = 0;
  for (let i = 0; i < balances.length - 1; i++) {
    const return_ = balances[i + 1] - balances[i];
    sumOfSquaredReturns += Math.pow(return_ - averageReturn, 2);
  }
  const stdDev = Math.sqrt(sumOfSquaredReturns / (balances.length - 2));

  // Calculate and return the Kelly Criterion
  return averageReturn / stdDev;
}
export default kellyCriterion;
