import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import Select from "react-select";
import { DataContext } from "../../DataContext";
import { setLoaderState } from "../../actions/actionCreators";
import store from "../../store";
import "./importStyles.scss";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import csvToArray from "./csvToArray";
import tradeovate from "./brokers/tradovate";
import mt4 from "./brokers/mt4";
import mt5 from "./brokers/mt5";
import Mt4Instructions from "./instructions/mt4";
import Mt5Instructions from "./instructions/mt5";
import TradovateInstructions from "./instructions/tradovate";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import dropdownStyles from "../../functions/dropdownStyles";
import { getSpecificPortfolio } from "../../functions/getSpecificPortfolio";
import dydxlogo from "./../../images/dydxlogo.svg";
import tradestationlogo from "./../../images/tradestationlogo.svg";
import krakenlogo from "./../../images/kraken-logo.png";
import robinhoodlogo from "./../../images/Logo-Robinhood.png";
import metamasklogo from "./../../images/MetaMask_Fox.svg";
import etherlogo from "./../../images/eth-glyph-colored2.png";
import mt4logo from "./../../images/metatrader-4-logo-og.png";
import mt5logo from "./../../images/mt5-logo.png";
import tradovatelogo from "./../../images/Tradovate-darkbg.png";
import rithmiclogo from "./../../images/evaluation_rithmic.png";
import goldbrain from "./../../images/goldbrain.png";

import thinkorswimlogo from "./../../images/thinkorswim.png";
import iblogo from "./../../images/Interactive_Brokers-Logo.png";
import showConfirmedActionButton from "./../../functions/showConfirmedActionButton";
import Modal from "react-modal";
import ImportFromFileComponent from "./ImportFileComponent";
import AutoImport from "./autoImport";
import AutoImportModal from "./AutoImportModal";
import UnlinkModal from "./UnlinkModal";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import {
  faCaretLeft,
  faCaretRight,
  faFileImport,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import liveIcon from "./../../images/noun-live-5455344.png";
import IntegrationsSubComponent from "./IntegrationsSubComponent";
import BrokerRequests from "./brokerRequests";

//import useForceUpdate from "../../hooks/useForceUpdate";

/* import Tooltip from "./../../components/Tooltip";
 */ var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const IntegrationsComponent = (props) => {
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const chosenPortfolios = allData?.allData.chosenPortfolios;
  const createdPortfolios = allData?.allData.createdPortfolios;
  const portfolio = allData.portfolio;

  const show100TradesCheck = props.show100TradesCheck;
  const allGlobalData = useContext(DataContext);
  const history = useHistory();

  const connectionsArray = allGlobalData?.connectionsArray;
  let autoImportConnectionsArray = [];
  let allautoImportConnectionsArray = [];

  createdPortfolios.forEach((portfolio) => {
    const linkedBroker = portfolio.settings.linkedBrokerInfo;
    linkedBroker.broker !== "" &&
      chosenPortfolios.includes(portfolio.name) &&
      autoImportConnectionsArray.push({
        ...portfolio.settings.linkedBrokerInfo,
        port: portfolio.name,
      });
    linkedBroker.broker !== "" &&
      allautoImportConnectionsArray.push({
        ...portfolio.settings.linkedBrokerInfo,
        port: portfolio.name,
      });
  });
  const globalSettings = allData?.allData.globalSettings;
  const customer = allGlobalData?.customer;
  const customerPrice = customer?.priceId;
  const integrationInfo = globalSettings?.integrationsInfo;
  function checkLastUpWithin30Days(object) {
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    const currentTime = Date.now();

    for (const key in object) {
      if (object.hasOwnProperty(key) && object[key].hasOwnProperty("lastUp")) {
        const lastUpTime = object[key].lastUp;
        if (currentTime - lastUpTime <= thirtyDaysInMillis) {
          return true;
        }
      }
    }

    return false;
  }
  const importCheckVar = checkLastUpWithin30Days(integrationInfo);

  const [selectedBroker, setselectedBroker] = useState();
  const [selectedConnectedBroker, setselectedConnectedBroker] = useState();

  const [importOpen, setimportOpen] = useState(false);
  const [importState, setimportState] = useState("");
  const [choicesOpen, setchoicesOpen] = useState(false);
  const [unlinkOpen, setunlinkOpen] = useState(false);
  const [upgradeOpen, setupgradeOpen] = useState(false);

  const [showInstructions, setshowInstructions] = useState(false);
  const [showImportChoices, setshowImportChoices] = useState(false);
  const [overwriteentries, setoverwriteentries] = useState();
  const [isweb3selected, setisweb3selected] = useState(false);
  const [hasOverwrite, sethasOverwrite] = useState(false);
  const [hasError, sethasError] = useState({ state: false, broker: "" });
  const integrationMaxCount =
    customerPrice === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    customerPrice === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    customerPrice === "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      ? 2
      : customerPrice === "price_1NrZ4jJEnF6qjMZiJjvHqJT0" ||
        customerPrice === "price_1NrZ4UJEnF6qjMZi1v4TgU02" ||
        customerPrice === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ"
      ? 3
      : customerPrice === "price_1NrZ7AJEnF6qjMZiUgjO2W1q" ||
        customerPrice === "price_1NrZ6KJEnF6qjMZiAaU4KZKz" ||
        customerPrice === "price_1NrZ5FJEnF6qjMZiHoyWDR4o"
      ? 4
      : customerPrice === "price_1Nr5PrJEnF6qjMZihHXdaxIo" ||
        customerPrice === "price_1Nr5PjJEnF6qjMZiVIBveXUn" ||
        customerPrice === "price_1Nr5PTJEnF6qjMZiqqEDvCCM"
      ? 5
      : customerPrice === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
        customerPrice === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
        customerPrice === "price_1NrcfhJEnF6qjMZiefJh6cra"
      ? 6
      : customerPrice === "price_1NrchLJEnF6qjMZiBxInopoT" ||
        customerPrice === "price_1Nrch2JEnF6qjMZiEGeV65e8" ||
        customerPrice === "price_1NrcgjJEnF6qjMZiaLXJ0wuf"
      ? 7
      : customerPrice === "price_1NrciMJEnF6qjMZiGXzuyOfu" ||
        customerPrice === "price_1Nrci7JEnF6qjMZiU55b7OP5" ||
        customerPrice === "price_1NrchkJEnF6qjMZi8UVeSqNY"
      ? 8
      : customerPrice === "price_1NrcjTJEnF6qjMZilagP5YoA" ||
        customerPrice === "price_1Nrcj3JEnF6qjMZijnp3WNFm" ||
        customerPrice === "price_1NrcihJEnF6qjMZibyfQKCST"
      ? 9
      : customerPrice === "price_1NrckLJEnF6qjMZi7Z3y3Yi7" ||
        customerPrice === "price_1Nrck6JEnF6qjMZizx8aB3PQ" ||
        customerPrice === "price_1NrcjpJEnF6qjMZiTlAJ2R1W"
      ? 10
      : customerPrice === "price_1Mla3qJEnF6qjMZijRgbEgN3"
      ? 2
      : customerPrice === "price_1NNs2kJEnF6qjMZiRWeutOMh"
      ? 6
      : 2; //default to 0, later add admin priveleges
  const masterCount =
    customerPrice === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    customerPrice === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    customerPrice === "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      ? 2
      : customerPrice === "price_1NrZ4jJEnF6qjMZiJjvHqJT0" ||
        customerPrice === "price_1NrZ4UJEnF6qjMZi1v4TgU02" ||
        customerPrice === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ"
      ? 3
      : customerPrice === "price_1NrZ7AJEnF6qjMZiUgjO2W1q" ||
        customerPrice === "price_1NrZ6KJEnF6qjMZiAaU4KZKz" ||
        customerPrice === "price_1NrZ5FJEnF6qjMZiHoyWDR4o"
      ? 4
      : customerPrice === "price_1Nr5PrJEnF6qjMZihHXdaxIo" ||
        customerPrice === "price_1Nr5PjJEnF6qjMZiVIBveXUn" ||
        customerPrice === "price_1Nr5PTJEnF6qjMZiqqEDvCCM"
      ? 5
      : 2;
  const ultimateCount =
    customerPrice === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
    customerPrice === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
    customerPrice === "price_1NrcfhJEnF6qjMZiefJh6cra"
      ? 6
      : customerPrice === "price_1NrchLJEnF6qjMZiBxInopoT" ||
        customerPrice === "price_1Nrch2JEnF6qjMZiEGeV65e8" ||
        customerPrice === "price_1NrcgjJEnF6qjMZiaLXJ0wuf"
      ? 7
      : customerPrice === "price_1NrciMJEnF6qjMZiGXzuyOfu" ||
        customerPrice === "price_1Nrci7JEnF6qjMZiU55b7OP5" ||
        customerPrice === "price_1NrchkJEnF6qjMZi8UVeSqNY"
      ? 8
      : customerPrice === "price_1NrcjTJEnF6qjMZilagP5YoA" ||
        customerPrice === "price_1Nrcj3JEnF6qjMZijnp3WNFm" ||
        customerPrice === "price_1NrcihJEnF6qjMZibyfQKCST"
      ? 9
      : customerPrice === "price_1NrckLJEnF6qjMZi7Z3y3Yi7" ||
        customerPrice === "price_1Nrck6JEnF6qjMZizx8aB3PQ" ||
        customerPrice === "price_1NrcjpJEnF6qjMZiTlAJ2R1W"
      ? 10
      : 10;
  const mastercountdisplay =
    customerPrice === "price_1NrZ3SJEnF6qjMZifdKYlDFo"
      ? "$39"
      : customerPrice === "price_1NrZ4jJEnF6qjMZiJjvHqJT0"
      ? "$44"
      : customerPrice === "price_1NrZ7AJEnF6qjMZiUgjO2W1q"
      ? "$49"
      : customerPrice === "price_1Nr5PrJEnF6qjMZihHXdaxIo"
      ? "$54"
      : customerPrice === "price_1NrZ3BJEnF6qjMZiC0mbaEzN"
      ? "$35"
      : customerPrice === "price_1NrZ4UJEnF6qjMZi1v4TgU02"
      ? "$39"
      : customerPrice === "price_1NrZ6KJEnF6qjMZiAaU4KZKz"
      ? "$44"
      : customerPrice === "price_1Nr5PjJEnF6qjMZiVIBveXUn"
      ? "$48"
      : customerPrice === "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      ? "$29"
      : customerPrice === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ"
      ? "$33"
      : customerPrice === "price_1NrZ5FJEnF6qjMZiHoyWDR4o"
      ? "$36"
      : "$40";

  const [masterAutoImportCount, setmasterAutoImportCount] =
    useState(masterCount);

  const closeImportChoicesModal = () => {
    setchoicesOpen(false);
  };
  const openImportChoicesModal = () => {
    setchoicesOpen(true);
  };
  const closeImportModal = () => {
    setimportOpen(false);
  };
  const openImportModal = () => {
    setimportOpen(true);
  };
  const closeUnlinkModal = () => {
    setunlinkOpen(false);
  };
  const openUnlinkModal = () => {
    setunlinkOpen(true);
  };
  const closeUpgradeModal = () => {
    setupgradeOpen(false);
  };
  const openUpgradeModal = () => {
    setupgradeOpen(true);
  };
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      const smartImport = document.getElementById("smartImportRadio");
      smartImport && (smartImport.checked = true);
      sethasOverwrite("Smart Import");
      var addedType = localStorage.getItem("savedsettings");
      if (addedType === "Unlink") {
        showConfirmedActionButton("Broker Unlinked!");
        localStorage.removeItem("savedsettings");
      } else if (addedType === "Link") {
        showConfirmedActionButton("Broker Linked!");
        localStorage.removeItem("savedsettings");
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [firstUpdate, selectedBroker]);

  const showInstructionClass =
    importState === "auto"
      ? "IntegrationImportModal"
      : !showInstructions
      ? "IntegrationImportModal2"
      : "IntegrationImportModalShow";

  // integrations array
  const integrations = [
    // AI IMPORT
/*     ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "aifileimport"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "aifileimport")
          .map((connection) => {
            return {
              // ai
              logo: goldbrain,
              logotop: "20px",
              logoheight: "60px",
              logowidth: "60px",
              comingSoon: false,
              sb: {
                label: "AI File",
                value: "AI File",
              },
              web3s: false,
              integrationInfo: integrationInfo?.aifileimport,
              autoi: false,
              filei: true,
              logotransform: "scale(1)",
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // ai
            logo: goldbrain,
            logotop: "20px",
            logoheight: "60px",
            logowidth: "60px",
            comingSoon: false,
            sb: {
              label: "AI File",
              value: "AI File",
            },
            web3s: false,
            integrationInfo: integrationInfo?.aifileimport,
            autoi: false,
            filei: true,
            logotransform: "scale(1)",
            connected: null,
          },
        ]), */

    // DYDX
/*     ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "dYdX"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "dYdX")
          .map((connection) => ({
            logo: dydxlogo,
            comingSoon: false,
            logotop: "-21px",
            sb: {
              label: "dYdX",
              value: "dYdX",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.25)",
            connected: autoImportConnectionsArray.filter(
              (conn) => conn.integrationId === connection.integrationId
            ),
          }))
      : [
          {
            logo: dydxlogo,
            comingSoon: false,
            logotop: "-21px",
            sb: {
              label: "dYdX",
              value: "dYdX",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.25)",
            connected: null, // or whatever default value you want
          },
        ]), */

    // METAMASK
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "metamask"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "metamask")
          .map((connection) => {
            return {
              // ethereum
              logo: etherlogo,
              comingSoon: false,
              logotransform: "scale(0.55)",
              logotop: "-8px",
              sb: {
                label: "MetaMask",
                value: "metamask",
              },
              web3s: true,
              integrationInfo: "",
              autoi: true,
              filei: false,
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // ethereum
            logo: etherlogo,
            comingSoon: false,
            logotransform: "scale(0.55)",
            logotop: "-8px",
            sb: {
              label: "MetaMask",
              value: "metamask",
            },
            web3s: true,
            integrationInfo: "",
            autoi: true,
            filei: false,
            connected: null,
          },
        ]),

    // INTERACTIVE BROKERS
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "interactivebrokers"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "interactivebrokers")
          .map((connection) => {
            return {
              // interactive brokers
              logo: iblogo,
              logotransform: "scale(0.6)",
              sb: {
                label: "Interactive Brokers",
                value: "interactivebrokers",
              },
              web3s: false,
              integrationInfo: "",
              autoi: true,
              filei: true,
              comingSoon: true,
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // interactive brokers
            logo: iblogo,
            logotransform: "scale(0.6)",
            sb: {
              label: "Interactive Brokers",
              value: "interactivebrokers",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            comingSoon: true,
            connected: null,
          },
        ]),

    // KRAKEN
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "kraken"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "kraken")
          .map((connection) => ({
            logo: krakenlogo,
            comingSoon: true,
            logotop: "8px",
            sb: {
              label: "Kraken",
              value: "kraken",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.5)",
            connected: autoImportConnectionsArray.filter(
              (conn) => conn.integrationId === connection.integrationId
            ),
          }))
      : [
          {
            logo: krakenlogo,
            comingSoon: true,
            logotop: "8px",
            sb: {
              label: "Kraken",
              value: "kraken",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.5)",
            connected: null, // or whatever default value you want
          },
        ]),

    // METATRADER 4
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "metatrader4"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "metatrader4")
          .map((connection) => {
            return {
              // mt4
              logo: mt4logo,
              comingSoon: false,
              logotop: "-2px",
              sb: {
                label: "MetaTrader 4",
                value: "metatrader4",
              },
              web3s: false,
              integrationInfo: integrationInfo?.metatrader4,
              autoi: true,
              filei: true,
              logotransform: "scale(0.6)",
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // mt4
            logo: mt4logo,
            comingSoon: false,
            logotop: "-2px",
            sb: {
              label: "MetaTrader 4",
              value: "metatrader4",
            },
            web3s: false,
            integrationInfo: integrationInfo?.metatrader4,
            autoi: true,
            filei: true,
            logotransform: "scale(0.6)",
            connected: null,
          },
        ]),

    // METATRADER 5
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "metatrader5"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "metatrader5")
          .map((connection) => {
            return {
              // mt5
              logo: mt5logo,
              logotop: "-3px",
              comingSoon: false,
              sb: {
                label: "MetaTrader 5",
                value: "metatrader5",
              },
              web3s: false,
              integrationInfo: integrationInfo?.metatrader5,
              autoi: true,
              filei: true,
              logotransform: "scale(0.58)",
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // mt5
            logo: mt5logo,
            logotop: "-3px",
            comingSoon: false,
            sb: {
              label: "MetaTrader 5",
              value: "metatrader5",
            },
            web3s: false,
            integrationInfo: integrationInfo?.metatrader5,
            autoi: true,
            filei: true,
            logotransform: "scale(0.58)",
            connected: null,
          },
        ]),

    // RITHMIC
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "rithmic"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "rithmic")
          .map((connection) => {
            return {
              // rithmic
              logo: rithmiclogo,
              logotop: "-3px",
              comingSoon: false,
              sb: {
                label: "Rithmic",
                value: "rithmic",
              },
              web3s: false,
              integrationInfo: integrationInfo?.rithmic,
              autoi: false,
              filei: true,
              logotransform: "scale(0.58)",
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // rithmic
            logo: rithmiclogo,
            logotop: "-8px",
            comingSoon: false,
            sb: {
              label: "Rithmic",
              value: "rithmic",
            },
            web3s: false,
            integrationInfo: integrationInfo?.rithmic,
            autoi: false,
            filei: true,
            logotransform: "scale(0.52)",
            connected: null,
          },
        ]),

    // ROBINHOOD
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "robinhood"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "robinhood")
          .map((connection) => {
            return {
              // robinhood
              logo: robinhoodlogo,
              comingSoon: false,
              logotop: "0px",
              sb: {
                label: "Robinhood",
                value: "robinhood",
              },
              web3s: false,
              integrationInfo: "",
              autoi: true,
              filei: false,
              logotransform: "scale(0.48)",
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // robinhood
            logo: robinhoodlogo,
            comingSoon: false,
            logotop: "0px",
            sb: {
              label: "Robinhood",
              value: "robinhood",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.48)",
            connected: null,
          },
        ]),

    // THINKORSWIM
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "thinkorswim"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "thinkorswim")
          .map((connection) => {
            return {
              // thinkorswim
              logo: thinkorswimlogo,
              logotransform: "scale(0.5)",
              logotop: "-16px",
              sb: { label: "thinkorswim", value: "thinkorswim" },
              web3s: false,
              integrationInfo: integrationInfo?.thinkorswim,
              autoi: true,
              filei: false,
              comingSoon: true,
              connected: autoImportConnectionsArray.filter(
                (conn) => conn.integrationId === connection.integrationId
              ),
            };
          })
      : [
          {
            // thinkorswim
            logo: thinkorswimlogo,
            logotransform: "scale(0.5)",
            logotop: "-16px",
            sb: { label: "thinkorswim", value: "thinkorswim" },
            web3s: false,
            integrationInfo: integrationInfo?.thinkorswim,
            autoi: true,
            filei: false,
            comingSoon: true,
            connected: null,
          },
        ]),

    // TRADESTATION
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "tradestation"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "tradestation")
          .map((connection) => ({
            logo: tradestationlogo,
            comingSoon: false,
            logotop: "8px",
            sb: {
              label: "tradestation",
              value: "tradestation",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.55)",
            connected: autoImportConnectionsArray.filter(
              (conn) => conn.integrationId === connection.integrationId
            ),
          }))
      : [
          {
            logo: tradestationlogo,
            comingSoon: true,
            logotop: "8px",
            sb: {
              label: "tradestation",
              value: "tradestation",
            },
            web3s: false,
            integrationInfo: "",
            autoi: true,
            filei: false,
            logotransform: "scale(0.55)",
            connected: null, // or whatever default value you want
          },
        ]),

    // TRADOVATE
    ...(autoImportConnectionsArray.filter(
      (connection) => connection.broker === "tradovate"
    ).length > 0
      ? autoImportConnectionsArray
          .filter((connection) => connection.broker === "tradovate")
          .map((connection) => {
            return {
              // tradovate
              logo: tradovatelogo,
              logotransform: "scale(0.475)",
              logotop: "-10px",
              sb: { label: "Tradovate", value: "tradovate" },
              web3s: false,
              integrationInfo: integrationInfo?.tradovate,
              autoi: false,
              filei: true,
              comingSoon: false,
              connected: null,
            };
          })
      : [
          {
            // tradovate
            logo: tradovatelogo,
            logotransform: "scale(0.475)",
            logotop: "-10px",
            sb: { label: "Tradovate", value: "tradovate" },
            web3s: false,
            integrationInfo: integrationInfo?.tradovate,
            autoi: false,
            filei: true,
            comingSoon: false,
            connected: null,
          },
        ]),
    /*     {
      // tradovate
      logo: tradovatelogo,
      logotransform: "scale(0.475)",
      logotop: "-10px",
      sb: { label: "Tradovate", value: "tradovate" },
      web3s: false,
      integrationInfo: integrationInfo?.tradovate,
      autoi: false,
      filei: true,
      comingSoon: false,
    }, */
  ];
  // Custom comparison function
  const compareFunction = (a, b) => {
    const brokerA = a.sb.value;
    const brokerB = b.sb.value;

    if (autoImportConnectionsArray.find((obj) => obj.broker === brokerA)) {
      return -1; // a should come before b
    } else if (
      autoImportConnectionsArray.find((obj) => obj.broker === brokerB)
    ) {
      return 1; // b should come before a
    } else {
      return 0; // order remains unchanged
    }
  };
  // Sort the second array based on the comparison function
  const sortedArray = integrations.sort(compareFunction);

  return (
    <div className={"integrationCardShow"}>
      <Modal
        ariaHideApp={false}
        id={showInstructionClass}
        className={showInstructionClass}
        isOpen={importOpen}
        onRequestClose={closeImportModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        {importState === "import" && (
          <ImportFromFileComponent
            tier={tier}
            active={active}
            showInstructions={showInstructions}
            //show100TradesCheck={showINIT}
            //showCheck={showINIT2}
            selectedBroker={selectedBroker}
            setShowInstructions={(e) => {
              setshowInstructions(e);
            }}
            setShowImport={(e) => {
              setimportOpen(e);
            }}
            allData={allData}
          />
        )}
        {importState === "auto" && (
          <AutoImportModal
            tier={tier}
            active={active}
            userData={allData}
            broker={selectedBroker}
            isweb3selected={isweb3selected}
            setShowImport={(e) => {
              setimportOpen(e);
            }}
            setShowError={(e) => {
              sethasError(e);
            }}
            setImportShow={() => {
              setimportOpen(true);
              setimportState("import");
              setisweb3selected(false);
              setselectedBroker(selectedBroker);
            }}
          />
        )}
      </Modal>
      <Modal
        ariaHideApp={false}
        id={"IntegrationImportModal3"}
        className={"IntegrationImportModal3"}
        isOpen={unlinkOpen}
        onRequestClose={closeUnlinkModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <UnlinkModal
          userData={allData}
          selectedBroker={selectedBroker}
          selectedConnectedBroker={selectedConnectedBroker}
          setunlinkOpen={(e) => setunlinkOpen(e)}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        id={"IntegrationImportModal4"}
        className={"IntegrationImportModal4"}
        isOpen={upgradeOpen}
        onRequestClose={closeUpgradeModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <div>
          <span className="autoimportmodalheading7">
            {"Add Integration Slots"}
          </span>
          <span className="newpricingtablecardCardMportnumberselector2">
            Need more portfolios with auto import?
            <PopoverStickOnHover
              component={
                <div
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    width: 200,
                    textAlign: "left",
                  }}
                >
                  The maximum number of portfolios with automated importing for
                  your current plan is <b>{integrationMaxCount}</b>. Upgrade to
                  add more!
                </div>
              }
              placement="bottom"
              onMouseEnter={() => {}}
              delay={300}
              setClass="accountNumberSuperWrapper"
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <span className="tooptip-i-style79">&#x1D48A;</span>
            </PopoverStickOnHover>
          </span>
          {/*           <div className="newpricingtablecardCardMautoitogglernew2">
            <button
              disabled={masterAutoImportCount < 3 ? true : false}
              className="newpricingtablecardCardMautoitoggler1"
              onClick={() =>
                setmasterAutoImportCount(masterAutoImportCount - 1)
              }
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>

            <div className="newpricingtablecardCardMautoitoggler2">
              {masterAutoImportCount}
            </div>

            <button
              disabled={masterAutoImportCount > 4 ? true : false}
              className="newpricingtablecardCardMautoitoggler3"
              onClick={() =>
                setmasterAutoImportCount(masterAutoImportCount + 1)
              }
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
          <div>
            <div>
              Currently paying:<div>{mastercountdisplay}</div>
            </div>
            <div>
              Upgraded price:<div>34</div>
            </div>
          </div> */}

          <div
            id="pricing-tier-button-master2"
            className="addmoreintegrationslotsbutton"
            onClick={() =>
              history.push({
                pathname: "/Manage-Account",
                state: {
                  account: "none",
                  subscription: "block",
                  actions: "none",
                },
              })
            }
            /*             onClick={async () => {
              payWallState === "expired"
                ? runExpiredStripeFunction(masterPriceId)
                : runInitialStripeFunction(masterPriceId);
            }} */
          >
            {"Upgrade Your Plan"}
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        id={"importChoiceModal"}
        className={"importChoiceModal"}
        isOpen={choicesOpen}
        onRequestClose={closeImportChoicesModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModalPic"
      >
        <h3 className="modalSymbolHeader47">{"Import Choice"}</h3>

        {importState === "import" && (
          <div className="importtypewrapper">
            <div
              style={{
                textAlign: "left",
                color: "#deebf7",
                margin: "20px 0px -20px 16px",
              }}
            >
              There are entries already logged in this portfolio. Choose your
              import method.
            </div>
            <div id="overwriteselectwrapper">
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#fbd665",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Recommended choice. Our smart algorithm looks for entries
                    you have imported before and won't import them again. The
                    rest are imported normally.
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container">
                  <label className="container checksygf3">
                    <input
                      type="radio"
                      name="overwrite"
                      id="smartImportRadio"
                      value={"Smart Import"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle radiocircleSM"></span>
                  </label>
                  <div className="radio-label-overwrite moveright">
                    <label>{"Smart Import"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#deebf7",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    No entries are deleted from this portfolio; every entry is
                    imported from the selected file. Could create duplicates.
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container">
                  <label className="container checksygf">
                    <input
                      type="radio"
                      name="overwrite"
                      id="orderTypeBuy"
                      value={"Keep Entries"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-nongold moveright">
                    <label>{"Keep"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#deebf7",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Deletes the entries in this portfolio before importing.
                    <div style={{ color: "#fa798b" }}>
                      Proceed with caution.
                    </div>
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container2 addedwidthoverwrite">
                  <label className="container checksygf2">
                    <input
                      type="radio"
                      name="overwrite"
                      id="orderTypeBuy"
                      value={"Overwrite Entries"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-nongold moveright">
                    <label>&nbsp;{"Overwrite"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
            </div>
          </div>
        )}
        {importState === "auto" && (
          <div id="existingtradestext">
            <div style={{ textAlign: "left" }}>
              There are entries already logged in this portfolio. Would you like
              to keep or overwrite them?
            </div>
            <span>
              <span id="optionsselectwrapper-upper">
                <div className="keep-entries-container">
                  <label className="container checksati1">
                    <input
                      type="radio"
                      name="legType"
                      id="orderTypeBuy"
                      value={"Keep Entries"}
                      onClick={(e) => {
                        setoverwriteentries(e.target.value);
                        //props.setOverwriteEntries(e.target.value);
                      }}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-buy">
                    <label>{"Keep Entries"}</label>
                  </div>
                </div>
                <div className="optionsellcall-container-upper">
                  <label className="container checksati2">
                    <input
                      type="radio"
                      name="legType"
                      id="orderTypeSell"
                      value={"Overwrite Entries"}
                      onClick={(e) => {
                        setoverwriteentries(e.target.value);
                        //props.setOverwriteEntries(e.target.value);
                      }}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-sell">
                    <label>{"Overwrite Entries"}</label>
                  </div>
                </div>
              </span>
            </span>
          </div>
        )}
      </Modal>
      {/*       <div className="Integrations-heading">Integrations</div> */}
      {/*       <BrokerRequests /> */}
      <div className="Integrations-wrapper">
        {sortedArray.map((i) => {
          return (
            <IntegrationsSubComponent
              key={i.sb?.label}
              logo={i.logo}
              logotop={i.logotop}
              logotransform={i.logotransform}
              logoheight={i.logoheight}
              logowidth={i.logowidth}
              sb={i.sb}
              web3s={i.web3s}
              integrationInfo={i.integrationInfo}
              autoi={i.autoi}
              filei={i.filei}
              connected={i.connected}
              comingSoon={i.comingSoon}
              errorShow={hasError}
              tier={tier}
              active={active}
              importCheckVar={importCheckVar}
              allautoImportConnectionsArray={allautoImportConnectionsArray}
              chosenPortfolios={chosenPortfolios}
              createdPortfolios={createdPortfolios}
              portfolio={portfolio}
              openImportModal={(e) => openImportModal(e)}
              closeImportModal={(e) => closeImportModal(e)}
              openImportChoicesModal={(e) => openImportChoicesModal(e)}
              openUnlinkModal={(e) => openUnlinkModal(e)}
              setimportState={(e) => setimportState(e)}
              setisweb3selected={(e) => setisweb3selected(e)}
              setselectedBroker={(e) => setselectedBroker(e)}
              setselectedConnectedBroker={(e) => setselectedConnectedBroker(e)}
              setshowUpgradeModal={(e) => props.setshowUpgradeModal(e)}
              setupgradeOpen={(e) => setupgradeOpen(e)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default IntegrationsComponent;
