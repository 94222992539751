import {
  getSpecificPortfolio,
  getMatchingPortfolios,
} from "../functions/getSpecificPortfolio";
import createDatafromSettingsProps from "./createDatafromSettingsFunction";

export const generateInitData = (allData) => {
  const allDataIn = allData;
  let portfolio = {};
  let portfolios = [];
  if (Object.keys(allDataIn).length === 0) {
  } else {
    const portfolioPre = getSpecificPortfolio(allDataIn);
    let propsToChangei = {};
    let globalpropsToChange = {};
    const settings = portfolioPre?.settings;

    // to ensure no errors if settings do not exist, we add them
    const finalPush = createDatafromSettingsProps(
      allDataIn.chosenPortfolios,
      allDataIn.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allDataIn.globalSettings,
      allDataIn.version
    );

    portfolio = getSpecificPortfolio(finalPush);
    portfolios = getMatchingPortfolios(finalPush);
  }

  // Create array of broker connections
  let connections = [];
  portfolios.forEach((portfolio) => {
    const linkedBrokerInfo = portfolio.settings.linkedBrokerInfo;
    if (linkedBrokerInfo.broker === "") {
    } else {
      connections.push(linkedBrokerInfo);
    }
  });
  return {
    connections: connections,
    portfolio: portfolio,
    matchingPortfolios: portfolios,
  };
};
export default generateInitData;
