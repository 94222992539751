import {
  getDateDifference,
  getDateDifferencewSeconds,
} from "../getDateDifference";
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const getMaxDD = (x) => {
  let peak = 0;
  let maxDrawdown = 0;
  let dd = 0;
  for (let i = 0, j = x.length; i < j; i++) {
    peak = x[i] > peak ? x[i] : peak;
    dd = peak - x[i];
    maxDrawdown = dd > maxDrawdown ? dd : maxDrawdown;
  }
  return maxDrawdown;
};
export const getMaxRU = (x) => {
  let trough = 2147483647;
  let maxRunUp = 0;
  let ru = 0;
  for (let i = 0, j = x.length; i < j; i++) {
    trough = x[i] < trough ? x[i] : trough;
    ru = x[i] - trough;
    maxRunUp = ru > maxRunUp ? ru : maxRunUp;
  }
  return maxRunUp;
};
export const getMaxDDPercent = (x) => {
  let peak = 0;
  let maxDrawdown = 0;
  let dd = 0;
  for (let i = 0, j = x.length; i < j; i++) {
    peak = x[i] > peak ? x[i] : peak;
    dd = ((peak - x[i]) / peak) * 100;
    maxDrawdown = dd > maxDrawdown ? dd : maxDrawdown;
  }
  return maxDrawdown;
};
export const getMaxRUPercent = (x) => {
  let trough = 2147483647;
  let maxRunUp = 0;
  let ru = 0;
  for (let i = 0, j = x.length; i < j; i++) {
    trough = x[i] < trough ? x[i] : trough;
    ru = ((x[i] - trough) / trough) * 100;
    maxRunUp = ru > maxRunUp ? ru : maxRunUp;
  }
  return maxRunUp;
};
function uniquePeaks(arr) {
  let uniquePeaksArr = [];
  for (let i = 0; i < arr.length; i++) {
    let peakExists = false;
    for (let j = 0; j < uniquePeaksArr.length; j++) {
      if (arr[i].peak === uniquePeaksArr[j].peak) {
        peakExists = true;
        if (arr[i].dd > uniquePeaksArr[j].dd) {
          uniquePeaksArr[j].dd = arr[i].dd;
        }
      }
    }
    if (!peakExists && arr[i].dd !== 0) {
      uniquePeaksArr.push(arr[i]);
    }
  }
  return uniquePeaksArr;
}
function uniqueTroughs(arr) {
  let uniqueTroughArr = [];
  for (let i = 0; i < arr.length; i++) {
    let troughExists = false;
    for (let j = 0; j < uniqueTroughArr.length; j++) {
      if (arr[i].trough === uniqueTroughArr[j].trough) {
        troughExists = true;
        if (arr[i].ru > uniqueTroughArr[j].ru) {
          uniqueTroughArr[j].ru = arr[i].ru;
        }
      }
    }
    if (!troughExists && arr[i].ru !== 0) {
      uniqueTroughArr.push(arr[i]);
    }
  }
  return uniqueTroughArr;
}

export const getRunUpCalcs = (x) => {
  let trough = 2147483647;
  let maxRunup = 0;
  let maxRunUpPercent = 0;
  let ru = 0;
  let ruPercent = 0;
  let date = "";
  let datePercent = "";
  let ruPeriods = [];
  let runupsPercent = [];
  let runups = [];
  let longestRunup = { start: null, end: null, difference: 0 };
  let currentRunup = { start: null, end: null, difference: 0 };
  for (let i = 0, j = x.length; i < j; i++) {
    if (x[i].balance < trough) {
      trough = x[i].balance;
    }
    ru = x[i].balance - trough;
    ruPercent = ((x[i].balance - trough) / trough) * 100;
    if (ru > maxRunup) {
      maxRunup = ru;
      date = x[i].endDate;
    } else {
    }
    if (ruPercent > maxRunUpPercent) {
      maxRunUpPercent = ruPercent;
      datePercent = x[i].endDate;
    } else {
    }
    // LONGEST RU
    // check if current item is the start of a new runup period
    if (i === 0) {
    } else {
      if (x[i].balance > trough && currentRunup.start === null) {
        currentRunup.start = x[i].startDate;
      }
      // check if current item is the end of a runup period
      if (x[i].balance <= trough && currentRunup.start !== null) {
        currentRunup.end = x[i - 1].endDate;
        currentRunup.difference = Math.abs(
          Math.floor(
            (new Date(currentRunup.end) - new Date(currentRunup.start)) / 1000
          )
        );
        if (currentRunup.difference > longestRunup.difference) {
          longestRunup = currentRunup;
        } else {
        }
        ruPeriods.push({ difference: currentRunup.difference, trough: trough });
        currentRunup = { start: null, end: null, difference: 0 };
      } else {
      }
      runups.push({ trough: trough, ru: ru });
      runupsPercent.push({ trough: trough, ru: ruPercent });

    }
  }
  let runupsNew = runups.slice();
  let runupsPercentNew = runupsPercent.slice();
  runupsNew.sort((a, b) => b.ru - a.ru);
  let uniquerunups = uniqueTroughs(runupsNew).map((ru) => {
    return ru.ru;
  });

  runupsPercentNew.sort((a, b) => b.ru - a.ru);
  let uniquerunupsPercent = uniqueTroughs(runupsPercentNew).map((ru) => {
    return ru.ru;
  });
  let uniqueDiffs = uniqueTroughs(ruPeriods).map((ru) => {
    return ru.difference;
  });

  if (longestRunup.end === null) {
    longestRunup.end = x[x.length - 1].endDate;
  }
  if (longestRunup.start === null) {
    longestRunup.start = currentRunup.start;
  }

  return {
    date: date,
    datePercent: datePercent,
    runupPeriod: getDateDifference(longestRunup.start, longestRunup.end),
    avgrunUpperiod: getDateDifferencewSeconds(arrAvg(uniqueDiffs)),
    fiveLargest: uniquerunups.slice(0, 5),
    fiveLargestPercents: uniquerunupsPercent.slice(0, 5),
    allRUs: uniquerunups,
    allRUsPercent: uniquerunupsPercent,
    maxRU: maxRunup,
    maxRUpercent: maxRunUpPercent,
  };
};

export const getDrawdownCalcs = (x) => {
  let ddPeriods = [];
  let drawdownsPercent = [];
  let drawdowns = [];
  let peak = 0;
  let maxDrawdown = 0;
  let maxDrawdownPercent = 0;
  let dd = 0;
  let ddPercent = 0;
  let date = "";
  let datePercent = "";

  let longestDrawdown = { start: null, end: null, difference: 0 };
  let currentDrawdown = { start: null, end: null, difference: 0 };
  for (let i = 0, j = x.length; i < j; i++) {
    if (x[i].balance > peak) {
      peak = x[i].balance;
    }
    dd = peak - x[i].balance;
    ddPercent = ((peak - x[i].balance) / peak) * 100;
    if (dd > maxDrawdown) {
      maxDrawdown = dd;
      date = x[i].endDate;
    } else {
    }
    if (ddPercent > maxDrawdownPercent) {
      maxDrawdownPercent = ddPercent;
      datePercent = x[i].endDate;
    } else {
    }
    // LONGEST DD
    // check if current item is the start of a new drawdown period
    if (i === 0) {
    } else {
      if (x[i].balance < peak && currentDrawdown.start === null) {
        currentDrawdown.start = x[i].startDate;
      }
      // check if current item is the end of a drawdown period
      if (x[i].balance >= peak && currentDrawdown.start !== null) {
        currentDrawdown.end = x[i].endDate;
        currentDrawdown.difference = Math.abs(
          Math.floor(
            (new Date(currentDrawdown.start) - new Date(currentDrawdown.end)) /
              1000
          )
        );
        if (currentDrawdown.difference > longestDrawdown.difference) {
          longestDrawdown = currentDrawdown;
        } else {
        }
        ddPeriods.push({ difference: currentDrawdown.difference, peak: peak });
        currentDrawdown = { start: null, end: null, difference: 0 };
      } else {
      }
      drawdowns.push({ peak: peak, dd: dd });
      drawdownsPercent.push({ peak: peak, dd: ddPercent });
    }
  }
  let drawdownsNew = drawdowns.slice();
  let drawdownsPercentNew = drawdownsPercent.slice();
  drawdownsNew.sort((a, b) => b.dd - a.dd);
  let uniquedrawdowns = uniquePeaks(drawdownsNew).map((dd) => {
    return dd.dd;
  });

  drawdownsPercentNew.sort((a, b) => b.dd - a.dd);
  let uniquedrawdownsPercent = uniquePeaks(drawdownsPercentNew).map((dd) => {
    return dd.dd;
  });
  let uniqueDiffs = uniquePeaks(ddPeriods).map((dd) => {
    return dd.difference;
  });

  // edge case if a single dd or ru takes up the entire graph
  if (longestDrawdown.end === null) {
    longestDrawdown.end = x[x.length - 1].endDate;
  }
  if (longestDrawdown.start === null) {
    longestDrawdown.start = currentDrawdown.start;
  }
  return {
    date: date,
    datePercent: datePercent,
    drawdownPeriod: getDateDifference(
      longestDrawdown.start,
      longestDrawdown.end
    ),
    avgdrawdownPeriod: getDateDifferencewSeconds(arrAvg(uniqueDiffs)),
    fiveLargest: uniquedrawdowns.slice(0, 5),
    fiveLargestPercents: uniquedrawdownsPercent.slice(0, 5),
    allDDs: uniquedrawdowns,
    allDDsPercent: uniquedrawdownsPercent,
    maxDD: maxDrawdown,
    maxDDpercent: maxDrawdownPercent,
  };
};

export default getMaxDD;
