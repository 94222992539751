import React from 'react';
import PropTypes from 'prop-types';

import PageTemplate from '../../components/PageTemplate/PageTemplate';


const EfficiencyReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Efficiency Report </div>
  } />
);

EfficiencyReport.defaultProps = {
tabName: "Efficiency"
}

EfficiencyReport.propTypes = {
tabName: PropTypes.string,
}

export default EfficiencyReport;

