import React from "react";
import {
  metricComponentforDollars,
  metricComponentforPercent,
} from "../../functions/metricComponents";
import {
  ProTierComponent,
  MasterTierComponent,
} from "../../components/tierComponents/pro";

var he = require("he");

export const showTotalMetricTable = (
  calcs,
  TotalMetricsLabels,
  props,
  tier,
  active
) => {
  let tableRows = [];
  for (let i = 0; i < TotalMetricsLabels.length; i++) {
    if (
      TotalMetricsLabels[i] === "Total P/L " + he.decode(props.defaultSymbol) ||
      TotalMetricsLabels[i] ===
        "Total Commissions " + he.decode(props.defaultSymbol) ||
      TotalMetricsLabels[i] ===
        "Total Fees " + he.decode(props.defaultSymbol) ||
      TotalMetricsLabels[i] ===
        "Total Slippage " + he.decode(props.defaultSymbol) ||
      TotalMetricsLabels[i] ===
        "Total Deposits " + he.decode(props.defaultSymbol) ||
      TotalMetricsLabels[i] ===
        "Total Withdrawals " + he.decode(props.defaultSymbol)
    ) {
      if (
        TotalMetricsLabels[i] ===
        "Total Slippage " + he.decode(props.defaultSymbol)
      ) {
        if (tier === "free") {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <MasterTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        } else if (tier === "pro" && active) {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <MasterTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        } else if (tier === "oldpro" && active) {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <MasterTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        } else if (tier === "master" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "ultimate" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "admin" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <MasterTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        }
      } else if (
        TotalMetricsLabels[i] ===
          "Total Commissions " + he.decode(props.defaultSymbol) ||
        TotalMetricsLabels[i] === "Total Fees " + he.decode(props.defaultSymbol)
      ) {
        if (tier === "free") {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <ProTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        } else if (tier === "pro" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "oldpro" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "master" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "ultimate" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else if (tier === "admin" && active) {
          if (Number(calcs[i]) > 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#55f8a6",
                props
              )
            );
          } else if (Number(calcs[i]) < 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#fa798b",
                props
              )
            );
          } else if (Number(calcs[i]) === 0) {
            tableRows = tableRows.concat(
              metricComponentforDollars(
                i,
                TotalMetricsLabels,
                calcs,
                "#818181",
                props
              )
            );
          } else {
            tableRows = tableRows.concat(
              <div className="outermetriccell" key={TotalMetricsLabels[i]}>
                <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
                <div className="innermetriccell">{calcs[i]}</div>
              </div>
            );
          }
        } else {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">
                {
                  <div style={{ position: "relative", top: -12, left: -10 }}>
                    <ProTierComponent />
                  </div>
                }
              </div>
            </div>
          );
        }
      } else {
        if (Number(calcs[i]) > 0) {
          tableRows = tableRows.concat(
            metricComponentforDollars(
              i,
              TotalMetricsLabels,
              calcs,
              "#55f8a6",
              props
            )
          );
        } else if (Number(calcs[i]) < 0) {
          tableRows = tableRows.concat(
            metricComponentforDollars(
              i,
              TotalMetricsLabels,
              calcs,
              "#fa798b",
              props
            )
          );
        } else if (Number(calcs[i]) === 0) {
          tableRows = tableRows.concat(
            metricComponentforDollars(
              i,
              TotalMetricsLabels,
              calcs,
              "#818181",
              props
            )
          );
        } else {
          tableRows = tableRows.concat(
            <div className="outermetriccell" key={TotalMetricsLabels[i]}>
              <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
              <div className="innermetriccell">{calcs[i]}</div>
            </div>
          );
        }
      }
    } else if (TotalMetricsLabels[i] === "Total P/L %") {
      if (Number(calcs[i]) > 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, TotalMetricsLabels, calcs, "#55f8a6")
        );
      } else if (Number(calcs[i]) < 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, TotalMetricsLabels, calcs, "#fa798b")
        );
      } else if (Number(calcs[i]) === 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, TotalMetricsLabels, calcs, "#818181")
        );
      } else {
        tableRows = tableRows.concat(
          <div className="outermetriccell" key={TotalMetricsLabels[i]}>
            <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
            <div className="innermetriccell">{calcs[i]}</div>
          </div>
        );
      }
    } else {
      tableRows = tableRows.concat(
        <div className="outermetriccell" key={TotalMetricsLabels[i]}>
          <div className="innermetriccell1">{TotalMetricsLabels[i]}</div>
          <div className="innermetriccell">{calcs[i]}</div>
        </div>
      );
    }
  }
  return tableRows;
};

export const showAvgMetricTable = (calcs, AvgMetricsLabels, props) => {
  let tableRows = [];
  for (let i = 0; i < AvgMetricsLabels.length; i++) {
    if (
      AvgMetricsLabels[i] ===
        "Avg. P/L Per Trade " + he.decode(props.defaultSymbol) ||
      AvgMetricsLabels[i] ===
        "Avg. P/L Per Pip " + he.decode(props.defaultSymbol) ||
      AvgMetricsLabels[i] ===
        "Avg. Slippage Per Trade " + he.decode(props.defaultSymbol)
    ) {
      if (Number(calcs[i]) > 0) {
        tableRows = tableRows.concat(
          metricComponentforDollars(
            i,
            AvgMetricsLabels,
            calcs,
            "#55f8a6",
            props
          )
        );
      } else if (Number(calcs[i]) < 0) {
        tableRows = tableRows.concat(
          metricComponentforDollars(
            i,
            AvgMetricsLabels,
            calcs,
            "#fa798b",
            props
          )
        );
      } else if (Number(calcs[i]) === 0) {
        tableRows = tableRows.concat(
          metricComponentforDollars(
            i,
            AvgMetricsLabels,
            calcs,
            "#818181",
            props
          )
        );
      } else {
        tableRows = tableRows.concat(
          <div className="outermetriccell" key={AvgMetricsLabels[i]}>
            <div className="innermetriccell1">{AvgMetricsLabels[i]}</div>
            <div className="innermetriccell">{calcs[i]}</div>
          </div>
        );
      }
    } else if (AvgMetricsLabels[i] === "Average P/L Per Trade %") {
      if (Number(calcs[i]) > 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, AvgMetricsLabels, calcs, "#55f8a6")
        );
      } else if (Number(calcs[i]) < 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, AvgMetricsLabels, calcs, "#fa798b")
        );
      } else if (Number(calcs[i]) === 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, AvgMetricsLabels, calcs, "#818181")
        );
      } else {
        tableRows = tableRows.concat(
          <div className="outermetriccell" key={AvgMetricsLabels[i]}>
            <div className="innermetriccell1">{AvgMetricsLabels[i]}</div>
            <div className="innermetriccell">{calcs[i]}</div>
          </div>
        );
      }
    } else {
      tableRows = tableRows.concat(
        <div className="outermetriccell" key={AvgMetricsLabels[i]}>
          <div className="innermetriccell1">{AvgMetricsLabels[i]}</div>
          <div className="innermetriccell">{calcs[i]}</div>
        </div>
      );
    }
  }
  return tableRows;
};

export const showMISCMetricTable = (calcs, MiscMetricsLabels, props) => {
  let tableRows = [];
  for (let i = 0; i < MiscMetricsLabels.length; i++) {
    if (
      MiscMetricsLabels[i] === "Standard Deviation Per Trade %" ||
      MiscMetricsLabels[i] === "Coefficient of Variation Per Trade %" ||
      MiscMetricsLabels[i] === "Coefficient of Variation Per Trade $"
    ) {
      if (Number(calcs[i]) > 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, MiscMetricsLabels, calcs, "#55f8a6")
        );
      } else if (Number(calcs[i]) < 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, MiscMetricsLabels, calcs, "#fa798b")
        );
      } else if (Number(calcs[i]) === 0) {
        tableRows = tableRows.concat(
          metricComponentforPercent(i, MiscMetricsLabels, calcs, "#818181")
        );
      } else {
        tableRows = tableRows.concat(
          <div className="outermetriccell" key={MiscMetricsLabels[i]}>
            <div className="innermetriccell1">{MiscMetricsLabels[i]}</div>
            <div className="innermetriccell">{calcs[i]}</div>
          </div>
        );
      }
    } else {
      tableRows = tableRows.concat(
        <div className="outermetriccell" key={MiscMetricsLabels[i]}>
          <div className="innermetriccell1">{MiscMetricsLabels[i]}</div>
          <div className="innermetriccell">{calcs[i]}</div>
        </div>
      );
    }
  }
  return tableRows;
};

export default metricComponentforDollars;
