import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import AIInsightsComponent from "./Component";
import "./styles.scss";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const AIReport = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;

  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "AI Insights - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <AIInsightsComponent
        allData={processedData}
        tabName={"AI Insights"}
        pageName={"AI Insights"}
        tier={tier}
        active={active}
      />
    );
  }
};

AIReport.defaultProps = {
  tabName: "AI Insights",
  pageName: "AI Insights",
};

AIReport.propTypes = {
  tabName: PropTypes.string,
};

export default AIReport;
