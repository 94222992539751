import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../../components/PageTemplate/PageTemplate';


const LossesReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Losses Report </div>
  } />
);

LossesReport.defaultProps = {
tabName: "Losses"
}

LossesReport.propTypes = {
tabName: PropTypes.string,
}

export default LossesReport;

