const initialState = {
  open: false,
};

export const filtersOpen = (state = initialState, action) => {
  switch (action.type) {
    case "SETFILTERSOPEN":
      return action.npmstrat;

    default:
      return state;
  }
};

export default filtersOpen;
