import $ from "jquery";
import {
  ProfileIconHoverOnStyle,
  ProfileIconHoverOffStyle,
} from "./InitialStyledQuery";

export const profileiconfunction = (globalvar, history, location) => {
  $(".profile-icon").hover(
    function () {
      ProfileIconHoverOnStyle(".profile-icon");
    },
    function () {
      if ($(".account-dropdown").css("display") === "flex") {
        ProfileIconHoverOnStyle(".profile-icon");
      } else {
        ProfileIconHoverOffStyle(".profile-icon");
      }
    }
  );

  $(".profile-icon").click(function () {
    $(".tooltip-bg").hide();
    $(".filter-icon").css({
      backgroundColor: "transparent",
    });
    $(".portfolio-dropdown").css({
      display: "none",
    });
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    const dropdown = $(".account-dropdown");
    const dropdown2 = $(".logout-dropdown");

    const profileicon = $("#profile-icon");
    const editelem = $(".editdashboardcomponenntwrapper");

    if (location.pathname === "/Error-Found") {
      if (dropdown2.css("display") === "flex") {
        dropdown2.css({
          display: "none",
        });
        ProfileIconHoverOffStyle(".profile-icon");
        profileicon.css({ border: "2px solid #808080" });
      } else {
        if (!editelem.css("width")) {
          dropdown2.css({
            right: "14px",
          });
        } else {
          dropdown2.css({
            right: "356px",
          });
        }
        dropdown2.css({
          display: "flex",
        });
        ProfileIconHoverOnStyle(".profile-icon");
        profileicon.css({ border: "2px solid #fbd665" });
      }
    } else {
      if (dropdown.css("display") === "flex") {
        dropdown.css({
          display: "none",
        });
        ProfileIconHoverOffStyle(".profile-icon");
        profileicon.css({ border: "2px solid #808080" });
      } else {
        if (!editelem.css("width")) {
          dropdown.css({
            right: "14px",
          });
        } else {
          dropdown.css({
            right: "356px",
          });
        }
        dropdown.css({
          display: "flex",
        });
        ProfileIconHoverOnStyle(".profile-icon");
        profileicon.css({ border: "2px solid #fbd665" });
      }
    }

    globalvar.filterbuffervalue = 0;

    if ($(".tooltip-bg").is(":visible")) {
      $(".forum-icon").css({ "background-color": "#1f2831" });
    } else {
      $(".forum-icon").css({ "background-color": "#14181e" });
    }
  });
};
export const portfoliodropdownfunction = (globalvar) => {
  $(".viewingportbutton").click(function () {
    $(".tooltip-bg").hide();
    $(".filter-icon").css({
      backgroundColor: "transparent",
    });
    const dropdown = $(".portfolio-dropdown");
    const editelem = $(".editdashboardcomponenntwrapper");
    if (dropdown.css("display") === "flex") {
      ProfileIconHoverOffStyle(".profile-icon");
      $(".portfolio-dropdown").css({
        display: "none",
      });
      $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    } else {
      ProfileIconHoverOnStyle(".profile-icon");
      if (!editelem.css("width")) {
        if (window.matchMedia("(min-width: 590px)").matches) {
          $(".portfolio-dropdown").css({
            right: "276px",
          });
        }
        if (window.matchMedia("(max-width: 590px)").matches) {
          $(".portfolio-dropdown").css({
            right: "98px",
          });
        }
      } else {
        if (window.matchMedia("(min-width: 590px)").matches) {
          $(".portfolio-dropdown").css({
            right: "626px",
          });
        }
        if (window.matchMedia("(max-width: 590px)").matches) {
          $(".portfolio-dropdown").css({
            right: "448px",
          });
        }
      }
      $(".portfolio-dropdown").css({
        display: "flex",
      });
      $(".viewingportbutton").addClass("viewingportbuttonhovered");
    }
    globalvar.filterbuffervalue = 0;

    if ($(".tooltip-bg").is(":visible")) {
      $(".forum-icon").css({ "background-color": "#1f2831" });
    } else {
      $(".forum-icon").css({ "background-color": "#14181e" });
    }
  });
};
export const portfolioiconfunction = (globalvar) => {
  $(".portfolio-icon").click(function () {
    $(".tooltip-bg").hide();
    $(".account-dropdown").css({
      display: "none",
    });
    $(".portfolio-dropdown").css({
      display: "none",
    });
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    $("#portfolioModal").fadeIn(300);
    $("#overlay").fadeIn(300);
    $(".mandatory3port").css({ display: "none" });
    $(".mandatory3port2").css({ display: "none" });
    $(".filter-icon").css({
      backgroundColor: "transparent",
    });
    $(".portfolio-icon").css({ color: "#fbd665" });
    globalvar.filterbuffervalue = 0;
    if ($(".account-dropdown").css("display") === "flex") {
      ProfileIconHoverOnStyle(".profile-icon");
    } else {
      ProfileIconHoverOffStyle(".profile-icon");
    }
    if ($(".tooltip-bg").is(":visible")) {
      $(".forum-icon").css({ "background-color": "#1f2831" });
    } else {
      $(".forum-icon").css({ "background-color": "#14181e" });
    }
  });
};

export default profileiconfunction;
