import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import pinnedMetrics from "./arrays";
import getColorPoint from "../../functions/includeColor";

const PinnedMetrics = (props) => {
  const firstUpdate = useRef(true);
  const dashboardSettings = props.userSettings.dashboardElements;
  const ref = useRef(null);
  const color = useRef("");

  useEffect(() => {
    /*     document.onreadystatechange = () => {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
      console.log(ref.current.clientWidth);
    }; */
    if (firstUpdate.current) {
    }

    return () => {
      firstUpdate.current = false;
    };
  }, []);
  const returnMetrics = () => {
    let elements = [];
    let graphs = [
      "maxdrawdownpercent",
      "assetclasses",
      "winlossesbe",
      "averageefficiency",
      "percenttimeinmarket",
    ]; /* dashboardSettings.pinnedPercentages */
    const equityvaluefunction = (calcprop) => {
      let returnValue = 0;
      if (calcprop) {
        returnValue = props.userCalculations[calcprop];
      }
      return returnValue;
    };
    const equityvalue = (calcprop) => {
      return (
        calcprop &&
        (props.userCalculations[calcprop] === "-"
          ? 0
          : props.userCalculations[calcprop])
      );
    };
    const colours = (color, calcprop) => {
      const returnColor =
        calcprop === "winlossesbe" || calcprop === "assetclasses"
          ? color
          : ["#28303c", color];
      return returnColor;
    };
    const data = (title, calcprop, color) => {
      const metric = equityvalue(calcprop);
      const totaltrades =
        equityvalue("totalTrades") === 0 ? 1 : equityvalue("totalTrades");
      return {
        labels: [title],
        datasets: [
          {
            data:
              calcprop === "winlossesbe"
                ? [
                    metric?.wins / totaltrades,
                    metric?.losses / totaltrades,
                    metric?.bes / totaltrades,
                  ]
                : calcprop === "assetclasses"
                ? [
                    metric?.totalStockTrades,
                    metric?.totalCryptoTrades,
                    metric?.totalCFDTrades,
                    metric?.totalForexTrades,
                    metric?.totalFutureTrades,
                    metric?.totalOptionTrades,
                  ]
                : [100 - metric, metric].reverse(),
            backgroundColor: colours(color, calcprop).reverse(),
            borderColor: colours(color, calcprop).reverse(),
          },
        ],
      };
    };
    const options = (title, calcprop) => {
      const numWins = equityvaluefunction(calcprop)?.wins;
      const numLosses = equityvaluefunction(calcprop)?.losses;
      const numBEs = equityvaluefunction(calcprop)?.bes;

      const totalStockTrades = equityvaluefunction(calcprop)?.totalStockTrades;
      const totalCryptoTrades =
        equityvaluefunction(calcprop)?.totalCryptoTrades;
      const totalCFDTrades = equityvaluefunction(calcprop)?.totalCFDTrades;
      const totalForexTrades = equityvaluefunction(calcprop)?.totalForexTrades;
      const totalFutureTrades =
        equityvaluefunction(calcprop)?.totalFutureTrades;
      const totalOptionTrades =
        equityvaluefunction(calcprop)?.totalOptionTrades;

      const numTrades = numWins + numLosses + numBEs;
      const numTrades2 =
        totalStockTrades +
        totalCryptoTrades +
        totalCFDTrades +
        totalForexTrades +
        totalFutureTrades +
        totalOptionTrades;

      const winRate = ((numWins * 100) / numTrades).toFixed(3);
      const lossRate = ((numLosses * 100) / numTrades).toFixed(3);
      const besRate = ((numBEs * 100) / numTrades).toFixed(3);
      const stockRate = ((totalStockTrades * 100) / numTrades2).toFixed(3);
      const cryptoRate = ((totalCryptoTrades * 100) / numTrades2).toFixed(3);
      const cfdRate = ((totalCFDTrades * 100) / numTrades2).toFixed(3);
      const forexRate = ((totalForexTrades * 100) / numTrades2).toFixed(3);
      const futureRate = ((totalFutureTrades * 100) / numTrades2).toFixed(3);
      const optionsRate = ((totalOptionTrades * 100) / numTrades2).toFixed(3);
      return {
        tooltips: {
          enabled: true,
          backgroundColor: "#14181e",
          titleFontSize: 14,
          titleFontColor: "#fbd665",
          bodyFontColor: "#fff7cb",
          bodyFontSize: 14,
          displayColors: false,
          callbacks: {
            title: (e) => {
              return "";
            },
            afterBody: function (e) {
              // do some stuff
              //multistringText.push("another string");
              let percent;
              if (equityvaluefunction(calcprop) === "-") {
              } else if (calcprop === "winlossesbe") {
                percent =
                  e[0].index === 0
                    ? winRate
                    : e[0].index === 1
                    ? lossRate
                    : besRate;
                return "(" + percent + "%)";
              } else if (calcprop === "assetclasses") {
                percent =
                  e[0].index === 0
                    ? stockRate
                    : e[0].index === 1
                    ? cryptoRate
                    : e[0].index === 2
                    ? cfdRate
                    : e[0].index === 3
                    ? forexRate
                    : e[0].index === 4
                    ? futureRate
                    : optionsRate;
                return "(" + percent + "%)";
              }
              return;
            },
            label: (e) => {
              let returnthis = "";
              if (equityvaluefunction(calcprop) === "-") {
                returnthis = "";
              } else if (calcprop === "winlossesbe") {
                returnthis =
                  e.index === 0
                    ? [`Wins: ${numWins} `]
                    : e.index === 1
                    ? [`Losses: ${numLosses} `]
                    : [`Break Even's: ${numBEs}`];
              } else if (calcprop === "assetclasses") {
                returnthis =
                  e.index === 0
                    ? [`Stock Trades: ${totalStockTrades}`]
                    : e.index === 1
                    ? [`Crypto Trades: ${totalCryptoTrades}`]
                    : e.index === 2
                    ? [`CFD Trades: ${totalCFDTrades}`]
                    : e.index === 3
                    ? [`Forex Trades: ${totalForexTrades}`]
                    : e.index === 4
                    ? [`Futures Trades: ${totalFutureTrades}`]
                    : [`Options Trades: ${totalOptionTrades}`];
              } else {
                returnthis = equityvaluefunction(calcprop) + "%";
              }
              return returnthis;
            },
          },
        },
        animation: {
          animateRotate: true,
          duration: 1700,
          render: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 90,
        legend: {
          reverse: true,
          display: false,
        },
      };
    };

    graphs.length &&
      graphs.forEach((g) => {
        const filterByID = (metric) => {
          const metricKey = Object.keys(metric)[0];
          if (metricKey === g) {
            return true;
          } else {
            return false;
          }
        };
        let riskText;
        let neededGraphPre = pinnedMetrics.filter(filterByID)[0];
        let neededGraph = neededGraphPre[Object.keys(neededGraphPre)[0]];
        const totalWins = equityvalue(neededGraph.calcprop)?.wins;
        const totalLosses = equityvalue(neededGraph.calcprop)?.losses;
        const totalBEs = equityvalue(neededGraph.calcprop)?.bes;
        const totalStockTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalStockTrades;
        const totalCryptoTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalCryptoTrades;
        const totalCFDTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalCFDTrades;
        const totalForexTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalForexTrades;
        const totalFutureTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalFutureTrades;
        const totalOptionTrades = equityvaluefunction(
          neededGraph.calcprop
        )?.totalOptionTrades;
        const graphPropValues = Number(
          equityvaluefunction(neededGraph.calcprop)
        );
        if (neededGraph.calcprop === "avgRisk") {
          if (graphPropValues < 5) {
            color.current = getColorPoint(
              "#49f875",
              "#e1f967",
              graphPropValues / 5,
              "hex"
            );
            if (graphPropValues < 1) {
              riskText = "Very Low Risk";
            } else if (graphPropValues < 3) {
              riskText = "Low Risk";
            } else {
              riskText = "Moderate Risk";
            }
          } else if (graphPropValues >= 5 && graphPropValues < 10) {
            color.current = getColorPoint(
              "#e1f967",
              "#ff9735",
              (graphPropValues - 5) / 5,
              "hex"
            );
            if (graphPropValues < 10) {
              riskText = "High Risk";
            }
          } else if (graphPropValues >= 10 && graphPropValues < 15) {
            riskText = "Very High Risk";
            color.current = getColorPoint(
              "#ff9735",
              "rgb(238, 73, 73)",
              (graphPropValues - 10) / 5,
              "hex"
            );
          } else if (graphPropValues >= 15 && graphPropValues < 20) {
            riskText = "Very High Risk";
            color.current = getColorPoint(
              "rgb(238, 73, 73)",
              "rgb(139, 43, 43)",
              (graphPropValues - 15) / 5,
              "hex"
            );
          } else {
            riskText = "Very High Risk";
            color.current = "rgb(139, 43, 43)";
          }
        }
        let riskColor = "";
        riskColor =
          graphPropValues === "-" || !graphPropValues
            ? "#1a2028"
            : color.current;

        const graph = neededGraph && (
          <div
            className="winrategraph noselect"
            key={"winrategraph" + g}
            id={g}
          >
            <div className="deletepicbutton2" onClick={() => {}}>
              X
            </div>
            <div className="winrateheader">{neededGraph.title}</div>
            <div className="winrategraphdivtextwrapper" ref={ref}>
              <div className={neededGraph.graphClass}>
                <Doughnut
                  data={data(
                    neededGraph.title,
                    neededGraph.calcprop,
                    neededGraph.calcprop === "avgRisk"
                      ? riskColor
                      : neededGraph.color
                  )}
                  options={options(neededGraph.title, neededGraph.calcprop)}
                  className="winrategraphactual"
                />
                <div
                  /*               style={{ margin: `-${height - 4}px 0px 0px 0px` }} */
                  id="pinnedmetricvalue"
                  className={
                    neededGraph.calcprop === "avgRisk"
                      ? ""
                      : neededGraph.valueClass
                  }
                  style={{
                    color:
                      neededGraph.calcprop === "avgRisk"
                        ? riskColor
                        : neededGraph.color,
                  }}
                >
                  {neededGraph.calcprop === "winlossesbe" ? (
                    <div
                      style={{
                        margin: "4px 0px 0px 0px",
                        display: "flex",
                        flexFlow: "column",
                        textAlign: "center",
                        fontSize: "0.875em",
                      }}
                    >
                      <div>{`W: ${totalWins}`}</div>
                      <div>{`L: ${totalLosses}`}</div>
                      <div>{`BE: ${totalBEs}`}</div>
                    </div>
                  ) : neededGraph.calcprop === "assetclasses" ? (
                    <div
                      style={{
                        margin: "4px 0px 0px 0px",
                        display: "flex",
                        flexFlow: "column",
                        textAlign: "center",
                        fontSize: "0.54em",
                      }}
                    >
                      <div>{`Stocks: ${totalStockTrades}`}</div>
                      <div>{`Crypto: ${totalCryptoTrades}`}</div>
                      <div>{`CFDs: ${totalCFDTrades}`}</div>{" "}
                      <div>{`Forex: ${totalForexTrades}`}</div>
                      <div>{`Futures: ${totalFutureTrades}`}</div>
                      <div>{`Options: ${totalOptionTrades}`}</div>
                    </div>
                  ) : equityvaluefunction(neededGraph.calcprop) === "-" ? (
                    "-%"
                  ) : (
                    equityvaluefunction(neededGraph.calcprop) + "%"
                  )}
                </div>
              </div>
            </div>
            <div
              className="riskmetriclabel2"
              style={{
                color: riskColor,
                /*               filter: `drop-shadow(0 0 2px ${riskColor})`,
              msFilter: `drop-shadow(0 0 2px ${riskColor})`,
              MozFilter: `drop-shadow(0 0 2px ${riskColor})`,
              WebkitFilter: `drop-shadow(0 0 2px ${riskColor})`, */
              }}
            >
              {riskText}
            </div>
          </div>
        );
        elements.push(graph);
      });
    return elements;
  };
  return (
    <div className="pinnedMetricsWrapper" id="pinnedMetricsWrapper">
      {returnMetrics()}
    </div>
  );
};

export default PinnedMetrics;
