import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import processDataandSettings from "./../../utils/processDataandSettings";
import { DataContext } from "../../DataContext";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";
import QuantumQueryComponent from "./component";
import useForceUpdate from "../../hooks/useForceUpdate";

const QuantumQuery = (props) => {
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;

  const riskFreeRate = dataInstance.miscData.riskFreeRate;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;
  const forceUpdate = useForceUpdate();

  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;

  let showINIT = false;
  // Tiering Logic
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = true;
  } else if (tier === "oldpro" && active) {
    showINIT = true;
  } else if (tier === "master" && active) {
    showINIT = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
  } else {
    showINIT = true;
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect:
      tier === "pro" && active
        ? "QuantumQuery"
        : tier === "master" && active
        ? "QuantumQuery Advanced"
        : "",
    blur: showINIT,
    tierText:
      tier === "pro" && active
        ? "master"
        : tier === "master" && active
        ? "ultimate"
        : "",
  });

  useEffect(() => {
    if (tier === "free") {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "pro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "oldpro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "master" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "ultimate" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "admin" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    }
  }, [tier, active]);
  let processedData = {};

  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "QuantumQuery - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return <PageTemplateBlank title={props.tabName} showloader={true} />;
  } else {
    return (
      <PageTemplate
        title={props.tabName}
        upgradeShow={showUpgradeModal.current}
        setshowUpgradeModal={(e) => {
          showUpgradeModal.current = {
            ...showUpgradeModal.current,
            show: e.show,
            featureSelect: e.featureSelect,
            blur: e.blur,
            tierText: e.tierText,
          };
          forceUpdate();
        }}
        page={
          <QuantumQueryComponent
            userData={allData}
            processedData={processedData}
            tier={tier}
            active={active}
            riskFreeRate={riskFreeRate}
            setshowUpgradeModal={(e) => {
              showUpgradeModal.current = {
                ...showUpgradeModal.current,
                show: e.show,
                featureSelect: e.featureSelect,
                blur: e.blur,
                tierText: e.tierText,
              };
              forceUpdate();
            }}
          />
        }
        userData={processedData}
      />
    );
  }
};

QuantumQuery.defaultProps = {
  tabName: "QuantumQuery",
  pageName: "QuantumQuery",
};

QuantumQuery.propTypes = {
  tabName: PropTypes.string,
};

export default QuantumQuery;
