const initialState =  {
  tempsettings: {
    currencySymbol: "&#36;",
    historyColumnDisplay: {
      dateTimeOpened: true,
      dateTimeClosed: true,
      expirationDate: false,
      holdingTime: false,
      profitLoss: true,
      netPLdollar: false,
      netPLpercent: false,
      orderType: true,
      symbol: true,
      lotSize: true,
      strikePrice: false,
      entryPrice: true,
      expectedEntry: false,
      stopLoss: true,
      takeProfit: true,
      exitPrice: true,
      expectedExit: false,
      slippage: false,
      maxAdEx: false,
      maxFavEx: false,
      commissions: false,
      fees: false,
      riskpercent: false,
      efficiency: false,
      rewardriskratio: false,
      rmultiple: false,
      strategy: true,
      selectedConfidence: false,
      selectedEmotion: false,
      selectedMarket: false,
      selectedTimeFrame: false,
      selectedMistake: true,
      selectedTags: true,
    },
    colorTheme: "dark",
    calculationType: "Gross",
    performanceMetricCalculationType: "Dollar",
    performanceMetricCalculationAriGeo: "Arithmetic",
    performanceMetricGraphs: [""],
  },
}

export const temporarySettings = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SETTINGS_LIST': 
      return {
        ...state, 
        tempsettings: {
          ...state.tempsettings,
          [action.id]: action.value
        }
      }
    default: 
      return state
  }
}

export default temporarySettings