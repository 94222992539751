export function calculateRoySafetyFirstCriteria(trades) {
  // Initialize variables
  let sum = 0;
  let maxDrawdown = 0;
  let prevMax = 0;

  // Iterate through trades
  for (let i = 0; i < trades.length; i++) {
    // Add the balance after the trade to the sum
    sum += trades[i];

    // Update the previous max
    prevMax = Math.max(prevMax, sum);

    // Calculate the drawdown for this trade
    const drawdown = prevMax - sum;

    // Update the max drawdown if necessary
    maxDrawdown = Math.max(maxDrawdown, drawdown);
  }

  // Calculate and return the Safety First Criteria
  return sum / maxDrawdown;
}
export default calculateRoySafetyFirstCriteria;
