import getMaxDD from "../functions/calculations_for_metrics/getmaxDD";
import slippageCalculation from "../functions/slippageCalculation";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../functions/getStartorEndDateTime";
import { handleNANGOOD } from "./../functions/handleUndefined";

import getEntryExitLots from "./../functions/getEntryExitLots";
import { returnNetPLDollar } from "./../functions/profitLossCalculation";
export const OverallPerformanceCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW
) => {
  function getStandardDeviation(array) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b, 0) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n
    );
  }
  let result = {};
  let totalPL_dollar = 0;
  let totalPL_percent = 0;
  let totalLotsTraded = 0;
  let totalTrades = 0;
  let totalCommissions = 0;
  let totalFees = 0;
  let totalSlippage = 0;
  let totalDeposits = 0;
  let totalWithdrawals = 0;
  let totalDW = start_DW;
  let miscellaneousHighestEquity = 0;
  let miscellaneousMaxLotSize = 0;
  let bufferlotSize = 0;
  let totalLosingTrades = 0;
  let miscellaneousStdDevPerTradeDollartemparray = [];
  let miscellaneousDownsideDevPerTradeDollartemparray = [];

  //let miscellaneousStdDevPerTradePercenttemparray = [];
  let accountbalancearr = [];
  let totalStrategies = 0;
  let totalSymbols = 0;
  let totalExectutions = 0;
  let symbolarr = [];
  let strategyarr = [];
  let totalPipsGainedLost = 0; // total Pips Gained Lost
  // Main For Loop

  for (let i = 0, j = data.length; i < j; i++) {
    let correctedSUM = 0;
    let x100profitLoss = Number(data[i].entry.profitLoss * 100);
    let x100commissions = Number(data[i].entry.commissions * 100);
    let x100fees = Number(data[i].entry.fees * 100);
    if (calctype === "Gross") {
      correctedSUM = x100profitLoss / 100;
    } else if (calctype === "Net") {
      correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
    }
    //add elements to symbol and strategy arrays
    const thisAccountBalance = Number(data[i].balance);

    // Highest Equity
    if (thisAccountBalance > miscellaneousHighestEquity) {
      miscellaneousHighestEquity = thisAccountBalance;
    } else {
    }
    // Total fees
    totalFees += x100fees / 100;
    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      if (data[i].entry.orderType === "Deposit") {
        if (calctype === "Gross") {
          totalDeposits += correctedSUM;
        } else if (calctype === "Net") {
          totalDeposits += correctedSUM;
        }
      } else if (data[i].entry.orderType === "Withdrawal") {
        if (calctype === "Gross") {
          totalWithdrawals += correctedSUM;
        } else if (calctype === "Net") {
          totalWithdrawals += correctedSUM;
        }
      } else {
        /*         if (calctype === "Gross") {
            totalDeposits += x100profitLoss / 100;
          } else if (calctype === "Net") {
            totalDeposits += (x100profitLoss + x100fees) / 100;
          } */
      }
      totalDW += correctedSUM;
      continue;
    } else {
      const newSymbol = data[i].entry.symbol;
      const newnewSymbol = newSymbol.symbols;
      const slippage = slippageCalculation(
        data[i].entry.multiExecution,
        data[i].entry.exitExecution,
        data[i].entry.orderType,
        data[i].entry.selectedPortfolioType,
        data[i].entry.symbol.pointValue,
        false,
        data[i].entry.profitLoss
      );

      if (
        newnewSymbol === undefined ||
        newnewSymbol === "" ||
        newnewSymbol === null ||
        !newSymbol
      ) {
      } else {
        if (symbolarr.includes(newSymbol.symbols[0]) === false) {
          symbolarr.push(newSymbol.symbols[0]);
        }
      }

      if (
        data[i].entry.strategy === undefined ||
        data[i].entry.strategy === "" ||
        data[i].entry.strategy === null
      ) {
      } else {
        const strategies = data[i].entry.strategy;
        for (let id = 0, jd = strategies.length; id < jd; id++) {
          if (strategyarr.includes(strategies[id]) === false) {
            strategyarr.push(strategies[id]);
          }
        }
      }
      totalPL_dollar += correctedSUM;
      totalSlippage += slippage;
      totalTrades += 1;
      totalCommissions += x100commissions / 100;
      miscellaneousStdDevPerTradeDollartemparray.push(correctedSUM);
      accountbalancearr.push(thisAccountBalance);
      if (correctedSUM < 0) {
        totalLosingTrades += 1;
        miscellaneousDownsideDevPerTradeDollartemparray.push(correctedSUM);
      } else {
      }
    }

    // ----------- Everything from this point on only takes in trades -----------
    // ----------- If you want to include deposits/withdrawals, go to totalFees -----------
    //Total lots traded
    let innerLotSize = 0;
    for (let im = 0, jm = data[i].entry.multiExecution.length; im < jm; im++) {
      innerLotSize += Number(data[i].entry.multiExecution[im].lotSize);
      bufferlotSize = Number(data[i].entry.multiExecution[im].lotSize);
      if (bufferlotSize > miscellaneousMaxLotSize) {
        miscellaneousMaxLotSize = bufferlotSize;
      } else {
      }
    }
    totalExectutions += Number(data[i].entry.multiExecution.length);
    totalLotsTraded += Number(innerLotSize);

    // Maximum Lot Size
  }

  // ------------------------ Current Streak ------------------------
  let prevcheck = "";
  let streakType = "";
  let streakTypeArray = [];
  let k = 0;
  for (let i = data.length - 1, j = 0; i >= j; i--) {
    let tempvalue = 0;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    if (calctype === "Gross") {
      tempvalue = Number(data[i].entry.profitLoss * 100);
    } else if (calctype === "Net") {
      tempvalue =
        Number(data[i].entry.profitLoss * 100) +
        Number(data[i].entry.commissions * 100) +
        Number(data[i].entry.fees * 100);
    }
    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      if (tempvalue > 0) {
        streakType = "win";
      } else if (tempvalue < 0) {
        streakType = "loss";
      } else if (tempvalue === 0) {
        streakType = "break-even";
      }
      if (streakTypeArray[0] === undefined) {
        streakTypeArray[0] = streakType;
        prevcheck = streakType;
      } else {
        streakTypeArray[k + 1] = streakType;
        if (streakTypeArray[k + 1] !== prevcheck) {
          streakTypeArray.pop();
          break;
        } else {
          prevcheck = streakType;
          k += 1;
        }
      }
    }
  }
  let miscellaneousCurrentStreak = "";
  if (streakTypeArray.length === 0) {
    miscellaneousCurrentStreak = "0 trades";
  } else if (streakTypeArray.length === 1) {
    miscellaneousCurrentStreak = "1 " + String(streakTypeArray[0]);
  } else {
    if (streakTypeArray[0] === "Win" || streakTypeArray[0] === "Break-Even") {
      miscellaneousCurrentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "s";
    } else {
      miscellaneousCurrentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "es";
    }
  }

  // ------------------------------------------------
  // Number of symbols and strategies traded
  totalSymbols = symbolarr.length;
  totalStrategies = strategyarr.length;

  //Total P/L in %
  totalPL_percent =
    totalDeposits + totalWithdrawals + start_Balance === 0
      ? 0
      : (totalPL_dollar / (totalDeposits + totalWithdrawals + start_Balance)) *
        100;

  // Average P/L per Trade in chosen currency
  let averagePLPerTradedollar =
    totalTrades === 0 ? 0 : totalPL_dollar / totalTrades;

  // Average P/L per Trade in %
  let averagePLPerTradePercent =
    totalTrades === 0 ? 0 : totalPL_percent / totalTrades;

  // Average P/L per Trade in Pips
  let averagePipPLPerTrade = 0;
  averagePipPLPerTrade =
    totalTrades === 0 ? 0 : totalPipsGainedLost / totalTrades;

  // Average Lot Size Per Trade
  let averageLotSizePerTrade = 0;
  averageLotSizePerTrade =
    totalTrades === 0 ? 0 : totalLotsTraded / totalTrades;

  // Average P/L Per Pip
  let averagePLPerPip = 0;

  // Average Exectuions Per Trade
  let averageExecutionsPerTrade = 0;
  averageExecutionsPerTrade =
    totalTrades === 0 ? 0 : totalExectutions / totalTrades;

  // Average Slippage Per Trade
  let averageSlippagePerTrade = 0;
  averageSlippagePerTrade = totalTrades === 0 ? 0 : totalSlippage / totalTrades;

  // Standard Deviation Per Trade $
  let miscellaneousStdDevPerTradeDollar = 0;
  miscellaneousStdDevPerTradeDollar =
    totalTrades === 0
      ? 0
      : getStandardDeviation(miscellaneousStdDevPerTradeDollartemparray);

  // Standard Deviation Per Trade %
  let miscellaneousStdDevPerTradePercent = 0;

  // Coefficient of Variation Per Trade $
  let miscellaneousCoefofVarPerTradeDollar =
    averagePLPerTradedollar === 0
      ? 0
      : 100 * (miscellaneousStdDevPerTradeDollar / averagePLPerTradedollar);

  // Coefficient of Variation Per Trade %
  let miscellaneousCoefofVarPerTradePercent =
    averagePLPerTradedollar === 0
      ? 0
      : 100 * (miscellaneousStdDevPerTradePercent / averagePLPerTradePercent);

  // Downside Deviation Per Trade $
  let miscellaneousDownsideDevPerTradeDollar = 0;
  miscellaneousDownsideDevPerTradeDollar =
    totalLosingTrades === 0
      ? 0
      : getStandardDeviation(miscellaneousDownsideDevPerTradeDollartemparray);

  // Account Size Required
  let miscellaneousAccountSizeRequired = Math.abs(getMaxDD(accountbalancearr));
  let miscellaneousVariancePerTradeDollar = Math.pow(
    miscellaneousStdDevPerTradeDollar,
    2
  );
  result = {
    totalPL_dollar,
    totalPL_percent,
    totalPipsGainedLost,
    totalTrades,
    totalLotsTraded: handleNANGOOD(totalLotsTraded, "-"),
    totalCommissions,
    totalFees,
    totalSlippage,
    totalDeposits,
    totalWithdrawals,
    totalStrategies,
    totalSymbols,
    averagePLPerTradedollar,
    averagePLPerTradePercent,
    averagePipPLPerTrade,
    averageLotSizePerTrade: handleNANGOOD(averageLotSizePerTrade, "-"),
    averagePLPerPip,
    averageExecutionsPerTrade: handleNANGOOD(averageExecutionsPerTrade, "-"),
    averageSlippagePerTrade: handleNANGOOD(averageSlippagePerTrade, "-"),
    miscellaneousMaxLotSize: handleNANGOOD(miscellaneousMaxLotSize, "-"),
    miscellaneousHighestEquity: handleNANGOOD(miscellaneousHighestEquity, "-"),
    miscellaneousStdDevPerTradeDollar: handleNANGOOD(miscellaneousStdDevPerTradeDollar, "-"),
    miscellaneousStdDevPerTradePercent: handleNANGOOD(miscellaneousStdDevPerTradePercent, "-"),
    miscellaneousCoefofVarPerTradeDollar: handleNANGOOD(miscellaneousCoefofVarPerTradeDollar, "-"),
    miscellaneousCoefofVarPerTradePercent: handleNANGOOD(miscellaneousCoefofVarPerTradePercent, "-"),
    miscellaneousDownsideDevPerTradeDollar: handleNANGOOD(miscellaneousDownsideDevPerTradeDollar, "-"),
    miscellaneousVariancePerTradeDollar: handleNANGOOD(miscellaneousVariancePerTradeDollar, "-"),
    miscellaneousAccountSizeRequired: handleNANGOOD(miscellaneousAccountSizeRequired, "-"),
    miscellaneousCurrentStreak: miscellaneousCurrentStreak,
    start_Balance,
    start_DW,
  };
  return result;
};

export default OverallPerformanceCalculations;
