import React, { useMemo, useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import dollarCostAverage from "../../../functions/dollarCostAvg";
import { returnNetPLDollar } from "../../../functions/profitLossCalculation";
import { numberWithCommas } from "../../Logbook/functions";
import getDateDifference from "../../../functions/getDateDifference";
import bookarrow from "../../../images/bookarrow.svg";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import supportedCryptos from "../../../arrays/isCurrencyCrypto";
import getOptionsStrategy from "../../../functions/getOptionsStrategy";
import longShortDelineation from "../../../functions/longShortDelineation";
import ProTierComponent from "../../../components/tierComponents/pro";
import OpenPositionDonutGraph from "../DonutChart";
import PopoverStickOnHover from "../../../components/PopoverStickOnHover";
import { v4 as uuidv4 } from "uuid";

import $ from "jquery";

let he = require("he");

//import { Bar } from "react-chartjs-2";
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
export const OpenTradeComponent = (props) => {
  const userData = props.userData;
  const tier = props.tier;
  const active = props.active;
  const allData = userData && userData.allData;

  const portfolio = userData && userData.portfolio;
  const createdPortfolios = allData?.createdPortfolios;

  const entries = portfolio && portfolio.entries;
  const filterOpenTrades = (trade) => {
    const entryExecs = trade.entry.multiExecution;
    let entryLots = 0;
    entryExecs.forEach((exec) => {
      entryLots += Number(exec.lotSize);
    });
    let exitLots = 0;
    const exitExecs = trade.entry.exitExecution;
    exitExecs.forEach((exec) => {
      exitLots += Number(exec.exitLotSize);
    });
    entryLots = fixRound(entryLots); // fix rounding issue
    exitLots = fixRound(exitLots);
    if (exitLots !== entryLots) {
      return true;
    } else {
      return false;
    }
  };

  let openTrades =
    entries && entries.slice().filter(filterOpenTrades).reverse();
  let recentTradesInit = "";
  let tierDiv = "";
  let ismaster = false;
  if (tier === "free") {
    recentTradesInit = true;
    tierDiv = (
      <div style={{ position: "absolute", top: 12, left: 200 }}>
        <ProTierComponent />
      </div>
    );
  } else if (tier === "pro" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "oldpro" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "master" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
    ismaster = true;
  } else if (tier === "ultimate" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
    ismaster = true;
  } else if (tier === "admin" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
    ismaster = true;
  } else {
    recentTradesInit = true;
    tierDiv = (
      <div style={{ position: "absolute", top: 12, left: 200 }}>
        <ProTierComponent />
      </div>
    );
  }
  let recentTrades = entries.slice(-15).reverse();

  const settings = portfolio && portfolio.settings;
  const thedefaultSymbol = settings.currencySymbol;
  const supportedCryptosCheck = supportedCryptos.includes(thedefaultSymbol);

  const chosenPortType = portfolio.assetClasses;
  const chosenPortTypeLength = chosenPortType.length;
  const datenow = new Date();

  const [dateNow, setdateNow] = useState(datenow);
  const [recentTradesShow, setrecentTradesShow] = useState(recentTradesInit);
  const datav = recentTradesShow ? recentTrades : openTrades;

  const firstUpdate = useRef(true);
  const history = useHistory();

  useEffect(() => {
    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    let interval = setInterval(async () => {
      const datenowinner = new Date();
      setdateNow(datenowinner);
    }, 1000);

    if (firstUpdate.current) {
      props.setOpenPositionsState(recentTradesInit);
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [firstUpdate, props, recentTradesInit]);
  const toComponentB = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row.original,
    });
  };
  const handleRowClick = (row, b) => {
    toComponentB(row);
  };

  const columns = useMemo(
    () => [
      {
        Header: "entryId",
        accessor: "entryId",
        Cell: (props) => {
          if (props.value === "") {
            return <div id="entryidvalue">{""}</div>;
          } else {
            return <div id="entryidvalue">{props.value}</div>;
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          if (recentTradesShow) {
            return !sorted.length || id !== "entry.endDateTime" ? (
              "Date & Time Closed"
            ) : (
              <span style={{ color: "#fbd665" }}>{"Date & Time Closed"}</span>
            );
          } else {
            return !sorted.length || id !== "entry.startDateTime" ? (
              "Date & Time Opened"
            ) : (
              <span style={{ color: "#fbd665" }}>{"Date & Time Opened"}</span>
            );
          }
        },
        id: recentTradesShow ? "entry.endDateTime" : "entry.startDateTime",
        accessor: (row) => {
          if (recentTradesShow) {
            const entryExecs = row.entry.multiExecution;
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;
            if (
              row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "deposit" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "withdrawal" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "fundingpayment" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "approval" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "commit" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") === "nft" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") === "wrap" ||
              row.entry.orderType.toLowerCase().replace(/ /gi, "") === "self"
            ) {
              return new Date(-8639999999999999);
            } else {
              if (exitLots !== entryLots) {
                return new Date(-8640000000000000);
              } else {
                return new Date(row.entry.endDateTime);
              }
            }
          } else {
            return new Date(row.entry.startDateTime);
          }
        },
        sortType: "basic",
        Cell: (props) => {
          const row = props.row;
          if (recentTradesShow) {
            const entryExecs = row.original.entry.multiExecution;
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.original.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;
            if (
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "deposit" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "withdrawal" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "fundingpayment" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "approval" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "commit" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "nft" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "wrap" ||
              row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
                "self"
            ) {
              return moment(row.original.entry.startDateTime).format(
                "MM/DD/YYYY HH:mm:ss"
              );
            } else {
              if (exitLots !== entryLots) {
                return (
                  <span className="open-trade2">
                    {/* this.setstate(open = true) ---- maybe make a fucntion that reutrns the class and also activates functions that change open boolean state */}
                    Open
                    {/* To use props open = true -> Then we create a JS variable element and assign value 
          <Welcome name="Mary" /> to it. Here React automatically updates the 
          props object giving it a new property called name with the value Mary. */}
                  </span>
                );
              } else {
                return moment(row.original.entry.endDateTime).format(
                  "MM/DD/YYYY HH:mm:ss"
                );
              }
            }
          } else {
            if (props.value === "") {
              return "";
            } else {
              return moment(props.value).format("MM/DD/YYYY HH:mm:ss");
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "holdingtime" ? (
            "Holding Time"
          ) : (
            <span style={{ color: "#fbd665" }}>
              {"Holding Time"}
            </span>
          );
        },
        id: "holdingtime",
        accessor: (row) => {
          const startDate = new Date(row.entry.startDateTime);
          const endDate = new Date(row.entry.endDateTime);
          const entryExecs = row.entry.multiExecution;
          let entryLots = 0;
          entryExecs.forEach((exec) => {
            entryLots += Number(exec.lotSize);
          });
          let exitLots = 0;
          const exitExecs = row.entry.exitExecution;
          exitExecs.forEach((exec) => {
            exitLots += Number(exec.exitLotSize);
          });
          entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
          exitLots = Math.round(exitLots * 1e10) / 1e10;
          if (exitLots !== entryLots) {
            return (dateNow.getTime() - startDate.getTime()) / 1000;
          } else {
            return (endDate.getTime() - startDate.getTime()) / 1000;
          }
        },
        sortType: "basic",
        Cell: ({ row }) => {
          if (
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "deposit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "withdrawal" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "fundingpayment" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "approval" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "commit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "nft" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "wrap" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "self"
          ) {
            return "";
          } else {
            const datenow = new Date();
            const entryExecs = row.original.entry.multiExecution;
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.original.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;

            if (exitLots !== entryLots) {
              return getDateDifference(
                datenow,
                row.original.entry.startDateTime
              );
            } else {
              return getDateDifference(
                row.original.entry.endDateTime,
                row.original.entry.startDateTime
              );
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          let returnText = "";
          if (tier === "free") {
            returnText = `Gross P/L`;
          } else if (tier === "pro" && active) {
            returnText = `Net P/L`;
          } else if (tier === "oldpro" && active) {
            returnText = `Net P/L`;
          } else if (tier === "master" && active) {
            returnText = `Net P/L`;
          } else if (tier === "ultimate" && active) {
            returnText = `Net P/L`;
          } else if (tier === "admin" && active) {
            returnText = `Net P/L`;
          } else {
            returnText = `Gross P/L`;
          }
          let returnHeader =
            !sorted.length || id !== "netPLdollar" ? (
              recentTradesShow ? (
                returnText
              ) : (
                `Realized P/L`
              )
            ) : (
              <span style={{ color: "#fbd665" }}>
                {recentTradesShow ? returnText : `Realized P/L`}
              </span>
            );
          return returnHeader;
        },
        id: "netPLdollar",
        accessor: (row) =>
          returnNetPLDollar(
            row.entry.profitLoss,
            row.entry.commissions,
            row.entry.fees,
            settings.calculationType
          ),
        sortType: "basic",
        Cell: ({ row }) => {
          if (
            row.original.entry.profitLoss === "" &&
            row.original.entry.commissions === "" &&
            row.original.entry.fees === ""
          ) {
            return "";
          } else {
            let netPLwCommas = numberWithCommas(
              parseFloat(
                returnNetPLDollar(
                  row.original.entry.profitLoss,
                  row.original.entry.commissions,
                  row.original.entry.fees,
                  settings.calculationType
                )
              ).toFixed(2)
            );
            let netPLCrypto = parseFloat(
              returnNetPLDollar(
                row.original.entry.profitLoss,
                row.original.entry.commissions,
                row.original.entry.fees,
                settings.calculationType
              ).toFixed(6)
            );
            let grossPLCrypto = parseFloat(
              row.original.entry.profitLoss
            ).toFixed(6);
            let grossPL = parseFloat(row.original.entry.profitLoss);
            let grossPLwCommas = numberWithCommas(
              parseFloat(row.original.entry.profitLoss).toFixed(2)
            ); // parsefloat is encompassing here b/c we want the trailing zeros to be chopped off
            const netPL = parseFloat(
              returnNetPLDollar(
                row.original.entry.profitLoss,
                row.original.entry.commissions,
                row.original.entry.fees,
                settings.calculationType
              ) // here we do not want trailing 0s chopped
            );
            let comparisonCheck = "";
            let netPLFinal = "";
            if (netPL === "") {
              return "";
            } else {
              if (tier === "free") {
                netPLFinal = supportedCryptosCheck
                  ? grossPLCrypto + " " + String(thedefaultSymbol)
                  : he.decode(String(thedefaultSymbol)) + grossPLwCommas;
                comparisonCheck = grossPL;
              } else if (
                (tier === "pro" && active) ||
                (tier === "oldpro" && active) ||
                (tier === "master" && active) ||
                (tier === "ultimate" && active) ||
                (tier === "admin" && active)
              ) {
                comparisonCheck = netPL;
                netPLFinal = supportedCryptosCheck
                  ? netPLCrypto + " " + String(thedefaultSymbol)
                  : he.decode(String(thedefaultSymbol)) + netPLwCommas;
              } else {
                comparisonCheck = grossPL;
                netPLFinal = supportedCryptosCheck
                  ? grossPLCrypto + " " + String(thedefaultSymbol)
                  : he.decode(String(thedefaultSymbol)) + grossPLwCommas;
              }
              if (Number(comparisonCheck) > 0) {
                return <span className="orderOutcome-Win">{netPLFinal}</span>;
              } else if (Number(comparisonCheck) < 0) {
                return <span className="orderOutcome-Loss">{netPLFinal}</span>;
              } else if (Number(comparisonCheck) === 0) {
                return <span className="orderOutcome-BE">{netPLFinal}</span>;
              } else {
                return "";
              }
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.orderType" ? (
            "Order Type"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Order Type"}</span>
          );
        },
        id: "entry.orderType",
        accessor: "entry.orderType",
        sortType: "basic",
        Cell: (props) => {
          const row = props.row;
          props.value.toLowerCase(); // make the value lower case first
          const display =
            props.value &&
            props.value[0].toUpperCase() + props.value.substring(1); // then capitalize the first letter
          if (
            props.value.toLowerCase().replace(/ /gi, "") === "long" ||
            props.value.toLowerCase().replace(/ /gi, "") === "longcall" ||
            props.value.toLowerCase().replace(/ /gi, "") === "longput" ||
            props.value.toLowerCase().replace(/ /gi, "") === "buylimit" ||
            props.value.toLowerCase().replace(/ /gi, "") === "buystop"
          ) {
            return (
              <span className="orderType-cell-component orderType-Long">
                {display}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "multi-legstrategy"
          ) {
            return longShortDelineation(
              row.original.entry.orderType,
              row.original.entry.multiExecution
            ) === "Long" ? (
              <div className="orderType-cell-component-multi orderType-Long">
                {display}
                <span className="orderType-cell-component-text">
                  {getOptionsStrategy(
                    row.original.entry.multiExecution,
                    "",
                    row.original.entry.orderType
                  )}
                </span>
              </div>
            ) : (
              <div className="orderType-cell-component-multi orderType-Short">
                {display}
                <span className="orderType-cell-component-text">
                  {getOptionsStrategy(
                    row.original.entry.multiExecution,
                    "",
                    row.original.entry.orderType
                  )}
                </span>
              </div>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "short" ||
            props.value.toLowerCase().replace(/ /gi, "") === "shortcall" ||
            props.value.toLowerCase().replace(/ /gi, "") === "shortput" ||
            props.value.toLowerCase().replace(/ /gi, "") === "selllimit" ||
            props.value.toLowerCase().replace(/ /gi, "") === "sellstop"
          ) {
            return (
              <span className="orderType-cell-component orderType-Short">
                {display}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "deposit"
          ) {
            return (
              <span className="orderType-cell-component orderType-Deposit">
                {display}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "withdrawal"
          ) {
            return (
              <span className="orderType-cell-component orderType-Withdrawal">
                {display}
              </span>
            );
          } else if (props.value.toLowerCase().replace(/ /gi, "") === "open") {
            return (
              <span className="orderType-cell-component orderType-Open">
                {display}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "fundingpayment"
          ) {
            return (
              <span className="orderType-cell-component orderType-Open">
                {"Funding Payment"}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "approval"
          ) {
            return (
              <span className="orderType-cell-component orderType-Approval">
                {row.original.entry.orderType}
              </span>
            );
          } else if (
            props.value.toLowerCase().replace(/ /gi, "") === "commit"
          ) {
            return (
              <span className="orderType-cell-component orderType-Commit">
                {row.original.entry.orderType}
              </span>
            );
          } else if (props.value.toLowerCase().replace(/ /gi, "") === "nft") {
            return (
              <span className="orderType-cell-component orderType-NFT">
                {row.original.entry.orderType}
              </span>
            );
          } else if (props.value.toLowerCase().replace(/ /gi, "") === "wrap") {
            return (
              <span className="orderType-cell-component orderType-Self">
                {row.original.entry.orderType}
              </span>
            );
          } else if (props.value.toLowerCase().replace(/ /gi, "") === "self") {
            return (
              <span className="orderType-cell-component orderType-Self">
                {row.original.entry.orderType}
              </span>
            );
          } else {
            return "";
          }
        },
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Symbol"
              : chosenPortType[0] === "Stocks" ||
                chosenPortType[0] === "Options"
              ? "Ticker Symbol"
              : chosenPortType[0] === "Crypto"
              ? "Pair"
              : "Symbol";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.symbol.symbols" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        accessor: "entry.symbol.symbols",
        id: "entry.symbol.symbols",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "") {
            return "";
          } else {
            return <span className="symbolcolstyles">{props.value}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          if (recentTradesShow) {
            const headerTo =
              chosenPortTypeLength !== 1
                ? "Units"
                : chosenPortType[0] === "Stocks"
                ? "Shares"
                : chosenPortType[0] === "Options" ||
                  chosenPortType[0] === "Futures"
                ? "Contracts"
                : chosenPortType[0] === "Forex"
                ? "Lots"
                : chosenPortType[0] === "Crypto"
                ? "Coins/Tokens"
                : chosenPortType[0] === "CFD"
                ? "Quantity"
                : "Units";
            return !sorted.length || id !== "entry.lotSize" ? (
              headerTo
            ) : (
              <span style={{ color: "#fbd665" }}>{headerTo}</span>
            );
          } else {
            const headerTo =
              chosenPortTypeLength !== 1
                ? "Open Units"
                : chosenPortType[0] === "Stocks"
                ? "Open Shares"
                : chosenPortType[0] === "Options" ||
                  chosenPortType[0] === "Futures"
                ? "Open Contracts"
                : chosenPortType[0] === "Forex"
                ? "Open Lots"
                : chosenPortType[0] === "Crypto"
                ? "Open Coins/Tokens"
                : chosenPortType[0] === "CFD"
                ? "Open Quantity"
                : "Open Units";
            return !sorted.length || id !== "openunits" ? (
              headerTo
            ) : (
              <span style={{ color: "#fbd665" }}>{headerTo}</span>
            );
          }
        },
        id: recentTradesShow ? "entry.lotSize" : "openunits",
        accessor: (row) => {
          const entryExecs = row.entry.multiExecution;

          if (recentTradesShow) {
            let lots = 0;
            entryExecs.forEach((execution) => {
              lots += Number(execution.lotSize);
            });
            return lots;
          } else {
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;
            const openlots = entryLots - exitLots;
            return openlots;
          }
        },
        sortType: "number",
        Cell: ({ row }) => {
          const entryExecs = row.original.entry.multiExecution;

          if (recentTradesShow) {
            let lots = 0;
            entryExecs.forEach((execution) => {
              lots += Number(execution.lotSize);
            });
            if (
              lots === "" ||
              row.original.entry.orderType === "Deposit" ||
              row.original.entry.orderType === "Withdrawal" ||
              row.original.entry.orderType === "Funding Payment" ||
              row.original.entry.orderType === "Commit" ||
              row.original.entry.orderType === "Approval" ||
              row.original.entry.orderType === "Wrap" ||
              row.original.entry.orderType === "Self"
            ) {
              return "";
            } else {
              return (
                <span>
                  {lots.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 5,
                  })}
                </span>
              );
            }
          } else {
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.original.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;
            const openlots = entryLots - exitLots;

            if (
              openlots === "" ||
              row.original.entry.orderType === "Deposit" ||
              row.original.entry.orderType === "Withdrawal" ||
              row.original.entry.orderType === "Funding Payment" ||
              row.original.entry.orderType === "Commit" ||
              row.original.entry.orderType === "Approval" ||
              row.original.entry.orderType === "Wrap" ||
              row.original.entry.orderType === "Self"
            ) {
              return "";
            } else {
              return (
                <span>
                  {openlots.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 5,
                  })}
                </span>
              );
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          if (recentTradesShow) {
            const headerTo =
              chosenPortTypeLength !== 1
                ? "Avg. Exit Price"
                : chosenPortType[0] === "Options" ||
                  chosenPortType[0] === "Futures"
                ? "Avg. Exit Spot Price"
                : "Avg. Exit Price";
            return !sorted.length || id !== "entry.exitPrice" ? (
              headerTo
            ) : (
              <span style={{ color: "#fbd665" }}>{headerTo}</span>
            );
          } else {
            const headerTo =
              chosenPortTypeLength !== 1
                ? "Avg. Entry Price"
                : chosenPortType[0] === "Options" ||
                  chosenPortType[0] === "Futures"
                ? "Avg. Entry Spot Price"
                : "Avg. Entry Price";
            return !sorted.length || id !== "entry.entryPrice" ? (
              headerTo
            ) : (
              <span style={{ color: "#fbd665" }}>{headerTo}</span>
            );
          }
        },
        id: recentTradesShow ? "entry.exitPrice" : "entry.entryPrice",
        sortType: "number",
        accessor: (row) => {
          const orderType = row.entry.orderType;
          if (recentTradesShow) {
            const exitExecutioninner = row.entry.exitExecution;
            let avgExit = dollarCostAverage(
              exitExecutioninner,
              "exit",
              orderType
            );
            return avgExit;
          } else {
            const multiExecutioninner = row.entry.multiExecution;
            let avgEntry = dollarCostAverage(
              multiExecutioninner,
              "entry",
              orderType
            );
            return avgEntry;
          }
        },
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          if (recentTradesShow) {
            const exitExecution = row.original.entry.exitExecution;
            let avg = dollarCostAverage(exitExecution, "exit", orderType);
            if (
              avg === "" ||
              row.original.entry.orderType === "Deposit" ||
              row.original.entry.orderType === "Withdrawal" ||
              row.original.entry.orderType === "Funding Payment" ||
              row.original.entry.orderType === "Commit" ||
              row.original.entry.orderType === "Approval" ||
              row.original.entry.orderType === "Wrap" ||
              row.original.entry.orderType === "Self" ||
              isNaN(avg)
            ) {
              return "";
            } else {
              return <span>{parseFloat(avg)}</span>;
            }
          } else {
            const multiExecutioninner = row.original.entry.multiExecution;
            let avg = dollarCostAverage(
              multiExecutioninner,
              "entry",
              orderType
            );
            if (
              avg === "" ||
              row.original.entry.orderType === "Deposit" ||
              row.original.entry.orderType === "Withdrawal" ||
              row.original.entry.orderType === "Funding Payment" ||
              row.original.entry.orderType === "Commit" ||
              row.original.entry.orderType === "Approval" ||
              row.original.entry.orderType === "Wrap" ||
              row.original.entry.orderType === "Self" ||
              isNaN(avg)
            ) {
              return "";
            } else {
              return <span>{parseFloat(avg)}</span>;
            }
          }
        },
      },
    ],
    [
      supportedCryptosCheck,
      recentTradesShow,
      settings.calculationType,
      chosenPortType,
      chosenPortTypeLength,
      thedefaultSymbol,
      dateNow,
      active,
      tier,
    ]
  );
  const donutGraph = useMemo(() => {
    return (
      !recentTradesShow && (
        <OpenPositionDonutGraph
          userData={userData}
          defaultSymbol={String(userData.portfolio.settings.currencySymbol)}
          dashoOpenMode={recentTradesShow}
        />
      )
    );
  }, [recentTradesShow, userData]);
  const newarr = [""];
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns: columns,
        data: datav,
        initialState: { hiddenColumns: newarr, pageIndex: 0, pageSize: 100 },
        autoResetSortBy: false,
        autoResetPage: false,
      },
      useSortBy,
      usePagination
    );

  const featureSelect = {
    show: true,
    featureSelect: "Track open positions",
    blur: false,
  };
  const toggleButtonFunction = () => {
    if (tier === "free") {
      props.setshowUpgradeModal(featureSelect);
    } else if (tier === "pro" && active) {
      if (!recentTradesShow) {
        setrecentTradesShow(true);
        props.setOpenPositionsState(true);
      } else {
        setrecentTradesShow(false);
        props.setOpenPositionsState(false);
      }
    } else if (tier === "oldpro" && active) {
      if (!recentTradesShow) {
        setrecentTradesShow(true);
        props.setOpenPositionsState(true);
      } else {
        setrecentTradesShow(false);
        props.setOpenPositionsState(false);
      }
    } else if (tier === "master" && active) {
      if (!recentTradesShow) {
        setrecentTradesShow(true);
        props.setOpenPositionsState(true);
      } else {
        setrecentTradesShow(false);
        props.setOpenPositionsState(false);
      }
    } else if (tier === "ultimate" && active) {
      if (!recentTradesShow) {
        setrecentTradesShow(true);
        props.setOpenPositionsState(true);
      } else {
        setrecentTradesShow(false);
        props.setOpenPositionsState(false);
      }
    } else if (tier === "admin" && active) {
      if (!recentTradesShow) {
        setrecentTradesShow(true);
        props.setOpenPositionsState(true);
      } else {
        setrecentTradesShow(false);
        props.setOpenPositionsState(false);
      }
    } else {
      props.setshowUpgradeModal(featureSelect);
    }
  };
  const dashboardwidth = props.dashboardwidth;
  const innerwidth = props.innerwidth;
  return (
    <div
      className="noselect openpositionssuperwrapper"
      style={{
        width: "100%",
      }}
    >
      <div
        className="openpositionsheader"
        style={{
          width: !recentTradesShow
            ? dashboardwidth <= 529
              ? "calc(100% - 62px)"
              : "calc(70% + 26px)"
            : "100%",
        }}
      >
        <div className="openpositionssubheaderfirst">
          {recentTradesShow ? `Recent Entries` : `Open Positions`}
        </div>
        <div className="openpositionssubheader">
          {recentTradesShow
            ? `Last 15 Entries`
            : openTrades.length === 1
            ? `1 Open Position`
            : `${openTrades.length} Open Positions`}
        </div>
      </div>
      <button
        className="taketologbookbutton"
        style={{
          right: !recentTradesShow
            ? innerwidth <= 900
              ? "272px"
              : "calc(25% + 44px)"
            : "52px",
        }}
        onClick={() => {
          history.push({
            pathname: "/Logbook",
            state: {
              sortBy: [
                {
                  id: "entry.endDateTime",
                  desc: recentTradesShow ? true : false,
                },
              ],
            },
          });
        }}
      >
        <PopoverStickOnHover
          placement="top"
          delay={300}
          xAdjust={23}
          yAdjust={0}
          keepOpen={false}
          component={
            <div id="perfmetertexttooltipinner2">{`View in Logbook`}</div>
          }
        >
          <div className="taketologbookbuttoninner">
            <img src={bookarrow} alt="0" />
          </div>
        </PopoverStickOnHover>
      </button>
      <div
        className="togglerecenttradesbutton"
        onClick={() => toggleButtonFunction()}
      >
        {dashboardwidth <= 420
          ? !recentTradesShow
            ? `Recent`
            : `Open`
          : dashboardwidth <= 529
          ? !recentTradesShow
            ? `Show Recent`
            : `Show Open`
          : !recentTradesShow
          ? `Show Recent Entries`
          : `Show Open Positions`}
      </div>
      {tierDiv}
      <div {...getTableProps()} className="openpositionswrapper">
        <table className="openpositionswrapperinner">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="sortingcaret2"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className="sortingcaret2"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ width: "100%" }}>
            {page.map((row) => {
              prepareRow(row);
              const portfolioName = row.original.entry.selectedPortfolio;
              let createdPortfolioMatching = createdPortfolios.slice();
              createdPortfolioMatching = createdPortfolioMatching.filter(
                (port) => {
                  return port.name === portfolioName;
                }
              );
              const portfolioColorPre =
                createdPortfolioMatching[0]?.settings?.portfolioColor;
              const colorRGB = portfolioColorPre && hexToRgb(portfolioColorPre);
              const portfolioColor =
                colorRGB &&
                `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.1)`;

              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    height: 52,
                    backgroundColor:
                      (ismaster ? portfolioColor : "") || "transparent",
                  }}
                  key={uuidv4()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={(b) => {
                          handleRowClick(row, b);
                          b.stopPropagation();
                        }}
                        style={{ height: 52 }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {donutGraph}
    </div>
  );
};

export default OpenTradeComponent;
