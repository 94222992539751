export const getFilteredList = (id, value) => {
  return {
    type: "GET_FILTERED_LIST",
    id,
    value,
  };
};

export const resetFilteredList = (npmstrat) => {
  return {
    type: "RESET_FILTERED_LIST",
    npmstrat,
  };
};

export const getPortfolioList = (id, value) => {
  return {
    type: "GET_PORTFOLIO_LIST",
    id,
    value,
  };
};

export const resetPortfolioList = (npmstrat) => {
  return {
    type: "RESET_PORTFOLIO_LIST",
    npmstrat,
  };
};

export const setLoaderState = (npmstrat) => {
  return {
    type: "SETLOADERSTATE",
    npmstrat,
  };
};

export const getRightPanelStored = (id, value) => {
  return {
    type: "GET_RIGHT_PANEL_STORED",
    id,
    value,
  };
};

export const setSettingsList = (id, value) => {
  return {
    type: "SET_SETTINGS_LIST",
    id,
    value,
  };
};

export const getUserDataANDSettings = (id, value) => {
  return {
    type: "GET_DATA_AND_SETTINGS",
    id,
    value,
  };
};
export const setFiltersOpen = (npmstrat) => {
  return {
    type: "SETFILTERSOPEN",
    npmstrat,
  };
};
export const setFiltersToggleLocation = (npmstrat) => {
  return {
    type: "SETFILTERSTOGGLELOCATION",
    npmstrat,
  };
};
export default getFilteredList;
