export const getEndDateTime = (exitexecutionsArrayn) => {
  let endDateTimezy = "";
  for (let im = 0, jm = exitexecutionsArrayn.length; im < jm; im++) {
    if (im === 0) {
      endDateTimezy = exitexecutionsArrayn[im].endDateTime;
    } else {
      endDateTimezy =
        exitexecutionsArrayn[im].endDateTime > endDateTimezy
          ? exitexecutionsArrayn[im].endDateTime
          : endDateTimezy;
    }
  }
  return endDateTimezy;
};

export const getStartDateTime = (executionsArrayn) => {
  let startDateTimezy = "";
  for (let im = 0, jm = executionsArrayn.length; im < jm; im++) {
    if (im === 0) {
      startDateTimezy = executionsArrayn[im].startDateTime;
    } else {
      startDateTimezy =
        executionsArrayn[im].startDateTime < startDateTimezy
          ? executionsArrayn[im].startDateTime
          : startDateTimezy;
    }
  }
  return startDateTimezy;
};

export default getEndDateTime;
