import React, { Component } from "react";
import { connect } from "react-redux";

import "./loader.scss";

import loaderImg from "../../images/NewFuse.png";
import loaderImg1b from "../../images/NewFuse.png";
import loaderImg2b from "../../images/NewFuseStrike.png";

const mapStateToProps = (state) => {
  return { loading: state.loaderState };
};

class FullPageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    const element = document.getElementById("loadertext");
    setTimeout(function () {
      const autoimessage = localStorage.getItem("autoiterationMessage");
      if (element) {
        if (element.innerHTML === "") {
          if (autoimessage) {
            element.innerHTML =
              "Welcome back!✨ Hang tight while we reconnect your account (may take a couple of minutes)...";
          } else {
            element.innerHTML = "Hang tight, we are getting things ready...";
          }
        }
      }
    }, 7000);
  }
  render() {
    return (
      <div>
        {this.props.loading.loading === false ? (
          <div></div>
        ) : (
          <div className="loader-container">
            <div id="overlayMAIN"></div>
            <div id="noclickMAIN"></div>
            <div className="loader3b">
              <img src={loaderImg} alt="0" />
            </div>
            <div className="loader2b">
              <img src={loaderImg2b} alt="0" />
            </div>
            <div className="loader1b">
              <img src={loaderImg1b} alt="0" />
            </div>
            <div id="loadertext"></div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(FullPageLoader);
