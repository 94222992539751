export const dollarCostAverage = (executions, side, type) => {
  const countDecimals = (num) => {
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1]?.length || 0;
  };
  let tempsum = 0;
  let totalLots = 0;
  // For Options
  let totalBuyLots = 0;
  let totalSellLots = 0;
  let tempbuysum = 0;
  let tempsellsum = 0;
  let decimals = 0;
  if (side === "entry") {
    for (let i in executions) {
      if (type === "Multi-Leg Strategy") {
        const legtype = executions[i].legType;
        if (legtype === "Long Call" || legtype === "Long Put") {
          tempbuysum =
            tempbuysum +
            Number(executions[i].lotSize) * Number(executions[i].entryPrice);
          totalBuyLots = totalBuyLots + Number(executions[i].lotSize);
          decimals = countDecimals(Number(executions[i].entryPrice));
        } else if (legtype === "Short Call" || legtype === "Short Put") {
          tempsellsum =
            tempsellsum +
            Number(executions[i].lotSize) * Number(executions[i].entryPrice);
          totalSellLots = totalSellLots + Number(executions[i].lotSize);
          decimals = countDecimals(Number(executions[i].entryPrice));
        }
      } else {
        tempsum =
          tempsum +
          Number(executions[i].lotSize) * Number(executions[i].entryPrice);
        totalLots = totalLots + Number(executions[i].lotSize);
        decimals = countDecimals(Number(executions[i].entryPrice));
      }
    }
  } else {
    for (let i in executions) {
      if (type === "Multi-Leg Strategy") {
        const legtype = executions[i].exitlegType;
        if (legtype === "Long Call" || legtype === "Long Put") {
          tempbuysum =
            tempbuysum +
            Number(executions[i].exitLotSize) * Number(executions[i].exitPrice);
          totalBuyLots = totalBuyLots + Number(executions[i].exitLotSize);
          decimals = countDecimals(Number(executions[i].exitPrice));
        } else if (legtype === "Short Call" || legtype === "Short Put") {
          tempsellsum =
            tempsellsum +
            Number(executions[i].exitLotSize) * Number(executions[i].exitPrice);
          totalSellLots = totalSellLots + Number(executions[i].exitLotSize);
          decimals = countDecimals(Number(executions[i].exitPrice));
        }
      } else {
        tempsum =
          tempsum +
          Number(executions[i].exitLotSize) * Number(executions[i].exitPrice);
        totalLots = totalLots + Number(executions[i].exitLotSize);
        decimals = countDecimals(Number(executions[i].exitPrice));
      }
    }
  }
  let dollarCostAvg = 0;
  const contractsper = (totalBuyLots + totalSellLots)/Number(executions.length)
  if (type === "Multi-Leg Strategy") {
    dollarCostAvg = (tempbuysum - tempsellsum)/Number(contractsper);
  } else {
    dollarCostAvg = tempsum / totalLots;
  }

  return dollarCostAvg.toFixed(decimals);
};
export default dollarCostAverage;
