export const MMStrategylabels = [
  `Optimal f`,
  `Optimal Leverage`,
  `Theoretical Unit Size`,
  `Roy's Safety First Criteria`,
  `Kelly Criterion`,
  `Larry Williams`, // (Using Avg. Risk %)
];
export const MMStrategymetriclabels = [
  `optimalf`,
  `optimalleverage`,
  `theoreticalunitsize`,
  `royssafetyfirstcriteria`,
  `kellycriterion`,
  `larrywilliams`,
];
export const MMRMultipleCol1labels = [
  `Total R-Multiple`,
  `Avg. R-Multiple`,
  `Standard Deviation`,
  `Coefficient of Variation`,
];
export const MMRMultipleCol2labels = [
  `Avg. R-Multiple (Wins)`,
  `Largest R-Multiple (Wins)`,
  `Avg. R-Multiple (Losses)`,
  `Largest R-Multiple (Losses)`,
];
export const MMRMultiplemetricCol1labels = [
  `totalrmultiple`,
  `averageRMultiple`,
  `standarddeviationrmultiple`,
  `coefficientofvariationrmultiple`,
];
export const MMRMultiplemetricCol2labels = [
  `avgrmultiplewins`,
  `largestrmultiplewins`,
  `avgrmultiplelosses`,
  `largestrmultipleLosses`,
];
export default MMStrategylabels