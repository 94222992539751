import React from "react";
import styled from "styled-components";
import {
  faTachometerAlt,
  faAtom,
  faPlus,
  faTools,
  faBook,
  faBookOpen,
  faHome,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

import SideNavMainComponent from "./SideNavMainComp";
import MegaMenu from "../Dropdowns/MegaMenu";
import MessageDropdown from "../Dropdowns/MessageDropdown";
import ToolsDropDown from "../Dropdowns/ToolsDropDown";
import ManageDataDropDown from "../Dropdowns/ManageDataDropdown";
import { withRouter } from "react-router-dom";
import whitegraph from "./../../../images/comparergraphwhite.svg";
import goldgraph from "./../../../images/comparergraphgold.svg";
import whitegraph2 from "./../../../images/newwhitesvg.svg";
import goldgraph2 from "./../../../images/newgoldsvg.svg";
import SupportIcon from "./../../../images/Chat-02-256.png";
import goldbrain from "./../../../images/goldbrain.png";
import whitebrain from "./../../../images/whitebrain.png";
/* import whitegraphnode from "./../../../images/Nodes-256.ico";
import CommunityDropDown from "../Dropdowns/CommunityDropdown"; */
require("typeface-roboto");

export const SideNavWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  display: flex;
  width: 50px;
  height: 100%;
  flew-direction: column wrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

// ----- Side navigation bar -----
export class SideNav extends React.Component {
  constructor(props) {
    super(props);
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

    this.state = {
      height: height,
      width: width,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

    this.setState({ height: height, width: width });
  }

  render() {
    const heightMultiplier =
      this.state.width < 1680 || this.state.height < 900 ? 1.25 : 1;
    const DashboardElement = {
      link: "/Dashboard",
      text: "Dashboard",
      icon: faTachometerAlt,
      colour: "#deebf7",
      top: -1,
      iconclass: "Dashicon",
      isFlatIcon: false,
    };
    const ManageDataElement = {
      link: "/Logbook",
      text: "Logbook",
      icon: this.props.location.pathname === "/Logbook" ? faBookOpen : faBook,
      colour: "#deebf7",
      left: this.props.location.pathname === "/Logbook" ? -3 : -1,
      top: 50,
      iconclass: "LogBookicon",
      isFlatIcon: false,
    };
    const CalendarElement = {
      link: "/Calendar",
      text: "Calendar",
      icon: faCalendarAlt,
      colour: "#deebf7",
      iconclass: "LogBookicon",
      isFlatIcon: false,
      top: 101,
    };
    const ComparerElement = {
      link: "/Quantum-Query",
      text: "QuantumQuery",
      icon: goldgraph,
      colour: "#deebf7",
      iconclass: "AIicon",
      isFlatIcon: true,
      top: 152,
    };
    const AddNewTradeElement = {
      link: "/Manual-Trade-Entry",
      text: "Add Entries",
      icon: faPlus,
      marginLeft: 16,
      colour: "#deebf7",
      /*       top: 203, */
      top: 305,
      name: "icon-caret-import",
      cname: "managedatabuffer",
      isFlatIcon: false,
    };
    const InsightsElement = {
      text: "Reports",
      icon: faAtom,
      colour: "#deebf7",
      top: 203,
      name: "icon-caret-insights",
      cname: "insightsbuffer",
      isFlatIcon: false,
    };
    const ToolsElement = {
      text: "Tools",
      icon: faTools,
      colour: "#deebf7",
      top: 254,
      name: "icon-caret-tools",
      cname: "toolsbuffer",
      left: 0,
      isFlatIcon: false,
    };
    const HomeElement = {
      link: "https://tradefuse.app",
      text: "Home",
      icon: faHome,
      colour: "#deebf7",
      top: this.state.height * heightMultiplier - 168,
      iconclass: "Homeicon",
      isFlatIcon: false,
    };
    const SupportElement = {
      link: "https://tradefuse.app/support",
      text: "Support",
      icon: SupportIcon,
      colour: "#deebf7",
      top: this.state.height * heightMultiplier - 117,
      iconclass: "Supporticon",
      isFlatIcon: true,
    };
    const CommunityElement = {
      link: "https://discord.gg/v8386xk",
      text: "Discord",
      icon: faDiscord,
      colour: "#deebf7",
      top: this.state.height * heightMultiplier - 219,
      iconclass: "Dashicon",
      isFlatIcon: false,
    };
    /*     const BlockchainElement = {
      link: "/Blockchain",
      text: "Blockchain",
      icon: faCubes,
      top: this.state.height - 168,
      colour: "#deebf7",
      iconclass: "Blockchainicon",
    };
    */
    return (
      <SideNavWrapper>
        <SideNavMainComponent
          link={DashboardElement.link}
          text={DashboardElement.text}
          icon={DashboardElement.icon}
          colour={
            this.props.location.pathname === "/Dashboard" ||
            this.props.location.pathname === "/"
              ? "#fbd665"
              : DashboardElement.colour
          }
          top={DashboardElement.top}
          left={DashboardElement.left}
          iconclass={DashboardElement.iconclass}
          isFlatIcon={DashboardElement.isFlatIcon}
          isLink={true}
          isTop={true}
          isClickable={false}
          insights={false}
        />
        <SideNavMainComponent
          link={ManageDataElement.link}
          text={ManageDataElement.text}
          icon={ManageDataElement.icon}
          colour={
            this.props.location.pathname === "/Logbook"
              ? "#fbd665"
              : ToolsElement.colour
          }
          top={ManageDataElement.top}
          left={ManageDataElement.left}
          iconclass={ManageDataElement.iconclass}
          isFlatIcon={ManageDataElement.isFlatIcon}
          isLink={true}
          isTop={true}
          isClickable={false}
          insights={false}
        />{" "}
        <SideNavMainComponent
          link={CalendarElement.link}
          text={CalendarElement.text}
          icon={CalendarElement.icon}
          colour={
            this.props.location.pathname === "/Calendar"
              ? "#fbd665"
              : CalendarElement.colour
          }
          top={CalendarElement.top}
          left={CalendarElement.left}
          iconclass={CalendarElement.iconclass}
          isFlatIcon={CalendarElement.isFlatIcon}
          isLink={true}
          isTop={true}
          isClickable={false}
          insights={false}
        />
        <SideNavMainComponent
          link={ComparerElement.link}
          text={ComparerElement.text}
          icon={
            this.props.location.pathname === "/Quantum-Query"
              ? goldbrain
              : whitebrain
          }
          colour={
            this.props.location.pathname === "/Quantum-Query"
              ? "#fbd665"
              : ComparerElement.colour
          }
          top={ComparerElement.top}
          left={ComparerElement.left}
          iconclass={ComparerElement.iconclass}
          isFlatIcon={ComparerElement.isFlatIcon}
          isLink={true}
          isTop={true}
          isClickable={false}
          insights={false}
        />
        <SideNavMainComponent
          text={AddNewTradeElement.text}
          icon={AddNewTradeElement.icon}
          colour={
            this.props.location.pathname === "/Manual-Trade-Entry" ||
            this.props.location.pathname === "/Deposits-Withdrawals" ||
            this.props.location.pathname === "/Integrations"
              ? "#fbd665"
              : AddNewTradeElement.colour
          }
          top={AddNewTradeElement.top}
          marginLeft={AddNewTradeElement.marginLeft}
          name={AddNewTradeElement.name}
          cname={AddNewTradeElement.cname}
          isFlatIcon={AddNewTradeElement.isFlatIcon}
          isLink={false}
          isClickable={false}
          insights={false}
        />
        {
          <SideNavMainComponent
            text={InsightsElement.text}
            icon={InsightsElement.icon}
            colour={
              this.props.location.pathname === "/Comprehensive" ||
              this.props.location.pathname === "/KPIs" ||
              this.props.location.pathname === "/Fund-Report" ||
              this.props.location.pathname === "/Drawdown-RunUp" ||
              this.props.location.pathname === "/Holding-Time-Report" ||
              this.props.location.pathname === "/Risk-Management" ||
              this.props.location.pathname === "/MAE-and-MFE-Report" ||
              this.props.location.pathname === "/Efficiency-Report" ||
              this.props.location.pathname === "/Daily-Report" ||
              this.props.location.pathname === "/Weekly-Report" ||
              this.props.location.pathname === "/Monthly-Report" ||
              this.props.location.pathname === "/Yearly-Report" ||
              this.props.location.pathname === "/Wins-Report" ||
              this.props.location.pathname === "/Losses-Report" ||
              this.props.location.pathname === "/Break-Evens-Report"
                ? "#fbd665"
                : InsightsElement.colour
            }
            top={InsightsElement.top}
            left={InsightsElement.left}
            name={InsightsElement.name}
            cname={InsightsElement.cname}
            isLink={false}
            isClickable={false}
            insights={true}
            isFlatIcon={InsightsElement.isFlatIcon}
            source={
              this.props.location.pathname === "/Comprehensive" ||
              this.props.location.pathname === "/KPIs" ||
              this.props.location.pathname === "/Fund-Report" ||
              this.props.location.pathname === "/Drawdown-RunUp" ||
              this.props.location.pathname === "/Holding-Time-Report" ||
              this.props.location.pathname === "/Risk-Management" ||
              this.props.location.pathname === "/MAE-and-MFE-Report" ||
              this.props.location.pathname === "/Efficiency-Report" ||
              this.props.location.pathname === "/Daily-Report" ||
              this.props.location.pathname === "/Weekly-Report" ||
              this.props.location.pathname === "/Monthly-Report" ||
              this.props.location.pathname === "/Yearly-Report" ||
              this.props.location.pathname === "/Wins-Report" ||
              this.props.location.pathname === "/Losses-Report" ||
              this.props.location.pathname === "/Break-Evens-Report"
                ? goldgraph2
                : whitegraph2
            }
          />
        }
        {
          <SideNavMainComponent
            text={ToolsElement.text}
            icon={ToolsElement.icon}
            colour={
              this.props.location.pathname === "/Monte-Carlo-Simulator" ||
              this.props.location.pathname === "/Position-Size-Calculator" ||
              this.props.location.pathname === "/Streak-Odds-Calculator" ||
              this.props.location.pathname === "/Compare-&-Conquer"
                ? "#fbd665"
                : ToolsElement.colour
            }
            top={ToolsElement.top}
            left={ToolsElement.left}
            name={ToolsElement.name}
            cname={ToolsElement.cname}
            isLink={false}
            isClickable={false}
          />
        }
        <SideNavMainComponent
          link={HomeElement.link}
          text={HomeElement.text}
          icon={HomeElement.icon}
          colour={HomeElement.colour}
          top={HomeElement.top}
          isClickable={false}
          iconclass={HomeElement.iconclass}
          isFlatIcon={HomeElement.isFlatIcon}
          isLink={true}
          isTop={false}
          insights={false}
        />
        <SideNavMainComponent
          link={SupportElement.link}
          text={SupportElement.text}
          icon={SupportElement.icon}
          colour={SupportElement.colour}
          top={SupportElement.top}
          isClickable={false}
          iconclass={SupportElement.iconclass}
          isLink={true}
          isTop={false}
          insights={false}
          isFlatIcon={SupportElement.isFlatIcon}
        />
        {/*         <SideNavMainComponent
          text={CommunityElement.text}
          icon={CommunityElement.icon}
          colour={
            this.props.location.pathname === "/Comprehensive" ||
            this.props.location.pathname === "/KPIs" ||
            this.props.location.pathname === "/Fund-Report" ||
            this.props.location.pathname === "/Streak-Odds-Calculator" ||
            this.props.location.pathname === "/Holding-Time-Report" ||
            this.props.location.pathname === "/Risk-Management" ||
            this.props.location.pathname === "/MAE-and-MFE-Report" ||
            this.props.location.pathname === "/Efficiency-Report" ||
            this.props.location.pathname === "/Daily-Report" ||
            this.props.location.pathname === "/Weekly-Report" ||
            this.props.location.pathname === "/Monthly-Report" ||
            this.props.location.pathname === "/Yearly-Report" ||
            this.props.location.pathname === "/Wins-Report" ||
            this.props.location.pathname === "/Losses-Report" ||
            this.props.location.pathname === "/Break-Evens-Report"
              ? "#fbd665"
              : CommunityElement.colour
          }
          top={CommunityElement.top}
          left={CommunityElement.left}
          name={CommunityElement.name}
          cname={CommunityElement.cname}
          isLink={false}
          isClickable={false}
          insights={true}
          source={whitegraphnode}
        /> */}
        <SideNavMainComponent
          link={CommunityElement.link}
          text={CommunityElement.text}
          icon={CommunityElement.icon}
          colour={CommunityElement.colour}
          top={CommunityElement.top}
          isClickable={false}
          iconclass={CommunityElement.iconclass}
          isLink={true}
          isTop={false}
          insights={false}
          isFlatIcon={CommunityElement.isFlatIcon}
        />
        <MegaMenu style={{ "font-family": "Roboto, sans-serif" }} />
        <ToolsDropDown />
        {/*         <CommunityDropDown /> */}
        <ManageDataDropDown />
        <MessageDropdown />
      </SideNavWrapper>
    );
  }
}

export default withRouter(SideNav);
