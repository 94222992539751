import React, { useContext } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";

const BlankPage = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance && dataInstance.customer.active;
  const tier = dataInstance && dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;
  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    processedData = processDataandSettings(allData, tier, active);
  }
  if (Object.keys(processedData).length === 0) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <PageTemplate
        title={props.tabName}
        page={<div></div>}
        userData={processedData}
      />
    );
  }
};

BlankPage.defaultProps = {
  tabName: "",
};

BlankPage.propTypes = {
  tabName: PropTypes.string,
};

export default BlankPage;
