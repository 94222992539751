// get leg type from options side and type
export const getLegType = (side, type) => {
  let optionType = "";
  if (type === "put") {
    if (side === "buy") {
      optionType = "Long Put";
    } else {
      optionType = "Short Put";
    }
  } else {
    if (side === "buy") {
      optionType = "Long Call";
    } else {
      optionType = "Short Call";
    }
  }
  return optionType;
};
export default getLegType;
