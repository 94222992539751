export function removeAllRobinhoodTokens() {
  // Get all keys from localStorage
  const keys = Object.keys(localStorage);

  // Iterate over the keys and remove items that start with "robinhoodToken"
  keys.forEach((key) => {
    if (key.startsWith("robinhoodToken")) {
      localStorage.removeItem(key);
    }
  });
}
export default removeAllRobinhoodTokens;
