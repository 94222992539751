import React, { useContext } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import processDataandSettings from "../../utils/processDataandSettings";
import { DataContext } from "../../DataContext";
import useStateWithPromise from "../../hooks/useStateWithPromise";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import "./styles.scss";

const ErrorHandler = (props) => {
  const dataInstance = useContext(DataContext);
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const allData = dataInstance && dataInstance.allData;
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  let processedData = {};
  const historyState = props.history.location.state;
  const errorText = historyState?.error;
  console.log(errorText);
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Error Found - TradeFuse";
    //processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  return <PageTemplateBlank title={props.tabName} showloader={false} />;
};

ErrorHandler.defaultProps = {
  tabName: "Error Found",
  pageName: "Error Found",
};

ErrorHandler.propTypes = {
  tabName: PropTypes.string,
};

export default ErrorHandler;
