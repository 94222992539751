import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import chroma from "chroma-js";
import { Doughnut } from "react-chartjs-2";
import totalCostCalculation from "./../../functions/totalCostCalculation";
import generateRandomColors from "./../../functions/generateRandomColors";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

//const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);

let he = require("he");
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
function getIndex(arr, val) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      return i;
    }
  }
  return -1; // Indicates that the value was not found in the array
}
function darkenColors(colors) {
  // Create an empty array to hold the darkened colors
  const darkenedColors = [];
  const darkFactor = 0.6;
  // Iterate over each color in the input array
  for (let i = 0; i < colors.length; i++) {
    // Parse the color string into its red, green, and blue components
    const red = parseInt(colors[i].substring(1, 3), 16);
    const green = parseInt(colors[i].substring(3, 5), 16);
    const blue = parseInt(colors[i].substring(5, 7), 16);

    // Darken each component by multiplying it by darkFactor (which is darkFactor*(100%)
    const darkRed = Math.floor(red * darkFactor);
    const darkGreen = Math.floor(green * darkFactor);
    const darkBlue = Math.floor(blue * darkFactor);

    // Convert the darkened components back into a color string and add it to the array
    const darkenedColor = `#${darkRed.toString(16)}${darkGreen.toString(
      16
    )}${darkBlue.toString(16)}`;
    darkenedColors.push(darkenedColor);
  }

  // Return the array of darkened colors
  return darkenedColors;
}
const OpenPositionDonutGraph = (props) => {
  const userData = props.userData;
  const portfolio = userData.portfolio;
  const entries = portfolio.entries;
  const filterOpenTrades = (trade) => {
    const entryExecs = trade.entry.multiExecution;
    let entryLots = 0;
    entryExecs.forEach((exec) => {
      entryLots += Number(exec.lotSize);
    });
    let exitLots = 0;
    const exitExecs = trade.entry.exitExecution;
    exitExecs.forEach((exec) => {
      exitLots += Number(exec.exitLotSize);
    });
    entryLots = fixRound(entryLots); // fix rounding issue
    exitLots = fixRound(exitLots);
    if (exitLots !== entryLots) {
      return true;
    } else {
      return false;
    }
  };

  // sort the open trades alphabetically at the end
  let openTrades =
    entries &&
    entries
      .filter(filterOpenTrades)
      .slice()
      .sort((a, b) =>
        a?.entry?.symbol?.symbols[0]?.localeCompare(
          b?.entry?.symbol?.symbols[0]
        )
      );

  const supportedCryptosCheck = supportedCryptos.includes(props.defaultSymbol);
  const history = useHistory();
  const chartRef = useRef();

  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i, entries) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };

  // creates an array that's sorted exactly how the target array is sorted
  /*   function sortArrays(targetArr, sortArr) {
    const sortedTargetArr = [...targetArr].sort();
    const indexArr = sortedTargetArr.map((item) => targetArr.indexOf(item));
    const sortedArr = indexArr.map((index) => sortArr[index]);
    return sortedArr;
  } */
  const equityvaluefunction = () => {
    let equityvalue = [];
    let symbols = [];

    // push the open trade total costs
    for (var i = 0, j = openTrades.length; i < j; i++) {
      const entryExecsd = openTrades[i].entry.multiExecution;
      const ordertype = openTrades[i].entry.orderType;
      const portfoliotype = openTrades[i].entry.selectedPortfolioType;
      const pointvalue = openTrades[i].entry.symbol.pointValue;
      const symbol = openTrades[i].entry.symbol.symbols[0];

      const totalCost = totalCostCalculation(
        entryExecsd,
        ordertype,
        portfoliotype,
        pointvalue
      );
      equityvalue.push(totalCost);
      symbols.push(symbol);
    }
    return { equityvalue, symbols };
  };

  const donutValuesPre = equityvaluefunction().equityvalue;
  const donutSymbolsPre = equityvaluefunction().symbols;

  // sort alphabetically and sort data according to the new indexes
  const donutValues = donutValuesPre;
  const donutSymbols = donutSymbolsPre;

  const totalExposure = arrSum(donutValues);

  const donutColors = useRef(generateRandomColors(donutValues.length));
  const donutColorsDarker = useRef(darkenColors(donutColors.current));
  const [donutshow, setdonutshow] = useState(
    Object.fromEntries(donutSymbols.map((item) => [item, true]))
  );
  let donutValuesArr = [];
  for (let prop in donutshow) {
    const indexi = getIndex(donutSymbols, prop);
    if (donutshow.hasOwnProperty(prop) && donutshow[prop]) {
      donutValuesArr.push(donutValues[indexi]);
    }
  }
  const [donutSymbolsActual, setdonutSymbolsActual] = useState(donutValuesArr);
  //const lineColor = "#664bff" //purple
  /*   let donutSymbols = [];

  let donutValues = [];
  function getIndex(arr, val) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === val) {
        return i;
      }
    }
    return -1; // Indicates that the value was not found in the array
  }
  for (let prop in donutshow) {
    const indexi = getIndex(donutSymbols, prop);
    if (donutshow.hasOwnProperty(prop) && donutshow[prop]) {
      donutSymbols.push(prop);
      donutValues.push(donutValuesPre[indexi]);
    }
  } */
  /*   const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: equityvaluefunction(),
      },
    ],
  }; */
  const data = {
    labels: donutSymbols,
    datasets: [
      {
        data: donutSymbolsActual,
        backgroundColor: donutColors.current,
        borderColor: donutColorsDarker.current,
        borderWidth: 2,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    cutoutPercentage: 62,
    metaData: {
      id: "positionsDoughnut",
    },
    tooltips: {
      enabled: true,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: (tooltipItems) => {
          const entry = getEntrybyIndex(tooltipItems[0].index, openTrades);
          const actualEntry = entry && entry[0]?.entry;
          const symbol = actualEntry?.symbol.symbols[0];
          return symbol;
        },
        label: (tooltipItems) => {
          const entry = getEntrybyIndex(tooltipItems.index, openTrades);
          const actualEntry = entry && entry[0]?.entry;
          const entryExecsd = actualEntry?.multiExecution;
          const ordertype = actualEntry?.orderType;
          const portfoliotype = actualEntry?.selectedPortfolioType;
          const pointvalue = actualEntry?.symbol.pointValue;
          const totalCost = totalCostCalculation(
            entryExecsd,
            ordertype,
            portfoliotype,
            pointvalue
          );
          return [
            "Cost Basis: " +
              (supportedCryptosCheck
                ? parseFloat(totalCost.toFixed(6)) + " " + props.defaultSymbol
                : he.decode(props.defaultSymbol) +
                  parseFloat(totalCost).toFixed(2)),
            `% of Total: ${((totalCost / arrSum(donutValues)) * 100).toFixed(
              3
            )}%`,
          ];
        },
      },
    },
    onHover: function (e) {
      var point = this.getElementAtEvent(e);
      if (point.length) e.target.style.cursor = "pointer";
      else e.target.style.cursor = "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index, openTrades)[0]);
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
      position: "bottom",
      align: "center",
      onHover: function (e) {
        e.target.style.cursor = "pointer";
      },
    },
  };
  return (
    <div className="OpenPositionDonutGraph">
      <div
        className="OpenPositionDonutGraph-label"
        id="OpenPositionDonutGraph-label"
      >
        Open Position Breakdown
      </div>
      <div
        className="OpenPositionDonutGraph-exp"
        id="OpenPositionDonutGraph-exp"
      >
        {`Total Exposure:`}
        <div
          className="OpenPositionDonutGraph-expval"
          id="OpenPositionDonutGraph-expval"
        >
          {supportedCryptosCheck
            ? parseFloat(totalExposure.toFixed(6)) + " " + props.defaultSymbol
            : he.decode(props.defaultSymbol) +
              parseFloat(totalExposure).toFixed(2)}
        </div>
      </div>
      <div className="OpenPositionDonutGraphwrapper">
        <Doughnut
          data={data}
          options={options}
          ref={chartRef}
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
      <div className="OpenPositionDonutGraphLegendwrapper">
        {donutSymbols.map((symbol, i) => {
          return (
            <div
              key={uuidv4()}
              style={{
                position: "relative",
                backgroundColor: donutColors.current[i],
                borderRadius: "8px",
                color: "#14181e",
                fontSize: "0.95em",
                height: "28px",
                cursor: "pointer",
                margin: "2px",
                padding: "3px 7px 3px 7px",
                width: "max-content",
                backgroundColor: donutshow[symbol]
                  ? donutColors.current[i]
                  : "transparent",
                border: donutshow[symbol]
                  ? `1px solid transparent`
                  : `1px solid ${donutColors.current[i]}`,
                color: donutshow[symbol] ? "#14181e" : `#556171`,
              }}
              onClick={(e) => {
                let donutValuesArr = [...donutValues];
                let currentdon = "";
                if (!donutshow[symbol]) {
                  currentdon = {
                    ...donutshow,
                    [symbol]: true,
                  };
                  setdonutshow(currentdon);
                } else {
                  currentdon = {
                    ...donutshow,
                    [symbol]: false,
                  };
                  setdonutshow(currentdon);
                }
                for (let prop in currentdon) {
                  const indexi = getIndex(donutSymbols, prop);
                  if (currentdon.hasOwnProperty(prop) && currentdon[prop]) {
                    donutValuesArr[indexi] = donutValues[indexi];
                  } else {
                    donutValuesArr[indexi] = undefined;
                  }
                }
                setdonutSymbolsActual(donutValuesArr);
              }}
            >
              {symbol}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OpenPositionDonutGraph;
