const proxyURL =
  "https://api.tradefuse.app/getStripeCustomer";

export const getCustomer = async (customerId, history) => {
  const dataIn = {
    data: {
      customerId: customerId,
    },
  };
  const response = await fetch(proxyURL, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Host: proxyURL,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
      "Access-Control-Allow-Headers": "Content-Type",
    },
    body: JSON.stringify(dataIn),
  }).catch((err) => {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message,
      },
    });
    window.location.reload();
  });
  return response.json();
};
export default getCustomer;