const initialState = {
  loading: false,
};

export const loaderState = (state = initialState, action) => {
  switch (action.type) {
    case "SETLOADERSTATE":
      return action.npmstrat;

    default:
      return state;
  }
};

export default loaderState
