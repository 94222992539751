var he = require("he");

export const drawdownDOLLARlabels = (symbol) => {
  const labels = [
    `Max. DD. ${he.decode(String(symbol))}`,
    `Date of Max. DD.`,
    `Avg. Max. DD. ${he.decode(String(symbol))}`,
    `Avg. DD. ${he.decode(String(symbol))}`,
    `Longest DD. Period`,
    `Avg. DD. Period`,
    `Absolute DD. ${he.decode(String(symbol))}`,
  ];
  return labels;
};
export const drawdownPERCENTlabels = [
  `Max. DD. %`,
  `Date of Max. DD.`,
  `Avg. Max. DD. %`,
  `Avg. DD. %`,
  `Longest DD. Period`,
  `Avg. DD. Period`,
  `Absolute DD. %`,
];
export const drawdownDOLLARmetriclabels = [
  `maxDrawdownDollar`,
  `dateofMaxDrawdown`,
  `averageMaxDrawdownDollar`,
  `averageDrawdownDollar`,
  `longestDrawdownPeriod`,
  `averageDrawdownPeriod`,
  `absoluteDrawdownDollar`,
];
export const drawdownPERCENTmetriclabels = [
  `maxDrawdownPercent`,
  `dateofMaxDrawdownPercent`,
  `averageMaxDrawdownPercent`,
  `averageDrawdownPercent`,
  `longestDrawdownPeriod`,
  `averageDrawdownPeriod`,
  `absoluteDrawdownPercent`,
];
export const runupDOLLARlabels = (symbol) => {
  const labels = [
    `Max. RU. ${he.decode(String(symbol))}`,
    `Date of Max. RU.`,
    `Avg. Max. RU. ${he.decode(String(symbol))}`,
    `Avg. RU. ${he.decode(String(symbol))}`,
    `Longest RU. Period`,
    `Avg. RU. Period`,
    `Absolute RU. ${he.decode(String(symbol))}`,
  ];
  return labels;
};
export const runupPERCENTlabels = [
  `Max. RU. %`,
  `Date of Max. RU.`,
  `Avg. Max. RU. %`,
  `Avg. RU. %`,
  `Longest RU. Period`,
  `Avg. RU. Period`,
  `Absolute RU. %`,
];
export const runupDOLLARmetriclabels = [
  `maxRunUpDollar`,
  `dateofMaxRunUp`,
  `averageMaxRunUpDollar`,
  `averageRunUpDollar`,
  `longestRunUpPeriod`,
  `averageRunUpPeriod`,
  `absoluteRunUpDollar`,
];
export const runupPERCENTmetriclabels = [
  `maxRunUpPercent`,
  `dateofMaxRunUpPercent`,
  `averageMaxRunUpPercent`,
  `averageRunUpPercent`,
  `longestRunUpPeriod`,
  `averageRunUpPeriod`,
  `absoluteRunUpPercent`,
];