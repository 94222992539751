import React, { useEffect, useState, useCallback, useRef } from "react";
import $ from "jquery";
import dollarCostAverage from "./../../functions/dollarCostAvg";
import { handleNANGOOD } from "./../../functions/handleUndefined";
import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
//import getRiskedPercentColor from "../../functions/getRiskedPercentColor";
import { profitLossCalculation } from "./../../functions/profitLossCalculation";
import getColorPoint from "./../../functions/includeColor";
import ProTierComponent from "./../../components/tierComponents/pro";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";

const ImportantMetricComponent = (props) => {
  const row = props.row;
  const metric = props.metric;
  const showINIT = props.showCheck;

  /*   const data = props.userData;
  const balance = props.balance;
  const startingBalance = props.startingBalance; */
  //const calculations = props.userCalculations;
  const settings = props.settings;
  const [factor, setfactor] = useState(0);
  const [factorRM, setfactorRM] = useState(0);
  const [riskWidth, setriskWidth] = useState(0);
  const [emptyWidth, setemptyWidth] = useState(0);
  const color = useRef("");

  const balance = props.balance;
  const orderType = row.orderType;
  const multiExecutioninner = row.multiExecution;
  let avgEntry = dollarCostAverage(multiExecutioninner, "entry", orderType);
  const exitExecution = row.exitExecution;
  let avgExit = dollarCostAverage(exitExecution, "exit", orderType);
  const RRR =
    (Number(row.takeProfit) - avgEntry) / (avgEntry - Number(row.stopLoss));
  const rmultiple = (avgExit - avgEntry) / (avgEntry - Number(row.stopLoss));
  const adjrrr = -avgEntry / (avgEntry - Number(row.stopLoss));

  const divisor = row.selectedPortfolioType === "Forex" ? rmultiple : adjrrr;

  const initPl = profitLossCalculation(
    multiExecutioninner,
    [],
    orderType,
    row.selectedPortfolioType,
    row.symbol.pointValue,
    true
  );

  const dividend =
    row.selectedPortfolioType === "Forex"
      ? returnNetPLDollar(
          row.profitLoss,
          row.commissions,
          row.fees,
          settings.calculationType
        )
      : returnNetPLDollar(
          initPl,
          row.commissions,
          row.fees,
          settings.calculationType
        );
  // If the asset class if forex we have to calculate risk a slightly different way
  const percent = Number(dividend / Number(balance)) * 100;
  const riskPercent = percent / divisor;

  useEffect(() => {
    const element = $("#viewTradeimportantmetricgraphwraper");
    const elementRM = $("#viewTradeimportantmetricgraphwraper2");
    const elementRisk = $("#viewTradeimportantmetricgraphwraper3");
    let riskWidthTemp = Number(elementRisk.width()) * (riskPercent / 100);

    // idk how this works, but wait 150ms before setting stuff with the element width
    // if we dont wait, the element width will be wrong. If we wait too long, it will flash
    // 150 ms works perfectly on windows 10
    setTimeout(() => {
      let weightfactor = Number(element.width()) / (RRR + 1);
      let weightfactorRM = Number(elementRM.width()) / (rmultiple + 1);
      setfactor(showINIT ? 0 : weightfactor);
      setfactorRM(showINIT ? 0 : weightfactorRM);
      setriskWidth(showINIT ? 0 : riskWidthTemp);
      setemptyWidth(showINIT ? 0 : Number(elementRisk.width()) - riskWidthTemp);
    }, 150);

    $(window).resize(function () {
      let weightfactor = Number(element.width()) / (RRR + 1);
      let weightfactorRM = Number(elementRM.width()) / (rmultiple + 1);
      setfactor(showINIT ? 0 : weightfactor);
      setfactorRM(showINIT ? 0 : weightfactorRM);
      setriskWidth(showINIT ? 0 : riskWidthTemp);
      setemptyWidth(showINIT ? 0 : Number(elementRisk.width()) - riskWidthTemp);
    });
  }, [RRR, rmultiple, riskPercent, showINIT]);
  const graph = useCallback(() => {
    let returnGraph = "";
    if (metric.value === "avgRRR") {
      returnGraph = (
        <div id="viewTradeimportantmetricgraphwraper">
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={
              <div style={{ color: "#fff7cb" }}>
                {"Reward: " + Number(RRR).toFixed(3)}
              </div>
            }
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(RRR * factor, 0),
                backgroundColor: "#55f8a6",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                filter: "drop-shadow(0 0 2px #55f8a6)",
                WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
                MozFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
              }}
            ></div>{" "}
          </PopoverStickOnHover>
          <PopoverStickOnHover
            placement="bottom"
            delay={350}
            keepOpen={false}
            xAdjust={10}
            yAdjust={0}
            component={<div style={{ color: "#fff7cb" }}>{"Risk: " + 1}</div>}
          >
            <div
              style={{
                display: "block",
                height: 10,
                width: handleNANGOOD(factor, 0),
                backgroundColor: "#fa798b",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                filter: "drop-shadow(0 0 2px #fa798b)",
                WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
                MozFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
                msFilter:
                  "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
              }}
            ></div>{" "}
          </PopoverStickOnHover>
        </div>
      );
    } else if (metric.value === "rmultipleratio") {
      returnGraph = (
        <div id="viewTradeimportantmetricgraphwraper2">
          <div
            style={{
              display: "block",
              height: 10,
              width: rmultiple * factorRM,
              backgroundColor: "#55f8a6",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              filter: "drop-shadow(0 0 2px #55f8a6)",
              WebkitFilter: "drop-shadow(0 0 2px #55f8a6)",
              MozFilter:
                "drop-shadow(0 0 2px #55f8a6)" /* Old versions of Firefox ffc20a*/,
              msFilter:
                "drop-shadow(0 0 2px #55f8a6)" /* Internet Explorer/Edge */,
            }}
          ></div>
          <div
            style={{
              display: "block",
              height: 10,
              width: factorRM,
              backgroundColor: "#fa798b",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              filter: "drop-shadow(0 0 2px #fa798b)",
              WebkitFilter: "drop-shadow(0 0 2px #fa798b)",
              MozFilter:
                "drop-shadow(0 0 2px #fa798b)" /* Old versions of Firefox ffc20a*/,
              msFilter:
                "drop-shadow(0 0 2px #fa798b)" /* Internet Explorer/Edge */,
            }}
          ></div>
        </div>
      );
    } else {
      //linear-gradient(to right, #55f8a6 0%, #e1f967 5%, #ff9735 10%, rgb(238, 73, 73) 15%, rgb(139, 43, 43) 20%)

      if (riskPercent < 5) {
        color.current = getColorPoint(
          "#49f875",
          "#e1f967",
          riskPercent / 5,
          "hex"
        );
      } else if (riskPercent >= 5 && riskPercent < 10) {
        color.current = getColorPoint(
          "#e1f967",
          "#ff9735",
          (riskPercent - 5) / 5,
          "hex"
        );
      } else if (riskPercent >= 10 && riskPercent < 15) {
        color.current = getColorPoint(
          "#ff9735",
          "rgb(238, 73, 73)",
          (riskPercent - 10) / 5,
          "hex"
        );
      } else if (riskPercent >= 15 && riskPercent < 20) {
        color.current = getColorPoint(
          "rgb(238, 73, 73)",
          "rgb(139, 43, 43)",
          (riskPercent - 15) / 5,
          "hex"
        );
      } else {
        color.current = "rgb(139, 43, 43)";
      }
      returnGraph = (
        <div id="viewTradeimportantmetricgraphwraper3">
          <div
            style={{
              display: "block",
              height: 6,
              width: riskWidth,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              background: color.current,
              filter: "drop-shadow(0 0 3px #171c23)",
              WebkitFilter: "drop-shadow(0 0 3px #171c23)",
              MozFilter:
                "drop-shadow(0 0 3px #171c23)" /* Old versions of Firefox ffc20a*/,
              msFilter:
                "drop-shadow(0 0 3px #171c23)" /* Internet Explorer/Edge */,
            }}
          ></div>
          <div
            style={{
              display: "block",
              height: 6,
              width: emptyWidth,
              right: 6,
              borderBottomRightRadius: 3,
              borderTopRightRadius: 3,
            }}
          ></div>
        </div>
      );
    }
    return returnGraph;
  }, [
    factorRM,
    factor,
    rmultiple,
    RRR,
    metric.value,
    riskPercent,
    emptyWidth,
    riskWidth,
  ]);
  const acceptableRRR =
    handleNANGOOD(RRR, "-") === "-" ? "-" : handleNANGOOD(RRR, "-").toFixed(3);
  const acceptablermultiple =
    handleNANGOOD(rmultiple, "-") === "-"
      ? "-"
      : handleNANGOOD(rmultiple, "-").toFixed(3);
  const acceptablerrisk =
    handleNANGOOD(riskPercent, "-") === "-"
      ? "-"
      : handleNANGOOD(riskPercent, "-").toFixed(3);
  const metricvalue = () => {
    if (metric.value === "avgRRR") {
      return acceptableRRR + " : 1";
    } else if (metric.value === "rmultipleratio") {
      return acceptablermultiple + " R";
    } else {
      return acceptablerrisk + "%";
    }
  };

  return (
    <span id="viewTradeEfficiencyBarOuter">
      <span
        style={{
          color: color.current !== "" ? color.current : "#fff7cb",
          fontWeight: 400,
        }}
      >
        {showINIT ? (
          <div
            style={{
              position: "relative",
              top: -12,
              left: "45%",
              marginBottom: 8,
            }}
          >
            <ProTierComponent />
          </div>
        ) : (
          <div className="riskgraphmetricvaluewrapper">
            <div>{metricvalue()}</div>
          </div>
        )}
      </span>
      <span id="logBookEfficiencyBar"> {graph()}</span>
    </span>
  );
};

export default ImportantMetricComponent;
