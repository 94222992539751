import React from "react";
import styled from "styled-components";

export const CurrencySymbolItem = styled.div`
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
  color: #55f8a6;
`;
export const CryptoSymbolItem = styled.div`
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  color: #ffe593;
`;
export const HybridSymbolItem = styled.div`
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  color: #2f8eb4;
`;
export const currencySymbolCodes = [
  { label: <CurrencySymbolItem>&#36;</CurrencySymbolItem>, value: "&#36;" },
  { label: <CurrencySymbolItem>&#8352;</CurrencySymbolItem>, value: "&#8352;" },
  { label: <CurrencySymbolItem>&#8353;</CurrencySymbolItem>, value: "&#8353;" },
  { label: <CurrencySymbolItem>&#8354;</CurrencySymbolItem>, value: "&#8354;" },
  { label: <CurrencySymbolItem>&#8355;</CurrencySymbolItem>, value: "&#8355;" },
  { label: <CurrencySymbolItem>&#6107;</CurrencySymbolItem>, value: "&#6107;" },
  { label: <CurrencySymbolItem>&#165;</CurrencySymbolItem>, value: "&#165;" },
  { label: <CurrencySymbolItem>&#402;</CurrencySymbolItem>, value: "&#402;" },
  { label: <CurrencySymbolItem>&#1423;</CurrencySymbolItem>, value: "&#1423;" },
  { label: <CurrencySymbolItem>&#1547;</CurrencySymbolItem>, value: "&#1547;" },
  { label: <CurrencySymbolItem>&#2547;</CurrencySymbolItem>, value: "&#2547;" },
  { label: <CurrencySymbolItem>&#3647;</CurrencySymbolItem>, value: "&#3647;" },
  {
    label: <CurrencySymbolItem>&#65020;</CurrencySymbolItem>,
    value: "&#65020;",
  },
  { label: <CurrencySymbolItem>&#8357;</CurrencySymbolItem>, value: "&#8357;" },
  { label: <CurrencySymbolItem>&#8358;</CurrencySymbolItem>, value: "&#8358;" },
  { label: <CurrencySymbolItem>&#8359;</CurrencySymbolItem>, value: "&#8359;" },
  { label: <CurrencySymbolItem>&#8360;</CurrencySymbolItem>, value: "&#8360;" },
  { label: <CurrencySymbolItem>&#8361;</CurrencySymbolItem>, value: "&#8361;" },
  { label: <CurrencySymbolItem>&#8362;</CurrencySymbolItem>, value: "&#8362;" },
  { label: <CurrencySymbolItem>&#8363;</CurrencySymbolItem>, value: "&#8363;" },
  { label: <CurrencySymbolItem>&#8364;</CurrencySymbolItem>, value: "&#8364;" },
  { label: <CurrencySymbolItem>&#8365;</CurrencySymbolItem>, value: "&#8365;" },
  { label: <CurrencySymbolItem>&#8367;</CurrencySymbolItem>, value: "&#8367;" },
  { label: <CurrencySymbolItem>&#8368;</CurrencySymbolItem>, value: "&#8368;" },
  { label: <CurrencySymbolItem>&#8369;</CurrencySymbolItem>, value: "&#8369;" },
  { label: <CurrencySymbolItem>&#8370;</CurrencySymbolItem>, value: "&#8370;" },
  { label: <CurrencySymbolItem>&#8372;</CurrencySymbolItem>, value: "&#8372;" },
  { label: <CurrencySymbolItem>&#8373;</CurrencySymbolItem>, value: "&#8373;" },
  { label: <CurrencySymbolItem>&#8374;</CurrencySymbolItem>, value: "&#8374;" },
  { label: <CurrencySymbolItem>&#8375;</CurrencySymbolItem>, value: "&#8375;" },
  { label: <CurrencySymbolItem>&#8376;</CurrencySymbolItem>, value: "&#8376;" },
  { label: <CurrencySymbolItem>&#8377;</CurrencySymbolItem>, value: "&#8377;" },
  { label: <CurrencySymbolItem>&#8378;</CurrencySymbolItem>, value: "&#8378;" },
  { label: <CurrencySymbolItem>&#8379;</CurrencySymbolItem>, value: "&#8379;" },
  { label: <CurrencySymbolItem>&#8380;</CurrencySymbolItem>, value: "&#8380;" },
  { label: <CurrencySymbolItem>&#8381;</CurrencySymbolItem>, value: "&#8381;" },
  { label: <CurrencySymbolItem>&#8382;</CurrencySymbolItem>, value: "&#8382;" },

  { label: <HybridSymbolItem>&#8371;</HybridSymbolItem>, value: "&#8371;" },
  { label: <HybridSymbolItem>&#8366;</HybridSymbolItem>, value: "&#8366;" },

  { label: <CryptoSymbolItem>&#8383;</CryptoSymbolItem>, value: "&#8383;" },
  { label: <CryptoSymbolItem>&#926;</CryptoSymbolItem>, value: "&#926;" },
  { label: <CryptoSymbolItem>&#8226;</CryptoSymbolItem>, value: "&#8226;" },
  { label: <CryptoSymbolItem>Ł</CryptoSymbolItem>, value: "Ł" },
  { label: <CryptoSymbolItem>⬡</CryptoSymbolItem>, value: "⬡" },
  { label: <CryptoSymbolItem>◎</CryptoSymbolItem>, value: "◎" },

  { label: <CryptoSymbolItem>⬙</CryptoSymbolItem>, value: "⬙" },
  { label: <CryptoSymbolItem>ȿ</CryptoSymbolItem>, value: "ȿ" },
  { label: <CryptoSymbolItem>ⓩ</CryptoSymbolItem>, value: "ⓩ" },
  { label: <CryptoSymbolItem>ε</CryptoSymbolItem>, value: "ε" },
  { label: <CryptoSymbolItem>ሠ</CryptoSymbolItem>, value: "ሠ" },
  { label: <CryptoSymbolItem>ξ</CryptoSymbolItem>, value: "ξ" },
  { label: <CryptoSymbolItem>⨎</CryptoSymbolItem>, value: "⨎" },
  { label: <CryptoSymbolItem>⟁</CryptoSymbolItem>, value: "⟁" },
  { label: <CryptoSymbolItem>ꜩ</CryptoSymbolItem>, value: "ꜩ" },
  { label: <CryptoSymbolItem>⚛</CryptoSymbolItem>, value: "⚛" },
  { label: <CryptoSymbolItem>Ð</CryptoSymbolItem>, value: "Ð" },
  { label: <CryptoSymbolItem>Ȅ</CryptoSymbolItem>, value: "Ȅ" },
  { label: <CryptoSymbolItem>Ö</CryptoSymbolItem>, value: "Ö" },
  { label: <CryptoSymbolItem>Ǖ</CryptoSymbolItem>, value: "Ǖ" },
  { label: <CryptoSymbolItem>*</CryptoSymbolItem>, value: "*" },
];

export const currencySymbolCodes2 = [
  {
    label: <CurrencySymbolItem>&#1547;, Afghani</CurrencySymbolItem>,
    value: "&#1547;",
  },
  {
    label: <CurrencySymbolItem>&#8371;, Austral</CurrencySymbolItem>,
    value: "&#8371;",
  },
  {
    label: <CurrencySymbolItem>&#3647;, Baht</CurrencySymbolItem>,
    value: "&#3647;",
  },
  {
    label: <CurrencySymbolItem>&#8373;, Cedi</CurrencySymbolItem>,
    value: "&#8373;",
  },
  {
    label: <CurrencySymbolItem>&#8353;, Colón</CurrencySymbolItem>,
    value: "&#8353;",
  },
  {
    label: <CurrencySymbolItem>&#8354;, Cruzeiro</CurrencySymbolItem>,
    value: "&#8354;",
  },
  {
    label: <CurrencySymbolItem>&#1423;, Dram</CurrencySymbolItem>,
    value: "&#1423;",
  },
  {
    label: <CurrencySymbolItem>&#36;, Dollar</CurrencySymbolItem>,
    value: "&#36;",
  },
  {
    label: <CurrencySymbolItem>&#8363;, Dong</CurrencySymbolItem>,
    value: "&#8363;",
  },
  {
    label: <CurrencySymbolItem>&#8367;, Drachma</CurrencySymbolItem>,
    value: "&#8367;",
  },
  {
    label: <CurrencySymbolItem>&#8364;, Euro</CurrencySymbolItem>,
    value: "&#8364;",
  },
  {
    label: <CurrencySymbolItem>&#8352;, Euro Currency</CurrencySymbolItem>,
    value: "&#8352;",
  },
  {
    label: <CurrencySymbolItem>&#402;, Florin/Guilder</CurrencySymbolItem>,
    value: "&#402;",
  },
  {
    label: <CurrencySymbolItem>&#8355;, Franc</CurrencySymbolItem>,
    value: "&#8355;",
  },
  {
    label: <CurrencySymbolItem>&#8370;, Guarani</CurrencySymbolItem>,
    value: "&#8370;",
  },
  {
    label: <CurrencySymbolItem>&#8372;, Hryvnia</CurrencySymbolItem>,
    value: "&#8372;",
  },
  {
    label: <CurrencySymbolItem>&#8365;, Kip</CurrencySymbolItem>,
    value: "&#8365;",
  },
  {
    label: <CurrencySymbolItem>&#8382;, Lari</CurrencySymbolItem>,
    value: "&#8382;",
  },
  {
    label: <CurrencySymbolItem>&#8378;, Lira</CurrencySymbolItem>,
    value: "&#8378;",
  },
  {
    label: <CurrencySymbolItem>&#8380;, Manat</CurrencySymbolItem>,
    value: "&#8380;",
  },
  {
    label: <CurrencySymbolItem>&#8379;, Mark</CurrencySymbolItem>,
    value: "&#8379;",
  },
  {
    label: <CurrencySymbolItem>&#8357;, Mill</CurrencySymbolItem>,
    value: "&#8357;",
  },
  {
    label: <CurrencySymbolItem>&#8358;, Naira</CurrencySymbolItem>,
    value: "&#8358;",
  },
  {
    label: <CurrencySymbolItem>&#8359;, Peseta</CurrencySymbolItem>,
    value: "&#8359;",
  },
  {
    label: <CurrencySymbolItem>&#8369;, Peso</CurrencySymbolItem>,
    value: "&#8369;",
  },
  {
    label: <CurrencySymbolItem>&#65020;, Rial</CurrencySymbolItem>,
    value: "&#65020;",
  },
  {
    label: <CurrencySymbolItem>&#6107;, Riel</CurrencySymbolItem>,
    value: "&#6107;",
  },
  {
    label: <CurrencySymbolItem>&#8381;, Ruble</CurrencySymbolItem>,
    value: "&#8381;",
  },
  {
    label: <CurrencySymbolItem>&#8360;, Rupee</CurrencySymbolItem>,
    value: "&#8360;",
  },
  {
    label: <CurrencySymbolItem>&#8377;, Rupee</CurrencySymbolItem>,
    value: "&#8377;",
  },
  {
    label: <CurrencySymbolItem>&#8375;, Spesmilo</CurrencySymbolItem>,
    value: "&#8375;",
  },
  {
    label: <CurrencySymbolItem>&#8362;, Shekel</CurrencySymbolItem>,
    value: "&#8362;",
  },
  {
    label: <CurrencySymbolItem>&#2547;, Taka</CurrencySymbolItem>,
    value: "&#2547;",
  },
  {
    label: <CurrencySymbolItem>&#8376;, Tenge</CurrencySymbolItem>,
    value: "&#8376;",
  },
  {
    label: <CurrencySymbolItem>&#8366;, Tugrik</CurrencySymbolItem>,
    value: "&#8366;",
  },
  {
    label: <CurrencySymbolItem>&#165;, Yen/Yuan</CurrencySymbolItem>,
    value: "&#165;",
  },
  {
    label: <CurrencySymbolItem>&#8361;, Won</CurrencySymbolItem>,
    value: "&#8361;",
  },
  { label: <CryptoSymbolItem>ALGO, Algorand</CryptoSymbolItem>, value: "ALGO" },
  { label: <CryptoSymbolItem>AMP, Amp</CryptoSymbolItem>, value: "AMP" },
  {
    label: <CryptoSymbolItem>AVAX, Avalanche</CryptoSymbolItem>,
    value: "AVAX",
  },
  {
    label: <CryptoSymbolItem>BAT, Basic Attention Token</CryptoSymbolItem>,
    value: "BAT",
  },
  { label: <CryptoSymbolItem>BTC, Bitcoin</CryptoSymbolItem>, value: "BTC" },
  { label: <CryptoSymbolItem>ADA, Cardano</CryptoSymbolItem>, value: "ADA" },
  { label: <CryptoSymbolItem>CGLD, Celo</CryptoSymbolItem>, value: "CGLD" },
  {
    label: <CryptoSymbolItem>CLV, Clover Finance</CryptoSymbolItem>,
    value: "CLV",
  },
  {
    label: <CryptoSymbolItem>LINK, ChainLink</CryptoSymbolItem>,
    value: "LINK",
  },
  { label: <CryptoSymbolItem>COMP, Compound</CryptoSymbolItem>, value: "COMP" },
  { label: <CryptoSymbolItem>ATOM, Cosmos</CryptoSymbolItem>, value: "ATOM" },
  { label: <CryptoSymbolItem>DAI, Dai</CryptoSymbolItem>, value: "DAI" },
  {
    label: <CryptoSymbolItem>MANA, Decentraland</CryptoSymbolItem>,
    value: "MANA",
  },
  { label: <CryptoSymbolItem>DOGE, Dogecoin</CryptoSymbolItem>, value: "DOGE" },
  { label: <CryptoSymbolItem>DYDX, dYdX</CryptoSymbolItem>, value: "DYDX" },
  { label: <CryptoSymbolItem>EOS, EOS</CryptoSymbolItem>, value: "EOS" },
  { label: <CryptoSymbolItem>ETH, Ethereum</CryptoSymbolItem>, value: "ETH" },
  { label: <CryptoSymbolItem>FIL, Filecoin</CryptoSymbolItem>, value: "FIL" },
  { label: <CryptoSymbolItem>FLOW, Flow</CryptoSymbolItem>, value: "FLOW" },
  { label: <CryptoSymbolItem>GRT, The Graph</CryptoSymbolItem>, value: "GRT" },
  { label: <CryptoSymbolItem>LTC, Litecoin</CryptoSymbolItem>, value: "LTC" },
  { label: <CryptoSymbolItem>MKR, Maker</CryptoSymbolItem>, value: "MKR" },
  { label: <CryptoSymbolItem>XMR, Monero</CryptoSymbolItem>, value: "XMR" },
  { label: <CryptoSymbolItem>DOT, Polkadot</CryptoSymbolItem>, value: "DOT" },
  {
    label: <CryptoSymbolItem>MATIC, Polygon</CryptoSymbolItem>,
    value: "MATIC",
  },
  {
    label: <CryptoSymbolItem>QNT, Quant Network</CryptoSymbolItem>,
    value: "SHIB",
  },
  {
    label: <CryptoSymbolItem>SHIB, SHIBA INU</CryptoSymbolItem>,
    value: "SHIB",
  },
  { label: <CryptoSymbolItem>SOL, Solana</CryptoSymbolItem>, value: "SOL" },
  { label: <CryptoSymbolItem>XLM, Stellar</CryptoSymbolItem>, value: "XLM" },

  { label: <CryptoSymbolItem>XTZ, Tezos</CryptoSymbolItem>, value: "Tezos" },
  { label: <CryptoSymbolItem>UNI, Uniswap</CryptoSymbolItem>, value: "UNI" },
  { label: <CryptoSymbolItem>USDT, Tether</CryptoSymbolItem>, value: "USDT" },
  { label: <CryptoSymbolItem>ZEC, Zcash</CryptoSymbolItem>, value: "ZEC" },
];

export default currencySymbolCodes;
