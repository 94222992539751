import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import "./styles.scss";
import proDiamond from "./../../images/186300.png";
import createStripeSession from "./../../utils/stripe/createStripeSession";
import { setLoaderState } from "../../actions/actionCreators";
import store from "../../store";
import stripePriceIds from "./../../utils/stripe/stripetiers";
import stockTrader from "./../../images/stock_trader.jpg";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
export const UpdateSubscriptionModalComponent = (props) => {
  const location = useLocation();
  const history = useHistory();
  const showIN = props.open;
  const blur = props.blur;
  const tierText = props.tierText;
  const featureIn = props.featureToShow;
  const allData = props.data.allData;
  const customerId = allData && allData.globalSettings.stripeId;
  const currentURL = window.location;
  const success_url = currentURL.origin + "/Dashboard";
  const cancel_url = currentURL.href;
  //const featureToShow = props.featureToShow;
  const [modalIsOpen, setIsOpen] = useState(showIN);
  useEffect(() => {
    setIsOpen(showIN);
  }, [showIN]);
  /*   const heightToAdjust = 900;
  const widthToAdjust = 1680;
  let scaleAmount = 1;

  const resizeFunction = (scaleAmountIn) => {
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;

    if (width < widthToAdjust || height < heightToAdjust) {
      scaleAmountIn = 0.8;
      document.body.style.transform = `scale(${scaleAmountIn})`;
    } else {
      scaleAmountIn = 1;
      document.body.style.transform = `scale(${scaleAmountIn})`;
    }
    document.body.style.width = `${100 * (1 / scaleAmountIn)}%`;
    document.body.style.height = `${100 * (1 / scaleAmountIn)}%`;
    document.body.style["transform-origin"] = `0 0`;
  };
 */

  const closeModalOnlyClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      props.setSubscriptionModalOpen(false);
      props.setshowUpgradeModal(false);
      localStorage.removeItem("upgradeModalOpen");
    }, 400);

    /*     resizeFunction(scaleAmount);
    window.onresize = function () {
      resizeFunction(scaleAmount);
    }; */
  };
  const proFeatures = [
    "2 portfolios",
    "Unlimited entries",
    "Unlimited imports",
    "Advanced reports",
    "Customizable dashboard",
    "5 pictures per trade",
    "Calendar",
    "Track open positions",
    "Save global filters",
    "Commissions/Fees",
  ];
  const masterFeatures = [
    "Everything in Pro, plus:",
    "5 portfolios",
    "Automated importing",
    "QuantumQuery",
    "AI-powered insights",
    "Advanced Tools",
    "10 pictures per trade",
    "Colored portfolios",
    "Slippage",
    "Enhanced support",
  ];
  const ultimateFeatures = [
    "Everything in Master, plus:",
    "10 portfolios",
    "More portfolios with automated importing",
    "QuantumQuery Advanced",
    "25 pictures per trade",
    "Integration request priority",
    "24/7 priority support",
  ];
  const proFeatureComponent = () => {
    const component = proFeatures.map((feature) =>
      feature === "Everything in Free, plus:" ? (
        <div key={uuidv4()} className="upgradefeaturelistheader">
          Everything in Free, plus:
        </div>
      ) : (
        <ul
          key={uuidv4()}
          className="upgradefeaturelistitem"
          style={{
            fontSize: featureIn === feature ? "1em" : "0.9em",
            color: featureIn === feature ? "#91a5c8" : "#8193b3",
            fontWeight: featureIn === feature ? 500 : 300,
          }}
        >
          {feature}
        </ul>
      )
    );
    return component;
  };
  const masterFeatureComponent = () => {
    const component = masterFeatures.map((feature) =>
      feature === "Everything in Pro, plus:" ? (
        <div key={uuidv4()} className="upgradefeaturelistheader">
          Everything in Pro, plus:
        </div>
      ) : (
        <ul
          key={uuidv4()}
          className="upgradefeaturelistitem"
          style={{
            fontSize: featureIn === feature ? "1em" : "0.9em",
            color: featureIn === feature ? "#91a5c8" : "#8193b3",
            fontWeight: featureIn === feature ? 500 : 300,
          }}
        >
          {feature}
        </ul>
      )
    );
    return component;
  };
  const ultimateFeatureComponent = () => {
    const component = ultimateFeatures.map((feature) =>
      feature === "Everything in Master, plus:" ? (
        <div key={uuidv4()} className="upgradefeaturelistheader">
          Everything in Master, plus:
        </div>
      ) : (
        <ul
          key={uuidv4()}
          className="upgradefeaturelistitem"
          style={{
            fontSize: featureIn === feature ? "1em" : "0.9em",
            color: featureIn === feature ? "#91a5c8" : "#8193b3",
            fontWeight: featureIn === feature ? 500 : 300,
          }}
        >
          {feature}
        </ul>
      )
    );
    return component;
  };
  return (
    <Modal
      ariaHideApp={false}
      id={"upgradetoPro"}
      className={"upgradetoPro"}
      isOpen={modalIsOpen}
      closeTimeoutMS={200}
      contentLabel="Trade"
      onRequestClose={closeModalOnlyClose}
      shouldCloseOnOverlayClick={
        location.pathname === "/Streak-Odds-Calculator" ||
        location.pathname === "/Drawdown-RunUp" ||
        location.pathname === "/Risk-Management" ||
        location.pathname === "/KPIs" ||
        location.pathname === "/Comprehensive" ||
        location.pathname === "/Manual-Trade-Entry" ||
        location.pathname === "/Deposits-Withdrawals" ||
        location.pathname === "/Integrations" ||
        location.pathname === "/Calendar" ||
        location.pathname === "/Monte-Carlo-Simulator" ||
        location.pathname === "/Compare-&-Conquer" ||
        location.pathname === "/Quantum-Query" ||
        location.pathname === "/Position-Size-Calculator"
          ? blur
            ? false
            : true
          : true
      }
      overlayClassName={blur ? "OverlayModalBlurred" : "OverlayModal"}
    >
      <h3 className="modalSymbolHeader7">
        {tierText === "ultimate"
          ? "UPGRADE TO ULTIMATE"
          : tierText === "master"
          ? "UPGRADE TO MASTER"
          : "UPGRADE TO PRO"}
      </h3>
      <span className="upgradesubscriptionheader">
        <img
          alt=""
          src={proDiamond}
          style={{ margin: "-2px 10px 0px 0px", width: 22, height: 22 }}
        ></img>
        <span className="upgradesubscriptionsubheader">
          {tierText === "ultimate"
            ? "Unlock these additional features with the Ultimate Plan"
            : tierText === "master"
            ? "Unlock these additional features with the Master Plan"
            : "Unlock these additional features with the Pro Plan"}
        </span>
        <img
          alt=""
          src={proDiamond}
          style={{ margin: "0px 0px 0px 10px", width: 22, height: 22 }}
        ></img>
      </span>
      <div
        className={
          tierText === "ultimate"
            ? "upgradefeaturelistwrapperultimate"
            : tierText === "master"
            ? "upgradefeaturelistwrappermaster"
            : "upgradefeaturelistwrapper"
        }
      >
        {tierText === "ultimate"
          ? ultimateFeatureComponent()
          : tierText === "master"
          ? masterFeatureComponent()
          : proFeatureComponent()}
      </div>
      <div className="upgradebottomlefttextwrapper">
        <span className="upgradebottomlefttextfirst">
          For just{" "}
          <span className="upgradebottomlefttextbilledsub">
            **Billed annually
          </span>
          <span style={{ color: "#fbd665" }}>
            {tierText === "ultimate"
              ? "$51/month"
              : tierText === "master"
              ? "$29/month"
              : "$14/month"}
          </span>
          ,
        </span>
        <div className="upgradebottomlefttextbilledrest">
          {tierText === "ultimate"
            ? "you can get access to TradeFuse's Ultimate features"
            : tierText === "master"
            ? "you can get access to TradeFuse's Master features"
            : "you can get access to TradeFuse's Pro features"}
        </div>
        <div
          onClick={() => {
            closeModalOnlyClose();
            history.push({
              pathname: "/Manage-Account",
              state: {
                account: "none",
                subscription: "block",
                actions: "none",
              },
            });
          }}
          className="upgradebottomlefttextsecond"
        >
          Explore pricing
        </div>
      </div>
      <div className="upgrademodalpicshadow">
        <img
          alt=""
          className="upgrademodalstocktraderpic"
          src={stockTrader}
        ></img>
      </div>

      <button
        id="upgrade-prompt-button-pro"
        onClick={async () => {
          closeModalOnlyClose();
          store.dispatch(setLoaderState({ loading: true }));
          const session = await createStripeSession(
            customerId,
            tierText === "ultimate"
              ? stripePriceIds.annualultimate
              : tierText === "master"
              ? stripePriceIds.annualmaster
              : stripePriceIds.annual,
            success_url,
            cancel_url,
            history
          );
          store.dispatch(setLoaderState({ loading: false }));
          window.location.replace(session.url);
        }}
      >
        Upgrade
      </button>
    </Modal>
  );
};
export default UpdateSubscriptionModalComponent;
