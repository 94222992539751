export const getEntryExitLots = (multiExecutionIn, exitExecutionIn) => {
  const multiExecution = multiExecutionIn;
  const exitExecution = exitExecutionIn;
  const entryExecsd = multiExecution;
  let entryLots = 0;
  entryExecsd.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
  exitLots = Math.round(exitLots * 1e10) / 1e10;
  return { entryLots, exitLots };
};
export default getEntryExitLots