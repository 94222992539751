import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import {
  deleteNOTtNONEVisible,
  isCheckedFunction,
} from "./deleteTradesFunctions";
import $ from "jquery";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import DeleteButton from "../../components/deleteButton";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import CreatableSelect from "react-select/creatable";
import dropdownStyles from "./../../functions/dropdownStyles";
import { theme } from "./../../components/selectTheme";

const whatToCompareOptions = [
  {
    label: "Strategies",
    value: "strategies",
    prop: "strategy",
    single: "Strategy",
  },
  {
    label: "Mistakes",
    value: "mistakes",
    prop: "selectedMistake",
    single: "Mistake",
  },
  { label: "Tags", value: "tags", prop: "tags", single: "Tag" },
  {
    label: "Emotional States",
    value: "emotional states",
    prop: "selectedEmotion",
    single: "Emotional State",
  },
  {
    label: "Physical States",
    value: "physical states",
    prop: "selectedPhysical",
    single: "Physical State",
  },
  {
    label: "Time Frames",
    value: "time frames",
    prop: "selectedTimeframe",
    single: "Time Frame",
  },
  {
    label: "Confidences",
    value: "confidences",
    prop: "selectedConfidence",
    single: "Confidence",
  },
  {
    label: "Market States",
    value: "market states",
    prop: "selectedMarket",
    single: "Market State",
  },
];

const TagTradesComponent = (props) => {
  const allData = props.userData;
  const createdPortfolios = allData.allData.createdPortfolios;
  const chosenPortfolios = allData.allData.chosenPortfolios;
  const dropDownItems = allData.filterLists;

  const history = useHistory();
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();
  const firstUpdate = useRef(true);
  const [comparerType, setComparerType] = useState("");
  const [method, setMethod] = useState("");
  const [selectOptions, setselectOptions] = useState([]);
  const [isDisabled, setisDisabled] = useState(true);

  const validation = useCallback(() => {
    if (!comparerType || !method) {
      setisDisabled(true);
    } else {
      setisDisabled(false);
    }
  }, [comparerType, method]);

  useEffect(() => {
    validation();
  }, [comparerType, validation]);
  useEffect(() => {
    validation();
  }, [method, validation]);
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType = localStorage.getItem("deletedTrade");
      if (addedType === "Tagged") {
        showConfirmedActionButton("Trades Tagged!");
        localStorage.removeItem("deletedTrade");
      }
    }
  }, []);
  const handleCloseDeleteTrades = () => {
    $("#tag-trades-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    setselectOptions([]);
    setComparerType("");
    setMethod("");
  };
  const handleMethodChange = (newValue) => {
    setMethod(newValue);
  };

  const handleTypeChange = (newValue) => {
    setComparerType(newValue);
    if (!newValue) {
      handleMethodChange("");
    }
    if (newValue !== comparerType) {
      handleMethodChange("");
    }
    const inLabel = !newValue ? "" : newValue.label;

    switch (inLabel) {
      case "Strategies":
        setselectOptions(dropDownItems.strategy);
        break;
      case "Mistakes":
        setselectOptions(dropDownItems.mistake);
        break;
      case "Tags":
        setselectOptions(dropDownItems.tags);
        break;
      case "Emotional States":
        setselectOptions(dropDownItems.emotion);
        break;
      case "Physical States":
        setselectOptions(dropDownItems.physical);
        break;
      case "Time Frames":
        setselectOptions(dropDownItems.timeframeWithDefault);
        break;
      case "Confidences":
        setselectOptions(dropDownItems.confidenceWithDefault);
        break;
      case "Market States":
        setselectOptions(dropDownItems.marketStateWithDefault);
        break;
      default:
        break;
    }
  };

  const handleTagTrades = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    const isDeleteChecked = document.getElementById("deleteTradeButton");
    const isTagChecked = document.getElementById("tagTradeButton");
    const isShareChecked = document.getElementById("shareTradeButton");
    const isAccessorChecked = document.querySelectorAll("#boxTradeChecks");
    const isEntryIDSChecked = document.querySelectorAll("#entryidvalue");
    $(`.history-table > table > tbody > tr`).css({
      "box-shadow": "",
      backgroundColor: "",
    });
    deleteNOTtNONEVisible(isDeleteChecked, isTagChecked, isShareChecked);
    var entryidsforlambda = [];
    var h = 0;

    for (var i = 0, j = isAccessorChecked.length; i < j; i++) {
      if (isAccessorChecked[i].checked === true) {
        entryidsforlambda[h] = isEntryIDSChecked[i].innerHTML;
        h += 1;
      } else {
        continue;
      }
    }
    let temparr = createdPortfolios;

    for (var ie = 0, je = createdPortfolios.length; ie < je; ie++) {
      let currentData = createdPortfolios[ie].entries;
      if (createdPortfolios[ie].name === "Default") {
      } else {
        let tempAll = createdPortfolios[ie].name;
        temparr = temparr.filter((x) => x.name !== tempAll);
        for (let ii in entryidsforlambda) {
          // need to create array of pictures to delete as well
          const sliced = currentData.slice();
          let newTrade = sliced.filter(
            (entry) => entry.entryId === entryidsforlambda[ii]
          );
          if (!newTrade.length) continue;
          if (
            newTrade[0].entry.orderType === "Deposit" ||
            newTrade[0].entry.orderType === "Withdrawal" ||
            newTrade[0].entry.orderType === "Funding Payment" ||
            newTrade[0].entry.orderType === "Commit" ||
            newTrade[0].entry.orderType === "Approval" ||
            newTrade[0].entry.orderType === "Wrap" ||
            newTrade[0].entry.orderType === "Self"
          ) {
            continue;
          } else {
            if (
              comparerType.prop === "selectedTimeframe" ||
              comparerType.prop === "selectedConfidence" ||
              comparerType.prop === "selectedMarket"
            ) {
              newTrade[0].entry[comparerType.prop] = method.label;
            } else {
              newTrade[0].entry[comparerType.prop] = [
                ...newTrade[0].entry[comparerType.prop],
                ...method.map((m) => m.label),
              ];
            }

            // ------------

            // This is where the trade is actually removed
            let replaceIndex = currentData
              .map((item) => item.entryId)
              .indexOf(entryidsforlambda[ii]);
            if (replaceIndex !== -1) {
              currentData[replaceIndex] = newTrade[0];
            }
          }
        }
        const portfolio = {
          name: createdPortfolios[ie].name,
          assetClasses: createdPortfolios[ie].assetClasses,
          settings: createdPortfolios[ie].settings,
          entries: currentData,
        };
        temparr = temparr.concat(portfolio);
      }
    }
    const uploadData = {
      chosenPortfolios: chosenPortfolios,
      createdPortfolios: temparr,
      globalSettings: allData.allData.globalSettings,
      version: Number(allData.allData.version) + 1,
      sharedTrades: allData.allData.sharedTrades,
    };
    $("#tag-trades-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    const S3Data = {
      data: uploadData,
      userId: user.sub,
    };
    await putUserData(S3Data, history);
    allGlobalData.updateAllData(uploadData);
    isCheckedFunction(false, isAccessorChecked);
    store.dispatch(setLoaderState({ loading: false }));
    localStorage.setItem("deletedTrade", "Tagged");
    history.push("/BlankPage");
    history.goBack();
  };
  return (
    <div id="tag-trades-class">
      <span className="deletetradesheading">Tag Entries</span>
      <label className="tagselectionfields2">
        {" "}
        What do you want to tag?
        <CreatableSelect
          styles={dropdownStyles}
          isClearable
          value={comparerType}
          onChange={handleTypeChange}
          theme={theme}
          options={whatToCompareOptions}
        />{" "}
      </label>
      {comparerType && (
        <label className="tagselectionfields22 zindex1">
          {`Select or create a new ${comparerType.single}:`}
          {comparerType.prop === "selectedTimeframe" ||
          comparerType.prop === "selectedConfidence" ||
          comparerType.prop === "selectedMarket" ? (
            <CreatableSelect
              styles={dropdownStyles}
              isClearable
              value={method}
              onChange={handleMethodChange}
              theme={theme}
              options={selectOptions.sort()}
            />
          ) : (
            <CreatableSelect
              isMulti
              styles={dropdownStyles}
              isClearable
              value={method}
              onChange={handleMethodChange}
              theme={theme}
              options={selectOptions.sort()}
            />
          )}
        </label>
      )}
      <button
        className="deletetradesclosebutton"
        onClick={handleCloseDeleteTrades}
      >
        <DeleteButton />
      </button>
      <button className="tagtradenobutton" onClick={handleCloseDeleteTrades}>
        Cancel
      </button>
      <button
        className="tagtradesubmitbutton"
        disabled={isDisabled}
        onClick={handleTagTrades}
      >
        Tag Entries
      </button>
    </div>
  );
};

export default TagTradesComponent;
