export const addNewEntriesandIds = (allDataRefIN, portfolio, ids, trades) => {
  // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA
  let allDataRef = allDataRefIN;
  let temparr = allDataRef.createdPortfolios.slice();
  let tempName = portfolio.name;
  if (tempName === "Default") {
  } else {
    // filter portfolios to only deal with the one we need
    temparr = temparr.filter((x) => x.name !== tempName);

    // Add ids
    let portfolioSettings = portfolio.settings;
    const oldIds = !portfolioSettings?.linkedBrokerInfo?.ids
      ? []
      : portfolioSettings?.linkedBrokerInfo?.ids;
    const newIds = [...oldIds, ...ids];
    const uniqueIds = [...new Set(newIds)]; // gets rid of duplicates
    portfolioSettings.linkedBrokerInfo.ids = uniqueIds;
    const portfolioedit = {
      name: portfolio.name,
      assetClasses: portfolio.assetClasses,
      settings: portfolioSettings,
      entries: trades,
    };

    // then add back to array of portfolios
    temparr = temparr.concat(portfolioedit);
  }

  return temparr;
};
export default addNewEntriesandIds;
