import React from "react";
import "../../../DateTime.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  GetFUNCTIONFormattedDate,
  GetFUNCTIONFormattedDatOTHER,
} from "./getFormattedDate";
import { withRouter } from "react-router-dom";
import {
  getFilteredList,
  setLoaderState,
} from "./../../../actions/actionCreators";
import store from "./../../../store";
import $ from "jquery";
import DeleteButton from "./../../deleteButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { DataContext } from "../../../DataContext";
import { DatePicker } from "@mui/x-date-pickers";
var buffer = {};

class CustomDateRangeComponent extends React.Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
    };
  }
  handleSubmit = async () => {
    const reduxFilters = store.getState().defaultfilterValue.filtervalue;
    const filterProp = !this.props.allData.portfolio.settings.filters
      ? {
          dateRangeFilter: null,
          hourRangeFilter: null,
          orderTypeFilter: null,
          symbolFilter: null,
          strategyFilter: null,
          emotionalStateFilter: null,
          physicalStateFilter: null,
          mistakeFilter: null,
          timeFrameFilter: null,
          confidenceFilter: null,
          marketStateFilter: null,
          dayOfWeekFilter: null,
          monthFilter: null,
          assetClassFilter: null,
          tagsFilter: null,
          yearFilter: null,
        }
      : reduxFilters;
    localStorage.setItem("filtersAreRunning", "yes");
    localStorage.setItem("filters", JSON.stringify(filterProp));
  };

  handleExitClick = () => {
    store
      .dispatch(getFilteredList("dateRangeFilter", null))
      .then(() => store.dispatch(setLoaderState({ loading: false })));
    $("#choose-date-range").fadeOut(300);
    $("#overlay").fadeOut(300);
    document.getElementById("noclick").style.display = "none";
    document.getElementById("select-date-red").style.display = "none";
    document.getElementById("select-date-red2").style.display = "none";
  };

  handleendDateChange = (endDate) => this.setState({ endDate });

  handlestartDateChange = (startDate) => {
    this.setState({ startDate });
  };

  handleSubmitClick = () => {
    const savecheck =
      this.props.allData && this.props.allData.portfolio.settings.savefilters;
    if (this.state.startDate === "" || this.state.endDate === "") {
      if (this.state.startDate === "") {
        document.getElementById("select-date-red").style.display = "inline";
        //$(".customDRinputs").focus();
      }
      if (this.state.endDate === "") {
        document.getElementById("select-date-red2").style.display = "inline";
        //$(".customDRinputs").focus();
      }
      return false;
    } else {
      buffer[0] = GetFUNCTIONFormattedDate(this.state.startDate);
      buffer[1] = GetFUNCTIONFormattedDatOTHER(this.state.endDate);
      buffer[2] = "";
      store.dispatch(getFilteredList("dateRangeFilter", buffer));
      store.dispatch(setLoaderState({ loading: false }));
      document.getElementById("select-date-red").style.display = "none";
      document.getElementById("select-date-red2").style.display = "none";
      $("#choose-date-range").fadeOut(300);
      $("#overlay").fadeOut(300);
      document.getElementById("noclick").style.display = "none";
      this.props.history.push("/BlankPage");
      this.props.history.goBack();
      savecheck && this.handleSubmit();
    }
  };

  render() {
    return (
      <div id="choose-date-range">
        <span className="choosedaterangeheading">Custom Range</span>
        <button
          className="choosedaterangeclosebutton"
          onClick={this.handleExitClick}
        >
          <DeleteButton />
        </button>
        <div className="main-labels-CDR">
          <div id="select-date-red">**Select Date</div>
          <label>
            {"Start Date"}
            {/*             <DateTime
              id="startDatecustoms"
              className="customDRinputs"
              value={this.state.startDate}
              onChange={this.handlestartDateChange}
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
            /> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    placeholder="Required"
                    className="text-input-boxes required"
                  />
                )}
                inputFormat="MM/DD/YYYY"
                value={this.state.startDate}
                onChange={this.handlestartDateChange}
              />
            </LocalizationProvider>
          </label>
        </div>
        <div className="main-labels-CDR main-labels-CDR-moveup">
          <div id="select-date-red2">**Select Date</div>
          <label>
            {"End Date"}
            {/*             <DateTime
              id="endDatecustoms"
              className="customDRinputs"
              value={this.state.endDate}
              onChange={this.handleendDateChange}
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
            /> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    placeholder="Required"
                    className="text-input-boxes required"
                  />
                )}
                componentsProps={{
                  actionBar: {
                    actions: ["today"],
                  },
                }}
                inputFormat="MM/DD/YYYY"
                value={this.state.endDate}
                onChange={this.handleendDateChange}
              />
            </LocalizationProvider>
          </label>
        </div>
        <button
          className="choosedaterangecancelbutton"
          onClick={this.handleExitClick}
        >
          Cancel
        </button>
        <button
          className="choosedaterangesubmitbutton"
          onClick={this.handleSubmitClick}
        >
          Apply
        </button>
      </div>
    );
  }
}

export default withRouter(CustomDateRangeComponent);
