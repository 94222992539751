export const getOptionsStrategy = (multiExecutionIn, defaultVal, orderType) => {
  let strategy = defaultVal;
  let multiExecutionnotSorted = multiExecutionIn;
  multiExecutionnotSorted.sort(function (a, b) {
    var c = new Date(a.startDateTime);
    var d = new Date(b.startDateTime);
    return c - d;
  });
  // only consider the first 6 executions?
  const multiExecution = multiExecutionnotSorted.slice(0, 6);
  let entryLots = 0;
  multiExecutionnotSorted.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  if (entryLots % 4 === 0) {
    if (orderType === "Multi-Leg Strategy") {
      // if total contracts are divisble by 4
      const sellCall = multiExecution.filter((x) => x.legType === "Short Call");
      const buyCall = multiExecution.filter((x) => x.legType === "Long Call");
      const sellPut = multiExecution.filter((x) => x.legType === "Short Put");
      const buyPut = multiExecution.filter((x) => x.legType === "Long Put");
      if (
        buyCall.length &&
        buyPut.length &&
        sellCall.length &&
        sellPut.length
      ) {
        if (
          Number(buyCall[0].strikePrice) > Number(sellCall[0].strikePrice) &&
          Number(buyPut[0].strikePrice) < Number(sellPut[0].strikePrice)
        ) {
          strategy = "Iron Condor";
        } else if (
          Number(buyCall[0].strikePrice) === Number(buyPut[0].strikePrice) &&
          Number(sellCall[0].strikePrice) === Number(sellPut[0].strikePrice)
        ) {
          strategy = "Iron Butterfly";
        }
      }
      if (
        sellCall.length >= 1 &&
        buyCall.length >= 1 &&
        sellPut.length === 0 &&
        buyPut.length === 0
      ) {
        // Extract Lower Buy Call Strike
        let lowerStrike = 0;
        buyCall.forEach((option) => {
          if (option.strikePrice < lowerStrike) {
            lowerStrike = option.strikePrice;
          }
        });
        let higherStrike = 0;
        buyCall.forEach((option) => {
          if (option.strikePrice > higherStrike) {
            higherStrike = option.strikePrice;
          }
        });
        if (
          higherStrike > sellCall[0].strikePrice &&
          sellCall[0].strikePrice > lowerStrike
        ) {
          strategy = "Long Butterfly Spread w/ Calls";
        } else {
          strategy = "Short Butterfly Spread w/ Calls";
        }
      }

      if (
        sellPut.length >= 1 &&
        buyPut.length >= 1 &&
        sellCall.length === 0 &&
        buyCall.length === 0
      ) {
        // Extract Lower Buy Put Strike
        let lowerStrike = 0;
        buyPut.forEach((option) => {
          if (option.strikePrice < lowerStrike) {
            lowerStrike = option.strikePrice;
          }
        });
        let higherStrike = 0;
        buyPut.forEach((option) => {
          if (option.strikePrice > higherStrike) {
            higherStrike = option.strikePrice;
          }
        });
        if (
          higherStrike > sellPut[0].strikePrice &&
          sellPut[0].strikePrice > lowerStrike
        ) {
          strategy = "Long Butterfly Spread w/ Puts";
        } else {
          strategy = "Short Butterfly Spread w/ Puts";
        }
      }
    }
  } else if (entryLots % 2 === 0) {
    if (orderType === "Multi-Leg Strategy") {
      const expirDateFirstPre = new Date(multiExecution[0].expirationDate);
      const expirDateSecondPre = new Date(multiExecution[1].expirationDate);

      const allExpirationDatesEqual =
        String(expirDateSecondPre) === String(expirDateFirstPre) ? true : false;
      if (allExpirationDatesEqual) {
        if (
          multiExecution[0].legType === "Long Call" ||
          multiExecution[1].legType === "Long Call"
        ) {
          if (
            multiExecution[0].legType === "Short Call" ||
            multiExecution[1].legType === "Short Call"
          ) {
            const sellCall = multiExecution.filter(
              (x) => x.legType === "Short Call"
            );
            const buyCall = multiExecution.filter(
              (x) => x.legType === "Long Call"
            );
            if (
              Number(sellCall[0].strikePrice) > Number(buyCall[0].strikePrice)
            ) {
              strategy = "Call Debit Spread";
            } else if (
              Number(sellCall[0].strikePrice) < Number(buyCall[0].strikePrice)
            ) {
              strategy = "Call Credit Spread";
            }
          } else if (
            multiExecution[0].legType === "Long Put" ||
            multiExecution[1].legType === "Long Put"
          ) {
            const buyPut = multiExecution.filter(
              (x) => x.legType === "Long Put"
            );
            const buyCall = multiExecution.filter(
              (x) => x.legType === "Long Call"
            );
            if (
              Number(buyPut[0].strikePrice) === Number(buyCall[0].strikePrice)
            ) {
              strategy = "Long Straddle";
            } else if (
              Number(buyPut[0].strikePrice) < Number(buyCall[0].strikePrice)
            ) {
              strategy = "Long Strangle";
            } else if (
              Number(buyPut[0].strikePrice) > Number(buyCall[0].strikePrice)
            ) {
              strategy = "Inverted Long Strangle";
            }
          }
        } else if (
          multiExecution[0].legType === "Short Put" ||
          multiExecution[1].legType === "Short Put"
        ) {
          if (
            multiExecution[0].legType === "Long Put" ||
            multiExecution[1].legType === "Long Put"
          ) {
            const sellPut = multiExecution.filter(
              (x) => x.legType === "Short Put"
            );
            const buyPut = multiExecution.filter(
              (x) => x.legType === "Long Put"
            );
            if (
              Number(sellPut[0].strikePrice) > Number(buyPut[0].strikePrice)
            ) {
              strategy = "Put Credit Spread";
            } else if (
              Number(sellPut[0].strikePrice) < Number(buyPut[0].strikePrice)
            ) {
              strategy = "Put Debit Spread";
            }
          } else if (
            multiExecution[0].legType === "Short Call" ||
            multiExecution[1].legType === "Short Call"
          ) {
            const sellPut = multiExecution.filter(
              (x) => x.legType === "Short Put"
            );
            const sellCall = multiExecution.filter(
              (x) => x.legType === "Short Call"
            );
            if (
              Number(sellPut[0].strikePrice) === Number(sellCall[0].strikePrice)
            ) {
              strategy = "Short Straddle";
            } else if (
              Number(sellPut[0].strikePrice) < Number(sellCall[0].strikePrice)
            ) {
              strategy = "Short Strangle";
            } else if (
              Number(sellPut[0].strikePrice) > Number(sellCall[0].strikePrice)
            ) {
              strategy = "Inverted Short Strangle";
            }
          }
        }
      } else {
        strategy = "Calendar Spread";
      }
    }
  } else if (multiExecution.length === 3) {
  } else if (multiExecution.length === 6) {
  } else if (multiExecution.length === 1) {
    strategy = defaultVal;
  }
  return strategy;
};
export default getOptionsStrategy;
