import getColorPoint from "../../functions/includeColor";

export const getEfficiencyBarColor = (efficiency) => {
  let color;
  const efficiencyTimes100 = Math.abs(efficiency);
  if (efficiencyTimes100 < 20) {
    color = getColorPoint(
      "rgb(139, 43, 43)",
      "rgb(238, 73, 73)",
      efficiencyTimes100 / 20,
      "hex"
    );
  } else if (efficiencyTimes100 >= 20 && efficiencyTimes100 < 40) {
    color = getColorPoint(
      "rgb(238, 73, 73)",
      "#ff9735",
      (efficiencyTimes100 - 20) / 20,
      "hex"
    );
  } else if (efficiencyTimes100 >= 40 && efficiencyTimes100 < 60) {
    color = getColorPoint(
      "#ff9735",
      "#e1f967",
      (efficiencyTimes100 - 40) / 20,
      "hex"
    );
  } else if (efficiencyTimes100 >= 60 && efficiencyTimes100 < 80) {
    color = getColorPoint(
      "#e1f967",
      "#49f875",
      (efficiencyTimes100 - 60) / 20,
      "hex"
    );
  } else {
    color = getColorPoint(
      "#49f875",
      "#00b82e",
      (efficiencyTimes100 - 80) / 20,
      "hex"
    );
  }
  return color;
};
export default getEfficiencyBarColor;
