export const theme = (theme) => ({
  ...theme,
  borderRadius: 5,
  colors: {
    ...theme.colors,
    primary25: "#2e3a48",
    primary50: "#2e3a48",
    primary: "#deebf7",
    neutral0: "#1a2028",
    neutral5: "#222a35",
    neutral10: "#2e3a48",
    neutral20: "#14181e",
    neutral30: "#14181e",
    neutral40: "#fbd665",
    neutral80: "#fbd665",
  },
});
export default theme