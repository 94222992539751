import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import store from "./store";
import ViewHistory from "./pages/Logbook";
import Dashboard from "./pages/Dashboard";
import AddNewTrade from "./pages/Manual-Trade-Entry";
import YearlyReport from "./pages/Yearly-Report";
import BreakEvensReport from "./pages/Break-Evens-Report";
import DailyReport from "./pages/Daily-Report";
import StreakProbabilityCalculator from "./pages/Streak-Odds-Calculator";
import DrawdownReport from "./pages/Drawdown-RunUp";
import ErrorFound from "./pages/Error-Found";
import PositionSizeCalculator from "./pages/Position-Size-Calculator";
import EfficiencyReport from "./pages/Efficiency-Report";
import FundReport from "./pages/Fund-Report";
import HoldingTimeReport from "./pages/Holding-Time-Report";
import Integrations from "./pages/Integrations";
import DepositWithdrawal from "./pages/Deposits-Withdrawals";
import Settings from "./pages/Settings";
import LossesReport from "./pages/Losses-Report";
import MAEandMFEReport from "./pages/MAE-and-MFE-Report";
import MoneyManagementReport from "./pages/Risk-Management";
import MonthlyReport from "./pages/Monthly-Report";
import OverallReport from "./pages/Comprehensive";
import PerformanceRatiosReport from "./pages/KPIs";
import Simulator from "./pages/Monte-Carlo-Simulator";
import WeeklyReport from "./pages/Weekly-Report";
import WinsReport from "./pages/Wins-Report";
import Calendar from "./pages/Calendar";
import Comparer from "./pages/Compare-&-Conquer";
import BlankPage from "./pages/BlankPage";
import AIReport from "./pages/AI-Insights";
import PrivateRoute from "../src/components/private-route";
import { DataProvider } from "./DataContext";
import { SharedTradeProvider } from "./SharedTradeContext";
import ViewTrade from "./pages/View-Trade-Page";
import QuantumQuery from "./pages/Quantum-Query";

import ManageAccount from "./pages/Manage-Account";
import Auth0ProviderWithHistory from "./auth0-provider-with-history";
import $ from "jquery";
import removeAllRobinhoodTokens from "./functions/robinhood/removeRobinhoodTokens";
var ElementQueries = require("css-element-queries/src/ElementQueries");
const App = () => {
  // ------- Intialize Element Queries -------
  ElementQueries.init();

  let scaleAmount = 1;

  // ------- DELETE CREDENTIALS AND RESET initalLogin after 1 Hour of user inactivity -------
  function deleteAfterHourOfInactivity() {
    // Remove initialLogin and broker Tokens after 1 Hour
    localStorage.setItem("initialLogin", "yes");
    removeAllRobinhoodTokens();
    // and any others
  }

  // Set a timer to call the function after 1 Hour of inactivity
  let inactivityTimer = setTimeout(deleteAfterHourOfInactivity, 60 * 60 * 1000); // 1 Hour

  // Reset the timer if there is any user activity
  window.addEventListener("mousemove", function () {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(deleteAfterHourOfInactivity, 60 * 60 * 1000); // 1 Hour
  });

  window.addEventListener("beforeunload", function (event) {
    // This function will be executed before the page is unloaded
    // You can perform any necessary tasks here
    // Make sure to include a return statement to display the browser's default confirmation message
    //event.returnValue = "";
  });

  window.addEventListener("unload", function () {
    // This function will be executed when the page is unloaded

    // Call your desired function here
    myFunction();
  });

  function myFunction() {
    // Your function code here
    localStorage.removeItem("canMetaTraderOpen");
    localStorage.removeItem("initialLoad");
    localStorage.removeItem("autoiterationMessage");
    localStorage.removeItem("connectingBroker");
  }
  return (
    <Auth0ProviderWithHistory>
      <SharedTradeProvider>
        <DataProvider scale={scaleAmount}>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/Logbook" component={ViewHistory} />
            <PrivateRoute exact path="/Dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/Manual-Trade-Entry"
              component={AddNewTrade}
            />
            <PrivateRoute
              exact
              path="/Yearly-Report"
              component={YearlyReport}
            />
            <PrivateRoute
              exact
              path="/Break-Evens-Report"
              component={BreakEvensReport}
            />
            <PrivateRoute exact path="/Daily-Report" component={DailyReport} />
            <PrivateRoute
              exact
              path="/Streak-Odds-Calculator"
              component={StreakProbabilityCalculator}
            />
            <PrivateRoute
              exact
              path="/Drawdown-RunUp"
              component={DrawdownReport}
            />
            <PrivateRoute
              exact
              path="/Efficiency-Report"
              component={EfficiencyReport}
            />
            <PrivateRoute exact path="/Fund-Report" component={FundReport} />
            <PrivateRoute
              exact
              path="/Holding-Time-Report"
              component={HoldingTimeReport}
            />
            <PrivateRoute exact path="/Integrations" component={Integrations} />
            <PrivateRoute
              exact
              path="/Deposits-Withdrawals"
              component={DepositWithdrawal}
            />
            <PrivateRoute exact path="/Settings" component={Settings} />
            <PrivateRoute
              exact
              path="/Losses-Report"
              component={LossesReport}
            />
            <PrivateRoute
              exact
              path="/MAE-and-MFE-Report"
              component={MAEandMFEReport}
            />
            <PrivateRoute
              exact
              path="/Risk-Management"
              component={MoneyManagementReport}
            />{" "}
            <PrivateRoute
              exact
              path="/Quantum-Query"
              component={QuantumQuery}
            />
            <PrivateRoute
              exact
              path="/Monthly-Report"
              component={MonthlyReport}
            />
            <PrivateRoute
              exact
              path="/Comprehensive"
              component={OverallReport}
            />
            <PrivateRoute
              exact
              path="/KPIs"
              component={PerformanceRatiosReport}
            />{" "}
            <PrivateRoute
              exact
              path="/Position-Size-Calculator"
              component={PositionSizeCalculator}
            />
            <PrivateRoute
              exact
              path="/Manage-Account"
              component={ManageAccount}
            />
            <PrivateRoute
              exact
              path="/Monte-Carlo-Simulator"
              component={Simulator}
            />
            <PrivateRoute
              exact
              path="/Weekly-Report"
              component={WeeklyReport}
            />{" "}
            <PrivateRoute exact path="/AI-Insights" component={AIReport} />
            <PrivateRoute exact path="/Trade-Details" component={ViewTrade} />
            <PrivateRoute exact path="/Wins-Report" component={WinsReport} />
            <PrivateRoute exact path="/Calendar" component={Calendar} />
            <PrivateRoute exact path="/Compare-&-Conquer" component={Comparer} />
            <PrivateRoute exact path="/BlankPage" component={BlankPage} />{" "}
            <PrivateRoute exact path="/Error-Found" component={ErrorFound} />
            {/* <Route exact path="/callback" component={Callback} /> */}
          </Switch>
        </DataProvider>
      </SharedTradeProvider>
    </Auth0ProviderWithHistory>

    /*       <div className="App">
        <h1>Good to Go YO!</h1>
        <p>Your Truffle Box is installed and ready.</p>
        <h2>Smart Contract Example</h2>
        <p>
          If your contracts compiled and migrated successfully, below will show
          a stored value of 5 (by default).
        </p>
        <p>
          Try changing the value stored on <strong>line 40</strong> of App.js.
        </p>
        <div>The stored value is: {this.state.storageValue}</div>
      </div> */
  );
};

export default App;
