import { combineReducers } from "redux-loop";
import defaultfilterValue from "./defaultFilterValue";
import loaderState from "./loader";
import rightPanelToggle from "./rightPanelToggle";
import temporarySettings from "./temporarySettings";
import allData from "./allData";
import filtersOpen from "./filtersMenu";
import filtersToggleLocation from "./filterToggleLocation";
const rootReducer = combineReducers({
  defaultfilterValue,
  loaderState,
  rightPanelToggle,
  temporarySettings,
  filtersOpen,
  filtersToggleLocation,
  allData,
});

export default rootReducer;
