import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { convertFromRaw, convertToRaw } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "./index.scss";
import draftToHtml from "draftjs-to-html";
import usePrevious from "../../hooks/usePrevious";
import isObjectEqual from "../../functions/isObjectEqual";

function checkAndSetDefaultState(originalState) {
  const defaultState = {
    blocks: [],
    entityMap: {},
  };

  if (originalState && originalState.blocks && originalState.entityMap) {
    return originalState;
  }

  return defaultState;
}

const NoteComponent = (props) => {
  const originalState = props.originalState;
  const initContent = convertFromRaw(checkAndSetDefaultState(originalState));

  let editorStatePre = EditorState.createWithContent(initContent);
  const [editorState, setEditorState] = useState(editorStatePre);
  const [focusState, setFocusState] = useState(false);

  let preveditorState = useRef(usePrevious(editorState));
  const editorClassname = useRef("editorClassName");

  const _handleChange = (editorState) => {
    setEditorState(editorState);
    props.setNoteState(convertToRaw(editorState.getCurrentContent()));
  };
  const _handleBlur = () => {
    setFocusState(false);
    editorClassname.current = "editorClassName";
  };
  const _handleFocus = () => {
    setFocusState(true);
    editorClassname.current = "editorClassNameFocused";
  };

  useEffect(() => {
    const originalState = props.originalState;
    const initContent = convertFromRaw(checkAndSetDefaultState(originalState));
    const editorStatePre = EditorState.createWithContent(initContent);

    const prevBlock =
      preveditorState.current &&
      convertToRaw(preveditorState.current.getCurrentContent());
    const currentBlock = convertToRaw(editorStatePre.getCurrentContent());
    !isObjectEqual(prevBlock, currentBlock) &&
      !focusState &&
      setEditorState(editorStatePre);
  }, [focusState, props, preveditorState]);

  return (
    <div className="container-root">
      <Editor
        editorState={editorState}
        onEditorStateChange={_handleChange}
        onBlur={_handleBlur}
        onFocus={_handleFocus}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={editorClassname.current}
        toolbar={{
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
          },
          blockType: { inDropdown: true },
          textAlign: { inDropdown: false },
          link: { inDropdown: false, options: [] },
          image: { uploadEnabled: false },
          colorPicker: { colors: [] },
          remove: { className: undefined, component: undefined },
          history: { inDropdown: false },
        }}
      />
    </div>
  );
};

export default NoteComponent;
