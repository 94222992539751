import React, { useContext, useEffect, useState, useRef } from "react";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import { useAuth0 } from "@auth0/auth0-react";
import useStateWithPromise from "../../hooks/useStateWithPromise";
import TextResults from "./textBoxResults";
import SelectMetrics from "./SelectMetrics";
export const AIInsightsComponent = (props) => {
  const firstUpdate = useRef(true);
  const { user /* isAuthenticated  */ } = useAuth0();
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const portfolio = allData && allData.portfolio;
  const settings = portfolio && portfolio.settings;
  const [width, setWidth] = useState([window.innerWidth]);
  const [comprehensiveWidth, setcomprehensiveWidth] = useState(0);
  const [height, setHeight] = useState([window.innerHeight]);

  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });

    if (firstUpdate.current) {
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
    };
  }, [setcomprehensiveWidth]);
  return (
    <PageTemplate
      title={props.tabName}
      upgradeShow={showUpgradeModal}
      setshowUpgradeModal={(e) => {
        setshowUpgradeModal({
          ...showUpgradeModal,
          show: e.show,
          featureSelect: e.featureSelect,
          blur: e.blur,
          tierText: e.tierText,
        });
      }}
      page={
        <div className="aisuperWrapper">
          <SelectMetrics result={"yo"} />
          <TextResults result={"yo"} />
        </div>
      }
      userData={allData}
    />
  );
};
export default AIInsightsComponent;
