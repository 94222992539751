import React from "react";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import getEntryExitLots from "./../../functions/getEntryExitLots";

const DrawdownPercentGraph = (props) => {
  const tier = props.tier;
  const active = props.active;
  const entries = props.userData;
  //const startingBalance = props.startingBalance;
  const calculationType = props.calculationType;
  //const startingBalanceSetting = props.startingBalanceSetting;
  //const calcs = props.userCalculations;

  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  const equityvaluefunction = () => {
    //let newaccountbal = startingBalance;
    let equityvalue = [];
    let drawdownvalue = [];
    let totalDW = Number(props.startingBalance);

    //const secondToLastItem = equityvalue[equityvalue.length - 2];

    // push the drawdown values

    for (var i = 0, j = entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      //newaccountbal += correctedSUM;
      const thisAccountBalance = Number(entries[i].balance);
      equityvalue = equityvalue.concat(thisAccountBalance);
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        totalDW += correctedSUM;
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
      }
      let max = Math.max(...equityvalue);
      let newdrawdown = ((thisAccountBalance - max) / max) * 100;
      drawdownvalue = drawdownvalue.concat(newdrawdown.toFixed(3));
    }

    return drawdownvalue;
  };

  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (let iiy = 0, jjy = entries.length; iiy < jjy; iiy++) {
      xaxisvalue = xaxisvalue.concat(iiy + 1);
    }
    return xaxisvalue;
  };
  const lineColor = "red";
  //const lineColor = "#664bff" //purple

  // Tiering
  let yvalues = "";
  if (tier === "free") {
    yvalues = [];
  } else if (tier === "pro" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "oldpro" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "master" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "ultimate" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "admin" && active) {
    yvalues = equityvaluefunction();
  } else {
    yvalues = [];
  }

  const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#fa798b",
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: yvalues,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => {
          return "Drawdown: ";
        },
        label: (tooltipItems) => {
          return "%" + parseFloat(tooltipItems.yLabel).toFixed(3);
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return "%" + value.toFixed(3);
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Equity",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="drawdownreportgraph">
      <div className="underwatercurve-label" id="underwatercurve-label">
        Underwater Chart
      </div>
      <div className="drawdownreportgraphwrapper">
        <Line
          data={data}
          options={options}
          className="drawdownreportgraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default DrawdownPercentGraph;
