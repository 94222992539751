import { useEffect, useRef, useState } from "react";

export const usePrevious = (value, initialValue) => {
  const [prevValue, setPrevValue] = useState(initialValue);
  const ref = useRef();
  
  useEffect(() => {
    setPrevValue(ref.current);
    ref.current = value;
  }, [value]);
  
  return prevValue;
};
export default usePrevious;
