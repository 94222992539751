import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import DrawdownComponent from "./Components";
import { DataContext } from "../../DataContext";
import processDataandSettings from "../../utils/processDataandSettings";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";

const StreakProbabilityCalculator = (props) => {
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;

  let showINIT = false;
  // Tiering Logic
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = true;
  } else if (tier === "oldpro" && active) {
    showINIT = true;
  } else if (tier === "master" && active) {
    showINIT = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
  } else {
    showINIT = true;
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect: "Advanced Tools",
    blur: showINIT,
    tierText: "master",
  });

  useEffect(() => {
    if (tier === "free") {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    } else if (tier === "pro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "oldpro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "master" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "ultimate" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "admin" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: true,
      };
    }
  }, [tier, active]);
  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Streak Odds Calculator - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }
  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <DrawdownComponent
          tier={tier}
          active={active}
          showUpgradeModal={showUpgradeModal}
          defaultSymbol={String(
            processedData.portfolio.settings.currencySymbol
          )}
          userPortfolioType={
            processedData.portfolio.assetClasses.length > 1
              ? []
              : processedData.portfolio.assetClasses
          }
          allData={processedData}
          tabName="Streak Odds Calculator"
          pageName="Streak Odds Calculator"
        />
      </div>
    );
  }
};

StreakProbabilityCalculator.defaultProps = {
  tabName: "Streak Odds Calculator",
  pageName: "Streak Odds Calculator",
};

StreakProbabilityCalculator.propTypes = {
  tabName: PropTypes.string,
};

export default StreakProbabilityCalculator;
