/* function roundHundredths(value) {
  return Math.round(value * 100) / 100;
} */
import longShortDelineation from "./longShortDelineation";
import { countExerciseAssignIsEqual } from "./countExerciseAssign";

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const profitLossCalculation = (
  multiexec,
  exitexec,
  type,
  assetClass,
  pointValue, // 100 for options, can be anything for futures
  init // if true, force profitLoss to calculate as if there were full exitexec
) => {
  let entryCost = 0;
  let exitCost = 0;
  let tempbuysum = 0;
  let tempsellsum = 0;
  let exittempbuysum = 0;
  let exittempsellsum = 0;

  const entryExecs = multiexec;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  const exitExecs = exitexec;
  let exitLots = 0;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);

  // If there are no exit executions, do not calculate profit/loss
  if (type === "Multi-Leg Strategy") {
    for (let i in multiexec) {
      const entrylegtype = multiexec[i].legType;
      if (entrylegtype === "Long Call" || entrylegtype === "Long Put") {
        tempbuysum =
          tempbuysum +
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      } else if (
        entrylegtype === "Short Call" ||
        entrylegtype === "Short Put"
      ) {
        tempsellsum =
          tempsellsum +
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      }
    }
    for (let i in exitexec) {
      const exitlegtype = exitexec[i].exitlegType;
      const exercised = exitexec[i].exercised;
      if (
        exercised === "Expired" ||
        exercised === "Exercised" ||
        exercised === "Assigned"
      ) {
        if (countExerciseAssignIsEqual(exitexec)) {
          let equityComponents = exitexec[i].equityComponents;
          if (equityComponents) {
            for (let k in equityComponents) {
              const comp = equityComponents[k];
              if (comp.side === "buy") {
                tempbuysum =
                  tempbuysum +
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              } else {
                exittempbuysum =
                  exittempbuysum +
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              }
            }
          }
        } else {
        }
      } else {
        if (exitlegtype === "Long Call" || exitlegtype === "Long Put") {
          exittempbuysum =
            exittempbuysum +
            Number(exitexec[i].exitLotSize) * Number(exitexec[i].exitPrice);
        } else if (
          exitlegtype === "Short Call" ||
          exitlegtype === "Short Put"
        ) {
          exittempsellsum =
            exittempsellsum +
            Number(exitexec[i].exitLotSize) * Number(exitexec[i].exitPrice);
        }
      }
    }
    entryCost = tempbuysum - tempsellsum;
    exitCost = exittempbuysum - exittempsellsum;
  } else {
    for (let i in multiexec) {
      entryCost += multiexec[i].lotSize * multiexec[i].entryPrice;
    }
    for (let ie in exitexec) {
      const exercised = exitexec[ie].exercised;
      if (
        exercised === "Expired" ||
        exercised === "Exercised" ||
        exercised === "Assigned"
      ) {
        if (countExerciseAssignIsEqual(exitexec)) {
          let equityComponents = exitexec[ie].equityComponents;
          if (equityComponents) {
            for (let k in equityComponents) {
              const comp = equityComponents[k];
              if (comp.side === "buy") {
                tempbuysum =
                  tempbuysum +
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              } else {
                exittempbuysum =
                  exittempbuysum +
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              }
            }
          }
        } else {
        }
      } else {
        exitCost += exitexec[ie].exitLotSize * exitexec[ie].exitPrice;
      }
    }
  }

  let profitLoss = 0;

  // If true, force profitLoss to calculate as if there were full exit lots
  // If true, empty array is probably passed as exitExecs
  let lotsRatio = 1;
  if (!init) {
    lotsRatio = exitLots / entryLots;
  } else {
  }

  // We multiply the entry cost by the fraction of lots closed to only calculate closed P/L
  const difference =
    type === "Multi-Leg Strategy"
      ? exitCost - entryCost
      : longShortDelineation(type, entryExecs) === "Long"
      ? exitCost - entryCost * lotsRatio
      : entryCost * lotsRatio - exitCost;

  if (assetClass === "Options") {
    profitLoss = difference * 100;
  } else if (assetClass === "Futures") {
    profitLoss = difference * pointValue;
  } else if (
    assetClass === "Exercise Stock" ||
    assetClass === "Assigned Stock"
  ) {
    profitLoss = difference;
  } else {
    profitLoss = difference;
  }
  profitLoss = Math.round(profitLoss * 1e10) / 1e10; // fix rounding issue

  return profitLoss;
};

export const returnNetPLDollar = (a, b, c, d) => {
  // This functions return net or gross P/L depending on d
  let newa = 0;
  let newb = 0;
  let newc = 0;
  if (a !== "") {
    newa = Number(a);
  }
  if (b !== "") {
    newb = Number(b);
  }
  if (c !== "") {
    newc = Number(c);
  }
  return d === "Gross" ? newa : newa + newb + newc;
};

export default profitLossCalculation;
