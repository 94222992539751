import {
  getDrawdownCalcs,
  getRunUpCalcs,
} from "../functions/calculations_for_metrics/getmaxDD";
import { handleNANGOOD } from "./../functions/handleUndefined";
import getEntryExitLots from "./../functions/getEntryExitLots";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../functions/getStartorEndDateTime";
import { returnNetPLDollar } from "./../functions/profitLossCalculation";

// --------- *********IMPORTANT********* ---------

// ** calculations that are open an have no exit executions can be omitted from all calculations

//------------------------------------------------
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const drawdownCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW
) => {
  let averageMaxDrawdownDollar = 0;
  let averageMaxDrawdownPercent = 0;
  let averageDrawdownDollar = 0;
  let averageDrawdownPercent = 0;
  let averageDrawdownPeriod = 0;
  let absoluteDrawdownDollar = 0;
  let absoluteDrawdownPercent = 0;

  let result = {};
  let totalDW = start_DW;
  let totalTrades = 0;

  //let newaccountbal = start_Balance;
  //let miscellaneousStdDevPerTradePercenttemparray = [];
  let fullaccountbalancearr = [];

  let fullaccountbalancearrwithDates = [];

  // Main For Loop

  for (let i = 0, j = data.length; i < j; i++) {
    const thisAccountBalance = Number(data[i].balance);
    const entryId = data[i].entryId;
    let orderType = data[i].entry.orderType;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    const correctedSUM = returnNetPLDollar(
      data[i].entry.profitLoss,
      data[i].entry.commissions,
      data[i].entry.fees,
      calctype
    );

    /*     let eachStartDateTime = new Date(startDateTimez); // or startDateTimez
    let eachEndDateTime = new Date(endDateTimez); */

    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      // for deposits and withdrawals, replace the new ACTUAL account balance
      // at the beginning of the array
      totalDW += correctedSUM;
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      // newaccountbal += correctedSUM;
      fullaccountbalancearr.push(thisAccountBalance);

      const endDateTimez = getEndDateTime(exitExecution);
      const startDateTimez = getStartDateTime(multiExecution);
      let pushThis = {
        balance: thisAccountBalance,
        startDate: startDateTimez,
        endDate: endDateTimez,
        id: entryId,
      };
      fullaccountbalancearrwithDates.push(pushThis); //only doing to non deposit/withdrawals
      totalTrades += 1;
    }
  }

  // ---------------- Drawdown calculations ----------------
  let maxDrawdownDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          getDrawdownCalcs(fullaccountbalancearrwithDates).maxDD,
          0
        );
  let maxDrawdownPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          getDrawdownCalcs(fullaccountbalancearrwithDates).maxDDpercent,
          0
        );
  let dateofMaxDrawdown =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).date;
  let dateofMaxDrawdownPercent =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).datePercent;
  averageMaxDrawdownDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(getDrawdownCalcs(fullaccountbalancearrwithDates).fiveLargest),
          0
        );
  averageDrawdownDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(getDrawdownCalcs(fullaccountbalancearrwithDates).allDDs),
          0
        );
  averageMaxDrawdownPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(
            getDrawdownCalcs(fullaccountbalancearrwithDates).fiveLargestPercents
          ),
          0
        );
  averageDrawdownPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(
            getDrawdownCalcs(fullaccountbalancearrwithDates).allDDsPercent
          ),
          0
        );
  absoluteDrawdownDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(totalDW - Math.min(...fullaccountbalancearr), 0);
  absoluteDrawdownPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          ((totalDW - Math.min(...fullaccountbalancearr)) / totalDW) * 100,
          0
        );
  let longestDrawdownPeriod =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).drawdownPeriod;

  averageDrawdownPeriod =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).avgdrawdownPeriod;

  // ---------------- Runup calculations ----------------
  let maxRunUpDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(getRunUpCalcs(fullaccountbalancearrwithDates).maxRU, 0);
  let dateofMaxRunUp =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).date;
  let maxRunUpPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          getRunUpCalcs(fullaccountbalancearrwithDates).maxRUpercent,
          0
        );
  let longestRunUpPeriod =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).runupPeriod;
  let averageMaxRunUpDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).fiveLargest),
          0
        );
  let averageRunUpDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).allRUs),
          0
        );
  let averageMaxRunUpPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          getRunUpCalcs(fullaccountbalancearrwithDates).fiveLargestPercents,
          0
        );
  let averageRunUpPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).allRUsPercent),
          0
        );
  let absoluteRunUpDollar =
    totalTrades === 0
      ? 0
      : handleNANGOOD(Math.max(...fullaccountbalancearr) - totalDW, 0);
  let absoluteRunUpPercent =
    totalTrades === 0
      ? 0
      : handleNANGOOD(
          ((Math.max(...fullaccountbalancearr) - totalDW) / totalDW) * 100,
          0
        );
  let averageRunUpPeriod =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).avgrunUpperiod;
  result = {
    maxDrawdownDollar,
    maxDrawdownPercent,
    dateofMaxDrawdown,
    dateofMaxDrawdownPercent,
    averageMaxDrawdownDollar,
    averageMaxDrawdownPercent,
    averageDrawdownDollar,
    averageDrawdownPercent,
    longestDrawdownPeriod,
    averageDrawdownPeriod,
    absoluteDrawdownDollar,
    absoluteDrawdownPercent,
    maxRunUpDollar,
    maxRunUpPercent,
    dateofMaxRunUp,
    averageMaxRunUpDollar,
    averageRunUpDollar,
    longestRunUpPeriod,
    absoluteRunUpDollar,
    absoluteRunUpPercent,
    averageMaxRunUpPercent,
    averageRunUpPercent,
    averageRunUpPeriod,
    start_Balance,
    start_DW,
  };
  return result;
};
export default drawdownCalculations;
