import { v4 as uuidv4 } from "uuid";
export const createEquityComponent = (
  exitstrikePrice,
  exitLotSize,
  exercised
) => {
  let equityComponents = [];
  const comp = {
    id: uuidv4(),
    price: exitstrikePrice,
    quantity: Number(exitLotSize) * 100,
    side: exercised === "Exercised" ? "buy" : "sell",
  };
  equityComponents.push(comp);
  return equityComponents;
};
export default createEquityComponent;
