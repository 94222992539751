import $ from "jquery";
import { ontoggle } from "./Togglelogic";
import { ProfileIconHoverOffStyle } from "./../../../components/Navigation-Bar/JQUERY/InitialStyledQuery";
export const sidenavhover = (listname, globalvar) => {
  $(listname).hover(
    function () {
      if (globalvar.mainbuffervalue === 1) {
        return false;
      }
      $(".hamburger").css({
        "background-color": "#14181e",
        color: "#deebf7",
      });
      globalvar.mainbuffervalue = 0;
    },
    function () {
      if (globalvar.mainbuffervalue === 1) {
        return false;
      }
      $(".hamburger").css({
        "background-color": "#14181e",
        color: "#deebf7",
      });
      globalvar.mainbuffervalue = 0;
    }
  );
};

export const sidenavcomphover = (
  listnamez,
  listnamez2,
  listnamez3,
  caretbuff,
  caretbuffo1,
  caretbuffo2,
  dropbuff,
  dropbuffo1,
  dropbuffo2,
  globalvar
) => {
  $(caretbuff).click(function (e) {
    e.stopPropagation();
    ProfileIconHoverOffStyle(".profile-icon");
    if ($(dropbuff).is(":visible")) {
      $(dropbuff).css({
        display: "none",
      });
      $(caretbuff).css({
        display: "block",
        color: "#fbd665",
      });
    } else {
      $(dropbuffo1).css({
        display: "none",
      });
      $(dropbuffo2).css({
        display: "none",
      });
      $(dropbuff).css({
        display: "block",
        left: 204,
      });
      $(caretbuff).css({
        display: "block",
        color: "#fbd665",
      });
      $(caretbuffo1).css({
        color: "#deebf7",
        "background-color": "#222a35",
        display: "none",
      });
      $(caretbuffo2).css({
        color: "#deebf7",
        "background-color": "#222a35",
        display: "none",
      });
    }
  });
  $(document).click(function (event) {
    var $target = $(event.target);
    if (!$target.closest("#profile-icon").length) {
      $(".profile-icon").css({
        "box-shadow": "none",
      });
    } else {
    }
  });
  $("body").click(function (e) {
    if (globalvar.mainbuffervalue === 1) {
      $(
        ".dropdown-list-insights, .dropdown-list-tools, .dropdown-list-import"
      ).css({
        display: "none",
      });
    } else {
    }
    if ($(listnamez + ":hover").length !== 0) {
      $(caretbuff).css({
        color: "#deebf7",
      });
    } else {
      $(caretbuff).css({
        color: "#deebf7",
        "background-color": "#222a35",
        display: "none",
      });
    }
  });
  $(dropbuff).hover(
    function () {
      /*       $(".sidenavstyledmain").css({
        width: 204,
      });
      $(".insightsbuffer").css({
        width: 204,
      });
      $(".toolsbuffer").css({
        width: 204,
      });
      $(".managedatabuffer").css({
        width: 204,
      });
      $(".sidebaronly").css({
        position: "fixed",
        height: "100%",
        "z-index": 11,
        width: 210,
        top: "0em",
        "background-color": "#14181e",
      }); */
      if (globalvar.mainbuffervalue === 1) {
        return false;
      } else {
        /*         $(".sidebaronly").css({
          "box-shadow": "0 0 4px 2px #171c23",
        }); */
      }
    },
    function (e) {
      if (globalvar.mainbuffervalue === 1) {
        return false;
      } else {
        ontoggle();
        $(".sidebaronly").css({
          "box-shadow": "none",
        });
        $(dropbuff).css({
          display: "none",
        });
        $(listnamez).css({
          "background-color": "#14181e",
        });
        $(caretbuff).css({
          color: "#deebf7",
          "background-color": "#222a35",
          display: "none",
        });
        let mouseXvalue = e.pageX;
        if (mouseXvalue > 210) {
          if ($(listnamez + ":hover").length !== 0) {
            return false;
          } else {
            if ($(caretbuff + ":hover").length !== 0) {
              $(caretbuff).css({
                display: "block",
                color: "#fbd665",
                "background-color": "#1a2028",
                transition: "none",
              });
              return false;
            } else {
            }
          }
        } else {
          if ($(listnamez + ":hover").length !== 0) {
            $(caretbuff).css({
              display: "block",
              color: "#fbd665",
              transition: "none",
            });
            return false;
          } else {
            if (globalvar.mainbuffervalue === 1) {
              $(caretbuff).css({
                display: "block",
                color: "#fbd665",
                "background-color": "#14181e",
                transition: "none",
              });
            } else {
              $(caretbuff).css({
                color: "#222a35",
                "background-color": "#222a35",
                display: "none",
              });
              $(caretbuffo1).css({
                color: "#222a35",
                "background-color": "#222a35",
                display: "none",
              });
              $(caretbuffo2).css({
                color: "#222a35",
                "background-color": "#222a35",
                display: "none",
              });
            }
          }
        }
      }
    }
  );
  $(caretbuff).hover(
    function () {
      $(caretbuff).css({
        transition: "all 0.15s",
        color: "#fbd665",
        "background-color": "#1a2028",
      });
    },
    function () {
      if ($(dropbuff).is(":visible")) {
        $(caretbuff).css({
          color: "#fbd665",
          "background-color": "#222a35",
          transition: "none",
          display: "block",
        });
      } else {
        $(caretbuff).css({
          color: "#deebf7",
          "background-color": "#222a35",
          transition: "0.15s",
          display: "block",
        });
      }
    }
  );
  $(listnamez).hover(
    function (e) {
      if (globalvar.mainbuffervalue === 1) {
      } else {
        $(dropbuffo1).css({
          display: "none",
        });
        $(dropbuffo2).css({
          display: "none",
        });
        $(dropbuff).css({
          display: "block",
          left: 53,
          "margin-top": -6,
        });
        $(caretbuff).css({
          color: "#222a35",
          "background-color": "#222a35",
          display: "none",
        });
        $(caretbuffo1).css({
          color: "#222a35",
          "background-color": "#222a35",
          display: "none",
        });
        $(caretbuffo2).css({
          color: "#222a35",
          "background-color": "#222a35",
          display: "none",
        });
      }
      $(listnamez).css({
        "background-color": "#222a35",
      });
      $(listnamez2).css({
        "background-color": "#14181e",
      });
      $(listnamez3).css({
        "background-color": "#14181e",
      });
      if ($(dropbuff).is(":visible")) {
        if ($(caretbuff + ":hover").length !== 0) {
          if (globalvar.mainbuffervalue === 1) {
            $(caretbuff).css({
              transition: "all 0.15s",
              color: "#fbd665",
              "background-color": "#1a2028",
            });
          } else {
            $(caretbuff).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
            $(caretbuffo1).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
            $(caretbuffo2).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
          }
        } else {
          if (globalvar.mainbuffervalue === 1) {
            $(caretbuff).css({
              color: "#fbd665",
              "background-color": "#222a35",
              transition: "none",
              display: "block",
            });
          } else {
            $(caretbuff).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
            $(caretbuffo1).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
            $(caretbuffo2).css({
              color: "#222a35",
              "background-color": "#222a35",
              display: "none",
            });
          }
        }
      } else {
        if (globalvar.mainbuffervalue === 1) {
          $(caretbuff).css({
            color: "#deebf7",
            "background-color": "#222a35",
            transition: "0.15s",
            display: "block",
          });
        } else {
          $(caretbuff).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
          $(caretbuffo1).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
          $(caretbuffo2).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
        }
      }
    },
    function () {
      if ($(dropbuff).is(":visible")) {
        $(listnamez).css({
          "background-color": "#14181e",
        });
        if (globalvar.mainbuffervalue === 1) {
          $(caretbuff).css({
            color: "#fbd665",
            "background-color": "#14181e",
            transition: "none",
          });
        } else {
          $(caretbuff).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
          $(caretbuffo1).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
          $(caretbuffo2).css({
            color: "#222a35",
            "background-color": "#222a35",
            display: "none",
          });
          $(listnamez).css({
            "background-color": "#222a35",
          });
          $(listnamez2).css({
            "background-color": "#14181e",
          });
          $(listnamez3).css({
            "background-color": "#14181e",
          });
        }
      } else {
        $(listnamez).css({
          "background-color": "#14181e",
        });
        $(caretbuff).css({
          display: "none",
        });
      }
    }
  );
  $(
    ".sidebaronly, .sidenavstyledmain, .insightsbuffer, .toolsbuffer, .managedatabuffer"
  ).hover(
    function () {},
    function () {
      if (
        $(dropbuff + ":hover").length !== 0 ||
        $(dropbuffo1 + ":hover").length !== 0 ||
        $(dropbuffo2 + ":hover").length !== 0
      ) {
        return false;
      } else {
        if (globalvar.mainbuffervalue === 1) {
          return false;
        }
        $(dropbuff).css({
          display: "none",
          left: 204,
          "margin-top": 0,
        });
        $(listnamez).css({
          "background-color": "#14181e",
        });
        $(caretbuff).css({
          color: "#deebf7",
          "background-color": "#222a35",
          display: "none",
        });
      }
    }
  );
};
export default sidenavcomphover;
