function isObjectEqual(obj1, obj2) {
  // If both objects are null or undefined, they are equal
  if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) {
    return obj1 === obj2;
  }

  // If the types of the objects are different, they are not equal
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If the objects are not of type object, compare them directly
  if (typeof obj1 !== 'object') {
    return obj1 === obj2;
  }

  // If the objects are arrays, compare their elements recursively
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!isObjectEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  // If the objects are Date objects, compare them based on their time value
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  // Compare the properties of the objects recursively
  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);
  if (obj1Props.length !== obj2Props.length) {
    return false;
  }
  for (let prop of obj1Props) {
    if (!isObjectEqual(obj1[prop], obj2[prop])) {
      return false;
    }
  }
  return true;
}
export default isObjectEqual;
