import React, { Component } from "react";

import "./loader.scss";

import loaderImg from "../../images/NewFuse.png";
import loaderImg1b from "../../images/NewFuse.png";
import loaderImg2b from "../../images/NewFuseStrike.png";

class AlwaysLoadingLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidUpdate() {
  }
  render() {
    return (
      <span className="loader-container-b">
        <div className="loader3b">
          <img src={loaderImg} alt="0" />
        </div>
        <div className="loader2b">
          <img src={loaderImg2b} alt="0" />
        </div>
        <div className="loader1b">
          <img src={loaderImg1b} alt="0" />
        </div>
      </span>
    );
  }
}

export default AlwaysLoadingLoader;
