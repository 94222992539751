import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import $ from "jquery";
import dydxlogo from "./../../images/dydxlogo.svg";
import robinhoodlogo from "./../../images/Logo-Robinhood.png";
import metamasklogo from "./../../images/MetaMask_Fox.svg";
import dropdownStyles from "../../functions/dropdownStyles";

export const AutoImport = (props) => {
  /*  */

  const allData = props.data;
  const portfolios = allData.allData.chosenPortfolios;
  const createdPortfolios = allData.allData.createdPortfolios;
  const selectedBrokerIn = props.selectedBroker;
  const trades = allData.portfolio.entries;
  const numberofPortfolios = portfolios.length;
  const disablevarINIT = numberofPortfolios === 2 ? false : true;
  const disablevarweb3INIT = numberofPortfolios === 2 ? false : true;
  // ------- BROKER/PLATFORM RECOGNITION ALGORITHM -------
  const selectedBrokerINIT = allData.portfolio.settings.linkedBrokerInfo;
  let selectedBrokerSuggestion = selectedBrokerINIT.broker;
  const lowerCaseBrokerSuggestion =
    selectedBrokerSuggestion &&
    selectedBrokerSuggestion.replace(/[^a-zA-Z ]/g, "").toLowerCase();
  let selectedBrokerSuggestionINIT = "";
  let selectedSuggestionINIT = "";

  if (lowerCaseBrokerSuggestion === "robinhood") {
    selectedBrokerSuggestionINIT = {
      label: "Robinhood",
      value: "robinhood",
    };
    selectedSuggestionINIT = "Robinhood";
  } else if (lowerCaseBrokerSuggestion === "dydx") {
    selectedBrokerSuggestionINIT = {
      label: "dYdX",
      value: "dydx",
    };
    selectedSuggestionINIT = "dYdX";
  } else if (lowerCaseBrokerSuggestion === "metamask") {
    selectedBrokerSuggestionINIT = {
      label: "MetaMask",
      value: "metamask",
    };
    selectedSuggestionINIT = "MetaMask";
  } else if (lowerCaseBrokerSuggestion === "thinkorswim") {
    selectedBrokerSuggestionINIT = {
      label: "thinkorswim",
      value: "thinkorswim",
    };
    selectedSuggestionINIT = "thinkorswim";
  } else {
  }

  // Create array of connections info
  let allAutoImportConnectionsArray = [];

  createdPortfolios.forEach((portfolio) => {
    allAutoImportConnectionsArray.push(
      portfolio.settings.linkedBrokerInfo.broker
    );
  });

  // -------------------------------------
  const [selectedBroker, setselectedBroker] = useState(selectedBrokerIn);
  const [overwriteentries, setoverwriteentries] = useState();

  /* selectedBrokerSuggestionINIT */
  const [disablevar, setdisablevar] = useState(disablevarINIT);
  const [disablevarweb3, setdisablevarweb3] = useState(disablevarweb3INIT);

  const validate = useCallback(() => {
    const chosenPortfolios = portfolios;
    if (chosenPortfolios.length !== 2) {
      if (allData.allData.createdPortfolios.length === 1) {
        document.getElementById("messageBoxMessage").innerHTML =
          "Please create a portfolio to add a trade.";
      } else {
        document.getElementById("messageBoxMessage").innerHTML =
          "When adding a deposit/withdrawal, only one portfolio can be selected.";
      }
    } else {
      if (
        selectedBroker === null ||
        !selectedBroker ||
        (trades.length && !overwriteentries && selectedBrokerSuggestion === "")
      ) {
        setdisablevar(true);
      } else {
        setdisablevar(false);
      }
    }
  }, [
    selectedBroker,
    portfolios,
    allData,
    overwriteentries,
    trades.length,
    selectedBrokerSuggestion,
  ]);

  const validate2 = useCallback(() => {
    const chosenPortfolios = portfolios;
    if (chosenPortfolios.length !== 2) {
    } else {
      if (
        trades.length &&
        !overwriteentries &&
        selectedBrokerSuggestion === ""
      ) {
        setdisablevarweb3(true);
      } else {
        setdisablevarweb3(false);
      }
    }
  }, [portfolios, overwriteentries, trades.length, selectedBrokerSuggestion]);

  useEffect(() => {
    validate();
  }, [selectedBroker, overwriteentries, validate]);
  useEffect(() => {
    validate2();
  }, [selectedBroker, overwriteentries, validate2]);

  const requestBrokerLogin = async (portfolio) => {
    if (selectedBrokerSuggestion !== "") {
      $("#autoimportunlinkmodalwrapper").fadeIn(300);
      document.getElementById("autoimportunlinkmodalwrapper").style.display =
        "flex";
      $("#overlay").fadeIn(300);
    } else {
      const modalwraper = document.getElementById("autoimportmodalwrapper");
      props.setSelectedBroker(portfolio);
      $("#autoimportmodalwrapper").fadeIn(300);
      modalwraper.style.display = "flex";
      $("#overlay").fadeIn(300);
    }
  };
  /*   const firstOption =
    selectedBrokerSuggestion === "dYdX" ? (
      <div id="existingtradestext">
        <div style={{ textAlign: "left" }}>
          dYdX tracks a third type of entry: "Funding Payments". Would you like
          to add them as individual entries or track them as fees in the current
          trade?
        </div>
        <span>
          <span id="optionsselectwrapper-upper">
            <div className="keep-entries-container">
              <label className="container checksati1">
                <input
                  type="radio"
                  name="legType"
                  id="orderTypeBuy"
                  value={"Keep Entries"}
                  onClick={(e) => {
                    props.setAddFundingPayments(e.target.value);
                  }}
                />
                <span className="radiocircle"></span>
              </label>
              <div className="radio-label-buy">
                <label>{"Keep Entries"}</label>
              </div>
            </div>
            <div className="optionsellcall-container-upper">
              <label className="container checksati2">
                <input
                  type="radio"
                  name="legType"
                  id="orderTypeSell"
                  value={"Overwrite Entries"}
                  onClick={(e) => {
                    props.setOverwriteEntries(e.target.value);
                  }}
                />
                <span className="radiocircle"></span>
              </label>
              <div className="radio-label-sell">
                <label>{"Overwrite Entries"}</label>
              </div>
            </div>
          </span>
        </span>
      </div>
    ) : (
      <div></div>
    ); */
  const deleteTradeQuestion =
    !trades.length || selectedBrokerSuggestion !== "" ? (
      <div id="existingtradestextempty"></div>
    ) : (
      <div id="existingtradestext">
        <div style={{ textAlign: "left" }}>
          There are entries already logged in this portfolio. Would you like to
          keep or overwrite them?
        </div>
        <span>
          <span id="optionsselectwrapper-upper">
            <div className="keep-entries-container">
              <label className="container checksati1">
                <input
                  type="radio"
                  name="legType"
                  id="orderTypeBuy"
                  value={"Keep Entries"}
                  onClick={(e) => {
                    setoverwriteentries(e.target.value);
                    //props.setOverwriteEntries(e.target.value);
                  }}
                />
                <span className="radiocircle"></span>
              </label>
              <div className="radio-label-buy">
                <label>{"Keep Entries"}</label>
              </div>
            </div>
            <div className="optionsellcall-container-upper">
              <label className="container checksati2">
                <input
                  type="radio"
                  name="legType"
                  id="orderTypeSell"
                  value={"Overwrite Entries"}
                  onClick={(e) => {
                    setoverwriteentries(e.target.value);
                    //props.setOverwriteEntries(e.target.value);
                  }}
                />
                <span className="radiocircle"></span>
              </label>
              <div className="radio-label-sell">
                <label>{"Overwrite Entries"}</label>
              </div>
            </div>
          </span>
        </span>
      </div>
    );
  const supportedBrokersPre = [
    {
      label: "Robinhood",
      value: "robinhood",
    },
    {
      label: "dYdX",
      value: "dYdX",
    },
    {
      label: "thinkorswim (Coming Soon)",
      value: "thinkorswim",
    },
    {
      label: "Binance (Coming Soon)",
      value: "binance",
    },
    /*     {
      label: "thinkorswim (Coming Soon)",
      value: "thinkorswim",
    }, */
    /*     {
      label: "Kraken (Coming Soon)",
      value: "kraken",
    }, */
    /*     {
      label: "Coinbase",
      value: "coinbase",
    },
    {
      label: "E-Trade",
      value: "etrade",
    },
    {
      label: "Interactive Brokers",
      value: "interactivebrokers",
    },
    {
      label: "Binance",
      value: "binance",
    },
    {
      label: "WeBull",
      value: "webull",
    }, */
    /*     { label: "WeBull", value: "webull" }, */
  ];

  // Only show brokers that aren't already linked to another account
  const supportedBrokers = supportedBrokersPre.filter(
    (broker) => !allAutoImportConnectionsArray.includes(broker.value)
  );
  let modallogodiv = "";
  if (!selectedBroker) {
  } else {
    if (selectedBroker.label === "Robinhood") {
      modallogodiv = (
        <img className="linklogowrapperimgmainrh" src={robinhoodlogo} alt="" />
      );
    } else if (selectedBroker.label === "dYdX") {
      modallogodiv = (
        <img className="linklogowrapperimgmain" src={dydxlogo} alt="" />
      );
    } else if (selectedBroker.label === "MetaMask") {
      modallogodiv = (
        <img
          className="linklogowrapperimgmainsmall"
          src={metamasklogo}
          alt=""
        />
      );
    } else {
    }
  }

  const buttonText =
    selectedBrokerSuggestion !== ""
      ? `Unlink ${selectedBrokerSuggestion}`
      : `Link ${selectedBrokerIn.label}`;
  const buttonClass =
    selectedBrokerSuggestion !== ""
      ? "autoimportunlinkbrokerbutton"
      : "autoimportlinkbrokerbutton";
  /*   const modalHeight =
    !trades.length || selectedBrokerSuggestion !== "" ? "234px" : "320px"; */

  let text = "";
  let headertext = "";
  if (createdPortfolios.length === 1) {
    text = "Create a Portfolio";
    headertext = "Please create a Portfolio to link a broker/exchange.";
  }
  const height =
    createdPortfolios.length === 1
      ? "200px"
      : selectedBrokerSuggestion !== ""
      ? "186px"
      : !trades.length
      ? "350px"
      : "488px";
  return (
    <div id="autoimportlinkbrokerwrapper" style={{ height: "max-content" }}>
      <div id="autoimportlinkbrokerwrapperinner">
        {createdPortfolios.length === 1 ? "" : deleteTradeQuestion}
        {createdPortfolios.length === 1 ? (
          <div id="selectPortfolioHeaderWrapper">
            <div id="selectPortfolioHeaderText">{headertext}</div>
            <div
              onClick={() =>
                $(".portfolio-dropdown").css({
                  display: "flex",
                })
              }
              id="selectPortfoliobutton"
              className="selectPortfoliobuttonmove"
            >
              {text}
            </div>
          </div>
        ) : (
          <div>
            {selectedBroker.label === "MetaMask" ? (
              <div id="autoimportconnectwalletbuttonwrapper">
                {selectedBrokerSuggestion === "" && (
                  <button
                    disabled={disablevarweb3}
                    onClick={() => requestBrokerLogin(selectedBroker)}
                    id="autoimportconnectwalletbutton"
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            ) : (
              <button
                disabled={disablevar}
                id={buttonClass}
                onClick={() => requestBrokerLogin(selectedBroker)}
              >
                {buttonText}
              </button>
            )}
          </div>
        )}
        {/*       {firstOption} */}

        {/*         <button
          onClick={async () => {
            const relayLinkRobinhood = async () => {
              const response = await fetch("https://ipfs.infura.io:5001", {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                  Host: "https://ipfs.infura.io",
                  Accept: "application/json",
                  Authorization: btoa("2Fxr9TaCztvbn8d7JXWaStGNvMF:53177edf75be172a9ab05eed17ec0c4a"),
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                  "Access-Control-Allow-Headers": "Content-Type, Authorization",
                },
              }).catch((err) => {
                throw err;
              });
              return response.json(); // parses JSON response into native JavaScript objects
            };
            const retrundis = await relayLinkRobinhood();
            console.log(retrundis)
          }}
        >
          Connect Wallet
        </button> */}
      </div>
    </div>
  );
};
export default AutoImport;
