import React from 'react';
import PropTypes from 'prop-types';

import PageTemplate from '../../components/PageTemplate/PageTemplate';

const WinsReport = (props) => (
  <PageTemplate
    title={props.tabName}
    page={
      <div> Wins Report </div>
    } />
);

WinsReport.defaultProps = {
  tabName: "Wins"
}

WinsReport.propTypes = {
  tabName: PropTypes.string,
}


export default WinsReport;
