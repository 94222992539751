import React from "react";
import { showMISCMetricTable } from "./MetricFunctions";
import supportedCryptos from "../../arrays/isCurrencyCrypto";

var he = require("he");
export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const MiscPerformanceMetrics = (props) => {
  const supportedCryptosCheck = supportedCryptos.includes(props.defaultSymbol);

  const MiscMetricsLabels = [
    "Maximum Position Size",
    "Highest Equity " + he.decode(props.defaultSymbol),
    "Account Size Required " + he.decode(props.defaultSymbol),
    "Current Streak",
  ];

  const MiscMetricsLabelsCol2 = [
    "Standard Deviation Per Trade " + he.decode(props.defaultSymbol),
    "Coefficient of Variation Per Trade " + he.decode(props.defaultSymbol),
    "Downside Deviation Per Trade " + he.decode(props.defaultSymbol),
    "Variance Per Trade " + he.decode(props.defaultSymbol),
  ];
  /*   const MiscMetricsLabelsCol2_tog = [
    "Standard Deviation Per Trade %",
    "Coefficient of Variation Per Trade %",
    "Downside Deviation Per Trade %",
    "Variance Per Trade %",
  ]; */
  const formatNumber = (value, decimals) => {
    // Check if the value is a number and not NaN
    if (typeof value === "number" && !isNaN(value)) {
      return value.toFixed(decimals);
    }
    return value; // Return the value as is if it's not a number
  };

  const miscMetrics = [
    formatNumber(props.userCalculations["miscellaneousMaxLotSize"], 2),
    supportedCryptosCheck
      ? formatNumber(props.userCalculations["miscellaneousHighestEquity"], 6) +
        " " +
        props.defaultSymbol
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(props.userCalculations["miscellaneousHighestEquity"], 2)
        ),
    supportedCryptosCheck
      ? formatNumber(
          props.userCalculations["miscellaneousAccountSizeRequired"],
          6
        ) +
        " " +
        props.defaultSymbol
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(
            props.userCalculations["miscellaneousAccountSizeRequired"],
            2
          )
        ),
    props.userCalculations["miscellaneousCurrentStreak"],
  ];

  const miscMetricsCol2 = [
    supportedCryptosCheck
      ? formatNumber(
          props.userCalculations["miscellaneousStdDevPerTradeDollar"],
          6
        ) +
        " " +
        he.decode(props.defaultSymbol)
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(
            props.userCalculations["miscellaneousStdDevPerTradeDollar"],
            2
          )
        ),
    supportedCryptosCheck
      ? formatNumber(
          props.userCalculations["miscellaneousCoefofVarPerTradeDollar"],
          6
        ) +
        " " +
        he.decode(props.defaultSymbol)
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(
            props.userCalculations["miscellaneousCoefofVarPerTradeDollar"],
            2
          )
        ),
    supportedCryptosCheck
      ? formatNumber(
          props.userCalculations["miscellaneousDownsideDevPerTradeDollar"],
          6
        ) +
        " " +
        he.decode(props.defaultSymbol)
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(
            props.userCalculations["miscellaneousDownsideDevPerTradeDollar"],
            2
          )
        ),
    supportedCryptosCheck
      ? formatNumber(
          props.userCalculations["miscellaneousVariancePerTradeDollar"],
          6
        ) +
        " " +
        he.decode(props.defaultSymbol)
      : he.decode(props.defaultSymbol) +
        numberWithCommas(
          formatNumber(
            props.userCalculations["miscellaneousVariancePerTradeDollar"],
            2
          )
        ),
  ];
  return (
    <div className="miscmetricomponentwrapper">
      <div className="misc-metrics-label" id="misc-metrics-label">
        Miscellaneous
      </div>
      <div className="misc-metrics-table">
        <div className="miscmetricol1">
          {showMISCMetricTable(miscMetrics, MiscMetricsLabels, props)}
        </div>
        <div className="miscmetricol2">
          {showMISCMetricTable(miscMetricsCol2, MiscMetricsLabelsCol2, props)}
        </div>
      </div>
    </div>
  );
};

export default MiscPerformanceMetrics;
