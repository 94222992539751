export const demoData = (port) => {
  return {
    name: "Demo Data",
    assetClasses: ["Crypto", "Futures", "Stocks"],
    entries: [
      {
        entryId: "9c54de8c-f7ae-45d8-abb6-12ae12194697",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: port,
          selectedPortfolioType: "",
          orderType: "Deposit",
          orderNumber: "781638",
          stopLoss: "",
          takeProfit: "",
          profitLoss: 326.45,
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "Initial deposit from savings",
          multiExecution: [],
          exitExecution: [],
          startDateTime: 1565870242000,
          endDateTime: 1565870242000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "dd05d632-43de-421c-871a-c8e3ad5bb526",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["USDCAD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "High",
          selectedEmotion: ["😐 Neutral"],
          selectedPhysical: ["😎 Feelin' good", "🥶 Cold"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["Moderate Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "803317",
          dayOfWeek: "Thursday",
          monthOfYear: "August",
          stopLoss: "1.3331",
          takeProfit: "1.33073",
          profitLoss: 33.93,
          commissions: -2.1,
          fees: 0,
          maxAdEx: "1.3326",
          maxFavEx: "1.33065",
          comments: "First trade! Felt pretty good, standard break out set up",
          multiExecution: [
            {
              id: "490dcc83-d1ae-41d1-b538-d362a852a3c8",
              lotSize: 0.35,
              entryPrice: 1.332,
              startDateTime: 1565903042000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "0ec5d2d5-dab8-4848-b87a-83362386e1e9",
              exitLotSize: 0.35,
              exitPrice: 1.33071,
              endDateTime: 1565904582000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1565903042000,
          endDateTime: 1565904582000,
          tags: ["first trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "70a8befc-2c31-4977-a115-d8f594e55c32",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: port,
          selectedPortfolioType: "",
          orderType: "Deposit",
          orderNumber: "784598",
          stopLoss: "",
          takeProfit: "",
          profitLoss: 9.93,
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "deposit from doordash",
          multiExecution: [],
          exitExecution: [],
          startDateTime: 1565957762000,
          endDateTime: 1565957762000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "bc52e42c-9b37-42c0-b083-d22bcec5838e",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["XAUUSD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "High",
          selectedEmotion: ["😰 Anxious"],
          selectedPhysical: ["😪 Sleepy"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["High Risk", "Got Out Early"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "806033",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "1510.17",
          takeProfit: "1517.01",
          profitLoss: 32.6,
          commissions: -1.82,
          fees: 0,
          maxAdEx: "1511.64",
          maxFavEx: "1515.30",
          comments:
            "Another nice trade although i could have gotten much more profit...got out early",
          multiExecution: [
            {
              id: "cb12fab2-4af7-4c30-a82b-62b6689ecd25",
              lotSize: 0.2,
              entryPrice: 1512.95,
              startDateTime: 1565989891000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "189c66bf-cdb9-41a4-bbbb-cfd441338519",
              exitLotSize: 0.2,
              exitPrice: 1514.58,
              endDateTime: 1565991439000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1565989891000,
          endDateTime: 1565991439000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "412e7c9b-9fef-42d5-94f6-e22635867cb7",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPJPY"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Low",
          selectedEmotion: ["😞 Disappointed"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["B quality setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "806474",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "129.366",
          takeProfit: "128.705",
          profitLoss: -1.81,
          commissions: -0.3,
          fees: 0,
          maxAdEx: "129.244",
          maxFavEx: "128.957",
          comments:
            "Took this trade a little late, and during what I think is a bad session (tokyo?)",
          multiExecution: [
            {
              id: "969163b7-3093-41ac-b1b1-d758bb943447",
              lotSize: 0.04,
              entryPrice: 129.164,
              startDateTime: 1566001313000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "e23f9a72-c913-4b3b-9b0b-78e6d7179a5f",
              exitLotSize: 0.04,
              exitPrice: 129.212,
              endDateTime: 1566004032000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566001313000,
          endDateTime: 1566004032000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "d605a4ed-a082-4adc-99b2-541c04e4ec89",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: port,
          selectedPortfolioType: "",
          orderType: "Deposit",
          orderNumber: "786713",
          stopLoss: "",
          takeProfit: "",
          profitLoss: 45.21,
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "More doordash money",
          multiExecution: [],
          exitExecution: [],
          startDateTime: 1566204722000,
          endDateTime: 1566204722000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "6edc5318-ea18-40ca-aa8d-36b406e7e88f",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURUSD"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😔 Pensive", "😖 Frustrated"],
          selectedPhysical: ["🙄 Distracted"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Tilted", "B quality setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "807486",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "1.10872",
          takeProfit: "1.10971",
          profitLoss: -3.36,
          commissions: -1.06,
          fees: 0,
          maxAdEx: "1.10888",
          maxFavEx: "1.10933",
          comments:
            "Not a great setup tbh and took the trade thinking...ahh it just numbers, it's b, should be ok",
          multiExecution: [
            {
              id: "6b5fff65-6692-40f4-9444-ba852159a1df",
              lotSize: 0.16,
              entryPrice: 1.10915,
              startDateTime: 1566212263000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "ebad56aa-930c-4d48-8dc3-aa2ab201f497",
              exitLotSize: 0.16,
              exitPrice: 1.10894,
              endDateTime: 1566212703000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566212263000,
          endDateTime: 1566212703000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "26586ac7-3907-4e0e-a639-56b12d555262",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURNZD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Low",
          selectedEmotion: ["😕 Confused"],
          selectedPhysical: ["😋 Hungry", "😫 Tired"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["Moderate Risk", "C Quality Setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "807639",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "1.72960",
          takeProfit: "1.72301",
          profitLoss: -28.02,
          commissions: -1.86,
          fees: 0,
          maxAdEx: "1.72922",
          maxFavEx: "1.72640",
          comments:
            "Took this trade while I was hungry and pretty tired so not the best trading time...lol...maybe it was an ok setup but still",
          multiExecution: [
            {
              id: "7adf64e7-ab26-4df4-93f6-e67edf9b9646",
              lotSize: 0.28,
              entryPrice: 1.72763,
              startDateTime: 1566218707000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "d2981eb6-92c5-4c2f-b6bc-21a463a56ed2",
              exitLotSize: 0.28,
              exitPrice: 1.72919,
              endDateTime: 1566220504000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566218707000,
          endDateTime: 1566220504000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "cfb6057a-96d4-4f0f-8e67-451636f2253d",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["NZDJPY"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😐 Neutral", "😒 Unamused"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["Moderate Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "807671",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "68.098",
          takeProfit: "68.552",
          profitLoss: 23.45,
          commissions: -0.77,
          fees: 0,
          maxAdEx: "68.115",
          maxFavEx: "68.519",
          comments:
            "Secret Sauce...had to wait a while and missed the best entry but whatever",
          multiExecution: [
            {
              id: "6a1c0c74-849d-4bb3-83b8-525ad01db4ef",
              lotSize: 0.2,
              entryPrice: 68.267,
              startDateTime: 1566221500000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "03d273a5-9f69-4c8e-a855-82d65f7e3ca2",
              exitLotSize: 0.2,
              exitPrice: 68.392,
              endDateTime: 1566239178000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566221500000,
          endDateTime: 1566239178000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "9d2734e2-5090-4293-b907-c41289ef0a01",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["NZDJPY"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😐 Neutral", "😒 Unamused"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["Moderate Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "807670",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "68.098",
          takeProfit: "68.551",
          profitLoss: 22.14,
          commissions: -0.77,
          fees: 0,
          maxAdEx: "68.115",
          maxFavEx: "68.519",
          comments: "identical trade slightly different exit",
          multiExecution: [
            {
              id: "93a0ea5a-039c-454b-b01c-33b8acba57cb",
              lotSize: 0.2,
              entryPrice: 68.267,
              startDateTime: 1566221495000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "7d7573b3-0ad0-4447-992f-5097a9832e3b",
              exitLotSize: 0.2,
              exitPrice: 68.385,
              endDateTime: 1566239206000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566221495000,
          endDateTime: 1566239206000,
          tags: ["2nd trade same setup"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "458c7ebc-d0a3-4666-9b28-811230af9a6c",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURUSD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Low",
          selectedEmotion: ["😖 Frustrated", "😰 Anxious"],
          selectedPhysical: ["😪 Sleepy"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "808034",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "1.11203",
          takeProfit: "1.10864",
          profitLoss: -5.98,
          commissions: -1.74,
          fees: 0,
          maxAdEx: "1.1106",
          maxFavEx: "1.10952",
          comments:
            "Took the trade after getting rejected on tinder. Not happy about it.",
          multiExecution: [
            {
              id: "ad12e7f9-72f3-4cf2-8e8d-6f5f7f462a20",
              lotSize: 0.26,
              entryPrice: 1.11037,
              startDateTime: 1566239454000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "04bd4cf1-db28-40b6-aa5c-95cb22880382",
              exitLotSize: 0.26,
              exitPrice: 1.1106,
              endDateTime: 1566244224000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566239454000,
          endDateTime: 1566244224000,
          tags: ["high slippage"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "24e7fc73-df5d-4ba8-93e7-467c2b1b9ac2",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["AUDJPY"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😄 Joyful"],
          selectedPhysical: ["😫 Tired"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["C Quality Setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "808585",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "72.310",
          takeProfit: "72.085",
          profitLoss: 2.14,
          commissions: -0.78,
          fees: 0,
          maxAdEx: "72.286",
          maxFavEx: "72.188",
          comments:
            "Took this trade feeling pretty good emotionally since I just won some trades",
          multiExecution: [
            {
              id: "6495668e-4919-46c4-a7eb-037fb53c6fb4",
              lotSize: 0.19,
              entryPrice: 72.212,
              startDateTime: 1566258821000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "133c31a4-dc58-4162-9e6d-d45cd133f82a",
              exitLotSize: 0.19,
              exitPrice: 72.2,
              endDateTime: 1566259813000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566258821000,
          endDateTime: 1566259813000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "b7b07358-c0e9-4ce6-9646-8b18c34dcaaf",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["NZDCAD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "High",
          selectedEmotion: ["😄 Ecstatic", "😄 Joyful"],
          selectedPhysical: ["😎 Feelin' good", "🙄 Distracted"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "30-Minute",
          selectedMistake: ["High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "808596",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "0.84061",
          takeProfit: "0.87817",
          profitLoss: 18.26,
          commissions: -0.74,
          fees: -0.06,
          maxAdEx: "0.84923",
          maxFavEx: "0.86144",
          comments: "Long trade...showed patience!",
          multiExecution: [
            {
              id: "3a567389-de1f-45f6-b327-9139ffd42217",
              lotSize: 0.19,
              entryPrice: 0.85262,
              startDateTime: 1566259053000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "0ac4dbce-ac84-44c7-9e70-ff6bddcdb15d",
              exitLotSize: 0.19,
              exitPrice: 0.8539,
              endDateTime: 1566324814000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566259053000,
          endDateTime: 1566324814000,
          tags: ["I was patient"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "142ac667-4372-4f7a-a134-4cd643c8cdef",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURCAD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😩 Weary", "😒 Unamused"],
          selectedPhysical: ["🤒 Sick", "😪 Sleepy"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["C Quality Setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "811832",
          dayOfWeek: "Wednesday",
          monthOfYear: "August",
          stopLoss: "1.47812",
          takeProfit: "1.47497",
          profitLoss: 2.22,
          commissions: -2.46,
          fees: 0,
          maxAdEx: "1.47732",
          maxFavEx: "1.47599",
          comments:
            "Took this trade really in the morning...not there 100% mentally",
          multiExecution: [
            {
              id: "bfd1beca-b0a1-4b2b-988f-ff593d2ac575",
              lotSize: 0.37,
              entryPrice: 1.47686,
              startDateTime: 1566384902000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "9e841953-a07b-469a-90d3-661689222f89",
              exitLotSize: 0.37,
              exitPrice: 1.47678,
              endDateTime: 1566388266000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566384902000,
          endDateTime: 1566388266000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "1d3a7ed2-8097-4485-b133-2ba075a3f458",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURNZD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "High",
          selectedEmotion: ["😔 Pensive"],
          selectedPhysical: ["🤒 Sick", "😫 Tired"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["None"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "812807",
          dayOfWeek: "Wednesday",
          monthOfYear: "August",
          stopLoss: "1.73361",
          takeProfit: "1.72604",
          profitLoss: 14.68,
          commissions: -2.93,
          fees: 0,
          maxAdEx: "1.73228",
          maxFavEx: "1.72979",
          comments:
            "Took this trade base don another chart...don't know if this will be good long term but we will see",
          multiExecution: [
            {
              id: "26432170-14f3-4ad5-969e-fa4cf73eb4bb",
              lotSize: 0.44,
              entryPrice: 1.73096,
              startDateTime: 1566424081000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "37f249c1-dee2-4c44-b1b2-075dd6d60353",
              exitLotSize: 0.44,
              exitPrice: 1.73044,
              endDateTime: 1566431540000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566424081000,
          endDateTime: 1566431540000,
          tags: ["Took trade based on another chart"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "d7831282-d077-4583-81db-0fa6e8b473d3",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["AUDUSD"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Low",
          selectedEmotion: ["😰 Anxious"],
          selectedPhysical: ["🙄 Distracted"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["Over-trading"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "813423",
          dayOfWeek: "Wednesday",
          monthOfYear: "August",
          stopLoss: "0.67988",
          takeProfit: "0.67799",
          profitLoss: -15.4,
          commissions: -1.8,
          fees: 0,
          maxAdEx: "0.67986",
          maxFavEx: "0.69844",
          comments: "Random pic",
          multiExecution: [
            {
              id: "c3d15c97-5b44-4f45-8f19-2eea7a4b710d",
              lotSize: 0.44,
              entryPrice: 0.67916,
              startDateTime: 1566436610000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "15225c4e-0987-4b56-aba5-8f7f15054ac2",
              exitLotSize: 0.44,
              exitPrice: 0.67951,
              endDateTime: 1566439610000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566436610000,
          endDateTime: 1566439610000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "7b76d353-6b5b-49b1-99d4-f67883ac3d12",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["NZDCHF"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Medium",
          selectedEmotion: ["🤑 Greedy"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["B quality setup", "Too low Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "818559",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "0.62988",
          takeProfit: "0.62900",
          profitLoss: -1.09,
          commissions: -0.22,
          fees: 0,
          maxAdEx: "0.62981",
          maxFavEx: "0.62832",
          comments: "Not really a good trade",
          multiExecution: [
            {
              id: "8c58d616-cd63-4474-b5ec-db1eb23e5897",
              lotSize: 0.06,
              entryPrice: 0.62941,
              startDateTime: 1566588476000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "ae477532-cf14-4117-81cd-a67810c80a96",
              exitLotSize: 0.06,
              exitPrice: 0.62959,
              endDateTime: 1566588902000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566588476000,
          endDateTime: 1566588902000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "0498e8ec-7efc-4142-878a-e10d92b0a8c6",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURUSD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "High",
          selectedEmotion: ["😄 Ecstatic"],
          selectedPhysical: ["🥶 Cold"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["None"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "819223",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "1.10553",
          takeProfit: "1.10874",
          profitLoss: 50.4,
          commissions: -2.32,
          fees: 0,
          maxAdEx: "1.10618",
          maxFavEx: "1.10822",
          comments: "",
          multiExecution: [
            {
              id: "137d6d27-9174-4ad1-9048-1f31f1c98a19",
              lotSize: 0.35,
              entryPrice: 1.10675,
              startDateTime: 1566602325000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "71856d07-a897-4eac-a933-2d4ddae715a9",
              exitLotSize: 0.35,
              exitPrice: 1.10819,
              endDateTime: 1566605028000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566602325000,
          endDateTime: 1566605028000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "c3173f6c-555d-4855-83ed-0740f1eb8dfa",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURUSD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "High",
          selectedEmotion: ["😄 Ecstatic", "🤠 Feeling like gambling"],
          selectedPhysical: ["🥶 Cold"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["None"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "819185",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "1.10553",
          takeProfit: "1.10919",
          profitLoss: 48.6,
          commissions: -2.4,
          fees: 0,
          maxAdEx: "1.10618",
          maxFavEx: "1.10822",
          comments: "last trade is similar",
          multiExecution: [
            {
              id: "99d8df8b-24b0-4b53-b9fa-3a27813bd293",
              lotSize: 0.36,
              entryPrice: 1.10679,
              startDateTime: 1566601461000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "85503a95-23e0-44b9-9e85-df7f14945e02",
              exitLotSize: 0.36,
              exitPrice: 1.10814,
              endDateTime: 1566605029000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566601461000,
          endDateTime: 1566605029000,
          tags: ["2nd trade same setup"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "7730edb6-ad32-4a9a-be0e-248e692e5714",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPCHF"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Medium",
          selectedEmotion: ["🤠 Feeling like gambling", "🤑 Greedy"],
          selectedPhysical: ["🥶 Cold"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["B quality setup", "High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "819104",
          dayOfWeek: "Friday",
          monthOfYear: "August",
          stopLoss: "1.20504",
          takeProfit: "1.19913",
          profitLoss: 110.71,
          commissions: -4.05,
          fees: 0,
          maxAdEx: "1.20417",
          maxFavEx: "1.20149",
          comments: "Way too high risk but got away with it again",
          multiExecution: [
            {
              id: "f630be36-bef6-445b-aee1-65238926076e",
              lotSize: 0.55,
              entryPrice: 1.20355,
              startDateTime: 1566599765000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "f802e118-9dc1-49e0-8279-bc133325b1cf",
              exitLotSize: 0.55,
              exitPrice: 1.20158,
              endDateTime: 1566608740000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566599765000,
          endDateTime: 1566608740000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "b7bdbe69-69fc-4354-ae6b-a1e678a0b48b",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: port,
          selectedPortfolioType: "",
          orderType: "Deposit",
          orderNumber: "800907",
          stopLoss: "",
          takeProfit: "",
          profitLoss: 84.52,
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "more doordash money",
          multiExecution: [],
          exitExecution: [],
          startDateTime: 1566726487000,
          endDateTime: 1566726487000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "c7113c59-8a16-457e-82a6-5d1ec05b7805",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["NZDCHF"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Low",
          selectedEmotion: ["😌 Relaxed"],
          selectedPhysical: ["😫 Tired"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "30-Minute",
          selectedMistake: ["C Quality Setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "824297",
          dayOfWeek: "Monday",
          monthOfYear: "August",
          stopLoss: "0.62564",
          takeProfit: "0.62310",
          profitLoss: -46.66,
          commissions: -1.5,
          fees: 0,
          maxAdEx: "0.62592",
          maxFavEx: "0.62420",
          comments:
            "It was an okay trade but not a great setup...thought I could get away with it",
          multiExecution: [
            {
              id: "42a27d86-f5de-44f0-b433-94376be9b29c",
              lotSize: 0.39,
              entryPrice: 0.62447,
              startDateTime: 1566859269000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "fb57c914-f3da-44b6-ad81-fd3e4b08fb18",
              exitLotSize: 0.39,
              exitPrice: 0.62564,
              endDateTime: 1566871350000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1566859269000,
          endDateTime: 1566871350000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "1b5fbece-32e1-491e-a8aa-88fbcc3d3669",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPCAD"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "Low",
          selectedEmotion: ["🤑 Greedy", "😄 Ecstatic"],
          selectedPhysical: ["😪 Sleepy"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["High Risk", "B quality setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "1243065",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "1.72216",
          takeProfit: "1.72399",
          profitLoss: 115.56,
          commissions: -21.72,
          fees: 0,
          maxAdEx: "1.72229",
          maxFavEx: "1.72414",
          comments:
            "really short trade, high risk, again high reward lol. Not a good habit",
          multiExecution: [
            {
              id: "19c695a2-a615-4a82-988d-3a70f48e403b",
              lotSize: 2.82,
              entryPrice: 1.72275,
              startDateTime: 1583377079000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "90517490-3ffe-4968-a131-f80ba29fb2c1",
              exitLotSize: 2.82,
              exitPrice: 1.7233,
              endDateTime: 1583377374000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1583377079000,
          endDateTime: 1583377374000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "924099cc-61f7-41eb-a1e9-0000bd545c31",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EURGBP"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😄 Ecstatic", "🤠 Feeling like gambling"],
          selectedPhysical: ["🙄 Distracted"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["High Risk", "Over-trading"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "1243336",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "0.86673",
          takeProfit: "0.86514",
          profitLoss: -53.43,
          commissions: -8.68,
          fees: 0,
          maxAdEx: "0.86644",
          maxFavEx: "0.86571",
          comments: "Definitely overtrading on this one",
          multiExecution: [
            {
              id: "48983393-1cff-4081-ac6a-484816e745b2",
              lotSize: 1.3,
              entryPrice: 0.86605,
              startDateTime: 1583380324000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "2e2459ad-553f-41af-a0d7-db348ca1f71a",
              exitLotSize: 1.3,
              exitPrice: 0.86637,
              endDateTime: 1583380871000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1583380324000,
          endDateTime: 1583380871000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "f3351403-1804-4b5b-95c6-9f3d4c71eb96",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPJPY"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😀 Happy", "😄 Ecstatic"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Moderate Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "1243363",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "137.877",
          takeProfit: "138.168",
          profitLoss: 32.58,
          commissions: -5.4,
          fees: 0,
          maxAdEx: "137.944",
          maxFavEx: "138.091",
          comments:
            "Nice setup...I was definitely alert from eating tacos before",
          multiExecution: [
            {
              id: "b1431876-61c6-413b-8cc3-e7884762b103",
              lotSize: 0.7,
              entryPrice: 137.986,
              startDateTime: 1583381018000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "a6143847-849c-4bd2-9672-c885b071fec5",
              exitLotSize: 0.7,
              exitPrice: 138.036,
              endDateTime: 1583381495000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1583381018000,
          endDateTime: 1583381495000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "78020ecd-0889-47bf-a897-8453282fc77d",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPJPY"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😐 Neutral"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["B quality setup"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "1243444",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "138.104",
          takeProfit: "138.209",
          profitLoss: 38.82,
          commissions: -7.48,
          fees: 0,
          maxAdEx: "138.088",
          maxFavEx: "138.179",
          comments: "Just a solid trade...b quality setup but it was like b+",
          multiExecution: [
            {
              id: "204a0059-8b8f-48e4-b377-eb20134797a5",
              lotSize: 0.97,
              entryPrice: 138.136,
              startDateTime: 1583381942000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "9f3163e0-1f6e-4728-b763-aaecb414b7e6",
              exitLotSize: 0.97,
              exitPrice: 138.179,
              endDateTime: 1583383462000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1583381942000,
          endDateTime: 1583383462000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "94a83e23-95dc-470d-956c-9ff257273c54",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["GBPCAD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Low",
          selectedEmotion: ["😩 Weary", "🤠 Feeling like gambling"],
          selectedPhysical: ["🥵 Hot"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Tilted", "Moderate Risk", "Past stop loss"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "1243664",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "1.72456",
          takeProfit: "1.72294",
          profitLoss: -16.87,
          commissions: -9.18,
          fees: 0,
          maxAdEx: "1.72510",
          maxFavEx: "1.72338",
          comments: "I let this go way past my stop loss...not smart",
          multiExecution: [
            {
              id: "0d429ae4-d8dc-4722-bed5-b0566232c856",
              lotSize: 1.19,
              entryPrice: 1.72399,
              startDateTime: 1583384919000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "580931c5-2d30-4ea2-b0c5-73cd32a2f26c",
              exitLotSize: 1.19,
              exitPrice: 1.72418,
              endDateTime: 1583384934000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1583384919000,
          endDateTime: 1583384934000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "af24826a-fcc5-473a-89b6-f60164952ce5",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["ADAUSD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😀 Happy"],
          selectedPhysical: ["😎 Feelin' good", "🥶 Cold"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["Got Out Early"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423523474",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "0.4764",
          takeProfit: "0.4702",
          profitLoss: 44,
          commissions: -20,
          fees: 0,
          maxAdEx: "0.4778",
          maxFavEx: "0.4729",
          comments: "Got out a little early but nice trade overall!",
          multiExecution: [
            {
              id: "027ad047-4229-4897-bf80-fcee1e1c9072",
              lotSize: 4,
              entryPrice: 0.4742,
              startDateTime: 1655117800000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "e713ca11-f0f7-4265-9104-ab720e02c00e",
              exitLotSize: 4,
              exitPrice: 0.4731,
              endDateTime: 1655119204000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655117800000,
          endDateTime: 1655119204000,
          tags: ["Crypto trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "e0cffd13-21d4-4f9f-84f9-8b90514e43bf",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["BTCUSD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Low",
          selectedEmotion: ["😞 Disappointed", "😡 Fuming"],
          selectedPhysical: ["😪 Sleepy"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Moderate Risk", "Tilted"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423526418",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "26323.21",
          takeProfit: "26000",
          profitLoss: -19.32,
          commissions: -0.25,
          fees: 0,
          maxAdEx: "26259.60",
          maxFavEx: "26148.67",
          comments:
            "Some personal stuff going on but I was not in the right headspace to take the trade. Looked good though so idk kinda pissed",
          multiExecution: [
            {
              id: "cd74ea80-dbc7-4113-82a2-e0c68a280333",
              lotSize: 0.05,
              entryPrice: 26176.17,
              startDateTime: 1655119386000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "47325309-58ae-4f27-b4d4-705797c80b91",
              exitLotSize: 0.05,
              exitPrice: 26214.8,
              endDateTime: 1655119571000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655119386000,
          endDateTime: 1655119571000,
          tags: ["Crypto trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "076ca6f9-2cbe-425d-b0e6-bf848cf5f815",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EOSUSD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😰 Anxious", "🤑 Greedy"],
          selectedPhysical: ["😫 Tired"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423526901",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "1.032",
          takeProfit: "0.866",
          profitLoss: 15,
          commissions: -25,
          fees: 0,
          maxAdEx: "0.992",
          maxFavEx: "0.9813",
          comments:
            "Really high risk trade...got out after seeing some drawdown",
          multiExecution: [
            {
              id: "73afca8e-b1f3-49da-8a4e-098353c7431b",
              lotSize: 5,
              entryPrice: 0.9867,
              startDateTime: 1655119591000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "b65cdfe0-c2f1-4e13-ba24-8f88aaa675db",
              exitLotSize: 5,
              exitPrice: 0.9837,
              endDateTime: 1655125850000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655119591000,
          endDateTime: 1655125850000,
          tags: ["Crypto trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "5bee368d-a1be-4120-8a8b-12ae971738b3",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["EOSUSD"],
            pointValue: 0,
          },
          strategy: ["Support/Resistance"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😰 Anxious", "🤑 Greedy"],
          selectedPhysical: ["😫 Tired"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "15-Minute",
          selectedMistake: ["High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423526921",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "1.032",
          takeProfit: "0.866",
          profitLoss: 18,
          commissions: -25,
          fees: 0,
          maxAdEx: "0.9925",
          maxFavEx: "0.98123",
          comments: "Same trade as last one...so essentially double the risk",
          multiExecution: [
            {
              id: "2c46f321-2d9d-4ff5-a229-5daaf9145224",
              lotSize: 5,
              entryPrice: 0.9875,
              startDateTime: 1655119595000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "dc9ce200-e5be-42b9-8ddb-6e1c3ac2210d",
              exitLotSize: 5,
              exitPrice: 0.9839,
              endDateTime: 1655125864000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655119595000,
          endDateTime: 1655125864000,
          tags: ["Crypto trade", "2nd trade same setup"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "43fdfbbd-feaa-4abe-bef6-bcc4ad5c9bd4",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["ETHUSD"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "High",
          selectedEmotion: ["😂 Tears of Joy", "😤 Triumphant"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Sideways Volatile",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["High Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423543479",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "1399.70",
          takeProfit: "1274.91",
          profitLoss: 210.6,
          commissions: -0.45,
          fees: 0,
          maxAdEx: "1378.61",
          maxFavEx: "1329.48",
          comments:
            "I was very confident in this trade, never have I felt that good about it",
          multiExecution: [
            {
              id: "93a2ff1d-02c8-4ba0-b109-0886dd1485c8",
              lotSize: 0.09,
              entryPrice: 1354.56,
              startDateTime: 1655133204000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "4c6e7270-2be0-43b2-b9ab-e5a899c39453",
              exitLotSize: 0.09,
              exitPrice: 1331.16,
              endDateTime: 1655135846000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655133204000,
          endDateTime: 1655135846000,
          tags: ["Crypto trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "169081dc-e6c1-4e0d-9932-ed992c2ab0a8",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["AUDJPY"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😨 Fearful", "😤 Triumphant"],
          selectedPhysical: ["🤕 Physically injured", "🙄 Distracted"],
          selectedMarket: "Trending Volatile",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Got Out Early"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423592263",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "93.199",
          takeProfit: "93.087",
          profitLoss: 21.31,
          commissions: -7.5,
          fees: 0,
          maxAdEx: "93.184",
          maxFavEx: "93.116",
          comments:
            "Took a position size large relative to the spread but my stop loss was close so it's alright",
          multiExecution: [
            {
              id: "23d3e948-50d1-42b4-9a4e-5b40bc2f5843",
              lotSize: 1.5,
              entryPrice: 93.167,
              startDateTime: 1655163595000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "d7fc1e1b-9435-41d2-89e9-3e997af60a00",
              exitLotSize: 1.5,
              exitPrice: 93.148,
              endDateTime: 1655163759000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655163595000,
          endDateTime: 1655163759000,
          tags: ["high slippage"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "b70f319a-c05b-44dd-98a3-23ee0cf95178",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["XRPUSD"],
            pointValue: 0,
          },
          strategy: ["Secret Sauce"],
          selectedConfidence: "High",
          selectedEmotion: ["😐 Neutral"],
          selectedPhysical: ["😎 Feelin' good"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "5-Minute",
          selectedMistake: ["Too low Risk"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423599563",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "0.31432",
          takeProfit: "0.30586",
          profitLoss: 23.28,
          commissions: -0.2,
          fees: 0,
          maxAdEx: "0.31268",
          maxFavEx: "0.30605",
          comments: "Stuck to my RRR, great setup, textbook trade honestly",
          multiExecution: [
            {
              id: "9db51669-3f22-4786-a814-ce8e54dd4a80",
              lotSize: 0.04,
              entryPrice: 0.31187,
              startDateTime: 1655165418000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "97e8c392-cdb3-4b62-b585-f785fe7e99dc",
              exitLotSize: 0.04,
              exitPrice: 0.30605,
              endDateTime: 1655167946000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655165418000,
          endDateTime: 1655167946000,
          tags: ["Crypto trade", "Stuck to my RRR"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "fbe3ad79-1ffc-40eb-bcd9-1bfce9c2eb12",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["XRPUSD"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Low",
          selectedEmotion: ["😄 Ecstatic", "🤠 Feeling like gambling"],
          selectedPhysical: ["😪 Sleepy"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Over-trading"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423609792",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "0.30894",
          takeProfit: "0.30143",
          profitLoss: -10.96,
          commissions: -0.2,
          fees: 0,
          maxAdEx: "0.30944",
          maxFavEx: "0.30472",
          comments:
            "Took this trade as a follow up[ of the previous, basically once I got out I took another short from a momo high setup but it did not work out....",
          multiExecution: [
            {
              id: "2c1e1cd8-a2c5-4c7a-8457-5ff513701def",
              lotSize: 0.04,
              entryPrice: 0.30587,
              startDateTime: 1655168398000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "2308be72-d36e-4c14-9900-dea4a6585c4e",
              exitLotSize: 0.04,
              exitPrice: 0.30861,
              endDateTime: 1655168486000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655168398000,
          endDateTime: 1655168486000,
          tags: ["Continuation"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "a83d2abd-f1c2-40b6-a9d7-5a6550eb859e",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["DOTUSD"],
            pointValue: 0,
          },
          strategy: ["Break Out"],
          selectedConfidence: "Medium",
          selectedEmotion: ["😲 Astonished"],
          selectedPhysical: ["🥶 Cold", "😎 Feelin' good"],
          selectedMarket: "Trending Smooth",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["Got Out Early"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Short",
          orderNumber: "423612167",
          dayOfWeek: "Monday",
          monthOfYear: "June",
          stopLoss: "6.871",
          takeProfit: "6.190",
          profitLoss: "31.49",
          commissions: -0.3,
          fees: 0,
          maxAdEx: "6.763",
          maxFavEx: "6.277",
          comments: "Quick in and out",
          multiExecution: [
            {
              id: "163ee9e5-739e-4179-af9f-9b6c1f8902a4",
              lotSize: 0.06,
              entryPrice: 6.669,
              startDateTime: 1655169237000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "4460c5e5-6b2c-47a0-a338-886a40f8c9ea",
              exitLotSize: 0.06,
              exitPrice: "6.466",
              endDateTime: 1655169322000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "Short",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1655169237000,
          endDateTime: 1655169322000,
          tags: ["Crypto trade"],
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
      {
        entryId: "7e9b463c-87d5-46e0-9e10-a8cb128e66d0",
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: ["USDCHF"],
            pointValue: 0,
          },
          strategy: ["Momo High"],
          selectedConfidence: "Medium",
          selectedEmotion: "",
          selectedPhysical: ["😵 Dizzy"],
          selectedMarket: "Sideways Dead",
          selectedTimeframe: "1-Minute",
          selectedMistake: ["C Quality Setup", "Tilted"],
          selectedPortfolio: port,
          selectedPortfolioType: "Forex",
          orderType: "Long",
          orderNumber: "1629252854",
          dayOfWeek: "Wednesday",
          monthOfYear: "March",
          stopLoss: "0.92749",
          takeProfit: "0.93056",
          profitLoss: -24.38,
          commissions: 0,
          fees: 0,
          maxAdEx: "0.92733",
          maxFavEx: "0.92914",
          comments: "",
          multiExecution: [
            {
              id: "f00027e4-c9ae-4e70-bb69-766f4a5ca305",
              lotSize: 0.17,
              entryPrice: 0.92884,
              startDateTime: 1678932600000,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            },
          ],
          exitExecution: [
            {
              id: "8f61a8a8-df80-4ee7-b4be-164acf8c8183",
              exitLotSize: 0.17,
              exitPrice: 0.92751,
              endDateTime: 1678933107000,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            },
          ],
          startDateTime: 1678932600000,
          endDateTime: 1678933107000,
          tags: "",
          verifiedLevel: 0,
          idLinks: [],
          demoData: true,
        },
      },
    ],
    settings: {
      currencySymbol: "&#36;",
      historyColumnDisplay: {
        dateTimeOpened: true,
        dateTimeClosed: true,
        holdingTime: true,
        profitLoss: true,
        netPLdollar: true,
        netPLpercent: true,
        orderType: true,
        symbol: true,
        executions: true,
        lotSize: true,
        entryPrice: true,
        expectedEntry: true,
        stopLoss: true,
        takeProfit: true,
        exitPrice: true,
        expectedExit: true,
        slippage: true,
        commissions: true,
        fees: true,
        maxAdEx: true,
        maxFavEx: true,
        efficiency: true,
        riskpercent: true,
        rewardriskratio: true,
        rmultiple: true,
        strategy: true,
        selectedConfidence: true,
        selectedEmotion: true,
        selectedPhysical: true,
        selectedMarket: true,
        selectedTimeFrame: true,
        selectedMistake: true,
        selectedTags: true,
      },
      colorTheme: "dark",
      calculationType: "Gross",
      performanceMetricCalculationType: "Dollar",
      performanceMetricCalculationAriGeo: "Arithmetic",
      performanceMetricGraphs: [],
      linkedBrokerInfo: {
        broker: "",
      },
      dateFilterBy: "Trade Open",
      filters: {
        dateRangeFilter: null,
        hourRangeFilter: null,
        orderTypeFilter: null,
        symbolFilter: null,
        strategyFilter: null,
        emotionalStateFilter: null,
        physicalStateFilter: null,
        mistakeFilter: null,
        timeFrameFilter: null,
        confidenceFilter: null,
        marketStateFilter: null,
        dayOfWeekFilter: null,
        monthFilter: null,
        assetClassFilter: null,
        tagsFilter: null,
        yearFilter: null,
      },
      savefilters: false,
      sidepanelprops: [
        {
          width: 0,
          state: "Shared Trades",
        },
      ],
      drawdownCalculationType: "Dollar",
      startingBalance: 0,
      drawdownToggle: "Drawdown",
      dashboardElements: {
        order: [
          "tickertape",
          "toppinnedmetrics",
          "maingraphs",
          "pinnedPercentages",
        ],
        tickertape: {
          display: true,
          symbols: [],
        },
        toppinnedmetrics: [
          "avgholdingtime",
          "pl24hr",
          "tfindex",
          "profitlossratio",
          "avgPLpertrade",
        ],
        maingraphs: [
          {
            sorted: true,
            name: "pnlhistorygraph",
          },
          {
            sorted: true,
            name: "cummulativereturn",
          },
          {
            sorted: true,
            name: "pnlbytimegraph",
          },
          {
            sorted: true,
            name: "pnlbydayofweekgraph",
          },
          {
            sorted: true,
            name: "pnlbymonthgraph",
          },
          {
            sorted: true,
            name: "pnlbysessiongraph",
          },
          {
            sorted: true,
            name: "pnlbystrategygraph",
          },
          {
            sorted: true,
            name: "pnlbymistakegraph",
          },
          {
            sorted: true,
            name: "pnlbyemotionsgraph",
          },
          {
            sorted: true,
            name: "pnlbyphysicalstategraph",
          },
          {
            sorted: true,
            name: "pnlbytimeframegraph",
          },
          {
            sorted: true,
            name: "pnlbymarketstategraph",
          },
          {
            sorted: true,
            name: "pnlbyConfidencegraph",
          },
          {
            sorted: true,
            name: "pnlbytagsgraph",
          },
        ],
        pinnedPercentages: [
          "maxdrawdownpercent",
          "averageefficiency",
          "winlossesbe",
          "averagerisk",
          "percenttimeinmarket",
        ],
      },
      comparisonObjs: [],
      comparerKPIbundles: [],
    },
  };
};
export default demoData;
