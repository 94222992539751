import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import getSpecificPortfolio from "../../functions/getSpecificPortfolio";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import { handleNANGOOD } from "../../functions/handleUndefined";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import { getMaxDDPercent } from "../../functions/calculations_for_metrics/getmaxDD";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import "./styles.scss";
let he = require("he");

function usePrevious(data) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
}
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
function percentile(arr, percentile) {
  // Sort the array
  arr.sort(function (a, b) {
    return a - b;
  });

  // Find the index of the nth percentile
  const index = Math.ceil((percentile / 100) * arr.length) - 1;

  // Return the value at that index
  return arr[index];
}

function calculateTradingStats(accountBalances) {
  let maxConsecutiveLosses = 0;
  let maxConsecutiveWins = 0;
  let maxDrawdown = -Infinity;

  for (let i = 0; i < accountBalances.length; i++) {
    const balances = accountBalances[i];
    let currentConsecutiveLosses = 0;
    let currentConsecutiveWins = 0;
    let currentDrawdown = getMaxDDPercent(balances);
    maxDrawdown = currentDrawdown > maxDrawdown ? currentDrawdown : maxDrawdown;
    for (let j = 1; j < balances.length; j++) {
      if (balances[j] < balances[j - 1]) {
        currentConsecutiveLosses++;
        currentConsecutiveWins = 0;
      } else {
        currentConsecutiveWins++;
        currentConsecutiveLosses = 0;
      }
      maxConsecutiveLosses = Math.max(
        maxConsecutiveLosses,
        currentConsecutiveLosses
      );
      maxConsecutiveWins = Math.max(maxConsecutiveWins, currentConsecutiveWins);
    }
  }

  return { maxConsecutiveLosses, maxConsecutiveWins, maxDrawdown };
}
export const PositionSizeCalculatorComponent = (props) => {
  const userData = props.userData;
  const allData = props.processedData;
  const tier = props.tier;
  const active = props.active;

  const calculations = allData.calculations;
  const portfolio = getSpecificPortfolio(userData);
  const [hasUseMetrics, sethasUseMetrics] = useState();
  const [simulationResults, setsimulationResults] = useState({
    numberOfShares: "-",
    positionSize: "-",
    leverageReq: "-",
    amountRisked: "-",
  });
  const [simulationTime, setsimulationTime] = useState(0);

  const firstUpdate = useRef(true);

  const supportedCryptosCheck = supportedCryptos.includes(
    String(allData.portfolio.settings.currencySymbol)
  );

  // Tiering
  let showMaster = false;

  if (tier === "free") {
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    showMaster = true;
  } else if (tier === "admin" && active) {
    showMaster = true;
  } else {
  }
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, []);

  function calculatePositionSize(
    riskPercent,
    entryPrice,
    stopLoss,
    accountBalance
  ) {
    // Calculate the total amount of money you're willing to risk
    let riskAmount = (riskPercent / 100) * accountBalance;

    // Calculate the risk per share
    let riskPerShare = Math.abs(entryPrice - stopLoss);

    // Calculate the number of shares to buy
    let numberOfShares = (riskAmount / riskPerShare).toFixed(2);

    // Calculate the total position size
    let positionSize = numberOfShares * entryPrice;

    let fixPositionSize = supportedCryptosCheck
      ? Number(positionSize).toFixed(6)
      : Number(positionSize).toFixed(2);
    let fixamountRisked = supportedCryptosCheck
      ? Number(riskAmount).toFixed(6)
      : Number(riskAmount).toFixed(2);
    let leverageReq = Number(positionSize / accountBalance).toFixed(2);

    // Return the results in an object with two properties
    return {
      numberOfShares: handleNANGOOD(numberOfShares, "-"),
      positionSize: handleNANGOOD(fixPositionSize, "-"),
      leverageReq: handleNANGOOD(leverageReq, "-"),
      amountRisked: handleNANGOOD(fixamountRisked, "-"),
    };
  }

  const [state, setState] = useState({
    riskPercent: 2,
    entryPrice: 200,
    stopLoss: 190,
    accountBalance: 10000,
  });
  const { riskPercent, entryPrice, stopLoss, accountBalance } = state;
  const prevWR = usePrevious(riskPercent);
  const prevPLR = usePrevious(stopLoss);
  const prevRisk = usePrevious(entryPrice);
  const prevBal = usePrevious(accountBalance);
  const movecursor = {
    paddingLeft: "10px",
  };
  return (
    <div className="simulatorWrapper">
      <div className="secondsimwrapper">
        <div className="PSCDirectionsWrapper">
          <br />
          <br />
          <div style={{ color: "#fff7cb" }}>
            <b style={{ color: "#fbd665", fontSize: "1.2em" }}>
              Welcome to our Position Size Calculator!
            </b>{" "}
            <br />
            <br />
            This tool helps you determine the appropriate amount of stock or
            securities to buy based on your trading strategy's risk parameters.
            To ensure accurate calculations, be sure to enter:
          </div>
          <br />
          <div>
            1. <b style={{ color: "#fff7cb" }}>Account Balance:</b> Enter your
            total trading account balance.
          </div>
          <div>
            2. <b style={{ color: "#fff7cb" }}>Risk Percentage:</b> Input the
            percentage of your account that you are willing to risk on a single
            trade.
          </div>
          <div>
            3. <b style={{ color: "#fff7cb" }}>Entry Price:</b> The price you
            entered the trade at.
          </div>
          <div>
            4. <b style={{ color: "#fff7cb" }}>Stop Loss</b> The price level at
            which you'll exit to prevent further losses if the market goes
            against you.
          </div>
          <br />
          <div style={{ color: "#fbd665" }}>
            We are continuously working to improve this tool and expand its
            capabilities. Thank you for using our Position Size Calculator!
          </div>
          <br />
          <div style={{ color: "#808080", textDecoration: "underline" }}>
            <b>Notes</b>{" "}
          </div>
          <div style={{ color: "#808080" }}>
            <b>- Current Limitations:</b> This tool is under ongoing development
            and currently does not support calculations for FOREX trading.
          </div>
          <div style={{ color: "#808080" }}>
            <b>- Accuracy:</b> While we strive for accuracy, market conditions
            and price fluctuations can impact the effectiveness of your
            calculated position size. Always review your calculations based on
            current market data.
          </div>
        </div>
        <div className="PSCinputWrapper">
          <div className="siminputs-heading">Position Size Calculator</div>
          <div className="simuserinputWrapperinner">
            <div className="showEDITExecsBoxtext2">
              <span>Inputs</span>
            </div>
            <label className="SIMselectionfields">
              Account Balance
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="accountBalance"
                style={movecursor}
                value={accountBalance}
                onChange={(e) => {
                  setState({ ...state, accountBalance: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Risk %<span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="riskPercent"
                style={movecursor}
                value={riskPercent}
                onChange={(e) => {
                  setState({ ...state, riskPercent: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Entry Price
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="entryPrice"
                style={movecursor}
                value={entryPrice}
                onChange={(e) => {
                  setState({ ...state, entryPrice: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
            <label className="SIMselectionfields">
              Stop Loss
              <span className="mandatory profitLossErrorMsg">{""}</span>
              <input
                disabled={!showMaster}
                name="stopLoss"
                style={movecursor}
                value={stopLoss}
                onChange={(e) => {
                  setState({ ...state, stopLoss: e.target.value });
                }}
                className="text-input-boxes"
                autoComplete="off"
                pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              />
            </label>{" "}
          </div>
          <div className="simuserinputWrapperinner">
            <div className="showEDITExecsBoxtext2">
              <span>Results</span>
            </div>{" "}
            <div className="PSCtitlewrapper">
              {" "}
              <div className="PSCtitle2">
                Number of Shares
                <div className="PSCtitle2x">
                  {simulationResults?.numberOfShares}
                </div>
              </div>
              <div className="PSCtitle3">
                Position Size
                <div className="PSCtitle3x">
                  {he.decode(String(allData.portfolio.settings.currencySymbol))}
                  {simulationResults?.positionSize}
                </div>
              </div>{" "}
              <div className="PSCtitle2">
                Leverage Required
                <div className="PSCtitle3x">
                  {simulationResults?.leverageReq}
                  {"x"}
                </div>
              </div>{" "}
              <div className="PSCtitle3">
                Amount Risked
                <div className="PSCtitle3x">
                  {he.decode(String(allData.portfolio.settings.currencySymbol))}
                  {simulationResults?.amountRisked}
                </div>
              </div>{" "}
            </div>
            {/*             {simulationTime !== 0 && (
              <div>Simulation successful! Run in {simulationTime}s</div>
            )} */}
            <button
              disabled={!showMaster}
              onClick={() => {
                var startTime, endTime;
                startTime = new Date();

                const simulationResults = calculatePositionSize(
                  riskPercent,
                  entryPrice,
                  stopLoss,
                  accountBalance
                );
                setsimulationResults(simulationResults);
              }}
              id="runsimulationbutton2"
            >
              Calculate
            </button>
            <button
              disabled={!showMaster}
              onClick={() => {
                setState({
                  ...state,
                  riskPercent: "",
                  entryPrice: "",
                  stopLoss: "",
                  accountBalance: "",
                });
                sethasUseMetrics(false);
              }}
              id="runsimcancelbutton2"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionSizeCalculatorComponent;
