export const symbols = [
  { value: "audcad", label: "AUDCAD" },
  { value: "audchf", label: "AUDCHF" },
  { value: "audjpy", label: "AUDJPY" },
  { value: "audnzd", label: "AUDNZD" },
  { value: "audusd", label: "AUDUSD" },
  { value: "cadchf", label: "CADCHF" },
  { value: "cadjpy", label: "CADJPY" },
  { value: "chfjpy", label: "CHFJPY" },
  { value: "euraud", label: "EURAUD" },
  { value: "eurcad", label: "EURCAD" },
  { value: "eurchf", label: "EURCHF" },
  { value: "eurgbp", label: "EURGBP" },
  { value: "eurjpy", label: "EURJPY" },
  { value: "eurnzd", label: "EURNZD" },
  { value: "eurusd", label: "EURUSD" },
  { value: "gbpaud", label: "GBPAUD" },
  { value: "gbpcad", label: "GBPCAD" },
  { value: "gbpchf", label: "GBPCHF" },
  { value: "gbpjpy", label: "GBPJPY" },
  { value: "gbpnzd", label: "GBPNZD" },
  { value: "gbpusd", label: "GBPUSD" },
  { value: "nzdcad", label: "NZDCAD" },
  { value: "nzdchf", label: "NZDCHF" },
  { value: "nzdjpy", label: "NZDJPY" },
  { value: "nzdusd", label: "NZDUSD" },
  { value: "usdcad", label: "USDCAD" },
  { value: "usdchf", label: "USDCHF" },
  { value: "usdjpy", label: "USDJPY" },
  { value: "xauusd", label: "XAUUSD" },
  { value: "xagusd", label: "XAGUSD" },
  { value: ".WTICrude", label: ".WTICrude" },
  { value: ".BrentCrude", label: ".BrentCrude" },
];

export const strategy = [
  { value: "fundamental", label: "Fundamental" },
  { value: "newsplay", label: "News Play" },
  { value: "supportResistance", label: "Support/Resistance" },
  { value: "breakout", label: "Breakout" },
  { value: "retracement", label: "Retracement" },
  { value: "fibRetracement", label: "Fib Retracement" },
  { value: "momoHigh", label: "MOMO High" },
  { value: "momoLow", label: "MOMO Low" },
  { value: "scalp", label: "Scalp" },
  { value: "emaCross", label: "EMA Cross" },
  { value: "macdDivergence", label: "MACD Divergence" },
  { value: "vwap", label: "VWAP" },
  { value: "elliotWave", label: "Elliot Wave" },
];

export const confidence = [
  { value: "veryHigh", label: "Very High" },
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
  { value: "veryLow", label: "Very Low" },
];

export const physicals = [
  { value: "Cold", label: "🥶 Cold" },
  { value: "Distracted", label: "🙄 Distracted" },
  { value: "Dizzy", label: "😵 Dizzy" },
  { value: "Feelin' good", label: "😎 Feelin' good" },
  { value: "Hot", label: "🥵 Hot" },
  { value: "Hungry", label: "😋 Hungry" },
  { value: "Physically injured", label: "🤕 Physically injured" },
  { value: "Sick", label: "🤒 Sick" },
  { value: "Sleepy", label: "😪 Sleepy" },
  { value: "Tired", label: "😫 Tired" },
  { value: "Woozy", label: "🥴 Woozy" },
];

export const emotions = [
  { value: "Angry", label: "😠 Angry" },
  { value: "Astonished", label: "😲 Astonished" },
  { value: "Anxious", label: "😰 Anxious" },
  { value: "Confused", label: "😕 Confused" },
  { value: "Crying", label: "😭 Crying" },
  { value: "Disappointed", label: "😞 Disappointed" },
  {
    value: "Disappointed, but relieved",
    label: "😢 Disappointed, but relieved",
  },
  { value: "Expressionless", label: "😑 Expressionless" },
  { value: "Fearful", label: "😨 Fearful" },
  { value: "Feeling like gambling", label: "🤠 Feeling like gambling" },
  { value: "Frustrated", label: "😖 Frustrated" },
  { value: "Fuming", label: "😡 Fuming" },
  { value: "Greedy", label: "🤑 Greedy" },
  { value: "Ecstatic", label: "😄 Ecstatic" },
  { value: "Happy", label: "😀 Happy" },
  { value: "Joyful", label: "😄 Joyful" },
  { value: "Neutral", label: "😐 Neutral" },
  { value: "Pensive", label: "😔 Pensive" },
  { value: "Relaxed", label: "😌 Relaxed" },
  { value: "Tears of Joy", label: "😂 Tears of Joy" },
  { value: "Triumphant", label: "😤 Triumphant" },
  { value: "Unamused", label: "😒 Unamused" },
  { value: "Weary", label: "😩 Weary" },
  { value: "Worried", label: "😟 Worried" },
];

export const market = [
  { value: "trendingSmooth", label: "Trending Smooth" },
  { value: "trendingVolatile", label: "Trending Volatile" },
  { value: "sidewaysVolatile", label: "Sideways Volatile" },
  { value: "sidewaysDead", label: "Sideways Dead" },
];

export const assetClasses = [
  { value: "Forex", label: "Forex" },
  { value: "Options", label: "Options" },
  { value: "Stocks", label: "Stocks" },
  { value: "Futures", label: "Futures" },
  { value: "Crypto", label: "Crypto" },
  { value: "CFD", label: "CFD" },
];

export const timeFrame = [
  { value: "1Min", label: "1-Minute" },
  { value: "5Min", label: "5-Minute" },
  { value: "15Min", label: "15-Minute" },
  { value: "30Min", label: "30-Minute" },
  { value: "1hour", label: "1-Hour" },
  { value: "4hour", label: "4-Hour" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const mistakes = [
  { value: "enteredEarly", label: "Entered Early" },
  { value: "enteredLate", label: "Entered Late" },
  { value: "exitedEarly", label: "Exited Early" },
  { value: "exitedLate", label: "Exited Late" },
  { value: "badRiskManagement", label: "Bad Risk Management" },
  { value: "newsEventTooClose", label: "News Event Too Close" },
];

export default timeFrame;
