const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const DEVSQ = (arr) => {
  var mean = arrAvg(arr);
  var result = 0;
  for (var i = 0; i < arr.length; i++) {
    result += Math.pow((arr[i] - mean), 2);
  }
  return result;
};
export default DEVSQ