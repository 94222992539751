import React, { useEffect, useState, useRef, createContext } from "react";
//import useForceUpdate from "./hooks/useForceUpdate";

///

const SharedTradeContext = createContext(undefined);

/********


/********
 *
 * @param {Object} sharedTrades array of shared trades.

 * ************************** also subject to change in the future
 */

const SharedTradeProvider = ({ children }) => {
  const [sharedTrades, setsharedTrades] = useState([]);
  const connected = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    // FUNCTIONS
    const fetchData = async (loaderState) => {
      // Again, possibly will delete in future
      /*       const getSharedTrades = await processSharedTrades(
      );
      setsharedTrades(getSharedTrades); */
      //-------------------------------------
    };
    if (firstUpdate.current) {
      fetchData(true);
    } else {
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [connected]);

  useEffect(() => {
    // THIS USEEFFECT INTERCEPTS INCOMING WEBSOCKET MESSAGES AND SETS THEM TO allData
    // RETRIEVE allData AFTER IT HAS FINISHED GETTING
    // ----------------------------------------------------------
  }, []);

  return (
    <SharedTradeContext.Provider
      value={{
        sharedTrades: sharedTrades,
        updatesharedTrades: (val) => setsharedTrades(val),
      }}
    >
      {children}
    </SharedTradeContext.Provider>
  );
};

export { SharedTradeContext, SharedTradeProvider };
