export async function getBC4Month() {
  const proxyURL =
    "https://api.tradefuse.app/getRiskFreeRate";

  try {
    const dataIn = {};
    const response = await fetch(proxyURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    }).catch((err) => {
      return { rate: 2.0 };
    });
    return response.json();
  } catch (error) {
    return { rate: 2.0 };
  }
}
export default getBC4Month;
