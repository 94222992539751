export function calculateSharpeRatios(balances, riskFreeRate) {
  // Check if input is valid
  if (
    !Array.isArray(balances) ||
    balances.length === 0 ||
    typeof riskFreeRate !== "number"
  ) {
    return "Invalid input";
  }

  // Calculate returns
  let returns = [];
  for (let i = 1; i < balances.length; i++) {
    returns.push((balances[i] - balances[i - 1]) / balances[i - 1]);
  }

  // Calculate arithmetic mean of returns
  let arithmeticMean = returns.reduce((a, b) => a + b, 0) / returns.length;

  // Calculate geometric mean of returns
  let geometricMean = 1;
  for (let i = 0; i < returns.length; i++) {
    geometricMean *= 1 + returns[i];
  }
  geometricMean = Math.pow(geometricMean, 1 / returns.length) - 1;

  // Calculate standard deviation of returns
  let stdDev = 0;
  for (let i = 0; i < returns.length; i++) {
    stdDev += Math.pow(returns[i] - arithmeticMean, 2);
  }
  stdDev = Math.sqrt(stdDev / returns.length);

  // Calculate geometric standard deviation
  let geometricStdDev = 0;
  for (let i = 0; i < returns.length; i++) {
    geometricStdDev += Math.pow(
      Math.log(1 + returns[i]) - Math.log(1 + geometricMean),
      2
    );
  }
  geometricStdDev = Math.sqrt(geometricStdDev / returns.length);

  // Calculate arithmetic sharpe ratio
  let arithmeticSharpeRatio =
    (arithmeticMean * 100 - riskFreeRate) / (stdDev * 100);
  // Calculate geometric sharpe ratio
  let geometricSharpeRatio =
    (geometricMean * 100 - riskFreeRate) / (geometricStdDev * 100);

  return {
    arithmeticSharpeRatio: arithmeticSharpeRatio,
    geometricSharpeRatio: geometricSharpeRatio,
  };
}

export function calculateSortinoRatios(balances, riskFreeRate) {
  let returns = [];
  for (let i = 1; i < balances.length; i++) {
    returns.push((balances[i] - balances[i - 1]) / balances[i - 1]);
  }

  let expectedReturn = returns.reduce((a, b) => a + b, 0) / returns.length;
  let negativeReturns = returns.filter((returns) => returns < 0);
  let stdDevNegativeReturns = Math.sqrt(
    negativeReturns
      .map((x) => x - expectedReturn)
      .map((x) => x ** 2)
      .reduce((a, b) => a + b, 0) / negativeReturns.length
  );

  let arithmeticSortinoRatio =
    (expectedReturn * 100 - riskFreeRate) / (stdDevNegativeReturns * 100);
  let geometricMeanReturns =
    returns.reduce((a, b) => a * (1 + b), 1) ** (1 / returns.length) - 1;
  let stdDevCompoundedNegativeReturns = Math.sqrt(
    negativeReturns
      .map((x) => (1 + x) ** (1 / negativeReturns.length) - 1)
      .map((x) => x - geometricMeanReturns)
      .map((x) => x ** 2)
      .reduce((a, b) => a + b, 0) / negativeReturns.length
  );
  let geometricSortinoRatio =
    (geometricMeanReturns * 100 - riskFreeRate) /
    (stdDevCompoundedNegativeReturns * 100);

  return { arithmeticSortinoRatio, geometricSortinoRatio };
}
export default calculateSharpeRatios;
