import { Network, Alchemy, AlchemySubscription } from "alchemy-sdk";
import MetaApi, { SynchronizationListener } from "metaapi.cloud-sdk";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";

const metaapi = new MetaApi(process.env.REACT_APP_METAAPIKEY);
class MySynchronizationListener extends SynchronizationListener {
  constructor(props) {
    super(props);
    this.state = {
      props: props,
    };
  }
  async onDealAdded(instanceIndex, deal) {
    this.state.props.relayDataFunction(deal, this.state.props.connection);
  }
}
const { DydxClient } = require("@dydxprotocol/v3-client");
const NodeRSA = require("node-rsa");
const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");
// Alchemy setup
const alchemySettings = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(alchemySettings);

export const autoimportws = async (relayData, initData, sockets) => {
  const init_Data = initData;
  const allConnections = init_Data.connections;
  let socketsInner = [];

  // WE LOOP THROUGH TO CREATE MULTIPLE CONNECTIONS IF NECESSARY
  for (let i = 0, j = allConnections.length; i < j; i++) {
    const connection = allConnections[i];
    const brokerName = connection.broker;

    // ------------- DYDX -------------
    if (brokerName === "dYdX") {
      const apiCredsPre = connection.cred;
      //decrypt credentials
      const decryptedString = key.decrypt(apiCredsPre, "utf8");
      const apiCreds = JSON.parse(decryptedString);
      //----------
      const DYDX_HTTP_HOST = "https://api.dydx.exchange";
      const WS_HOST = "wss://api.dydx.exchange/v3/ws";
      const client = new DydxClient(DYDX_HTTP_HOST, {
        apiKeyCredentials: apiCreds,
      });

      const timestamp = new Date().toISOString();
      const signature = client.private.sign({
        requestPath: "/ws/accounts",
        method: "GET",
        isoTimestamp: timestamp,
      });
      const msg = {
        type: "subscribe",
        channel: "v3_accounts",
        accountNumber: "0",
        apiKey: apiCreds.key,
        signature,
        timestamp,
        passphrase: apiCreds.passphrase,
      };
      const socket = new WebSocket(WS_HOST);

      // set array of sockets
      socketsInner.push({
        type: "traditional",
        socket: socket,
      });

      const setupWebSocket = () => {
        const canOpen = localStorage.getItem("candYdXOpen");
        socket.addEventListener("message", (message) => {
          const data = JSON.parse(message.data); //just assuming you receive a JSON object
          const content = data.contents;
          const fills = content && content.fills;
          const type = data.type;
          // SET CONNECTION STATUS TO TRUE TO PREVENT MULTIPLE WEBSOCKETS
          // IN ONE WINDOW
          if (type === "connected") {
            localStorage.setItem("candYdXOpen", "iExist");
            localStorage.setItem("canCallWS", "iExist");
          }
          if (fills && type === "channel_data") {
            relayData(data, connection);
          }
        });
        !canOpen &&
          socket.addEventListener("open", () => {
            //console.log("connection open");
            !canOpen && socket.send(JSON.stringify(msg));
          });

        socket.addEventListener("error", (error) => {
          /*           console.log("<", error);
           */
        });

        socket.addEventListener("close", () => {
          // Re-open connection if it closes
          //localStorage.removeItem("candYdXOpen");
          const resetConnection = localStorage.getItem("resetConnections");
          if (resetConnection === "no") {
          } else {
            setTimeout(() => setupWebSocket(), 800);
          }
        });
      };
      setupWebSocket();
    }

    // ------------- METAMASK -------------
    if (brokerName === "metamask") {
      const account = connection.address;
      const socket = alchemy.ws;

      // set array of sockets
      socketsInner.push({
        type: "alchemy",
        socket: socket,
      });
      const canOpen = localStorage.getItem("canMetaMaskOpen");

      const setupWebSocket = async () => {
        localStorage.setItem("canMetaMaskOpen", "iExist");
        localStorage.setItem("canCallWS", "iExist");
        alchemy.ws.on(
          {
            method: "alchemy_minedTransactions",
            addresses: [
              {
                from: account,
              },
              {
                to: account,
              },
            ],
            includeRemoved: true,
            hashesOnly: false,
          },
          async (res) => {
            if (res) {
              const tx = await alchemy.core.getTransaction(
                res.transaction.hash
              );
              const date = new Date();
              let newObj = tx;
              const timestampInSeconds = Math.floor(date.getTime() / 1000);
              newObj["timestamp"] = timestampInSeconds;
              relayData(tx, connection);
            } else {
            }
          }
        );
      };
      !canOpen && setupWebSocket();
    }

    // ------------- Metatrader 4/5 -------------
    if (brokerName === "metatrader4" || brokerName === "metatrader5") {
      const apiCredsPre = connection.cred;
      //decrypt credentials
      const decryptedString = key.decrypt(apiCredsPre, "utf8");
      const apiCreds = JSON.parse(decryptedString);
      const localMT = localStorage.getItem("canMetaTraderOpen");

      //----------
      try {
        const account = await metaapi.metatraderAccountApi.getAccount(apiCreds);
        if (!account) {
        } else {
          // set array of sockets
          const setupWebSocket = async () => {
            localStorage.setItem("canMetaTraderOpen", "iExist");
            try {
              if (account.state !== "DEPLOYED") {
                //store.dispatch(setLoaderState({ loading: false }));
                localStorage.setItem(
                  "autoiterationMessage",
                  `reconnecting`
                );
                await account.deploy();
              } else {
                //console.log("Account already deployed");
              }
              const mtconnection = account.getStreamingConnection();
              // now add the listener
              const listener = new MySynchronizationListener({
                relayDataFunction: relayData,
                connection: connection,
              });
              mtconnection.addSynchronizationListener(listener);
              if (account.connectionStatus !== "CONNECTED") {
                localStorage.setItem(
                  "autoiterationMessage",
                  `reconnecting`
                );
                await account.waitConnected();
              } else {
              }
              await mtconnection.connect();

              // open the connection after adding listeners
              await mtconnection.waitSynchronized();
              socketsInner.push({
                type: "metatrader",
                socket: mtconnection,
              });
              localStorage.removeItem("autoiterationMessage");

              // relayData(data, connection);

              // wait until synchronization com
            } catch (err) {
              console.log(err);
            }
          };
          !localMT && setupWebSocket();
        }
      } catch {}
    }
    sockets.current = socketsInner;
  }
};
export default autoimportws;
