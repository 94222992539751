import React, { useEffect, useRef } from "react";
import "./styles.scss";
import defaultTickerSymbols from "./defaultTickerSymbols";

export const TickerTape = (props) => {
  const { symbols } = props;
  const firstUpdate = useRef(true);
  const debounceTimer = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Debounce function to delay updating the ticker tape
    const debounceUpdate = () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current); // Clear existing timer
      }
      debounceTimer.current = setTimeout(() => {
        // Only check localStorage for updates after the initial load
        if (
          !firstUpdate.current &&
          localStorage.getItem("tickertapeChange") === "yes"
        ) {
          loadOrUpdateWidget(); // Load or update widget after 2 seconds of inactivity
          localStorage.removeItem("tickertapeChange"); // Remove the variable after updating
        }
      }, 2000); // 2 seconds delay
    };

    // Function to either load the widget initially or attempt an update
    const loadOrUpdateWidget = () => {
      const scriptExists =
        containerRef.current && containerRef.current.querySelector("script");
      if (!firstUpdate.current && scriptExists && window.TradingView) {
        // Since we can't directly update symbols, we're forced to reload the script
        reloadScript();
      } else {
        loadScript();
      }
    };

    // Function to load the TradingView widget script
    const loadScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.innerHTML = JSON.stringify({
        symbols: symbols.map((symbol) => ({
          proName: symbol.proName,
          title: symbol.title,
        })),
        showSymbolLogo: true,
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "regular",
        locale: "en",
      });

      containerRef.current && (containerRef.current.innerHTML = ""); // Clear previous widget
      containerRef.current && containerRef.current.appendChild(script); // Append new script
    };

    // Function to reload the script when symbols change
    const reloadScript = () => {
      containerRef.current && (containerRef.current.innerHTML = ""); // Clear the container
      loadScript(); // Re-load the script with new symbols
    };

    // Initial load without checking localStorage
    if (firstUpdate.current) {
      firstUpdate.current = false;
      loadScript();
    }

    // Debounce updates for rapid symbol changes, with localStorage check after initial load
    debounceUpdate();
  }, [symbols]); // Dependency array includes symbols to run effect when symbols change

  return (
    <div id="myContainerTickerTape" ref={containerRef}>
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
      </div>
    </div>
  );
};

export default TickerTape;
