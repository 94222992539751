import { proddev } from "../../PROD-DEV-TOGGLE";

var jsonpack = require("jsonpack/main");
const NodeRSA = require("node-rsa");
const crypto = require("crypto");

const getUserData = async (data, history) => {
  let request;
  try {
    request = await fetch(
      proddev === "prod"
        ? "https://opkt3gy2of.execute-api.us-west-1.amazonaws.com/prod/get-entries"
        : "https://c5jfnnmixj.execute-api.us-west-1.amazonaws.com/default/get-entries",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data,
        }),
      }
    );
    if (!request.ok) {
      //console.log(request.ok);
    }
  } catch (err) {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message, // Passing the error message instead of the error object
      },
    });
    window.location.reload();
    return; // Early return to stop execution of further code
  }

  let returnedData = await request.json();

  // then unpack
  var unpackedData = !returnedData.data
    ? undefined
    : typeof returnedData.data === "object"
    ? jsonpack.unpack(jsonpack.pack(returnedData.data)) // for users who created an account before jsonpack was implemented
    : jsonpack.unpack(returnedData.data);

  let newData = {
    data: unpackedData, // decompress the data
    input: returnedData.input,
    message: returnedData.message,
    picData: returnedData.picData,
  };
  return newData;
};

export default getUserData;