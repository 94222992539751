import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  DateRangeOptions,
  MonthOptions,
  MarketStateOptions,
  DayofWeekOptions,
  TimeFrameOptions,
  ConfidenceOptions,
  HourRangeOptions,
} from "./FilterMenuDropdownOptions";
import "./filterstyling.scss";
import CreatableAdvanced from "./FilterMenuDropdownComponent";
import moment from "moment/moment";
import store from "./../../../store";
import getFilteredList from "./../../../actions/actionCreators";
import isArray from "../../../functions/arrayFunctions/isArray";
const FilterSelectComponent = styled.div`
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column wrap;
  flex: auto;
  align-items: left;
  justify-content: left;
  margin: 0;
  padding-right: 36px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const FilterMenu = (props) => {
  const allData = props.userData;
  const portfolio = allData === undefined ? [] : allData.portfolio;
  const filterlistActual = allData === undefined ? [] : allData.filterLists;
  const assetClasses = portfolio.assetClasses;
  const optionsIncludes =
    assetClasses && isArray(assetClasses) && assetClasses.includes("Options");
  const generateAssetClasses = (portfolio) => {
    let arr = [];
    portfolio &&
      portfolio.forEach((item) => {
        arr = arr.concat({ label: item, value: item });
      });
    return arr;
  };
  let _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
    }
    return () => {
      _isMounted.current = false;
    };
  }, [props]);

  const allIds = [
    "dateRangeFilter",
    "orderTypeFilter",
    "symbolFilter",
    "strategyFilter",
    "emotionalStateFilter",
    "mistakeFilter",
    "timeFrameFilter",
    "confidenceFilter",
    "marketStateFilter",
    "dayOfWeekFilter",
    "monthFilter",
    "assetClassFilter",
    "tagsFilter",
    "physicalStateFilter",
    "hourRangeFilter",
    "yearFilter",
  ];

  // IMPORTANT LINES OF CODE
  const globalfilter = store.getState();
  const globalFilterState = globalfilter.defaultfilterValue;
  // Dates
  const stateDateRange = globalFilterState.filtervalue.dateRangeFilter;
  var stringDate1 =
    stateDateRange === null
      ? null
      : moment(stateDateRange[0]).add(1, "days").format("MM/DD/YYYY");
  var stringDate2 =
    stateDateRange === null
      ? null
      : moment(stateDateRange[1]).format("MM/DD/YYYY");
  var stringDate = "";
  stringDate = stringDate1 + " - " + stringDate2;
  const dateRangeFilterlength =
    Object.keys(stateDateRange === null ? 0 : stateDateRange).length / 2;
  const stringDateRange = {
    value: stringDate,
    label: stringDate,
  };
  const finaldefaultvalue =
    dateRangeFilterlength > 1 ? stringDateRange : stateDateRange;
  // Time
  const stateTimeRange = globalFilterState.filtervalue.hourRangeFilter;
  var stringTime1 = stateTimeRange === null ? null : stateTimeRange[0];
  var stringTime2 = stateTimeRange === null ? null : stateTimeRange[1];
  var stringTime = "";
  stringTime = stringTime1 + " - " + stringTime2;
  const timeRangeFilterlength =
    Object.keys(stateTimeRange === null ? 0 : stateTimeRange).length / 2;
  const stringTimeRange = {
    value: stringTime,
    label: stringTime,
  };
  const finaldefaultvalueTime =
    timeRangeFilterlength > 1 ? stringTimeRange : stateTimeRange;
  return (
    <div className="divforfilterscroll">
{/*       <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdownfirst"
      >
        <label className="filterlabelfix">{"Date Range"}</label>
        <CreatableAdvanced
          allowCreateWhileLoading={false}
          id={allIds[0]}
          defaultFilterValue={finaldefaultvalue}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[0], buffer))
          }
          isMulti={false}
          defaultOptions={DateRangeOptions}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Time of Day"}</label>
        <CreatableAdvanced
          id={allIds[14]}
          defaultFilterValue={finaldefaultvalueTime}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[14], buffer))
          }
          isMulti={false}
          defaultOptions={HourRangeOptions}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Order Type"}</label>
        <CreatableAdvanced
          id={allIds[1]}
          defaultFilterValue={globalFilterState.filtervalue.orderTypeFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[1], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.orderType}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Symbol"}</label>
        <CreatableAdvanced
          id={allIds[2]}
          defaultFilterValue={globalFilterState.filtervalue.symbolFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[2], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.symbol}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Asset Class"}</label>
        <CreatableAdvanced
          id={allIds[12]}
          defaultFilterValue={globalFilterState.filtervalue.assetClassFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[11], buffer))
          }
          isMulti={true}
          defaultOptions={generateAssetClasses(portfolio.assetClasses)}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Strategy"}</label>
        <CreatableAdvanced
          id={allIds[3]}
          defaultFilterValue={globalFilterState.filtervalue.strategyFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[3], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.strategy}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Emotional State"}</label>
        <CreatableAdvanced
          id={allIds[4]}
          defaultFilterValue={
            globalFilterState.filtervalue.emotionalStateFilter
          }
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[4], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.emotion}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Physical State"}</label>
        <CreatableAdvanced
          id={allIds[13]}
          defaultFilterValue={globalFilterState.filtervalue.physicalStateFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[13], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.physical}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Mistake"}</label>
        <CreatableAdvanced
          id={allIds[5]}
          defaultFilterValue={globalFilterState.filtervalue.mistakeFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[5], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.mistake}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Time Frame"}</label>
        <CreatableAdvanced
          id={allIds[6]}
          defaultFilterValue={globalFilterState.filtervalue.timeFrameFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[6], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.timeframeWithDefault}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Confidence"}</label>
        <CreatableAdvanced
          id={allIds[7]}
          defaultFilterValue={globalFilterState.filtervalue.confidenceFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[7], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.confidenceWithDefault}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Market State"}</label>
        <CreatableAdvanced
          id={allIds[8]}
          defaultFilterValue={globalFilterState.filtervalue.marketStateFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[8], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.marketStateWithDefault}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdown"
      >
        <label className="filterlabelfix">{"Day of Week"}</label>
        <CreatableAdvanced
          id={allIds[9]}
          defaultFilterValue={globalFilterState.filtervalue.dayOfWeekFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[9], buffer))
          }
          isMulti={true}
          defaultOptions={DayofWeekOptions}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdownlast"
      >
        <label className="filterlabelfix">{"Month"}</label>
        <CreatableAdvanced
          id={allIds[10]}
          defaultFilterValue={globalFilterState.filtervalue.monthFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[10], buffer))
          }
          isMulti={true}
          defaultOptions={MonthOptions}
          allData={allData}
        />
      </FilterSelectComponent>
      <FilterSelectComponent
        id="filtercomponents"
        className="filtermenudropdownlast"
      >
        <label className="filterlabelfix">{"Tags"}</label>
        <CreatableAdvanced
          id={allIds[12]}
          defaultFilterValue={globalFilterState.filtervalue.tagsFilter}
          selectedOptions={(buffer) =>
            store.dispatch(getFilteredList(allIds[12], buffer))
          }
          isMulti={true}
          defaultOptions={filterlistActual.tags}
          allData={allData}
        />
      </FilterSelectComponent> */}
    </div>
  );
};

export default FilterMenu;
