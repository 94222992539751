import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import masterTierComponent from "./../../../components/tierComponents/master";
const ToolsDropDown = () => {
  const location = useLocation();

  return (
    <div className="dropdown-list-tools">
      <div className="dropdown-text">
        <Link
          to="/Compare-&-Conquer"
          display="block"
          className="dropdown-items"
          style={{
            color: location.pathname === "/Compare-&-Conquer" ? "#fbd665" : "#deebf7",
          }}
        >
          Compare & Conquer
        </Link>
      </div>
      <div className="dropdown-text">
        <Link
          to="/Monte-Carlo-Simulator"
          display="block"
          className="dropdown-items"
          style={{
            color:
              location.pathname === "/Monte-Carlo-Simulator"
                ? "#fbd665"
                : "#deebf7",
          }}
        >
          Monte Carlo Simulator
        </Link>
      </div>
      <div className="dropdown-text">
        <Link
          to="/Position-Size-Calculator"
          display="block"
          className="dropdown-items"
          style={{
            color:
              location.pathname === "/Position-Size-Calculator"
                ? "#fbd665"
                : "#deebf7",
          }}
        >
          Position Size Calculator
        </Link>
      </div>
      <div className="dropdown-text">
        <Link
          to="/Streak-Odds-Calculator"
          display="block"
          className="dropdown-items"
          style={{
            color:
              location.pathname === "/Streak-Odds-Calculator"
                ? "#fbd665"
                : "#deebf7",
          }}
        >
          Streak Odds Calculator
        </Link>
      </div>
      {/*       <div className="dropdown-text">
        <Link
          to="/AI-Insights"
          display="block"
          className="dropdown-items"
          style={{
            color: location.pathname === "/AI-Insights" ? "#fbd665" : "#deebf7",
          }}
        >
          AI Insights
        </Link>
        <div style={{ position: "relative", top: 14, left: -20 }}>
          {masterTierComponent()}
        </div>
      </div> */}
      {/*
       */}
    </div>
  );
};

export default ToolsDropDown;
