import {
  getMaxDD,
  getMaxDDPercent,
  getDrawdownCalcs,
  getRunUpCalcs,
} from "../functions/calculations_for_metrics/getmaxDD";
import dollarCostAverage from "../functions/dollarCostAvg";
import { profitLossCalculation } from "../functions/profitLossCalculation";
import { returnNetPLDollar } from "../functions/profitLossCalculation";
import longShortDelineation from "../functions/longShortDelineation";
import { getDateDifferencewSeconds } from "../functions/getDateDifference";
import { handleNANGOOD } from "../functions/handleUndefined";
import calculateZScore from "../functions/zScoreCalculation";
import slippageCalculation from "../functions/slippageCalculation";
import getStandardDeviation from "../functions/calculations_for_metrics/standardDeviation";
import {
  getEndDateTime,
  getStartDateTime,
} from "../functions/getStartorEndDateTime";
import getEntryExitLots from "../functions/getEntryExitLots";
import linearRegression from "../functions/calculations_for_metrics/linearRegression";
import pcorr from "../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../functions/calculations_for_metrics/steyx";
import DEVSQ from "../functions/calculations_for_metrics/DEVSQ";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../functions/sharpeRatio";
import calculateRoySafetyFirstCriteria from "./../functions/roysSafetyFirstyCriteria";
import kellyCriterion from "./../functions/kellyCriterion";
import optimalF from "../functions/calculations_for_metrics/optimalf";

var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
const sumArray = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const drawdownFunction = (equityCurve) => {
  // Initialisations
  var highWaterMark = -Infinity;

  // Other initialisations
  let ddVectorNew = [];

  // Loop over all the values to compute the drawdown vector
  for (var i = 0; i < equityCurve.length; ++i) {
    highWaterMark =
      equityCurve[i] > highWaterMark ? equityCurve[i] : highWaterMark;
    ddVectorNew.push(((highWaterMark - equityCurve[i]) / highWaterMark) * 100);
  }
  // Return the computed vector
  return ddVectorNew;
};
const ulcerIndexFunction = (equityCurve) => {
  // Compute the drawdown function
  var ddFunc = drawdownFunction(equityCurve);
  const handleNANs = (value) => {
    if (
      isNaN(value) ||
      value === null ||
      value === Infinity ||
      value === undefined ||
      value === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  // Compute the sum of squares of this function
  var sumSquares = 0.0;
  for (var i = 0; i < ddFunc.length; ++i) {
    if (handleNANs(ddFunc[i])) {
      sumSquares += 0;
    } else {
      sumSquares += ddFunc[i] * ddFunc[i];
    }
  }
  const returnedValue = Math.sqrt(sumSquares / ddFunc.length);
  // Compute and return the ulcer index
  return returnedValue;
};

// Helper function to calculate week of the year
function getWeek(date) {
  let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return (
    date.getFullYear() +
    "-" +
    Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  );
}

function calculateAverage(total, count) {
  let perAvg = 0;
  let totalCount = 0;
  for (let key in total) {
    perAvg += total[key];
    totalCount += 1;
  }
  return perAvg / totalCount;
}
// --------- *********IMPORTANT********* ---------

// ** calculations that are open an have no exit executions can be omitted from all calculations

//------------------------------------------------
//const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
export const quantumCalculations = (
  filteredTrades,
  calctype,
  start_Balance,
  riskFreeRate,
  dateFilterBy
) => {
  let result = {};

  let profitLossArr = [];
  let profitLossArr2 = [];

  let rMultipleWins = [];
  let rMultipleLosses = [];
  let rrrarr = [];
  var dailyPL = {},
    dailyCount = {},
    weeklyPL = {},
    weeklyCount = {},
    monthlyPL = {},
    monthlyCount = {},
    quarterlyPL = {},
    quarterlyCount = {},
    yearlyPL = {},
    yearlyCount = {};
  //let totalLossTrades = 0;

  let totalprofitLoss = 0;
  let totalprofitLoss_percent = 0;
  let tradefreqperday = 0;
  let tradefreqperweek = 0;
  let tradefrequencypermonth = 0;
  let tradefreqperyear = 0;
  let totalDeposits = 0;
  let totalWithdrawals = 0;
  let totalSlippage = 0;

  let numberofDeposits = 0;
  let numberofWithdrawals = 0;
  let avgDeposit = 0;
  let avgWithdrawal = 0;
  let totalProfits = 0;
  let totalLosses = 0;
  let totalTrades = 0;
  let totalWinningTrades = 0;
  let totalLosingTrades = 0;
  let tradefuseIndex = 0;
  let totalBEs = 0;
  let avgRiskRewardRatio = 0;
  let avgRiskRewardRatiovalueonly = 0;
  let volatility = 0;
  let volatilityPercent = 0;
  let zScore = 0;
  let percentgainArray = [];
  let percentgainLOSSArray = [];
  let percentgainWINArray = [];
  let totalCommissions = 0;
  let totalFees = 0;
  let totalStockTrades = 0;
  let totalOptionTrades = 0;
  let totalFutureTrades = 0;
  let totalForexTrades = 0;
  let totalCFDTrades = 0;
  let totalCryptoTrades = 0;
  let optimalf = 0;
  let optimalleverage = 0;
  let theoreticalunitsize = 0;
  let royssafetyfirstcriteria = 0;
  let kellycriterion = 0;
  let larrywilliams = 0;
  let largestLoss = 0;
  let totalVolumeTraded = 0;
  let winlossesbe = {};
  //let miscellaneousstandardDeviationPerTradepercenttemparray = [];
  //let adjfullaccountbalancearr = [];
  let fullaccountbalancearr = [];
  let fullaccountbalancearrForGraph = [];
  let iterationarray = [];
  let positionSizeArr = [];
  let volumeArr = [];
  let totalVolumeArr = [];
  let avgVolumeArr = [];
  let winRateArr = [];
  let lossRateArr = [];
  let beRateArr = [];

  let statisticalZScoreArr = [];

  let avgpositionSizeArr = [];

  let fullaccountbalancearrwithDates = [];
  let totalDW = start_Balance;
  // Variables to init before the loop
  let riskedPercentArr = [];
  let avgEfficiencyArr = [];
  let timeInMarketArr = [];
  let volatilityArr = [];
  let volatilityPercentArr = [];
  let standardDeviationArr = [];
  let standardDeviationPercentArr = [];
  let timeInMarket = 0;
  let profitlossRatioArr = [];
  let profitFactorArr = [];
  let recoveryFactorArr = [];
  let expectationArr = [];
  let ulcerIndexArr = [];
  let averageRiskPerTradeArr = [];
  let cpcArr = [];

  let zScoreArr = [];
  let percentConfidenceArr = [];
  let avgPLArr = [];
  let avgHoldingTimeArr = [];
  let tradefuseGoldenRatioArr = [];
  let twentyfourhrArr = [];
  let tradefuseIndexArr = [];
  let avgRiskRewardRatioArr = [];
  let rMultipleArr = [];
  let probChancearr = [];
  let avgEfficiencyArrForGraph = [];
  let onlyTrades = [];
  let maxDrawdownDollarArr = [];
  let maxRunUpDollarArr = [];
  let maxDrawdownPercentArr = [];
  let maxRunUpPercentArr = [];
  let arithmeticSharpeRatioArr = [];
  let geometricSharpeRatioArr = [];
  let arithmeticSortinoRatioArr = [];
  let geometricSortinoRatioArr = [];
  let KRatioArr = [];
  let LRCorrelationArr = [];
  let LRStandardErrorArr = [];
  // Main For Loop

  //console.log(whatToCompare, whatToCompareProp, selectedItem);
  //console.log(filteredTrades)

  for (let i = 0, j = filteredTrades.length; i < j; i++) {
    const thisAccountBalance = Number(filteredTrades[i].balance);
    const entryId = filteredTrades[i].entryId;
    let orderType = filteredTrades[i].entry.orderType;
    let multiExecution = filteredTrades[i].entry.multiExecution;
    let exitExecution = filteredTrades[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    const correctedSUM = returnNetPLDollar(
      filteredTrades[i].entry.profitLoss,
      filteredTrades[i].entry.commissions,
      filteredTrades[i].entry.fees,
      calctype
    );
    const slippage = slippageCalculation(
      filteredTrades[i].entry.multiExecution,
      filteredTrades[i].entry.exitExecution,
      filteredTrades[i].entry.orderType,
      filteredTrades[i].entry.selectedPortfolioType,
      filteredTrades[i].entry.symbol.pointValue,
      false,
      filteredTrades[i].entry.profitLoss
    );
    totalCommissions += Number(filteredTrades[i].entry.commissions * 100) / 100;
    totalFees += Number(filteredTrades[i].entry.fees * 100) / 100;

    if (
      filteredTrades[i].entry.orderType === "Deposit" ||
      filteredTrades[i].entry.orderType === "Withdrawal" ||
      filteredTrades[i].entry.orderType === "Funding Payment" ||
      filteredTrades[i].entry.orderType === "Commit" ||
      filteredTrades[i].entry.orderType === "Approval" ||
      filteredTrades[i].entry.orderType === "Wrap" ||
      filteredTrades[i].entry.orderType === "Self"
    ) {
      // only pushing these because the there needs to be values shown on the graphs instead of blanks
      // we can push these because these do not affect calculations. Do not push these values for arrays used in calculations.
      totalDW += correctedSUM;
      //adjfullaccountbalancearr.shift();
      //adjfullaccountbalancearr.unshift(totalDW);
      if (correctedSUM > 0) {
        totalDeposits += correctedSUM;
        numberofDeposits += 1;
      } else if (correctedSUM < 0) {
        totalWithdrawals += correctedSUM;
        numberofWithdrawals += 1;
      }
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      iterationarray.push(i);
      if (correctedSUM < largestLoss) {
        largestLoss = correctedSUM;
      }
      fullaccountbalancearr.push(thisAccountBalance);
      fullaccountbalancearrForGraph.push({
        value: Number(thisAccountBalance),
        id: entryId,
      });
      totalprofitLoss += correctedSUM;
      totalSlippage += slippage;
      totalTrades += 1;
      //adjfullaccountbalancearr.push(thisAccountBalance);
      profitLossArr.push(correctedSUM);
      profitLossArr2.push({
        value: Number(totalprofitLoss),
        id: entryId,
      });

      onlyTrades.push(filteredTrades[i]);
      let percentgain =
        (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
      percentgainArray.push(percentgain);
      if (percentgain < 0) {
        percentgainLOSSArray.push(percentgain);
      } else if (percentgain > 0) {
        percentgainWINArray.push(percentgain);
      }
      if (correctedSUM > 0) {
        totalWinningTrades += 1;
        totalProfits += correctedSUM;
      } else if (correctedSUM < 0) {
        totalLosingTrades += 1;
        totalLosses += correctedSUM;
      } else if (correctedSUM === 0) {
        totalBEs += 1;
      }
      // Count assetClasses
      if (
        filteredTrades[i].entry.selectedPortfolioType === "Stocks" ||
        filteredTrades[i].entry.selectedPortfolioType === "Exercise Stock" ||
        filteredTrades[i].entry.selectedPortfolioType === "Assign Stock"
      ) {
        totalStockTrades += 1;
      } else if (filteredTrades[i].entry.selectedPortfolioType === "Options") {
        totalOptionTrades += 1;
      } else if (filteredTrades[i].entry.selectedPortfolioType === "Futures") {
        totalFutureTrades += 1;
      } else if (filteredTrades[i].entry.selectedPortfolioType === "Forex") {
        totalForexTrades += 1;
      } else if (filteredTrades[i].entry.selectedPortfolioType === "CFD") {
        totalCFDTrades += 1;
      } else if (filteredTrades[i].entry.selectedPortfolioType === "Crypto") {
        totalCryptoTrades += 1;
      }
      // Some metrics must be calculated inside this if statement
      const endDateTimez = getEndDateTime(exitExecution);
      const startDateTimez = getStartDateTime(multiExecution);

      let eachStartDateTime = new Date(startDateTimez); // or startDateTimez
      let eachEndDateTime = new Date(endDateTimez);
      let neutralDateTime =
        dateFilterBy === "Trade Open" ? eachStartDateTime : eachEndDateTime;
      // Format as 'YYYY-MM-DD' using local time
      let day =
        neutralDateTime.getFullYear() +
        "-" +
        ("0" + (neutralDateTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + neutralDateTime.getDate()).slice(-2);

      // Assuming getWeek is defined to calculate the week number
      let week = getWeek(neutralDateTime); // Make sure this calculation is what you expect in local time

      // Format as 'YYYY-MM' using local time
      let month =
        neutralDateTime.getFullYear() +
        "-" +
        ("0" + (neutralDateTime.getMonth() + 1)).slice(-2);
      let quarter =
        neutralDateTime.getFullYear() +
        "-Q" +
        Math.ceil((neutralDateTime.getMonth() + 1) / 3); // Calculate which quarter the date falls into

      let year = neutralDateTime.getFullYear().toString();

      // Calculate daily P/L
      dailyPL[day] = (dailyPL[day] || 0) + correctedSUM;
      dailyCount[day] = (dailyCount[day] || 0) + 1;
      // Calculate weekly P/L
      weeklyPL[week] = (weeklyPL[week] || 0) + correctedSUM;
      weeklyCount[week] = (weeklyCount[week] || 0) + 1;
      // Calculate monthly P/L
      monthlyPL[month] = (monthlyPL[month] || 0) + correctedSUM;
      monthlyCount[month] = (monthlyCount[month] || 0) + 1;
      // Quarterly P/L
      quarterlyPL[quarter] = (quarterlyPL[quarter] || 0) + correctedSUM;
      quarterlyCount[quarter] = (quarterlyCount[quarter] || 0) + 1;
      // Yearly calculations
      yearlyPL[year] = (yearlyPL[year] || 0) + correctedSUM;
      yearlyCount[year] = (yearlyCount[year] || 0) + 1;

      // COMPUTE TIME IN MARKET
      let eachTime = (eachEndDateTime - eachStartDateTime) / 1000;
      timeInMarketArr.push(eachTime);

      avgHoldingTimeArr.push({
        value: arrAvg(timeInMarketArr),
        id: entryId,
      });
      timeInMarket += eachTime;
      // ---------------------

      // Initialize stuff for below calculations
      let avgEntry = dollarCostAverage(multiExecution, "entry", orderType);
      let avgExit = dollarCostAverage(exitExecution, "exit", orderType);
      const positionSize = multiExecution.reduce(
        (acc, exec) => acc + exec.lotSize,
        0
      );
      const totalVolume =
        multiExecution.reduce((acc, exec) => acc + exec.lotSize, 0) +
        exitExecution.reduce((acc, exec) => acc + exec.exitLotSize, 0);
      volumeArr.push({ value: totalVolume, id: entryId });
      const volumeSum = sumArray(volumeArr.map((item) => item.value));
      const avgVolume = arrAvg(volumeArr.map((item) => item.value));

      totalVolumeArr.push({
        value: volumeSum,
        id: entryId,
      });
      avgVolumeArr.push({
        value: avgVolume,
        id: entryId,
      });
      positionSizeArr.push(positionSize);
      avgpositionSizeArr.push({
        value: arrAvg(positionSizeArr),
        id: entryId,
      });
      let rmultiple =
        (avgExit - avgEntry) /
        (avgEntry - Number(filteredTrades[i].entry.stopLoss));
      let RRR =
        (Number(filteredTrades[i].entry.takeProfit) - avgEntry) /
        (avgEntry - Number(filteredTrades[i].entry.stopLoss));
      rrrarr.push(RRR);
      avgRiskRewardRatioArr.push({
        value: arrAvg(rrrarr),
        id: entryId,
      });
      if (correctedSUM > 0) {
        rMultipleWins.push(rmultiple);
      } else if (correctedSUM < 0) {
        rMultipleLosses.push(rmultiple);
      }
      let rmultipleratio = (
        arrAvg(rMultipleWins) / Math.abs(arrAvg(rMultipleLosses))
      )?.toFixed(3);
      rMultipleArr.push({
        value: rmultipleratio,
        id: entryId,
      });
      // ---------------------

      // COMPUTE AVG EFFICIENCY
      let efficiency = 0;
      if (
        longShortDelineation(
          filteredTrades[i].entry.orderType,
          multiExecution
        ) === "Long"
      ) {
        efficiency =
          100 *
          ((avgExit - avgEntry) /
            (filteredTrades[i].entry.maxFavEx -
              filteredTrades[i].entry.maxAdEx));
      } else if (
        longShortDelineation(
          filteredTrades[i].entry.orderType,
          multiExecution
        ) === "Short"
      ) {
        efficiency =
          100 *
          ((avgEntry - avgExit) /
            (filteredTrades[i].entry.maxAdEx -
              filteredTrades[i].entry.maxFavEx));
      }
      avgEfficiencyArr.push(efficiency);
      avgEfficiencyArrForGraph.push({
        value: arrAvg(avgEfficiencyArr),
        id: entryId,
      });
      // ---------------------

      // COMPUTE RISK %
      let adjrrr =
        -avgEntry / (avgEntry - Number(filteredTrades[i].entry.stopLoss));

      let divisor =
        filteredTrades[i].entry.selectedPortfolioType === "Forex"
          ? rmultiple
          : adjrrr;

      let initPl = profitLossCalculation(
        multiExecution,
        [],
        orderType,
        filteredTrades[i].entry.selectedPortfolioType,
        filteredTrades[i].entry.symbol.pointValue,
        true
      );
      // If the asset class if forex we have to calculate risk a slightly different way
      let dividend =
        filteredTrades[i].entry.selectedPortfolioType === "Forex"
          ? correctedSUM
          : returnNetPLDollar(
              initPl,
              filteredTrades[i].entry.commissions,
              filteredTrades[i].entry.fees,
              calctype
            );
      let percent =
        Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
      let riskedPercent = percent / divisor;
      riskedPercentArr.push(riskedPercent);
      averageRiskPerTradeArr.push({
        value: arrAvg(riskedPercentArr),
        id: entryId,
      });

      // ---------------------
      let pushThis = {
        amount: correctedSUM,
        balance: thisAccountBalance,
        startDate: eachStartDateTime,
        endDate: eachEndDateTime,
        id: entryId,
      };
      fullaccountbalancearrwithDates.push(pushThis);

      // Per iteration calculations, mainly for graphs
      let perzScore = calculateZScore(fullaccountbalancearr).zScore;
      zScoreArr.push({
        value: Number(perzScore),
        id: entryId,
      });
      let perpercentConfidence = calculateZScore(
        fullaccountbalancearr
      ).confidence;
      let perstatisticalZScore = calculateZScore(
        fullaccountbalancearr
      ).statisticalZScore;

      percentConfidenceArr.push({
        value: Number(perpercentConfidence),
        id: entryId,
      });
      statisticalZScoreArr.push({
        value: Number(perstatisticalZScore),
        id: entryId,
      });
      let peraveragePLPerWindollar = totalProfits / totalWinningTrades;
      let peraveragePLPerLossdollar = totalLosses / totalLosingTrades;
      let peraveragePLPerTradepercent = arrAvg(percentgainArray);
      let peraveragePLPerLosspercent = arrAvg(percentgainLOSSArray);
      let perstandardDeviationPerTradepercent =
        getStandardDeviation(percentgainArray);
      let perexpectation =
        peraveragePLPerTradepercent / Math.abs(peraveragePLPerLosspercent);

      expectationArr.push({ value: perexpectation, id: entryId });
      let perulcerIndex = ulcerIndexFunction(fullaccountbalancearr);
      ulcerIndexArr.push({ value: perulcerIndex, id: entryId });

      let perprobabilityOfRandomChance =
        totalTrades - 1 <= 0
          ? 0
          : (1 -
              studentsTCumulativeValue(
                peraveragePLPerTradepercent /
                  (perstandardDeviationPerTradepercent /
                    Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              )) *
            100;
      probChancearr.push({
        value: perprobabilityOfRandomChance,
        id: entryId,
      });
      let standardDeviationPer = getStandardDeviation(profitLossArr);
      standardDeviationArr.push({
        value: standardDeviationPer,
        id: entryId,
      });
      standardDeviationPercentArr.push({
        value: perstandardDeviationPerTradepercent,
        id: entryId,
      });
      let volatilityPer = standardDeviationPer / totalTrades;
      let volatilityPercentPer =
        perstandardDeviationPerTradepercent / totalTrades;
      volatilityArr.push({ value: volatilityPer, id: entryId });
      volatilityPercentArr.push({ value: volatilityPercentPer, id: entryId });

      let perProfitLossRatio =
        peraveragePLPerWindollar / Math.abs(peraveragePLPerLossdollar);
      profitlossRatioArr.push({ value: perProfitLossRatio, id: entryId });
      let perprofitFactor = totalProfits / Math.abs(totalLosses);
      profitFactorArr.push({ value: perprofitFactor, id: entryId });
      let perrecoveryFactor =
        totalprofitLoss / Math.abs(getMaxDD(fullaccountbalancearr));
      recoveryFactorArr.push({ value: perrecoveryFactor, id: entryId });
      let perCPCIndex =
        perprofitFactor *
        perProfitLossRatio *
        (totalWinningTrades / totalTrades);
      cpcArr.push({
        value: perCPCIndex,
        id: entryId,
      });
      let tradefuseGoldenRatioinner = (
        perProfitLossRatio / arrAvg(rrrarr)
      )?.toFixed(3);
      tradefuseGoldenRatioArr.push({
        value: Number(tradefuseGoldenRatioinner),
        id: entryId,
      });
      avgPLArr.push({
        value: arrAvg(profitLossArr),
        id: entryId,
      });
      let tradefuseIndexPer =
        (totalWinningTrades / totalTrades) * (1 + perProfitLossRatio);
      tradefuseIndexArr.push({
        value: tradefuseIndexPer,
        id: entryId,
      });
      let maxDrawdownDollarPer =
        totalTrades === 0
          ? 0
          : handleNANGOOD(
              getDrawdownCalcs(fullaccountbalancearrwithDates).maxDD,
              0
            );
      maxDrawdownDollarArr.push({
        value: maxDrawdownDollarPer,
        id: entryId,
      });
      let maxDrawdownPercentPer =
        totalTrades === 0
          ? 0
          : handleNANGOOD(
              getDrawdownCalcs(fullaccountbalancearrwithDates).maxDDpercent,
              0
            );
      maxDrawdownPercentArr.push({
        value: maxDrawdownPercentPer,
        id: entryId,
      });
      let maxRunUpDollarPer =
        totalTrades === 0
          ? 0
          : handleNANGOOD(
              getRunUpCalcs(fullaccountbalancearrwithDates).maxRU,
              0
            );
      maxRunUpDollarArr.push({
        value: maxRunUpDollarPer,
        id: entryId,
      });
      let maxRunUpPercentPer =
        totalTrades === 0
          ? 0
          : handleNANGOOD(
              getRunUpCalcs(fullaccountbalancearrwithDates).maxRUpercent,
              0
            );
      maxRunUpPercentArr.push({
        value: maxRunUpPercentPer,
        id: entryId,
      });
      let arithmeticSharpeRatioPer = handleNANGOOD(
        calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.arithmeticSharpeRatio?.toFixed(3),
        "-"
      );
      arithmeticSharpeRatioArr.push({
        value: arithmeticSharpeRatioPer,
        id: entryId,
      });
      let geometricSharpeRatioPer = handleNANGOOD(
        calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.geometricSharpeRatio?.toFixed(3),
        "-"
      );
      geometricSharpeRatioArr.push({
        value: geometricSharpeRatioPer,
        id: entryId,
      });
      let arithmeticSortinoRatioPer = handleNANGOOD(
        calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.arithmeticSortinoRatio?.toFixed(3),
        "-"
      );
      arithmeticSortinoRatioArr.push({
        value: arithmeticSortinoRatioPer,
        id: entryId,
      });
      let geometricSortinoRatioPer = handleNANGOOD(
        calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.geometricSortinoRatio?.toFixed(3),
        "-"
      );
      geometricSortinoRatioArr.push({
        value: geometricSortinoRatioPer,
        id: entryId,
      });
      let lineararrPer = [];
      const linearRegressionCalcPer = linearRegression(
        fullaccountbalancearr,
        iterationarray
      );
      const slopePer = linearRegressionCalcPer.slope;
      const interceptPer = linearRegressionCalcPer.intercept;
      for (let iu = 0, ju = fullaccountbalancearr.length; iu < ju; iu++) {
        let lineararrentry = slopePer * iu + interceptPer;
        lineararrPer.push(lineararrentry);
      }
      let LRCorrelationPer = handleNANGOOD(
        pcorr(fullaccountbalancearr, lineararrPer)?.toFixed(3),
        "-"
      );
      LRCorrelationArr.push({
        value: LRCorrelationPer,
        id: entryId,
      });
      let LRStandardErrorPer = handleNANGOOD(
        STEYX(fullaccountbalancearr, lineararrPer)?.toFixed(3),
        "-"
      );
      LRStandardErrorArr.push({
        value: LRStandardErrorPer,
        id: entryId,
      });
      let KRatioPer = handleNANGOOD(
        (
          slopePer /
          ((STEYX(fullaccountbalancearr, lineararrPer) /
            Math.sqrt(DEVSQ(lineararrPer))) *
            totalTrades)
        )?.toFixed(3),
        "-"
      );
      KRatioArr.push({
        value: KRatioPer,
        id: entryId,
      });
      let perwinRate = handleNANGOOD(
        (totalTrades === 0
          ? 0
          : (totalWinningTrades / totalTrades) * 100
        )?.toFixed(3),
        "-"
      );
      winRateArr.push({
        value: perwinRate,
        id: entryId,
      });
      let perlossRate = handleNANGOOD(
        (totalTrades === 0
          ? 0
          : (totalLosingTrades / totalTrades) * 100
        )?.toFixed(3),
        "-"
      );
      lossRateArr.push({
        value: perlossRate,
        id: entryId,
      });
      let perbreakevensRate = handleNANGOOD(
        (totalTrades === 0 ? 0 : (totalBEs / totalTrades) * 100)?.toFixed(3),
        "-"
      );
      beRateArr.push({
        value: perbreakevensRate,
        id: entryId,
      });
      // ---------------------
    }

    // ---------------------
  }

  // Initiate total time between first entry and last exit
  const firstTrade = onlyTrades[0];
  const lastTrade = onlyTrades[onlyTrades.length - 1];
  let multiExecutionlastTrade = lastTrade ? lastTrade.entry.multiExecution : [];
  let exitExecutionlastTrade = lastTrade ? lastTrade.entry.exitExecution : [];
  const entryExitValueslastTrade = getEntryExitLots(
    multiExecutionlastTrade,
    exitExecutionlastTrade
  );

  // Calculate total trading time
  const totalTradingTime =
    lastTrade && firstTrade
      ? ((entryExitValueslastTrade.entryLots !==
        entryExitValueslastTrade.exitLots //handles open trades
          ? Date.now()
          : new Date(lastTrade.entry.endDateTime)) -
          new Date(firstTrade.entry.startDateTime)) /
        1000
      : 0;

  // Metric calculations after end of loop
  totalprofitLoss_percent = handleNANGOOD(
    ((totalprofitLoss / totalDW) * 100)?.toFixed(3),
    "-"
  );
  let averagePLPerTradepercent = arrAvg(percentgainArray);
  let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);

  let standardDeviationPercent = handleNANGOOD(
    getStandardDeviation(percentgainArray)?.toFixed(3),
    "-"
  );
  let standardDeviationPerTradepercentUse =
    getStandardDeviation(percentgainArray)?.toFixed(3);

  let averagePLPerWindollar = totalProfits / totalWinningTrades;
  let averagePLPerLossdollar = totalLosses / totalLosingTrades;
  let averagePLPerTradedollar = totalprofitLoss / totalTrades;

  let winRate = handleNANGOOD(
    (totalTrades === 0 ? 0 : (totalWinningTrades / totalTrades) * 100)?.toFixed(
      3
    ),
    "-"
  );
  let lossRate = handleNANGOOD(
    (totalTrades === 0 ? 0 : (totalLosingTrades / totalTrades) * 100)?.toFixed(
      3
    ),
    "-"
  );
  let breakevensRate = handleNANGOOD(
    (totalTrades === 0 ? 0 : (totalBEs / totalTrades) * 100)?.toFixed(3),
    "-"
  );
  // ---------------- Drawdown calculations ----------------
  let maxDrawdownDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          getDrawdownCalcs(fullaccountbalancearrwithDates).maxDD,
          0
        )?.toFixed(2);

  let maxDrawdownPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          getDrawdownCalcs(fullaccountbalancearrwithDates).maxDDpercent,
          0
        )?.toFixed(3);

  let dateofMaxDrawdown =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).date;

  let dateofMaxDrawdownPercent =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).datePercent;

  let averageMaxDrawdownDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          arrAvg(getDrawdownCalcs(fullaccountbalancearrwithDates).fiveLargest),
          0
        )?.toFixed(2);

  let averageDrawdownDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          arrAvg(getDrawdownCalcs(fullaccountbalancearrwithDates).allDDs),
          0
        )?.toFixed(2);

  let averageMaxDrawdownPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          arrAvg(
            getDrawdownCalcs(fullaccountbalancearrwithDates).fiveLargestPercents
          ),
          0
        )?.toFixed(3);

  let averageDrawdownPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          arrAvg(
            getDrawdownCalcs(fullaccountbalancearrwithDates).allDDsPercent
          ),
          0
        )?.toFixed(3);

  let absoluteDrawdownDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(totalDW - Math.min(...fullaccountbalancearr), 0)?.toFixed(
          2
        );

  let absoluteDrawdownPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          ((totalDW - Math.min(...fullaccountbalancearr)) / totalDW) * 100,
          0
        )?.toFixed(3);

  let longestDrawdownPeriod =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).drawdownPeriod;

  let averageDrawdownPeriod =
    totalTrades === 0
      ? "-"
      : getDrawdownCalcs(fullaccountbalancearrwithDates).avgdrawdownPeriod;

  // ---------------- Runup calculations ----------------
  let maxRunUpDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          getRunUpCalcs(fullaccountbalancearrwithDates).maxRU,
          0
        )?.toFixed(2);

  let dateofMaxRunUp =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).date;

  let maxRunUpPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          getRunUpCalcs(fullaccountbalancearrwithDates).maxRUpercent,
          0
        )?.toFixed(3);

  let longestRunUpPeriod =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).runupPeriod;

  let averageMaxRunUpDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).fiveLargest),
          0
        )?.toFixed(2);

  let averageRunUpDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).allRUs),
          0
        )?.toFixed(2);

  let averageMaxRunUpPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          arrAvg(
            getRunUpCalcs(fullaccountbalancearrwithDates).fiveLargestPercents
          ),
          0
        )?.toFixed(3);

  let averageRunUpPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          arrAvg(getRunUpCalcs(fullaccountbalancearrwithDates).allRUsPercent),
          0
        )?.toFixed(3);

  let absoluteRunUpDollar =
    totalTrades === 0
      ? "0.00"
      : handleNANGOOD(Math.max(...fullaccountbalancearr) - totalDW, 0)?.toFixed(
          2
        );

  let absoluteRunUpPercent =
    totalTrades === 0
      ? "0.000"
      : handleNANGOOD(
          ((Math.max(...fullaccountbalancearr) - totalDW) / totalDW) * 100,
          0
        )?.toFixed(3);

  let averageRunUpPeriod =
    totalTrades === 0
      ? "-"
      : getRunUpCalcs(fullaccountbalancearrwithDates).avgrunUpperiod;

  let avgRisk = handleNANGOOD(arrAvg(riskedPercentArr)?.toFixed(3), "-");
  let avgEfficiency = handleNANGOOD(arrAvg(avgEfficiencyArr)?.toFixed(3), "-");
  let percentTimeinMarket = handleNANGOOD(
    ((timeInMarket / totalTradingTime) * 100)?.toFixed(3),
    "-"
  );

  let probabilityOfRandomChance =
    totalTrades - 1 <= 0
      ? 0.0
      : handleNANGOOD(
          (
            (1 -
              studentsTCumulativeValue(
                averagePLPerTradepercent /
                  (standardDeviationPerTradepercentUse /
                    Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              )) *
            100
          )?.toFixed(3),
          "-"
        );
  avgRiskRewardRatio = handleNANGOOD(arrAvg(rrrarr)?.toFixed(3), "-") + " : 1";
  avgRiskRewardRatiovalueonly = handleNANGOOD(arrAvg(rrrarr)?.toFixed(3), "-");
  let rmultipleratio = (
    arrAvg(rMultipleWins) / Math.abs(arrAvg(rMultipleLosses))
  )?.toFixed(3);
  let profitLossRatio = handleNANGOOD(
    (averagePLPerWindollar / Math.abs(averagePLPerLossdollar))?.toFixed(3),
    "-"
  );
  let recoveryFactor = handleNANGOOD(
    (totalprofitLoss / Math.abs(getMaxDD(fullaccountbalancearr)))?.toFixed(3),
    "-"
  );
  let expectation = handleNANGOOD(
    (averagePLPerTradepercent / Math.abs(averagePLPerLosspercent))?.toFixed(3),
    3
  );

  let profitFactor = handleNANGOOD(
    (totalProfits / Math.abs(totalLosses))?.toFixed(3),
    "-"
  );
  let CPCIndex = handleNANGOOD(
    (
      profitFactor *
      profitLossRatio *
      (totalWinningTrades / totalTrades)
    )?.toFixed(3),
    "-"
  );
  let ulcerIndex = handleNANGOOD(
    ulcerIndexFunction(fullaccountbalancearr)?.toFixed(3),
    "-"
  );
  let lineararr = [];
  const linearRegressionCalc = linearRegression(
    fullaccountbalancearr,
    iterationarray
  );
  const slope = linearRegressionCalc.slope;
  const intercept = linearRegressionCalc.intercept;
  for (let iu = 0, ju = fullaccountbalancearr.length; iu < ju; iu++) {
    let lineararrentry = slope * iu + intercept;
    lineararr.push(lineararrentry);
  }
  let LRCorrelation = handleNANGOOD(
    pcorr(fullaccountbalancearr, lineararr)?.toFixed(3),
    "-"
  );
  let LRStandardError = handleNANGOOD(
    STEYX(fullaccountbalancearr, lineararr)?.toFixed(3),
    "-"
  );
  let KRatio = handleNANGOOD(
    (
      slope /
      ((STEYX(fullaccountbalancearr, lineararr) / Math.sqrt(DEVSQ(lineararr))) *
        totalTrades)
    )?.toFixed(3),
    "-"
  );
  let avgPositionSize = handleNANGOOD(arrAvg(positionSizeArr)?.toFixed(3), "-");

  let standardDeviationUse = getStandardDeviation(profitLossArr);
  let standardDeviation = handleNANGOOD(
    getStandardDeviation(profitLossArr)?.toFixed(2),
    "-"
  );

  volatility = handleNANGOOD(
    (standardDeviationUse / totalTrades)?.toFixed(2),
    "-"
  );
  volatilityPercent = handleNANGOOD(
    (standardDeviationPerTradepercentUse / totalTrades)?.toFixed(3),
    "-"
  );
  optimalf = optimalF(percentgainArray)?.toFixed(3);
  optimalleverage = Math.abs(
    optimalf / (Math.min(...percentgainArray) / 100)
  )?.toFixed(3);
  theoreticalunitsize = Math.abs(largestLoss / optimalf)?.toFixed(3);
  larrywilliams = (
    (arrAvg(riskedPercentArr) *
      fullaccountbalancearr[fullaccountbalancearr.length - 1]) /
    largestLoss
  )?.toFixed(3);
  royssafetyfirstcriteria = calculateRoySafetyFirstCriteria(
    fullaccountbalancearr
  )?.toFixed(3);
  kellycriterion = kellyCriterion(fullaccountbalancearr)?.toFixed(3);

  let arithmeticSharpeRatio = handleNANGOOD(
    calculateSharpeRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.arithmeticSharpeRatio?.toFixed(3),
    "-"
  );
  let geometricSharpeRatio = handleNANGOOD(
    calculateSharpeRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.geometricSharpeRatio?.toFixed(3),
    "-"
  );
  let arithmeticSortinoRatio = handleNANGOOD(
    calculateSortinoRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.arithmeticSortinoRatio?.toFixed(3),
    "-"
  );
  let geometricSortinoRatio = handleNANGOOD(
    calculateSortinoRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.geometricSortinoRatio?.toFixed(3),
    "-"
  );
  let kellypercent = handleNANGOOD(
    ((averagePLPerTradedollar / averagePLPerWindollar) * 100)?.toFixed(3),
    "-"
  );
  let normalizedExpectancypercent = handleNANGOOD(
    (
      (averagePLPerTradedollar / Math.abs(averagePLPerLossdollar)) *
      100
    )?.toFixed(3),
    "-"
  );

  let tradefuseGoldenRatio = handleNANGOOD(
    (
      averagePLPerWindollar /
      Math.abs(averagePLPerLossdollar) /
      arrAvg(rrrarr)
    )?.toFixed(3),
    "-"
  );
  let avgHoldingTime = getDateDifferencewSeconds(arrAvg(timeInMarketArr));
  let avgProfitLoss = handleNANGOOD(arrAvg(profitLossArr)?.toFixed(2), "-");
  tradefuseIndex = handleNANGOOD(
    (
      (totalWinningTrades / totalTrades) *
      (1 + averagePLPerWindollar / Math.abs(averagePLPerLossdollar))
    )?.toFixed(3),
    "-"
  );
  zScore = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).zScore?.toFixed(3),
    "-"
  );
  let statisticalZScore = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).statisticalZScore?.toFixed(3),
    "-"
  );
  let percentConfidence = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).confidence?.toFixed(3),
    "-"
  );
  const volumeSum = sumArray(volumeArr.map((item) => item.value));
  const avgVolume = arrAvg(volumeArr.map((item) => item.value));
  totalVolumeTraded = handleNANGOOD(volumeSum?.toFixed(3), "-");
  let avgVolumeTraded = handleNANGOOD(avgVolume?.toFixed(3), "-");

  let currentBalance = fullaccountbalancearr[fullaccountbalancearr.length - 1];
  avgDeposit = handleNANGOOD((totalDeposits / numberofDeposits)?.toFixed(2), 0);
  avgWithdrawal = handleNANGOOD(
    (totalWithdrawals / numberofWithdrawals)?.toFixed(2),
    0
  );

  // Calculate trade frequency
  const tradeCount = totalTrades;
  const totalDays = Object.keys(dailyCount).length;
  const totalWeeks = Object.keys(weeklyCount).length;
  const totalMonths = Object.keys(monthlyCount).length; // Approximation for average number of days in a month
  const totalQuarters = Object.keys(quarterlyCount).length;
  const totalYears = Object.keys(yearlyCount).length; // Approximation for average number of days in a year

  // 24HR P/L
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const twentyFourHrsAgo = fullaccountbalancearrwithDates.filter(
    (bal) => new Date(bal.endDate) > yesterday
  );
  let twentyFourHrPL = 0;
  twentyFourHrsAgo.forEach((bal) => {
    twentyfourhrArr.push({
      value: bal.amount,
      id: bal.id,
    });
    twentyFourHrPL += bal.amount;
  });
  twentyFourHrPL = handleNANGOOD(twentyFourHrPL?.toFixed(2), "-");

  // ------------------------ Current Streak ------------------------
  let prevcheck = "";
  let streakType = "";
  let streakTypeArray = [];
  let k = 0;
  for (let i = filteredTrades.length - 1, j = 0; i >= j; i--) {
    let tempvalue = 0;
    let multiExecution = filteredTrades[i].entry.multiExecution;
    let exitExecution = filteredTrades[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    if (calctype === "Gross") {
      tempvalue = Number(filteredTrades[i].entry.profitLoss * 100);
    } else if (calctype === "Net") {
      tempvalue =
        Number(filteredTrades[i].entry.profitLoss * 100) +
        Number(filteredTrades[i].entry.commissions * 100) +
        Number(filteredTrades[i].entry.fees * 100);
    }
    if (
      filteredTrades[i].entry.orderType === "Deposit" ||
      filteredTrades[i].entry.orderType === "Withdrawal" ||
      filteredTrades[i].entry.orderType === "Funding Payment" ||
      filteredTrades[i].entry.orderType === "Commit" ||
      filteredTrades[i].entry.orderType === "Approval" ||
      filteredTrades[i].entry.orderType === "Wrap" ||
      filteredTrades[i].entry.orderType === "Self"
    ) {
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      if (tempvalue > 0) {
        streakType = "win";
      } else if (tempvalue < 0) {
        streakType = "loss";
      } else if (tempvalue === 0) {
        streakType = "break-even";
      }
      if (streakTypeArray[0] === undefined) {
        streakTypeArray[0] = streakType;
        prevcheck = streakType;
      } else {
        streakTypeArray[k + 1] = streakType;
        if (streakTypeArray[k + 1] !== prevcheck) {
          streakTypeArray.pop();
          break;
        } else {
          prevcheck = streakType;
          k += 1;
        }
      }
    }
  }
  let currentStreak = "";
  if (streakTypeArray.length === 0) {
    currentStreak = "0 trades";
  } else if (streakTypeArray.length === 1) {
    currentStreak = "1 " + String(streakTypeArray[0]);
  } else {
    if (streakTypeArray[0] === "win" || streakTypeArray[0] === "break-even") {
      currentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "s";
    } else {
      currentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "es";
    }
  }

  let daily_avgProfitLoss = calculateAverage(dailyPL, dailyCount);
  let weekly_avgProfitLoss = calculateAverage(weeklyPL, weeklyCount);
  let monthly_avgProfitLoss = calculateAverage(monthlyPL, monthlyCount);
  let quarterly_avgProfitLoss = calculateAverage(monthlyPL, monthlyCount);
  let yearly_avgProfitLoss = calculateAverage(yearlyPL, yearlyCount);

  // Graphs
  let profitLossRatioGraph = profitlossRatioArr;
  let profitFactorGraph = profitFactorArr;
  let expectationGraph = expectationArr;
  let cpcGraph = cpcArr;
  let totalprofitLossGraph = profitLossArr2;
  let fullaccountbalanceGraph = fullaccountbalancearrForGraph;
  let avgPLGraph = avgPLArr;
  let probofRandomChanceGraph = probChancearr;
  let avgHoldingTimeGraph = avgHoldingTimeArr;
  let tradefuseGoldenRatioGraph = tradefuseGoldenRatioArr;
  let tradefuseIndexGraph = tradefuseIndexArr;
  let recoveryFactorGraph = recoveryFactorArr;
  let avgPositionSizeGraph = avgpositionSizeArr;
  let avgRiskRewardRatioGraph = avgRiskRewardRatioArr;
  let RMultipleRatioGraph = rMultipleArr;
  let volatilityGraph = volatilityArr;
  let volatilityPercentGraph = volatilityPercentArr;
  let zScoreGraph = zScoreArr;
  let ulcerIndexGraph = ulcerIndexArr;
  let avgEfficiencyGraph = avgEfficiencyArrForGraph;
  let averageRiskPerTradeGraph = averageRiskPerTradeArr;
  let maxDrawdownPercentGraph = maxDrawdownPercentArr;
  let maxDrawdownDollarGraph = maxDrawdownDollarArr;
  let maxRunUpPercentGraph = maxRunUpPercentArr;
  let maxRunUpDollarGraph = maxRunUpDollarArr;
  let arithmeticSharpeRatioGraph = arithmeticSharpeRatioArr;
  let geometricSharpeRatioGraph = geometricSharpeRatioArr;
  let arithmeticSortinoRatioGraph = arithmeticSortinoRatioArr;
  let geometricSortinoRatioGraph = geometricSortinoRatioArr;
  let percentConfidenceGraph = percentConfidenceArr;
  let LRCorrelationGraph = LRCorrelationArr;
  let LRStandardErrorGraph = LRStandardErrorArr;
  let KRatioGraph = KRatioArr;
  let standardDeviationGraph = standardDeviationArr;
  let statisticalZScoreGraph = statisticalZScoreArr;
  let standardDeviationPercentGraph = standardDeviationPercentArr;
  let totalVolumeTradedGraph = totalVolumeArr;
  let volumeTradedGraph = volumeArr;
  let avgVolumeTradedGraph = avgVolumeArr;
  let winRateGraph = winRateArr;
  let lossRateGraph = lossRateArr;
  let breakEvenRateGraph = beRateArr;

  // Result
  result = {
    // ------- Calculations -------
    winRate,
    lossRate,
    breakevensRate,
    totalTrades,
    maxDrawdownDollar,
    maxDrawdownPercent,
    dateofMaxDrawdown,
    dateofMaxDrawdownPercent,
    averageMaxDrawdownDollar,
    averageMaxDrawdownPercent,
    averageDrawdownDollar,
    averageDrawdownPercent,
    longestDrawdownPeriod,
    averageDrawdownPeriod,
    absoluteDrawdownDollar,
    absoluteDrawdownPercent,
    maxRunUpDollar,
    maxRunUpPercent,
    dateofMaxRunUp,
    averageMaxRunUpDollar,
    averageRunUpDollar,
    longestRunUpPeriod,
    absoluteRunUpDollar,
    absoluteRunUpPercent,
    averageMaxRunUpPercent,
    averageRunUpPercent,
    averageRunUpPeriod,
    totalVolumeTraded,
    avgVolumeTraded,
    avgRisk,
    avgRiskRewardRatio,
    avgRiskRewardRatiovalueonly,
    avgEfficiency,
    expectation,
    percentTimeinMarket,
    avgPositionSize,
    tradefuseGoldenRatio,
    tradefuseIndex,
    profitLossRatio,
    CPCIndex,
    ulcerIndex,
    profitFactor,
    recoveryFactor,
    avgHoldingTime,
    rmultipleratio,
    kellypercent,
    normalizedExpectancypercent,
    arithmeticSharpeRatio,
    arithmeticSortinoRatio,
    geometricSharpeRatio,
    geometricSortinoRatio,
    zScore,
    statisticalZScore,
    percentConfidence,
    avgProfitLoss,
    twentyFourHrPL,
    standardDeviation,
    standardDeviationPercent,
    volatility,
    volatilityPercent,
    totalprofitLoss,
    totalprofitLoss_percent,
    currentStreak,
    optimalf: handleNANGOOD(optimalf, "-"),
    optimalleverage: handleNANGOOD(optimalleverage, "-"),
    theoreticalunitsize: handleNANGOOD(theoreticalunitsize, "-"),
    royssafetyfirstcriteria: handleNANGOOD(royssafetyfirstcriteria, "-"),
    kellycriterion: handleNANGOOD(kellycriterion, "-"),
    larrywilliams: handleNANGOOD(larrywilliams, "-"),
    KRatio,
    LRCorrelation,
    LRStandardError,
    probabilityOfRandomChance,
    currentBalance,
    totalSlippage,
    totalWithdrawals,
    totalDeposits,
    numberofDeposits,
    avgDeposit,
    avgWithdrawal,
    numberofWithdrawals,
    totalCommissions,
    totalFees,

    // DAILY Metrics
    daily_avgProfitLoss,

    // WEEKLY Metrics
    weekly_avgProfitLoss,

    // MONTHLY Metrics
    monthly_avgProfitLoss,

    // QUARTERLY Metrics
    quarterly_avgProfitLoss,

    // YEARLY Metrics
    yearly_avgProfitLoss,
    // ------- Graphs -------
    profitLossRatioGraph,
    avgHoldingTimeGraph,
    totalprofitLossGraph,
    avgPLGraph,
    tradefuseGoldenRatioGraph,
    tradefuseIndexGraph,
    avgRiskRewardRatioGraph,
    zScoreGraph,
    RMultipleRatioGraph,
    profitFactorGraph,
    probofRandomChanceGraph,
    recoveryFactorGraph,
    expectationGraph,
    volatilityGraph,
    volatilityPercentGraph,
    standardDeviationGraph,
    statisticalZScoreGraph,
    standardDeviationPercentGraph,
    cpcGraph,
    ulcerIndexGraph,
    fullaccountbalanceGraph,
    percentConfidenceGraph,
    avgEfficiencyGraph,
    averageRiskPerTradeGraph,
    avgPositionSizeGraph,
    maxDrawdownPercentGraph,
    maxDrawdownDollarGraph,
    maxRunUpPercentGraph,
    maxRunUpDollarGraph,
    arithmeticSharpeRatioGraph,
    geometricSharpeRatioGraph,
    arithmeticSortinoRatioGraph,
    geometricSortinoRatioGraph,
    LRCorrelationGraph,
    LRStandardErrorGraph,
    KRatioGraph,
    totalVolumeTradedGraph,
    volumeTradedGraph,
    avgVolumeTradedGraph,
    winRateGraph,
    lossRateGraph,
    breakEvenRateGraph,
    // ----------------------
    start_Balance,
    tradefrequencyperday:
      totalDays === 0 || !totalDays || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalDays)?.toFixed(2)} trades/`,
    tradefrequencyperweek:
      totalWeeks === 0 || !totalWeeks || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalWeeks)?.toFixed(2)} trades/`,
    tradefrequencypermonth:
      totalMonths === 0 || !totalMonths || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalMonths)?.toFixed(2)} trades/`,
    tradefrequencyperquarter:
      totalQuarters === 0 || !totalQuarters || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalQuarters)?.toFixed(2)} trades/`,
    tradefrequencyperyear:
      totalYears === 0 || !totalYears || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalYears)?.toFixed(2)} trades/`,
    winlossesbe: {
      wins: totalWinningTrades,
      losses: totalLosingTrades,
      bes: totalBEs,
    },
    assetclasses: {
      totalStockTrades: totalStockTrades,
      totalCryptoTrades: totalCryptoTrades,
      totalCFDTrades: totalCFDTrades,
      totalForexTrades: totalForexTrades,
      totalFutureTrades: totalFutureTrades,
      totalOptionTrades: totalOptionTrades,
    },
  };
  return result;
};

export default quantumCalculations;
