const initialState = {
  location: "0px",
};

export const filtersToggleLocation = (state = initialState, action) => {
  switch (action.type) {
    case "SETFILTERSTOGGLELOCATION":
      return action.npmstrat;

    default:
      return state;
  }
};

export default filtersToggleLocation;
