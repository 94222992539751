export const deleteNOTVisible = (deletebutton, tagbutton) => {
  deletebutton.style.visibility = "hidden";
  deletebutton.style.opacity = "0";
  tagbutton.style.visibility = "hidden";
  tagbutton.style.opacity = "0";
  /*   sharebutton.style.visibility = "hidden";
  sharebutton.style.opacity = "0"; */
};
export const deleteNOTtNONEVisible = (deletebutton, tagbutton) => {
  deletebutton.style.visibility = "hidden";
  deletebutton.style.opacity = "0";
  deletebutton.style.transition = "none";
  tagbutton.style.visibility = "hidden";
  tagbutton.style.opacity = "0";
  tagbutton.style.transition = "none";
  /*   sharebutton.style.visibility = "hidden";
  sharebutton.style.opacity = "0";
  sharebutton.style.transition = "none"; */
};
export const deleteISVisible = (deletebutton, tagbutton) => {
  deletebutton.style.visibility = "visible";
  deletebutton.style.opacity = "100";
  tagbutton.style.visibility = "visible";
  tagbutton.style.opacity = "100";
  deletebutton.style.transition = "all 0.1s ease-in-out";
  tagbutton.style.transition = "all 0.1s ease-in-out";
  /*   sharebutton.style.visibility = "visible";
  sharebutton.style.opacity = "100";
  sharebutton.style.transition = "all 0.1s ease-in-out"; */
};
export const isCheckedFunction = (buffer, isssAccessorChecked) => {
  const elem = document.getElementById("boxHeaderChecks");
  elem && (elem.checked = buffer);
  for (var i = 0, j = isssAccessorChecked.length; i < j; i++) {
    isssAccessorChecked[i].checked = buffer;
  }
};
export const isCTheckedFunction = (buffer, isssAccessorChecked) => {
  const elem = document.getElementById("boxAccessorChecks");
  elem && (elem.checked = buffer);
  for (var i = 0, j = isssAccessorChecked.length; i < j; i++) {
    isssAccessorChecked[i].checked = buffer;
  }
};
export default isCheckedFunction;
