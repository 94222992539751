import React from "react";
import { showAvgMetricTable } from "./MetricFunctions";
var he = require("he");

const AveragePerformanceMetrics = (props) => {
  const porttype = props.userPortfolioType[0];
  const porttypelength = props.userPortfolioType.length;
  const typestring =
    porttypelength !== 1
      ? "Units"
      : porttype === "Stocks"
      ? "Shares"
      : porttype === "Options" || porttype === "Futures"
      ? "Contracts"
      : porttype === "Forex"
      ? "Lots"
      : porttype === "Crypto"
      ? "Coins/Tokens"
      : porttype === "CFD"
      ? "Quantity"
      : "Units";
  const AvgMetricsLabels = [
    "Avg. P/L Per Trade " + he.decode(props.defaultSymbol),
    "Avg. P/L Per Trade %",
    `Avg. ${typestring} Per Trade`,
    "Avg. Executions Per Trade",
    "Avg. Slippage Per Trade " + he.decode(props.defaultSymbol),
  ];

  const avgMetrics = [
    props.userCalculations["averagePLPerTradedollar"],
    props.userCalculations["averagePLPerTradePercent"],
    parseFloat(props.userCalculations["averageLotSizePerTrade"])?.toFixed(2),
    parseFloat(props.userCalculations["averageExecutionsPerTrade"])?.toFixed(2),
    props.userCalculations["averageSlippagePerTrade"],
  ];

  return (
    <div className="avgmetricomponentwrapper">
      <div className="avg-metrics-label" id="avg-metrics-label">
        Averages
      </div>
      <div className="avg-metrics-table" id="metric-component">
        <div>{showAvgMetricTable(avgMetrics, AvgMetricsLabels, props)}</div>
      </div>
    </div>
  );
};

export default AveragePerformanceMetrics;
