import React from 'react';
import PropTypes from 'prop-types';

import PageTemplate from '../../components/PageTemplate/PageTemplate';


const BreakEvensReport = (props) => (
  <PageTemplate
    title={props.tabName}
    page={
      <div> Break Even Report </div>
    } />
);

BreakEvensReport.defaultProps = {
  tabName: "Break-Evens"
}

BreakEvensReport.propTypes = {
  tabName: PropTypes.string,
}
export default BreakEvensReport;
