import React, { PureComponent } from "react";
import CreatableSelect from "react-select/creatable";
import theme from "../../../components/selectTheme";
import store from "./../../../store";
import createOption from "./createOption";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { DataContext } from "../../../DataContext";
import dropdownStyles from "./../../../functions/dropdownStyles";
class CreatableAdvanced extends PureComponent {
  static contextType = DataContext;

  state = {
    isLoading: false,
    options: this.props.defaultOptions,
    id: this.props.id,
    value: this.props.defaultFilterValue,
  };
  handleSubmit = async () => {
    const reduxFilters = store.getState().defaultfilterValue.filtervalue;
    const filterProp = !this.props.allData.portfolio.settings.filters
      ? {
          dateRangeFilter: null,
          hourRangeFilter: null,
          orderTypeFilter: null,
          symbolFilter: null,
          strategyFilter: null,
          emotionalStateFilter: null,
          physicalStateFilter: null,
          mistakeFilter: null,
          timeFrameFilter: null,
          confidenceFilter: null,
          marketStateFilter: null,
          dayOfWeekFilter: null,
          monthFilter: null,
          assetClassFilter: null,
          tagsFilter: null,
          yearFilter: null,
        }
      : reduxFilters;

    localStorage.setItem("filtersAreRunning", "yes");
    localStorage.setItem("filters", JSON.stringify(filterProp));
  };
  handleChange = async (newValue) => {
    const savecheck =
      this.props.allData && this.props.allData.portfolio.settings.savefilters;
    await this.setState({ value: newValue });
    let buffer = this.state.value;
    if (buffer === null) {
      this.props.selectedOptions(buffer);
      this.props.history.push("/BlankPage");
      this.props.history.goBack();
      savecheck && this.handleSubmit(); // crucial this is last
    } else if (
      buffer.label === "Custom Range" &&
      this.props.id === "dateRangeFilter"
    ) {
      $("#choose-date-range").fadeIn(300);
      $("#overlay").fadeIn(300);
      this.setState({ value: "" });
      document.getElementById("choose-date-range").style.display = "flex";
    } else if (
      buffer.label === "Custom Range" &&
      this.props.id === "hourRangeFilter"
    ) {
      $("#choose-time-range").fadeIn(300);
      $("#overlay").fadeIn(300);
      this.setState({ value: "" });
      document.getElementById("choose-time-range").style.display = "flex";
    } else {
      this.props.selectedOptions(buffer);
      this.props.history.push("/BlankPage");
      this.props.history.goBack();
      savecheck && this.handleSubmit();
    }
  };

  handleCreate = (inputValue) => {
    const savecheck = this.props.allData.portfolio.settings.savefilters;
    this.setState({ isLoading: true });
    document.getElementById("noclick").style.display = "inline";

    const { options } = this.state;
    const newOption = createOption(inputValue);

    setTimeout(async () => {
      const getTemporaryArray = () => {
        let temporaryFilterArray = [];
        for (
          var i = 0,
            j =
              this.props.defaultFilterValue === null
                ? 0
                : this.props.defaultFilterValue.length;
          i < j;
          i++
        ) {
          temporaryFilterArray = temporaryFilterArray.concat(
            this.props.defaultFilterValue[i]
          );
        }
        return temporaryFilterArray;
      };
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: [...getTemporaryArray(), newOption],
      });
      document.getElementById("noclick").style.display = "none";
      let buffernu = this.state.value;
      this.props.selectedOptions(buffernu);
      this.props.history.push("/BlankPage");
      this.props.history.goBack();
      savecheck && this.handleSubmit();
    }, 1000);
  };

  render() {
    const { isLoading, options, value } = this.state;
    return (
      <CreatableSelect
        styles={dropdownStyles}
        isClearable={true}
        backspaceRemovesValue={true}
        isMulti={this.props.isMulti}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.handleChange}
        onCreateOption={this.handleCreate}
        options={options}
        value={value}
        theme={theme}
        placeholder={this.props.placeHolder}
      />
    );
  }
}

export default withRouter(CreatableAdvanced);
