import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from '../../components/PageTemplate/PageTemplate';


const WeeklyReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Weekly Report </div>
  } />
);

WeeklyReport.defaultProps = {
tabName: "Weekly"
}

WeeklyReport.propTypes = {
tabName: PropTypes.string,
}

export default WeeklyReport;

