import convertUTCDateToLocalDate from "../../convertUTCDateToLocalTime";
import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation";
import { montharr, weekday } from "../../../arrays/weekandmonth";
import sha256 from "./../../sha256";
import { filterByID } from "./../../filterByID";

export const parseKrakenOrders = (
  tradesIn, // trades already imported
  idsIn, // arrays if ids already imported
  portfolioIn, // visible portfolio (selected portfolio or default)
  tradedata, // incoming entries from dydx
  userId,
  verified, // trade verification level
  importType
) => {
  let trades = tradesIn;
  let ids = idsIn;
  let portfolio = portfolioIn;
  let changedIds = [];
  return { trades: trades, ids: ids, changedIds: changedIds };
};

export default parseKrakenOrders;
