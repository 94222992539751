import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useCallback,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import mergeTradefromWebSockets from "./functions/autoimport/mergeTradefromWebSockets";
import autoimportws from "./functions/autoimport/autoimportws";
import getDataandSettings from "./utils/getDataandSettings";
import initialLoadMerge from "./functions/autoimport/initialLoadMerge";
import mergeTradesfromPolling from "./functions/autoimport/mergeTradesfromPolling";
import $ from "jquery";
import generateInitData from "./functions/generateINITData";
import updateStripeCustomerWrapper from "./utils/stripe/updateStripeCustomer";
import { useAuth0 } from "@auth0/auth0-react";
import getCustomer from "./utils/stripe/getStripeCustomer";
import { stripePriceIdsMapping } from "./utils/stripe/stripetiers";
import getKeyByValue from "./functions/getKeyByValue";
import useStateWithPromise from "./hooks/useStateWithPromise";
import usePrevious from "./hooks/usePrevious";
import getBC4Month from "./utils/getRiskFreeRate";
import Modal from "react-modal";
import { relayLinkRobinhood } from "./functions/robinhood/utils";
import store from "./store";
import { setLoaderState } from "./actions/actionCreators";
import AutoImportModal from "./pages/Integrations/AutoImportModal";
import AlwaysLoadingLoader from "./components/FullPageLoader/AlwaysLoadingLoader";
import { Paywall } from "./paywall";

import "./index.scss";
import { getSP500Data } from "./utils/getSPdata";

const NodeRSA = require("node-rsa");

const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");

const DataContext = createContext(undefined);
/********

// THIS CONTEXT IS RESPONSIBLE FOR GETTING THE DATA FROM AWS
// AND PASSING IT DOWN TO CHILDREN THROUGH THE VALUE PROP.
// WE USE A CONTEXT TO KEEP THE "allData" STATE CONSISTENT ACROSS ROUTE CHANGES
// WHEN UPDATES ARE MADE VIA WEBSOCKETS, USER EDITS A TRADE, ETC.
// THE WEBSOCKET FUNCTIONS ARE DIRECTLY IN THIS CONTEXT AS WELL, 
// AGAIN TO ENSURE LIVE UPDATE CONSISTENCY
 
/*********
 *
 * @param {Object} allData the eventual Object of data we pass down to all children.
 * @param {Object} allData.calculations are page specific calculations
 * @param {Object} allData.portfolio is the chosen portfolio. Default if more than one is selected
 * @param {Array} allData.filterLists are dropdown lists generated from the user's data
 * @param {Object} allData.allData is the data stored in Skynet specifically
 * ************************** also subject to change in the future
 */

const DataProvider = ({ children }) => {
  const [allData, setallData] = useState({
    data: {},
  });
  const [riskFreeRate, setriskFreeRate] = useState(0);
  const [SP500Data, setSP500Data] = useState([]);

  const prevDataRef = useRef(allData);
  const [, setisLoaded] = useState(false);
  const [need2faOpen, setneed2faOpen] = useState(false);
  const [paywallShow, setpaywallShow] = useState(false);
  const [paywallState, setpaywallState] = useState();
  const [subscriptionEND, setsubscriptionEND] = useState();

  const [connectionsArray, setconnectionsArray] = useState([]);
  const [mfaCodeError, setmfaCodeError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const savedMessage = localStorage.getItem("autoiterationMessage");
  const [message, setMessage] = useState(savedMessage ? savedMessage : "");
  const [customer, setCustomer] = useStateWithPromise({
    active: null,
    tier: null,
    priceId: null,
    subscription: [],
    stripeId: null,
    email: "",
  });
  //const { tier, active } = customer;
  const { user, isAuthenticated } = useAuth0();
  const firstUpdate = useRef(isAuthenticated);

  const sockets = useRef([]);
  const openApplyModal = async () => {
    setneed2faOpen(true);
  };
  const closeApplyModal = () => {
    for (let i = 0, j = connectionsArray.length; i < j; i++) {
      const connection = connectionsArray[i];
      localStorage.removeItem(`${connection.broker}Code`);
    }
    setmfaCodeError(false);
    setneed2faOpen(false);
  };

  const relayData = useCallback(
    async (msgData, connection) => {
      //const allDataIn = allData.data;
      localStorage.setItem("incomingMsg", "yes");
      //const initData = generateInitData(allDataIn);
      //const matchingPortfolios = initData && initData.matchingPortfolios;
      mergeTradefromWebSockets(
        setallData,
        connection,
        user.sub,
        msgData,
        prevDataRef,
        history
      );
    },
    [user]
  );
  // ----------------------------------------------------------

  // ======== GET STRIPE CUSTOMER FUNCTION ========
  const fetchCustomer = async (customerId) => {
    return await getCustomer(customerId, history).then((res) => {
      return res;
    }); // stripe customer obj
  };

  useEffect(() => {
    prevDataRef.current = allData;
  }, [allData]);

  // THIS USEEFFECT CALLS FETCHDATA ON INITIAL LOAD
  useEffect(() => {
    // FUNCTION TO HANDLE WHEN WE SHOULD REFRESH THE "initialLoad" localStorage var
    window.onload = function () {
      var refreshing = localStorage.getItem("refreshing");
      if (refreshing) {
        localStorage.removeItem("refreshing");
        localStorage.removeItem("initialLoad");
      }
    };

    // ----------------------------------------
    // THIS FUNCTION INITIALY GETS THE DATA FROM AWS
    // AND MERGES THE NEW TRADES FROM THE LINKED BROKER IF NECESSARY
    // ----------------------------------------------------------
    const fetchData = async (loaderState) => {
      const getDataSettingsFunction = await getDataandSettings(
        user,
        loaderState,
        history
      );
      if (getDataSettingsFunction === "SendToStripe") {
        setpaywallShow(true);
        setpaywallState("initial");
      } else {
        const initData = generateInitData(getDataSettingsFunction);
        const matchingPortfolios = initData.matchingPortfolios;

        // Create array of connections info based on selected portfolios
        let autoImportConnectionsArray = [];

        matchingPortfolios.forEach((portfolio) => {
          const linkedBroker = portfolio.settings.linkedBrokerInfo;
          linkedBroker.broker !== "" &&
            autoImportConnectionsArray.push({
              ...portfolio.settings.linkedBrokerInfo,
              port: portfolio.name,
            });
        });
        setconnectionsArray(autoImportConnectionsArray);

        const customerId =
          getDataSettingsFunction === null
            ? ""
            : getDataSettingsFunction.globalSettings.stripeId;
        // ===========================================
        // Actually get the customer
        // Unfortunately we have to call it here
        // instead of in a separate provider
        const customer = await fetchCustomer(customerId);
        const email = customer.email;
        const subscription = customer.subscriptions;
        const subscriptiondata = subscription && subscription.data;
        let priceIdf = "";
        let activef = "";
        let tierf = "free";
        if (!subscriptiondata?.length) {
          // They are not subscribed to anything
        } else {
          priceIdf = subscriptiondata && subscriptiondata[0].plan.id;
          activef = subscriptiondata && subscriptiondata[0].plan.active;
          tierf = getKeyByValue(stripePriceIdsMapping, priceIdf)[0];
        }
        const finalTier = tierf?.replace(/[0-9]/g, "");
        const updateObj = {
          tier: finalTier, // removes numbers from tier
          priceId: priceIdf,
          active: activef,
          subscription: subscriptiondata,
          email: email,
          stripeId: customerId,
        };
        const tierfPre = tierf === "free" ? true : false;
        setCustomer(updateObj);
        setpaywallShow(tierfPre);
        tierfPre === true && setpaywallState("expired");

        // ======== SET STRIPE "LAST SESSION" ========

        var initialLogin = localStorage.getItem("initialLogin");
        if (initialLogin === "yes") {
          await updateStripeCustomerWrapper(
            customerId,
            getDataSettingsFunction,
            "login",
            user.sub,
            history
          );
          // check if the auto imports includes one that needs mfa code
          // then prompt the user if so
          localStorage.setItem("initialLogin", "no");
          const neededMFAs = autoImportConnectionsArray.some(
            (obj) => obj.broker === "robinhood" /* || other brokers */
          );
          if (autoImportConnectionsArray?.length && neededMFAs) {
            if (customer) {
              if (
                (customer.tier === "master" ||
                  customer.tier === "ultimate" ||
                  customer.tier === "admin") &&
                customer.active
              ) {
                setneed2faOpen(true);
              }
            }
          }
        }
        await updateStripeCustomerWrapper(
          customerId,
          getDataSettingsFunction,
          "session",
          user.sub,
          history
        );
        localStorage.setItem("trialinitialLogin", "yes"); // to show trial expired modal on new session

        setisLoaded(true); // billing data is available;

        // GET RISK FREE RATE
        const rate = await getBC4Month();
        setriskFreeRate(rate);

        // GET Sp500 Data
        const sp500data = await getSP500Data();
        setSP500Data(sp500data);

        // ------- THIRD PART OF FULL AUTOMATION: RETRIEVE NEW DATA WHEN NECESSARY -------
        // NEED TO MERGE NEW DATA WITH LOGBOOK DATA IF THEY ARENT CAPTURED BY WEBSOCKET MSGS
        let mergedData = [];

        /*       $(window).bind("beforeunload", function () {
          // Removes the intial load variable so that
          // whent he user hard refreshes, it calls initialLoadMerge again
          // which is the expected behavior
  
          // WE HAVE TO REMOVE MT4 CONNECTIONS HERE
          // SO THAT ON PAGE RELOAD, THE CONNECTION WILL REESTABLISH ITSELF
          localStorage.removeItem("canMetaTraderOpen");
  
          return localStorage.removeItem("initialLoad");
  
          MOVED TO APP.JS 6/6/23
        }); */
        const initialLoad = localStorage.getItem("initialLoad");
        if (!initialLoad) {
          if (
            (finalTier === "master" ||
              finalTier === "ultimate" ||
              finalTier === "admin") &&
            activef
          ) {
          } else {
            autoImportConnectionsArray = [];
          }
          mergedData = await initialLoadMerge(
            autoImportConnectionsArray,
            getDataSettingsFunction,
            user.sub,
            history
          );
          localStorage.setItem("initialLoad", "x");
        } else {
          mergedData = getDataSettingsFunction;
        }
        setallData({ data: mergedData });
      }
    };

    if (isAuthenticated && window.location.pathname !== "/Error-Found") {
      fetchData(true);
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [isAuthenticated, user, setCustomer]);
  // ----------------------------------------------------------

  // ------- SECOND PART OF FULL AUTOMATION: WEBSOCKETS -------
  // THIS USEEFFECT INTERCEPTS INCOMING WEBSOCKET MESSAGES AND SETS THEM TO allData
  // RETRIEVE allData AFTER IT HAS FINISHED GETTING
  useEffect(() => {
    // ------- WEBSOCKET FUNCTION -------
    // only call when selected portfolios contain a broker link
    if (customer) {
      if (
        (customer.tier === "master" ||
          customer.tier === "ultimate" ||
          customer.tier === "admin") &&
        customer.active
      ) {
        const initData = generateInitData(allData.data);
        const canCallWS = localStorage.getItem("canCallWS");
        !canCallWS && autoimportws(relayData, initData, sockets);
      } else {
      }
    }
  }, [relayData, allData, customer]);
  // ----------------------------------------------------------

  $(window).bind("beforeunload", function () {
    //localStorage.removeItem("candYdXOpen");
    //localStorage.removeItem("canMetaMaskOpen");

    //localStorage.removeItem("canCallWS");
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
  });

  // THIS USEEFFECT IS THE INTERVAL FOR BROKERS THAT REQUIRE POLLING
  // RETRIEVE allData AFTER IT HAS FINISHED GETTING
  useEffect(() => {
    let interval;
    if (customer) {
      if (
        (customer.tier === "master" ||
          customer.tier === "ultimate" ||
          customer.tier === "admin") &&
        customer.active
      ) {
        const initData = generateInitData(allData.data);
        const matchingPortfolios = initData.matchingPortfolios;

        // Create array of connections info based on selected portfolios
        let autoImportConnectionsArray = [];

        matchingPortfolios.forEach((portfolio) => {
          const linkedBroker = portfolio.settings.linkedBrokerInfo;
          linkedBroker.broker !== "" &&
            autoImportConnectionsArray.push({
              ...portfolio.settings.linkedBrokerInfo,
              port: portfolio.name,
            });
        });

        let poll = false;
        autoImportConnectionsArray.forEach((connection) => {
          if (connection.broker === "robinhood") {
            poll = true;
          } else {
          }
        });

        interval =
          poll &&
          setInterval(async () => {
            const isDBRunning = localStorage.getItem("dbSetRunning");
            isDBRunning !== "yes" &&
              (await mergeTradesfromPolling(
                allData,
                setallData,
                autoImportConnectionsArray,
                user.sub,
                history
              ));
          }, 6000);
      }
    }
    return () => {
      if (customer) {
        if (
          (customer.tier === "master" ||
            customer.tier === "ultimate" ||
            customer.tier === "admin") &&
          customer.active
        ) {
          clearInterval(interval);
        }
      }
    };
  }, [allData, user, customer]);
  // ----------------------------------------------------------
  // poll stripe for paywall show
  useEffect(() => {
    const interval = setInterval(async () => {
      if (customer && customer?.active) {
        const customerId = !customer ? "" : customer.stripeId;
        // ===========================================
        // Actually get the customer
        // Unfortunately we have to call it here
        // instead of in a separate provider
        const customerIn = await fetchCustomer(customerId);
        const email = customer.email;
        const subscription = customerIn.subscriptions;
        const subscriptiondata = subscription && subscription.data;
        let priceIdf = "";
        let tierf = "free";
        let activef = "";
        if (!subscriptiondata?.length) {
          // They are not subscribed to anything
        } else {
          priceIdf = subscriptiondata && subscriptiondata[0].plan.id;
          tierf = getKeyByValue(stripePriceIdsMapping, priceIdf)[0];
          activef = subscriptiondata && subscriptiondata[0].plan.active;
        }
        const finalTier = tierf?.replace(/[0-9]/g, "");
        const updateObj = {
          tier: finalTier, // removes numbers from tier
          priceId: priceIdf,
          active: activef,
          subscription: subscriptiondata,
          email: email,
          stripeId: customerId,
        };
        setCustomer(updateObj);
        const tierfPre = tierf === "free" ? true : false;
        setpaywallShow(tierfPre);

        tierfPre === true && setpaywallState("expired");
      }
    }, 600 * 1000); // Check every 10min

    return () => {
      clearInterval(interval);
    };
  }, [customer, paywallState]);

  // Connection messages
  useEffect(() => {
    const interval = setInterval(() => {
      const savedMessage = localStorage.getItem("autoiterationMessage");
      const parsedMessage = savedMessage !== null ? savedMessage : null;

      setMessage(parsedMessage);
    }, 100); // Check every 300 ms

    return () => {
      clearInterval(interval);
    };
  }, [message]);
  useEffect(() => {
    const element = document.querySelector("#fetchingInsightsloader3");
    if (message && message !== "reconnecting") {
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
    } else {
      if (element) {
        element.style.opacity = 0;
        element.style.transform = "translate(100%, 0)";
        element.style.transition = "none";
      }
    }
    return () => {};
  }, [message]);
  const submitMfaFunction = async () => {
    for (let i = 0, j = connectionsArray.length; i < j; i++) {
      const connection = connectionsArray[i];
      if (connection.broker === "robinhood") {
        const code = localStorage.getItem(`${connection.broker}Code`);
        //decrypt credentials
        const email = connection.email;
        const apiCredsPre = connection.password;
        const decryptedString = key.decrypt(apiCredsPre, "utf8");
        const password = JSON.parse(decryptedString);
        const relayData =
          code && (await relayLinkRobinhood(email, password, code));
        const accessToken = relayData?.initializedRobinhood?.access_token;

        if (!accessToken) {
          localStorage.removeItem(`${connection.broker}Code`);
          setmfaCodeError(true);
          setTimeout(() => {
            setmfaCodeError(false);
          }, 6000);
          return;
        }
        // Only call these if the token exists
        closeApplyModal();
        setmfaCodeError(false);
        /*         const encryptedToken = key.encrypt(
          JSON.stringify(accessToken),
          "base64"
        ); */
        localStorage.setItem(
          `${connection.broker}Token${connection.portfolio}`,
          accessToken
        );
      }
    }
  };
  const brokerNameMapper = (name) => {
    let brokerName = "";
    if (name === "robinhood") {
      brokerName = "Robinhood";
    }
    return brokerName;
  };

  const showLoaderCheck =
    message !== "AI scores ready!" &&
    message !== "Robinhood connection successful!" &&
    message !== "dYdX connection successful!" &&
    message !== "MetaTrader connection successful!" &&
    message !== "MetaMask connection successful!" &&
    message !== "An error has occurred. Refreshing..." &&
    message !== "Connection failed. Wait a few moments and try again.";

  const showErrorCheck =
    message !== "An error has occurred. Refreshing..." &&
    message !== "Connection failed. Wait a few moments and try again.";

  return (
    <DataContext.Provider
      value={{
        allData: allData.data,
        updateAllData: (data) => setallData({ data: data }),
        setmfaModalOpen: (e) => setneed2faOpen(e),
        connectionsArray: connectionsArray,
        setconnectionsArray: (e) => setconnectionsArray(e),
        sockets: sockets,
        customer: customer,
        setCustomer: (e) => setCustomer(e),
        miscData: {
          riskFreeRate: Math.pow(1 + riskFreeRate.rate / 100, 1 / 252) - 1,
          SP500Data: SP500Data,
          need2faOpen: need2faOpen,
        },
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <Modal
          ariaHideApp={false}
          id="CODEConfirmationModal"
          className="CODEConfirmationModal"
          isOpen={need2faOpen}
          //onRequestClose={closeApplyModal}
          closeTimeoutMS={250}
          contentLabel="Trade"
          overlayClassName="OverlayModal"
        >
          <div className="modalSymbolHeaderData">{"MFA CODE"}</div>
          <div className="deletemodaltext">
            {`Protecting your broker information associated with integrations is our top priority. To access your integration(s), enter your authorization code(s).`}
          </div>
          <div className="mfcode-text-input-boxes-superwrapper">
            {connectionsArray.map((connection, index) => {
              const valueIn = localStorage.getItem(`${connection.broker}Code`);
              return (
                connection.broker === "robinhood" && ( // and other brokers with mfa codes!!
                  <div className="mfcode-text-input-boxes-wrapper" key={index}>
                    {brokerNameMapper(connection.broker) + " code"}
                    {mfaCodeError && (
                      <span className="mandatory4">{"Invalid Code"}</span>
                    )}
                    <input
                      id="logbook-text-input-boxes-stopLoss"
                      name="stopLoss"
                      style={{ paddingLeft: "10px", marginTop: 0 }}
                      value={valueIn}
                      onChange={(e) => {
                        localStorage.setItem(
                          `${connection.broker}Code`,
                          e.target.value
                        );
                      }}
                      className="logbook-text-input-boxes"
                      autoComplete="off"
                      type="password"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                    />
                  </div>
                )
              );
            })}
          </div>

          <button
            className="submitmfabutton"
            onClick={() => {
              submitMfaFunction();
            }}
          >
            {"Submit"}
            {/* <!-- Order Success Checkmark --> */}
            {/*             <div class="checkmark-wrapper mt-5">
                <span class="checkmarkz"></span>
            </div> */}
            {/* <!-- Order Success Checkmark --> */}
          </button>
          <button
            className="clearmfaButton"
            onClick={() => {
              closeApplyModal();
            }}
          >
            {"Ask Me Later"}
          </button>
        </Modal>{" "}
        <span
          id={"fetchingInsightsloader3"}
          className={`${message ? "showflex" : ""}`}
        >
          <span
            className={
              showLoaderCheck
                ? "finalloadtext"
                : showErrorCheck
                ? "notfinalloadtext"
                : "finalerrortext"
            }
          >
            {message}&nbsp;&nbsp;
            {message === "AI scores ready!" &&
            window.location.pathname !== "/Compare-&-Conquer" ? (
              <button
                className="addassetClassButton2"
                onClick={() => {
                  history.push("/Compare-&-Conquer");
                  window.location.reload();
                }}
              >
                View
              </button>
            ) : (
              ""
            )}
          </span>
          {showLoaderCheck && <AlwaysLoadingLoader />}
          {/*           {!showErrorCheck && location.pathname !== "/Integrations" && (
            <div
              className="retrybuttonerror"
              onClick={async () => {
                await history.push("/Integrations");
              }}
            >
              Retry &#8594;
            </div>
          )} */}
          <span
            className="fetchingInsightsloader3x"
            onClick={() => {
              localStorage.removeItem("autoiterationMessage");
              setMessage(false);

              //setfetchingAImessage(false);
            }}
          ></span>
        </span>
        {paywallShow ? (
          <Paywall
            user={user}
            customer={customer}
            setCustomerIdInit={() => {}}
            paywallState={paywallState}
          />
        ) : (
          children
        )}
        {/*         <Paywall
          user={user}
          customer={customer}
          setCustomerIdInit={() => {}}
          paywallState={paywallState}
        /> */}
      </div>
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
