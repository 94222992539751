// from rh strategy text, convert to actual TradeFuse options type
export const rhOptionsStrategyParser = (strategy) => {
  let orderType =
    strategy === "short_put_spread"
      ? "Put Debit Spread"
      : strategy === "short_call_spread"
      ? "Call Debit Spread"
      : strategy === "long_put_spread"
      ? "Put Credit Spread"
      : strategy === "long_call_spread"
      ? "Call Credit Spread"
      : strategy === "iron_condor"
      ? "Iron Condor"
      : strategy === "long_call"
      ? "Long Call"
      : strategy === "short_call"
      ? "Short Call"
      : strategy === "long_put"
      ? "Long Put"
      : strategy === "short_put"
      ? "Short Put"
      : strategy === "iron_butterfly"
      ? "Iron Butterfly"
      : strategy === "calendar_spread"
      ? "Calendar Spread"
      : "";
  return orderType;
};
export default rhOptionsStrategyParser;
