import { proddev } from "../../PROD-DEV-TOGGLE";

const retrievePicture = async (data) => {
  const stringifiedData = JSON.stringify(data);

  const request = await fetch(
    proddev === "prod"
      ? "https://opkt3gy2of.execute-api.us-west-1.amazonaws.com/prod/retrieve-pictures"
      : "https://c5jfnnmixj.execute-api.us-west-1.amazonaws.com/default/retrieve-pictures",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: stringifiedData,
    }
  );
  return request.json();
};

export default retrievePicture;