export const columnIds = [
  "dateTimeOpened",
  "dateTimeClosed",
  "holdingTime",
  "profitLoss",
  "netPLdollar",
  "netPLpercent",
  "orderType",
  "symbol",
  "executions",
  "lotSize",
  "entryPrice",
  "expectedEntry",
  "stopLoss",
  "takeProfit",
  "exitPrice",
  "expectedExit",
  "slippage",
  "commissions",
  "fees",
  "maxAdEx",
  "maxFavEx",
  "efficiency",
  "riskpercent",
  "rewardriskratio",
  "rmultiple",
  "strategy",
  "selectedConfidence",
  "selectedEmotion",
  "selectedPhysical",
  "selectedMarket",
  "selectedTimeFrame",
  "selectedMistake",
  "selectedTags",
];
export const columnNames = [
  "dtopenedcheckbox",
  "dtclosedcheckbox",
  "holdingtimecheckbox",
  "plamountcheckbox",
  "netpldollarcheckbox",
  "netplpercentcheckbox",
  "ordertypecheckbox",
  "symbolcheckbox",
  "executionscheckbox",
  "lotsizecheckbox",
  "entrypricecheckbox",
  "expectedentrycheckbox",
  "stoplosscheckbox",
  "takeprofitcheckbox",
  "exitpricecheckbox",
  "expectedexitcheckbox",
  "slippagecheckbox",
  "commissionscheckbox",
  "feescheckbox",
  "maxadexcheckbox",
  "maxfavexcheckbox",
  "efficiencybarcheckbox",
  "riskpercentcheckbox",
  "rewardriskcheckbox",
  "rmultiplecheckbox",
  "strategycheckbox",
  "confidencecheckbox",
  "emotionalstatecheckbox",
  "physicalstatecheckbox",
  "marketstatecheckbox",
  "timeframecheckbox",
  "mistakecheckbox",
  "tagcheckbox",
];
export const entryDataIDs = [
  "entry.startDateTime",
  "entry.endDateTime",
  "holdingtime",
  "entry.profitLoss",
  "netPLdollar",
  "netPLpercent",
  "entry.orderType",
  "entry.symbol.symbols",
  "executions",
  "entry.lotSize",
  "entry.entryPrice",
  "entry.expectedEntry",
  "entry.stopLoss",
  "entry.takeProfit",
  "entry.exitPrice",
  "entry.expectedExit",
  "slippage",
  "entry.commissions",
  "entry.fees",
  "entry.maxAdEx",
  "entry.maxFavEx",
  "efficiency",
  "riskpercent",
  "rewardriskratio",
  "rmultiple",
  "entry.strategy",
  "entry.selectedConfidence",
  "entry.selectedEmotion",
  "entry.selectedPhysical",
  "entry.selectedMarket",
  "entry.selectedTimeframe",
  "entry.selectedMistake",
  "entry.tags",
];
export default columnIds;
