import React, { useEffect, useState, useRef } from "react";
import probabilityofLosingStreak from "../../functions/calculations_for_metrics/probabilityofLosingStreak";
import getEntryExitLots from "./../../functions/getEntryExitLots";
function usePrevious(data) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
}
export const RandomChanceTool = (data, state, tier, active) => {
  let disabledVar = "";
  if (tier === "free") {
    disabledVar = true;
  } else if (tier === "pro" && active) {
    disabledVar = false;
  } else if (tier === "oldpro" && active) {
    disabledVar = false;
  } else if (tier === "master" && active) {
    disabledVar = false;
  } else if (tier === "ultimate" && active) {
    disabledVar = false;
  } else if (tier === "admin" && active) {
    disabledVar = false;
  } else {
    disabledVar = true;
  }

  const allData = data;
  const firstUpdate = useRef(true);
  const [numTrades, setnumTrades] = useState();
  const [consecutiveLosses, setconsecutiveLosses] = useState();
  const [winRate, setwinRate] = useState("");
  const [streak, setstreak] = useState("");
  const [hasUseMetrics, sethasUseMetrics] = useState();
  const prevCount = usePrevious(winRate);
  const toggleState = state;
  useEffect(() => {
    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [firstUpdate]);
  const equityvaluefunction = () => {
    let totalwins = 0;
    let totaltrades = 0;
    for (var i = 0, j = allData.portfolio.entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(
        allData.portfolio.entries[i].entry.profitLoss * 100
      );
      let x100commissions = Number(
        allData.portfolio.entries[i].entry.commissions * 100
      );
      let x100fees = Number(allData.portfolio.entries[i].entry.fees * 100);
      if (allData.portfolio.settings.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (allData.portfolio.settings.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      let multiExecution = allData.portfolio.entries[i].entry.multiExecution;
      let exitExecution = allData.portfolio.entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        allData.portfolio.entries[i].entry.orderType === "Deposit" ||
        allData.portfolio.entries[i].entry.orderType === "Withdrawal" ||
        allData.portfolio.entries[i].entry.orderType === "Funding Payment" ||
        allData.portfolio.entries[i].entry.orderType === "Commit" ||
        allData.portfolio.entries[i].entry.orderType === "Approval" ||
        allData.portfolio.entries[i].entry.orderType === "Wrap" ||
        allData.portfolio.entries[i].entry.orderType === "Self"
      ) {
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        if (correctedSUM > 0) {
          totalwins += 1;
        } else {
        }
        totaltrades += 1;
      }
    }
    const winrate = 100 * (totalwins / totaltrades);
    const actualwinrate = totaltrades === 0 ? "-" : winrate.toFixed(3);
    return actualwinrate;
  };

  const handleUseMetrics = (e) => {
    sethasUseMetrics(e.target.checked);
    if (!e.target.checked) {
      setwinRate(prevCount);
    } else {
      setwinRate(equityvaluefunction());
    }
  };
  const handleClick = () => {
    const tosetvar =
      isNaN(
        probabilityofLosingStreak(
          Number(numTrades),
          Number(consecutiveLosses),
          Number(1 - winRate / 100)
        ).toFixed(5)
      ) ||
      isNaN(
        probabilityofLosingStreak(
          Number(numTrades),
          Number(consecutiveLosses),
          Number(winRate / 100)
        ).toFixed(5)
      )
        ? ""
        : toggleState === "Drawdown"
        ? probabilityofLosingStreak(
            Number(numTrades),
            Number(consecutiveLosses),
            Number(1 - winRate / 100)
          ).toFixed(5) + "%"
        : probabilityofLosingStreak(
            Number(numTrades),
            Number(consecutiveLosses),
            Number(winRate / 100)
          ).toFixed(5) + "%";
    setstreak(tosetvar);
  };
  return (
    <div id="randomChanceTool">
      {toggleState === "Drawdown" ? (
        <div className="randomprobtool-label tiqred" id="randomprobtool-label">
          Losing Streak Odds Calculator
        </div>
      ) : (
        <div
          className="randomprobtool-label tiqgreen"
          id="randomprobtool-label"
        >
          Winning Streak Odds Calculator
        </div>
      )}

      <div id="inputlabelwrapperDrawdown">
        <div className="main-labels-Drawdown">
          <label className="ANTselectionfields">
            Number of Trades
            <input
              disabled={disabledVar}
              name="fees"
              value={numTrades}
              onChange={(e) => {
                setnumTrades(e.target.value);
              }}
              className="text-input-boxes"
              autoComplete="off"
            />
          </label>
        </div>
        <div className="main-labels-Drawdown">
          <label className="ANTselectionfields">
            {toggleState === "Drawdown"
              ? "Number of Consecutive Losses"
              : "Number of Consecutive Wins"}
            <input
              disabled={disabledVar}
              name="fees"
              value={consecutiveLosses}
              onChange={(e) => {
                setconsecutiveLosses(e.target.value);
              }}
              className="text-input-boxes"
              autoComplete="off"
            />
          </label>
        </div>
        <div className="main-labels-Drawdown">
          <label className="ANTselectionfields">
            Win Rate (%)
            <input
              disabled={disabledVar}
              name="fees"
              value={winRate}
              onChange={(e) => {
                setwinRate(e.target.value);
              }}
              className="text-input-boxes"
              autoComplete="off"
            />
          </label>
        </div>
        <div style={{ margin: "0px 0px 0px 0px", padding: "0px" }}>
          <label className="container checkssspvt">
            <input
              disabled={disabledVar}
              type="checkbox"
              id="boxNoEntrySlip"
              name="hasEntrySlippage"
              checked={hasUseMetrics}
              onClick={(e) => handleUseMetrics(e)}
            />
            <span className="checkmark checkmark-col1-2"></span>
            &emsp;&ensp;Use Current Metrics
          </label>
        </div>
      </div>
      <div className="randomprobtoolcalc-label" id="randomprobtoolcalc-label">
        {toggleState === "Drawdown"
          ? "Probability of the Specified Losing Streak:"
          : "Probability of the Specified Winning Streak:"}
      </div>
      {toggleState === "Drawdown" ? (
        <div id="problosingstreakcalculation">{streak}</div>
      ) : (
        <div id="probwinningstreakcalculation">{streak}</div>
      )}
      <button
        disabled={disabledVar}
        onClick={() => {
          handleClick();
        }}
        id="problosscalculatebutton"
      >
        Calculate
      </button>
      <button
        disabled={disabledVar}
        onClick={() => {
          setstreak("");
          setconsecutiveLosses("");
          setwinRate("");
          setnumTrades("");
          sethasUseMetrics(false);
        }}
        id="problosscalculatecancelbutton"
      >
        Clear
      </button>
    </div>
  );
};
export default RandomChanceTool;
