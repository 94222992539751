import React from 'react';
import PropTypes from 'prop-types';

import PageTemplate from '../../components/PageTemplate/PageTemplate';


const DailyReport = (props) => (
  <PageTemplate
  title={props.tabName}
  page={
    <div> Daily Report </div>
  } />
);

DailyReport.defaultProps = {
tabName: "Daily"
}

DailyReport.propTypes = {
tabName: PropTypes.string,
}

export default DailyReport;

