import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import Select from "react-select";
import { DataContext } from "../../DataContext";
import { setLoaderState } from "../../actions/actionCreators";
import store from "../../store";
import "./importStyles.scss";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import csvToArray from "./csvToArray";
import tradeovate from "./brokers/tradovate";
import mt4 from "./brokers/mt4";
import mt5 from "./brokers/mt5";
import Mt4Instructions from "./instructions/mt4";
import Mt5Instructions from "./instructions/mt5";
import TradovateInstructions from "./instructions/tradovate";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import dropdownStyles from "../../functions/dropdownStyles";
import { getSpecificPortfolio } from "../../functions/getSpecificPortfolio";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import rithmic from "./brokers/rithmic";
import aiParser from "./brokers/aiParser";
import interactivebrokers from "./brokers/interactivebrokers";

//import useForceUpdate from "../../hooks/useForceUpdate";

/* import Tooltip from "./../../components/Tooltip";
 */ var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const ImportFromFileComponent = (props) => {
  const allData = props.allData;
  const portfolio = allData.portfolio;
  const allportfolios = allData.allData;
  const show100TradesCheck = props.show100TradesCheck;
  const showCheck = props.showCheck;
  const tier = props.tier;
  const active = props.active;
  const entries = portfolio.entries;
  const selectedBroker = props.selectedBroker;
  const showInstructionsin = props.showInstructions;
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();

  const firstUpdate = useRef(true);
  //const [selectedBroker, setselectedBroker] = useState();
  const [fileStringPreview, setfileStringPreview] = useState();
  const [fileStringPreview2, setfileStringPreview2] = useState();

  const [hasFileImported, sethasFileImported] = useState(false);
  const [hasFileImported2, sethasFileImported2] = useState(false);
  const [firstbuttondisable, setfirstbuttondisable] = useState(true);

  const [invalidFile, setinvalidFile] = useState(false);
  const [showInstructions, setshowInstructions] = useState(showInstructionsin);

  const history = useHistory();
  const [hasOverwrite, sethasOverwrite] = useState(false);

  const [canImport, setcanImport] = useState(true);

  const [trades, settrades] = useState([]); // set parsed trades form handleFile to this in each broker handle
  const [temptrades1, settemptrades1] = useState(); // set parsed trades form handleFile to this in each broker handle
  const [temptrades2, settemptrades2] = useState(); // set parsed trades form handleFile to this in each broker handle
  //const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (firstUpdate.current) {
      const smartImport = document.getElementById("smartImportRadio");
      smartImport && (smartImport.checked = true);
      sethasOverwrite("Smart Import");
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [firstUpdate, selectedBroker]);

  const supportedBrokers = [
    // Forex
    { label: "MetaTrader 4", value: "metatrader4" },
    { label: "MetaTrader 5", value: "metatrader5" },
    // Stocks/Options/Futures
    { label: "Tradovate", value: "tradovate" },
    { label: "Thinkorswim (Coming Soon)", value: "thinkorswim" },
    /*     { label: "Fidelity", value: "fidelity" },
    { label: "Interactive Brokers", value: "interactivebrokers" },
    { label: "TradeStation", value: "tradestation" },
    { label: "Lightspeed", value: "lightspeed" },
    { label: "TradeZero", value: "tradezero" },
    // Crypto
    { label: "E-Trade", value: "etrade" },
    { label: "Coinbase", value: "coinbase" },
    { label: "Kraken", value: "kraken" },
    // Mobile
    { label: "Robinhood", value: "robinhood" },
    { label: "WeBull", value: "webull" }, */
  ];

  const getFile = () => {
    if (selectedBroker === null || !selectedBroker) {
      $(".importCardErrorMsg").css({ display: "flex" });
      $(".importCardErrorMsg").html("Choose a broker to continue");
    } else {
      document.getElementById("file-input").click();
      $(".importCardErrorMsg").css({ display: "none" });
      $(".importCardErrorMsg").html("");
    }
  };
  const getFile2 = () => {
    if (selectedBroker === null || !selectedBroker) {
    } else {
      document.getElementById("file-input2").click();
    }
  };
  const validate = useCallback(() => {
    const chosenPortfolios = allportfolios.chosenPortfolios;
    if (chosenPortfolios.length !== 2) {
      $("#messageBox").fadeIn(300);
      $("#overlay").fadeIn(300);
      if (allportfolios.createdPortfolios.length === 1) {
        document.getElementById("messageBoxMessage").innerHTML =
          "Please create a portfolio to import trades.";
      } else {
        const returnThis = `When importing trades, only one portfolio can be selected.`;
        document.getElementById("messageBoxMessage").innerHTML = returnThis;
      }
    } else {
      if (selectedBroker === null || !selectedBroker) {
        setfirstbuttondisable(true);
      } else {
        setfirstbuttondisable(false);
      }
      if (
        selectedBroker === null ||
        !selectedBroker ||
        !hasFileImported ||
        (selectedBroker.value === "tradovate" && !hasFileImported2) ||
        (entries.length && !hasOverwrite) ||
        invalidFile === true
      ) {
        setcanImport(true);
      } else {
        setcanImport(false);
      }
    }
  }, [
    selectedBroker,
    hasFileImported,
    hasFileImported2,
    hasOverwrite,
    entries.length,
    allportfolios.chosenPortfolios,
    allportfolios.createdPortfolios.length,
    invalidFile,
  ]);
  useEffect(() => {
    validate();
  }, [selectedBroker, validate]);
  useEffect(() => {
    validate();
  }, [hasFileImported, validate]);
  useEffect(() => {
    validate();
  }, [hasFileImported2, validate]);

  const submitImport = async () => {
    localStorage.setItem("imported", "imported");
    store.dispatch(setLoaderState({ loading: true }));
    props.setShowImport(false);
    // ------ get chosen portfolio and create necessary temporary arrays -----
    let tempAll = allData.allData.chosenPortfolios;
    let tempAllOther = allData.allData.createdPortfolios;
    let temparr = allData.allData.createdPortfolios;
    const portName = getSpecificPortfolio(allData.allData).name;

    temparr = temparr.filter((x) => x.name !== portName);
    tempAllOther = tempAllOther.filter((x) => x.name === portName);

    let mutableTrades = tempAllOther[0].entries; // array of user trades that can be edited
    let loadDemoDataSetting = tempAllOther[0].settings.demoDataLoaded;
    if (hasOverwrite === "Keep Entries") {
      trades.forEach((trade) => {
        mutableTrades = mutableTrades.concat(trade);
      });
    } else if (hasOverwrite === "Overwrite Entries") {
      mutableTrades = [];
      loadDemoDataSetting = false;
      trades.forEach((trade) => {
        mutableTrades = mutableTrades.concat(trade);
      });
    } else if (hasOverwrite === "Smart Import") {
      trades.forEach((trade) => {
        let mutableTradesNu = mutableTrades.slice(); // array of user trades that can be edited
        mutableTradesNu = mutableTradesNu.filter(
          // only checks order number for now
          (trade2) =>
            Number(trade2.entry.orderNumber) === Number(trade.entry.orderNumber)
        );
        if (mutableTradesNu && mutableTradesNu.length === 1) {
        } else {
          mutableTrades = mutableTrades.concat(trade);
        }
      });
    } else {
      trades.forEach((trade) => {
        mutableTrades = mutableTrades.concat(trade);
      });
    }

    // Handling tiering
    // Free tier users only get 50 trades
    if (tier === "free") {
      mutableTrades = mutableTrades.slice(-50);
    } else if (tier === "pro" && active) {
    } else if (tier === "oldpro" && active) {
    } else if (tier === "master" && active) {
    } else if (tier === "ultimate" && active) {
    } else if (tier === "admin" && active) {
    } else {
      mutableTrades = mutableTrades.slice(-50);
    }

    const portfolio = {
      name: tempAllOther[0].name,
      assetClasses: tempAllOther[0].assetClasses,
      settings: tempAllOther[0].settings,
      entries: mutableTrades,
    };
    temparr = temparr.concat(portfolio);
    const propsToChange = {
      demoDataLoaded: loadDemoDataSetting,
      verified: 0,
    };
    const globalpropsToChange = {
      integrationsInfo: {
        ...allData.allData.globalSettings.integrationsInfo,
        [selectedBroker.value]: {
          lastUp: new Date(),
          port: portfolio.name,
          num: trades.length,
        },
      },
    };

    const finalPush = createDatafromSettingsProps(
      tempAll,
      temparr,
      propsToChange,
      globalpropsToChange,
      allData.portfolio.settings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    // SET S3 DATA
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      user.sub,
      history
    );

    await putUserData(S3Data, history);
    allGlobalData.updateAllData(finalPush);
    history.push("/Dashboard");
  };

  const handleDeleteFile = (e) => {
    sethasFileImported(false);
    setinvalidFile(false);
    setfileStringPreview("");
    document.getElementById("file-input").value = "";
  };
  const handleDeleteFile2 = (e) => {
    sethasFileImported2(false);
    setfileStringPreview2("");
    setinvalidFile(false);
    document.getElementById("file-input2").value = "";
  };
  const handleFile = (e) => {
    var r = new FileReader();
    var el = document.createElement("html");
    const file = !e.target.files[0] ? null : e.target.files[0];
    file && r.readAsText(file);
    file && setfileStringPreview(String(file.name));

    // --------- switch-case to handle the correct broker file ---------
    if (!selectedBroker || !file) {
    } else {
      switch (selectedBroker.value) {
        case "coinbase":
          break;
        // -------------- AI --------------
        case "AI File":
          r.onload = async (readerEvent) => {
            await aiParser(
              readerEvent,
              user.sub,
              portfolio,
              settrades,
              el,
              setinvalidFile
            );
          };
          break;
        // -------------- INTERACTIVE BROKERS --------------
        case "interactivebrokers":
          r.onload = (readerEvent) => {
            interactivebrokers(
              readerEvent,
              user.sub,
              portfolio,
              settrades,
              setinvalidFile
            );
          };
          break;
        // -------------- METATRADER 4 --------------
        case "metatrader4":
          r.onload = (readerEvent) => {
            mt4(
              readerEvent,
              user.sub,
              portfolio,
              settrades,
              el,
              setinvalidFile
            );
          };
          break;
        // -------------- METATRADER 5 --------------
        case "metatrader5":
          r.onload = (readerEvent) => {
            mt5(
              readerEvent,
              user.sub,
              portfolio,
              settrades,
              el,
              setinvalidFile
            );
          };
          break;
        // -------------- RITHMIC --------------
        case "rithmic":
          r.onload = (readerEvent) => {
            rithmic(
              readerEvent,
              user.sub,
              portfolio,
              settrades,
              setinvalidFile
            );
          };
          break;
        // -------------- TRADOVATE --------------
        case "tradovate":
          r.onload = (readerEvent) => {
            tradeovate(
              readerEvent,
              temptrades2,
              settemptrades1,
              csvToArray,
              user.sub,
              portfolio,
              settrades,
              "Orders",
              setinvalidFile
            );
          };
          break;

        default:
        // code block
      }
      sethasFileImported(true);
    }
  };

  const handleFile2 = (e) => {
    var r = new FileReader();
    const file = !e.target.files[0] ? null : e.target.files[0];
    file && r.readAsText(file);
    file && setfileStringPreview2(String(file.name));
    // --------- switch-case to handle the correct broker file ---------
    if (!selectedBroker || !file) {
    } else {
      switch (selectedBroker.value) {
        case "tradovate":
          r.onload = (readerEvent) => {
            tradeovate(
              readerEvent,
              temptrades1,
              settemptrades2,
              csvToArray,
              user.sub,
              portfolio,
              settrades,
              "Cash History"
            );
          };
          break;
        default:
        // code block
      }
      sethasFileImported2(true);
    }
  };
  let modalheader = "Import";

  const showInstructionClass = !showInstructions
    ? "importCard"
    : "importCardShow";
  const showInstructionText = !showInstructions
    ? "Show Instructions"
    : "Hide Instructions";
  const showInstructionClick = useCallback(() => {
    if (!showInstructions) {
      setshowInstructions(true);
      props.setShowInstructions(true);
    } else {
      setshowInstructions(false);
      props.setShowInstructions(false);
    }
  }, [showInstructions, props]);
  const instructionElem = useCallback(() => {
    let element = "";
    if (selectedBroker === null || !selectedBroker) {
      element = (
        <div className="showinstructionsprompt">
          Select a Broker/Platform to show instructions.
        </div>
      );
    } else {
      if (selectedBroker.value === "metatrader4") {
        element = <Mt4Instructions entries={entries} />;
      } else if (selectedBroker.value === "metatrader5") {
        element = <Mt5Instructions entries={entries} />;
      } else if (selectedBroker.value === "tradovate") {
        element = <TradovateInstructions entries={entries} />;
      }
    }
    return element;
  }, [selectedBroker, entries]);
  const firstButtonText = () => {
    const brokerVal = selectedBroker && selectedBroker.value;
    let result = "";
    switch (brokerVal) {
      case "tradovate":
        result = "Choose Orders .csv File";
        break;
      case "metatrader4":
        result = "Choose .htm File";
        break;
      case "metatrader5":
        result = "Choose .html File";
        break;
      case "AI File":
        result = "Choose .csv File";
        break;
      case "rithmic":
        result = "Choose .csv File";
        break;
      case "interactivebrokers":
        result = "Choose .csv File";
        break;
      default:
        result = <i className="defaultButtonText">Choose Broker/Platform</i>;
        break;
    }
    return result;
  };

  const instructionLink = () => {
    const brokerVal = selectedBroker && selectedBroker.value;
    let result = "";
    switch (brokerVal) {
      case "tradovate":
        result = "https://tradefuse.app/import-from-tradovate/";
        break;
      case "metatrader4":
        result = "https://tradefuse.app/import-from-metatrader4/";
        break;
      case "metatrader5":
        result = "https://tradefuse.app/import-from-metatrader5/";
        break;
      default:
        result = "";
        break;
    }
    return result;
  };
  return (
    <div className={showInstructionClass}>
      <span className="autoimportmodalheading">
        {selectedBroker?.label + " Import"}
      </span>
      <div className="importCardLeft">
        <span className="mandatory importCardErrorMsg">{""}</span>
        {!entries.length ? (
          <div></div>
        ) : (
          <div className="importtypewrapper">
            <div
              style={{
                textAlign: "left",
                color: "#deebf7",
                margin: "20px 0px -20px 16px",
              }}
            >
              There are entries already logged in this portfolio. Choose your
              import method.
            </div>
            <div id="overwriteselectwrapper">
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#fbd665",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Recommended choice. Our smart algorithm looks for entries
                    you have imported before and won't import them again. The
                    rest are imported normally.
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container">
                  <label className="container checksygf3">
                    <input
                      type="radio"
                      name="overwrite"
                      id="smartImportRadio"
                      value={"Smart Import"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle radiocircleSM"></span>
                  </label>
                  <div className="radio-label-overwrite moveright">
                    <label>{"Smart Import"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#deebf7",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    No entries are deleted from this portfolio; every entry is
                    imported from the selected file. Could create duplicates.
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container">
                  <label className="container checksygf">
                    <input
                      type="radio"
                      name="overwrite"
                      id="orderTypeBuy"
                      value={"Keep Entries"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-nongold moveright">
                    <label>{"Keep"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
              <PopoverStickOnHover
                component={
                  <div
                    style={{
                      color: "#deebf7",
                      padding: "2px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Deletes the entries in this portfolio before importing.
                    <div style={{ color: "#fa798b" }}>
                      Proceed with caution.
                    </div>
                  </div>
                }
                placement="bottom"
                delay={300}
                xAdjust={27}
                yAdjust={0}
                keepOpen={false}
              >
                <div className="overwrite-container2 addedwidthoverwrite">
                  <label className="container checksygf2">
                    <input
                      type="radio"
                      name="overwrite"
                      id="orderTypeBuy"
                      value={"Overwrite Entries"}
                      disabled={show100TradesCheck}
                      onClick={(e) => sethasOverwrite(e.target.value)}
                    />
                    <span className="radiocircle"></span>
                  </label>
                  <div className="radio-label-nongold moveright">
                    <label>&nbsp;{"Overwrite"}</label>
                  </div>
                </div>
              </PopoverStickOnHover>
            </div>
          </div>
        )}

        {/*         <label className="IMPORTselectionfields">
          Broker/Platform:
          <Select
            isClearable
            styles={dropdownStyles}
            value={selectedBroker}
            isDisabled={show100TradesCheck}
            onChange={(e) => {
              setselectedBroker(e);
              if (!e) {
                sethasFileImported(false);
                sethasFileImported2(false);
                document.getElementById("file-input").value = "";
                document.getElementById("file-input2").value = "";
                setfileStringPreview("");
                setfileStringPreview2("");
              } else {
                $(".importCardErrorMsg").css({ display: "none" });
                $(".importCardErrorMsg").html("");
              }
            }}
            theme={theme}
            options={supportedBrokers}
            placeholder={
              <em
                style={{
                  fontStyle: "italic",
                  color: "#808080",
                  opacity: 0.7,
                }}
              >
                {"Select..."}
              </em>
            }
          />
        </label> */}
        {invalidFile && (
          <div
            id={!entries.length ? "importwrongpreview3" : "importwrongpreview"}
          >
            {`Invalid file format for ${selectedBroker.label}`}
          </div>
        )}

        <button
          id="importfromcsvchoose"
          disabled={
            show100TradesCheck ? show100TradesCheck : firstbuttondisable
          }
          onClick={() => getFile()}
        >
          {firstButtonText()}
        </button>
        <div id="importpreview">
          {fileStringPreview}{" "}
          {!fileStringPreview ? (
            ""
          ) : (
            <span
              className="deleteFileButton"
              onClick={() => handleDeleteFile()}
            >
              <FontAwesomeIcon icon={faPlus} />
            </span>
          )}
        </div>

        {selectedBroker && selectedBroker.value === "tradovate" && (
          <button
            id="importfromcsvchoose2"
            disabled={
              show100TradesCheck ? show100TradesCheck : firstbuttondisable
            }
            onClick={() => getFile2()}
          >
            Choose Cash History .csv File
          </button>
        )}
        <div id="importpreview">
          {" "}
          {fileStringPreview2}{" "}
          {!fileStringPreview2 ? (
            ""
          ) : (
            <span
              className="deleteFileButton"
              onClick={() => handleDeleteFile2()}
            >
              <FontAwesomeIcon icon={faPlus} />
            </span>
          )}
        </div>
        <button
          disabled={show100TradesCheck ? show100TradesCheck : canImport}
          id="importfromcsvimport"
          onClick={() => submitImport()}
        >
          Import Trades
        </button>
        <input
          onChange={(e) => handleFile(e)}
          id="file-input"
          type="file"
          style={{ display: "none" }}
          disabled={show100TradesCheck}
          accept=".csv,.htm,.html"
        />
        <input
          onChange={(e) => handleFile2(e)}
          id="file-input2"
          type="file"
          style={{ display: "none" }}
          disabled={show100TradesCheck}
          accept=".csv,.htm,.html"
        />
      </div>
      {showInstructions && (
        <div className="showInstructionElem">{instructionElem()}</div>
      )}
      <div className={"showintructionbuttonwrapper"}>
        {`Need help?`}
        <button
          className={"showintructionbutton"}
          onClick={() => window.open(instructionLink(), "_blank")}
        >
          {"See instructions"}
        </button>
      </div>
    </div>
  );
};

export default ImportFromFileComponent;
