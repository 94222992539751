import getSizeInBytes from "../../functions/getSizeInBytes";

import moment from "moment-timezone";
import getCustomer from "./getStripeCustomer";
import getFormattedDateTime from "../../functions/getDateTimeStripeFormat";
import { proddev } from "../../PROD-DEV-TOGGLE";

// To import DAC, uncomment here, and 2 spots below.
const proxyURL =
  "https://api.tradefuse.app/updateStripeCustomer";
var jsonpack = require("jsonpack/main");
function getQueryParam(url, param) {
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get(param);
}
export const updateStripeCustomerWrapper = async (
  customerId,
  dataPre,
  type,
  auth0id,
  history
) => {
  function get_ga_clientid() {
    var cookie = {};
    document.cookie.split(";").forEach(function (el) {
      var splitCookie = el.split("=");
      var key = splitCookie[0].trim();
      var value = splitCookie[1];
      cookie[key] = value;
    });
    return cookie["_ga"]?.substring(6);
  }

  const clientId = get_ga_clientid();
  const customer =
    customerId && customerId !== "" && (await getCustomer(customerId, history)); // stripe customer obj
  const customermetadata = customer.metadata;
  const logins =
    customermetadata && customermetadata.Logins ? customermetadata.Logins : 0;
  const sessions =
    customermetadata && customermetadata.Sessions
      ? customermetadata.Sessions
      : 0;
  const channel = customermetadata && customermetadata.Channel;
  const ipv4 = customermetadata && customermetadata.IPv4Address;
  const useragent = customermetadata && customermetadata.UserAgent;
  const campaign = customermetadata && customermetadata.Campaign;

  let totalTrades = 0;
  var packed = jsonpack.pack(dataPre);

  const totalSizeKB = (getSizeInBytes(packed) / 1000).toFixed(2);
  const createdPortfolios = dataPre === null ? [] : dataPre.createdPortfolios;
  for (let i = 0, j = createdPortfolios.length; i < j; i++) {
    let entries = createdPortfolios[i].entries;
    let entriedlength = entries.length;
    totalTrades = totalTrades + Number(entriedlength);
  }

  const sharedTradesLength = !dataPre
    ? 0
    : !dataPre.sharedTrades
    ? 0
    : !dataPre.sharedTrades.length
    ? 0
    : dataPre.sharedTrades.length;

  const dateTimeString = String(getFormattedDateTime());
  const currentUrl = new URL(window.location.href);
  // Extract the utm_source parameter
  const utmSource = getQueryParam(currentUrl, "utm_source");
  const utmCampaign = getQueryParam(currentUrl, "utm_campaign");
  async function getInfo() {
    try {
      // get user agent
      const userAgent = navigator.userAgent;

      // get IP address
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      const ipAddress = data.ip;
      return { ipAddress: ipAddress, userAgent: userAgent };
      // use the values of userAgent and ipAddress here
      // ...
    } catch (error) {
      history.push({
        pathname: "/Error-Found",
        state: {
          error: error.message,
        },
      });
      window.location.reload();
    }
  }
  const userAgentIpAddy = await getInfo();
  const data2 = {
    name: customer.name,
    email: customer.email,
    metadata: {
      Logins: type === "login" ? Number(logins) + 1 : Number(logins), // only add 1 if login type
      "Last Login": dateTimeString,
      "Last Session": dateTimeString,
      Trades: totalTrades,
      "Shared Trades": sharedTradesLength,
      Sessions: type === "session" ? Number(sessions) + 1 : Number(sessions),
      "Storage Used": `${totalSizeKB} KB`,
      Channel: channel ? channel : utmSource || "",
      IPv4Address: ipv4 ? ipv4 : userAgentIpAddy.ipAddress || "",
      UserAgent: useragent ? useragent : userAgentIpAddy.userAgent || "",
      Campaign: campaign ? campaign : utmCampaign || "",
      auth0id: auth0id,
      proddev: proddev,
      clientId: clientId,
    },
  };
  const runUpdateStripeCustomer = async () => {
    let dataIn = {
      action: "updateStripeCustomer",
      data: {
        customerId: customerId,
        name: data2.name,
        email: data2.email,
        Logins: data2.metadata["Logins"],
        "Shared Trades": data2.metadata["Shared Trades"],
        Sessions: data2.metadata["Sessions"],
        "Storage Used": data2.metadata["Storage Used"],
        Channel: data2.metadata["Channel"],
        IPv4Address: data2.metadata["IPv4Address"],
        UserAgent: data2.metadata["UserAgent"],
        Campaign: data2.metadata["Campaign"],
        auth0id: data2.metadata["auth0id"],
        proddev: data2.metadata["proddev"],
        clientId: data2.metadata["clientId"],
      },
    };
    if (type === "session") {
      dataIn.data["Last Session"] = data2.metadata["Last Session"];
    } else if (type === "login") {
      dataIn.data["Last Login"] = data2.metadata["Last Login"];
    } else if (type === "trades") {
      dataIn.data["Trades"] = data2.metadata["Trades"];
    }
    const response = await fetch(proxyURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(dataIn),
    }).catch((err) => {
      history.push({
        pathname: "/Error-Found",
        state: {
          error: err.message,
        },
      });
      window.location.reload();
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };

  customerId && customerId !== "" && (await runUpdateStripeCustomer());
};
export default updateStripeCustomerWrapper;