import React from "react";
import PropTypes from "prop-types";
import PageTemplate from '../../components/PageTemplate/PageTemplate';

const YearlyReport = (props) => (
  <PageTemplate
    title={props.tabName}
    page={
      <div>Yearly Report</div>
    }
  />
);

YearlyReport.defaultProps = {
  tabName: "Yearly",
};

YearlyReport.propTypes = {
  tabName: PropTypes.string,
};

export default YearlyReport;
