import React /* , { useState } */ from "react";
import { HorizontalBar } from "react-chartjs-2";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import { handleNANGOOD } from "../../functions/handleUndefined";

var he = require("he");

const SimulatorDistributionGraph = (props) => {
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const dateToggle = props.sorted;
  const symbol = props.defaultSymbol;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const simulationResultsPre = props.simulationResults;
  const simulationResults = simulationResultsPre.map((result) => {
    return result.data;
  });
  const simLength = simulationResults?.length;
  const numChunks = 25;

  const equityvaluefunction = (n, simulationResults) => {
    let maxEndingValue = -Infinity;
    let minEndingValue = Infinity;
    for (let i = 0; i < simulationResults.length; i++) {
      const endingValue = simulationResults[i][simulationResults[i].length - 1];
      if (endingValue > maxEndingValue) {
        maxEndingValue = endingValue;
      }
      if (endingValue < minEndingValue) {
        minEndingValue = endingValue;
      }
    }
    const chunkSize = (maxEndingValue - minEndingValue) / n;
    const yaxisarray = new Array(n).fill(0);
    let xaxisarray = [];

    for (let i = 0; i < simulationResults.length; i++) {
      const endingValue = simulationResults[i][simulationResults[i].length - 1];
      let chunkIndex = Math.floor((endingValue - minEndingValue) / chunkSize);
      if (chunkIndex === n) {
        chunkIndex = n - 1;
      }
      yaxisarray[chunkIndex] += 1;
    }
    for (let i = 0; i < n; i++) {
      const chunkStart =
        i === 0
          ? handleNANGOOD(
              Math.floor(minEndingValue + i * chunkSize).toFixed(0),
              ""
            )
          : handleNANGOOD((minEndingValue + i * chunkSize).toFixed(0), "");
      const chunkEnd =
        i === n - 1
          ? handleNANGOOD(
              Math.ceil(minEndingValue + (i + 1) * chunkSize).toFixed(0),
              ""
            )
          : handleNANGOOD(
              (minEndingValue + (i + 1) * chunkSize).toFixed(0),
              ""
            );
      const chunkLabel =
        chunkStart === "" && chunkEnd === ""
          ? ""
          : supportedCryptosCheck
          ? `${chunkStart + " " + symbol} - ${chunkEnd + " " + symbol}`
          : `${he.decode(symbol) + chunkStart} - ${
              he.decode(symbol) + chunkEnd
            }`;
      xaxisarray.push(chunkLabel);
    }

    return { xaxisarray, yaxisarray };
  };
  const colours = equityvaluefunction(
    numChunks,
    simulationResults
  ).yaxisarray.map((value) => "#ffce3a");
  const xData = equityvaluefunction(
    numChunks,
    simulationResults
  ).xaxisarray.reverse();
  const yData = equityvaluefunction(
    numChunks,
    simulationResults
  ).yaxisarray.reverse();

  const data = {
    labels: xData,
    datasets: [
      /*       {
        label: "My First dataset",
        //new option, type will default to bar as that what is used to create the scale
        type: "line",
        strokeColor: "#98b5eb",
        backgroundColor: "#98b5eb",
        borderColor: "#98b5eb",
        data: equityvaluefunction(numChunks, simulationResults).yaxisarray.map(
          (value) => value
        ),
      }, */
      {
        label: "P/L",
        fill: false,
        lineTension: 0.1,
        backgroundColor: colours,
        borderColor: colours,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colours,
        pointBackgroundColor: colours,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colours,
        pointHoverBorderColor: colours,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: yData,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      intersect: false,
      enabled: true,
      callbacks: {
        title: () => {
          return null;
        },
        label: (tooltipItems) => {
          return [
            `Range: ${xData[tooltipItems.index]}`,
            `Occurences: ${parseFloat(tooltipItems.xLabel.toFixed(0))}`,
            `Odds of occuring: ${
              simLength === 0 || !simLength
                ? "0%"
                : parseFloat(
                    (yData[tooltipItems.index] / simLength) * 100
                  ).toFixed(3) + "%"
            }`,
          ];
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor: "#deebf7",
            beginAtZero: true,
            callback: function (value) {
              return value;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Occurences",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Ending Balance Range",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  const sortedindi = "";

  return (
    <div className="distributiongraph noselect">
      <div className="dashboard-graphs-label">{`Distribution`}</div>
      <div className="cummulativereturn-label3">{sortedindi}</div>

      {/*       <div className="cummulativereturn-toggleindi">
        {!dateToggle ? "Sorted By First Entry" : "Sorted By Last Exit"}
      </div> */}

      <div className="cumulativereturngraphwrapper">
        <HorizontalBar
          data={data}
          options={options}
          className="cumulativereturngraphactual"
        />
      </div>
    </div>
  );
};

export default SimulatorDistributionGraph;
