import React, { useState } from "react";

const SearchBar = ({ onSearch, placeHolder }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (onSearch) {
      onSearch(event.target.value);
    }
  };

  return (
    <input
      type="text"
      className="text-input-boxes-searchbar"
      placeholder={placeHolder}
      value={searchTerm}
      onChange={handleSearch}
    />
  );
};

export default SearchBar;
