import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import $ from "jquery";
import { DataContext } from "../../DataContext";
import useForceUpdate from "../../hooks/useForceUpdate";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import { useHistory } from "react-router-dom";

export const ToggleDDRU = (props) => {
  const allData = props.data;
  const settings = allData.portfolio.settings;
  const tier = props.tier;
  const active = props.active;
  // Tiering
  let toggledisabled = true;
  if (tier === "free") {
  } else if (tier === "pro" && active) {
    toggledisabled = false;
  } else if (tier === "oldpro" && active) {
    toggledisabled = false;
  } else if (tier === "master" && active) {
    toggledisabled = false;
  } else if (tier === "ultimate" && active) {
    toggledisabled = false;
  } else if (tier === "admin" && active) {
    toggledisabled = false;
  } else {
  }
  /// LOCAL STORAGE VARIABLES
  var localDrawdownSettings = JSON.parse(
    localStorage.getItem("streakCalcToggle")
  );
  var isDBRunning = localStorage.getItem("dbSetRunning");

  // set intial drawdown to local storage is local storage does not equal null
  const displayTypeINIT =
    localDrawdownSettings !== null
      ? localDrawdownSettings
      : settings.streakCalcToggle === "Drawdown"
      ? false
      : true;
  const [calcState, setcalcState] = useState(displayTypeINIT);
  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const { user /* isAuthenticated */ } = useAuth0();

  const [callcounter, setcallcounter] = useState(0);
  const allGlobalData = useContext(DataContext);
  const history = useHistory();

  const forceUpdate = useForceUpdate();
  const firstUpdate = useRef(true);
  const handleChange = useCallback(
    async (e) => {
      const toggleState = !e ? "Drawdown" : "Run-Up";
      const currentTimez = Date.now();
      setfinalStateTimeChanged(currentTimez);
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });
      setcallcounter(1);
      localStorage.setItem("streakCalcToggle", JSON.stringify(e));
      props.setcalcstate(toggleState);
      setcalcState(e);
      forceUpdate();
    },
    [props, forceUpdate]
  );
  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localDrawdownSettings2 = JSON.parse(
      localStorage.getItem("streakCalcToggle")
    );
    const checkvar = settings.streakCalcToggle === "Drawdown" ? false : true;
    let propsToChangei = {
      streakCalcToggle:
        localDrawdownSettings2 !== null
          ? localDrawdownSettings2 === false
            ? "Drawdown"
            : "Runup"
          : checkvar,
    };
    let globalpropsToChange = {};
    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allData.allData.globalSettings,
      allData.allData.version
    );

    // SET S3 DATA
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      allGlobalData.updateAllData(finalPush);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, settings, allGlobalData, user]);
  useEffect(() => {
    // checks every 200ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleCallSetJSON();
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    allData,
    localDrawdownSettings,
    settings,
    firstUpdate,
    calcState,
    callcounter,
    finalStateTimeChanged,
    handleCallSetJSON,
    isDBRunning,
  ]);
  const divtoreturn = (
    <div className="streak-metrics-toggle">
      <div className="select-toggler-drawdown" id="select-toggler-drawdown">
        {"Wins"}
        <label className="switch toggleadjust">
          <input
            disabled={toggledisabled}
            id="perftogglebutton"
            type="checkbox"
            checked={!calcState}
            onChange={(e) => handleChange(!e.target.checked)}
          />
          <span className="sliderperf roundperf"></span>
        </label>
        {"Losses"}
      </div>
    </div>
  );
  return divtoreturn;
};
export default ToggleDDRU;
