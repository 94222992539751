import React from "react";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import getEntryExitLots from "./../../functions/getEntryExitLots";

const TIQIndexGraph = (props) => {
  const entries = props.userData;
  const tier = props.tier;
  const active = props.active;
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = entries.filter((_, index) => index === i);
    return entry;
  };
  const equityvaluefunction = () => {
    let metricvalue = [];
    let numWins = 0;
    let numLosses = 0;
    let numTrades = 0;
    let totalProfits = 0;
    let totalLosses = 0;
    for (var i = 0, j = entries.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(entries[i].entry.profitLoss * 100);
      let x100commissions = Number(entries[i].entry.commissions * 100);
      let x100fees = Number(entries[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      let multiExecution = entries[i].entry.multiExecution;
      let exitExecution = entries[i].entry.exitExecution;

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        entries[i].entry.orderType === "Deposit" ||
        entries[i].entry.orderType === "Withdrawal" ||
        entries[i].entry.orderType === "Funding Payment" ||
        entries[i].entry.orderType === "Commit" ||
        entries[i].entry.orderType === "Approval" ||
        entries[i].entry.orderType === "Wrap" ||
        entries[i].entry.orderType === "Self"
      ) {
        metricvalue.push(metricvalue[i - 1]);
        continue;
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        if (correctedSUM < 0) {
          totalLosses += correctedSUM;
          numLosses += 1;
        } else if (correctedSUM > 0) {
          totalProfits += correctedSUM;
          numWins += 1;
        }
      }

      numTrades += 1;
      let averagePLPerWindollar = totalProfits / numWins;
      let averagePLPerLossdollar = totalLosses / numLosses;
      let TradersIQIndexNew =
        (numWins / numTrades) *
        (1 + averagePLPerWindollar / Math.abs(averagePLPerLossdollar));
      if (isNaN(TradersIQIndexNew)) {
        metricvalue.push("-");
      } else {
        metricvalue.push(TradersIQIndexNew.toFixed(3));
      }
    }
    return metricvalue;
  };
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = entries.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };
  const lineColor = "#ffeb94";
  //const lineColor = "#664bff" //purple

  // Tiering
  let yvalues = "";
  if (tier === "free") {
    yvalues = [];
  } else if (tier === "pro" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "oldpro" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "master" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "ultimate" && active) {
    yvalues = equityvaluefunction();
  } else if (tier === "admin" && active) {
    yvalues = equityvaluefunction();
  } else {
    yvalues = [];
  }
  const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: yvalues,
      },
    ],
  };
  const options = {
    tooltips: {
      mode: "index",
      intersect: false,
      enabled: true,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: () => {
          return "TradeFuse Index: ";
        },
        label: (tooltipItems) => {
          return parseFloat(tooltipItems.yLabel).toFixed(3);
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length) {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#deebf7",
            callback: function (value) {
              return value.toFixed(3);
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
          },
          scaleLabel: {
            display: true,
            labelString: "Entry Number",
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };
  return (
    <div className="TIQindexgraph">
      {/*       <div className="TIQindexgraph-label">
        TradeFuse Index
      </div> */}
      <div className="TIQindexgraphwrapper">
        <Line
          data={data}
          options={options}
          className="TIQindexgraphactual"
          onElementsClick={(elems) => {
            if (!elems.length) {
            } else {
              toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
            }
            // if required to build the URL, you can
            // get datasetIndex and value index from an `elem`:
            //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
            // and then redirect to the target page:
            //window.location = "https://example.com";
          }}
        />
      </div>
    </div>
  );
};

export default TIQIndexGraph;
