import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import DepositWithdrawalData from "./DepositWithdrawalData";
import { DataContext } from "../../DataContext";
import processDataandSettings from "./../../utils/processDataandSettings";
import useForceUpdate from "../../hooks/useForceUpdate";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const DepositWithdrawal = (props) => {
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const active = dataInstance.customer.active;
  const tier = dataInstance.customer.tier;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const forceUpdate = useForceUpdate();

  let processedData = {};
  let createdPortfolios = "";
  let showINIT = true;
  let showINIT2 = true;
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Deposits/Withdrawals - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    createdPortfolios = allData.createdPortfolios;
    //store.dispatch(setLoaderState({ loading: false }));
  }

  // Tiering Logic
  const portfolio = processedData.portfolio;
  const numEntries = portfolio && portfolio.entries.length;
  const maxEntries = 50;
  if (tier === "free") {
    if (createdPortfolios.length === 1) {
      showINIT = false;
    } else {
      if (numEntries >= maxEntries) {
        showINIT = true;
      } else {
        showINIT = false;
      }
      showINIT2 = true;
    }
  } else if (tier === "pro" && active) {
    showINIT = false;
    showINIT2 = false;
  } else if (tier === "oldpro" && active) {
    showINIT = false;
    showINIT2 = false;
  } else if (tier === "master" && active) {
    showINIT = false;
    showINIT2 = false;
  } else if (tier === "ultimate" && active) {
    showINIT = false;
    showINIT2 = false;
  } else if (tier === "admin" && active) {
    showINIT = false;
    showINIT2 = false;
  } else {
    if (createdPortfolios.length === 1) {
      showINIT = false;
    } else {
      if (numEntries >= maxEntries) {
        showINIT = true;
      } else {
        showINIT = false;
      }
      showINIT2 = true;
    }
  }

  const showUpgradeModal = useRef({
    show: showINIT,
    featureSelect: "Unlimited entries",
    blur: showINIT,
  });

  // Logic for when to show the upgrade modal
  useEffect(() => {
    if (tier === "free") {
      if (createdPortfolios.length === 1) {
        showUpgradeModal.current = {
          ...showUpgradeModal.current,
          show: false,
        };
      } else {
        if (numEntries >= maxEntries) {
          showUpgradeModal.current = {
            ...showUpgradeModal.current,
            show: true,
          };
        } else {
          showUpgradeModal.current = {
            ...showUpgradeModal.current,
            show: false,
          };
        }
      }
    } else if (tier === "pro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "oldpro" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "master" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "ultimate" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else if (tier === "admin" && active) {
      showUpgradeModal.current = {
        ...showUpgradeModal.current,
        show: false,
      };
    } else {
      if (createdPortfolios.length === 1) {
        showUpgradeModal.current = {
          ...showUpgradeModal.current,
          show: false,
        };
      } else {
        if (numEntries >= maxEntries) {
          showUpgradeModal.current = {
            ...showUpgradeModal.current,
            show: true,
          };
        } else {
          showUpgradeModal.current = {
            ...showUpgradeModal.current,
            show: false,
          };
        }
      }
    }
  }, [tier, active, createdPortfolios, numEntries]);

  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          title={props.tabName}
          upgradeShow={showUpgradeModal.current}
          setshowUpgradeModal={(e) => {
            showUpgradeModal.current = {
              ...showUpgradeModal.current,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            };
            forceUpdate();
          }}
          page={
            <DepositWithdrawalData
              userData={processedData}
              tier={tier}
              active={active}
              show100TradesCheck={showINIT}
              showCheck={showINIT2}
              setshowUpgradeModal={(e) => {
                showUpgradeModal.current = {
                  ...showUpgradeModal.current,
                  show: e.show,
                  featureSelect: e.featureSelect,
                  blur: e.blur,
                  tierText: e.tierText,
                };
                forceUpdate();
              }}
            />
          }
          userData={processedData}
        />{" "}
      </div>
    );
  }
};

DepositWithdrawal.defaultProps = {
  tabName: "Deposits & Withdrawals",
  pageName: "Deposits/Withdrawals",
};

DepositWithdrawal.propTypes = {
  tabName: PropTypes.string,
};

export default DepositWithdrawal;
