import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./HistoryTableStyles.scss";
import EntryTable from "./EntryTable";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import PageTemplateBlank from "../../components/PageTemplate/PageTemplateBlank";
import DeleteTradesComponent from "./deleteTradesComponent";
import TagTradesComponent from "./tagTradesComponent";
import ChooseColumnsComponent from "./chooseColumns";
import { DataContext } from "../../DataContext";
import processDataandSettings from "../../utils/processDataandSettings";
import useStateWithPromise from "../../hooks/useStateWithPromise";
import store from "./../../store";
import { setLoaderState } from "./../../actions/actionCreators";

const ViewHistory = (props) => {
  const dataInstance = useContext(DataContext);
  const allData = dataInstance && dataInstance.allData;
  const active = dataInstance && dataInstance.customer.active;
  const tier = dataInstance && dataInstance.customer.tier;
  const need2faCheck = dataInstance?.miscData?.need2faOpen;

  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  let processedData = {};
  if (Object.keys(allData).length === 0) {
  } else {
    window.parent.document.title = "Logbook - TradeFuse";
    processedData = processDataandSettings(allData, tier, active);
    //store.dispatch(setLoaderState({ loading: false }));
  }

  if (Object.keys(processedData).length === 0 || need2faCheck) {
    return (
      <PageTemplateBlank
        title={props.tabName}
        userData={processedData}
        showloader={true}
      />
    );
  } else {
    return (
      <div className="appgodwrapper">
        <PageTemplate
          title={props.tabName}
          upgradeShow={showUpgradeModal}
          setshowUpgradeModal={(e) => {
            setshowUpgradeModal({
              ...showUpgradeModal,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            });
          }}
          page={
            <div className="historywrappa">
              <EntryTable
                tier={tier}
                active={active}
                title={props.tabName}
                userPortfolio={processedData.portfolio}
                userCalculations={processedData.calculations}
                userLists={processedData.filterLists}
                allData={processedData.allData}
              />
            </div>
          }
          userData={processedData}
        />
        <DeleteTradesComponent userData={processedData} />
        <TagTradesComponent userData={processedData} />

        <ChooseColumnsComponent
          userPortfolioType={
            processedData.portfolio.assetClasses.length > 1
              ? []
              : processedData.portfolio.assetClasses
          }
          userData={processedData}
        />
      </div>
    );
  }
};

ViewHistory.defaultProps = {
  tabName: "Logbook",
  pageName: "Logbook",
};

ViewHistory.propTypes = {
  tabName: PropTypes.string,
};

export default ViewHistory;
