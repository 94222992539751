import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";

import CumulativeReturnGraph from "./CumulativeReturnGraph";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import "./dashboardstyles.scss";
import CurrentEquity from "./CurrentEquity";
import PnLHistoryGraph from "./PnLHistoryGraph";
import OpenTradeComponent from "./OpenTradeComponent";
import PinnedMetrics from "./pinnedMetricComponent";
import PerformanceMeter from "./PerformanceMeter";
import TickerTape from "./../../components/TickerTape";
import TopPinnedMetrics from "./TopPinnedMetrics";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { DataContext } from "../../DataContext";
import PnLByTradeInfoGraph from "./PnLByTradeInfo";
import {
  faEdit,
  faCheck,
  faAngleLeft,
  faGripLines,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PnLByTimeGraph from "./PnLByTimeGraph";
import PnLByDayofWeekGraph from "./PnLByDayofWeekGraph";
import PnLByMonthGraph from "./PnLByMonthGraph";
import PnLBySessionGraph from "./PnLBySessionGraph";
import Sortable from "sortablejs";
import $ from "jquery";
import useForceUpdate from "../../hooks/useForceUpdate";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import useStateWithPromise from "./../../hooks/useStateWithPromise";
import OpenPositionDonutGraph from "./DonutChart";
import ProTierComponent from "./../../components/tierComponents/pro";
import EditDashboardComponent from "./EditDashboardComponent";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import defaultTickerSymbols from "../../components/TickerTape/defaultTickerSymbols";
import { useHistory } from "react-router-dom";

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
let editelemtog = 0;
const filterOpenTrades = (trade) => {
  const entryExecs = trade.entry.multiExecution;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (exitLots !== entryLots) {
    return true;
  } else {
    return false;
  }
};
const DashboardComponent = (props) => {
  const firstUpdate = useRef(true);
  const { user /* isAuthenticated  */ } = useAuth0();
  const tier = props.tier;
  const active = props.active;
  const allData = props.allData;
  const dasheditMode = props.editMode;
  const portfolio = allData && allData.portfolio;
  const riskFreeRate = props.riskFreeRate;
  const settings = portfolio && portfolio.settings;
  const entries = portfolio && portfolio.entries;
  const history = useHistory();

  let openTrades =
    entries && entries.filter(filterOpenTrades).slice().reverse();
  let recentTradesInit = "";
  if (tier === "free") {
    recentTradesInit = true;
  } else if (tier === "pro" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "oldpro" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "master" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "ultimate" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else if (tier === "admin" && active) {
    recentTradesInit = openTrades.length > 0 ? false : true;
  } else {
    recentTradesInit = true;
  }
  const mainGraphINIT = settings && settings.dashboardElements.maingraphs;
  const plhistINIT = mainGraphINIT.filter(
    (comp) => comp.name === "pnlhistorygraph"
  );
  const pnltimeINIT = mainGraphINIT.filter(
    (comp) => comp.name === "pnlbytimegraph"
  );
  const pnldayofweekINIT = mainGraphINIT.filter(
    (comp) => comp.name === "pnlbydayofweekgraph"
  );
  const pnlmonthINIT = mainGraphINIT.filter(
    (comp) => comp.name === "pnlbymonthgraph"
  );
  const pnlsessionINIT = mainGraphINIT.filter(
    (comp) => comp.name === "pnlbysessiongraph"
  );
  const [pnlhistorysorted, setpnlhistorysorted] = useState(
    !plhistINIT.length ? "" : plhistINIT[0].sorted
  );
  const [pnltimesorted, setpnltimesorted] = useState(
    !pnltimeINIT.length ? "" : pnltimeINIT[0].sorted
  );
  const [pnldayofweeksorted, setpnldayofweeksorted] = useState(
    !pnldayofweekINIT.length ? "" : pnldayofweekINIT[0].sorted
  );
  const [pnlmonthsorted, setpnlmonthsorted] = useState(
    !pnlmonthINIT.length ? "" : pnlmonthINIT[0].sorted
  );
  const [pnlsessionsorted, setpnlsessionsorted] = useState(
    !pnlsessionINIT.length ? "" : pnlsessionINIT[0].sorted
  );
  const [showUpgradeModal, setshowUpgradeModal] = useStateWithPromise({
    show: false,
    featureSelect: "",
    blur: false,
  });
  /*   const [togglerCount, settogglerCount] = useState(0);
   */
  const forceUpdate = useForceUpdate();
  var initwidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  var initheight =
    window.innerHeight > 0 ? window.innerHeight : window.screen.height;

  const [height, setHeight] = useState(initheight);
  const [width, setWidth] = useState(initwidth);
  const [dashboardWidth, setdashboardWidth] = useState(initwidth);
  const [dashpseudoeditMode, setdashpseudoeditMode] = useState(false);
  const [innerportWidth, setinnerportWidth] = useState(0);
  const elementRef = useRef(null);
  const elementrefwidth = elementRef.current?.offsetWidth;
  const [dashoOpenMode, setdashoOpenMode] = useState(recentTradesInit);
  //const [pinnedsortable, setpinnedsortable] = useState();
  const buttonwrapperToggle = useRef(false);

  const allGlobalData = useContext(DataContext);
  //const sharedTrades = sharedTradesData.sharedTrades;
  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);

  /// LOCAL STORAGE VARIABLES
  /*   var localDashboardSettings = JSON.parse(
    localStorage.getItem("dashboardSettings")
  ); */
  var isDBRunning = localStorage.getItem("dbSetRunning");
  // ---------------

  let openTradeShow = false;
  if (tier === "free") {
  } else if (tier === "pro" && active) {
    openTradeShow = true;
  } else if (tier === "oldpro" && active) {
    openTradeShow = true;
  } else if (tier === "master" && active) {
    openTradeShow = true;
  } else if (tier === "ultimate" && active) {
    openTradeShow = true;
  } else if (tier === "admin" && active) {
    openTradeShow = true;
  } else {
  }

  const chosenGraphs = mainGraphINIT.map((graph) => graph.name);
  const saveDashboard = useCallback(async (e) => {
    localStorage.setItem("dashboardSettings", JSON.stringify(e));
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
  }, []);

  // This function gets called after 1.6 seconds of
  // no user provoked state changes
  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localDashboardSettings2 = JSON.parse(
      localStorage.getItem("dashboardSettings")
    );
    const checkvar = settings.dashboardElements;
    let propsToChangei = {
      dashboardElements:
        localDashboardSettings2 !== null ? localDashboardSettings2 : checkvar,
    };

    let globalpropsToChange = {};

    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allData.allData.globalSettings,
      allData.allData.version
    );

    //**4/7/22 remember to add context state setting if necessary */
    allGlobalData.updateAllData(finalPush);
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, allGlobalData, settings, user.sub]);

  useEffect(() => {
    var addedType = localStorage.getItem("imported");
    if (addedType === "imported") {
      showConfirmedActionButton("Trades Imported!");
      localStorage.setItem("imported", "None");
    } else {
    }
    var addedType2 = localStorage.getItem("loadDemoDataAction");
    if (addedType2 === "yes") {
      showConfirmedActionButton("Demo Data Added!");
      localStorage.removeItem("loadDemoDataAction");
    } else if (addedType2 === "no") {
      showConfirmedActionButton("Demo Data Deleted!");
      localStorage.removeItem("loadDemoDataAction");
    }
    var addedType3 = localStorage.getItem("tradeDeleted");
    if (addedType3 === "yes") {
      showConfirmedActionButton("Entry Deleted!");
      localStorage.removeItem("tradeDeleted");
    }
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
    /*     console.log(document.getElementById("filtermenunewwrapper"));
    const editdashboaerdtooltipclass =
      document.getElementById("filtermenunewwrapper").style.height === 0
        ? "accountNumberSuperWrapper"
        : "accountNumberSuperWrapper3"; */
    //$(".dashboardeditbuttonswrapper").appendTo("body");
    // hide dropdowns if clicked outside
    $(document).mouseup(function (e) {
      let accountv = $(".dropdown-list-dashboardeditelem");
      let accountv2 = $(".dropdown-list-dashboardeditelem2");
      let ellipsel = $(".handledashdostuff");

      if (!accountv.is(e.target) && accountv.has(e.target).length === 0) {
        accountv.css({ display: "none" });
        if (!ellipsel.is(e.target) && ellipsel.has(e.target).length === 0) {
          editelemtog = 0;
        } else {
        }
      } else {
      }
      if (!accountv2.is(e.target) && accountv2.has(e.target).length === 0) {
        accountv2.css({ display: "none" });
        if (!ellipsel.is(e.target) && ellipsel.has(e.target).length === 0) {
          editelemtog = 0;
        } else {
        }
      } else {
      }
    });

    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleCallSetJSON();
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
      const dashboard_Wrapper = document.getElementById("dashboardwrapper");
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.attributeName === "style" ||
            mutation.attributeName === "max-width" ||
            mutation.attributeName === "min-width"
          ) {
            const width = dashboard_Wrapper.offsetWidth;
            setdashboardWidth(width);
          }
        });
      });
      if (dashboard_Wrapper) {
        observer.observe(dashboard_Wrapper, { attributes: true });
      } else {
        console.log("DashboardWrapper not found");
      }
      //setdashoOpenMode(false);
    }

    // for innerdashboardportfolios element
    const observer2 = new ResizeObserver((entries) => {
      if (entries[0]?.target?.offsetWidth) {
        setinnerportWidth(entries[0].target.offsetWidth);
      }
    });

    if (elementRef.current) {
      observer2.observe(elementRef.current);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
      clearInterval(interval);
      observer2.disconnect();
    };
  }, [
    handleCallSetJSON,
    allData,
    props.pageName,
    dasheditMode,
    callcounter,
    finalStateTimeChanged,
    isDBRunning,
    settings,
    saveDashboard,
    setdashboardWidth,
    setinnerportWidth,
    elementrefwidth,
    elementRef,
  ]);
  /*   const divPosition = document.getElementById("rightpanelclickable");
  const buttonPosition = document.getElementById(
    "superdashboardeditbuttonswrapper"
  );

  const position = getOffset(divPosition);
  buttonPosition.style.position = "absolute";
  buttonPosition.style.left = position.left + "px";
  buttonPosition.style.top = position.top + "px"; */
  const components = (allData) => {
    return [
      {
        name: "cummulativereturn",
        component: (
          <CumulativeReturnGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
          />
        ),
      },
      {
        name: "pnlbymonthgraph",
        component: (
          <PnLByMonthGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={pnlmonthsorted}
          />
        ),
      },
      {
        name: "pnlbydayofweekgraph",
        component: (
          <PnLByDayofWeekGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={pnldayofweeksorted}
          />
        ),
      },
      {
        name: "pnlhistorygraph",
        component: (
          <PnLHistoryGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={pnlhistorysorted}
          />
        ),
      },
      {
        name: "pnlbytimegraph",
        component: (
          <PnLByTimeGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={pnltimesorted}
          />
        ),
      },
      {
        name: "pnlbysessiongraph",
        component: (
          <PnLBySessionGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            sorted={pnlsessionsorted}
          />
        ),
      },
      {
        name: "pnlbystrategygraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="strategy"
            title="Strategies"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbymistakegraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedMistake"
            title="Mistakes"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbysymbolgraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="symbol"
            title="Symbols"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbyassetgraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedPortfolioType"
            title="Asset Classes"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbyemotionsgraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedEmotion"
            title="Emotional States"
            titleName="cummulativereturn-label4
"
          />
        ),
      },
      {
        name: "pnlbyphysicalstategraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedPhysical"
            title="Physical States"
            titleName="cummulativereturn-label4
"
          />
        ),
      },
      {
        name: "pnlbyConfidencegraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedConfidence"
            title="Confidence"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbymarketstategraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedMarket"
            title="Market States"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
      {
        name: "pnlbytimeframegraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="selectedTimeframe"
            title="Time Frame"
            titleName="cummulativereturn-label4"
          />
        ),
      },
      {
        name: "pnlbytagsgraph",
        component: (
          <PnLByTradeInfoGraph
            userData={allData.portfolio.entries}
            userCalculations={allData.calculations}
            defaultSymbol={String(allData.portfolio.settings.currencySymbol)}
            calculationType={allData.portfolio.settings.calculationType}
            startingBalance={allData.calculations.start_Balance}
            startingDW={allData.calculations.start_DW}
            graph="tags"
            title="Tags"
            titleName="dashboard-graphs-label
"
          />
        ),
      },
    ];
  };
  // Create sortable elements
  var el = document.getElementById("maingraphsdashboardwrapper");
  var sortable =
    el &&
    Sortable.create(el, {
      animation: 150,
      filter: ".nosortingplease",
      /*       disabled: !dasheditMode, */
      handle: ".handledashmove",
      onUpdate: function (/**Event*/ evt) {
        // same properties as onEnd
        let maingraphs = [];
        const graphsdivs = el.children;
        if (!graphsdivs.length) {
        } else {
          for (let ikk = 0, jkk = graphsdivs.length; ikk < jkk; ikk++) {
            maingraphs.push(graphsdivs[ikk].id);
          }

          var localDashboardSettings = JSON.parse(
            localStorage.getItem("dashboardSettings")
          );
          const checkvar =
            localDashboardSettings && localDashboardSettings !== null
              ? localDashboardSettings
              : settings.dashboardElements;

          let maingraphsactual = checkvar.maingraphs.slice();

          maingraphsactual.sort(
            (a, b) => maingraphs.indexOf(a.name) - maingraphs.indexOf(b.name)
          );

          const dataToSet = {
            order: checkvar.order,
            tickertape: checkvar.tickertape,
            toppinnedmetrics: checkvar.toppinnedmetrics,
            maingraphs: maingraphsactual,
            pinnedPercentages: checkvar.pinnedPercentages,
            recentTradesTable: checkvar.recentTradesTable,
          };
          saveDashboard(dataToSet);
          //settopPinnedMetrics(topgraphs)
        }
      },
    });
  /*     var el2 = document.getElementById("dashboardwrapper");
    el2 &&
      setsupersortable(
        Sortable.create(el2, {
          animation: 150,
          disabled: !dasheditMode,
        })
      ); */
  var el3 = document.getElementById("superToppinnedMetricsWrapper");
  var topsortable =
    el3 &&
    Sortable.create(el3, {
      animation: 150,
      handle: ".toppinnedmetricwrappereditmode",
      /*       disabled: !dasheditMode, */
      onUpdate: function (/**Event*/ evt) {
        // same properties as onEnd
        let topgraphs = [];
        const graphsdivs3 = el3.children;
        if (!graphsdivs3.length) {
        } else {
          for (let ik = 0, jk = graphsdivs3.length; ik < jk; ik++) {
            topgraphs.push(graphsdivs3[ik].id);
          }
          var localDashboardSettings3 = JSON.parse(
            localStorage.getItem("dashboardSettings")
          );
          const checkvar =
            localDashboardSettings3 && localDashboardSettings3 !== null
              ? localDashboardSettings3
              : settings.dashboardElements;
          const dataToSet = {
            order: checkvar.order,
            tickertape: checkvar.tickertape,
            toppinnedmetrics: topgraphs,
            maingraphs: checkvar.maingraphs,
            pinnedPercentages: checkvar.pinnedPercentages,
            recentTradesTable: checkvar.recentTradesTable,
          };
          saveDashboard(dataToSet);
          //settopPinnedMetrics(topgraphs)
        }
      },
    });

  var el4 = document.getElementById("pinnedMetricsWrapper");
  var pinnedsortable =
    el4 &&
    Sortable.create(el4, {
      animation: 150,
      handle: ".grabbingActive",

      /*       disabled: !dasheditMode, */
      onUpdate: function (/**Event*/ evt) {
        // same properties as onEnd
        let graphs = [];
        const graphsdivs = el4.children;
        if (!graphsdivs.length) {
        } else {
          for (let im = 0, jm = graphsdivs.length; im < jm; im++) {
            graphs.push(graphsdivs[im].id);
          }
          var localDashboardSettings4 = JSON.parse(
            localStorage.getItem("dashboardSettings")
          );
          const checkvar =
            localDashboardSettings4 && localDashboardSettings4 !== null
              ? localDashboardSettings4
              : settings.dashboardElements;
          const dataToSet = {
            order: checkvar.order,
            tickertape: checkvar.tickertape,
            toppinnedmetrics: checkvar.toppinnedmetrics,
            maingraphs: checkvar.maingraphs,
            pinnedPercentages: graphs,
            recentTradesTable: checkvar.recentTradesTable,
          };
          saveDashboard(dataToSet);

          //setpinnedPercentages(graphs);
        }
      },
    });
  const chosenGraphsNotSort = [
    "cummulativereturn",
    "pnlbystrategygraph",
    "pnlbymistakegraph",
    "pnlbyemotionsgraph",
    "pnlbyphysicalstategraph",
    "pnlbytimeframegraph",
    "pnlbymarketstategraph",
    "pnlbyConfidencegraph",
    "pnlbysymbolgraph",
    "pnlbyassetgraph",
    "pnlbytagsgraph",
  ];

  const dashboardSettings = settings.dashboardElements;
  const adjustHeightMultiplier = width < 1680 || height < 900 ? 0.8 : 1;
  const isSmallWidth = width <= 1337 * adjustHeightMultiplier;
  const isSmallDashboardWidth = dashboardWidth <= 1266 * adjustHeightMultiplier;

  let minHeightForOpenComp = "";
  let marginforopenwrapper = "";

  if (isSmallWidth || isSmallDashboardWidth) {
    if (dashboardWidth <= 1013) {
      if (dashboardWidth <= 529) {
        minHeightForOpenComp = dashoOpenMode
          ? dashboardWidth <= 360 * (1 / adjustHeightMultiplier)
            ? isSmallWidth
              ? "556px"
              : "542px"
            : isSmallWidth
            ? "578px"
            : "542px"
          : "1000px";
        if (dashboardWidth <= 450) {
          marginforopenwrapper = !dashoOpenMode
            ? isSmallWidth
              ? "-20px 0px 98px -2px"
              : "-20px 0px 4px -2px"
            : isSmallWidth
            ? "-20px 0px 0px -2px"
            : "-20px 0px 68px -2px";
        } else {
          marginforopenwrapper = !dashoOpenMode
            ? "-16px 0px 4px -2px"
            : isSmallWidth
            ? dashoOpenMode
              ? "-16px 0px 0px -2px"
              : "0px 0px 0px -2px"
            : "-20px 0px 68px -2px";
        }
      } else {
        minHeightForOpenComp = !dashoOpenMode
          ? isSmallWidth
            ? dashoOpenMode
              ? "max-content"
              : isSmallWidth
              ? dashboardWidth <= 529 * (1 / adjustHeightMultiplier)
                ? "1000px"
                : ""
              : "1000px"
            : dashoOpenMode
            ? "578px"
            : dashboardWidth <= 529 * (1 / adjustHeightMultiplier)
            ? "1000px"
            : ""
          : dashboardWidth <= 529 * (1 / adjustHeightMultiplier)
          ? "578px"
          : "";
        marginforopenwrapper = "-16px 0px 2px -2px";
      }
    } else {
      if (height < 900 || width < 1680) {
        minHeightForOpenComp = "590px";
        marginforopenwrapper = "-14px 0px -50px 0px";
      } else {
        minHeightForOpenComp = ``;
        marginforopenwrapper = "-14px 0px 0px 0px";
      }
    }
  } else {
    if (dashboardWidth <= 529) {
      minHeightForOpenComp = "400px";
    } else {
      minHeightForOpenComp = "590px";
    }
  }
  return (
    props && (
      <div
        className={
          dasheditMode && dashpseudoeditMode
            ? "appvicegodwrapperedit"
            : "appvicegodwrapper"
        }
      >
        <PageTemplate
          title={props.tabName}
          upgradeShow={showUpgradeModal}
          setshowUpgradeModal={(e) => {
            setshowUpgradeModal({
              ...showUpgradeModal,
              show: e.show,
              featureSelect: e.featureSelect,
              blur: e.blur,
              tierText: e.tierText,
            });
          }}
          page={
            <div
              className="dashboardwrapper"
              id="dashboardwrapper"
              /*               style={{ width: dasheditMode ? "calc(100% - 300px)" : "100%" }}
               */
            >
              {dashboardSettings.tickertape.display && (
                <TickerTape
                  symbols={
                    dashboardSettings.tickertape.postChange
                      ? dashboardSettings.tickertape.symbols
                      : defaultTickerSymbols
                  }
                />
              )}
              {
                <div
                  className="superdashboardeditbuttonswrapper"
                  id="superdashboardeditbuttonswrapper"
                  onMouseLeave={async () => {
                    $(".superdashboardeditbuttonswrapper").css({
                      marginRight: "34px",
                    });
                    $(".dashboardcaretbutton").removeClass("rotate180degrees");
                    buttonwrapperToggle.current = false;
                  }}
                  onClick={async () => {
                    if (!buttonwrapperToggle.current) {
                      $(".superdashboardeditbuttonswrapper").css({
                        marginRight: "34px",
                      });
                      $(".dashboardcaretbutton").removeClass(
                        "rotate180degrees"
                      );
                      buttonwrapperToggle.current = false;
                    } else {
                      $(".superdashboardeditbuttonswrapper").css({
                        marginRight: "120px",
                      });
                      $(".dashboardcaretbutton").addClass("rotate180degrees");
                      buttonwrapperToggle.current = true;
                    }
                  }}
                >
                  <div className="dashboardeditbuttonswrapper">
                    <button
                      className={"dashboardcaretbutton"}
                      onMouseEnter={async () => {
                        if (!buttonwrapperToggle.current) {
                          $(".superdashboardeditbuttonswrapper").css({
                            marginRight: "120px",
                          });
                          $(".dashboardcaretbutton").addClass(
                            "rotate180degrees"
                          );
                          buttonwrapperToggle.current = true;
                        } else {
                        }
                      }}
                      onClick={async () => {
                        if (!buttonwrapperToggle.current) {
                          $(".superdashboardeditbuttonswrapper").css({
                            marginRight: "120px",
                          });
                          $(".dashboardcaretbutton").addClass(
                            "rotate180degrees"
                          );
                          buttonwrapperToggle.current = true;
                        } else {
                          $(".superdashboardeditbuttonswrapper").css({
                            marginRight: "34px",
                          });
                          $(".dashboardcaretbutton").removeClass(
                            "rotate180degrees"
                          );
                          buttonwrapperToggle.current = false;
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button
                      className={"dashboardeditbutton"}
                      onClick={async () => {
                        // This is to close it when the users clicks edit mode then immediately hovers off
                        setTimeout(() => {
                          if (
                            $("#superdashboardeditbuttonswrapper:hover")
                              .length > 0
                          ) {
                          } else {
                            $(".superdashboardeditbuttonswrapper").css({
                              marginRight: "34px",
                            });
                            $(".dashboardcaretbutton").removeClass(
                              "rotate180degrees"
                            );
                            buttonwrapperToggle.current = false;
                          }
                        }, 1);

                        if (tier === "free") {
                          setshowUpgradeModal({
                            ...showUpgradeModal,
                            show: true,
                            featureSelect: "Customizable dashboard",
                            blur: false,
                          });
                        } else if (
                          (tier === "pro" && active) ||
                          (tier === "oldpro" && active) ||
                          (tier === "master" && active) ||
                          (tier === "ultimate" && active) ||
                          (tier === "admin" && active)
                        ) {
                          if (!dasheditMode) {
                            $(".toppinnedmetricwrapper").addClass(
                              "toppinnedmetricwrappereditmode"
                            );
                            $(".winrategraph").addClass("grabbingActive");
                            props.seteditMode(true);
                            setdashpseudoeditMode(true);
                          } else {
                            $(".toppinnedmetricwrapper").removeClass(
                              "toppinnedmetricwrappereditmode"
                            );
                            $(".winrategraph").removeClass("grabbingActive");
                            props.seteditMode(false);
                            setdashpseudoeditMode(false);
                            sortable.destroy();
                            topsortable.destroy();
                            pinnedsortable.destroy();
                            sortable.option("disabled", true);
                            topsortable.option("disabled", true);
                            pinnedsortable.option("disabled", true);
                            forceUpdate();
                          }
                        } else {
                          setshowUpgradeModal({
                            ...showUpgradeModal,
                            show: true,
                            featureSelect: "Customizable dashboard",
                            blur: false,
                          });
                        }
                      }}
                    >
                      {!dasheditMode ? (
                        /*                         <PopoverStickOnHover
                          component={
                            <div
                              style={{
                                color: "#fbd665",
                                padding: "2px",
                                width: 99,
                                textAlign: "left",
                              }}
                            >
                              Edit Dashboard
                            </div>
                          }
                          placement="bottom"
                          onMouseEnter={() => {}}
                          delay={250}
                          setClass={"editdashboaerdtooltipclass"}
                          keepOpen={false}
                          transformMe={false}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </PopoverStickOnHover> */ <FontAwesomeIcon
                          icon={faEdit}
                        />
                      ) : (
                        <div style={{ transform: "scaleX(-1)" }}>
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              }
              {dasheditMode && (
                <div className="dashboardeditnotifwrapper">
                  {
                    "You are currently editing the dashboard. Changes will be saved automatically."
                  }
                  <button
                    id={"doneditingbutton"}
                    onClick={() => {
                      props.seteditMode(false);
                      $(".toppinnedmetricwrapper").removeClass(
                        "toppinnedmetricwrappereditmode"
                      );
                      $(".winrategraph").removeClass("grabbingActive");
                      sortable.destroy();
                      topsortable.destroy();
                      pinnedsortable.destroy();
                      sortable.option("disabled", true);
                      topsortable.option("disabled", true);
                      pinnedsortable.option("disabled", true);
                      forceUpdate();
                    }}
                  >
                    Done
                  </button>
                  {!dashpseudoeditMode && (
                    <button
                      id={"psuedodoneditingbutton"}
                      onClick={() => {
                        setdashpseudoeditMode(true);
                        forceUpdate();
                      }}
                    >
                      Show Edit Pane
                    </button>
                  )}
                </div>
              )}
              <TopPinnedMetrics
                userCalculations={allData.calculations}
                userData={allData.portfolio.entries}
                defaultSymbol={String(
                  allData.portfolio.settings.currencySymbol
                )}
                userSettings={allData.portfolio.settings}
                dasheditMode={dasheditMode}
              />
              {width <= 1337 * adjustHeightMultiplier ||
              dashboardWidth <= 1266 * adjustHeightMultiplier ? (
                <div className="innerdashboardcolumnwrapper1">
                  <div className="innerdashboardcolumnequitywrapover">
                    <CurrentEquity
                      userData={allData.portfolio.entries}
                      defaultSymbol={String(
                        allData.portfolio.settings.currencySymbol
                      )}
                      dasheditMode={dasheditMode}
                      portfolio={portfolio}
                      calculations={allData.calculations}
                      calculationType={
                        allData.portfolio.settings.calculationType
                      }
                      allData={allData}
                    />
                    <PerformanceMeter
                      userData={allData.portfolio.entries}
                      riskFreeRate={riskFreeRate}
                      calculationType={
                        allData.portfolio.settings.calculationType
                      }
                      allData={allData}
                    />
                  </div>
                  <PinnedMetrics
                    userCalculations={allData.calculations}
                    userData={allData.portfolio.entries}
                    defaultSymbol={String(
                      allData.portfolio.settings.currencySymbol
                    )}
                    userSettings={allData.portfolio.settings}
                    dasheditMode={dasheditMode}
                  />
                </div>
              ) : (
                <div className="innerdashboardcolumnwrapper">
                  <div className="innerdashboardcolumn">
                    <div className="innerdashboardcolumnequitywrapover">
                      <CurrentEquity
                        userData={allData.portfolio.entries}
                        defaultSymbol={String(
                          allData.portfolio.settings.currencySymbol
                        )}
                        dasheditMode={dasheditMode}
                        allData={allData}
                        portfolio={portfolio}
                        calculations={allData.calculations}
                        calculationType={
                          allData.portfolio.settings.calculationType
                        }
                      />
                      <PerformanceMeter
                        riskFreeRate={riskFreeRate}
                        userData={allData.portfolio.entries}
                        calculationType={
                          allData.portfolio.settings.calculationType
                        }
                        allData={allData}
                      />
                    </div>
                    <PinnedMetrics
                      userCalculations={allData.calculations}
                      userData={allData.portfolio.entries}
                      defaultSymbol={String(
                        allData.portfolio.settings.currencySymbol
                      )}
                      userSettings={allData.portfolio.settings}
                      dasheditMode={dasheditMode}
                    />
                  </div>
                  <div
                    ref={elementRef}
                    id="innerdashboardportfolios"
                    className="innerdashboardportfolios"
                    style={{
                      minHeight: minHeightForOpenComp,
                      margin: marginforopenwrapper,
                    }}
                  >
                    <OpenTradeComponent
                      userData={allData}
                      tier={tier}
                      active={active}
                      dashboardwidth={dashboardWidth}
                      upgradeShow={showUpgradeModal}
                      setshowUpgradeModal={(e) => {
                        setshowUpgradeModal({
                          ...showUpgradeModal,
                          show: e.show,
                          featureSelect: e.featureSelect,
                          blur: e.blur,
                          tierText: e.tierText,
                        });
                      }}
                      setOpenPositionsState={(e) => {
                        setdashoOpenMode(e);
                      }}
                      innerwidth={innerportWidth}
                    />
                  </div>
                </div>
              )}
              {(width <= 1337 * adjustHeightMultiplier ||
                dashboardWidth <= 1266 * adjustHeightMultiplier) && (
                <div
                  ref={elementRef}
                  className="innerdashboardportfolios"
                  style={{
                    minHeight: minHeightForOpenComp,
                    margin: marginforopenwrapper,
                  }}
                  id="innerdashboardportfolios"
                >
                  <OpenTradeComponent
                    userData={allData}
                    tier={tier}
                    active={active}
                    dashboardwidth={dashboardWidth}
                    upgradeShow={showUpgradeModal}
                    setshowUpgradeModal={(e) => {
                      setshowUpgradeModal({
                        ...showUpgradeModal,
                        show: e.show,
                        featureSelect: e.featureSelect,
                        blur: e.blur,
                        tierText: e.tierText,
                      });
                    }}
                    setOpenPositionsState={(e) => {
                      setdashoOpenMode(e);
                    }}
                    innerwidth={innerportWidth}
                  />
                </div>
              )}
              <div
                className="maingraphsdashboardwrapper"
                id="maingraphsdashboardwrapper"
              >
                {chosenGraphs.map((comp) => {
                  const component = components(allData).filter(
                    (item) => item.name === comp
                  );
                  let sorttext = "";
                  // sort button text
                  if (comp === "pnlhistorygraph") {
                    if (!pnlhistorysorted) {
                      sorttext = "Sort By Date";
                    } else {
                      sorttext = "Sort By P/L";
                    }
                  } else if (comp === "pnlbytimegraph") {
                    if (!pnltimesorted) {
                      sorttext = "By Last Exit";
                    } else {
                      sorttext = "By First Entry";
                    }
                  } else if (comp === "pnlbydayofweekgraph") {
                    if (!pnldayofweeksorted) {
                      sorttext = "By Last Exit";
                    } else {
                      sorttext = "By First Entry";
                    }
                  } else if (comp === "pnlbymonthgraph") {
                    if (!pnlmonthsorted) {
                      sorttext = "By Last Exit";
                    } else {
                      sorttext = "By First Entry";
                    }
                  } else if (comp === "pnlbysessiongraph") {
                    if (!pnlsessionsorted) {
                      sorttext = "By Last Exit";
                    } else {
                      sorttext = "By First Entry";
                    }
                  }
                  // -------------
                  const returncomp = component[0] && component[0].component;
                  const returnclass = "maingraphsdashboardinner dashtopwrapper";
                  return (
                    <div id={comp} className={returnclass} key={comp}>
                      {returncomp}
                      {dasheditMode && (
                        <div>
                          <button
                            className="handledashdostuff"
                            onClick={() => {
                              const editelemshow = $(
                                `#${comp + "dashboardeditelem"}`
                              );
                              if (editelemtog === 1) {
                                editelemshow.css({ display: "none" });
                                editelemtog = 0;
                              } else {
                                editelemshow.css({ display: "block" });
                                editelemtog = 1;
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </button>
                          <div
                            className={
                              !chosenGraphsNotSort.includes(comp)
                                ? "dropdown-list-dashboardeditelem"
                                : "dropdown-list-dashboardeditelem2"
                            }
                            id={comp + "dashboardeditelem"}
                            key={comp}
                          >
                            {/* show the sorting for only specific graphs */}
                            {!chosenGraphsNotSort.includes(comp) && (
                              <div className="dropdown-text2">
                                <div
                                  display="block"
                                  className="dropdown-items2"
                                  onClick={() => {
                                    var localDashboardSettings4 = JSON.parse(
                                      localStorage.getItem("dashboardSettings")
                                    );
                                    const checkvar =
                                      localDashboardSettings4 !== null
                                        ? localDashboardSettings4
                                        : settings.dashboardElements;
                                    let maingraphs = checkvar.maingraphs;

                                    // -----------------------------

                                    if (comp === "pnlhistorygraph") {
                                      // Save sorting in pnl history graph
                                      const indexhistory =
                                        maingraphs.indexOf("pnlhistorygraph");
                                      let plhist = maingraphs.filter(
                                        (comp) =>
                                          comp.name === "pnlhistorygraph"
                                      );
                                      let plhistnot = maingraphs.slice();
                                      if (!pnlhistorysorted) {
                                        setpnlhistorysorted(true);
                                        plhist[0].sorted = true;
                                      } else {
                                        setpnlhistorysorted(false);
                                        plhist[0].sorted = false;
                                      }
                                      plhistnot[indexhistory] = plhist[0];
                                      const dataToSet = {
                                        order: checkvar.order,
                                        tickertape: checkvar.tickertape,
                                        toppinnedmetrics:
                                          checkvar.toppinnedmetrics,
                                        maingraphs: plhistnot,
                                        pinnedPercentages:
                                          checkvar.pinnedPercentages,
                                        recentTradesTable:
                                          checkvar.recentTradesTable,
                                      };
                                      saveDashboard(dataToSet);
                                    } else if (comp === "pnlbytimegraph") {
                                      // Save sorting in pnl time graph
                                      const indextime =
                                        maingraphs.indexOf("pnlbytimegraph");
                                      let pltime = maingraphs.filter(
                                        (comp) => comp.name === "pnlbytimegraph"
                                      );
                                      let pltimenot = maingraphs.slice();
                                      if (!pnltimesorted) {
                                        setpnltimesorted(true);
                                        pltime[0].sorted = true;
                                      } else {
                                        setpnltimesorted(false);
                                        pltime[0].sorted = false;
                                      }
                                      pltimenot[indextime] = pltime[0];
                                      const dataToSet = {
                                        order: checkvar.order,
                                        tickertape: checkvar.tickertape,
                                        toppinnedmetrics:
                                          checkvar.toppinnedmetrics,
                                        maingraphs: pltimenot,
                                        pinnedPercentages:
                                          checkvar.pinnedPercentages,
                                        recentTradesTable:
                                          checkvar.recentTradesTable,
                                      };
                                      saveDashboard(dataToSet);
                                    } else if (comp === "pnlbydayofweekgraph") {
                                      // Save sorting in pnl time graph
                                      const indexdow = maingraphs.indexOf(
                                        "pnlbydayofweekgraph"
                                      );
                                      let pldayofweek = maingraphs.filter(
                                        (comp) =>
                                          comp.name === "pnlbydayofweekgraph"
                                      );
                                      let pldayofweeknot = maingraphs.slice();
                                      if (!pnldayofweeksorted) {
                                        setpnldayofweeksorted(true);
                                        pldayofweek[0].sorted = true;
                                      } else {
                                        setpnldayofweeksorted(false);
                                        pldayofweek[0].sorted = false;
                                      }
                                      pldayofweeknot[indexdow] = pldayofweek[0];
                                      const dataToSet = {
                                        order: checkvar.order,
                                        tickertape: checkvar.tickertape,
                                        toppinnedmetrics:
                                          checkvar.toppinnedmetrics,
                                        maingraphs: pldayofweeknot,
                                        pinnedPercentages:
                                          checkvar.pinnedPercentages,
                                        recentTradesTable:
                                          checkvar.recentTradesTable,
                                      };
                                      saveDashboard(dataToSet);
                                    } else if (comp === "pnlbymonthgraph") {
                                      const indexmonth =
                                        maingraphs.indexOf("pnlbysessiongraph");
                                      // Save sorting in pnl time graph
                                      let plmonth = maingraphs.filter(
                                        (comp) =>
                                          comp.name === "pnlbymonthgraph"
                                      );
                                      let plmonthnot = maingraphs.slice();
                                      if (!pnlmonthsorted) {
                                        setpnlmonthsorted(true);
                                        plmonth[0].sorted = true;
                                      } else {
                                        setpnlmonthsorted(false);
                                        plmonth[0].sorted = false;
                                      }
                                      plmonthnot[indexmonth] = plmonth[0];

                                      const dataToSet = {
                                        order: checkvar.order,
                                        tickertape: checkvar.tickertape,
                                        toppinnedmetrics:
                                          checkvar.toppinnedmetrics,
                                        maingraphs: plmonthnot,
                                        pinnedPercentages:
                                          checkvar.pinnedPercentages,
                                        recentTradesTable:
                                          checkvar.recentTradesTable,
                                      };
                                      saveDashboard(dataToSet);
                                    } else if (comp === "pnlbysessiongraph") {
                                      // Save sorting in pnl time graph
                                      const indexsession =
                                        maingraphs.indexOf("pnlbysessiongraph");
                                      let plsession = maingraphs.filter(
                                        (comp) =>
                                          comp.name === "pnlbysessiongraph"
                                      );
                                      let plsessionnot = maingraphs.slice();
                                      if (!pnlsessionsorted) {
                                        setpnlsessionsorted(true);
                                        plsession[0].sorted = true;
                                      } else {
                                        setpnlsessionsorted(false);
                                        plsession[0].sorted = false;
                                      }
                                      plsessionnot[indexsession] = plsession[0];
                                      const dataToSet = {
                                        order: checkvar.order,
                                        tickertape: checkvar.tickertape,
                                        toppinnedmetrics:
                                          checkvar.toppinnedmetrics,
                                        maingraphs: plsessionnot,
                                        pinnedPercentages:
                                          checkvar.pinnedPercentages,
                                        recentTradesTable:
                                          checkvar.recentTradesTable,
                                      };
                                      saveDashboard(dataToSet);
                                    }
                                  }}
                                >
                                  {sorttext}
                                </div>
                              </div>
                            )}
                            <div className="dropdown-text2">
                              <div display="block" className="dropdown-items2">
                                Delete
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {dasheditMode && (
                        <span className="handledashmove">
                          <FontAwesomeIcon icon={faGripLines} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          }
          userData={allData}
        />{" "}
        {dasheditMode && dashpseudoeditMode && (
          <EditDashboardComponent
            userData={allData}
            tier={tier}
            active={active}
            dashboardwidth={dashboardWidth}
            upgradeShow={showUpgradeModal}
            setshowUpgradeModal={(e) => {
              setshowUpgradeModal({
                ...showUpgradeModal,
                show: e.show,
                featureSelect: e.featureSelect,
                blur: e.blur,
                tierText: e.tierText,
              });
            }}
            setEditState={(e) => props.seteditMode(e)}
            setPsuedoEditState={(e) => setdashpseudoeditMode(e)}
            setOpenPositionsState={(e) => {
              setdashoOpenMode(e);
            }}
            innerwidth={innerportWidth}
          />
        )}
      </div>
    )
  );
};

export default DashboardComponent;
